import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';

type Props = {
  organizationToDelete: string | undefined;
  deleteOrganization: (id: string) => void;
  cascade: boolean;
};

function DeleteOrganization({ organizationToDelete, deleteOrganization, cascade }: Props) {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteOrganization = async () => {
    setLoadingButton(true);
    try {
      await apiFetch('delete', `/organizations/${organizationToDelete}`);

      organizationToDelete && deleteOrganization(organizationToDelete);
      setLoadingButton(false);
    } catch (err) {
      console.error(err);
      setLoadingButton(false);
    }
  };

  const handleDeleteOrganizationCascade = async () => {
    setLoadingButton(true);
    try {
      await apiFetch('delete', `/organizations/cascade/${organizationToDelete}`);

      organizationToDelete && deleteOrganization(organizationToDelete);
      setLoadingButton(false);
    } catch (err) {
      console.error(err);
      setLoadingButton(false);
    }
  };

  return (
    <div className='delete-modal'>
      <h1 className='headline4-font'>
        {cascade ? t('admin.deleteOrganizationCascade') : t('admin.deleteOrganization')}
      </h1>
      <h2 className='subtitle3-font '>{t('admin.areYouSureDelete')}</h2>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('admin.delete')}
          onClick={cascade ? handleDeleteOrganizationCascade : handleDeleteOrganization}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default DeleteOrganization;
