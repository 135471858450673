import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { SIZE_PAGINATION_SMALL, VIDEOS } from '../../../../constants';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getSocialDataList } from '../../../../services/api/socialData';
import { ResponseSocialData } from '../../../../types/entities/socialData';
import formatNumber from '../../../../utils/formatNumber';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import CardCTA from '../../../ui/cards/cardCTA/CardCTA';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import Icon from '../../../ui/icon/Icon';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import useFetchInfiniteList from '../../../ui/infiniteList/hooks/useFetchInfiniteList';
import Modal from '../../../ui/modal/Modal';
import TotalLegendV2 from '../../../ui/totalLegend/TotalLegendV2';
import YoutubeVideo from '../../../ui/videoPlayer/YoutubeVideo';
import VideoTutorialPopup from '../../../ui/videoTutorialPopup/VideoTutorialPopup';
import AddResponse from './addResponse/AddResponse';
import DeleteResponse from './deleteResponse/DeleteResponse';
import EditResponse from './editResponse/EditResponse';
import useColumns from './hooks/useColumns';
import './styles.scss';

const SocialAndGovernance = () => {
  const { t, i18n } = useTranslation();
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const dispatch = useDispatch();

  const selectedOrganization = useSelectedOrganization();

  const [showAdd, setShowAdd] = useState(false);
  const [responseIdToEdit, setResponseIdToEdit] = useState('');
  const [responseIdToDelete, setResponseIdToDelete] = useState('');
  const [showVideo, setShowVideo] = useState(false);

  const videos = VIDEOS.socialAndGovernance as { [key: string]: string };

  const videoLink = videos[i18n.resolvedLanguage] as string;

  const fetch = async (page: number) => {
    const response = await getSocialDataList(page, SIZE_PAGINATION_SMALL);
    if (response?.response?.status >= 400) return;
    return response;
  };

  const { fetchData, data, addElement, editElement, removeElement, total, loading } =
    useFetchInfiniteList(fetch, []);

  const addSocialData = (socialData: ResponseSocialData) => {
    setShowAdd(false);
    addElement(socialData);
    dispatch(setNotification(t('notification.addSocialData')));
  };

  const editSocialData = (socialData: ResponseSocialData) => {
    editElement(socialData);
    setResponseIdToEdit('');
    dispatch(setNotification(t('notification.editSocialData')));
  };

  const deleteSocialData = (id: string) => {
    removeElement(id);
    setResponseIdToDelete('');
    dispatch(setNotification(t('notification.deleteSocialData')));
  };

  const onOpenAddSocialData = () => setShowAdd(true);
  const onOpenVideo = () => setShowVideo(true);

  const onCloseAddSocialData = () => setShowAdd(false);
  const onCloseDeleteSocialData = () => setResponseIdToDelete('');
  const onCloseVideo = () => setShowVideo(false);

  const columns = useColumns();

  const renderBooleanValue = (value: boolean | null) => {
    if (value === true) {
      return t('socialAndGovernance.yes');
    }
    if (value === false) {
      return t('socialAndGovernance.no');
    }
    return '-';
  };

  const parseData = (responses: ResponseSocialData[]) => {
    return responses.map((response) => {
      const dropdownOptions = [
        {
          id: 'edit',
          name: t('socialAndGovernance.edit'),
          onClick: () => {
            setResponseIdToEdit(response.id);
          }
        }
      ];
      if (selectedOrganization?.role !== 'fund') {
        dropdownOptions.push({
          id: 'delete',
          name: t('socialAndGovernance.delete'),
          onClick: () => {
            setResponseIdToDelete(response.id);
          }
        });
      }
      return {
        year: response.year_of_data,
        globalCompact: renderBooleanValue(response.global_compact_monitoring_protocol),
        infringement: renderBooleanValue(response.global_compact_infringement),
        guns: renderBooleanValue(response.manufacture_and_sale_of_weapons),
        totalRevenue:
          response.company_annual_revenue !== null
            ? `${formatNumber(response.company_annual_revenue)}€`
            : '-',
        menWomenRevenue:
          response.women_annual_mean_income !== null && response.men_annual_mean_income !== null
            ? `${formatNumber(response.women_annual_mean_income)}€/${tGeneral(
                'hour'
              )} - ${formatNumber(response.men_annual_mean_income)}€/${tGeneral('hour')}`
            : '-',
        boardDirectors:
          response.number_of_women_in_the_board !== null &&
          response.number_of_men_in_the_board !== null
            ? `${formatNumber(response.number_of_women_in_the_board)} ${tGeneral(
                'women'
              )}, ${formatNumber(response.number_of_men_in_the_board)} ${tGeneral('men')}`
            : '-',
        biodiversity: renderBooleanValue(response.activities_in_biodiversity_hotspots),
        waste:
          response.amount_of_discharges_water_bodies !== null
            ? `${formatNumber(response.amount_of_discharges_water_bodies)} tons`
            : '-',
        edit: (
          <ButtonDropdown
            button={<Icon icon={'elipsis_horizontal'} color='gray-dark' />}
            options={dropdownOptions}
          />
        )
      };
    });
  };

  return (
    <section className='socialAndGovernance'>
      <div className='socialAndGovernance__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('socialAndGovernance.title')}</h3>
        <Breadcrumb />
      </div>
      <CardCTA style={{ marginTop: '1rem' }}>
        <CardCTA.Header>
          <span className='headline4-font'>{t('socialAndGovernance.start')}</span>
          <span className='subtitle3-font'>{t('socialAndGovernance.startDescription')}</span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <Button
            lookAndFeel={'primary'}
            text={t('socialAndGovernance.viewForm')}
            size='small'
            onClick={onOpenAddSocialData}
            disabled={selectedOrganization?.role === 'fund'}
            style={{ width: '7%' }}
          />
        </CardCTA.Buttons>
      </CardCTA>
      <InfiniteList
        header={
          <TotalLegendV2
            totalLabel='Total'
            totalElements={[{ value: total, label: t('socialAndGovernance.total') }]}
            style={{ marginLeft: 'auto' }}
          />
        }
        data={parseData(data)}
        columns={columns}
        loading={loading}
        firstLoading={loading && data.length === 0}
        fetchData={fetchData}
      />
      <Modal.WithPortal
        show={showAdd}
        onClose={onCloseAddSocialData}
        maxWidth={'756px'}
        width={'756px'}>
        <AddResponse
          addSocialData={addSocialData}
          onClose={() => setShowAdd(false)}
          yearsAnswered={data.map((elem) => elem.year_of_data.toString())}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!responseIdToEdit}
        onClose={() => setResponseIdToEdit('')}
        maxWidth={'756px'}
        width={'756px'}>
        <EditResponse
          socialDataId={responseIdToEdit}
          editSocialData={editSocialData}
          onClose={() => setResponseIdToEdit('')}
          yearsAnswered={data.map((elem) => elem.year_of_data.toString())}
        />
      </Modal.WithPortal>
      <Modal.WithPortal show={!!responseIdToDelete} onClose={onCloseDeleteSocialData}>
        <DeleteResponse
          id={responseIdToDelete}
          deleteResponse={deleteSocialData}
          onClose={onCloseDeleteSocialData}
        />
      </Modal.WithPortal>
      {videoLink && (
        <>
          <VideoTutorialPopup
            title={t('socialAndGovernance.videoTitle')}
            description={t('socialAndGovernance.discover')}
            image={'/images/cecilia.png'}
            onClick={onOpenVideo}
          />
          <Modal show={showVideo} onClose={onCloseVideo} width='700px'>
            <FormHeader title={t('socialAndGovernance.demo')} />
            <YoutubeVideo url={videoLink} />
          </Modal>
        </>
      )}
    </section>
  );
};

export default SocialAndGovernance;
