import React from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';

type Props = {
  i18key: string;
};
const TransportDistributionDisclaimer = ({ i18key }: Props) => {
  const { t } = useTranslation();

  return (
    <DescriptionBox
      descriptions={[
        t(`ghgReport.${i18key}Disclaimer.description1`),
        t(`ghgReport.${i18key}Disclaimer.description2`),
        t(`ghgReport.${i18key}Disclaimer.description3`),
        t(`ghgReport.${i18key}Disclaimer.description4`),
        t(`ghgReport.${i18key}Disclaimer.description5`)
      ]}
    />
  );
};

export default TransportDistributionDisclaimer;
