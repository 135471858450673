import { DropdownOptionAPI } from 'nuvo-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRdLerList } from '../../../../../../../../services/api/waste';
import { RdListElement } from '../../../../../../../../types/entities/waste';

const useLerRdCodes = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [lerCodesState, setLerCodes] = useState<DropdownOptionAPI[]>([]);
  const [rdCodesState, setRdCodes] = useState<DropdownOptionAPI[]>([]);

  const fetch = async () => {
    setLoading(true);
    const response = await getRdLerList();
    setLoading(false);
    if (response instanceof Error || response?.response?.status >= 400) return;

    const list: RdListElement[] = response;

    const rdCodes: DropdownOptionAPI[] = [];
    const lerCodes: DropdownOptionAPI[] = [];

    const lerCodesSet: Set<string> = new Set();

    list.forEach((element) => {
      const lerCodesBasic: string[] = [];

      if (element.rd_code === null) return;

      element.ler_list.forEach((ler) => {
        lerCodesBasic.push(ler.ler_code);

        if (lerCodesSet.has(ler.ler_code)) return;

        lerCodes.push({
          type: 'string',
          value: ler.ler_code,
          label: ler.ler_code,
          alternativeMatches: [
            ler.ler_name,
            ler.ler_code,
            `${ler.ler_code} ${t(`low_codes.${ler.ler_name}`)}`
          ]
        });
        lerCodesSet.add(ler.ler_code);
      });

      rdCodes.push({
        type: 'string',
        value: element.rd_code,
        label: element.rd_code,
        alternativeMatches: [
          element.rd_name,
          element.rd_code,
          `${element.rd_code} ${t(`rd_codes.${element.rd_name}`)}`
        ],
        validations: [
          {
            validate: {
              EQ: { ler_code: lerCodesBasic }
            }
          }
        ]
      });
    });

    setLerCodes(lerCodes);
    setRdCodes(rdCodes);
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    loading,
    lerCodes: lerCodesState,
    rdCodes: rdCodesState
  };
};
export default useLerRdCodes;
