import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import { deleteIntensityMetricGroup } from '../../../../services/api/intensityMetrics';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  id: string;
  removeIntensityMetricGroup: (id: string) => void;
  closeModal: () => void;
};
const DeleteIntensityMetricGroup = ({ id, removeIntensityMetricGroup, closeModal }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDelete = async () => {
    setLoadingButton(true);
    // Delete intensity metric group
    await deleteIntensityMetricGroup(id, user?.selectedOrganization ?? '');
    setLoadingButton(false);
    // Callback and remove from list
    removeIntensityMetricGroup(id);
  };

  return (
    <div className='delete-intensity-metric-group'>
      <FormHeader
        title={t('deleteIntensityMetricGroup.title')}
        description={t('deleteIntensityMetricGroup.description')}
      />
      <FormButtonSection>
        <Button lookAndFeel={'secondary'} onClick={closeModal} text={t('general.cancel')} />
        <Button
          lookAndFeel={'warning'}
          onClick={handleDelete}
          text={t('deleteIntensityMetricGroup.delete')}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteIntensityMetricGroup;
