import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../types/table';
import { StatusTag } from '../../../../../ui/statusTag';
import { STATUS_I18N_KEYS } from '../../constants';
import { limitString } from '../../../../../../utils/limitString';
import descriptionsPrimaryCode from '../../../../../../constants/json/sic_description.json';
import { Status } from '../../../../../../types/utilsEnums/status';

export const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'codeManagement.primaryCode.columns' });

  const columns: Column[] = [
    {
      title: t('code'),
      dataIndex: 'code',
      key: 'code',
      width: 100
    },
    {
      title: t('type'),
      dataIndex: 'framework',
      key: 'framework',
      width: 100
    },
    {
      title: t('description'),
      dataIndex: 'code',
      key: 'description',
      width: 150,
      render: (code) => {
        if (!code) return '-';

        const description = descriptionsPrimaryCode[code as keyof typeof descriptionsPrimaryCode];

        return description ? limitString(description, 80) : '-';
      }
    },
    {
      title: t('dcycle-product'),
      dataIndex: ['dcycleProduct', 'name'],
      key: 'dcycleProduct',
      render: (dcycleProduct) => (dcycleProduct ? limitString(dcycleProduct, 80) : '-')
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status) =>
        status && (
          <StatusTag
            status={status as Status}
            text={STATUS_I18N_KEYS[status as keyof typeof STATUS_I18N_KEYS]}
          />
        )
    }
  ];

  return { columns };
};
