import ToggleElement from './ToggleElement';
import './styles.scss';

type ToggleOption = {
  id: string;
  name: string;
  tooltip?: string;
};
type Props = {
  label?: string;
  options: ToggleOption[]; // Min length 2
  value: ToggleOption;
  setValue: () => void;
  disabled?: boolean;
  tooltipPosition?: 'top' | 'left';
};

function SlideToggle2({
  label,
  options,
  value,
  setValue,
  disabled = false,
  tooltipPosition = 'top'
}: Props) {
  const onToggle = () => {
    if (!disabled) {
      setValue();
    }
  };

  if (options.length < 2) {
    return null;
  }

  return (
    <div className='slide-toggle-wrapper2'>
      {label && <span className={'body2-font'}>{label}</span>}
      <div className='slide-toggle input-border' onClick={onToggle}>
        {options.map((option) => (
          <ToggleElement
            key={option.id}
            option={option}
            value={value}
            tooltipPosition={tooltipPosition}
          />
        ))}
      </div>
    </div>
  );
}

export default SlideToggle2;
