import apiFetch from '../../../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../../../../utils/url';
import type { LCAFoldersResponse } from '../types/LCADetail';

type Params = {
  id?: string;
  methodologyId?: string;
  impactCategory?: string;
};

const URL = '/acv/portfolio';

export const getLCAFolders = async ({
  id,
  methodologyId,
  impactCategory
}: Params): Promise<LCAFoldersResponse> => {
  if (!id || !methodologyId || !impactCategory) {
    throw new Error('Missing required parameters');
  }

  const response = await apiFetch(
    'GET',
    getUrl(`${URL}/${id}/folders`, {
      queryParams: generateQueryParamsFromObject({
        methodology_id: methodologyId,
        impact_category: impactCategory
      })
    })
  );

  return response.data;
};
