import apiFetch from '../../utils/apiFetch';

export const sendEmails = async (
  subject: string,
  fileBase64?: string,
  organizations?: string[],
  emails?: string[]
) => {
  try {
    const data = {
      subject,
      html: fileBase64,
      organization_ids: organizations,
      emails
    };

    const response = await apiFetch('POST', '/emails', data);

    return response.data;
  } catch (error) {
    return error;
  }
};
