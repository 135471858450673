import { Position } from '../hooks/useHandleStep';

export const getBiggerPosition = (position: Position | null) => {
  if (!position) return null;

  return {
    left: position.left - 5,
    top: position.top - 5,
    right: position.right - 5,
    bottom: position.bottom - 5,
    width: position.width + 10,
    height: position.height + 10
  };
};
