import { Dispatch, SetStateAction } from 'react';
import { EventDrink, PreEventFormData } from '../../../../../../types/entities/events';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputDrink from './InputDrink';

type Props = {
  setFormData: Dispatch<SetStateAction<PreEventFormData>>;
  formData: EventDrink[];
};
const Drinks = ({ setFormData, formData }: Props) => {
  const drinks = ['water', 'coffee', 'soda', 'alcohol'];

  const getIconSrc = (type: string) => {
    type Icon = {
      [key: string]: string;
    };
    const icon: Icon = {
      water: '/images/icons/waterBottle.svg',
      coffee: '/images/icons/coffeeToGo.svg',
      soda: '/images/icons/soda.svg',
      alcohol: '/images/icons/alcohol.svg'
    };
    return icon[type];
  };

  const onChangeValue = (value: EventDrink) => {
    const index = formData.findIndex(
      (data) => data.type === value.type && data.container === value.container
    );
    if (index === -1) {
      setFormData((prev) => ({ ...prev, drinks: [...prev.drinks, value] }));
    } else {
      const newFormData = [...formData];
      newFormData[index] = value;
      setFormData((prev) => ({ ...prev, drinks: newFormData }));
    }
  };

  return (
    <div className='pre-event-drinks'>
      <FormWrapper>
        {drinks.map((drink) => {
          return (
            <InputDrink
              key={drink}
              type={drink}
              data={formData}
              onChangeValue={onChangeValue}
              icon={getIconSrc(drink)}
            />
          );
        })}
      </FormWrapper>
    </div>
  );
};

export default Drinks;
