import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const FormWrapper = ({ className, ...props }: Props) => {
  return (
    <div className={'form-wrapper-standard ' + className} {...props}>
      {props.children}
    </div>
  );
};

export default FormWrapper;
