import { useTranslation } from 'react-i18next';
import { months } from 'constants/months';
import { Month } from 'types/utils/months';

const useTranslatedMonths = () => {
  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage as keyof Month['short'];

  // Generate a list of translated month names based on the language
  const translatedMonths = months.map((month) => month.short[lang]);

  return translatedMonths;
};

export default useTranslatedMonths;
