import React from 'react';
import QRCode from 'react-qr-code';
import './styles.scss';
type Props = {
  value: string;
  maxWidth?: number;
  size?: number;
};
const QrCode = ({ value, maxWidth = 64, size = 256 }: Props) => {
  return (
    <div
      className='qr-wrapper dashed-border'
      style={{ height: 'auto', margin: '0 auto', maxWidth, width: '100%' }}>
      <QRCode
        size={size}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={value}
        viewBox={`0 0 ${size} ${size}`}
      />
    </div>
  );
};

export default QrCode;
