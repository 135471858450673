import { useTranslation } from 'react-i18next';

const useSubscriptionPlanOptions = () => {
  const { t } = useTranslation();

  const options = [
    {
      id: 'free_plan',
      name: t('admin.free_plan')
    },
    {
      id: 'custom_paid_plan',
      name: t('admin.custom_paid_plan')
    }
  ];

  return options;
};

export default useSubscriptionPlanOptions;
