import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommutingType } from '../../../../../types/entitiesEnums/employeePeriod';
import StepCarpooling from './stepCarpooling/StepCarpooling';
import StepDailyTrips from './stepDailyTrips/StepDailyTrips';
import StepDailyTripsInLabore from './stepDailyTrips/StepDailyTripsInLabore';
import StepName from './stepName/StepName';
import StepStartDate from './stepStartDate/StepStartDate';
import StepSummary from './stepSummary/StepSummary';
import StepSummaryPepeJeans from './stepSummaryPepeJeans/StepSummaryPepeJeans';
import StepTelecommute from './stepTelecommute/StepTelecommute';
import StepThanksPepeJeans from './stepThanksPepeJeans/StepThanksPepeJeans';
import StepTotalKm from './stepTotalKm/StepTotalKm';
import StepTotalKmToInLabore from './stepTotalKm/StepTotalKmToInLabore';
import StepTransport from './stepTransport/StepTransport';
import StepType from './stepType/StepType';
import StepWeeklyTravels from './stepWeeklyTravels/StepWeeklyTravels';
import StepWeeklyTravelsInLabore from './stepWeeklyTravels/StepWeeklyTravelsInLabore';

type Props = {
  type: CommutingType | null;
  isQrStatus: boolean;
  isPepeJeans: boolean;
};

const useSteps = ({ type, isQrStatus, isPepeJeans }: Props) => {
  const { t } = useTranslation();

  // IN ITINERE
  const stepsInItinere = {
    startDate: <StepStartDate next='telecommute' title={t('employeeForm.formTitle1')} />,
    telecommute: <StepTelecommute next='daysNumber' title={t('employeeForm.formTitle1')} />,
    daysNumber: (
      <StepWeeklyTravels next='numberWeeklyTravels' title={t('employeeForm.formTitle1')} />
    ),
    numberWeeklyTravels: <StepDailyTrips next='totalKm' title={t('employeeForm.formTitle1')} />,
    totalKm: <StepTotalKm next='transport' title={t('employeeForm.formTitle1')} />,
    transport: <StepTransport title={t('employeeForm.formTitle1')} />,
    type: <StepType title={t('employeeForm.formTitle1')} />,
    carpooling: <StepCarpooling next='summary' title={t('employeeForm.formTitle1')} />,
    summary: <StepSummary />
  };

  // IN LABORE
  const stepsInLabore = {
    startDate: <StepStartDate next='daysNumber' title={t('employeeForm.formTitle1')} />,
    daysNumber: (
      <StepWeeklyTravelsInLabore next='numberWeeklyTravels' title={t('employeeForm.formTitle1')} />
    ),
    numberWeeklyTravels: (
      <StepDailyTripsInLabore next='totalKm' title={t('employeeForm.formTitle1')} />
    ),
    totalKm: <StepTotalKmToInLabore next='transport' title={t('employeeForm.formTitle1')} />,
    transport: <StepTransport title={t('employeeForm.formTitle1')} />,
    type: <StepType title={t('employeeForm.formTitle1')} />,
    carpooling: <StepCarpooling next='summary' title={t('employeeForm.formTitle1')} />,
    summary: <StepSummary />
  };

  // IN ITINERE QR
  const stepsInItinereQR = {
    name: <StepName next='startDate' title={t('employeeForm.formTitle1')} />,
    startDate: <StepStartDate next='telecommute' title={t('employeeForm.formTitle1')} />,
    telecommute: <StepTelecommute next='daysNumber' title={t('employeeForm.formTitle1')} />,
    daysNumber: (
      <StepWeeklyTravels next='numberWeeklyTravels' title={t('employeeForm.formTitle1')} />
    ),
    numberWeeklyTravels: <StepDailyTrips next='totalKm' title={t('employeeForm.formTitle1')} />,
    totalKm: <StepTotalKm next='transport' title={t('employeeForm.formTitle1')} />,
    transport: <StepTransport title={t('employeeForm.formTitle1')} />,
    type: <StepType title={t('employeeForm.formTitle1')} />,
    carpooling: <StepCarpooling next='summary' title={t('employeeForm.formTitle1')} />,
    summary: <StepSummary />
  };

  // IN ITINERE PEPE JEANS
  const stepsInItinerePepeJeans = {
    // startDate: (
    //   <StepStartDatePepeJeans next='totalKm' title={t('employeeForm.formTitle1')} />
    // ),
    // totalKm: <StepTotalKm next='transport' title={t('employeeForm.formTitle1')} />,
    transport: <StepTransport title={t('employeeForm.formTitle1')} />,
    type: <StepType title={t('employeeForm.formTitle1')} />,
    carpooling: <StepCarpooling next='summary' title={t('employeeForm.formTitle1')} />,
    summary: <StepSummaryPepeJeans next='thanks' />,
    thanks: <StepThanksPepeJeans />
  };
  // PEPE JEANS

  // IN ITINERE QR PEPE JEANS
  const stepsInItinereQRPepeJeans = {
    name: <StepName next='startDate' title={t('employeeForm.formTitle1')} />,
    // startDate: (
    //   <StepStartDatePepeJeans next='totalKm' title={t('employeeForm.formTitle1')} />
    // ),
    // totalKm: <StepTotalKm next='transport' title={t('employeeForm.formTitle1')} />,
    transport: <StepTransport title={t('employeeForm.formTitle1')} />,
    type: <StepType title={t('employeeForm.formTitle1')} />,
    carpooling: <StepCarpooling next='summary' title={t('employeeForm.formTitle1')} />,
    summary: <StepSummaryPepeJeans next='thanks' />,
    thanks: <StepThanksPepeJeans />
  };
  // PEPE JEANS

  const getSteps = ({ type, isQrStatus, isPepeJeans }: Props) => {
    if (isPepeJeans) {
      // PEPE JEANS
      if (type === CommutingType.IN_ITINERE && isQrStatus) return stepsInItinereQRPepeJeans;
      if (type === CommutingType.IN_ITINERE && !isQrStatus) return stepsInItinerePepeJeans;
      // PEPE JEANS
    }
    if (type === CommutingType.IN_ITINERE && isQrStatus) return stepsInItinereQR;
    if (type === CommutingType.IN_ITINERE && !isQrStatus) return stepsInItinere;
    if (type === CommutingType.IN_LABORE) return stepsInLabore;

    return {};
  };

  const steps: { [key: string]: ReactNode } = useMemo(
    () => getSteps({ type, isQrStatus, isPepeJeans }),
    [type, isQrStatus]
  );

  return {
    steps
  };
};

export default useSteps;
