import { useQuery } from '@tanstack/react-query';
import { getMITECOVersions } from '../../services/api/orgEf';

export const useMITECOVersions = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['mitecoVersions'],
    queryFn: getMITECOVersions
  });

  return { data: data ?? [], isFetching };
};
