import { Status } from '../utilsEnums/status';

export const HEAVY_FILE_STATUS = {
  IN_QUEUE: Status.IN_QUEUE,
  IN_PROGRESS: Status.IN_PROGRESS,
  ERROR: Status.ERROR,
  FILE_ERROR: Status.FILE_ERROR,
  SUCCESS: Status.SUCCESS,
  DELETING: Status.DELETING,
  DELETED: Status.DELETED,
  INTERNAL_ERROR: Status.INTERNAL_ERROR
} as const;

export type HeavyFilesStatus = (typeof HEAVY_FILE_STATUS)[keyof typeof HEAVY_FILE_STATUS];
