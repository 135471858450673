import React from 'react';

type Props = {
  id: string;
  name: string;
  type: string;
  removeElement: (id: string) => void;
};
const ElementAdded = ({ id, name, type, removeElement }: Props) => {
  const handleRemove = () => {
    removeElement(id);
  };

  const renderIcon = () => {
    if (type === 'facilities') {
      return '/images/icons/buildingGray.svg';
    }
    if (type === 'vehicles') {
      return '/images/icons/carGray.svg';
    }
    return '';
  };
  return (
    <div className='element-added'>
      <span className='subtitle3-font image-text-wrapper'>
        <img src={renderIcon()} alt={name} />
        {name}
      </span>
      <img
        className='delete-icon'
        src='/images/icons/close.svg'
        alt='delete employee'
        onClick={handleRemove}
      />
    </div>
  );
};

export default ElementAdded;
