import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateOrganizationInvoicingData } from '../../actions/auth';
import { UserContext } from '../../context/userContext';
import EditInvoicingData from '../invoicing/editInvoicingData/EditInvoicingData';
import LoaderCard from '../ui/loaders/loaderCard/LoaderCard';
import Modal from '../ui/modal/Modal';
import InvoicingData from './InvoicingData';
import OrganizationData from './OrganizationData';

import UserData from './UserData';
import './styles.scss';
import { EmissionFactor } from './emissionFactor/EmissionFactor';
import { Assumptions } from './assumptions/Assumptions';
import { Invoicing } from './invoicing/Invoicing';
import { AccountLinked } from './accountLinked/AccountLiked';
import { useOrgParent } from './hooks/useOrgParent';
import { LinkAccount } from './linkAccount/LinkAccount';
import { LinkPending } from './linkPending/LinkPending';
import useSelectedOrganization from '../../customHooks/useSelectedOrganization';

function Profile() {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const [showEditInvoicingData, setShowEditInvoicingData] = useState(false);
  const selectedOrganization = useSelectedOrganization();
  const { data: holding, isLoading } = useOrgParent();

  if (!user) {
    return null;
  }

  const handleShowEditInvoicingData = () => {
    setShowEditInvoicingData(true);
  };

  const handleHideEditInvoicingData = () => {
    setShowEditInvoicingData(false);
  };

  const editInvoicingData = (invoicingData: InvoicingDataType) => {
    handleHideEditInvoicingData();
    if (user?.selectedOrganization) {
      dispatch(updateOrganizationInvoicingData(user?.selectedOrganization, invoicingData));
    }
  };

  return (
    <section className='profile'>
      <div className='profile__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('profile.title')}</h3>
        <h4 className='subtitle2-font on-light-text-color'>{t('profile.subtitle')}</h4>
      </div>
      {isLoading ? (
        <LoaderCard />
      ) : (
        <div className='profile__body'>
          <UserData user={user} />
          {selectedOrganization && <OrganizationData organization={selectedOrganization} />}
          {selectedOrganization ? (
            <InvoicingData
              showEditInvoicingDataModal={handleShowEditInvoicingData}
              role={selectedOrganization?.role || ''}
              organization={selectedOrganization}
            />
          ) : null}
          <div className='navigation-wrapper'>
            <EmissionFactor />
            <Assumptions />
            <Invoicing />
            {holding?.status === 'accepted' && <AccountLinked holding={holding} />}
            {holding?.status !== 'accepted' && <LinkAccount />}
            {holding?.status === 'pending' && <LinkPending holding={holding} />}
          </div>
        </div>
      )}

      <Modal
        show={showEditInvoicingData}
        onClose={handleHideEditInvoicingData}
        width='428px'
        maxWidth='428px'>
        {selectedOrganization ? (
          <EditInvoicingData
            editInvoicingData={editInvoicingData}
            organization={selectedOrganization}
            buttonText={t('profile.save')}
          />
        ) : null}
      </Modal>
    </section>
  );
}

export default Profile;
