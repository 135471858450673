import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  handleConfirm: () => Promise<void>;
  handleClose: () => void;
  nif: string;
  error: ErrorType | null;
};

const Confirmation = ({ handleConfirm, handleClose, nif, error }: Props) => {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);

  const handleConfirmClick = async () => {
    setLoadingButton(true);
    await handleConfirm();
    setLoadingButton(false);
  };

  return (
    <>
      <FormHeader
        title={t('datadisConnection.confirmation')}
        description={t('datadisConnection.confirmationDescription', { nif })}
      />
      <FormButtonSection>
        <Button
          lookAndFeel={'secondary'}
          text={t('datadisConnection.goBack')}
          onClick={handleClose}
        />
        <Button
          lookAndFeel={'primary'}
          text={t('datadisConnection.accept')}
          onClick={handleConfirmClick}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <span className='error-text error-font error-text-color'>{error.description}</span>}
    </>
  );
};

export default Confirmation;
