import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const FormElement = ({ ...props }: Props) => {
  return (
    <div className='form-line' {...props}>
      {props.children}
    </div>
  );
};

export default FormElement;
