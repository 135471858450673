import { EsgImpactsBackend, EsgImpactsFrontend } from '../types/entities/esgMetrics';

export const adaptEsgImpactsBackToFront = (data: EsgImpactsBackend[]): EsgImpactsFrontend[] => {
  const parsedData = data.map((value) => ({
    entity: value.company_name ? value.company_name : value.facility_name,
    facilityName: value.facility_name,
    intensityUnit: value.intenisty_unit,
    unit: value.unit,
    orgId: value.organization_id,
    valuePreviousYear: value.values[0].value ? value.values[0].value : 0,
    valueCurrentYear: value.values[1].value ? value.values[1].value : 0,
    errorMessage: value.error_message,
    hasChilds: value.has_childs
  }));

  return parsedData;
};
