import { useTranslation } from 'react-i18next';
import './styles.scss';
function Closing() {
  const { t } = useTranslation();
  return (
    <div className='closing-page'>
      <div className='title'>
        <img src='/images/report/logo.svg' alt='logo' />
        <div className='divider-line' />
        <h1 className='on-dark-text-color name-icon-big-font'>{t('report.closingTitle')}</h1>
        <h1 className='on-dark-text-color percentage-big-font'>{t('report.closingTitle2')}</h1>
      </div>
    </div>
  );
}

export default Closing;
