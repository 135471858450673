import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountries } from '../../../../../../constants/phonePrefixes';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { IPurchaseSpendBasedFormFront } from '../../../../../../types/purchases';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import { convertDateToString } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElement from '../../../../../ui/formComponents/formElement/FormElement';
import SlideToggle from '../../../../../ui/formComponents/slideToggle/SlideToggle';
import FormCalendar from '../../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import InputNumber from '../../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../../ui/formComponents2/select/Select';
import useUnits from './useUnits';

type Props = {
  handleSubmit: (purchase: IPurchaseSpendBasedFormFront) => Promise<void>;
  formDataFilled?: IPurchaseSpendBasedFormFront;
  error?: ErrorType;
  isEditing?: boolean;
};

function FormSpendBased({ handleSubmit, formDataFilled, error, isEditing }: Props) {
  const { t: translate, i18n } = useTranslation('translation', { keyPrefix: 'purchases' });

  const t = (i18nKey: string) => translate(`inputs.${i18nKey}`);

  const foundOrganization = useSelectedOrganization();

  const emptyFormData = {
    isRecycled: false,
    price: '',
    businessName: '',
    supplierCountry: { id: '', name: '' },
    currency: { id: '', name: '' },
    country: { id: '', name: '' },
    purchaseDate: '',
    description: '',
    co2e: 0,
    errors: []
  };

  const [formData, setFormData] = useState<IPurchaseSpendBasedFormFront>(
    formDataFilled || emptyFormData
  );

  const units = useUnits();

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const handleErrors = () => {
    const optionalFields: string[] = ['sector', 'isRecycled', 'frequency', 'coe2', 'product'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors.length !== 0 ? newErrors : false;
  };

  const handleCLickSubmit = async () => {
    if (handleErrors() || loadingButton) return;
    setLoadingButton(true);
    await handleSubmit(formData);
    setLoadingButton(false);
  };

  const handleUpdateCountry = (value: SelectOptionFormat) => {
    const handlers = [onChangeValue('supplierCountry'), onChangeValue('country')];

    handlers.forEach((handler) => handler(value));
  };

  return (
    <>
      <div className='grid-cols-2 gap-x-2 gap-y-8'>
        <FormElement className='col-span-2'>
          <FormText
            label={t('productName')}
            icon={'/images/icons/shoppingBagGradient.svg'}
            placeholder={t('placeholders.productName')}
            value={formData.description}
            onChange={onChangeValue('description')}
            error={formData.errors.find((elem) => elem.error === 'description')}
          />
          {!isEditing && (
            <SlideToggle
              label={t('recycled')}
              checked={formData.isRecycled}
              setChecked={onChangeValue('isRecycled')}
              style={{ marginTop: '0.5em' }}
            />
          )}
        </FormElement>

        <FormText
          label={t('businessName')}
          icon={'/images/icons/briefcase.svg'}
          placeholder={t('placeholders.businessName')}
          value={formData.businessName}
          onChange={onChangeValue('businessName')}
          className='col-span-1 md-col-span-2'
          error={formData.errors.find((elem) => elem.error === 'businessName')}
          disabled={isEditing}
        />
        <FormSelect
          icon={'/images/icons/pin.svg'}
          placeholder={t('placeholders.supplierCountry')}
          label={t('supplierCountry')}
          value={formData.supplierCountry}
          onChange={handleUpdateCountry}
          error={formData.errors.find((elem) => elem.error === 'supplierCountry')}
          options={getCountries(
            i18n.resolvedLanguage,
            foundOrganization ? foundOrganization.country : 'ES'
          )}
          className='col-span-1 md-col-span-2'
          disabled={isEditing}
        />

        <FormCalendar
          label={t('purchaseDate')}
          handleChangeDate={onChangeValue('purchaseDate')}
          error={formData.errors.find(
            (elem) => elem.error === 'date' || elem.error === 'purchaseDate'
          )}
          dateValue={formData.purchaseDate}
          maxDate={convertDateToString(new Date())}
          minDate={'01/01/2010'}
          handleDateError={handleDateError('purchaseDate')}
          className='col-span-1 md-col-span-2'
        />

        <InputWrapper
          label={t('price')}
          icon='/images/icons/plus.svg'
          error={formData.errors.find(
            (elem) => elem.error === 'price' || elem.error === 'currency'
          )}
          className='col-span-1 md-col-span-2'>
          <InputNumber
            value={formData.price}
            onChange={onChangeValue('price')}
            placeholder={t('placeholders.price')}
          />
          <Select
            value={formData.currency}
            onChange={onChangeValue('currency')}
            options={units.filter((elem) => elem.name === '€')}
            placeholder={t('placeholders.currency')}
          />
        </InputWrapper>
      </div>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={translate('save')}
          onClick={handleCLickSubmit}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
}

export default FormSpendBased;
