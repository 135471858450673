import { useContext, useState } from 'react';
import { Status } from '../../../types/utilsEnums/status';
import Button from '../../ui/button/Button';
import { StatusTag } from '../../ui/statusTag';
import { ProfileCard } from '../profileCard/ProfileCard';
import Modal from '../../ui/modal/Modal';
import { EmissionFactorModal } from './EmissionFactorModal';
import { useTranslation } from 'react-i18next';
import { useMITECOOrgEf } from '../../../customHooks/api/useMITECOOrgEf';
import { useMITECOVersions } from '../../../customHooks/api/useMITECOVersions';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { UserContext } from '../../../context/userContext';

export const EmissionFactor = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const { data: efVersion, isFetching } = useMITECOOrgEf();
  const { data: MITECOVersions } = useMITECOVersions();
  const user = useContext(UserContext);
  const flags = useFeatureFlags();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    if (!flags?.changeEfFromProfile) return;
    setIsOpen(true);
  };

  return (
    <>
      <Modal.WithPortal show={isOpen} onClose={handleClose}>
        <EmissionFactorModal closeModal={handleClose} />
      </Modal.WithPortal>

      <ProfileCard.Layout>
        <ProfileCard.Content>
          <ProfileCard.Title>{t('profile.emissionFactors.title')}</ProfileCard.Title>
          <div className='flex gap-x-4'>
            <div className='border-1 border-solid border-gray-2-light rounded-4 h-7 flex items-center bg-neutral-gray-warm-80 flex-grow'>
              <span className='border-0 border-r-1 border-solid border-gray-2-light font-body-b2-sb m-0 p-2'>
                {t('profile.emissionFactors.currentVersion')}
              </span>
              <span className='font-body-b2-r p-2'>
                {MITECOVersions.find((version) => version.id === efVersion?.version)?.name ??
                  efVersion?.version}
              </span>
            </div>

            {user?.email.endsWith('@dcycle.io') && (
              <Button
                lookAndFeel='secondary'
                size='small'
                text={t('profile.emissionFactors.changeVersion')}
                style={{ width: 'fit-content' }}
                onClick={handleOpen}
                disabled={!flags?.changeEfFromProfile}
              />
            )}
          </div>

          {efVersion?.latest && (
            <StatusTag
              status={Status.SUCCESS}
              text={t('profile.emissionFactors.usingLatestVersion')}
            />
          )}

          {!isFetching && !efVersion?.latest && (
            <StatusTag
              status={Status.PENDING}
              text={t('profile.emissionFactors.notUsingLatestVersion')}
              icon='information'
            />
          )}
        </ProfileCard.Content>
      </ProfileCard.Layout>
    </>
  );
};
