import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { getMcfValues } from '../../../../../services/api/treatment';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import SlideToggle from '../../../../ui/formComponents/slideToggle/SlideToggle';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { TreatmentPlantForm } from '../EditCreateFacility';

type Props = {
  bodyForm: TreatmentPlantForm;
  setFormData: React.Dispatch<any>;
  mode: 'create' | 'edit';
};

const WasteWaterTreatmentForm = ({ bodyForm, setFormData, mode }: Props) => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const user = useContext(UserContext);
  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [lineOptions, setLineOptions] = useState<SelectOptionFormat[]>([]);

  useEffect(() => {
    const getLineOptions = async () => {
      if (!user?.selectedOrganization) return;
      const response = await getMcfValues({ organization_id: user?.selectedOrganization });
      if (response?.status >= 400) return;
      setLineOptions(
        response.map((value: McfValue) => ({
          id: value.id,
          name: t(`mcfValues.${value.mcf_code}`)
        }))
      );
    };

    getLineOptions();
  }, []);

  return (
    <FormWrapper>
      <FormSelect
        icon={'/images/icons/wave.svg'}
        placeholder={t('facilities.writeFacilityWaterLine')}
        label={t('facilities.waterLine')}
        value={bodyForm.waterLine}
        onChange={onChangeValue('waterLine')}
        options={lineOptions}
        error={bodyForm?.errors?.find((elem) => elem.error === 'waterLine')}
        sort={false}
        disabled={mode !== 'create'}
      />
      <FormSelect
        icon={'/images/icons/wave.svg'}
        placeholder={t('facilities.writeFacilitySludgeLine')}
        label={t('facilities.sludgeLine')}
        value={bodyForm.sludgeLine}
        onChange={onChangeValue('sludgeLine')}
        options={lineOptions}
        error={bodyForm?.errors?.find((elem) => elem.error === 'sludgeLine')}
        sort={false}
        disabled={mode !== 'create'}
      />
      {flags?.methaneBurn && (
        <div className='toggle-element'>
          <SlideToggle
            label={t('facilities.methaneBurn')}
            checked={bodyForm.methaneBurn}
            setChecked={mode === 'create' ? onChangeValue('methaneBurn') : () => null}
          />
        </div>
      )}
    </FormWrapper>
  );
};

export default WasteWaterTreatmentForm;
