import Draggable from 'react-draggable';
import IconBackground from '../iconBackground/IconBackground';
import './styles.scss';
type Props = {
  title: string;
  description: string;
  image: string;
  onClick?: () => void;
};

//  defaultPosition={{ x: 20, y: 650 }}

const VideoTutorialPopup = ({ title, description, image, onClick }: Props) => {
  return (
    <Draggable bounds=''>
      <div className='video-tutorial-popup card'>
        <img src={image} alt='title' draggable={false} className='video-icon' />
        <div className='video-tutorial-popup__text'>
          <h4 className='headline4-font'>{title}</h4>
          <p className='subtitle3-font'>{description}</p>
        </div>
        <IconBackground
          icon='/images/icons/play.svg'
          alt={'play'}
          selected
          round
          onClick={onClick}
        />
      </div>
    </Draggable>
  );
};

export default VideoTutorialPopup;
