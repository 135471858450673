import { useTranslation } from 'react-i18next';
import './styles.scss';
import InputFileSingle from '../../../../../ui/formComponents/inputFile/InputFileSingle';

type Props = {
  file: File | null;
  handleUploadFile: (fileUploaded: File) => void;
  error?: ErrorType;
  template?: any;
};

const UploadCSV = ({ file, handleUploadFile, error, template }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='file-upload-wrapper'>
      <div className='download-csv-container'>
        {template && (
          <h3
            className='body1-bold-font highlight-text-color pointer'
            onClick={() => template('employees')}>
            {t('sendEmailForm.downloadTemplate')}
          </h3>
        )}

        <span className={template ? 'body1-bold-font template' : 'body1-bold-font'}>
          {t('sendEmailForm.addTitle')}
        </span>
      </div>
      <InputFileSingle
        handleFile={handleUploadFile}
        buttonText={file ? '' : t('employees.uploadCsv')}
        fileName={file?.name || ''}
        labelText={file ? '' : t('employees.dragAndDropFile')}
        error={error}
        allowedTypes={['text/csv']}
      />
    </div>
  );
};

export default UploadCSV;
