import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('members.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('members.firstName'),
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: t('members.lastName'),
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: t('members.phoneNumber'),
      dataIndex: 'phone_number',
      key: 'phone_number'
    },
    {
      title: t('members.role'),
      dataIndex: 'role',
      key: 'role'
    },
    {
      title: t('members.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
};

export default useColumns;
