import { useTranslation } from 'react-i18next';

type Props = {
  darkStyle?: boolean;
};

const SocialLinks = ({ darkStyle = false }: Props) => {
  const { t } = useTranslation();
  return (
    <div className='report-header-social'>
      <a
        href='https://dcycle.io/blog'
        target='_blank'
        className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}
        rel='noreferrer'>
        {t('report.blog')}
      </a>
      <a
        href='https://dcycle.io/product'
        target='_blank'
        className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}
        rel='noreferrer'>
        {t('report.functionalities')}
      </a>
      <a
        href='https://dcycle.io/customer'
        target='_blank'
        className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}
        rel='noreferrer'>
        {t('report.news')}
      </a>
    </div>
  );
};

export default SocialLinks;
