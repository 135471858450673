import { useQuery } from '@tanstack/react-query';
import { getMethodologies } from '../services/getMethodologies';

export const useMethodologies = (acvId?: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['lca', 'impact_categories', acvId],
    queryFn: () => getMethodologies(acvId)
  });

  return { data, isLoading };
};
