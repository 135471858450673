import { convertDateBackToFront } from '../utils/convertDates';
export const adaptLogisticShipmentBackToFront = (
  backendData: ILogisticsShipmentBackend
): ILogisticsShipmentFrontend => {
  let movementId = backendData.unique_movement_id || '';
  if (backendData.movement_stretch) {
    movementId += '-' + backendData.movement_stretch;
  }
  if (backendData.movement_stage) {
    movementId += '-' + backendData.movement_stage;
  }
  return {
    id: movementId,
    uniqueMovementId: movementId,
    loadTonne: backendData.load_tonne ?? undefined,
    vehicleLicensePlate: backendData.vehicle_license_plate,
    trailerLicensePlate: backendData.trailer_license_plate,
    tripDate: backendData?.trip_date ? convertDateBackToFront(backendData.trip_date) : '',
    tkm: backendData.tkm ?? undefined,
    origin: backendData.origin,
    destination: backendData.destination,
    cleaning: backendData.cleaning,
    subcontractor: backendData.subcontractor,
    distanceKm: backendData.distance_km ?? undefined,
    emissionIntensity: backendData.emission_intensity ?? undefined,
    kgCO2e: backendData.kgco2e ?? undefined,
    client: backendData.client,
    vehicleType: backendData.vehicle_type ?? undefined,
    status: backendData.status,
    errorMessages: backendData.error_messages,
    estimatedData: backendData.estimated_data
  };
};
