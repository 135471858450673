import debounce from 'lodash.debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { getCategories } from '../../../../../../services/api/categories';

import { useParams } from 'react-router-dom';
import {
  getOrganizationGoalSbti,
  getOrganizationModelingSbti,
  putReductionActionSbti,
  putReductionPercentageSbti
} from '../../../../../../services/api/sbtiModeling';
import { getTotalImpactsByCategoryYearlySbti } from '../../../../../../services/api/total_impacts';

type CategoryType = {
  id: string;
  principal_category: number;
  subcategory: string;
  framework: string;
  original_name: string;
};

const useGetData = () => {
  const [organizationGoal, setOrganizationGoal] = useState<IOrganizationGoalSbtiBack | null>(null);
  const [organizationModeling, setOrganizationModeling] = useState<IOrganizationModelingBack[]>([]);
  const [totalImpactByCategoryYearly, setTotalImpactByCategoryYearly] = useState<
    TotalImpactYearly[]
  >([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);

  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);

  const getOrganizationGoalData = async () => {
    if (!id) return;
    const response = await getOrganizationGoalSbti({ id });
    if (response?.response?.status > 400) return;

    // if (response.length === 0) response = null;
    // else response = response[0];

    setOrganizationGoal(response);
    return response;
  };

  const getOrganizationModelingData = async () => {
    if (!id) return;
    const response = await getOrganizationModelingSbti({ id });
    if (response?.response?.status > 400) return;
    if (!Array.isArray(response)) return;
    setOrganizationModeling(
      response.map((element: IOrganizationModelingBack) => {
        if (element.category_name !== 'employees_in_itinere') return element;
        return {
          ...element,
          category_name: 'employees'
        };
      })
    );
    return response;
  };

  const cleanCategory = (totalImpacts: TotalImpactYearly[]) => {
    return totalImpacts.map((totalImpact) => {
      if (totalImpact.category.includes('employees')) totalImpact.category = 'employees';
      return totalImpact;
    });
  };

  const getTotalImpactsByCategoryYearlyData = async () => {
    if (!id) return;
    const response = await getTotalImpactsByCategoryYearlySbti({ id });
    if (response?.response?.status > 400) return;
    const cleanResponse = cleanCategory(response);
    setTotalImpactByCategoryYearly(cleanResponse);
    return response;
  };

  const getCategoriesList = async () => {
    const response = await getCategories();
    if (response >= 400) return;
    setCategories(response);
  };

  const handleRequests = async () => {
    if (loading) return;
    setLoading(true);
    await Promise.all([
      getOrganizationGoalData(),
      getOrganizationModelingData(),
      getCategoriesList(),
      getTotalImpactsByCategoryYearlyData()
    ]);
    setLoading(false);
  };

  useEffect(() => {
    handleRequests();
  }, []);

  const putReductionPercentageDebounced = useMemo(
    () =>
      debounce(async (category: string, value: string) => {
        if (category === 'employees') category = 'employees_in_itinere';
        const categoryFound = categories.find(
          (categoryElement) => categoryElement.subcategory === category
        );
        if (!categoryFound || !id) return;
        await putReductionPercentageSbti({ id, category: categoryFound.id, value: value || '0' });
      }, 500),
    [categories]
  );

  const handleOnChangeReductionPercentage = (category: string) => (value: string) => {
    setOrganizationModeling((prev) => {
      const modelingIndex = prev.findIndex((value) => value.category_name === category);

      putReductionPercentageDebounced(category, value);

      if (modelingIndex < 0)
        return prev.concat({
          reduction_percentage: value,
          reduction_description: '',
          id_framework_categories: '',
          category_name: category
        });

      const modeling = prev[modelingIndex];

      modeling.reduction_percentage = value;

      return prev.map((organizationModeling) =>
        organizationModeling.category_name === category ? modeling : organizationModeling
      );
    });
  };

  const putReductionActionDebounced = useMemo(
    () =>
      debounce(async (category: string, value: string) => {
        if (category === 'employees') category = 'employees_in_itinere';
        const categoryFound = categories.find(
          (categoryElement) => categoryElement.subcategory === category
        );
        if (!categoryFound || !id) return;
        await putReductionActionSbti({ id, category: categoryFound.id, value });
      }, 500),
    [categories]
  );

  const handleOnChangeReductionAction =
    (category: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setOrganizationModeling((prev) => {
        const modelingIndex = prev.findIndex((value) => value.category_name === category);

        putReductionActionDebounced(category, e.target.value);

        if (modelingIndex < 0)
          return prev.concat({
            reduction_percentage: '',
            reduction_description: e.target.value,
            id_framework_categories: '',
            category_name: category
          });
        const modeling = prev[modelingIndex];

        modeling.reduction_description = e.target.value;

        return prev.map((organizationModeling) =>
          organizationModeling.category_name === category ? modeling : organizationModeling
        );
      });
    };

  return {
    organizationGoal,
    organizationModeling,
    totalImpactByCategoryYearly,
    loading,
    handleOnChangeReductionPercentage,
    handleOnChangeReductionAction
  };
};

export default useGetData;
