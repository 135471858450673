import { useContext, useRef } from 'react';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';

import Button from '../../../ui/button/Button';
import NotificationBubble from '../../../ui/notificationBubbleWrapper/NotificationBubbleWrapper';

import './styles.scss';
import Icon from '../../../ui/icon/Icon';
import { useNavigate } from 'react-router-dom';
const TasksButton = () => {
  const user = useContext(UserContext);

  const navigate = useNavigate();
  const taskRef = useRef<HTMLDivElement>(null);

  const handleOnClickTasksModal = async () => {
    navigate(ROUTES.TODOS);
  };

  const pendingTasksTotal = user?.tasks ? user?.tasks.total - user?.tasks.total_completed : 0;
  return (
    <div className='header-tasks'>
      <NotificationBubble total={pendingTasksTotal}>
        <div id='task_icon' className='tasks-icon' ref={taskRef}>
          <Button
            lookAndFeel={'secondary'}
            onClick={handleOnClickTasksModal}
            size='small'
            id='tasks'
            iconNode={<Icon icon='tasks_2' color='gray-dark' />}
          />
        </div>
      </NotificationBubble>
    </div>
  );
};

export default TasksButton;
