import ButtonArrow, { ButtonArrowType } from '../../buttonArrow/ButtonArrow';
import './styles.scss';

type Props = {
  handleNext?: (value: any) => void;
  handleBack?: (value: any) => void;
};

const SwitchStep = ({ handleNext, handleBack }: Props) => {
  return (
    <div className='switch-step'>
      <ButtonArrow
        type={ButtonArrowType.UP}
        onClick={handleBack}
        style={{ visibility: handleBack ? 'visible' : 'hidden' }}
      />
      <ButtonArrow
        type={ButtonArrowType.DOWN}
        onClick={handleNext}
        style={{ visibility: handleNext ? 'visible' : 'hidden' }}
      />
    </div>
  );
};

export default SwitchStep;
