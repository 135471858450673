import { useTranslation } from 'react-i18next';

const useScopeOptions = () => {
  const { t } = useTranslation();
  const scopeOptions = [
    {
      id: '1',
      name: t('createIntensityMetricGroup.1')
    },
    {
      id: '2',
      name: t('createIntensityMetricGroup.2')
    },
    {
      id: '3',
      name: t('createIntensityMetricGroup.3')
    },
    {
      id: '1,2',
      name: t('createIntensityMetricGroup.1,2')
    },
    {
      id: '2,3',
      name: t('createIntensityMetricGroup.2,3')
    },
    {
      id: '1,2,3',
      name: t('createIntensityMetricGroup.1,2,3')
    }
  ];
  return scopeOptions;
};

export default useScopeOptions;
