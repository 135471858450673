import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { getShipmentsByOrgIdStatus } from '../../../../services/api/transportRoutes';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import InputText from '../../../ui/formComponents/inputText/InputText';
import Select from '../../../ui/formComponents/select/Select';

const commonInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big' | undefined
};
const GetShipmentsError = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    organizationId: '',
    transportDirection: {
      id: 'downstream',
      name: t('shipmentsUpload.downstream')
    },
    errors: [] as ErrorType[]
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    try {
      const errors = checkFormErrors(formData, formData.errors, []);
      if (errors.length > 0) {
        setFormData({
          ...formData,
          errors
        });
        return;
      }
      setLoadingButton(true);
      const data = await getShipmentsByOrgIdStatus(
        formData.organizationId,
        formData.transportDirection.id
      );
      window.open(data.file_url, '_blank');
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.error(error);
    }
  };
  return (
    <div className='get-shipments-error'>
      <FormHeader title={t('shipmentsUpload.getShipmentsWithErrors')} />
      <FormWrapper>
        <InputText
          {...commonInputProps}
          icon={'/images/icons/organization.svg'}
          placeholder={t('shipmentsUpload.organizationId')}
          label={t('shipmentsUpload.organizationId')}
          value={formData.organizationId}
          onChangeValue={onChangeValue('organizationId')}
          error={formData.errors.find((error) => error.error === 'organizationId')}
        />
        <Select
          placeholder={''}
          icon={'/images/icons/cardBox.svg'}
          label={t('shipmentsUpload.transportDirection')}
          options={[
            {
              id: 'upstream',
              name: t('shipmentsUpload.upstream')
            },
            {
              id: 'downstream',
              name: t('shipmentsUpload.downstream')
            }
          ]}
          value={formData.transportDirection}
          onChangeValue={onChangeValue('transportDirection')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('shipmentsUpload.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default GetShipmentsError;
