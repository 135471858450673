import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import { useModalContext } from '../ModalHandler';
import { CustomControl } from './CustomControl';

export const AddBlock = () => {
  const { setModalData } = useModalContext();

  const { t } = useLCATranslation();

  const onClick = () => {
    setModalData({ type: 'create_node' });
  };

  return (
    <TooltipWrapper text={t('lcaScenarios.addBlockTooltip')} position='top'>
      <CustomControl
        onClick={onClick}
        icon='add'
        color='gray-dark'
        label={t('lcaScenarios.addBlock')}
      />
    </TooltipWrapper>
  );
};
