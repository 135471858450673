import { ReactNode } from 'react';

import './styles.scss';

type Props = {
  text: ReactNode;
  onChange: (value: boolean) => void;
  selected: boolean;
  error?: ErrorType;
  type?: 'circle' | 'square';
  color?: 'gradient' | 'gray';
  disabled?: boolean;
};

const Checkbox = ({
  text,
  onChange,
  selected,
  error,
  type = 'circle',
  color = 'gradient',
  disabled = false
}: Props) => {
  const handleCheckboxClick = () => {
    if (disabled) return;
    onChange(!selected);
  };

  let style = type === 'circle' ? 'checkbox-outer-circle' : 'checkbox-outer-square';
  let tick = '/images/icons/tick.svg';

  if (color === 'gray' || (disabled && !selected)) {
    style = style.concat(' ', 'gray');
    tick = '/images/icons/tickGray.svg';
  }

  let textColor = 'on-light-text-color';
  if (error) {
    textColor = 'error-text-color';
  }

  if (disabled && !selected) {
    textColor = 'disabled-text-color';
  }

  return (
    <>
      <div className='modern-checkbox-container'>
        <div className={`${style} ${!selected && 'unselected'}`} onClick={handleCheckboxClick}>
          {selected && (
            <div className='checkbox-icon-wrapper'>
              <img src={tick} alt='tick-selected' />
            </div>
          )}
        </div>
        <div className={`helper-text font-12 ${textColor}`}>{text}</div>
      </div>
    </>
  );
};

export default Checkbox;
