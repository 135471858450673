import { gray_color2 } from '../styles/colors';
import randomNumber from '../utils/randomNumber';
export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function createRandomDataChart() {
  const data = [
    {
      label: 'scope1',
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 0,
      stepped: false,
      fill: true,
      tension: 0.001,
      borderColor: gray_color2,
      backgroundColor: 'rgba(234, 234, 234, 0.4)',
      data: []
    },
    {
      label: 'scope2',
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 0,
      stepped: false,
      fill: true,
      tension: 0.001,
      borderColor: gray_color2,
      backgroundColor: 'rgba(234, 234, 234, 0.3)',
      data: []
    },
    {
      label: 'scope3',
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 0,
      stepped: false,
      fill: true,
      tension: 0.001,
      borderColor: gray_color2,
      backgroundColor: 'rgba(234, 234, 234, 0.2)',
      data: []
    }
  ];

  return data.map((scope) => {
    let i = 0;
    let end = 12;
    const newData = [...scope.data];
    const startDate = new Date(`${new Date().getUTCFullYear()}-01-01`);
    while (i < end) {
      newData.push({
        x: new Date(startDate.setMonth(startDate.getMonth() + i)),
        y: getRandomInt(100)
      });
      i += 1;
    }
    scope.data = newData;
    return scope;
  });
}

export function createRandomDataBarChart(locale) {
  let data = [];
  const xAxis = [
    { label: locale === 'es' ? 'Combustion' : 'Combustion', color: gray_color2 },
    { label: locale === 'es' ? 'Recargas' : 'Recharges', color: gray_color2 },
    { label: locale === 'es' ? 'Luz' : 'Electricity', color: gray_color2 },
    { label: locale === 'es' ? 'Agua' : 'Water', color: gray_color2 },
    { label: locale === 'es' ? 'Vehículos' : 'Vehicles', color: gray_color2 },
    { label: locale === 'es' ? 'Empleados' : 'Employees', color: gray_color2 },
    { label: locale === 'es' ? 'Viajes' : 'Travels', color: gray_color2 },
    { label: locale === 'es' ? 'M. entregada' : 'Shipments', color: gray_color2 },
    { label: locale === 'es' ? 'M. recibida' : 'Deliveries', color: gray_color2 },
    { label: locale === 'es' ? 'Compras' : 'Purchases', color: gray_color2 }
    // { label: 'Viajes', color: gray_color2 },
    // { label: 'Compras', color: gray_color2 },
    // { label: 'Residuos', color: gray_color2 }
  ];

  data = xAxis.map((x) => {
    return {
      label: x.label,
      value: getRandomInt(100),
      color: x.color
    };
  });

  return [data];
}

export const createRandomDataModeling = () => {
  const labels = [
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030'
  ];
  const disabled = labels.map((element) => randomNumber(25, 50)) || [];
  const line1 = labels.map((element) => randomNumber(50, 55)) || [];

  return { labels, datasets: { disabled, line1 } };
};
