import React, { useLayoutEffect, useRef, useState } from 'react';
import useDivPosition from '../../../../../customHooks/useDivPosition';
import './styles.scss';

const EXTRA_SPACE = 20;

type Props = {
  wrapperRef: any;
  children?: React.ReactNode;
  avoidOffScreen?: boolean;
};
const DropdownCard = ({ wrapperRef, children, avoidOffScreen }: Props) => {
  const divPosition = useDivPosition({ wrapperRef });
  const [top, setTop] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (ref.current?.clientHeight) {
      setTop(divPosition.top - (ref.current?.clientHeight + EXTRA_SPACE));
    }
  }, [ref.current?.clientHeight, divPosition.top]);

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        top: (avoidOffScreen && top) || divPosition.top + divPosition.height - 3,
        left: divPosition.left - 1,
        width: divPosition.width + 2
      }}
      className={`${
        avoidOffScreen && 'avoid-off-screen'
      } select-options-wrapper position-select select-options--basic input-border-color`}>
      {children}
    </div>
  );
};

export default DropdownCard;
