import { CSSProperties, HTMLAttributes, ReactNode } from 'react';

type ICardProps = HTMLAttributes<HTMLDivElement>;

const Card = (props: ICardProps) => {
  const className = `${props.className} card card-horizontal`;
  return <div {...props} className={className} />;
};

const Header = ({
  children,
  title,
  style
}: {
  children?: ReactNode;
  title: ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <div className='card-horizontal__header' style={style}>
      <h4 className='headline4-font on-light-text-color' style={{ width: '100%' }}>
        {title}
      </h4>
      {children}
    </div>
  );
};

const Body = ({
  children,
  className,
  style
}: {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <div className={`${className} card-horizontal__body`} style={{ ...style }}>
      {children}
    </div>
  );
};

Card.Header = Header;
Card.Body = Body;

export default Card;
