import { LCABlock, LCABlockBack } from '../types/LCABlock';

export const adaptInputsBackToFront = (inputs: LCABlockBack['inputs']): LCABlock['inputs'] => {
  if (!inputs) {
    return [];
  }

  return inputs.map((input) => {
    return {
      id: input.id,
      type: input.entity_type
    };
  });
};

export const adaptOutputsBackToFront = (outputs: LCABlockBack['outputs']): LCABlock['outputs'] => {
  if (!outputs) {
    return [];
  }

  return outputs.map((output) => {
    return {
      id: output.id,
      name: output.name,
      type: output.entity_type
    };
  });
};

export const adaptLCABlockBackToFront = (block: LCABlockBack): LCABlock => {
  return {
    id: block.id,
    name: block.name,
    coordinate_x: block.coordinates_x,
    coordinate_y: block.coordinates_y,
    type: block.entity_type,
    inputs: adaptInputsBackToFront(block.inputs),
    outputs: adaptOutputsBackToFront(block.outputs)
  };
};
