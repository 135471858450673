import { gray_color } from '../../../styles/colors';
import './styles.scss';

type Bar = {
  value: number;
  color: string;
};
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  total: number;
  values: Bar[];
}
function HorizontalBar({ total, values, ...rest }: Props) {
  return (
    <div className='horizontal-bar-wrapper' {...rest}>
      {values.map((elem, index) =>
        elem.value > 0 ? (
          <div
            className='bar'
            key={index}
            style={{
              width: `calc(${(elem.value * 100) / total}% + 4px)`,
              background: elem.color || gray_color,
              zIndex: values.length - index
            }}></div>
        ) : (
          <></>
        )
      )}
    </div>
  );
}

export default HorizontalBar;
