import { ReactNode, useMemo } from 'react';

import styles from './styles.module.scss';
import uuid from 'uuid';

type Props = {
  text: ReactNode;
  onChange: (value: boolean) => void;
  selected: boolean;
  disabled?: boolean;
};

export const RadioButton: React.FC<Props> = ({ text, onChange, selected, disabled = false }) => {
  const id = useMemo(() => uuid.v4(), []);

  const handleClick = () => {
    if (disabled) return;
    onChange(true);
  };

  return (
    <label htmlFor={id} className={styles['label']} aria-disabled={disabled}>
      <input id={id} type='radio' onClick={handleClick} checked={selected} />
      <span>{text}</span>
    </label>
  );
};
