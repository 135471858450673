import { useTranslation } from 'react-i18next';

import { EmployeeReport } from '../../../../../types/entities/employee';
import CardHeader from '../../cardHeader/CardHeader';
import EmployeePieChartCard from './components/EmployeePieChartCard';

type Props = {
  employees: EmployeeReport[];
  employeesLength: number;
};

function EmployeesPieChart({ employees, employeesLength }: Props) {
  const { t } = useTranslation();

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <div className='flex justify-between'>
          <CardHeader
            title={t('report.employees')}
            description={
              employees
                ? t('report.employeesPieChartDescription').replace(
                    '{{employeesCompleted}}',
                    employees.length > 0 ? employees.length.toString() : '0'
                  )
                : ''
            }
          />
          <div className='scope-tag'>
            <div className='text-tag font-10 tag-bg-text-color tag-space-between'>{`${t(
              'measureMain.scope'
            )} 3`}</div>
          </div>
        </div>

        <div className='cards-wrapper'>
          <EmployeePieChartCard employees={employees} type='sustainable' />
          <EmployeePieChartCard employees={employees} type='polluting' />
        </div>
      </div>
    </div>
  );
}

export default EmployeesPieChart;
