import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUnits } from '../../../../../services/api/unit';
import { getOptionalQuestionsSocialAndGovernance } from '../../../../../services/api/socialData';
import { OptionalQuestion } from '../../../../../types/entities/socialData';

const useGetData = () => {
  const { t } = useTranslation();
  const [units, setUnits] = useState<SelectOptionFormat[]>([]);
  const [questions, setQuestions] = useState<OptionalQuestion[]>([]);

  useEffect(() => {
    const getUnitsSocialAndGovernance = async () => {
      const data = await getUnits();
      if (!data) return;
      setUnits(
        data
          .filter((unit: Unit) => unit.name === 'metric_tonne_(t)')
          .map((unit: Unit) => ({
            id: unit.id,
            name: t(`units.${unit.name}`)
          }))
      );
    };

    const getQuestions = async () => {
      const response = await getOptionalQuestionsSocialAndGovernance();
      if (response?.response?.status >= 400) return;
      setQuestions(
        response.map((question: OptionalQuestion) => ({
          id: question.id,
          name: question.pai_extra_question_code,
          type: question.value_type
        }))
      );
    };

    (async () => await Promise.all([getUnitsSocialAndGovernance(), getQuestions()]))();
  }, []);

  return { units, questions };
};

export default useGetData;
