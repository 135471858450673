import React, { useContext, useState } from 'react';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../ui/button/Button';
import { deletePreEvent } from '../../../../../services/api/events';
import { UserContext } from '../../../../../context/userContext';

type Props = {
  eventToDelete: string;
  type: string;
  removeEvent: (type: string) => void;
};
const DeleteEventData = ({ eventToDelete, type, removeEvent }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDelete = async () => {
    try {
      setLoadingButton(true);
      // Delete event data
      if (type === 'pre-event') {
        await deletePreEvent(eventToDelete, user?.selectedOrganization ?? '');
        // window.location.reload();
      } else {
        // await deletePostEvent(eventToDelete, user?.selectedOrganization ?? '');
      }

      setLoadingButton(false);
      removeEvent(type);
    } catch (err) {
      setLoadingButton(false);
    }
  };
  return (
    <div className='delete-event-data'>
      <FormHeader
        title={t('deleteEventData.title')}
        description={t('deleteEventData.description')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleDelete}
          text={t('deleteEventData.delete')}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteEventData;
