import { useEffect, useState } from 'react';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { getPurchasesSuppliers } from '../../../../../../services/api/purchases';

type Props = {
  product: string;
};

const useSuppliers = ({ product }: Props) => {
  const selectedOrganization = useSelectedOrganization();

  const [suppliers, setSuppliers] = useState<SelectOptionFormat[]>([]);

  const fetchSectors = async () => {
    if (!selectedOrganization || !product) return;
    const response = await getPurchasesSuppliers(selectedOrganization.id, product);
    if (response?.response?.status >= 400) return;
    setSuppliers(
      response.map((elem: string) => ({
        id: elem,
        name: elem
      }))
    );
  };

  useEffect(() => {
    fetchSectors();
  }, [product]);

  return suppliers;
};

export default useSuppliers;
