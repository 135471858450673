import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adaptBusinessTravelFrontToBack } from '../../../../../adapters/businessTravel';
import { UserContext } from '../../../../../context/userContext';
import useErrors from '../../../../../customHooks/useErrors';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import { postBusinessTravel } from '../../../../../services/api/businessTravels';

import {
  IFrontendFormBusinessTravel,
  IGetBackendBusinessTravel
} from '../../../../../types/entities/businessTravels';

import Button from '../../../../ui/button/Button';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import AddBusinessTravel from './AddBusinessTravel';
import UploadBusinessTravel from './UploadBusinessTravel';

type Props = {
  addBusinessTravel: (value: IGetBackendBusinessTravel) => void;
};
enum Modes {
  MANUALLY = 'manually',
  TEMPLATE = 'template'
}

function AddBusinessTravelWrapperBeforeNuvo({ addBusinessTravel }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const flags = useFeatureFlags();
  const ERRORS = useErrors();

  const [mode, setMode] = useState<Modes>(
    flags?.businessTravelUpload ? Modes.TEMPLATE : Modes.MANUALLY
  );

  const [error, setError] = useState<ErrorType>();

  const setModeManually = () => {
    setMode(Modes.MANUALLY);
  };

  const setModeTemplate = () => {
    if (mode === Modes.TEMPLATE) {
      // download template csv in public/files/businessTravel/business_travels_template_Dcycle.csv
      const url = '/files/businessTravel/business_travels_template_Dcycle.csv';
      window.open(url, '_blank');

      return;
    }
    setMode(Modes.TEMPLATE);
  };

  const post = async (formData: IFrontendFormBusinessTravel) => {
    if (!user?.selectedOrganization) return;

    const backendBusinessTravel = adaptBusinessTravelFrontToBack(formData);
    const response = await postBusinessTravel({
      value: backendBusinessTravel,
      organizationId: user.selectedOrganization
    });

    if (
      response?.response?.status === 422 &&
      response?.response?.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES'
    ) {
      setError(ERRORS.BUSINESS_TRAVEL.INVALID_TRAVEL_MODE_BETWEEN_PLACES);
      return;
    }

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    return response;
  };

  return (
    <div className='new-business-travel'>
      <FormHeader
        title={t('businessTravels.addNewBusinessTravel')}
        description={t('businessTravels.addNewBusinessTravelDescription')}
      />
      {flags?.businessTravelUpload && (
        <div className='buttons-mode'>
          <Button
            lookAndFeel={mode === Modes.TEMPLATE ? 'primary' : 'secondary'}
            onClick={setModeTemplate}
            text={
              mode === Modes.TEMPLATE
                ? t('businessTravels.downloadTemplate')
                : t('businessTravels.uploadAuto')
            }
            size='small'
          />
          <Button
            lookAndFeel={mode === Modes.MANUALLY ? 'primary' : 'secondary'}
            onClick={setModeManually}
            text={t('businessTravels.addManually')}
            size='small'
            style={{ width: '10%' }}
          />
        </div>
      )}
      {mode === Modes.MANUALLY ? (
        <AddBusinessTravel
          user={user}
          addBusinessTravel={addBusinessTravel}
          postBusinessTravel={post}
          error={error}
        />
      ) : null}
      {mode === Modes.TEMPLATE ? (
        <UploadBusinessTravel addBusinessTravel={addBusinessTravel} />
      ) : null}
    </div>
  );
}

export default AddBusinessTravelWrapperBeforeNuvo;
