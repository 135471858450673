import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import useErrors from '../../../../../customHooks/useErrors';
import { postDatadisContract } from '../../../../../services/api/datadis';
import {
  DatadisContract,
  DatadisContractListElementParsedType,
  FormDataDatadisContract
} from '../../../../../types/entities/datadisContract';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import Form from './form/Form';

type Props = {
  addContract: (newContract: DatadisContractListElementParsedType) => void;
  nif: string;
};

const AddContract = ({ addContract, nif }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const ERRORS = useErrors();

  const [error, setError] = useState<ErrorType | null>(null);

  const handleAdd = async (formData: FormDataDatadisContract, contract: DatadisContract) => {
    if (!user?.selectedOrganization) return;
    const response = await postDatadisContract(formData, contract, user.selectedOrganization);

    if (response?.response?.data === 'There is already an active supply contract for this CUPS') {
      setError(ERRORS.DATADIS.ACTIVE_CONTRACT);
      return;
    }

    if (
      response?.response?.data ===
      'There is already a supply contract within this period of start_date and end_date'
    ) {
      setError(ERRORS.DATADIS.SAME_PERIOD);
      return;
    }

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    if (response?.response?.status === 504) {
      setError(ERRORS.DATADIS.TIMEOUT);
      return;
    }

    addContract(response);
  };

  return (
    <div className='new-fund'>
      <FormHeader
        title={t('datadisContractAdd.title')}
        description={t('datadisContractAdd.subtitle')}
      />
      <Form handleSubmit={handleAdd} error={error} nif={nif} />
    </div>
  );
};

export default AddContract;
