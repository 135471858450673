import { AZKOYEN_OLD_IDS, LCA_DETAIL_OPTIONS } from '../constants';

export const getMode = (mode: string | null, id?: string): keyof typeof LCA_DETAIL_OPTIONS => {
  if (mode && mode in LCA_DETAIL_OPTIONS) {
    return mode as keyof typeof LCA_DETAIL_OPTIONS;
  }

  if (id && AZKOYEN_OLD_IDS.includes(id)) {
    return LCA_DETAIL_OPTIONS.environmental_impacts;
  }

  return LCA_DETAIL_OPTIONS.canvas;
};
