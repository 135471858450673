import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersContext } from './FilterContext';
import Button from '../../ui/button/Button';
import './styles.scss';

const FilterHandlers = () => {
  const { open, setOpen, removeFilters, remove, filters, dates } = useContext(FiltersContext);
  const { t } = useTranslation();

  // get length of filters with different type, do not count if type is repeated
  const filtersWithTypeNotRepeated = filters
    .map((filter) => filter.type)
    .filter((type, index, self) => self.indexOf(type) === index);

  let filtersLength = filtersWithTypeNotRepeated.length;

  if (dates) {
    filtersLength++;
  }

  return (
    <div className='filter-content'>
      <div className='filter-buttons'>
        <Button
          icon={open ? '/images/icons/filterWhite.svg' : '/images/icons/filter.svg'}
          lookAndFeel={open ? 'primary' : 'secondary'}
          onClick={() => setOpen(!open)}
          size='small'
          text={t('general.filter')}></Button>
        {open && (
          <Button
            text={
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {t('filter.remove')}
                {filtersLength ? (
                  <div className='filter-total primary-bg-color'>{filtersLength}</div>
                ) : null}
              </div>
            }
            lookAndFeel={'secondary'}
            onClick={() => removeFilters(!remove)}
            size='small'
            icon='/images/icons/bin.svg'
          />
        )}
      </div>
    </div>
  );
};

export default FilterHandlers;
