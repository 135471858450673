import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { LANGUAGES } from '../../../../../constants';
import { UserContext } from '../../../../../context/userContext';
import {
  resendPendingEmployeesForm,
  resendToEmployeesForm
} from '../../../../../services/api/employees';

import moment from 'moment';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import { convertDateFrontToBack } from '../../../../../utils/convertDates';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Select from '../../../../ui/formComponents/select/Select';
import FormCalendarDouble from '../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';

type Props = {
  onConfirm: () => void;
  sendToPending?: boolean;
  emailsToSend?: string[];
};

type FormDataEmail = {
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};
const ConfirmResend = ({ onConfirm, sendToPending, emailsToSend }: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const currentYear = moment().format('YYYY');

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<SelectOptionFormat>({
    id: i18n.resolvedLanguage,
    name: t(`languages.${i18n.resolvedLanguage}`)
  });
  const [error, setError] = useState<ErrorType>();
  const [formData, setFormData] = useState<FormDataEmail>({
    startDate: '',
    endDate: '',
    errors: []
  });
  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const resendFormsToPending = async () => {
    if (handleErrors()) return;
    try {
      setLoading(true);
      const response = await resendPendingEmployeesForm(
        user?.selectedOrganization ?? '',
        language.id,
        convertDateFrontToBack(formData.startDate),
        convertDateFrontToBack(formData.endDate)
      );
      if (response === 'MAX_EMAILS_EXCEEDED' && !sendToPending) {
        setError({
          error: 'maxEmailsExceeded',
          description: t('error.maxEmailsExceeded')
        });
      }
      onConfirm();
      dispatch(setNotification(t('notification.formsResent')));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resendToSelected = async () => {
    if (handleErrors()) return;
    try {
      if (!emailsToSend || emailsToSend?.length === 0) {
        dispatch(setNotification(t('notification.formsResentErrorNoEmail')));
        return;
      }
      setLoading(true);
      await resendToEmployeesForm(
        user?.selectedOrganization ?? '',
        language.id,
        emailsToSend,
        convertDateFrontToBack(formData.startDate),
        convertDateFrontToBack(formData.endDate)
      );
      onConfirm();
      dispatch(setNotification(t('notification.formsResent')));
      setLoading(false);
    } catch (error) {
      dispatch(setNotification(t('notification.formsResentError')));
    }
  };

  const onChangeLanguage = (value: SelectOptionFormat) => {
    setLanguage(value);
  };

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  return (
    <div className='confirm-resend'>
      <Select
        options={LANGUAGES.map((language) => ({
          id: language,
          name: t(`languages.${language}`)
        }))}
        icon={'/images/icons/globe.svg'}
        placeholder={t('sendEmailForm.language')}
        label={t('sendEmailForm.language')}
        value={language}
        onChangeValue={onChangeLanguage}
        height='32px'
      />
      <FormCalendarDouble
        tooltip={t('employees.dateRangeTooltip')}
        label={t('sendEmailForm.measurement_period')}
        handleChangeStartDate={onChangeValue('startDate')}
        handleChangeEndDate={onChangeValue('endDate')}
        handleStartDateError={handleDateError('startDate')}
        handleEndDateError={handleDateError('endDate')}
        startDate={formData.startDate}
        endDate={formData.endDate}
        minStartDate={'01/01/1960'}
        minEndDate={'01/01/1960'}
        maxEndDate={`31/12/${currentYear}`}
        error={formData?.errors?.find(
          (elem) => elem.error === 'startDate' || elem.error === 'endDate'
        )}
      />

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('employees.resend')}
          onClick={sendToPending ? resendFormsToPending : resendToSelected}
          loading={loading}
          style={{ marginTop: '2em' }}
        />
      </FormButtonSection>
      {error && <p className='error-text-color body1-bold-font'>{error.description}</p>}
    </div>
  );
};

export default ConfirmResend;
