export default function getBase64(file: File, cb: (file_url: string | null | ArrayBuffer) => void) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    // eslint-disable-next-line no-console
    console.error('Error: ', error);
  };
}
