import CardCTA from '../../ui/cards/cardCTA/CardCTA';
import SectionHeader from '../sectionHeader/SectionHeader';
import './styles.scss';

type Props = {
  buttons?: React.ReactNode;
  children?: React.ReactNode;
  helpCTA?: React.ReactNode;
  gridTemplateRows?: string;
  sectionTitle: string;
  title: string;
  description?: string | React.ReactNode;
  breadcrumb?: React.ReactNode;
};

const MainPageLayout = ({
  buttons,
  children,
  helpCTA,
  sectionTitle,
  title,
  description,
  breadcrumb,
  gridTemplateRows
}: Props) => {
  // let gridTemplateRowsStyle = helpCTA ? '4.5rem 0.05fr 8.375rem 1fr' : '4.5rem 8.375rem 1fr';
  // if (gridTemplateRows) gridTemplateRowsStyle = gridTemplateRows;

  return (
    <section className='main-page-layout' /*style={{ gridTemplateRows: gridTemplateRowsStyle }}*/>
      <SectionHeader title={sectionTitle} breadcrumb={breadcrumb ?? null} />
      {helpCTA ?? null}
      <CardCTA>
        <CardCTA.Header>
          <span className='headline4-font'>{title}</span>
          <span
            className='subtitle3-font'
            style={{
              whiteSpace: 'pre-line'
            }}>
            {description ? description : ''}
          </span>
        </CardCTA.Header>
        {buttons && <CardCTA.Buttons>{buttons}</CardCTA.Buttons>}
      </CardCTA>
      {children ?? null}
    </section>
  );
};

const Layout: React.FC = ({ children }) => {
  return <section className='main-page-layout'>{children}</section>;
};

const Header = SectionHeader;

type CardProps = {
  title: string;
  description?: string;
};

const Card: React.FC<CardProps> = ({ title, description }) => {
  return (
    <CardCTA>
      <CardCTA.Header>
        <span className='headline4-font'>{title}</span>
        <span
          className='subtitle3-font'
          style={{
            whiteSpace: 'pre-line'
          }}>
          {description ? description : ''}
        </span>
      </CardCTA.Header>
    </CardCTA>
  );
};

const ButtonsLayout = CardCTA.Buttons;

MainPageLayout.Layout = Layout;

MainPageLayout.Header = Header;

MainPageLayout.Card = Card;

MainPageLayout.Buttons = ButtonsLayout;

export default MainPageLayout;
