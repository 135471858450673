import { useTranslation } from 'react-i18next';
import CardTooltipList from '../ui/cardTooltipList/CardTooltipList';
import SectionHeader from '../layout/sectionHeader/SectionHeader';
import useCards from './useCards';

import './styles.scss';

const CustomizeMainPage = () => {
  const { t } = useTranslation();
  const cards = useCards();
  return (
    <section className='customize-main-page'>
      <SectionHeader title={t('customizeMainPage.title')} />
      <CardTooltipList cards={cards} />
    </section>
  );
};

export default CustomizeMainPage;
