import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPredefinedTask } from '../../../../services/api/tasks';
import { IPostBackendTask, PredefinedTask } from '../../../../types/entities/task';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import TableComponent from '../../../ui/table/Table';
import './styles.scss';

type Props = {
  tasks: PredefinedTask[];
  handleCreateTasks: () => void;
};

const CreateTasks = ({ tasks, handleCreateTasks }: Props) => {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async () => {
    const requests = tasks.map((elem) => {
      const bodyParsed: IPostBackendTask = {
        created_by: elem.created_by, // user id
        organization_id: elem.organization_id,
        assigned_to: elem.assigned_to.id, // user id
        description: elem.description,
        due_date: elem.due_date, // timestamp
        reminder_date: elem.reminder_date, // timestamp
        category: elem.category,
        tags: elem.tags
      };

      return createPredefinedTask(bodyParsed, true);
    });
    setLoadingButton(true);
    await Promise.all(requests);
    setLoadingButton(false);
    handleCreateTasks();
  };

  const parseData = () => {
    return tasks.map((elem) => ({
      ...elem,
      category: t(`tasks.categories.${elem.category}`),
      assigned_to: elem.assigned_to.name
    }));
  };

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Assigned to',
      dataIndex: 'assigned_to',
      key: 'assigned_to'
    }
  ];
  return (
    <div className='admin-create-tasks'>
      <FormHeader title={'Resumen de las tareas a crear'} />
      <TableComponent data={parseData()} columns={columns} loading={false} />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text='Create'
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default CreateTasks;
