import { ReactNode } from 'react';
import Button from '../button/Button';
import './styles.scss';

type Props = {
  // startDate?: string;
  // endDate?: string;
  // handleChangeStartDate?: (date?: string) => void;
  // handleChangeEndDate?: (date?: string) => void;
  listView: boolean;
  onChangeListView: () => void;
  // searchInputList?: SearchInput[];
  children?: ReactNode;
};
function CardsFilter({ listView, onChangeListView, children }: Props) {
  return (
    <div className='cards-filter main-bg-color card-border-color'>
      <Button
        className='cards-filter__button'
        icon={listView ? '/images/icons/menuWhite.svg' : '/images/icons/menu.svg'}
        lookAndFeel={listView ? 'primary' : 'secondary'}
        size='small'
        onClick={onChangeListView}
      />
      {children}
    </div>
  );
}

export default CardsFilter;
