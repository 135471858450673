import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../button/Button';
import Select from '../../formComponents/select/Select';
import StepWrapper from '../stepWrapper/StepWrapper';
import '../styles.scss';

type Props = {
  nextStep: () => void;
  stepNumber: number;
  title: string;
  onChangeValue: (value: SelectOptionFormat) => void;
  value: SelectOptionFormat;
  width?: number;
  buttonText: string;
  options: SelectOptionFormat[];
  icon: string;
  placeholder: string;
};

const StepSelector = ({
  nextStep,
  stepNumber,
  title,
  onChangeValue,
  value,
  width = 300,
  buttonText,
  options,
  icon,
  placeholder
}: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState<ErrorType>();

  const changeStep = () => {
    if (value.id === '') {
      setError({ error: 'requiredField', description: `${t('error.requiredField')}` });
      return null;
    }
    nextStep();
  };

  return (
    <StepWrapper title={title} stepNumber={stepNumber}>
      <div className={'input-wrapper'}>
        <div className='input-element'>
          <Select
            icon={icon}
            placeholder={placeholder}
            onChangeValue={onChangeValue}
            height='31px'
            fontClass='input-small-font'
            size='small'
            value={value}
            options={options}
            error={error}
          />
        </div>
      </div>

      <div className='step-page-form__footer'>
        <Button lookAndFeel='primary' onClick={changeStep} text={buttonText} size={'medium'} />
      </div>
    </StepWrapper>
  );
};

export default StepSelector;
