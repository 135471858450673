import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExtraQuestion,
  FormSocialData,
  OptionalQuestion,
  ResponseSocialData,
  ValueTypes
} from '../../../../../../types/entities/socialData';
import { getUnits } from '../../../../../../services/api/unit';
import {
  getOptionalQuestionsSocialAndGovernance,
  getSocialData
} from '../../../../../../services/api/socialData';

type Props = {
  id: string;
};

const useGetData = ({ id }: Props) => {
  const { t } = useTranslation();
  const [units, setUnits] = useState<SelectOptionFormat[]>([]);
  const [questions, setQuestions] = useState<OptionalQuestion[]>([]);

  const [loading, setLoading] = useState(true);
  const [socialData, setSocialData] = useState<FormSocialData>({
    year: { id: '', name: '' },
    hasGlobalCompact: {
      id: '',
      name: ''
    },
    isGlobalCompactViolated: {
      id: '',
      name: ''
    },
    isRelatedGuns: {
      id: '',
      name: ''
    },
    anualRevenue: '',
    womanRevenue: '',
    manRevenue: '',
    numberWomanBoardDirectors: '',
    numberMenBoardDirectors: '',
    socialLaboralEvidenceComments: '',
    socialLaboralEvidenceAttached: undefined,
    biodiversityZone: {
      id: '',
      name: ''
    },
    quantityWaste: '',
    unitWaste: {
      id: '',
      name: ''
    },
    naceCode: {
      id: '',
      name: ''
    },
    environmentEvidenceComments: '',
    environmentEvidenceAttached: undefined,
    optionalQuestions: [],
    optionalQuestionsEvidenceComments: '',
    optionalQuestionsEvidenceAttached: undefined,
    errors: []
  });

  const yesNoOptions = [
    {
      id: 'yes',
      name: t('socialAndGovernance.yes')
    },
    {
      id: 'no',
      name: t('socialAndGovernance.no')
    }
  ];

  useEffect(() => {
    const getDataUnits = async () => {
      const data = await getUnits();
      if (!data) return;
      setUnits(
        data
          .filter((unit: Unit) => unit.name === 'metric_tonne_(t)')
          .map((unit: Unit) => ({
            id: unit.id,
            name: t(`units.${unit.name}`)
          }))
      );
    };

    const getQuestions = async () => {
      const response = await getOptionalQuestionsSocialAndGovernance();
      if (response?.response?.status >= 400) return;
      setQuestions(
        response.map((question: OptionalQuestion) => ({
          id: question.id,
          name: question.pai_extra_question_code,
          type: question.value_type
        }))
      );
    };

    const parseOptionalQuestionResponse = (element: ExtraQuestion) => {
      if (element.value_type === 'float') {
        return element.question_response;
      }
      if (element.value_type === 'float_double') {
        return element.question_response;
      }
      if (element.value_type === 'boolean') {
        return element.question_response
          ? {
              id: 'yes',
              name: t('socialAndGovernance.yes')
            }
          : {
              id: 'no',
              name: t('socialAndGovernance.no')
            };
      }
    };

    const getFormData = async () => {
      const response = await getSocialData(id);
      if (response?.response?.status >= 400) {
        setLoading(false);
        return;
      }

      const socialDataResponse: ResponseSocialData = response;

      const foundSocialDataAttachments = socialDataResponse.attachments?.filter(
        (elem) => elem.category === 'social'
      );
      const foundEnvironmentDataAttachments = socialDataResponse.attachments?.filter(
        (elem) => elem.category === 'environmental'
      );
      const foundOptionalQuestionsAttachments = socialDataResponse.attachments?.filter(
        (elem) => elem.category === 'pias'
      );

      setSocialData({
        year: {
          id: String(socialDataResponse.year_of_data),
          name: String(socialDataResponse.year_of_data)
        },
        hasGlobalCompact: socialDataResponse.global_compact_monitoring_protocol
          ? yesNoOptions[0]
          : yesNoOptions[1],
        isGlobalCompactViolated: socialDataResponse.global_compact_infringement
          ? yesNoOptions[0]
          : yesNoOptions[1],
        isRelatedGuns: socialDataResponse.manufacture_and_sale_of_weapons
          ? yesNoOptions[0]
          : yesNoOptions[1],
        anualRevenue: String(socialDataResponse.company_annual_revenue),
        womanRevenue: String(socialDataResponse.women_annual_mean_income),
        manRevenue: String(socialDataResponse.men_annual_mean_income),
        numberWomanBoardDirectors: String(socialDataResponse.number_of_women_in_the_board),
        numberMenBoardDirectors: String(socialDataResponse.number_of_men_in_the_board),
        socialLaboralEvidenceComments: foundSocialDataAttachments
          ? foundSocialDataAttachments[0]?.description
          : '',
        socialLaboralEvidenceAttached: foundSocialDataAttachments
          ? foundSocialDataAttachments.map((elem) => ({
              ...elem
            }))
          : [],
        biodiversityZone: socialDataResponse.activities_in_biodiversity_hotspots
          ? yesNoOptions[0]
          : yesNoOptions[1],
        quantityWaste: String(socialDataResponse.amount_of_discharges_water_bodies),
        unitWaste: socialDataResponse.unit,
        naceCode: socialDataResponse.nace_sector_code
          ? {
              id: socialDataResponse.nace_sector_code,
              name: t('naceCodes.' + socialDataResponse.nace_sector_code)
            }
          : {
              id: '',
              name: ''
            },
        environmentEvidenceComments: foundEnvironmentDataAttachments
          ? foundEnvironmentDataAttachments[0]?.description
          : '',
        environmentEvidenceAttached: foundEnvironmentDataAttachments
          ? foundEnvironmentDataAttachments.map((elem) => ({
              ...elem
            }))
          : [],
        optionalQuestions:
          socialDataResponse?.extra_questions?.map((element) => ({
            id: element.id,
            type: element.value_type as ValueTypes,
            response: parseOptionalQuestionResponse(element)
          })) ?? [],
        optionalQuestionsEvidenceComments: foundOptionalQuestionsAttachments
          ? foundOptionalQuestionsAttachments[0]?.description
          : '',
        optionalQuestionsEvidenceAttached: foundOptionalQuestionsAttachments
          ? foundOptionalQuestionsAttachments.map((elem) => ({
              ...elem
            }))
          : [],
        errors: []
      });
    };

    const getData = async () => {
      setLoading(true);
      await Promise.all([getDataUnits(), getFormData(), getQuestions()]);
      setLoading(false);
    };
    console.log('useEffect');
    getData();
  }, []);

  return { units, questions, loading, socialData };
};

export default useGetData;
