import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useErrors from '../../../customHooks/useErrors';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { uploadFilePresignedUrl } from '../../../services/api/aws';
import { uploadLcaFile } from '../../../services/api/lca';

import checkFormErrors from '../../../utils/checkFormErrors';
import Button from '../../ui/button/Button';
import FormButtonSection from '../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../ui/formComponents/inputFile/InputFileSingle';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';

type FormData = {
  organizationId: string;
  file: File | null;
  errors: ErrorType[];
};
type Props = {
  uploadLca: () => void;
};
export const LCAUploadV2 = ({ uploadLca }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    organizationId: '',
    file: null,
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const ERRORS = useErrors();

  const handleSubmit = async () => {
    setFormData((prev) => ({
      ...prev,
      errors: prev.errors.filter((elem) => elem.error !== ERRORS.FORM.SOMETHING_WENT_WRONG.error)
    }));
    const newErrors = checkFormErrors(formData, formData.errors);
    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    if (!formData.file) return;
    setLoadingButton(true);
    const data = await uploadLcaFile(formData.organizationId, formData.file?.name);
    setLoadingButton(false);
    if (!data || !data.upload_url) {
      setFormData((prev) => ({
        ...prev,
        errors: [ERRORS.FORM.SOMETHING_WENT_WRONG]
      }));
      return;
    }
    // upload file using the presigned url the same way as logistic
    const dataUpload = await uploadFilePresignedUrl(formData.file, data.upload_url);

    if (!dataUpload) {
      setFormData((prev) => ({
        ...prev,
        errors: [ERRORS.FORM.SOMETHING_WENT_WRONG]
      }));
      return;
    }
    uploadLca();
  };

  const generalError = formData.errors.find((elem) => elem.error === 'general');
  return (
    <>
      <FormHeader title={t('lcaAdmin.title')} />
      <FormWrapper>
        <FormText
          icon='/images/icons/organization.svg'
          label={t('lcaAdmin.organizationId')}
          onChange={onChangeValue('organizationId')}
          placeholder={t('lcaAdmin.organizationIdPlaceholder')}
          value={formData.organizationId}
        />
        <FormElementFull>
          <InputFileSingle
            handleFile={onChangeValue('file')}
            buttonText={t('lcaAdmin.upload')}
            labelText={t('lcaAdmin.uploadLabel')}
            fileName={formData.file?.name ?? ''}
            allowedTypes={[
              'text/csv',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ]}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('lcaAdmin.upload')}
          size='small'
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {generalError && (
        <span className='error-text-color body2-font'>{generalError?.description}</span>
      )}
    </>
  );
};
