type Primitive = string | number | boolean;

// Helper to generate query params array from an object
export const generateQueryParamsFromObject = (obj: Record<string, Primitive | undefined>) => {
  return Object.entries(obj)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => {
      return { key, value };
    });
};

const FILTER_BUILDERS = {
  in: (key: string, value: Primitive) => {
    return `${key}:in[${value}]`;
  },
  rd: (key: string, value: Primitive) => {
    return `${key}:rd[${value}]`;
  },
  eq: (key: string, value: Primitive) => {
    return `${key}:eq${value}`;
  },
  lk: (key: string, value: Primitive) => {
    return `${key}:lk${value}`;
  },
  il: (key: string, value: Primitive) => {
    return `${key}:il${value}`;
  }
} as const;

// those are the only filter types that allow string[] as value
const FILTER_MAPPER = {
  in: (value: Primitive) => {
    return `%22${value}%22`;
  },
  rd: (value: Primitive) => {
    return `${value}`;
  }
} as const;

export type FilterType = keyof typeof FILTER_BUILDERS;

export type MultipleFilterType = keyof typeof FILTER_MAPPER;

export type IFilter = {
  field: string;
  value?: Primitive | Primitive[];
  type: FilterType;
};

export type ISorter = {
  field: string;
  order: 'asc' | 'desc';
};

export type QueryParams = {
  key: string;
  value?: string | number | boolean;
};

export type URLOptions = {
  filters?: IFilter[];
  sorters?: ISorter[];
  queryParams?: QueryParams[];
};

export const getFilter = (value: NonNullable<IFilter['value']>, type: FilterType) => {
  if (Array.isArray(value)) {
    if (!(type in FILTER_MAPPER)) {
      throw new Error(`Filter type ${type} only accepts a single string`);
    }
    return value.map(FILTER_MAPPER[type as keyof typeof FILTER_MAPPER]).join(',');
  }

  return value;
};

export const getQueryParamsFromObject = (obj?: URLOptions) => {
  const queryParams: string[] = [];

  if (!obj) {
    return '';
  }

  const filteredEntries = obj.queryParams?.filter(({ value }) => {
    if (!value) {
      return false;
    }

    if (Array.isArray(value) && value.length === 0) {
      return false;
    }

    return true;
  });

  filteredEntries?.forEach(({ key, value }) => {
    queryParams.push(`${key}=${value}`);
  });

  const filters = obj.filters?.reduce((acc, { field, value, type }) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return acc;
    }

    const valueAsFilter = getFilter(value, type);

    const filter = FILTER_BUILDERS[type](field, valueAsFilter);

    return [...acc, filter];
  }, [] as string[]);

  if (filters?.length) {
    queryParams.push(`filter_by=${filters.join('$')}`);
  }

  const sorters = obj.sorters?.reduce((acc, { field, order }) => {
    return [...acc, `${field}:${order}`];
  }, [] as string[]);

  if (sorters?.length) {
    queryParams.push(`sort_by=${sorters.join('$')}`);
  }

  if (queryParams.length === 0) {
    return '';
  }

  return `?${queryParams.join('&')}`;
};

export const getUrl = (url: string, urlOptions?: URLOptions) => {
  return `${url}${getQueryParamsFromObject(urlOptions)}`;
};
