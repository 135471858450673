import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../context/userContext';
import { getEventById, getPreEvent } from '../../../../services/api/events';
import { EventType, PreEventBody } from '../../../../types/entities/events';

type Props = {
  id: string;
};
// Fetch event Detail info from backend
const useGetData = ({ id }: Props) => {
  const [eventDetail, setEventDetail] = useState<EventType>();
  const [preEventData, setPreEventData] = useState<PreEventBody>();
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);

  const today = new Date();
  const aWeekAgo = new Date();
  aWeekAgo.setDate(today.getDate() - 7);

  const fetchData = async () => {
    try {
      setLoading(true);
      // Call api to get event detail
      const eventDetail = await getEventById(id, user?.selectedOrganization ?? '');
      if (!eventDetail) {
        setLoading(false);
        return;
      }
      setEventDetail({
        id: eventDetail.id,
        name: eventDetail.name,
        location: eventDetail.location,
        location_type: eventDetail.location_type,
        co2e_pre: eventDetail.co2e_pre,
        co2e_post: eventDetail.co2e_post,
        start_date: moment(new Date(eventDetail.start_date)).format('DD/MM/YYYY'),
        end_date: moment(new Date(eventDetail.end_date)).format('DD/MM/YYYY'),
        country: eventDetail.country
      });

      // Call api to get preEventData
      const preEvent = await getPreEvent(id, user?.selectedOrganization ?? '');
      if (!preEvent) {
        setLoading(false);
        return;
      }
      setPreEventData(preEvent);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user]);

  return { eventDetailData: eventDetail, preEventData, loading };
};

export default useGetData;
