import { Month } from '../types/utils/months';

export const months: Month[] = [
  {
    name: {
      en: 'January',
      es: 'Enero',
      pt: 'Janeiro'
    },
    short: {
      en: 'Jan',
      es: 'Ene',
      pt: 'Jan'
    },
    number: 1,
    days: 31
  },
  {
    name: {
      en: 'February',
      es: 'Febrero',
      pt: 'Fevereiro'
    },
    short: {
      en: 'Feb',
      es: 'Feb',
      pt: 'Fev'
    },
    number: 2,
    days: 28
  },
  {
    name: {
      en: 'March',
      es: 'Marzo',
      pt: 'Março'
    },
    short: { en: 'Mar', es: 'Mar', pt: 'Mar' },
    number: 3,
    days: 31
  },
  {
    name: {
      en: 'April',
      es: 'Abril',
      pt: 'Abril'
    },
    short: { en: 'Apr', es: 'Abr', pt: 'Abr' },
    number: 4,
    days: 30
  },
  {
    name: {
      en: 'May',
      es: 'Mayo',
      pt: 'Maio'
    },
    short: { en: 'May', es: 'May', pt: 'Mai' },
    number: 5,
    days: 31
  },
  {
    name: {
      en: 'June',
      es: 'Junio',
      pt: 'Junho'
    },
    short: { en: 'Jun', es: 'Jun', pt: 'Jun' },
    number: 6,
    days: 30
  },
  {
    name: {
      en: 'July',
      es: 'Julio',
      pt: 'Julho'
    },
    short: { en: 'Jul', es: 'Jul', pt: 'Jul' },
    number: 7,
    days: 31
  },
  {
    name: {
      en: 'August',
      es: 'Agosto',
      pt: 'Agosto'
    },
    short: { en: 'Aug', es: 'Ago', pt: 'Ago' },
    number: 8,
    days: 31
  },
  {
    name: {
      en: 'September',
      es: 'Septiembre',
      pt: 'Setembro'
    },
    short: { en: 'Sep', es: 'Sep', pt: 'Set' },
    number: 9,
    days: 30
  },
  {
    name: {
      en: 'October',
      es: 'Octubre',
      pt: 'Outubro'
    },
    short: { en: 'Oct', es: 'Oct', pt: 'Out' },
    number: 10,
    days: 31
  },
  {
    name: {
      en: 'November',
      es: 'Noviembre',
      pt: 'Novembro'
    },
    short: { en: 'Nov', es: 'Nov', pt: 'Nov' },
    number: 11,
    days: 30
  },
  {
    name: {
      en: 'December',
      es: 'Diciembre',
      pt: 'Dezembro'
    },
    short: { en: 'Dec', es: 'Dic', pt: 'Dez' },
    number: 12,
    days: 31
  }
];
