import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MAX_INVOICES_PER_REQUEST, MAX_PAYLOAD_SIZE } from '../../../../../../../constants';
import { UserContext } from '../../../../../../../context/userContext';
import { useFeatureFlags } from '../../../../../../../customHooks/useFeatureFlags';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../../../../customHooks/useSelectedOrganization';
import { uploadInvofox } from '../../../../../../../services/api/invoices';
import { Facility } from '../../../../../../../types/entities/facility';
import { FacilityFuelType } from '../../../../../../../types/entities/facilityFuel';
import {
  InvoiceAuto,
  InvoiceToUpload,
  Percentages
} from '../../../../../../../types/entities/invoice';
import { InvoiceStatus } from '../../../../../../../types/entitiesEnums/invoice';
import { InputSize } from '../../../../../../../types/utilsEnums/input';
import checkFormErrors from '../../../../../../../utils/checkFormErrors';
import getBase64 from '../../../../../../../utils/getBase64';
import makeRequestsInBatches from '../../../../../../../utils/makeRequestInBatches';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FileItem from '../../../../../../ui/fileItem/FileItem';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFile from '../../../../../../ui/formComponents/inputFile/InputFile';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import DocumentPDFV2 from '../../../../../../ui/pdf/DocumentPDFV2';
import TooltipWrapper from '../../../../../../ui/tooltip/TooltipWrapper';
import useConstants from '../../../../../logistics/components/shipment/hooks/useConstants';
import DivideConsumptionsWrapper from '../../createInvoice/components/common/divideConsumptions/DivideConsumptionsWrapper';
import DatadisInformation from '../../createInvoice/components/datadisInformation/DatadisInformation';
import {
  validateFacilityWithZero,
  validateFacilityWithoutId
} from '../../createInvoice/components/validations/validator';
import useGetData from '../../createInvoice/hooks/useGetData';
import { InputNuvoWastes } from '../uploadWasteInvoices/nuvo/InputNuvoWastes';
import './styles.scss';
import UploadWasteInvoices from '../uploadWasteInvoices/UploadWasteInvoices';

type Props = {
  facility: Facility;
  setInvoiceMode?: Dispatch<SetStateAction<string>>;
  addInvoice: (invoicesUploaded: InvoiceAuto[]) => void;
  setTotalPending?: Dispatch<SetStateAction<number>>;
  setTotal?: Dispatch<SetStateAction<number>>;
  selectInvoiceType: ReactNode;
  type: string;
};

type FormData = {
  supplier: SelectOptionFormat;
  toc?: SelectOptionFormat;
  facilityFuel: SelectOptionFormat;
  percentages: Percentages[];
  errors: ErrorType[];
};

const customInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big'
};

const UploadInvoicesUsual = ({
  facility,
  setInvoiceMode,
  addInvoice,
  type,
  selectInvoiceType,
  setTotalPending,
  setTotal
}: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();
  const flags = useFeatureFlags();

  const location = useLocation();
  const { tocsSuperchargerHub } = useConstants();
  const {
    suppliers,
    facilityFuels,
    organizationOptions,
    loadingData,
    lastUsedFacilityPercentages
  } = useGetData({
    type,
    country: facility.country,
    facilityId: facility.id
  });

  // STATES

  const [loadingButton, setLoadingButton] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    supplier: { id: '', name: '' },

    facilityFuel: { id: '', name: '' },
    percentages: [
      {
        facility: { id: facility.id, name: facility.name },
        organization: {
          id: selectedOrganization?.id ?? '',
          name: selectedOrganization?.company_name ?? ''
        },
        percentage: '100'
      }
    ],
    errors: []
  });
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const [invoicesToUpload, setInvoicesToUpload] = useState<InvoiceToUpload[]>([]);
  const [documentIndex, setDocumentIndex] = useState(0);
  const [sizesBiggerThanMax, setSizesBiggerThanMax] = useState<{ size: number; index: number }[]>(
    []
  );

  const isSuperchargerHub = facility.supercharger;

  useEffect(() => {
    setFormData((prev) =>
      isSuperchargerHub ? { ...formData, toc: { id: '', name: '' } } : { ...prev }
    );
  }, [isSuperchargerHub]);

  useEffect(() => {
    if (lastUsedFacilityPercentages.length > 0) {
      setFormData((prev) => ({
        ...prev,
        percentages: lastUsedFacilityPercentages.map((elem) => ({
          facility: {
            id: elem.facility_id,
            name: elem.facility_name
          },
          organization: {
            id: elem.organization_id,
            name: elem.company_name
          },
          percentage: (elem.percentage * 100).toString()
        }))
      }));
    }
  }, [JSON.stringify(lastUsedFacilityPercentages)]);

  const getIconSrc = (fileType: string) => {
    if (fileType === 'heat') {
      return '/images/icons/fire50.svg';
    } else if (fileType === 'electricity') {
      return '/images/icons/thunder50.svg';
    } else if (fileType === 'water') {
      return '/images/icons/water.svg';
    }
    return '';
  };

  const handleUploadFiles = (filesUploaded: File[]) => {
    const optionalFields = [];
    if (['heat', 'recharge', 'water'].includes(type as string)) {
      optionalFields.push('supplier');
    }
    if (['electricity', 'water'].includes(type as string)) {
      optionalFields.push('facilityFuel');
    }

    // Do not check supplier if country is not Spain
    if (['electricity'].includes(type as string) && facility.country !== 'ES') {
      optionalFields.push('supplier');
    }
    // Check if there are errors in the form
    const newErrors = checkFormErrors(formData, [], optionalFields);

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    filesUploaded.forEach((file) => {
      getBase64(file, (file_url: string | null | ArrayBuffer) => {
        if (file_url) {
          const newInvoiceToUpload: InvoiceToUpload = {
            supplier_id: (formData.supplier as SelectOptionFormat).id
              ? (formData.supplier as SelectOptionFormat).id
              : null,
            facility_fuel_id: (formData.facilityFuel as SelectOptionFormat).id
              ? (formData.facilityFuel as SelectOptionFormat).id
              : null,
            file,
            type: type as string,
            file_url: file_url as string,
            facility_id: facility.id || '',
            uploaded_by: user ? user.id : '',
            toc: formData.toc?.id,
            facility_percentages: [
              {
                organization_id: selectedOrganization?.id ?? '',
                facility_id: facility.id,
                percentage: 100
              }
            ],
            status: InvoiceStatus.LOADING
          };
          setInvoicesToUpload((prev) => [...prev, newInvoiceToUpload]);
        }
      });
    });
  };

  const handleRemoveInvoice = (index: number) => {
    // filter errors to remove the one of type "file"
    const newErrors = formData.errors.filter((elem) => elem.error !== 'file');
    const oldInvoicesToUpload = [...invoicesToUpload];
    oldInvoicesToUpload.splice(index, 1);
    setInvoicesToUpload(oldInvoicesToUpload);
    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));
  };

  const handleNextDocument = () => {
    if (invoicesToUpload && documentIndex < invoicesToUpload.length - 1) {
      setDocumentIndex(documentIndex + 1);
    }
  };

  const handlePrevDocument = () => {
    if (invoicesToUpload && documentIndex > 0) {
      setDocumentIndex(documentIndex - 1);
    }
  };

  const handleUploadInvoice = async () => {
    const newErrors = [...formData.errors];
    let size = 0;
    let sizeBase64 = 0;

    const sizes: number[] = [];
    invoicesToUpload.forEach((invoice) => {
      size += invoice.file.size / (1024 * 1024);
      sizeBase64 = invoice.file_url.length / (1024 * 1024);
      sizes.push(sizeBase64);
    });

    const sizesBiggerThanMaxList: {
      size: number;
      index: number;
    }[] = [];
    sizes.forEach((elem, index) => {
      if (elem >= MAX_PAYLOAD_SIZE) {
        sizesBiggerThanMaxList.push({
          size: elem,
          index
        });
      }
    });

    setSizesBiggerThanMax(sizesBiggerThanMaxList);

    if (!invoicesToUpload || invoicesToUpload.length === 0 || sizesBiggerThanMax.length > 0) {
      newErrors.push({
        error: 'file',
        description: t('error.maxPayloadSize')
      });
    }
    if (invoicesToUpload.length > MAX_INVOICES_PER_REQUEST) {
      newErrors.push({
        error: 'file',
        description: t('error.maxInvoicesPerRequest')
      });
    }

    const facilityWithZeroError = validateFacilityWithZero(formData.percentages);
    if (facilityWithZeroError) {
      newErrors.push(facilityWithZeroError);
    }

    const facilityWithoutIdError = validateFacilityWithoutId(formData.percentages);
    if (facilityWithoutIdError) {
      newErrors.push(facilityWithoutIdError);
    }

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    setLoadingButton(true);

    const uploadRequests: any = [];

    invoicesToUpload.forEach((invoice) => {
      uploadRequests.push(() =>
        uploadInvofox(
          {
            ...invoice,
            facility_percentages: formData.percentages.map((percentage) => ({
              organization_id: percentage.organization.id,
              facility_id: percentage.facility.id,
              percentage: parseFloat(percentage.percentage) / 100
            }))
          },
          true
        )
      );
    });

    const invoicesUploaded: any[] = [];

    try {
      const responses = await makeRequestsInBatches(uploadRequests);
      responses.forEach((response: any, index: number) => {
        if (
          response &&
          response.response &&
          response.response.status &&
          response.response.status === 413
        ) {
          sizesBiggerThanMaxList.push({
            size: sizes[index],
            index
          });
          // continue loop to next iteration
          return;
        }
        const invoice = response.data[0];
        invoicesUploaded.push({
          type: invoice.type,
          supplier: (invoice.supplier as SupplierType) || null,
          facility_fuel: invoice.facility_fuel as FacilityFuelType,
          status: 'loading',
          unit: invoice.unit as Unit,
          id: invoice.id,
          start_date: invoice.start_date,
          end_date: invoice.end_date,
          invoice_id: invoice.invoice_id,
          percentage:
            formData.percentages.find((elem) => elem.facility.id === facility.id)?.percentage ?? 100
        });
      });
      if (sizesBiggerThanMaxList.length > 0) {
        setSizesBiggerThanMax(sizesBiggerThanMaxList);
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'file', description: t('error.maxPayloadSize') }]
        }));
        setLoadingButton(false);
        return;
      }
      addInvoice(invoicesUploaded);
      setLoadingButton(false);
    } catch (err: any) {
      let responseStatus = 200;
      if (err.response && err.response.status) {
        responseStatus = err.response.status;
      }
      if (responseStatus === 413) {
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'file', description: t('error.maxPayloadSize') }]
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'file', description: t('error.uploadInvoices') }]
        }));
      }
      setLoadingButton(false);
    }
  };

  const documentPDF =
    invoicesToUpload && invoicesToUpload.length > 0 ? (
      <DocumentPDFV2 file={invoicesToUpload[documentIndex].file_url} />
    ) : null;

  const buttonTooltip =
    formData.percentages.reduce((acc, elem) => acc + parseFloat(elem.percentage), 0) === 0
      ? t('facilityDetail.noPercentageAssignedYet')
      : t('facilityDetail.percentageError');

  return (
    <div className='flex'>
      {documentPDF && (
        <div className='invoice-pdf-wrapper' style={{ height: '75vh' }}>
          {documentPDF}
          {invoicesToUpload && invoicesToUpload.length > 0 && (
            <div className='change-document-wrapper'>
              <img
                src='/images/icons/chevron.svg'
                className='chevron-icon-left'
                onClick={handlePrevDocument}
                alt='chevron'
              />
              <span className='subtitle3-font'>{`${t('facilityDetail.invoice')} ${
                documentIndex + 1
              }/${invoicesToUpload.length}`}</span>
              <img
                src='/images/icons/chevron.svg'
                className='chevron-icon-right'
                onClick={handleNextDocument}
                alt='chevron'
              />
            </div>
          )}
        </div>
      )}
      <div className='upload-invoice'>
        <FormHeader title={t('facilityDetail.uploadNewInvoice')} />
        {selectInvoiceType}
        {type === 'electricity' && flags?.datadis && <DatadisInformation />}
        {loadingData && <CustomSkeletonLoader count={2} />}

        {type === 'waste' && !loadingData && (
          <UploadWasteInvoices
            facility={facility}
            handleClose={() => setInvoiceMode && setInvoiceMode('')}
          />
        )}

        {type && type !== 'waste' && !loadingData && (
          <>
            <FormWrapper style={{ width: '584px', maxHeight: '45vh', overflowY: 'auto' }}>
              {suppliers && suppliers.length > 0 && (
                <FormSelect
                  {...customInputProps}
                  icon={
                    type === 'heat' ? '/images/icons/fire50.svg' : '/images/icons/thunder50.svg'
                  }
                  placeholder={t('facilityDetail.selectASupplier')}
                  label={
                    type === 'heat'
                      ? t('facilityDetail.supplierOptional')
                      : t('facilityDetail.supplier')
                  }
                  options={suppliers}
                  value={formData.supplier as SelectOptionFormat}
                  onChange={onChangeValue('supplier')}
                  error={formData.errors.find((elem) => elem.error === 'supplier')}
                  optional={type === 'heat'}
                  size={InputSize.MEDIUM}
                />
              )}
              {facility.categories?.length === 1 &&
                type === 'electricity' &&
                location.pathname.includes('hubs') && (
                  <FormSelect
                    {...customInputProps}
                    icon='/images/icons/shipmentGradient.svg'
                    placeholder={t('facilityDetail.selectASupplier')}
                    label={t('hubs.tocType')}
                    options={tocsSuperchargerHub}
                    value={formData?.toc as SelectOptionFormat}
                    onChange={onChangeValue('toc')}
                    error={formData.errors.find((elem) => elem.error === 'toc')}
                    size={InputSize.MEDIUM}
                  />
                )}
              {facilityFuels && facilityFuels.length > 0 && (
                <FormSelect
                  {...customInputProps}
                  icon='/images/icons/catalog.svg'
                  placeholder={t('facilityDetail.selectAFuel')}
                  label={t('facilityDetail.fuel')}
                  options={facilityFuels.map((elem: FacilityFuelType) => ({
                    id: elem.id,
                    name: t(`facility_fuels.${elem.name}`)
                  }))}
                  value={formData.facilityFuel as SelectOptionFormat}
                  onChange={onChangeValue('facilityFuel')}
                  error={formData.errors.find((elem) => elem.error === 'facilityFuel')}
                  size={InputSize.MEDIUM}
                />
              )}

              {!isSuperchargerHub ? (
                <DivideConsumptionsWrapper
                  setFormData={setFormData}
                  organizationOptions={organizationOptions}
                  formData={formData}
                  units={[]}
                  facility={facility}
                />
              ) : null}

              <div className='flex-col gap-2' style={{ width: '100%' }}>
                <span className='body1-bold-font'>
                  {invoicesToUpload && invoicesToUpload.length > 0
                    ? t('facilityDetail.invoiceUploaded')
                    : t('facilityDetail.uploadYourInvoices')}
                </span>
                {invoicesToUpload && invoicesToUpload.length > 0 && (
                  <div className='files-wrapper'>
                    {invoicesToUpload.map((elem: InvoiceToUpload, index: number) => (
                      <FileItem
                        key={index}
                        file={elem.file}
                        index={index}
                        icon={getIconSrc(elem.type)}
                        removeFile={handleRemoveInvoice}
                        error={
                          sizesBiggerThanMax.find((elem) => elem.index === index)
                            ? t('error.fileTooBig')
                            : ''
                        }
                      />
                    ))}
                  </div>
                )}
                <div className='file-upload-wrapper' style={{ width: '100%' }}>
                  <InputFile
                    handleFiles={handleUploadFiles}
                    buttonText={
                      invoicesToUpload && invoicesToUpload.length > 0
                        ? t('facilityDetail.uploadAnotherInvoice')
                        : t('facilityDetail.uploadInvoiceButtonFile')
                    }
                    fileNames={
                      invoicesToUpload && invoicesToUpload.length > 0
                        ? invoicesToUpload.map((invoice) => invoice.file.name)
                        : []
                    }
                    labelText={t('facilityDetail.dragAndDropFile')}
                    allowedTypes={['application/pdf']}
                    error={formData.errors.find((error) => error.error === 'file')}
                  />
                </div>
              </div>
            </FormWrapper>

            <FormButtonSection>
              <TooltipWrapper
                text={buttonTooltip}
                shouldAppear={!!formData.errors.find((elem) => elem.error === 'percentages')}
                style={{ width: '100%' }}>
                <Button
                  lookAndFeel='primary'
                  text={t('facilityDetail.uploadInvoiceButton')}
                  onClick={handleUploadInvoice}
                  loading={loadingButton}
                  disabled={!!formData.errors.find((error) => error.error === 'percentages')}
                />
              </TooltipWrapper>
            </FormButtonSection>
            {formData.errors.find((error) => error.error === 'request') && (
              <ErrorText>
                {formData.errors.find((error) => error.error === 'request')?.description}
              </ErrorText>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadInvoicesUsual;
