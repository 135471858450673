import { createContext, useContext, useMemo, useState } from 'react';
import Modal from '../modal/Modal';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import Button from '../button/Button';
import InputText from '../formComponents2/inputText/InputText';
import InputWrapper from '../formComponents2/inputUtils/inputWrapper/InputWrapper';
import TooltipWrapper from '../tooltip/TooltipWrapper';

type DeleteContext = {
  deleteInput: string;
  setDeleteInput: (value: string) => void;
};

const DeleteContext = createContext<DeleteContext | null>(null);

const DeleteProvider: React.FC = ({ children }) => {
  const [deleteInput, setDeleteInput] = useState('');

  return (
    <DeleteContext.Provider value={{ deleteInput, setDeleteInput }}>
      {children}
    </DeleteContext.Provider>
  );
};

export const useDeleteContext = () => {
  const context = useContext(DeleteContext);
  if (context === null) {
    throw new Error('useDeleteContext must be used within a DeleteProvider');
  }
  return context;
};

type MainProps = React.ComponentProps<typeof Modal.WithPortal>;

const Main = ({ children, ...props }: MainProps) => {
  return (
    <Modal.WithPortal {...props}>
      <DeleteProvider>{children}</DeleteProvider>
    </Modal.WithPortal>
  );
};
type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <div className='flex flex-col gap-y-2'>{children}</div>
);

type DeleteInputProps = {
  label: string;
};

const DeleteInput: React.FC<DeleteInputProps> = ({ label }) => {
  const { deleteInput, setDeleteInput } = useDeleteContext();

  const id = useMemo(uuid, []);

  const { t } = useTranslation();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    setDeleteInput(event.target.value);
  };

  return (
    <label htmlFor={id} className='flex-col gap-y-2'>
      <span>{label}</span>

      <InputWrapper className='w-percentage-40' iconV2='input_text'>
        <InputText
          id={id}
          value={deleteInput}
          onChange={onChange}
          placeholder={t('deletionModal.inputPlaceholder')}
        />
      </InputWrapper>
    </label>
  );
};

type ButtonProps = {
  onDelete: () => void;
  loading?: boolean;
};

const DeleteButton: React.FC<ButtonProps> = ({ onDelete, loading }) => {
  const { deleteInput } = useDeleteContext();
  const { t } = useTranslation();

  const unlock = deleteInput.toLowerCase() === t('deletionModal.eliminate').toLowerCase();

  const onClick = () => {
    if (unlock && !loading) {
      onDelete();
    }
  };

  return (
    <TooltipWrapper
      shouldAppear={!unlock}
      text={t('deletionModal.mustUnlock')}
      asChild
      style={{ width: '100%' }}>
      <Button
        lookAndFeel='warning'
        disabled={!unlock}
        onClick={onClick}
        text={t('deletionModal.agree')}
        height='medium'
        loading={loading}
      />
    </TooltipWrapper>
  );
};
type ButtonsProps = {
  children: React.ReactNode;
};

const Buttons: React.FC<ButtonsProps> = ({ children }) => {
  return <div className='flex gap-x-2 mt-8'>{children}</div>;
};

type ContentProps = {
  children: React.ReactNode;
};
const Content: React.FC<ContentProps> = ({ children }) => {
  return <div className='flex-col gap-y-20 font-body-b2-r'>{children}</div>;
};

type HeaderProps = {
  title: string;
};

const Header: React.FC<HeaderProps> = ({ title }) => {
  return <h1 className='font-headings-h3-sb elipsis'>{title}</h1>;
};

export const DeletionModal = {
  Main,
  Content,
  Buttons,
  Layout,
  Header,
  Input: DeleteInput,
  Btn: DeleteButton
};
