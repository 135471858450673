import { Vehicle } from '../../../../../../types/entities/vehicle';
import { useTranslation } from 'react-i18next';
import { limitString } from '../../../../../../utils/limitString';
import VehicleCardDisabled from './VehicleCardDisabled';
import VehicleCardActive from './VehicleCardActive';

type Props = {
  vehicle: Vehicle;
  listView: boolean;
  onClickCard: (id: string) => void;
  dropdownOptions: {
    id: string;
    name: string;
    onClick: () => void;
  }[];
};

const VehicleCard = ({ vehicle, listView, onClickCard, dropdownOptions }: Props) => {
  const { t } = useTranslation();

  const renderDescription = (vehicle: Vehicle) => {
    if (vehicle.license_plate) return vehicle.license_plate;

    const description = vehicle.known_vehicle_brand
      ? `${vehicle.known_vehicle_brand} ${vehicle.known_vehicle_model}`
      : `${t(`vehicles.${vehicle.unknown_vehicle_type}`)}${
          vehicle.vehicle_fuel ? ' - ' + t(`vehicle_fuels.${vehicle?.vehicle_fuel}`) : ''
        }`;

    if (!description) return '';
    return limitString(description, 30);
  };

  const errorDropdownOptions = dropdownOptions.filter(
    (option) => option.id !== 'archive' && option.id !== 'dearchive'
  );

  if (vehicle.status === 'error') {
    return (
      <VehicleCardDisabled
        vehicle={vehicle}
        description={renderDescription(vehicle)}
        listView={listView}
        dropdownOptions={errorDropdownOptions}
      />
    );
  }

  const activeDropdownOptions = dropdownOptions.filter((option) => option.id !== 'dearchive');
  const archivedDropdownOptions = dropdownOptions.filter((option) => option.id === 'dearchive');

  const dropdownOptionsUsed =
    vehicle.status === 'active' ? activeDropdownOptions : archivedDropdownOptions;

  return (
    <VehicleCardActive
      vehicle={vehicle}
      description={renderDescription(vehicle)}
      listView={listView}
      dropdownOptions={dropdownOptionsUsed}
      onClickCard={onClickCard}
    />
  );
};

export default VehicleCard;
