import { useRef, useState } from 'react';
import UserModal from './UserModal';
import { t } from 'i18next';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';

const UserButton = () => {
  const userRef = useRef<HTMLDivElement>(null);
  const [showUserModal, setShowUserModal] = useState(false);

  const handleOnCloseProfile = () => setShowUserModal(false);
  const handleOnClickProfile = () => setShowUserModal(!showUserModal);

  return (
    <div className='header-profile' ref={userRef}>
      <Button
        lookAndFeel='secondary'
        iconNode={<Icon icon='user' color='gray-dark' />}
        onClick={handleOnClickProfile}
        text={t('header.myProfile')}
        size='small'
      />
      {showUserModal && <UserModal handleOnClickProfile={handleOnCloseProfile} userRef={userRef} />}
    </div>
  );
};

export default UserButton;
