import moment from 'moment';
import { User } from '../../../../../../../types/entities/user';
import { InvoiceStatus } from '../../../../../../../types/entitiesEnums/invoice';
import { InvoiceBody, InvoiceFormData } from '../constants';

type Params = {
  formData: InvoiceFormData;
  user: Partial<User> | null;
  type: string;
  mode: string;
  parsedUnitId: string;
  facility_id: string;
};

export const buildInvoice = ({
  formData,
  user,
  type,
  mode,
  parsedUnitId,
  facility_id
}: Params): InvoiceBody => {
  const percentages = formData.percentages.map(({ percentage, facility, organization }) => {
    return {
      facility_id: facility.id,
      organization_id: organization.id,
      percentage: parseFloat(percentage) / 100
    };
  });

  const invoice: InvoiceBody = {
    type,
    custom_emission_factor_id: formData.emissionFactor || null,
    supplier_id: formData.supplier?.id || null,
    facility_fuel_id: formData.facilityFuel?.id || null,
    unit_id: parsedUnitId,
    base_quantity: parseFloat(parseFloat(formData.quantity as string).toFixed(2)),
    // quantity: parseFloat(parseFloat(formData.quantity as string).toFixed(2)),
    invoice_id: formData.invoiceId as string,
    cups: formData.cups as string,
    start_date: moment.utc(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    end_date: moment.utc(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    status: InvoiceStatus.UPLOADED,
    uploaded_by: user?.id || '',
    facility_id,
    facility_percentages: percentages,
    toc: formData.toc?.id || ''
  };

  if (mode === 'custom') {
    invoice.custom_emission_factor_id = formData.emissionFactor as string;
    invoice.supplier_id = null;
    invoice.facility_fuel_id = null;
  }

  return invoice;
};
