import { useUnits } from '../../../../../../customHooks/api/useUnits';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import Button from '../../../../../ui/button/Button';
import FormCalendarDouble from '../../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import InputNumber from '../../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../../ui/formComponents2/select/Select';
import Modal from '../../../../../ui/modal/Modal';
import { LCA_DECIMALS } from '../../../../constants';
import { useForm } from './FormContext';
import { STEP_ENUM } from './constants';

type MetadataPageProps = {
  setSection: (section: string) => void;
  onClose: () => void;
};

export const MetadataPage = ({ setSection, onClose }: MetadataPageProps) => {
  const { t } = useLCATranslation({ keyPrefix: 'portfolio.createLCA.modal' });

  return (
    <>
      <Modal.Content>
        <Content />
      </Modal.Content>
      <Modal.Buttons>
        <Button lookAndFeel='secondary' text={t('cancelButton')} size='medium' onClick={onClose} />
        <Button
          lookAndFeel='primary'
          text={t('nextButton')}
          size='medium'
          onClick={() => setSection(STEP_ENUM.IMPACT_CATEGORIES)}
        />
      </Modal.Buttons>
    </>
  );
};

const Content = () => {
  const { form, errors, setForm, partialValidate } = useForm();

  const { data } = useUnits();

  const { t } = useLCATranslation({ keyPrefix: 'portfolio.createLCA.modal' });

  const handleChange = (name: string, value: string | SelectOptionFormat<string>) => {
    setForm((prev) => {
      const newValue = { ...prev, [name]: value };

      if (errors?.[name as keyof typeof form]) {
        partialValidate(STEP_ENUM.METADATA, name as keyof typeof form, newValue);
      }

      return newValue;
    });
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.name, e.target.value);
  };

  const onChangeDate = (name: string) => {
    return (date: string) => {
      handleChange(name, date);
    };
  };

  const onChangeUnit = (name: string) => {
    return (unit: SelectOptionFormat<string>) => {
      handleChange(name, unit);
    };
  };

  const onChangeNumber = (name: string) => {
    return (value: string) => {
      handleChange(name, value);
    };
  };

  return (
    <div className='flex flex-col gap-y-4 pb-10'>
      <FormText
        name='name'
        placeholder={t('namePlaceholder')}
        value={form.name}
        onChange={handleChangeText}
        iconV2='shirt'
        label={t('nameLabel')}
        autoComplete='off'
        error={errors?.name}
      />
      <FormCalendarDouble
        startDate={form.startDate}
        endDate={form.endDate}
        handleChangeStartDate={onChangeDate('startDate')}
        handleChangeEndDate={onChangeDate('endDate')}
        iconV2='calendar'
        label={t('periodLabel')}
        error={errors?.startDate || errors?.endDate}
      />
      <InputWrapper
        iconV2='input_numeric'
        label={t('valueLabel')}
        error={errors?.value || errors?.unit}>
        <InputNumber
          placeholder={t('valuePlaceholder')}
          name='value'
          value={form.value}
          onChange={onChangeNumber('value')}
          autoComplete='off'
          decimals={LCA_DECIMALS}
        />
        <Select value={form.unit} options={data} onChange={onChangeUnit('unit')} />
      </InputWrapper>
    </div>
  );
};

MetadataPage.Content = Content;
