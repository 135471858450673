import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { createDataGroupAssociationData } from '../../../../../services/api/grouping';
import { AddElementsFormData, AssociationData } from '../../../../../types/entities/grouping';

import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import SlideToggle from '../../../../ui/formComponents/slideToggle/SlideToggle';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import ElementAdded from './ElementAdded';
import './styles.scss';
import useData from './useData';

type Props = {
  id: string;
  handleAddElements: (elements: any[]) => void;
  entitiesAlreadyAdded: any[];
};
const AddElements = ({ id, handleAddElements, entitiesAlreadyAdded }: Props) => {
  const { t } = useTranslation();

  const selectedOrganization = useSelectedOrganization();

  const [formData, setFormData] = useState<AddElementsFormData>({
    organization: {
      id: '',
      name: ''
    },
    category: {
      id: '',
      name: ''
    },
    entitySelected: {
      id: '',
      name: '',
      type: '',
      organization_id: ''
    },
    entitiesSelected: [],
    errors: []
  });

  const [selectAll, setSelectAll] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { entities, organizations, loadingOrganizations, loadingEntities } = useData({
    organizationId: formData.organization.id,
    category: formData.category.id
  });

  const filteredEntities = entities.filter(
    (elem) => !entitiesAlreadyAdded.find((elem2) => elem2.entity_id === elem.id)
  );

  useEffect(() => {
    setFormData({
      ...formData,
      organization: {
        id: selectedOrganization?.id ?? '',
        name: selectedOrganization?.company_name ?? ''
      }
    });
  }, [selectedOrganization?.id]);

  const handleSubmit = async () => {
    const newErrors = [];
    if (formData.entitiesSelected.length === 0) {
      newErrors.push({
        error: 'entitiesSelected'
      });
      setFormData({ ...formData, errors: newErrors });
      return;
    }
    const body: AssociationData = {
      data_group_id: id,
      entities: {}
    };

    formData.entitiesSelected.forEach(
      (elem: { id: string; name: string; type: string; organization_id: string }) => {
        if (!body.entities[elem.organization_id]) {
          body.entities[elem.organization_id] = [
            {
              entity_id: elem.id,
              entity_name: elem.name,
              entity_type: elem.type
            }
          ];
        } else {
          body.entities[elem.organization_id].push({
            entity_id: elem.id,
            entity_name: elem.name,
            entity_type: elem.type
          });
        }
      }
    );
    setLoadingButton(true);
    const data = await createDataGroupAssociationData(body);
    handleAddElements(data);
    setLoadingButton(false);
  };

  const handleAdd = () => {
    const entitiesToAdd = [...formData.entitiesSelected];
    if (!selectAll && !formData.entitySelected.id) return;
    if (selectAll) {
      filteredEntities.forEach((elem) => {
        entitiesToAdd.push({
          id: elem.id,
          name: elem.name,
          type: formData.category.id,
          organization_id: formData.organization.id
        });
      });
    } else {
      entitiesToAdd.push(formData.entitySelected);
    }
    // Add formData.entitySelected to formData.entitiesSelected
    setFormData({
      ...formData,
      entitiesSelected: entitiesToAdd
    });
  };

  const removeElement = (id: string) => {
    // Remove element with id from entitiesSelected
    setFormData({
      ...formData,
      entitiesSelected: formData.entitiesSelected.filter((elem) => elem.id !== id)
    });
  };

  const handleChangeEntitySelected = (value: SelectOptionFormat) => {
    setFormData({
      ...formData,
      entitySelected: {
        id: value.id,
        name: value.name,
        type: formData.category.id,
        organization_id: formData.organization.id
      }
    });
  };

  const handleOnChangeCategory = (value: SelectOptionFormat) => {
    onChangeValue('category')(value);
    onChangeValue('entitySelected')({
      id: '',
      name: '',
      type: '',
      organization_id: ''
    });
    setSelectAll(false);
  };

  return (
    <div className='add-elements'>
      <FormHeader
        title={t('groupingDetail.form.title')}
        description={t('groupingDetail.form.description')}
      />
      <FormWrapper>
        {!loadingOrganizations && (
          <FormSelect
            icon='/images/icons/organization.svg'
            placeholder={''}
            label={t('groupingDetail.form.organization')}
            options={organizations}
            value={formData.organization}
            onChange={onChangeValue('organization')}
          />
        )}
        {loadingOrganizations ? (
          <CustomSkeletonLoader count={1} />
        ) : (
          <FormSelect
            icon='/images/icons/catalog.svg'
            placeholder={''}
            label={t('groupingDetail.form.category')}
            options={[
              {
                id: 'facilities',
                name: t('groupingDetail.form.facilities')
              },
              {
                id: 'vehicles',
                name: t('groupingDetail.form.vehicles')
              }
            ]}
            value={formData.category}
            onChange={handleOnChangeCategory}
            error={formData.errors.find((elem) => elem.error === 'category')}
          />
        )}
        {loadingEntities ? (
          <CustomSkeletonLoader count={1} />
        ) : (
          formData.category.id && (
            <FormElementFull className='add-element-input'>
              <div className='form-select-button'>
                <FormSelect
                  icon='/images/icons/paper.svg'
                  placeholder={''}
                  label={t('groupingDetail.form.element')}
                  options={filteredEntities}
                  value={formData.entitySelected}
                  onChange={handleChangeEntitySelected}
                  disabled={selectAll}
                />
                <Button
                  lookAndFeel='primary'
                  text={t('groupingDetail.form.add')}
                  onClick={handleAdd}
                  size='small'
                />
              </div>
              <SlideToggle
                label={t('groupingDetail.form.selectAll')}
                checked={selectAll}
                setChecked={(checked: boolean) => setSelectAll(checked)}
              />
            </FormElementFull>
          )
        )}
      </FormWrapper>
      <div className='form-selected-elements'>
        {formData.entitiesSelected.map((elem) => (
          <ElementAdded
            id={elem.id}
            name={elem.name}
            type={elem.type}
            removeElement={removeElement}
            key={elem.id}
          />
        ))}
      </div>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('groupingDetail.form.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {formData.errors.find((elem) => elem.error === 'entitiesSelected') && (
        <span className='error-text-color body2-font'>
          {t('groupingDetail.form.errorEntitiesSelected')}
        </span>
      )}
    </div>
  );
};

export default AddElements;
