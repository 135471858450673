import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { IOrganizationGoalFront } from '../../../../../../types/entities/organizationGoal';
import Button from '../../../../../ui/button/Button';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormNumber from '../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';

const emptyFormData: IOrganizationGoalFront = {
  baseYear: { id: '', name: '' },
  yearToCompare: { id: '', name: '' },
  reductionScope1: '',
  reductionScope2: '',
  reductionScope3: '',
  errors: []
};

type Props = {
  formDataFilled?: IOrganizationGoalFront;
  handleOnSubmit: (value: IOrganizationGoalFront) => Promise<void>;
};

const getYearToCompare = (from: number, max: number) => {
  return Array.from({ length: max - from }, (v, i) => {
    const number = from + i + 1;
    return { id: String(number), name: String(number) };
  });
};

const AddGoal = ({ formDataFilled, handleOnSubmit }: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IOrganizationGoalFront>(formDataFilled || emptyFormData);
  const [loading, setLoading] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const yearToCompareOptions = getYearToCompare(new Date().getFullYear(), 2050);
  const yearBaseOptions = getYearToCompare(2015, new Date().getFullYear());

  const submit = async () => {
    if (loading) return;
    setLoading(true);
    await handleOnSubmit(formData);
    setLoading(false);
  };

  return (
    <>
      <FormHeader
        title={t('modeling.objective.add.title')}
        description={t('modeling.objective.add.subtitle')}
      />
      <FormWrapper>
        <FormSelect
          icon='/images/icons/calendar50.svg'
          label={t('modeling.objective.add.baseDate')}
          tooltip={t('modeling.objective.add.baseDateTooltip')}
          value={formData.baseYear}
          options={yearBaseOptions}
          onChange={onChangeValue('baseYear')}
          placeholder={t('input.placeholderSelect')}
        />
        <FormSelect
          icon='/images/icons/calendar50.svg'
          label={t('modeling.objective.add.yearComparison')}
          placeholder={t('input.placeholderSelect')}
          options={yearToCompareOptions}
          value={formData.yearToCompare}
          onChange={onChangeValue('yearToCompare')}
        />
        <FormNumber
          icon='/images/icons/fire50.svg'
          label={t('modeling.objective.add.reductionScope1')}
          placeholder={t('input.placeholder')}
          onChange={onChangeValue('reductionScope1')}
          value={formData.reductionScope1}
          max={100}
          min={0}
        />
        <FormNumber
          icon='/images/icons/thunder50.svg'
          label={t('modeling.objective.add.reductionScope2')}
          placeholder={t('input.placeholder')}
          onChange={onChangeValue('reductionScope2')}
          value={formData.reductionScope2}
          max={100}
          min={0}
        />
        <FormNumber
          icon='/images/icons/leaf.svg'
          label={t('modeling.objective.add.reductionScope3')}
          placeholder={t('input.placeholder')}
          onChange={onChangeValue('reductionScope3')}
          value={formData.reductionScope3}
          max={100}
          min={0}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('modeling.objective.add.button')}
          loading={loading}
          onClick={submit}
        />
      </FormButtonSection>
    </>
  );
};

export default AddGoal;
