import { useTranslation } from 'react-i18next';
import Label from '../../../../ui/label/Label';
import './styles.scss';

type Props = {
  children: number;
};

const AvoidedEmissionTag = ({ children }: Props) => {
  const { t } = useTranslation();

  let text = t('avoidedEmissions.extra');
  let lookAndFeel: 'primary' | 'secondary' = 'primary';

  if (children > 0) {
    text = t('avoidedEmissions.avoided');
    lookAndFeel = 'secondary';
  }

  return (
    <div className='avoided-emission-tag'>
      <Label lookAndFeel={lookAndFeel}>{text}</Label>
    </div>
  );
};

export default AvoidedEmissionTag;
