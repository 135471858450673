import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { FormDataVehicles, VehicleFuel } from '../../../../types/entities/vehicle';
import { getUniqueItemsByProperties } from '../../../../utils/removeDuplicates';
import Checkbox from '../../../ui/formComponents/checkbox/Checkbox';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';

type Props = {
  formData: FormDataVehicles;
  formDataFilled?: FormDataVehicles;
  setFormData: (value: any) => void;
  knownVehicles: any[];
  unknownVehicles: any[];
  fuels: VehicleFuel[];
  hasConsumption?: boolean;
};
const FormProperties = ({
  formData,
  formDataFilled,
  setFormData,
  knownVehicles,
  unknownVehicles,
  fuels,
  hasConsumption = false
}: Props) => {
  const { t } = useTranslation();
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const unknownVehiclesMapped = unknownVehicles.map((elem: { type: string }) => {
    return {
      id: elem.type,
      name: t(`vehicles.${elem.type}`)
    };
  });

  const unknownVehiclesFiltered = getUniqueItemsByProperties(unknownVehiclesMapped, ['id']);

  const onChangeNoModel = (value: boolean) => {
    setFormData((prev: any) => ({
      ...prev,
      noModel: value,
      model: value ? { id: '', name: '' } : prev.model,
      errors: value ? prev.errors.filter((elem: ErrorType) => elem.error !== 'model') : prev.errors
    }));
  };

  const onChangeUnknownType = async (value: SelectOptionFormat) => {
    setFormData((prev: any) => ({
      ...prev,
      unknownType: value,
      fuel: { id: '', name: '' },
      errors: prev.errors.filter((elem: ErrorType) => elem.error !== 'unknownType')
    }));
  };

  const foundKnownVehicle = formData.model.id
    ? knownVehicles.find((elem) => elem.model === formData.model.id)
    : null;

  const vehicleFuelFilters = getUniqueItemsByProperties(knownVehicles, ['vehicle_motor_type']).map(
    (elem: any) => ({
      id: elem.vehicle_motor_type,
      name: t(`vehicle_fuels.${elem.vehicle_motor_type}`)
    })
  );

  const gearboxFilters = getUniqueItemsByProperties(knownVehicles, ['gearbox_type']).map(
    (elem: any) => ({
      id: elem.gearbox_type,
      name: t(`vehicles.${elem.gearbox_type}`)
    })
  );

  const knownVehiclesFiltered = knownVehicles
    .filter((elem) => elem.brand === formData.brand.id)
    .filter((elem) =>
      formData.vehicleFuelFilter.id
        ? elem.vehicle_motor_type === formData.vehicleFuelFilter.id
        : true
    )
    .filter((elem) =>
      formData.gearboxFilter.id ? elem.gearbox_type === formData.gearboxFilter.id : true
    )
    .map((elem: { model: string }) => {
      return {
        id: elem.model,
        name: elem.model
      };
    });

  return (
    <FormWrapper>
      {formData.brand.id && (
        <>
          {!formData.noModel && !formData.noBrand && (
            <>
              <FormSelect
                icon='/images/icons/plug.svg'
                label={t('vehicles.vehicleFuelFilter')}
                placeholder={t('vehicles.fuelPlaceholder')}
                options={vehicleFuelFilters}
                value={
                  formData.vehicleFuelFilter.id
                    ? {
                        id: formData.vehicleFuelFilter.id,
                        name: t(`vehicle_fuels.${formData.vehicleFuelFilter.id}`)
                      }
                    : foundKnownVehicle
                    ? {
                        id: foundKnownVehicle?.vehicle_motor_type,
                        name: t(`vehicle_fuels.${foundKnownVehicle?.vehicle_motor_type}`)
                      }
                    : { id: '', name: '' }
                }
                onChange={onChangeValue('vehicleFuelFilter')}
                disabled={!!formDataFilled}
              />
              <FormSelect
                icon='/images/icons/ruler.svg'
                label={t('vehicles.gearboxFilter')}
                placeholder={t('vehicles.gearboxPlaceholder')}
                options={gearboxFilters}
                value={
                  formData.gearboxFilter.id
                    ? {
                        id: formData?.gearboxFilter.id,
                        name: t(`vehicles.${formData?.gearboxFilter.id}`)
                      }
                    : foundKnownVehicle
                    ? {
                        id: foundKnownVehicle?.gearbox_type,
                        name: t(`vehicles.${foundKnownVehicle?.gearbox_type}`)
                      }
                    : { id: '', name: '' }
                }
                onChange={onChangeValue('gearboxFilter')}
                disabled={!!formDataFilled}
              />
            </>
          )}
          <FormElementFull>
            <FormSelect
              icon={'/images/icons/tesla.svg'}
              placeholder={t('vehicles.modelPlaceholder')}
              label={t('vehicles.knowModel')}
              value={formData.model}
              onChange={onChangeValue('model')}
              options={knownVehiclesFiltered}
              disabled={!!formDataFilled || formData.noModel}
              error={formData.errors.find((elem) => elem.error === 'model')}
            />
            <div className='checkbox-margin'>
              <Checkbox
                text={t('vehicles.notKnowModel')}
                onChange={onChangeNoModel}
                selected={formData.noModel}
                disabled={!!formDataFilled}
              />
            </div>
          </FormElementFull>
        </>
      )}
      {(formData.noBrand || formData.noModel) && (
        <>
          <FormSelect
            icon={'/images/icons/tesla.svg'}
            placeholder={t('vehicles.typePlaceholder')}
            label={t('vehicles.type')}
            value={formData.unknownType}
            onChange={onChangeUnknownType}
            options={unknownVehiclesFiltered}
            disabled={hasConsumption}
            error={formData.errors.find((elem) => elem.error === 'unknownType')}
          />
          <FormSelect
            icon={'/images/icons/plug.svg'}
            placeholder={t('vehicles.fuelPlaceholder')}
            label={t('vehicles.fuel')}
            value={formData.fuel}
            onChange={onChangeValue('fuel')}
            options={
              fuels
                ? fuels.map((elem) => {
                    return {
                      id: elem.id,
                      name: t(`vehicle_fuels.${elem.fuel}`)
                    };
                  })
                : []
            }
            disabled={hasConsumption}
            error={formData.errors.find((elem) => elem.error === 'fuel')}
          />
        </>
      )}
    </FormWrapper>
  );
};

export default FormProperties;
