import { Status } from '../../../types/utilsEnums/status';
import DisabledLabel from '../statusLabels/disabledLabel/DisabledLabel';
import ErrorLabel from '../statusLabels/errorLabel/ErrorLabel';
import PendingLabel from '../statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../statusLabels/successLabel/SuccessLabel';
import WarningLabel from '../statusLabels/warningLabel/WarningLabel';

export const TAG = {
  [Status.ACTIVE]: SuccessLabel,
  [Status.PENDING]: PendingLabel,
  [Status.ERROR]: ErrorLabel,
  [Status.FILE_ERROR]: WarningLabel,
  [Status.IN_PROGRESS]: WarningLabel,
  [Status.LOADING]: PendingLabel,
  [Status.BLOCKED]: DisabledLabel,
  [Status.IN_QUEUE]: PendingLabel,
  [Status.SUCCESS]: SuccessLabel,
  [Status.DELETING]: PendingLabel,
  [Status.DELETED]: DisabledLabel,
  [Status.INTERNAL_ERROR]: ErrorLabel,
  [Status.IN_REVIEW]: WarningLabel
} as const;

export const STATUS_ICON_MAP = {
  [Status.DELETED]: 'trash_check',
  [Status.DELETING]: 'trash',
  [Status.INTERNAL_ERROR]: 'information'
} as const;
