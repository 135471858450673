import { useTranslation } from 'react-i18next';
import './styles.scss';
import Icon from '../../ui/icon/Icon';
import { createContext, useContext } from 'react';
import { IFilter } from '../../../utils/url';

type FiltersProps = {
  children?: React.ReactNode;
};

type FiltersContextProps = {
  filters: IFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
};

type FiltersMenuContext = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type ProviderProps = FiltersContextProps &
  FiltersMenuContext & {
    children: React.ReactNode;
  };

const FiltersContext = createContext<FiltersContextProps | null>(null);

const FiltersMenuContext = createContext<FiltersMenuContext | null>(null);

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within a FiltersContext');
  }
  return context;
};

export const useFiltersMenuContext = () => {
  const context = useContext(FiltersMenuContext);
  if (!context) {
    throw new Error('useFiltersMenu must be used within a FiltersMenuContext');
  }
  return context;
};

const FiltersProvider = ({ children, open, setOpen, filters, setFilters }: ProviderProps) => {
  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>
      <FiltersMenuContext.Provider value={{ open, setOpen }}>
        {children}
      </FiltersMenuContext.Provider>
    </FiltersContext.Provider>
  );
};

type FiltersProviderOnlyProps = {
  children: React.ReactNode;
  filters: IFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
};

const FiltersProviderOnly = ({ children, filters, setFilters }: FiltersProviderOnlyProps) => {
  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>
  );
};

FiltersProvider.FiltersOnly = FiltersProviderOnly;

const FiltersMenu = ({ children }: FiltersProps) => {
  const { t } = useTranslation();

  const { open, setOpen } = useFiltersMenuContext();

  return (
    <div className={`filters-wrapper-left-side ${open ? 'in' : ''}`}>
      <div className='filters__header'>
        <h4 className='headline4-font'>{t('general.filters.title')}</h4>
        <Icon icon='close' onClick={() => setOpen(false)} color='gray' />
      </div>
      <div className={`filters ${open ? 'in' : ''}`}>{children}</div>
    </div>
  );
};

export const Filters = {
  Menu: FiltersMenu,
  Root: FiltersProvider
};
