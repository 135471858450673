export const questions = {
  children: [
    {
      answer: 'car',
      question: 'Vehicle size',
      children: [
        {
          answer: 'diesel',
          result: 'transport, passenger car, small size, diesel, euro 5'
        },
        {
          answer: 'natural_gas',
          result: 'transport, passenger car, small size, natural gas, euro 5'
        },
        {
          answer: 'petrol',
          result: 'transport, passenger car, small size, petrol, euro 5'
        },
        {
          answer: 'lpg',
          result: 'transport, passenger car, small size, liquefied petroleum gas, euro 5'
        },
        {
          answer: 'electric',
          question: 'Renewable energy',
          children: [
            {
              answer: 'yes',
              result:
                'transport, passenger car, electric, electricity from renewable energy products - dcycle'
            },
            {
              answer: 'no',
              result: 'transport, passenger car, electric'
            },
            {
              answer: 'do_not_know',
              result: 'transport, passenger car, electric'
            }
          ]
        },
        {
          answer: 'electric_renewable'
        },
        {
          answer: 'hybrid',
          result: 'transport, passenger car, hybrid - dcycle'
        },
        { answer: 'do_not_know', result: 'transport, passenger car' }
      ]
    },
    {
      answer: 'metro',
      question: 'Vehicle type',
      children: [
        {
          answer: 'electric',
          question: 'Renewable energy',
          children: [
            {
              answer: 'yes',
              result:
                'transport, passenger train, urban, electricity from renewable energy products - dcycle'
            },
            { answer: 'no', result: 'transport, passenger train, urban - dcycle' },
            { answer: 'do_not_know', result: 'transport, passenger train, urban - dcycle' }
          ]
        },
        {
          answer: 'electric_renewable'
        },
        { answer: 'do_not_know', result: 'transport, passenger train, urban - dcycle' }
      ]
    },
    {
      answer: 'train',
      question: 'Vehicle type',
      children: [
        {
          answer: 'electric',
          question: 'Renewable energy',
          children: [
            {
              answer: 'yes',
              result:
                'transport, passenger train, electricity from renewable energy products - dcycle'
            },
            { answer: 'no', result: 'transport, passenger train' },
            { answer: 'do_not_know', result: 'transport, passenger train' }
          ]
        },
        {
          answer: 'electric_renewable'
        },
        { answer: 'do_not_know', result: 'transport, passenger train' }
      ]
    },
    {
      answer: 'trolleybus',
      question: 'Vehicle type',
      children: [
        {
          answer: 'electric',
          question: 'Renewable energy',
          children: [
            {
              answer: 'yes',
              result: 'transport, trolleybus, electricity from renewable energy products - dcycle'
            },
            { answer: 'no', result: 'transport, trolleybus' },
            { answer: 'do_not_know', result: 'transport, trolleybus' }
          ]
        },
        {
          answer: 'electric_renewable'
        },
        { answer: 'do_not_know', result: 'transport, trolleybus' }
      ]
    },
    {
      answer: 'bus',
      question: 'Vehicle type',
      children: [
        { answer: 'diesel', result: 'transport, regular bus' },
        { answer: 'natural_gas', result: 'transport, regular bus' },
        { answer: 'petrol', result: 'transport, regular bus' },
        { answer: 'lpg', result: 'transport, regular bus' },
        { answer: 'do_not_know', result: 'transport, regular bus' }
      ]
    },
    {
      answer: 'motorbike',
      question: 'Vehicle type',
      children: [
        { answer: 'diesel', result: 'transport, passenger, motor scooter' },
        {
          answer: 'natural_gas',
          result: 'transport, passenger, motor scooter'
        },
        { answer: 'petrol', result: 'transport, passenger, motor scooter' },
        { answer: 'lpg', result: 'transport, passenger, motor scooter' },
        {
          answer: 'electric',
          question: 'Renewable energy',
          children: [
            {
              answer: 'yes',
              result:
                'transport, passenger, electric scooter, electricity from renewable energy products - dcycle'
            },
            {
              answer: 'no',
              result: 'transport, passenger, electric scooter'
            },
            {
              answer: 'do_not_know',
              result: 'transport, passenger, electric scooter'
            }
          ]
        },
        {
          answer: 'electric_renewable'
        },
        {
          answer: 'do_not_know',
          result: 'transport, passenger, motor scooter'
        }
      ]
    },
    {
      answer: 'aircraft',
      result: ''
    }
  ]
};
