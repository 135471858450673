import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../ui/button/Button';
import ErrorPageContainer from './ErrorPageContainer/ErrorPageContainer';
import './styles.scss';

type Props = {
  text: string;
  goBack?: string;
};

const ErrorPage = ({ text, goBack }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(goBack ?? '');
  };

  return (
    <>
      <img className='error-page__logo' src='/images/logoBlack.svg' alt='logo' />
      <ErrorPageContainer>
        <img className='error-page__main-image' src='/images/woman.svg' alt='logo' />
        <span className='headline1-font'>{t('errorPage.ups')}</span>
        <span className='error-page__text subtitle2-font'>{text}</span>
        {goBack ? (
          <div className='error-page__button-wrapper'>
            <Button lookAndFeel={'primary'} onClick={handleOnClick} text={t('errorPage.goBack')} />
          </div>
        ) : (
          <></>
        )}
      </ErrorPageContainer>
    </>
  );
};

export default ErrorPage;
