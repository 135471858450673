import { useContext, useEffect, useState } from 'react';
import { getMemberships } from '../../../services/api/membership';
import { getTags } from '../../../services/api/tasks';
import { User } from '../../../types/entities/user';
import { UserContext } from '../../../context/userContext';

const useGetData = () => {
  const user = useContext(UserContext);
  const [users, setUsers] = useState<SelectOptionFormat[]>([]);
  const [tags, setTags] = useState<SelectOptionFormat[]>([]);

  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    const memberships = await getMemberships(false, user?.email);
    const users = memberships.items
      .filter((elem: User) => elem.first_name)
      .map((membership: User) => ({
        id: membership.id,
        name: membership.first_name + ' ' + membership.last_name
      }));
    setUsers(users);
  };

  const fetchTags = async () => {
    const data = await getTags();
    const tags = data.map((elem: string) => ({
      id: elem,
      name: elem
    }));
    setTags(tags);
  };

  const fetchData = async () => {
    setLoading(true);
    await fetchUsers();
    await fetchTags();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { users, tags, loading };
};

export default useGetData;
