import Tooltip from '../../tooltip/Tooltip';
import '../styles.scss';

type OwnProps = {
  stepNumber?: number;
  title?: string;
  tooltip?: string;
};
type Props = OwnProps;

const StepHeader = ({ stepNumber, title, tooltip }: Props) => {
  const visibility = stepNumber ? 'visible' : 'hidden';

  return (
    <div className='step-header'>
      {visibility === 'visible' && (
        <span className='step-number highlight-bg-color span-font'>{stepNumber}</span>
      )}
      <h3 className='headline3-font on-light-text-color headline-employees-step'>
        {title} {tooltip && <Tooltip text={tooltip} />}
      </h3>
    </div>
  );
};

export default StepHeader;
