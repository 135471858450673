import apiFetch from '../../utils/apiFetch';

export const getNotifications = async () => {
  try {
    // query params
    const queryParams = {
      // 26/03/2024 in timestamp
      // start_date: convertDateFrontToBackTimestamp('26/03/2024')
    };
    const response = await apiFetch('GET', '/organizations/notifications', null, null, queryParams);

    return response.data;
  } catch (error) {
    return null;
  }
};
