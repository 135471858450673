import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useModal from '../hooks/useModal';
import useOptions from './hooks/useOptions';

export const ModalC7 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });

  const { formData, setFormData, loading, patchCriteria, loadingButton, errorPatch } = useModal([
    'baseYear'
  ]);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const options = useOptions();

  return (
    <>
      <FormHeader title={t('title')} />
      {loading && (
        <CustomSkeletonLoader
          count={1}
          style={{
            marginBottom: '2rem'
          }}
        />
      )}

      {!loading && (
        <FormWrapper>
          <FormSelect
            tooltip={t('tooltipModalC7')}
            label={t('c7Modal')}
            iconV2='calendar'
            onChange={onChangeValue('baseYear')}
            value={
              formData.baseYear || {
                id: '',
                name: ''
              }
            }
            options={options}
            error={formData.errors.find((error) => error.error === 'baseYear')}
          />
        </FormWrapper>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('save')}
          loading={loadingButton}
          size='small'
          onClick={() => patchCriteria(formData)}
        />
      </FormButtonSection>
      <ErrorText>{errorPatch && errorPatch.description}</ErrorText>
    </>
  );
};
