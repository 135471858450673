import { useState } from 'react';
import CheckboxMultiple from '../formComponents/checkboxMultiple/CheckboxMultiple';
import TooltipCard from '../tooltip/TooltipCard';
import './styles.scss';

type CardSize = 'small' | 'medium' | 'big';

type Props = {
  card: {
    icon: string;
    id: string;
    title: string;
    disabled?: boolean;
    tooltip?: string;
    checkbox?: boolean;
  };
  onClickCard: (id: string) => void;
  selected: boolean;
  size?: CardSize;
  error?: ErrorType;
  tooltip?: string;
};

function SelectCard({ card, onClickCard, selected, size, error, tooltip }: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleOnClickCard = () => {
    if (!card.disabled) {
      onClickCard(card.id);
    }
  };

  const handleShowTooltip = () => {
    setShowTooltip(true);
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={`select-card ${selected ? 'select-card--selected' : ''} ${size} ${
        error ? 'error-border-color' : ''
      } ${card.disabled ? 'select-card--disabled' : ''}`}
      key={card.id}
      onClick={handleOnClickCard}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}>
      {card.tooltip && showTooltip && <TooltipCard text={card.tooltip} />}
      {card.icon && <img src={card.icon} alt={card.id} />}
      {card.checkbox ? (
        <div className='checkbox-wrapper'>
          <CheckboxMultiple
            id={card.title}
            type='square'
            selected={selected}
            colour={!selected ? 'gray' : undefined}
            onSelect={(id: string) => id}
            onRemove={(id: string) => id}
          />
          <span
            className={`${
              size === 'small' ? 'select-card-small-font' : 'select-card-font'
            } on-light-text-color`}>
            {card.title}
          </span>
        </div>
      ) : (
        <span
          className={`${
            size === 'small' ? 'select-card-small-font' : 'select-card-font'
          } on-light-text-color`}>
          {card.title}
        </span>
      )}
    </div>
  );
}

export default SelectCard;
