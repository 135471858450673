import type { DropdownOptionAPI } from 'nuvo-react';
import { useTranslation } from 'react-i18next';

type NuvoCountry = DropdownOptionAPI & {
  type: 'string';
};

export const useNuvoCountries = () => {
  const { t } = useTranslation();

  const countriesNuvo: NuvoCountry[] = [
    {
      label: t('countries.aruba'),
      value: 'aw',
      alternativeMatches: ['Aruba', 'AW'],
      type: 'string'
    },
    {
      label: t('countries.afghanistan'),
      value: 'af',
      alternativeMatches: ['Afganistán', 'Afghanistan', 'AF'],
      type: 'string'
    },
    {
      label: t('countries.angola'),
      value: 'ao',
      alternativeMatches: ['Angola', 'AO'],
      type: 'string'
    },
    {
      label: t('countries.anguilla'),
      value: 'ai',
      alternativeMatches: ['Anguila', 'Anguilla', 'AI'],
      type: 'string'
    },
    {
      label: t('countries.alandIslands'),
      value: 'ax',
      alternativeMatches: ['Islas Aland', 'Aland Islands', 'AX'],
      type: 'string'
    },
    {
      label: t('countries.albania'),
      value: 'al',
      alternativeMatches: ['Albania', 'AL'],
      type: 'string'
    },
    {
      label: t('countries.andorra'),
      value: 'ad',
      alternativeMatches: ['Andorra', 'AD'],
      type: 'string'
    },
    {
      label: t('countries.unitedArabEmirates'),
      value: 'ae',
      alternativeMatches: ['Emiratos Árabes Unidos', 'United Arab Emirates', 'AE'],
      type: 'string'
    },
    {
      label: t('countries.argentina'),
      value: 'ar',
      alternativeMatches: ['Argentina', 'AR'],
      type: 'string'
    },
    {
      label: t('countries.armenia'),
      value: 'am',
      alternativeMatches: ['Armenia', 'AM'],
      type: 'string'
    },
    {
      label: t('countries.americanSamoa'),
      value: 'as',
      alternativeMatches: ['Samoa Americana', 'American Samoa', 'AS'],
      type: 'string'
    },
    {
      label: t('countries.antarctica'),
      value: 'aq',
      alternativeMatches: ['Antártida', 'Antarctica', 'AQ'],
      type: 'string'
    },
    {
      label: t('countries.frenchSouthernTerritories'),
      value: 'tf',
      alternativeMatches: [
        'Territorios Franceses del Sur',
        'Tierras Australes y Antarticas Francesas',
        'French Southern Territories',
        'TF'
      ],
      type: 'string'
    },
    {
      label: t('countries.antiguaAndBarbuda'),
      value: 'ag',
      alternativeMatches: ['Antigua y Barbuda', 'Antigua and Barbuda', 'AG'],
      type: 'string'
    },
    {
      label: t('countries.australia'),
      value: 'au',
      alternativeMatches: ['Australia', 'AU'],
      type: 'string'
    },
    {
      label: t('countries.austria'),
      value: 'at',
      alternativeMatches: ['Austria', 'AT'],
      type: 'string'
    },
    {
      label: t('countries.azerbaijan'),
      value: 'az',
      alternativeMatches: ['Azerbaiyán', 'Azerbaijan', 'AZ'],
      type: 'string'
    },
    {
      label: t('countries.bosniaAndHerzegovina'),
      value: 'ba',
      alternativeMatches: ['Bosnia y Hercegovina', 'Bosnia and Herzegovina', 'BA'],
      type: 'string'
    },
    {
      label: t('countries.burundi'),
      value: 'bi',
      alternativeMatches: ['Burundi', 'BI'],
      type: 'string'
    },
    {
      label: t('countries.belgium'),
      value: 'be',
      alternativeMatches: ['Bélgica', 'Belgium', 'BE'],
      type: 'string'
    },
    {
      label: t('countries.benin'),
      value: 'bj',
      alternativeMatches: ['Benín', 'Benin', 'BJ'],
      type: 'string'
    },
    {
      label: t('countries.saintBarthelemy'),
      value: 'bl',
      alternativeMatches: ['Santa Barthelemy', 'Saint Barthélemy', 'BL'],
      type: 'string'
    },
    {
      label: t('countries.bonaireSintEustatiusYSaba'),
      value: 'bq',
      alternativeMatches: [
        'Bonaire, Sint Eustatius y Saba',
        'Bonaire, Sint Eustatius and Saba',
        'BQ'
      ],
      type: 'string'
    },
    {
      label: t('countries.burkinaFaso'),
      value: 'bf',
      alternativeMatches: ['Burkina Faso', 'BF'],
      type: 'string'
    },
    {
      label: t('countries.bangladesh'),
      value: 'bd',
      alternativeMatches: ['Bangladesh', 'BD'],
      type: 'string'
    },
    {
      label: t('countries.bulgaria'),
      value: 'bg',
      alternativeMatches: ['Bulgaria', 'BG'],
      type: 'string'
    },
    {
      label: t('countries.bahrain'),
      value: 'bh',
      alternativeMatches: ['Bahrein', 'Bahrain', 'BH'],
      type: 'string'
    },
    {
      label: t('countries.bahamas'),
      value: 'bs',
      alternativeMatches: ['Bahamas', 'BS'],
      type: 'string'
    },
    {
      label: t('countries.bosniaAndHerzegovina'),
      value: 'ba',
      alternativeMatches: ['Bosnia y Hercegovina', 'Bosnia and Herzegovina', 'BA'],
      type: 'string'
    },
    {
      label: t('countries.saintBarthelemy'),
      value: 'bl',
      alternativeMatches: ['Santa Barthelemy', 'Saint Barthélemy', 'BL'],
      type: 'string'
    },
    {
      label: t('countries.bolivia'),
      value: 'bo',
      alternativeMatches: ['Bolivia', 'BO'],
      type: 'string'
    },
    {
      label: t('countries.brazil'),
      value: 'br',
      alternativeMatches: ['Brasil', 'Brazil', 'BR'],
      type: 'string'
    },
    {
      label: t('countries.barbados'),
      value: 'bb',
      alternativeMatches: ['Barbados', 'BB'],
      type: 'string'
    },
    {
      label: t('countries.bruneiDarussalam'),
      value: 'bn',
      alternativeMatches: ['Brunéi Darussalam', 'Brunei Darussalam', 'BN'],
      type: 'string'
    },
    {
      label: t('countries.canada'),

      value: 'ca',
      alternativeMatches: ['Canadá', 'Canada', 'CA'],
      type: 'string'
    },
    {
      label: t('countries.chile'),
      value: 'cl',
      alternativeMatches: ['Chile', 'CL'],
      type: 'string'
    },
    {
      label: t('countries.china'),
      value: 'cn',
      alternativeMatches: ['China', 'CN'],
      type: 'string'
    },
    {
      label: t('countries.cameroon'),
      value: 'cm',
      alternativeMatches: ['Camerún', 'Cameroon', 'CM'],
      type: 'string'
    },
    {
      label: t('countries.congo'),
      value: 'cg',
      alternativeMatches: ['Congo', 'CG'],
      type: 'string'
    },
    {
      label: t('countries.colombia'),
      value: 'co',
      alternativeMatches: ['Colombia', 'CO'],
      type: 'string'
    },
    {
      label: t('countries.costaRica'),
      value: 'cr',
      alternativeMatches: ['Costa Rica', 'CR'],
      type: 'string'
    },
    {
      label: t('countries.cuba'),
      value: 'cu',
      alternativeMatches: ['Cuba', 'CU'],
      type: 'string'
    },
    {
      label: t('countries.capeVerde'),
      value: 'cv',
      alternativeMatches: ['Cabo Verde', 'Cape Verde', 'CV'],
      type: 'string'
    },
    {
      label: t('countries.curaçao'),
      value: 'cw',
      alternativeMatches: ['Curazao', 'Curaçao', 'CW'],
      type: 'string'
    },
    {
      label: t('countries.christmasIsland'),
      value: 'cx',
      alternativeMatches: ['Isla de Navidad', 'Christmas Island', 'CX'],
      type: 'string'
    },
    {
      label: t('countries.caymanIslands'),
      value: 'ky',
      alternativeMatches: ['Islas Caimán', 'Cayman Islands', 'KY'],
      type: 'string'
    },
    {
      label: t('countries.cyprus'),
      value: 'cy',
      alternativeMatches: ['Chipre', 'Cyprus', 'CY'],
      type: 'string'
    },
    {
      label: t('countries.czechRepublic'),
      value: 'cz',
      alternativeMatches: ['República Checa', 'Czech Republic', 'CZ'],
      type: 'string'
    },
    {
      label: t('countries.germany'),
      value: 'de',
      alternativeMatches: ['Alemania', 'Germany', 'DE'],
      type: 'string'
    },
    {
      label: t('countries.djibouti'),
      value: 'dj',
      alternativeMatches: ['Yibuti', 'Djibouti', 'DJ'],
      type: 'string'
    },
    {
      label: t('countries.dominica'),
      value: 'dm',
      alternativeMatches: ['Dominica', 'DM'],
      type: 'string'
    },
    {
      label: t('countries.denmark'),
      value: 'dk',
      alternativeMatches: ['Dinamarca', 'Denmark', 'DK'],
      type: 'string'
    },
    {
      label: t('countries.dominicanRepublic'),
      value: 'do',
      alternativeMatches: ['República Dominicana', 'Dominican Republic', 'DO'],
      type: 'string'
    },
    {
      label: t('countries.algeria'),
      value: 'dz',
      alternativeMatches: ['Argelia', 'Algeria', 'DZ'],
      type: 'string'
    },
    {
      label: t('countries.ecuador'),
      value: 'ec',
      alternativeMatches: ['Ecuador', 'EC'],
      type: 'string'
    },
    {
      label: t('countries.egypt'),
      value: 'eg',
      alternativeMatches: ['Egipto', 'Egypt', 'EG'],
      type: 'string'
    },
    {
      label: t('countries.eritrea'),
      value: 'er',
      alternativeMatches: ['Eritrea', 'ER'],
      type: 'string'
    },
    {
      label: t('countries.westernSahara'),
      value: 'eh',
      alternativeMatches: ['Sahara Occidental', 'Western Sahara', 'EH'],
      type: 'string'
    },
    {
      label: t('countries.spain'),
      value: 'es',
      alternativeMatches: ['España', 'Spain', 'ES'],
      type: 'string'
    },
    {
      label: t('countries.estonia'),
      value: 'ee',
      alternativeMatches: ['Estonia', 'EE'],
      type: 'string'
    },
    {
      label: t('countries.ethiopia'),
      value: 'et',
      alternativeMatches: ['Etiopía', 'Ethiopia', 'ET'],
      type: 'string'
    },
    {
      label: t('countries.finland'),
      value: 'fi',
      alternativeMatches: ['Finlandia', 'Finland', 'FI'],
      type: 'string'
    },
    {
      label: t('countries.fiji'),
      value: 'fj',
      alternativeMatches: ['Fiyi', 'Fiji', 'FJ'],
      type: 'string'
    },
    {
      label: t('countries.falklandIslands'),
      value: 'fk',
      alternativeMatches: ['Islas Malvinas', 'Falkland Islands', 'FK'],
      type: 'string'
    },
    {
      label: t('countries.micronesia'),
      value: 'fm',
      alternativeMatches: ['Micronesia', 'FM'],
      type: 'string'
    },
    {
      label: t('countries.faroeIslands'),
      value: 'fo',
      alternativeMatches: ['Islas Feroe', 'Faroe Islands', 'FO'],
      type: 'string'
    },
    {
      label: t('countries.france'),
      value: 'fr',
      alternativeMatches: ['Francia', 'France', 'FR'],
      type: 'string'
    },
    {
      label: t('countries.gabon'),
      value: 'ga',
      alternativeMatches: ['Gabón', 'Gabon', 'GA'],
      type: 'string'
    },
    {
      label: t('countries.unitedKingdom'),
      value: 'gb',
      alternativeMatches: [
        'Reino Unido',
        'United Kingdom',
        'Great Britain',
        'England',
        'Inglaterra',
        'UK',
        'GB'
      ],
      type: 'string'
    },
    {
      label: t('countries.grenada'),
      value: 'gd',
      alternativeMatches: ['Grenada', 'GD'],
      type: 'string'
    },
    {
      label: t('countries.georgia'),
      value: 'ge',
      alternativeMatches: ['Georgia', 'GE'],
      type: 'string'
    },
    {
      label: t('countries.frenchGuiana'),
      value: 'gf',
      alternativeMatches: ['Guayana Francesa', 'French Guiana', 'GF'],
      type: 'string'
    },
    {
      label: t('countries.guernsey'),
      value: 'gg',
      alternativeMatches: ['Guernsey', 'GG'],
      type: 'string'
    },
    {
      label: t('countries.ghana'),
      value: 'gh',
      alternativeMatches: ['Ghana', 'GH'],
      type: 'string'
    },
    {
      label: t('countries.gibraltar'),
      value: 'gi',
      alternativeMatches: ['Gibraltar', 'GI'],
      type: 'string'
    },
    {
      label: t('countries.greenland'),
      value: 'gl',
      alternativeMatches: ['Groenlandia', 'Greenland', 'GL'],
      type: 'string'
    },
    {
      label: t('countries.gambia'),
      value: 'gm',
      alternativeMatches: ['Gambia', 'GM'],
      type: 'string'
    },
    {
      label: t('countries.guinea'),
      value: 'gn',
      alternativeMatches: ['Guinea', 'GN'],
      type: 'string'
    },
    {
      label: t('countries.guadeloupe'),
      value: 'gp',
      alternativeMatches: ['Guadalupe', 'Guadeloupe', 'GP'],
      type: 'string'
    },
    {
      label: t('countries.equatorialGuinea'),
      value: 'gq',
      alternativeMatches: ['Guinea Ecuatorial', 'Equatorial Guinea', 'GQ'],
      type: 'string'
    },
    {
      label: t('countries.greece'),
      value: 'gr',
      alternativeMatches: ['Grecia', 'Greece', 'GR'],
      type: 'string'
    },
    {
      label: t('countries.southGeorgiaAndTheSouthSandwichIslands'),
      value: 'gs',
      alternativeMatches: [
        'Georgia del Sur y las Islas Sandwich del Sur',
        'South Georgia and the South Sandwich Islands',
        'GS'
      ],
      type: 'string'
    },
    {
      label: t('countries.guatemala'),
      value: 'gt',
      alternativeMatches: ['Guatemala', 'GT'],
      type: 'string'
    },
    {
      label: t('countries.guam'),
      value: 'gu',
      alternativeMatches: ['Guam', 'GU'],
      type: 'string'
    },
    {
      label: t('countries.guineaBissau'),
      value: 'gw',
      alternativeMatches: ['Guinea-Bisáu', 'Guinea-Bissau', 'GW'],
      type: 'string'
    },
    {
      label: t('countries.guyana'),
      value: 'gy',
      alternativeMatches: ['Guyana', 'GY'],
      type: 'string'
    },
    {
      label: t('countries.hongKong'),
      value: 'hk',
      alternativeMatches: ['Hong Kong', 'HK'],
      type: 'string'
    },
    {
      label: t('countries.heardIslandAndMcDonaldIslands'),
      value: 'hm',
      alternativeMatches: ['Islas Heard y McDonald', 'Heard Island and McDonald Islands', 'HM'],
      type: 'string'
    },
    {
      label: t('countries.honduras'),
      value: 'hn',
      alternativeMatches: ['Honduras', 'HN'],
      type: 'string'
    },
    {
      label: t('countries.croatia'),
      value: 'hr',
      alternativeMatches: ['Croacia', 'Croatia', 'HR'],
      type: 'string'
    },
    {
      label: t('countries.haiti'),
      value: 'ht',
      alternativeMatches: ['Haití', 'Haiti', 'HT'],
      type: 'string'
    },
    {
      label: t('countries.hungary'),
      value: 'hu',
      alternativeMatches: ['Hungría', 'Hungary', 'HU'],
      type: 'string'
    },
    {
      label: t('countries.indonesia'),
      value: 'id',
      alternativeMatches: ['Indonesia', 'ID'],
      type: 'string'
    },
    {
      label: t('countries.ireland'),
      value: 'ie',
      alternativeMatches: ['Irlanda', 'Ireland', 'IE'],
      type: 'string'
    },
    {
      label: t('countries.israel'),
      value: 'il',
      alternativeMatches: ['Israel', 'IL'],
      type: 'string'
    },
    {
      label: t('countries.isleOfMan'),
      value: 'im',
      alternativeMatches: ['Isla de Man', 'Isle of Man', 'IM'],
      type: 'string'
    },
    {
      label: t('countries.india'),
      value: 'in',
      alternativeMatches: ['India', 'IN'],
      type: 'string'
    },
    {
      label: t('countries.britishIndianOceanTerritory'),
      value: 'io',
      alternativeMatches: [
        'Territorio Británico del Océano Índico',
        'British Indian Ocean Territory',
        'IO'
      ],
      type: 'string'
    },
    {
      label: t('countries.iraq'),
      value: 'iq',
      alternativeMatches: ['Iraq', 'IQ'],
      type: 'string'
    },
    {
      label: t('countries.iran'),
      value: 'ir',
      alternativeMatches: ['Irán', 'Iran', 'IR'],
      type: 'string'
    },
    {
      label: t('countries.iceland'),
      value: 'is',
      alternativeMatches: ['Islandia', 'Iceland', 'IS'],
      type: 'string'
    },
    {
      label: t('countries.italy'),
      value: 'it',
      alternativeMatches: ['Italia', 'Italy', 'IT'],
      type: 'string'
    },
    {
      label: t('countries.jersey'),
      value: 'je',
      alternativeMatches: ['Jersey', 'JE'],
      type: 'string'
    },
    {
      label: t('countries.jamaica'),
      value: 'jm',
      alternativeMatches: ['Jamaica', 'JM'],
      type: 'string'
    },
    {
      label: t('countries.jordan'),
      value: 'jo',
      alternativeMatches: ['Jordania', 'Jordan', 'JO'],
      type: 'string'
    },
    {
      label: t('countries.japan'),
      value: 'jp',
      alternativeMatches: ['Japón', 'Japan', 'JP'],
      type: 'string'
    },
    {
      label: t('countries.kenya'),
      value: 'ke',
      alternativeMatches: ['Kenia', 'Kenya', 'KE'],
      type: 'string'
    },
    {
      label: t('countries.kyrgyzstan'),
      value: 'kg',
      alternativeMatches: ['Kirguistán', 'Kyrgyzstan', 'KG'],
      type: 'string'
    },
    {
      label: t('countries.cambodia'),
      value: 'kh',
      alternativeMatches: ['Camboya', 'Cambodia', 'KH'],
      type: 'string'
    },
    {
      label: t('countries.kiribati'),
      value: 'ki',
      alternativeMatches: ['Kiribati', 'KI'],
      type: 'string'
    },
    {
      label: t('countries.comoros'),
      value: 'km',
      alternativeMatches: ['Comoras', 'Comoros', 'KM'],
      type: 'string'
    },
    {
      label: t('countries.saintKittsAndNevis'),
      value: 'kn',
      alternativeMatches: ['San Cristóbal y Nieves', 'Saint Kitts and Nevis', 'KN'],
      type: 'string'
    },
    {
      label: t('countries.northKorea'),
      value: 'kp',
      alternativeMatches: ['Corea del Norte', 'North Korea', 'KP'],
      type: 'string'
    },
    {
      label: t('countries.southKorea'),
      value: 'kr',
      alternativeMatches: ['Corea del Sur', 'South Korea', 'KR'],
      type: 'string'
    },
    {
      label: t('countries.kuwait'),
      value: 'kw',
      alternativeMatches: ['Kuwait', 'KW'],
      type: 'string'
    },
    {
      label: t('countries.kazakhstan'),
      value: 'kz',
      alternativeMatches: ['Kazajistán', 'Kazakhstan', 'KZ'],
      type: 'string'
    },
    {
      label: t('countries.laos'),
      value: 'la',
      alternativeMatches: ['Laos', 'LA'],
      type: 'string'
    },
    {
      label: t('countries.lebanon'),
      value: 'lb',
      alternativeMatches: ['Líbano', 'Lebanon', 'LB'],
      type: 'string'
    },
    {
      label: t('countries.saintLucia'),
      value: 'lc',
      alternativeMatches: ['Santa Lucía', 'Saint Lucia', 'LC'],
      type: 'string'
    },
    {
      label: t('countries.liechtenstein'),
      value: 'li',
      alternativeMatches: ['Liechtenstein', 'LI'],
      type: 'string'
    },
    {
      label: t('countries.sriLanka'),
      value: 'lk',
      alternativeMatches: ['Sri Lanka', 'LK'],
      type: 'string'
    },
    {
      label: t('countries.liberia'),
      value: 'lr',
      alternativeMatches: ['Liberia', 'LR'],
      type: 'string'
    },
    {
      label: t('countries.lesotho'),
      value: 'ls',
      alternativeMatches: ['Lesoto', 'Lesotho', 'LS'],
      type: 'string'
    },
    {
      label: t('countries.lithuania'),
      value: 'lt',
      alternativeMatches: ['Lituania', 'Lithuania', 'LT'],
      type: 'string'
    },
    {
      label: t('countries.luxembourg'),
      value: 'lu',
      alternativeMatches: ['Luxemburgo', 'Luxembourg', 'LU'],
      type: 'string'
    },
    {
      label: t('countries.latvia'),
      value: 'lv',
      alternativeMatches: ['Letonia', 'Latvia', 'LV'],
      type: 'string'
    },
    {
      label: t('countries.libya'),
      value: 'ly',
      alternativeMatches: ['Libia', 'Libya', 'LY'],
      type: 'string'
    },
    {
      label: t('countries.morocco'),
      value: 'ma',
      alternativeMatches: ['Marruecos', 'Morocco', 'MA'],
      type: 'string'
    },
    {
      label: t('countries.monaco'),
      value: 'mc',
      alternativeMatches: ['Mónaco', 'Monaco', 'MC'],
      type: 'string'
    },
    {
      label: t('countries.moldova'),
      value: 'md',
      alternativeMatches: ['Moldavia', 'Moldova', 'MD'],
      type: 'string'
    },
    {
      label: t('countries.montenegro'),
      value: 'me',
      alternativeMatches: ['Montenegro', 'ME'],
      type: 'string'
    },
    {
      label: t('countries.saintMartin'),
      value: 'mf',
      alternativeMatches: ['San Martín', 'Saint Martin', 'MF'],
      type: 'string'
    },
    {
      label: t('countries.madagascar'),
      value: 'mg',
      alternativeMatches: ['Madagascar', 'MG'],
      type: 'string'
    },
    {
      label: t('countries.marshallIslands'),
      value: 'mh',
      alternativeMatches: ['Islas Marshall', 'Marshall Islands', 'MH'],
      type: 'string'
    },
    {
      label: t('countries.macedonia'),
      value: 'mk',
      alternativeMatches: ['Macedonia', 'MK'],
      type: 'string'
    },
    {
      label: t('countries.mali'),
      value: 'ml',
      alternativeMatches: ['Mali', 'ML'],
      type: 'string'
    },
    {
      label: t('countries.myanmarBurma'),
      value: 'mm',
      alternativeMatches: ['Myanmar (Birmania)', 'Myanmar (Burma)', 'MM'],
      type: 'string'
    },
    {
      label: t('countries.mongolia'),
      value: 'mn',
      alternativeMatches: ['Mongolia', 'MN'],
      type: 'string'
    },
    {
      label: t('countries.macau'),
      value: 'mo',
      alternativeMatches: ['Macao', 'Macau', 'MO'],
      type: 'string'
    },
    {
      label: t('countries.northernMarianaIslands'),
      value: 'mp',
      alternativeMatches: ['Islas Marianas del Norte', 'Northern Mariana Islands', 'MP'],
      type: 'string'
    },
    {
      label: t('countries.martinique'),
      value: 'mq',
      alternativeMatches: ['Martinica', 'Martinique', 'MQ'],
      type: 'string'
    },
    {
      label: t('countries.mauritania'),
      value: 'mr',
      alternativeMatches: ['Mauritania', 'MR'],
      type: 'string'
    },
    {
      label: t('countries.montserrat'),
      value: 'ms',
      alternativeMatches: ['Montserrat', 'MS'],
      type: 'string'
    },
    {
      label: t('countries.malta'),
      value: 'mt',
      alternativeMatches: ['Malta', 'MT'],
      type: 'string'
    },
    {
      label: t('countries.mauritius'),
      value: 'mu',
      alternativeMatches: ['Mauricio', 'Mauritius', 'MU'],
      type: 'string'
    },
    {
      label: t('countries.maldives'),
      value: 'mv',
      alternativeMatches: ['Maldivas', 'Maldives', 'MV'],
      type: 'string'
    },
    {
      label: t('countries.malawi'),
      value: 'mw',
      alternativeMatches: ['Malaui', 'Malawi', 'MW'],
      type: 'string'
    },
    {
      label: t('countries.mexico'),
      value: 'mx',
      alternativeMatches: ['México', 'Mexico', 'MX'],
      type: 'string'
    },
    {
      label: t('countries.malaysia'),
      value: 'my',
      alternativeMatches: ['Malasia', 'Malaysia', 'MY'],
      type: 'string'
    },
    {
      label: t('countries.mozambique'),
      value: 'mz',
      alternativeMatches: ['Mozambique', 'MZ'],
      type: 'string'
    },
    {
      label: t('countries.namibia'),
      value: 'na',
      alternativeMatches: ['Namibia', 'NA'],
      type: 'string'
    },
    {
      label: t('countries.newCaledonia'),
      value: 'nc',
      alternativeMatches: ['Nueva Caledonia', 'New Caledonia', 'NC'],
      type: 'string'
    },
    {
      label: t('countries.niger'),
      value: 'ne',
      alternativeMatches: ['Níger', 'Niger', 'NE'],
      type: 'string'
    },
    {
      label: t('countries.norfolkIsland'),
      value: 'nf',
      alternativeMatches: ['Isla Norfolk', 'Norfolk Island', 'NF'],
      type: 'string'
    },
    {
      label: t('countries.nigeria'),
      value: 'ng',
      alternativeMatches: ['Nigeria', 'NG'],
      type: 'string'
    },
    {
      label: t('countries.nicaragua'),
      value: 'ni',
      alternativeMatches: ['Nicaragua', 'NI'],
      type: 'string'
    },
    {
      label: t('countries.netherlands'),
      value: 'nl',
      alternativeMatches: ['Países Bajos', 'Netherlands', 'NL'],
      type: 'string'
    },
    {
      label: t('countries.norway'),
      value: 'no',
      alternativeMatches: ['Noruega', 'Norway', 'NO'],
      type: 'string'
    },
    {
      label: t('countries.nepal'),
      value: 'np',
      alternativeMatches: ['Nepal', 'NP'],
      type: 'string'
    },
    {
      label: t('countries.nauru'),
      value: 'nr',
      alternativeMatches: ['Nauru', 'NR'],
      type: 'string'
    },
    {
      label: t('countries.niue'),
      value: 'nu',
      alternativeMatches: ['Niue', 'NU'],
      type: 'string'
    },
    {
      label: t('countries.newZealand'),
      value: 'nz',
      alternativeMatches: ['Nueva Zelanda', 'New Zealand', 'NZ'],
      type: 'string'
    },
    {
      label: t('countries.oman'),
      value: 'om',
      alternativeMatches: ['Omán', 'Oman', 'OM'],
      type: 'string'
    },
    {
      label: t('countries.panama'),
      value: 'pa',
      alternativeMatches: ['Panamá', 'Panama', 'PA'],
      type: 'string'
    },
    {
      label: t('countries.peru'),
      value: 'pe',
      alternativeMatches: ['Perú', 'Peru', 'PE'],
      type: 'string'
    },
    {
      label: t('countries.frenchPolynesia'),
      value: 'pf',
      alternativeMatches: ['Polinesia Francesa', 'French Polynesia', 'PF'],
      type: 'string'
    },
    {
      label: t('countries.papuaNewGuinea'),
      value: 'pg',
      alternativeMatches: ['Papúa Nueva Guinea', 'Papua New Guinea', 'PG'],
      type: 'string'
    },
    {
      label: t('countries.philippines'),
      value: 'ph',
      alternativeMatches: ['Filipinas', 'Philippines', 'PH'],
      type: 'string'
    },
    {
      label: t('countries.pakistan'),
      value: 'pk',
      alternativeMatches: ['Pakistán', 'Pakistan', 'PK'],
      type: 'string'
    },
    {
      label: t('countries.poland'),
      value: 'pl',
      alternativeMatches: ['Polonia', 'Poland', 'PL'],
      type: 'string'
    },
    {
      label: t('countries.saintPierreAndMiquelon'),
      value: 'pm',
      alternativeMatches: ['San Pedro y Miquelón', 'Saint Pierre and Miquelon', 'PM'],
      type: 'string'
    },
    {
      label: t('countries.pitcairnIslands'),
      value: 'pn',
      alternativeMatches: ['Islas Pitcairn', 'Pitcairn Islands', 'PN'],
      type: 'string'
    },
    {
      label: t('countries.puertoRico'),
      value: 'pr',
      alternativeMatches: ['Puerto Rico', 'PR'],
      type: 'string'
    },
    {
      label: t('countries.palestinianTerritories'),
      value: 'ps',
      alternativeMatches: ['Territorios Palestinos', 'Palestinian Territories', 'PS'],
      type: 'string'
    },
    {
      label: t('countries.portugal'),
      value: 'pt',
      alternativeMatches: ['Portugal', 'PT'],
      type: 'string'
    },
    {
      label: t('countries.palau'),
      value: 'pw',
      alternativeMatches: ['Palaos', 'Palau', 'PW'],
      type: 'string'
    },
    {
      label: t('countries.paraguay'),
      value: 'py',
      alternativeMatches: ['Paraguay', 'PY'],
      type: 'string'
    },
    {
      label: t('countries.qatar'),
      value: 'qa',
      alternativeMatches: ['Qatar', 'QA'],
      type: 'string'
    },
    {
      label: t('countries.reunion'),
      value: 're',
      alternativeMatches: ['Reunión', 'Réunion', 'RE'],
      type: 'string'
    },
    {
      label: t('countries.romania'),
      value: 'ro',
      alternativeMatches: ['Rumanía', 'Romania', 'RO'],
      type: 'string'
    },
    {
      label: t('countries.serbia'),
      value: 'rs',
      alternativeMatches: ['Serbia', 'RS'],
      type: 'string'
    },
    {
      label: t('countries.russia'),
      value: 'ru',
      alternativeMatches: ['Rusia', 'Russia', 'RU'],
      type: 'string'
    },
    {
      label: t('countries.rwanda'),
      value: 'rw',
      alternativeMatches: ['Ruanda', 'Rwanda', 'RW'],
      type: 'string'
    },
    {
      label: t('countries.saudiArabia'),
      value: 'sa',
      alternativeMatches: ['Arabia Saudita', 'Saudi Arabia', 'SA'],
      type: 'string'
    },
    {
      label: t('countries.solomonIslands'),
      value: 'sb',
      alternativeMatches: ['Islas Salomón', 'Solomon Islands', 'SB'],
      type: 'string'
    },
    {
      label: t('countries.seychelles'),
      value: 'sc',
      alternativeMatches: ['Seychelles', 'SC'],
      type: 'string'
    },
    {
      label: t('countries.sudan'),
      value: 'sd',
      alternativeMatches: ['Sudán', 'Sudan', 'SD'],
      type: 'string'
    },
    {
      label: t('countries.sweden'),
      value: 'se',
      alternativeMatches: ['Suecia', 'Sweden', 'SE'],
      type: 'string'
    },
    {
      label: t('countries.singapore'),
      value: 'sg',
      alternativeMatches: ['Singapur', 'Singapore', 'SG'],
      type: 'string'
    },
    {
      label: t('countries.saintHelena'),
      value: 'sh',
      alternativeMatches: ['Santa Elena', 'Saint Helena', 'SH'],
      type: 'string'
    },
    {
      label: t('countries.slovenia'),
      value: 'si',
      alternativeMatches: ['Eslovenia', 'Slovenia', 'SI'],
      type: 'string'
    },
    {
      label: t('countries.svalbardAndJanMayen'),
      value: 'sj',
      alternativeMatches: ['Svalbard y Jan Mayen', 'Svalbard and Jan Mayen', 'SJ'],
      type: 'string'
    },

    {
      label: t('countries.slovakia'),
      value: 'sk',
      alternativeMatches: ['Eslovaquia', 'Slovakia', 'SK'],
      type: 'string'
    },
    {
      label: t('countries.sierraLeone'),
      value: 'sl',
      alternativeMatches: ['Sierra Leona', 'Sierra Leone', 'SL'],
      type: 'string'
    },
    {
      label: t('countries.sanMarino'),
      value: 'sm',
      alternativeMatches: ['San Marino', 'SM'],
      type: 'string'
    },
    {
      label: t('countries.senegal'),
      value: 'sn',
      alternativeMatches: ['Senegal', 'SN'],
      type: 'string'
    },
    {
      label: t('countries.somalia'),
      value: 'so',
      alternativeMatches: ['Somalia', 'SO'],
      type: 'string'
    },
    {
      label: t('countries.suriname'),
      value: 'sr',
      alternativeMatches: ['Surinam', 'Suriname', 'SR'],
      type: 'string'
    },
    {
      label: t('countries.saoTomeAndPrincipe'),
      value: 'st',
      alternativeMatches: ['Santo Tomé y Príncipe', 'São Tomé and Príncipe', 'ST'],
      type: 'string'
    },
    {
      label: t('countries.elSalvador'),
      value: 'sv',
      alternativeMatches: ['El Salvador', 'SV'],
      type: 'string'
    },
    {
      label: t('countries.sintMaarten'),
      value: 'sx',
      alternativeMatches: ['San Martín (Países Bajos)', 'Sint Maarten', 'SX'],
      type: 'string'
    },
    {
      label: t('countries.syria'),
      value: 'sy',
      alternativeMatches: ['Siria', 'Syria', 'SY'],
      type: 'string'
    },
    {
      label: t('countries.eswatini'),
      value: 'sz',
      alternativeMatches: ['Suazilandia', 'Eswatini', 'SZ'],
      type: 'string'
    },
    {
      label: t('countries.turksAndCaicosIslands'),
      value: 'tc',
      alternativeMatches: ['Islas Turcas y Caicos', 'Turks and Caicos Islands', 'TC'],
      type: 'string'
    },
    {
      label: t('countries.chad'),
      value: 'td',
      alternativeMatches: ['Chad', 'TD'],
      type: 'string'
    },
    {
      label: t('countries.togo'),
      value: 'tg',
      alternativeMatches: ['Togo', 'TG'],
      type: 'string'
    },
    {
      label: t('countries.thailand'),
      value: 'th',
      alternativeMatches: ['Tailandia', 'Thailand', 'TH'],
      type: 'string'
    },
    {
      label: t('countries.tajikistan'),
      value: 'tj',
      alternativeMatches: ['Tayikistán', 'Tajikistan', 'TJ'],
      type: 'string'
    },
    {
      label: t('countries.tokelau'),
      value: 'tk',
      alternativeMatches: ['Tokelau', 'TK'],
      type: 'string'
    },
    {
      label: t('countries.timorLeste'),
      value: 'tl',
      alternativeMatches: ['Timor-Leste', 'TL'],
      type: 'string'
    },
    {
      label: t('countries.turkmenistan'),
      value: 'tm',
      alternativeMatches: ['Turkmenistán', 'Turkmenistan', 'TM'],
      type: 'string'
    },
    {
      label: t('countries.tunisia'),
      value: 'tn',
      alternativeMatches: ['Túnez', 'Tunisia', 'TN'],
      type: 'string'
    },
    {
      label: t('countries.tonga'),
      value: 'to',
      alternativeMatches: ['Tonga', 'TO'],
      type: 'string'
    },
    {
      label: t('countries.turkey'),
      value: 'tr',
      alternativeMatches: ['Turquía', 'Turkey', 'TR'],
      type: 'string'
    },
    {
      label: t('countries.trinidadAndTobago'),
      value: 'tt',
      alternativeMatches: ['Trinidad y Tobago', 'Trinidad and Tobago', 'TT'],
      type: 'string'
    },
    {
      label: t('countries.tuvalu'),
      value: 'tv',
      alternativeMatches: ['Tuvalu', 'TV'],
      type: 'string'
    },
    {
      label: t('countries.taiwan'),
      value: 'tw',
      alternativeMatches: ['Taiwán', 'Taiwan', 'TW'],
      type: 'string'
    },
    {
      label: t('countries.tanzania'),
      value: 'tz',
      alternativeMatches: ['Tanzania', 'TZ'],
      type: 'string'
    },
    {
      label: t('countries.ukraine'),
      value: 'ua',
      alternativeMatches: ['Ucrania', 'Ukraine', 'UA'],
      type: 'string'
    },
    {
      label: t('countries.uganda'),
      value: 'ug',
      alternativeMatches: ['Uganda', 'UG'],
      type: 'string'
    },
    {
      label: t('countries.unitedStatesMinorOutlyingIslands'),
      value: 'um',
      alternativeMatches: [
        'Islas menores alejadas de los Estados Unidos',
        'United States Minor Outlying Islands',
        'UM'
      ],
      type: 'string'
    },
    {
      label: t('countries.unitedStates'),
      value: 'us',
      alternativeMatches: ['Estados Unidos', 'United States', 'US'],
      type: 'string'
    },
    {
      label: t('countries.uruguay'),
      value: 'uy',
      alternativeMatches: ['Uruguay', 'UY'],
      type: 'string'
    },
    {
      label: t('countries.uzbekistan'),
      value: 'uz',
      alternativeMatches: ['Uzbekistán', 'Uzbekistan', 'UZ'],
      type: 'string'
    },
    {
      label: t('countries.vaticanCity'),
      value: 'va',
      alternativeMatches: ['Ciudad del Vaticano', 'Vatican City', 'VA'],
      type: 'string'
    },
    {
      label: t('countries.saintVincentAndTheGrenadines'),
      value: 'vc',
      alternativeMatches: [
        'San Vicente y las Granadinas',
        'Saint Vincent and the Grenadines',
        'VC'
      ],
      type: 'string'
    },
    {
      label: t('countries.venezuela'),
      value: 've',
      alternativeMatches: ['Venezuela', 'VE'],
      type: 'string'
    },
    {
      label: t('countries.britishVirginIslands'),
      value: 'vg',
      alternativeMatches: ['Islas Vírgenes Británicas', 'British Virgin Islands', 'VG'],
      type: 'string'
    },
    {
      label: t('countries.usVirginIslands'),
      value: 'vi',
      alternativeMatches: ['Islas Vírgenes de los Estados Unidos', 'VI'],
      type: 'string'
    },
    {
      label: t('countries.vietnam'),
      value: 'vn',
      alternativeMatches: ['Vietnam', 'VN'],
      type: 'string'
    },
    {
      label: t('countries.vanuatu'),
      value: 'vu',
      alternativeMatches: ['Vanuatu', 'VU'],
      type: 'string'
    },
    {
      label: t('countries.wallisAndFutuna'),
      value: 'wf',
      alternativeMatches: ['Wallis y Futuna', 'Wallis and Futuna', 'WF'],
      type: 'string'
    },
    {
      label: t('countries.samoa'),
      value: 'ws',
      alternativeMatches: ['Samoa', 'WS'],
      type: 'string'
    },
    {
      label: t('countries.yemen'),
      value: 'ye',
      alternativeMatches: ['Yemen', 'YE'],
      type: 'string'
    },
    {
      label: t('countries.mayotte'),
      value: 'yt',
      alternativeMatches: ['Mayotte', 'YT'],
      type: 'string'
    },
    {
      label: t('countries.southAfrica'),
      value: 'za',
      alternativeMatches: ['Sudáfrica', 'South Africa', 'ZA'],
      type: 'string'
    },
    {
      label: t('countries.zambia'),
      value: 'zm',
      alternativeMatches: ['Zambia', 'ZM'],
      type: 'string'
    },
    {
      label: t('countries.zimbabwe'),
      value: 'zw',
      alternativeMatches: ['Zimbabue', 'Zimbabwe', 'ZW'],
      type: 'string'
    },
    {
      label: t('countries.belarus'),
      value: 'by',
      alternativeMatches: ['Bielorrusia', 'Belarus', 'BY'],
      type: 'string'
    },
    {
      label: t('countries.belize'),
      value: 'bz',
      alternativeMatches: ['Belice', 'Belize', 'BZ'],
      type: 'string'
    },
    {
      label: t('countries.bermuda'),
      value: 'bm',
      alternativeMatches: ['Bermudas', 'Bermuda', 'BM'],
      type: 'string'
    },
    {
      label: t('countries.bhutan'),
      value: 'bt',
      alternativeMatches: ['Bután', 'Bhutan', 'BT'],
      type: 'string'
    },
    {
      label: t('countries.bouvetIsland'),
      value: 'bv',
      alternativeMatches: ['Isla Bouvet', 'Bouvet Island', 'BV'],
      type: 'string'
    },
    {
      label: t('countries.botswana'),
      value: 'bw',
      alternativeMatches: ['Botswana', 'BW'],
      type: 'string'
    },
    {
      label: t('countries.centralAfricanRepublic'),
      value: 'cf',
      alternativeMatches: ['República Centroafricana', 'Central African Republic', 'CF'],
      type: 'string'
    },
    {
      label: t('countries.cocosIslands'),
      value: 'cc',
      alternativeMatches: ['Islas Cocos', 'Cocos Islands', 'CC'],
      type: 'string'
    },
    {
      label: t('countries.switzerland'),
      value: 'ch',
      alternativeMatches: ['Suiza', 'Switzerland', 'CH'],
      type: 'string'
    },
    {
      label: t('countries.côteDIvoire'),
      value: 'ci',
      alternativeMatches: ['Costa de Marfil', "Côte d'Ivoire", 'CI'],
      type: 'string'
    },
    {
      label: t('countries.congoTheDemocraticRepublicOfThe'),
      value: 'cd',
      alternativeMatches: [
        'Congo, República Democrática del',
        'Congo, The Democratic Republic Of The',
        'CD'
      ],
      type: 'string'
    },
    {
      label: t('countries.cookIslands'),
      value: 'ck',
      alternativeMatches: ['Islas Cook', 'Cook Islands', 'CK'],
      type: 'string'
    },
    {
      label: t('countries.southSudan'),
      value: 'ss',
      alternativeMatches: ['Sudán del Sur', 'South Sudan', 'SS'],
      type: 'string'
    }
  ];

  return { countriesNuvo };
};
