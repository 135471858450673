const GREEN_PALETTE = [
  '#38CFD9',
  '#32B6BF',
  '#2CA0A8',
  '#268D94',
  '#217980',
  '#2E696E',
  '#255659',
  '#1E4547',
  '#142F30',
  '#64E6ED'
] as const;

const PURPLE_20_PALETTE = [
  '#6E86F7',
  '#6379D8',
  '#5164B5',
  '#435194',
  '#303E80',
  '#242E5E',
  '#0E228A',
  '#112BAD',
  '#1536D6',
  '#2945FF'
] as const;

const BLUE_PALETTE = [
  '#2945FF',
  '#1536D6',
  '#112BAD',
  '#0E228A',
  '#242E5E',
  '#303E80',
  '#435194',
  '#5164B5',
  '#6379D8',
  '#6E86F7'
] as const;

const PURPLE_40_PALETTE = [
  '#CED2FA',
  '#B8BBE0',
  '#ACAEFA',
  '#9B9DE0',
  '#8B8DC9',
  '#A5A6C9',
  '#9496B5',
  '#6D72B0',
  '#5D6196',
  '#F0F3FE'
] as const;

export const LCA_DISABLED_COLOR_PALETTE = ['#A3AAB2', '#EAEAEA', '#A3AAB2', '#EAEAEA'] as const;

export const LCA_COLORS_DEFAULT = [
  GREEN_PALETTE[0],
  BLUE_PALETTE[0],
  PURPLE_40_PALETTE[0],
  PURPLE_20_PALETTE[0]
] as const;

export const LCA_COLOR_PALETTE = [
  GREEN_PALETTE,
  BLUE_PALETTE,
  PURPLE_40_PALETTE,
  PURPLE_20_PALETTE
] as const;
