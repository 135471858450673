import React from 'react';
import { useTranslation } from 'react-i18next';

const useSideBarFilters = () => {
  const { t } = useTranslation();
  return [
    {
      options: [
        {
          id: 'rented',
          name: t('facilities.rented'),
          type: 'type'
        },
        {
          id: 'owned',
          name: t('facilities.owned'),
          type: 'type'
        }
      ],
      title: t('facilities.facilityType'),
      elToShow: 0
    }
  ];
};

export default useSideBarFilters;
