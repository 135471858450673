import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LANGUAGES } from '../../../../../constants';
import { NotificationContext } from '../../../../../context/notificationContext';
import { UserContext } from '../../../../../context/userContext';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { Employee } from '../../../../../types/entities/employee';
import apiFetch from '../../../../../utils/apiFetch';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import { convertDateFrontToBack } from '../../../../../utils/convertDates';
import { createCSVToSendByEmail } from '../../../../../utils/createCSVToSendByEmail';
import getBase64 from '../../../../../utils/getBase64';
import Button from '../../../../ui/button/Button';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import Modal from '../../../../ui/modal/Modal';
import UploadCSV from '../../sendEmailForm/sendEmailCSVContent/UploadCSV/UploadCSV';
import { InfoTag } from '../infoTag/InfoTag';

export type Props = {
  close: () => void;
  addEmployees?: (employees: Employee[]) => void;
};

export type FormData = {
  subject: string;
  file: File | null;
  organization_id: string | undefined;
  lang: SelectOptionFormat<string>;
  temporal?: string;
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};

export type Body = {
  subject: string;
  file: string | ArrayBuffer;
  organization_id: string | undefined;
  lang: string;
  temporal?: string;
  start_date: string;
  end_date: string;
};

export const EmailFormModalEmployees = ({ addEmployees, close }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentYear = moment().format('YYYY');

  const setNotification = useContext(NotificationContext);
  const user = useContext(UserContext);

  const selectedOrg = user?.organizations?.filter((org) => org.id === user.selectedOrganization);

  const [formData, setFormData] = useState<FormData>({
    subject: t('sendEmailForm.subjectDefault'),
    file: null,
    organization_id: user?.selectedOrganization,
    lang: { id: i18n.resolvedLanguage, name: t(`languages.${i18n.resolvedLanguage}`) },
    errors: [],
    startDate: '',
    endDate: ''
  });

  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });
  const [error, setError] = useState<ErrorType>();

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    if (!formData.file) {
      const error = {
        error: 'file',
        description: t('sendEmailForm.addFile')
      };
      setErrors((errors) => errors.concat(error));
    } else {
      setErrors(errors.filter((error) => error.error !== 'file'));
    }

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  const sendCsv = () => {
    const errors = handleErrors();
    if (!user || !formData.file) {
      return null;
    }
    getBase64(formData?.file, async (file_url: string | null | ArrayBuffer) => {
      if (!file_url) {
        return null;
      }

      const start_date = convertDateFrontToBack(formData.startDate);
      const end_date = convertDateFrontToBack(formData.endDate);

      const body: Body = {
        subject:
          (selectedOrg && t(formData.subject, { organization: selectedOrg[0]?.company_name })) ??
          '',
        file: file_url,
        organization_id: user?.selectedOrganization,
        lang: formData.lang.id,
        start_date,
        end_date
      };

      if (errors) return;
      try {
        setLoadingButton(true);

        const response = await apiFetch('POST', '/employees/csv', body);
        setLoadingButton(false);

        if (addEmployees) {
          addEmployees(response.data);
        }
        dispatch(setNotification(t('notification.customFormSent')));

        close();
      } catch (err: any) {
        setLoadingButton(false);
        if (err.response.data.includes('EMPLOYEE_LIMIT_EXCEEDED')) {
          setError({
            error: 'maxEmailsOrg',
            description: t('error.maxEmailFormEmployeesExceeded')
          });
        }
        if (err.response.data.includes('MAX_EMAILS_EXCEEDED')) {
          setError({
            error: 'maxEmailsExceeded',
            description: t('error.maxEmailsExceeded')
          });
        }

        if (err.response.data === 'Invalid data entry') {
          setError({
            error: 'badCSVInput',
            description: t('error.badCSVInput')
          });
        }
      }
    });
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      subject: t('sendEmailForm.subjectDefault', { lng: formData.lang.id })
    }));
  }, [formData.lang.id]);

  return (
    <>
      <Modal.Header
        title={t(`sendEmailForm.employee.title`)}
        description={t(`sendEmailForm.employee.subtitle`)}
      />
      <InfoTag />
      <UploadCSV
        template={createCSVToSendByEmail}
        file={formData.file}
        handleUploadFile={onChangeValue('file')}
        error={errors.find((elem) => elem.error === 'badCSV' || elem.error === 'file')}
      />
      <FormWrapper>
        <FormCalendarDouble
          tooltip={t('employees.dateRangeTooltip')}
          label={t('sendEmailForm.measurement_period')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          handleStartDateError={handleDateError('startDate')}
          handleEndDateError={handleDateError('endDate')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          minStartDate={'01/01/1960'}
          minEndDate={'01/01/1960'}
          maxEndDate={`31/12/${currentYear}`}
          error={formData?.errors?.find(
            (elem) => elem.error === 'startDate' || elem.error === 'endDate'
          )}
        />
        <FormSelect
          label={t('sendEmailForm.language')}
          iconV2='earth'
          value={formData.lang}
          onChange={onChangeValue('lang')}
          options={LANGUAGES.map((language) => ({
            id: language,
            name: t(`languages.${language}`)
          }))}
        />
      </FormWrapper>
      <FormElementFull>
        <FormText
          label={t('sendEmailForm.subjectTitle')}
          value={selectedOrg && t(formData.subject, { organization: selectedOrg[0].company_name })}
          iconV2='mail'
          placeholder={t('sendEmailForm.subjectPlaceholder')}
          onChange={onChangeValue('subject')}
          error={formData?.errors?.find((elem) => elem.error === 'subject')}
        />
      </FormElementFull>
      <div className='button-send-email'>
        <Button
          lookAndFeel={'primary'}
          onClick={sendCsv}
          text={t('sendEmailForm.send')}
          size={'medium'}
          height={'small'}
          loading={loadingButton}
        />
      </div>
      {error && <p className='error-text-color body1-bold-font'>{error.description}</p>}
    </>
  );
};
