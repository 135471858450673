import { ReactNode } from 'react';
import Tooltip from '../../../tooltip/Tooltip';
import './styles.scss';

type Props = {
  label?: ReactNode;
  tooltip?: string;
  children: ReactNode;
};

const InputHeaderWrapper = ({ label, tooltip, children }: Props) => {
  return (
    <>
      <label className='input-label-font on-light-text-color label-wrapper extra-data-input-wrapper'>
        {label}
        {tooltip && <Tooltip text={tooltip} />}
      </label>
      {children}
    </>
  );
};

export default InputHeaderWrapper;
