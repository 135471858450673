import { IconName } from '../../../../../types/utils/iconName';
import { InputSize } from '../../../../../types/utilsEnums/input';
import Icon from '../../../icon/Icon';
import InputWrapperPlainLabel from './InputWrapperPlainLabel';
import './styles.scss';

/*
  This component is the same as InputWrapperPlainLabel, but having an icon in lateral element
*/
interface Props extends Omit<React.ComponentProps<typeof InputWrapperPlainLabel>, 'icon'> {
  icon?: string;
  iconV2?: IconName;
}

const InputWrapperPlain = ({ icon, iconV2, ...rest }: Props) => {
  const imageSize = rest.size === InputSize.MEDIUM ? '14' : '12';
  const iconV2Size = rest.size === InputSize.MEDIUM ? 'large' : 'medium';

  const renderIcon = () => {
    if (icon) return <img src={icon} alt='input-icon' width={imageSize} />;
    if (iconV2) return <Icon icon={iconV2} size={iconV2Size} color='gradient' />;

    return null;
  };

  return <InputWrapperPlainLabel {...rest} leftSection={renderIcon()} />;
};

export default InputWrapperPlain;
