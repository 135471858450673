import { useTranslation } from 'react-i18next';
import './styles.scss';
import { ReactComponent as SFCLogoColor } from '../../../../../assets/icons/sfc.svg';

interface Props {
  onDark?: boolean;
}

export const SfcLogo = ({ onDark }: Props) => {
  const { t } = useTranslation();
  return (
    <div className='flex gap-x-2 justify-end items-center'>
      <p className={`font-body-b2-sb ${onDark ? 'on-dark-text-color' : ''}`}>
        {t('logistics.sfcCertified')}
      </p>
      <SFCLogoColor />
    </div>
  );
};
