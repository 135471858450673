import { useTranslation } from 'react-i18next';
import scope3Categories from '../../../../../../../constants/json/scope3Categories.json';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import CheckboxMultiple from '../../../../../../ui/formComponents/checkboxMultiple/CheckboxMultiple';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElement from '../../../../../../ui/formComponents/formElement/FormElement';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useModal from '../hooks/useModal';

export const ModalC16 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });
  const { t: tr } = useTranslation('translation');
  const { formData, setFormData, loading, patchCriteria, loadingButton, errorPatch } = useModal();

  const handleSelectCategory = (id: string) => {
    setFormData((prev) => ({
      ...prev,
      categories: [...(prev.categories || []), id],
      errors: prev.errors.filter((elem) => elem.error !== 'categories')
    }));
  };

  const handleRemoveCategory = (id: string) => {
    // Remove category from categoriesSelected

    setFormData((prev) => ({
      ...prev,
      categories: (prev.categories || []).filter((elem) => elem !== id),
      errors: prev.errors.filter((elem) => elem.error !== 'categories')
    }));
  };

  return (
    <>
      <FormHeader title={t('title')} />
      {loading && (
        <CustomSkeletonLoader
          count={1}
          style={{
            marginBottom: '2rem'
          }}
        />
      )}

      {!loading && (
        <>
          <p className='input-label-font'>{t('c16Modal')}</p>
          <FormWrapper>
            {scope3Categories.map((elem, index) => {
              return (
                <FormElement key={`${elem.category}-${index}`}>
                  <div className='checkbox-wrapper'>
                    <CheckboxMultiple
                      id={elem.category}
                      onSelect={handleSelectCategory}
                      onRemove={handleRemoveCategory}
                      selected={!!formData?.categories?.includes(elem.category)}
                    />
                    <span className='body1-font'>{tr(`categories.${elem.category}`)}</span>
                  </div>
                </FormElement>
              );
            })}
          </FormWrapper>
        </>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('save')}
          loading={loadingButton}
          size='small'
          onClick={() => patchCriteria(formData)}
        />
      </FormButtonSection>
      <ErrorText>{errorPatch && errorPatch.description}</ErrorText>
    </>
  );
};
