import { useTranslation } from 'react-i18next';
import { postFund } from '../../../../../services/api/funds';
import { FormFundData, FundType } from '../../../../../types/entities/funds';
import FormFund from '../formFund/FormFund';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useState } from 'react';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../ui/button/Button';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import ErrorText from '../../../../ui/errorText/ErrorText';

type Props = {
  addFund: (value: FundType) => void;
};

const CreateFund = ({ addFund }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'funds' });
  const { t: tErrors } = useTranslation('translation', { keyPrefix: 'error' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const formDataEmpty = {
    name: '',
    size: '',
    article: {
      id: '',
      name: ''
    },
    questions: [],
    errors: []
  };

  const [formData, setFormData] = useState<FormFundData>(formDataEmpty);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (formData.questions.length < 2) {
      newErrors.push({
        error: 'questions',
        description: tErrors('selectAtLeast2')
      });
    }

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleAddFund = async () => {
    if (handleErrors()) return;
    setLoadingButton(true);
    const response = await postFund(formData);
    setLoadingButton(false);

    if (response?.response?.status >= 400) {
      setError({
        error: 'general',
        description: tErrors('somethingWentWrong')
      });
      return;
    }

    addFund(response);
  };

  return (
    <>
      <FormHeader title={t('title')} description={t('subtitle')} />
      <FormFund formData={formData} setFormData={setFormData} />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={tGeneral('save')}
          onClick={handleAddFund}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText style={{ marginTop: '0.5rem' }}>{error.description}</ErrorText>}
    </>
  );
};

export default CreateFund;
