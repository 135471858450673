import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteInvite } from '../../services/api/invites';
import { deleteMembership } from '../../services/api/membership';
import { User } from '../../types/entities/user';

import Button from '../ui/button/Button';

import './styles.scss';

type Props = {
  deleteMember: (id: string, type: string) => void;
  memberToDelete: {
    id: string;
    type: string;
  };
  user: User | null;
  members: User[];
};
function DeleteMember({ user, memberToDelete, deleteMember, members }: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState<ErrorType>();
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteMember();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleDeleteMember = async () => {
    // Check if you are deleting the only owner, if it is like that do not allow request because there has to be at least 1 owner

    const owners = members.filter((member) => member.role === 'owner');

    if (owners?.length === 1 && owners[0].id === memberToDelete.id) {
      setError({
        error: 'role',
        description: t('members.atLeast1Owner')
      });
      return;
    }
    try {
      setLoadingButton(true);

      if (memberToDelete.type === 'member') {
        await deleteMembership(memberToDelete.id, user?.selectedOrganization || '');
      } else {
        await deleteInvite(memberToDelete.id, user?.selectedOrganization || '');
      }

      deleteMember(memberToDelete.id, memberToDelete.type);
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
    }
  };

  return (
    <div className='delete-modal'>
      <h1 className='headline4-font'>{t('members.deleteMember')}</h1>
      <h2 className='subtitle3-font '>{t('members.areYouSureDelete')}</h2>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('members.delete')}
          onClick={handleDeleteMember}
          loading={loadingButton}
        />
      </div>
      {error && <span className='error-text error-font error-text-color'>{error.description}</span>}
    </div>
  );
}

export default DeleteMember;
