import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  handleFiles: (filesUploaded: File[]) => void;
  children: React.ReactNode;
  fileNames: string[];
  error?: ErrorType;
  allowedTypes: string[];
};
function InputFileText({ handleFiles, children, error, allowedTypes }: Props) {
  const drop = useRef<any>(null);
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef<any>(null);

  const [errorType, setErrorType] = useState('');

  useEffect(() => {
    if (drop && drop.current) {
      const dropCurrent = drop.current;
      dropCurrent.addEventListener('dragover', handleDragOver);
      dropCurrent.addEventListener('drop', handleDrop);

      return () => {
        dropCurrent.removeEventListener('dragover', handleDragOver);
        dropCurrent.removeEventListener('drop', handleDrop);
      };
    }
  });

  // Programatically click the hidden file input element
  // when the children div parent is clicked
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation(); // Add this line to stop event propagation
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const checkFileTypes = (files: File[]) => {
    return files.filter((file: File) => {
      return allowedTypes.length === 0 || allowedTypes?.includes(file.type);
    });
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let newError = '';

    if (target && target.files && target.files.length > 0) {
      const files = checkFileTypes(Array.from(target.files));
      if (files && files.length) {
        handleFiles(files);
      } else {
        newError = t('error.typeNotValid');
      }
    }
    target.files = null;
    hiddenFileInput.current.value = null;
    setErrorType(newError);
  };

  const handleDragOver = (e: ChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: CustomEvent & { dataTransfer?: DataTransfer }) => {
    e.preventDefault();
    e.stopPropagation();
    let newError = '';
    const dataTransfer = e.dataTransfer;

    if (dataTransfer && dataTransfer.files) {
      const files = checkFileTypes(Array.from(dataTransfer.files));

      if (files && files.length) {
        handleFiles(files);
      } else {
        newError = t('error.typeNotValid');
      }
    }
    setErrorType(newError);
  };

  const { t } = useTranslation();

  return (
    <div className='input-file-text' ref={drop}>
      <div
        className={error || errorType ? 'input-error-text error-text-color' : ''}
        onClick={handleClick}>
        {children}
      </div>
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        multiple
      />
      {/* {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
      {errorType && (
        <span className='input-error-text error-font error-text-color'>{errorType}</span>
      )} */}
    </div>
  );
}

export default InputFileText;
