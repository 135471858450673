import { AxiosError } from 'axios';

async function makeRequestsInBatches(requests: any[], batchSize = 3) {
  const numBatches = Math.ceil(requests.length / batchSize);
  const responses = [];

  const batches = [];

  // Slice the requests into batches of batchSize
  for (let i = 0; i < numBatches; i++) {
    const batch = requests.slice(i * batchSize, (i + 1) * batchSize);
    batches.push(batch);
  }

  // Process each batch
  for (const batch of batches) {
    // Create an array of Promises representing the requests
    const batchPromises = batch.map((request: () => Promise<any>) => {
      if (request) {
        return request().catch((error: AxiosError) => {
          // eslint-disable-next-line no-console
          console.error('One of the promises failed:', error);
          return null; // or any other fallback value if needed
        });
      }
    });

    // Wait for all the promises to resolve
    const batchResponses = await Promise.all(batchPromises);
    responses.push(...batchResponses);
  }
  return responses;
}

export default makeRequestsInBatches;
