import { toPng, getFontEmbedCSS } from 'html-to-image';

const exportAsImage = async (element, imageFileName, width = 800, height = 800) => {
  const dataUrl = await toPng(element, {
    background: 'transparent',
    canvasWidth: width,
    canvasHeight: height,
    quality: 1,
    cacheBust: true,
    fontEmbedCSS: await getFontEmbedCSS(element)
  });
  const image = new Image();
  image.src = dataUrl;
  downloadImage(dataUrl, imageFileName);
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement('a');
  fakeLink.style = 'display:none;';
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default exportAsImage;
