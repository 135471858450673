import Table from 'rc-table';
import './styles.scss';

type Props = React.ComponentProps<typeof Table>;

const ReportTable = ({ ...props }: Props) => {
  const className = 'report-table' + ' ' + props.className; // general class name

  return <Table {...props} className={className} />;
};

export default ReportTable;
