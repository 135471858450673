import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../button/Button';
import './styles.scss';
type Props = {
  label?: string;
  handleFile: (fileUploaded: File) => void;
  buttonText: string;
  buttonIcon?: string;
  labelText: string;
  fileName: string;
  error?: ErrorType;
  allowedTypes: string[];
};
function InputFileSingle({
  label,
  handleFile,
  buttonText,
  fileName,
  labelText,
  error,
  buttonIcon,
  allowedTypes
}: Props) {
  const drop = useRef<any>(null);
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef<any>(null);
  const [errorType, setErrorType] = useState('');
  useEffect(() => {
    if (drop && drop.current) {
      const dropCurrent = drop.current;
      dropCurrent.addEventListener('dragover', handleDragOver);
      dropCurrent.addEventListener('drop', handleDrop);
      return () => {
        dropCurrent.removeEventListener('dragover', handleDragOver);
        dropCurrent.removeEventListener('drop', handleDrop);
      };
    }
  });
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setErrorType('');
    if (target && target.files && target.files.length > 0) {
      let file = null;
      if (
        allowedTypes.length === 0 ||
        allowedTypes.find((type) => target.files && type === target.files[0].type)
      ) {
        file = target.files[0];
      } else {
        setErrorType(t(`error.fileError`) + ' ' + allowedTypes.join(', '));
      }
      if (file) {
        handleFile(file);
      }
    }
  };
  const handleDragOver = (e: ChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e: CustomEvent & { dataTransfer?: DataTransfer }) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorType('');
    const dataTransfer = e.dataTransfer;
    if (dataTransfer && dataTransfer.files) {
      let file = null;
      if (
        allowedTypes.length === 0 ||
        allowedTypes.find((type) => type === dataTransfer.files[0].type)
      ) {
        file = dataTransfer.files[0];
      } else {
        setErrorType(t(`error.fileError`) + ' ' + allowedTypes.join(', '));
      }

      if (file) {
        handleFile(file);
      }
    }
  };

  const { t } = useTranslation();
  return (
    <>
      {label ? <div className='input-label-font on-light-text-color'>{label}</div> : null}
      <div className='input-file dashed-border' ref={drop}>
        {buttonText && (
          <Button
            lookAndFeel='primary'
            text={buttonText}
            onClick={handleClick}
            icon={buttonIcon}
            size='medium'></Button>
        )}
        <input
          type='file'
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
          multiple
        />
        {/* {fileNames.map((fileName) => (
        <label className='input-file-font'>{fileName}</label>
      ))} */}
        <label className='input-file-font'>{fileName ? fileName : labelText}</label>
        {error && !errorType && (
          <span className='input-error-text error-font error-text-color'>
            {error.description ? error.description : t('error.requiredField')}
          </span>
        )}
        {errorType && (
          <span className='input-error-text error-font error-text-color'>{errorType}</span>
        )}
      </div>
    </>
  );
}
export default InputFileSingle;
