import { validate } from 'email-validator';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/entities/user';

import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';
import InputTextSelect from '../../ui/formComponents/inputTextSelect/InputTextSelect';
import './styles.scss';
import Modal from '../../ui/modal/Modal';

type Props = {
  addMember?: (value: User) => void;
  inviteUser: (value: User) => void;
  members: User[];
  user: User | null;
  organizationId: string;
};
function AddMember({ addMember, user, inviteUser, members, organizationId }: Props) {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [role, setRole] = useState({ id: 'member', name: t('members.member') });
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleAddMember();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const newErrors = errors.filter((elem) => elem.error !== 'email');
    setErrors(newErrors);
    setEmail(e.target.value);
  };

  const onChangeRole = (value: SelectOptionFormat) => {
    const newErrors = errors.filter((elem) => elem.error !== 'role');
    setErrors(newErrors);

    setRole(value);
  };

  const handleAddMember = async () => {
    // Error handle
    const newErrors = [...errors];
    if (!validate(email) && !errors.some((elem) => elem.error === 'email')) {
      newErrors.push({ error: 'email' });
    }
    if (members.find((elem) => elem.email === email)) {
      newErrors.push({
        error: 'email',
        description: t('members.cannotInvite')
      });
    }
    if ((!role.id || !role.name) && !errors.some((elem) => elem.error === 'role')) {
      newErrors.push({ error: 'role' });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return null;
    }

    if (!user?.organizations || user?.organizations.length === 0) {
      return null;
    }
    try {
      // Add member
      setLoadingButton(true);

      const response = await apiFetch(
        'post',
        '/invite_user',
        {
          email,
          organization_id: organizationId,
          role: role.id,
          send_email: true,
          invited_by_user_id: user.id,
          lang: i18n.resolvedLanguage
        },
        { 'x-organization-id': organizationId }
      );

      const newUser: User = response.data;
      newUser.isInvitation = !response.data.found;
      newUser.created_at = moment(new Date()).format('YYYY-MM-DD');
      newUser.isInvitation ? inviteUser(newUser) : addMember ? addMember(newUser) : null;
    } catch (error: any) {
      setLoadingButton(false);
      // console.error('error response status', error.response.status);
      if (error.response.status === 403) {
        setErrors([...errors, { error: 'email', description: t('error.userAlreadyInvited') }]);
      }
    }
  };

  return (
    <div className='new-member'>
      <Modal.Header
        title={t('members.addNewMember')}
        description={t('members.addNewMemberDescription')}
      />
      <div className='select-inputs-wrapper'>
        <InputTextSelect
          icon={'/images/icons/people.svg'}
          placeholder={t('inviteModal.emailPlaceholder')}
          textValue={email}
          onChangeTextValue={onChangeEmail}
          selectOptions={[
            { id: 'member', name: t('members.member') },
            { id: 'owner', name: t('members.owner') }
          ]}
          onChangeSelectOption={onChangeRole}
          selectOptionValue={role}
          height='32px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'email')}
        />
      </div>

      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('members.add')}
          onClick={handleAddMember}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default AddMember;
