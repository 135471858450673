import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import { patchPreEvent } from '../../../../../services/api/events';
import { EventType, PreEventBody, PreEventFormData } from '../../../../../types/entities/events';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import Attendees from './attendees/Attendees';
import Drinks from './drinks/Drinks';
import Employees from './employees/Employees';
import Food from './food/Food';
import Purchases from './purchases/Purchases';
import Site from './site/Site';

type Props = {
  eventDetail: EventType;
  preEvent: PreEventBody;
  updatePreEvent: (value: PreEventBody, co2e: number, hide: boolean) => void;
};
const PreEventData = ({ eventDetail, preEvent, updatePreEvent }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [formData, setFormData] = useState<PreEventFormData>({
    sites: [],
    employees: [],
    attendees: [],
    food: [],
    drinks: [],
    purchases: [],
    errors: []
  });
  const stepIds = ['site', 'employees', 'attendees', 'food', 'drinks', 'purchases'];

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonSaveAndExit, setLoadingButtonSaveAndExit] = useState(false);

  useEffect(() => {
    parsePreEventData();
  }, [preEvent]);

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps,
    handleNext,
    isLastElement
  } = useStepCarousel({
    stepsText: stepIds.map((elem) => ({
      id: elem,
      text: t(`eventData.${elem}`)
    }))
  });

  const handleSelect = (id: string) => {
    handleSelectCarousel(id);
  };

  const parsePreEventData = () => {
    // Convert preEventData to formData
    setFormData({
      ...formData,
      sites: preEvent.sites ?? [],
      employees: preEvent.employees ?? [],
      attendees: preEvent.attendees ?? [],
      food: preEvent.food ?? [],
      drinks: preEvent.drinks ?? [],
      purchases: preEvent.miscellaneous ?? []
    });
  };

  const parseFormData = () => {
    const body: PreEventBody = {
      food: formData.food.map((elem) => ({
        id: elem.id ?? undefined,
        type: elem.type,
        quantity: elem.quantity,
        dish: elem.dish
      })),
      drinks: formData.drinks.map((elem) => ({
        id: elem.id ?? undefined,
        type: elem.type,
        quantity: elem.quantity,
        container: elem.container
      })),
      miscellaneous: formData.purchases.map((elem) => ({
        id: elem.id ?? undefined,
        type: elem.type,
        quantity: elem.quantity
      })),
      attendees: formData.attendees.map((elem) => ({
        id: elem.id ?? undefined,
        quantity: elem.quantity,
        distance: elem.distance ? elem.distance : 0,
        transport_type: elem.transport_type
      })),
      employees: formData.employees.map((elem) => ({
        id: elem.id ?? undefined,
        quantity: elem.quantity,
        distance: elem.distance,
        transport_type: elem.transport_type
      })),
      sites: formData.sites.map((elem) => ({
        id: elem.id ?? undefined,
        area: elem.area,
        hours: elem.hours,
        renewable_energy: elem.renewable_energy
      }))
    };
    return body;
  };

  const handleNextStep = async () => {
    // Call next step if it is not the last step
    if (!isLastElement) {
      handleNext();
      return;
    }
    try {
      // Update event with data
      setLoadingButton(true);
      const body = parseFormData();
      const response = await patchPreEvent(eventDetail.id, body, user?.selectedOrganization ?? '');
      const details = response.details as PreEventBody;
      // if (details) {
      //   // Update body with ids that come from details
      // }
      // Update event with data
      updatePreEvent(
        {
          ...details
        },
        response.co2e,
        true
      );
      setLoadingButton(false);
    } catch (error) {
      console.error(error);
      setLoadingButton(false);
    }
  };

  const renderStep = () => {
    type StepContent = {
      [key: string]: ReactNode;
    };
    const stepContent: StepContent = {
      site: <Site setFormData={setFormData} formData={formData.sites} />,
      employees: <Employees setFormData={setFormData} formData={formData.employees} />,
      attendees: (
        <Attendees
          setFormData={setFormData}
          formData={formData.attendees}
          eventId={eventDetail.id}
          eventName={eventDetail.name}
        />
      ),
      food: <Food setFormData={setFormData} formData={formData.food} />,
      drinks: <Drinks setFormData={setFormData} formData={formData.drinks} />,
      purchases: <Purchases setFormData={setFormData} formData={formData.purchases} />
    };

    return stepContent[stepSelected?.id ?? ''];
  };

  const handleSaveAndExit = async () => {
    try {
      setLoadingButtonSaveAndExit(true);
      // Update event with data
      const body = parseFormData();
      const response = await patchPreEvent(eventDetail.id, body, user?.selectedOrganization ?? '');
      setLoadingButtonSaveAndExit(false);
      const details = response.details as PreEventBody;
      updatePreEvent(
        {
          ...details
        },
        response.co2e,
        true
      );
    } catch (error) {
      console.log(error);
      setLoadingButtonSaveAndExit(false);
    }
  };

  return (
    <div className='pre-event-data'>
      <FormHeader title={t('eventData.title')} description={t('eventData.description')} />
      <StepCarouselList steps={steps} handleSelect={handleSelect} lookAndFeel={'big'} />
      {renderStep()}
      <FormButtonSection>
        <Button
          lookAndFeel={'secondary'}
          text={t('eventData.saveAndExit')}
          onClick={handleSaveAndExit}
          size='medium'
          loading={loadingButtonSaveAndExit}
        />
        <Button
          lookAndFeel={'primary'}
          text={isLastElement ? t('eventData.save') : t('eventData.next')}
          onClick={handleNextStep}
          size='medium'
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default PreEventData;
