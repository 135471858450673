import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { moveNodes } from '../../../../services/api/lca/lcaScenarios';
import { LCABlockBack } from '../../types/LCABlock';

type Params = {
  acv_id: string;
};

export const useMoveNodes = ({ acv_id }: Params) => {
  const queryClient = useQueryClient();
  const selectedOrg = useSelectedOrganization();

  const { mutateAsync } = useMutation({
    mutationFn: moveNodes,
    onSuccess: (data) => {
      queryClient.setQueryData(['lcaBlocks', selectedOrg?.id, acv_id], (prev: LCABlockBack[]) => {
        for (const block of data) {
          const index = prev.findIndex((prev_block) => prev_block.id === block.id);

          prev[index] = {
            ...prev[index],
            coordinates_x: block.coordinates_x,
            coordinates_y: block.coordinates_y
          };
        }

        return [...prev];
      });
    }
  });

  return {
    moveNodes: mutateAsync
  };
};
