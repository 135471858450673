import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { AVAILABLE_COLORS } from '../../../../constants';
import { gray_color } from '../../../../styles/colors';
import { formatRoundNumber } from '../../../../utils/formatNumber';
import { formatNumberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import CardHeader from '../cardHeader/CardHeader';
import './styles.scss';

type DataChart = {
  title: string;
  value: number;
  color: string;
}[];

type Props = {
  categories: CategoryData[];
};
export const ImpactScope3 = ({ categories: allCategories }: Props) => {
  const { t } = useTranslation();

  //not showing sold products categories for the moment (affects investments)
  const categories = allCategories.filter(
    (category) => category.scope === 3 && !category.category.includes('sold_products_')
  );

  const totalEmissions = categories.reduce(
    (acc, category) => acc + category.total_emissions / 1000,
    0
  );

  categories.sort((a: CategoryData, b: CategoryData) => {
    return b.total_emissions - a.total_emissions;
  });

  const dataChart: DataChart = categories.map((category, index: number) => {
    return {
      title: category.category,
      value: ((category.total_emissions / 1000) * 100) / totalEmissions,
      color: AVAILABLE_COLORS[index]
    };
  });

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader
          title={t('report.totalScope3')}
          description={t('report.totalScope3Description')}
        />
        <div className='total-scope3-card'>
          <div className='chart-container'>
            <PieChart
              data={totalEmissions ? dataChart : [{ title: '', value: 100, color: gray_color }]}
              lineWidth={24}
              totalValue={100}
              rounded
              animate
            />
            <div className='co2-information-scope3'>
              <div className='co2-value co2-dashboard-font-small'>
                {formatRoundNumber(totalEmissions)}
              </div>
              <span className='co2-units on-light-text-color font-12'>t CO₂ eq.</span>
            </div>
          </div>
          <div className='w-percentage-50'>
            <div className='card-info-columns'>
              <span className='font-10 weight-600' style={{ width: '70%' }}>
                {t('report.distribution')}
              </span>
              <div className='flex w-percentage-50 justify-around'>
                <span className='font-10 weight-600 text-left'>t CO₂ eq.</span>
              </div>
            </div>
            <div className='rows-wrapper on-card-gray-bg-color flex-col'>
              {categories.map((element, index: number) => (
                <div className='co2-information' key={element.category}>
                  <div
                    className='co2-value co2-dashboard-font-small flex justify-between font-10'
                    style={{ fontSize: '10px' }}>
                    <div className='w-percentage-50 flex items-center font-10'>
                      <div
                        className='dot round'
                        style={{ backgroundColor: AVAILABLE_COLORS[index] }}
                      />
                      <p>{t(`categories.${element.category}`)}</p>
                    </div>
                    <div className='flex w-percentage-50 justify-around '>
                      <p className='w-percentage-50 text-end'>
                        {element.total_emissions >= 0
                          ? formatRoundNumber(element.total_emissions / 1000)
                          : formatNumberToDecimalNonZero(element.total_emissions / 1000)}
                      </p>
                      <p className='highlight-text-color'>
                        {dataChart[index].value >= 0
                          ? formatRoundNumber(dataChart[index].value)
                          : formatNumberToDecimalNonZero(dataChart[index].value, 1)}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
