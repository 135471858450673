import { InputHTMLAttributes } from 'react';
import { InputSize } from '../../../../types/utilsEnums/input';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: InputSize;
}

const InputText = ({ maxLength = 100, size = InputSize.MEDIUM, className, ...props }: Props) => {
  const { t } = useTranslation();
  const sizeClassName = size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';
  const newClassName = `input-text ${sizeClassName} basic-input ` + className;
  const placeholder = props.placeholder || t('input.placeholder');

  return (
    <>
      <input className={newClassName} maxLength={maxLength} placeholder={placeholder} {...props} />
      {/* Percentage symbol overlay
      {symbol && (
        <span className={sizeClassName} style={{ paddingRight: '0.5rem' }}>
          {symbol}
        </span>
      )} */}
    </>
  );
};

export default InputText;
