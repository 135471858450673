import React, { HTMLAttributes, ReactNode } from 'react';
import './styles.scss';
interface Props extends HTMLAttributes<HTMLDivElement> {
  image: string | ReactNode;
  description: string | React.ReactNode;
  disabled?: boolean;
}

const InformationCardPlain = ({ image, description, disabled = false, ...rest }: Props) => {
  return (
    <div
      className={`how-to-export-information-card pointer solid-border ${
        disabled ? 'card-disabled-bg-color' : null
      }`}
      {...rest}>
      <div className='how-to-export-information-card__content'>
        {typeof image === 'string' ? (
          <img src={image} alt='how-to-export-information-card' className='client-image' />
        ) : (
          image
        )}
        <span className='body1-font'>{description}</span>
        <img src='/images/icons/chevronRight.svg' alt='chevron-right' className={`chevron`} />
      </div>
    </div>
  );
};

export default InformationCardPlain;
