import { useEffect, useState } from 'react';
import { currencySymbols } from '../../../../../../constants/currencySymbols';
import { getUnits } from '../../../../../../services/api/unit';

const useSectors = () => {
  const [units, setUnits] = useState<Unit[]>([]);

  const fetchData = async () => {
    const unitsData = await getUnits({ type: 'fiat_currency' });
    if (unitsData) {
      setUnits(unitsData.map((elem: Unit) => ({ id: elem.id, name: currencySymbols[elem.name] })));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return units;
};

export default useSectors;
