import { gray_color } from '../../../../../styles/colors';
import './styles.scss';

type Bar = {
  value: number;
  color: string;
};
type Props = {
  total: number;
  values: Bar[];
};
function HorizontalBar({ total, values }: Props) {
  return (
    <div className='bar-wrapper'>
      {values.map((elem, index) =>
        elem.value > 0 ? (
          <div
            className='bar'
            key={index}
            style={{
              width: `calc(${(elem.value * 100) / total}% + 4px)`,
              background: elem.color || gray_color,
              zIndex: values.length - index
            }}></div>
        ) : (
          <></>
        )
      )}
    </div>
  );
}

export default HorizontalBar;
