import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';
import Select from '../../select/Select';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children'>;
type SelectType = React.ComponentProps<typeof Select>;

type Props = SelectType & InputWrapperType;

const FormSelect = (props: Props) => {
  return (
    <InputWrapper {...props}>
      <Select {...props} />
    </InputWrapper>
  );
};

export default FormSelect;
