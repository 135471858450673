import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import Button from '../../ui/button/Button';
import './styles.scss';

function PremiumFeatureAdvise() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const [error] = useState('');

  const navigateToBudgetCalculator = () => {
    navigate(ROUTES.BUDGET_CALCULATOR);
  };

  if (!user || !selectedOrganization) return null;

  return (
    <div className='premium-feature-advise'>
      <img src='/images/woman.svg' alt='woman' />
      <h1 className='headline3-font'>{t('premiumFeatureAdvise.title')}</h1>
      <p className='subtitle3-font'>{t('premiumFeatureAdvise.description')}</p>
      <div
        className='buttons'
        style={{
          width: 175
        }}>
        <Button
          lookAndFeel='primary'
          onClick={navigateToBudgetCalculator}
          text={t('subscriptionReminder.talkToExpert')}
          size='small'
        />
      </div>
      {error && <span className='error-text error-font error-text-color'>{error}</span>}
    </div>
  );
}

export default PremiumFeatureAdvise;
