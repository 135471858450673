import React, { useContext } from 'react';
import NotificationBubble from '../../../../../ui/notificationBubbleWrapper/notificationBubble/NotificationBubble';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../context/userContext';

type Props = {
  data: NotificationBackend[];
};
const useStepsText = ({ data }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const user = useContext(UserContext);

  const tasks = user?.tasks ?? undefined;
  const tasksLength = tasks ? tasks.total - tasks.total_completed : 0;

  return [
    {
      id: 'all',
      text: (
        <div className='flex items-center gap-2' style={{ height: '18px' }}>
          {t('all')}{' '}
          {data.filter((elem) => !elem.read).length + tasksLength > 0 ? (
            <NotificationBubble animation=''>
              {data.filter((elem) => !elem.read).length + tasksLength}
            </NotificationBubble>
          ) : (
            <></>
          )}
        </div>
      )
    },
    {
      id: 'incidences',
      text: (
        <div className='flex items-center gap-2' style={{ height: '18px' }}>
          {t('incidences')}
        </div>
      )
    }
  ];
};

export default useStepsText;
