import { useCallback, useEffect, useState } from 'react';
import { Edge, EdgeChange, applyEdgeChanges } from '@xyflow/react';
import { buildEdgesFromBlock, buildEdges } from '../utils/builders';
import type { LCABlock } from '../../types/LCABlock';

type Params = {
  lcaBlocks: LCABlock[];
  isLoading: boolean;
};

export const useLCAEdges = ({ lcaBlocks }: Params) => {
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    if (lcaBlocks.length === 0) return;

    setEdges(lcaBlocks.flatMap(buildEdgesFromBlock));
  }, [lcaBlocks]);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((prevEdges) => {
      return buildEdges(applyEdgeChanges(changes, prevEdges));
    });
  }, []);

  return [edges, setEdges, onEdgesChange] as const;
};
