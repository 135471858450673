import React, { useState } from 'react';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import AddInvestmentToFundForm from '../../../common/addInvestmentToFundForm/FormInvestment';
import { FormConfigureDataType } from '../../../../../../types/entities/groupFunds';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import Button from '../../../../../ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { createInvestment, editInvestment } from '../../../../../../services/api/groupFunds';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../actions/notification';
import { replaceStrongTags } from '../../../../../../utils/stringToBold';
import {
  IBackendFundRelation,
  IBackendPatchFundRelationHistoric
} from '../../../../../../types/entities/organization';
import { adaptFundRelationPatchToFundRelation } from '../../../../../../adapters/matrices';
import ErrorText from '../../../../../ui/errorText/ErrorText';

type Props = {
  onSubmit: (data: IBackendFundRelation, fundName: string, investmentId?: string) => void;
  onClose: () => void;
  formDataFilled?: FormConfigureDataType;
  investmentId?: string;
  fundId?: string;
};
const UpdateInvestment = ({ onSubmit, onClose, formDataFilled, investmentId, fundId }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState<FormConfigureDataType>(
    formDataFilled ?? {
      fund: {
        id: '',
        name: ''
      },
      investment: '',
      isDebtFinancing: false,
      companyValue: '',
      investmentDate: '',
      tag: '',
      errors: []
    }
  );

  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async () => {
    const optionalFields = ['tag', 'isDebtFinancing'];

    const newErrors = checkFormErrors(formData, [], optionalFields);
    if (Number(formData.investment) > Number(formData.companyValue)) {
      newErrors.push({
        error: 'investment',
        description: t('configureGroupFunds.investmentGreaterThanCompanyValue')
      });
    }
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    setLoadingButton(true);
    if (!formDataFilled && !investmentId) {
      const data = await createInvestment(id ?? '', formData);
      if (!data) {
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'general', description: t('error.somethingWentWrong') }]
        }));
        return;
      }
      onSubmit(data, formData.fund.name);
    } else {
      const data: IBackendPatchFundRelationHistoric = await editInvestment(
        investmentId ?? '',
        id ?? '',
        formData
      );

      if (!data) {
        setFormData((prev) => ({
          ...prev,
          errors: [{ error: 'general', description: t('error.somethingWentWrong') }]
        }));
        return;
      }

      onSubmit(
        adaptFundRelationPatchToFundRelation({
          ...data,
          fund_id: formData.fund.id
        }),
        formData.fund.name,
        investmentId
      );
    }

    setLoadingButton(false);

    dispatch(setNotification(t('notification.companyConfigured')));
  };

  const generalError = formData.errors.find((error) => error.error === 'general');

  if (!id) return null;
  return (
    <div>
      <FormHeader
        title={t('companyDetail.updateInvestment.title')}
        description={replaceStrongTags(
          t('companyDetail.updateInvestment.description', {
            replace: {
              name: searchParams.get('name') ?? ''
            }
          })
        )}
      />
      <AddInvestmentToFundForm
        formData={formData}
        setFormData={setFormData}
        organizationId={id}
        fundId={fundId}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='secondary'
          text={t('companyDetail.updateInvestment.cancel')}
          size='small'
          onClick={onClose}
        />
        <Button
          lookAndFeel='primary'
          onClick={handleSubmit}
          loading={loadingButton}
          text={t('companyDetail.updateInvestment.save')}
          size='small'
        />
      </FormButtonSection>
      {generalError && (
        <ErrorText style={{ marginTop: '1rem' }}>{generalError.description}</ErrorText>
      )}
    </div>
  );
};

export default UpdateInvestment;
