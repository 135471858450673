import './styles.scss';
type Props = {
  value: string;
  id: string;
  fontClass?: string;
  onRemove: (id: string) => void;
};
function MultiSelectValue({ value, id, fontClass = 'input-font', onRemove }: Props) {
  const handleOnRemove = () => {
    onRemove(id);
  };
  return (
    <div className={`multi-select-value ${fontClass}`} key={id}>
      {value}
      <img src='/images/icons/closeBlue.svg' alt='close-icon' onClick={handleOnRemove} />
    </div>
  );
}

export default MultiSelectValue;
