import { useTranslation } from 'react-i18next';
import './styles.scss';

const OffsetDataImage = () => {
  const { i18n } = useTranslation();
  const src = `/images/offset/offset${i18n.resolvedLanguage}.png`;
  return (
    <>
      <img style={{ width: '100%' }} className='image-offseting' src={src} alt='offset' />
    </>
  );
};

export default OffsetDataImage;
