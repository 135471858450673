import { LDProvider as LDContext } from 'launchdarkly-react-client-sdk';
import controller from '../../environments/controller';
import { User } from '../../types/entities/user';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const environment = controller();

export const LDProvider: React.FC = ({ children }) => {
  const user: User | null = useSelector((state: any) => state.auth.user, _.isEqual);

  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const context = foundOrganization
    ? {
        kind: 'multi',
        organization: {
          key: foundOrganization?.id,
          name: foundOrganization?.company_name,
          sector: foundOrganization?.sector,
          partner: foundOrganization?.partner
        },
        user: {
          key: user?.id,
          user_email: user?.email
        }
      }
    : undefined;

  return (
    <LDContext clientSideID={environment.ldClientSideID} context={context}>
      {children}
    </LDContext>
  );
};
