import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import exportAsImage from '../../../utils/exportAsImage';
import Button from '../../ui/button/Button';
import CardDownload from '../../ui/cards/cardDownload/CardDownload';
import Modal from '../../ui/modal/Modal';
import './styles.scss';
import SnippetPreview from './SnippetPreview';
type Props = {
  htmlFile: string;
  code: string;
  name: string;
  lcaName: string;
  lang: string;
  font: string;
  showComparison: boolean;
};
const DownloadCard = ({ htmlFile, code, name, lcaName, lang, font, showComparison }: Props) => {
  const [showModal, setShowModal] = useState(false);

  // make string code first letter uppercase
  const codeFirstLetterUppercase = code.charAt(0).toUpperCase() + code.slice(1);

  const openHtmlInModal = () => {
    setShowModal(true);
  };

  const hideModal = () => setShowModal(false);

  const htmlParsed = htmlFile.replaceAll('Inter', font).replaceAll('Roboto', font);
  // .replaceAll('https://dev-app.dcycle.io', 'http://localhost:3000');

  return (
    <div className='snippet-download-card'>
      <CardDownload
        handleDownloadFile={openHtmlInModal}
        img={'/images/defaultLcaSnippet.svg'}
        title={`${lcaName}_${codeFirstLetterUppercase}_${lang.toUpperCase()}_${name}_snippet`}
        type={''}
        date={''}
      />
      <Modal show={showModal} onClose={hideModal} width={showComparison ? '1000px' : '400px'}>
        <SnippetPreview
          htmlContent={htmlParsed}
          showComparison={showComparison}
          lcaName={lcaName}
          codeFirstLetterUppercase={codeFirstLetterUppercase}
          lang={lang}
          name={name}
        />
      </Modal>
    </div>
  );
};

export default DownloadCard;
