import BarReport from '../barReport/BarReport';
import './styles.scss';

type Props = {
  data: {
    id: string;
    name: string;
    value: number;
    total: number;
    image: {
      src: string;
      alt: string;
    };
  }[];
};

const BarSection = ({ data }: Props) => {
  const sort = (a: (typeof data)[number], b: (typeof data)[number]) => {
    if (a.id === 'remaining') return 1;
    if (b.id === 'remaining') return -1;
    return b.value - a.value;
  };

  return (
    <div className='vertical-bars on-card-gray-bg-color'>
      {data.sort(sort).map((elem) => (
        <BarReport key={elem.name} {...elem} />
      ))}
    </div>
  );
};

export default BarSection;
