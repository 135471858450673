import { useTranslation } from 'react-i18next';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import Button from '../../../../../ui/button/Button';

type Props = {
  closeModal: () => void;
};

const ModalNoFunds = ({ closeModal }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='organization-description-form'>
      <FormHeader
        title={t(`orgDescriptionModal.paiReport.noLinkTitle`)}
        description={t(`orgDescriptionModal.paiReport.noLink`)}
      />
      <Button
        lookAndFeel={'primary'}
        onClick={closeModal}
        text={t(`orgDescriptionModal.paiReport.back`)}
        size='medium'
      />
    </div>
  );
};

export default ModalNoFunds;
