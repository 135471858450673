import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useModal from '../hooks/useModal';

export const ModalC14 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });

  const mandatory = ['marketAndLocation'];
  const { formData, setFormData, loading, patchCriteria, loadingButton, errorPatch } =
    useModal(mandatory);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  return (
    <>
      <FormHeader title={t('title')} />
      {loading && (
        <CustomSkeletonLoader
          count={1}
          style={{
            marginBottom: '2rem'
          }}
        />
      )}

      {!loading && (
        <FormWrapper>
          <FormSelect
            tooltip={t('tooltipModalC14')}
            label={t('c14Modal')}
            iconV2='multitag'
            onChange={onChangeValue('marketAndLocation')}
            value={
              formData.marketAndLocation || {
                id: '',
                name: ''
              }
            }
            options={[
              { id: 'market', name: t('market') },
              { id: 'location', name: t('location') },
              { id: 'both', name: t('both') }
            ]}
            error={formData.errors.find((error) => error.error === 'marketAndLocation')}
          />
        </FormWrapper>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('save')}
          loading={loadingButton}
          size='small'
          onClick={() => patchCriteria(formData)}
        />
      </FormButtonSection>
      <ErrorText>{errorPatch && errorPatch.description}</ErrorText>
    </>
  );
};
