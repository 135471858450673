import apiFetch from '../../utils/apiFetch';

export const getOrganizationModelingSbti = async ({ id }: { id: string }) => {
  try {
    const response = await apiFetch('GET', `/sbti/scenario_modeling/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const putReductionPercentageSbti = async ({
  id,
  category,
  value
}: {
  id: string;
  category: string;
  value: string;
}) => {
  try {
    const response = await apiFetch('PATCH', `/sbti/scenario_modeling/${id}`, {
      framework_categories_id: category,
      reduction_percentage: value
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const putReductionActionSbti = async ({
  id,
  category,
  value
}: {
  id: string;
  category: string;
  value: string;
}) => {
  try {
    const response = await apiFetch('PATCH', `/sbti/scenario_modeling/${id}`, {
      framework_categories_id: category,
      reduction_description: value
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getOrganizationGoalSbti = async ({ id }: { id: string }) => {
  try {
    const response = await apiFetch('GET', `/sbti/organization_goals/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
