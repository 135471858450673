import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  fifth_color,
  scope1_color,
  scope2_color,
  scope3_color
} from '../../../../../../../styles/colors';
import numberToDecimal from '../../../../../../../utils/numberToDecimal';
import TooltipChart from '../../../../../../ui/tooltip/TooltipChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function StackedBarLineChart({ labels, datasets }) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        position: 'bottom',
        grid: {
          display: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: function (context) {
            return {
              size: 10,
              family: 'Inter',
              weight: '400',
              lineHeight: 1.2
            };
          },
          padding: 0,
          margin: 0
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: {
            size: 0,
            family: 'Inter',
            weight: '400',
            lineHeight: 1.2
          }
        }
      }
    },
    hover: {
      onHover: (e) => {
        var el = document.getElementById('canvas1');
        el.style.cursor = e[0] ? 'pointer' : 'default';
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          const disabled = false; // remove

          if (chartRef && chartRef.current) {
            const tooltip = chartRef.current.tooltip;
            if (tooltip.opacity === 0) {
              setShowTooltip(false);
            } else {
              // assuming your tooltip is `position: fixed`
              // set position of tooltip
              const position = context.chart.canvas.getBoundingClientRect();
              const left = position.left + window.pageXOffset + tooltip.caretX;
              const top = position.top + window.pageYOffset + tooltip.caretY - 20;

              setLeft(left);
              setTop(top);
              if (disabled) {
                setValue('0 kg CO₂ eq.');
                setShowTooltip(true);
              } else {
                let value = tooltip.dataPoints[0].raw;
                value = value / 1000;
                let unit = 't CO₂ eq.';
                setValue(`${numberToDecimal(value)} ${unit}`);
                setShowTooltip(true);
              }
            }
          }
        }
      }
    },
    font: {
      size: 10,
      family: 'Inter',
      weight: '400',
      lineHeight: 1.2
    }
  };

  const data = {
    labels,
    datasets: [
      {
        data: datasets.line1,
        backgroundColor: fifth_color,
        borderColor: fifth_color
      },
      {
        data: datasets.line2,
        backgroundColor: fifth_color,
        borderColor: fifth_color,
        borderDash: [5, 5] // Set borderDash to create a dotted line
      },
      {
        data: datasets.scope1,
        backgroundColor: scope1_color,
        barThickness: 20,
        borderRadius: 8,
        type: 'bar',
        stack: 'combined'
      },
      {
        data: datasets.scope2,
        backgroundColor: scope2_color,
        barThickness: 20,
        borderRadius: 8,
        type: 'bar',
        stack: 'combined'
      },
      {
        data: datasets.scope3,
        backgroundColor: scope3_color,
        barThickness: 20,
        borderRadius: 8,
        type: 'bar',
        stack: 'scope3'
      },
      {
        data: datasets.scope1Mocked,
        backgroundColor: `${scope1_color}70`,
        barThickness: 20,
        borderRadius: 8,
        type: 'bar',
        stack: 'combined'
      },
      {
        data: datasets.scope2Mocked,
        backgroundColor: `${scope2_color}70`,
        barThickness: 20,
        borderRadius: 8,
        type: 'bar',
        stack: 'combined'
      },
      {
        data: datasets.scope3Mocked,
        backgroundColor: `${scope3_color}70`,
        barThickness: 20,
        borderRadius: 8,
        type: 'bar',
        stack: 'scope3'
      }
    ]
  };

  return (
    <>
      {showTooltip && <TooltipChart text={value} top={top} left={left} />}
      <Line
        options={options}
        data={data}
        ref={chartRef}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </>
  );
}
