import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = (transportDirection: string) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('shipments.description'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('shipments.supplier'),
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: t(`shipments.${transportDirection}.startDate`),
      dataIndex: 'start_date',
      key: 'start_date'
    },
    {
      title: t('shipments.quantity'),
      dataIndex: 'quantity_transported',
      key: 'quantity_transported'
    },
    {
      title: t('shipments.originDestination'),
      dataIndex: 'origin_destination',
      key: 'origin_destination'
    },
    {
      title: t('shipments.transportType'),
      dataIndex: 'transport_route_type',
      key: 'transport_route_type'
    },
    {
      title: t('shipments.frequency'),
      dataIndex: 'transport_frequency',
      key: 'transport_frequency'
    },
    {
      title: t('shipments.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: <div style={{ textAlign: 'center' }}>CO₂ eq</div>,
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
