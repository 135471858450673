import { useTranslation } from 'react-i18next';
import './styles.scss';
import Table from '../../../../ui/table/Table';
import useColumns from './hooks/useColumns';
import useGetData from './hooks/useGetData';
import { useContext } from 'react';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import {
  formatNumberToDecimalNonZero,
  numberToDecimalNonZero
} from '../../../../../utils/numberToDecimal';

function List() {
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const { t } = useTranslation();

  const columns = useColumns();

  const { hocsInfo } = useGetData({ startDate, endDate });

  return (
    <div className='card dashboard-card'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>{t('dashboardLogistic.impactByHub')}</h4>
      </div>
      <div className='dashboard-card__body dashboard-card__body--list'>
        <div className='list-wrapper'>
          <Table
            columns={columns}
            data={hocsInfo.map((elem) => ({
              hub: elem.hub_name,
              intensityValue: (
                <div style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.intensity_value.value)}
                </div>
              ),
              geiTotalEmissions: (
                <div className='highlight-text-color weight-600' style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.emissions.value)}
                </div>
              ),
              managedTons: (
                <div style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.tonne.value)}
                </div>
              )
            }))}
            loading={false}
            size={'small'}
          />
        </div>
      </div>
    </div>
  );
}

export default List;
