import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { scope1_color, scope2_color } from '../../../../../styles/colors';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import moment from 'moment';
import BarChartComparison from '../../../../ui/charts/barChartComparison/BarChartComparison';
import Tag from '../../../../ui/tag/Tag';
import { formatDate } from '../../../../../utils/formatDate';
import { formatNumberToDecimalNonZero } from '../../../../../utils/numberToDecimal';
import Label from '../../../../ui/label/Label';

const YearComparisonFuel = () => {
  const { t } = useTranslation();
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const getColor = (fuel: string) => {
    if (fuel === 'electric') return scope2_color;
    else return scope1_color;
  };

  const dataChart = [
    [
      {
        label: t('dashboard.total'),
        value: 75,
        color: 'gradient'
      },
      {
        label: t('templates.logistics_recharges.fuel_types.gasoline'),
        value: 20,
        color: getColor('gasoline')
      },
      {
        label: t('templates.logistics_recharges.fuel_types.b_5'),
        value: 30,
        color: getColor('b_5')
      },
      {
        label: t('templates.logistics_recharges.fuel_types.electric'),
        value: 15,
        color: getColor('electric')
      }
    ],
    [
      {
        label: t('dashboard.total'),
        value: 70,
        color: 'gradient'
      },
      {
        label: t('templates.logistics_recharges.fuel_types.gasoline'),
        value: 40,
        color: getColor('gasoline')
      },
      {
        label: t('templates.logistics_recharges.fuel_types.b_5'),
        value: 15,
        color: getColor('b_5')
      },
      {
        label: t('templates.logistics_recharges.fuel_types.electric'),
        value: 15,
        color: getColor('electric')
      }
    ]
  ];

  const co2CurrentYear = {
    scope1: 50,
    scope2: 15
  };
  const co2PrevYear = {
    scope1: 55,
    scope2: 15
  };

  const co2CurrentYearTotal = co2CurrentYear.scope1 + co2CurrentYear.scope2;
  const co2PrevYearTotal = co2PrevYear.scope1 + co2PrevYear.scope2;

  const co2Delta = {
    scope1:
      co2CurrentYear.scope1 > 0 && co2PrevYear.scope1 > 0
        ? (co2CurrentYear.scope1 - co2PrevYear.scope1) / co2PrevYear.scope1
        : 0,
    scope2:
      co2CurrentYear.scope2 > 0 && co2PrevYear.scope2 > 0
        ? (co2CurrentYear.scope2 - co2PrevYear.scope2) / co2PrevYear.scope2
        : 0,
    total:
      co2CurrentYearTotal > 0 && co2PrevYearTotal > 0
        ? (co2CurrentYearTotal - co2PrevYearTotal) / co2PrevYearTotal
        : 0
  };

  const startDatePreviousYear = moment(startDate, 'DD/MM/YYYY').subtract(1, 'year').toDate();
  const endDatePreviousYear = moment(endDate, 'DD/MM/YYYY').subtract(1, 'year').toDate();

  const renderDelta = (value: number) => {
    return (
      <Label lookAndFeel={value > 0 ? 'secondary' : 'success'}>
        <div className='icon-text-wrapper'>
          <img
            src={
              value > 0
                ? '/images/icons/arrowUpSecondary.svg'
                : '/images/icons/arrowDownPrimary.svg'
            }
            alt='arrow'
          />
          <span>
            {value > 0 ? '+' : ''}
            {formatNumberToDecimalNonZero(value * 100)}%
          </span>
        </div>
      </Label>
    );
  };
  return (
    <div className='dashboard-card card'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>
          {t('dashboardLogistic.fuelYearComparison')}
        </h4>
      </div>
      <div className='flex gap-1 small-device-flex' style={{ marginLeft: '1rem' }}>
        <Tag
          tags={[
            {
              icon1: '',
              text: <span className='on-light-text-color tag-font'>{t('dashboard.total')}</span>,
              text2: renderDelta(co2Delta.total)
            }
          ]}
        />
        <Tag
          tags={[
            {
              icon1: '/images/icons/dotScope1.svg',
              text: (
                <span className='on-light-text-color tag-font'>{t('dashboard.scope1Short')}</span>
              ),
              text2: renderDelta(co2Delta.scope1)
            }
          ]}
        />
        <Tag
          tags={[
            {
              icon1: '/images/icons/dotScope2.svg',
              text: (
                <span className='on-light-text-color tag-font'>{t('dashboard.scope2Short')}</span>
              ),
              text2: renderDelta(co2Delta.scope2)
            }
          ]}
        />
      </div>
      <div className='content-wrapper'>
        <BarChartComparison chartData={dataChart} disabled={undefined} />
        <div className='dates-legend'>
          <Tag
            tags={[
              {
                icon1: '',
                text: (
                  <span className='on-light-text-color tag-font'>
                    {formatDate(startDatePreviousYear)} - {formatDate(endDatePreviousYear)}
                  </span>
                )
              }
            ]}
          />
          <Tag
            tags={[
              {
                icon1: '',
                text: (
                  <span className='on-light-text-color tag-font'>
                    {startDate} - {endDate}
                  </span>
                )
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default YearComparisonFuel;
