import { useTranslation } from 'react-i18next';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Button from '../../../ui/button/Button';
import IconBackground from '../../../ui/iconBackground/IconBackground';
import './styles.scss';
type Props = {
  optionSelectedTitle: string;
  optionSelectedDescription: string;
  projectSelectedTitle: string;
  projectSelectedDescription: string;
  co2: number;
  price: number;
  handleSubmit: () => void;
  loadingButton: boolean;
};
function OffsetSummary({
  optionSelectedTitle,
  optionSelectedDescription,
  projectSelectedTitle,
  projectSelectedDescription,
  co2,
  price,
  handleSubmit,
  loadingButton
}: Props) {
  const { t } = useTranslation();
  let co2Parsed = co2;
  let unit = 'kg CO₂ eq.';
  if (co2 >= 1000) {
    co2Parsed = co2 / 1000;
    unit = 't CO₂ eq.';
  }
  return (
    <div className='offset-summary'>
      <img
        className='image-header'
        src='/images/icons/offsetSummaryHeader.svg'
        alt='Offset Summary'
      />
      <div className='offset-summary-content'>
        <h1 className='headline3-font'>{t('offset.offsetSummary')}</h1>
        <p className='subtitle3-font'>{t('offset.offsetSummaryDescription')}</p>
        <div className='offset-summary-characteristic'>
          <IconBackground icon={'/images/icons/windmill.svg'} alt={'windmill'} />
          <div className='characteristic-content'>
            <h2 className='headline4-font'>{optionSelectedTitle}</h2>
            <p className='subtitle3-font'>{optionSelectedDescription}</p>
          </div>
        </div>
        <div className='offset-summary-characteristic'>
          <IconBackground icon={'/images/icons/thunder50.svg'} alt={'thunder'} />
          <div className='characteristic-content'>
            <h2 className='headline4-font'>{projectSelectedTitle}</h2>
            <p className='subtitle3-font'>{projectSelectedDescription}</p>
          </div>
        </div>
        <div className='offset-summary-characteristic'>
          <IconBackground icon={'/images/icons/bookmark.svg'} alt={'bookmark'} />
          <div className='characteristic-content'>
            <h2 className='headline4-font'>{t('offset.certificate')}</h2>
            <p className='subtitle3-font'>{t('offset.certificateDescription')}</p>
          </div>
        </div>
        <div className='offset-summary-pricing'>
          <div className='offset-total'>
            <span className='highlight-text-color headline4-font'>
              {numberToDecimal(co2Parsed)}
            </span>
            <span className='body1-font'>{unit}</span>
          </div>
          <div className='offset-prices'>
            <div className='offset-price-per-kg'>
              <span className='headline4-font'>{price}</span>
              <span className='body1-font'>€ ton</span>
            </div>
            <div>
              <span className='headline4-font'>
                {co2 / 1000 < 10
                  ? numberToDecimal((co2 / 1000) * price) + 200
                  : numberToDecimal((co2 / 1000) * price)}
              </span>
              <span className='body1-font'>€ total</span>
            </div>
          </div>
          {co2 / 1000 < 10 && (
            <span className='body1-font management-cost'>{t('offset.managementCost')}</span>
          )}
        </div>
        <Button
          lookAndFeel={'primary'}
          text={t('offset.offsetSummaryButton')}
          onClick={handleSubmit}
          size='medium'
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default OffsetSummary;
