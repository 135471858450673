import { CustomEdge } from './components/CustomEdge';
import { MaterialNode } from './components/CustomNodes/MaterialNode';
import { PieceNode } from './components/CustomNodes/PieceNode';
import { ProcessNode } from './components/CustomNodes/ProcessNode';
import { NODE_TYPES } from './constants';

export const NODE_COMPONENTS = {
  [NODE_TYPES.material]: MaterialNode,
  [NODE_TYPES.process]: ProcessNode,
  [NODE_TYPES.piece]: PieceNode,
  [NODE_TYPES.product]: PieceNode
};

export const EDGE_TYPES = {
  lcaEdge: CustomEdge
};
