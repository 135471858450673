import { ReactElement } from 'react';
import './styles.scss';

type TagContent = {
  title?: ReactElement;
  info?: ReactElement;
};

type Props = {
  tags: TagContent[];
};
function TagCompanyImpact({ tags }: Props) {
  return (
    <div className='tag-wrapper'>
      {tags.map((tag, index) => {
        return (
          <div key={`tag-${index}`}>
            {tag.title && <div className='title'>{tag.title}</div>}
            {tag.info && <div className='info'>{tag.info}</div>}
          </div>
        );
      })}
    </div>
  );
}

export default TagCompanyImpact;
