import { useTranslation } from 'react-i18next';
import useYearOptions from '../../../../../../../../customHooks/useYearOptions';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { FormDataStateESG } from '../ModalDatapoint';

type Props = {
  formDataState: FormDataStateESG;
};

export const YearComponent = ({ formDataState }: Props) => {
  const { t } = useTranslation();
  const yearOptions = useYearOptions();
  const { formData, setFormData } = formDataState;

  const parseValue =
    typeof formData?.value === 'string'
      ? { id: formData.value, name: formData.value }
      : { id: '', name: '' };

  return (
    <>
      <FormWrapper>
        <FormSelect
          icon='/images/icons/calendar.svg'
          label={t('selectYear')}
          onChange={(e) => setFormData({ ...formData, value: e.id })}
          value={parseValue}
          options={yearOptions.years}
          error={formData.errors.find((error) => error.error === 'year')}
          sort={false}
        />
      </FormWrapper>
    </>
  );
};
