import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import SlideToggle from '../../../../ui/formComponents/slideToggle/SlideToggle';
import FormGoogleMaps from '../../../../ui/formComponents2/formInputs/formGoogleMaps/FormGoogleMaps';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import Label from '../../../../ui/label/Label';
import SelectCardWrapper from '../../../../ui/selectCard/SelectCardWrapper';
import useCards from './hooks/useCards';
import useGetData from './hooks/useGetData';

type Props = {
  formData: FormDataHubs;
  setFormData: (value: any) => void;
  disabledFields?: string[];
};
const EditHubForm = ({ formData, setFormData, disabledFields }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'hubs' });
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const cards = useCards();

  const { hocOptions } = useGetData();

  const onClickCard = (id: string) => {
    setFormData((prev: any) => ({
      ...prev,
      type: id,
      errors: formData.errors.filter((elem: ErrorType) => elem.error !== 'type')
    }));
  };

  return (
    <>
      <div className='vehicle-type'>
        <h3 className='font-10 weight-600'>{t('hubForm.ownership')}</h3>
        <SelectCardWrapper
          mode='create'
          cards={
            disabledFields?.find((elem) => elem === 'type')
              ? cards.filter((elem) => elem.id === formData.type)
              : cards
          }
          onClickCard={onClickCard}
          error={formData.errors.find((elem) => elem.error === 'type')}
          type={
            cards.find((elem) => elem.id === formData.type) ?? {
              id: '',
              icon: '',
              title: ''
            }
          }
        />
      </div>
      <FormWrapper>
        <FormText
          label={t('hubForm.name')}
          placeholder={t('hubForm.namePlaceholder')}
          value={formData.name}
          onChange={
            disabledFields?.find((elem) => elem === 'name') ? () => null : onChangeValue('name')
          }
          error={formData.errors.find((elem) => elem.error === 'name')}
          disabled={disabledFields?.includes('name') ?? false}
        />
        <FormSelect
          label={t('hubForm.category')}
          placeholder={t('hubForm.categoryPlaceholder')}
          options={hocOptions}
          value={formData.hubCategory}
          onChange={onChangeValue('hubCategory')}
          error={formData.errors.find((elem) => elem.error === 'hubCategory')}
          disabled={disabledFields?.includes('category') ?? false}
        />

        {formData.isSuperCharger ? (
          <FormElementFull>
            {formData.type !== 'subcontracted' && (
              <SlideToggle
                label={t('isSupercharger')}
                checked={formData.isSuperCharger ? formData?.isSuperCharger : false}
                setChecked={onChangeValue('isSuperCharger')}
                tooltipText={t('isSuperchargerTooltipSwitch')}
                disabled={true}
              />
            )}
          </FormElementFull>
        ) : (
          <FormElementFull>
            <Label lookAndFeel='secondary'>
              <div
                className='subtitle3-font'
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <img src='/images/icons/info.svg' alt='info' width={16} height={16} />
                {t('hubForm.disclaimer')}
              </div>
            </Label>
          </FormElementFull>
        )}
        <FormGoogleMaps
          label={t('hubForm.address')}
          placeholder={t('hubForm.addressPlaceholder')}
          onChange={onChangeValue('address')}
          value={formData.address}
          error={formData.errors.find((elem) => elem.error === 'address')}
          disabled={disabledFields?.includes('address') ?? false}
        />
      </FormWrapper>
    </>
  );
};

export default EditHubForm;
