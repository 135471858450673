import {
  IHeavyFileStatusBackend,
  IHeavyFileStatusFrontend
} from '../types/entities/heavyFilesStatus';
import { convertDateBackToFront, convertDateBackToFrontWithTime } from '../utils/convertDates';

export const adaptHeavyFilesStatusBackToFront = (
  value: IHeavyFileStatusBackend
): IHeavyFileStatusFrontend => {
  return {
    id: value.heavy_file_id,
    name: value.name,
    status: value.status,
    uploadedBy: value.uploaded_by,
    deletedBy: value.deleted_by,
    deletedAt: convertDateBackToFrontWithTime(value.deleted_at),
    createdAt: convertDateBackToFront(value.created_at),
    url: value.file_url ?? '',
    errorInfo: value.error_info
  };
};
