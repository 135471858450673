import { useInfiniteQuery } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getLCAMaterials, LCAMaterial } from '../../../../services/api/lca/lcaPortfolio';
import { IFilter } from '../../../../utils/url';

type Params = {
  filters?: IFilter[];
};
export const useLCAMaterials = ({ filters }: Params = { filters: undefined }) => {
  const org = useSelectedOrganization();

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['lca-materials', org?.id, JSON.stringify(filters)],
    queryFn: ({ pageParam }) => getLCAMaterials({ page: pageParam, filters }),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * 10 >= lastPage.total) {
        return undefined;
      }
      return lastPage.page + 1;
    },
    initialPageParam: 1
  });

  return {
    data: data?.pages.reduce((acc, page) => {
      return [...acc, ...page.items];
    }, [] as LCAMaterial[]),
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  };
};
