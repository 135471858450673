import { createPortal } from 'react-dom';
import Icon from '../../ui/icon/Icon';
import { ReactNode, useState } from 'react';
import { ModalContext } from './ModalContext';
import { useModalContext } from './useModalContext';

import './sideModal.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title?: ReactNode;
};

const Base: React.FC<Props> = ({ children, isOpen, setIsOpen, title }) => {
  return createPortal(
    <div className={`side-modal__wrapper ${isOpen ? 'in' : ''}`}>
      <div className='side-modal__header'>
        {title && <h4 className='headline4-font'>{title}</h4>}
        <span className='ml-auto pointer'>
          <Icon icon='close' onClick={() => setIsOpen(false)} color='gray-dark' />
        </span>
      </div>
      <div className={`side-modal ${isOpen ? 'in' : ''}`}>{children}</div>
    </div>,
    document.body
  );
};

const Root: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen
      }}>
      {children}
    </ModalContext.Provider>
  );
};

type SideModalProps = {
  children: ReactNode;
  title?: ReactNode;
};

export const SideModal = ({ children, title }: SideModalProps) => {
  const { isOpen, setIsOpen } = useModalContext();

  return (
    <Base isOpen={isOpen} setIsOpen={setIsOpen} title={title}>
      {children}
    </Base>
  );
};

const FullHeightBase: React.FC<Props> = ({ children, isOpen, setIsOpen, title }) => {
  return createPortal(
    <div
      className={`side-modal__wrapper side-modal__wrapper__full-height bg-neutral-gray-80 ${
        isOpen ? 'in' : ''
      }`}>
      <div className='side-modal__header'>
        {title && <h4 className='headline4-font'>{title}</h4>}
        <span className='ml-auto pointer'>
          <Icon icon='close' onClick={() => setIsOpen(false)} color='disabled' size='medium' />
        </span>
      </div>
      <div className={`side-modal pt-6 ${isOpen ? 'in' : ''}`}>{children}</div>
    </div>,
    document.body
  );
};

SideModal.Root = Root;
SideModal.Base = Base;
SideModal.FullHeightBase = FullHeightBase;
