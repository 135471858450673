import apiFetch from '../../utils/apiFetch';

const URL = '/nuvo_analytics';

type PostProps = {
  numberOfRows: number;
  fileName: string;
  category: string;
};

export const postNuvoAnalytics = async ({ numberOfRows, fileName, category }: PostProps) => {
  try {
    const response = await apiFetch('POST', URL, {
      number_of_rows: numberOfRows,
      file_name: fileName,
      category
    });
    return response;
  } catch (error) {
    return error;
  }
};
