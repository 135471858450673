import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/userContext';
import { getFundsQuestions } from '../../../../../../services/api/funds';
import { FundsQuestions } from '../../../../../../types/entities/funds';

const useGetData = () => {
  const [questions, setQuestions] = useState<SelectOptionFormat[]>([]);
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);

  useEffect(() => {
    const getData = async () => {
      if (!user?.selectedOrganization) return;

      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await getFundsQuestions();
      setLoading(false);
      if (!response) return;

      setQuestions(
        response.map((element: FundsQuestions) => ({
          id: element.id,
          name: t(`funds.questions.${element.pai_extra_question_code}`)
        }))
      );
    };

    getData();
  }, []);

  return { questions, loading };
};

export default useGetData;
