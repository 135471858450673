import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../../context/userContext';
import { getDatadisContractDetail } from '../../../../../../../services/api/datadis';
import { DatadisCUPS, DatadisContract } from '../../../../../../../types/entities/datadisContract';
import { getIntervalOptions } from './utils';

type Props = {
  nif: string;
  cups?: DatadisCUPS;
};

const useGetContracts = ({ nif, cups }: Props) => {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const [contractsData, setContractData] = useState<DatadisContract[]>();
  const [loading, setLoading] = useState(false);

  const getContractData = async () => {
    if (!user?.selectedOrganization || !cups) return;
    setLoading(true);
    const response = await getDatadisContractDetail(
      nif,
      cups.cups,
      cups.distributor_code,
      user?.selectedOrganization
    );

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    setContractData(response);
    setLoading(false);
  };

  useEffect(() => {
    getContractData();
  }, [cups, nif]);

  const dateIntervalsOptions = useMemo(
    () => getIntervalOptions(t('datadisContracts.now'), contractsData),
    [contractsData]
  );

  return { contractsData, dateIntervalsOptions, loading };
};

export default useGetContracts;
