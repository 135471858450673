import { ReactNode } from 'react';
import PoweredByDcycle from '../../../../layout/poweredByDcycle/PoweredByDcycle';

import DcycleFooter from './components/dcycleFooter/DcycleFooter';
import SocialLinks from './components/socialLinks/SocialLinks';
import './styles.scss';

type Props = {
  children: ReactNode;
  org_name: string;
  icon?: string;
  backgroundImage?: string;
  lookAndFeel?: 'dark' | 'white';
  logo_url?: string;
  newPage?: boolean;
  page: number;
  isGlec?: boolean;
  total: number;
};

const Layout = ({
  org_name,
  backgroundImage,
  lookAndFeel = 'white',
  logo_url,
  newPage = true,
  page,
  total,
  icon,
  isGlec,
  children
}: Props) => {
  let background = 'white';

  if (lookAndFeel === 'dark') {
    background = 'linear-gradient(180deg, #000000 0%, rgba(25, 42, 62, 0.8) 100%)';
  }
  if (backgroundImage) {
    background = `url("${backgroundImage}")`;
  }

  const darkStyle = lookAndFeel === 'dark';

  const renderCompanyLogo = () => {
    if (logo_url) {
      return (
        <img className='logo' src={logo_url && logo_url} alt='logo' width={40} height='auto' />
      );
    }

    return (
      <span className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}>{org_name}</span>
    );
  };

  const renderPoweredBy = () => {
    return <PoweredByDcycle darkStyle={darkStyle} position='free' size='small' />;
  };

  return (
    <section
      className={`ghg-layout ${newPage ? 'page-break' : ''}`}
      style={{ background: background, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      {/* {(Boolean(org_name) || Boolean(icon)) && ( */}
      {Boolean(org_name) && (
        <div className='report-header flex justify-between items-center' style={{ width: '100%' }}>
          {renderCompanyLogo()}
          {renderPoweredBy()}
        </div>
      )}

      {!org_name && !icon && process.env.REACT_APP_PARTNER === 'dcycle' && (
        <SocialLinks darkStyle={darkStyle} />
      )}

      {children}

      {process.env.REACT_APP_PARTNER === 'dcycle' ? (
        <DcycleFooter page={page} total={total} darkStyle={darkStyle} isGlec={isGlec} />
      ) : (
        <PoweredByDcycle darkStyle={darkStyle} />
      )}
    </section>
  );
};

export default Layout;
