import { EmployeeReport } from '../../../../../../types/entities/employee';

const getTotalCo2e = (employees: EmployeeReport[]) => {
  let total = 0;
  employees.forEach((employee) => (total += employee.co2e));

  return total;
};

export default getTotalCo2e;
