import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../../../context/userContext';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import useYearOptions from '../../../../../customHooks/useYearOptions';
import { updateIntensityMetric } from '../../../../../services/api/intensityMetrics';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import numberToDecimal from '../../../../../utils/numberToDecimal';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import {
  convertDateBackToFront,
  convertDateBackToFrontTimestamp
} from '../../../../../utils/convertDates';
import FormCalendarDoubleSelect from '../../../../ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';

type FormData = {
  startDate: string;
  endDate: string;
  quantity: string;
  errors: ErrorType[];
};
type Props = {
  intensityMetric: IntensityMetric;
  editIntensityMetric: (intensityMetric: IntensityMetric) => void;
  intensityMetricGroup: IntensityMetricGroup;
};
const EditIntensityMetric = ({
  intensityMetric,
  editIntensityMetric,
  intensityMetricGroup
}: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormData>({
    startDate: convertDateBackToFront(intensityMetric.start_date),
    endDate: convertDateBackToFront(intensityMetric.end_date),
    quantity: intensityMetric.quantity.toString(),
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleEdit = async () => {
    const optionalFields: string[] = [];
    // Validate form with errors
    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

    if (Number(formData.quantity) === 0) {
      newErrors.push({
        error: 'quantity',
        description: t('error.valueBiggerThan0')
      });
    }
    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    setLoadingButton(true);
    // Call api and callback to add to list
    const updatedIntensityMetric = await updateIntensityMetric(intensityMetric.id ?? '', {
      quantity: parseFloat(formData.quantity)
    });
    setLoadingButton(false);

    editIntensityMetric({
      id: intensityMetric.id,
      start_date: updatedIntensityMetric.start_date,
      end_date: updatedIntensityMetric.end_date,
      intensity_metric: `${numberToDecimal(intensityMetric.value)} Kg CO₂ eq. / ${
        intensityMetricGroup.unit
      }`,
      quantity: parseFloat(formData.quantity),
      value: updatedIntensityMetric.value,
      user: {
        first_name: user?.first_name ?? '',
        last_name: user?.last_name ?? ''
      }
    });
  };

  return (
    <div className='edit-intensity-metric'>
      <FormHeader
        title={t('editIntensityMetric.title')}
        description={t('editIntensityMetric.description')}
      />
      <FormWrapper>
        <FormElementFull>
          <FormCalendarDoubleSelect
            startDate={formData.startDate}
            endDate={formData.endDate}
            handleChangeStartDate={onChangeValue('startDate')}
            handleChangeEndDate={onChangeValue('endDate')}
            label={t('createIntensityMetric.metricDates')}
            error={formData.errors.find(
              (error) => error.error === 'startDate' || error.error === 'endDate'
            )}
            disabled
          />
        </FormElementFull>
        <FormNumber
          label={t('createIntensityMetric.quantity')}
          icon={'/images/icons/intensityMetric.svg'}
          placeholder={t('createIntensityMetric.quantityPlaceholder')}
          value={formData.quantity}
          onChange={onChangeValue('quantity')}
          error={formData.errors.find((error) => error.error === 'quantity')}
          decimals={5}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('editIntensityMetric.edit')}
          onClick={handleEdit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default EditIntensityMetric;
