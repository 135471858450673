import Card from './Card';
import './styles.scss';

type Props = {
  cards: CardTooltip[];
};

function CardTooltipList({ cards }: Props) {
  return (
    <div className='card-tooltip-list'>
      {cards.map((card, index) => (
        <Card key={index} card={card} tooltipPosition={'right'} />
      ))}
    </div>
  );
}

export default CardTooltipList;
