import './styles.scss';

type Props = {
  isSelected: boolean;
};

const Line = ({ isSelected }: Props) => {
  return (
    <div className={`carousel-line ${isSelected ? 'secondary-bg-color' : 'gray-bg-color2'}`}></div>
  );
};

export default Line;
