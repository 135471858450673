import { ICON_SIZE_MAP, IconSize } from '../../icon/utils/constants';

const SIZES = {
  [ICON_SIZE_MAP.SMALL]: 12,
  [ICON_SIZE_MAP.MEDIUM]: 16,
  [ICON_SIZE_MAP.LARGE]: 24,
  [ICON_SIZE_MAP.XLARGE]: 32
} as const;

export const getIconPx = (size: IconSize) => {
  return SIZES[size];
};
