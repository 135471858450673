import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getPurchases } from '../../../../../../services/api/purchases';
import { generateQueryParamsFromObject, IFilter, ISorter } from '../../../../../../utils/url';
import { IPurchaseGeneralBackend } from '../../../../../../types/purchases';

type Params = {
  filters?: IFilter[];
  sorters?: ISorter[];
};
const makeFetch = async ({ filters, sorters, page }: Params & { page: number }) => {
  const queryParams = generateQueryParamsFromObject({ page });

  const response = await getPurchases({ queryParams, filters, sorters });

  return response;
};

export const useGetPurchases = ({ filters, sorters }: Params) => {
  const queryClient = useQueryClient();

  const { data, refetch, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
    queryKey: ['purchases', JSON.stringify(filters), JSON.stringify(sorters)],
    queryFn: ({ pageParam }) => makeFetch({ filters, sorters, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1,
    select: (data) => {
      return data.pages.reduce(
        (acc, current) => {
          return {
            total: current.total,
            total2: current.total2,
            page: current.page,
            size: current.size,
            items: [...acc.items, ...current.items]
          };
        },
        { total: 0, total2: 0, page: 0, size: 0, items: [] }
      );
    }
  });

  const addPurchase = (purchase: IPurchaseGeneralBackend) => {
    queryClient.setQueryData(
      ['purchases', JSON.stringify(filters), JSON.stringify(sorters)],
      (
        oldData: InfiniteData<
          Pagination<IPurchaseGeneralBackend, { total2: number; size: number }>,
          number
        >
      ) => {
        return {
          pageParams: oldData.pageParams,
          pages: oldData.pages.map((pag, index, array) => {
            if (index === array.length - 1) {
              return {
                ...pag,
                items: [...pag.items, purchase]
              };
            }

            return pag;
          })
        };
      }
    );
  };

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['purchases'] });
    queryClient.invalidateQueries({ queryKey: ['purchaseSuppliers'] });
  };

  return {
    data: data?.items ?? [],
    total: data?.total ?? 0,
    total2: data?.total2 ?? 0,
    fetchData: fetchNextPage,
    loading: isFetching,
    firstLoading: isLoading,
    refreshData: refetch,
    addPurchase,
    invalidate
  };
};
