import React, { useContext, useState } from 'react';
import { GroupFunds } from '../../../../../../types/entities/groupFunds';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import { replaceStrongTags } from '../../../../../../utils/stringToBold';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../ui/button/Button';
import { divestFundRelation } from '../../../../../../services/api/funds';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendar from '../../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { convertDateToString } from '../../../../../../utils/convertDates';
import { UserContext } from '../../../../../../context/userContext';

type FormData = {
  divestDate: string;
  errors: ErrorType[];
};
type Props = {
  companyToDivest: GroupFunds;
  divestCompany: (id: string) => void;
  handleClose: () => void;
};
const Divest = ({ companyToDivest, divestCompany, handleClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'divestCompany' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormData>({
    divestDate: convertDateToString(new Date()),
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    setLoadingButton(true);
    const data = await divestFundRelation(
      companyToDivest.child_id,
      user?.selectedOrganization ?? '',
      formData.divestDate
    );
    setLoadingButton(false);
    divestCompany(companyToDivest.child_id);
  };
  return (
    <div>
      <FormHeader
        title={t('title')}
        description={replaceStrongTags(t('description', { company: companyToDivest.child_name }))}
      />
      <FormWrapper>
        <FormCalendar
          handleChangeDate={onChangeValue('divestDate')}
          dateValue={formData.divestDate}
          label={t('divestDate')}
          error={formData.errors.find((error) => error.error === 'divestDate')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'secondary'}
          text={tGeneral('cancel')}
          onClick={handleClose}
          size='small'
        />
        <Button
          lookAndFeel='warning'
          text={t('divest')}
          onClick={handleSubmit}
          size='small'
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default Divest;
