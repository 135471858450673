import { useTranslation } from 'react-i18next';
import { LogisticReportFront } from '../../../../types/entities/logistics';
import formatNumber from '../../../../utils/formatNumber';

const useParseFuel = (fuelData: LogisticReportFront['emissionFactorsUsed']['logisticFuels']) => {
  const { t } = useTranslation();

  const fuelDataParsed: string[][] = fuelData?.map((fuel) => [
    t(`templates.logistics_recharges.fuel_types.${fuel.fuelName}`),
    t(`units.${fuel.fuelUnit.name}`),
    fuel.region,
    `${formatNumber(fuel.wtt.value)} ${fuel.wtt.units}`,
    `${formatNumber(fuel.ttw.value)} ${fuel.ttw.units}`,
    `${formatNumber(fuel.wtw.value)} ${fuel.wtw.units}`
  ]);

  return fuelDataParsed;
};

export default useParseFuel;
