import { IconName } from '../../../../../types/utils/iconName';
import { IconColor } from '../../../../ui/IconBase/IconBase';
import { VARIANTS } from '../../../../ui/label/Label';

type ObjectStatus = {
  icon: IconName;
  color: keyof typeof VARIANTS;
  textColor: IconColor;
};

export const transformDateBackend = (originalDate: string) => {
  const dateParts = originalDate.split('/');
  const day = Number(dateParts[0]);
  const month = Number(dateParts[1]);
  const year = Number(dateParts[2]);

  const date = new Date(year, month - 1, day);

  const formattedYear = date.getFullYear();
  const formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
  const formattedDay = ('0' + date.getDate()).slice(-2);

  return formattedYear + '-' + formattedMonth + '-' + formattedDay;
};

export const renderStatus = (status: string): ObjectStatus => {
  const statusMap: Record<string, ObjectStatus> = {
    default: {
      icon: 'clock',
      color: 'tertiary',
      textColor: 'blocked'
    },
    info: {
      icon: 'information',
      color: 'secondary',
      textColor: 'blocked'
    },
    pending: {
      icon: 'clock',
      color: 'secondary',
      textColor: 'blocked'
    },
    loading: {
      icon: 'clock',
      color: 'secondary',
      textColor: 'blocked'
    },
    blocked: {
      icon: 'error',
      color: 'disabled',
      textColor: 'disabled'
    },
    active: {
      icon: 'success',
      color: 'success',
      textColor: 'success'
    },
    inactive: {
      icon: 'error',
      color: 'disabled',
      textColor: 'disabled'
    },
    terminated: {
      icon: 'error',
      color: 'disabled',
      textColor: 'disabled'
    }
  };

  const statusObject: ObjectStatus = statusMap[status] || statusMap['default'];

  return statusObject;
};
