import { useTranslation } from 'react-i18next';
import './styles.scss';
import { createPortal } from 'react-dom';

const HideShowChat = () => {
  const { t } = useTranslation();

  const urlsNotShow = ['communicate', 'employee-form-qr'];

  const handleShowHideChat = () => {
    /* const element: any = document.querySelector('#hubspot-messages-iframe-container');
    if (element) {
      if (showChat) {
        element.style.visibility = 'hidden';
      } else {
        element.style.visibility = 'visible';
      }
    } */

    Helpkit.show('sidebar');
  };

  const url = new URLSearchParams(window.location.href);
  if (urlsNotShow.some((urlNotShow) => url.toString().includes(urlNotShow))) return null;

  return createPortal(
    <div
      /*       className={`hide-show-chat ${showChat ? 'on-chat' : 'below-chat'}`}
       */
      className='hide-show-chat below-chat'
      onClick={handleShowHideChat}>
      {/* {showChat ? (
        <img src='/images/icons/close.svg' alt='close-icon' />
      ) : (
        <span className='hide-show-chat__text body3-font'>{t('hideShowChat.show')}</span>
      )} */}
      <span className='hide-show-chat__text body3-font'>{t('hideShowChat.show')}</span>
    </div>,
    document.body
  );
};

export default HideShowChat;
