import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useErrors from '../../../../../customHooks/useErrors';
import { deleteContract } from '../../../../../services/api/datadis';

import Button from '../../../../ui/button/Button';
import ErrorText from '../../../../ui/errorText/ErrorText';

type Props = {
  idToDelete: string;
  nif: string;
  removeContract: (idToDelete: string) => void;
};

const DeleteContract = ({ idToDelete, nif, removeContract }: Props) => {
  const { t } = useTranslation();
  const ERRORS = useErrors();

  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const handleDeleteContract = async () => {
    if (loadingButton) return;

    setLoadingButton(true);
    const response = await deleteContract(idToDelete, nif);
    setLoadingButton(false);

    if (response?.response?.status >= 400) setError(ERRORS.DATADIS.DELETE_FAILED);

    removeContract(idToDelete);
  };

  return (
    <div className='delete-invoice'>
      <h1 className='headline4-font'>{t('datadisContracts.deleteContract')}</h1>
      <h2 className='subtitle3-font '>{t('datadisContracts.areYouSureDelete')}</h2>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('datadisContracts.deleteContract')}
          onClick={handleDeleteContract}
          loading={loadingButton}
        />
      </div>
      {Boolean(error) && <ErrorText>{error?.description}</ErrorText>}
    </div>
  );
};

export default DeleteContract;
