import { useEffect, useRef } from 'react';
import { InputSize } from '../../../../../types/utilsEnums/input';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: SelectOptionFormat;
  onClickOption?: (option: any) => void;
  isSelected?: boolean;
  size?: InputSize;
}

const Option = ({ value, onClickOption, isSelected, size, ...rest }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleOnClick = () => onClickOption && onClickOption(value);

  useEffect(() => {
    if (!isSelected || !ref?.current) return;
    ref.current.scrollIntoView({
      behavior: 'auto', // You can use 'auto' or 'smooth' for the scrolling behavior
      block: 'center' // You can use 'start', 'center', or 'end' to specify where the element should be aligned
    });
  }, []);

  const sizeClassName = size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';

  return (
    <div
      ref={ref}
      className={`select-options__item on-light-text-color  ${
        isSelected ? 'active' : ''
      } ${sizeClassName}`}
      id={value.id}
      onClick={handleOnClick}
      {...rest}>
      {value.name}
    </div>
  );
};

export default Option;
