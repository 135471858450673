import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersContext } from './FilterContext';
import './styles.scss';

type FiltersProps = {
  children?: React.ReactNode;
};

const Filters = ({ children }: FiltersProps) => {
  const { open, setOpen } = useContext(FiltersContext);

  const { t } = useTranslation();
  return (
    <div className={`filters-wrapper-left-side ${open ? 'in' : ''}`}>
      <div className='filters__header'>
        <h4 className='headline4-font'>{t('general.filters.title')}</h4>
        <img src={'/images/icons/close.svg'} alt='close-button' onClick={() => setOpen(false)} />
      </div>
      <div className={`filters ${open ? 'in' : ''}`}>{children}</div>
    </div>
  );
};

export default Filters;
