import apiFetch from '../../utils/apiFetch';

export const getOrganizationModeling = async () => {
  try {
    const response = await apiFetch('GET', `/scenario_modeling`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const putReductionPercentage = async (category: string, value: string) => {
  try {
    const response = await apiFetch('PUT', `/scenario_modeling`, {
      framework_categories_id: category,
      reduction_percentage: value
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const putReductionAction = async (category: string, value: string) => {
  try {
    const response = await apiFetch('PUT', `/scenario_modeling`, {
      framework_categories_id: category,
      reduction_description: value
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
