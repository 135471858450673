import { ReactNode } from 'react';
import './styles.scss';

type DataItem = {
  [key: string]: ReactNode | string;
};

type HeaderElement = {
  key: string;
  name: string;
}[];

type Props = {
  data: DataItem[];
  header: HeaderElement;
};

const Header = ({ data, header }: Props) => {
  if (data.length === 0) return null;

  return (
    <thead className='report-table-header'>
      <tr className='body2-font'>
        {header.map(({ key, name }) => (
          <td key={key}>{name}</td>
        ))}
      </tr>
    </thead>
  );
};

const Body = ({ data, header }: Props) => {
  if (data.length === 0) return null;
  return (
    <tbody className='report-table-wrapper'>
      {data.map((dataItem, index) => (
        <tr key={index}>
          {header.map(({ key }, index2) => (
            <td key={index2 + index}>{dataItem[key] ?? '-'}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

/**
 * A table component that displays a list of data objects.
 */
const ReportTable = ({ data, header }: Props) => {
  return (
    <table className='report-table'>
      <Header data={data} header={header} />
      <Body data={data} header={header} />
    </table>
  );
};

export default ReportTable;
