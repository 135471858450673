import Checkbox from '../../formComponents2/checkbox/Checkbox';
import './styles.scss';

type Props = {
  id: string;
  onSelect: (id: string) => void;
  onRemove: (id: string) => void;
  selected: boolean;
  error?: ErrorType;
  type?: 'circle' | 'square';
  colour?: 'gray';
};

const CheckboxMultiple = ({
  id,
  onSelect,
  onRemove,
  selected,
  error,
  type = 'circle',
  colour
}: Props) => {
  const handleCheckboxClick = () => {
    if (selected) {
      onRemove(id);
    } else {
      onSelect(id);
    }
  };

  return (
    <>
      <div className='modern-checkbox-container'>
        <div
          className={`checkbox-outer-${type} ${!selected && 'unselected'} ${colour ? colour : ''}`}
          onClick={handleCheckboxClick}>
          {selected && (
            <div className='checkbox-icon-wrapper'>
              {colour === 'gray' ? (
                <img src='/images/icons/tickGray.svg' alt='tick-selected' />
              ) : (
                <img src='/images/icons/tick.svg' alt='tick-selected' />
              )}
            </div>
          )}
        </div>
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : ''}
        </span>
      )}
    </>
  );
};

type NewProps = Omit<Props, 'type' | 'colour'> & {
  text: string;
};

const NewCheckboxMultiple = ({ id, onSelect, onRemove, selected, error, text }: NewProps) => {
  const handleCheckboxClick = () => {
    if (selected) {
      onRemove(id);
    } else {
      onSelect(id);
    }
  };

  return (
    <>
      <Checkbox onChange={handleCheckboxClick} text={text} selected={selected} />
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : ''}
        </span>
      )}
    </>
  );
};

CheckboxMultiple.New = NewCheckboxMultiple;

export default CheckboxMultiple;
