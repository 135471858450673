//type intensityMetricGroup []

import { TFunction } from 'i18next';
import { scope1_color, scope2_color } from '../../../../styles/colors';

import {
  EsgMetric,
  EsgMetricBackend,
  EsgMetricFrontend
} from '../../../../types/entities/esgMetrics';
import formatNumber from '../../../../utils/formatNumber';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Label from '../../../ui/label/Label';

export const parseDataForSelectIntensityMetric = (intensityMetricData: any) => {
  return intensityMetricData?.items?.map((item: any) => ({
    id: item.id,
    name: item.name
  }));
};

export const getPreviousYear = () => {
  const currentDate = new Date();
  const previousYear = currentDate.getFullYear() - 1;
  return previousYear;
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
};

export const renderDelta = (value: number) => (
  <Label lookAndFeel={value > 0 ? 'secondary' : 'success'}>
    <div className='icon-text-wrapper'>
      <img
        src={
          value > 0 ? '/images/icons/arrowUpSecondary.svg' : '/images/icons/arrowDownPrimary.svg'
        }
        alt='arrow'
      />
      <span>
        {value > 0 ? '+' : ''}
        {formatNumber(numberToDecimal(value * 100))}%
      </span>
    </div>
  </Label>
);

export const parseDataSelectEsgMetric = (data: EsgMetric[], t: TFunction) => {
  return data.map((item) => ({
    id: item.id,
    name: t(`esgMetricsDetails.${item.name}`)
  }));
};

type IParseImpactFacilityAndOrgData = {
  data?: EsgMetricFrontend;
  valueCategoryChartId?: string;
};

export const parseImpactFacilityAndOrgData = ({
  data,
  valueCategoryChartId
}: IParseImpactFacilityAndOrgData) => {
  if (!data) return [];

  const environmentalData = data.environmental?.find(
    (item) => item.metric === valueCategoryChartId
  );
  const socialGovernanceData = data.socialGovernance?.find(
    (item) => item.metric === valueCategoryChartId
  );

  const selectedData = environmentalData || socialGovernanceData;

  const chartData = [
    {
      label: getPreviousYear().toString(),
      value: String(selectedData?.valuePreviousYear),
      color: scope1_color
    },
    {
      label: getCurrentYear().toString(),
      value: String(selectedData?.valueCurrentYear),
      color: scope2_color
    }
  ];

  return chartData;
};

export const getUnit = ({ data, valueCategoryChartId }: IParseImpactFacilityAndOrgData) => {
  if (!data) return [];

  const environmentalData = data.environmental?.find(
    (item) => item.metric === valueCategoryChartId
  );
  const socialGovernanceData = data.socialGovernance?.find(
    (item) => item.metric === valueCategoryChartId
  );

  const selectedData = environmentalData || socialGovernanceData;

  return selectedData?.unit;
};

export const calculateGap = (previousYearValue: number, currentYearValue: number) => {
  const dif = currentYearValue - previousYearValue;
  // const gap = (dif / previousYearValue) * 100;

  return dif;
};

export const calculateGapPercentage = (previousYearValue: number, currentYearValue: number) => {
  const dif = currentYearValue - previousYearValue;
  const gap = (dif / previousYearValue) * 100;
  return (
    <Label lookAndFeel={gap > 0 ? 'secondary' : 'success'}>
      <div className='icon-text-wrapper'>
        <img
          src={
            gap > 0 ? '/images/icons/arrowUpSecondary.svg' : '/images/icons/arrowDownPrimary.svg'
          }
          alt='arrow'
        />
        <span>
          {gap > 0 ? '+' : ''}
          {formatNumber(numberToDecimal(gap * 100))}%
        </span>
      </div>
    </Label>
  );
};

export const buildName = (elem: EsgMetricBackend, t: TFunction) => {
  if (elem.code) {
    return `${elem.code} ${t(`low_codes.${elem.metric}`)}`;
  } else if (elem.code_type || elem.metric === 'no_rd') {
    return t(`low_codes.${elem.metric}`) + ' - ' + t(`esgMetricsDetails.type.${elem.type}`);
  }
  return t(`esgMetricsDetails.${elem.metric}`) + ' - ' + t(`esgMetricsDetails.type.${elem.type}`);
};
