import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import apiFetch from '../../utils/apiFetch';
import { convertDateFrontToBackTimestamp } from '../../utils/convertDates';

export const getPresignedUrlLogistics = async (file_name: string, type: string) => {
  try {
    const body: any = {};

    if (type === 'requests') body.requests_file_name = file_name;
    else body.recharges_file_name = file_name;

    const response = await apiFetch('POST', '/logistics/upload', body);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getLogisticsClients = async (lastClientName?: string, search?: string) => {
  try {
    const params: {
      last_client_name?: string;
      search?: string;
    } = {};
    if (lastClientName) {
      params.last_client_name = lastClientName;
    }
    if (search) {
      params.search = search;
    }
    const response = await apiFetch('GET', '/logistics/clients', null, {}, params);
    return response.data;
  } catch (error: any) {
    const whiteListContent = ['NO_AVAILABLE_DATA', 'NO_RECHARGES', 'NO_REQUESTS'];

    if (error.response.status === 422 && whiteListContent.includes(error.response.data)) {
      return error.response.data;
    }
    return null;
  }
};

export const getLogisticsReport = async (
  client: string,
  startDate: Date,
  endDate: Date,
  organization_id: string
) => {
  try {
    const startDateParsed = new Date(moment(startDate).format('YYYY-MM-DD'));
    const endDateParsed = new Date(moment(endDate).format('YYYY-MM-DD'));

    const startDateTimestamp = Math.floor(startDateParsed.getTime() / 1000);
    const endDateTimestamp = Math.floor(endDateParsed.getTime() / 1000);
    const response = await apiFetch(
      'GET',
      '/logistics/report',
      null,
      {
        'x-organization-id': organization_id
      },
      {
        client,
        start_date: startDateTimestamp,
        end_date: endDateTimestamp
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    if (error.response.status === 422) {
      return { error: error.response.data };
    }
    return null;
  }
};

export const getLogisticUploadStatus = async (organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      '/logistics/status',
      null,
      {
        'x-organization-id': organization_id
      },
      null,
      2000
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFileQueues = async (organization_id: string) => {
  try {
    const response = await apiFetch('GET', '/file_queues', null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// function that receive       page,
//      size: SIZE_PAGINATION_SMALL,
//      id: filters.id,
//      client: filters.client.id

type GetLogisticShipmentList = {
  page: number;
  size: number;
  id?: string;
  client?: string;
  startDate?: string;
  endDate?: string;
  vehicleLicense?: string;
  trailerLicense?: string;
  loadKgLower?: string;
  loadKgUpper?: string;
  distanceKmLower?: string;
  distanceKmUpper?: string;
  tkmLower?: string;
  tkmUpper?: string;
  vehicleType?: string;
  tripStatus?: string;
  cleaning?: string;
  subcontractor?: string;
};

export const getLogisticShipmentList = async ({
  page,
  size,
  id,
  client,
  startDate,
  endDate,
  vehicleLicense,
  trailerLicense,
  loadKgLower,
  loadKgUpper,
  distanceKmLower,
  distanceKmUpper,
  tkmLower,
  tkmUpper,
  vehicleType,
  tripStatus,
  cleaning,
  subcontractor
}: GetLogisticShipmentList) => {
  try {
    const params = new URLSearchParams();
    params.append('page', String(page));
    params.append('size', String(size));
    if (id) params.append('unique_movement_id', String(id));
    if (client) params.append('clients', `${client}`);
    if (startDate)
      params.append('trip_date_from', convertDateFrontToBackTimestamp(startDate).toString());
    if (endDate)
      params.append('trip_date_until', convertDateFrontToBackTimestamp(endDate).toString());
    if (vehicleLicense) params.append('vehicle_license_plate', vehicleLicense);
    if (trailerLicense) params.append('trailer_license_plate', trailerLicense);
    if (loadKgLower) params.append('load_kg_lower', loadKgLower);
    if (loadKgUpper) params.append('load_kg_upper', loadKgUpper);
    if (distanceKmLower) params.append('distance_km_lower', distanceKmLower);
    if (distanceKmUpper) params.append('distance_km_upper', distanceKmUpper);
    if (tkmLower) params.append('tkm_lower', tkmLower);
    if (tkmUpper) params.append('tkm_upper', tkmUpper);
    if (vehicleType) params.append('vehicle_type', vehicleType);
    if (tripStatus) params.append('trip_status', tripStatus);
    if (cleaning) params.append('cleaning', cleaning);
    if (subcontractor) params.append('subcontractor', subcontractor);

    const response = await apiFetch('GET', `/logistics/requests?${params.toString()}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getLogisticHocs = async () => {
  try {
    const response = await apiFetch('GET', '/logistics/hocs');
    return response.data;
  } catch (err) {
    return null;
  }
};

type Response = {
  response?: AxiosResponse;
  error?: AxiosError;
};

export const getTocs = async (): Promise<Response> => {
  try {
    const response = await apiFetch('GET', '/logistics/tocs');
    return { response };
  } catch (error: unknown) {
    return { error: error as AxiosError };
  }
};
