import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { convertStringToDateBackend } from '../../../../../../../utils/convertDates';
import formatNumber from '../../../../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../../../../utils/numberToDecimal';
import InputNumber from '../../../../../../ui/formComponents2/inputNumber/InputNumber';
import InputText from '../../../../../../ui/formComponents2/inputText/InputText';
import LoaderCard from '../../../../../../ui/loaders/loaderCard/LoaderCard';
import CommonStatusLabel from '../../../../../../ui/statusLabels/commonStatusLabel/CommonStatusLabels';
import Table from '../../../../../../ui/table/Table';
import Tooltip from '../../../../../../ui/tooltip/Tooltip';
import './styles.scss';
import {
  formatZero,
  getScopeData,
  groupByCategory,
  groupDataByScope,
  listCategories,
  listCategoriesScope,
  unionWithGoals
} from './utils';

type Props = {
  loading: boolean;
  baseYear?: string; // date
  currentYear: string; // date
  goalYearScope1and2?: string; // date
  goalYearScope3?: string; // date
  organizationModeling: IOrganizationModelingBack[] | null;
  totalImpactByCategoryYearly: TotalImpactYearly[] | null;
  handleOnChangeReductionPercentage: (category: string) => (value: string) => void;
  handleOnChangeReductionAction: (category: string) => (e: ChangeEvent<HTMLInputElement>) => void;
  organizationGoal: IOrganizationGoalSbtiBack | null;
};

const TableComponent = ({
  loading,
  totalImpactByCategoryYearly,
  organizationModeling,
  baseYear,
  currentYear,
  goalYearScope1and2,
  goalYearScope3,
  handleOnChangeReductionPercentage,
  handleOnChangeReductionAction,
  organizationGoal
}: Props) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'category',
      dataIndex: 'category',
      title: t('modeling.table.category')
    },
    {
      key: 'reduction_percentage',
      dataIndex: 'reduction_percentage',
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}>
          {t('modeling.table.reduction_percentage')}{' '}
          <Tooltip text={t('modeling.table.reduction_percentage_tooltip')} />
        </div>
      )
    },
    {
      key: 'reduction_action',
      dataIndex: 'reduction_action',
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}>
          {t('modeling.table.reduction_action')}{' '}
          <Tooltip text={t('modeling.table.reduction_action_tooltip')} />
        </div>
      )
    },
    {
      key: 'base_year',
      dataIndex: 'base_year',
      title: (
        <div style={{ textAlign: 'right' }}>
          {t('modeling.table.base_year', {
            year: organizationModeling ? baseYear : '2019'
          })}
        </div>
      )
    },
    {
      key: 'current_year',
      dataIndex: 'current_year',
      title: (
        <div style={{ textAlign: 'right' }}>
          {t('modeling.table.current_year', {
            year: convertStringToDateBackend(currentYear).getFullYear()
          })}
        </div>
      )
    },
    {
      key: 'gap_year',
      dataIndex: 'gap_year',
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}>
          {t('modeling.table.gap_year', {
            year: convertStringToDateBackend(currentYear).getFullYear()
          })}
          <Tooltip text={t('modeling.table.gap_year_tooltip')} />
        </div>
      )
    },
    {
      key: 'goal_year',
      dataIndex: 'goal_year',
      title: (
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}>
          {t('modeling.table.goal_year', {
            year: organizationModeling ? `${goalYearScope1and2} - ${goalYearScope3}` : '2030'
          })}
          <Tooltip text={t('modeling.table.goal_year_tooltip')} />
        </div>
      )
    }
  ];

  const parseData = () => {
    if (!totalImpactByCategoryYearly || !baseYear || !organizationModeling) return [];

    const groupedDataScopeArray = groupDataByScope(totalImpactByCategoryYearly);

    const baseYearNumber = Number(baseYear);
    const currentYearNumber = Number(convertStringToDateBackend(currentYear).getFullYear());

    const scope1Grouped = groupByCategory(
      groupedDataScopeArray.scope1,
      baseYearNumber,
      currentYearNumber
    );
    const scope2Grouped = groupByCategory(
      groupedDataScopeArray.scope2,
      baseYearNumber,
      currentYearNumber
    );
    const scope3Grouped = groupByCategory(
      groupedDataScopeArray.scope3,
      baseYearNumber,
      currentYearNumber
    );

    const categoriesIncludedScope1 = Array.from(
      new Set(scope1Grouped.map((element) => element.category))
    );

    const categoriesIncludedScope2 = Array.from(
      new Set(scope2Grouped.map((element) => element.category))
    );

    const categoriesIncludedScope3 = Array.from(
      new Set(scope3Grouped.map((element) => element.category))
    );

    const categoriesIncluded = categoriesIncludedScope1
      .concat(categoriesIncludedScope2)
      .concat(categoriesIncludedScope3);

    const categoriesNotIncluded = listCategories.filter(
      (category) => !categoriesIncluded.includes(category) && !category.includes('scope')
    );

    categoriesNotIncluded.forEach((category) => {
      scope1Grouped.push({
        baseYear: 0,
        currentYear: 0,
        goalYear: 0,
        category,
        scope: listCategoriesScope[category]
      });
    });

    const scope1 = unionWithGoals(organizationModeling, scope1Grouped);
    const scope2 = unionWithGoals(organizationModeling, scope2Grouped);
    const scope3 = unionWithGoals(organizationModeling, scope3Grouped);

    const scope1Data = getScopeData(scope1, 'scope1');
    const scope2Data = getScopeData(scope2, 'scope2');
    const scope3Data = getScopeData(scope3, 'scope3');

    const totalImpacts = [scope1Data, ...scope1, scope2Data, ...scope2, scope3Data, ...scope3];

    totalImpacts.sort((a, b) => {
      const categoryA = a.category;
      const categoryB = b.category;

      const indexA = listCategories.indexOf(categoryA);
      const indexB = listCategories.indexOf(categoryB);

      return indexA - indexB;
    });

    let percentageScope1 = 0;
    let percentageScope2 = 0;
    let percentageScope3 = 0;

    totalImpacts.forEach((element) => {
      if (element.category === 'scope1') {
        const difference = (element?.baseYear || 0) - (element?.goalYear || 0);
        percentageScope1 = (difference * 100) / (element?.baseYear || 0);
      }

      if (element.category === 'scope2') {
        const difference = (element?.baseYear || 0) - (element?.goalYear || 0);
        percentageScope2 = (difference * 100) / (element?.baseYear || 0);
      }

      if (element.category === 'scope3') {
        const difference = (element?.baseYear || 0) - (element?.goalYear || 0);
        percentageScope3 = (difference * 100) / (element?.baseYear || 0);
      }
    });

    percentageScope1 =
      isNaN(percentageScope1) || !isFinite(percentageScope1) ? 0 : percentageScope1;
    percentageScope2 =
      isNaN(percentageScope2) || !isFinite(percentageScope2) ? 0 : percentageScope2;
    percentageScope3 =
      isNaN(percentageScope3) || !isFinite(percentageScope3) ? 0 : percentageScope3;

    const calculateGap = (
      currentYear: number | undefined,
      baseYear: number | undefined,
      organizationGoal: IOrganizationGoalSbtiBack | null,
      scope?: number | undefined
    ) => {
      let ambitionLevel;

      const ambitionLevelScope1and2 =
        organizationGoal?.ambition_level_scope_1_2 === '1_5' ? 4.2 : 2.5;

      scope === 3 ? (ambitionLevel = 1.23) : (ambitionLevel = ambitionLevelScope1and2);

      if (currentYear === undefined || baseYear === undefined || organizationGoal === null)
        return 0;

      const nYears = currentYearNumber - organizationGoal.base_year;

      const calculatedValue =
        (currentYear - (baseYear - baseYear * ((ambitionLevel / 100) * nYears))) / 1000;

      return Number(calculatedValue.toFixed(2));
    };

    const data = totalImpacts.map((element) => {
      const gap = calculateGap(
        element.currentYear,
        element.baseYear,
        organizationGoal,
        element.scope
      );
      return {
        category: (
          <span className={element.category.includes('scope') ? 'report-card-font' : ''}>
            {element.category.includes('scope')
              ? 'Total ' + t(`categories.${element.category}`)
              : t(`categories.${element.category}`)}
          </span>
        ),
        reduction_percentage: (
          <>
            <div className='input-container'>
              {!element.category.includes('scope') && (
                <div className='wrapper-input-number-modeling secondary-text-color'>
                  <InputNumber
                    className='secondary-text-color placeholder-secondary-color'
                    value={element.percentage}
                    onChange={handleOnChangeReductionPercentage(element.category)}
                    placeholder='0'
                    max={100}
                    min={0}
                  />
                  <span className='body1-font '>%</span>
                </div>
              )}
              {element.category.includes('scope1') && `${Math.round(percentageScope1)}%`}
              {element.category.includes('scope2') && `${Math.round(percentageScope2)}%`}
              {element.category.includes('scope3') && `${Math.round(percentageScope3)}%`}
            </div>
          </>
        ),
        reduction_action: (
          <>
            <div style={{ textAlign: 'center' }}>
              <div className='input-container'>
                {!element.category.includes('scope') && (
                  <InputText
                    className='secondary-text-color'
                    value={element.action}
                    onChange={handleOnChangeReductionAction(element.category)}
                    placeholder={t('modeling.table.placeholder')}
                  />
                )}
              </div>
            </div>
          </>
        ),
        base_year: (
          <div style={{ textAlign: 'right' }}>
            <span
              className={element.category.includes('scope') ? 'report-card-font' : ''}
              style={{ fontSize: '14px' }}>
              {formatZero(Number(numberToDecimalNonZero(element.baseYear || 0) / 1000).toFixed(2))}
            </span>
          </div>
        ),
        current_year: (
          <div style={{ textAlign: 'right' }}>
            <span
              className={element.category.includes('scope') ? 'report-card-font' : ''}
              style={{ fontSize: '14px' }}>
              {formatZero(
                Number(numberToDecimalNonZero(element.currentYear || 0) / 1000).toFixed(2)
              )}
            </span>
          </div>
        ),
        gap_year: (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              {/* darle una vuelta a esto */}
              <CommonStatusLabel
                iconColor={Number(gap) <= 0 ? 'success' : 'error'}
                status={Number(gap) <= 0 ? 'success' : 'error'}
                icon={Number(gap) <= 0 ? 'grow_up_2' : 'grow_up'}>
                <span>{gap > 0 ? `+${formatNumber(gap)}` : formatNumber(gap)}</span>
                <span> t CO₂ eq.</span>
              </CommonStatusLabel>
            </div>
          </>
        ),
        goal_year: (
          <div style={{ textAlign: 'right' }}>
            <span
              className={element.category.includes('scope') ? 'report-card-font' : ''}
              style={{ fontSize: '14px' }}>
              {Number((numberToDecimalNonZero(element.goalYear || 0) / 1000).toFixed(2))}
            </span>
          </div>
        )
      };
    });

    return data;
  };

  const dataParsed = parseData();

  if (loading) return <LoaderCard className='item-table' />;
  return (
    <div
      className='card item-table'
      style={{
        overflow: 'hidden'
      }}>
      <div className='wrapper-table-component'>
        <h3 className='headline4-font' style={{ marginTop: '24px', marginBottom: '0.66rem' }}>
          {t('modeling.table.title')}
        </h3>
        <div className='infinite-scroll-wrapper-sbti'>
          <Table
            columns={columns}
            data={dataParsed}
            emptyText={t('table.empty')}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
