import { useTranslation } from 'react-i18next';
import { ILCASoldProductFront } from '../../../../../../../types/entities/useAndEndOfLife';

const useConstants = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife' });

  const emptyFormData: ILCASoldProductFront = {
    lcaId: {
      id: '',
      name: ''
    },
    productCode: {
      id: '',
      name: ''
    },
    productionYear: {
      id: '',
      name: ''
    },
    productionQuantity: '',
    type: 'final',
    errors: []
  };

  const cards: Card[] = [
    {
      id: 'final',
      icon: '/images/icons/tshirt.svg',
      title: t('final')
    },
    {
      id: 'intermediate',
      icon: '/images/icons/stacked50.svg',
      title: t('intermediate'),
      disabled: true
    }
  ];

  return { emptyFormData, cards };
};

export default useConstants;
