import apiFetch from '../../utils/apiFetch';

const URL = '/organization_efs';

export const getMITECOOrgEf = (orgId?: string) => async (): Promise<OrgEf> => {
  if (!orgId) {
    throw new Error('Organization ID is required');
  }

  const response = await apiFetch('GET', '/organization_efs/current_org_version');

  return response.data;
};

export const getLatestMITECOVersion = async (): Promise<string> => {
  const response = await apiFetch('GET', '/organization_efs/latest_version');

  return response.data;
};

type Params = {
  id: string;
};

export const updateOrgEf =
  (organizationId?: string) =>
  async ({ id }: Params) => {
    if (!organizationId) {
      throw new Error('Organization ID is required');
    }

    const response = await apiFetch('PATCH', `${URL}/${organizationId}`, {
      version: id
    });

    return response.data;
  };

type MITECOVersion = {
  id: string;
  name: string;
};

export const getMITECOVersions = async (): Promise<MITECOVersion[]> => {
  const response = await apiFetch('GET', '/organization_efs');

  return response.data;
};

export const getGWPTable = async (returnRequest = false) => {
  const request = apiFetch('GET', '/organization_efs/gwp_table');

  if (returnRequest) {
    return request;
  }

  const response = await request;

  return response.data;
};

export const getIPCCVersion = async (returnRequest = false) => {
  const request = apiFetch('GET', '/organization_efs/ipcc_version');

  if (returnRequest) {
    return request;
  }
  const response = await request;

  return response.data;
};
