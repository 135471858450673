import { useTranslation } from 'react-i18next';
import { User } from '../../types/entities/user';
import FormWrapper from '../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../ui/formComponents2/formInputs/formText/FormText';
import InputNumber from '../ui/formComponents2/inputNumber/InputNumber';
import InputText from '../ui/formComponents2/inputText/InputText';
import InputWrapper from '../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
type Props = {
  user: User;
};
function UserData({ user }: Props) {
  const { first_name, last_name, phone_number, prefix, email } = user;
  const { t } = useTranslation();

  const selectedOrganization = user.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );
  return (
    <div className='card-profile-info main-bg-color solid-border'>
      <div className='card-profile-info__header page-header'>
        <h1 className='headline4-font'>{t('profile.userInfo')}</h1>
      </div>
      <FormWrapper>
        <FormText
          icon='/images/icons/person.svg'
          label={t('profile.name')}
          value={first_name}
          disabled
        />
        <FormText
          icon='/images/icons/person.svg'
          label={t('profile.lastName')}
          value={last_name}
          disabled
        />

        <FormText
          icon='/images/icons/envelope.svg'
          label={t('profile.email')}
          placeholder={''}
          value={email || ''}
          disabled
        />
        <InputWrapper icon='/images/icons/phone.svg' label={t('profile.phone')} disabled>
          <InputText value={prefix || ''} placeholder={''} />
          <InputNumber
            value={phone_number?.toString()}
            onChange={function (value: string): void {
              throw new Error('Function not implemented.');
            }}
          />
        </InputWrapper>
        {/* <InputPhoneNumber
          icon='/images/icons/phone.svg'
          label={t('profile.phone')}
          placeholder={''}
          value={phone_number?.toString()}
          valuePrefix={prefix || ''}
          showOnly={true}
          disabled
        /> */}
        <FormText
          icon='/images/icons/key.svg'
          label={t('profile.role')}
          placeholder={''}
          value={selectedOrganization?.role ? t(`role.${selectedOrganization?.role}`) : ''}
          tooltip={
            selectedOrganization?.role ? t(`profile.tooltipRole${selectedOrganization?.role}`) : ''
          }
          disabled
        />
      </FormWrapper>
    </div>
  );
}

export default UserData;
