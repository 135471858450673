import { useTranslation } from 'react-i18next';
import { Column } from '../../../../types/table';

const useColumns = () => {
  const { t } = useTranslation();
  const columns: Column[] = [
    {
      title: t('vehicleDetail.customId'),
      dataIndex: 'custom_id',
      key: 'custom_id'
    },
    {
      title: t('vehicleDetail.quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: t('vehicleDetail.startDate'),
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: t('vehicleDetail.endDate'),
      dataIndex: 'endDate',
      key: 'endDate'
    },
    {
      title: t('vehicleDetail.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: t('vehicleDetail.file_url'),
      dataIndex: 'file_url',
      key: 'file_url'
    },
    {
      title: <div style={{ textAlign: 'right' }}>CO₂ eq</div>,
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
