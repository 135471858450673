import { useEffect, useState } from 'react';
import { InvoiceType, InvoicesResponse } from '../../../../../types/entities/invoice';
import apiFetch from '../../../../../utils/apiFetch';
import { AxiosResponse } from 'axios';

type Props = {
  url: string;
  category: string;
};

const useFetchData = ({ url, category }: Props) => {
  const [total, setTotal] = useState(0);
  const [total2, setTotal2] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);
  const [page, setPage] = useState(1);
  const [invoices, setInvoices] = useState<InvoiceType[]>([]);
  const [loading, setLoading] = useState(false);

  const size = 15;

  const fetchMoreData = async () => {
    if (loading) return;

    // adapt data
    setLoading(true);
    const data = await fetchData(page);
    setLoading(false);

    setPage((prev) => prev + 1);

    if (!data) return;

    // add new data to table
    setInvoices([...invoices, ...data]);
  };

  const fetchData = async (pageNumber: number) => {
    const operator = url.includes('?') ? '&' : '?';
    const urlWithParams = `${url}${operator}page=${pageNumber}&size=${size}&filters=${category}`;
    // get new data
    const response: AxiosResponse<InvoicesResponse> = await apiFetch('GET', urlWithParams);

    // if error, return
    if (response?.status >= 400) return;

    // update data
    setTotal(response.data.total);
    setTotal2(response.data.total2);
    setTotalGeneral(response.data.total_general);
    return response.data.items;
  };

  useEffect(() => {
    let ignore = false;
    if (!url || !category) return;

    // reset page in order to make first loading works
    setPage(1);

    // adapt data
    setLoading(true);
    fetchData(1)
      .then((res) => {
        if (ignore) return;

        setInvoices(res ?? []);

        setPage(2);
      })
      .finally(() => {
        if (!ignore) {
          setLoading(false);
        }
      });

    return () => {
      ignore = true;
    };
  }, [url, category]);

  return {
    fetchData: fetchMoreData,
    firstLoading: page === 1 && loading,
    loading,
    total,
    setTotal,
    total2,
    setTotal2,
    totalGeneral,
    setTotalGeneral,
    invoices,
    setInvoices
  };
};

export default useFetchData;
