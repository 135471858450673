import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('companies.company'),
      dataIndex: 'child_name',
      key: 'child_name'
    },
    {
      title: t('companies.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: t('companies.tag'),
      dataIndex: 'tag',
      key: 'tag'
    },
    {
      title: t('companies.sharePercentage'),
      dataIndex: 'share',
      key: 'share'
    },
    {
      title: t('companies.scopesIncluded'),
      dataIndex: 'investment_scopes',
      key: 'investment_scopes'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
};

export default useColumns;
