import numberToDecimal from '../../../../../../utils/numberToDecimal';
import { DATA_QUALITY_THRESHOLD } from '../../../../../../constants';
import { useTranslation } from 'react-i18next';
import Label from '../../../../../ui/label/Label';
import './styles.scss';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
type Props = {
  dataQualityPercentage: number;
  sectorComparison: number;
};
const DataQuality = ({ dataQualityPercentage, sectorComparison }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='data-quality card-border-color'>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          columnGap: '1rem',
          justifyContent: 'space-between',
          marginBottom: '1rem'
        }}>
        <span className='body3-font'>{t('header.dataQuality')}</span>
        <span className='co2-dashboard-font-small highlight-text-color'>{`${dataQualityPercentage}%`}</span>
      </div>
      <div className='data-quality__progress-bar gray-bg-color2'>
        <div
          className='data-quality__progress-bar__progress highlight-horizontal-bg-color'
          style={{ width: `${dataQualityPercentage}%` }}
        />
      </div>
      <div className='sector-comparison'>
        <span
          className='body1-font'
          style={{
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          {t('header.sectorComparison')}
          <span className='body1-bold-font highlight-text-color'>
            {numberToDecimal(sectorComparison)}%
          </span>
        </span>
        {dataQualityPercentage >= DATA_QUALITY_THRESHOLD && (
          <TooltipWrapper text={t('header.comparedWithXCompanies')}>
            <Label lookAndFeel='secondary'>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <img src='/images/icons/diamondOnTag.svg' alt='diamond' />
                <span>
                  {sectorComparison < 0
                    ? t('header.impactLowerThanSector')
                    : t('header.impactHigherThanSector')}
                </span>
              </div>
            </Label>
          </TooltipWrapper>
        )}
        {dataQualityPercentage < DATA_QUALITY_THRESHOLD && (
          <TooltipWrapper
            text={t('dashboard.dataQualityNeeded').replace(
              '{threshold}',
              DATA_QUALITY_THRESHOLD.toString()
            )}>
            <Label lookAndFeel='secondary'>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <img src='/images/icons/diamondOnTag.svg' alt='diamond' />
                <span>{t('error.lowDataQuality')}</span>
              </div>
            </Label>
          </TooltipWrapper>
        )}
      </div>
    </div>
  );
};

export default DataQuality;
