import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EMPLOYEE_FORM_GUIDE } from '../../../../../../constants';
import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import { replaceStrongTags } from '../../../../../../utils/stringToBold';
import Button from '../../../../../ui/button/Button';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import '../styles.scss';

function StepNewInit() {
  const { t, i18n } = useTranslation();

  const EMPLOYEE_FORM_GUIDE_TRANSLATED =
    i18n.resolvedLanguage === 'es' ? EMPLOYEE_FORM_GUIDE.es : EMPLOYEE_FORM_GUIDE.en;

  const { handleInItinere } = useContext(EmployeeFormContext);

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle2')} //añadir fecha aqui
      subtitle={replaceStrongTags(t('employeeForm.formSubtitle3'))}>
      <a
        href={EMPLOYEE_FORM_GUIDE_TRANSLATED}
        target='_blank'
        rel='noreferrer'
        className='highlight-text-color pointer subtitle3-font'>
        {t('employeeForm.guideHelp')}
      </a>
      <Button
        className='button-employee-form'
        lookAndFeel={'primary'}
        onClick={handleInItinere}
        text={t('employeeForm.next')}
      />
    </StepControllerContainer>
  );
}

export default StepNewInit;
