import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sectors } from '../../../constants/sectors';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { Organization } from '../../../types/entities/organization';
import apiFetch from '../../../utils/apiFetch';
import checkFormErrors from '../../../utils/checkFormErrors';
import { convertDateToString, convertStringToDate } from '../../../utils/convertDates';
import { formatDate } from '../../../utils/formatDate';
import Button from '../../ui/button/Button';
import InputCalendar from '../../ui/formComponents/inputCalendar/InputCalendar';
import InputNumber from '../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../ui/formComponents/inputText/InputText';
import Select from '../../ui/formComponents/select/Select';
import useSubscriptionPlanOptions from './hooks/useSubscriptionPlanOptions';
import Modal from '../../ui/modal/Modal';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { InputSize } from '../../../types/utilsEnums/input';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import FormNumber from '../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormCalendar from '../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import Icon from '../../ui/icon/Icon';

type FormData = {
  name: string;
  sector: SelectOptionFormat;
  limitFacilities: number;
  limitVehicles: number;
  limitEmployees: number;
  limitBusinessTravels: number;
  limitUsers: number;
  limitShipments: number;
  limitDeliveries: number;
  limitPurchases: number;
  limitOfficialSupplierBonus: string;
  subscriptionPlan: SelectOptionFormat;
  lastPaymentDate: string;
  subscriptionPlanExpireDate: string;
  employeeCountSignup: number;
  vat: string;
  errors: ErrorType[];
};
type Props = {
  organizationToEdit: Organization;
  editOrganization: (value: Organization, id: string) => void;
};
function EditOrganization({ organizationToEdit, editOrganization }: Props) {
  const { t, i18n } = useTranslation();

  const subscriptionPlanOptions = useSubscriptionPlanOptions();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  let sectorParsed = '';
  const sectorFound = sectors.find((elem) => elem.id === organizationToEdit.sector) as {
    title: { en: string; es: string };
  };
  if (sectorFound) {
    sectorParsed = sectorFound.title[i18n.language.split('-')[0] as keyof Language];
  }

  const [formData, setFormData] = useState<FormData>({
    name: organizationToEdit.company_name || '',
    sector: { id: organizationToEdit.sector || '', name: sectorParsed },
    limitFacilities: organizationToEdit.limit_facilities || 0,
    limitVehicles: organizationToEdit.limit_vehicles || 0,
    limitEmployees: organizationToEdit.limit_employees || 0,
    limitBusinessTravels: organizationToEdit.limit_business_travels || 0,
    limitUsers: organizationToEdit.limit_users || 0,
    limitShipments: organizationToEdit.limit_shipments || 0,
    limitDeliveries: organizationToEdit.limit_deliveries || 0,
    limitPurchases: organizationToEdit.limit_purchases || 0,
    limitOfficialSupplierBonus: organizationToEdit.limit_official_suppliers_bonus ?? '0',
    subscriptionPlan: {
      id: organizationToEdit.subscription_plan,
      name: t(`admin.${organizationToEdit.subscription_plan}`)
    },
    lastPaymentDate: formatDate(
      organizationToEdit.last_payment_date
        ? new Date(organizationToEdit.last_payment_date)
        : new Date()
    ),
    subscriptionPlanExpireDate: formatDate(
      organizationToEdit.subscription_plan_expire_date
        ? new Date(organizationToEdit.subscription_plan_expire_date)
        : new Date()
    ),
    employeeCountSignup: organizationToEdit.employee_count_signup || 0,
    vat: organizationToEdit.vat || '',
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue<FormData>({ setFormData });

  const handleErrors = () => {
    const optionalFields = ['errors'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const onChangeSubscriptionPlan = (value: SelectOptionFormat) => {
    setFormData({
      ...formData,
      subscriptionPlan: value,
      limitFacilities: 1,
      limitVehicles: 1,
      limitEmployees: 0,
      limitBusinessTravels: 0,
      limitUsers: 1,
      limitShipments: 0,
      limitDeliveries: 0,
      limitPurchases: 0,
      subscriptionPlanExpireDate: formatDate(new Date(today.setDate(today.getDate() + 14)))
    });
  };

  const handleEditOrganization = async () => {
    if (handleErrors()) return;

    try {
      setLoadingButton(true);
      const organization = {
        company_name: formData.name,
        limit_facilities: formData.limitFacilities,
        limit_vehicles: formData.limitVehicles,
        limit_employees: formData.limitEmployees,
        limit_business_travels: formData.limitBusinessTravels,
        limit_users: formData.limitUsers,
        limit_purchases: formData.limitPurchases,
        limit_shipments: formData.limitShipments,
        limit_deliveries: formData.limitDeliveries,
        limit_official_suppliers_bonus: formData.limitOfficialSupplierBonus,
        subscription_plan: formData.subscriptionPlan.id,
        last_payment_date: moment(convertStringToDate(formData.lastPaymentDate)).format(
          'YYYY-MM-DD'
        ),
        subscription_plan_expire_date: formData.subscriptionPlanExpireDate
          ? moment(convertStringToDate(formData.subscriptionPlanExpireDate)).format('YYYY-MM-DD')
          : null,
        employee_count_signup: formData.employeeCountSignup,
        vat: formData.vat,
        country: organizationToEdit.country,
        sector: formData.sector.id
      };

      if (formData.subscriptionPlan.id === 'free_plan') {
        organization.limit_facilities = 1;
        organization.limit_vehicles = 1;
        organization.limit_employees = 0;
        organization.limit_business_travels = 0;
        organization.limit_users = 1;
        organization.limit_purchases = 0;
        organization.limit_shipments = 0;
        organization.limit_deliveries = 0;
        organization.limit_official_suppliers_bonus = '0';
      }

      const response = await apiFetch(
        'PATCH',
        `/organizations/${organizationToEdit.id}`,
        organization
      );
      setLoadingButton(false);
      if (response.status === 200) {
        editOrganization(response.data, organizationToEdit.id);
      }
    } catch (error) {
      setLoadingButton(false);
      console.error(error);
    }
  };

  // variable that represents today date plus 5 years
  const fiveYearsFromNow = new Date();
  fiveYearsFromNow.setFullYear(fiveYearsFromNow.getFullYear() + 5);

  const disabled = formData.subscriptionPlan.id === 'free_plan';

  return (
    <div className='edit-organization'>
      <Modal.Header title={t('admin.editOrganization')} />
      <Modal.Content>
        <FormWrapper>
          <FormSelect
            icon={'/images/icons/piechart.svg'}
            label={t('admin.sector')}
            value={formData.sector}
            onChange={onChangeValue('sector')}
            placeholder={''}
            options={sectors.map((elem) => {
              return {
                id: elem.id,
                name: elem.title[i18n.language.split('-')[0] as keyof Language]
              };
            })}
            error={formData.errors.find((elem) => elem.error === 'sector')}
          />
          <FormText
            icon={'/images/icons/organization.svg'}
            placeholder={t('admin.writeOrganizationName')}
            label={t('admin.name')}
            value={formData.name}
            onChange={onChangeValue('name')}
            error={formData.errors.find((elem) => elem.error === 'name')}
          />
          <FormText
            label={t('admin.vat')}
            icon='/images/icons/idcard.svg'
            placeholder={t('register.vatPlaceholder')}
            value={formData.vat}
            onChange={onChangeValue('vat')}
            error={formData.errors.find((elem) => elem.error === 'vat')}
          />

          <FormSelect
            label={t('admin.subscriptionPlan')}
            icon='/images/icons/envelope.svg'
            options={subscriptionPlanOptions}
            value={formData.subscriptionPlan}
            onChange={onChangeSubscriptionPlan}
          />

          <FormNumber
            icon={'/images/icons/building.svg'}
            label={t('admin.limitFacilities')}
            placeholder={t('admin.writeLimitFacilities')}
            value={formData.limitFacilities.toString()}
            onChange={onChangeValue('limitFacilities')}
            disabled={disabled}
          />
          <FormNumber
            icon={'/images/icons/car.svg'}
            label={t('admin.limitVehicles')}
            placeholder={t('admin.writeLimitVehicles')}
            value={formData.limitVehicles.toString()}
            onChange={onChangeValue('limitVehicles')}
            disabled={disabled}
          />
          <FormNumber
            icon={'/images/icons/people.svg'}
            label={t('admin.limitEmployees')}
            placeholder={t('admin.writeLimitEmployees')}
            value={formData.limitEmployees.toString()}
            onChange={onChangeValue('limitEmployees')}
            disabled={disabled}
          />
          <FormNumber
            icon={'/images/icons/plane.svg'}
            label={t('admin.limitBusinessTravels')}
            placeholder={t('admin.writeLimitBusinessTravels')}
            value={formData.limitBusinessTravels.toString()}
            onChange={onChangeValue('limitBusinessTravels')}
            disabled={disabled}
          />
          <FormNumber
            icon={'/images/icons/person.svg'}
            label={t('admin.limitUsers')}
            placeholder={t('admin.writeLimitUsers')}
            value={formData.limitUsers.toString()}
            onChange={onChangeValue('limitUsers')}
            disabled={disabled}
          />
          <FormNumber
            icon={'/images/icons/shipmentGradient.svg'}
            label={t('admin.limitShipments')}
            placeholder={t('admin.writeLimitShipments')}
            value={formData.limitShipments.toString()}
            onChange={onChangeValue('limitShipments')}
            disabled={disabled}
          />
          <FormNumber
            icon={'/images/icons/cardBox.svg'}
            label={t('admin.limitDeliveries')}
            placeholder={t('admin.writeLimitDeliveries')}
            value={formData.limitDeliveries.toString()}
            onChange={onChangeValue('limitDeliveries')}
            disabled={disabled}
          />
          <FormNumber
            icon={'/images/icons/shoppingBagGradient.svg'}
            label={t('admin.limitPurchases')}
            value={formData.limitPurchases.toString()}
            onChange={onChangeValue('limitPurchases')}
            disabled={disabled}
          />
          <FormNumber
            icon='/images/icons/people.svg'
            label={t('admin.employeeCountSignup')}
            value={formData.employeeCountSignup.toString()}
            onChange={onChangeValue('employeeCountSignup')}
            disabled={disabled}
          />
          <FormNumber
            iconV2={'cash'}
            label={t('admin.limitOfficialSupplierBonus')}
            value={formData.limitOfficialSupplierBonus}
            onChange={onChangeValue('limitOfficialSupplierBonus')}
            disabled={disabled}
          />
          {formData.lastPaymentDate && (
            <FormCalendar
              label={t('admin.lastPaymentDate')}
              handleChangeDate={onChangeValue('lastPaymentDate')}
              dateValue={formData.lastPaymentDate}
              error={formData.errors.find((elem) => elem.error === 'lastPaymentDate')}
            />
          )}
          <FormCalendar
            label={t('admin.subscriptionPlanExpireDate')}
            handleChangeDate={onChangeValue('subscriptionPlanExpireDate')}
            dateValue={formData.subscriptionPlanExpireDate}
            error={formData.errors.find((elem) => elem.error === 'subscriptionPlanExpireDate')}
            maxDate={convertDateToString(fiveYearsFromNow)}
          />
        </FormWrapper>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel='primary'
          text={t('admin.edit')}
          onClick={handleEditOrganization}
          loading={loadingButton}
        />
      </Modal.Buttons>
    </div>
  );
}

export default EditOrganization;
