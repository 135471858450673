import { useFrameworkCodes } from 'components/measure/activity/purchases/suppliersManagement/hooks/useFrameworkCodes';
import { useNuvoCountries } from 'customHooks/useNuvoCountries';
import { ColumnAPI } from 'nuvo-react';
import { PURCHASE_FRAMEWORK_OPTIONS, PurchaseFramework } from 'utils/mapToPurchaseFramework';

export const useColumns = () => {
  const { data: sicCodes } = useFrameworkCodes({ framework: PurchaseFramework.SIC });
  const { data: cnaeCodes } = useFrameworkCodes({ framework: PurchaseFramework.CNAE });
  const { countriesNuvo } = useNuvoCountries();
  const codeOptions = sicCodes
    .map((item) => ({
      label: item.name,
      value: `${item.id}-sic`,
      type: 'string' as const,
      alternativeMatches: [`${item.id}`],
      validations: [
        {
          validate: {
            EQ: { code_type: PurchaseFramework.SIC }
          },
          errorMessage: 'Code must be from the selected framework'
        }
      ]
    }))
    .concat(
      cnaeCodes.map((item) => ({
        label: item.name,
        value: `${item.id}-cnae`,
        type: 'string' as const,
        alternativeMatches: [`${item.id}`],
        validations: [
          {
            validate: {
              EQ: { code_type: PurchaseFramework.CNAE }
            },
            errorMessage: 'Code must be from the selected framework'
          }
        ]
      }))
    );

  const columns: ColumnAPI[] = [
    {
      key: 'name',
      label: 'Name',
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'country',
      label: 'Country',
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'code',
      label: 'Code',
      columnType: 'category',
      dropdownOptions: codeOptions,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'code_type',
      label: 'Code Type',
      columnType: 'category',
      dropdownOptions: PURCHASE_FRAMEWORK_OPTIONS.map((item) => ({
        label: item.name,
        value: item.id,
        type: 'string'
      })),
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  return { columns };
};
