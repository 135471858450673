import { PieChart } from 'react-minimal-pie-chart';
import numberToDecimal from '../../../../../utils/numberToDecimal';
import './styles.scss';
type PieChartData = {
  title: string;
  value: number;
  color: string;
};

type Legend = {
  title: string;
  color: string;
  value: number;
};
type Props = {
  pieChartData: PieChartData[];
  totalCo2?: number;
  legends: Legend[];
};

const sortPieChartData = (pieChartData: PieChartData[]) => {
  return [...pieChartData].sort((a, b) => b.value - a.value);
};

const PieChartDistribution = ({ pieChartData, totalCo2, legends }: Props) => {
  const sortedPieChartData = sortPieChartData(pieChartData);

  const renderCo2Information = () => {
    if (totalCo2 === undefined) return null;
    return (
      <div className='co2-information'>
        <div className='co2-value co2-dashboard-font-small'>{numberToDecimal(totalCo2)}</div>
        <span className='co2-units on-light-text-color body1-font'>t CO₂ eq.</span>
      </div>
    );
  };

  const renderLegends = () => {
    return (
      <div className='pie-chart-distribution__legends-wrapper'>
        {legends.map((legend, index) => (
          <div key={`${legend.title}-${index}`} className='item'>
            <div className='item__color' style={{ backgroundColor: legend.color }}></div>
            <span className='body1-bold-font'> {legend.title}</span>
            <span className='body1-font'>{legend.value} %</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='pie-chart-distribution'>
      <div className='co2-progress-bar'>
        <PieChart // your data
          data={sortedPieChartData}
          // width and height of the view box
          viewBoxSize={[100, 100]}
          lineWidth={24}
          totalValue={100}
          rounded
          animate></PieChart>
        {totalCo2 && totalCo2 >= 0 && renderCo2Information()}
      </div>
      {renderLegends()}
    </div>
  );
};

export default PieChartDistribution;
