import { Column } from '../../../types/table';
import Table from '../table/Table';
import './styles.scss';
type Props = {
  data: UploadFileBulkError[];
  columns: Column[];
};
const ErrorList = ({ data, columns }: Props) => {
  const parseRow = (elem: UploadFileBulkError) => {
    const rowParsed: { [key: string]: any } = { ...elem.row };

    if (columns.find((column) => column.dataIndex === 'error')) {
      let errors = [];

      if (typeof elem.errors === 'object' && !Array.isArray(elem.errors)) {
        elem.errors = elem.errors as object;
        errors.push(
          Array.from(Object.entries(elem.errors), (x: any[]) => {
            return x[1].message;
          })
        );
      } else {
        errors = elem.errors;
      }

      rowParsed.error = <span className='error-text-color'>{errors.join(', ')}</span>;
    }
    if (columns.find((column) => column.dataIndex === 'row_index')) {
      // Add row_index to rowParsed, +1 because the first row are the columns in the CSV
      rowParsed.row_index = (elem.row_index + 1).toString();
    }
    return rowParsed;
  };

  return (
    <div className='error-list'>
      <Table columns={columns} data={data.map((elem) => parseRow(elem))} loading={false} />
    </div>
  );
};

export default ErrorList;
