import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const FormButtonSection = ({ ...props }: Props) => {
  return (
    <div className={`form-button-section ${props.className ?? ''}`} {...props}>
      {props.children}
    </div>
  );
};

export default FormButtonSection;
