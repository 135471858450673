import { ChangeEvent } from 'react';
import { InputSize } from '../../../../types/utilsEnums/input';
import './styles.scss';

type Props = {
  fontClass?: string;
  showCalendar: boolean;
  date: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  onKeyDown: () => void;
  resetDate: () => void;
  size?: InputSize;
  disabled: boolean;
};
const InputDateText = ({
  fontClass,
  showCalendar,
  date,
  onChange,
  onClick,
  onKeyDown,
  resetDate,
  size = InputSize.MEDIUM,
  disabled
}: Props) => {
  const sizeClassName = size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';

  return (
    <div className='input__calendar__content__date'>
      <input
        type='text'
        className={`${fontClass} ${showCalendar ? 'input-placeholder-color' : ''} ${
          disabled ? 'transparent-bg-color' : ''
        } ${sizeClassName} basic-input`}
        value={date}
        placeholder={'dd/mm/yyyy'}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onKeyDown();
          }
        }}
      />
      {/* {resetDate ? <img src='/images/icons/close.svg' onClick={resetDate} /> : null} */}
    </div>
  );
};

export default InputDateText;
