import { z } from 'zod';

import { STEP_ENUM } from './constants';

const metadataSchema = z.object({
  name: z.string().trim().min(1, 'error.name_required').max(100, 'error.name_max_length'),
  startDate: z.string().min(10, 'error.start_date_required'),
  endDate: z.string().min(10, 'error.end_date_required'),
  value: z.string().min(1, 'error.value_required'),
  unit: z.object({
    id: z.string().min(1, 'error.unit_required'),
    name: z.string()
  })
});

const impactCategoriesSchema = metadataSchema.extend({
  impactCategories: z.array(z.string()).min(3, 'error.impact_categories_required')
});

export const schema = {
  [STEP_ENUM.METADATA]: metadataSchema,
  [STEP_ENUM.IMPACT_CATEGORIES]: impactCategoriesSchema
};
