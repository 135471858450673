import { useTranslation } from 'react-i18next';

const useSortingOptions = () => {
  const { t } = useTranslation();
  return [
    { id: 'created_at', name: t('input.sorting.facilities.creationDate') },
    { id: 'name', name: t('input.sorting.facilities.facilityName') },
    { id: 'updated_at', name: t('input.sorting.facilities.editionDate') },
    { id: 'co2e', name: t('input.sorting.facilities.co2Tons') }
  ];
};

export default useSortingOptions;
