import { useTranslation } from 'react-i18next';
import { EsgMetricsEnhanced } from '../../../../../../../../types/entities/esgMetrics';
import { Column } from '../../../../../../../../types/table';
import Button from '../../../../../../../ui/button/Button';
import FormSelect from '../../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import DisabledLabel from '../../../../../../../ui/statusLabels/disabledLabel/DisabledLabel';
import PendingLabel from '../../../../../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../../../../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../../../../../../ui/tooltip/TooltipWrapper';
import { useFeatureFlags } from '../../../../../../../../customHooks/useFeatureFlags';
import { User } from 'types/entities/user';
import NameIcon from 'components/ui/nameIcon/NameIcon';

type Props = {
  setDatapointToEdit: (datapoint: EsgMetricsEnhanced) => void;
  handleEditRelevance: (value: SelectOptionFormat, datapoint: EsgMetricsEnhanced) => void;
  setShowPremiumModal: (value: boolean) => void;
  dataSource?: boolean;
};

const capitalizeFirstLetter = (text: string) => {
  if (text?.length === 0) {
    return text; // Return the empty string if input is empty
  }
  return text?.charAt(0).toUpperCase() + text?.slice(1);
};
const placeholdersTooltips = [
  {
    id: 'E1-1_01',
    text: 'Impactos sobre el cambio climático, en particular, las emisiones de GEI de la empresa (tal como exige el Requisito de Divulgación ESRS E1-6)'
  },
  {
    id: 'E1-1_02',
    text: 'Riesgos físicos relacionados con el clima en las operaciones propias y a lo largo de la cadena de valor ascendente y descendente, en particular I.la identificación de riesgos relacionados con el clima, considerando al menos escenarios climáticos de altas emisiones; y II.la evaluación de cómo sus activos y actividades empresariales pueden estar expuestos y son sensibles a estos peligros relacionados con el clima, creando riesgos físicos graves para la empresa'
  },
  {
    id: 'E1-1_03',
    text: 'Ha identificado los peligros relacionados con el clima (véase la tabla siguiente) a corto, medio y largo plazo y ha analizado si sus activos y actividades empresariales pueden estar expuestos a estos peligros'
  },
  {
    id: 'E1-1_04',
    text: 'La identificación de los riesgos relacionados con el clima y la evaluación de la exposición y la sensibilidad se basan en escenarios climáticos de altas emisiones, que pueden, por ejemplo, estar basados en los SSP5-8.5 del IPCC, en proyecciones climáticas regionales pertinentes basadas en estos escenarios de emisiones, o en escenarios climáticos de la NGFS (Network for Greening the Financial System) con alto riesgo físico, como «Hot house world» o «Too little, too late». Para los requisitos generales relativos al análisis de escenarios relacionados con el clima, véanse los apartados 18, 19, AR 13 a AR 15.'
  }
];

const useColumns = ({
  setDatapointToEdit,
  handleEditRelevance,
  setShowPremiumModal,
  dataSource
}: Props) => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const renderTag = (elem: EsgMetricsEnhanced) => {
    const tagMap: { [key: string]: JSX.Element } = {
      COMPLETE: <SuccessLabel key='completed'>{t('general.withData')}</SuccessLabel>,
      INCOMPLETE: <PendingLabel key='pending'>{t('general.pending')}</PendingLabel>,
      OPTIONAL: (
        <DisabledLabel key='optional' icon='information'>
          {t('general.optional')}
        </DisabledLabel>
      )
    };
    let text: string = elem.status;
    if (elem.status === 'INCOMPLETE' && elem.relevance === 'not_mandatory') {
      text = 'OPTIONAL';
    }

    return tagMap[text];
  };
  const renderTypeTag = (elem: EsgMetricsEnhanced) => {
    return (
      <div
        className='text-tag body3-font tag-bg-text-color tag-space-between'
        style={{ width: 'fit-content', padding: '4px 8px' }}>
        {capitalizeFirstLetter(t(`esgMetrics.dataTypes.${elem.data_type}`))}
      </div>
    );
  };

  const renderNameWithTooltip = (elem: EsgMetricsEnhanced) => {
    // Find the tooltip object where the id matches elem.id
    const tooltip = placeholdersTooltips.find((placeholder) => placeholder.id === elem.short_name);

    return (
      <TooltipWrapper
        text={tooltip ? tooltip.text : ''} // Use the text property directly
        shouldAppear={!!tooltip} // Only show the tooltip if it exists
        position='top'>
        <span>{capitalizeFirstLetter(t(`esgMetrics.datapoints.${elem.id}`))}</span>
      </TooltipWrapper>
    );
  };

  const columns: Column[] = [
    ...(dataSource
      ? [
          {
            title: t('esgMetrics.general.dataPointNumber'),
            dataIndex: 'datapoint_number',
            key: 'datapoint_number'
          },
          {
            title: t('esgMetrics.general.datasource'),
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            render: (text: string) => <span>{capitalizeFirstLetter(text)}</span>
          }
        ]
      : [
          {
            title: t('esgMetrics.general.idDatapoint'),
            dataIndex: 'short_name',
            key: 'short_name'
          },
          {
            title: t('esgMetrics.general.datapoint'),
            dataIndex: 'id',
            key: 'id',
            width: '40%',
            render: (_: unknown, data: EsgMetricsEnhanced) => renderNameWithTooltip(data)
          },
          {
            title: t('esgMetrics.general.dataType'),
            dataIndex: 'data_type',
            key: 'data_type',
            render: (_: unknown, data: EsgMetricsEnhanced) => renderTypeTag(data)
          }
        ]),
    {
      title: t('esgMetrics.general.state'),
      dataIndex: 'status',
      key: 'state',
      render: (_: unknown, data: EsgMetricsEnhanced) => renderTag(data)
    },
    {
      title: t('controlPanel.metrics.responsible'),
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      render: (user: User) => {
        return (
          <span className='flex gap-x-2 items-center'>
            <NameIcon name={user?.first_name ?? '-'} lastName={user?.last_name} />
            {user?.id
              ? `${user?.first_name} ${user?.last_name ?? ''}`
              : t('controlPanel.metrics.unassigned')}
          </span>
        );
      }
    },
    {
      title: t('esgMetrics.general.relevance'),
      dataIndex: 'relevance',
      key: 'relevance',
      render: (_: unknown, data: EsgMetricsEnhanced) => (
        <TooltipWrapper
          text={
            !flags?.csrd
              ? t('esgMetrics.general.relevanceBlockedTooltip')
              : t('esgMetrics.general.relevanceTooltip')
          }
          shouldAppear={data.relevance === 'always_mandatory' || !flags?.csrd}>
          <div style={{ width: '3rem' }}>
            <FormSelect
              value={{
                id: data.relevance,
                name: t(`esgMetrics.${data.relevance?.replace('always_mandatory', 'mandatory')}`)
              }}
              onChange={(value: SelectOptionFormat) => handleEditRelevance(value, data)}
              options={[
                {
                  id: 'mandatory',
                  name: t('esgMetrics.mandatory')
                },
                {
                  id: 'not_mandatory',
                  name: t('esgMetrics.not_mandatory')
                }
              ]}
              disabled={data.relevance === 'always_mandatory' || !flags?.csrd}
              sort={false}
            />
          </div>
        </TooltipWrapper>
      )
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '12%',
      render: (_: unknown, data: EsgMetricsEnhanced) => {
        return data.value ? (
          <Button
            lookAndFeel={flags?.csrd ? 'secondary' : 'blocked'}
            size='small'
            text={t('esgMetrics.general.edit')}
            onClick={() => setDatapointToEdit(data)}
            style={{ whiteSpace: 'nowrap' }}
            onClickBlocked={
              flags?.csrd
                ? undefined
                : () => {
                    setShowPremiumModal(true);
                  }
            }
          />
        ) : (
          <Button
            lookAndFeel={flags?.csrd ? 'secondary' : 'blocked'}
            size='small'
            text={t('esgMetrics.general.addResponse')}
            onClick={() => setDatapointToEdit(data)}
            style={{ whiteSpace: 'nowrap' }}
            onClickBlocked={
              flags?.csrd
                ? undefined
                : () => {
                    setShowPremiumModal(true);
                  }
            }
          />
        );
      }
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
