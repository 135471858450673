import './styles.scss';
type Props = {
  title: string;
  subtitle?: string;
  breadcrumb?: React.ReactNode;
};
const SectionHeader = ({ title, subtitle, breadcrumb }: Props) => {
  return (
    <div className='section-header page-header'>
      <h3 className='headline3-font on-light-text-color'>{title}</h3>
      {subtitle && <h4 className='subtitle2-font on-light-text-color'>{subtitle}</h4>}
      {breadcrumb}
    </div>
  );
};

export default SectionHeader;
