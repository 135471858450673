import { useState } from 'react';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import apiFetch from '../../../utils/apiFetch';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';

type FormData = {
  invofoxId: string;
  errors: ErrorType[];
};

type InvofoxData = {
  organization_name: string;
  facility_name: string;
  enabled: boolean;
  created_at: string;
  updated_at: string;
  user_email: string;
};
const Invofox = () => {
  const [formData, setFormData] = useState<FormData>({
    invofoxId: '',
    errors: []
  });

  const [invofoxData, setInvofoxData] = useState<InvofoxData>();

  const [loading, setLoading] = useState<boolean>(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleFetchInvofox = async () => {
    setLoading(true);

    const response = await apiFetch('GET', `/invofox/${formData.invofoxId}`);

    setInvofoxData(response.data);
    setLoading(false);
  };

  return (
    <div className='admin-invofox'>
      <SectionHeader title={'Invofox'} />
      <div
        style={{
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '1rem',
          boxSizing: 'border-box'
        }}>
        <div style={{ width: '100%' }} className='flex items-end gap-2'>
          <div style={{ width: '20rem' }}>
            <FormText
              iconV2={'fire'}
              placeholder='Invofox ID'
              label='Invofox ID'
              value={formData.invofoxId}
              onChange={onChangeValue('invofoxId')}
            />
          </div>
          <Button
            lookAndFeel='primary'
            text='Search'
            onClick={handleFetchInvofox}
            size='medium'
            style={{ width: '7rem' }}
            loading={loading}
            disabled={!formData.invofoxId}
          />
        </div>
        {invofoxData && (
          <div className='invofox-data flex flex-col gap-2 mt-4'>
            <span>
              Organization: <span className='weight-600'>{invofoxData.organization_name}</span>
            </span>
            <span>
              Facility: <span className='weight-600'>{invofoxData.facility_name}</span>
            </span>
            <span>
              Enabled: <span className='weight-600'>{invofoxData.enabled ? 'Yes' : 'No'}</span>
            </span>
            <span>
              Created At: <span className='weight-600'>{invofoxData.created_at}</span>
            </span>
            <span>
              Updated At: <span className='weight-600'>{invofoxData.updated_at}</span>
            </span>
            <span>
              Uploaded by: <span className='weight-600'>{invofoxData.user_email}</span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Invofox;
