import Label from '../../label/Label';
import './styles.scss';
import '../styles.scss';
import { IconName } from '../../../../types/utils/iconName';
import Icon from '../../icon/Icon';

type Props = React.ComponentProps<typeof Label> & {
  icon?: IconName;
  showIcon?: boolean;
};

<img src='/images/icons/errorWarning.svg' alt='error' width={16} height={16} />;

const ErrorLabel = ({ children, showIcon = true, icon = 'error', ...rest }: Props) => {
  return (
    <Label {...rest} lookAndFeel='error'>
      <div className='label-loading-wrapper error-loading-wrapper'>
        {showIcon && <Icon icon={icon} color='error' size='medium' />}
        {children}
      </div>
    </Label>
  );
};

export default ErrorLabel;
