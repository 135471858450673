import Skeleton from 'react-loading-skeleton';
import './styles.scss';
import { HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  count: number;
}
function CustomSkeletonLoader({ count, ...rest }: Props) {
  return (
    <div className='loading-skeleton-wrapper' {...rest}>
      {/* <SkeletonTheme baseColor='#202020' highlightColor='#000000' width='50px'> */}
      <Skeleton className='loading-skeleton' count={count} />
      {/* </SkeletonTheme> */}
    </div>
  );
}

export default CustomSkeletonLoader;
