import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { SIZE_PAGINATION_BIG } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import usePaginatedList from '../../../customHooks/paginatedList';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { GroupingType } from '../../../types/entities/grouping';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import CardList from '../../ui/cardList/CardList';
import CardsFilter from '../../ui/filter/CardsFilter';
import Modal from '../../ui/modal/Modal';
import CreateGrouping from './createGrouping/CreateGrouping';
import DeleteGrouping from './deleteGrouping/DeleteGrouping';
import EditGrouping from './editGrouping/EditGrouping';
import './styles.scss';

import './styles.scss';

const MODAL_WIDTH = '500px';
const Grouping = () => {
  const { t } = useTranslation();

  const selectedOrganization = useSelectedOrganization();

  const [showCreate, setShowCreate] = useState(false);
  const [groupingToDelete, setGroupingToDelete] = useState<string>('');
  const [groupingToEdit, setGroupingToEdit] = useState<GroupingType | null>(null);
  const [listView, setListView] = useState(false);
  const [loading, setLoading] = useState(true);

  // Filters
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const size = SIZE_PAGINATION_BIG;
  const url = '/data_groups';

  const filters = {
    inputTextList: [
      {
        name: 'name',
        value: searchValue
      }
    ]
  };

  // fetchPagination
  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values,
    setValues: setGroupings
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: selectedOrganization?.id,
    filters
  });
  const groupings: GroupingType[] = values;

  useEffect(() => {
    fetchData();
  }, [searchValue]);

  const handleShowCreate = () => {
    setShowCreate(true);
  };

  const onClickCard = (id: string) => {
    navigate(`${ROUTES.CUSTOM_GROUPING}/${id}`);
  };

  const handleEditGrouping = (id: string) => {
    // Find grouping
    const grouping = groupings.find((grouping) => grouping.id === id);
    if (!grouping) return;
    setGroupingToEdit(grouping);
  };

  const addGrouping = (grouping: GroupingType) => {
    setGroupings([grouping, ...groupings]);
    setShowCreate(false);
    dispatch(setNotification(t('notification.createGrouping')));
  };

  const removeGrouping = (id: string) => {
    setGroupings(groupings.filter((grouping) => grouping.id !== id));
    setGroupingToDelete('');
    dispatch(setNotification(t('notification.deleteGrouping')));
  };

  const editGrouping = (grouping: GroupingType) => {
    setGroupings(
      groupings.map((elem) => {
        if (elem.id === grouping.id) {
          return grouping;
        }
        return elem;
      })
    );
    setGroupingToEdit(null);
    dispatch(setNotification(t('notification.editGrouping')));
  };

  return (
    <section className='grouping'>
      <SectionHeader title={t('grouping.title')} breadcrumb={<Breadcrumb />} />
      <CardsFilter
        listView={listView}
        onChangeListView={() => {
          setListView(!listView);
        }}
      />
      <div id={'grouping'} className='infinite-scroll-wrapper-card'>
        <InfiniteScroll
          dataLength={groupings ? groupings.length : 0}
          next={fetchMoreData}
          hasMore={hasMore}
          style={{ overflow: 'visible' }}
          endMessage={<></>}
          loader={undefined}
          scrollableTarget={'grouping'}>
          <CardList
            listView={listView}
            title={t('grouping.newGrouping')}
            description={t('grouping.newGroupingDescription')}
            data={groupings.map((grouping) => ({
              id: grouping.id,
              title: grouping.name,
              description: grouping.description,
              icon: '/images/icons/folder.svg'
            }))}
            blocked={false}
            showCreateModal={handleShowCreate}
            loading={loading}
            onClickCard={onClickCard}
            dropDownOptions={[
              {
                id: 'edit',
                name: t('grouping.edit'),
                onClick: handleEditGrouping
              },
              {
                id: 'delete',
                name: t('grouping.delete'),
                onClick: setGroupingToDelete
              }
            ]}
            buttonText={t('grouping.create')}
            hideCo2e
          />
        </InfiniteScroll>
      </div>
      <Modal
        show={showCreate}
        onClose={() => setShowCreate(false)}
        width={MODAL_WIDTH}
        maxWidth={MODAL_WIDTH}>
        <CreateGrouping addGrouping={addGrouping} />
      </Modal>
      <Modal
        show={groupingToDelete !== ''}
        onClose={() => setGroupingToDelete('')}
        width={MODAL_WIDTH}
        maxWidth={MODAL_WIDTH}>
        <DeleteGrouping groupingToDelete={groupingToDelete} removeGrouping={removeGrouping} />
      </Modal>
      <Modal
        show={!!groupingToEdit}
        onClose={() => setGroupingToEdit(null)}
        width={MODAL_WIDTH}
        maxWidth={MODAL_WIDTH}>
        {groupingToEdit && (
          <EditGrouping editGrouping={editGrouping} groupingToEdit={groupingToEdit} />
        )}
      </Modal>
    </section>
  );
};

export default Grouping;
