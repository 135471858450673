import moment from 'moment';
import { sectorsEquivalence } from '../constants/sectorsEquivalence';
import { Organization } from '../types/entities/organization';
import numberToDecimal from './numberToDecimal';

const calculateSectorEquivalence = (
  organization: Organization,
  endDate: Date,
  totalCo2: number
) => {
  let sectorEquivalencePercentage = 0;
  let organizationSize = 'micro';
  if (organization.employee_count_signup >= 10 && organization.employee_count_signup < 50) {
    organizationSize = 'small';
  } else if (organization.employee_count_signup >= 50 && organization.employee_count_signup < 250) {
    organizationSize = 'medium';
  } else if (organization.employee_count_signup >= 250) {
    organizationSize = 'large';
  }

  // const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);
  // const startDateYear = momentStartDate.year();
  const endDateYear = momentEndDate.year();
  // const yearsBetween: number[] = [];
  // for (let i = startDateYear; i <= endDateYear; i++) {
  //   yearsBetween.push(i);
  // }

  // const daysBetween = momentEndDate.diff(momentStartDate, 'days');

  // const equivalences = [];
  // yearsBetween.forEach((year, index) => {
  //   const foundEquivalence = sectorsEquivalence.find((equivalence) => equivalence.size === organizationSize && equivalence.sector === organization.sector && equivalence.year === year.toString());
  //   if (foundEquivalence) {
  //     equivalences.push(foundEquivalence);
  //   } else {
  //     sectorsEquivalence
  //       .filter((equivalence) => equivalence.size === organizationSize && equivalence.sector === organization.sector)
  //       .sort((a, b) => (+a.year < +b.year ? 1 : -1))
  //       .forEach((equivalence) => {

  //       });
  //   }
  // });
  let foundEquivalence = sectorsEquivalence.find(
    (equivalence) =>
      equivalence.size === organizationSize &&
      equivalence.sector === organization.sector &&
      equivalence.year === endDateYear
  );

  if (!foundEquivalence) {
    const sectorsEquivalenceFiltered = sectorsEquivalence
      .filter(
        (equivalence) =>
          equivalence.size === organizationSize && equivalence.sector === organization.sector
      )
      .sort((a, b) => (+a.year < +b.year ? 1 : -1));
    if (sectorsEquivalenceFiltered.length > 0 && sectorsEquivalenceFiltered[0].year < endDateYear) {
      foundEquivalence = sectorsEquivalenceFiltered[0];
    } else if (
      sectorsEquivalenceFiltered.length > 0 &&
      sectorsEquivalenceFiltered[sectorsEquivalenceFiltered.length - 1].year > endDateYear
    ) {
      foundEquivalence = sectorsEquivalenceFiltered[sectorsEquivalenceFiltered.length - 1];
    }
  }
  const totalCo2Tons = totalCo2 / 1000;
  if (
    totalCo2Tons > 0 &&
    foundEquivalence &&
    foundEquivalence.co2_avg_s12 &&
    foundEquivalence.co2_avg_s12 > 0
  ) {
    sectorEquivalencePercentage =
      (totalCo2Tons - foundEquivalence.co2_avg_s12) / foundEquivalence.co2_avg_s12;
  }
  return `${sectorEquivalencePercentage > 0 ? '+' : ''}${numberToDecimal(
    sectorEquivalencePercentage * 100
  )}%`;
};

export default calculateSectorEquivalence;
