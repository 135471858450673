import { ReactNode } from 'react';
import './styles.scss';

type Props = {
  children: ReactNode[];
};

const ErrorPageContainer = ({ children }: Props) => {
  return <div className='error-page-container'>{children}</div>;
};

export default ErrorPageContainer;
