function getFileNameFromUrl(url: string) {
  // Step 1: Split the URL to remove the query parameters
  const urlWithoutParams = url.split('?')[0];

  // Step 2: Split the remaining URL by '/' and get the last segment
  const pathSegments = urlWithoutParams.split('/');
  const fileName = pathSegments[pathSegments.length - 1];

  return fileName;
}

export default getFileNameFromUrl;
