import { useEffect, useState } from 'react';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { getPurchasesItems } from '../../../../../../services/api/purchases';

const useProducts = () => {
  const selectedOrganization = useSelectedOrganization();

  const [products, setProducts] = useState<SelectOptionFormat[]>([]);

  const fetchProducts = async () => {
    if (!selectedOrganization) return;
    const response = await getPurchasesItems(selectedOrganization.id);
    if (response?.response?.status >= 400) return;
    setProducts(
      response.map((elem: string) => ({
        id: elem,
        name: elem
      }))
    );
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return products;
};

export default useProducts;
