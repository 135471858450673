import { createContext } from 'react';
import { EmployeePeriodFormType } from '../types/entities/employeePeriod';
import { CarSize, CommutingType } from '../types/entitiesEnums/employeePeriod';

type EmployeeFormContextType = {
  type: CommutingType | null;
  logo: string | null;
  formData: EmployeePeriodFormType;
  setFormData?: any;
  stepCounter: number;
  handleNext?: (next: string) => void;
  handleBack?: () => void;
  handleInItinere?: () => void;
  handleInLabore?: () => void;
  handleNewAnswer?: () => void;
  handleSubmit?: () => Promise<any>;
  errorSubmit?: ErrorType;
};

export const employeeFormEmpty = {
  transport: { id: '', name: '' },
  size: CarSize.NONE,
  fuelType: { id: '', name: '' },
  weeklyTravels: '',
  dailyTrips: '',
  totalKm: '',
  startDate: '',
  endDate: null,
  telecommuting: { id: '', name: '' },
  carpooling: { id: '', name: '' },
  errors: []
};

const EmployeeFormContext = createContext<EmployeeFormContextType>({
  type: null,
  logo: null,
  formData: employeeFormEmpty,
  setFormData: undefined,
  stepCounter: 1,
  handleNext: undefined,
  handleBack: undefined,
  handleInItinere: undefined,
  handleInLabore: undefined,
  handleNewAnswer: undefined,
  handleSubmit: undefined,
  errorSubmit: undefined
});
export { EmployeeFormContext };
