import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import { UserContext } from '../../../../../../../context/userContext';
import useGetBase64 from '../../../../../../../customHooks/useGetBase64';
import { uploadFilePresignedUrl } from '../../../../../../../services/api/aws';
import { getPresignedUrlLogistics } from '../../../../../../../services/api/logistics';
import { getOrganizationFiles } from '../../../../../../../services/api/organizationFiles';

import Button from '../../../../../../ui/button/Button';
import FileItem from '../../../../../../ui/fileItem/FileItem';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import InputFileSingle from '../../../../../../ui/formComponents/inputFile/InputFileSingle';
import Modal from '../../../../../../ui/modal/Modal';
import StepCarouselList from '../../../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../../../ui/stepCarouselList/useStepCarousel';
import Info from './components/Info';
import './styles.scss';

interface FileWithDate extends File {
  createdAt?: Date;
  fileUrl?: string;
}
type Props = {
  title: string;
  description: string;
  // uploadFile: (file: File) => void;
  removeFile: (index: number) => void;
  handleSaveFiles: (type: string) => void;
  files: File[];
  type: string;
  // fetchClients: () => void;
  maxSize?: {
    value: number;
    text: string;
  };
};
const UploadFiles = ({
  title,
  description,
  // uploadFile,
  removeFile,
  handleSaveFiles,
  files,
  type,
  // fetchClients,
  maxSize
}: Props) => {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const [file, setFile] = useState<FileWithDate>();

  const [previousFiles, setPreviousFiles] = useState<FileWithDate[]>([]);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const [showInfo, setShowInfo] = useState(false);

  const { fileBase64 } = useGetBase64(file);

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps
  } = useStepCarousel({
    stepsText: [
      {
        id: 'uploaded',
        text: t('logistics.uploaded')
      },
      {
        id: 'previous',
        text: t('logistics.previous')
      }
    ]
  });

  if (!user?.selectedOrganization) return null;

  const fetchFiles = async () => {
    try {
      const data = await getOrganizationFiles(user.selectedOrganization ?? '', `logistics_${type}`);
      if (data) {
        setPreviousFiles(
          data.map((elem: { file_name: string; created_at: string; file_url: string }) => {
            return {
              name: elem.file_name,
              createdAt: new Date(elem.created_at),
              fileUrl: elem.file_url
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const handleFileUpload = (fileUploaded: FileWithDate) => {
    setErrors([]);
    setFile(fileUploaded);
    // uploadFile(fileUploaded);
  };

  const handleSelect = (id: string) => {
    handleSelectCarousel(id);
  };

  const handleUploadFile = async () => {
    if (!file || !fileBase64) {
      setErrors((prev) =>
        prev.concat([
          {
            error: 'file',
            description: t('error.fileRequired')
          }
        ])
      );
      return;
    }

    if (maxSize && file.size > maxSize.value) {
      setErrors((prev) =>
        prev.concat([
          {
            error: 'file',
            description: t('error.maxSizeReach', { maxSize: maxSize.text })
          }
        ])
      );
      return;
    }

    setLoadingButton(true);
    const response = await getPresignedUrlLogistics(file.name, type);

    // await fetchClients();

    if (response?.response?.data?.message === 'MISSING_COLUMNS_LOGISTIC_REQUESTS') {
      const missingColumns = response?.response?.data?.data?.missing_columns;

      let stringMissingColumns = '';
      Object.keys(missingColumns).forEach((missing) => (stringMissingColumns += `, ${missing}`));

      setErrors((prev) =>
        prev.concat({
          error: 'missingColumns',
          description: t('logistics.errors.missingColumns', {
            missing_columns: stringMissingColumns.slice(1)
          })
        })
      );
      setLoadingButton(false);
      return;
    }
    if (response?.response?.data === 'LOGISTICS_UPLOAD_EXISTING_FILE_NAME_FOR_ORGANIZATION') {
      setErrors((prev) =>
        prev.concat({
          error: 'duplicateFile',
          description: t('logistics.errors.duplicateFile')
        })
      );
      setLoadingButton(false);
      return;
    }
    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    const { requests, recharges } = response;
    let upload_url = '';
    if (type === 'requests') upload_url = requests.upload_url;
    if (type === 'consumptions') upload_url = recharges.upload_url;

    const dataUpload = await uploadFilePresignedUrl(file, upload_url);

    handleSaveFiles(type);
    setLoadingButton(false);
  };

  const handleRemoveFile = (index: number) => {
    removeFile(index);
  };

  const renderTitle = () => {
    const text = t('logistics.uploadYourFiles');
    const replacements = [
      {
        search: '{{templates}}',
        component: (
          <span className='highlight-text-color pointer' onClick={downloadFile}>
            {t('logistics.templates')}
          </span>
        )
      },
      {
        search: '{{info}}',
        component: (
          <span className='highlight-text-color pointer' onClick={handleShowInfo}>
            {t('logistics.info')}
          </span>
        )
      }
    ];

    let replacedText: any = text;

    replacements.forEach(({ search, component }) => {
      replacedText = reactStringReplace(replacedText, search, () => component);
    });

    return replacedText;
  };

  const downloadFile = () => {
    const url = '/files/logistics/logistic_templates_Dcycle.zip';
    window.open(url, '_blank');
  };

  const handleShowInfo = () => setShowInfo(true);
  const handleHideInfo = () => setShowInfo(false);

  const arrayFile = [];
  if (file) arrayFile.push(file);

  return (
    <div className='upload-files'>
      <FormHeader title={title} description={description} />
      <p className='file-label body1-bold-font'>{renderTitle()}</p>
      <InputFileSingle
        handleFile={handleFileUpload}
        buttonText={t('logistics.uploadFile')}
        labelText={t('logistics.dragAndDropFile')}
        fileName={file?.name || ''}
        allowedTypes={[]}
        error={errors.find((error) => error.error === 'file')}
      />
      <div className='type-selector'>
        <span className='input-label-font on-light-text-color '>{t('logistics.documents')}</span>
        <StepCarouselList steps={steps} handleSelect={handleSelect} lookAndFeel={'small'} />
      </div>
      <div className='files-wrapper'>
        {stepSelected?.id === 'uploaded'
          ? arrayFile?.map((elem, index) => (
              <FileItem
                key={index}
                file={elem}
                index={index}
                icon={'/images/icons/paper.svg'}
                removeFile={handleRemoveFile}
              />
            ))
          : previousFiles?.map((elem, index) => (
              <FileItem
                key={index}
                file={elem}
                index={index}
                icon={'/images/icons/paper.svg'}
                createdAt={elem.createdAt}
                fileUrl={elem.fileUrl}
              />
            ))}
      </div>
      <Button
        lookAndFeel={'primary'}
        onClick={handleUploadFile}
        text={t('logistics.save')}
        size='small'
        loading={loadingButton}
      />
      {errors.find(
        (error) =>
          error.error === 'duplicateFile' ||
          error.error === 'missingColumns' ||
          error.error === 'maxSizeReach'
      ) && (
        <span className='error-text error-font error-text-color'>
          {
            errors.find(
              (error) =>
                error.error === 'duplicateFile' ||
                error.error === 'missingColumns' ||
                error.error === 'maxSizeReach'
            )?.description
          }
        </span>
      )}
      <Modal onClose={handleHideInfo} show={showInfo} maxWidth='500px' width='500px'>
        <Info goBack={handleHideInfo} />
      </Modal>
    </div>
  );
};

export default UploadFiles;
