import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getPrefixes from '../../../../constants/phonePrefixes';
import { UserContext } from '../../../../context/userContext';
import { updateVehicle } from '../../../../services/api/vehicle';
import { FormDataVehicles, Vehicle, VehicleFuel } from '../../../../types/entities/vehicle';
import { ConsumptionType } from '../../../../types/entities/vehicleConsumption';
import FormVehicle from '../formVehicle/FormVehicle';
import useCards from '../hooks/useCards';
import useCountries from '../hooks/useCountries';

type Props = {
  vehicleToEdit: Vehicle;
  editVehicle?: (value: Vehicle, id: string) => void;
};

const checkHasConsumption = (consumption: ConsumptionType[] | number) => {
  if (Array.isArray(consumption)) {
    return Boolean(consumption.length);
  }

  return Boolean(consumption);
};

const EditVehicle = ({ vehicleToEdit, editVehicle }: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);

  const cards = useCards();

  const countriesOptions = useCountries();
  const [error, setError] = useState<ErrorType>();

  const handleEditVehicle = async (
    formData: FormDataVehicles,
    knownVehicles: any,
    unknownVehicles: any,
    fuels: VehicleFuel[]
  ) => {
    setError(undefined);
    const knownVehicleId =
      !formData.noBrand && !formData.noModel
        ? knownVehicles.find(
            (vehicle: { brand: string; model: string }) =>
              vehicle.brand === formData.brand.id && vehicle.model === formData.model.id
          ).id
        : null;
    const unknownVehicleId =
      formData.noBrand || formData.noModel
        ? unknownVehicles.find(
            (vehicle: { type: string }) => vehicle.type === formData.unknownType.id
          )?.id
        : null;
    const vehicleFuelId = formData.fuel.id || null;

    let nameValue = formData.name;
    if (
      (!nameValue ||
        nameValue ===
          `${vehicleToEdit.known_vehicle_brand} ${vehicleToEdit?.known_vehicle_model}`) &&
      !formData.noBrand
    ) {
      nameValue = `${formData.brand.name} ${formData.model.name}`;
    } else if (!nameValue && formData.noBrand) {
      nameValue = formData.unknownType.id;
    }

    const vehicleModified = {
      known_vehicle_id: knownVehicleId ?? undefined,
      unknown_vehicle_id: unknownVehicleId ?? undefined,
      ownership: formData.type,
      vehicle_fuel_id: vehicleFuelId ?? undefined,
      is_known: formData.noBrand || formData.noModel ? false : true,
      type: formData.vehicleType.id ? formData.vehicleType.id : vehicleToEdit.type || '',
      name: nameValue,
      license_plate: formData.carRegistration || '',
      country: formData.country.id || vehicleToEdit.country,
      organization_id: user?.selectedOrganization ?? ''
    };

    const newVehicle = await updateVehicle(vehicleToEdit.id, vehicleModified);

    if (typeof newVehicle === 'string' && newVehicle.includes('Vehicle with license plate')) {
      setError({
        error: 'carRegistration',
        description: t('error.licensePlateAlreadyExists')
      });
      return;
    }
    if (editVehicle && newVehicle) {
      const foundFuel = fuels?.find((fuel) => fuel.id === newVehicle.vehicle_fuel_id);
      if (foundFuel) {
        newVehicle.vehicle_fuel_id = foundFuel?.id;
      }
      editVehicle(
        {
          ...newVehicle,
          known_vehicle_brand: formData.brand.id,
          known_vehicle_model: formData.model.id,
          unknown_vehicle_type: formData.unknownType.id,
          fuel: foundFuel?.fuel,
          vehicle_fuel: foundFuel?.fuel
        },
        vehicleToEdit.id
      );
    }
  };

  let countryFound = { id: '', name: '' };
  if (vehicleToEdit?.country) {
    const foundValue = getPrefixes(i18n.resolvedLanguage).find(
      (elem) => elem.code === vehicleToEdit.country
    );
    if (foundValue) {
      countryFound = { id: foundValue.code, name: foundValue.name };
    }
  } else {
    countryFound =
      countriesOptions && countriesOptions.length > 0 ? countriesOptions[0] : { id: '', name: '' };
  }

  return (
    <FormVehicle
      onSubmit={handleEditVehicle}
      title={t('vehicles.editVehicle')}
      description={t('vehicles.editVehicleDescription')}
      formDataFilled={{
        type: cards.find((card) => card.id === vehicleToEdit.ownership)?.id ?? cards[0].id,
        brand: vehicleToEdit.known_vehicle_brand
          ? {
              id: vehicleToEdit.known_vehicle_brand,
              name: vehicleToEdit.known_vehicle_brand
            }
          : {
              id: '',
              name: ''
            },
        noBrand: vehicleToEdit?.unknown_vehicle_type ? true : false,
        unknownType: vehicleToEdit?.unknown_vehicle_type
          ? {
              id: vehicleToEdit.unknown_vehicle_type,
              name: t(`vehicles.${vehicleToEdit.unknown_vehicle_type}`)
            }
          : { id: '', name: '' },
        name:
          vehicleToEdit?.name && !i18n.exists(`vehicles.${vehicleToEdit.name}`)
            ? vehicleToEdit?.name
            : '',
        model: vehicleToEdit?.known_vehicle_model
          ? {
              id: vehicleToEdit.known_vehicle_model,
              name: vehicleToEdit.known_vehicle_model
            }
          : { id: '', name: '' },
        noModel: vehicleToEdit?.unknown_vehicle_type ? true : false,
        carRegistration: vehicleToEdit?.license_plate || '',
        fuel:
          vehicleToEdit?.vehicle_fuel && vehicleToEdit.vehicle_fuel_id
            ? {
                id: vehicleToEdit.vehicle_fuel_id,
                name: t(`vehicle_fuels.${vehicleToEdit.vehicle_fuel}`)
              }
            : { id: '', name: '' },
        vehicleType: vehicleToEdit?.type
          ? { id: vehicleToEdit.type, name: t(`vehicles.${vehicleToEdit.type}`) }
          : { id: '', name: '' },
        country: countryFound,
        vehicleFuelFilter: { id: '', name: '' },
        gearboxFilter: { id: '', name: '' },
        cylinderCapacityFilter: { id: '', name: '' },
        vehiclePowerFilter: { id: '', name: '' },
        errors: []
      }}
      errorRequest={error}
      hasConsumption={checkHasConsumption(vehicleToEdit.consumptions)}
    />
  );
};

export default EditVehicle;
