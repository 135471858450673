import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../../../../context/userContext';
import { getGroups } from '../../../../../../../../../services/api/customEFOld';
import { getUnits } from '../../../../../../../../../services/api/unit';
import { getLowCodes } from '../../../../../../../../../services/api/waste';
import { EmissionFactorGroupType } from '../../../../../../../../../types/entities/emissionFactor';
import useSelectedOrganization from '../../../../../../../../../customHooks/useSelectedOrganization';
import { getFacilities } from '../../../../../../../../../services/api/facility';
import { Facility } from '../../../../../../../../../types/entities/facility';
import { HoldingCompany } from '../../../../../../../../../types/entities/holdings';
import { getMatrixChilds } from '../../../../../../../../../services/api/matrices';
import { Organization } from '../../../../../../../../../types/entities/organization';

const useGetData = () => {
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const { t } = useTranslation();
  const [units, setUnits] = useState<SelectOptionFormat[]>([]);
  const [lowCodes, setLerCodes] = useState<SelectOptionFormat[]>([]);
  const [codesForNuvo, setCodesForNuvo] = useState<any[]>([]);
  const [groups, setGroups] = useState<EmissionFactorGroupType[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<SelectOptionFormat[]>([
    {
      id: selectedOrganization?.id ?? '',
      name: selectedOrganization?.company_name ?? ''
    }
  ]);

  const requestOrganizations = async () => {
    const foundOrganization = user?.organizations?.find(
      (elem) => elem.id === user?.selectedOrganization
    );
    if (!foundOrganization || foundOrganization.role === 'fund') return;
    if (foundOrganization.is_group_fund || foundOrganization.role === 'member') {
      setOrganizationOptions([
        {
          id: selectedOrganization?.id ?? '',
          name: selectedOrganization?.company_name ?? ''
        }
      ]);
      return;
    }
    const data: HoldingCompany[] = await getMatrixChilds(selectedOrganization?.id ?? '');

    const newOrganizationOptions = data
      .filter((elem: HoldingCompany) => {
        const foundInUser = user?.organizations?.find(
          (organization: Organization) => organization.id === elem.child_id
        );

        if (!foundInUser) return false;

        return true;
      })
      .map((elem: HoldingCompany) => ({
        id: elem.child_id,
        name: elem.child_name
      }));
    newOrganizationOptions.push({
      id: selectedOrganization?.id ?? '',
      name: selectedOrganization?.company_name ?? ''
    });
    setOrganizationOptions(newOrganizationOptions);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (!user?.selectedOrganization) return;
      const request1 = getUnits({ type: 'solid' }, true);
      const request2 = getLowCodes({
        organization_id: user?.selectedOrganization,
        returnRequest: true
      });
      const request3 = getGroups(user?.selectedOrganization ?? '', true);

      const responses = await Promise.allSettled([request1, request2, request3]);
      if (responses[0].status === 'fulfilled')
        setUnits(
          responses[0].value.data
            .filter((unit: Unit) => unit.name === 'kilogram_(kg)')
            .map((unit: Unit) => ({
              id: unit.id,
              name: unit.name
            }))
        );
      if (responses[1].status === 'fulfilled') {
        setLerCodes(
          responses[1].value.data.map(
            (elem: { low_id: string; low_name: string; low_code: string }) => ({
              id: elem.low_id,
              name: `${elem.low_code} ${t(`low_codes.${elem.low_name}`)}`
            })
          )
        );

        setCodesForNuvo(
          responses[1].value.data.map(
            (elem: { low_id: string; low_name: string; low_code: string }) => ({
              label: t(`low_codes.${elem.low_name}`),
              value: elem.low_code,
              type: 'string'
            })
          )
        );
      }

      if (responses[2].status === 'fulfilled') setGroups(responses[2].value.data.items);

      await requestOrganizations();

      setLoading(false);
    };

    getData();
  }, []);

  return {
    units,
    lowCodes,
    codesForNuvo,
    groups,
    loading,
    organizationOptions
  };
};

export default useGetData;
