import { useContext, useState } from 'react';
import { DeletionModal } from '../../../../ui/deletionModal/DeletionModal';
import { Trans, useTranslation } from 'react-i18next';
import { deleteFile } from '../../service/deleteFile';
import { UserContext } from '../../../../../context/userContext';
import { IHeavyFileStatusFrontend } from '../../../../../types/entities/heavyFilesStatus';
import { CategoriesUploadedFiles } from '../../constants';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import Icon from '../../../../ui/icon/Icon';
import styles from './styles.module.scss';
import Button from '../../../../ui/button/Button';

type DownloadBTNProps = {
  file: IHeavyFileStatusFrontend;
};

const DownloadBTN: React.FC<DownloadBTNProps> = ({ file }) => {
  const onClick = () => {
    window.open(file.url, '_blank');
  };
  return (
    <button
      className={`${styles['link-gray-dark']} flex gap-x-1 font-body-b2-sb underline p-2 max-w-full`}
      onClick={onClick}>
      <span className='flex items-center'>
        <Icon icon='file' color='gray-dark' size='medium' />
      </span>
      <span className='m-0 p-0 elipsis' title={file.name}>
        {file.name}
      </span>
    </button>
  );
};

type Props = {
  file?: IHeavyFileStatusFrontend;
  category: CategoriesUploadedFiles;
  onClose: () => void;
  onDeleted?: (id: string) => void;
};

export const DeleteFileModal: React.FC<Props> = ({ file, category, onClose, onDeleted }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext);

  if (!user?.id || !file) {
    return null;
  }

  const onDelete = async () => {
    try {
      setLoading(true);
      await deleteFile({ fileId: file.id, category, userId: user.id });
      onDeleted?.(file.id);
      dispatch(setNotification(t('deletionModal.successDelete')));
      onClose();
    } catch (error) {
      dispatch(setNotification(t('deletionModal.errorDelete')));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DeletionModal.Main show={Boolean(file.id)} onClose={onClose} maxWidth='1080px'>
      <DeletionModal.Layout>
        <DeletionModal.Header title={t('deletionModal.title', { fileName: file.name })} />
        <DeletionModal.Content>
          <div className='flex-col gap-y-2'>
            <span>{t('deletionModal.areYouSure')}</span>
            <DownloadBTN file={file} />

            <Trans i18nKey='deletionModal.consequences'>
              <div>
                <span>Consecuencias del eliminado de archivos:</span>
                <ul className='m-0 pl-6'>
                  <li>
                    Si eliminas este archivo, se borrarán todos los datos asociados al archivo en la
                    plataforma.
                  </li>
                  <li>Los archivos eliminados se podrán seguir descargando desde la plataforma.</li>
                </ul>
              </div>
            </Trans>
          </div>
          <DeletionModal.Input label={t('deletionModal.inputLabel', { fileName: file.name })} />
        </DeletionModal.Content>
        <DeletionModal.Buttons>
          <Button
            lookAndFeel='secondary'
            onClick={onClose}
            text={t('deletionModal.cancel')}
            height='medium'
          />
          <DeletionModal.Btn onDelete={onDelete} loading={loading} />
        </DeletionModal.Buttons>
      </DeletionModal.Layout>
    </DeletionModal.Main>
  );
};
