import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSize } from '../../../types/utilsEnums/input';
import InputText from '../formComponents2/inputText/InputText';
import InputWrapper from '../formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../formComponents2/select/Select';
import SortIcon from './assets/SortIcon.svg';
import './styles.scss';

type SortingType = {
  onSort: (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => void;
  options: SelectOptionFormat[];
  label: string;
  placeholder: string;
  defaultSort?: {
    id: string;
    name: string;
    direction: 'asc' | 'desc';
  };
  size?: InputSize;
};

const Sorting = ({
  onSort,
  options,
  placeholder,
  label,
  defaultSort,
  size = InputSize.SMALL
}: SortingType) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sorting' });

  const [value, setValue] = useState<SelectOptionFormat>(options[0]);
  const [direction, setDirection] = useState<SelectOptionFormat>({
    id: 'desc',
    name: t('desc')
  });
  const onSelect = (e: SelectOptionFormat) => {
    setValue(e);
    onSort(e, direction.id as 'asc' | 'desc');
  };
  const onSelectDirection = (directionValue: SelectOptionFormat) => {
    setDirection(directionValue);
    onSort(value, directionValue.id as 'asc' | 'desc');
  };

  useEffect(() => {
    if (!defaultSort) return;
    setValue(defaultSort);
    setDirection({
      id: defaultSort.direction,
      name: t(defaultSort.direction)
    });
  }, [defaultSort?.id]);

  const directionOptions = [
    { id: 'asc', name: t('asc') },
    { id: 'desc', name: t('desc') }
  ].filter((option) => option.id !== direction.id);

  return (
    <InputWrapper icon={SortIcon} size={size}>
      <InputText value={label} style={{ fontWeight: '600', width: '80px' }} />
      <Select
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onSelect}
        sort={false}
        style={{ width: '110px' }}
      />
      <Select
        options={directionOptions}
        placeholder={t('direction')}
        value={direction}
        onChange={onSelectDirection}
        sort={false}
        style={{ width: '75px' }}
      />
    </InputWrapper>
  );
};

export default Sorting;
