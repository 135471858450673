import { useTranslation } from 'react-i18next';

const useConstants = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize.create' });

  const emptyCustomEFDetail: ICustomEFFront = {
    id: '',
    name: '',
    startDate: '',
    endDate: '',
    type: 'simple',

    // simple ef
    simpleEF: '',
    simpleUnit: {
      id: '',
      name: ''
    },

    // custom ef
    customEF1: '',
    customEF2: '',
    customEF3: '',
    customUnit: {
      id: '',
      name: ''
    },
    uncertaintyGrade: '',
    errors: []
  };

  const carouselStepsFactorType = [
    {
      id: 'simple',
      text: t('simple')
    },
    {
      id: 'advanced',
      text: t('advance')
    }
  ];

  return { emptyCustomEFDetail, carouselStepsFactorType };
};

export default useConstants;
