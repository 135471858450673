import InputText from '../../inputText/InputText';
import InputTextSelect from '../../inputTextSelect/InputTextSelect';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children'>;
type SelectType = React.ComponentProps<typeof InputTextSelect>;
type InputTextType = React.ComponentProps<typeof InputText>;
type Props = SelectType & InputWrapperType & InputTextType;

const FormTextSelect = (props: Props) => {
  return (
    <InputWrapper {...props}>
      <InputTextSelect {...props} />
    </InputWrapper>
  );
};

export default FormTextSelect;
