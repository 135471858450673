import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIso14064Report } from '../../../services/api/total_impacts';
import { UserContext } from './../../../context/userContext';
import useOnChangeValue from './../../../customHooks/useOnChangeValue';
import checkFormErrors from './../../../utils/checkFormErrors';
import Button from './../../ui/button/Button';
import FormButtonSection from './../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from './../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from './../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from './../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDoubleSelect from '../../ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';

type Props = {
  closeModal: () => void;
};

type FormDataType = {
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};

function Iso14604Report({ closeModal }: Props) {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormDataType>({
    startDate: '',
    endDate: '',
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleGeneratePaiReport = async () => {
    if (!user?.selectedOrganization) return;
    setLoadingButton(true);
    const newErrors: ErrorType[] = checkFormErrors(formData, [], []);

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      setLoadingButton(false);
      return;
    }

    // Format of dates must be python datetime format
    const startDateParsed = moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');
    const endOfYear = moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');

    const response = await getIso14064Report(
      startDateParsed,
      endOfYear,
      i18n.resolvedLanguage || 'en',
      user?.selectedOrganization
    );

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = response;
    link.setAttribute('download', 'iso14064_report.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className='organization-description-form'>
      <FormHeader
        title={t(`orgDescriptionModal.iso14064Report.completeData`)}
        description={t(`orgDescriptionModal.iso14064Report.completeDataDescription`)}
      />
      <FormWrapper>
        <FormElementFull>
          <FormCalendarDoubleSelect
            label={t('orgDescriptionModal.selectDateRange')}
            handleChangeStartDate={onChangeValue('startDate')}
            handleChangeEndDate={onChangeValue('endDate')}
            startDate={formData.startDate ? (formData.startDate as string) : ''}
            endDate={formData.endDate ? (formData.endDate as string) : ''}
            error={formData.errors.find(
              (error) =>
                error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
            )}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleGeneratePaiReport}
          text={t(`orgDescriptionModal.iso14064Report.saveAndGenerateReport`)}
          size='medium'
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
}

export default Iso14604Report;
