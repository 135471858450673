import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserContext } from '../../context/userContext';
import usePaginatedList from '../../customHooks/paginatedList';
import NotFound from '../layout/NotFound';
import LoaderTables from '../ui/loaders/loaderTables/LoaderTables';
import InvoiceCard from './InvoiceCard';

import './styles.scss';
function Invoicing() {
  const user = useContext(UserContext);

  const { t } = useTranslation();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);
  today.setDate(today.getDate() + 1);
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  const url = '/stripe_invoices';
  const size = 21;

  const [loading, setLoading] = useState(true);

  // fetchPagination
  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values: invoices
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: user?.selectedOrganization
  });

  useEffect(() => {
    fetchData();
  }, [user]);

  const selectedOrganization = user?.organizations?.find(
    (org) => org.id === user?.selectedOrganization
  );

  if (selectedOrganization?.role !== 'owner') {
    return <NotFound />;
  }
  return (
    <section className='invoicing'>
      <div className='invoicing__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('invoicing.title')}</h3>
        <h4 className='subtitle2-font on-light-text-color'>{t('invoicing.subtitle')}</h4>
      </div>
      {loading && <LoaderTables />}
      <InfiniteScroll
        dataLength={invoices ? invoices.length : 0}
        next={fetchMoreData}
        hasMore={hasMore}
        height={500}
        loader={<></>}
        endMessage={<></>}>
        <div className='invoicing__body'>
          {invoices &&
            invoices.length > 0 &&
            !loading &&
            invoices
              .sort((a, b) => (a.created_at >= b.created_at ? -1 : 1))
              .map((elem) => <InvoiceCard data={elem} key={elem.id} />)}
        </div>
      </InfiniteScroll>
    </section>
  );
}

export default Invoicing;
