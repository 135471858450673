import React, { Dispatch, SetStateAction } from 'react';
import { FormConfigureDataType } from '../../../../../types/entities/groupFunds';
import useGetData from './hooks/useGetData';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../../constants/routes';
import FormElement from '../../../../ui/formComponents/formElement/FormElement';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormCalendar from '../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import Label from '../../../../ui/label/Label';
import formatNumber from '../../../../../utils/formatNumber';
import CustomSkeletonLoader from '../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';

type Props = {
  formData: FormConfigureDataType;
  setFormData: Dispatch<SetStateAction<FormConfigureDataType>>;
  organizationId: string;
  fundId?: string;
  newLink?: boolean;
};
const FormInvestment = ({
  formData,
  setFormData,
  organizationId,
  fundId,
  newLink = false
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { funds, latestInvestment, loading } = useGetData({ id: organizationId, fundId, newLink });

  const { onChangeValue } = useOnChangeValue({ setFormData });
  if (loading)
    return (
      <div style={{ marginBottom: '1rem' }}>
        <CustomSkeletonLoader count={4} />
      </div>
    );
  return (
    <FormWrapper>
      <FormElementFull>
        <FormSelect
          icon='/images/icons/holding.svg'
          label={
            <>
              {t('configureGroupFunds.fundLabel')}
              <span
                onClick={() => navigate(ROUTES.MEASURE_FUNDS)}
                className='highlight-text-color clickable'>
                {t('configureGroupFunds.here')}
              </span>
            </>
          }
          options={funds.map((elem) => ({
            id: elem.id,
            name: elem.fund_name
          }))}
          value={formData.fund}
          onChange={onChangeValue('fund')}
          error={formData.errors.find((error) => error.error === 'fund')}
        />
      </FormElementFull>
      <FormElement>
        <FormNumber
          icon={'/images/icons/database.svg'}
          label={t('configureGroupFunds.investmentLabel')}
          placeholder={t('configureGroupFunds.investmentPlaceholder')}
          value={formData.investment}
          onChange={onChangeValue('investment')}
          tooltip={t('configureGroupFunds.investmentTooltip')}
          error={formData.errors.find((error) => error.error === 'investment')}
        />
        {/* <SlideToggle
          label={t('configureGroupFunds.isDebtFinancing')}
          checked={formData.isDebtFinancing}
          setChecked={onChangeValue('isDebtFinancing')}
          style={{ marginTop: '0.5rem' }}
        /> */}
        {latestInvestment !== undefined && (
          <Label lookAndFeel='secondary' style={{ marginTop: '0.5rem' }}>
            {t('configureGroupFunds.latestAdded', {
              value: formatNumber(latestInvestment?.lastInvestmentValue)
            })}
          </Label>
        )}
      </FormElement>
      <FormElement>
        <FormNumber
          icon={'/images/icons/database.svg'}
          label={t('configureGroupFunds.companyValueLabel')}
          placeholder={t('configureGroupFunds.companyValueLabel')}
          value={formData.companyValue}
          onChange={onChangeValue('companyValue')}
          error={formData.errors.find((error) => error.error === 'companyValue')}
          tooltip={t('configureGroupFunds.companyValueTooltip')}
        />
        {latestInvestment !== undefined && (
          <Label lookAndFeel='secondary' style={{ marginTop: '0.5rem' }}>
            {t('configureGroupFunds.latestAdded', {
              value: formatNumber(latestInvestment?.lastEnterpriseValue)
            })}
          </Label>
        )}
      </FormElement>
      <FormCalendar
        label={t('configureGroupFunds.investmentDateLabel')}
        handleChangeDate={onChangeValue('investmentDate')}
        dateValue={formData.investmentDate}
        error={formData.errors.find((error) => error.error === 'investmentDate')}
        tooltip={t('configureGroupFunds.investmentDateTooltip')}
      />
      <FormText
        label={t('configureGroupFunds.tagLabel')}
        icon={'/images/icons/message.svg'}
        placeholder={t('configureGroupFunds.tagPlaceholder')}
        value={formData.tag}
        onChange={onChangeValue('tag')}
      />
    </FormWrapper>
  );
};

export default FormInvestment;
