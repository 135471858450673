import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('companies.company'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('companies.country'),
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: t('companies.type'),
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: t('companies.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
};

export default useColumns;
