import { ReactNode } from 'react';

export function replaceStrongTags(text?: string): ReactNode {
  if (!text) return;
  const parts = text.split(/<strong>|<\/strong>/g);
  const jsxElements: ReactNode[] = parts.map((part, index) => {
    if (index % 2 === 0) {
      return part; // Text content
    } else {
      return <strong key={index}>{part}</strong>; // <strong> element
    }
  });

  return <>{jsxElements}</>;
}
