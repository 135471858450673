import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/userContext';
import numberToDecimal from '../../../utils/numberToDecimal';
import Button from '../button/Button';
import IconBackground from '../iconBackground/IconBackground';
import './styles.scss';

type Props = {
  card: CardTooltip;
  data: CardData;
};
const CardContent = ({ card, data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const handleOnClickCard = () => {
    if (!card.disabled && !card.freeTrial) {
      if (card.onClickCallback && user?.selectedOrganization && !user.email.includes('dcycle')) {
        card.onClickCallback();
      }
      navigate(data.route);
    }
  };

  const renderTotal = (total: number | string | undefined, unit: string | undefined) => {
    if (card.freeTrial) {
      return (
        <Button
          lookAndFeel='blocked'
          text={t('blockedButton.improvePlan')}
          onClick={() => {
            console.log('');
          }}
          size='tiny'
        />
      );
    }
    return (
      <div className='total-info'>
        {typeof total === 'number' ? (
          <span className='highlight-text-color small-card-font'>
            {numberToDecimal(total / 1000)}
          </span>
        ) : (
          <span
            className={`${
              card.disabled ? 'disabled-text-color' : 'highlight-text-color'
            } small-card-font`}>
            {total}
          </span>
        )}
        {unit && <p className='body1-font'>t CO₂ eq.</p>}
      </div>
    );
  };

  return (
    <div className='content' key={data.title} onClick={handleOnClickCard}>
      <IconBackground icon={data.icon} alt={data.title} />
      <h1 className='headline4-font'>{data.title}</h1>
      <p className='subtitle3-font'>{data.description}</p>
      {renderTotal(data.total, data.unit)}
    </div>
  );
};

export default CardContent;
