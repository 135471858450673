import React, { ReactNode } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import './styles.scss';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
type Props = {
  children: ReactNode;
};
const CompaniesWrapper = ({ children }: Props) => {
  const { t } = useTranslation();
  const selectedOrganization = useSelectedOrganization();

  const [searchParams, setSearchParams] = useSearchParams({
    type: 'childs'
  });

  const STEPS = [
    {
      id: 'childs',
      text: t('companies.childs')
    },
    {
      id: 'parents',
      text: t('companies.parents')
    }
  ];

  const { steps } = useStepCarousel({
    stepsText: STEPS,
    selected: searchParams.get('type') ?? 'childs'
  });

  return (
    <section className='companies'>
      <div className='companies__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('companies.title')}</h3>
        <Breadcrumb />
      </div>
      {!selectedOrganization?.is_group_fund && (
        <StepCarouselList
          steps={steps}
          handleSelect={(id: string) => {
            setSearchParams({ type: id });
          }}
        />
      )}
      {children}
    </section>
  );
};

export default CompaniesWrapper;
