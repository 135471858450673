import { useTranslation } from 'react-i18next';
import { createCSVToSendByEmail } from '../../../../../../utils/createCSVToSendByEmail';
import Button from '../../../../../ui/button/Button';
import './styles.scss';

type Props = {
  handleGoToManual: () => void;
  type: string;
};

const ButtonsSection = ({ handleGoToManual, type }: Props) => {
  const { t } = useTranslation();

  const handleCreateCSVToSendByEmail = () => {
    createCSVToSendByEmail(type);
  };
  return (
    <div className='button-section'>
      <Button
        lookAndFeel={'primary'}
        onClick={handleCreateCSVToSendByEmail}
        text={t('sendEmailForm.downloadCsv')}
        size={'small'}
      />
      <Button
        lookAndFeel={'secondary'}
        onClick={handleGoToManual}
        text={t('sendEmailForm.goToAddMaualy')}
        size={'small'}
      />
    </div>
  );
};

export default ButtonsSection;
