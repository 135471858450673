import { useEffect, useState } from 'react';
import { IconName } from '../../../types/utils/iconName';
import IconBase from '../IconBase/IconBase';

import { IconSize } from './utils/constants';

interface Props extends Omit<React.ComponentProps<typeof IconBase>, 'icon'> {
  icon: IconName;
}

const Icon = ({ icon, ...rest }: Props) => {
  const SvgIcon = useDynamicSVGImport(icon);
  if (!SvgIcon) return null;
  return <IconBase icon={<SvgIcon />} {...rest} />;
};

function useDynamicSVGImport(icon: string) {
  const [iconElement, setIconElement] = useState<React.FC<React.SVGProps<SVGSVGElement>> | null>(
    null
  );

  const importIcon = async () => {
    try {
      setIconElement(
        (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../../assets/icons/${icon}.svg`))
          .default
      );
    } catch (error) {
      setIconElement(null);
    }
  };

  useEffect(() => {
    importIcon();
  }, [icon]);

  return iconElement;
}

export default Icon;

export type { IconSize };
