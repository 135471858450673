import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { UserContext } from '../../../../context/userContext';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { patchEmployees } from '../../../../services/api/employees';
import {
  BasicEmployee,
  EmployeeTable,
  FormEmployeeData
} from '../../../../types/entities/employee';

import Button from '../../../ui/button/Button';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';

type Props = {
  editEmployee: (id: string, employee: BasicEmployee) => void;
  employeeToEdit: EmployeeTable;
};

function EditEmployee({ editEmployee, employeeToEdit }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useContext(UserContext);

  const [loadingButton, setLoadingButton] = useState(false);

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);

  const [formData, setFormData] = useState<FormEmployeeData>({
    email: employeeToEdit.email ?? '',
    name: employeeToEdit?.name,
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleEditEmployee = async () => {
    const newErrors: ErrorType[] = handleErrors();
    if (!user?.selectedOrganization || newErrors.length !== 0 || !employeeToEdit.employeeId) return;
    const body = { ...formData };
    if (!body.email) {
      body.email = undefined;
    }
    const responseEmployee = await patchEmployees(
      employeeToEdit.employeeId || '',
      body,
      user.selectedOrganization
    );

    if (responseEmployee?.response?.status >= 400) return;

    editEmployee(employeeToEdit.employeeId || '', body);

    // editElement({ id: employeeToEdit.id, body });
    dispatch(setNotification(t('notification.editEmployee')));
    setLoadingButton(true);
  };

  const handleName = (value: ChangeEvent<HTMLInputElement> | undefined) => {
    onChangeValue('name')(value);
    formData.email
      ? formData.errors.filter((error) => error.error !== 'name')
      : formData.errors.filter((error) => error.error !== 'email');
  };

  const handleErrors = () => {
    const newErrors: ErrorType[] = formData.errors;

    if (!formData.email && !formData.name) {
      newErrors.push({
        error: 'email',
        description: t('employees.errorEmailAndName')
      });
    }

    if (formData?.email && !formData.email.includes('@')) {
      newErrors.push({
        error: 'email',
        description: t('employees.notValidEmail')
      });
    }

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
    }
    return newErrors;
  };

  return (
    <div className='edit-employee'>
      <FormHeader
        title={t('employees.editEmployee')}
        description={t('employees.editEmployeeDescription')}
      />
      <FormWrapper>
        <FormText
          iconV2='user'
          label={t('employees.email')}
          error={formData.errors.find((elem) => elem.error === 'email')}
          placeholder={t('employees.writeEmployeeEmail')}
          value={formData.email}
          onChange={onChangeValue('email')}
        />

        <FormText
          iconV2='user'
          label={t('employees.name')}
          placeholder={t('employees.writeEmployeeName')}
          onChange={handleName}
          value={formData.name}
          error={formData.errors.find((elem) => elem.error === 'name')}
        />
      </FormWrapper>

      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('employees.save')}
          onClick={handleEditEmployee}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default EditEmployee;
