import { t } from 'i18next';
import { formatRoundNumber } from '../../../utils/formatNumber';
import Dot from '../dot/Dot';
import Tag from '../tag/Tag';
import TooltipWrapper from '../tooltip/TooltipWrapper';

type Props = {
  scope: number;
  value: number; // in tons of CO2 always
  totalCo2?: number; // in tons of CO2 always
  shortText?: boolean;
  noIcons?: boolean;
  textLookAndFeel?: string;
};
const ScopeTag = ({
  scope,
  shortText = false,
  value,
  totalCo2,
  noIcons = false,
  textLookAndFeel = 'primary'
}: Props) => {
  const renderScopeName = () => {
    type ScopeText = {
      [key: number]: string;
    };
    const scopesText: ScopeText = {
      1: t('dashboard.scope1'),
      2: t('dashboard.scope2'),
      3: t('dashboard.scope3')
    };
    const scopesTextShort: ScopeText = {
      1: t('dashboard.scope1Short'),
      2: t('dashboard.scope2Short'),
      3: t('dashboard.scope3Short')
    };
    return shortText ? scopesTextShort[scope] : scopesText[scope];
  };

  const renderScopeIcon = () => {
    const scopesIcon: { [key: number]: string } = {
      1: '/images/icons/fire50.svg',
      2: '/images/icons/thunder50.svg',
      3: '/images/icons/cloud50.svg'
    };
    return scopesIcon[scope];
  };

  let percentage = 0;
  if (totalCo2 !== undefined && value && totalCo2) {
    percentage = (value / totalCo2) * 100;
  }

  const highlightTextColor =
    textLookAndFeel === 'primary' ? 'highlight-text-color' : 'on-light-text-color';

  return (
    <TooltipWrapper text={t(`scopes.scope${scope}`)} position='top'>
      <Tag
        tags={[
          {
            text: <Dot type={`scope${scope}` as 'scope1' | 'scope2' | 'scope3'} />
          },
          {
            text: <span className='on-light-text-color tag-font'>{renderScopeName()}</span>,
            icon1: noIcons ? undefined : renderScopeIcon()
          },
          {
            text: (
              <span className={`${highlightTextColor} tag-bigger-font`}>
                {formatRoundNumber(value)}
              </span>
            )
          },
          {
            text: <span className='on-light-text-color tag-smaller-font'>t CO₂ eq.</span>
          },
          {
            text:
              totalCo2 !== undefined ? (
                <span className={`${highlightTextColor} tag-bigger-font`}>
                  {formatRoundNumber(percentage)}%
                </span>
              ) : (
                <></>
              )
          }
        ]}
      />
    </TooltipWrapper>
  );
};

export default ScopeTag;
