import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../utils/formatDate';
import Checkbox from '../checkbox/Checkbox';
import { ErrorDate } from '../inputCalendar/InputCalendar';
import InputCalendarDouble from '../inputCalendarDouble/InputCalendarDouble';
import InputWrapperPlain from '../inputUtils/inputWrapperPlain/InputWrapperPlain';
import './styles.scss';

type Props = {
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
  startDate: string;
  endDate: string;
};

const InputDateFilter = ({ startDate, endDate, setStartDate, setEndDate }: Props) => {
  const { t } = useTranslation();
  const [preDefinedDate, setPreDefinedDate] = useState<any>({
    id: 'default',
    name: t('dashboard.all')
  });
  const [openCustom, setOpenCustom] = useState(false);

  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const options = [
    { id: 'default', name: t('dashboard.all') },
    { id: 'this_year', name: t('calendar.thisYear') },
    { id: 'last_year', name: t('calendar.lastYear') },
    { id: 'last_3_months', name: t('calendar.last3Months') },
    { id: 'custom', name: t('calendar.custom') }
  ];
  const handleOnChangeStartDate = (value: string) => {
    setStartDateError('');
    setStartDate(value);
  };

  const handleOnChangeEndDate = (value: string) => {
    setEndDateError('');
    setEndDate(value);
  };

  const onChangePreDefinedDate = (value: SelectOptionFormat) => {
    setPreDefinedDate(value);
    setOpenCustom(false);
    let startDateParsed: Date | string = new Date(moment(new Date()).format('YYYY-MM-DD'));
    let endDateParsed: Date | string = new Date(moment(new Date()).format('YYYY-MM-DD'));
    if (value.id === 'last_month') {
      setPreDefinedDate({ id: 'last_month', name: t('calendar.lastMonth') });
      startDateParsed.setMonth(startDateParsed.getMonth() - 1);
    } else if (value.id === 'last_3_months') {
      setPreDefinedDate({ id: 'last_3_months', name: t('calendar.last3Months') });
      startDateParsed.setMonth(startDateParsed.getMonth() - 3);
    } else if (value.id === 'last_6_months') {
      setPreDefinedDate({ id: 'last_6_months', name: t('calendar.last6Months') });
      startDateParsed.setMonth(startDateParsed.getMonth() - 6);
    } else if (value.id === 'last_9_months') {
      setPreDefinedDate({ id: 'last_9_months', name: t('calendar.last9Months') });
      startDateParsed.setMonth(startDateParsed.getMonth() - 9);
    } else if (value.id === 'last_year') {
      setPreDefinedDate({ id: 'last_year', name: t('calendar.lastYear') });
      startDateParsed.setFullYear(startDateParsed.getFullYear() - 1);
      endDateParsed.setFullYear(endDateParsed.getFullYear() - 1);
      startDateParsed.setMonth(0, 1);
      endDateParsed.setMonth(11, 31);
    } else if (value.id === 'this_year') {
      setPreDefinedDate({ id: 'this_year', name: t('calendar.thisYear') });
      startDateParsed.setMonth(0, 1);
      endDateParsed.setMonth(11, 31);
    } else if (value.id === 'custom') {
      setOpenCustom(true);
      setPreDefinedDate({ id: 'custom', name: t('calendar.custom') });
    } else {
      setPreDefinedDate({ id: 'default', name: t('dashboard.all') });
      startDateParsed = '';
      endDateParsed = '';
    }
    const formatedDate =
      typeof startDateParsed === 'object' ? formatDate(startDateParsed) : startDateParsed;
    const formatedEndDate =
      typeof endDateParsed === 'object' ? formatDate(endDateParsed) : endDateParsed;
    handleOnChangeStartDate(formatedDate);
    handleOnChangeEndDate(formatedEndDate);
  };

  if (!preDefinedDate) return null;

  return (
    <div className='input-date-filter'>
      {options.map((item: SelectOptionFormat) => (
        <Checkbox
          text={item.name}
          key={item.id}
          onChange={() => onChangePreDefinedDate(item)}
          selected={preDefinedDate.id === item.id}
          type='circle'
        />
      ))}
      {openCustom && (
        <InputWrapperPlain
          error={
            startDateError || endDateError
              ? {
                  error: 'date',
                  description: startDateError || endDateError
                }
              : undefined
          }>
          <InputCalendarDouble
            startDate={startDate}
            endDate={endDate}
            handleChangeEndDate={handleOnChangeEndDate}
            handleChangeStartDate={handleOnChangeStartDate}
            handleStartDateError={(value: ErrorDate) => setStartDateError(value)}
            handleEndDateError={(value: ErrorDate) => setEndDateError(value)}
            position='top'
          />
        </InputWrapperPlain>
      )}
    </div>
  );
};

export default InputDateFilter;
