import { useContext } from 'react';
import { UserContext } from '../context/userContext';

const useSelectedOrganization = () => {
  const user = useContext(UserContext);
  const found = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  return found;
};

export default useSelectedOrganization;
