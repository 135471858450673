import Button from '../../../../ui/button/Button';
import Modal from '../../../../ui/modal/Modal';
import { ImportEmissionsModalPages } from './ImportEmissionsModalPages';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const ImportEmissionsModal: React.FC<Props> = ({ show, onClose }) => {
  return (
    <Modal.WithPortal show={show} onClose={onClose}>
      <Modal.Header
        title='Añade o edita impactos totales manualmente'
        description='Introduce manualmente los impactos totales por categorías si ya los conoces.'
      />
      <Modal.Content>
        <ImportEmissionsModalPages.SelectScope />
      </Modal.Content>
      <Modal.Buttons>
        <Button lookAndFeel='secondary' text='Cancelar' size='small' onClick={onClose} />
        <Button lookAndFeel='primary' text='Guardar y cerrar' size='small' onClick={onClose} />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
