import apiFetch from '../../utils/apiFetch';

export const downloadByCategory = async (category: string, output_format: string) => {
  try {
    const response = await apiFetch('GET', `/download/${category}`, null, null, {
      output_format
    });
    return response.data;
  } catch (error) {
    return null;
  }
};
