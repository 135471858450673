import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../../../../context/userContext';
import { getRdCodesByLowId } from '../../../../../../../../../services/api/waste';

type Props = {
  lowId: string;
};

const useGetRdCodes = ({ lowId }: Props) => {
  const user = useContext(UserContext);
  const { t } = useTranslation();
  const [rdCodes, setRdCodes] = useState<any[]>([]);
  const [rdCodesNuvo, setRdCodesNuvo] = useState<any[]>([]);

  useEffect(() => {
    const getData = async () => {
      if (!user?.selectedOrganization || !lowId) return;
      const response = await getRdCodesByLowId({
        organization_id: user?.selectedOrganization,
        lowId
      });
      if (response.status >= 400) return;
      setRdCodesNuvo(
        response.map((elem: { rd_id: string; rd_name: string; rd_code: string }) => ({
          label: elem.rd_name,
          value: elem.rd_id,
          type: 'string'
        }))
      );
      setRdCodes(
        response.map((elem: { rd_id: string; rd_name: string; rd_code: string }) => ({
          id: elem.rd_id,
          name: `${elem.rd_code} ${t(`rd_codes.${elem.rd_name}`)}`
        }))
      );
    };

    getData();
  }, [lowId]);

  return {
    rdCodes,
    rdCodesNuvo
  };
};

export default useGetRdCodes;
