import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EsgFrameworkCompleteness } from '../../../../../types/entities/esgMetrics';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import HorizontalCard from 'components/ui/cardPanel/HorizontalCard';
import FrameworkProgressBar from './components/FrameworkProgressBar';
import Button from 'components/ui/button/Button';
import ConfigureFrameworksModal from '../metrics/components/configureFrameworksModal/ConfigureFrameworksModal';
import Modal from 'components/ui/modal/Modal';
import Icon from 'components/ui/icon/Icon';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import { formatDate } from 'utils/formatDate';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import logoLogicReturner from './components/FrameworkDashboard/utils/logoLogicReturner';
import checkMaxPercentage from 'utils/limitPercentageCalculator';

type Props = {
  data: EsgFrameworkCompleteness[];
  loading: boolean;
};
const FrameworkProgress = ({ data, loading }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  const navigate = useNavigate();
  const [extendedData, setExtendedData] = useState<EsgFrameworkCompleteness[]>([]);

  const [showConfigureFrameworksModal, setShowConfigureFrameworksModal] = useState(false);
  useEffect(() => {
    if (!loading && data) {
      const ghgProtocolEntry = data.find((item) => item.framework === 'GHG_PROTOCOL');
      const csrdEntry = data.find((item) => item.framework === 'CSRD');

      const extendedArray = [
        ...data.map((entry) => {
          if (entry.framework === 'GHG_PROTOCOL') {
            return {
              ...entry,
              blocked: entry.blocked || false,
              date: entry.date || formatDate(new Date(2025, 0, 1)), // January 1, 2025
              logo: logoLogicReturner(entry.framework, entry.blocked || false)
            };
          } else if (entry.framework === 'CSRD') {
            return {
              ...entry,
              completed: checkMaxPercentage(entry.completed, entry.total, 15),
              blocked: entry.blocked || false,
              date: entry.date || formatDate(new Date(2024, 11, 31)), // December 31, 2024
              logo: logoLogicReturner(entry.framework, entry.blocked || false)
            };
          }
          return entry;
        }),
        {
          framework: 'MITECO',
          total: ghgProtocolEntry ? ghgProtocolEntry.total : 100,
          completed: ghgProtocolEntry ? ghgProtocolEntry.completed : 100,
          blocked: false,
          date: formatDate(new Date(2025, 6, 31)),
          logo: logoLogicReturner('MITECO', false)
        },
        {
          framework: 'ISO_14064',
          total: ghgProtocolEntry ? ghgProtocolEntry.total : 100,
          completed: ghgProtocolEntry ? ghgProtocolEntry.completed : 100,
          blocked: false,
          date: formatDate(new Date(2024, 6, 31)),
          logo: logoLogicReturner('ISO_14064', false)
        },
        {
          framework: 'SBTi',
          total: ghgProtocolEntry ? ghgProtocolEntry.total : 100,
          completed: checkMaxPercentage(
            ghgProtocolEntry ? ghgProtocolEntry.completed : 100,
            ghgProtocolEntry ? ghgProtocolEntry.total : 100,
            81
          ),
          blocked: false,
          date: formatDate(new Date(2025, 0, 1)),
          logo: logoLogicReturner('SBTi', false)
        },
        {
          framework: 'ISO_14001',
          total: 100,
          completed: 45,
          blocked: true,
          date: formatDate(new Date(2025, 0, 1)),
          logo: logoLogicReturner('ISO_14001', true)
        },
        {
          framework: 'CDP',
          total: 100,
          completed: 40,
          blocked: true,
          date: formatDate(new Date(2025, 0, 1)),
          logo: logoLogicReturner('CDP', true)
        },
        {
          framework: 'ECOVADIS',
          total: 100,
          completed: 30,
          blocked: true,
          date: formatDate(new Date(2025, 0, 1)),
          logo: logoLogicReturner('ECOVADIS', true)
        }
      ];

      // Reorder extendedArray to place GHG_PROTOCOL and CSRD as the first and second elements if they exist
      const sortedArray = extendedArray.sort((a, b) => {
        if (a.framework === 'GHG_PROTOCOL') return -1;
        if (b.framework === 'GHG_PROTOCOL') return 1;
        if (a.framework === 'CSRD') return -1;
        if (b.framework === 'CSRD') return 1;
        return 0;
      });

      setExtendedData(sortedArray);
    }
  }, [loading, data]);

  if (loading) {
    return <LoaderCard />;
  }
  return (
    <CardPanel>
      <CardPanel.Header
        title={t('title')}
        style={{
          alignItems: 'center'
        }}>
        <Button
          style={{
            whiteSpace: 'nowrap',
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
          }}
          lookAndFeel='link-dark'
          size='small'
          iconNode={<Icon icon='settings' color='gray-dark' />}
          onClick={() => setShowConfigureFrameworksModal(true)}>
          {t('configureFrameworks')}
        </Button>
      </CardPanel.Header>
      <CardPanel.Body>
        {extendedData.map((elem) => (
          <HorizontalCard
            key={'elem.id'}
            className={`${elem.blocked ? '' : 'card-button'}`}
            // if the card is blocked, do nothing, otherwise navigate to the framework details page
            onClick={() => {
              if (!elem.blocked) {
                if (elem.framework === 'GHG_PROTOCOL') {
                  navigate(ROUTES.IMPROVE_DASHBOARD_TOTAL + `/${elem.framework}`);
                }
              }
            }}>
            <FrameworkProgressBar
              key={elem.framework}
              title={
                t(elem.framework, {
                  keyPrefix: 'controlPanel.frameworkProgress.frameworks'
                }) || elem.framework.replaceAll('_', ' ')
              }
              total={elem.total}
              completed={elem.completed}
              date={elem.date}
              blocked={elem.blocked}
              logo={elem.logo}
              submitted={elem?.submitted || false}
            />
          </HorizontalCard>
        ))}
      </CardPanel.Body>
      <Modal.WithPortal
        show={showConfigureFrameworksModal}
        onClose={() => setShowConfigureFrameworksModal(false)}
        width='756px'
        maxWidth='756px'>
        <ConfigureFrameworksModal handleClose={() => setShowConfigureFrameworksModal(false)} />
      </Modal.WithPortal>
    </CardPanel>
  );
};

export default FrameworkProgress;
