import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import { createVehicle } from '../../../../services/api/vehicle';
import { FormDataVehicles, Vehicle, VehicleFuel } from '../../../../types/entities/vehicle';
import FormVehicle from '../formVehicle/FormVehicle';

type Props = {
  addVehicle: (value: Vehicle) => void;
};
const CreateVehicle = ({ addVehicle }: Props) => {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const [error, setError] = useState<ErrorType>();

  const handleAddVehicle = async (
    formData: FormDataVehicles,
    knownVehicles: any[],
    unknownVehicles: any[],
    fuels: VehicleFuel[]
  ) => {
    let unknownVehicleId = null;
    if (formData.noBrand || formData.noModel) {
      const foundVehicle = unknownVehicles.find(
        (vehicle) => vehicle.type === formData.unknownType.id
      );
      unknownVehicleId = foundVehicle ? foundVehicle.id : null;
    }
    let nameValue = formData.name;
    if (!nameValue && !formData.noBrand) {
      nameValue = `${formData.brand.name} ${formData.model.name}`;
    } else if (!nameValue && formData.noBrand) {
      nameValue = formData.unknownType.id;
    }

    const vehicle = {
      known_vehicle_id:
        !formData.noBrand && !formData.noModel
          ? knownVehicles.find(
              (vehicle) =>
                vehicle.brand === formData.brand.id && vehicle.model === formData.model.id
            ).id
          : null,
      unknown_vehicle_id: formData.noBrand || formData.noModel ? unknownVehicleId : null,
      vehicle_fuel_id: formData.fuel.id || null,
      type: formData.vehicleType.id ? formData.vehicleType.id : '',
      name: nameValue,
      ownership: formData.type,
      license_plate: formData.carRegistration,
      is_known: formData.noBrand || formData.noModel ? false : true,
      organization_id: user?.selectedOrganization ?? '',
      country: formData.country.id
    };

    const newVehicle = await createVehicle(vehicle, user?.selectedOrganization ?? '');
    if (typeof newVehicle === 'string' && newVehicle.includes('Vehicle with license plate')) {
      setError({
        error: 'carRegistration',
        description: t('error.licensePlateAlreadyExists')
      });
      return;
    }

    if (addVehicle && newVehicle) {
      const foundFuel = fuels?.find((fuel) => fuel.id === newVehicle.vehicle_fuel_id);
      if (foundFuel) {
        newVehicle.vehicle_fuel_id = foundFuel?.id;
        newVehicle.vehicle_fuel = foundFuel.fuel;
      }
      addVehicle({
        ...newVehicle,
        known_vehicle_brand: formData.brand.id,
        known_vehicle_model: formData.model.id,
        unknown_vehicle_type: formData.unknownType.id,
        fuel: foundFuel?.fuel
      });
    }
  };
  return (
    <FormVehicle
      onSubmit={handleAddVehicle}
      title={t('vehicles.createNewVehicle')}
      description={t('vehicles.createNewVehicleDescription')}
      errorRequest={error}
    />
  );
};

export default CreateVehicle;
