import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import './styles.scss';
export const EsgMetricsInfography = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SectionHeader title={t('measureMain.esgMetrics')} breadcrumb={<Breadcrumb /> ?? null} />
      <div className='infography-container'>
        <img src='/images/CSRD_Dcycle_datapoints.png' className='infography-esg-metrics' />
      </div>
    </div>
  );
};
