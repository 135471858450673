import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';
import './styles.scss';
import { ROUTES } from '../../../constants/routes';

type DashboardLandingType = {
  title?: string;
  subtitle?: string;
  link?: string;
  textButton?: string;
};

const NoDataLanding = ({ title, subtitle, link, textButton }: DashboardLandingType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='dashboard-no-data'>
      <div className='card dashboard-card landing-card'>
        <div className='dashboard-card__header landing-image'>
          <img src='/images/woman.svg' alt='delivery' />
        </div>
        <div className='dashboard-card__body'>
          <span className='headline1-font on-light-text-color'>
            {title || t('noData.default.title')}
          </span>
          <span className='subtitle1-font on-light-text-color'>
            {subtitle || t('noData.default.subTitle')}
          </span>
          {link && (
            <Button
              lookAndFeel='primary'
              onClick={() => navigate(`${link}`)}
              text={textButton || t('errorPage.goBack')}
              size='medium'
              style={{ maxWidth: 200 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NoDataLanding;
