import { createContext, useContext, useEffect, useState } from 'react';
import Button from '../../../../ui/button/Button';
import { useCreateLCAReferenceOutput } from '../../hooks/useCreateLCAReferenceOutput';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import InputWrapper from '../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from '../../../../ui/formComponents2/inputNumber/InputNumber';
import Select from '../../../../ui/formComponents2/select/Select';
import { formSchema } from './formSchema';
import { adaptZodErrors } from '../../../../../adapters/zodErrors';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { useUnits } from '../../../../../customHooks/api/useUnits';
import { LCA_DECIMALS } from '../../../constants';
import { useModalContext } from '../ModalHandler';

type ConfigureReferenceOutputModalContextType = {
  node_id: string | null;
  setNodeId: React.Dispatch<React.SetStateAction<string | null>>;
};

const ConfigureReferenceOutputModalContext =
  createContext<ConfigureReferenceOutputModalContextType | null>(null);

const Provider: React.FC = ({ children }) => {
  const [node_id, setNodeId] = useState<string | null>(null);

  return (
    <ConfigureReferenceOutputModalContext.Provider value={{ node_id, setNodeId }}>
      {children}
    </ConfigureReferenceOutputModalContext.Provider>
  );
};

export const useConfigureReferenceOutputModalContext = () => {
  const context = useContext(ConfigureReferenceOutputModalContext);

  if (!context) {
    throw new Error(
      'useConfigureReferenceOutputModalContext must be used within a ConfigureReferenceOutputModalContextProvider'
    );
  }

  return context;
};

type Props = {
  acv_id: string;
};

const INITIAL_FORM = {
  name: '',
  quantity: '',
  unit: {
    id: '',
    name: ''
  }
};

type FormType = typeof INITIAL_FORM;

export const ConfigureReferenceOutputModal = ({ acv_id }: Props) => {
  const { setModalData, modalData } = useModalContext();

  const { data: units } = useUnits();

  const { t } = useLCATranslation();

  const [form, setForm] = useState<FormType>(INITIAL_FORM);
  const [errors, setErrors] = useState<ErrorContainer<typeof form> | null>(null);

  const { createLCAReferenceOutput } = useCreateLCAReferenceOutput();

  const onSubmit = () => {
    if (!modalData?.target || modalData.type !== 'reference_output') return;

    const validationResult = formSchema.safeParse(form);

    if (!validationResult.success) {
      setErrors(adaptZodErrors(validationResult.error, t));

      return;
    }

    createLCAReferenceOutput({
      acv_id,
      node_id: modalData.target,
      name: form.name,
      quantity: Number(form.quantity),
      unit_id: form.unit.id
    });

    setModalData(undefined);
  };

  useEffect(() => {
    setForm(INITIAL_FORM);
    setErrors(null);
  }, [modalData?.target]);

  return (
    <div className='flex-col gap-y-4'>
      <div className='flex-col gap-y-2'>
        <p className='m-0 font-body-b1-b text-neutral-gray-cold-20'>
          {t('lcaDetail.processFlow.configReference.title')}
        </p>
        <span className='m-0 font-body-b2-r text-neutral-gray-warm-20'>
          {t('lcaDetail.processFlow.configReference.description')}
        </span>
      </div>
      <FormText
        label={t('lcaDetail.processFlow.configReference.nameLabel')}
        iconV2='shirt'
        placeholder={t('lcaDetail.processFlow.configReference.namePlaceholder')}
        value={form.name}
        onChange={(event) => setForm((prev) => ({ ...prev, name: event.target.value }))}
        error={errors?.name}
      />

      <InputWrapper
        iconV2='input_numeric'
        label={t('lcaDetail.processFlow.configReference.quantityLabel')}
        error={errors?.quantity || errors?.unit}>
        <InputNumber
          value={form.quantity}
          onChange={(quantity) => setForm((prev) => ({ ...prev, quantity }))}
          placeholder={t('lcaDetail.processFlow.configReference.quantityPlaceholder')}
          decimals={LCA_DECIMALS}
        />
        <Select
          value={form.unit}
          onChange={(unit) => setForm((prev) => ({ ...prev, unit }))}
          options={units?.map(({ id, name }) => ({ id, name })) ?? []}
        />
      </InputWrapper>
      <Button
        lookAndFeel='primary'
        size='small'
        onClick={onSubmit}
        text={t('lcaDetail.processFlow.configReference.save')}
      />
    </div>
  );
};

ConfigureReferenceOutputModal.Root = Provider;
