import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';

import { EventEmployee, PreEventFormData } from '../../../../../../types/entities/events';
import InputEmployee from './InputEmployee';

type Props = {
  setFormData: Dispatch<SetStateAction<PreEventFormData>>;
  formData: EventEmployee[];
};
const Employees = ({ setFormData, formData }: Props) => {
  const { t } = useTranslation();
  const options = [
    { id: 'car', name: t('employees.car') },
    { id: 'metro', name: t('employees.metro') },
    { id: 'train', name: t('employees.train') },
    // { id: 'trolleybus', name: t('employees.trolleybus') },
    { id: 'bus', name: t('employees.bus') },
    { id: 'bicycle', name: t('employees.bicycle') },
    // { id: 'motorbike', name: t('employees.motorbike') },
    { id: 'aircraft', name: t('businessTravels.aircraft') }
  ];

  const onChangeValueList = (value: EventEmployee) => {
    const newList = [...formData];
    // Check if the transport is already in the employees list
    const foundIndex = newList.findIndex((elem) => elem.transport_type === value.transport_type);

    if (foundIndex > -1) {
      newList[foundIndex].distance = value.distance;
      newList[foundIndex].quantity = value.quantity;
      setFormData((prev) => ({
        ...prev,
        employees: newList
      }));
      return;
    }
    newList.push(value);
    setFormData((prev) => ({ ...prev, employees: newList }));
  };

  const labels = [t('eventData.numberOfPeople'), t('eventData.personKm'), t('eventData.transport')];
  return (
    <div className='pre-event-employees'>
      <FormWrapper>
        {options.map((option, index) => {
          const foundFormData = formData.find((elem) => elem.transport_type === option.id);
          return (
            <FormElementFull key={option.id}>
              <InputEmployee
                transport={option}
                labels={index === 0 ? labels : undefined}
                onChangeValue={onChangeValueList}
                formData={
                  foundFormData ?? {
                    quantity: 0,
                    distance: 0,
                    transport_type: option.id
                  }
                }
              />
            </FormElementFull>
          );
        })}
      </FormWrapper>
    </div>
  );
};

export default Employees;
