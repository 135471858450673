import { createContext, Dispatch, SetStateAction } from 'react';

type FreeTrial = {
  showPremiumFeatureModal: boolean;
  setShowPremiumFeatureModal: Dispatch<SetStateAction<boolean>>;
};

const FreeTrialContext = createContext<FreeTrial>({
  showPremiumFeatureModal: false,
  setShowPremiumFeatureModal: () => {
    /* do nothing */
  }
});

type FreeTrialProviderProps = {
  children: React.ReactNode;
  showPremiumFeatureModal: boolean;
  setShowPremiumFeatureModal: Dispatch<SetStateAction<boolean>>;
};

const FreeTrialProvider: React.FC<FreeTrialProviderProps> = ({
  children,
  setShowPremiumFeatureModal,
  showPremiumFeatureModal
}) => {
  return (
    <FreeTrialContext.Provider
      value={{
        setShowPremiumFeatureModal,
        showPremiumFeatureModal
      }}>
      {children}
    </FreeTrialContext.Provider>
  );
};

export { FreeTrialContext, FreeTrialProvider };
