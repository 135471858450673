import { useTranslation } from 'react-i18next';

import './styles.scss';
import FilterHandlers from '../../../layout/NewFilters/FiltersHandlers';
import { FilterText } from '../../../layout/NewFilters/FilterText';

type Props = {
  children?: React.ReactNode;
};

export const ListHeader = ({ children }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'codeManagement' });
  return (
    <div className='code-management__list-header'>
      <div className='code-management__list-header__filters'>
        <FilterText field='code' type='il' placeholder={t('codePlaceholder')} />
        <FilterHandlers blacklistedFilters={{ all: ['code'] }} />
      </div>
      {children}
    </div>
  );
};
