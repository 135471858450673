import { useEffect, useState } from 'react';
import useErrors from '../../../../../../../customHooks/useErrors';
import { getLcaProductListNotPaginated } from '../../../../../../../services/api/lca';

type Props = {
  lcaId: string;
};

interface ILcaProduct {
  product_name: string;
  product_code: string;
}

const useFetcProducts = ({ lcaId }: Props) => {
  const ERRORS = useErrors();

  const [lcaProducts, setLcaProducts] = useState<ILcaProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const getLCAProducts = async () => {
    if (loading || !lcaId) return;

    setLoading(true);
    const { response, error } = await getLcaProductListNotPaginated({ lcaId });
    setLoading(false);

    const status = response?.status || error?.response?.status || -1;

    if (status >= 400 || !response) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    setLcaProducts(response.data);
  };

  useEffect(() => {
    getLCAProducts();
  }, [lcaId]);

  return { lcaProducts, loading, error };
};

export default useFetcProducts;
