import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { deleteLCANode } from '../../../../services/api/lca/lcaScenarios';
import { LCABlockBack } from '../../types/LCABlock';

export const useDeleteNode = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteLCANode,
    onMutate: (variables) => {
      queryClient.setQueryData(
        ['lcaBlocks', org?.id, variables.acv_id],
        (oldData: LCABlockBack[]) => {
          return oldData.filter((block) => block.id !== variables.node_id);
        }
      );
    }
  });

  return { deleteLCANode: mutateAsync, isPending };
};
