import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'avoidedEmissions' });
  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: t('comparative'),
      dataIndex: 'comparative',
      key: 'comparative'
    },
    {
      title: t('avoidedEmission'),
      dataIndex: 'avoided_emission',
      key: 'avoided_emission'
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
