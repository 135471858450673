import { useTranslation } from 'react-i18next';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import GrayBackground from '../../../../../../ui/grayBackground/GrayBackground';
import Label from '../../../../../../ui/label/Label';
import { StarLabel } from '../../../../../../ui/statusLabels/starLabel/StarLabel';
import TableComponent from '../../../../../../ui/table/Table';
import { IModalData } from '../../hooks/useMockedData';
import { useTable } from '../../hooks/useTable';
import './styles.scss';

type Props = {
  onClose: () => void;
  modalData: IModalData | undefined;
};

const data1 = {
  dataOrigin: 'dataOrigin',
  companySize: 'companySize',
  carbonFootprint: 0,
  reductionGoals: 'reductionGoals',
  isoStandards: 'isoStandards',
  sustainabilityCertifications: 'sustainabilityCertifications',
  emissionFactorSource: 'emissionFactorSource'
};
export const ModalSupplier = ({ onClose, modalData }: Props) => {
  const { t } = useTranslation();
  const { columns, data } = useTable(modalData || data1);
  const riskTranslated = t(`purchases.suppliersTable.${modalData?.risk}`);
  return (
    <div>
      <FormHeader
        title={
          <div className='flex'>
            {modalData?.companyName}
            <div className='flex' style={{ marginLeft: '1em' }}>
              <Label lookAndFeel='primary'>{t('suppliers.risk', { risk: riskTranslated })}</Label>
              <StarLabel
                text={t('suppliers.score', { score: modalData?.scoreData })}
                scoreData={modalData?.scoreData || 'A'}
              />
            </div>
          </div>
        }
      />
      <GrayBackground>
        <div className='modal-supplier-webinar'>
          <TableComponent data={data} loading={false} columns={columns} />
        </div>
      </GrayBackground>
    </div>
  );
};
