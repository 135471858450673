import { EXCEL_DATA_CATEGORIES } from '../../../../constants/excelData';
import apiFetch from '../../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import { getExcelData } from './getExcelData';

const URL = '/organizations/files/excel-report';

type DownloadExcelMITECOParams = {
  year: string;
};

export const downloadExcelMITECO = async ({ year }: DownloadExcelMITECOParams) => {
  const dataRequests = [
    getExcelData({ category: EXCEL_DATA_CATEGORIES.combustion, year }),
    getExcelData({ category: EXCEL_DATA_CATEGORIES.electricity, year }),
    getExcelData({ category: EXCEL_DATA_CATEGORIES.recharge, year }),
    getExcelData({ category: EXCEL_DATA_CATEGORIES.vehicles, year })
  ];

  const [combustion, electricity, recharge, vehicles] = await Promise.all(dataRequests);

  const body = {
    combustion,
    electricity,
    recharge,
    vehicles
  };

  const response = await apiFetch(
    'POST',
    getUrl(URL, { queryParams: generateQueryParamsFromObject({ year }) }),
    body
  );

  return response.data as string;
};
