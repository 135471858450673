import { useState } from 'react';
import Button from '../../../ui/button/Button';
import Icon from '../../../ui/icon/Icon';
import { ImportEmissionsModal } from './ImportEmissionsModal/ImportEmissionsModal';

export const ImportEmissionsBanner: React.FC = () => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  return (
    <>
      {open && <ImportEmissionsModal show={open} onClose={() => setOpen(false)} />}
      <div className='w-full flex justify-between items-end bg-neutral-white border-neutral-gray-warm-60 rounded-8 border-dashed border-1 border-box p-4 mb-4'>
        <div className='flex-col gap-y-2'>
          <span className='m-0 font-body-b1-b'>Añade o edita impactos totales</span>
          <span className='m-0 font-body-b2-r'>
            Introduce manualmente los impactos totales por categorías si ya los conoces.
          </span>
        </div>
        <div className='flex gap-x-4'>
          <Button
            lookAndFeel='secondary'
            text='Editar impactos totales'
            size='small'
            iconNode={<Icon icon='edit' size='medium' color='gray-dark' />}
            onClick={onClick}
          />
        </div>
      </div>
    </>
  );
};
