import { ISecondaryCodeFront } from '../../../../../../types/secondaryCode';
import GenericInfiniteList from '../../../../../ui/infiniteList/InfiniteListV2';
import { useColumns } from '../hooks/useColumns';

type Props = Omit<React.ComponentProps<typeof GenericInfiniteList>, 'columns' | 'data'> & {
  data: ISecondaryCodeFront[];
};

export const InfiniteList: React.FC<Props> = ({
  data,
  loading,
  fetchData,
  firstLoading,
  header
}) => {
  const { columns } = useColumns();

  return (
    <GenericInfiniteList
      columns={columns}
      loading={loading}
      data={data}
      fetchData={fetchData}
      firstLoading={firstLoading}
      header={header}
    />
  );
};
