import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { DATA_QUALITY_THRESHOLD } from '../../../constants';
import { UserContext } from '../../../context/userContext';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { getDataQuality } from '../../../services/api/data_quality';
import { getMatrix } from '../../../services/api/matrices';
import { gray_color2, scope1_color, scope2_color, scope3_color } from '../../../styles/colors';
import { Holding } from '../../../types/entities/holdings';
import calculateSectorEquivalence from '../../../utils/calculateSectorEquivalence';
import { convertStringToDate } from '../../../utils/convertDates';
import exportAsImage from '../../../utils/exportAsImage';
import { formatDate } from '../../../utils/formatDate';
import numberToDecimal from '../../../utils/numberToDecimal';
import Button from '../../ui/button/Button';
import SlideToggle2 from '../../ui/formComponents/slideToggle2/SlideToggle2';
import Label from '../../ui/label/Label';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import ScopeTag from '../../ui/scopeTag/ScopeTag';
import Tag from '../../ui/tag/Tag';
import TagCompanyImpact from '../../ui/tag/TagCompanyImpact';
import './styles.scss';

function Snippet() {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const flags = useFeatureFlags();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const exportRef = useRef(null);
  const [data, setData] = useState<ScopeData[]>();
  const [dataQualityPercentage, setDataQualityPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloadingImg, setDownloadingImg] = useState(false);
  const [groupDataMode, setGroupDataMode] = useState<'total' | 'investment'>(
    flags?.consolidation ? 'total' : 'investment'
  );

  let pieChartData = [{ title: '', value: 100, color: gray_color2 }];

  const foundOrganization = user?.organizations?.find(
    (elem) => elem.id === user?.selectedOrganization
  );

  const getDataQualityPercentage = async () => {
    if (!user?.selectedOrganization) return;
    const responseDataQuality = await getDataQuality(user.selectedOrganization);
    setDataQualityPercentage(responseDataQuality.data_quality_value);
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const startDate = query.get('start_date');
    const endDate = query.get('end_date');

    if (startDate && endDate) {
      setStartDate(convertStringToDate(startDate));
      setEndDate(convertStringToDate(endDate));
    }
  }, [window.location.search]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    getDataQualityPercentage();
    requestInfo(formatDate(startDate), formatDate(endDate));
  }, [startDate, endDate, groupDataMode]);

  const requestInfo = async (startDate: string, endDate: string) => {
    try {
      if (!user?.selectedOrganization) {
        return;
      }
      setLoading(true);
      const groupByScope = [
        { scope: 1, total_emissions: 0 },
        { scope: 2, total_emissions: 0 },
        { scope: 3, total_emissions: 0 }
      ];

      const data: Holding = await getMatrix(
        user?.selectedOrganization,
        convertStringToDate(startDate),
        convertStringToDate(endDate),
        groupDataMode
      );
      if (!data) {
        setLoading(false);
        return;
      }

      data?.childs_info.forEach((child) => {
        child.co2e.forEach((elem) => {
          if (groupDataMode === 'total') {
            if (elem.scope === 1) {
              groupByScope[0].total_emissions += elem.total_emissions;
            }
            if (elem.scope === 2) {
              groupByScope[1].total_emissions += elem.total_emissions;
            }
            if (elem.scope === 3) {
              groupByScope[2].total_emissions += elem.total_emissions;
            }
          } else {
            groupByScope[2].total_emissions += elem.total_emissions;
          }
        });
      });
      data?.parent_co2e?.forEach((elem) => {
        if (elem.scope === 1) {
          groupByScope[0].total_emissions += elem.total_emissions;
        }
        if (elem.scope === 2) {
          groupByScope[1].total_emissions += elem.total_emissions;
        }
        if (elem.scope === 3) {
          groupByScope[2].total_emissions += elem.total_emissions;
        }
      });

      setData(groupByScope);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderCo2Information = (totalCo2: number) => {
    let unit = 'kg CO₂ eq.';
    let totalCo2Parsed = totalCo2;
    if (totalCo2Parsed >= 1000) {
      totalCo2Parsed = totalCo2Parsed / 1000;
      unit = 't CO₂ eq.';
    }
    return (
      <div className='co2-information'>
        <div className='co2-value secondary-text-color percentage-big-font'>
          {numberToDecimal(totalCo2Parsed)}
        </div>
        <span className='co2-units on-light-text-color body1-font'>{unit}</span>
      </div>
    );
  };

  const downloadPNG = async () => {
    if (exportRef.current) {
      setDownloadingImg(true);
      await exportAsImage(exportRef.current, 'snippet', 900, 550).then(() => {
        setDownloadingImg(false);
      });
    }
  };

  const handleSwitchGroupMode = () => {
    if (loading) return;
    if (groupDataMode === 'total') {
      setGroupDataMode('investment');
    } else {
      setGroupDataMode('total');
    }
  };

  let totalCo2 = 0;
  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  if (data && data.filter((elem) => elem.total_emissions > 0).length > 0) {
    data.forEach((value) => {
      totalCo2 += value.total_emissions;

      if (value.scope === 1) {
        scope1 += value.total_emissions;
      }
      if (value.scope === 2) {
        scope2 += value.total_emissions;
      }
      if (value.scope === 3) {
        scope3 += value.total_emissions;
      }
    });

    pieChartData = [
      { title: '', value: numberToDecimal((scope1 * 100) / totalCo2), color: scope1_color },
      { title: '', value: numberToDecimal((scope2 * 100) / totalCo2), color: scope2_color },
      {
        title: '',
        value: numberToDecimal((scope3 * 100) / totalCo2),
        color: scope3_color
      }
    ];
    pieChartData.sort((a, b) => {
      return b.value - a.value;
    });
  }

  const scope1Value = scope1 / 1000;
  const scope2Value = scope2 / 1000;
  const scope3Value = scope3 / 1000;

  const totalCo2Value = totalCo2 / 1000;

  if (!foundOrganization || !startDate || !endDate) {
    return null;
  }
  if (loading) {
    return <LoaderTables />;
  }

  return (
    <section className='snippet'>
      <div className='snippet__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('snippet.title')}</h3>
      </div>
      {flags?.consolidation && (
        <div className='snippet__mode'>
          <SlideToggle2
            options={[
              {
                id: 'total',
                name: t('dashboard.consolidated'),
                tooltip: t('dashboard.consolidatedTooltip')
              },
              {
                id: 'investment',
                name: t('dashboard.investment'),
                tooltip: t('dashboard.investmentTooltip')
              }
            ]}
            value={{ id: groupDataMode, name: groupDataMode }}
            setValue={handleSwitchGroupMode}
          />
        </div>
      )}
      <div className='snippet-wrapper'>
        <div className='snippet-downloadable card-border-color main-bg-color' ref={exportRef}>
          <h4 className='headline4-font on-light-text-color'>{t('dashboard.environmentImpact')}</h4>
          <div className='company-environment-wrapper-downloadable'>
            <div className='company-environment-impact'>
              <div className='company-environment-impact__co2-wrapper round on-card-gray-bg-color'>
                <div className='co2-progress-bar'>
                  <PieChart // your data
                    data={pieChartData}
                    // width and height of the view box
                    viewBoxSize={[100, 100]}
                    lineWidth={24}
                    totalValue={100}
                    rounded
                    animate></PieChart>
                  {renderCo2Information(totalCo2)}
                </div>
              </div>
              {foundOrganization.offset > 0 && (
                <span className='offset secondary-text-color body1-bold-font'>
                  {foundOrganization.offset < 1000
                    ? t('dashboard.alreadyOffsetKg').replace(
                        '{{offset}}',
                        numberToDecimal(foundOrganization.offset).toString()
                      )
                    : t('dashboard.alreadyOffsetTon').replace(
                        '{{offset}}',
                        numberToDecimal(foundOrganization.offset / 1000).toString()
                      )}
                </span>
              )}
            </div>

            <div className='company-environment-scopes'>
              <div className='company-environment-scopes__information'>
                <ScopeTag
                  scope={1}
                  value={scope1Value}
                  totalCo2={totalCo2Value}
                  textLookAndFeel='secondary'
                />
                <ScopeTag
                  scope={2}
                  value={scope2Value}
                  totalCo2={totalCo2Value}
                  textLookAndFeel='secondary'
                />
                <ScopeTag
                  scope={3}
                  value={scope3Value}
                  totalCo2={totalCo2Value}
                  textLookAndFeel='secondary'
                />
              </div>
              <div className='comparison-wrapper'>
                <div className='comparison-wrapper__item'>
                  {dataQualityPercentage > DATA_QUALITY_THRESHOLD ? (
                    <Tag
                      tags={[
                        {
                          icon1: '',
                          text: (
                            <span className='on-light-text-color tag-font'>
                              {t('dashboard.impactComparedWithSector')}
                            </span>
                          ),
                          icon2: '/images/icons/piechart.svg'
                        },
                        {
                          text: (
                            <span className='secondary-text-color tag-bigger-font'>
                              {calculateSectorEquivalence(foundOrganization, endDate, totalCo2)}
                            </span>
                          )
                        }
                      ]}
                    />
                  ) : (
                    <TagCompanyImpact
                      tags={[
                        {
                          title: (
                            <span className='on-light-text-color tag-font'>
                              {t('dashboard.impactComparedWithSector')}
                            </span>
                          )
                        },
                        {
                          info: (
                            <Label lookAndFeel='secondary'>
                              <div className='icon-text-wrapper'>
                                <img src='/images/icons/diamondOnTag.svg' alt='diamond' />
                                <span>{t('error.lowDataQuality')}</span>
                              </div>
                            </Label>
                          )
                        }
                      ]}
                    />
                  )}
                </div>
                {/* <div className='comparison-wrapper__item'>
                  <TagCompanyImpact
                    tags={[
                      {
                        title: (
                          <span className='on-light-text-color tag-font'>
                            {t('dashboard.impactTravel')}
                          </span>
                        )
                      },
                      {
                        info: (
                          <>
                            <img src='/images/icons/carBigger.svg' alt='Pie chart' />
                            <span className='secondary-text-color tag-bigger-font'>
                              {numberToDecimal(totalCo2 / 0.118)}
                            </span>
                          </>
                        )
                      }
                    ]}
                  />
                </div> */}
                <div className='comparison-wrapper__item'>
                  <TagCompanyImpact
                    tags={[
                      {
                        title: (
                          <span className='on-light-text-color tag-font'>
                            {t('dashboard.impactByEmployee')}
                          </span>
                        )
                      },
                      {
                        info: (
                          <>
                            <img src='/images/icons/person.svg' alt='Pie chart' />
                            <span className='secondary-text-color tag-bigger-font'>
                              {foundOrganization.employee_count_signup > 0
                                ? numberToDecimal(
                                    totalCo2 / 1000 / foundOrganization.employee_count_signup
                                  )
                                : numberToDecimal(totalCo2 / 1000)}
                            </span>
                          </>
                        )
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='snippet-footer'>
            <span className='body3-font'>
              {t('dashboard.dateRange')
                .replace('{{startDate}}', startDate.toLocaleDateString())
                .replace('{{endDate}}', endDate.toLocaleDateString())}
            </span>
            <span className='body3-font data-via'>Data via</span>
            <img src={'/images/logoBlack.svg'} alt='logo' />
            {process.env.REACT_APP_PARTNER !== 'dcycle' && (
              <div className='powered-by'>
                <span className={`body3-font`}>Powered by</span>
                <img src={'/images/dcycleLogoBlack.svg'} alt='dcycle' />
              </div>
            )}
          </div>
        </div>
        <Button
          lookAndFeel={'primary'}
          onClick={downloadPNG}
          text={t('snippet.download')}
          loading={downloadingImg}
        />
      </div>
    </section>
  );
}

export default Snippet;
