export enum InvoiceSource {
  DATADIS = 'datadis'
}

export enum InvoiceStatus {
  ERROR = 'error',
  ACTIVE = 'active',
  UPLOADED = 'uploaded',
  LOADING = 'loading'
}
