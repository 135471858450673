import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountries } from '../../../../constants/phonePrefixes';
import { UserContext } from '../../../../context/userContext';

const useCountries = () => {
  const { i18n } = useTranslation();
  const user = useContext(UserContext);

  const foundOrganization = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  const countriesOptions = getCountries(
    i18n.resolvedLanguage,
    foundOrganization ? foundOrganization.country : 'ES'
  );

  return countriesOptions;
};

export default useCountries;
