import { useQuery } from '@tanstack/react-query';
import { getIntensityMetricGroups } from '../../../../services/api/intensityMetrics';

export const useGetIntensityMetrics = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['intensityMetrics'],
    queryFn: async () => await getIntensityMetricGroups()
  });

  return { data, isLoading: isFetching };
};
