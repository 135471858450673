import useCriteriaContext from '../../hooks/useCriteriaContext';
import ModalC21 from '../ModalC21/ModalC21';
import { ModalC1 } from '../modalC1/ModalC1';
import { ModalC11 } from '../modalC11/ModalC11';
import { ModalC14 } from '../modalC14/ModalC14';
import ModalC15 from '../modalC15/ModalC15';
import { ModalC16 } from '../modalC16/ModalC16';
import ModalC17 from '../modalC17/ModalC17';
import ModalC18 from '../modalC18/ModalC18';
import ModalC19 from '../modalC19/ModalC19';
import { ModalC2 } from '../modalC2/ModalC2';
import ModalC20 from '../modalC20/ModalC20';
import ModalC20_1 from '../modalC20_1/ModalC20_1';
import ModalC5 from '../modalC5/ModalC5';
import ModalC6 from '../modalC6/ModalC6';
import { ModalC7 } from '../modalC7/ModalC7';
import ModalC8 from '../modalC8/ModalC8';
import ModalC9 from '../modalC9/ModalC9';

const ModalCriteria = () => {
  const { criteriaContext } = useCriteriaContext();

  // List with all modals for each criteria
  const modals = {
    C1: ModalC1,
    C2: ModalC2,
    C5: ModalC5,
    C6: ModalC6,
    C7: ModalC7,
    C8: ModalC8,
    C9: ModalC9,
    C11: ModalC11,
    C14: ModalC14,
    C15: ModalC15,
    C17: ModalC17,
    C18: ModalC18,
    C16: ModalC16,
    C19: ModalC19,
    C20: ModalC20,
    C20_1: ModalC20_1,
    C21: ModalC21
  };

  const Modal = modals[criteriaContext.criteria.criteria as keyof typeof modals];
  return <Modal />;
};

export default ModalCriteria;
