import { EmployeeReport } from '../../../../../../types/entities/employee';
import { EmployeeGroupByTransportType } from '../types/types';

const groupByTransportType = (employees: EmployeeReport[], totalCo2: number) => {
  const employeesGroupedByTransportType: EmployeeGroupByTransportType[] = [];
  const indexs = new Set<string>([]);

  employees.forEach((employee) => {
    if (!indexs.has(employee.transport_type)) {
      employeesGroupedByTransportType.push({
        id: employee.transport_type,
        nEmployees: 1,
        co2e: employee.co2e,
        total: (employee.co2e * 100) / totalCo2,
        situation: employee.situation
      });
      indexs.add(employee.transport_type);
      return;
    }

    const element = employeesGroupedByTransportType.find(
      (element) => element.id === employee.transport_type
    );

    if (!element) return;
    element.co2e += employee.co2e;
    element.nEmployees += 1;
    element.total = (element.co2e * 100) / totalCo2;
  });

  return employeesGroupedByTransportType.sort((a, b) => b.co2e - a.co2e);
};

export default groupByTransportType;
