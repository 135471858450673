export const ID_ORGS_PRIVACY_COMMUNICATION = [
  { id: '69256b6a-56d8-4465-9a31-2e43f6c2ebaf', name: 'ALUDEC INYECCION SA' },
  { id: 'c0003a37-a9db-4ccf-8685-abb195368cd3', name: 'ALUDEC COATINGS, S.A.' },
  { id: 'c2da86f7-ea59-4cc7-9424-bc01cd8335c7', name: 'Aludec Componentes SL' },
  { id: 'c5e126aa-ffb6-4c5a-b762-736bd36a9a07', name: 'Aludec Galvanic SA' },
  { id: '2660dbc7-edd6-4ed8-b23e-8ba85f3310d4', name: 'ALUDEC S.A.' },
  { id: 'edc52791-fb89-4772-b69c-cc9facb12cb9', name: 'ALUDEC STAMPING SA' },
  { id: '448cfe65-6952-4014-b5c8-e0abe3792f53', name: 'Marta Empresa' }
];

//last id for testing in stag (org Marta Empresa)
