import { ComponentProps } from 'react';
import ErrorText from '../../../errorText/ErrorText';
import InputWrapperPlain from '../inputWrapperPlain/InputWrapperPlain';
import InputLabel from './components/InputLabel';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type InputWrapperPlainType = ComponentProps<typeof InputWrapperPlain>;
type InputLabelType = ComponentProps<typeof InputLabel>;

interface Props extends InputWrapperPlainType, InputLabelType {
  tooltip?: string;
  error?: ErrorType;
  disabled?: boolean;
  className?: string;
}

const InputWrapper = ({ error, tooltip, label, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`input ${rest.className ?? ''}`}>
      {label && <InputLabel tooltip={tooltip} label={label} />}
      <InputWrapperPlain {...rest} error={error}>
        {rest.children}
      </InputWrapperPlain>
      {error && (
        <ErrorText style={{ marginTop: '0.5rem' }}>
          {error?.description ?? t('error.requiredField')}
        </ErrorText>
      )}
    </div>
  );
};

export default InputWrapper;
