import { Dispatch } from '@reduxjs/toolkit';
import { PurchasesType } from '../types/purchasesLoader';
import { PURCHASES_ACTIONS } from './types';

type PurchasesDispatch = {
  type: keyof typeof PURCHASES_ACTIONS;
  payload: PurchasesType;
};

export const startPurchaseLoader = (dispatch: Dispatch<PurchasesDispatch>) => {
  dispatch({ type: 'START_LOADING_PURCHASES', payload: { isLoading: true } });
};

export const stopPurchaseLoader = (dispatch: Dispatch<PurchasesDispatch>) => {
  dispatch({ type: 'STOP_LOADING_PURCHASES', payload: { isLoading: false } });
};
