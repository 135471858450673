import { useEffect, useState } from 'react';
import useErrors from '../../../../../../../customHooks/useErrors';
import { getUseAndEndOfLifeById } from '../../../../../../../services/api/lca';
import { ILCASoldProductBack } from '../../../../../../../types/entities/useAndEndOfLife';

type Props = { id?: string };

const useFetchElementToEdit = ({ id }: Props) => {
  const ERRORS = useErrors();

  const [element, setElement] = useState<ILCASoldProductBack>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const getElementToEdit = async () => {
    if (!id) {
      setElement(undefined);
      return;
    }
    if (loading) return;

    setLoading(true);
    const { response, error } = await getUseAndEndOfLifeById({ lcaId: id });
    setLoading(false);

    const status = response?.status || error?.response?.status || -1;

    if (status >= 400 || !response) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    setElement(response.data);
  };

  useEffect(() => {
    getElementToEdit();
  }, [id]);

  return { element, loading, error };
};

export default useFetchElementToEdit;
