import { ImpactCategory } from './types/ImpactCategory';

export const PAGE_SIZE = 32;

export const LCA_IMPACT_CATEGORIES = {
  CLIMATE_CHANGE: 'climate_change',
  CLIMATE_CHANGE_FOSSIL: 'climate_change_fossil',
  CLIMATE_CHANGE_BIOGENIC: 'climate_change_biogenic',
  CLIMATE_CHANGE_LAND_USE: 'climate_change_land_use',
  OZONE_DEPLETION: 'ozone_depletion',
  HUMAN_TOXICITY_CARCINOGENIC: 'human_toxicity_carcinogenic',
  HUMAN_TOXICITY_NON_CARCINOGENIC: 'human_toxicity_non_carcinogenic',
  PARTICULATE_MATTER_FORMATION: 'particulate_matter_formation',
  IONISING_RADIATION: 'ionising_radiation',
  PHOTOCHEMICAL_OXIDANT_FORMATION_HUMAN: 'photochemical_oxidant_formation_human',
  ACIDIFICATION: 'acidification',
  EUTROPHICATION_TERRESTRIAL: 'eutrophication_terrestrial',
  EUTROPHICATION_FRESH_WATER: 'eutrophication_freshwater',
  EUTROPHICATION_MARINE: 'eutrophication_marine',
  ECOTOXICITY_FRESH_WATER: 'ecotoxicity_freshwater',
  LAND_USE: 'land_use',
  WATER_SCARCITY: 'water_scarcity',
  MATERIAL_RESOURCES_DEPLETION: 'material_resources_depletion',
  FOSSIL_FUELS_DEPLETION: 'fossil_fuels_depletion',
  NON_RENEWABLE_ENERGY_RESOURCES: 'non_renewable_energy_resources',
  RENEWABLE_ENERGY_RESOURCES: 'renewable_energy_resources',
  WATER_USE: 'water_use',
  HAZARDOUS_WASTE: 'hazardous_waste',
  NON_HAZARDOUS_WASTE: 'non_hazardous_waste',
  INT_LOW_LEVEL_RADIOACTIVE_WASTE: 'int_low_level_radioactive_waste',
  HIGH_LEVEL_RADIOACTIVE_WASTE: 'high_level_radioactive_waste',
  ECOTOXICITY: 'ecotoxicity',
  EUTROPHICATION: 'eutrophication',
  PHOTOCHEMICAL_OXIDANT_FORMATION_ECOSYSTEM: 'photochemical_oxidant_formation_ecosystem'
} as const;

export const LCA_IMPACT_CATEGORIES_TRANSLATION_KEYS = {
  [LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE]: 'lcaDetail.impactCategories.climate_change',
  [LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE_FOSSIL]: 'lcaDetail.impactCategories.climate_change_fossil',
  [LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE_BIOGENIC]:
    'lcaDetail.impactCategories.climate_change_biogenic',
  [LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE_LAND_USE]:
    'lcaDetail.impactCategories.climate_change_land_use',
  [LCA_IMPACT_CATEGORIES.OZONE_DEPLETION]: 'lcaDetail.impactCategories.ozone_depletion',
  [LCA_IMPACT_CATEGORIES.HUMAN_TOXICITY_CARCINOGENIC]:
    'lcaDetail.impactCategories.human_toxicity_carcinogenic',
  [LCA_IMPACT_CATEGORIES.HUMAN_TOXICITY_NON_CARCINOGENIC]:
    'lcaDetail.impactCategories.human_toxicity_non_carcinogenic',
  [LCA_IMPACT_CATEGORIES.PARTICULATE_MATTER_FORMATION]:
    'lcaDetail.impactCategories.particulate_matter_formation',
  [LCA_IMPACT_CATEGORIES.IONISING_RADIATION]: 'lcaDetail.impactCategories.ionising_radiation',
  [LCA_IMPACT_CATEGORIES.PHOTOCHEMICAL_OXIDANT_FORMATION_HUMAN]:
    'lcaDetail.impactCategories.photochemical_oxidant_formation_human',
  [LCA_IMPACT_CATEGORIES.ACIDIFICATION]: 'lcaDetail.impactCategories.acidification',
  [LCA_IMPACT_CATEGORIES.EUTROPHICATION_TERRESTRIAL]:
    'lcaDetail.impactCategories.eutrophication_terrestrial',
  [LCA_IMPACT_CATEGORIES.EUTROPHICATION_FRESH_WATER]:
    'lcaDetail.impactCategories.eutrophication_freshwater',
  [LCA_IMPACT_CATEGORIES.EUTROPHICATION_MARINE]: 'lcaDetail.impactCategories.eutrophication_marine',
  [LCA_IMPACT_CATEGORIES.ECOTOXICITY_FRESH_WATER]:
    'lcaDetail.impactCategories.ecotoxicity_freshwater',
  [LCA_IMPACT_CATEGORIES.LAND_USE]: 'lcaDetail.impactCategories.land_use',
  [LCA_IMPACT_CATEGORIES.WATER_SCARCITY]: 'lcaDetail.impactCategories.water_scarcity',
  [LCA_IMPACT_CATEGORIES.MATERIAL_RESOURCES_DEPLETION]:
    'lcaDetail.impactCategories.material_resources_depletion',
  [LCA_IMPACT_CATEGORIES.FOSSIL_FUELS_DEPLETION]:
    'lcaDetail.impactCategories.fossil_fuels_depletion',
  [LCA_IMPACT_CATEGORIES.NON_RENEWABLE_ENERGY_RESOURCES]:
    'lcaDetail.impactCategories.non_renewable_energy_resources',
  [LCA_IMPACT_CATEGORIES.RENEWABLE_ENERGY_RESOURCES]:
    'lcaDetail.impactCategories.renewable_energy_resources',
  [LCA_IMPACT_CATEGORIES.WATER_USE]: 'lcaDetail.impactCategories.water_use',
  [LCA_IMPACT_CATEGORIES.HAZARDOUS_WASTE]: 'lcaDetail.impactCategories.hazardous_waste',
  [LCA_IMPACT_CATEGORIES.NON_HAZARDOUS_WASTE]: 'lcaDetail.impactCategories.non_hazardous_waste',
  [LCA_IMPACT_CATEGORIES.INT_LOW_LEVEL_RADIOACTIVE_WASTE]:
    'lcaDetail.impactCategories.int_low_level_radioactive_waste',
  [LCA_IMPACT_CATEGORIES.HIGH_LEVEL_RADIOACTIVE_WASTE]:
    'lcaDetail.impactCategories.high_level_radioactive_waste',
  [LCA_IMPACT_CATEGORIES.ECOTOXICITY]: 'lcaDetail.impactCategories.ecotoxicity',
  [LCA_IMPACT_CATEGORIES.EUTROPHICATION]: 'lcaDetail.impactCategories.eutrophication',
  [LCA_IMPACT_CATEGORIES.PHOTOCHEMICAL_OXIDANT_FORMATION_ECOSYSTEM]:
    'lcaDetail.impactCategories.photochemical_oxidant_formation_ecosystem'
} as const;

export const LCA_IMPACT_CATEGORIES_CLIMATE_CHANGE: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE,
  LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE_FOSSIL,
  LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE_BIOGENIC,
  LCA_IMPACT_CATEGORIES.CLIMATE_CHANGE_LAND_USE
];

export const LCA_IMPACT_CATEGORIES_HUMAN_TOXICITY: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.HUMAN_TOXICITY_CARCINOGENIC,
  LCA_IMPACT_CATEGORIES.HUMAN_TOXICITY_NON_CARCINOGENIC
];

export const LCA_IMPACT_CATEGORIES_EUTROPHICATION: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.EUTROPHICATION_TERRESTRIAL,
  LCA_IMPACT_CATEGORIES.EUTROPHICATION_FRESH_WATER,
  LCA_IMPACT_CATEGORIES.EUTROPHICATION_MARINE,
  LCA_IMPACT_CATEGORIES.EUTROPHICATION
];

export const LCA_IMPACT_CATEGORIES_ECO_TOXICITY: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.ECOTOXICITY_FRESH_WATER,
  LCA_IMPACT_CATEGORIES.ECOTOXICITY
];

export const LCA_IMPACT_CATEGORIES_PHOTO_OXIDANT: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.PHOTOCHEMICAL_OXIDANT_FORMATION_HUMAN,
  LCA_IMPACT_CATEGORIES.PHOTOCHEMICAL_OXIDANT_FORMATION_ECOSYSTEM
];

export const LCA_IMPACT_CATEGORIES_WATER_USE: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.WATER_SCARCITY,
  LCA_IMPACT_CATEGORIES.WATER_USE
];

export const LCA_IMPACT_CATEGORIES_WASTE: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.HAZARDOUS_WASTE,
  LCA_IMPACT_CATEGORIES.NON_HAZARDOUS_WASTE,
  LCA_IMPACT_CATEGORIES.INT_LOW_LEVEL_RADIOACTIVE_WASTE,
  LCA_IMPACT_CATEGORIES.HIGH_LEVEL_RADIOACTIVE_WASTE
];

export const LCA_IMPACT_CATEGORIES_ENERGY_RESOURCES: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.NON_RENEWABLE_ENERGY_RESOURCES,
  LCA_IMPACT_CATEGORIES.RENEWABLE_ENERGY_RESOURCES
];

export const LCA_IMPACT_CATEGORIES_ACIDIFICATION: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.ACIDIFICATION
];

export const LCA_IMPACT_CATEGORIES_IONISING_RADIATION: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.IONISING_RADIATION
];

export const LCA_IMPACT_CATEGORIES_PARTICULATE_MATTER_FORMATION: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.PARTICULATE_MATTER_FORMATION
];

export const LCA_IMPACT_CATEGORIES_OZONE_DEPLETION: ImpactCategory[] = [
  LCA_IMPACT_CATEGORIES.OZONE_DEPLETION
];

export const LCA_IMPACT_CATEGORIES_LAND_USE: ImpactCategory[] = [LCA_IMPACT_CATEGORIES.LAND_USE];

export const LCA_STATUS_KEY = 'acv';
