import React from 'react';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../ui/button/Button';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../constants/routes';

type Props = {
  handleClose: () => void;
  queryParams: {
    intensity_metric_id_1: string;
    intensity_metric_id_2: string;
  };
};
const DisclaimerConfirm = ({ handleClose, queryParams }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'orgDescriptionModal.ghg_report.disclaimer'
  });
  const handleConfirm = () => {
    window.open(
      `${ROUTES.COMMUNICATE_GHG}?intensity_metric_id_1=${queryParams.intensity_metric_id_1}&intensity_metric_id_2=${queryParams.intensity_metric_id_2}`,
      '_blank'
    );
  };
  return (
    <div>
      <FormHeader title={t('title')} description={t('description')} />
      <FormButtonSection>
        <Button lookAndFeel='secondary' onClick={handleClose} text={t('cancel')} />
        <Button lookAndFeel='primary' onClick={handleConfirm} text={t('generate')} />
      </FormButtonSection>
    </div>
  );
};

export default DisclaimerConfirm;
