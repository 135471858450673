import { LCACard } from '../../../Portfolio/LCADetail/components/LCACard';

const ICON_MAP = {
  material: 'leather',
  energy: 'electricity'
} as const;

type Props = {
  type: keyof typeof ICON_MAP;
};

export const MaterialIcon: React.FC<Props> = ({ type }) => {
  return (
    <div className='relative'>
      <LCACard.Icon icon='factory' lookAndFeel='process' />
      <LCACard.Icon
        icon={ICON_MAP[type]}
        lookAndFeel='material'
        size='medium'
        style={{ position: 'absolute', top: 'calc(100% - 8px)', left: 'calc(100% - 8px)' }}
      />
    </div>
  );
};
