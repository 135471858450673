import LoaderCard from '../../ui/loaders/loaderCard/LoaderCard';
import FrameworkProgress from './components/frameworkProgress/FrameworkProgress';
import Metrics from './components/metrics/Metrics';
import useGetData from './hooks/useGetData';
import './styles.scss';

const TOTALS = {
  environmental: 502,
  social: 383,
  governance: 51
} as Record<string, number>;

const ControlPanel = () => {
  const { organizationESGStatus, organizationFrameworkStatus } = useGetData();
  return (
    <div className='dashboard__body-control-panel'>
      <FrameworkProgress
        data={organizationFrameworkStatus.data}
        loading={organizationFrameworkStatus.loading}
      />
      {!organizationFrameworkStatus.loading && !organizationESGStatus.loading ? (
        <Metrics />
      ) : (
        <LoaderCard />
      )}
    </div>
  );
};

export default ControlPanel;
