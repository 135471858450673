import Button from '../../ui/button/Button';
import Modal from '../../ui/modal/Modal';
import './styles.scss';
type Props = {
  title: string;
  subtitle: string;
  buttonText: string;
  onClick: () => void;
  buttonDisabled?: boolean;
  error?: string;
};
function OldProfileCard({
  title,
  subtitle,
  buttonText,
  onClick,
  buttonDisabled = false,
  error = ''
}: Props) {
  return (
    <div className='card-navigation main-bg-color solid-border'>
      <div className='card-navigation-title'>
        <Modal.Header title={title} description={subtitle} />
      </div>
      <Button
        lookAndFeel='primary'
        size='medium'
        text={buttonText}
        onClick={onClick}
        disabled={buttonDisabled}
      />
      {error && <span className='error-text error-font error-text-color'>{error}</span>}
    </div>
  );
}

const Layout: React.FC = ({ children }) => {
  return (
    <div className='border-1 border-solid border-gray-2-light rounded-4 bg-neutral-white p-8'>
      {children}
    </div>
  );
};

const Content: React.FC = ({ children }) => {
  return <div className='flex-col gap-y-4 flex-grow'>{children}</div>;
};

const Title: React.FC = ({ children }) => {
  return <h4 className='font-body-b1-b m-0'>{children}</h4>;
};

const Description: React.FC = ({ children }) => {
  return <p className='font-body-b2-r m-0'>{children}</p>;
};

const Error: React.FC = ({ children }) => {
  return <span className='error-text error-font error-text-color'>{children}</span>;
};

export const ProfileCard = {
  Layout,
  Content,
  Title,
  Description,
  Error
};
