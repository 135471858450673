import { useTranslation } from 'react-i18next';

type Params<T extends boolean> = {
  asConst?: T;
};

type NotAsConst = [{ id: string; name: string }, { id: string; name: string }];

type AsConst = readonly [{ id: 'yes'; name: string }, { id: 'no'; name: string }];

const useYesNoOptions = <T extends boolean = false>({ asConst }: Params<T> = {}): T extends true
  ? Prettify<AsConst>
  : Prettify<NotAsConst> => {
  const { t } = useTranslation();

  if (asConst) {
    // The return type will be AsConst, which is a readonly array,
    // however we need to cast it to any because typescript do not understand that T is true
    return [
      {
        id: 'yes',
        name: t('yesNoOptions.yes')
      },
      {
        id: 'no',
        name: t('yesNoOptions.no')
      }
    ] as any;
  }

  return [
    {
      id: 'yes',
      name: t('yesNoOptions.yes')
    },
    {
      id: 'no',
      name: t('yesNoOptions.no')
    }
  ];
};

export default useYesNoOptions;
