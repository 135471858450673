import { useTranslation } from 'react-i18next';
import { getCountries } from '../../../../../../constants/phonePrefixes';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import { useFormContext } from '../FormContext';
import Button from '../../../../../ui/button/Button';
import { useStepContext } from '../../../../../ui/stepCarouselList/StepContext';
import { STEP_IDS } from '../constants';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import Modal from '../../../../../ui/modal/Modal';
import StepCarouselList from '../../../../../ui/stepCarouselList/StepCarouselList';
import { useModalContext } from '../../ModalHandler';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../../../constants';

type Props = {
  errors: {
    name?: ErrorType;
    country?: ErrorType;
  } | null;
};

export const FirstStep = ({ errors }: Props) => {
  const { form, setForm } = useFormContext();

  const { i18n } = useTranslation();

  const { t } = useLCATranslation();

  const { steps, handleSelect } = useStepContext();

  return (
    <>
      <Modal.Header
        title={t('lcaDetail.processFlow.processModal.title')}
        description={t('lcaDetail.processFlow.processModal.description')}
      />

      <StepCarouselList
        steps={steps}
        handleSelect={handleSelect}
        style={{ marginBottom: '1rem' }}
      />
      <Modal.Content style={{ flexGrow: 1, height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH }}>
        <div className='flex gap-x-4 w-full'>
          <div className='grow'>
            <FormText
              label={t('lcaDetail.processFlow.processModal.processNameLabel')}
              iconV2='factory'
              placeholder={t('lcaDetail.processFlow.processModal.processNamePlaceholder')}
              value={form.name}
              error={errors?.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className='grow'>
            <FormSelect
              label={t('lcaDetail.processFlow.processModal.countryLabel')}
              iconV2='location'
              error={errors?.country}
              options={getCountries(i18n.resolvedLanguage, i18n.resolvedLanguage.toUpperCase())}
              value={form.country}
              onChange={(country) => setForm({ ...form, country })}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Buttons />
      </Modal.Buttons>
    </>
  );
};

const Buttons = () => {
  const { handleSelect } = useStepContext();

  const { t } = useLCATranslation();

  const { setModalData } = useModalContext();

  const onClick = () => {
    handleSelect(STEP_IDS.SECOND);
  };

  const onCancel = () => {
    setModalData(undefined);
  };

  return (
    <>
      <Button
        lookAndFeel='secondary'
        text={t('lcaDetail.processFlow.processModal.cancelFirstStep')}
        size='small'
        onClick={onCancel}
      />
      <Button
        lookAndFeel='primary'
        text={t('lcaDetail.processFlow.processModal.saveFirstStep')}
        size='small'
        onClick={onClick}
      />
    </>
  );
};

FirstStep.Buttons = Buttons;
