import React from 'react';
import { useTranslation } from 'react-i18next';
import Dot from '../../../../ui/dot/Dot';
import { groupByCategory } from '../../../../../constants/groupByCategoryFunction';
import formatNumber, { formatNumberToNdecimal } from '../../../../../utils/formatNumber';

type Props = {
  filter: SelectOptionFormat;
};
const useColumns = ({ filter }: Props) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('dashboard.company'),
      dataIndex: 'company',
      key: 'company'
    },

    {
      title: (
        <span>
          <Dot type={'scope1'} />
          {t('dashboard.scope1')}
        </span>
      ),
      dataIndex: 'scope1',
      key: 'scope1',
      align: 'right',
      render: (value: string) => {
        return value;
      }
    },
    {
      title: (
        <span>
          <Dot type={'scope2'} />
          {t('dashboard.scope2')}
        </span>
      ),
      align: 'right',
      dataIndex: 'scope2',
      key: 'scope2',
      render: (value: string) => {
        return value;
      }
    },
    {
      title: (
        <span>
          <Dot type={'scope3'} />
          {t('dashboard.scope3')}
        </span>
      ),
      align: 'right',
      dataIndex: 'scope3',
      key: 'scope3',
      render: (value: string) => {
        return value;
      }
    },
    {
      title: t('dashboard.percentage'),
      dataIndex: 'percentage',
      key: 'percentage',
      align: 'right'
    },
    {
      title: 'Total',
      dataIndex: 'co2',
      key: 'co2',
      align: 'right'
    },
    {
      title: '',
      dataIndex: 'goToCompany',
      key: 'goToCompany'
    }
  ];

  const categoriesColumns: any = [];

  groupByCategory()
    .filter((elem) => elem.category !== 'employees_in_labore')
    .map((elem) => ({
      ...elem,
      category: elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, '')
    }))
    .forEach((elem) =>
      filter.id !== 'all' && elem.scope === parseInt(filter.id)
        ? categoriesColumns.push({
            title: (
              <span>
                {elem.scope === 1 || elem.scope === 2 || elem.scope === 3 ? (
                  <Dot type={`scope${elem.scope}`} />
                ) : null}
                {t(`categories.${elem.category}`)}
              </span>
            ),
            dataIndex: elem.category,
            key: elem.category,
            align: 'right',
            render: (value: string) => {
              return value;
            }
          })
        : null
    );

  const columns2 = [
    {
      title: t('dashboard.company'),
      dataIndex: 'company',
      key: 'company'
    },
    ...categoriesColumns,
    {
      title: 'Total',
      dataIndex: 'co2',
      key: 'co2',
      align: 'right'
    },
    {
      title: '',
      dataIndex: 'goToCompany',
      key: 'goToCompany'
    }
  ];

  return { columns, columns2, categoriesColumns };
};

export default useColumns;
