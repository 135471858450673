import { ChangeEvent, InputHTMLAttributes, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../../../customHooks/useOutsideClick';
import { InputSize } from '../../../../types/utilsEnums/input';
import InputText from '../inputText/InputText';
import Chevron from '../select/chevron/Chevron';
import DropdownCard from '../select/dropdownCard/DropdownCard';
import useOptionsFiltered from '../select/hooks/useOptionsFiltered';
import OptionList from '../select/optionList/OptionList';
import '../select/styles.scss';

interface Props {
  placeholder: string;
  options: SelectOptionFormat[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  sort?: boolean;
  optional?: boolean;
  size?: InputSize;
  extraOptions?: ReactNode;
}
const InputTextSelect = ({
  placeholder,
  value,
  options,
  onChange,
  size = InputSize.MEDIUM,
  disabled = false,
  sort = true,
  optional = false,
  extraOptions,
  ...props
}: Props & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);

  const wrapperRef = useOutsideClick(() => setShowDropdown(false));
  const optionsFiltered = useOptionsFiltered({ options, sort, optional, searchValue: value });

  const handleChangeValue = (value: SelectOptionFormat) => {
    onChange(value.id);
    setShowDropdown(false);
  };

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setShowDropdown(true);
  };

  return (
    <div ref={wrapperRef} style={{ width: '100%' }}>
      {/* wrapperRef is used to close the dropdown when clicking outside of it */}
      <div
        className={`input-select on-light-text-color`}
        onClick={() =>
          !disabled && !!options && options.length > 0 && setShowDropdown(!showDropdown)
        }>
        <InputText
          placeholder={placeholder !== '' ? placeholder : t('input.selectOption')}
          value={value}
          onChange={onChangeSearchValue}
          {...props}
        />
        <Chevron rotate={showDropdown} size={size} />
      </div>
      {showDropdown && options?.length > 0 && (
        <DropdownCard wrapperRef={wrapperRef}>
          <OptionList
            options={optionsFiltered}
            idSelected={value}
            handleChangeValue={handleChangeValue}
            size={size}
          />
          {extraOptions}
        </DropdownCard>
      )}
    </div>
  );
};

export default InputTextSelect;
