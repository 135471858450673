import React, { useState } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import uuid from 'uuid';
import { getLogisticsClients } from '../../../../services/api/logistics';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import useErrors from '../../../../customHooks/useErrors';
import { getUniqueItemsByProperties } from '../../../../utils/removeDuplicates';

type Props = {
  search?: string;
};
const useClients = ({ search }: Props) => {
  const selectedOrganization = useSelectedOrganization();
  const ERRORS = useErrors();

  const [error, setError] = useState<ErrorType>();

  const { data, fetchNextPage, isLoading, hasNextPage } = useInfiniteQuery({
    initialPageParam: '',
    queryKey: ['clients', search],
    queryFn: async ({ pageParam: lastClientName }) => {
      const clients = await getLogisticsClients(lastClientName, search);

      if (!clients) return;

      if (clients === ERRORS.LOGISTICS.NO_AVAILABLE_DATA.error) {
        setError(ERRORS.LOGISTICS.NO_AVAILABLE_DATA);
        return;
      }

      if (clients === ERRORS.LOGISTICS.NO_RECHARGES.error) {
        setError(ERRORS.LOGISTICS.NO_RECHARGES);
        return;
      }

      if (clients === ERRORS.LOGISTICS.NO_REQUESTS.error) {
        setError(ERRORS.LOGISTICS.NO_REQUESTS);
        return;
      }

      const mappedData: SelectOptionFormat[] = clients.map((elem: string) => ({
        id: elem,
        name: elem,
        optionalKey: uuid.v4()
      }));

      // If the organization is not present in the list, add it
      if (
        !clients.find((elem: SelectOptionFormat) => elem.id === selectedOrganization?.id) &&
        !lastClientName
      ) {
        mappedData.unshift({
          id: selectedOrganization?.company_name as string,
          name: selectedOrganization?.company_name as string
        });
      }

      return mappedData;
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage || !lastPage.length) return undefined;
      const lastClientName = lastPage[lastPage.length - 1].name;
      return lastClientName;
    }
  });
  return {
    clients:
      data?.pages
        .filter((elem): elem is SelectOptionFormat[] => {
          return elem !== undefined;
        })
        .flat() ?? [],
    fetchNextPage,
    loading: isLoading,
    error,
    hasNextPage
  };
};

export default useClients;
