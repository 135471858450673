import { useEffect, useState } from 'react';
import getBase64 from '../utils/getBase64';

type Props = File | undefined;

const useGetBase64 = (file: Props) => {
  const [fileBase64, setFileBase64] = useState<string | null | ArrayBuffer>();

  const processData = () => {
    if (!file) return;
    getBase64(file, async (file_url: string | null | ArrayBuffer) => {
      if (!file_url) return;
      setFileBase64(file_url);
    });
  };

  useEffect(() => {
    processData();
  }, [file]);

  return {
    fileBase64
  };
};

export default useGetBase64;

const convertBase64 = async (file: Props): Promise<string | null> => {
  if (!file) return null;

  return new Promise<string | null>((resolve) => {
    getBase64(file, (file_url: string | null | ArrayBuffer) => {
      if (!file_url) resolve(null);
      else resolve(file_url as string);
    });
  });
};

export { convertBase64 };
