import Checkbox from '../../../../../ui/formComponents/checkbox/Checkbox';

type Props = {
  title: string;
  text: string;
};

const Line = ({ title, text }: Props) => {
  return (
    <div>
      <Checkbox text={title} onChange={() => null} selected={true} />
      <p className='body1-font summary-text'>{text}</p>
    </div>
  );
};

export default Line;
