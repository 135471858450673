/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Position } from '../../interactiveTutorialTool/hooks/useHandleStep';
import './styles.scss';

export enum Location {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  BOTTOM = 'BOTTOM',
  TOP = 'TOP'
}

type DivPosition = {
  left: number;
  top: number;
  width: number;
  height: number;
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  positionElement: Position | null;
  plusTop?: number;
  plusLeft?: number;
  location?: Location;
}

const TutorialModal = ({ positionElement, location, plusTop, plusLeft, ...rest }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [position, setPosition] = useState<DivPosition | null>(null);
  const [arrowPosition, setArrowPosition] = useState<DivPosition | null>(null);

  const handleCalculatePosition = () => {
    if (!ref?.current || !positionElement) {
      setPosition(null);
      return;
    }

    const divPostion = ref.current.getBoundingClientRect();

    let newPosition = null;
    let newArrowPosition = null;

    if (location === Location.LEFT) {
      newPosition = {
        left: positionElement.left - divPostion.width - 20,
        top: positionElement.top
      };

      newArrowPosition = {
        left: positionElement.left - 20,
        top: positionElement.top + positionElement.height / 8
      };
    }

    if (location === Location.RIGHT) {
      newPosition = {
        left: positionElement.left + positionElement.width + 20,
        top: positionElement.top
      };

      newArrowPosition = {
        left: newPosition.left - 9,
        top: positionElement.top + positionElement.height / 8
      };
    }

    if (location === Location.TOP)
      newPosition = {
        left: positionElement.left - divPostion.width / 2 + positionElement.width / 2,
        top: positionElement.top - divPostion.height - 10
      };

    if (location === Location.BOTTOM)
      newPosition = {
        left: positionElement.left - divPostion.width / 2 + positionElement.width / 2,
        top: positionElement.top + positionElement.height + 10
      };

    if (!newPosition) return;

    if (plusTop) newPosition.top += plusTop;
    if (plusLeft) newPosition.left += plusLeft;

    setPosition({
      ...newPosition,
      width: divPostion.width,
      height: divPostion.height
    });

    if (!newArrowPosition) return;

    setArrowPosition({
      ...newArrowPosition,
      width: 10,
      height: 10
    });
  };

  useEffect(() => {
    handleCalculatePosition();
  }, [positionElement?.top, positionElement?.left, positionElement?.width, positionElement]);

  const { style, ...restProps } = rest;

  const getImage = () => {
    // Top and bottom dont already introduced!
    const images = {
      [Location.RIGHT]: '/images/pointerArrowLeft.svg',
      [Location.LEFT]: '/images/pointerArrowRight.svg',
      [Location.TOP]: '/images/pointerArrowBottom.svg',
      [Location.BOTTOM]: '/images/pointerArrowTop.svg'
    };
    if (!location) return;
    return images[location];
  };

  return (
    <>
      <div
        ref={ref}
        className='modal main-bg-color card-border-color modal-box-shadow modal-no-opacity round-border'
        style={{
          position: 'fixed',
          left: position?.left,
          top: position?.top,
          visibility: position ? 'visible' : 'hidden',
          ...style
        }}
        {...restProps}>
        {restProps.children}
      </div>
      <img
        src={getImage()}
        style={{
          position: 'fixed',
          top: arrowPosition?.top,
          left: arrowPosition?.left,
          width: '10px',
          zIndex: 1000000,
          visibility: position ? 'visible' : 'hidden'
        }}
      />
    </>
  );
};

export default TutorialModal;
