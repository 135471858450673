import { useUnits } from '../../../../customHooks/api/useUnits';
import { useLCATranslation } from '../../../../customHooks/translations/useLCATranslation';
import { convertDateBackToFront } from '../../../../utils/convertDates';
import Button from '../../../ui/button/Button';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import Modal from '../../../ui/modal/Modal';
import { FormProvider, useForm } from '../CreateLCA/components/CreateLCAModal/FormContext';
import { MetadataPage } from '../CreateLCA/components/CreateLCAModal/MetadataPage';
import { useLCADetail } from '../LCADetail/hooks/useLCADetail';
import { usePatchLCA } from '../LCADetail/hooks/usePatchLCA';

type Props = {
  lcaId: string | null;
  setLcaId: (lcaId: string | null) => void;
};
export const EditLCAModal: React.FC<Props> = ({ lcaId, setLcaId }) => {
  const { data, isLoading: isLoadingDetails } = useLCADetail(lcaId ?? undefined);

  const { t } = useLCATranslation();

  const { data: units, isLoading: isLoadingUnit } = useUnits();

  const unit = units?.find((unit) => unit.id === data?.unit_id);

  const defaultValues = {
    name: data?.name,
    startDate: data?.start_date ? convertDateBackToFront(data.start_date) : undefined,
    endDate: data?.end_date ? convertDateBackToFront(data.end_date) : undefined,
    value: typeof data?.value === 'number' ? data?.value.toString() : undefined,
    unit: {
      id: unit?.id ?? '',
      name: unit?.name ?? ''
    },
    impactCategories: data?.impact_categories
  };

  const onClose = () => {
    setLcaId(null);
  };

  const isLoading = isLoadingDetails || isLoadingUnit;

  return (
    <Modal.WithPortal show={Boolean(lcaId)} onClose={() => setLcaId(null)}>
      {(isLoading || !lcaId) && (
        <div className='grid place-center h-80'>
          <LoaderTables mode='list' />
        </div>
      )}
      {!isLoading && lcaId && (
        <FormProvider defaultValues={defaultValues}>
          <Modal.Header title={t('portfolio.editLCA.title')} />
          <MetadataPage.Content />
          <Buttons onClose={onClose} acv_id={lcaId} />
        </FormProvider>
      )}
    </Modal.WithPortal>
  );
};

type ButtonsProps = {
  onClose: () => void;
  acv_id: string;
};
const Buttons: React.FC<ButtonsProps> = ({ onClose, acv_id }) => {
  const { t } = useLCATranslation();

  const { form } = useForm();

  const { patchLCA, isPending } = usePatchLCA();

  const onSave = async () => {
    await patchLCA({ acv_id, data: form });

    onClose();
  };

  return (
    <Modal.Buttons>
      <Button
        lookAndFeel='secondary'
        text={t('portfolio.cancel')}
        size='medium'
        onClick={onClose}
      />
      <Button
        lookAndFeel='primary'
        text={t('portfolio.save')}
        size='medium'
        onClick={onSave}
        loading={isPending}
      />
    </Modal.Buttons>
  );
};
