import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import './styles.scss';

type Props = {
  img: string;
  title: string;
  type: string;
  date: string;
  shareUrl?: string;
  handleDownloadFile: () => void;
};

const CardDownload = ({ img, title, type, date, handleDownloadFile, shareUrl }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const copyUrlToClipboard = () => {
    if (shareUrl) {
      navigator.clipboard.writeText(shareUrl);
      dispatch(setNotification(t('notification.copiedToClipboard')));
    }
  };

  return (
    <div className='card-download solid-border main-bg-color'>
      {shareUrl && (
        <div className='card-download__share main-bg-color'>
          <img
            src='/images/icons/share.svg'
            alt='share'
            className='share'
            onClick={copyUrlToClipboard}
          />
        </div>
      )}
      <div className='card-download__download main-bg-color'>
        <img
          src='/images/icons/downloadGradient.svg'
          alt='download'
          className='download'
          onClick={handleDownloadFile}
        />
      </div>
      <div className='card-download__img-wrapper icon-bg-color'>
        <img src={img} alt={title} className='card-download__img' />
      </div>
      <div className='card-download__content'>
        <h1 className='headline4-font'>{title}</h1>
        {type && (
          <div className='card-download__content__info'>
            <img src='/images/icons/leaf.svg' alt='leaf' />
            <p className='body1-font'>{type}</p>
          </div>
        )}
        {date && (
          <div className='card-download__content__info'>
            <img src='/images/icons/calendar50.svg' alt='calendar' />
            <p className='body1-font'>{date}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardDownload;
