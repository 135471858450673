import { useState } from 'react';
import { FormType } from './constants';
import { adaptZodErrors } from '../../../../../adapters/zodErrors';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { ValidateFn } from './types';
import { useModalContext } from '../ModalHandler';

export const useValidate = () => {
  const [errors, setErrors] = useState<ErrorContainer<FormType> | null>(null);
  const { modalData } = useModalContext();
  const { t } = useLCATranslation();

  const validate: ValidateFn = (form, schema) => {
    if (!modalData?.target || modalData.type !== 'edit_material') return false;

    const formValue = schema.safeParse(form);

    if (!formValue.success) {
      setErrors(adaptZodErrors(formValue.error, t));
      return false;
    }

    return true;
  };

  return { errors, setErrors, validate };
};
