import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useModal from '../hooks/useModal';

const ModalC20 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });

  const options: SelectOptionFormat<IYesNo>[] = [
    {
      id: 'yes',
      name: t('yes')
    },
    {
      id: 'no',
      name: t('no')
    }
  ];
  const mandatory = ['yesNo'];
  const { formData, setFormData, loading, patchCriteria, loadingButton, errorPatch } =
    useModal(mandatory);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  return (
    <>
      <FormHeader title={t('title')} />
      {loading && (
        <CustomSkeletonLoader
          count={1}
          style={{
            marginBottom: '2rem'
          }}
        />
      )}

      {!loading && (
        <FormWrapper>
          <FormSelect
            label={t('c20Modal')}
            iconV2='thermometer'
            onChange={onChangeValue('yesNo')}
            value={
              formData.yesNo || {
                id: '',
                name: ''
              }
            }
            options={options}
            error={formData.errors.find((error) => error.error === 'yesNo')}
          />
        </FormWrapper>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('save')}
          loading={loadingButton}
          size='small'
          onClick={() => patchCriteria(formData)}
        />
      </FormButtonSection>
      <ErrorText>{errorPatch && errorPatch.description}</ErrorText>
    </>
  );
};

export default ModalC20;
