import { numberToScientificNotation } from '../../../../../utils/numberToDecimal';
import { FrontLCAMethodology, LCAMethodology } from '../types/LCADetail';

export const adaptMethodologies = (methodologies: LCAMethodology[]): FrontLCAMethodology[] => {
  return methodologies.map((value) => {
    return {
      id: value.id,
      methodology: value.methodology,
      impact: numberToScientificNotation(value.impact),
      impact_category: value.impact_category,
      category: value.category ?? '',
      unit: value.unit
    };
  });
};
