import { ReactComponent as Step1 } from '../../../../../assets/img/step_1_purchases.svg';
import { ReactComponent as Step2 } from '../../../../../assets/img/step_2_purchases.svg';
import { ReactComponent as Step3 } from '../../../../../assets/img/step_3_purchases.svg';

const STEP_COMPONENTS = {
  1: Step1,
  2: Step2,
  3: Step3
} as const;

const Layout: React.FC = ({ children }) => {
  return (
    <div className='bg-tertiary w-full rounded-16 border-box flex items-center gap-x-4'>
      {children}
    </div>
  );
};

type ImageProps = {
  step: keyof typeof STEP_COMPONENTS;
};

const Image: React.FC<ImageProps> = ({ step }) => {
  const Component = STEP_COMPONENTS[step];

  return <Component className='rounded-16' />;
};

const TextWrapper: React.FC = ({ children }) => {
  return <span className='flex-col border-box px-4'>{children}</span>;
};

const Title: React.FC = ({ children }) => {
  return <strong className='font-body-b1-b mb-2'>{children}</strong>;
};

const Subtitle: React.FC = ({ children }) => {
  return <span className='font-body-b2-r'>{children}</span>;
};

export const HowToUploadStep = {
  Layout,
  Image,
  TextWrapper,
  Title,
  Subtitle
};
