export const environment = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_D0PUiRSgz',
      userPoolWebClientId: 's48tgkv271sug2eg6uc9qmdgd',
      oauth: {
        domain: 'dcycle-backend-auth-dcycledev-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://zxb39lv15j.execute-api.eu-west-1.amazonaws.com/dev',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};
