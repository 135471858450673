import React, { useState } from 'react';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { t } from 'i18next';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../ui/button/Button';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import { linkCompany } from '../../../../../services/api/matrices';
import { IBackendLinkedCompany } from '../../../../../types/entities/organization';

type FormData = {
  holdingId: string;
  errors: ErrorType[];
};

type Props = {
  handleLinkCompany: (company: IBackendLinkedCompany) => void;
};

const LinkCompany = ({ handleLinkCompany }: Props) => {
  const [formData, setFormData] = useState<FormData>({
    holdingId: '',
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    // check errors
    const newErrors = checkFormErrors(formData, formData.errors, []);
    if (newErrors.length > 0) return;

    setLoadingButton(true);
    const data = await linkCompany(formData.holdingId);
    setLoadingButton(false);
    if (!data) {
      setFormData({
        ...formData,
        errors: [
          {
            error: 'general',
            description: t('error.somethingWentWrong')
          }
        ]
      });
      return;
    }

    handleLinkCompany(data as IBackendLinkedCompany);
  };

  const generalError = formData.errors.find((elem) => elem.error === 'general');
  return (
    <>
      <FormHeader
        title={t('parentCompanies.linkCompany.title')}
        description={t('parentCompanies.linkCompany.description')}
      />
      <FormWrapper style={{ whiteSpace: 'pre-line' }}>
        <FormText
          icon={'/images/icons/chain.svg'}
          placeholder={t('profile.writeHoldingId')}
          label={t('profile.holdingId')}
          value={formData.holdingId}
          onChange={onChangeValue('holdingId')}
          error={formData.errors.find((elem) => elem.error === 'holdingId')}
          tooltip={t('parentCompanies.linkCompany.tooltip')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('profile.link')}
          size='small'
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {generalError && (
        <span className='error-text-color body1-bold-font' style={{ marginTop: '0.5rem' }}>
          {generalError.description}
        </span>
      )}
    </>
  );
};

export default LinkCompany;
