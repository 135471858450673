import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import {
  DatadisCUPS,
  DatadisContract,
  FormDataDatadisContract
} from '../../../../../../types/entities/datadisContract';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import Button from '../../../../../ui/button/Button';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import InputHeaderWrapper from '../../../../../ui/formComponents2/inputUtils/inputHeaderWrapper/InputHeaderWrapper';
import InputWrapper from '../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../../ui/formComponents2/select/Select';
import CustomSkeletonLoader from '../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useGetContracts from './hooks/useGetContracts';
import useGetData from './hooks/useGetData';
import { getSelectedContract } from './utils';
import useDebounceValue from '../../../../../../customHooks/useDebounceValue';
import useFetchFacilities from './hooks/useFetchFacilities';

type Props = {
  nif: string;
  handleSubmit: (formData: FormDataDatadisContract, contract: DatadisContract) => Promise<void>;
  formDataFilled?: FormDataDatadisContract;
  error: ErrorType | null;
};

const emptyFormData: FormDataDatadisContract = {
  cups: {
    id: '',
    name: ''
  },
  facility: {
    id: '',
    name: ''
  },
  supplier: {
    id: '',
    name: ''
  },
  dateInterval: {
    id: '',
    name: ''
  },
  startDate: '',
  endDate: '',
  errors: []
};

const Form = ({ nif, handleSubmit, error, formDataFilled }: Props) => {
  const { t } = useTranslation();
  const { suppliers, cups, loading, error: errorDatadis } = useGetData({ nif });
  const [formData, setFormData] = useState<FormDataDatadisContract>(
    formDataFilled || emptyFormData
  );
  const [selectedCups, setSelectedCups] = useState<DatadisCUPS>();

  const {
    contractsData,
    dateIntervalsOptions,
    loading: loadingContract
  } = useGetContracts({
    nif,
    cups: selectedCups
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounceValue(searchValue);

  const {
    data: facilities,
    fetch: fetchFacilities,
    loading: loadingFacilities,
    page
  } = useFetchFacilities({ searchValue: debouncedSearchValue });

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const selectedContract = getSelectedContract(contractsData, selectedCups, formData);

  const onChangeCups = (value: SelectOptionFormat) => {
    const newSelectedContract = cups.find((cupsElement) => cupsElement.cups === value.id);
    if (!newSelectedContract) return;
    onChangeValue('cups')(value);
    onChangeValue('startDate')('');
    onChangeValue('endDate')('');
    setSelectedCups(newSelectedContract);
  };

  const handleErrors = () => {
    let optionalFields = ['startDate', 'endDate'];

    if (!formData.startDate && formData.endDate)
      optionalFields = optionalFields.filter((field) => field !== 'startDate');

    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    if (newErrors.length === 0) return;

    setFormData((previous) => ({
      ...previous,
      errors: newErrors
    }));

    return newErrors;
  };

  const handleClickSubmit = async () => {
    if (loadingButton || handleErrors() || !selectedContract) return;

    setLoadingButton(true);
    await handleSubmit(formData, selectedContract);
    setLoadingButton(false);
  };

  const cupsOptions: SelectOptionFormat[] = cups.map((cupsElement) => ({
    id: cupsElement.cups,
    name: cupsElement.cups
  }));

  const supplierOptions: SelectOptionFormat[] = suppliers.map((supplier) => ({
    id: supplier.id,
    name: supplier.name
  }));

  const facilityOption: SelectOptionFormat[] = facilities.map((facility) => ({
    id: facility.id,
    name: facility.name
  }));

  if (loading)
    return (
      <>
        <CustomSkeletonLoader count={5} />
        <span className='body1-font' style={{ marginTop: '1rem' }}>
          {t('datadisConnection.mightTakeTime')}
        </span>
      </>
    );

  return (
    <>
      <FormWrapper>
        <FormElementFull>
          <InputWrapper
            icon='/images/icons/thunder50.svg'
            label={t('datadisContractAdd.cupsLabel')}
            tooltip={t('datadisContractAdd.cupsTooltip')}
            error={formData.errors.find((error) => error.error === 'cups')}>
            <Select
              placeholder={t('datadisContractAdd.cupsPlaceholder')}
              options={cupsOptions}
              value={formData.cups}
              onChange={onChangeCups}
            />
            <InputHeaderWrapper
              label={t('datadisContractAdd.facilityLabel')}
              tooltip={t('datadisContractAdd.facilityTooltip')}>
              <Select
                placeholder={t('datadisContractAdd.facilityPlaceholder')}
                options={facilityOption}
                value={formData.facility}
                onChange={onChangeValue('facility')}
                handleGetDown={fetchFacilities}
                searchValue={searchValue}
                onChangeSearchValue={setSearchValue}
                loading={loadingFacilities}
                searchBackendCallback={(searchValue: string) => {
                  setSearchValue(searchValue);
                  return Promise.resolve();
                }}
              />
            </InputHeaderWrapper>
          </InputWrapper>
        </FormElementFull>

        <FormElementFull>
          <InputWrapper
            icon='/images/icons/catalog.svg'
            label={t('datadisContractAdd.supplierLabel')}
            tooltip={t('datadisContractAdd.supplierTooltip')}
            error={formData.errors.find((error) => error.error === 'supplier')}>
            <Select
              placeholder={t('datadisContractAdd.supplierPlaceholder')}
              options={supplierOptions}
              value={formData.supplier}
              onChange={onChangeValue('supplier')}
            />
            <InputHeaderWrapper
              label={t('datadisContractAdd.dateIntervalLabel')}
              tooltip={t('datadisContractAdd.dateTooltip')}>
              <Select
                placeholder={t('datadisContractAdd.dateIntervalPlaceholder')}
                options={dateIntervalsOptions}
                value={formData.dateInterval}
                onChange={onChangeValue('dateInterval')}
              />
            </InputHeaderWrapper>
          </InputWrapper>
        </FormElementFull>

        {!!formData.dateInterval.id && selectedContract && (
          <FormElementFull>
            <FormCalendarDouble
              label={t('datadisContractAdd.customDateLabel')}
              tooltip={t('datadisContractAdd.customDateTooltip')}
              startDate={formData.startDate}
              endDate={formData.endDate}
              minStartDate={moment(selectedContract.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              maxEndDate={
                selectedContract.end_date
                  ? moment(selectedContract.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                  : undefined
              }
              handleChangeStartDate={onChangeValue('startDate')}
              handleChangeEndDate={onChangeValue('endDate')}
              handleStartDateError={handleDateError('startDate')}
              handleEndDateError={handleDateError('endDate')}
              error={formData.errors.find(
                (error) => error.error === 'startDate' || error.error === 'endDate'
              )}
            />
          </FormElementFull>
        )}
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('employees.save')}
          onClick={handleClickSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
      {!error && errorDatadis && <ErrorText>{errorDatadis.description}</ErrorText>}
    </>
  );
};

export default Form;
