import { validate } from 'email-validator';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LANGUAGES } from '../../../../../constants';
import { NotificationContext } from '../../../../../context/notificationContext';
import { UserContext } from '../../../../../context/userContext';
import useTemporalOptions from '../../../../../customHooks/useTemporalOptions';
import { IGetBackendBusinessTravel } from '../../../../../types/entities/businessTravels';
import { Employee, SimpleEmployee } from '../../../../../types/entities/employee';

import { SendFormType } from '../../../../../types/utilsEnums/form';
import apiFetch from '../../../../../utils/apiFetch';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import LoaderFormEmployee from '../../../../ui/loaders/loaderFormEmployees/LoaderFormEmployee';
import AddEmployee from './addEmployee/AddEmployee';
import { isDuplicated } from './functions';
import LinkToCSV from './linkToCSV/LinkToCSV';
import ListOfEmployees from './listOfEmployees/ListOfEmployees';
import './styles.scss';

type Props = {
  type: SendFormType;
  handleGoToCSV: () => void;
  close: () => void;
  addEmployees?: (employees: Employee[]) => void;
  addBusinessTrips?: (businessTrips: IGetBackendBusinessTravel[]) => void;
};

type Body = {
  subject: string;
  emails: string[];
  organization_id: string | undefined;
  lang: string;
  temporal?: string;
};

function SendEmailContent({ type, handleGoToCSV, close, addEmployees, addBusinessTrips }: Props) {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const { options } = useTemporalOptions();

  const setNotification = useContext(NotificationContext);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<ErrorType[]>([]);

  const [newEmail, setNewEmail] = useState('');
  const [employees, setEmployees] = useState<SimpleEmployee[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectOptionFormat>(options[0]);

  const [subject, setSubject] = useState('');
  const subjectHasChange = useRef(false);

  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [language, setLanguage] = useState<SelectOptionFormat>({
    id: i18n.resolvedLanguage,
    name: t(`languages.${i18n.resolvedLanguage}`)
  });

  const getEmployees = async () => {
    setLoading(true);
    const response = await apiFetch('GET', '/employees', null, {
      'x-organization-id': user?.selectedOrganization
    });
    setLoading(false);
    setEmployees(
      response?.data?.items.map((employee: Employee) => ({
        email: employee.email,
        selected: false
      }))
    );
  };

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (!subjectHasChange.current)
      setSubject(t('sendEmailForm.subjectDefault', { lng: language.id }));
  }, [language]);

  // ADD EMPLOYEES

  const handleOnChangeNewEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setNewEmail(e.target.value);
  };

  const handleOnClickAddEmail = () => {
    if (!validate(newEmail)) {
      setErrors((errors) =>
        errors.concat({
          error: 'email',
          description: t('error.notValid')
        })
      );
      return;
    }

    const newSimpleEmployee: SimpleEmployee = {
      email: newEmail,
      selected: true
    };

    if (isDuplicated(newEmail, employees)) {
      setEmployees((employeesOld) => {
        return employeesOld.map((employee) => ({
          ...employee,
          selected: employee.email === newEmail ? true : employee.selected
        }));
      });
      setNewEmail('');
      return;
    }

    const newEmployees = [...employees];
    newEmployees.unshift(newSimpleEmployee);

    setEmployees(newEmployees);

    setNewEmail('');
  };

  // LIST OF EMPLOYEES

  const handleSelectEmployee = (employeeToChange: SimpleEmployee) => {
    setEmployees((employees) => {
      return employees.map((employee) => ({
        ...employee,
        selected: employee.email === employeeToChange.email ? !employee.selected : employee.selected
      }));
    });
  };

  const handleDeleteEmployee = (employeeToChange: SimpleEmployee) => {
    setEmployees((employees) =>
      employees.filter((employee) => {
        return employee.email !== employeeToChange.email;
      })
    );
  };

  const handleSelectAllEmployees = () => {
    setEmployees((employees) =>
      employees.map((employee) => ({
        ...employee,
        selected: !allSelected
      }))
    );
    setAllSelected((allSelected) => !allSelected);
  };

  // SEND TEMPORARY

  // const handleSelectOption = (value: SelectOptionFormat) => {
  //   setSelectedOption(value);
  // };

  // SEND EMAILS

  const handleSendEmails = async () => {
    try {
      const emailsToSend = employees
        .filter((employee) => employee.selected)
        .map((employee) => employee.email);

      let url = '';
      const body: Body = {
        subject,
        emails: emailsToSend,
        organization_id: user?.selectedOrganization,
        lang: language.id
      };
      if (type === 'employee') {
        url = '/employees/form';
      }
      if (type === 'businessTravel') {
        url = '/business_travels/form';
        body.temporal = selectedOption.id;
      }
      setLoadingButton(true);
      const response = await apiFetch('POST', url, body);
      setLoadingButton(false);
      dispatch(setNotification(t('notification.customFormSent')));
      close();

      if (addEmployees) {
        addEmployees(response.data);
      }
      if (addBusinessTrips) {
        addBusinessTrips(response.data);
      }
    } catch (err: any) {
      setLoadingButton(false);
      if (err.response.data === 'MAX_EMAILS_EXCEEDED') {
        setErrors((errors) =>
          errors.concat({
            error: 'maxEmailsExceeded',
            description: t('error.maxEmailsExceeded')
          })
        );
      }
    }
  };

  const onChangeLanguage = (value: SelectOptionFormat) => {
    setLanguage(value);
  };

  const onChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
    subjectHasChange.current = true;
    setSubject(e.target.value);
  };

  const employeesWithEmail = employees.filter((employee) => employee.email);

  return (
    <div className='send-email-content'>
      <AddEmployee
        employee={newEmail}
        handleOnChangeNewUser={handleOnChangeNewEmail}
        handleOnClickAddEmail={handleOnClickAddEmail}
        errors={errors}
      />
      {loading ? (
        <LoaderFormEmployee />
      ) : (
        <ListOfEmployees
          employees={employeesWithEmail}
          handleSelectEmployee={handleSelectEmployee}
          handleDeleteEmployee={handleDeleteEmployee}
          handleSelectAll={handleSelectAllEmployees}
          allSelected={allSelected}
        />
      )}
      {/* {type === 'businessTravel' ? (
        <SendTemporary
          handleSelectOption={handleSelectOption}
          selectedOption={selectedOption}
          options={options}
        />
      ) : null} */}
      <FormWrapper>
        <FormSelect
          options={LANGUAGES.map((language) => ({
            id: language,
            name: t(`languages.${language}`)
          }))}
          icon='/images/icons/globe.svg'
          placeholder={t('sendEmailForm.language')}
          label={t('sendEmailForm.language')}
          value={language}
          onChange={onChangeLanguage}
        />
        {type === 'employee' && (
          <FormElementFull>
            <FormText
              label={t('sendEmailForm.subjectTitle')}
              value={subject}
              icon='/images/icons/envelope.svg'
              placeholder={t('sendEmailForm.subjectPlaceholder')}
              onChange={onChangeSubject}
            />
          </FormElementFull>
        )}
      </FormWrapper>
      {errors.find((error) => error.error === 'maxEmailsExceeded') && (
        <p className='error-text-color body1-bold-font'>{t('error.maxEmailsExceeded')}</p>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleSendEmails}
          text={t('sendEmailForm.send')}
          size={'medium'}
          height={'small'}
          loading={loadingButton}
        />
      </FormButtonSection>
      <LinkToCSV handleOnClick={handleGoToCSV} />
    </div>
  );
}

export default SendEmailContent;
