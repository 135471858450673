import { ReactNode } from 'react';
import './styles.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode[];
}

function SlideToggle3({ children, ...rest }: Props) {
  const className = `slide-toggle-wrapper3 input-border ${rest.className}}`;

  return (
    <div {...rest} className={className}>
      {children}
    </div>
  );
}

export default SlideToggle3;
