import { ChangeEvent, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../tooltip/Tooltip';
import InputSearch from '../inputSearch/InputSearch';
import SelectOption from './SelectOption';
import './styles.scss';

type Props = {
  icon?: string;
  label?: string | ReactNode;
  tooltipText?: string;
  placeholder: string;
  options: SelectOptionFormat[];
  value: SelectOptionFormat;
  type?: 'basic';
  onChangeValue: (value: SelectOptionFormat) => void;
  fontClass?: string;
  error?: ErrorType;
  height?: string;
  size?: 'small' | 'big';
  showOnly?: boolean;
  disabled?: boolean;
  sort?: boolean;
  optional?: boolean;
};

const Select = ({
  icon,
  label,
  tooltipText,
  value,
  placeholder,
  onChangeValue,
  options,
  type,
  error,
  fontClass = 'input-font',
  height,
  size = 'small',
  showOnly,
  disabled = false,
  sort = true,
  optional = false
}: Props) => {
  const { t } = useTranslation();

  const [showOptions, setShowOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [optionIndex, setOptionIndex] = useState(0);
  const [optionsFiltered, setOptionsFiltered] = useState<SelectOptionFormat[]>(options);

  const [disableAutoScroll, setDisableAutoScroll] = useState(false);

  const wrapperRef: any = useRef(null);
  const showOptionsRef: any = useRef(showOptions);

  function downHandler(e: { preventDefault: () => void; key: string; target: any }) {
    if (wrapperRef?.current?.contains(e.target)) {
      if (e.key === 'Tab' && showOptionsRef.current) {
        hideSelectOptions();
      }
      if (e.key === 'ArrowDown' && showOptionsRef.current) {
        e.preventDefault();
        setOptionIndex((prevState) => {
          return prevState < optionsFiltered.length - 1 ? prevState + 1 : prevState;
        });
        setDisableAutoScroll(false);
      } else if (e.key === 'Enter' && showOptionsRef.current) {
        if (optionsFiltered[optionIndex]) {
          onChangeValue(optionsFiltered[optionIndex]);
          setShowOptions(false);
        }
      } else if (e.key === 'ArrowUp' && showOptionsRef.current) {
        e.preventDefault();
        setOptionIndex((prevState) => (prevState > 0 ? prevState - 1 : prevState));
        setDisableAutoScroll(false);
      }
    }
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
        showOptionsRef.current = false;
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', downHandler);

    const element = optionsFiltered[optionIndex]
      ? document.getElementById(optionsFiltered[optionIndex].id)
      : null;
    if (element && !disableAutoScroll) {
      // scrollParentToChild(wrapperRef.current, element);
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', downHandler);
    };
  }, [wrapperRef, optionIndex, optionsFiltered]);

  useEffect(() => {
    let optionsFilteredValue = options;

    if (sort && optionsFilteredValue?.sort) {
      optionsFilteredValue = optionsFilteredValue.sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    if (optional && optionsFilteredValue?.unshift) {
      optionsFilteredValue.unshift({ name: t('select.doNotKnow'), id: '' });
    }
    setOptionsFiltered(optionsFilteredValue);
  }, [options]);

  useEffect(() => {
    if (!showOptions) {
      setOptionsFiltered(options);
    }
  }, [showOptions]);

  const searchFunction = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setSearchValue(e.target.value);
    const optionsFilteredValue = options.filter((elem) =>
      e.target.value ? elem.name.toLowerCase().includes(e.target.value.toLowerCase()) : true
    );
    setOptionsFiltered(optionsFilteredValue);
    setOptionIndex(0);
  };

  const handleChangeValue = (value: SelectOptionFormat) => {
    onChangeValue(value);
    hideSelectOptions();
  };

  const hideSelectOptions = () => {
    showOptionsRef.current = false;
    setShowOptions(false);
    setSearchValue('');
  };

  const showHideSelectOptions = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    showOptionsRef.current = !showOptions;
    setShowOptions(!showOptions);
  };

  const changeSelectedIndex = (index: number) => {
    setOptionIndex(index);
    setDisableAutoScroll(true);
  };
  const showSelectOptionsFocus = () => {
    showOptionsRef.current = true;
    setShowOptions(true);
  };

  if (!height && size === 'small') {
    height = '33px';
  }
  if (!height && size === 'big') {
    height = '48.5px';
  }

  return (
    <div
      className={`input ${type === 'basic' ? 'input--basic' : ''} ${size} `}
      onClick={showHideSelectOptions}
      ref={wrapperRef}>
      <div className='tooltip-wrapper'>
        {label && (
          <label className='input-label-font on-light-text-color label-wrapper'>
            {label}
            {tooltipText && <Tooltip text={tooltipText} position='right' />}
          </label>
        )}
      </div>
      <input type='text' className='hide' onFocus={showSelectOptionsFocus} id='select-hidden' />
      <div
        className={`input__select flex ${fontClass} ${size} ${
          type === 'basic'
            ? 'input__select--basic'
            : `${error ? 'error-border-color' : 'input-border-color'}`
        } ${disabled ? 'input-disabled-bg-color' : ''}`}
        style={{ height }}
        onClick={hideSelectOptions}>
        {icon && (
          <div className={`image-wrapper ${error ? 'error-border-color' : 'input-border-color'}`}>
            <img src={icon} alt='input-icon' width={18} />
          </div>
        )}
        <div
          className={`input__select__content on-light-text-color ${fontClass} ${size} `}
          style={{ paddingLeft: showOnly ? '0px' : '8px' }}>
          {value.name && value.id ? (
            <span>{`${value.name}`}</span>
          ) : (
            <span className='placeholder'>{placeholder}</span>
          )}
          {!showOnly && (
            <img
              src='/images/icons/chevron.svg'
              alt='chevron'
              className={`${showOptions && !disabled ? 'rotate180' : ''}`}
            />
          )}
          {showOptions && !showOnly && !disabled && (
            <div
              className={`select-options ${
                type === 'basic' ? 'select-options--basic' : ''
              } input-border-color`}>
              {options.length > 4 && (
                <InputSearch
                  icon={''}
                  placeholder={t('search.search')}
                  value={searchValue}
                  height={height}
                  onChangeValue={searchFunction}
                  fontClass={fontClass}
                  size={size}
                  id='select-search'
                />
              )}
              {optionsFiltered.map &&
                optionsFiltered.map((option, index) => {
                  return (
                    <SelectOption
                      key={`${option.id}-${index}`}
                      value={option}
                      onClickOption={handleChangeValue}
                      fontClass={fontClass}
                      height={height}
                      optionIndex={optionIndex}
                      index={index}
                      changeSelectedIndex={changeSelectedIndex}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default Select;
