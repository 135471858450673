import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const FormElementShort = ({ ...props }: Props) => {
  return <div className='form-line-short' {...props}></div>;
};

export default FormElementShort;
