import { useTranslation } from 'react-i18next';
import { OFFSET_INFO } from '../../../constants';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import HelpCTA from '../../ui/helpCTA/HelpCTA';
import OffsetDataImage from './offsetDataImage/OffsetDataImage';
import OffsetProjects from './offsetProjects/OffsetProjects';
import OffsetText from './offsetText/OffsetText';
import './styles.scss';

const Offset = () => {
  const { t, i18n } = useTranslation();

  const redirectUrl = i18n.resolvedLanguage === 'es' ? OFFSET_INFO.es : OFFSET_INFO.en;
  const handleRedirect = () => window.open(redirectUrl);

  return (
    <section className='offset'>
      <SectionHeader title={t('offset.title')} subtitle={t('offset.subtitle')} />
      <hr className='split-line' />
      <div style={{ position: 'absolute', right: 0 }}>
        <HelpCTA description={t('offset.text.undestand')}>
          <div className='flex buttons'>
            <Button
              onClick={handleRedirect}
              text={t('offset.text.offset')}
              lookAndFeel={'primary'}
              size='tiny'
            />
          </div>
        </HelpCTA>
      </div>
      <div className='offset-container'>
        <div className='first-row'>
          <OffsetText />
          <OffsetDataImage />
        </div>
        <div className='second-row'>
          <OffsetProjects />
        </div>
      </div>
    </section>
  );
};

export default Offset;
