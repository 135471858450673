import { PurchaseStatus } from '../components/measure/activity/purchases/type';
import { IPurchaseSupplierBackend } from './purchaseSupplier';
import { Status } from './utilsEnums/status';

export enum PurchaseType {
  SPEND_BASE = 'spend_based',
  SUPPLIER_SPECIFIC = 'supplier_specific'
}

export enum SupplierSpecificPurchaseStatus {
  ACTIVE = 'active',
  LOADING = 'loading',
  ERROR = 'error'
}
/* BACKEND */

export type IPurchaseRest = {
  total2: number;
};

export type IPurchaseGeneralBackend =
  | (IPurchaseSpendBasedBackend & {
      purchase_type: PurchaseType.SPEND_BASE;
    })
  | (IPurchaseSupplierSpecificBackend & {
      purchase_type: PurchaseType.SUPPLIER_SPECIFIC;
    });

export type IPurchaseSpendBasedBackend = {
  custom_emmission_factor_id?: string | null;
  organization_id?: string;
  sector?: string;
  product_name: string;
  recycled?: number;
  quantity: number;
  country?: string;
  purchase_date: Date;
  frequency?: string;
  description?: string;
  unit_id: string;
  supplier_id?: string;
  supplier?: IPurchaseSupplierBackend;
  // GET
  id: string;
  unit?: Unit;
  file_url?: string;
  file_name?: string;
  status: PurchaseStatus;
  co2e?: number;
};

export type IPurchaseSupplierSpecificBackend = {
  product_name: string;
  supplier?: string;
  quantity: number;
  purchase_date: Date;
  unit_id: string;
  description?: string;
  // GET
  file_name?: never;
  id: string;
  unit?: Unit;
  file_url?: string;
  status?: Status;
  co2e?: number;
  organization_id?: string;
};

/* FRONT */

export type IPurchaseSpendBasedFormFront = {
  isRecycled: boolean;
  price: string;
  currency: SelectOptionFormat;
  country: SelectOptionFormat;
  purchaseDate: string;
  description: string;
  businessName: string;
  supplierCountry: SelectOptionFormat;
  supplier?: string;
  errors: ErrorType[];
  co2e: number;
};

export type IPurchaseSupplierSpecificFormFront = {
  product: SelectOptionFormat;
  quantity: string;
  unit: SelectOptionFormat;
  purchaseDate: string;
  description: string;
  supplier: SelectOptionFormat;
  errors: ErrorType[];
  co2e: number;
};

/* --------------------- OTHERS --------------------- */

export type PurchaseProduct = {
  product: string;
  sector: string;
};

export type PurchasesReport = {
  total: number;
  total_recycled: number;
  items: {
    co2e: number;
    product_name: string;
    quantity: number;
    purchase_type: string;
  }[];
};

export type PurchasesReportGHG = {
  total: number;
  total_recycled: number;
  items: {
    month: number;
    year: number;
    co2e: number;
    quantity: number;
  }[];
};
