import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ASK_FOR_LCA } from '../../../../../../constants';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { ILCASoldProductFront } from '../../../../../../types/entities/useAndEndOfLife';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import Button from '../../../../../ui/button/Button';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormNumber from '../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import SelectCardWrapper from '../../../../../ui/selectCard/SelectCardWrapper';
import { getYears } from '../../utils';
import useConstants from './hooks/useConstants';
import useFetchLcas from './hooks/useFetchLCAs';
import useFetchProducts from './hooks/useFetchProducts';
import useDebounceValue from '../../../../../../customHooks/useDebounceValue';
import FormSelectRaw from '../../../../../ui/formComponents2/formInputs/formSelectRaw/FormSelect';

type Props = {
  handleSubmit: (value: ILCASoldProductFront) => Promise<void>;
  loading?: boolean;
  formDataFilled?: ILCASoldProductFront;
  error?: ErrorType;
};

const Form = ({ loading, error, formDataFilled, handleSubmit }: Props) => {
  const years = getYears();

  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife.form' });
  const { t: mainT } = useTranslation('translation');
  const { emptyFormData, cards } = useConstants();

  const [formData, setFormData] = useState<ILCASoldProductFront>(emptyFormData); // add the name in products and lcas

  // initialize form data
  useEffect(() => {
    if (!formDataFilled) return;
    setFormData(formDataFilled);
  }, [formDataFilled]);

  // search value for lca select
  const [searchValue, setSearchValue] = useState('');

  // get lcas for select
  const { data: lcas, fetch: fetchLcas, loading: loadingLcas } = useFetchLcas({ searchValue });

  // get products for select
  const { lcaProducts: products, loading: loadingProducts } = useFetchProducts({
    lcaId: formData?.lcaId.id
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleLca = (value: SelectOptionFormat) => {
    onChangeValue('lcaId')(value);
    onChangeValue('productCode')({ id: '', name: '' });
  };

  const [loadingButton, setLoadingButton] = useState(false);

  const handleAskForLCA = () => window.open(ASK_FOR_LCA);

  const handleErrors = () => {
    const optionalFields: string[] = ['id'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors.length !== 0 ? newErrors : false;
  };

  const handleCLickSubmit = async () => {
    if (handleErrors() || loadingButton) return;
    setLoadingButton(true);
    await handleSubmit(formData);
    setLoadingButton(false);
  };

  if (loading) return <CustomSkeletonLoader count={4} />;

  return (
    <>
      <div className='form-wrapper'>
        <FormElementFull>
          <h3 className='body1-bold-font'>{t('type')}</h3>
          <SelectCardWrapper cards={cards} onClickCard={() => null} type={cards[0]} />
        </FormElementFull>

        <FormSelectRaw
          icon={'/images/icons/catalog.svg'}
          label={
            <>
              {t('pr')}{' '}
              <span onClick={handleAskForLCA} className='highlight-text-color pointer'>
                {mainT('here')}
              </span>
            </>
          }
          options={lcas}
          value={formData.lcaId}
          onChange={handleLca}
          searchValue={searchValue}
          onChangeSearchValue={(value) => setSearchValue(value)}
          handleGetDown={fetchLcas}
          loading={loadingLcas}
          disabled={!!formDataFilled}
          error={formData.errors.find((elem) => elem.error === 'lcaId')}
        />

        <FormSelect
          icon={'/images/icons/tshirt.svg'}
          label={t('productCode')}
          value={formData.productCode}
          error={formData.errors.find((elem) => elem.error === 'productCode')}
          options={products.map((elem) => ({ id: elem.product_code, name: elem.product_name }))}
          onChange={onChangeValue('productCode')}
          disabled={!!formDataFilled}
          loading={loadingProducts}
        />

        <FormSelect
          icon={'/images/icons/calendar.svg'}
          label={t('productionYear')}
          options={years}
          value={formData.productionYear}
          sort={false}
          onChange={onChangeValue('productionYear')}
          error={formData.errors.find((elem) => elem.error === 'productionYear')}
        />
        <FormNumber
          icon={'/images/icons/arrowGoingUp.svg'}
          label={t('productionQuantity')}
          onChange={onChangeValue('productionQuantity')}
          placeholder={t('commonNumberPlaceholder')}
          value={formData.productionQuantity}
          error={formData.errors.find((elem) => elem.error === 'productionQuantity')}
          onlyIntegers
        />
      </div>
      <FormButtonSection
        style={{
          marginTop: '1rem'
        }}>
        <Button
          lookAndFeel='primary'
          text={t('save')}
          onClick={handleCLickSubmit}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
};

export default Form;
