import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sectors } from '../../../constants/sectors';
import { RegisterInfo2 } from '../../../types/entities/user';
import StepSelector from '../../ui/stepPage/templateSteps/StepSelector';
type Props = {
  nextStep: (value: RegisterInfo2) => void;
  stepNumber: number;
  registerInfo: RegisterInfo2;
};
const StepSector = ({ nextStep, stepNumber, registerInfo }: Props) => {
  const { t, i18n } = useTranslation();
  const [sector, setSector] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });

  const onChangeSector = (option: SelectOptionFormat) => {
    setSector(option);
  };

  const handleNextStep = () => {
    nextStep({
      ...registerInfo,
      sector: sector.id
    });
  };

  const sectorsParsed = sectors.map((elem) => {
    return {
      id: elem.id,
      name: elem.title[i18n.language.split('-')[0] as keyof Language]
    };
  });

  return (
    <StepSelector
      nextStep={handleNextStep}
      stepNumber={stepNumber}
      title={t('onboardingUser.stepSector.title')}
      //   tooltip={t('onboardingUser.stepSector.tooltip')}
      buttonText={t('onboardingUser.stepSector.button')}
      placeholder={t('onboardingUser.stepSector.placeholder')}
      onChangeValue={onChangeSector}
      value={sector}
      options={sectorsParsed}
      icon={'/images/icons/piechart.svg'}
    />
  );
};

export default StepSector;
