import { useTranslation } from 'react-i18next';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import HubForm from '../hubForm/EditHubForm';

type Props = {
  data: IBackendHub;
  onClose: () => void;
};
const HubData = ({ data, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <FormHeader title={t('hubs.hubData.title')} />
      <HubForm
        formData={{
          type: data.type,
          name: data.name,
          hubCategory: {
            id: data.category ?? '',
            name: t(`logisticHocs.${data.category}`)
          },
          address: data.address,
          isSuperCharger: false,
          categories: [],
          errors: []
        }}
        setFormData={function (value: any): void {
          console.log('it is a disabled form');
        }}
        disabledFields={['type']}
      />
      <FormButtonSection>
        <Button lookAndFeel='primary' onClick={onClose} text={t('hubs.hubData.close')} />
      </FormButtonSection>
    </div>
  );
};

export default HubData;
