import React, { useState } from 'react';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../ui/button/Button';
import { deleteMatrixRel } from '../../../../../../services/api/matrices';
import ErrorText from '../../../../../ui/errorText/ErrorText';

type Props = {
  childId: string;
  parentId: string;
  idToRemove: string;
  nameToRemove: string;
  deleteRequest: (id: string) => void;
  handleClose: () => void;
};
const DeletePending = ({
  childId,
  parentId,
  idToRemove,
  nameToRemove,
  deleteRequest,
  handleClose
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'unlinkCompany' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    setLoadingButton(true);
    const data = await deleteMatrixRel(childId, parentId);
    setLoadingButton(false);
    if (!data) {
      setError(t('error.somethingWentWrong'));
      return;
    }

    deleteRequest(idToRemove);
  };

  return (
    <div>
      <FormHeader title={t('title', { name: nameToRemove })} description={t('description')} />
      <FormButtonSection>
        <Button
          lookAndFeel='secondary'
          text={tGeneral('cancel')}
          onClick={handleClose}
          size='small'
        />
        <Button
          lookAndFeel='warning'
          text={tGeneral('unlink')}
          onClick={handleSubmit}
          loading={loadingButton}
          size='small'
        />
      </FormButtonSection>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

export default DeletePending;
