import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import { VIDEOS } from '../../../constants';
import { UserContext } from '../../../context/userContext';

import { AvoidedEmissionListElement } from '../../../types/entities/avoidedEmission';
import numberToDecimal from '../../../utils/numberToDecimal';
import MainPageLayout from '../../layout/mainPageLayout/MainPageLayout';
import Button from '../../ui/button/Button';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import FormHeader from '../../ui/formComponents/formHeader/FormHeader';
import InfiniteList from '../../ui/infiniteList/InfiniteList';
import Modal from '../../ui/modal/Modal';
import VideoTutorialPopup from '../../ui/videoTutorialPopup/VideoTutorialPopup';
import YoutubeVideo from '../../ui/videoPlayer/YoutubeVideo';
import AddAvoidedEmission from './components/addAvoidedEmission/AddAvoidedEmission';
import AvoidedEmissionTag from './components/avoidedEmissionTag/AvoidedEmissionTag';
import DeleteAvoidedEmission from './components/deleteAvoidedEmission/DeleteAvoidedEmission';
import EditAvoidedEmission from './components/editAvoidedEmission/EditAvoidedEmission';
import './styles.scss';
import useColumns from './hooks/useColumns';
import Icon from '../../ui/icon/Icon';

const AvoidedEmissions = () => {
  const { t, i18n } = useTranslation();
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const dispatch = useDispatch();
  const url = `/avoided_emissions/list_by_organization`;

  const [avoidedEmissions, setAvoidedEmissions] = useState<AvoidedEmissionListElement[]>([]);
  const user = useContext(UserContext);
  const [total, setTotal] = useState(0);

  const [showAdd, setShowAdd] = useState(false);

  const [emissionToDelete, setEmissionToDelete] = useState('');
  const [emissionToEdit, setEmissionToEdit] = useState('');
  const [showVideo, setShowVideo] = useState(false);

  const videos = VIDEOS.avoidedEmissions as { [key: string]: string };

  const videoLink = videos[i18n.resolvedLanguage] as string;

  const handleShowAdd = () => setShowAdd(true);
  const onCloseModal = () => {
    setShowAdd(false);
    setEmissionToDelete('');
    setEmissionToEdit('');
  };

  const getImage = (name: string) => {
    const data = {
      stationary: {
        src: '/images/icons/fire50.svg',
        alt: 'heat'
      },
      electricity: {
        src: '/images/icons/thunder50.svg',
        alt: 'electricity'
      },
      recharge: {
        src: '/images/icons/velocimeter.svg',
        alt: 'recharges'
      },
      waste: {
        src: '/images/icons/waste.svg',
        alt: 'waste'
      }
    };

    return (
      data[name as keyof typeof data] || {
        src: '/images/icons/people.svg',
        alt: 'people'
      }
    );
  };

  const columns = useColumns();

  const addAvoidedEmission = (avoidedEmission: AvoidedEmissionListElement) => {
    setAvoidedEmissions((prev) =>
      prev.concat({
        ...avoidedEmission,
        total_emissions: numberToDecimal(avoidedEmission.total_emissions),
        selected_emissions: numberToDecimal(avoidedEmission.selected_emissions),
        avoided_emissions: numberToDecimal(avoidedEmission.avoided_emissions)
      })
    );
    setTotal((prev) => prev + 1);
    dispatch(setNotification(t('notification.addAvoidedEmission')));
    onCloseModal();
  };

  const editAvoidedEmission = (id: string, avoidedEmission: AvoidedEmissionListElement) => {
    setAvoidedEmissions((prev) => {
      const index = prev.findIndex((element) => element.id === id);
      if (index === -1) return prev;
      return prev.fill(avoidedEmission, index, index + 1);
    });
    dispatch(setNotification(t('notification.editAvoidedEmission')));
    onCloseModal();
  };

  const deleteAvoidedEmission = (id: string) => {
    setAvoidedEmissions((prev) => prev.filter((avoidedEmission) => avoidedEmission.id !== id));
    setTotal((prev) => prev - 1);
    dispatch(setNotification(t('notification.deleteAvoidedEmission')));
    onCloseModal();
  };

  const parseData = (avoidedEmissions: AvoidedEmissionListElement[]) => {
    return avoidedEmissions.map((avoidedEmission) => ({
      name: (
        <div className='name-section'>
          <img {...getImage(avoidedEmission.emission_source)} /> {avoidedEmission.name}
        </div>
      ),
      quantity: numberToDecimal(avoidedEmission.total_emissions),
      comparative: numberToDecimal(avoidedEmission.selected_emissions),
      avoided_emission: Math.abs(numberToDecimal(avoidedEmission.avoided_emissions)),
      status: (
        <AvoidedEmissionTag>
          {numberToDecimal(avoidedEmission.avoided_emissions)}
        </AvoidedEmissionTag>
      ),
      edit: (
        <ButtonDropdown
          button={<Icon icon='elipsis_horizontal' color='gray-dark' />}
          options={[
            {
              id: `${avoidedEmission.id}-delete`,
              name: tGeneral('delete'),
              onClick: () => {
                setEmissionToDelete(avoidedEmission.id);
              }
            },
            {
              id: `${avoidedEmission.id}-edit`,
              name: tGeneral('edit'),
              onClick: () => {
                setEmissionToEdit(avoidedEmission.id);
              }
            }
          ]}
        />
      )
    }));
  };
  return (
    <>
      <MainPageLayout
        sectionTitle={t('avoidedEmissions.title')}
        title={t('avoidedEmissions.start')}
        description={t('avoidedEmissions.startDescription')}
        buttons={
          <Button
            lookAndFeel={'primary'}
            onClick={handleShowAdd}
            text={t('avoidedEmissions.add')}
            size='small'
          />
        }>
        <InfiniteList
          i18key={'avoidedEmissions'}
          url={url}
          values={avoidedEmissions}
          setValues={setAvoidedEmissions}
          columns={columns}
          parseData={parseData}
          organization={user?.selectedOrganization}
          total={total}
          setTotal={setTotal}
        />
        <Modal.WithPortal show={showAdd} onClose={onCloseModal} width='800px' maxWidth='800px'>
          <AddAvoidedEmission addAvoidedEmission={addAvoidedEmission} />
        </Modal.WithPortal>
        <Modal.WithPortal
          show={!!emissionToEdit}
          onClose={onCloseModal}
          width='800px'
          maxWidth='800px'>
          <EditAvoidedEmission editAvoidedEmission={editAvoidedEmission} id={emissionToEdit} />
        </Modal.WithPortal>
        <Modal.WithPortal show={!!emissionToDelete} onClose={onCloseModal}>
          <DeleteAvoidedEmission
            deleteAvoidedEmissionById={deleteAvoidedEmission}
            id={emissionToDelete}
          />
        </Modal.WithPortal>
        {videoLink ? (
          <>
            <VideoTutorialPopup
              title={t('avoidedEmissions.title')}
              description={t('lca.discover')}
              image={'/images/pablo.png'}
              onClick={() => setShowVideo(true)}
            />
            <Modal show={showVideo} onClose={() => setShowVideo(false)} width='700px'>
              <FormHeader title={t('avoidedEmissions.title')} />
              <YoutubeVideo url={videoLink} />
            </Modal>
          </>
        ) : null}
      </MainPageLayout>
    </>
  );
};

export default AvoidedEmissions;
