import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  Title,
  Tooltip
} from 'chart.js';
import { useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import numberToDecimal from 'utils/numberToDecimal';
import TooltipChart from 'components/ui/tooltip/TooltipChart';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import { MonthlyDataPoint } from 'components/dashboard/dashboardTotal/hooks/useTotalDashboard';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend);

interface YearComparisonBarChartProps {
  labels: string[];
  datasets: {
    thisYear: MonthlyDataPoint[];
    lastYear: MonthlyDataPoint[];
  };
  loading: boolean;
  scope: 'total' | 'scope_1' | 'scope_2' | 'scope_3';
  thisYearColor1: string;
  thisYearColor2: string;
  lastYearColor: string;
  category?: SelectOptionFormat;
}

// Define a mapping for related categories
const categoryMapping: { [key: string]: string[] } = {
  vehicles: ['transport', 'freight', 'vehicles'],
  employees: ['employees', 'employees_in_labore', 'employees_in_itinere']
};

export function YearComparisonBarChart({
  labels,
  datasets,
  loading,
  scope,
  thisYearColor1,
  thisYearColor2,
  lastYearColor,
  category
}: YearComparisonBarChartProps) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0 },
    scales: {
      x: {
        type: 'category' as const,
        offset: true,
        position: 'bottom' as const,
        grid: { display: false, drawBorder: false },
        ticks: {
          display: scope === 'total',
          color: 'rgba(25, 42, 62, 0.8)',
          font: { size: 10, family: 'Inter', weight: '400', lineHeight: 1.2 },
          padding: 0
        }
      },
      y: {
        type: 'linear' as const,
        offset: true,
        grid: { display: false, drawBorder: false },
        ticks: {
          display: scope === 'total',
          color: 'rgba(25, 42, 62, 0.8)',
          font: { size: 10, family: 'Inter', weight: '400', lineHeight: 1.2 }
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
        align: 'end' as const,
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          font: {
            size: 10,
            family: 'Inter',
            weight: '400'
          },
          padding: 10
        }
      },
      tooltip: {
        enabled: false,
        external: function (context: any) {
          const tooltip = context.tooltip;
          if (tooltip.opacity === 0) {
            setShowTooltip(false);
            return;
          }
          const position = context.chart.canvas.getBoundingClientRect();
          setLeft(position.left + window.scrollX + tooltip.caretX);
          setTop(position.top + window.scrollY + tooltip.caretY - 20);
          setValue(`${numberToDecimal(tooltip.dataPoints[0].raw, 3)} t CO₂ eq.`);
          setShowTooltip(true);
        }
      }
    },
    clip: false as unknown as false
  };
  // Function to calculate the sum of CO₂ for mapped categories
  const calculateCategoryTotal = (data: MonthlyDataPoint[], categoryId: string): number[] => {
    const mappedCategories = categoryMapping[categoryId] || [categoryId];
    return data.map((month) =>
      month.category
        .filter((cat) => mappedCategories.includes(cat.id))
        .reduce((sum, cat) => sum + (cat.co2 || 0), 0)
    );
  };

  // Select the appropriate dataset based on category or scope
  const selectedDataset = {
    thisYear: category
      ? calculateCategoryTotal(datasets.thisYear, category.id)
      : datasets.thisYear.map((d) => d[scope]),
    lastYear: category
      ? calculateCategoryTotal(datasets.lastYear, category.id)
      : datasets.lastYear.map((d) => d[scope])
  };

  const data = {
    labels,
    datasets: [
      {
        label: `${datasets.thisYear[0]?.x.split('-')[0]}`,
        data: selectedDataset.thisYear,
        type: 'line' as const,
        borderColor: thisYearColor1,
        borderWidth: 0,
        fill: true,
        backgroundColor: function (context: any) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, thisYearColor1);
          gradient.addColorStop(1, thisYearColor2);

          return gradient;
        },
        pointRadius: 0,
        hitRadius: 10,
        pointHoverRadius: 4
      },
      {
        label: `${datasets.lastYear[0]?.x.split('-')[0]}`,
        data: selectedDataset.lastYear,
        type: 'line' as const,
        borderColor: lastYearColor,
        borderWidth: 2,
        fill: false,
        backgroundColor: lastYearColor,
        pointRadius: 0,
        pointHoverRadius: 4,
        hitRadius: 10,
        borderDash: [2, 3]
      }
    ]
  };

  if (loading) {
    return <LoaderCard />;
  }

  return (
    <>
      {showTooltip && <TooltipChart text={value} top={top} left={left} />}
      <Chart
        type='bar'
        options={options}
        data={data}
        ref={chartRef}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          height: '250px'
        }}
      />
    </>
  );
}

export default YearComparisonBarChart;
