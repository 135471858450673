import { useQuery } from '@tanstack/react-query';
import { getLatestMITECOVersion } from '../../services/api/orgEf';

export const useLatestMITECOVersion = () => {
  const { data } = useQuery({
    queryKey: ['latestMITECOVersion'],
    queryFn: getLatestMITECOVersion
  });

  return { data };
};
