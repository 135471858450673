import { useTranslation } from 'react-i18next';

export const useSteps = () => {
  const { t } = useTranslation();

  return [
    {
      id: 'doubleMateriality',
      text: t('doubleMateriality.steps.doubleMateriality')
    },
    {
      id: 'companyContext',
      text: t('doubleMateriality.steps.companyContext')
    },
    {
      id: 'engageStakeholders',
      text: t('doubleMateriality.steps.engageStakeholders')
    },
    {
      id: 'irosGapAnalysis',
      text: t('doubleMateriality.steps.irosGapAnalysis')
    }
  ];
};
