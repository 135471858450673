import apiFetch from '../../utils/apiFetch';

export const getDataQuality = async (organization_id: string) => {
  try {
    const response = await apiFetch('GET', `/data_quality/${organization_id}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getDataQualityMatrix = async (organization_id: string, returnRequest = false) => {
  try {
    const request = apiFetch('GET', `/data_quality/matrix/${organization_id}`);
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getDataQualityAccuracy = async (
  organization_id: string,
  start_date: Date,
  end_date: Date,
  returnRequest = false
) => {
  try {
    const request = apiFetch('POST', '/data_quality/accuracy/date', {
      organization_id,
      start_date,
      end_date
    });
    if (returnRequest) return request;

    const response = await request;

    return response.data;
  } catch (error) {
    return null;
  }
};
