import { useEffect, useState } from 'react';
import { adaptLogisticReportBackToFront } from '../../../../adapters/logistics';
import { getLogisticsReport } from '../../../../services/api/logistics';
import { LogisticReportFront } from '../../../../types/entities/logistics';
import { convertStringToDate } from '../../../../utils/convertDates';
import useGetLogisticExcelReportData from '../../../measure/logistics/components/client/hooks/useGetLogisticExcelReportData';
import useParseFuel from './useParseFuel';
import useParseTOC from './useParseTOC';

type GlectReportData = {
  logistics: LogisticReportFront;
};

type Props = {
  clientId: string;
  startDate: string;
  endDate: string;
  organizationId: string;
  language: string;
};

const useGetData = ({ clientId, startDate, endDate, organizationId, language }: Props) => {
  const {
    parseSelfOrganizationData,
    parseTocData,
    parseSumData,
    parseSumDataHocs,
    parseGeneralData,
    parseHocsData,
    parseTransportModes,
    parseHubsCategory,
    empty
  } = useGetLogisticExcelReportData(startDate, endDate, language);

  const [data, setData] = useState<GlectReportData>({ logistics: empty });
  const [loading, setLoading] = useState(false);

  const tocDataParsed = useParseTOC(data?.logistics.emissionFactorsUsed.logisticsTocs || []);
  const fuelDataParsed = useParseFuel(data?.logistics.emissionFactorsUsed.logisticFuels || []);

  const handleFetchData = async () => {
    if (loading) return;

    setLoading(true);
    const response = await getLogisticsReport(
      clientId,
      convertStringToDate(startDate),
      convertStringToDate(endDate),
      organizationId
    );
    setLoading(false);

    if (response?.response?.status >= 400) return;

    const logisticsData = adaptLogisticReportBackToFront(response);

    setData({
      ...data,
      logistics: logisticsData
    });
  };

  useEffect(() => {
    handleFetchData();
  }, [clientId, startDate, endDate, organizationId]);

  const lengthTocList = Object.entries(data?.logistics.vehicleInfo).length;
  const lengthHocsList = Object.entries(data?.logistics.hocsInfo).length;
  const lengthTransportModeList = Object.entries(data?.logistics.transportModeInfo).length;
  const lengthHubCategoriesList = Object.entries(data?.logistics.hocsInfo).length;

  const selfOrganizationDataParsed = parseSelfOrganizationData(data?.logistics);
  const tocOrganizationDataParsed = Object.entries(data?.logistics.vehicleInfo).map(
    ([key, value], index) => parseTocData(value, key, index + 1, lengthTocList)
  );
  const hocsOrganizationDataParsed = Object.entries(data?.logistics.hocsInfo).map(
    ([key, value], index) => parseHocsData(value, key, index + 1, lengthHocsList)
  );
  const transportModeOrganizationDataParsed = Object.entries(data?.logistics.transportModeInfo).map(
    ([key, value], index) => parseTransportModes(value, key, index + 1, lengthTransportModeList)
  );
  const hubCategoriesOrganizationDataParsed = Object.entries(data?.logistics.hubCategoryInfo).map(
    ([key, value], index) => parseHubsCategory(value, key, index + 1, lengthHubCategoriesList)
  );
  const sumDataParsed = parseSumData(data?.logistics, lengthTocList);
  const sumDataHocsParsed = parseSumDataHocs(data?.logistics, lengthTocList);
  const generalDataParsed = parseGeneralData();

  return {
    data,
    selfOrganizationDataParsed,
    tocOrganizationDataParsed,
    hocsOrganizationDataParsed,
    transportModeOrganizationDataParsed,
    hubCategoriesOrganizationDataParsed,
    generalDataParsed,
    sumDataParsed,
    sumDataHocsParsed,
    tocDataParsed,
    fuelDataParsed,
    loading
  };
};

export default useGetData;
