import React, { useEffect, useState } from 'react';
import { localStorageGetItem, localStorageSetItem } from '../../../../../utils/localStorage';
import { getNotifications } from '../../../../../services/api/notifications';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { ROUTES } from '../../../../../constants/routes';

const useGetData = () => {
  const [data, setData] = useState<NotificationBackend[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedOrganization = useSelectedOrganization();

  const currentUrl = window.location.href;

  const intervalSeconds = 20 * 60; // 20 minutes;

  const fetch = async () => {
    setLoading(true);
    const response = await getNotifications();
    setLoading(false);

    if (!response) return [];

    const notificationsSessionStorage: NotificationBackend[] =
      localStorageGetItem('notifications')?.filter(
        (elem: NotificationBackend) =>
          elem.message.params.notification.organization_id === selectedOrganization?.id
      ) ?? [];

    // Group by response, by file_id and message.code
    const groupedData: NotificationBackend[] = response.reduce(
      (acc: NotificationBackend[], notification: NotificationBackend) => {
        const existingNotification = acc.find(
          (n) =>
            n.message.params.notification.file_id ===
              notification.message.params.notification.file_id &&
            n.message.params.notification?.message?.code ===
              notification.message.params.notification?.message?.code
        );

        if (existingNotification && notification.message.params.notification.message) {
          existingNotification.message.params.notification.message.count +=
            notification.message.params.notification.message.count;
          return acc;
        }

        return [...acc, notification];
      },
      []
    );

    const newNotifications = groupedData.filter(
      (notification) =>
        !notificationsSessionStorage?.find(
          (n) =>
            n.message.params.notification.file_id ===
              notification.message.params.notification.file_id &&
            n.message.params.notification?.message?.code ===
              notification.message.params.notification?.message?.code
        )
    );

    const groupedNotifications = [...newNotifications, ...notificationsSessionStorage];
    if (newNotifications.length > 0) {
      // Combine notifications from localStorage and new notifications and set it to localStorage
      localStorageSetItem('notifications', groupedNotifications);
    }

    setData(groupedNotifications);
  };

  useEffect(() => {
    if (!selectedOrganization?.id || loading) return;
    if (!currentUrl.includes(ROUTES.IMPROVE_DASHBOARD)) {
      return;
    }
    fetch();
  }, [currentUrl]);

  useEffect(() => {
    if (!selectedOrganization?.id || loading) return;
    // Check if we are in prod
    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') return;

    fetch();
    // Fetch data at intervals
    const intervalId = setInterval(fetch, intervalSeconds * 1000);
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [intervalSeconds]);

  return { data, setData };
};

export default useGetData;
