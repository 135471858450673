import moment from 'moment';
import { DatadisContract, FormDataDatadisContract } from '../../types/entities/datadisContract';
import apiFetch from '../../utils/apiFetch';

export const getDatadisCups = async (authorized_nif: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/supply_contracts/datadis/supply_points/${authorized_nif}`,
      null,
      {
        'x-organization-id': organization_id
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDatadisContractDetail = async (
  autorized_nif: string,
  cups: string,
  distributor_code: number,
  organization_id: string
) => {
  try {
    const response = await apiFetch(
      'GET',
      `/supply_contracts/datadis/contracts_details/${autorized_nif}/${distributor_code}/${cups}`,
      null,
      {
        'x-organization-id': organization_id
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getDatadisNifAuthorization = async (
  authorized_nif: string,
  organization_id: string
) => {
  try {
    const response = await apiFetch(
      'GET',
      `/supply_contracts/datadis/authorization/${authorized_nif}`,
      null,
      {
        'x-organization-id': organization_id
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const postDatadisConnection = async (nif: string, organization_id: string) => {
  try {
    const response = await apiFetch('POST', `/organization_identifiers/${nif}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type PostDatadisContract = {
  facility_id: string;
  supplier_id: string;
  cups: string;
  distributor_code: number;
  point_type: number;
  authorized_nif: string;
  start_date: string;
  end_date?: string;
  status: string;
};

export const postDatadisContract = async (
  formData: FormDataDatadisContract,
  contract: DatadisContract,
  organization_id: string
) => {
  try {
    const data: PostDatadisContract = {
      facility_id: formData.facility.id,
      supplier_id: formData.supplier.id,
      cups: formData.cups.id,
      distributor_code: contract.distributor_code,
      point_type: contract.point_type,
      authorized_nif: contract.authorized_nif,
      start_date: formData.startDate
        ? moment(formData.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : contract.start_date,
      end_date: formData.endDate
        ? moment(formData.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : contract.end_date,
      status: Boolean(formData?.endDate) || Boolean(contract?.end_date) ? 'inactive' : 'active'
    };

    const response = await apiFetch('POST', `/supply_contracts`, data, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteContract = async (idToDelete: string, nif: string) => {
  try {
    const response = await apiFetch('DELETE', `/supply_contracts/${nif}/${idToDelete}`, null, {
      // 'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const recalculateDatadisErrors = async (organization_id: string) => {
  try {
    const response = await apiFetch('PUT', `/supply_contracts`, null, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
