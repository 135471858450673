import { createContext, useContext } from 'react';
import { StepCarouselType } from '../../../types/stepCarousel';

type StepContextType<T extends string = string> = {
  steps: Array<StepCarouselType<T>> | ReadonlyArray<StepCarouselType<T>>;
  selectedStep?: StepCarouselType<T>;
  handleSelect: (id: T) => void;
};

type ProviderProps = StepContextType & {
  children: React.ReactNode;
};

const StepContext = createContext<StepContextType | null>(null);

export const StepProvider = ({ children, steps, handleSelect, selectedStep }: ProviderProps) => {
  return (
    <StepContext.Provider value={{ steps, handleSelect, selectedStep }}>
      {children}
    </StepContext.Provider>
  );
};

export const useStepContext = () => {
  const context = useContext(StepContext);

  if (!context) {
    throw new Error('useStepContext must be used within a StepProvider');
  }

  return context;
};
