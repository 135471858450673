import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import PoweredByDcycle from '../../../layout/poweredByDcycle/PoweredByDcycle';
type Props = {
  children: ReactNode;
  org_name: string;
  backgroundImage?: string;
  lookAndFeel?: 'dark' | 'white';
  newPage?: boolean;
  page: number;
  total: number;
};

const Layout = ({
  org_name,
  backgroundImage,
  lookAndFeel = 'white',
  newPage = true,
  page,
  total,
  children
}: Props) => {
  const { t } = useTranslation();

  let background = 'white';
  if (lookAndFeel === 'dark') {
    background = 'linear-gradient(180deg, #000000 0%, rgba(25, 42, 62, 0.8) 100%)';
  }
  if (backgroundImage) {
    background = `url("${backgroundImage}")`;
  }

  const darkStyle = lookAndFeel === 'dark';

  const renderFooter = () => {
    // if partner is dcycle render links, if not render powered by Dcycle
    if (process.env.REACT_APP_PARTNER === 'dcycle') {
      return (
        <div className='report-footer'>
          <div className={`${darkStyle ? 'tag-white' : 'tag-black'} body3-font`}>dcycle.io</div>
          <div
            className={`${
              darkStyle ? 'tag-transparent-black' : 'tag-transparent-white'
            } body3-font`}>
            app.dcycle.io
          </div>
          <span className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}>
            {t('ghgReport.page', { page, total })}
          </span>
        </div>
      );
    } else {
      return <PoweredByDcycle darkStyle={!!darkStyle} />;
    }
  };

  const renderSocialLinks = () => {
    if (process.env.REACT_APP_PARTNER === 'dcycle') {
      return (
        <div className='report-header-social'>
          <a
            href='https://dcycle.io/blog'
            target='_blank'
            className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}
            rel='noreferrer'>
            {t('report.blog')}
          </a>
          <a
            href='https://dcycle.io/product'
            target='_blank'
            className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}
            rel='noreferrer'>
            {t('report.functionalities')}
          </a>
          <a
            href='https://dcycle.io/customer'
            target='_blank'
            className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}
            rel='noreferrer'>
            {t('report.news')}
          </a>
        </div>
      );
    }
    return null;
  };

  return (
    <section
      className={`${newPage ? 'page-break' : ''}`}
      style={{ background: background, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      {org_name ? (
        <div className='report-header'>
          {darkStyle ? (
            <img className='logo' src='/images/report/logo.svg' alt='logo' width={50} height={16} />
          ) : (
            <img
              className='logo'
              src='/images/report/logoBlack.svg'
              alt='logo'
              width={50}
              height={16}
            />
          )}
          <span className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}>x</span>
          <span className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}>{org_name}</span>
        </div>
      ) : (
        renderSocialLinks()
      )}
      {children}
      {renderFooter()}
    </section>
  );
};

export default Layout;
