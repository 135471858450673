import i18next from 'i18next';
import moment from 'moment';
import { Percentages } from '../../../../../../../../types/entities/invoice';

export const validateDates = (startDate: string, endDate: string) => {
  const startDateMoment = moment(startDate, 'DD/MM/YYYY');
  const endDateMoment = moment(endDate, 'DD/MM/YYYY');
  const days = endDateMoment.diff(startDateMoment, 'days');
  if (days > 730) {
    return { error: 'date', description: i18next.t('facilityDetail.dateIncorrect') };
  }
  return null;
};

export const validateFacilityWithZero = (percentages: Percentages[]) => {
  // check if in percentages there is a facility with 0 percentage
  const foundFacilityWithZero = percentages.find(
    (elem) => !Number(elem.percentage) && elem.facility.id
  );
  if (foundFacilityWithZero) {
    return {
      error: 'percentages_facility',
      description: i18next.t('facilityDetail.selectAPercentage')
    };
  }
};

export const validateFacilityWithoutId = (percentages: Percentages[]) => {
  // check if in percentages there is a percentage without facility id
  const foundFacilityWithoutId = percentages.find((elem) => !elem.facility.id);
  if (foundFacilityWithoutId) {
    return {
      error: 'percentages_facility',
      description: i18next.t('facilityDetail.selectAFacility')
    };
  }
};
