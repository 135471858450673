import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { ROUTES } from '../../../../constants/routes';
import { NotificationContext } from '../../../../context/notificationContext';
import { UserContext } from '../../../../context/userContext';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { IGetBackendBusinessTravel } from '../../../../types/entities/businessTravels';
import { CarSize, TransportOptions } from '../../../../types/entitiesEnums/employeePeriod';
import { Status } from '../../../../types/utilsEnums/status';
import { formatDate } from '../../../../utils/formatDate';
import { limitString } from '../../../../utils/limitString';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import InfiniteList from '../../../ui/infiniteList/InfiniteList';
import Modal from '../../../ui/modal/Modal';
import ErrorLabel from '../../../ui/statusLabels/errorLabel/ErrorLabel';
import PendingLabel from '../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../ui/statusLabels/successLabel/SuccessLabel';
import { CategoriesUploadedFiles } from '../../uploadedFiles/constants';
import SendEmailForm from '../sendEmailForm/SendEmailForm';
import AddBusinessTravelWrapper from './addBusinessTravel/AddBusinessTravelWrapper';
import AddBusinessTravelWrapperBeforeNuvo from './addBusinessTravel/AddBusinessTravelWrapperBeforeNuvo';
import InputNuvo from './components/inputNuvo/InputNuvo';
import DeleteBusinessTravel from './deleteBusinessTravel/DeleteBusinessTravel';
import EditBusinessTravel from './editBusinessTravel/EditBusinessTravel';
import './styles.scss';
import TooltipWrapper from '../../../ui/tooltip/TooltipWrapper';
import formatNumber from '../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';

function BusinessTravels() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const setNotification = useContext(NotificationContext);

  const dispatch = useDispatch();
  const flags = useFeatureFlags();

  const [businessTravels, setBusinessTravels] = useState<IGetBackendBusinessTravel[]>([]);
  const [businessTravelToEdit, setBusinessTravelToEdit] =
    useState<IGetBackendBusinessTravel | null>();
  const [businessTravelToDelete, setBusinessTravelToDelete] = useState('');
  const [showAddBusinessTravel, setShowAddBusinessTravel] = useState(false);
  const [showSendCustomForm, setShowSendCustomForm] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalLimit, setTotalLimit] = useState(0);

  const url = '/business_travels';

  const renderDropdownComponent = (businessTravel: IGetBackendBusinessTravel) => {
    const options = [
      {
        id: `${businessTravel.id}-delete`,
        name: t('businessTravels.delete'),
        onClick: () => {
          setBusinessTravelToDelete(businessTravel.id);
        }
      }
    ];

    if (businessTravel.transport_type) {
      options.push({
        id: `${businessTravel.id}-edit`,
        name: t('businessTravels.edit'),
        onClick: () => {
          setBusinessTravelToEdit(businessTravel);
        }
      });
    }

    if (businessTravel.file_name) {
      options.push({
        id: `${businessTravel.id}-goToFile`,
        name: t('businessTravels.goToFile'),
        onClick: () => {
          navigate(
            getUrl(`${ROUTES.MEASURE_UPLOADED_FILES}/${CategoriesUploadedFiles.BUSINESS_TRAVELS}`, {
              queryParams: generateQueryParamsFromObject({
                name: businessTravel.file_name ?? ''
              })
            })
          );
        }
      });
    }
    return <ButtonDropdown options={options} />;
  };

  const renderNameComponent = (name: string) => {
    let nameParsed = name;
    if (nameParsed === 'file_processing') {
      nameParsed = t(`businessTravels.${nameParsed}`);
    }
    return (
      <div className='icon-text-wrapper'>
        <img src='/images/icons/planeGray.svg' alt='aircraft' />
        <span>{nameParsed ? limitString(nameParsed, 15) : '-'}</span>
      </div>
    );
  };

  const renderBusinessTravelType = (businessTravelType?: string) => {
    if (!businessTravelType) return '-';
    return businessTravelType === 'one_way'
      ? t('businessTravels.oneWay')
      : t(`businessTravels.${businessTravelType}`);
  };

  const handleSetTotal = (total: number, modifyLimit = true) => {
    if (modifyLimit) setTotalLimit(total);
    setTotal(total);
  };

  const onCloseModal = () => {
    setShowAddBusinessTravel(false);
    setShowSendCustomForm(false);
    setBusinessTravelToEdit(null);
    setBusinessTravelToDelete('');
  };

  const handleShowAddBusinessTravelModal = () => {
    setShowAddBusinessTravel(true);
  };

  const handleShowSendCustomForm = () => {
    setShowSendCustomForm(true);
  };

  const addBusinessTravel = (businessTravel: IGetBackendBusinessTravel) => {
    const oldBusinessTravels = [...businessTravels];

    oldBusinessTravels.unshift({
      ...businessTravel,
      edit: renderDropdownComponent(businessTravel)
    });

    setBusinessTravels(oldBusinessTravels);
    onCloseModal();
    dispatch(setNotification(t('notification.createBusinessTravel')));
    handleSetTotal(total + 1);
  };

  const editBusinessTravel = (value: IGetBackendBusinessTravel) => {
    const newBusinessTravels = businessTravels ? [...businessTravels] : [];
    const indexBusinessTravelFound = newBusinessTravels.findIndex((elem) => elem.id === value.id);
    if (indexBusinessTravelFound > -1) {
      newBusinessTravels[indexBusinessTravelFound] = value;
    }

    setBusinessTravels(newBusinessTravels);
    dispatch(setNotification(t('notification.editBusinessTravel')));

    setBusinessTravelToEdit(null);
  };

  const removeBusinessTravel = (id: string) => {
    const newBusinessTravels = businessTravels ? [...businessTravels] : [];
    const indexBusinessTravelFound = newBusinessTravels.findIndex((elem) => elem.id === id);
    if (indexBusinessTravelFound > -1) {
      newBusinessTravels.splice(indexBusinessTravelFound, 1);
    }

    setBusinessTravels(newBusinessTravels);
    dispatch(setNotification(t('notification.deleteBusinessTravel')));
    setBusinessTravelToDelete('');
    handleSetTotal(total - 1);
  };

  const addBusinessTrips = (businessTrips: IGetBackendBusinessTravel[]) => {
    const oldBusinessTravels = [...businessTravels];

    businessTrips.forEach((elem) => {
      oldBusinessTravels.unshift({
        id: elem.id,
        email: elem.email,
        start_date: elem.start_date,
        end_date: elem.end_date,
        status: elem.status,
        source: elem.source,
        distance_km: elem.distance_km
      });
    });

    setBusinessTravels(oldBusinessTravels);
  };

  const columns = [
    {
      title: t('businessTravels.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('businessTravels.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('businessTravels.transport'),
      dataIndex: 'transport_type',
      key: 'transport_type'
    },
    {
      title: t('businessTravels.travel_type'),
      dataIndex: 'travel_type',
      key: 'travel_type'
    },
    {
      title: t('businessTravels.origin'),
      dataIndex: 'origin',
      key: 'origin'
    },
    {
      title: t('businessTravels.destination'),
      dataIndex: 'destination',
      key: 'destination'
    },
    {
      title: t('businessTravels.distance_km'),
      dataIndex: 'distance_km',
      key: 'distance_km'
    },
    {
      title: t('businessTravels.travelNumber'),
      dataIndex: 'travel_number',
      key: 'travel_number'
    },
    {
      title: t('businessTravels.date'),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('businessTravels.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: <div style={{ textAlign: 'right' }}>CO₂ eq</div>,
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];

  const renderTransportType = (value: IGetBackendBusinessTravel) => {
    let transportType = value.transport_type;
    if (value.transport_type === TransportOptions.CAR && value.vehicle_size === CarSize.SMALL) {
      transportType = TransportOptions.CAR_SMALL;
    } else if (
      value.transport_type === TransportOptions.CAR &&
      value.vehicle_size === CarSize.MEDIUM
    ) {
      transportType = TransportOptions.CAR_MEDIUM;
    } else if (
      value.transport_type === TransportOptions.CAR &&
      value.vehicle_size === CarSize.LARGE
    ) {
      transportType = TransportOptions.CAR_LARGE;
    }
    return t(`businessTravels.${transportType}`);
  };

  const renderStatusTag = (status: string) => {
    switch (status) {
      case Status.ACTIVE:
        return <SuccessLabel key='active'>{t('general.completed')}</SuccessLabel>;
      case Status.LOADING:
        return <PendingLabel>{t('general.processing')}</PendingLabel>;
      case Status.ERROR:
        return <ErrorLabel>{t('general.withErrors')}</ErrorLabel>;
      default:
        return <></>;
    }
  };

  const parseData = (businessTravels: IGetBackendBusinessTravel[]) => {
    const data = businessTravels.map((businessTravel) => {
      return {
        ...businessTravel,
        email: businessTravel.email ? businessTravel.email : '-',
        name: renderNameComponent(businessTravel.name ?? '-'),
        origin: businessTravel.origin ? limitString(businessTravel.origin, 15) : '-',
        destination: businessTravel.destination ? limitString(businessTravel.destination, 15) : '-',
        transport_type: businessTravel.transport_type ? renderTransportType(businessTravel) : '-',
        travel_type: renderBusinessTravelType(businessTravel.travel_type),
        distance_km: businessTravel.distance_km ? businessTravel.distance_km : '-',
        travel_number: businessTravel.travel_number ? businessTravel.travel_number : '-',
        date:
          businessTravel.start_date && businessTravel.end_date
            ? `${formatDate(new Date(businessTravel.start_date))}-${formatDate(
                new Date(businessTravel.end_date)
              )}`
            : '-',
        status: !businessTravel.transport_type ? (
          <PendingLabel>{t('general.loading')}</PendingLabel>
        ) : (
          renderStatusTag(businessTravel.status)
        ),
        edit: renderDropdownComponent(businessTravel),
        co2e:
          businessTravel.status === 'loading' && businessTravel.co2e === undefined ? (
            <TooltipWrapper
              text={t('facilityDetail.co2eTooltip')}
              style={{ width: '100%', textAlign: 'right' }}>
              <span
                className='highlight-text-color'
                style={{ fontWeight: 600, textAlign: 'right' }}>
                -
              </span>
            </TooltipWrapper>
          ) : (
            <span
              className='highlight-text-color'
              style={{ fontWeight: 600, textAlign: 'right', display: 'block' }}>
              {formatNumber(numberToDecimalNonZero(businessTravel.co2e ?? 0))} kg
            </span>
          ),

        disabled:
          (businessTravel.email && !businessTravel.origin) || businessTravel.status === 'loading'
            ? true
            : false
      };
    });
    return data;
  };

  const NuvoBTN =
    foundOrganization && totalLimit >= foundOrganization.limit_business_travels ? (
      <Button lookAndFeel='blocked' text={t('businessTravels.uploadAuto')} size='small' />
    ) : (
      <InputNuvo />
    );

  return (
    <section className='business-travels'>
      <div className='business-travels__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('businessTravels.title')}</h3>
        <Breadcrumb />
      </div>
      <div className='business-travels__body main-bg-color solid-border '>
        <div className='business-travels-card main-bg-color solid-border '>
          <h1 className='headline4-font'>{t('businessTravels.start')}</h1>
          {flags?.nuvo ? (
            <p className='subtitle3-font'>
              {reactStringReplace(t('businessTravels.startDescriptionNuvo'), '{{form}}', () => (
                <span className='highlight-text-color pointer' onClick={handleShowSendCustomForm}>
                  {t('businessTravels.form')}
                </span>
              ))}
            </p>
          ) : (
            <p className='subtitle3-font'>{t('businessTravels.startDescription')}</p>
          )}
          {businessTravels && (
            <div className='buttons'>
              {!flags?.nuvo && (
                <Button
                  lookAndFeel={
                    foundOrganization && totalLimit >= foundOrganization.limit_business_travels
                      ? 'blocked'
                      : 'primary'
                  }
                  text={t('businessTravels.sendByEmail')}
                  size='small'
                  onClick={handleShowSendCustomForm}
                />
              )}
              {flags?.nuvo && NuvoBTN}

              <Button
                lookAndFeel={
                  foundOrganization && totalLimit >= foundOrganization.limit_business_travels
                    ? 'blocked'
                    : 'secondary'
                }
                text={t('businessTravels.addManual')}
                size='small'
                onClick={handleShowAddBusinessTravelModal}
              />
            </div>
          )}
        </div>
      </div>
      <InfiniteList
        i18key={'businessTravels'}
        url={url}
        values={businessTravels}
        setValues={setBusinessTravels}
        columns={columns}
        parseData={parseData}
        organization={user?.selectedOrganization}
        total={total}
        setTotal={handleSetTotal}
        filters={{
          inputTextKey: 'description',
          date: true,
          options: [
            { id: 'car', name: t('businessTravels.car') },
            { id: 'train', name: t('businessTravels.train') },
            { id: 'trolleybus', name: t('businessTravels.trolleybus') },
            { id: 'bus', name: t('businessTravels.bus') },
            { id: 'aircraft', name: t('businessTravels.aircraft') },
            { id: 'motorbike', name: t('businessTravels.motorbike') }
          ]
        }}
      />
      <Modal show={showAddBusinessTravel} onClose={onCloseModal} width='600px' maxWidth='600px'>
        {flags?.nuvo ? (
          <AddBusinessTravelWrapper addBusinessTravel={addBusinessTravel} />
        ) : (
          <AddBusinessTravelWrapperBeforeNuvo addBusinessTravel={addBusinessTravel} />
        )}
      </Modal>
      <Modal show={showSendCustomForm} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <SendEmailForm
          close={onCloseModal}
          type='businessTravel'
          addBusinessTrips={addBusinessTrips}
        />
      </Modal>
      <Modal show={!!businessTravelToEdit} onClose={onCloseModal} width='600px' maxWidth='600px'>
        {businessTravelToEdit && (
          <EditBusinessTravel
            businessTravelToEdit={businessTravelToEdit}
            editBusinessTravel={editBusinessTravel}
          />
        )}
      </Modal>
      <Modal show={!!businessTravelToDelete} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <DeleteBusinessTravel
          removeBusinessTravel={removeBusinessTravel}
          businessTravelToDelete={businessTravelToDelete}
        />
      </Modal>
    </section>
  );
}

export default BusinessTravels;
