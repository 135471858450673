import { useQuery } from '@tanstack/react-query';
import useSelectedOrganization from '../useSelectedOrganization';
import { getMITECOOrgEf } from '../../services/api/orgEf';

export const useMITECOOrgEf = () => {
  const org = useSelectedOrganization();

  const { data, isFetching } = useQuery({
    queryKey: ['orgEf', org?.id],
    queryFn: getMITECOOrgEf(org?.id)
  });

  return { data, isFetching };
};
