import { useSearchParams } from 'react-router-dom';
import { PrimaryCodeManagement } from './primary/PrimaryCodeManagement';
import { SecondaryCodeManagement } from './secondary';
import { Layout } from './Layout';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../ui/stepCarouselList/useStepCarousel';

const STEPS = [
  {
    id: 'primary',
    text: 'codeManagement.primary'
  },
  {
    id: 'secondary',
    text: 'codeManagement.secondary'
  }
];

export const CodeManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    type: 'primary'
  });

  const { steps } = useStepCarousel({
    stepsText: STEPS,
    selected: searchParams.get('type') ?? 'primary'
  });

  const Section =
    searchParams.get('type') === 'secondary' ? SecondaryCodeManagement : PrimaryCodeManagement;

  const handleSelect = (id: string) => {
    setSearchParams({ type: id });
  };

  return (
    <Layout>
      <StepCarouselList steps={steps} handleSelect={handleSelect} />
      <Section />
    </Layout>
  );
};
