import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../../../context/userContext';
import controller from '../../../../../environments/controller';
import Button from '../../../../ui/button/Button';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import StepControllerContainer from '../../../../ui/stepPage/StepControllerContainer';
import AddBusinessTravel from '../addBusinessTravel/AddBusinessTravel';

import { adaptBusinessTravelFrontToBack } from '../../../../../adapters/businessTravel';
import useErrors from '../../../../../customHooks/useErrors';
import { postBusinessTravelForm } from '../../../../../services/api/businessTravels';
import {
  IFrontendFormBusinessTravel,
  IGetBackendBusinessTravel
} from '../../../../../types/entities/businessTravels';

import './styles.scss';
const environment = controller();

const BusinessTravelForm = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const { id } = useParams();
  const ERRORS = useErrors();

  const [businessTravels, setBusinessTravels] = useState<IGetBackendBusinessTravel[]>([]);
  const [isAlreadyCompleted, setIsAlreadyCompleted] = useState(false);
  const [maxTriesReached, setMaxTriesReached] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${environment.backendUrl}/webhook/business_travels/form/${id}`,
          {
            method: 'GET'
          }
        );
        const data = await response.json();

        setMaxTriesReached(data.max_tries_reached);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const post = async (formData: IFrontendFormBusinessTravel) => {
    if (!id) return;
    const backendBusinessTravel = adaptBusinessTravelFrontToBack(formData);

    const response = await postBusinessTravelForm({
      id,
      value: backendBusinessTravel
    });

    if (
      response?.response?.status === 422 &&
      response?.response?.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES'
    ) {
      setError(ERRORS.BUSINESS_TRAVEL.INVALID_TRAVEL_MODE_BETWEEN_PLACES);
      return;
    }
    return response;
  };

  const addBusinessTravel = (businessTravel: IGetBackendBusinessTravel) => {
    const oldBusinessTravels = [...businessTravels];

    oldBusinessTravels.push({
      ...businessTravel
    });

    setBusinessTravels(oldBusinessTravels);
    setIsAlreadyCompleted(true);
  };

  const handleCreateAnotherBusinessTravel = () => {
    setIsAlreadyCompleted(false);
  };

  if (loading) {
    return <LoaderTables />;
  }

  if (maxTriesReached) {
    return (
      <StepControllerContainer
        title={t('businessTravelForm.completed.title')}
        subtitle={t('businessTravelForm.completed.subtitle')}
      />
    );
  }

  return (
    <>
      {!isAlreadyCompleted ? (
        <StepControllerContainer
          title={t('businessTravelForm.title')}
          subtitle={t('businessTravelForm.subtitle')}>
          <div className='business-travel-form__container'>
            <AddBusinessTravel
              user={user}
              addBusinessTravel={addBusinessTravel}
              postBusinessTravel={post}
              error={error}
            />
          </div>
        </StepControllerContainer>
      ) : (
        <StepControllerContainer
          title={t('businessTravelFormFinished.title')}
          subtitle={t('businessTravelFormFinished.subtitle')}>
          <div className='business-travel-form__container'>
            <Button
              text={`${t('businessTravelFormFinished.addAnotherBusinessTravel')}`}
              lookAndFeel={'primary'}
              onClick={handleCreateAnotherBusinessTravel}
            />
          </div>
        </StepControllerContainer>
      )}
    </>
  );
};

export default BusinessTravelForm;
