import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/Footer';
import './styles.scss';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';

type Props = {
  children: ReactNode[] | ReactNode;
};

const Container = ({ children }: Props) => {
  const { t } = useTranslation();

  const selectedOrganization = useSelectedOrganization();

  return (
    <section className='organization-selector-container'>
      <img
        className='organization-selector-container__logo'
        src={selectedOrganization?.logo_url ?? '/images/logoBlack.svg'}
        alt='logo'
      />
      <div className='organization-selector-container__header'>
        <h1 className='headline1-font on-light-text-color'>{t('organizationSelector.title')}</h1>
        <h2 className='subtitle1-font on-light-text-color'>{t('organizationSelector.subtitle')}</h2>
      </div>
      <div className='content'>{children}</div>
      <Footer />
    </section>
  );
};

export default Container;
