import { FirstStep } from './Steps/FirstStep';
import { SecondStep } from './Steps/SecondStep';

export const STEP_IDS = {
  FIRST: 'FIRST',
  SECOND: 'SECOND'
} as const;

export const STEP_COMPONENTS = {
  [STEP_IDS.FIRST]: FirstStep,
  [STEP_IDS.SECOND]: SecondStep
} as const;

export const STEPS = [
  {
    id: STEP_IDS.FIRST,
    text: 'lcaDetail.processFlow.processModal.basicInfo'
  },
  {
    id: STEP_IDS.SECOND,
    text: 'lcaDetail.processFlow.processModal.inputs'
  }
] as const;
