import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UPDATE_ORGANIZATION_SUCCESS } from '../../actions/types';
import getPrefixes from '../../constants/phonePrefixes';
import { sectors } from '../../constants/sectors';
import { uploadCompanyLogo } from '../../services/api/organization';
import { Organization } from '../../types/entities/organization';
import getBase64 from '../../utils/getBase64';
import FormWrapper from '../ui/formComponents/formWrapper/FormWrapper';
import InputPhoto from '../ui/formComponents/inputPhoto/InputPhoto';
import FormText from '../ui/formComponents2/formInputs/formText/FormText';
type Props = {
  organization: Organization;
};

function OrganizationData({ organization }: Props) {
  const { t, i18n } = useTranslation();

  const { id, company_name, sector, vat, employee_count_signup, country, logo_url } = organization;

  let sectorParsed = '';
  const sectorFound = sectors.find((elem) => elem.id === sector) as {
    title: { en: string; es: string };
  };
  if (sectorFound) {
    sectorParsed = sectorFound.title[i18n.language.split('-')[0] as keyof Language];
  }

  const dispatch = useDispatch();
  const [file, setFile] = useState(logo_url ?? '');

  const handleUploadFile = async (fileUploaded: File) => {
    // Call api to upload image and dispatch action to update user in redux
    getBase64(fileUploaded, async (file_url: string | null | ArrayBuffer) => {
      if (!file_url) return;
      const fileUrl = await uploadCompanyLogo(id, {
        file: file_url as string,
        file_name: fileUploaded.name
      });

      // Dispatch action to update company logo in redux
      dispatch({
        type: UPDATE_ORGANIZATION_SUCCESS,
        payload: {
          id: id,
          logo_url: fileUrl
        }
      });

      setFile(fileUrl);
    });
  };

  return (
    <div className='card-profile-info main-bg-color solid-border'>
      <div className='card-profile-info__header page-header'>
        <h1 className='headline4-font'>{t('profile.orgInfo')}</h1>
      </div>
      <InputPhoto
        name={organization.company_name}
        lastName={''}
        handleFile={handleUploadFile}
        fileUrl={file}
      />
      <FormWrapper>
        <FormText
          icon={'/images/icons/briefcase.svg'}
          label={t('profile.company')}
          placeholder={''}
          value={company_name}
          onChange={function (e: ChangeEvent<HTMLInputElement>): void {
            console.log('Function not implemented.');
          }}
          disabled
        />
        <FormText
          icon={'/images/icons/piechart.svg'}
          label={t('profile.sector')}
          placeholder={''}
          value={sectorParsed}
          onChange={function (e: ChangeEvent<HTMLInputElement>): void {
            console.log('Function not implemented.');
          }}
          disabled
        />
        <FormText
          icon={'/images/icons/idcard.svg'}
          label={t('profile.vat')}
          placeholder={''}
          value={vat}
          onChange={function (e: ChangeEvent<HTMLInputElement>): void {
            console.log('Function not implemented.');
          }}
          disabled
        />
        <FormText
          icon={'/images/icons/people.svg'}
          label={t('profile.employees')}
          placeholder={''}
          value={employee_count_signup.toString() || '0'}
          onChange={function (e: ChangeEvent<HTMLInputElement>): void {
            console.log('Function not implemented.');
          }}
          disabled
        />
        <FormText
          icon={'/images/icons/pin.svg'}
          label={t('profile.country')}
          placeholder={''}
          value={
            getPrefixes(i18n.resolvedLanguage).find((prefix) => prefix.code === country)?.name ||
            country
          }
          onChange={function (e: ChangeEvent<HTMLInputElement>): void {
            console.log('Function not implemented.');
          }}
          disabled
        />
      </FormWrapper>
    </div>
  );
}

export default OrganizationData;
