import {
  FormSocialData,
  OptionalQuestionResponse,
  SocialData
} from '../../types/entities/socialData';
import apiFetch from '../../utils/apiFetch';
import makeRequestsInBatches from '../../utils/makeRequestInBatches';
import removeSpacesAndDotsFromFileName from '../../utils/removeSpacesAndDotsFromFileName';
import { uploadFilePresignedUrl } from './aws';

const parseQuestionResponse = (element: OptionalQuestionResponse) => {
  if (element.type === 'float') return Number(element.response);
  if (element.type === 'boolean') return (element.response as SelectOptionFormat).id === 'yes';
  if (element.type === 'float_double')
    return (element.response as string[]).map((value) => Number(value));
  return undefined;
};

const parseSocialDataBody = async (formData: FormSocialData) => {
  try {
    const fileUrls: {
      social: { id: string; file_name: string; file_url: string }[];
      environment: { id: string; file_name: string; file_url: string }[];
      pias: { id: string; file_name: string; file_url: string }[];
    } = {
      social: [],
      environment: [],
      pias: []
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uploadFileRequests: any[] = [];
    if (
      formData.socialLaboralEvidenceAttached &&
      formData.socialLaboralEvidenceAttached.length > 0
    ) {
      formData.socialLaboralEvidenceAttached.forEach((file) => {
        if (file.file_name) {
          uploadFileRequests.push(() =>
            apiFetch('POST', '/social_data/attachment/upload', {
              file_name: file.file_name
            })
          );
        }
      });
    }
    // Same with environment
    if (formData.environmentEvidenceAttached && formData.environmentEvidenceAttached.length > 0) {
      formData.environmentEvidenceAttached.forEach((file) => {
        if (file.file_name) {
          uploadFileRequests.push(() =>
            apiFetch('POST', '/social_data/attachment/upload', {
              file_name: file.file_name
            })
          );
        }
      });
    }
    // Same with pias
    if (
      formData.optionalQuestionsEvidenceAttached &&
      formData.optionalQuestionsEvidenceAttached.length > 0
    ) {
      formData.optionalQuestionsEvidenceAttached.forEach((file) => {
        if (file.file_name) {
          uploadFileRequests.push(() =>
            apiFetch('POST', '/social_data/attachment/upload', {
              file_name: file.file_name
            })
          );
        }
      });
    }

    const responses = await makeRequestsInBatches(uploadFileRequests);

    const requestsUploadPresignedUrl: any[] = [];

    responses.forEach((response, index) => {
      if (
        formData.socialLaboralEvidenceAttached &&
        formData.socialLaboralEvidenceAttached.length > 0
      ) {
        const foundByFileName = formData.socialLaboralEvidenceAttached.find((file) => {
          if (!file.file_name) return false;
          return removeSpacesAndDotsFromFileName(file.file_name) === response.data.file_name;
        });
        if (foundByFileName && foundByFileName.file_name) {
          fileUrls.social.push({
            id: response.data.file_id,
            file_name: foundByFileName.file_name,
            file_url: response.data.file_url
          });
          if (foundByFileName.file) {
            requestsUploadPresignedUrl.push(() =>
              uploadFilePresignedUrl(foundByFileName.file, response.data.file_url)
            );
          }
        }
      }
      if (formData.environmentEvidenceAttached && formData.environmentEvidenceAttached.length > 0) {
        const foundByFileName = formData.environmentEvidenceAttached.find((file) =>
          file.file_name
            ? removeSpacesAndDotsFromFileName(file.file_name) === response.data.file_name
            : false
        );
        if (foundByFileName && foundByFileName.file_name) {
          fileUrls.environment.push({
            id: response.data.file_id,
            file_name: foundByFileName.file_name,
            file_url: response.data.file_url
          });
          if (foundByFileName.file) {
            requestsUploadPresignedUrl.push(() =>
              uploadFilePresignedUrl(foundByFileName.file, response.data.file_url)
            );
          }
        }
      }
      if (
        formData.optionalQuestionsEvidenceAttached &&
        formData.optionalQuestionsEvidenceAttached.length > 0
      ) {
        const foundByFileName = formData.optionalQuestionsEvidenceAttached.find((file) =>
          file.file_name
            ? removeSpacesAndDotsFromFileName(file.file_name) === response.data.file_name
            : false
        );
        if (foundByFileName && foundByFileName.file_name) {
          fileUrls.pias.push({
            id: response.data.file_id,
            file_name: foundByFileName.file_name,
            file_url: response.data.file_url
          });
          if (foundByFileName.file) {
            requestsUploadPresignedUrl.push(() =>
              uploadFilePresignedUrl(foundByFileName.file, response.data.file_url)
            );
          }
        }
      }
    });

    await makeRequestsInBatches(requestsUploadPresignedUrl);

    const attachments: any = [];

    if (fileUrls.social && fileUrls.social.length > 0) {
      fileUrls.social.forEach((file) => {
        attachments.push({
          id: file.id,
          category: 'social',
          file_name: file.file_name,
          file_url: file.file_url
        });
      });
    }
    if (fileUrls.environment && fileUrls.environment.length > 0) {
      fileUrls.environment.forEach((file) => {
        attachments.push({
          id: file.id,
          category: 'environmental',
          file_name: file.file_name,
          file_url: file.file_url
        });
      });
    }
    if (fileUrls.pias && fileUrls.pias.length > 0) {
      fileUrls.pias.forEach((file) => {
        attachments.push({
          id: file.id,
          category: 'pias',
          file_name: file.file_name,
          file_url: file.file_url
        });
      });
    }

    if (formData.socialLaboralEvidenceComments) {
      const foundSocialIndex = attachments.findIndex(
        (attachment: { category: string }) => attachment.category === 'social'
      );
      if (foundSocialIndex !== -1) {
        attachments[foundSocialIndex].description = formData.socialLaboralEvidenceComments;
      } else {
        attachments.push({
          id: '',
          file_name: '',
          file_url: '',
          category: 'social',
          description: formData.socialLaboralEvidenceComments
        });
      }
    }
    if (formData.environmentEvidenceComments) {
      const foundSocialIndex = attachments.findIndex(
        (attachment: { category: string }) => attachment.category === 'environmental'
      );
      if (foundSocialIndex !== -1) {
        attachments[foundSocialIndex].description = formData.environmentEvidenceComments;
      } else {
        attachments.push({
          id: '',
          file_name: '',
          file_url: '',
          category: 'environmental',
          description: formData.environmentEvidenceComments
        });
      }
    }
    if (formData.optionalQuestionsEvidenceComments) {
      const foundSocialIndex = attachments.findIndex(
        (attachment: { category: string }) => attachment.category === 'pias'
      );
      if (foundSocialIndex !== -1) {
        attachments[foundSocialIndex].description = formData.optionalQuestionsEvidenceComments;
      } else {
        attachments.push({
          id: '',
          file_name: '',
          file_url: '',
          category: 'pias',
          description: formData.optionalQuestionsEvidenceComments
        });
      }
    }
    const data: SocialData = {
      year_of_data: Number(formData.year.id),
      global_compact_monitoring_protocol: formData.hasGlobalCompact.id === 'yes' ? true : false,
      global_compact_infringement: formData.isGlobalCompactViolated.id === 'yes' ? true : false,
      manufacture_and_sale_of_weapons: formData.isRelatedGuns.id === 'yes' ? true : false,
      company_annual_revenue: Number(formData.anualRevenue),
      company_annual_revenue_currency_unit_id: undefined,
      women_annual_mean_income: Number(formData.womanRevenue),
      men_annual_mean_income: Number(formData.manRevenue),
      number_of_women_in_the_board: Number(formData.numberWomanBoardDirectors),
      number_of_men_in_the_board: Number(formData.numberMenBoardDirectors),
      activities_in_biodiversity_hotspots: formData.biodiversityZone.id === 'yes' ? true : false,
      amount_of_discharges_water_bodies: Number(formData.quantityWaste),
      amount_of_discharges_water_bodies_unit_id: formData.unitWaste.id,
      nace_sector_code: formData.naceCode.id,
      attachments: attachments && attachments.length > 0 ? attachments : undefined,
      extra_questions:
        formData.optionalQuestions?.map((element) => ({
          id: element.id,
          question_response: parseQuestionResponse(element),
          value_type: element.type
        })) ?? []
    };
    return data;
  } catch (error) {
    return null;
  }
};

export const postSocialData = async (formData: FormSocialData) => {
  try {
    const data = await parseSocialDataBody(formData);
    if (!data) return null;

    const response = await apiFetch('POST', '/social_data', data);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const patchSocialData = async (id: string, formData: FormSocialData) => {
  try {
    const data = await parseSocialDataBody(formData);
    if (!data) return null;

    const response = await apiFetch('PATCH', `/social_data/${id}`, data);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSocialData = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `/social_data/${id}`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSocialData = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/social_data/${id}`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getOptionalQuestionsSocialAndGovernance = async () => {
  try {
    const response = await apiFetch('GET', `/social_data/pai_extra_questions`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSocialDataList = async (page: number, size: number) => {
  try {
    const response = await apiFetch(
      'GET',
      '/social_data/list_by_organization',
      null,
      {},
      {
        page,
        size
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};
