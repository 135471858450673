import { useTranslation } from 'react-i18next';
import { TransportOptions } from '../../../../../types/entitiesEnums/employeePeriod';

const useTransportOptions = () => {
  const { t } = useTranslation();
  const blackListTransportOptions = [
    TransportOptions.AIRCRAFT,
    TransportOptions.TELECOMMUTING,
    TransportOptions.CAR
  ];
  // generate transport options from TransportOptions enum
  const transportOptions = Object.values(TransportOptions)
    .filter((value) => !blackListTransportOptions.includes(value))
    .map((transportOption) => ({
      id: transportOption,
      name: t(`employees.${transportOption}`)
    }));

  return transportOptions;
};

export default useTransportOptions;
