import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';
import './styles.scss';

const Footer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const user = useContext(UserContext);

  const handleRedirectToRegister = () => {
    navigate(`${ROUTES.REGISTER_COMPANY}/${user?.id}`);
  };

  return (
    <div className='span-font footer'>
      {t('organizationSelector.footer')}{' '}
      <span onClick={handleRedirectToRegister} className='create highlight-text-color'>
        {t('organizationSelector.create')}
      </span>
    </div>
  );
};

export default Footer;
