import FormElementShort from '../../../../../ui/formComponents/formElementShort/FormElementShort';
import InputMultiple from '../../../../../ui/formComponents/inputMultiple/InputMultiple';
import { useTranslation } from 'react-i18next';
import InputText from '../../../../../ui/formComponents/inputText/InputText';
import InputNumber from '../../../../../ui/formComponents/inputNumber/InputNumber';

type Props = {
  onChangeValue: (value: string) => void;
  value: string;
};
const InputBreakfast = ({ onChangeValue, value }: Props) => {
  const { t } = useTranslation();

  return (
    <FormElementShort>
      <InputMultiple
        inputs={[
          {
            input: (
              <InputText
                icon={'/images/icons/coffeeToGo.svg'}
                placeholder={''}
                value={t('eventData.breakfast')}
              />
            ),
            label: t('eventData.typeUnits'),
            tooltip: t('eventData.breakfastTooltip')
          },
          {
            input: (
              <InputNumber
                icon={''}
                placeholder={'0'}
                value={value}
                onChangeValue={onChangeValue}
              />
            ),
            label: t('eventData.dishes')
          }
        ]}
      />
    </FormElementShort>
  );
};

export default InputBreakfast;
