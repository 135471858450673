export enum PurchaseFramework {
  SIC = 'us_sic_v87',
  CNAE = 'cnae_2009',
  NACE = 'nace_rev_2'
}
const PURCHASE_FRAMEWORK_LITERAL: Record<string, string> = {
  [PurchaseFramework.SIC]: 'SIC (US 1987)',
  [PurchaseFramework.CNAE]: 'CNAE (2009)',
  [PurchaseFramework.NACE]: 'NACE (Rev. 2)'
};

export const mapToPurchaseFramework = (purchaseFramework: string) => {
  if (!purchaseFramework || !PURCHASE_FRAMEWORK_LITERAL[purchaseFramework]) {
    return '';
  }

  return PURCHASE_FRAMEWORK_LITERAL[purchaseFramework];
};

export const PURCHASE_FRAMEWORK_OPTIONS = Object.values(PurchaseFramework)
  .filter((framework) => framework !== PurchaseFramework.NACE)
  .map((id) => ({
    id,
    name: mapToPurchaseFramework(id)
  }));
