import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { SIZE_PAGINATION_BIG } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import usePaginatedList from '../../../customHooks/paginatedList';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { numberToDecimalNonZero } from '../../../utils/numberToDecimal';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import CardList from '../../ui/cardList/CardListRefactored';
import Card from '../../ui/cards/card/Card';
import Icon from '../../ui/icon/Icon';
import Label from '../../ui/label/Label';
import Modal from '../../ui/modal/Modal';
import ErrorLabel from '../../ui/statusLabels/errorLabel/ErrorLabel';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import TotalLegend from '../../ui/totalLegend/TotalLegend';
import CreateHub from './components/createHub/CreateHub';
import DeleteHub from './components/deleteHub/DeleteHub';
import EditHub from './components/editHub/EditHub';
import HubData from './components/hubData/HubData';
import { SfcLogo } from './components/sfcLogo/SfcLogo';
import './styles.scss';

const Hubs = () => {
  const { t } = useTranslation();
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [showCreate, setShowCreate] = useState(false);
  const [hubToEdit, setHubToEdit] = useState<IBackendHub>();
  const [showHubData, setShowHubData] = useState<IBackendHub>();
  const [hubToDelete, setHubToDelete] = useState<string>();

  const size = SIZE_PAGINATION_BIG;
  const url = '/logistic_hubs';

  const selectedOrganization = useSelectedOrganization();

  // fetchPagination
  const { fetchData, fetchMoreData, hasMore, values, setValues, total, setTotal } =
    usePaginatedList({
      url,
      listSize: size,
      setLoading,
      organization: selectedOrganization?.id
    });

  useEffect(() => {
    if (dateError) return;
    fetchData();
  }, [startDate, endDate]);

  const onClickCard = (id: string) => {
    navigate(`${ROUTES.MEASURE_HUBS}/${id}`);
  };

  const handleAddHub = (value: IBackendHub) => {
    setValues((prev: IBackendHub[]) => [value, ...prev]);
    setTotal((prev: number) => prev + 1);
    setShowCreate(false);
  };

  const handleEditHub = (value: IBackendHub) => {
    setValues((prev: IBackendHub[]) =>
      prev.map((elem: IBackendHub) => (elem.id === value.id ? value : elem))
    );
    setHubToEdit(undefined);
  };

  const handleDeleteHub = (id: string) => {
    setValues((prev: IBackendHub[]) => prev.filter((elem: IBackendHub) => elem.id !== id));
  };

  return (
    <section className='hubs'>
      <SectionHeader title={t('hubs.title')} breadcrumb={<Breadcrumb />} />
      <div className='hubs__subheader gap-x-4'>
        <SfcLogo />
        <div className='main-bg-color card-border-color legend-wrapper'>
          <TotalLegend total={total} loading={loading} i18key={'hubs'} />
        </div>
      </div>
      <div id={'hubs'} className='infinite-scroll-wrapper-card'>
        <InfiniteScroll
          dataLength={values ? values.length : 0}
          next={fetchMoreData}
          hasMore={hasMore}
          style={{ overflow: 'visible' }}
          endMessage={<></>}
          loader={undefined}
          scrollableTarget={'hubs'}>
          <CardList
            listView={false}
            title={t('hubs.newHub')}
            description={t('hubs.newHubDescription')}
            buttons={
              <Button
                lookAndFeel={
                  !!selectedOrganization && total >= selectedOrganization?.limit_facilities
                    ? 'blocked'
                    : 'primary'
                }
                text={t('hubs.create')}
                onClick={() => setShowCreate(true)}
                size='small'
              />
            }
            loading={loading}>
            {values
              .sort((a: IBackendHub, b: IBackendHub) => {
                // second the ones of element type "owned"
                if (a.type === 'owned' && b.type !== 'owned') return 0;
                // first the ones with status === "error"
                if (a.status === 'error' && b.status !== 'error') return -1;
                // third the ones of element type "subcontracted"
                if (a.type === 'subcontracted' && b.type !== 'subcontracted') return 1;

                return 0;
              })
              .map((elem: IBackendHub) => {
                if (elem.status === 'error') {
                  return (
                    <Card key={elem.facility_id ?? elem.id}>
                      <Card.Content>
                        <span className='headline4-font'>{elem.name}</span>
                        <TooltipWrapper text={t('hubs.errorTooltip')} size='small' position='top'>
                          <ErrorLabel lookAndFeel='error'>{t('hubs.error')}</ErrorLabel>
                        </TooltipWrapper>
                        <Button
                          text={t('hubs.create')}
                          lookAndFeel='primary'
                          onClick={() => setHubToEdit(elem)}
                          size='small'
                        />
                      </Card.Content>
                    </Card>
                  );
                }
                if (elem.type === 'owned') {
                  return (
                    <Card
                      key={elem.id}
                      listView={false}
                      onClick={() => onClickCard(elem?.facility_id)}>
                      <Card.Options>
                        <ButtonDropdown
                          button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                          options={[
                            {
                              id: 'edit',
                              name: t('hubs.edit'),
                              onClick: () => setHubToEdit(elem)
                            },
                            {
                              id: 'delete',
                              name: t('hubs.delete'),
                              onClick: () => setHubToDelete(elem.id)
                            }
                          ]}
                        />
                      </Card.Options>
                      <Card.Content>
                        <span className='headline4-font'>{elem.name}</span>
                        <span className='subtitle3-font'>
                          {elem.category
                            ? t(`logisticHocs.${elem.category}`)
                            : t(`logisticHocs.${elem.hub_category}`)}
                        </span>
                        <Card.Total
                          total={numberToDecimalNonZero(elem.co2e / 1000, 4)}
                          unit={'t CO₂ eq.'}
                        />
                      </Card.Content>
                    </Card>
                  );
                }

                return (
                  <Card key={elem.id} listView={false}>
                    <Card.Options>
                      <ButtonDropdown
                        button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                        options={[
                          {
                            id: 'info',
                            name: t('hubs.info'),
                            onClick: () => setShowHubData(elem)
                          },
                          {
                            id: 'edit',
                            name: t('hubs.edit'),
                            onClick: () => setHubToEdit(elem)
                          },
                          {
                            id: 'delete',
                            name: t('hubs.delete'),
                            onClick: () => setHubToDelete(elem.id)
                          }
                        ]}
                      />
                    </Card.Options>
                    <Card.ContentDisabled>
                      <span className='headline4-font'>{elem.name}</span>
                      <span className='subtitle3-font'>{t(`logisticHocs.${elem.category}`)}</span>
                      <Label lookAndFeel='disabled'>{t('hubs.subcontracted')}</Label>
                    </Card.ContentDisabled>
                  </Card>
                );
              })}
          </CardList>
        </InfiniteScroll>
      </div>
      <Modal show={showCreate} onClose={() => setShowCreate(false)} width='550px' maxWidth='550px'>
        <CreateHub handleAddHub={handleAddHub} />
      </Modal>
      <Modal
        show={!!hubToEdit}
        onClose={() => setHubToEdit(undefined)}
        width='550px'
        maxWidth='550px'>
        {hubToEdit && <EditHub hubToEdit={hubToEdit} handleEditHub={handleEditHub} />}
      </Modal>
      <Modal
        show={!!showHubData}
        onClose={() => setShowHubData(undefined)}
        width='550px'
        maxWidth='550px'>
        {showHubData && <HubData data={showHubData} onClose={() => setShowHubData(undefined)} />}
      </Modal>
      <Modal
        show={!!hubToDelete}
        onClose={() => setHubToDelete(undefined)}
        width='550px'
        maxWidth='550px'>
        {hubToDelete && (
          <DeleteHub
            handleDelete={handleDeleteHub}
            onClose={() => setHubToDelete(undefined)}
            id={hubToDelete}
          />
        )}
      </Modal>
    </section>
  );
};

export default Hubs;
