import {
  ILCASoldProductBack,
  ILCASoldProductFront,
  ILCASoldProductPostBack,
  IUseAndEndOfLifeBack
} from '../types/entities/useAndEndOfLife';

const useLcaSoldProductAdapter = () => {
  const adpatBack2Front = (data: ILCASoldProductBack): ILCASoldProductFront => {
    return {
      id: data.id,
      lcaId: {
        id: data.lca.id,
        name: data.lca.name
      },
      productCode: {
        id: data.lca_project_data.product_code,
        name: data.lca_project_data.product_name
      },
      productionYear: data.production_year
        ? {
            id: String(data.production_year),
            name: String(data.production_year)
          }
        : {
            id: '',
            name: ''
          },
      productionQuantity: data.production_quantity ? String(data.production_quantity) : '',
      type: data.type,
      errors: []
    };
  };

  const adpatFront2Back = (data: ILCASoldProductFront): ILCASoldProductPostBack => ({
    id: data.id,
    lca_id: data.lcaId.id,
    product_code: data.productCode.id,
    production_year: data.productionYear ? Number(data.productionYear.id) : undefined,
    production_quantity: data.productionQuantity ? Number(data.productionQuantity) : undefined,
    type: data.type
  });

  const adaptLcaSoldProductBack2LcaBack = (data: ILCASoldProductBack): IUseAndEndOfLifeBack => ({
    id: data.id || '',
    product_name: data.lca_project_data.product_name,
    lca_name: data.lca.name,
    lca_id: data.lca.id,
    type: 'final',
    lifespan_years: undefined,
    production_year: data.production_year,
    production_quantity: data.production_quantity,
    intermediate_processing_co2e: undefined,
    usage_co2e: undefined,
    waste_co2e: undefined
  });

  return {
    adpatBack2Front,
    adpatFront2Back,
    adaptLcaSoldProductBack2LcaBack
  };
};

export default useLcaSoldProductAdapter;
