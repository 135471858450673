type Props = {
  category: string;
};
const useSteps = ({ category }: Props) => {
  const stepsEnvironmental = [
    {
      id: 'climate_change',
      text: 'esgMetrics.environmental.climate_change',
      isSelected: true
    },
    {
      id: 'pollution',
      text: 'esgMetrics.environmental.pollution',
      isSelected: false
    },
    {
      id: 'water_and_marine_resources',
      text: 'esgMetrics.environmental.water_and_marine_resources',
      isSelected: false
    },
    {
      id: 'biodiversity_and_ecosystems',
      text: 'esgMetrics.environmental.biodiversity_and_ecosystems',
      isSelected: false
    },
    {
      id: 'resource_use_and_circular_economy',
      text: 'esgMetrics.environmental.resources_and_circular_economy',
      isSelected: false
    }
  ];

  const stepsSocial = [
    {
      id: 'workers_in_the_value_chain',
      text: 'esgMetrics.social.workers_in_the_value_chain',
      isSelected: false
    },

    {
      id: 'consumers_and_end-users',
      text: 'esgMetrics.social.consumers_and_end-users',
      isSelected: false
    },

    {
      id: 'affected_communities',
      text: 'esgMetrics.social.affected_communities',
      isSelected: false
    },
    {
      id: 'own_workforce',
      text: 'esgMetrics.social.own_workforce',
      isSelected: false
    }
  ];

  switch (category) {
    case 'environmental':
      return stepsEnvironmental;
    case 'social':
      return stepsSocial;
    default:
      return [];
  }
};

export default useSteps;
