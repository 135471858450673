import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/userContext';
import { getAvoidedEmission } from '../../../../../../services/api/avoidedEmissions';
import { AvoidedEmissionListElement } from '../../../../../../types/entities/avoidedEmission';

type Props = {
  id: string;
};

const useGetAvoidedEmission = ({ id }: Props) => {
  const user = useContext(UserContext);
  const [avoidedEmission, setAvoidedEmission] = useState<AvoidedEmissionListElement>();
  const [loading, setLoading] = useState(false);

  const getAvoidedEmissionById = async () => {
    if (!user?.selectedOrganization) return;

    setLoading(true);
    const response = await getAvoidedEmission(id, user.selectedOrganization);

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    setAvoidedEmission(response);
    setLoading(false);
  };

  useEffect(() => {
    getAvoidedEmissionById();
  }, [id]);

  return {
    avoidedEmission,
    loading
  };
};

export default useGetAvoidedEmission;
