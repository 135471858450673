import { useTranslation } from 'react-i18next';
import Button from '../../../ui/button/Button';
import Icon from '../../../ui/icon/Icon';
import { useState } from 'react';
import { OutdatedEfModal } from './OutdatedEfModal';

export const OutdatedEfBanner = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen && <OutdatedEfModal show={isOpen} onClose={() => setIsOpen(false)} />}
      <div className='w-full border-primary border-solid border-1 rounded-8 py-4 px-6 bg-neutral-white mb-6 border-box'>
        <div className='flex gap-x-3'>
          <Icon icon='information' color='gradient' size='large' />
          <div className='flex-col justify-between gap-y-6'>
            <div className='flex-col gap-y-2'>
              <p className='font-headings-h4-sb m-0'>{t('measureMain.outdatedEfs.title')}</p>
              <p className='font-body-b1-r m-0'>{t('measureMain.outdatedEfs.subTitle')}</p>
            </div>
            <Button
              lookAndFeel='primary'
              size='small'
              style={{
                width: 'fit-content'
              }}
              iconNode={<Icon icon='refresh' color='white' />}
              onClick={handleOpenModal}>
              {t('measureMain.outdatedEfs.btnText')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
