import moment from 'moment';
import { FormDataTreatment } from '../../components/measure/facilities/facilityDetail/components/createInvoice/components/treatment/Treatment';
import apiFetch from '../../utils/apiFetch';

export const getTreatmentById = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('GET', `/waste_water_treatments/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type CreateTreatmentBody = {
  file_url: string;
  name: string;
  population_size_served: number;
  incoming_water_quantity: number;
  quantity_unit_id: string;
  dbo_in_value: number;
  dbo_unit_id: string;
  nt_value: number;
  dbo_out_value: number;
  ch4_recovered: number;
  ch4_recovered_unit_id: string;
  sludge_evacuated: number;
  effluent_quantity: number;
  start_date: string;
  end_date: string;
  uploaded_by: string;
  facility_id: string;
  status: string;
  organization_id: string;
};

export const postTreatment = async (
  body: FormDataTreatment,
  organization_id: string,
  facility_id: string,
  uploaded_by: string
) => {
  const bodyFormated: CreateTreatmentBody = {
    file_url: '',
    name: body.name,
    organization_id,
    population_size_served: Number(body.nPerson),
    incoming_water_quantity: Number(body.caudalQuantity),
    quantity_unit_id: body.caudalUnit.id,
    dbo_in_value: Number(body.dboQuantity),
    dbo_unit_id: body.dboUnit.id,
    nt_value: Number(body.nitrogenQuantity),
    dbo_out_value: Number(body.dboOutQuantity),
    ch4_recovered: Number(body.recoveredMethane),
    ch4_recovered_unit_id: body.recoveredMethaneUnit.id,
    sludge_evacuated: Number(body.sludgeEvacuated),
    effluent_quantity: Number(body.caudalOutQuantity),
    start_date: moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    end_date: moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    uploaded_by,
    facility_id,
    status: 'uploaded'
  };

  try {
    const response = await apiFetch('POST', `/waste_water_treatments`, bodyFormated, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type EditTreatmentBody = {
  file_url: string;
  name: string;
  population_size_served: number;
  incoming_water_quantity: number;
  quantity_unit_id: string;
  dbo_in_value: number;
  dbo_unit_id: string;
  nt_value: number;
  dbo_out_value: number;
  ch4_recovered: number;
  ch4_recovered_unit_id: string;
  sludge_evacuated: number;
  effluent_quantity: number;
  start_date: string;
  end_date: string;
  status: string;
  organization_id: string;
};

export const patchTreatment = async (
  id: string,
  body: FormDataTreatment,
  organization_id: string
) => {
  const bodyFormated: EditTreatmentBody = {
    file_url: '',
    name: body.name,
    organization_id,
    population_size_served: Number(body.nPerson),
    incoming_water_quantity: Number(body.caudalQuantity),
    quantity_unit_id: body.caudalUnit.id,
    dbo_in_value: Number(body.dboQuantity),
    dbo_unit_id: body.dboUnit.id,
    nt_value: Number(body.nitrogenQuantity),
    dbo_out_value: Number(body.dboOutQuantity),
    ch4_recovered: Number(body.recoveredMethane),
    ch4_recovered_unit_id: body.recoveredMethaneUnit.id,
    sludge_evacuated: Number(body.sludgeEvacuated),
    effluent_quantity: Number(body.caudalOutQuantity),
    start_date: moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    end_date: moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    status: 'uploaded'
  };

  try {
    const response = await apiFetch('PATCH', `/waste_water_treatments/${id}`, bodyFormated, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteTreatment = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/waste_water_treatments/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

type McfValuesBody = {
  organization_id: string;
};

export const getMcfValues = async ({ organization_id }: McfValuesBody) => {
  try {
    const response = await apiFetch('GET', `/waste_water_treatments/mcf_values`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
