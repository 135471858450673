import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adaptBusinessTravelFrontToBack } from '../../../../../adapters/businessTravel';
import { UserContext } from '../../../../../context/userContext';
import useErrors from '../../../../../customHooks/useErrors';
import { postBusinessTravel } from '../../../../../services/api/businessTravels';

import {
  IFrontendFormBusinessTravel,
  IGetBackendBusinessTravel
} from '../../../../../types/entities/businessTravels';

import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import AddBusinessTravel from './AddBusinessTravel';

type Props = {
  addBusinessTravel: (value: IGetBackendBusinessTravel) => void;
};

function AddBusinessTravelWrapper({ addBusinessTravel }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const ERRORS = useErrors();

  const [error, setError] = useState<ErrorType>();

  const post = async (formData: IFrontendFormBusinessTravel) => {
    if (!user?.selectedOrganization) return;

    const backendBusinessTravel = adaptBusinessTravelFrontToBack(formData);
    const response = await postBusinessTravel({
      value: backendBusinessTravel,
      organizationId: user.selectedOrganization
    });

    if (
      response?.response?.status === 422 &&
      response?.response?.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES'
    ) {
      setError(ERRORS.BUSINESS_TRAVEL.INVALID_TRAVEL_MODE_BETWEEN_PLACES);
      return;
    }

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    return response;
  };

  return (
    <div className='new-business-travel'>
      <FormHeader
        title={t('businessTravels.addNewBusinessTravel')}
        description={t('businessTravels.addNewBusinessTravelDescription')}
      />
      <AddBusinessTravel
        user={user}
        addBusinessTravel={addBusinessTravel}
        postBusinessTravel={post}
        error={error}
      />
    </div>
  );
}

export default AddBusinessTravelWrapper;
