import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../../context/userContext';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import { postAvoidedEmission } from '../../../../../../../services/api/avoidedEmissions';

import {
  AvoidedEmissionListElement,
  FormFacilityData
} from '../../../../../../../types/entities/avoidedEmission';

import checkFormErrors from '../../../../../../../utils/checkFormErrors';
import Button from '../../../../../../ui/button/Button';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import GrayBackground from '../../../../../../ui/grayBackground/GrayBackground';
import StepCarouselList from '../../../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../../../ui/stepCarouselList/useStepCarousel';
import Facilities from './facilities/Facilities';
import InvoiceType from './invoiceType/InvoiceType';
import ListInvoices from './listInvoices/ListInvoices';
import './styles.scss';

type Props = {
  formData: FormFacilityData;
  setFormData: React.Dispatch<React.SetStateAction<FormFacilityData>>;
  addAvoidedEmission: (avoidedEmission: AvoidedEmissionListElement) => void;
};

const FacilitySelection = ({ formData, setFormData, addAvoidedEmission }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [loading, setLoading] = useState(false);

  const carouselSteps = [
    {
      id: 'facilities',
      text: t('avoidedEmissions.facilities')
    },
    {
      id: 'facilityDetail',
      text: ''
    },
    {
      id: 'type',
      text: ''
    }
  ];

  const {
    steps,
    stepSelected: stepSelectedBreadcrumb,
    handleSelect,
    handleNext
  } = useStepCarousel({
    stepsText: carouselSteps
  });

  if (steps?.at(1) && steps?.at(2)) {
    steps[1].text = formData.facilityName;
    steps[2].text = t(`consumption.${formData.category}`);
  }

  const onChangeFacility = (id: string, name: string) => {
    onChangeValue('facilityId')(id);
    onChangeValue('facilityName')(name);
  };

  const stepsToRender = {
    facilities: <Facilities onChangeFacility={onChangeFacility} next={handleNext} />,
    facilityDetail: (
      <InvoiceType onChangeSelectCategory={onChangeValue('category')} next={handleNext} />
    ),
    type: <ListInvoices formData={formData} setFormData={setFormData} next={handleNext} />
  };

  const handleErrors = () => {
    const optionalFields = ['name', 'start_date', 'end_date', 'invoice_id'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    if (formData.list.length === 0)
      newErrors.push({
        error: 'list',
        description: t('avoidedEmissions.listError')
      });
    setFormData((prev: FormFacilityData) => ({
      ...prev,
      errors: newErrors
    }));

    return newErrors;
  };

  const handleAdd = async () => {
    const newErrors: ErrorType[] = handleErrors();

    if (newErrors.length > 0 || !user?.selectedOrganization || loading) return;

    setLoading(true);

    const response = await postAvoidedEmission(formData, user.selectedOrganization);
    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    addAvoidedEmission(response);
    setLoading(false);
  };

  return (
    <>
      <h2 className='input-label-font on-light-text-color step-selector-avoided-emissions'>
        {t('avoidedEmissions.selectConsumption')}
      </h2>
      <div className='step-carousel-wrapper'>
        <StepCarouselList steps={steps} handleSelect={handleSelect} lookAndFeel='breadcrumb' />
      </div>

      <GrayBackground>
        {stepsToRender[stepSelectedBreadcrumb?.id as keyof typeof stepsToRender]}
      </GrayBackground>

      {stepSelectedBreadcrumb?.id === 'type' && (
        <>
          <FormButtonSection className='button-section'>
            <Button
              lookAndFeel='primary'
              text={t('avoidedEmissions.save')}
              onClick={handleAdd}
              size='small'
              loading={loading}
            />
          </FormButtonSection>
          <span className='error-text error-font error-text-color'>
            {formData.errors.find((error) => error.error === 'list')?.description}
          </span>
        </>
      )}
    </>
  );
};

export default FacilitySelection;
