import React, { useRef } from 'react';

type Props = {
  children: React.ReactNode;
  callback: (files: File[]) => void;
  disabled?: boolean;
};
const FileUploader = ({ children, callback, disabled }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    event.stopPropagation();
    // event.preventDefault();
    if (fileInputRef.current && event.target === event.currentTarget) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const files = Array.from(event.target.files);
    if (files && files.length > 0) {
      // Do something with the selected file
      callback(files);
    }
  };

  return (
    <div>
      {/* Invisible file input element */}
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple
      />
      {/* Component to trigger file input click */}
      <div onClick={handleClick} className='pointer'>
        {children}
      </div>
    </div>
  );
};

export default FileUploader;
