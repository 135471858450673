import React from 'react';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../ui/button/Button';
import { replaceStrongTags } from '../../../../../utils/stringToBold';

type Props = {
  handleClose: () => void;
};
const NewPAIAdded = ({ handleClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'newPAIAdded' });
  return (
    <>
      <FormHeader title={t('title')} description={replaceStrongTags(t('description'))} />
      <img
        src='/gif/newPAIAdded.GIF'
        alt='newPAIAdded'
        style={{ width: '100%', marginBottom: '5rem' }}
      />
      <FormButtonSection>
        <Button lookAndFeel='primary' onClick={handleClose} text={t('button')} />
      </FormButtonSection>
    </>
  );
};

export default NewPAIAdded;
