import React, { useContext, useEffect, useState } from 'react';
import apiFetch from '../../../../../../../utils/apiFetch';
import { UserContext } from '../../../../../../../context/userContext';
import { GroupFunds } from '../../../../../../../types/entities/groupFunds';

const useGetData = () => {
  const [organizationOptions, setOrganizationOptions] = useState<SelectOptionFormat[]>([]);
  const [organizationsBackend, setOrganizationsBackend] = useState<GroupFunds[]>([]);
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext);

  const fetchData = async () => {
    const url = `/matrices/groups_funds/${user?.selectedOrganization}/status`;
    setLoading(true);
    const response = await apiFetch('GET', url, null, {
      'x-organization-id': user?.selectedOrganization
    });
    setLoading(false);
    if (!response || !response.data) return;
    setOrganizationOptions(
      response.data
        .filter((elem: GroupFunds) => elem.status === 'accepted')
        .map((elem: GroupFunds) => ({
          id: elem.child_id,
          name: elem.child_name
        }))
    );
    setOrganizationsBackend(response.data.filter((elem: GroupFunds) => elem.status === 'accepted'));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { organizationOptions, organizationsBackend, loading };
};

export default useGetData;
