import React, { ChangeEvent } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  value?: string;
  onChange: (value: string) => void;
  error?: ErrorType;
}
function InputTextArea({ value, onChange, error, ...rest }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'input' });

  const handleOnChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value);

  const placeholder = !value && rest?.placeholder ? rest.placeholder : t('noData');

  const borderClass = error ? 'error-border-color' : 'input-border-color';

  return (
    <div>
      <textarea
        {...rest}
        placeholder={placeholder}
        onChange={handleOnChangeValue}
        value={value}
        className={`font-base textareav2 ${borderClass}`}
      />
    </div>
  );
}

export default InputTextArea;
