import { HTMLAttributes, ReactNode } from 'react';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  children: ReactNode | string;
}

const ErrorText = ({ children, ...rest }: Props) => {
  if (!children) return null;
  return (
    <span className='error-text error-font error-text-color' {...rest}>
      {children}
    </span>
  );
};

export default ErrorText;
