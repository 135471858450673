import { useState } from 'react';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import TableComponent from '../../../../ui/table/Table';
import { FormType, LCAFormMaterial } from './constants';
import { useColumns } from './useColumns';
import { materialFormSchema } from './materialFormSchema';
import Modal from '../../../../ui/modal/Modal';
import { ValidateFn } from './types';
import { MaterialSelection } from './MaterialSelection';
import { usePatchLCAMaterialNode } from '../../hooks/usePatchLCAMaterialNode';
import { useACVId } from '../../hooks/useACVId';
import { useModalContext } from '../ModalHandler';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../../constants';

type Props = {
  form: FormType;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
  errors: ErrorContainer<FormType> | null;
  validate: ValidateFn;
};

export const SecondStep: React.FC<Props> = ({ form, setForm, validate }) => {
  const { t } = useLCATranslation();

  const [isSelectMaterialOpen, setIsSelectMaterialOpen] = useState(false);

  const { acv_id } = useACVId();

  const { setModalData, modalData } = useModalContext();

  const { patchLCAMaterialNode } = usePatchLCAMaterialNode();

  const onCancel = () => {
    setModalData(undefined);
  };

  const onSave = async () => {
    if (!modalData?.target || modalData.type !== 'edit_material') {
      return;
    }

    const isValid = validate(form, materialFormSchema);

    if (!isValid) {
      return;
    }

    await patchLCAMaterialNode({ ...form, node_id: modalData.target, acv_id });

    setModalData(undefined);
  };

  const deleteMaterial = (material_id: string) => () => {
    setForm((prev) => ({
      ...prev,
      materials: prev.materials.filter((material) => material.material_id !== material_id)
    }));
  };

  const updateMaterialQuantity = (id: string, quantity: string) => {
    setForm((prev) => ({
      ...prev,
      materials: prev.materials.map((material) =>
        material.material_id === id ? { ...material, quantity } : material
      )
    }));
  };

  const updateMaterialUnit = (id: string, unit: SelectOptionFormat<string>) => {
    setForm((prev) => ({
      ...prev,
      materials: prev.materials.map((material) =>
        material.material_id === id ? { ...material, unit: unit.name, unit_id: unit.id } : material
      )
    }));
  };

  const { columns } = useColumns({ deleteMaterial, updateMaterialQuantity, updateMaterialUnit });

  if (isSelectMaterialOpen) {
    const handleSelectMaterials = (materials: LCAFormMaterial[]) => {
      setForm((prev) => ({
        ...prev,
        materials
      }));

      setIsSelectMaterialOpen(false);
    };

    const onCancel = () => {
      setIsSelectMaterialOpen(false);
    };

    return (
      <MaterialSelection
        materials={form.materials}
        setMaterials={handleSelectMaterials}
        onCancel={onCancel}
      />
    );
  }

  return (
    <>
      <div
        className='flex-col gap-y-2 grow'
        style={{ height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH }}>
        <Modal.Content style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          {!form.materials.length && (
            <span className='font-body-b2-r text-neutral-gray-20 bg-neutral-gray-warm-80 w-full py-2 px-4 border-box rounded-4 grow '>
              {t('lcaDetail.processFlow.materialModal.noMaterials')}
            </span>
          )}
          {!!form.materials.length && (
            <div className='bg-neutral-gray-warm-80 w-full p-4 border-box rounded-4 grow'>
              <TableComponent data={form.materials} columns={columns} loading={false} />
            </div>
          )}
        </Modal.Content>
        <Button
          lookAndFeel='link-dark'
          className='flex items-center gap-x-1'
          text={t('lcaDetail.processFlow.materialModal.addMaterial')}
          iconNode={<Icon icon='add' color='gray-dark' size='medium' />}
          onClick={() => setIsSelectMaterialOpen(true)}
        />
      </div>
      <Modal.Buttons>
        <Button
          lookAndFeel='secondary'
          text={t('lcaDetail.processFlow.materialModal.cancel')}
          size='small'
          onClick={onCancel}
        />
        <Button
          lookAndFeel='primary'
          text={t('lcaDetail.processFlow.materialModal.confirm')}
          size='small'
          onClick={onSave}
        />
      </Modal.Buttons>
    </>
  );
};
