import { useEffect, useState } from 'react';
import { getLicensePlates } from '../../../../../services/api/vehicle';

const useGetLicensePlates = () => {
  const [licensePlates, setLicensePlates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    const response = await getLicensePlates();
    setLoading(false);
    if (!response) return;
    setLicensePlates(response);
  };

  useEffect(() => {
    getData();
  }, []);

  return { licensePlates, loading };
};

export default useGetLicensePlates;
