import { User } from '../../../../types/entities/user';
import apiFetch from '../../../../utils/apiFetch';
import { MITECO_STATUS_KEY } from '../constants';

type MITECOAssistanceParams = {
  user: User;
  orgName: string;
};

export const requestMITECOAssistance = async ({ user, orgName }: MITECOAssistanceParams) => {
  const res = await apiFetch('post', '/organization_recommendations', {
    name: MITECO_STATUS_KEY,
    user_id: user.id,
    email: user.email,
    organization_name: orgName,
    organization_id: user.selectedOrganization
  });

  return res.data;
};
