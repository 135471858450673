import { BaseEdge, Position, getSmoothStepPath, useInternalNode } from '@xyflow/react';

type Props = {
  id: string;
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  target: string;
  targetPosition: Position;
  markerEnd?: string;
};

const REM = 16;

export const CustomEdge: React.FC<Props> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  targetPosition,
  sourcePosition,
  markerEnd
}) => {
  const targetInternalNode = useInternalNode(target);

  const top0 = targetY - (targetInternalNode?.measured.height || 0) / 2;

  const [edgePath] = getSmoothStepPath({
    sourceX: sourceX - 4,
    sourceY,
    targetX,
    targetY: top0 + 2 * REM,
    targetPosition,
    sourcePosition,
    borderRadius: 8
  });

  return <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />;
};
