import { useTranslation } from 'react-i18next';
import { Employee, EmployeeTable } from '../../../../../types/entities/employee';
import { EmployeePeriod } from '../../../../../types/entities/employeePeriod';
import { convertDateBackToFront } from '../../../../../utils/convertDates';

const useAdapterEmployees = () => {
  const { t } = useTranslation();

  const getStatus = (status: any) => {
    return status;
  };

  const adaptEmployeeFromBack2Table = (employee: Employee): EmployeeTable => {
    const periods = employee?.periods
      ?.map((period) => period)
      .sort((a: any, b: any) => {
        const dateA = new Date(a.end_date);
        const dateB = new Date(b.end_date);

        return dateB.getTime() - dateA.getTime();
      });

    const firstPeriod = periods?.at(0);
    periods?.shift();

    let transportType = '';

    // if (firstPeriod?.transport_type) {
    //   transportType = firstPeriod?.transport_type;
    // }

    if (firstPeriod?.transport_type && employee.status !== 'loading') {
      transportType =
        t(`employees.${firstPeriod?.transport_type}`) +
        ' ' +
        (firstPeriod.vehicle_size ? t(`employees.${firstPeriod.vehicle_size}`) : '');
    }

    if (
      !firstPeriod?.transport_type &&
      employee?.status === 'uploaded' &&
      firstPeriod?.situation === 'active'
    )
      transportType = t('employees.telecommute');

    if (!firstPeriod?.transport_type && employee?.status === 'loading') transportType = '-';

    const startDateFirstPeriod = firstPeriod?.start_date
      ? convertDateBackToFront(firstPeriod?.start_date)
      : undefined;
    const endDateFirstPeriod = firstPeriod?.end_date
      ? convertDateBackToFront(firstPeriod?.end_date)
      : t('employees.now');
    const period =
      startDateFirstPeriod && employee.status !== 'loading'
        ? `${startDateFirstPeriod} - ${endDateFirstPeriod}`
        : '-';

    return {
      employeeId: employee.id,
      periodId: firstPeriod?.id,
      name: employee.name,
      email: employee.email,
      user: employee.email ? employee.email : employee.name,
      km: firstPeriod?.total_km ? String(firstPeriod?.total_km) : '-',
      transportType: transportType ? transportType : '-',
      fuelType: firstPeriod?.fuel_type ? t(`employees.${firstPeriod?.fuel_type}`) : '-',
      period,
      co2e: firstPeriod?.co2e
        ? String(firstPeriod?.co2e?.toFixed(2))
        : employee?.status === 'loading'
        ? '-'
        : '0',
      status: employee.status === 'loading' ? 'loading' : getStatus(firstPeriod?.situation),
      responseWay:
        firstPeriod?.response_medium && employee.status !== 'loading'
          ? firstPeriod?.response_medium
          : null,
      daysPerWeek: firstPeriod?.weekly_travels.length
        ? String(firstPeriod?.weekly_travels.length)
        : '-',
      children: periods?.map((employeeChild) =>
        adaptEmployeePeriodFromBack2EmployeeTable(employeeChild)
      )
    };
  };

  const adaptEmployeePeriodFromBack2EmployeeTable = (elem: EmployeePeriod): EmployeeTable => {
    const startDateFirstPeriod = elem?.start_date
      ? convertDateBackToFront(elem?.start_date)
      : undefined;
    const endDateFirstPeriod = elem?.end_date
      ? convertDateBackToFront(elem?.end_date)
      : t('employees.now');
    const period = startDateFirstPeriod ? `${startDateFirstPeriod} - ${endDateFirstPeriod}` : '-';
    let transportType = '';
    if (elem?.transport_type) {
      transportType =
        t(`employees.${elem?.transport_type}`) +
        ' ' +
        (elem.vehicle_size ? t(`employees.${elem.vehicle_size}`) : '');
    }

    if (!elem?.transport_type) transportType = t('employees.telecommute');

    return {
      employeeId: elem?.employee_id,
      periodId: elem?.id,
      km: elem?.total_km ? String(elem?.total_km) : '-',
      transportType,
      fuelType: elem?.fuel_type ? t(`employees.${elem?.fuel_type}`) : '-',
      period,
      co2e: elem.co2e ? String(elem.co2e.toFixed(2)) : '0',
      daysPerWeek: elem?.weekly_travels.length ? String(elem?.weekly_travels.length) : '-',
      responseWay: elem.response_medium ? elem.response_medium : '',
      status: elem.situation ? elem.situation : elem.situation
    };
  };

  const adaptEmployeeListFromBack2Table = (employees: Employee[]): EmployeeTable[] => {
    return employees.map((employee) => adaptEmployeeFromBack2Table(employee));
  };

  return { adaptEmployeeFromBack2Table, adaptEmployeeListFromBack2Table };
};

export default useAdapterEmployees;
