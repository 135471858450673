import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../ui/table/Table';
import useColumns from './hooks/useColumns';
import useGetData from './hooks/useGetData';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import {
  formatNumberToDecimalNonZero,
  numberToDecimalNonZero
} from '../../../../../utils/numberToDecimal';
import Icon from '../../../../ui/icon/Icon';

const ImpactByCategory = () => {
  const { t } = useTranslation();
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const columns = useColumns();
  const { categoryInfo } = useGetData({ startDate, endDate });

  const renderCategory = (category: string) => {
    let icon = null;

    if (category === 'road') {
      icon = <Icon icon='car' color='gradient' />;
    }
    if (category === 'rail') {
      icon = <Icon icon='train' color='gradient' />;
    }
    if (category === 'air') {
      icon = <Icon icon='airplane' color='gradient' />;
    }
    if (category === 'maritime') {
      icon = <Icon icon='ship' color='gradient' />;
    }

    return (
      <div className='flex gap-1'>
        {' '}
        {icon} {t(`logisticMode.${category}`)}{' '}
      </div>
    );
  };
  return (
    <div className='card dashboard-card'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>
          {t('dashboardLogistic.impactByCategory')}
        </h4>
      </div>
      <div className='dashboard-card__body dashboard-card__body--list'>
        <div className='list-wrapper'>
          <Table
            columns={columns}
            data={categoryInfo.map((elem) => ({
              category: renderCategory(elem.category),
              numberOfTrips: (
                <div style={{ textAlign: 'right' }}>{formatNumberToDecimalNonZero(elem.trips)}</div>
              ),
              kms: (
                <div style={{ textAlign: 'right' }}>{formatNumberToDecimalNonZero(elem.kms)}</div>
              ),
              kgCO2e: (
                <div className='highlight-text-color weight-600' style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.emissions)}
                </div>
              )
            }))}
            loading={false}
            size={'small'}
          />
        </div>
      </div>
    </div>
  );
};

export default ImpactByCategory;
