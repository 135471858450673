import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';
import { ProfileCard } from '../profileCard/ProfileCard';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';

export const Invoicing = () => {
  const { t } = useTranslation();

  const org = useSelectedOrganization();

  const navigate = useNavigate();

  const btnText = org?.role !== 'owner' ? t('profile.noPermission') : t('profile.invoicing');

  const onClick = () => {
    if (org?.role !== 'owner') {
      return;
    }

    navigate(ROUTES.INVOICING);
  };

  return (
    <ProfileCard.Layout>
      <div className='flex gap-x-4 items-center'>
        <ProfileCard.Content>
          <ProfileCard.Title>{t('profile.invoicing')}</ProfileCard.Title>
          <ProfileCard.Description>{t('profile.invoicingDescription')}</ProfileCard.Description>
        </ProfileCard.Content>
        <Button
          lookAndFeel='primary'
          size='medium'
          text={btnText}
          style={{ width: 'fit-content' }}
          disabled={org?.role !== 'owner'}
          onClick={onClick}
        />
      </div>
    </ProfileCard.Layout>
  );
};
