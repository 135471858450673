import type { TFunction } from 'react-i18next';
import { z } from 'zod';

export const adaptZodErrors = (zodError: z.ZodError, t?: TFunction) => {
  const errors: { [key: string]: ErrorType } = {};

  Object.entries(zodError.formErrors.fieldErrors).forEach(([key, fieldError]) => {
    if (fieldError) {
      errors[key] = {
        error: fieldError[0],
        description: t ? t(fieldError[0]) : fieldError[0]
      };
    }
  });

  return errors;
};
