import { useQuery } from '@tanstack/react-query';
import { getLCALanding } from '../services/getLCALanding';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';

export const useLCALanding = () => {
  const org_id = useSelectedOrganization()?.id;

  const { data, error, isLoading } = useQuery({
    queryKey: ['lcaLanding', org_id],
    queryFn: getLCALanding
  });

  return { data, error, isLoading };
};
