import moment from 'moment';
import { FacilityFuelType } from '../../../../../../../types/entities/facilityFuel';
import { InvoiceType } from '../../../../../../../types/entities/invoice';
import { User } from '../../../../../../../types/entities/user';
import { InvoicePostBack } from '../constants';

export const backPostInvoiceToFront = (
  data: InvoicePostBack | null,
  facility_id: string,
  facility_fuel: FacilityFuelType | undefined,
  user: User,
  unit?: Partial<Unit>,
  toc?: string
): InvoiceType | null => {
  if (!data) return null;
  const facility_percentage = data.facility_percentages.find((facility_percentage) => {
    return facility_percentage.facility_id === facility_id;
  });

  const percentage = facility_percentage?.percentage || 1;

  const quantity = data.base_quantity * percentage;

  let start_date = data.start_date;
  let end_date = data.end_date;

  if (typeof start_date !== 'string') {
    start_date = moment(start_date).format('DD/MM/YYYY');
  }

  if (typeof end_date !== 'string') {
    end_date = moment(end_date).format('DD/MM/YYYY');
  }

  return {
    id: data.id,
    custom_emission_factor_id: data.custom_emission_factor_id ?? undefined,
    type: data.type,
    supplier: data.supplier_id,
    facility_fuel,
    cups: data.cups ?? '',
    base_quantity: data.base_quantity,
    unit,
    quantity,
    invoice_id: data.invoice_id,
    start_date,
    end_date,
    user,
    status: data.status,
    facility_id: data.facility_id,
    facility_percentages: data.facility_percentages,
    percentage,
    toc
  };
};
