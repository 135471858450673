import { useTranslation } from 'react-i18next';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import HorizontalCard from 'components/ui/cardPanel/HorizontalCard';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import numberToDecimal from 'utils/numberToDecimal';
import logoLogicReturner from '../utils/logoLogicReturner';
import { scope1_color_rgb, scope2_color_rgb, scope3_color_rgb } from 'styles/colors';
import changeOpacity from 'utils/changeOpacityRGB';
import { Holding } from 'types/entities/holdings';
import YearComparisonBarChart from './YearComparisonBarChart';
import useTranslatedMonths from '../utils/useTranslatedMonths';
import { MonthlyDataPoint } from 'components/dashboard/dashboardTotal/hooks/useTotalDashboard';
import formatNumber from 'utils/formatNumber';
const SCOPE_COLORS: {
  [key: number]: string;
} = {
  1: scope1_color_rgb,
  2: scope2_color_rgb,
  3: scope3_color_rgb
};
type Props = {
  loading: boolean;
  frameworkName: string;
  setScope: (value: SelectOptionFormat) => void;
  setOrganization: (value: SelectOptionFormat) => void;
  scopeOptions: SelectOptionFormat[];
  companyOptions: SelectOptionFormat[];
  dataHolding?: Holding;
  dataScopes: ScopeData[];
  totalCo2: number;
  datasets: { thisYear: MonthlyDataPoint[]; lastYear: MonthlyDataPoint[] };
};

type PropsFrameworkCard = {
  title: string;
  color: string;
  color2: string;
  co2: number;
  scope: SelectOptionFormat;
  loading: boolean;
  labels: string[];
  datasets: { thisYear: MonthlyDataPoint[]; lastYear: MonthlyDataPoint[] };
};
const FrameworkCard = ({
  title,
  color,
  color2,
  co2,
  scope,
  loading,
  labels,
  datasets
}: PropsFrameworkCard) => {
  return (
    <HorizontalCard
      color='gray_color'
      style={{ width: 'calc(33% - 0.5rem)', maxWidth: 'calc(33% - 0.5rem)' }}>
      <div
        className='framework-progress flex items-center justify-between gap-4 h-full'
        style={{ width: '30%' }}>
        <div
          className='flex-col items-start gap-2 h-full justify-center'
          style={{ width: 'calc(100% - 1rem)' }}>
          <div className='framework-progress__title flex justify-between items-center gap-2'>
            <div className='headline4-font on-light-text-color'>{title}</div>
          </div>
          <div className='co2-information flex items-center gap-1'>
            <div className='co2-value co2-dashboard-font-small' style={{ color: color }}>
              {formatNumber(numberToDecimal(co2 / 1000, 3))}
            </div>
            <span className='co2-units on-light-text-color body1-font'>t CO₂ eq.</span>
          </div>
        </div>
      </div>
      <HorizontalCard.Body style={{ minWidth: '60%', position: 'relative', height: '100%' }}>
        <YearComparisonBarChart
          loading={loading}
          labels={labels}
          datasets={datasets}
          scope={scope.id as 'total' | 'scope_1' | 'scope_2' | 'scope_3'}
          thisYearColor1={color}
          thisYearColor2={color2}
          lastYearColor={color}
        />
      </HorizontalCard.Body>
    </HorizontalCard>
  );
};

const FrameworkScopes = ({
  frameworkName,
  loading,
  scopeOptions,
  totalCo2,
  dataScopes,
  datasets
}: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });

  const labels = useTranslatedMonths();
  if (loading) {
    return <LoaderCard />;
  }
  return (
    <CardPanel>
      <CardPanel.Header
        style={{
          alignItems: 'center'
        }}>
        <div className='framework-scope__iconTitle flex gap-4'>
          <img
            src={logoLogicReturner(frameworkName, false)}
            alt='GHG'
            className='framework-scope__icon'
          />
          <div className='framework-scope__titleNumber flex-col justify-between'>
            <h4 className='co2-dashboard-font-small on-light-text-color'>
              {t('totalCarbonFootprint')}
            </h4>
            <div className='co2-information flex items-center gap-1'>
              <div className='co2-value co2-dashboard-font-small highlight-text-color'>
                {formatNumber(numberToDecimal(totalCo2 / 1000, 3))}
              </div>
              <span className='co2-units on-light-text-color body1-font'>t CO₂ eq.</span>
            </div>
          </div>
        </div>
      </CardPanel.Header>
      <CardPanel.Body>
        {[1, 2, 3].map((scope, index) => (
          <FrameworkCard
            key={index}
            title={t(`scope${scope}`)}
            color={changeOpacity(SCOPE_COLORS[scope], 0.7)}
            color2={changeOpacity(SCOPE_COLORS[scope], 0.2)}
            co2={dataScopes[index].total_emissions}
            scope={scopeOptions[scope]}
            loading={loading}
            labels={labels}
            datasets={datasets}
          />
        ))}
      </CardPanel.Body>
    </CardPanel>
  );
};

export default FrameworkScopes;
