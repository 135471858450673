import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { getSupplierSpecificUnits } from '../../../../../../services/api/purchases';

type Props = {
  item: string;
  supplier: string;
};

const useUnits = ({ item, supplier }: Props) => {
  const { t } = useTranslation();
  const selectedOrganization = useSelectedOrganization();
  const [units, setUnits] = useState<Unit[]>([]);

  const fetchData = async () => {
    if (!selectedOrganization?.id || !item || !supplier) return;

    // This endpoint return just ONE unit NOT an array
    const unitData = await getSupplierSpecificUnits(selectedOrganization.id, item, supplier);
    if (!unitData) return;
    setUnits([{ id: unitData.id, name: t(`units.${unitData.name}`), type: unitData.type }]);
  };

  useEffect(() => {
    fetchData();
  }, [item, supplier]);

  return units;
};

export default useUnits;
