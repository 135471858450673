import { ReactNode } from 'react';

const replaceLinkWithAnchorTag = (text: string, linkText: string): ReactNode => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  const url = text.match(urlRegex);
  const jsxElements: ReactNode[] = parts.map((part, index) => {
    if (index % 2 === 0) {
      return part; // Text content
    } else {
      return (
        <a
          key={index}
          href={url ? url[0] : ''}
          target='_blank'
          rel='noopener noreferrer'
          className='highlight-text-color'>
          {linkText}
        </a>
      ); // <a> element
    }
  });

  return <>{jsxElements}</>;
};

export default replaceLinkWithAnchorTag;
