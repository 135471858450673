import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import './styles.scss';

type ICardProps = HTMLAttributes<HTMLDivElement>;

const CardPanel = (props: ICardProps) => {
  const className = `${props.className} card card-panel`;
  return <div {...props} className={className} />;
};

const Header = ({
  children,
  title,
  style
}: {
  children?: ReactNode;
  title?: ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <div className='card-panel__header' style={style}>
      {title && <h3 className='headline3-font on-light-text-color'>{title}</h3>}
      {children}
    </div>
  );
};

const Body = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div className={`${className} card-panel__body`} style={{ gridTemplateRows: 'auto' }}>
      {children}
    </div>
  );
};

CardPanel.Header = Header;
CardPanel.Body = Body;

export default CardPanel;
