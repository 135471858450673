import React, { useEffect, useState } from 'react';
import { getFacilities } from '../../../../../../../services/api/facility';
import { Facility } from '../../../../../../../types/entities/facility';

type Props = {
  organizationId: string;
};
const useGetFacilitiesByOrg = ({ organizationId }: Props) => {
  const [facilityOptions, setFacilityOptions] = useState<SelectOptionFormat[]>([]);
  const [loading, setLoading] = useState(false);

  const requestFacilities = async () => {
    if (!organizationId) return;
    // Request facilities
    setLoading(true);
    const data = await getFacilities(organizationId);
    setLoading(false);
    if (data?.response?.status >= 400) return;

    const filteredData = data.items
      .filter((elem: Facility) => elem.status !== 'archived')
      .map((elem: Facility) => ({
        id: elem.id,
        name: elem.name
      }));

    setFacilityOptions(filteredData);
  };

  useEffect(() => {
    requestFacilities();
  }, [organizationId]);

  return { facilityOptions, loading };
};

export default useGetFacilitiesByOrg;
