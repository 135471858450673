import type { Column } from '../../../../types/table';
import Table from '../../../ui/table/Table';
import './styles.scss';

type Props = {
  columns: Column[];
  data: any[];
};
const List = ({ columns, data }: Props) => {
  return (
    <div className='report-list'>
      <Table
        columns={columns}
        data={data.map((elem, index) => ({
          ...elem,
          key: elem.id ?? `elem-${index}`
        }))}
        loading={false}
      />
    </div>
  );
};

export default List;
