import type { ExcelDataCategory } from '../../../../constants/excelData';
import apiFetch from '../../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../../../utils/url';

const URL = '/organizations/excel-data';

type Params = {
  category: ExcelDataCategory;
  year: string;
};

export const getExcelData = async ({ category, year }: Params) => {
  const response = await apiFetch(
    'GET',
    getUrl(URL, { queryParams: generateQueryParamsFromObject({ category, year }) })
  );

  return response.data;
};
