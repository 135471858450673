import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLcaSoldProductAdapter from '../../../../../../adapters/useLcaSoldProductAdapter';
import useErrors from '../../../../../../customHooks/useErrors';
import { createUseAndEndOfLife } from '../../../../../../services/api/useAndEndOfLife';
import {
  ILCASoldProductBack,
  ILCASoldProductFront
} from '../../../../../../types/entities/useAndEndOfLife';

import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import Form from '../form/Form';

type Props = {
  addElement: (element: ILCASoldProductBack) => void;
};

const AddForm = ({ addElement }: Props) => {
  const ERRORS = useErrors();
  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife.form' });
  const { adpatFront2Back } = useLcaSoldProductAdapter();
  const [error, setError] = useState<ErrorType>();

  const handleCreate = async (formData: ILCASoldProductFront) => {
    const element = adpatFront2Back(formData);

    const response = await createUseAndEndOfLife({ element });

    if (!response || response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    addElement(response);
  };

  return (
    <>
      <FormHeader title={t('titleAdd')} description={t('descriptionAdd')} />
      <Form handleSubmit={handleCreate} error={error} />
    </>
  );
};

export default AddForm;
