import * as datadisContract from '../../../../../../types/entities/datadisContract';

export const getSelectedContract = (
  contractsData: datadisContract.DatadisContract[] | undefined,
  selectedCups: datadisContract.DatadisCUPS | undefined,
  formData: datadisContract.FormDataDatadisContract
) => {
  if (!contractsData || !selectedCups) return null;

  const selectedContract = contractsData.find(
    (contract) => contract.start_date === formData.dateInterval.id
  );

  if (!selectedContract) return null;
  selectedContract.point_type = selectedCups.point_type;

  return selectedContract;
};
