import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import useLcaSoldProductAdapter from '../../../../adapters/useLcaSoldProductAdapter';
import { ILCASoldProductBack } from '../../../../types/entities/useAndEndOfLife';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import Button from '../../../ui/button/Button';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../ui/modal/Modal';
import TotalLegend from '../../../ui/totalLegend/TotalLegend';
import AddForm from './components/addForm/AddForm';
import DeleteForm from './components/deleteForm/DeleteForm';
import EditForm from './components/editForm/EditForm';
import useConstants from './hooks/useConstants';
import useFetch from './hooks/useFetch';

const UseAndEndOfLife = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife' });
  const { t: notificationT } = useTranslation('translation', { keyPrefix: 'notification' });
  const dispatch = useDispatch();
  const { adaptLcaSoldProductBack2LcaBack } = useLcaSoldProductAdapter();

  const {
    data,
    total,
    loading,
    firstLoading,
    fetchData,
    addElement,
    editElement,
    removeElement,
    idToEdit,
    idToRemove,
    handleSetIdToEdit,
    handleSetIdToRemove
  } = useFetch();

  const { columns } = useConstants();

  // ADD
  const [showAddModal, setShowAddModal] = useState(false);
  const handleOpenAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => setShowAddModal(false);

  const handleAddElement = (element: ILCASoldProductBack) => {
    const elementAdapted = adaptLcaSoldProductBack2LcaBack(element);
    addElement(elementAdapted);
    handleHideAddModal();
    dispatch(setNotification(notificationT('useAndEndOfLifeAdd')));
  };

  // EDIT
  const showEditModal = !!idToEdit;
  const handleHideEditModal = () => handleSetIdToEdit('');

  const handleEditElement = (element: ILCASoldProductBack) => {
    const elementAdapted = adaptLcaSoldProductBack2LcaBack(element);
    editElement(elementAdapted);
    handleSetIdToEdit('');
    dispatch(setNotification(notificationT('useAndEndOfLifeEdit')));
  };

  // REMOVE
  const showRemoveModal = !!idToRemove;
  const handleHideRemoveModal = () => handleSetIdToRemove('');

  const handleRemoveElement = (id: string) => {
    removeElement(id);
    handleSetIdToRemove('');
    dispatch(setNotification(notificationT('useAndEndOfLifeDelete')));
  };

  return (
    <MainPageLayout
      sectionTitle={t('title')}
      title={t('start')}
      description={t('startDescription')}
      breadcrumb={<Breadcrumb />}
      buttons={
        <Button
          lookAndFeel={'primary'}
          text={t('add')}
          size='small'
          onClick={handleOpenAddModal}
          style={{ width: '7%' }}
        />
      }>
      <InfiniteList
        fetchData={fetchData}
        data={data}
        columns={columns}
        loading={loading}
        firstLoading={firstLoading}
        total={total}
        header={
          <div className='table-tags' style={{ marginLeft: 'auto' }}>
            <TotalLegend total={total} loading={loading} i18key={'useAndEndOfLife'} />
          </div>
        }
      />

      <Modal show={showAddModal} onClose={handleHideAddModal} maxWidth='613px' width='613px'>
        <AddForm addElement={handleAddElement} />
      </Modal>

      <Modal show={showEditModal} onClose={handleHideEditModal} maxWidth='613px' width='613px'>
        <EditForm editElement={handleEditElement} id={idToEdit} />
      </Modal>

      <Modal show={showRemoveModal} onClose={handleHideRemoveModal} maxWidth='613px' width='613px'>
        <DeleteForm deleteElement={handleRemoveElement} id={idToRemove} />
      </Modal>
    </MainPageLayout>
  );
};

export default UseAndEndOfLife;
