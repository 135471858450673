import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import useGetBase64 from '../../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { sendEmails } from '../../../../services/api/emails';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../ui/formComponents/inputFile/InputFileSingle';
import InputText from '../../../ui/formComponents2/inputText/InputText';
import InputWrapper from '../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';

type Props = {
  organizations: string[];
  onClose: () => void;
};

type FormSendEmailData = {
  subject: string;
  html: File | undefined;
  errors: ErrorType[];
};

const SendEmail = ({ organizations, onClose }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormSendEmailData>({
    subject: '',
    html: undefined,
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const { fileBase64 } = useGetBase64(formData.html);

  const handleSendEmail = async () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length !== 0 || !user?.selectedOrganization) return;

    const response = await sendEmails(formData.subject, fileBase64 as string, organizations);

    if (response?.response?.status >= 400) return;
    onClose();
  };

  return (
    <div className='new-responses'>
      <FormHeader
        title={t('organization.sendEmailTitle')}
        description={t('organization.sendEmailSubtitle')}
      />
      <FormWrapper>
        <InputWrapper
          label={t('organization.subject')}
          icon={'/images/icons/plus.svg'}
          error={formData.errors.find((error) => error.error === 'subject')}>
          <InputText
            placeholder={t('organization.enterSubject')}
            value={formData.subject}
            onChange={onChangeValue('subject')}
          />
        </InputWrapper>

        <FormElementFull>
          <InputFileSingle
            label={t('organization.file')}
            handleFile={onChangeValue('html')}
            buttonText={t('organization.fileButton')}
            labelText={t('organization.fileDescription')}
            fileName={formData.html?.name ?? ''}
            allowedTypes={['text/html']}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleSendEmail}
          text={t('organization.send')}
          size='small'
        />
      </FormButtonSection>
    </div>
  );
};

export default SendEmail;
