import { useTranslation } from 'react-i18next';
import { useVehiclesTypes } from './useVehiclesTypes';
import useFuels from './useFuels';

export const useFilters = () => {
  const { t } = useTranslation();
  const { data: unknown_vehicles } = useVehiclesTypes();

  const unknownVehicles = unknown_vehicles.map((vehicle: any) => ({
    id: vehicle.type,
    name: t(`vehicles.${vehicle.type}`),
    total: undefined,
    type: 'unknown_vehicle_type'
  }));

  const { data: vehicle_fuels } = useFuels();

  const fuelVehicles = vehicle_fuels.map((fuel) => ({
    id: fuel.value,
    name: fuel.name,
    total: undefined,
    type: 'vehicle_fuel'
  }));

  const ownership = [
    {
      id: 'rented',
      name: t('vehicles.rented'),
      total: undefined,
      type: 'ownership'
    },
    {
      id: 'owned',
      name: t('vehicles.owned'),
      total: undefined,
      type: 'ownership'
    }
  ];

  return { ownership, unknownVehicles, fuelVehicles };
};
