import { PREMIUM_ROUTES, ROUTES } from '../constants/routes';
import { useFeatureFlags } from './useFeatureFlags';

export const usePremiumRoutes = () => {
  const flags = useFeatureFlags();
  const premiumRoutesCopy = [...PREMIUM_ROUTES];

  // if (!flags?.iso14001) {
  //   premiumRoutesCopy.push(ROUTES.COMMUNICATE_14001);
  // }

  return premiumRoutesCopy;
};
