import { useTranslation } from 'react-i18next';
import { useVehiclesTypes } from './useVehiclesTypes';

export const useNuvoVehicleTypes = () => {
  const { data, isLoading } = useVehiclesTypes();
  const { t } = useTranslation();

  return {
    data:
      data?.map((vehicle: any) => ({
        label: t(`vehicles.${vehicle.type}`),
        value: vehicle.id,
        type: 'string'
      })) ?? [],
    isLoading
  };
};
