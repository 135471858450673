import './styles.scss';

type Props = {
  mode?: string;
};
function LoaderTables({ mode = 'table' }: Props) {
  return (
    <div className={`loader-tables-wrapper mode-${mode}`}>
      <div className='dot-falling'></div>
    </div>
  );
}

export default LoaderTables;
