import { createContext } from 'react';

type ESGMode = {
  mode: string;
  setMode: React.Dispatch<any>;
};

const ESGModeContext = createContext<ESGMode>({
  mode: 'environmental',
  setMode: () => {
    // It gets implemented by the provider
  }
});

export { ESGModeContext };
