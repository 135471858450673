import { useTranslation } from 'react-i18next';
import FileAttached from '../../../../../../../tasks/components/form/components/fileAttached/FileAttached';
import FormElementFull from '../../../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../../../../../ui/formComponents/inputFile/InputFileSingle';
import { FormDataStateESG } from '../ModalDatapoint';
import getFileNameFromUrl from '../../../../../../../../utils/getFileNameFromUrl';

type Props = {
  formDataState: FormDataStateESG;
};

export const UploadFileComponent = ({ formDataState }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { formData, setFormData } = formDataState;

  const handleRemove = (id: string) => {
    setFormData({
      ...formData,
      value: undefined,
      errors: formData.errors.filter((error: ErrorType) => error.error !== 'file')
    });
  };

  const handleUploadFile = (file: File) => {
    setFormData({
      ...formData,
      value: file,
      errors: formData.errors.filter((error: ErrorType) => error.error !== 'file')
    });
  };

  let formDataValue: File | undefined = undefined;
  let url = '';
  if (formData.value instanceof File) {
    formDataValue = formData.value as File;
  } else if (typeof formData.value === 'string') {
    url = formData.value as string;
    const fileName = getFileNameFromUrl(formData.value as string);
    formDataValue = new File([], fileName as string);
  }

  return (
    <FormWrapper>
      <FormElementFull>
        <p className='font-10 weight-600'>{t('uploadModalDescription')}</p>
        <InputFileSingle
          handleFile={handleUploadFile}
          buttonText={t('uploadButton')}
          fileName={formDataValue?.name ?? ''}
          allowedTypes={[]}
          error={formData.errors.find((error: ErrorType) => error.error === 'value')}
          labelText={t('uploadModalLabel')}
          buttonIcon={'/images/icons/plusWhite.svg'}
        />
        <div className='mt-2'>
          {formDataValue && (
            <FileAttached
              key={formDataValue?.name}
              file={{ id: '', name: formDataValue?.name, url }}
              handleRemove={handleRemove}
            />
          )}
        </div>
      </FormElementFull>
    </FormWrapper>
  );
};
