import React from 'react';
import Modal from '../../../../../../ui/modal/Modal';
import { useTranslation } from 'react-i18next';
import Label from '../../../../../../ui/label/Label';
import Icon from '../../../../../../ui/icon/Icon';
import Button from '../../../../../../ui/button/Button';
import { ROUTES } from '../../../../../../../constants/routes';
import { useNavigate } from 'react-router-dom';

const PersonalizedMetricsBookMeeting = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics.personalizedMetricsBookMeeting'
  });
  const navigate = useNavigate();
  return (
    <div>
      <Modal.Header
        title={
          <div className='flex gap-1 items-center'>
            {t('title')}
            <Label
              size='medium'
              lookAndFeel='primary'
              style={{ padding: '1px 4px', borderRadius: '4px', fontSize: '14px' }}>
              PRO
            </Label>
          </div>
        }
      />
      <img
        src='/images/personalizedMetrics.svg'
        alt='Personalized Metrics'
        width={'100%'}
        className='mb-8'
      />
      <span className='font-14 weight-600'>{t('subtitle')}</span>
      <div className='flex flex-col gap-2 mt-4'>
        {[1, 2, 3].map((elem) => (
          <div className='flex items-center gap-2' key={elem}>
            <Icon icon='tickCircle' size='small' color='gradient' />
            <span className='font-14 weight-400'>{t(`characteristic${elem}`)}</span>
          </div>
        ))}
      </div>
      <Modal.Buttons>
        <Button
          lookAndFeel='primary'
          text={t('bookMeeting')}
          onClick={() => navigate(ROUTES.BOOK_MEETING)}
          size='medium'
        />
      </Modal.Buttons>
    </div>
  );
};

export default PersonalizedMetricsBookMeeting;
