import { useEffect, useState } from 'react';
import {
  AMAZON_MARKETPLACE_IDS,
  AMAZON_QUERY_PARAMS,
  AMAZON_STATUSES,
  LEVEL_DOMAINS_TO_COUNTRY_CODES
} from '../constants/amzAuth';
import controller from '../environments/controller';
import { amzAuthGetToken } from '../services/amzAuthService';
import setTokenForAmzCustomer from '../services/api/amzCustomers';
import { AmazonAuthType } from '../types/utils/amzAuthType';
import { obtainLevelDomain } from '../utils/parseUrls';
import useSelectedOrganization from './useSelectedOrganization';

export type AmzAuthLoginType = Pick<
  AmazonAuthType,
  'amazon_callback_uri' | 'amazon_state' | 'auth_code'
>;

const BASE_URL = controller().clientUrl;

const DEFAULT_MARKET_PLACE = AMAZON_MARKETPLACE_IDS['es'];

const useAmzAuth = (params?: AmzAuthLoginType) => {
  const [amzParams, setAmzParams] = useState<AmzAuthLoginType | undefined>(params);
  const [isFromAmzAuth, setIsFromAmzAuth] = useState(false);
  const selectedOrganization = useSelectedOrganization();

  const setAmzInLocalStorage = (amzParams: AmzAuthLoginType) => {
    const { amazon_callback_uri, amazon_state } = amzParams;
    if (amazon_callback_uri && amazon_state) {
      localStorage.setItem(AMAZON_QUERY_PARAMS.CALLBACK_URI_PARAM, amazon_callback_uri);
      localStorage.setItem(AMAZON_QUERY_PARAMS.STATE_PARAM, amazon_state);
    }
  };

  const getAmzAuthFromStorage = () => ({
    amazon_callback_uri: localStorage.getItem(AMAZON_QUERY_PARAMS.CALLBACK_URI_PARAM),
    amazon_state: localStorage.getItem(AMAZON_QUERY_PARAMS.STATE_PARAM)
  });

  const handleAmazonRedirect = (status: string) => {
    const url = `${amzParams?.amazon_callback_uri}?status=${status}&amazon_state=${amzParams?.amazon_state}&redirect_uri=${BASE_URL}dashboard&state=200`;
    window.open(url, '_self');
  };
  const handleAmazonEndProcess = (status: string) => {
    const url = `${amzParams?.amazon_callback_uri}?status=${status}&amazon_state=${amzParams?.amazon_state}&redirect_uri=${BASE_URL}dashboard&state=200`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    const amazon_callback_uri =
      params?.amazon_callback_uri || getAmzAuthFromStorage().amazon_callback_uri;
    const amazon_state = params?.amazon_state || getAmzAuthFromStorage().amazon_state;
    if (amazon_callback_uri && amazon_state) {
      const amzParamState = {
        amazon_callback_uri,
        amazon_state
      } as AmzAuthLoginType;
      setAmzInLocalStorage(amzParamState);
      setAmzParams(amzParamState);
      setIsFromAmzAuth(true);
    } else {
      setIsFromAmzAuth(false);
    }
  }, []);

  useEffect(() => {
    if (!params) return;
    const { auth_code } = params;

    if (!isFromAmzAuth || !amzParams) return;

    const { amazon_state } = amzParams;
    let access_token, refresh_token;
    const levelDomain = obtainLevelDomain(amzParams.amazon_callback_uri as string);
    const countryCode = LEVEL_DOMAINS_TO_COUNTRY_CODES[levelDomain] ?? levelDomain;
    const MARKET_PLACE_ID = AMAZON_MARKETPLACE_IDS[countryCode] || DEFAULT_MARKET_PLACE;

    if (!auth_code || !amazon_state) return;

    amzAuthGetToken({
      auth_code,
      amazon_state
    }).then((response) => {
      access_token = response.access_token;
      refresh_token = response.refresh_token;
      const amzProps = {
        marketplace_id: MARKET_PLACE_ID,
        organization_id: selectedOrganization?.id,
        access_token,
        refresh_token
      };
      setTokenForAmzCustomer(amzProps)
        .then((data) => {
          if (data.ERROR) {
            handleAmazonEndProcess(AMAZON_STATUSES.AUTHORIZATION_CODE_USE_FAILURE);
          } else {
            handleAmazonEndProcess(AMAZON_STATUSES.AUTHORIZATION_CODE_USE_SUCCESSFUL);
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    });
  }, [params?.auth_code, amzParams, isFromAmzAuth]);

  return { amzParams, isFromAmzAuth, getAmzAuthFromStorage, handleAmazonRedirect };
};

export default useAmzAuth;
