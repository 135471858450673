import { ActionButton } from '../../../../../ui/actionButton/ActionButton';
import { useDeleteNode } from '../../../hooks/useDeleteNode';

type Props = {
  acv_id?: string;
  node_id: string;
  disabled?: boolean;
};

export const DeleteNodeActionBTN: React.FC<Props> = ({ acv_id, node_id, disabled }) => {
  const { deleteLCANode } = useDeleteNode();

  const handleDelete = async () => {
    if (!acv_id) {
      return;
    }

    await deleteLCANode({ acv_id, node_id });
  };

  return <ActionButton onClick={handleDelete} icon='trash' color='gray-dark' disabled={disabled} />;
};
