import { ChangeEvent, useEffect, useRef } from 'react';
import './styles.scss';
type Props = {
  imgUrl: string;
  description: string;
  handleFile: (fileUploaded: File) => void;
};
function InputFileInvoice({ imgUrl, description, handleFile }: Props) {
  const drop = useRef<any>(null);
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef<any>(null);

  useEffect(() => {
    if (drop && drop.current) {
      const dropCurrent = drop.current;
      dropCurrent.addEventListener('dragover', handleDragOver);
      dropCurrent.addEventListener('drop', handleDrop);

      return () => {
        dropCurrent.removeEventListener('dragover', handleDragOver);
        dropCurrent.removeEventListener('drop', handleDrop);
      };
    }
  });

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    if (target && target.files && target.files.length > 0) {
      const file = target.files[0];
      if (file.type.includes('pdf')) {
        handleFile(file);
      }
    }
  };

  const handleDragOver = (e: ChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: CustomEvent & { dataTransfer?: DataTransfer }) => {
    e.preventDefault();
    e.stopPropagation();
    const dataTransfer = e.dataTransfer;
    if (dataTransfer && dataTransfer.files) {
      const files = dataTransfer.files;
      if (files && files.length) {
        if (files[0].type.includes('pdf')) {
          handleFile(files[0]);
        }
      }
    }
  };

  return (
    <div className='input-file-invoice' ref={drop} onClick={handleClick}>
      <div className='input-file-invoice__img icon-bg-color'>
        <img src={imgUrl} alt='invoice' />
        <div className='input-file-invoice__img__button round highlight-bg-color'>+</div>
        <input
          type='file'
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </div>
      <p className='disabled-text-color body1-bold-font'>{description}</p>
    </div>
  );
}

export default InputFileInvoice;
