import { useTranslation } from 'react-i18next';
import { ITOCBackend, ITOCFrontend } from '../types/entities/logistics';

const useAdapterTocsBack2Front = () => {
  const { t } = useTranslation('', { keyPrefix: 'logistics.tocs' });

  const adaptTocBack2Front = (toc: ITOCBackend): ITOCFrontend => {
    return {
      id: toc.id,
      name: toc.vehicle + '_' + toc.type,
      name_translated: t(toc.vehicle + '_' + toc.type),
      vehicle: toc.vehicle,
      type: toc.type,
      defaultLoad: toc.default_load,
      energyEfficiencyFactor: toc.energy_efficiency_factor,
      status: toc.status,
      category: toc.category,
      wtw: toc.wtw,
      emptyRunning: toc.empty_running,
      location: toc.location
    };
  };

  return { adaptTocBack2Front };
};

export default useAdapterTocsBack2Front;
