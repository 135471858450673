import { useEffect, useState } from 'react';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import FormCalendarDouble from '../../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import InputWrapper from '../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputNumber from '../../../../../ui/formComponents2/inputNumber/InputNumber';
import Select from '../../../../../ui/formComponents2/select/Select';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../ui/button/Button';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import useUnits from './hooks/useUnits';
import useStepCarousel from '../../../../../ui/stepCarouselList/useStepCarousel';
import StepCarouselList from '../../../../../ui/stepCarouselList/StepCarouselList';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import InputFileSingle from '../../../../../ui/formComponents/inputFile/InputFileSingle';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import CustomSkeletonLoader from '../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useConstants from './useConstants';
import FormNumber from '../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import moment from 'moment';

type Props = {
  submit: (element: ICustomEFFront) => void;
  filledForm?: ICustomEFFront;
  error?: ErrorType;
  loading?: boolean;
};

const Form = ({ submit, filledForm, error, loading }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize.create' });
  const { emptyCustomEFDetail, carouselStepsFactorType } = useConstants();
  const [formData, setFormData] = useState<ICustomEFFront>(emptyCustomEFDetail);
  const [loadingButton, setLoadingButton] = useState(false);

  const { steps, handleSelect } = useStepCarousel({
    selected: formData.type,
    stepsText: carouselStepsFactorType
  });

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const handleSelectType = (value: string) => {
    onChangeValue('type')(value);
    handleSelect(value);
  };

  const { units, loading: loadingUnits } = useUnits();

  const handleErrors = () => {
    const optionalsValues =
      formData.type === 'advanced'
        ? ['simpleEF', 'simpleUnit']
        : ['customEF1', 'customEF2', 'customEF3', 'customUnit'];

    optionalsValues.push('doc', 'id', 'uncertaintyGrade');

    const newErrors = checkFormErrors(formData, [], optionalsValues);

    setFormData((prev) => ({ ...prev, errors: newErrors }));

    return !!newErrors?.length;
  };

  const handleCLickSubmit = async () => {
    if (handleErrors()) return;
    setLoadingButton(true);
    submit(formData);
    setLoadingButton(false);
  };

  useEffect(() => {
    if (!filledForm) return;
    setFormData(filledForm);
  }, [filledForm]);

  if (loading) return <CustomSkeletonLoader count={7} />;

  return (
    <>
      <div className='form-wrapper'>
        <FormText
          label={t('factorName')}
          value={formData.name}
          onChange={onChangeValue('name')}
          iconV2='thermometer'
          error={formData.errors.find((error) => error.error === 'name')}
        />
        <FormCalendarDouble
          label={t('date')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          handleStartDateError={handleDateError('startDate')}
          handleEndDateError={handleDateError('endDate')}
          maxEndDate={moment
            .utc(`31-12-${moment.utc().toDate().getFullYear()}`, 'DD-MM-YYYY')
            .format('DD/MM/YYYY')}
          disabled={!!filledForm}
          error={formData.errors.find(
            (error) => error.error === 'startDate' || error.error === 'endDate'
          )}
        />

        <StepCarouselList steps={steps} handleSelect={handleSelectType} blockClick={!!filledForm} />

        {formData.type === 'simple' && (
          <FormElementFull>
            <InputWrapper
              label={t('emissionName')}
              iconV2='multitag'
              disabled={!!filledForm}
              error={formData.errors.find(
                (error) => error.error === 'simpleEF' || error.error === 'simpleUnit'
              )}>
              <InputNumber
                value={formData.simpleEF}
                onChange={onChangeValue('simpleEF')}
                disabled={!!filledForm}
              />
              <span
                className='input-small-font'
                style={{
                  marginLeft: '0.5rem'
                }}>
                Kg CO₂ eq.
              </span>
              <Select
                value={formData.simpleUnit}
                onChange={onChangeValue('simpleUnit')}
                options={units}
                loading={loadingUnits}
                disabled={!!filledForm}
              />
            </InputWrapper>
          </FormElementFull>
        )}
        {formData.type === 'advanced' && (
          <>
            <FormElementFull>
              <InputWrapper
                label={t('customEF')}
                iconV2='multitag'
                disabled={!!filledForm}
                error={formData.errors.find(
                  (error) => error.error === 'customEF1' || error.error === 'customUnit'
                )}>
                <InputNumber
                  value={formData.customEF1}
                  onChange={onChangeValue('customEF1')}
                  disabled={!!filledForm}
                />
                <span
                  className='input-small-font'
                  style={{
                    marginLeft: '0.5rem'
                  }}>
                  Kg CO₂
                </span>
                <Select
                  value={formData.customUnit}
                  onChange={onChangeValue('customUnit')}
                  options={units}
                  loading={loadingUnits}
                  disabled={!!filledForm}
                />
              </InputWrapper>
            </FormElementFull>
            <FormElementFull>
              <InputWrapper
                iconV2='multitag'
                disabled={!!filledForm}
                error={formData.errors.find(
                  (error) => error.error === 'customEF2' || error.error === 'customUnit'
                )}>
                <InputNumber
                  value={formData.customEF2}
                  onChange={onChangeValue('customEF2')}
                  disabled={!!filledForm}
                />
                <span
                  className='input-small-font'
                  style={{
                    marginLeft: '0.5rem'
                  }}>
                  Gr CH4
                </span>
                <Select
                  value={formData.customUnit}
                  onChange={onChangeValue('customUnit')}
                  options={units}
                  loading={loadingUnits}
                  disabled={!!filledForm}
                />
              </InputWrapper>
            </FormElementFull>
            <FormElementFull>
              <InputWrapper
                iconV2='multitag'
                disabled={!!filledForm}
                error={formData.errors.find(
                  (error) => error.error === 'customEF3' || error.error === 'customUnit'
                )}>
                <InputNumber
                  value={formData.customEF3}
                  onChange={onChangeValue('customEF3')}
                  disabled={!!filledForm}
                />
                <span
                  className='input-small-font'
                  style={{
                    marginLeft: '0.5rem'
                  }}>
                  Gr N2O
                </span>
                <Select
                  value={formData.customUnit}
                  onChange={onChangeValue('customUnit')}
                  options={units}
                  loading={loadingUnits}
                  disabled={!!filledForm}
                />
              </InputWrapper>
            </FormElementFull>
          </>
        )}

        <FormElementFull>
          <FormNumber
            max={100}
            iconV2='add'
            label={t('uncertainty')}
            value={formData.uncertaintyGrade}
            onChange={onChangeValue('uncertaintyGrade')}
            disabled={!!filledForm}
            error={formData.errors.find((error) => error.error === 'uncertaintyGrade')}
          />
        </FormElementFull>

        <FormElementFull>
          <InputFileSingle
            label={t('uploadPDF')}
            handleFile={onChangeValue('doc')}
            buttonIcon={'/images/icons/plusWhite.svg'}
            buttonText={formData.doc ? '' : t('uploadDoc')}
            fileName={formData?.doc?.name || ''}
            labelText={formData.doc ? '' : t('dragFiles')}
            allowedTypes={['application/pdf']}
          />
        </FormElementFull>
      </div>

      <FormButtonSection
        style={{
          marginTop: '1rem'
        }}>
        <Button
          lookAndFeel='primary'
          text={t('save')}
          onClick={handleCLickSubmit}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
};

export default Form;
