import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { InputSize } from 'types/utilsEnums/input';
import OnboardingImageWrapper from './onboardingImageWrapper/OnboardingImageWrapper';
import { Session } from 'types/entities/user';
import apiFetch from 'utils/apiFetch';
import { loadUser } from 'actions/auth';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import FormEmail from 'components/ui/formComponents2/formInputs/formEmail/FormEmail';
import { useDispatch } from 'react-redux';
type FormData = {
  email: string;
  errors: ErrorType[];
};
const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormData>({
    email: '',
    errors: []
  });

  const { onChangeValue } = useOnChangeValue<FormData>({ setFormData });
  const navigate = useNavigate();

  const handleOnClick = async () => {
    // Create user and set it in local storage
    const sessionData: Session = {
      user_id: '',
      email: '',
      id: ''
    };
    const response = await apiFetch('POST', '/auth', {
      email: formData.email
    });

    if (response) {
      sessionData.email = response.data.email;
      sessionData.id = response.data.id;
      sessionData.user_id = response.data.user_id;
    }

    await dispatch(loadUser(sessionData));

    navigate(ROUTES.ORGANIZATION_SELECTOR, { replace: true });
  };
  return (
    <OnboardingImageWrapper
      title={t('login.titleLocalDevelopment')}
      subtitle={t('login.subtitleLocalDevelopment')}
      img={'/images/previewApp.svg'}>
      <FormWrapper>
        <FormEmail
          label='Email'
          icon={'/images/icons/person.svg'}
          onChange={onChangeValue('email')}
          value={formData.email}
          size={InputSize.LARGE}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button lookAndFeel='primary' text={t('login.access')} onClick={handleOnClick} />
      </FormButtonSection>
    </OnboardingImageWrapper>
  );
};

export default SignUp;
