import { Holding, OrgInfo } from '../../../../types/entities/holdings';
import { Organization } from '../../../../types/entities/organization';
import numberToDecimal from '../../../../utils/numberToDecimal';

type Props = {
  scope?: number;
  dataHolding: Holding;
  foundOrg: Organization;
};
const useChildsInfo = ({ scope, dataHolding, foundOrg }: Props) => {
  const parseOrg = (org: OrgInfo) => {
    let scope1 = 0;
    let scope2 = 0;
    let scope3 = 0;
    let totalCo2 = 0;

    const categories: any = [];
    org.co2e.forEach((elem) => {
      if (elem.scope === 1) {
        scope1 += elem.total_emissions;
      } else if (elem.scope === 2) {
        scope2 += elem.total_emissions;
      } else if (elem.scope === 3) {
        scope3 += elem.total_emissions;
      }
      if (!scope || elem.scope === scope) {
        totalCo2 += elem.total_emissions;
      }
      const foundEmployeeCategory = categories.find(
        (category: any) => category.category === 'employees'
      );
      if (
        foundEmployeeCategory &&
        (elem.category === 'employees_in_labore' || elem.category === 'employees_in_itinere')
      ) {
        foundEmployeeCategory.total_emissions += elem.total_emissions;
      } else {
        categories.push({
          category: elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, ''),
          total_emissions: elem.total_emissions
        });
      }
    });

    const orgParsed: any = {
      company: (
        <div className='company'>
          <img src={org.holding ? '/images/icons/holding.svg' : '/images/icons/organization.svg'} />
          <span>{org.org_name}</span>
        </div>
      ),
      co2e: numberToDecimal(totalCo2 / 1000),
      scope1: numberToDecimal(scope1 / 1000),
      scope2: numberToDecimal(scope2 / 1000),
      scope3: numberToDecimal(scope3 / 1000),
      id: org.org_id,
      holding: org.holding
    };
    categories.forEach((category: any) => {
      if (orgParsed[category.category]) {
        orgParsed[category.category] += numberToDecimal(category.total_emissions / 1000);
      } else {
        orgParsed[category.category] = numberToDecimal(category.total_emissions / 1000);
      }
    });

    return orgParsed;
  };

  const parentOrgInfo: OrgInfo = {
    org_id: foundOrg?.id ?? '',
    org_name: foundOrg?.company_name ?? '',
    co2e: dataHolding.parent_co2e,
    remaining: 0,
    country: foundOrg?.country ?? '',
    parent_org_name: '',
    parent_org_id: '',
    // data_quality: dataQualityPercentage,
    holding: true
  };

  const parsedValues = dataHolding.childs_info.map((org: OrgInfo) => {
    return parseOrg(org);
  });

  const dataHoldingList = parsedValues.sort((a: any, b: any) => b.co2 - a.co2);

  dataHoldingList.unshift(parseOrg(parentOrgInfo));
  return {
    dataHoldingList
  };
};

export default useChildsInfo;
