import apiFetch from '../../../utils/apiFetch';
import { LCALandingResponse } from '../types/LCALanding';

const URL = '/acv';

const MOCK_DATA: LCALandingResponse = [
  {
    type: 'portfolio',
    value: 8
  },
  {
    type: 'scenarios',
    value: 3
  }
];

export const getLCALanding = async (): Promise<LCALandingResponse> => {
  return MOCK_DATA;

  const response = await apiFetch('GET', URL);

  return response.data;
};
