import { savePDF } from '@progress/kendo-react-pdf';
import { useRef, useState } from 'react';

const useExportPdf = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const pdfRef = useRef(null);

  const handleExportPDF = () => {
    const element = pdfRef.current || document.body;
    const fileName = 'GLEC Report';

    setLoadingButton(true);

    savePDF(
      element,
      {
        paperSize: 'auto',
        margin: 0,
        fileName,
        forcePageBreak: '.page-break'
      },
      () => setLoadingButton(false)
    );
  };

  return { handleExportPDF, pdfRef, loadingButton };
};

export default useExportPdf;
