import { useTranslation } from 'react-i18next';
import Modal from '../../ui/modal/Modal';
import FormSelect from '../../ui/formComponents2/formInputs/formSelect/FormSelect';
import Checkbox from '../../ui/formComponents2/checkbox/Checkbox';
import Button from '../../ui/button/Button';
import { useEffect, useState } from 'react';
import { useUpdateOrgEf } from '../hooks/useUpdateOrgEf';
import { useMITECOOrgEf } from '../../../customHooks/api/useMITECOOrgEf';
import { StatusTag } from '../../ui/statusTag';
import { Status } from '../../../types/utilsEnums/status';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import { useMITECOVersions } from '../../../customHooks/api/useMITECOVersions';

type Props = {
  closeModal: () => void;
};
export const EmissionFactorModal: React.FC<Props> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { data: orgEf } = useMITECOOrgEf();
  const { data: options } = useMITECOVersions();
  const [version, setVersion] = useState<SelectOptionFormat<string>>();
  const dispatch = useDispatch();
  const [hasReadDisclaimer, setHasReadDisclaimer] = useState(false);

  useEffect(() => {
    if (orgEf && options) {
      const selectedVersion = options.find((option) => option.id === orgEf.version);

      setVersion(selectedVersion);
    }
  }, [orgEf, options]);

  const { updateOrgEf } = useUpdateOrgEf();

  const handleUpdateEFVersion = () => {
    if (!version) {
      return;
    }

    updateOrgEf({ id: version.id });
    dispatch(setNotification(t('profile.emissionFactors.modal.success')));
    closeModal();
  };

  return (
    <>
      <Modal.Header title={t('profile.emissionFactors.modal.title')} />
      <Modal.Content>
        <p className='m-0 font-body-b2-r max-w-70ch mb-6'>
          {t('profile.emissionFactors.modal.description')}
        </p>
        <div className='mb-8 flex-col gap-y-4'>
          <div className='w-72'>
            <FormSelect
              label={t('profile.emissionFactors.modal.selectVersion')}
              iconV2='dropdown'
              value={version ?? { id: '', name: '' }}
              options={options}
              onChange={setVersion}
            />
          </div>
          {version?.id !== orgEf?.version && (
            <StatusTag
              status={Status.PENDING}
              text={t('profile.emissionFactors.modal.disclaimer')}
              icon='information'
            />
          )}
        </div>

        <Checkbox
          selected={hasReadDisclaimer}
          onChange={setHasReadDisclaimer}
          text={t('profile.emissionFactors.modal.readDisclaimer')}
        />
        <div className='flex gap-x-3 mt-10'>
          <Button
            lookAndFeel='secondary'
            text={t('profile.emissionFactors.modal.cancel')}
            onClick={closeModal}
            size='medium'
          />
          <Button
            lookAndFeel='primary'
            text={t('profile.emissionFactors.modal.update')}
            onClick={handleUpdateEFVersion}
            size='medium'
            disabled={!hasReadDisclaimer}
          />
        </div>
      </Modal.Content>
    </>
  );
};
