import { primaryCodePatchAdapter } from '../../components/admin/purchases/codeManagement/primary/adapter';
import type { IPrimaryCodeBackend, IPrimaryCodeFrontend } from '../../types/entities/primaryCode';
import apiFetch from '../../utils/apiFetch';
import { IFilter, getUrl } from '../../utils/url';

const URL = '/primary-purchase-code';

export const getPrimaryCodes = (filters: IFilter[]) => async (page: number) => {
  const queryParams = [
    {
      key: 'page',
      value: page
    }
  ];

  try {
    const url = getUrl(URL, {
      queryParams,
      filters
    });

    const response = await apiFetch('GET', url);

    return response.data as Pagination<IPrimaryCodeBackend>;
  } catch (err) {
    return undefined;
  }
};

export const patchPrimaryCode = async (id: string, payload: Partial<IPrimaryCodeFrontend>) => {
  try {
    const url = getUrl(`${URL}/${id}`);

    const response = await apiFetch('PATCH', url, primaryCodePatchAdapter(payload));

    return response.data as IPrimaryCodeBackend;
  } catch (err) {
    return undefined;
  }
};
