import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../../types/table';
import Label from '../../../../../ui/label/Label';

type Props = {
  dataOrigin: string;
  companySize: string;
  carbonFootprint: number;
  reductionGoals: string;
  isoStandards: string;
  sustainabilityCertifications: string;
  emissionFactorSource: string;
};

type IColumnType = {
  key: string;
  value: string;
};

export const useTable = ({
  dataOrigin,
  companySize,
  carbonFootprint,
  reductionGoals,
  isoStandards,
  sustainabilityCertifications,
  emissionFactorSource
}: Props) => {
  const { t } = useTranslation();

  const columns: Column<IColumnType>[] = [
    {
      title: 'Información de la Empresa',
      key: 'companyInfo',
      dataIndex: 'key',
      width: 250,
      render: (key) => {
        return t(`suppliers.${key}`); // aqui te llega -> key: 'dataOrigin', key: 'companySize'
      }
    },
    {
      title: '',
      key: 'value',
      dataIndex: 'value',
      render: (value) => {
        return <Label lookAndFeel='secondary'>{value}</Label>; // hacer label
      }
    }
  ];
  const data = [
    {
      key: 'dataOrigin',
      value: dataOrigin
    },
    {
      key: 'companySize',
      value: companySize
    },
    {
      key: 'carbonFootprint',
      value: `${carbonFootprint} t CO2 eq.`
    },
    {
      key: 'reductionGoals',
      value: reductionGoals
    },
    {
      key: 'isoStandards',
      value: isoStandards
    },
    {
      key: 'sustainabilityCertifications',
      value: sustainabilityCertifications
    },
    {
      key: 'emissionFactorSource',
      value: emissionFactorSource
    }
  ];
  return { columns, data };
};
