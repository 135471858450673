import React from 'react';
import { useTranslation } from 'react-i18next';

const useNaceCodes = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'naceCodes' });
  return [
    {
      id: 'a',
      name: t('a')
    },
    {
      id: 'b',
      name: t('b')
    },
    {
      id: 'c',
      name: t('c')
    },
    {
      id: 'd',
      name: t('d')
    },
    {
      id: 'e',
      name: t('e')
    },
    {
      id: 'f',
      name: t('f')
    },
    {
      id: 'g',
      name: t('g')
    },
    {
      id: 'h',
      name: t('h')
    },
    {
      id: 'i',
      name: t('i')
    },
    {
      id: 'j',
      name: t('j')
    },
    {
      id: 'k',
      name: t('k')
    },
    {
      id: 'l',
      name: t('l')
    },
    {
      id: 'm',
      name: t('m')
    },
    {
      id: 'n',
      name: t('n')
    },
    {
      id: 'o',
      name: t('o')
    },
    {
      id: 'p',
      name: t('p')
    },
    {
      id: 'q',
      name: t('q')
    },
    {
      id: 'r',
      name: t('r')
    },
    {
      id: 's',
      name: t('s')
    },
    {
      id: 't',
      name: t('t')
    },
    {
      id: 'u',
      name: t('u')
    }
  ];
};

export default useNaceCodes;
