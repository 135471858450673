import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  step: number;
  total: number;
}
const StepCount = ({ step, total, ...props }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'interactiveTutorial' });
  return (
    <span className='subtitle3-font disabled-text-color' {...props}>
      {t('step')} {`${step}/${total}`}
    </span>
  );
};

export default StepCount;
