import { HTMLAttributes, ReactNode } from 'react';
import './styles.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const BlueWrapper = ({ children, ...rest }: Props) => {
  const className = `blue-wrapper body1-font tag-bg-text-color ${rest.className}`;
  delete rest.className;

  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
};

export default BlueWrapper;
