import { ReactNode, useContext } from 'react';
import { EmployeeFormContext } from '../../../context/employeeFormContext';
import LanguageSelector from '../languageSelector/LanguageSelector';

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
};

function StepControllerContainer({ title, subtitle, children }: Props) {
  const { logo } = useContext(EmployeeFormContext);

  return (
    <section className='step-page'>
      <div className='step-page-section'>
        <img
          className='step-page-section__logo'
          src={logo || '/images/logoBlack.svg'}
          alt='logo'
          width='10%'
        />
        <LanguageSelector />
        {(title || subtitle) && (
          <div className='step-page-user__header'>
            <h2 className='headline2-font on-light-text-color'>{title}</h2>
            <h3 className='subtitle2-font on-light-text-color'>{subtitle}</h3>
          </div>
        )}
        <div className='step-page-user__form'>{children}</div>
      </div>
    </section>
  );
}

export default StepControllerContainer;
