import { useEffect, useState } from 'react';
import {
  getKnownVehicles,
  getUnknownVehicles,
  getVehicleFuels
} from '../../../../services/api/vehicle';
import { VehicleFuel } from '../../../../types/entities/vehicle';

type Props = {
  brand: string;
  noBrand: boolean;
  noModel: boolean;
  country: string;
  unknownType: string;
};
const useGetDataForm = ({ brand, noBrand, noModel, country, unknownType }: Props) => {
  const [knownVehicles, setKnownVehicles] = useState<any[]>([]);
  const [unknownVehicles, setUnknownVehicles] = useState<any[]>([]);
  const [fuels, setFuels] = useState<VehicleFuel[] | null>(null);

  const fetchData = async () => {
    if (brand && !noModel) {
      const data = await getKnownVehicles(brand);
      if (data) {
        setKnownVehicles(data);
      }
    }

    if (noBrand || noModel) {
      const data = await getUnknownVehicles();
      if (data) {
        setUnknownVehicles(data);
      }
    }
    if (unknownType && country) {
      // For now only Spain
      const data = await getVehicleFuels('ES', unknownType);
      if (data) {
        setFuels(data);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [brand, noBrand, noModel, country, unknownType]);

  return { knownVehicles, unknownVehicles, fuels };
};

export default useGetDataForm;
