import React from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';

const StationaryEmissionsDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <div className='flex-col gap-1'>
      <DescriptionBox
        descriptions={[
          t('ghgReport.stationaryEmissionsDisclaimer.description1'),
          t('ghgReport.stationaryEmissionsDisclaimer.description2'),
          t('ghgReport.stationaryEmissionsDisclaimer.description3'),
          t('ghgReport.stationaryEmissionsDisclaimer.description4')
        ]}
      />
      <DescriptionBox
        descriptions={[
          t('ghgReport.fugitiveEmissionsDisclaimer.description1'),
          t('ghgReport.fugitiveEmissionsDisclaimer.description2')
        ]}
      />
    </div>
  );
};

export default StationaryEmissionsDisclaimer;
