import { validate } from 'email-validator';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { questions } from '../../../../constants/employeeCommutingQuestions';
import { UserContext } from '../../../../context/userContext';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { postEmployeePeriod } from '../../../../services/api/employeePeriod';
import { postEmployees } from '../../../../services/api/employees';
import { Employee, FormEmployeeData } from '../../../../types/entities/employee';
import { FormEmployeePeriodData } from '../../../../types/entities/employeePeriod';
import { CarSize, TransportOptions } from '../../../../types/entitiesEnums/employeePeriod';
import checkFormErrors from '../../../../utils/checkFormErrors';
import { formatDate } from '../../../../utils/formatDate';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElement from '../../../ui/formComponents/formElement/FormElement';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormNumber from '../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';
import useTransportOptions from './hooks/useTransportOptions';

type Props = {
  addEmployee: (value: Employee) => void;
  employees: Employee[];
  type: 'in_itinere' | 'in_labore';
};

function AddEmployee({ addEmployee, employees, type }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  const currentYear = moment().format('YYYY');

  const [fuelTypeOptions, setFuelTypeOptions] = useState<SelectOptionFormat[]>();

  const [formData, setFormData] = useState<FormEmployeeData & FormEmployeePeriodData>({
    email: '',
    name: '',
    transport: { id: '', name: '' },
    size: CarSize.NONE,
    fuelType: { id: '', name: '' },
    totalKm: '',
    weeklyTravels: [],
    numberWeeklyTravels: '',
    startDate: formatDate(oneYearAgo),
    endDate: '',
    situation: { id: 'active', name: t('employees.active') },
    telecommuting: { id: '', name: '' },
    carpooling: { id: '', name: '' },
    errors: []
  });

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const [loadingButton, setLoadingButton] = useState(false);

  const onChangeTransport = (value: SelectOptionFormat) => {
    let size = CarSize.NONE;
    if (value.id === TransportOptions.CAR_SMALL) size = CarSize.SMALL;
    if (value.id === TransportOptions.CAR_MEDIUM) size = CarSize.MEDIUM;
    if (value.id === TransportOptions.CAR_LARGE) size = CarSize.LARGE;
    if (value.id.includes(TransportOptions.CAR)) value.id = TransportOptions.CAR;

    onChangeValue('transport')(value);
    const foundQuestion = questions.children.find(
      (question: { answer: string }) => question.answer === value.id
    );
    if (!foundQuestion) return;

    setFormData((prev) => ({
      ...prev,
      size,
      fuelType: { id: '', name: '' },
      carpooling: { id: '', name: '' }
    }));

    const fuels = foundQuestion?.children?.map((fuel: { answer: any }) => ({
      id: fuel.answer,
      name: t(`employees.${fuel.answer}`)
    }));

    setFuelTypeOptions(fuels);
  };

  const onChangeTelecommuting = (value: SelectOptionFormat) => {
    onChangeValue('telecommuting')(value);

    if (value.id !== 'yes') return;
    setFuelTypeOptions([]);
    setFormData((previous) => {
      const newFormData = { ...previous };
      newFormData.fuelType = { id: '', name: '' };
      newFormData.size = CarSize.NONE;
      newFormData.totalKm = '';
      newFormData.weeklyTravels = [];
      newFormData.carpooling = { id: '', name: '' };
      newFormData.transport = { id: '', name: '' };
      return newFormData;
    });
  };

  const handleErrors = () => {
    let newErrors: ErrorType[] = formData.errors;

    if (formData.email && !validate(formData.email)) {
      newErrors.push({ error: 'email' });
    }

    if (formData.endDate === '') newErrors.push({ error: 'endDate' });
    if (formData.startDate && formData.endDate) {
      const start = moment(formData.startDate, 'DD/MM/YYYY');
      const end = moment(formData.endDate, 'DD/MM/YYYY');

      if (end.isBefore(start)) {
        newErrors.push({
          error: 'endDate',
          description: t('employees.endDateBeforeStartDate') // Add a localized error message
        });
      } else {
        // Remove the endDate error if the dates are valid
        newErrors = newErrors.filter((elem) => elem.error !== 'endDate');
      }
    }
    if (
      employees.find((employee) => employee.email === formData.email && Boolean(formData.email))
    ) {
      newErrors.push({
        error: 'email',
        description: t('employees.emailAlreadyExists')
      });
    }

    if (!formData.email && !formData.name) {
      newErrors.push({
        error: 'emailAndName',
        description: t('employees.errorEmailAndName')
      });
    } else {
      newErrors = newErrors.filter((elem) => elem.error !== 'emailAndName');
    }

    if (formData?.email && !formData.email.includes('@')) {
      newErrors.push({
        error: 'email',
        description: t('employees.notValidEmail')
      });
    }

    if (
      formData.telecommuting.id === 'yes' ||
      formData.telecommuting.id === '' ||
      formData.situation.id !== 'active'
    ) {
      const optionalFields = [
        'email',
        'name',
        'totalKm',
        'transport',
        'size',
        'fuelType',
        'weeklyTravels',
        'carpooling',
        'endDate',
        'numberWeeklyTravels'
      ];

      if (formData.situation.id !== 'active') optionalFields.push('telecommuting');

      newErrors.push(...checkFormErrors(formData, [], optionalFields));

      if (newErrors.length > 0) {
        setFormData((prev) => ({
          ...prev,
          errors: newErrors
        }));
      }
      return newErrors;
    }

    const optionalFields: string[] = ['email', 'name', 'endDate'];

    if (
      formData.weeklyTravels?.length === 0 &&
      !formData.errors.some((elem) => elem.error === 'weeklyTravels')
    ) {
      newErrors.push({ error: 'weeklyTravels' });
    }
    optionalFields.push('size');
    optionalFields.push('carpooling');

    if (!fuelTypeOptions || fuelTypeOptions.length === 0) {
      optionalFields.push('fuelType');
    }

    newErrors.push(...checkFormErrors(formData, [], optionalFields));

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
    }

    return newErrors;
  };

  const handleAddEmployee = async () => {
    const newErrors: ErrorType[] = handleErrors();
    if (!user?.selectedOrganization || newErrors.length !== 0) {
      setLoadingButton(false);
      return;
    }

    setLoadingButton(true);

    // Parsing data
    let renewableEnergy = '';
    const fuel = { ...formData.fuelType };
    if (formData.fuelType.id === 'electric_renewable') {
      renewableEnergy = 'yes';
      fuel.id = 'electric';
    } else if (formData.fuelType.id === 'electric') {
      renewableEnergy = 'no';
    }

    const responseEmployee = await postEmployees(formData, user.selectedOrganization);
    if (responseEmployee?.response?.status === 403) {
      newErrors.push({ error: 'email_exists' });
    }
    const responsePeriod = await postEmployeePeriod(
      responseEmployee.id,
      formData,
      type,
      renewableEnergy,
      fuel,
      user.selectedOrganization
    );

    if (
      responsePeriod?.response?.status === 422 &&
      responsePeriod.response.data === 'Invalid addresses.'
    ) {
      newErrors.push({ error: 'invalidAddress', description: t('error.invalidAddress') });
    }

    if (responsePeriod?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      setLoadingButton(false);
      return;
    }

    addEmployee({
      id: responseEmployee.id,
      email: formData.email,
      name: formData.name,
      status: 'uploaded',
      periods: responsePeriod?.periods
    });
    setLoadingButton(false);
    dispatch(setNotification(t('notification.createEmployee')));
  };

  const onChangeWeeklyTravels = (value: string) => {
    if (Number(value) > 7 || Number(value) < 0) return;

    onChangeValue('weeklyTravels')(Array.from({ length: Number(value) }, (_, index) => index));
  };

  const transportOptions = useTransportOptions();

  return (
    <div className='new-employee'>
      <FormHeader
        title={t('employees.addNewEmployee')}
        description={t('employees.addNewEmployeeDescription')}
      />
      <FormWrapper>
        <FormText
          iconV2='user'
          label={t('employees.email')}
          placeholder={t('employees.writeEmployeeEmail')}
          onChange={onChangeValue('email')}
          value={formData.email}
          error={formData.errors.find(
            (elem) => elem.error == 'email' || elem.error == 'emailAndName'
          )}
        />

        <FormText
          iconV2='user'
          label={t('employees.name')}
          placeholder={t('employees.writeEmployeeName')}
          onChange={onChangeValue('name')}
          value={formData.name}
          error={formData.errors.find((elem) => elem.error === 'name')}
        />

        <FormSelect
          icon={'/images/icons/turnOff.svg'}
          label={t('employees.situation')}
          placeholder={t('employees.selectSituation')}
          options={[
            { id: 'active', name: t('employees.active') },
            { id: 'inactive', name: t('employees.inactive') },
            { id: 'terminated', name: t('employees.terminated') }
          ]}
          value={formData.situation}
          onChange={onChangeValue('situation')}
          error={formData.errors.find((elem) => elem.error === 'situation')}
        />

        <FormElement>
          <FormCalendarDouble
            tooltip={t('employees.dateRangeTooltip')}
            label={t('employees.registerDate')}
            handleChangeStartDate={onChangeValue('startDate')}
            handleChangeEndDate={onChangeValue('endDate')}
            handleStartDateError={handleDateError('startDate')}
            handleEndDateError={handleDateError('endDate')}
            startDate={formData.startDate}
            endDate={''}
            minStartDate={'01/01/1960'}
            minEndDate={'01/01/1960'}
            maxEndDate={`31/12/${currentYear}`}
            error={formData.errors.find(
              (elem) => elem.error === 'startDate' || elem.error === 'endDate'
            )}
          />
        </FormElement>

        {formData.situation.id === 'active' && (
          <FormSelect
            iconV2='user'
            label={t('employeeForm.workModel')}
            error={formData.errors.find((elem) => elem.error === 'telecommuting')}
            placeholder={t('employeeForm.workModel')}
            options={[
              { id: 'yes', name: t('employeeForm.workFromHome') },
              { id: 'no', name: t('employeeForm.goToOffice') }
            ]}
            value={formData.telecommuting}
            onChange={onChangeTelecommuting}
          />
        )}
        {formData.telecommuting?.id === 'no' && formData.situation.id === 'active' && (
          <>
            <FormNumber
              iconV2='calendar'
              label={t(
                `employees.${type === 'in_itinere' ? 'weeklyTravels' : 'weeklyTravelsInLabore'}`
              )}
              placeholder={t('employees.weeklyTravelsPlaceholder')}
              onChange={onChangeWeeklyTravels}
              value={formData.weeklyTravels?.length === 0 ? '' : formData.weeklyTravels?.length}
              error={formData.errors.find((elem) => elem.error === 'weeklyTravels')}
            />

            <FormNumber
              iconV2='calendar'
              tooltip={
                type === 'in_itinere'
                  ? t('employeeForm.dailyTripsTooltip')
                  : t('employeeForm.dailyTripsTooltipInLabore')
              }
              label={t('employees.numberWeeklyTravels')}
              placeholder={t('employees.writeNumberWeeklyTravels')}
              onChange={onChangeValue('numberWeeklyTravels')}
              value={formData.numberWeeklyTravels}
              error={formData.errors.find((elem) => elem.error === 'numberWeeklyTravels')}
            />

            <FormNumber
              iconV2='location'
              label={t('employees.totalKm')}
              placeholder={t('employees.writeTotalKm')}
              onChange={onChangeValue('totalKm')}
              value={formData.totalKm}
              error={formData.errors.find((elem) => elem.error === 'totalKm')}
            />

            <FormSelect
              iconV2='car'
              label={t('employees.transport')}
              placeholder={t('employees.writeTransport')}
              options={transportOptions}
              value={formData.transport}
              onChange={onChangeTransport}
              error={formData.errors.find((elem) => elem.error === 'transport')}
            />

            {formData.size !== CarSize.NONE && (
              <FormSelect
                label={t('employeeForm.carpooling')}
                iconV2='car'
                placeholder={t('yesNoOptions.enterYesNo')}
                options={[
                  { id: 'yes', name: t('employeeForm.carpoolingYes') },
                  { id: 'no', name: t('employeeForm.carpoolingNo') }
                ]}
                value={formData.carpooling}
                onChange={onChangeValue('carpooling')}
                error={formData.errors.find((elem) => elem.error === 'carpooling')}
              />
            )}

            {fuelTypeOptions && fuelTypeOptions.length > 0 && (
              <FormSelect
                iconV2='electricity2'
                label={t('employees.fuelType')}
                placeholder={t('employees.selectFuelType')}
                options={fuelTypeOptions || []}
                value={formData.fuelType}
                onChange={onChangeValue('fuelType')}
                error={formData.errors.find((elem) => elem.error === 'fuelType')}
              />
            )}
          </>
        )}
      </FormWrapper>

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('employees.save')}
          onClick={handleAddEmployee}
          loading={loadingButton}
        />
      </FormButtonSection>
      {formData.errors && formData.errors.find((elem) => elem.error === 'email_exists') && (
        <span className='error-text error-font error-text-color'>{t('error.emailExists')}</span>
      )}
    </div>
  );
}

export default AddEmployee;
