import { useTranslation } from 'react-i18next';
import { SingleBarChart } from '../../../ui/charts/SingleBarChart';
import Tag from '../../../ui/tag/Tag';
import { calculateGapPercentage } from '../utils/utils';

type Props = {
  data: {
    label: string;
    value: string | undefined;
    color: string;
  }[];
  renderMockedData: boolean;
};

const ImpactFacilityAndOrg = ({ data, renderMockedData }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='evolution-comparison-tag small-device-flex'>
        <Tag
          tags={[
            {
              icon1: '',
              text: <span className='on-light-text-color tag-font'>{t('dashboard.total')}</span>,
              text2: renderMockedData
                ? calculateGapPercentage(0, 0)
                : calculateGapPercentage(Number(data[0]?.value), Number(data[1]?.value))
            }
          ]}
        />
      </div>
      <SingleBarChart chartData={[data]} disabled={renderMockedData} />
    </>
  );
};

export default ImpactFacilityAndOrg;
