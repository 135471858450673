import { InvoiceFormData } from '../constants';

type Params = {
  mode: string;
  formData: InvoiceFormData;
};

export const buildSupplier = ({ mode, formData }: Params) => {
  let supplier: SelectOptionFormat | undefined;

  if (mode === 'default') {
    supplier = formData.supplier as SelectOptionFormat;
  }

  return supplier;
};
