import { useParams } from 'react-router-dom';
import FrameworkScopes from './components/FrameworkScopes';
import YearComparisonBarChart from './components/YearComparisonBarChart';
import './styles.scss';
import {
  useTotalDashboard,
  MonthlyDataPoint
} from 'components/dashboard/dashboardTotal/hooks/useTotalDashboard';
import { useContext, useEffect, useState } from 'react';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { useTranslation } from 'react-i18next';
import { checkFreePlan } from 'utils/checkFreePlan';
import LoaderCard from 'components/ui/loaders/loaderCard/LoaderCard';
import List from 'components/dashboard/dashboardCommon/list/List';
import useTranslatedMonths from './utils/useTranslatedMonths';
import { primary_color_5opacity, highlight_color2_5opacity, blocked_color } from 'styles/colors';
import CardPanel from 'components/ui/cardPanel/CardPanel';
import Dot from 'components/ui/dot/Dot';
import { ReactComponent as SVGDashedLine } from 'assets/img/dashed_line_purple.svg';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from 'components/ui/formComponents2/select/Select';
import { FilterDatesContext } from 'context/filterDatesContext';

const extractUniqueCategories = (data: MonthlyDataPoint[]): SelectOptionFormat[] => {
  const { t } = useTranslation('translation', { keyPrefix: 'categories' });

  // Mapping of individual categories to consolidated category names
  const categoryMapping: { [key: string]: string } = {
    transport: 'vehicles',
    freight: 'vehicles',
    vehicles: 'vehicles',
    employees: 'employees',
    employees_in_labore: 'employees',
    employees_in_itinere: 'employees'
  };
  const uniqueCategories = data.reduce<SelectOptionFormat[]>((acc, monthData) => {
    monthData.category.forEach((cat) => {
      // Check if the category should be mapped to a consolidated name
      const consolidatedId = categoryMapping[cat.id] || cat.id;

      if (!acc.some((existingCat) => existingCat.id === consolidatedId)) {
        acc.push({
          id: consolidatedId,
          name: t(consolidatedId)
        });
      }
    });
    return acc;
  }, []);

  return [{ id: 'totalFootprint', name: t('totalFootprint') }, ...uniqueCategories];
};
const FrameworkDashboard = () => {
  const { frameworkName } = useParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  const [category, setCategory] = useState<SelectOptionFormat>({
    id: 'totalFootprint',
    name: t('totalFootprint')
  });
  const [scope, setScope] = useState<SelectOptionFormat>();
  const [organization, setOrganization] = useState<SelectOptionFormat>();
  const [organizationFilterOptions, setOrganizationFilterOptions] = useState<SelectOptionFormat[]>(
    []
  );
  const { startDate } = useContext(FilterDatesContext);
  const {
    loading,
    disabled,
    dataScopes,
    dataHolding,
    totalCo2,
    monthlyDataThisYear,
    monthlyDataLastYear
  } = useTotalDashboard();
  const foundOrgHolding = useSelectedOrganization();
  const freeTrialPlan = checkFreePlan(foundOrgHolding);
  const categories = extractUniqueCategories(monthlyDataThisYear);
  // First filter options
  const initialFilterOptions = [
    { id: 'totalFootprint', name: t('totalFootprint') },
    { id: 'scope_1', name: t('scope1') },
    { id: 'scope_2', name: t('scope2') },
    { id: 'scope_3', name: t('scope3') }
  ];

  useEffect(() => {
    if (dataHolding) {
      // Build parent organization info
      const parentOrgInfo: SelectOptionFormat = {
        id: foundOrgHolding?.id ?? 'parentOrg',
        name: foundOrgHolding?.company_name ?? 'Parent Organization'
      };

      // Build child organization options
      const childOrgOptions = dataHolding.childs_info.map((child) => ({
        id: child.org_id,
        name: child.org_name
      }));

      // Combine parent and child options
      setScope(initialFilterOptions[0]);
      setOrganizationFilterOptions([parentOrgInfo, ...childOrgOptions]);
    }
  }, [dataHolding?.parent_org_id, foundOrgHolding?.id]);
  const labels = useTranslatedMonths();

  const datasets = {
    thisYear: monthlyDataThisYear, // Total emissions for each month this year
    lastYear: monthlyDataLastYear // Total emissions for each month last year
  };
  return (
    <div className='framework-dashboard__body'>
      {frameworkName && (
        <>
          <FrameworkScopes
            frameworkName={frameworkName}
            setScope={setScope}
            setOrganization={setOrganization}
            scopeOptions={initialFilterOptions}
            companyOptions={organizationFilterOptions}
            loading={loading}
            dataHolding={dataHolding}
            totalCo2={totalCo2}
            dataScopes={dataScopes}
            datasets={datasets}
          />
        </>
      )}
      {scope?.id === 'totalFootprint' ? (
        <CardPanel>
          <CardPanel.Header
            title={
              <div className='flex gap-4 items-center'>
                {t('footprintProgress')}
                <img
                  className='h-6'
                  src='/images/placeholders/segmentedTabs.png'
                  alt='segmented tabs'
                />
              </div>
            }
            style={{
              alignItems: 'center'
            }}>
            <div className='flex-col gap-2'>
              <InputWrapper icon={'/images/icons/filter.svg'}>
                <Select
                  options={categories}
                  value={category}
                  onChange={setCategory}
                  placeholder={t('filterBy')}
                />
              </InputWrapper>
              <div className='flex gap-2 justify-end'>
                <div className='on-card-gray-bg-color progress-legend'>
                  <Dot type={'gradient'} />
                  <span className='font-10 weight-600'>{startDate.split('/')[2]}</span>
                </div>
                <div className='on-card-gray-bg-color progress-legend'>
                  <div
                    style={{
                      marginRight: '0.3rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <SVGDashedLine />
                  </div>
                  <span className='font-10 weight-600'>
                    {(parseInt(startDate.split('/')[2], 10) - 1).toString()}
                  </span>
                </div>
              </div>
            </div>
          </CardPanel.Header>
          <CardPanel.Body>
            <YearComparisonBarChart
              loading={loading}
              labels={labels}
              datasets={datasets}
              scope='total'
              thisYearColor1={primary_color_5opacity}
              thisYearColor2={highlight_color2_5opacity}
              lastYearColor={blocked_color}
              category={category.id === 'totalFootprint' ? undefined : category}
            />
          </CardPanel.Body>
        </CardPanel>
      ) : dataHolding ? (
        <List data={dataHolding} loading={loading} disabled={disabled} freeTrial={freeTrialPlan} />
      ) : (
        <LoaderCard style={{ gridColumn: '1 / -1' }} />
      )}
    </div>
  );
};

export default FrameworkDashboard;
