import { HTMLAttributes } from 'react';
import './styles.scss';

interface Props extends HTMLAttributes<HTMLSpanElement> {
  id: string;
  selected: boolean;
  name: React.ReactNode;
  blocked?: boolean;
  handleOnClick: (id: string) => void;
}
const ToggleElement = ({ id, selected, name, blocked, handleOnClick, ...rest }: Props) => {
  const className = `status-switch ${
    selected ? 'highlight-bg-color on-dark-text-color' : 'disabled-text-color'
  } body1-font`;

  const onClick = () => {
    if (blocked) {
      return;
    }
    handleOnClick(id);
  };

  return (
    <div className={className} {...rest} onClick={onClick}>
      {name}
    </div>
  );
};

export default ToggleElement;
