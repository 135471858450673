import moment from 'moment';
import { DatadisContract } from '../../../../../../../types/entities/datadisContract';

export const getIntervalOptions = (
  now: string,
  contracts?: DatadisContract[]
): SelectOptionFormat[] => {
  const dateIntervalsOptions: SelectOptionFormat[] = [];

  if (!contracts) return [];

  contracts.forEach((contract) => {
    const startDate = moment(contract.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const endDate = contract.end_date
      ? moment(contract.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : now;

    dateIntervalsOptions.push({
      id: contract.start_date,
      name: `${startDate} - ${endDate}`
    });
  });

  return dateIntervalsOptions;
};
