import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../customHooks/useOnChangeValue';
import useScreenSize from '../../customHooks/useScreenSize';
import useYearOptions from '../../customHooks/useYearOptions';

import dateToTimestamp from '../../utils/dateToTimestamp';
import { getDateOfYear } from '../../utils/getDate';
import Button from '../ui/button/Button';
import FormHeader from '../ui/formComponents/formHeader/FormHeader';
import FormSelect from '../ui/formComponents2/formInputs/formSelect/FormSelect';
import Modal from '../ui/modal/Modal';
import StepCarouselList from '../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../ui/stepCarouselList/useStepCarousel';
import TableComponent from '../ui/table/Table';
import { ValidationCategory } from '../validation/Validation';
import Assumption from '../validation/components/Assumption';
import ErrorDescription from '../validation/components/errorDescription/ErrorDescription';
import { VALIDATION_CATEGORIES, ValidationError } from '../validation/hooks/useGetData';
import useStepsByCategory from '../validation/hooks/useStepsByCategory';
import useGetData from './hooks/useGetData';

type FormData = {
  errors: ErrorType[];
  startDate?: number;
  endDate?: number;
  year: {
    id: string;
    name: string;
  };
};
const Assumptions = () => {
  const { years, maxYear } = useYearOptions();
  const { height } = useScreenSize();

  const [formData, setFormData] = useState<FormData>({
    year: {
      id: maxYear.toString(),
      name: maxYear.toString()
    },
    errors: []
  });
  const [assumptionToEdit, setAssumptionToEdit] = useState<ValidationError>();

  const { t } = useTranslation('translation', { keyPrefix: 'assumptions' });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const stepOptions = useStepsByCategory(ValidationCategory.ALL);

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps
  } = useStepCarousel({
    stepsText: stepOptions
  });

  const { data, loading, setTriggerUpdate } = useGetData({
    start_date: dateToTimestamp(getDateOfYear(Number(formData.year.id), 0, 1)),
    end_date: dateToTimestamp(getDateOfYear(Number(formData.year.id), 11, 31)),
    category: (stepSelected?.id as VALIDATION_CATEGORIES) ?? ''
  });

  const columns = [
    {
      title: t('error'),
      dataIndex: 'error',
      key: 'error'
    },
    {
      title: t('assumption'),
      dataIndex: 'assumption',
      key: 'assumption'
    }
  ];

  if (stepSelected?.id === 'consumptions') {
    columns.push({
      title: t('vehicleName'),
      dataIndex: 'vehicle_name',
      key: 'vehicle_name'
    });
  } else {
    columns.push({
      title: t('facilityName'),
      dataIndex: 'facility_name',
      key: 'facility_name'
    });
  }
  columns.push({
    title: '',
    dataIndex: 'edit',
    key: 'edit'
  });

  const parseData = (data: ValidationError[]) => {
    return data
      ?.filter((elem) => elem.assumption)
      .map((elem) => ({
        ...elem,
        error: <ErrorDescription elem={elem} />,
        assumption: elem.assumption?.comment,
        edit: (
          <Button
            lookAndFeel='secondary'
            size='small'
            onClick={() => {
              setAssumptionToEdit(elem);
            }}>
            {t('edit')}
          </Button>
        )
      }));
  };

  return (
    <div className='assumptions'>
      <FormHeader title={t('title')} description={t('description')} />
      <div style={{ width: '40%', marginBottom: '1rem' }}>
        <FormSelect
          icon='/images/icons/calendar50.svg'
          placeholder={t('selectOption')}
          label={t('selectYear')}
          options={years}
          value={formData.year}
          onChange={onChangeValue('year')}
          error={formData.errors.find((elem) => elem.error === 'year')}
          sort={false}
        />
      </div>
      <StepCarouselList steps={steps} handleSelect={handleSelectCarousel} lookAndFeel={'big'} />
      <div className='validation__wrapper on-card-gray-bg-color'>
        <div
          className='validation__list card'
          id='validation_card'
          style={{ maxHeight: height - 270, overflowY: 'scroll' }}>
          <TableComponent size='small' data={parseData(data)} columns={columns} loading={loading} />
        </div>
      </div>
      <Modal show={!!assumptionToEdit} onClose={() => setAssumptionToEdit(undefined)}>
        <Assumption
          error={assumptionToEdit}
          onFinishUpdate={() => {
            setAssumptionToEdit(undefined);
            setTriggerUpdate(true);
          }}
        />
      </Modal>
    </div>
  );
};

export default Assumptions;
