import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { User } from '../../../../types/entities/user';
import apiFetch from '../../../../utils/apiFetch';
import Button from '../../../ui/button/Button';
import './styles.scss';

type Props = {
  deleteEmployee: (id: string) => void;
  employeeToDelete: string;
  user: User | null;
};
function DeleteEmployee({ user, employeeToDelete, deleteEmployee }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteEmployee();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteEmployee = () => {
    if (user) {
      setLoadingButton(true);
      apiFetch(
        'delete',
        `/employees/${employeeToDelete}`,
        {},
        { 'x-organization-id': user.selectedOrganization }
      )
        .then((response) => {
          deleteEmployee(employeeToDelete);
          setLoadingButton(false);
          dispatch(setNotification(t('notification.deleteEmployee')));
        })
        .catch((err) => {
          setLoadingButton(false);
        });
    }
  };

  return (
    <div className='delete-modal'>
      <h1 className='headline4-font'>{t('employees.deleteEmployee')}</h1>
      <h2 className='subtitle3-font '>{t('employees.areYouSureDelete')}</h2>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('employees.delete')}
          onClick={handleDeleteEmployee}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default DeleteEmployee;
