import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  LCAMaterialMetadata,
  LCAProcessMetadata,
  patchLCAMaterialNode
} from '../../../../services/api/lca/lcaPortfolio';

export const usePatchLCAMaterialNode = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: patchLCAMaterialNode,
    onSuccess: (data, params) => {
      queryClient.setQueryData(
        ['lca-node-metadata', params.acv_id],
        (oldData: { material: Array<LCAMaterialMetadata>; process: Array<LCAProcessMetadata> }) => {
          const oldMaterial = oldData.material.find(
            (material) => material.node_id === params.node_id
          );

          const newMaterial = {
            ...oldMaterial,
            node_id: data.node_id,
            name: data.name,
            entity_id: data.items.map((item) => item.id)
          };

          return {
            ...oldData,
            material: (
              JSON.parse(JSON.stringify([...oldData.material])) as Array<LCAMaterialMetadata>
            )
              .filter((material) => material.node_id !== params.node_id)
              .concat(newMaterial)
          };
        }
      );

      queryClient.setQueryData(['lca-node-materials', params.acv_id, params.node_id], data.items);
    }
  });

  return { patchLCAMaterialNode: mutateAsync };
};
