import InputText from '../../inputText/InputText';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children'>;
type InputTextType = React.ComponentProps<typeof InputText>;

type Props = InputTextType & InputWrapperType;

const FormText = (props: Props) => {
  return (
    <InputWrapper {...props}>
      <InputText {...props} />
    </InputWrapper>
  );
};

export default FormText;
