import { useEffect, useState } from 'react';
import { getGroupingById } from '../../../../services/api/grouping';
import { GroupingType } from '../../../../types/entities/grouping';

const useData = (id: string) => {
  const [data, setData] = useState<GroupingType>();

  const fetchGrouping = async () => {
    const data = await getGroupingById(id);
    if (!data) {
      return;
    }
    setData(data);
  };

  useEffect(() => {
    if (id) {
      fetchGrouping();
    }
  }, []);

  return data;
};

export default useData;
