import { IconName } from '../../../types/utils/iconName';
import Icon, { IconSize } from '../icon/Icon';
import { IconColor } from '../IconBase/IconBase';
import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
  icon: IconName;
  color: IconColor;
  disabled?: boolean;
  size?: IconSize;
};

export const ActionButton: React.FC<Props> = ({
  onClick,
  icon,
  color,
  disabled,
  size = 'medium'
}) => {
  const handleClick = () => {
    if (disabled) return;

    onClick();
  };

  return (
    <button className='cursor-pointer h-6 w-6' onClick={handleClick}>
      <Icon
        icon={icon}
        color={disabled ? 'disabled' : color}
        size={size}
        className={styles['action-btn']}
        aria-disabled={disabled}
      />
    </button>
  );
};
