import './styles.scss';

function LoaderFormEmployee() {
  return (
    <div className={`loader-form-employee`}>
      <div className='dot-falling'></div>
    </div>
  );
}

export default LoaderFormEmployee;
