import { useTranslation } from 'react-i18next';
import InformationCard from '../../../../../../../ui/InformationCard/InformationCard';
import Button from '../../../../../../../ui/button/Button';
import FormButtonSection from '../../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  goBack: () => void;
};
const Info = ({ goBack }: Props) => {
  const { t } = useTranslation();

  const cards = [
    // {
    //   id: 'excel',
    //   image: '/images/howTo/excel.png',
    //   description: t('logistics.howToExportExcel'),
    //   url: 'https://dcycle-app-guias.webflow.io/excel'
    // },
    {
      id: 'numbers',
      image: '/images/howTo/numbers.png',
      description: t('logistics.howToExportNumber'),
      url: 'https://dcycle-app-guias.webflow.io/numbers'
    },
    {
      id: 'googleSheets',
      image: '/images/howTo/googleSheets.png',
      description: t('logistics.howToExportGoogleSheets'),
      url: 'https://dcycle-app-guias.webflow.io/google-sheets'
    }
  ];
  return (
    <div className='how-to-export'>
      <FormHeader
        title={t('logistics.howToExportTitle')}
        description={t('logistics.howToExportDescription')}
      />
      <div className='card-list'>
        {cards.map((card) => (
          <InformationCard
            key={card.id}
            image={card.image}
            description={card.description}
            url={card.url}
          />
        ))}
      </div>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('purchases.understoodGoBackAndUpload')}
          onClick={goBack}
        />
      </FormButtonSection>
    </div>
  );
};

export default Info;
