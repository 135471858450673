import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setNotification } from '../../../../actions/notification';
import { UserContext } from '../../../../context/userContext';
import {
  DatadisContractListElementParsedType,
  DatadisContractListElementType
} from '../../../../types/entities/datadisContract';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import ErrorPageNotFound from '../../../layout/errorPage/ErrorPageNotFound/ErrorPageNotFound';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import InfiniteList from '../../../ui/infiniteList/InfiniteList';
import Modal from '../../../ui/modal/Modal';
import AddContract from './addContract/AddContract';
import DeleteContract from './deleteContract/DeleteContract';

const DatadisContracts = () => {
  const { nif } = useParams();
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const url = `/supply_contracts/${nif}`;

  const [contracts, setContracts] = useState<DatadisContractListElementParsedType[]>([]);
  const [total, setTotal] = useState(0);
  const [idToDelete, setIdToDelete] = useState('');

  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = () => setShowAddModal(true);
  const handleHideAddModal = () => setShowAddModal(false);
  const handleHideDelete = () => setIdToDelete('');

  const addContract = (newContract: DatadisContractListElementParsedType) => {
    setContracts((previous) => previous.concat(newContract));
    handleHideAddModal();
    setTotal((previous) => previous + 1);
    dispatch(setNotification(t('notification.addDatadisContract')));
  };

  const removeContract = (idToDelete: string) => {
    setContracts((previous) => previous.filter((contract) => contract.id !== idToDelete));
    handleHideDelete();
    setTotal((previous) => previous - 1);
    dispatch(setNotification(t('notification.deleteDatadisContract')));
  };

  const columns = [
    {
      title: t('datadisContracts.cups'),
      dataIndex: 'cups',
      key: 'cups'
    },
    {
      title: t('datadisContracts.facility'),
      dataIndex: 'facility',
      key: 'facility'
    },
    {
      title: t('datadisContracts.supplier'),
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: t('datadisContracts.startDate'),
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: t('datadisContracts.endDate'),
      dataIndex: 'endDate',
      key: 'endDate'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];

  const renderDropdownComponent = (contract: DatadisContractListElementType) => {
    const options = [
      {
        id: `${contract.id}-delete`,
        name: t('datadisContracts.delete'),
        onClick: () => {
          setIdToDelete(contract.id);
        }
      }
    ];
    return (
      <ButtonDropdown
        button={<img src='/images/icons/editPen.svg' height={19} width={18} alt='edit-pen' />}
        options={options}
      />
    );
  };

  const parseData = (
    contracts: DatadisContractListElementType[]
  ): DatadisContractListElementParsedType[] => {
    return contracts?.map((contract) => ({
      id: contract.id,
      startDate: contract.start_date,
      endDate: contract.end_date ?? t('datadisContracts.now'),
      facility: contract.facility.name,
      supplier: contract.supplier.name,
      cups: contract.cups,
      edit: renderDropdownComponent(contract)
    }));
  };

  if (!nif) return <ErrorPageNotFound />;

  return (
    <>
      <MainPageLayout
        sectionTitle={t('datadisContracts.title')}
        title={t('datadisContracts.start')}
        description={t('datadisContracts.startDescription')}
        breadcrumb={
          <Breadcrumb
            crumbsReplace={[
              {
                key: nif,
                value: nif
              }
            ]}
          />
        }
        buttons={
          <Button
            lookAndFeel={'primary'}
            onClick={handleShowAddModal}
            text={t('datadisContracts.addContract')}
            size='small'
          />
        }>
        <InfiniteList
          i18key={'datadisContracts'}
          url={url}
          values={contracts}
          setValues={setContracts}
          columns={columns}
          parseData={parseData}
          organization={user?.selectedOrganization}
          total={total}
          setTotal={setTotal}
          filters={{
            inputTextKey: 'cups',
            date: true
          }}
        />
        <Modal show={showAddModal} onClose={handleHideAddModal} width='600px' maxWidth='600px'>
          <AddContract addContract={addContract} nif={nif} />
        </Modal>
        <Modal show={Boolean(idToDelete)} onClose={handleHideDelete} width='600px' maxWidth='600px'>
          <DeleteContract idToDelete={idToDelete} nif={nif} removeContract={removeContract} />
        </Modal>
      </MainPageLayout>
    </>
  );
};

export default DatadisContracts;
