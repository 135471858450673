import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../context/userContext';
import { createComment } from '../../../../../../services/api/tasks';
import { TaskComment } from '../../../../../../types/entities/task';
import Button from '../../../../../ui/button/Button';
import InputTextArea from '../../../../../ui/formComponents/inputTextArea/InputTextArea';
import NameIcon from '../../../../../ui/nameIcon/NameIcon';
import './styles.scss';

type Props = {
  taskId: string;
  addComment: (comment: TaskComment) => void;
};
const WriteComment = ({ taskId, addComment }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [comment, setComment] = useState<string>('');
  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async () => {
    if (!user || !comment) return;
    setLoadingButton(true);
    const data = await createComment(taskId, comment, user?.id);
    addComment({
      ...data,
      creator_user: user.first_name + ' ' + user.last_name
    });
    setComment('');
    setLoadingButton(false);
  };
  return (
    <div className='task-write-comment'>
      <NameIcon name={user?.first_name ?? ''} lastName={user?.last_name ?? ''} />
      <InputTextArea
        value={comment}
        onChangeValue={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setComment(e.target.value);
        }}
        maxLength={500}
      />
      <Button
        lookAndFeel='primary'
        size='small'
        text={t('tasks.save')}
        onClick={handleSubmit}
        disabled={!comment}
        loading={loadingButton}
      />
    </div>
  );
};

export default WriteComment;
