import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  adaptPurchasesSpendBasedBackToFront,
  adaptPurchasesSpendBasedFrontToBack,
  adaptPurchasesSupplierSpecificBackToFront,
  adaptPurchasesSupplierSpecificFrontToBack
} from 'adapters/purchases';
import useErrors from 'customHooks/useErrors';
import useSelectedOrganization from 'customHooks/useSelectedOrganization';
import { patchPurchase } from 'services/api/purchases';
import {
  IPurchaseGeneralBackend,
  IPurchaseSpendBasedFormFront,
  IPurchaseSupplierSpecificFormFront,
  PurchaseType
} from 'types/purchases';
import FormSpendBased from '../form/formSpendBased/FormSpendBased';
import FormSupplierSpecific from '../form/formSupplierSpecific/FormSupplierSpecific';
import { injectUnit } from '../utils/injectUnit';

type Props = {
  editPurchase: (purchase: IPurchaseGeneralBackend) => void;
  purchaseToEdit: IPurchaseGeneralBackend;
};

function EditPurchase({ editPurchase, purchaseToEdit }: Props) {
  const { t } = useTranslation();
  const selectedOrganization = useSelectedOrganization();
  const ERRORS = useErrors();

  const [error, setError] = useState<ErrorType>();

  const handleSubmit = async (purchase: Partial<IPurchaseGeneralBackend>) => {
    if (!selectedOrganization || !purchaseToEdit.id) return;
    const response = await patchPurchase(purchaseToEdit.id, selectedOrganization.id, purchase);

    if (!response || response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const data = await injectUnit(response.data);

    editPurchase(data);
  };

  const handleSubmitSpendBase = async (purchase: IPurchaseSpendBasedFormFront) => {
    const newPurchase = adaptPurchasesSpendBasedFrontToBack(purchase);
    await handleSubmit({
      ...newPurchase,
      recycled: 'recycled' in purchaseToEdit ? purchaseToEdit.recycled : newPurchase.recycled,
      purchase_type: PurchaseType.SPEND_BASE
    });
  };

  const handleSupplierSpecific = async (purchase: IPurchaseSupplierSpecificFormFront) => {
    const newPurchase = adaptPurchasesSupplierSpecificFrontToBack(purchase);
    await handleSubmit({ ...newPurchase, purchase_type: PurchaseType.SUPPLIER_SPECIFIC });
  };

  return (
    <div className='flex-col gap-y-8'>
      <h3 className='font-headings-h3-sb'>{t('purchases.editPurchase')}</h3>

      {purchaseToEdit.purchase_type === PurchaseType.SPEND_BASE && (
        <FormSpendBased
          handleSubmit={handleSubmitSpendBase}
          formDataFilled={adaptPurchasesSpendBasedBackToFront(purchaseToEdit)}
          error={error}
          isEditing
        />
      )}

      {purchaseToEdit.purchase_type === PurchaseType.SUPPLIER_SPECIFIC && (
        <FormSupplierSpecific
          handleSubmit={handleSupplierSpecific}
          formDataFilled={adaptPurchasesSupplierSpecificBackToFront(purchaseToEdit)}
          error={error}
        />
      )}
    </div>
  );
}

export default EditPurchase;
