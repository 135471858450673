import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { createLCAConnection } from '../../../../services/api/lca/lcaScenarios';

export const useCreateLCAConnection = () => {
  const queryClient = useQueryClient();

  const org = useSelectedOrganization();

  const { mutate, isPending } = useMutation({
    mutationFn: createLCAConnection,
    onSuccess: (_, { acv_id }) => {
      queryClient.invalidateQueries({
        queryKey: ['lcaBlocks', org?.id, acv_id]
      });
    }
  });

  return { createConnection: mutate, isPending };
};
