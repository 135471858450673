import './styles.scss';

type Props = {
  url: string;
};
const YoutubeVideo = ({ url }: Props) => {
  return (
    <div className='video-player'>
      <iframe
        src={url}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen></iframe>
    </div>
  );
};

export default YoutubeVideo;
