import SlideToggle from '../../../../../ui/formComponents/slideToggle/SlideToggle';

type Props = {
  category: SelectOptionFormat;
  selected: boolean;
  handleSelectCategory: (category: SelectOptionFormat) => void;
};
const CategoryToggle = ({ category, selected, handleSelectCategory }: Props) => {
  const selectCategory = (checked: boolean) => {
    handleSelectCategory(category);
  };

  return <SlideToggle setChecked={selectCategory} label={''} checked={selected} />;
};

export default CategoryToggle;
