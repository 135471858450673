import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../../../layout/sectionHeader/SectionHeader';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import { DatapointsTable } from './components/datapointsTable/DatapointsTable';
import HeaderCard from './components/HeaderCard';
import useSteps from './hooks/useSteps';
import './styles.scss';
import { useEffect, useState } from 'react';

const ESGMetricsDetail = () => {
  const { t } = useTranslation();
  // Obtain category from the URL param
  const { category } = useParams();

  if (!category) {
    return null;
  }

  const steps = useSteps({ category });
  const [parsedSteps, setParsedSteps] = useState(
    steps.map((step) => ({ ...step, title: t(step.text) }))
  );
  const {
    stepSelected,
    handleSelect,
    steps: stepsParsed
  } = useStepCarousel({
    stepsText: parsedSteps
  });

  // qwerty for webinar
  const [onlyFirstStep, setOnlyFirstStep] = useState(false);
  useEffect(() => {
    if (onlyFirstStep && steps.length > 0) {
      setParsedSteps([{ ...steps[0], title: t(steps[0].text) }]); // Set only the first step
      handleSelect(stepsParsed[0].id);
    } else {
      setParsedSteps(steps.map((step) => ({ ...step, title: t(step.text) }))); // Reset to all steps
    }
  }, [onlyFirstStep]);
  return (
    <div className='esg-main-container'>
      <SectionHeader title={t('measureMain.esgMetrics')} breadcrumb={<Breadcrumb />} />
      <HeaderCard keyTranslation={category} />
      {steps.length > 0 ? (
        <StepCarouselList steps={stepsParsed} handleSelect={handleSelect} />
      ) : (
        <div></div>
      )}
      <DatapointsTable
        category={category}
        subcategory={stepSelected?.id ?? ''}
        setOnlyFirstStep={setOnlyFirstStep}
      />
    </div>
  );
};

export default ESGMetricsDetail;
