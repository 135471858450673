import apiFetch from '../../../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../../../../utils/url';

const URL = '/assumptions';

type Params = {
  start_date: number;
  end_date: number;
};

export const getAssumptions = async ({
  start_date,
  end_date
}: Params): Promise<AssumptionsResponse> => {
  const response = await apiFetch(
    'GET',
    getUrl(URL, { queryParams: generateQueryParamsFromObject({ start_date, end_date }) })
  );

  return response.data;
};
