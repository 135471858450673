import {
  AvoidedEmissionFacility,
  AvoidedEmissionFacilityEdit,
  FormFacilityData,
  FormFacilityDataEdit
} from '../../types/entities/avoidedEmission';
import apiFetch from '../../utils/apiFetch';

export const postAvoidedEmission = async (formData: FormFacilityData, organization_id: string) => {
  try {
    const data: AvoidedEmissionFacility = {
      name: formData.name,
      consumption_ids: formData.list,
      total_emissions: Number(formData.quantity),
      consumption_category: formData.category === 'heat' ? 'stationary' : formData.category
    };

    const response = await apiFetch('POST', '/avoided_emissions', data, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteAvoidedEmission = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/avoided_emissions/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAvoidedEmission = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('GET', `/avoided_emissions/${id}`, null, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const patchAvoidedEmission = async (
  id: string,
  formData: FormFacilityDataEdit,
  organization_id: string
) => {
  try {
    const data: AvoidedEmissionFacilityEdit = {
      name: formData.name,
      total_emissions: Number(formData.quantity)
    };

    const response = await apiFetch('PATCH', `/avoided_emissions/${id}`, data, {
      'x-organization-id': organization_id
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
