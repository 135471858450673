import {
  IPrimaryCodeBackend,
  IPrimaryCodeFrontend
} from '../../../../../../types/entities/primaryCode';

export const primaryCodeAdapter = (
  backendResponse: IPrimaryCodeBackend[]
): IPrimaryCodeFrontend[] => {
  const newItems: IPrimaryCodeFrontend[] = backendResponse.map((item) => {
    return {
      id: item.id,
      code: item.code,
      framework: item.framework,
      description: item.primary_description,
      dcycleProduct: item.dcycle_product && {
        id: item.dcycle_product?.id,
        name: item.dcycle_product?.product
      },
      status: item.status,
      codeType: item.code_type
    };
  });

  return newItems;
};

export const primaryCodePatchAdapter = (payload: Partial<IPrimaryCodeFrontend>) => {
  const newPayload = {
    primary_code_id: payload.id,
    dcycle_product_id: payload.dcycleProduct?.id,
    description: payload.description
  };

  return newPayload;
};
