import { ReactNode } from 'react';

import Checkbox from '../../formComponents/checkbox/Checkbox';

export type MulticheckboxOption = {
  id: string;
  label: ReactNode;
  selected: boolean;
};
type Props = {
  items: MulticheckboxOption[];
  onChange: (item: string) => (value: boolean) => void;
};

const InputMultiselector = ({ items, onChange }: Props) => {
  return (
    <>
      {items?.map((item) => (
        <Checkbox
          key={item.id}
          text={item.label}
          onChange={onChange(item.id)}
          selected={item.selected}
          type='square'
        />
      ))}
    </>
  );
};

export default InputMultiselector;
