import { DropdownOptionAPI } from 'nuvo-react';

type DropdownType = DropdownOptionAPI & {
  type: 'string';
};

/* Legacy, use useNuvoCountries instead */
export const countriesNuvo: DropdownType[] = [
  {
    label: 'AW',
    value: 'aw',
    alternativeMatches: ['Aruba'],
    type: 'string'
  },
  {
    label: 'AF',
    value: 'af',
    alternativeMatches: ['Afganistán', 'Afghanistan'],
    type: 'string'
  },
  {
    label: 'AO',
    value: 'ao',
    alternativeMatches: ['Angola'],
    type: 'string'
  },
  {
    label: 'AI',
    value: 'ai',
    alternativeMatches: ['Anguila', 'Anguilla'],
    type: 'string'
  },
  {
    label: 'AX',
    value: 'ax',
    alternativeMatches: ['Islas Aland', 'Aland Islands'],
    type: 'string'
  },
  {
    label: 'AL',
    value: 'al',
    alternativeMatches: ['Albania'],
    type: 'string'
  },
  {
    label: 'AD',
    value: 'ad',
    alternativeMatches: ['Andorra'],
    type: 'string'
  },
  {
    label: 'AE',
    value: 'ae',
    alternativeMatches: ['Emiratos Árabes Unidos', 'United Arab Emirates'],
    type: 'string'
  },
  {
    label: 'AR',
    value: 'ar',
    alternativeMatches: ['Argentina'],
    type: 'string'
  },
  {
    label: 'AM',
    value: 'am',
    alternativeMatches: ['Armenia'],
    type: 'string'
  },
  {
    label: 'AS',
    value: 'as',
    alternativeMatches: ['Samoa Americana', 'American Samoa'],
    type: 'string'
  },
  {
    label: 'AQ',
    value: 'aq',
    alternativeMatches: ['Antártida', 'Antarctica'],
    type: 'string'
  },
  {
    label: 'TF',
    value: 'tf',
    alternativeMatches: [
      'Territorios Franceses del Sur',
      'Tierras Australes y Antarticas Francesas',
      'French Southern Territories'
    ],
    type: 'string'
  },
  {
    label: 'AG',
    value: 'ag',
    alternativeMatches: ['Antigua y Barbuda', 'Antigua and Barbuda'],
    type: 'string'
  },
  {
    label: 'AU',
    value: 'au',
    alternativeMatches: ['Australia'],
    type: 'string'
  },
  {
    label: 'AT',
    value: 'at',
    alternativeMatches: ['Austria'],
    type: 'string'
  },
  {
    label: 'AZ',
    value: 'az',
    alternativeMatches: ['Azerbaiyán', 'Azerbaijan'],
    type: 'string'
  },
  {
    label: 'BA',
    value: 'ba',
    alternativeMatches: ['Bosnia y Hercegovina', 'Bosnia and Herzegovina'],
    type: 'string'
  },
  {
    label: 'BI',
    value: 'bi',
    alternativeMatches: ['Burundi'],
    type: 'string'
  },
  {
    label: 'BE',
    value: 'be',
    alternativeMatches: ['Bélgica', 'Belgium'],
    type: 'string'
  },
  {
    label: 'BJ',
    value: 'bj',
    alternativeMatches: ['Benín', 'Benin'],
    type: 'string'
  },
  {
    label: 'BL',
    value: 'bl',
    alternativeMatches: ['Santa Barthelemy', 'Saint Barthélemy'],
    type: 'string'
  },
  {
    label: 'BQ',
    value: 'bq',
    alternativeMatches: ['Bonaire, Sint Eustatius y Saba', 'Bonaire, Sint Eustatius and Saba'],
    type: 'string'
  },
  {
    label: 'BF',
    value: 'bf',
    alternativeMatches: ['Burkina Faso'],
    type: 'string'
  },
  {
    label: 'BD',
    value: 'bd',
    alternativeMatches: ['Bangladesh'],
    type: 'string'
  },
  {
    label: 'BG',
    value: 'bg',
    alternativeMatches: ['Bulgaria'],
    type: 'string'
  },
  {
    label: 'BH',
    value: 'bh',
    alternativeMatches: ['Bahrein', 'Bahrain'],
    type: 'string'
  },
  {
    label: 'BS',
    value: 'bs',
    alternativeMatches: ['Bahamas'],
    type: 'string'
  },
  {
    label: 'BA',
    value: 'ba',
    alternativeMatches: ['Bosnia y Herzegovina', 'Bosnia and Herzegovina'],
    type: 'string'
  },
  {
    label: 'BL',
    value: 'bl',
    alternativeMatches: ['Santa Barthélemy', 'Saint Barthélemy'],
    type: 'string'
  },
  {
    label: 'BY',
    value: 'by',
    alternativeMatches: ['Bielorrusia', 'Belarus'],
    type: 'string'
  },
  {
    label: 'BZ',
    value: 'bz',
    alternativeMatches: ['Belize', 'Belice'],
    type: 'string'
  },
  {
    label: 'BM',
    value: 'bm',
    alternativeMatches: ['islas Bermudas', 'Bermuda islands'],
    type: 'string'
  },
  {
    label: 'BO',
    value: 'bo',
    alternativeMatches: ['Bolivia'],
    type: 'string'
  },
  {
    label: 'BR',
    value: 'br',
    alternativeMatches: ['Brasil', 'Brazil'],
    type: 'string'
  },
  {
    label: 'BB',
    value: 'bb',
    alternativeMatches: ['Barbados'],
    type: 'string'
  },
  {
    label: 'BN',
    value: 'bn',
    alternativeMatches: ['Brunei Darussalam'],
    type: 'string'
  },
  {
    label: 'BT',
    value: 'bt',
    alternativeMatches: ['Bután', 'Bhutan'],
    type: 'string'
  },
  {
    label: 'BV',
    value: 'bv',
    alternativeMatches: ['Isla bouvet', 'Bouvet Island'],
    type: 'string'
  },
  {
    label: 'BW',
    value: 'bw',
    alternativeMatches: ['Botswana', 'Botsuana'],
    type: 'string'
  },
  {
    label: 'CF',
    value: 'cf',
    alternativeMatches: ['República Centroafricana', 'Central African Republic'],
    type: 'string'
  },
  {
    label: 'CA',
    value: 'ca',
    alternativeMatches: ['Canadá', 'Canada'],
    type: 'string'
  },
  {
    label: 'CC',
    value: 'cc',
    alternativeMatches: ['Islas Cocos', 'Cocos Islands'],
    type: 'string'
  },
  {
    label: 'CH',
    value: 'ch',
    alternativeMatches: ['Suiza', 'Switzerland'],
    type: 'string'
  },
  {
    label: 'CL',
    value: 'cl',
    alternativeMatches: ['Chile'],
    type: 'string'
  },
  {
    label: 'CN',
    value: 'cn',
    alternativeMatches: ['China'],
    type: 'string'
  },
  {
    label: 'CI',
    value: 'ci',
    alternativeMatches: ["Cantas d'Iffioir", 'Costa de Marfil ', 'Songs of Iffioir'],
    type: 'string'
  },
  {
    label: 'CM',
    value: 'cm',
    alternativeMatches: ['Camerún', 'Cameroon'],
    type: 'string'
  },
  {
    label: 'CD',
    value: 'cd',
    alternativeMatches: [
      'Congo, la República Democrítica del',
      'Congo, the Democratic Republic of the'
    ],
    type: 'string'
  },
  {
    label: 'CG',
    value: 'cg',
    alternativeMatches: ['Congo'],
    type: 'string'
  },
  {
    label: 'CK',
    value: 'ck',
    alternativeMatches: ['Islas Cook', 'Cook Islands'],
    type: 'string'
  },
  {
    label: 'CO',
    value: 'co',
    alternativeMatches: ['Colombia'],
    type: 'string'
  },
  {
    label: 'KM',
    value: 'km',
    alternativeMatches: ['Comoras', 'Comores', 'Comoros'],
    type: 'string'
  },
  {
    label: 'CV',
    value: 'cv',
    alternativeMatches: ['Cabo Verde', 'Cabo Verde'],
    type: 'string'
  },
  {
    label: 'CR',
    value: 'cr',
    alternativeMatches: ['Costa Rica'],
    type: 'string'
  },
  {
    label: 'CU',
    value: 'cu',
    alternativeMatches: ['Cuba'],
    type: 'string'
  },
  {
    label: 'CW',
    value: 'cw',
    alternativeMatches: ['Curaçao', 'Curazao'],
    type: 'string'
  },
  {
    label: 'CX',
    value: 'cx',
    alternativeMatches: ['Isla de Navidad', 'Christmas Island'],
    type: 'string'
  },
  {
    label: 'KY',
    value: 'ky',
    alternativeMatches: ['Islas Caimín', 'Cayman Islands'],
    type: 'string'
  },
  {
    label: 'CY',
    value: 'cy',
    alternativeMatches: ['Chipre', 'Cyprus'],
    type: 'string'
  },
  {
    label: 'CZ',
    value: 'cz',
    alternativeMatches: ['Chequia', 'Republica Checa', 'Czech Republic'],
    type: 'string'
  },
  {
    label: 'DE',
    value: 'de',
    alternativeMatches: ['Alemania', 'Germany'],
    type: 'string'
  },
  {
    label: 'DJ',
    value: 'dj',
    alternativeMatches: ['Djibouti'],
    type: 'string'
  },
  {
    label: 'DM',
    value: 'dm',
    alternativeMatches: ['Dominio', 'Dominica', 'Domain'],
    type: 'string'
  },
  {
    label: 'DK',
    value: 'dk',
    alternativeMatches: ['Dinamarca', 'Denmark'],
    type: 'string'
  },
  {
    label: 'DO',
    value: 'do',
    alternativeMatches: ['República Dominicana', 'Dominican Republic'],
    type: 'string'
  },
  {
    label: 'DZ',
    value: 'dz',
    alternativeMatches: ['Argelia', 'Algeria'],
    type: 'string'
  },
  {
    label: 'EC',
    value: 'ec',
    alternativeMatches: ['Ecuador'],
    type: 'string'
  },
  {
    label: 'EG',
    value: 'eg',
    alternativeMatches: ['Egipto', 'Egypt'],
    type: 'string'
  },
  {
    label: 'ER',
    value: 'er',
    alternativeMatches: ['Eritrea'],
    type: 'string'
  },
  {
    label: 'EH',
    value: 'eh',
    alternativeMatches: ['Sahara Occidental', 'Western Sahara'],
    type: 'string'
  },
  {
    label: 'ES',
    value: 'es',
    alternativeMatches: ['España', 'Spain'],
    type: 'string'
  },
  {
    label: 'EE',
    value: 'ee',
    alternativeMatches: ['Estonia'],
    type: 'string'
  },
  {
    label: 'ET',
    value: 'et',
    alternativeMatches: ['Etiopía', 'Ethiopia'],
    type: 'string'
  },
  {
    label: 'FI',
    value: 'fi',
    alternativeMatches: ['Finlandia', 'Finland'],
    type: 'string'
  },
  {
    label: 'FJ',
    value: 'fj',
    alternativeMatches: ['Fiji', 'Fiyi'],
    type: 'string'
  },
  {
    label: 'FK',
    value: 'fk',
    alternativeMatches: ['Islas Malvinas (Malvinas)', 'Falkland Islands (Malvinas)'],
    type: 'string'
  },
  {
    label: 'FR',
    value: 'fr',
    alternativeMatches: ['Francia', 'France'],
    type: 'string'
  },
  {
    label: 'FO',
    value: 'fo',
    alternativeMatches: ['Islas Faroe', 'Islas Feroe', 'Faroe Islands'],
    type: 'string'
  },
  {
    label: 'FM',
    value: 'fm',
    alternativeMatches: [
      'Micronesia, estados federados de',
      'Micronesia, Federated States of',
      'Micronesia'
    ],
    type: 'string'
  },
  {
    label: 'GA',
    value: 'ga',
    alternativeMatches: ['Gabón', 'Gabon'],
    type: 'string'
  },
  {
    label: 'GB',
    value: 'gb',
    alternativeMatches: ['Reino Unido', 'United Kingdom'],
    type: 'string'
  },
  {
    label: 'GE',
    value: 'ge',
    alternativeMatches: ['Georgia'],
    type: 'string'
  },
  {
    label: 'GG',
    value: 'gg',
    alternativeMatches: ['Guernesey', 'Guernsey'],
    type: 'string'
  },
  {
    label: 'GH',
    value: 'gh',
    alternativeMatches: ['Ghana'],
    type: 'string'
  },
  {
    label: 'GI',
    value: 'gi',
    alternativeMatches: ['Gibraltar'],
    type: 'string'
  },
  {
    label: 'GN',
    value: 'gn',
    alternativeMatches: ['Guinea', 'Republica del Guinea'],
    type: 'string'
  },
  {
    label: 'GP',
    value: 'gp',
    alternativeMatches: ['Guadeloupo', 'Guadeloupe'],
    type: 'string'
  },
  {
    label: 'GM',
    value: 'gm',
    alternativeMatches: ['Gambia'],
    type: 'string'
  },
  {
    label: 'GW',
    value: 'gw',
    alternativeMatches: ['Guinea Bissau'],
    type: 'string'
  },
  {
    label: 'GQ',
    value: 'gq',
    alternativeMatches: ['Guinea Ecuatorial', 'Equatorial Guinea'],
    type: 'string'
  },
  {
    label: 'GR',
    value: 'gr',
    alternativeMatches: ['Grecia', 'Greece'],
    type: 'string'
  },
  {
    label: 'GD',
    value: 'gd',
    alternativeMatches: ['Grenada', 'Granada'],
    type: 'string'
  },
  {
    label: 'GL',
    value: 'gl',
    alternativeMatches: ['Groenlandia', 'Greenland'],
    type: 'string'
  },
  {
    label: 'GT',
    value: 'gt',
    alternativeMatches: ['Guatemala'],
    type: 'string'
  },
  {
    label: 'GF',
    value: 'gf',
    alternativeMatches: ['Guayana Francesa', 'French Guiana'],
    type: 'string'
  },
  {
    label: 'GU',
    value: 'gu',
    alternativeMatches: ['Guam'],
    type: 'string'
  },
  {
    label: 'GY',
    value: 'gy',
    alternativeMatches: ['Guayana', 'Guyana'],
    type: 'string'
  },
  {
    label: 'HK',
    value: 'hk',
    alternativeMatches: ['Hong Kong'],
    type: 'string'
  },
  {
    label: 'HM',
    value: 'hm',
    alternativeMatches: ['Escuché Island y Islas McDonald', 'Heard and McDonald Islands'],
    type: 'string'
  },
  {
    label: 'HN',
    value: 'hn',
    alternativeMatches: ['Honduras'],
    type: 'string'
  },
  {
    label: 'HR',
    value: 'hr',
    alternativeMatches: ['Croacia', 'Croatia'],
    type: 'string'
  },
  {
    label: 'HT',
    value: 'ht',
    alternativeMatches: ['Haití', 'Haiti'],
    type: 'string'
  },
  {
    label: 'HU',
    value: 'hu',
    alternativeMatches: ['Hungría', 'Hungary'],
    type: 'string'
  },
  {
    label: 'ID',
    value: 'id',
    alternativeMatches: ['Indonesia'],
    type: 'string'
  },
  {
    label: 'IM',
    value: 'im',
    alternativeMatches: ['Isla del hombre', 'Island of man', 'Isle of Man'],
    type: 'string'
  },
  {
    label: 'IN',
    value: 'in',
    alternativeMatches: ['India'],
    type: 'string'
  },
  {
    label: 'IO',
    value: 'io',
    alternativeMatches: [
      'Territorio Britanico del Oceano Indico',
      'Islas Virgenes Britanicas',
      'British Indian Ocean Territory'
    ],
    type: 'string'
  },
  {
    label: 'IE',
    value: 'ie',
    alternativeMatches: ['Irlanda', 'Ireland'],
    type: 'string'
  },
  {
    label: 'IR',
    value: 'ir',
    alternativeMatches: ['Iràn, República Islamica de', 'Iran', 'Iran, Islamic Republic of'],
    type: 'string'
  },
  {
    label: 'IQ',
    value: 'iq',
    alternativeMatches: ['Irak', 'Iraq'],
    type: 'string'
  },
  {
    label: 'IS',
    value: 'is',
    alternativeMatches: ['Islandia', 'Iceland'],
    type: 'string'
  },
  {
    label: 'IL',
    value: 'il',
    alternativeMatches: ['Israel'],
    type: 'string'
  },
  {
    label: 'IT',
    value: 'it',
    alternativeMatches: ['Italia', 'Italy'],
    type: 'string'
  },
  {
    label: 'JM',
    value: 'jm',
    alternativeMatches: ['Jamaica'],
    type: 'string'
  },
  {
    label: 'JE',
    value: 'je',
    alternativeMatches: ['Jersey'],
    type: 'string'
  },
  {
    label: 'JO',
    value: 'jo',
    alternativeMatches: ['Jordania', 'Jordan'],
    type: 'string'
  },
  {
    label: 'JP',
    value: 'jp',
    alternativeMatches: ['Japón', 'Japan'],
    type: 'string'
  },
  {
    label: 'KZ',
    value: 'kz',
    alternativeMatches: ['Kazajstán', 'Kazakhstan'],
    type: 'string'
  },
  {
    label: 'KE',
    value: 'ke',
    alternativeMatches: ['Kenia', 'Kenya'],
    type: 'string'
  },
  {
    label: 'KG',
    value: 'kg',
    alternativeMatches: ['Kirguistán', 'Kyrgyzstan'],
    type: 'string'
  },
  {
    label: 'KH',
    value: 'kh',
    alternativeMatches: ['Camboya', 'Comboya', 'Cambodia'],
    type: 'string'
  },
  {
    label: 'KI',
    value: 'ki',
    alternativeMatches: ['Kiribati'],
    type: 'string'
  },
  {
    label: 'KN',
    value: 'kn',
    alternativeMatches: ['Saint Kitts y Nevis', 'Saint Kitts and Nevis', 'San Cristobal y Nevis'],
    type: 'string'
  },
  {
    label: 'KR',
    value: 'kr',
    alternativeMatches: ['Corea, república de', 'Corea del Sur', 'Korea, Republic of', 'Korea'],
    type: 'string'
  },
  {
    label: 'KW',
    value: 'kw',
    alternativeMatches: ['Kuwait'],
    type: 'string'
  },
  {
    label: 'LA',
    value: 'la',
    alternativeMatches: ['Laos'],
    type: 'string'
  },
  {
    label: 'LB',
    value: 'lb',
    alternativeMatches: ['Líbano', 'Lebanon'],
    type: 'string'
  },
  {
    label: 'LR',
    value: 'lr',
    alternativeMatches: ['Liberia'],
    type: 'string'
  },
  {
    label: 'LY',
    value: 'ly',
    alternativeMatches: ['Libia', 'Libya'],
    type: 'string'
  },
  {
    label: 'LC',
    value: 'lc',
    alternativeMatches: ['Santa Lucía', 'Santa Lucia'],
    type: 'string'
  },
  {
    label: 'LI',
    value: 'li',
    alternativeMatches: ['Liechtenstein'],
    type: 'string'
  },
  {
    label: 'LK',
    value: 'lk',
    alternativeMatches: ['Sri Lanka'],
    type: 'string'
  },
  {
    label: 'LS',
    value: 'ls',
    alternativeMatches: ['Lesoto', 'Lesotho'],
    type: 'string'
  },
  {
    label: 'LT',
    value: 'lt',
    alternativeMatches: ['Lituania', 'Lithuania'],
    type: 'string'
  },
  {
    label: 'LU',
    value: 'lu',
    alternativeMatches: ['Luxemburgo', 'Luxembourg'],
    type: 'string'
  },
  {
    label: 'LV',
    value: 'lv',
    alternativeMatches: ['Letonia', 'Latvia'],
    type: 'string'
  },
  {
    label: 'MO',
    value: 'mo',
    alternativeMatches: ['Macau', 'Macao'],
    type: 'string'
  },
  {
    label: 'MF',
    value: 'mf',
    alternativeMatches: ['San Martin', 'Saint Martin'],
    type: 'string'
  },
  {
    label: 'MA',
    value: 'ma',
    alternativeMatches: ['Marruecos', 'Morocco'],
    type: 'string'
  },
  {
    label: 'MC',
    value: 'mc',
    alternativeMatches: ['Mónaco', 'Monaco'],
    type: 'string'
  },
  {
    label: 'MD',
    value: 'md',
    alternativeMatches: ['Moldavia, República de', 'Moldavia, Republic of', 'Moldova'],
    type: 'string'
  },
  {
    label: 'MG',
    value: 'mg',
    alternativeMatches: ['Madagascar'],
    type: 'string'
  },
  {
    label: 'MV',
    value: 'mv',
    alternativeMatches: ['Maldivas', 'Maldives'],
    type: 'string'
  },
  {
    label: 'MX',
    value: 'mx',
    alternativeMatches: ['México', 'Mexico'],
    type: 'string'
  },
  {
    label: 'MH',
    value: 'mh',
    alternativeMatches: ['Islas Marshall', 'Marshall Islands'],
    type: 'string'
  },
  {
    label: 'MK',
    value: 'mk',
    alternativeMatches: ['Macedonia del Norte', 'North Macedonia', 'Macedonia'],
    type: 'string'
  },
  {
    label: 'ML',
    value: 'ml',
    alternativeMatches: ['Malí', 'Mali'],
    type: 'string'
  },
  {
    label: 'MT',
    value: 'mt',
    alternativeMatches: ['Malta'],
    type: 'string'
  },
  {
    label: 'MM',
    value: 'mm',
    alternativeMatches: ['Myanmar', 'Birmania'],
    type: 'string'
  },
  {
    label: 'ME',
    value: 'me',
    alternativeMatches: ['Montenegro'],
    type: 'string'
  },
  {
    label: 'MN',
    value: 'mn',
    alternativeMatches: ['Mongolia'],
    type: 'string'
  },
  {
    label: 'MP',
    value: 'mp',
    alternativeMatches: [
      'Islas Marianas del Norte',
      'Marianas del Norte',
      'Northern Mariana Islands'
    ],
    type: 'string'
  },
  {
    label: 'MZ',
    value: 'mz',
    alternativeMatches: ['Mozambique'],
    type: 'string'
  },
  {
    label: 'MR',
    value: 'mr',
    alternativeMatches: ['Mauritania'],
    type: 'string'
  },
  {
    label: 'MS',
    value: 'ms',
    alternativeMatches: ['Montserrat'],
    type: 'string'
  },
  {
    label: 'MQ',
    value: 'mq',
    alternativeMatches: ['Martinica', 'Martinique'],
    type: 'string'
  },
  {
    label: 'MU',
    value: 'mu',
    alternativeMatches: ['Mauricio', 'Mauritius'],
    type: 'string'
  },
  {
    label: 'MW',
    value: 'mw',
    alternativeMatches: ['Malawi'],
    type: 'string'
  },
  {
    label: 'MY',
    value: 'my',
    alternativeMatches: ['Malasia', 'Malaysia'],
    type: 'string'
  },
  {
    label: 'YT',
    value: 'yt',
    alternativeMatches: ['Mayotte'],
    type: 'string'
  },
  {
    label: 'NA',
    value: 'na',
    alternativeMatches: ['Namibia'],
    type: 'string'
  },
  {
    label: 'NC',
    value: 'nc',
    alternativeMatches: ['Nueva Caledonia', 'New Caledonia'],
    type: 'string'
  },
  {
    label: 'NE',
    value: 'ne',
    alternativeMatches: ['Níger', 'Niger'],
    type: 'string'
  },
  {
    label: 'NF',
    value: 'nf',
    alternativeMatches: ['Isla Norfolk', 'Norfolk Island'],
    type: 'string'
  },
  {
    label: 'NG',
    value: 'ng',
    alternativeMatches: ['Nigeria'],
    type: 'string'
  },
  {
    label: 'NI',
    value: 'ni',
    alternativeMatches: ['Nicaragua'],
    type: 'string'
  },
  {
    label: 'NU',
    value: 'nu',
    alternativeMatches: ['Niue'],
    type: 'string'
  },
  {
    label: 'NL',
    value: 'nl',
    alternativeMatches: ['Países Bajos', 'Holanda', 'Netherlands', 'Holland'],
    type: 'string'
  },
  {
    label: 'NO',
    value: 'no',
    alternativeMatches: ['Noruega', 'Norway'],
    type: 'string'
  },
  {
    label: 'NP',
    value: 'np',
    alternativeMatches: ['Nepal'],
    type: 'string'
  },
  {
    label: 'NR',
    value: 'nr',
    alternativeMatches: ['Nauru'],
    type: 'string'
  },
  {
    label: 'NZ',
    value: 'nz',
    alternativeMatches: ['Nueva Zelanda', 'New Zealand'],
    type: 'string'
  },
  {
    label: 'OM',
    value: 'om',
    alternativeMatches: ['Omán', 'Oman'],
    type: 'string'
  },
  {
    label: 'PK',
    value: 'pk',
    alternativeMatches: ['Pakistán', 'Pakistan'],
    type: 'string'
  },
  {
    label: 'PA',
    value: 'pa',
    alternativeMatches: ['Panamá', 'Panama'],
    type: 'string'
  },
  {
    label: 'PN',
    value: 'pn',
    alternativeMatches: ['Pitcairn', 'Pitcairn'],
    type: 'string'
  },
  {
    label: 'PE',
    value: 'pe',
    alternativeMatches: ['Perú', 'Peru'],
    type: 'string'
  },
  {
    label: 'PH',
    value: 'ph',
    alternativeMatches: ['Filipinas', 'Philippines'],
    type: 'string'
  },
  {
    label: 'PW',
    value: 'pw',
    alternativeMatches: ['Palau'],
    type: 'string'
  },
  {
    label: 'PG',
    value: 'pg',
    alternativeMatches: ['Papúa Nueva Guinea', 'Papua New Guinea'],
    type: 'string'
  },
  {
    label: 'PL',
    value: 'pl',
    alternativeMatches: ['Polonia', 'Poland'],
    type: 'string'
  },
  {
    label: 'PR',
    value: 'pr',
    alternativeMatches: ['Puerto Rico'],
    type: 'string'
  },
  {
    label: 'KP',
    value: 'kp',
    alternativeMatches: [
      'República de Corea, Popular Democrítica de',
      "Republic of Korea, Democratic People's"
    ],
    type: 'string'
  },
  {
    label: 'PT',
    value: 'pt',
    alternativeMatches: ['Portugal'],
    type: 'string'
  },
  {
    label: 'PY',
    value: 'py',
    alternativeMatches: ['Paraguay'],
    type: 'string'
  },
  {
    label: 'PS',
    value: 'ps',
    alternativeMatches: ['Palestina, estado de', 'Territorios Palestinos', 'Palestine, state of'],
    type: 'string'
  },
  {
    label: 'PF',
    value: 'pf',
    alternativeMatches: ['Polinesia francés', 'French Polynesia', 'Polinesia Francesa'],
    type: 'string'
  },
  {
    label: 'QA',
    value: 'qa',
    alternativeMatches: ['Katar', 'Qatar'],
    type: 'string'
  },
  {
    label: 'RE',
    value: 're',
    alternativeMatches: ['Reunión'],
    type: 'string'
  },
  {
    label: 'RO',
    value: 'ro',
    alternativeMatches: ['Rumania', 'Romania'],
    type: 'string'
  },
  {
    label: 'RU',
    value: 'ru',
    alternativeMatches: ['Federación Rusa', 'Russian Federation', 'Russia', 'Rusia'],
    type: 'string'
  },
  {
    label: 'RW',
    value: 'rw',
    alternativeMatches: ['Ruanda', 'Rwanda'],
    type: 'string'
  },
  {
    label: 'SA',
    value: 'sa',
    alternativeMatches: ['Arabia Saudita', 'Saudi Arabia'],
    type: 'string'
  },
  {
    label: 'SD',
    value: 'sd',
    alternativeMatches: ['Sudín', 'Sudan'],
    type: 'string'
  },
  {
    label: 'SN',
    value: 'sn',
    alternativeMatches: ['Senegal'],
    type: 'string'
  },
  {
    label: 'SG',
    value: 'sg',
    alternativeMatches: ['Singapur', 'Singapore'],
    type: 'string'
  },
  {
    label: 'GS',
    value: 'gs',
    alternativeMatches: [
      'Georgia del sur y las islas Sandwich del sur',
      'South Georgia and the South Sandwich Islands'
    ],
    type: 'string'
  },
  {
    label: 'SH',
    value: 'sh',
    alternativeMatches: [
      'Santa Helena, Ascensión y Tristan da Cunha',
      'Saint Helena, Ascension and Tristan da Cunha'
    ],
    type: 'string'
  },
  {
    label: 'SJ',
    value: 'sj',
    alternativeMatches: ['Svalbard y Jan Mayen', 'Svalbard and Jan Mayen'],
    type: 'string'
  },
  {
    label: 'SB',
    value: 'sb',
    alternativeMatches: ['Islas Salomón', 'Solomon Islands'],
    type: 'string'
  },
  {
    label: 'SL',
    value: 'sl',
    alternativeMatches: ['Sierra Leona', 'Sierra Leone'],
    type: 'string'
  },
  {
    label: 'SV',
    value: 'sv',
    alternativeMatches: ['El Salvador'],
    type: 'string'
  },
  {
    label: 'SM',
    value: 'sm',
    alternativeMatches: ['San Marino'],
    type: 'string'
  },
  {
    label: 'SO',
    value: 'so',
    alternativeMatches: ['Somalia'],
    type: 'string'
  },
  {
    label: 'PM',
    value: 'pm',
    alternativeMatches: [
      'Saint Pierre y Miquelon',
      'Saint Pierre and Miquelon',
      'San Pedro y Miquelón'
    ],
    type: 'string'
  },
  {
    label: 'RS',
    value: 'rs',
    alternativeMatches: ['Serbia'],
    type: 'string'
  },
  {
    label: 'SS',
    value: 'ss',
    alternativeMatches: ['Sudán del Sur', 'South Sudan'],
    type: 'string'
  },
  {
    label: 'ST',
    value: 'st',
    alternativeMatches: ['Santo Tomé y Príncipe', 'São Tomé and Príncipe'],
    type: 'string'
  },
  {
    label: 'SR',
    value: 'sr',
    alternativeMatches: ['Surinam'],
    type: 'string'
  },
  {
    label: 'SK',
    value: 'sk',
    alternativeMatches: ['Eslovaquia', 'Slovakia'],
    type: 'string'
  },
  {
    label: 'SI',
    value: 'si',
    alternativeMatches: ['Eslovenia', 'Slovenia'],
    type: 'string'
  },
  {
    label: 'SE',
    value: 'se',
    alternativeMatches: ['Suecia', 'Sweden'],
    type: 'string'
  },
  {
    label: 'SZ',
    value: 'sz',
    alternativeMatches: ['Eswatini'],
    type: 'string'
  },
  {
    label: 'SX',
    value: 'sx',
    alternativeMatches: ['Sint Maarten', 'San Martin', 'Saint Martin'],
    type: 'string'
  },
  {
    label: 'SC',
    value: 'sc',
    alternativeMatches: ['Seychelles'],
    type: 'string'
  },
  {
    label: 'SY',
    value: 'sy',
    alternativeMatches: ['República Árabe Siria', 'Siria', 'Arab Republic of Syria'],
    type: 'string'
  },
  {
    label: 'TC',
    value: 'tc',
    alternativeMatches: ['Islas Turcas y Caicos', 'Turks and Caicos Islands'],
    type: 'string'
  },
  {
    label: 'TD',
    value: 'td',
    alternativeMatches: ['Chad'],
    type: 'string'
  },
  {
    label: 'TG',
    value: 'tg',
    alternativeMatches: ['Togo'],
    type: 'string'
  },
  {
    label: 'TH',
    value: 'th',
    alternativeMatches: ['Tailandia', 'Thailand'],
    type: 'string'
  },
  {
    label: 'TJ',
    value: 'tj',
    alternativeMatches: ['Tayikistán', 'Tajikistan', 'Tadjikistan'],
    type: 'string'
  },
  {
    label: 'TK',
    value: 'tk',
    alternativeMatches: ['Tokelau'],
    type: 'string'
  },
  {
    label: 'TM',
    value: 'tm',
    alternativeMatches: ['Turkmenistán', 'Turkmenistan'],
    type: 'string'
  },
  {
    label: 'TL',
    value: 'tl',
    alternativeMatches: ['Timor-Leste', 'East Timor', 'Timor Oriental'],
    type: 'string'
  },
  {
    label: 'TO',
    value: 'to',
    alternativeMatches: ['Tonga'],
    type: 'string'
  },
  {
    label: 'TT',
    value: 'tt',
    alternativeMatches: ['Trinidad y Tobago', 'Trinidad and Tobago'],
    type: 'string'
  },
  {
    label: 'TN',
    value: 'tn',
    alternativeMatches: ['Túnez', 'Tunisia'],
    type: 'string'
  },
  {
    label: 'TR',
    value: 'tr',
    alternativeMatches: ['Turkey', 'Turquia'],
    type: 'string'
  },
  {
    label: 'TV',
    value: 'tv',
    alternativeMatches: ['Tuvalu'],
    type: 'string'
  },
  {
    label: 'TW',
    value: 'tw',
    alternativeMatches: ['Taiwán', 'Taiwan'],
    type: 'string'
  },
  {
    label: 'TZ',
    value: 'tz',
    alternativeMatches: ['Tanzania'],
    type: 'string'
  },
  {
    label: 'UG',
    value: 'ug',
    alternativeMatches: ['Uganda'],
    type: 'string'
  },
  {
    label: 'UA',
    value: 'ua',
    alternativeMatches: ['Ucrania', 'Ukraine'],
    type: 'string'
  },
  {
    label: 'UM',
    value: 'um',
    alternativeMatches: [
      'Islas menores de los Estados Unidos',
      'Minor islands of the United States'
    ],
    type: 'string'
  },
  {
    label: 'UY',
    value: 'uy',
    alternativeMatches: ['Uruguay'],
    type: 'string'
  },
  {
    label: 'US',
    value: 'us',
    alternativeMatches: ['Estados Unidos', 'United States'],
    type: 'string'
  },
  {
    label: 'UZ',
    value: 'uz',
    alternativeMatches: ['Uzbekistán', 'Uzbekistan'],
    type: 'string'
  },
  {
    label: 'VA',
    value: 'va',
    alternativeMatches: [
      'Santa Sede (Estado de la Ciudad del Vaticano)',
      'Vaticano',
      'Holy See (State of Vatican City)',
      'Vatican'
    ],
    type: 'string'
  },
  {
    label: 'VC',
    value: 'vc',
    alternativeMatches: ['San Vicente y las Granadinas', 'Saint Vincent and the Grenadines'],
    type: 'string'
  },
  {
    label: 'VE',
    value: 've',
    alternativeMatches: ['Venezuela'],
    type: 'string'
  },
  {
    label: 'VG',
    value: 'vg',
    alternativeMatches: ['Islas Vírgenes, Britínica', 'British Virgin Islands'],
    type: 'string'
  },
  {
    label: 'VI',
    value: 'vi',
    alternativeMatches: ['Islas Vírgenes, EE. UU.', 'Virgin Islands, USA'],
    type: 'string'
  },
  {
    label: 'VN',
    value: 'vn',
    alternativeMatches: ['Vietnam'],
    type: 'string'
  },
  {
    label: 'VU',
    value: 'vu',
    alternativeMatches: ['Vanuatu', 'Vanuatu'],
    type: 'string'
  },
  {
    label: 'WF',
    value: 'wf',
    alternativeMatches: ['Wallis y Futuna', 'Wallis and Futuna'],
    type: 'string'
  },
  {
    label: 'WS',
    value: 'ws',
    alternativeMatches: ['Samoa'],
    type: 'string'
  },
  {
    label: 'YE',
    value: 'ye',
    alternativeMatches: ['Yemen'],
    type: 'string'
  },
  {
    label: 'ZA',
    value: 'za',
    alternativeMatches: ['Sudáfrica', 'South Africa'],
    type: 'string'
  },
  {
    label: 'ZM',
    value: 'zm',
    alternativeMatches: ['Zambia'],
    type: 'string'
  },
  {
    label: 'ZW',
    value: 'zw',
    alternativeMatches: ['Zimbabue', 'Zimbabwe'],
    type: 'string'
  }
];
