import { ReactNode, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmployeeFormContext,
  employeeFormEmpty
} from '../../../../../../context/employeeFormContext';
import { EmployeePeriodFormType } from '../../../../../../types/entities/employeePeriod';
import { CarSize, TransportOptions } from '../../../../../../types/entitiesEnums/employeePeriod';

import { useParams } from 'react-router-dom';
import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';
import useTransportOptions from '../../hooks/useTransportOptions';
import '../styles.scss';

type Props = {
  title?: ReactNode;
};

function StepTransport({ title }: Props) {
  const { t } = useTranslation();
  const { startDate, endDate } = useParams();

  const { formData, setFormData, stepCounter, handleNext, handleBack } =
    useContext(EmployeeFormContext);

  const next = useRef(formData.transport.id === TransportOptions.WALKING ? 'summary' : 'type');

  const handleErrors = () => {
    const newErrors: ErrorType[] = [];

    if (!formData?.transport.id)
      newErrors.push({
        error: 'transport',
        description: t('error.requiredField')
      });

    if (newErrors.length > 0) {
      setFormData((previous: EmployeePeriodFormType) => ({ ...previous, errors: newErrors }));
      return newErrors;
    }
    return false;
  };

  const handleOnChange = (value: SelectOptionFormat) => {
    let newSize = CarSize.NONE;
    if (value.id === TransportOptions.CAR_SMALL) newSize = CarSize.SMALL;
    if (value.id === TransportOptions.CAR_MEDIUM) newSize = CarSize.MEDIUM;
    if (value.id === TransportOptions.CAR_LARGE) newSize = CarSize.LARGE;
    if (value.id.includes(TransportOptions.CAR)) value.id = TransportOptions.CAR;

    const noFuelTypeTransports = [
      TransportOptions.WALKING,
      TransportOptions.ELECTRIC_KICK_SCOOTER,
      TransportOptions.TRAM
    ];
    if (noFuelTypeTransports.includes(value.id as TransportOptions)) next.current = 'summary';
    else next.current = 'type';

    setFormData((previous: EmployeePeriodFormType) => ({
      ...previous,
      transport: value,
      size: newSize,
      carpooling: employeeFormEmpty.carpooling,
      fuelType: employeeFormEmpty.fuelType,
      renewableEnergy: undefined,
      errors: []
    }));
  };

  const handleNextStep = () => {
    if (handleErrors() || !handleNext) return;
    handleNext(next.current);
  };

  if (!handleNext || !handleBack) return null;

  const transportOptions = useTransportOptions();
  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle1', {
        date: `${startDate && convertDateBackToFront(startDate)} - ${
          endDate && convertDateBackToFront(endDate)
        }`
      })}
      subtitle={t('employeeForm.formSubtitle2')}>
      <StepWrapper title={t('employeeForm.transport')} stepNumber={stepCounter}>
        <FormSelect
          icon={'/images/icons/car.svg'}
          placeholder={t('employeeForm.writeTransport')}
          value={formData.transport === 'telecommuting' ? '' : formData.transport}
          onChange={handleOnChange}
          error={formData?.errors?.find((error) => error.error === 'transport')}
          options={transportOptions}
        />
        <Button lookAndFeel={'primary'} onClick={handleNextStep} text={t('employeeForm.next')} />
      </StepWrapper>
      <SwitchStep handleNext={handleNextStep} handleBack={handleBack} />
    </StepControllerContainer>
  );
}

export default StepTransport;
