import { t } from 'i18next';
import { getVehicleFuelsV2 } from 'services/api/vehicle';
import { useQuery } from '@tanstack/react-query';

type Params = {
  country?: string;
  vehicle_type?: string;
};

const useFuels = ({ country, vehicle_type }: Params = {}) => {
  const { data, isLoading } = useQuery({
    queryKey: ['fuels', country, vehicle_type],
    queryFn: () => getVehicleFuelsV2(country, vehicle_type),
    select: (data) =>
      data.map(({ fuel, id, vehicle_types }) => ({
        id: id,
        name: t(`vehicle_fuels.${fuel}`),
        value: fuel,
        vehicle_types
      }))
  });

  return { data: data ?? [], isLoading };
};

export default useFuels;
