import { HTMLAttributes } from 'react';
import Label from '../../label/Label';
import './styles.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  width?: string;
}

const FormSectionTitle = ({ width = '4rem', ...props }: Props) => {
  return (
    <div className='label-sticker-wrapper' {...props}>
      <Label width={width}>{props.children}</Label>
    </div>
  );
};

export default FormSectionTitle;
