import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import ErrorPage from '../ErrorPage';

const ErrorPageSomethingWentWrongEmployeeForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <ErrorPage text={t('errorPage.somethingWentWrong')} goBack={`${ROUTES.EMPLOYEE_FORM}/${id}`} />
  );
};

export default ErrorPageSomethingWentWrongEmployeeForm;
