import { useEffect, useState } from 'react';
import { DEBOUNCE_DELAY } from '../constants';

function useDebounceValue<T>(value: T, delay: number = DEBOUNCE_DELAY): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();

  const handleDebounce = () => {
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    handleDebounce();
  }, [value]);

  return debouncedValue;
}

export default useDebounceValue;
