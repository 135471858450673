import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLcaSoldProductAdapter from '../../../../../../adapters/useLcaSoldProductAdapter';
import useErrors from '../../../../../../customHooks/useErrors';
import { updateUseAndEndOfLife } from '../../../../../../services/api/useAndEndOfLife';
import {
  ILCASoldProductBack,
  ILCASoldProductFront
} from '../../../../../../types/entities/useAndEndOfLife';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import Form from '../form/Form';
import useFetchElementToEdit from './hooks/useFetchElementToEdit';

type Props = {
  editElement: (element: ILCASoldProductBack) => void;
  id?: string;
};

const EditForm = ({ editElement, id }: Props) => {
  const ERRORS = useErrors();
  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife.form' });
  const { adpatFront2Back, adpatBack2Front } = useLcaSoldProductAdapter();

  const { element, loading } = useFetchElementToEdit({ id });

  const [error, setError] = useState<ErrorType>();

  const handleEdit = async (formData: ILCASoldProductFront) => {
    const element = adpatFront2Back(formData);

    const response = await updateUseAndEndOfLife({ element });

    if (!response || response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    editElement(response);
  };

  const elementToEdit = element ? adpatBack2Front(element) : undefined;

  return (
    <>
      <FormHeader title={t('titleEdit')} description={t('descriptionEdit')} />
      <Form
        handleSubmit={handleEdit}
        formDataFilled={elementToEdit}
        loading={loading}
        error={error}
      />
    </>
  );
};

export default EditForm;
