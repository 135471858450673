import { useTranslation } from 'react-i18next';
import { EventDrink } from '../../../../../../types/entities/events';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import InputMultiple from '../../../../../ui/formComponents/inputMultiple/InputMultiple';
import InputNumber from '../../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../../ui/formComponents/inputText/InputText';

type Props = {
  data: EventDrink[];
  onChangeValue: (value: EventDrink) => void;
  icon: string;
  type: string;
};
const InputDrink = ({ data, type, onChangeValue, icon }: Props) => {
  const { t } = useTranslation();

  const onChangeQuantity = (container: string) => (value: string) => {
    onChangeValue({
      type,
      container,
      quantity: +value
    });
  };

  const options = [
    {
      id: 'plastic',
      name: t('eventData.plastic')
    },
    {
      id: 'glass',
      name: t('eventData.glass')
    },
    { id: 'cardboard', name: t('eventData.cardboard') },
    { id: 'aluminium', name: t('eventData.aluminium') }
  ];

  const inputs = [
    {
      input: <InputText icon={icon} placeholder={''} value={t(`eventData.${type}`)} />,
      label: t('eventData.units'),
      tooltip: type === 'water' ? t('eventData.drinksTooltip') : ''
    }
  ];

  options.forEach((option) => {
    const valueFound = data.find((elem) => elem.container === option.id && elem.type === type);
    inputs.push({
      input: (
        <InputNumber
          icon={''}
          placeholder={'0'}
          value={valueFound?.quantity.toString() ?? ''}
          onChangeValue={onChangeQuantity(option.id)}
        />
      ),
      label: option.name,
      tooltip: ''
    });
  });

  return (
    <FormElementFull>
      <InputMultiple inputs={inputs} />
    </FormElementFull>
  );
};

export default InputDrink;
