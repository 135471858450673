import './styles.scss';

type Props = {
  title: string;
  description: string;
};
function CardHeader({ title, description }: Props) {
  return (
    <div className='report-card-header'>
      <div className='title'>
        <div className='border-left'></div>
        <h1 className='body1-bold-font'>{title}</h1>
      </div>
      <p className='body3-font'>{description}</p>
    </div>
  );
}

export default CardHeader;
