import moment from 'moment';
import { HEAVY_FILE_STATUS, HeavyFilesStatus } from '../../../types/entitiesEnums/heavyFilesStatus';

export enum CategoriesUploadedFiles {
  PURCHASES = 'purchases',
  VEHICLE_CONSUMPTIONS = 'vehicle-consumptions',
  TRANSPORT_UPSTREAM = 'transport-and-distribution-upstream',
  TRANSPORT_DOWNSTREAM = 'transport-and-distribution-downstream',
  LOGISTICS_RECHARGES = 'logistics-recharges',
  LOGISTICS_REQUESTS = 'logistics-requests',
  BUSINESS_TRAVELS = 'business-travels',
  WASTES = 'wastes',
  VEHICLES = 'vehicles',
  ESG = 'esg'
}

export const DEPLOY_DATE = moment.utc('2024-04-19 00:00:00', 'YYYY-MM-DD HH:mm:ss'); // Date that the feature was deployed

export const CATEGORY_WITH_DELETE_OPTION = [
  CategoriesUploadedFiles.TRANSPORT_DOWNSTREAM,
  CategoriesUploadedFiles.TRANSPORT_UPSTREAM,
  CategoriesUploadedFiles.PURCHASES,
  CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS,
  CategoriesUploadedFiles.BUSINESS_TRAVELS,
  CategoriesUploadedFiles.WASTES,
  CategoriesUploadedFiles.VEHICLES
];

export const STATUS_WITH_DELETE_OPTION: HeavyFilesStatus[] = [
  HEAVY_FILE_STATUS.ERROR,
  HEAVY_FILE_STATUS.SUCCESS
];

export const CATEGORIES_TRANSLATION = {
  [CategoriesUploadedFiles.PURCHASES]: 'categories.purchases',
  [CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS]: 'categories.vehicles_consumptions',
  [CategoriesUploadedFiles.TRANSPORT_UPSTREAM]: 'categories.transport_distribution_upstream',
  [CategoriesUploadedFiles.TRANSPORT_DOWNSTREAM]: 'categories.transport_distribution_downstream',
  [CategoriesUploadedFiles.LOGISTICS_RECHARGES]: 'categories.logistics_recharges',
  [CategoriesUploadedFiles.LOGISTICS_REQUESTS]: 'categories.logistics_requests',
  [CategoriesUploadedFiles.BUSINESS_TRAVELS]: 'categories.travels',
  [CategoriesUploadedFiles.WASTES]: 'categories.wastes',
  [CategoriesUploadedFiles.VEHICLES]: 'categories.vehicles',
  [CategoriesUploadedFiles.ESG]: 'categories.esg'
} as const;

export const STATUS_TOOLTIP = {
  [HEAVY_FILE_STATUS.ERROR]: 'uploadedFiles.statusTooltip.error',
  [HEAVY_FILE_STATUS.FILE_ERROR]: 'uploadedFiles.statusTooltip.file_error',
  [HEAVY_FILE_STATUS.IN_PROGRESS]: 'uploadedFiles.statusTooltip.in_progress',
  [HEAVY_FILE_STATUS.IN_QUEUE]: 'uploadedFiles.statusTooltip.in_queue',
  [HEAVY_FILE_STATUS.DELETED]: 'uploadedFiles.statusTooltip.deleted',
  [HEAVY_FILE_STATUS.DELETING]: 'uploadedFiles.statusTooltip.deleting',
  [HEAVY_FILE_STATUS.INTERNAL_ERROR]: 'uploadedFiles.statusTooltip.internal_error',
  [HEAVY_FILE_STATUS.SUCCESS]: 'uploadedFiles.statusTooltip.success'
} as const;
