import React from 'react';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../ui/button/Button';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../constants/routes';

type FormData = {
  startDate: string;
  endDate: string;
  intensityMetric: SelectOptionFormat;
  errors: ErrorType[];
};
type Props = {
  handleClose: () => void;
  formData: FormData;
  categoriesSelected: string[];
};
const DisclaimerConfirm = ({ handleClose, formData, categoriesSelected }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'orgDescriptionModal.dcycle_report.disclaimer'
  });
  const handleConfirm = () => {
    window.open(
      `${ROUTES.COMMUNICATE_WEB}?start_date=${formData.startDate}&end_date=${
        formData.endDate
      }&intensity_metric=&categories=${categoriesSelected.join(',')}`
    );
  };
  return (
    <div>
      <FormHeader title={t('title')} description={t('description')} />
      <FormButtonSection>
        <Button lookAndFeel='secondary' onClick={handleClose} text={t('cancel')} />
        <Button lookAndFeel='primary' onClick={handleConfirm} text={t('generate')} />
      </FormButtonSection>
    </div>
  );
};

export default DisclaimerConfirm;
