import React from 'react';
import './styles.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}
const CardWrapper = ({ children, ...rest }: Props) => {
  const otherClassName = rest.className ? rest.className : '';

  delete rest.className;
  return (
    <div className={`card-generic-wrapper ${otherClassName}`} {...rest}>
      {children}
    </div>
  );
};

export default CardWrapper;
