import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { gray_color2, highlight_color, highlight_color2 } from '../../../../styles/colors';

type DataChartType = {
  title: string;
  value: number;
  color: string;
};

type Props = {
  data: DataChartType[];
  datapointsInfo: { name: string; progress: string };
  progress: string;
};

export const EsgMetricChart = ({ data, datapointsInfo: datapoint, progress }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='esgMetrics-chart'>
      <div style={{ position: 'relative', width: '24%' }}>
        <PieChart
          data={data}
          lineWidth={28}
          style={{ width: '100%', height: 'auto' }} // Ensures PieChart fills the container
          totalValue={100}
          rounded
          background={gray_color2}>
          <defs>
            <linearGradient id='gradient1'>
              <stop offset='0%' style={{ stopColor: highlight_color }} />
              <stop offset='100%' style={{ stopColor: highlight_color2 }} />
            </linearGradient>
          </defs>
        </PieChart>
        <div
          style={{
            position: 'absolute',
            top: '47.5%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className='highlight-text-color progress-datapoints-number'>
          <div className='co2-value font-12 weight-700'>{progress}</div>
        </div>
      </div>
      <>
        <div className='progress-datapoints'>
          <div key={datapoint.name} className='progress-datapoints__item'>
            <p className='font-12 weight-600 m-0'>{t(`dashboard.${datapoint.name}`)}</p>
            <p className='font-12 m-0'>{datapoint.progress}</p>
          </div>
        </div>
      </>
    </div>
  );
};
