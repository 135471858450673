import './styles.scss';

type MenuBurgerProps = {
  setIsOpen: (open: boolean) => void;
  open: boolean;
};

const NUMBER_OF_LINES = 3;

const MenuBurger = ({ open, setIsOpen }: MenuBurgerProps) => {
  return (
    <div className='hamburger-lines' onClick={() => setIsOpen(!open)}>
      {new Array(NUMBER_OF_LINES).fill('').map((__, index) => (
        <span
          className={`line highlight-bg-color line-${index + 1} ${open && 'open'}`}
          key={`l-${index + 1}`}></span>
      ))}
    </div>
  );
};

export default MenuBurger;
