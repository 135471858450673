import { useTranslation } from 'react-i18next';
import { IPurchaseGeneralBackend } from '../../../../../types/purchases';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/routes';
import { CategoriesUploadedFiles } from '../../../uploadedFiles/constants';
import { PurchaseType } from '../../../../../types/entitiesEnums/purchases';

type Props = {
  purchase: IPurchaseGeneralBackend;
  handlers: {
    setPurchaseToEdit: (purchase: IPurchaseGeneralBackend) => void;
    setPurchaseToDelete: (purchaseId: string) => void;
    setPurchaseToPauseFrequency: (purchaseId: string) => void;
  };
  disabled?: boolean;
};
export const Dropdown: React.FC<Props> = ({
  purchase,
  handlers: { setPurchaseToEdit, setPurchaseToDelete, setPurchaseToPauseFrequency },
  disabled = false
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const options = [
    {
      id: `${purchase.id}-edit`,
      name: t('purchases.edit'),
      onClick: () => {
        setPurchaseToEdit(purchase);
      },
      disabled: purchase.purchase_type === PurchaseType.SUPPLIER_SPECIFIC
    },
    {
      id: `${purchase.id}-delete`,
      name: t('purchases.delete'),
      onClick: () => {
        if (purchase.id) {
          setPurchaseToDelete(purchase.id);
        }
      }
    }
  ];

  if ('frequency' in purchase && purchase.frequency !== 'once') {
    options.push({
      id: `${purchase.id}-pause`,
      name: t('purchases.pauseFrequency'),
      onClick: () => {
        if (purchase.id) {
          setPurchaseToPauseFrequency(purchase.id);
        }
      }
    });
  }

  if (purchase.file_name) {
    options.push({
      id: `${purchase.id}-go-to-file`,
      name: t('purchases.goToFile'),
      onClick: () => {
        navigate(
          `${ROUTES.MEASURE_UPLOADED_FILES}/${CategoriesUploadedFiles.PURCHASES}?name=${purchase.file_name}`
        );
      }
    });
  }

  return <ButtonDropdown options={options} disabled={disabled} />;
};
