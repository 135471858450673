import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../constants/routes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { UserContext } from '../../../context/userContext';
import { useMITECOOrgEf } from '../../../customHooks/api/useMITECOOrgEf';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { getMeasureCategories } from '../../../services/api/total_impacts';
import { checkFreePlan } from '../../../utils/checkFreePlan';
import { convertStringToDate } from '../../../utils/convertDates';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import CardTooltipList from '../../ui/cardTooltipList/CardTooltipList';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import { ImportEmissionsBanner } from './components/ImportEmissionsBanner';
import { OutdatedEfBanner } from './components/OutdatedEfBanner';
import './styles.scss';

function MeasureMainPage() {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const user = useContext(UserContext);
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);
  const { data: orgEf, isFetching } = useMITECOOrgEf();

  const [data, setData] = useState<MeasureData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.selectedOrganization || dateError) {
        return;
      }
      setLoading(true);
      const data = await getMeasureCategories(
        user?.selectedOrganization,
        convertStringToDate(startDate),
        convertStringToDate(endDate)
      );
      if (data) {
        setData(data);
      }
      setLoading(false);
    };
    fetchData();
  }, [startDate, endDate]);

  let facilitiesData: { total_emissions: number | undefined; category: string } = {
    category: 'facilities',
    total_emissions: undefined
  };

  data.forEach((elem) => {
    if (
      elem.category === 'stationary' ||
      elem.category === 'recharge' ||
      elem.category === 'electricity' ||
      elem.category === 'water' ||
      elem.category === 'wastes' ||
      elem.category === 'waste_water_treatments'
    ) {
      if (!facilitiesData) {
        facilitiesData = {
          category: 'facilities',
          total_emissions: elem.total_emissions
        };
      } else {
        if (!facilitiesData.total_emissions) {
          facilitiesData.total_emissions = 0;
        }
        facilitiesData.total_emissions += elem.total_emissions;
      }
    }
  });

  const passengerVehiclesData = data.find((elem) => elem.category === 'passenger');
  const freightVehiclesData = data.find((elem) => elem.category === 'freight');

  let vehiclesData: {
    category: string;
    total_emissions: number;
  } | null = {
    category: 'vehicles',
    total_emissions:
      (passengerVehiclesData ? passengerVehiclesData.total_emissions : 0) +
      (freightVehiclesData ? freightVehiclesData.total_emissions : 0)
  };

  if (!passengerVehiclesData && !freightVehiclesData) {
    vehiclesData = null;
  }

  const employeesDataInLabore = data.find((elem) => elem.category === 'employees_in_labore');
  const employeesDataInItinere = data.find((elem) => elem.category === 'employees_in_itinere');
  const travelsData = data.find((elem) => elem.category === 'travels');
  const shipmentsData = data.find((elem) => elem.category === 'transport_distribution_downstream');
  const deliveriesData = data.find((elem) => elem.category === 'transport_distribution_upstream');
  const purchasesData = data.find((elem) => elem.category === 'purchases');
  const logisticsData = data.find((elem) => elem.category === 'logistics');
  const soldProductsIntermediateProcessing = data.find(
    (elem) => elem.category === 'sold_products_intermediate_processing'
  );
  const soldProductsUsage = data.find((elem) => elem.category === 'sold_products_usage');
  const soldProductsWaste = data.find((elem) => elem.category === 'sold_products_waste');

  let useAndEndOfLifeData: MeasureData | undefined = undefined;
  if (!!soldProductsIntermediateProcessing || !!soldProductsUsage || !!soldProductsWaste) {
    useAndEndOfLifeData = {
      category: 'use_and_end_of_life',
      total_emissions:
        (soldProductsIntermediateProcessing
          ? soldProductsIntermediateProcessing.total_emissions
          : 0) +
        (soldProductsUsage ? soldProductsUsage.total_emissions : 0) +
        (soldProductsWaste ? soldProductsWaste.total_emissions : 0)
    };
  }

  const foundOrganization = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  const cards: CardTooltip[] = [
    {
      id: 'facilities',
      data: [
        {
          icon: '/images/icons/building.svg',
          title: t('measureMain.facilities'),
          description: t('measureMain.facilitiesDescription'),
          total:
            facilitiesData && facilitiesData.total_emissions !== undefined
              ? facilitiesData.total_emissions
              : t('measureMain.noData'),
          unit: facilitiesData && facilitiesData.total_emissions !== undefined ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_FACILITIES
        }
      ],
      tooltip: t('measureMain.facilitiesTooltip'),
      disabled: false,
      freeTrial: false,
      group: [1, 2, 3]
    },
    {
      id: 'vehicles',
      data: [
        {
          icon: '/images/icons/car.svg',
          title: t('measureMain.vehicles'),
          description: t('measureMain.vehiclesDescription'),
          total: vehiclesData ? vehiclesData.total_emissions : t('measureMain.noData'),
          unit: vehiclesData ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_VEHICLES
        }
        // {
        //   icon: '/images/icons/truck.svg',
        //   title: t('measureMain.merchandiseVehicles'),
        //   description: t('measureMain.merchandiseVehiclesDescription'),
        //   total: freightVehiclesData
        //     ? freightVehiclesData.total_emissions
        //     : t('measureMain.noData'),
        //   unit: freightVehiclesData ? 't CO₂ eq.' : '',
        //   route: ROUTES.MEASURE_VEHICLES_FREIGHT
        // }
      ],
      tooltip: t('measureMain.vehiclesTooltip'),
      disabled: false,
      freeTrial: false,
      group: [1]
    },
    {
      id: 'logistics',
      data: [
        {
          icon: '/images/icons/shipmentGradient.svg',
          title: t('measureMain.logistics'),
          description: t('measureMain.logisticsDescription'),
          total: logisticsData ? logisticsData.total_emissions : t('measureMain.noData'),
          unit: logisticsData ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_LOGISTICS
        }
      ],
      tooltip: t('measureMain.logisticsTooltip'),
      disabled: false,
      freeTrial: false,
      group: [1, 2, 3]
    },
    {
      id: 'employees',
      data: [
        {
          icon: '/images/icons/people.svg',
          title: t('measureMain.employees'),
          description: t('measureMain.employeesDescription'),
          total:
            employeesDataInItinere || employeesDataInLabore
              ? (employeesDataInItinere?.total_emissions ?? 0) +
                (employeesDataInLabore?.total_emissions ?? 0)
              : t('measureMain.noData'),
          unit: employeesDataInItinere || employeesDataInLabore ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_ACTIVITY_EMPLOYEES
        }
      ],
      tooltip: t('measureMain.employeesTooltip'),
      disabled: false,
      freeTrial: false,
      group: [3]
    },
    {
      id: 'businessTravels',
      data: [
        {
          icon: '/images/icons/plane.svg',
          title: t('measureMain.travels'),
          description: t('measureMain.travelsDescription'),
          total: travelsData ? travelsData.total_emissions : t('measureMain.noData'),
          unit: travelsData ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_ACTIVITY_BUSINESS_TRAVELS
        }
      ],
      tooltip: t('measureMain.travelsTooltip'),
      disabled: false,
      freeTrial: false,
      group: [3]
    },
    {
      id: 'shipments',
      data: [
        {
          icon: '/images/icons/cardBox.svg',
          title: t('measureMain.shipments'),
          description: t('measureMain.shipmentsDescription'),
          total: shipmentsData ? shipmentsData.total_emissions : t('measureMain.noData'),
          unit: shipmentsData ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=downstream'
        },
        {
          icon: '/images/icons/cardBox.svg',
          title: t('measureMain.deliveries'),
          description: t('measureMain.deliveriesDescription'),
          total: deliveriesData ? deliveriesData.total_emissions : t('measureMain.noData'),
          unit: deliveriesData ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_ACTIVITY_SHIPMENTS + '?transport_direction=upstream'
        }
      ],
      tooltip: t('measureMain.transportDistributionTooltip'),
      disabled: false,
      freeTrial: false,
      group: [3]
    },
    {
      id: 'purchases',
      data: [
        {
          icon: '/images/icons/shoppingBagGradient.svg',
          title: t('measureMain.purchases'),
          description: t('measureMain.purchasesDescription'),
          total: purchasesData ? purchasesData.total_emissions : t('measureMain.noData'),
          unit: purchasesData ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_ACTIVITY_PURCHASES
        }
      ],
      tooltip: t('measureMain.purchasesTooltip'),
      disabled: false,
      freeTrial: checkFreePlan(foundOrganization),
      group: [3]
    },
    {
      id: 'esgMetrics',
      data: [
        {
          icon: '/images/icons/globe.svg',
          title: t('measureMain.esgMetrics'),
          description: t('measureMain.esgMetricsDescription'),
          total: t('measureMain.noData'),
          unit: '',
          route: ROUTES.MEASURE_ESG_METRICS
        }
      ],
      tooltip: t('measureMain.esgMetricsTooltip'),
      disabled: false,
      freeTrial: checkFreePlan(foundOrganization),
      group: ['CSRD']
    },
    {
      id: 'socialAndGovernance',
      data: [
        {
          icon: '/images/icons/globe.svg',
          title: t('measureMain.socialAndGovernance'),
          description: t('measureMain.socialAndGovernanceDescription'),
          total: t('measureMain.noData'),
          unit: '',
          route: ROUTES.MEASURE_ACTIVITY_SOCIAL_AND_GOVERNANCE
        }
      ],
      tooltip: t('measureMain.socialAndGovernanceTooltip'),
      disabled: false,
      freeTrial: false,
      group: []
    },
    {
      id: 'events',
      data: [
        {
          icon: '/images/icons/pin50.svg',
          title: t('measureMain.events'),
          description: t('measureMain.eventsDescription'),
          total: '',
          unit: '',
          route: ROUTES.MEASURE_EVENTS
        }
      ],
      tooltip: t('measureMain.eventsTooltip'),
      disabled: false,
      freeTrial: false,
      group: []
    },
    {
      id: 'useAndEndOfLife',
      data: [
        {
          icon: '/images/icons/stacked50.svg',
          title: t('measureMain.useAndEndOfLife'),
          description: t('measureMain.useAndEndOfLifeDescription'),
          total: useAndEndOfLifeData
            ? useAndEndOfLifeData.total_emissions
            : t('measureMain.noData'),
          unit: useAndEndOfLifeData ? 't CO₂ eq.' : '',
          route: ROUTES.MEASURE_ACTIVITY_USE_AND_END_OF_LIFE
        }
      ],
      tooltip: t('measureMain.useAndEndOfLifeTooltip'),
      disabled: false,
      freeTrial: checkFreePlan(foundOrganization),
      group: [3]
    }
  ];

  const featureFlag = (array: typeof cards) => {
    return array.filter(
      (element) =>
        !Object.keys(flags as object).includes(element.id) ||
        (flags && flags[element.id as keyof typeof flags])
    );
  };

  const cardsFeatureFlags = featureFlag(cards);
  const cardsFilteredBySector = cardsFeatureFlags.filter((card) => {
    if (foundOrganization?.sector !== 'transport' && card.id === 'logistics') return false;
    return true;
  });

  return (
    <section className='measure-main'>
      <SectionHeader title={t('measureMain.title')} subtitle={t('measureMain.subtitle')} />
      {flags?.updateEfs && !isFetching && !orgEf?.latest && <OutdatedEfBanner />}
      {flags?.importEmissions && <ImportEmissionsBanner />}
      {loading ? <LoaderTables /> : <CardTooltipList cards={cardsFilteredBySector} />}
    </section>
  );
}

export default MeasureMainPage;
