import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../button/Button';
import './styles.scss';

type Props = {
  handleFiles: (filesUploaded: File[]) => void;
  buttonText: string;
  labelText: string;
  fileNames: string[];
  error?: ErrorType;
  allowedTypes: string[];
};
function InputFile({ handleFiles, buttonText, fileNames, labelText, error, allowedTypes }: Props) {
  const drop = useRef<any>(null);
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef<any>(null);

  const [errorType, setErrorType] = useState('');

  useEffect(() => {
    if (drop && drop.current) {
      const dropCurrent = drop.current;
      dropCurrent.addEventListener('dragover', handleDragOver);
      dropCurrent.addEventListener('drop', handleDrop);

      return () => {
        dropCurrent.removeEventListener('dragover', handleDragOver);
        dropCurrent.removeEventListener('drop', handleDrop);
      };
    }
  });

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let newError = '';

    if (target && target.files && target.files.length > 0) {
      const files = Array.from(target.files).filter((file: File) => {
        return allowedTypes?.includes(file.type);
      });
      if (files && files.length) {
        handleFiles(files);
      } else {
        newError = t('error.typeNotValid');
      }
    }
    target.files = null;
    hiddenFileInput.current.value = null;
    setErrorType(newError);
  };

  const handleDragOver = (e: ChangeEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: CustomEvent & { dataTransfer?: DataTransfer }) => {
    e.preventDefault();
    e.stopPropagation();
    let newError = '';
    const dataTransfer = e.dataTransfer;

    if (dataTransfer && dataTransfer.files) {
      const files = Array.from(dataTransfer.files).filter((file: File) =>
        allowedTypes?.includes(file.type)
      );

      if (files && files.length) {
        handleFiles(files);
      } else {
        newError = t('error.typeNotValid');
      }
    }
    setErrorType(newError);
  };

  const { t } = useTranslation();

  return (
    <div className='input-file dashed-border' ref={drop}>
      <Button lookAndFeel='primary' text={buttonText} onClick={handleClick} size='medium'></Button>
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        multiple
      />
      {/* {fileNames.map((fileName) => (
        <label className='input-file-font'>{fileName}</label>
      ))} */}
      {fileNames.length === 0 && <label className='input-file-font'>{labelText}</label>}
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
      {errorType && (
        <span className='input-error-text error-font error-text-color'>{errorType}</span>
      )}
    </div>
  );
}

export default InputFile;
