import { ReactNode } from 'react';

type Props = {
  content?: ReactNode;
  title: ReactNode;
  title2: ReactNode;
};
const FrontPage = ({ content, title, title2 }: Props) => {
  return (
    <div className='front-page'>
      <div className='title'>
        <h1 className='on-dark-text-color headline1-big-font'>{title}</h1>
        <h1 className='on-dark-text-color headline1-big-font-bolder'>{title2}</h1>
      </div>
      <div className='content'>{content}</div>
    </div>
  );
};

export default FrontPage;
