import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import { TransportOptions } from '../../../../../../types/entitiesEnums/employeePeriod';
import Button from '../../../../../ui/button/Button';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';
import Line from '../stepSummary/Line';
import '../stepSummary/styles.scss';

type Props = {
  next: string;
};

function StepSummaryPepeJeans({ next }: Props) {
  const { t } = useTranslation();
  const { formData, stepCounter, handleBack, handleSubmit, handleNext, errorSubmit } =
    useContext(EmployeeFormContext);

  const [loading, setLoading] = useState(false);

  const handleClickSubmit = async () => {
    if (!handleSubmit || !handleNext) return;
    setLoading(true);
    await handleSubmit();
    setLoading(false);
    handleNext(next);
  };

  if (!handleBack) return null;

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle3')}
      subtitle={t('employeeForm.formSubtitle4')}>
      <StepWrapper title={t('employeeForm.summary')} stepNumber={stepCounter} width={500}>
        <div className='summary-steps-container'>
          <Line
            title={t('employeeForm.summaryData.dateTitle')}
            text={`${formData.startDate} - ${formData?.endDate || t('employees.now')}`}
          />
          {formData.totalKm && (
            <Line title={t('employeeForm.summaryData.totalKmTitle')} text={formData.totalKm} />
          )}
          {Boolean(formData.transport.id) && (
            <Line
              title={t('employeeForm.summaryData.transportTitle')}
              text={`${formData.transport.name} ${formData.fuelType.name}`}
            />
          )}
          {formData.transport.id === TransportOptions.CAR && (
            <Line
              title={t('employeeForm.summaryData.carpoolingTitle')}
              text={formData.carpooling.name}
            />
          )}
        </div>

        <Button
          lookAndFeel={'primary'}
          onClick={handleClickSubmit}
          text={loading ? t('employeeForm.sending') : t('employeeForm.send')}
          loading={loading}
        />
        {errorSubmit && (
          <span className='error-text error-font error-text-color'>{errorSubmit.description}</span>
        )}
      </StepWrapper>

      <SwitchStep handleBack={handleBack} />
    </StepControllerContainer>
  );
}

export default StepSummaryPepeJeans;
