import { useCallback, useEffect, useState } from 'react';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getMITECOStatus } from '../services/getMITECOStatus';
import { OrganizationRecommendation } from '../../../../types/entities/organization';

export const useMITECO = () => {
  const [MITECOAssistance, setMITECOAssistance] = useState<OrganizationRecommendation | null>(null);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  const org = useSelectedOrganization();

  const makeFetch = useCallback(async () => {
    const res = await getMITECOStatus(org?.id);

    setStatus(res?.status === 'pending');
    setMITECOAssistance(res);

    setLoading(false);
  }, [org?.id]);

  useEffect(() => {
    setLoading(true);
    makeFetch().finally(() => {
      setLoading(false);
    });
  }, [makeFetch]);

  return { MITECOAssistance, loading, refetch: makeFetch, status, setLoading };
};
