import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { adaptTaskBackToFront } from '../../../../adapters/tasks';
import { UserContext } from '../../../../context/userContext';
import {
  editTask,
  getCommentsByTaskId,
  getTaskById,
  uploadAttachmentsToTask
} from '../../../../services/api/tasks';
import {
  Attachment,
  IFrontendFormTask,
  IGetBackendTask,
  TaskComment
} from '../../../../types/entities/task';

import { useQueryClient } from '@tanstack/react-query';
import { convertDateToString } from '../../../../utils/convertDates';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import Form from '../form/Form';

type Props = {
  handleEditTask: (task: IGetBackendTask) => void;
  // task: IGetBackendTask;
  users: SelectOptionFormat[];
  tags: SelectOptionFormat[];
  idTaskControlPanel?: string;
};
const EditTask = ({ handleEditTask, users, tags, idTaskControlPanel }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useContext(UserContext);
  const queryClient = useQueryClient();

  const [task, setTask] = useState<IGetBackendTask>();
  const [formData, setFormData] = useState<IFrontendFormTask>({
    userSelected: {
      id: '',
      name: ''
    },
    category: {
      id: '',
      name: ''
    },
    dueDate: convertDateToString(new Date()),
    description: '',
    tag: '',
    tagsSelected: [],
    createdBy: user?.id ?? '',
    organizationId: user?.selectedOrganization ?? '',
    files: [],
    errors: []
  });
  const [comments, setComments] = useState<TaskComment[]>([]);

  const fetchData = async () => {
    let data = null;
    if (idTaskControlPanel) {
      data = await getTaskById(idTaskControlPanel);
    } else {
      data = await getTaskById(id ?? '');
    }

    const newTask = adaptTaskBackToFront(data, t);
    newTask.files = data.attachments.map((elem: Attachment) => ({
      id: elem.id,
      name: elem.file_name,
      file: null,
      url: elem.file_url
    }));

    setFormData(newTask);
    if (idTaskControlPanel) {
      setFormData((prev) => ({
        ...prev,
        metric: ''
      }));
    }
    setTask(data);
  };

  const fetchComments = async () => {
    let data = null;
    idTaskControlPanel
      ? (data = await getCommentsByTaskId(idTaskControlPanel))
      : (data = await getCommentsByTaskId(id ?? ''));

    setComments(data);
  };

  useEffect(() => {
    Promise.all([fetchData(), fetchComments()]);
  }, []);

  const handleSubmit = async () => {
    let data: any = null;
    if (!formData) return;

    idTaskControlPanel
      ? (data = await editTask(formData, idTaskControlPanel ?? ''))
      : (data = await editTask(formData, id ?? ''));

    queryClient.invalidateQueries({ queryKey: ['metrics'] });

    // Upload attachments if there are present
    if (formData.files.length > 0) {
      formData.files.forEach(async (file) => {
        if (file.file) {
          await uploadAttachmentsToTask(data.id, file.file);
        }
      });
    }
    handleEditTask(data);
  };

  if (!formData && (!id || !idTaskControlPanel)) return null;

  return (
    <div className='edit-task'>
      <FormHeader title={t('tasks.editTitle')} description={t('tasks.editDescription')} />
      <Form
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        users={users}
        taskId={idTaskControlPanel ? idTaskControlPanel : id}
        comments={comments}
        tags={tags}
      />
    </div>
  );
};

export default EditTask;
