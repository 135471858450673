import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../ui/tooltip/Tooltip';
import OffsetDataImage from '../../../offset/offsetDataImage/OffsetDataImage';
import Button from '../../../../ui/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/routes';

const OffsetCard = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goToOffset = () => {
    navigate(ROUTES.IMPROVE_OFFSET);
  };

  return (
    <div className='card main-bg-color card-border-color'>
      <div className='card-header'>
        <h5 className='headline4-font'>{t('improveMain.compensations')}</h5>
        <Tooltip text={t('improveMain.compensationsTooltip')} />
      </div>
      <div className='card-offset'>
        <OffsetDataImage />
        <Button
          lookAndFeel='primary'
          text={t('improveMain.offset')}
          onClick={goToOffset}
          size='small'
        />
      </div>
    </div>
  );
};

export default OffsetCard;
