import type { IPurchaseSupplierFrontend } from '../../../../../../types/purchaseSupplier';

export const patchPurchaseSupplierAdapter = (data: Partial<IPurchaseSupplierFrontend>) => {
  return {
    country: data.country,
    code: data.purchaseCode?.code,
    framework: data.purchaseCode?.framework,
    code_type: data.purchaseCode?.codeType
  };
};
