import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../../../constants/routes';
import { FormFacilityData } from '../../../../../../../../types/entities/avoidedEmission';
import CheckboxMultiple from '../../../../../../../ui/formComponents/checkboxMultiple/CheckboxMultiple';
import EmptyModal from '../../emptyModal/EmptyModal';
import './styles.scss';
import InfiniteList from '../../../../../../../ui/infiniteList/InfiniteListV2';
import useFetchInfiniteList from '../../../../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import apiFetch from '../../../../../../../../utils/apiFetch';
import { IFilter, QueryParams, getUrl } from '../../../../../../../../utils/url';
import InputCalendar from '../../../../../../../ui/formComponents/inputCalendar/InputCalendar';
import TotalLegend from '../../../../../../../ui/totalLegend/TotalLegend';
import InputTextDebounce from '../../../../../../../ui/formComponents/inputTextDebounce/InputTextDebounce';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import moment from 'moment';

type Props = {
  formData: FormFacilityData;
  setFormData: any;
  next: () => void;
};

// TODO: Refactor this component to use new filtering logic instead

type Params = {
  facilityId: string;
  queryParams: QueryParams[];
  filter_by: IFilter[];
};

const URL = `/invoices/casted/facility/`;

const getFacilityInvoices = async ({ facilityId, queryParams, filter_by }: Params) => {
  const baseUrl = `${URL}${facilityId}`;

  const response = await apiFetch('GET', getUrl(baseUrl, { queryParams, filters: filter_by }));

  const data = await response.data;
  return data;
};

type MakeFetchParams = {
  facilityId: string;
  filters: string;
  start_date?: string;
  end_date?: string;
  invoice_id?: string;
};
const makeFetch = ({ facilityId, filters, start_date, end_date, invoice_id }: MakeFetchParams) => {
  return async (page: number) => {
    const queryParams: QueryParams[] = [
      {
        key: 'page',
        value: page
      },
      {
        key: 'filters',
        value: filters
      }
    ];

    /* if (start_date) {
      queryParams.push({
        key: 'start_date',
        value: moment.utc(start_date, 'DD/MM/YYYY').valueOf() / 1000
      });
    }

    if (end_date) {
      queryParams.push({
        key: 'end_date',
        value: moment.utc(end_date, 'DD/MM/YYYY').valueOf() / 1000
      });
    } */

    const filter_by: IFilter[] = [
      {
        field: 'invoice_id',
        type: 'il',
        value: invoice_id
      }
    ];

    if (start_date && end_date) {
      const values = [
        moment.utc(start_date, 'DD/MM/YYYY').valueOf() / 1000,
        moment.utc(end_date, 'DD/MM/YYYY').valueOf() / 1000
      ];

      filter_by.push({
        field: 'start_date',
        type: 'rd',
        value: values
      });
      filter_by.push({
        field: 'end_date',
        type: 'rd',
        value: values
      });
    }

    return getFacilityInvoices({ facilityId, queryParams, filter_by });
  };
};

const useInvoices = ({
  end_date,
  start_date,
  facilityId,
  filters,
  invoice_id
}: MakeFetchParams) => {
  const { data, loading, fetchData, total, setTotal } = useFetchInfiniteList(
    makeFetch({ facilityId, filters, end_date, start_date, invoice_id }),
    [facilityId, filters, end_date, start_date, invoice_id]
  );

  return { data, loading, fetchData, total, setTotal };
};

type PropsHeader = {
  total: number;
  onChangeValue: (id: string) => (value: string) => void;
  formData: FormFacilityData;
};
const ListHeader: React.FC<PropsHeader> = ({ total, onChangeValue, formData }) => {
  const { t } = useTranslation();

  const setStartDate = onChangeValue('start_date');

  const setEndDate = onChangeValue('end_date');

  const onChangeSearchValue = onChangeValue('invoice_id');

  const handleChangeStartDate = (date: string) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: string) => {
    setEndDate(date);
  };

  const resetStartDate = () => {
    setStartDate('');
  };

  const resetEndDate = () => {
    setEndDate('');
  };

  return (
    <div className='flex gap-x-2'>
      <InputTextDebounce
        icon={'/images/icons/search.svg'}
        placeholder={t('facilityDetail.inputFilter')}
        onChangeValue={(e) => onChangeSearchValue(e.target.value)}
        height='24px'
        fontClass='input-smaller-font'
        size='small'
      />

      <InputCalendar
        mode='range'
        height='24px'
        fontClass='input-smaller-font'
        size='small'
        predefinedDates
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
        startDateValue={formData.start_date}
        endDateValue={formData.end_date}
        resetStartDate={resetStartDate}
        resetEndDate={resetEndDate}
      />
      <TotalLegend total={total} loading i18key='facilityDetail' />
    </div>
  );
};
function ListInvoices({ formData, setFormData }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, fetchData, loading, total } = useInvoices({
    facilityId: formData.facilityId,
    filters: formData.category,
    start_date: formData.start_date,
    end_date: formData.end_date,
    invoice_id: formData.invoice_id
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  useEffect(() => removeAll(), []);

  const removeAll = () =>
    setFormData((prev: FormFacilityData) => ({
      ...prev,
      list: []
    }));

  const addAll = () =>
    setFormData((prev: FormFacilityData) => ({
      ...prev,
      list: data.map((elem) => elem.id),
      errors: prev.errors.filter((error) => error.error !== 'list')
    }));

  const selectInvoice = (id: string) => () =>
    setFormData((prev: FormFacilityData) => {
      const newSelected = data?.find((elem) => elem.id === id);
      if (!newSelected) return prev;
      return {
        ...prev,
        list: prev.list.concat(newSelected.id),
        errors: prev.errors.filter((error) => error.error !== 'list')
      };
    });

  const removeInvoice = (id: string) => () =>
    setFormData((prev: FormFacilityData) => ({
      ...prev,
      list: prev.list.filter((elem) => elem !== id)
    }));

  const renderFuelComponent = (facility_fuel: string, id: string) => {
    return (
      <div className='icon-text-wrapper'>
        <CheckboxMultiple
          type='square'
          onSelect={selectInvoice(id)}
          selected={formData.list.some((elem) => elem === id)}
          id={id}
          onRemove={removeInvoice(id)}
        />
        <span>{facility_fuel}</span>
      </div>
    );
  };

  const parseData = (invoices: any) => {
    const data = invoices.map((invoice: any) => {
      const invoiceStartDate = invoice.start_date
        ? new Date(invoice.start_date).toLocaleDateString()
        : '-';
      const invoiceEndDate = invoice.end_date
        ? new Date(invoice.end_date).toLocaleDateString()
        : '-';
      return {
        ...invoice,
        facility_fuel: renderFuelComponent(
          invoice.facility_fuel && invoice.facility_fuel.name
            ? t(`facility_fuels.${invoice.facility_fuel.name}`)
            : '-',
          invoice.id
        ),
        quantity: invoice.status === 'loading' ? '-' : invoice.quantity,
        unit: invoice.unit ? t(`units.${invoice.unit.name}`) : '-',
        invoiceId: invoice.invoice_id || '-',
        startDate: invoiceStartDate,
        endDate: invoiceEndDate,
        disabled: invoice.status !== 'uploaded'
      };
    });
    return data;
  };

  const columns = [
    {
      title: (
        <div className='flex'>
          <CheckboxMultiple
            type='square'
            onSelect={addAll}
            selected={data.length === formData.list.length}
            id='all'
            onRemove={removeAll}
          />
          <span>{t('sendEmailForm.selectAll')}</span>
        </div>
      ),
      dataIndex: 'facility_fuel',
      key: 'facility_fuel'
    },
    {
      title: t('facilityDetail.quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: t('facilityDetail.unit'),
      dataIndex: 'unit',
      key: 'unit'
    },
    {
      title: t('facilityDetail.startDate'),
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: t('facilityDetail.endDate'),
      dataIndex: 'endDate',
      key: 'endDate'
    },
    {
      title: t('facilityDetail.invoiceId'),
      dataIndex: 'invoiceId',
      key: 'invoiceId'
    }
  ];

  const handleNavigate = () => {
    navigate(`${ROUTES.MEASURE_FACILITIES}`);
  };
  const parsedData = parseData(data);
  return (
    <section className='list-invoices'>
      <InfiniteList
        data={parsedData}
        fetchData={fetchData}
        columns={columns}
        loading={loading}
        total={total}
        header={<ListHeader total={total} onChangeValue={onChangeValue} formData={formData} />}
      />

      {data.length === 0 && (
        <EmptyModal>
          {t('avoidedEmissions.noConsumptions.first')}
          <span onClick={handleNavigate} className='highlight-text-color clickable'>
            {t('avoidedEmissions.noConsumptions.second')}
          </span>
          {t('avoidedEmissions.noConsumptions.third')}
        </EmptyModal>
      )}
    </section>
  );
}

export default ListInvoices;
