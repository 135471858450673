import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetBackendBusinessTravel } from '../../../../types/entities/businessTravels';
import { Employee } from '../../../../types/entities/employee';
import { SendFormType } from '../../../../types/utilsEnums/form';
import { SendEmailCSVContent } from './sendEmailCSVContent/SendEmailCSVContent';
import SendEmailContent from './sendEmailContent/SendEmailContent';
import './styles.scss';
import Modal from '../../../ui/modal/Modal';

type Props = {
  close: () => void;
  type: SendFormType;
  addEmployees?: (employees: Employee[]) => void;
  addBusinessTrips?: (businessTrips: IGetBackendBusinessTravel[]) => void;
};

type ShowType = 'manual' | 'csv';

function SendEmailForm({ type, close, addEmployees, addBusinessTrips }: Props) {
  const { t } = useTranslation();

  const [show, setShow] = useState<ShowType>('csv');

  const handleSetShowToManual = () => setShow('manual');
  const handleSetShowToCSV = () => setShow('csv');

  return (
    <div className='send-business-travel-by-email'>
      <Modal.Header
        title={t(`sendEmailForm.${type}.title`)}
        description={t(`sendEmailForm.${type}.subtitle`)}
      />
      {show === 'manual' ? (
        <SendEmailContent
          handleGoToCSV={handleSetShowToCSV}
          close={close}
          type={type}
          addEmployees={addEmployees}
          addBusinessTrips={addBusinessTrips}
        />
      ) : null}
      {show === 'csv' ? (
        <SendEmailCSVContent
          handleGoToManual={handleSetShowToManual}
          close={close}
          type={type}
          addEmployees={addEmployees}
          addBusinessTrips={addBusinessTrips}
        />
      ) : null}
    </div>
  );
}

export default SendEmailForm;
