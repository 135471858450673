import React, { useState } from 'react';
import Button from '../../../../../ui/button/Button';
import { useTranslation } from 'react-i18next';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormNumber from '../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import FormCalendar from '../../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import useGetData from './hooks/useGetData';
import checkFormErrors from '../../../../../../utils/checkFormErrors';
import { createInvestment } from '../../../../../../services/api/groupFunds';
import { IBackendFundRelation } from '../../../../../../types/entities/organization';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../actions/notification';
import { FundDetailType } from '../../../../../../types/entities/groupFunds';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import CustomSkeletonLoader from '../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
type FormData = {
  organization: {
    id: string;
    name: string;
  };
  investment: string;
  isDebtFinancing: boolean;
  companyValue: string;
  investmentDate: string;
  tag: string;
  errors: ErrorType[];
};

type Props = {
  onSubmit: (data: FundDetailType) => void;
  onClose: () => void;
  fundId: string;
};
const LinkCompany = ({ onSubmit, onClose, fundId }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    organization: {
      id: '',
      name: ''
    },
    investment: '',
    isDebtFinancing: false,
    companyValue: '',
    investmentDate: '',
    tag: '',
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const optionalFields = ['tag', 'isDebtFinancing'];

    const newErrors = checkFormErrors(formData, [], optionalFields);
    if (Number(formData.investment) > Number(formData.companyValue)) {
      newErrors.push({
        error: 'investment',
        description: t('configureGroupFunds.investmentGreaterThanCompanyValue')
      });
    }
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }

    setLoadingButton(true);

    const data: IBackendFundRelation = await createInvestment(formData.organization.id ?? '', {
      ...formData,
      fund: {
        id: fundId,
        name: ''
      }
    });
    setLoadingButton(false);

    if (!data) {
      setFormData((prev) => ({
        ...prev,
        errors: [{ error: 'general', description: t('error.somethingWentWrong') }]
      }));
      return;
    }

    const foundOrg = organizationsBackend.find((org) => org.child_id === formData.organization.id);
    if (!foundOrg) return;
    onSubmit({
      country: foundOrg.country,
      child_id: foundOrg.child_id,
      company_name: foundOrg.child_name,
      tag: formData.tag,
      investment_value: formData.investment,
      debt_financing: false,
      enterprise_value: formData.companyValue,
      investment_date: data.investment_date,
      co2e: 0
    });

    dispatch(setNotification(t('notification.companyConfigured')));
  };

  const { organizationOptions, organizationsBackend, loading } = useGetData();

  const generalError = formData.errors.find((error) => error.error === 'general');
  return (
    <div className='link-company-to-fund'>
      <FormHeader
        title={t('funds.linkCompany.title')}
        description={t('funds.linkCompany.description')}
      />
      {loading ? (
        <div style={{ marginBottom: '1rem' }}>
          <CustomSkeletonLoader count={4} />
        </div>
      ) : (
        <FormWrapper>
          <FormElementFull>
            <FormSelect
              icon={'/images/icons/holding.svg'}
              label={t('funds.linkCompany.organizationLabel')}
              placeholder={t('funds.linkCompany.organizationPlaceholder')}
              options={organizationOptions}
              value={formData.organization}
              onChange={onChangeValue('organization')}
              error={formData.errors.find((error) => error.error === 'organization')}
            />
          </FormElementFull>
          <FormNumber
            icon={'/images/icons/database.svg'}
            label={t('configureGroupFunds.investmentLabel')}
            placeholder={t('configureGroupFunds.investmentPlaceholder')}
            value={formData.investment}
            onChange={onChangeValue('investment')}
            tooltip={t('configureGroupFunds.investmentTooltip')}
            error={formData.errors.find((error) => error.error === 'investment')}
          />

          <FormNumber
            icon={'/images/icons/database.svg'}
            label={t('configureGroupFunds.companyValueLabel')}
            placeholder={t('configureGroupFunds.companyValueLabel')}
            value={formData.companyValue}
            onChange={onChangeValue('companyValue')}
            error={formData.errors.find((error) => error.error === 'companyValue')}
            tooltip={t('configureGroupFunds.companyValueTooltip')}
          />
          <FormCalendar
            label={t('configureGroupFunds.investmentDateLabel')}
            handleChangeDate={onChangeValue('investmentDate')}
            dateValue={formData.investmentDate}
            error={formData.errors.find((error) => error.error === 'investmentDate')}
            tooltip={t('configureGroupFunds.investmentDateTooltip')}
          />
          <FormText
            label={t('configureGroupFunds.tagLabel')}
            icon={'/images/icons/message.svg'}
            placeholder={t('configureGroupFunds.tagPlaceholder')}
            value={formData.tag}
            onChange={onChangeValue('tag')}
          />
        </FormWrapper>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel='secondary'
          onClick={onClose}
          text={t('funds.linkCompany.cancel')}
          size='small'
        />
        <Button
          lookAndFeel='primary'
          onClick={handleSubmit}
          text={t('funds.linkCompany.save')}
          loading={loadingButton}
          size='small'
        />
      </FormButtonSection>
      {generalError && (
        <ErrorText style={{ marginTop: '1rem' }}>{generalError.description}</ErrorText>
      )}
    </div>
  );
};

export default LinkCompany;
