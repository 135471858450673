import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../ui/table/Table';
import useColumns from './hooks/useColumns';
import useGetData from './hooks/useGetData';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import {
  formatNumberToDecimalNonZero,
  numberToDecimalNonZero
} from '../../../../../utils/numberToDecimal';

const ImpactByToc = () => {
  const { t } = useTranslation();
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const columns = useColumns();
  const { tocsInfo } = useGetData({ startDate, endDate });
  return (
    <div className='card dashboard-card' style={{ gridColumn: '1 / -1' }}>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>{t('dashboardLogistic.impactByToc')}</h4>
      </div>
      <div className='dashboard-card__body dashboard-card__body--list'>
        <div className='list-wrapper'>
          <Table
            columns={columns}
            data={tocsInfo.map((elem) => ({
              vehicleId: t(`logistics.tocs.${elem.vehicleId}`),
              tonsTransported: (
                <div style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.tonsTransported.value)}
                </div>
              ),
              kmEmpty: (
                <div style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.kmEmpty.value)}
                </div>
              ),
              kmTotal: (
                <div style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.kmTotal.value)}
                </div>
              ),
              intensityValue: (
                <div style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.iv.value)}
                </div>
              ),
              tkm: (
                <div style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.tkm.value)}
                </div>
              ),
              kgCO2e: (
                <div className='highlight-text-color weight-600' style={{ textAlign: 'right' }}>
                  {formatNumberToDecimalNonZero(elem.kgCO2e.value)}
                </div>
              )
            }))}
            loading={false}
            size={'small'}
          />
        </div>
      </div>
    </div>
  );
};

export default ImpactByToc;
