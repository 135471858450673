import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { uploadFilePresignedUrl } from '../../../services/api/aws';
import { getPresignedUrlShipments } from '../../../services/api/transportRoutes';

import checkFormErrors from '../../../utils/checkFormErrors';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../layout/mainPageLayout/MainPageLayout';
import Button from '../../ui/button/Button';
import FormButtonSection from '../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../ui/formComponents/inputFile/InputFileSingle';
import InputText from '../../ui/formComponents/inputText/InputText';
import Select from '../../ui/formComponents/select/Select';
import Modal from '../../ui/modal/Modal';
import DeleteShipments from './deleteShipments/DeleteShipments';
import GetShipmentsError from './getShipmentsError/GetShipmentsError';
import './styles.scss';
type FormData = {
  organizationId: string;
  file: File | null;
  transportDirection: SelectOptionFormat;
  errors: ErrorType[];
};
const commonInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big' | undefined
};

const ShipmentsUpload = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    organizationId: '',
    file: null,
    transportDirection: {
      id: 'downstream',
      name: t('shipmentsUpload.downstream')
    },
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGetShipmentsWithErrors, setShowGetShipmentsWithErrors] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      // Call api to upload CSV file after converting to base64
      const errors = checkFormErrors(formData, formData.errors, []);
      if (errors.length > 0) {
        setFormData({
          ...formData,
          errors
        });
        return;
      }
      setLoadingButton(true);
      if (!formData.file) {
        return;
      }

      if (!formData.file) return;

      const data = await getPresignedUrlShipments(
        formData.file.name,
        formData.transportDirection.id
      );

      const response = await uploadFilePresignedUrl(formData.file, data.upload_url);

      setLoadingButton(false);
      if (response) {
        dispatch(setNotification(t('shipmentsUpload.success')));
        return;
      }
      dispatch(setNotification(t('error.somethingWentWrong')));
    } catch (error) {
      dispatch(setNotification(t('error.somethingWentWrong')));
      setLoadingButton(false);
    }
  };

  const downloadTemplateCsv = async () => {
    // Download the csv file in public/files/shipments/transport_and_distribution_template_Dcycle.csv
    const link = document.createElement('a');
    link.href =
      process.env.PUBLIC_URL + '/files/shipments/transport_and_distribution_template_Dcycle.csv';
    link.setAttribute('download', 'merch_template.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDelete = async () => {
    // Call api to upload CSV file after converting to base64
    const errors = checkFormErrors(formData, formData.errors, ['file']);
    if (errors.length > 0) {
      setFormData({
        ...formData,
        errors
      });
      return;
    }
    setShowDeleteModal(true);
  };

  const handleShowGetShipmentsWithErrors = () => {
    setShowGetShipmentsWithErrors(true);
  };

  const handleHideModal = () => {
    setShowDeleteModal(false);
    setShowGetShipmentsWithErrors(false);
  };

  return (
    <>
      <MainPageLayout
        sectionTitle={t('shipmentsUpload.title')}
        title={t('shipmentsUpload.createTemplateTitle')}
        description={t('shipmentsUpload.createTemplateStartDescription')}
        breadcrumb={<Breadcrumb />}
        buttons={
          <>
            <Button
              lookAndFeel='primary'
              text={t('shipmentsUpload.createTemplate')}
              onClick={downloadTemplateCsv}
              size='small'
            />
            <Button
              lookAndFeel='secondary'
              onClick={handleShowGetShipmentsWithErrors}
              text={t('shipmentsUpload.getShipmentsWithErrors')}
              size='small'
            />
          </>
        }>
        <div className='shipments-upload card'>
          <FormWrapper>
            <InputText
              {...commonInputProps}
              icon={'/images/icons/organization.svg'}
              placeholder={t('shipmentsUpload.organizationId')}
              label={t('shipmentsUpload.organizationId')}
              value={formData.organizationId}
              onChangeValue={onChangeValue('organizationId')}
              error={formData.errors.find((error) => error.error === 'organizationId')}
            />
            <Select
              placeholder={''}
              icon={'/images/icons/cardBox.svg'}
              label={t('shipmentsUpload.transportDirection')}
              options={[
                {
                  id: 'upstream',
                  name: t('shipmentsUpload.upstream')
                },
                {
                  id: 'downstream',
                  name: t('shipmentsUpload.downstream')
                }
              ]}
              value={formData.transportDirection}
              onChangeValue={onChangeValue('transportDirection')}
            />
            <FormElementFull>
              <InputFileSingle
                handleFile={onChangeValue('file')}
                buttonText={t('shipmentsUpload.upload')}
                labelText={t('shipmentsUpload.uploadLabel')}
                fileName={formData.file?.name || ''}
                allowedTypes={['text/csv']}
                error={formData.errors.find((error) => error.error === 'file')}
              />
            </FormElementFull>
          </FormWrapper>
          <FormButtonSection>
            <Button
              lookAndFeel='primary'
              text={t('shipmentsUpload.save')}
              onClick={handleSubmit}
              loading={loadingButton}
            />
            <Button
              lookAndFeel='secondary'
              text={t('shipmentsUpload.delete')}
              onClick={handleDelete}
            />
          </FormButtonSection>
        </div>
      </MainPageLayout>
      <Modal show={showDeleteModal} onClose={handleHideModal}>
        <DeleteShipments
          organizationId={formData.organizationId}
          transportDirection={formData.transportDirection.id}
          deleteCallback={handleHideModal}
        />
      </Modal>
      <Modal show={showGetShipmentsWithErrors} onClose={handleHideModal}>
        <GetShipmentsError />
      </Modal>
    </>
  );
};

export default ShipmentsUpload;
