import { ResultValues } from 'nuvo-react';
import { treatRest } from './utils';

export const nuvoAdapter = (data: ResultValues) => {
  return data.map((item) => {
    const { euro_gross_quantity, country, ...rest } = item;

    if (typeof euro_gross_quantity === 'object' && !Array.isArray(euro_gross_quantity)) {
      return {
        ...treatRest(rest),
        euro_gross_quantity: euro_gross_quantity?.value,
        supplier_country: country,
        origin_country: country
      };
    }

    return {
      ...treatRest(rest),
      euro_gross_quantity,
      supplier_country: country,
      origin_country: country
    };
  });
};
