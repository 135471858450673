import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { registerUser } from '../../../../actions/auth';
import { AMAZON_STATUSES } from '../../../../constants/amzAuth';
import getPrefixes, { getCountries } from '../../../../constants/phonePrefixes';
import { ROUTES } from '../../../../constants/routes';
import { sectors } from '../../../../constants/sectors';
import useAmzAuth from '../../../../customHooks/useAmzAuth';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { getUserById } from '../../../../services/api/user';
import checkFormErrors from '../../../../utils/checkFormErrors';
import PoweredByDcycle from '../../../layout/poweredByDcycle/PoweredByDcycle';
import InputNumber from '../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../ui/formComponents/inputText/InputText';
import Select from '../../../ui/formComponents/select/Select';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../ui/stepCarouselList/useStepCarousel';
import ReviewImage from './reviewImage/ReviewImage';
import Step from './step/Step';

import { AWS_BUSINESS_REFERAL } from '../../../../types/utilsEnums/amzAuth';
import './styles.scss';
type FormData = {
  type: SelectOptionFormat;
  sector: SelectOptionFormat;
  vat: string;
  employee_count_signup: string;
  country: SelectOptionFormat;
  company_name: string;
  errors: ErrorType[];
};

const commonInputProps = {
  height: '40px',
  fontClass: 'input-font'
};
const RegisterCompany = () => {
  const { t, i18n } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const { isFromAmzAuth, handleAmazonRedirect } = useAmzAuth(undefined);

  const [formData, setFormData] = useState<FormData>({
    type: {
      id: '',
      name: ''
    },
    sector: {
      id: '',
      name: ''
    },
    vat: '',
    employee_count_signup: '',
    country: {
      id: '',
      name: ''
    },
    company_name: '',
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;

        setFormData({
          ...formData,
          country: {
            id: data.country_code,
            name: getPrefixes(i18n.resolvedLanguage).find(
              (prefix) => prefix.code === data.country_code
            )?.name
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const fetchAndCheckUser = async (id: string) => {
    try {
      const data = await getUserById(id);
      if (!data) {
        navigate(ROUTES.NOT_FOUND);
      }

      setUser(data);
    } catch (err) {
      navigate(ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    if (params.user_id) {
      fetchAndCheckUser(params.user_id);
    }
  }, [params.user_id]);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const stepList = [
    {
      id: 'holding',
      text: t('onboardingUser.stepHolding.stepTitle'),
      stepContent: {
        title: t('onboardingUser.stepHolding.title'),
        buttonText: t('onboardingUser.stepHolding.button'),
        errorKeys: ['holding'],
        // tooltip: t('onboardingUser.stepHolding.tooltip'),
        input: (
          <Select
            {...commonInputProps}
            icon={'/images/icons/holding.svg'}
            placeholder={t('onboardingUser.stepHolding.placeholder')}
            onChangeValue={onChangeValue('type')}
            value={formData.type}
            options={[
              {
                id: 'company',
                name: t('onboardingUser.stepHolding.company')
              },
              {
                id: 'investmentFundManager',
                name: t('onboardingUser.stepHolding.investmentFundManager')
              }
            ]}
            error={formData.errors.find((elem) => elem.error === 'holding')}
            sort={false}
          />
        )
      },
      reviewImage: {
        reviewText: t('onboardingUser.stepHolding.reviewText'),
        profileImage: '/images/register/juanFernandez.png',
        profileName: 'Juan Fernández',
        profileCompany: 'Blue Banana',
        mainImage: `/images/register/${i18n.resolvedLanguage}/step1.svg`
      }
    },
    {
      id: 'company_name',
      text: t('onboardingUser.stepCompanyName.stepTitle'),
      stepContent: {
        title: t('onboardingUser.stepCompanyName.title'),
        buttonText: t('onboardingUser.stepCompanyName.button'),
        errorKeys: ['company_name', 'vat'],
        input: (
          <div className='inputs'>
            <InputText
              {...commonInputProps}
              icon={'/images/icons/briefcase.svg'}
              placeholder={t('onboardingUser.stepCompanyName.placeholder')}
              onChangeValue={onChangeValue('company_name')}
              value={formData.company_name}
              error={formData.errors.find((elem) => elem.error === 'company_name')}
            />
            <InputText
              {...commonInputProps}
              icon={'/images/icons/idcard.svg'}
              placeholder={t('onboardingUser.stepVat.placeholder')}
              onChangeValue={onChangeValue('vat')}
              value={formData.vat}
              error={formData.errors.find((elem) => elem.error === 'vat')}
            />
          </div>
        )
      },
      reviewImage: {
        reviewText: t('onboardingUser.stepCompanyName.reviewText'),
        profileImage: '/images/register/curraRotondo.png',
        profileName: 'Curra Rotondo',
        profileCompany: 'Lefrik',
        mainImage: `/images/register/${i18n.resolvedLanguage}/step2.svg`
      }
    },
    {
      id: 'country',
      text: t('onboardingUser.stepCountry.stepTitle'),
      stepContent: {
        title: t('onboardingUser.stepCountry.title'),
        buttonText: t('onboardingUser.stepCountry.button'),
        errorKeys: ['country'],
        input: (
          <Select
            {...commonInputProps}
            icon={'/images/icons/globe.svg'}
            placeholder={t('onboardingUser.stepCountry.placeholder')}
            onChangeValue={onChangeValue('country')}
            value={formData.country}
            options={getCountries(i18n.resolvedLanguage, i18n.resolvedLanguage.toUpperCase())}
            error={formData.errors.find((elem) => elem.error === 'country')}
          />
        )
      },
      reviewImage: {
        reviewText: t('onboardingUser.stepCountry.reviewText'),
        profileImage: '/images/register/cristinaAlvarez.png',
        profileName: 'Cristina Álvarez',
        profileCompany: 'Scalpers',
        mainImage: `/images/register/${i18n.resolvedLanguage}/step3.svg`
      }
    },
    {
      id: 'employee_count_signup',
      text: t('onboardingUser.stepEmployees.stepTitle'),
      stepContent: {
        title: t('onboardingUser.stepEmployees.title'),
        buttonText: t('onboardingUser.stepEmployees.button'),
        errorKeys: ['employee_count_signup'],
        input: (
          <InputNumber
            {...commonInputProps}
            icon={'/images/icons/people.svg'}
            placeholder={t('onboardingUser.stepEmployees.placeholder')}
            onChangeValue={onChangeValue('employee_count_signup')}
            value={formData.employee_count_signup}
            error={formData.errors.find((elem) => elem.error === 'employee_count_signup')}
          />
        )
      },
      reviewImage: {
        reviewText: t('onboardingUser.stepEmployees.reviewText'),
        profileImage: '/images/register/jaimeGil.png',
        profileName: 'Jaime Gil',
        profileCompany: 'Neutrale',
        mainImage: `/images/register/${i18n.resolvedLanguage}/step4.svg`
      }
    },
    {
      id: 'sector',
      text: t('onboardingUser.stepSector.stepTitle'),
      stepContent: {
        title: t('onboardingUser.stepSector.title'),
        buttonText: t('onboardingUser.stepSector.button'),
        errorKeys: ['sector'],
        input: (
          <Select
            {...commonInputProps}
            icon={'/images/icons/piechart.svg'}
            placeholder={t('onboardingUser.stepSector.placeholder')}
            onChangeValue={onChangeValue('sector')}
            value={formData.sector}
            options={sectors.map((elem) => {
              return {
                id: elem.id,
                name: elem.title[i18n.resolvedLanguage as keyof Language]
                  ? elem.title[i18n.resolvedLanguage as keyof Language]
                  : elem.title.en
              };
            })}
            error={formData.errors.find((elem) => elem.error === 'sector')}
          />
        )
      },
      reviewImage: {
        reviewText: t('onboardingUser.stepSector.reviewText'),
        profileImage: '/images/register/monicaRodriguez.png',
        profileName: 'Mónica Rodríguez',
        profileCompany: 'Recovo',
        mainImage: `/images/register/${i18n.resolvedLanguage}/step5.svg`
      }
    }
  ];

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps,
    handleNext,
    isLastElement
  } = useStepCarousel({
    stepsText: stepList.map((elem) => {
      return {
        id: elem.id,
        text: elem.text,
        errorKeys: elem.stepContent?.errorKeys
      };
    })
  });

  const currentStep = stepList.find((elem) => elem.id === stepSelected?.id);

  const handleErrors = () => {
    const optionalFields = ['errors', 'general'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields).filter((error) =>
      currentStep?.stepContent?.errorKeys.includes(error.error)
    );

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const verifyAmazonProcedence = (response: string) => {
    const isNotAuthenticated = [
      'INVALID_VAT_PASSED',
      'DUPLICATED_VAT',
      'Invalid data entry'
    ].includes(response);
    const amazonStatus = isNotAuthenticated
      ? AMAZON_STATUSES.AUTHORIZATION_FAILURE
      : AMAZON_STATUSES.AUTHORIZATION_SUCCESSFULL;
    handleAmazonRedirect(amazonStatus);
  };

  const handleNextStep = async () => {
    // check errors
    if (handleErrors()) return;

    if (isLastElement && params.user_id) {
      setLoadingButton(true);
      const payload = {
        sector: formData.sector.id,
        is_group_fund: formData.type.id === 'investmentFundManager',
        vat: formData.vat,
        country: formData.country.id,
        employee_count_signup: Number(formData.employee_count_signup),
        company_name: formData.company_name,
        ...{ ...(isFromAmzAuth && { referal: AWS_BUSINESS_REFERAL }) }
      };

      // submit form
      const response: any = await dispatch(registerUser(params.user_id, payload));
      const newErrors = [...formData.errors].filter((elem) => elem.error !== 'general');
      setLoadingButton(false);
      if (response === 'INVALID_VAT_PASSED') {
        newErrors.push({ error: 'vat', description: t('register.cifNotValid') });
      } else if (response === 'ORGANIZATION_DUPLICATED_VAT') {
        newErrors.push({ error: 'vat', description: t('register.cifDuplicated') });
      } else if (!response || response === 'Invalid data entry') {
        newErrors.push({ error: 'general' });
      } else if (isFromAmzAuth) {
        verifyAmazonProcedence(response);
      } else {
        navigate(ROUTES.IMPROVE_DASHBOARD);
      }
      if (newErrors.length > 0) {
        setFormData({ ...formData, errors: newErrors });
        return;
      }
    }

    handleNext();
  };

  const handleSelectStep = (id: string) => {
    const stepSelected = stepList.find((elem) => elem.id === id);
    if (!stepSelected || !currentStep) return;
    // only allow to select steps that are before the current step
    // if (stepSelected?.stepContent?.stepNumber > currentStep?.stepContent?.stepNumber) return;
    handleSelectCarousel(id);
  };

  if (!user) return <LoaderTables />;

  return (
    <div className='register-company'>
      <img className='main-logo' src='/images/logoBlack.svg' alt='logo' />
      <div className='register-company__step-wrapper'>
        <div className='register-company__step-wrapper__left'>
          <StepCarouselList
            steps={steps}
            handleSelect={handleSelectStep}
            errors={formData.errors.map((elem) => elem.error)}
          />
          <div className='register-company__step-wrapper__left__content'>
            {currentStep && currentStep.stepContent && (
              <Step
                title={currentStep.stepContent.title}
                nextStep={handleNextStep}
                buttonText={currentStep.stepContent.buttonText}
                input={currentStep.stepContent.input}
                loadingButton={!!isLastElement && loadingButton}
              />
            )}
          </div>
        </div>
        <div className='register-company__step-wrapper__right'>
          {currentStep?.reviewImage && <ReviewImage {...currentStep.reviewImage} />}
        </div>
      </div>
      {process.env.REACT_APP_PARTNER !== 'dcycle' && <PoweredByDcycle />}
    </div>
  );
};

export default RegisterCompany;
