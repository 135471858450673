import type { IconName } from '../../../../../../types/utils/iconName';
import {
  LCA_IMPACT_CATEGORIES,
  LCA_IMPACT_CATEGORIES_ACIDIFICATION,
  LCA_IMPACT_CATEGORIES_CLIMATE_CHANGE,
  LCA_IMPACT_CATEGORIES_ECO_TOXICITY,
  LCA_IMPACT_CATEGORIES_ENERGY_RESOURCES,
  LCA_IMPACT_CATEGORIES_EUTROPHICATION,
  LCA_IMPACT_CATEGORIES_HUMAN_TOXICITY,
  LCA_IMPACT_CATEGORIES_IONISING_RADIATION,
  LCA_IMPACT_CATEGORIES_LAND_USE,
  LCA_IMPACT_CATEGORIES_OZONE_DEPLETION,
  LCA_IMPACT_CATEGORIES_PARTICULATE_MATTER_FORMATION,
  LCA_IMPACT_CATEGORIES_WATER_USE
} from '../../../constants';
import type { ImpactCategory } from '../../../types/ImpactCategory';

export const getImpactCategoryIcon = (impactCategory: ImpactCategory): IconName => {
  if (LCA_IMPACT_CATEGORIES.PHOTOCHEMICAL_OXIDANT_FORMATION_HUMAN === impactCategory) {
    return 'flow';
  }

  if (LCA_IMPACT_CATEGORIES.PHOTOCHEMICAL_OXIDANT_FORMATION_ECOSYSTEM === impactCategory) {
    return 'layers';
  }

  if (LCA_IMPACT_CATEGORIES.MATERIAL_RESOURCES_DEPLETION === impactCategory) {
    return 'mineral_resources';
  }

  if (LCA_IMPACT_CATEGORIES_OZONE_DEPLETION.includes(impactCategory)) {
    return 'ozone';
  }

  if (LCA_IMPACT_CATEGORIES_IONISING_RADIATION.includes(impactCategory)) {
    return 'radiation';
  }

  if (LCA_IMPACT_CATEGORIES_PARTICULATE_MATTER_FORMATION.includes(impactCategory)) {
    return 'particles';
  }

  if (LCA_IMPACT_CATEGORIES_LAND_USE.includes(impactCategory)) {
    return 'wheat_plant';
  }

  if (LCA_IMPACT_CATEGORIES_HUMAN_TOXICITY.includes(impactCategory)) {
    return 'human_toxicity';
  }

  if (LCA_IMPACT_CATEGORIES_ENERGY_RESOURCES.includes(impactCategory)) {
    return 'electricity';
  }

  if (LCA_IMPACT_CATEGORIES_WATER_USE.includes(impactCategory)) {
    return 'water';
  }

  if (LCA_IMPACT_CATEGORIES_ACIDIFICATION.includes(impactCategory)) {
    return 'acidification';
  }

  if (LCA_IMPACT_CATEGORIES_CLIMATE_CHANGE.includes(impactCategory)) {
    return 'cloud';
  }

  if (LCA_IMPACT_CATEGORIES_ECO_TOXICITY.includes(impactCategory)) {
    return 'earth';
  }

  if (LCA_IMPACT_CATEGORIES_EUTROPHICATION.includes(impactCategory)) {
    return 'eutrophication';
  }

  return 'fire';
};
