import apiFetch from '../../utils/apiFetch';

export const getGHGReportInfo = async (reportId?: string) => {
  try {
    const url = reportId ? `/ghg_report_info?report_id=${reportId}` : '/ghg_report_info';
    const response = await apiFetch('GET', url);
    return response.data.ghg_report_info;
  } catch (error) {
    return error;
  }
};

export const postGHGReportInfo = async (data: IGHGBack) => {
  try {
    const response = await apiFetch('POST', '/ghg_report_info', data);
    return response.data;
  } catch (error) {
    return error;
  }
};
