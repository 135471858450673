import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';
import SelectRaw from '../../select/selectRaw/SelectRaw';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children'>;
type SelectType = React.ComponentProps<typeof SelectRaw>;

type Props = SelectType & InputWrapperType;

const FormSelectRaw = (props: Props) => {
  return (
    <InputWrapper {...props}>
      <SelectRaw {...props} />
    </InputWrapper>
  );
};

export default FormSelectRaw;
