import apiFetch from '../../utils/apiFetch';

type HubBody = {
  name: string;
  type: string;
  address: string;
  organization_id: string;
  category: string;
};
export const createHubSubcontracted = async (hub: HubBody) => {
  try {
    const response = await apiFetch('POST', '/logistic_hubs', hub);
    return response.data;
  } catch (err) {
    return { error: err };
  }
};

export const editHub = async (id: string, hub: HubBody) => {
  try {
    const response = await apiFetch('PATCH', `/logistic_hubs/${id}`, hub);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteHub = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `/logistic_hubs/${id}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const moveFacilityToHub = async (hub: HubBody) => {
  try {
    const response = await apiFetch('POST', '/logistic_hubs', hub);
    return response.data;
  } catch (err) {
    return null;
  }
};
