import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/userContext';
import { User } from '../../../types/entities/user';
import NotFound from '../../layout/NotFound';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import InfiniteList from '../../ui/infiniteList/InfiniteList';
import './styles.scss';

const UsersList = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [users, setUsers] = useState<User[]>([]);
  const [total, setTotal] = useState(0);

  const url = '/users';

  const parseData = (users: User[]) => {
    return users.map((user) => {
      return {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.prefix + user.phone_number,
        onboarding_done: user.onboarding_done ? t('users.yes') : t('users.no'),
        sign_in_count: user.sign_in_count ? user.sign_in_count.toString() : '0'
      };
    });
  };

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('users.id')
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t('users.email')
    },
    {
      key: 'first_name',
      dataIndex: 'first_name',
      title: t('users.firstName')
    },
    {
      key: 'last_name',
      dataIndex: 'last_name',
      title: t('users.lastName')
    },
    {
      key: 'phone_number',
      dataIndex: 'phone_number',
      title: t('users.phoneNumber')
    },
    {
      key: 'onboarding_done',
      dataIndex: 'onboarding_done',
      title: t('users.onboardingDone')
    },
    {
      key: 'sign_in_count',
      dataIndex: 'sign_in_count',
      title: t('users.signInCount')
    }
  ];

  if (process.env.REACT_APP_PARTNER !== 'dcycle') {
    return <NotFound />;
  }

  return (
    <section className='users-list'>
      <SectionHeader title='Users' />
      <InfiniteList
        i18key={'users'}
        url={url}
        values={users}
        setValues={setUsers}
        columns={columns}
        parseData={parseData}
        organization={user?.selectedOrganization}
        total={total}
        setTotal={setTotal}
        filters={{
          inputTextKey: 'email',
          date: true
        }}
      />
    </section>
  );
};

export default UsersList;
