import { useTranslation } from 'react-i18next';
import { SimpleEmployee } from '../../../../../../types/entities/employee';
import Checkbox from '../../../../../ui/formComponents/checkbox/Checkbox';
import EmployeeObject from '../employeeObject/EmployeeObject';
import './styles.scss';

type Props = {
  employees: SimpleEmployee[];
  handleSelectEmployee: (employee: SimpleEmployee) => void;
  handleDeleteEmployee: (employee: SimpleEmployee) => void;
  handleSelectAll: () => void;
  allSelected: boolean;
};

const ListOfEmployees = ({
  employees,
  handleSelectEmployee,
  handleDeleteEmployee,
  handleSelectAll,
  allSelected
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='employee-list-wrapper'>
      <Checkbox
        text={t('sendEmailForm.selectAll')}
        onChange={handleSelectAll}
        selected={allSelected}
        type='square'
      />

      <div className='employee-list'>
        {employees.map((employee) => {
          const handleSelect = () => handleSelectEmployee(employee);
          const handleDelete = () => handleDeleteEmployee(employee);
          return (
            <EmployeeObject
              key={employee.email}
              employee={employee}
              handleSelect={handleSelect}
              handleDelete={handleDelete}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListOfEmployees;
