import { useTranslation } from 'react-i18next';
import { Column } from '../../../../../types/table';

const useColumns = (category: string, categoriesLength?: number) => {
  const { t } = useTranslation();

  let columns: Column[] = [
    {
      title: t('facilityDetail.supplier'),
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: t('facilityDetail.fuel'),
      dataIndex: 'facility_fuel',
      key: 'facility_fuel'
    },
    {
      title: t('facilityDetail.quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    },
    // {
    //   title: t('facilityDetail.unit'),
    //   dataIndex: 'unit',
    //   key: 'unit'
    // },
    {
      title: t('facilityDetail.startDate'),
      dataIndex: 'startDate',
      key: 'startDate'
    },
    {
      title: t('facilityDetail.endDate'),
      dataIndex: 'endDate',
      key: 'endDate'
    },
    {
      title: t('facilityDetail.invoiceIdColumn'),
      dataIndex: 'invoiceId',
      key: 'invoiceId'
    },
    {
      title: t('facilityDetail.cups_title'),
      dataIndex: 'cups',
      key: 'cups'
    },
    {
      title: t('facilityDetail.uploadedBy'),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy'
    },
    {
      title: t('facilityDetail.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: t('facilityDetail.attached'),
      dataIndex: 'file_url',
      key: 'file_url'
    },
    {
      title: <div style={{ textAlign: 'center' }}>CO₂ eq</div>,
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];

  if (category === 'electricity' && categoriesLength === 1) {
    const tocCategory = {
      title: 'TOC',
      dataIndex: 'toc',
      key: 'toc',
      render: (toc: string) => (toc !== '_' && toc !== '-' ? t(`logistics.tocs.${toc}`) : '-')
    };
    columns.splice(1, 0, tocCategory);
  }

  if (category === 'electricity' || category === 'water' || category === 'wastes') {
    // Remove facility_fuel column
    const index = columns.findIndex((elem) => elem.key === 'facility_fuel');

    if (index !== -1) {
      columns.splice(index, 1);
    }
  }

  if (category === 'recharge' || category === 'water' || category === 'wastes') {
    // Remove supplier column
    const index = columns.findIndex((elem) => elem.key === 'supplier');

    if (index !== -1) {
      columns.splice(index, 1);
    }
  }

  if (category !== 'electricity') {
    // Remove cups column
    const index = columns.findIndex((elem) => elem.key === 'cups');

    if (index !== -1) {
      columns.splice(index, 1);
    }
  }

  if (category === 'wastes') {
    columns = [
      {
        title: t('facilityDetail.lowCode'),
        dataIndex: 'low_code',
        key: 'low_code'
      },
      {
        title: t('facilityDetail.rdCode'),
        dataIndex: 'rd_code',
        key: 'rd_code'
      },
      {
        title: t('facilityDetail.totalKmToWasteCenter'),
        dataIndex: 'total_km_to_waste_center',
        key: 'total_km_to_waste_center'
      },
      {
        title: t('facilityDetail.quantity'),
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: t('facilityDetail.unit'),
        dataIndex: 'unit',
        key: 'unit'
      },
      {
        title: t('facilityDetail.startDate'),
        dataIndex: 'startDate',
        key: 'startDate'
      },
      {
        title: t('facilityDetail.endDate'),
        dataIndex: 'endDate',
        key: 'endDate'
      },
      {
        title: t('facilityDetail.identificationNumber'),
        dataIndex: 'invoiceId',
        key: 'invoiceId'
      },
      {
        title: t('facilityDetail.uploadedBy'),
        dataIndex: 'uploadedBy',
        key: 'uploadedBy'
      },
      {
        title: t('facilityDetail.status'),
        dataIndex: 'status',
        key: 'status'
      },
      {
        title: t('facilityDetail.attached'),
        dataIndex: 'file_url',
        key: 'file_url'
      },
      {
        title: <div style={{ textAlign: 'right' }}>CO₂ eq</div>,
        dataIndex: 'co2e',
        key: 'co2e'
      },
      {
        title: '',
        dataIndex: 'edit',
        key: 'edit'
      }
    ];
  }
  return columns;
};

export default useColumns;
