import { ReactNode } from 'react';
import StepHeader from '../templateSteps/StepHeader';
import './styles.scss';

type StepHeaderType = React.ComponentProps<typeof StepHeader>;

interface Props extends StepHeaderType {
  children: ReactNode[] | ReactNode;
  title?: string;
  width?: number;
}

const StepWrapper = ({ children, title, width = 300, ...rest }: Props) => {
  return (
    <div className='step-page-form'>
      <StepHeader title={title} {...rest} />
      <div className='step-page-form__content' style={{ width }}>
        <div className='step-wrapper'>{children}</div>
      </div>
    </div>
  );
};

export default StepWrapper;
