import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../ui/button/Button';
type Props = {
  closeModal: () => void;
};
const ReportRequested = ({ closeModal }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'fundDetail.reportRequested' });
  return (
    <div className='pias-report-requested'>
      <FormHeader title={t('title')} description={t('description')} />
      <FormButtonSection>
        <Button lookAndFeel={'primary'} text={t('accept')} onClick={closeModal} size='small' />
      </FormButtonSection>
    </div>
  );
};

export default ReportRequested;
