import { useContext } from 'react';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { checkFreePlan } from '../../../utils/checkFreePlan';
import { convertStringToDate } from '../../../utils/convertDates';
import LoaderCard from '../../ui/loaders/loaderCard/LoaderCard';
import CompanyEnvironmentImpact from '../dashboardCommon/companyEnviromentImpact/CompanyEnvironmentImpact';
import DoubleCardWrapper from '../dashboardCommon/doubleCardWrapper/DoubleCardWrapper';
import { EsgMetrics } from '../dashboardCommon/esgMetrics/EsgMetrics';
import EvolutionCompany from '../dashboardCommon/evolution/Evolution';

import List from '../dashboardCommon/list/List';
import OtherMetrics from '../dashboardCommon/otherMetrics/OtherMetrics';
import ScopeEnvironmentImpactCompany from '../dashboardCommon/scopeEnviromentImpact/ScopeEnviromentImpact';
import { useInvestmentDashboard } from './hooks/useInvestmentDashboard';

export const DashboardInvestment = () => {
  const organizationFound = useSelectedOrganization();
  const { startDate, endDate } = useContext(FilterDatesContext);

  const {
    loading,
    disabled,
    dataScopes,
    dataYearComparison,
    dataCategories,
    dataHolding,
    totalCo2
  } = useInvestmentDashboard();

  const freeTrialPlan = checkFreePlan(organizationFound);

  if (!organizationFound) {
    return null;
  }

  return (
    <>
      <div className='dashboard__body-holdings'>
        <CompanyEnvironmentImpact
          data={dataScopes}
          organization={organizationFound}
          startDate={convertStringToDate(startDate)}
          endDate={convertStringToDate(endDate)}
          loading={loading}
          disabled={disabled}
        />
        {dataYearComparison ? (
          <EvolutionCompany
            data={dataYearComparison}
            dataEvolution={[]}
            startDate={convertStringToDate(startDate)}
            endDate={convertStringToDate(endDate)}
            loading={loading}
            disabled={disabled || freeTrialPlan}
            freeTrial={freeTrialPlan}
          />
        ) : (
          <LoaderCard />
        )}
        {dataCategories ? (
          <ScopeEnvironmentImpactCompany
            data={dataCategories}
            loading={loading}
            disabled={disabled || freeTrialPlan}
            freeTrial={freeTrialPlan}
          />
        ) : (
          <LoaderCard />
        )}
        <DoubleCardWrapper>
          <OtherMetrics
            organization={organizationFound}
            startDate={convertStringToDate(startDate)}
            endDate={convertStringToDate(endDate)}
            loading={loading}
            totalCo2={totalCo2}
          />
          <EsgMetrics />
        </DoubleCardWrapper>

        {dataHolding ? (
          <List
            data={dataHolding}
            loading={loading}
            disabled={disabled}
            freeTrial={freeTrialPlan}
          />
        ) : (
          <LoaderCard />
        )}
      </div>
    </>
  );
};
