export type DataType =
  | 'semi_narrative'
  | 'narrative'
  | 'integer'
  | 'percent'
  | 'monetary'
  | 'table'
  | 'energy'
  | 'ghgemissions'
  | 'intensity'
  | 'mass'
  | 'volume'
  | 'area'
  | 'date_(DD/MM/YYY)'
  | 'float'
  | 'year';

export enum ModalType {
  Narrative = 'narrative',
  Table = 'table',
  Numeric = 'numeric',
  YesNo = 'yesNo',
  Date = 'date',
  Year = 'year'
}

export const modalMapping: Record<DataType, ModalType> = {
  semi_narrative: ModalType.Narrative,
  narrative: ModalType.Narrative,
  integer: ModalType.Numeric,
  percent: ModalType.Numeric,
  monetary: ModalType.Numeric,
  table: ModalType.Table,
  energy: ModalType.Numeric,
  intensity: ModalType.Narrative,
  ghgemissions: ModalType.Numeric,
  mass: ModalType.Numeric,
  volume: ModalType.Numeric,
  float: ModalType.Numeric,
  area: ModalType.Numeric,
  'date_(DD/MM/YYY)': ModalType.Date,
  year: ModalType.Year
};
