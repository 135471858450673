import { SIZE_PAGINATION_BIG } from '../../../../constants';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getListCustomEFGroup } from '../../../../services/api/customEF';
import useFetchInfiniteList from '../../../ui/infiniteList/hooks/useFetchInfiniteList';

const useFetchCustomEF = () => {
  const selectedOrganization = useSelectedOrganization();

  const fetch = async (page: number) => {
    if (!selectedOrganization) return;
    const response = await getListCustomEFGroup({ page, size: SIZE_PAGINATION_BIG });

    if (response?.response?.status >= 400) return;

    return response;
  };

  const {
    fetchData,
    refreshData,
    firstLoading,
    loading,
    total,
    data,
    addElement,
    editElement,
    removeElement,
    addElements
  } = useFetchInfiniteList<ICustomEFGroupBack>(fetch, []);

  return {
    fetchData,
    refreshData,
    firstLoading,
    loading,
    total,
    data,
    addElement,
    editElement,
    removeElement,
    addElements
  };
};

export default useFetchCustomEF;
