import { AssociationData } from '../../types/entities/grouping';
import apiFetch from '../../utils/apiFetch';

const GROUPING_URL = '/data_groups';

type GroupingBody = {
  name: string;
  description: string;
};
export const createGrouping = async (grouping: GroupingBody) => {
  try {
    const response = await apiFetch('POST', GROUPING_URL, grouping);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteGrouping = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `${GROUPING_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateGrouping = async (id: string, grouping: GroupingBody) => {
  try {
    const response = await apiFetch('PATCH', `${GROUPING_URL}/${id}`, grouping);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getGroupingById = async (id: string) => {
  try {
    const response = await apiFetch('GET', `${GROUPING_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getDataGroupsEntities = async (organization_id: string, category: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `${GROUPING_URL}/entities/${organization_id}/${category}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createDataGroupAssociationData = async (data: AssociationData) => {
  try {
    const response = await apiFetch('POST', `${GROUPING_URL}/association_data`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteDataGroupAssociationData = async (data_group_id: string, id: string) => {
  try {
    const response = await apiFetch(
      'DELETE',
      `${GROUPING_URL}/${data_group_id}/association_data/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
