import { IPurchaseSupplierFrontend } from 'types/purchaseSupplier';
import { PurchaseFramework } from 'utils/mapToPurchaseFramework';

export const extractFramework = (supplier: IPurchaseSupplierFrontend) => {
  if (supplier.codeType === PurchaseFramework.NACE) {
    return PurchaseFramework.SIC;
  }

  return supplier.codeType ?? PurchaseFramework.SIC;
};

export const extractCode = (supplier: IPurchaseSupplierFrontend) => {
  if (supplier.codeType === PurchaseFramework.NACE) {
    return '';
  }

  return supplier.code ?? '';
};
