import { useQuery } from '@tanstack/react-query';
import {
  getLCAMetadata,
  type LCAMaterialMetadata,
  type LCAProcessMetadata
} from '../../../../services/api/lca/lcaPortfolio';

type Params = {
  acv_id: string;
};

export const useLCANodeMetadata = ({ acv_id }: Params) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['lca-node-metadata', acv_id],
    queryFn: () => getLCAMetadata(acv_id),
    select: (response) => {
      return {
        material:
          response.material?.reduce((acc, material) => {
            acc[material.node_id] = material;

            return acc;
          }, {} as Record<string, LCAMaterialMetadata>) ?? {},

        process:
          response.process?.reduce((acc, process) => {
            acc[process.node_id] = process;

            return acc;
          }, {} as Record<string, LCAProcessMetadata>) ?? {}
      };
    }
  });

  return { data, isFetching, isLoading };
};
