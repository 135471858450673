import { useTranslation } from 'react-i18next';
import TooltipWrapper from '../../../../../../ui/tooltip/TooltipWrapper';
import TagStar from './TagStar';

const TagStarTooltip = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.columns' });

  return (
    <TooltipWrapper
      text={t('tooltipIa')}
      style={{
        marginLeft: 'auto'
      }}>
      <TagStar />
    </TooltipWrapper>
  );
};

export default TagStarTooltip;
