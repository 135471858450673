import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { EmployeePeriodFormType } from '../../../../../../types/entities/employeePeriod';

import { useParams } from 'react-router-dom';
import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';
import { ID_ORGS_PRIVACY_COMMUNICATION } from '../stepInit/privacyCommunication/constants';

type Props = {
  next: string;
  title?: ReactNode;
};

const StepName = ({ next, title }: Props) => {
  const { t } = useTranslation();
  const { startDate, endDate, id } = useParams();

  const { formData, setFormData, stepCounter, handleNext, handleBack } =
    useContext(EmployeeFormContext);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleErrors = () => {
    const newErrors: ErrorType[] = [];

    if (!formData?.name)
      newErrors.push({
        error: 'name',
        description: t('error.requiredField')
      });

    if (newErrors.length > 0) {
      setFormData((previous: EmployeePeriodFormType) => ({ ...previous, errors: newErrors }));
      return newErrors;
    }
    return false;
  };

  const privacyField = ID_ORGS_PRIVACY_COMMUNICATION.find((elem) => elem.id.includes(id ?? ''));

  const handleNextStep = () => {
    if (handleErrors() || !handleNext) return;
    handleNext(next);
  };

  if (!handleNext || !handleBack) return null;

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle1', {
        date: `${startDate && convertDateBackToFront(startDate)} - ${
          endDate && convertDateBackToFront(endDate)
        }`
      })}
      subtitle={t('employeeForm.formSubtitle2')}>
      <StepWrapper
        title={privacyField ? t('employeeForm.privacyFieldAludec') : t('employeeForm.name')}
        stepNumber={stepCounter}>
        <FormText
          icon={'/images/icons/person.svg'}
          placeholder={
            privacyField
              ? t('employeeForm.privacyFieldAludecPlaceholder')
              : t('employeeForm.namePlaceholder')
          }
          value={formData?.name}
          onChange={onChangeValue('name')}
          error={formData?.errors?.find((error) => error.error === 'name')}
        />
        <Button lookAndFeel={'primary'} onClick={handleNextStep} text={t('employeeForm.next')} />
      </StepWrapper>
      <SwitchStep handleNext={handleNextStep} handleBack={handleBack} />
    </StepControllerContainer>
  );
};

export default StepName;
