import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../../context/userContext';
import useErrors from '../../../../../../../customHooks/useErrors';
import { getDatadisCups } from '../../../../../../../services/api/datadis';
import { getFacilities } from '../../../../../../../services/api/facility';
import { getSuppliers } from '../../../../../../../services/api/invoices';
import { DatadisCUPS } from '../../../../../../../types/entities/datadisContract';
import { Facility } from '../../../../../../../types/entities/facility';

type Props = {
  nif: string;
};

const useGetData = ({ nif }: Props) => {
  const user = useContext(UserContext);
  const ERRORS = useErrors();

  const [suppliers, setSuppliers] = useState<SupplierType[]>([]);
  const [cups, setCups] = useState<DatadisCUPS[]>([]);

  const [error, setError] = useState<ErrorType>();
  const [loading, setLoading] = useState(false);

  const getSuppliersData = async () => {
    const response = await getSuppliers('ES');
    if (response?.response?.status >= 400) return;
    setSuppliers(response.filter((elem: { type: string }) => elem.type === 'electricity'));
  };

  const getCupsData = async () => {
    if (!user?.selectedOrganization) return;
    const response = await getDatadisCups(nif, user?.selectedOrganization);
    if (response?.response?.status === 502 || response?.supply_points.length === 0) {
      setError(ERRORS.DATADIS.TIMEOUT);
      return;
    }
    if (response?.response?.status >= 400) return;
    setCups(response.supply_points);
  };

  const getData = async () => {
    setLoading(true);
    await Promise.all([getSuppliersData(), getCupsData()]);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [nif, user?.selectedOrganization]);

  return { suppliers, cups, loading, error };
};

export default useGetData;
