import { numberToScientificNotation } from '../../../../../utils/numberToDecimal';
import { FrontLCAComponent, LCAFolder, LCAFoldersResponse } from '../types/LCADetail';

export const adaptLCAFolders = (data: LCAFoldersResponse): FrontLCAComponent[] => {
  return Object.entries(data).map(([key, value]) => {
    const impact = numberToScientificNotation(value.reduce((acc, curr) => acc + curr.impacts, 0));

    return {
      id: key,
      name: key,
      impact,
      parent_id: '',
      is_last: false
    };
  });
};

export const adaptLCAFolderItems = (data: LCAFolder): FrontLCAComponent[] => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    impact: numberToScientificNotation(item.impacts),
    parent_id: item.parent_id,
    is_last: false
  }));
};
