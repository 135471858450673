import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import { deleteEvent } from '../../../../services/api/events';

type Props = {
  eventToDelete: string;
  removeEvent: (id: string) => void;
};
const DeleteEvent = ({ eventToDelete, removeEvent }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDelete = async () => {
    try {
      setLoadingButton(true);
      // Call api to delete event
      await deleteEvent(eventToDelete, user?.selectedOrganization ?? '');

      setLoadingButton(false);
      // Callback and remove from list
      removeEvent(eventToDelete);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='delete-event'>
      <FormHeader
        title={t('eventForm.deleteTitle')}
        description={t('eventForm.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleDelete}
          text={t('eventForm.delete')}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteEvent;
