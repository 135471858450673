import { useNavigate } from 'react-router-dom';
import { IconName } from '../../../types/utils/iconName';
import Icon from '../../ui/icon/Icon';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';

type ContainerProps = {
  children: React.ReactNode;
  cardSizeInRem?: number;
};

const Container: React.FC<ContainerProps> = ({ children, cardSizeInRem = 22.5 }) => {
  const className = ['gap-4 grid-auto-rows'];

  className.push(`grid-auto-cols-${cardSizeInRem * 4}`);

  return <div className={className.join(' ')}>{children}</div>;
};

type LayoutProps = {
  children: React.ReactNode;
  type?: 'section' | 'item';
  hoverEnabled?: boolean;
  disabled?: boolean;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  type = 'section',
  hoverEnabled = true,
  disabled = false
}) => {
  const classes = {
    wrapper: ['relative px-3 pb-3 rounded-8 card-border-color border-1 border-solid border-box'],
    body: ['flex-col items-center justify-center gap-y-4']
  };

  if (hoverEnabled && !disabled) {
    classes.wrapper.push('hover:border-primary');
  }

  if (disabled) {
    classes.wrapper.push('bg-gray-2');
  }

  if (!disabled) {
    classes.wrapper.push('card-bg-color');
  }

  if (type === 'item') {
    classes.wrapper.push('h-60');
    classes.body.push('h-full');
  } else {
    classes.wrapper.push('pt-10 h-full');
    classes.body.push('py-2');
  }

  return (
    <div className={classes.wrapper.join(' ')}>
      <div className={classes.body.join(' ')}>{children}</div>
    </div>
  );
};

type TooltipProps = {
  text: string;
};

const CardTooltip: React.FC<TooltipProps> = ({ text }) => {
  return (
    <TooltipWrapper text={text} asChild>
      <div className='absolute t-2 r-2'>
        <Icon icon='information' color='blocked' size='medium' />
      </div>
    </TooltipWrapper>
  );
};

type IconProps = {
  icon: IconName;
};

const CardIcon: React.FC<IconProps> = ({ icon }) => {
  return (
    <div className='p-2 w-8 h-8 flex items-center justify-center border-box icon-bg-color rounded-8'>
      <Icon icon={icon} color='gradient' size='medium' />
    </div>
  );
};

type TextProps = {
  text: string;
  disabled?: boolean;
};

const Title: React.FC<TextProps> = ({ text, disabled }) => {
  const classes = ['font-body-b1-sb m-0 text-center'];

  if (disabled) {
    classes.push('text-gray-2');
  }

  return <h4 className={classes.join(' ')}>{text}</h4>;
};

const Subtitle: React.FC<TextProps> = ({ text, disabled }) => {
  const classes = ['font-body-b2-r m-0 text-center'];

  if (disabled) {
    classes.push('text-gray-2');
  }

  return <p className={classes.join(' ')}>{text}</p>;
};

const Data: React.FC<TextProps> = ({ text }) => {
  return <span className='highlight-text-color font-12 weight-600 m-0'>{text}</span>;
};

const Unit: React.FC<TextProps> = ({ text }) => {
  return <p className='font-body-b2-r m-0'> {text} </p>;
};

type CardLinkProps = {
  to: string;
  children: React.ReactNode;
  disabled?: boolean;
};
const CardLink: React.FC<CardLinkProps> = ({ to, children, disabled }) => {
  const classes = ['reset'];

  const navigate = useNavigate();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (disabled) {
      return;
    }

    navigate(to);
  };

  if (!disabled) {
    classes.push('pointer');
  }

  return (
    <button className={classes.join(' ')} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

const CardButtonDropdown: React.FC<React.ComponentProps<typeof ButtonDropdown>> = ({ options }) => {
  return (
    <ButtonDropdown
      options={options}
      style={{ position: 'absolute', top: '1rem', right: '0.6rem' }}
    />
  );
};
export const Card = {
  Container,
  Layout,
  Title,
  Subtitle,
  Data,
  ButtonDropdown: CardButtonDropdown,
  Icon: CardIcon,
  Tooltip: CardTooltip,
  Unit,
  Link: CardLink
};
