import { numberToDecimalNonZero } from '../../../utils/numberToDecimal';
import Button from '../button/Button';
import Card from '../cards/cardInfoDropdown/CardInfoDropdown';
import LoaderTables from '../loaders/loaderTables/LoaderTables';
import './styles.scss';

type CardData = {
  id: string;
  title: string;
  description?: string;
  co2e?: number;
  icon: string;
  tag?: string;
};

type Props = {
  listView: boolean;
  title?: string;
  description?: string;
  data: CardData[];
  blocked: boolean;
  showCreateModal?: () => void;
  showSecondModal?: () => void;
  loading: boolean;
  onClickCard: (id: string) => void;
  dropDownOptions: DropDownActionOption[];
  buttonText?: string;
  buttonText2?: string;
  hideCo2e?: boolean;
};
function CardList({
  listView,
  title,
  description,
  data,
  blocked,
  showCreateModal,
  showSecondModal,
  loading,
  onClickCard,
  dropDownOptions,
  buttonText,
  buttonText2,
  hideCo2e
}: Props) {
  return (
    <div className={`card-list ${listView ? 'list' : ''}`}>
      {title && description && buttonText && showCreateModal && (
        <div className='card'>
          <div className='create-new-card card-border-color '>
            <h1 className='headline4-font'>{title}</h1>
            <p className='subtitle3-font'>{description}</p>
            <div className='button-wrapper'>
              {data && (
                <Button
                  lookAndFeel={blocked ? 'blocked' : 'primary'}
                  text={buttonText}
                  onClick={showCreateModal}
                  size='small'
                />
              )}
              {showSecondModal && (
                <Button
                  lookAndFeel={'secondary'}
                  text={buttonText2}
                  onClick={showSecondModal}
                  size='small'
                />
              )}
            </div>
          </div>
        </div>
      )}
      {loading && <LoaderTables mode='list' />}
      {data &&
        !loading &&
        data.map((elem) => {
          let total: number | undefined = 0;
          if (elem.co2e) {
            total = numberToDecimalNonZero(elem.co2e / 1000, 4);
          }
          if (hideCo2e) {
            total = undefined;
          }
          return (
            <Card
              key={elem.id}
              title={elem.title}
              description={elem.description}
              total={total}
              unit={'t CO₂ eq.'}
              id={elem.id}
              icon={elem.icon}
              onClickCard={onClickCard}
              tag={elem.tag ?? undefined}
              dropDownOptions={dropDownOptions.map((option) => {
                return {
                  ...option,
                  onClick: () => {
                    option.onClick(elem.id);
                  }
                };
              })}
              listView={listView}
            />
          );
        })}
    </div>
  );
}

export default CardList;
