import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDatesContext } from '../../../../../../../context/filterDatesContext';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import useYearOptions from '../../../../../../../customHooks/useYearOptions';
import { patchDatasource } from '../../../../../../../services/api/esg';
import { EsgMetricsEnhanced } from '../../../../../../../types/entities/esgMetrics';
import checkFormErrors from '../../../../../../../utils/checkFormErrors';
import { convertStringToDate } from '../../../../../../../utils/convertDates';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementHalf from '../../../../../../ui/formComponents/formElementHalf/FormElementHalf';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import GovernanceFactory from './components/GovernanceFactory';
import SocialFactory from './components/SocialFactory';

export type ResponseMetric = string | SelectOptionFormat | number | File | undefined;

type FormData = {
  year: SelectOptionFormat;
  errors: ErrorType[];
};
type Props = {
  metric: EsgMetricsEnhanced;
  type: string;
  closeModal: () => void;
  editElement: (element: EsgMetricsEnhanced) => void;
};
const NarrativeModal = ({ metric, type, closeModal, editElement }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'controlPanel.metrics.narrativeModal' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });

  const { startDate } = useContext(FilterDatesContext);

  const [formData, setFormData] = useState<FormData>({
    year: {
      id: '',
      name: ''
    },
    errors: []
  });

  useEffect(() => {
    if (metric.value === true) {
      setResponse({ id: 'yes', name: tGeneral('yes') });
    } else if (metric.value === false) {
      setResponse({ id: 'no', name: tGeneral('no') });
    } else {
      setResponse(metric.value);
    }
  }, [metric.value]);

  useEffect(() => {
    const year = convertStringToDate(startDate).getFullYear();
    setFormData((prev) => ({
      ...prev,
      year: {
        id: year.toString(),
        name: year.toString()
      }
    }));
  }, [startDate]);

  const [response, setResponse] = useState<ResponseMetric>();

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    setLoadingButton(true);

    const errors = checkFormErrors(formData, []);

    if (errors.length) {
      setFormData({ ...formData, errors });
      setLoadingButton(false);
      return;
    }

    const bodyParsed = {
      value: '' as string | boolean | number
    };

    if (typeof response === 'object' && 'id' in (response as SelectOptionFormat)) {
      const responseParsed = response as SelectOptionFormat;
      if (responseParsed.id === 'yes') {
        bodyParsed.value = true;
      } else if (responseParsed.id === 'no') {
        bodyParsed.value = false;
      } else {
        bodyParsed.value = responseParsed.id;
      }
    } else if (!isNaN(response as number)) {
      const responseParsed = response as string;
      bodyParsed.value = +responseParsed;
    } else {
      bodyParsed.value = response as string;
    }

    const data = await patchDatasource(metric.id, bodyParsed);
    setLoadingButton(false);

    if (!data) {
      setFormData({
        ...formData,
        errors: [{ error: 'general', description: tError('somethingWentWrong') }]
      });
      return;
    }

    editElement({ ...metric, value: data.value, status: data.status });
    closeModal();
  };

  const renderFactory = () => {
    if (type === 'social') {
      return <SocialFactory name={metric.name} response={response} setResponse={setResponse} />;
    }

    if (type === 'governance') {
      return <GovernanceFactory name={metric.name} response={response} setResponse={setResponse} />;
    }

    return <></>;
  };

  const foundError = formData.errors.find((error) => error.error === 'general');
  const yearOptions = useYearOptions();

  return (
    <div>
      <FormHeader title={t('title')} />
      <FormWrapper>
        <FormElementHalf>
          <FormSelect
            icon='/images/icons/calendar.svg'
            label={t('selectYear')}
            onChange={onChangeValue('year')}
            value={formData.year}
            options={yearOptions.years}
            error={formData.errors.find((error) => error.error === 'year')}
            sort={false}
            disabled
          />
        </FormElementHalf>

        {renderFactory()}
      </FormWrapper>

      <FormButtonSection>
        <Button
          lookAndFeel='secondary'
          text={tGeneral('closeWithoutSaving')}
          onClick={closeModal}
          size='medium'
        />
        <Button
          lookAndFeel='primary'
          text={tGeneral('save')}
          size='medium'
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {foundError && <ErrorText>{foundError.description}</ErrorText>}
    </div>
  );
};

export default NarrativeModal;
