import { HTMLAttributes } from 'react';
import './styles.scss';
import CardWrapper from '../common/CardWrapper';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}
const CardCTA = ({ children, ...rest }: Props) => {
  return (
    <CardWrapper className={`card-cta-wrapper`} {...rest}>
      {children}
    </CardWrapper>
  );
};

// ############################################################################
const CardHeader = ({ children }: { children: React.ReactNode }) => {
  return <div className='card-cta-wrapper__header'>{children}</div>;
};

CardCTA.Header = CardHeader;

// ############################################################################
const CardButtons = ({ children }: { children: React.ReactNode }) => {
  return <div className='card-cta-wrapper__buttons'>{children}</div>;
};

CardCTA.Buttons = CardButtons;

export default CardCTA;
