import { useTranslation } from 'react-i18next';

const useAdaptCriteria = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'sbti.criteriaForm.descriptions'
  });
  const { t: tr } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.answers' });
  const { t: tm } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });

  const getCriteriaAnswer = (criteria: ISbtiCriteriaBack): string => {
    const yesNoCriterias: ISbtiCriteria[] = [
      'C1',
      'C2',
      'C5',
      'C11',
      'C15',
      'C17',
      'C18',
      'C20_2',
      'C21',
      'C22',
      'C23',
      'C24'
    ];

    if (yesNoCriterias.includes(criteria.criteria)) {
      if (!criteria?.yes_no || criteria?.status === 'blocked') return '-';
      return criteria.yes_no === 'yes' ? tr('yes') : tr('no');
    }

    if (criteria.criteria === 'C3' || criteria.criteria === 'C4') {
      if (criteria.status === 'blocked') return '-';
      return tr('yesC3');
    }

    if (criteria.criteria === 'C6') {
      if (!criteria?.submit_year || !criteria.goal_year) return '-';
      return tr('C6', { baseYear: criteria.submit_year, goalYear: criteria.goal_year });
    }

    if (criteria.criteria === 'C7') {
      if (!criteria?.base_year) return '-';
      return String(criteria.base_year);
    }

    if (criteria.criteria === 'C8') {
      if (!criteria?.ambition_level) return '-';
      if (criteria.ambition_level === '1_5') return tr('1_5');
      if (criteria.ambition_level === '2') return tr('2');
      if (criteria.ambition_level === 'wb2') return tr('wb2');
      return '-';
    }

    if (criteria.criteria === 'C9') {
      if (!criteria?.method) return '-';
      return criteria.method === 'absolute' ? tr('absolute') : tr('sectorial');
    }

    if (criteria.criteria === 'C10') {
      if (criteria.status === 'blocked') return '-';
      return tr('C10');
    }

    if (criteria.criteria === 'C12') {
      if (criteria.status === 'blocked') return '-';
      return tr('C12');
    }

    if (criteria.criteria === 'C13') {
      if (criteria.status === 'blocked') return '-';
      return tr('C13');
    }

    if (criteria.criteria === 'C14') {
      if (criteria.status === 'blocked') return '-';
      if (criteria?.market && criteria?.location) return tr('C14');
      if (criteria?.market) return tr('C14_market');
      if (criteria?.location) return tr('C14_location');
      return '-';
    }

    if (criteria.criteria === 'C16') {
      if (!criteria?.categories) return '-';
      return criteria.categories.map((category) => tr(category)).join(', ');
    }

    if (criteria.criteria === 'C19') {
      if (!criteria?.goal_year) return '-';
      return String(criteria.goal_year);
    }
    if (criteria.criteria === 'C20') {
      if (!criteria?.ambition_level) return '-';
      if (criteria.ambition_level === '2') return tr('yes');
    }

    if (criteria.criteria === 'C20_1') {
      if (!criteria?.yes_no) return '-';
      const yesNo = criteria.yes_no === 'yes' ? tr('yes') : tr('no');
      return tr('C20_1', { answer: yesNo, percentage: criteria.percentage });
    }

    return '';
  };

  const adaptCriteriaBack2Table = (criteria: ISbtiCriteriaBack): ISbtiCriteriaTable => {
    const actionedCriteria = [
      'C1',
      'C2',
      'C5',
      'C7',
      'C11',
      'C6',
      'C8',
      'C9',
      'C14',
      'C15',
      'C16',
      'C17',
      'C18',
      'C19',
      'C20',
      'C20_1',
      'C21'
    ];

    const criteriaStandard = {
      id: criteria.id,
      criteria: criteria.criteria,
      status: criteria.status || 'blocked',
      criteriaText: t(`${criteria.criteria}`),
      hasAction: actionedCriteria.includes(criteria.criteria),
      blockedBy: criteria.blocked_by
    };

    const answer: string = getCriteriaAnswer(criteria);

    return {
      ...criteriaStandard,
      answer
    };
  };

  const adaptCriteriaFront2Back = (criteria: ISbtiCriteriaFront): ISbtiCriteriaSendBack => {
    return {
      id: criteria.id,
      description: criteria.description || undefined,
      submit_year: criteria?.submitYear?.id
        ? Number(criteria.submitYear.id)
        : undefined || undefined,
      base_year: criteria?.baseYear?.id ? Number(criteria.baseYear.id) : undefined || undefined,
      goal_year: criteria?.goalYear?.id ? Number(criteria.goalYear.id) : undefined || undefined,
      year: criteria?.year?.id ? Number(criteria.year.id) : undefined,
      production_quantity: criteria.productionQuantity,
      ambition_level: criteria?.ambitionLevel?.id || undefined,
      method: criteria?.method?.id || undefined,
      yes_no: criteria?.yesNo?.id || undefined,
      market:
        criteria?.marketAndLocation?.id === '' || criteria?.marketAndLocation?.id === undefined
          ? undefined
          : ['market', 'both'].includes(criteria?.marketAndLocation?.id),
      location:
        criteria?.marketAndLocation?.id === undefined || criteria?.marketAndLocation?.id === ''
          ? undefined
          : ['location', 'both'].includes(criteria?.marketAndLocation?.id),
      co2e: criteria.co2e,
      co2e_biogenic: criteria.co2eBiogenic,
      sector: criteria?.sector?.id,
      percentage: criteria?.percentage || undefined,
      categories: criteria?.categories
    };
  };

  const adaptCriteriaBack2Front = (criteria: ISbtiCriteriaBack): ISbtiCriteriaFront => {
    const marketAndLocation = {
      id: '',
      name: ''
    };

    if (criteria.market) {
      marketAndLocation.id = 'market';
      marketAndLocation.name = tm('market');
    }

    if (criteria.location) {
      marketAndLocation.id = 'location';
      marketAndLocation.name = tm('location');
    }

    if (criteria.location && criteria.market) {
      marketAndLocation.id = 'both';
      marketAndLocation.name = tm('both');
    }

    let criteriaYesNo = criteria.yes_no
      ? { id: criteria.yes_no, name: tr(criteria.yes_no) }
      : undefined;

    if (criteria?.criteria === 'C20' && criteria?.ambition_level === '2') {
      criteriaYesNo = { id: 'yes', name: tr('yes') };
    }

    if (criteria?.criteria === 'C20' && criteria?.ambition_level !== '2') {
      criteriaYesNo = { id: 'no', name: tr('no') };
    }

    let ambitionLevel = criteria.ambition_level
      ? { id: criteria.ambition_level, name: tr(criteria.ambition_level) }
      : undefined;

    if (criteria?.criteria === 'C20' && criteria?.ambition_level === '2') {
      ambitionLevel = undefined;
    }

    if (criteria?.criteria === 'C20' && criteria?.yes_no === 'no') {
      ambitionLevel = undefined;
    }

    return {
      id: criteria.id,
      category: criteria.category,
      criteria: criteria.criteria,
      status: criteria.status,
      description: criteria.description,
      submitYear: criteria.submit_year
        ? { id: String(criteria.submit_year), name: String(criteria.submit_year) }
        : undefined,
      baseYear: criteria.base_year
        ? { id: String(criteria.base_year), name: String(criteria.base_year) }
        : undefined,
      goalYear: criteria.goal_year
        ? { id: String(criteria.goal_year), name: String(criteria.goal_year) }
        : undefined,
      year: criteria.year ? { id: String(criteria.year), name: String(criteria.year) } : undefined,
      productionQuantity: criteria.production_quantity,
      ambitionLevel: ambitionLevel,
      method: criteria.method ? { id: criteria.method, name: tr(criteria.method) } : undefined,
      yesNo: criteriaYesNo,
      marketAndLocation,
      categories: criteria.categories,
      co2e: criteria.co2e,
      co2eBiogenic: criteria.co2e_biogenic,
      sector: criteria.sector ? { id: criteria.sector, name: tr(criteria.sector) } : undefined,
      percentage: criteria.percentage,
      errors: []
    };
  };

  return { adaptCriteriaBack2Table, adaptCriteriaFront2Back, adaptCriteriaBack2Front };
};

export default useAdaptCriteria;
