import { IconName } from '../../../../types/utils/iconName';
import Icon from '../../icon/Icon';
import Label from '../../label/Label';
import '../styles.scss';

type Props = React.ComponentProps<typeof Label> & {
  icon?: IconName;
  showIcon?: boolean;
};

const PendingLabel = ({ children, showIcon = true, icon = 'clock', ...rest }: Props) => {
  return (
    <Label {...rest} lookAndFeel='secondary'>
      <div className='label-loading-wrapper'>
        {showIcon && <Icon icon={icon} color='pending' size='medium' />}
        {children}
      </div>
    </Label>
  );
};

export default PendingLabel;
