import { useTranslation } from 'react-i18next';
import Modal from '../../../../ui/modal/Modal';
import TableComponent from '../../../../ui/table/Table';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import VideoTutorialPopup from '../../../../ui/videoTutorialPopup/VideoTutorialPopup';
import { HeaderInformativeTab } from '../HeaderInformativeTab';
import ModalCriteria from './components/modalCriteria/ModalCriteria';
import useCriteria from './hooks/useCriteria';
import { CriteriaProvider } from './hooks/useCriteriaContext';
import useCriteriaList from './hooks/useCriteriaList';
import './styles.scss';
import { sortCriteriaTable } from './utils';
import { useState } from 'react';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import YoutubeVideo from '../../../../ui/videoPlayer/YoutubeVideo';
import { VIDEOS } from '../../../../../constants';

export const Criteria = () => {
  const { t, i18n } = useTranslation();

  const videos = VIDEOS.sbti as { [key: string]: string };
  const videoLink = videos[i18n.resolvedLanguage] as string;

  const { criteriaListTable, criteriaList, handleNewCriterias, loading } = useCriteriaList();

  // criteriaToSHow and handleCloseCriteria are used to work with the corresponding modal
  const { criteria: criteriaToShow, columns, handleCloseCriteria } = useCriteria();

  const [showVideo, setShowVideo] = useState(false);

  const total = criteriaListTable?.filter((criteria) => criteria.status === 'accepted').length;
  const total2 = criteriaListTable?.filter((criteria) => criteria.status === 'pending').length;
  const total3 = criteriaListTable?.filter((criteria) => criteria.status === 'blocked').length;

  return (
    <>
      <HeaderInformativeTab />
      <div className='infinite-scroll-container card-border-color main-bg-color solid-border'>
        <div className='table-header'>
          <div
            style={{
              marginLeft: 'auto'
            }}>
            <TotalLegend
              total={total}
              total2={total2}
              total3={total3}
              loading={loading}
              i18key={'sbti'}
            />
          </div>
        </div>
        <div className='infinite-scroll-wrapper infinite-list-criteria'>
          <TableComponent
            data={criteriaListTable.sort(sortCriteriaTable)}
            columns={columns}
            loading={loading}
          />
        </div>
      </div>
      {criteriaToShow && (
        <Modal show={!!criteriaToShow} onClose={handleCloseCriteria} maxWidth='560px'>
          <CriteriaProvider
            value={{
              criteria: criteriaToShow,
              criteriaList,
              handleCloseCriteria,
              handleNewCriterias
            }}>
            <ModalCriteria />
          </CriteriaProvider>
        </Modal>
      )}
      <VideoTutorialPopup
        title={t('sbti.video')}
        description={t('purchases.video.description')}
        image='/images/juanjo.png'
        onClick={() => setShowVideo(true)}
      />
      <Modal show={showVideo} onClose={() => setShowVideo(false)} width='700px'>
        <FormHeader title={t('sbti.demo')} />
        <YoutubeVideo url={videoLink} />
      </Modal>
    </>
  );
};
