import { useEffect, useState } from 'react';

type Props = {
  startDate: string;
  endDate: string;
};

type CategoryInfo = {
  category: string;
  trips: number;
  kms: number;
  emissions: number;
};

const useGetData = ({ startDate, endDate }: Props) => {
  const [categoryInfo, setCategoryInfo] = useState<CategoryInfo[]>([]);
  const fetchData = async () => {
    // TODO: fetch data
    setCategoryInfo([
      {
        category: 'road',
        trips: 3,
        kms: 997.238,
        emissions: 857.17
      },
      {
        category: 'rail',
        trips: 1,
        kms: 949.51,
        emissions: 7.69
      },
      {
        category: 'air',
        trips: 2,
        kms: 10761.694,
        emissions: 10449.6
      },
      {
        category: 'maritime',
        trips: 1,
        kms: 8519.2,
        emissions: 109.9
      }
    ]);
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);
  return { categoryInfo };
};

export default useGetData;
