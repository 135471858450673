import { useQuery } from '@tanstack/react-query';
import { getUnits } from '../../services/api/unit';
import { useTranslation } from 'react-i18next';

type Unit = {
  id: string;
  name: string;
  type: string;
};

export const useUnits = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'units_short' });

  const { data, error, isLoading } = useQuery({
    queryKey: ['units'],
    queryFn: () => getUnits() as Promise<Unit[]>,
    select: (units) => units.map((unit: Unit) => ({ id: unit.id, name: t(unit.name) }))
  });

  return { data: data ?? [], error, isLoading };
};
