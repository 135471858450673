import { useEffect, useState } from 'react';
import {
  getFundList,
  getLatestFundInvestmentByCompany
} from '../../../../../../services/api/funds';
import { FundType } from '../../../../../../types/entities/funds';
import {
  IBackendLatestInvestment,
  IFrontendLatestInvestment
} from '../../../../../../types/entities/organization';
import { adaptLatestInvestmentBackToFront } from '../../../../../../adapters/matrices';

type Props = {
  id: string;
  fundId?: string;
  newLink?: boolean;
};

const useGetData = ({ id, fundId, newLink = false }: Props) => {
  const [funds, setFunds] = useState<FundType[]>([]);
  const [latestInvestment, setLatestInvestment] = useState<IFrontendLatestInvestment>();

  const [loading, setLoading] = useState(false);

  const fetchLatestInvestment = async () => {
    const data: IBackendLatestInvestment = await getLatestFundInvestmentByCompany(id, fundId);
    setLatestInvestment(adaptLatestInvestmentBackToFront(data));
  };

  const fetchFunds = async () => {
    if (loading) return;
    setLoading(true);
    const response = await getFundList();

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    setFunds(response.items);
    setLoading(false);
  };
  useEffect(() => {
    fetchFunds();
    if (newLink) return;
    fetchLatestInvestment();
  }, []);

  return { funds, latestInvestment, loading };
};

export default useGetData;
