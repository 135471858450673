import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { FilterOptionType } from './FilterSection';

export type FilterContextType = {
  setFilters: Dispatch<SetStateAction<FilterOptionType[]>>;
  filters: FilterOptionType[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  dates?: { startDate: string; endDate: string };
  setDates?: Dispatch<SetStateAction<{ startDate: string; endDate: string } | undefined>>;
  removeFilters: Dispatch<SetStateAction<boolean>>;
  setClearDates?: Dispatch<SetStateAction<boolean>>;
  remove: boolean;
  open: boolean;
  clearDates?: boolean;
  dateKeys?: string[];
  searchFilters?: {
    key: string;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
  }[];
};

const defaultValues: FilterContextType = {
  setFilters: (filter: SetStateAction<FilterOptionType[]>) => {
    console.log(filter);
  },
  filters: [],
  setOpen: (open: SetStateAction<boolean>) => {
    console.log(open);
  },
  removeFilters: (remove: SetStateAction<boolean>) => console.log(remove),
  dates: { startDate: '', endDate: '' },
  setDates: (dates: SetStateAction<{ startDate: string; endDate: string } | undefined>) =>
    console.log(dates),
  remove: false,
  open: false
};

const FiltersContext = createContext(defaultValues);

const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  return context;
};

export { FiltersContext, useFiltersContext };
