import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { getVehicleConsumptionByOrgIdStatus } from '../../../../services/api/vehicleConsumptions';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';

const GetVehicleConsumptionError = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    organizationId: '',
    errors: [] as ErrorType[]
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    const errors = checkFormErrors(formData, formData.errors, []);
    if (errors.length > 0) {
      setFormData({
        ...formData,
        errors
      });
      return;
    }
    setLoadingButton(true);
    const data = await getVehicleConsumptionByOrgIdStatus(formData.organizationId);
    if (!data) {
      setLoadingButton(false);
      return;
    }
    window.open(data.file_url, '_blank');
    setLoadingButton(false);
  };
  return (
    <div className='get-vehicleConsumption-error'>
      <FormHeader title={t('vehicleConsumptionUpload.getVehicleConsumptionWithErrors')} />
      <FormWrapper>
        <FormText
          icon={'/images/icons/organization.svg'}
          placeholder={t('vehicleConsumptionUpload.organizationId')}
          label={t('vehicleConsumptionUpload.organizationId')}
          value={formData.organizationId}
          onChange={onChangeValue('organizationId')}
          error={formData.errors.find((error) => error.error === 'organizationId')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('vehicleConsumptionUpload.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default GetVehicleConsumptionError;
