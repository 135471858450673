import { useEffect, useRef } from 'react';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { Organization } from '../../../../../../types/entities/organization';
import { getOrganizationSupplierCredits } from '../../../../../../services/api/purchaseSuppliers';
import { useDispatch } from 'react-redux';
import { setOrganizationLimitOfficialSuppliersBonusAction } from '../../../../../../actions/auth';
import { useTranslation } from 'react-i18next';
import { Dispatch } from '@reduxjs/toolkit';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { usePurchasesLoader } from '../../../../../../reducers/purchasesLoader';

const refreshLimit = async (org: Organization, dispatch: Dispatch<any>) => {
  const limit_official_suppliers_bonus = await getOrganizationSupplierCredits(org.id);

  dispatch(
    setOrganizationLimitOfficialSuppliersBonusAction(org.id, limit_official_suppliers_bonus ?? '0')
  );
};

const refreshUntilIsNotFetchingInforma = async (
  org: Organization,
  dispatch: Dispatch<any>,
  t: (i18nKey: string) => string,
  queryClient: QueryClient
) => {
  const isFetchingInforma = Number.isNaN(Number(org.limit_official_suppliers_bonus));

  if (!isFetchingInforma) {
    return;
  }

  const limit_official_suppliers_bonus = await getOrganizationSupplierCredits(org.id);

  if (
    limit_official_suppliers_bonus === null ||
    Number.isNaN(Number(limit_official_suppliers_bonus))
  ) {
    // If the request fails or we are still fetching the data from Informa, we try again in 10 seconds
    setTimeout(() => refreshUntilIsNotFetchingInforma(org, dispatch, t, queryClient), 10000);

    return;
  }

  dispatch(
    setOrganizationLimitOfficialSuppliersBonusAction(org.id, limit_official_suppliers_bonus)
  );

  queryClient.invalidateQueries({ queryKey: ['purchaseSuppliers'] });
};

export const useRefreshLimit = () => {
  const isFetchingInitialState = useRef(true);
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();
  const purchaseLoader = usePurchasesLoader();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isFetchingInforma = Number.isNaN(Number(org?.limit_official_suppliers_bonus));

  useEffect(() => {
    if (!org || purchaseLoader) {
      return;
    }

    if (isFetchingInitialState.current) {
      refreshLimit(org, dispatch).then(() => {
        isFetchingInitialState.current = false;
      });

      return;
    }

    if (isFetchingInforma && !isFetchingInitialState.current) {
      refreshUntilIsNotFetchingInforma(org, dispatch, t, queryClient);
    }
  }, [org, isFetchingInforma, purchaseLoader]);
};
