import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FreeTrialContext } from '../../../context/freeTrialContext';
import IconBackground from '../../ui/iconBackground/IconBackground';

type Props = {
  id: string;
  title: string;
  subtitle: string;
  status?: 'completed' | 'pending';
  percentage: string;
  onSelect: (id: string) => void;
  recommendation: string;
  icon: string;
  recommended?: boolean;
  premium: boolean;
  subscriptionPlan: string;
};
function SliderCard({
  id,
  title,
  subtitle,
  status,
  percentage,
  onSelect,
  recommendation,
  icon,
  recommended = false,
  premium,
  subscriptionPlan
}: Props) {
  const { t } = useTranslation();

  const freeTrialContext = useContext(FreeTrialContext);

  const handleSelectCard = () => {
    if (premium && subscriptionPlan === 'free_plan') {
      freeTrialContext.setShowPremiumFeatureModal(true);
    } else {
      onSelect(id);
    }
  };

  const renderRecommendationStatus = () => {
    if (status === 'pending') {
      return (
        <div className={'pending-tag body3-font tag-bg-text-color '}>
          {t('recommendations.applied')}
        </div>
      );
    } else if (status === 'completed') {
      return (
        <div className='completed-tag body3-font primary-light-bg-color on-dark-text-color'>
          {t('recommendations.completed')}
        </div>
      );
    } else {
      return (
        <div className={'pending-tag body3-font tag-selected-bg-text-color'}>
          {t('recommendations.pending')}
        </div>
      );
    }
  };
  return (
    <div
      className={`card slider-card ${recommendation === id ? 'card--selected' : ''} `}
      onClick={handleSelectCard}>
      {recommended && (
        <div className='recommended-tag tag2-bg-text-color'>
          <img src='/images/icons/starWhite.svg' alt='recommended' />
        </div>
      )}
      {premium && subscriptionPlan === 'free_plan' && (
        <div className='premium-tag tag-bg-color'>
          <img src='/images/icons/lockPlain.svg' alt='premium' />
        </div>
      )}
      <IconBackground icon={icon} alt={'bookmark'} />
      <div className='step-info'>
        {/* <span className='body1-font input-placeholder-color'>{title}</span> */}
        <span className='headline4-font'>{subtitle}</span>
        <div className='tags'>{renderRecommendationStatus()}</div>
      </div>
    </div>
  );
}

export default SliderCard;
