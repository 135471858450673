import moment from 'moment';
import apiFetch from '../../utils/apiFetch';

type CheckoutSession = {
  payment_type: string;
  payment_entity?: string;
  email: string;
  price: number;
  product_name: string;
  product_description: string;
  organization_id: string;
  offset?: number;
  offset_type?: number;
  scopes?: number[];
  start_date?: string;
  end_date?: string;
  limit_facilities?: number;
  limit_vehicles?: number;
  limit_employees?: number;
  event_id?: string;
};
export const createCheckoutSession = async (data: CheckoutSession) => {
  try {
    // Convert date to YYYY-MM-DD format
    const start_date = data?.start_date
      ? moment(data?.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : undefined;
    const end_date = data?.end_date
      ? moment(data?.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : undefined;

    const response = await apiFetch('POST', '/payments/create-checkout-session', {
      payment_type: data.payment_type,
      payment_entity: data.payment_entity ?? undefined,
      price: data.price,
      product_name: data.product_name,
      email: data.email,
      organization_id: data.organization_id,
      product_description: data.product_description,
      offset: data.offset ?? undefined,
      offset_type: data.offset_type ?? undefined,
      scopes: data.scopes ?? undefined,
      start_date,
      end_date,
      limit_facilities: data.limit_facilities ?? undefined,
      limit_vehicles: data.limit_vehicles ?? undefined,
      limit_employees: data.limit_employees ?? undefined,
      event_id: data.event_id ?? undefined
    } as CheckoutSession);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
