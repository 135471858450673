import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  adaptOrganizationGoalBackToFront,
  adaptOrganizationGoalFrontToBack
} from '../../../../../adapters/organizationGoal';
import {
  patchOrganizationGoal,
  postOrganizationGoal
} from '../../../../../services/api/organizationGoal';
import {
  IOrganizationGoalBack,
  IOrganizationGoalFront
} from '../../../../../types/entities/organizationGoal';
import { convertDateBackToFront } from '../../../../../utils/convertDates';
import Button from '../../../../ui/button/Button';
import GrayBackground from '../../../../ui/grayBackground/GrayBackground';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import Modal from '../../../../ui/modal/Modal';
import AddGoal from './addGoal/AddGoal';
import './styles.scss';

type Props = {
  loading: boolean;
  organizationGoal: IOrganizationGoalBack | null;
  handleAddOrganizationGoal: (value: IOrganizationGoalBack) => void;
};

const Objective = ({ loading, organizationGoal, handleAddOrganizationGoal }: Props) => {
  const { t } = useTranslation();
  const [showAddGoal, setShowAddGoal] = useState(false);

  const handleOpenAddGoal = () => setShowAddGoal(true);
  const handleCloseAddGoal = () => setShowAddGoal(false);

  const color = organizationGoal ? 'highlight-text-color' : 'disabled-text-color';
  const date = organizationGoal
    ? `${convertDateBackToFront(organizationGoal.base_year_date)} - ${convertDateBackToFront(
        organizationGoal.goal_year_date
      )}`
    : 'DD/MM/YYYY - DD/MM/YYYY';
  const scope1 = organizationGoal ? `${organizationGoal.scope1_goal} %` : '0 %';
  const scope2 = organizationGoal ? `${organizationGoal.scope2_goal} %` : '0 %';
  const scope3 = organizationGoal ? `${organizationGoal.scope3_goal} %` : '0 %';

  const handleOnSubmit = async (value: IOrganizationGoalFront) => {
    const valueBackend = adaptOrganizationGoalFrontToBack(value);
    let response = null;

    if (!value.id) response = await postOrganizationGoal(valueBackend);
    else response = await patchOrganizationGoal(value.id, valueBackend);

    setShowAddGoal(false);
    if (response?.response?.status >= 400) return;
    handleAddOrganizationGoal(response);
  };

  const organizationGoalFilled = organizationGoal
    ? adaptOrganizationGoalBackToFront(organizationGoal)
    : undefined;

  if (loading) return <LoaderCard className='item-objective' />;

  return (
    <div className='card item-objective'>
      <h2 className='headline4-font objective-title'>{t('modeling.objective.title')}</h2>
      <GrayBackground className='objective-wrapper'>
        <div className='objective-data' style={{ height: '100%' }}>
          <div className='objective'>
            <h4 className={`headline4-font ${color}`}>{date}</h4>
            <p className='subtitle3-font'>{t('modeling.objective.comparison')}</p>
          </div>

          <div className='objective'>
            <h4 className={`headline4-font ${color}`}>{scope1}</h4>
            <p className='subtitle3-font'>{t('modeling.objective.scope1Reduction')}</p>
          </div>

          <div className='objective'>
            <h4 className={`headline4-font ${color}`}>{scope2}</h4>
            <p className='subtitle3-font'>{t('modeling.objective.scope2Reduction')}</p>
          </div>

          <div className='objective'>
            <h4 className={`headline4-font ${color}`}>{scope3}</h4>
            <p className='subtitle3-font'>{t('modeling.objective.scope3Reduction')}</p>
          </div>
        </div>
        <Button
          lookAndFeel={'primary'}
          size='medium'
          text={t('modeling.objective.button')}
          style={{ width: '75%', margin: 'auto' }}
          onClick={handleOpenAddGoal}
        />
      </GrayBackground>
      <Modal show={showAddGoal} onClose={handleCloseAddGoal} maxWidth='400px'>
        <AddGoal handleOnSubmit={handleOnSubmit} formDataFilled={organizationGoalFilled} />
      </Modal>
    </div>
  );
};

export default Objective;
