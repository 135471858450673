import { useContext } from 'react';
import { UserContext } from '../../../context/userContext';
import { useQuery } from '@tanstack/react-query';
import { getUserInvites } from '../../../services/api/invites';

export const useUserInvites = () => {
  const user = useContext(UserContext);

  const { data } = useQuery({
    queryKey: ['userInvites', user?.email],
    queryFn: () => getUserInvites({ user })
  });

  return { data: data ?? [] };
};
