import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../../ui/modal/Modal';
import { Nuvo } from '../Nuvo/Nuvo';
import Button from '../../../../ui/button/Button';
import { useTotal } from '../suppliersManagement/hooks/useTotal';
import { ResultValues } from 'nuvo-react';
import { HowToUploadStep } from './HowToUploadStep';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const ModalHowToUpload: React.FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation();

  const { setTotal } = useTotal();

  const onUpload = (results: ResultValues) => {
    setTotal((prev) => {
      if (prev === undefined) {
        return results.length;
      }

      return prev + results.length;
    });
    onClose();
  };

  return (
    <Modal.WithPortal show={show} onClose={onClose} maxWidth='680px' closeOnOutsideClick={false}>
      <Modal.Header
        title={t('purchases.uploadYourPurchases')}
        description={t('purchases.uploadYourPurchasesDescription')}
      />

      <div className='flex-col gap-y-2'>
        <HowToUploadStep.Layout>
          <HowToUploadStep.Image step={1} />
          <Trans i18nKey='purchases.uploadYourPurchasesStep1'>
            <HowToUploadStep.TextWrapper>
              <HowToUploadStep.Title>0</HowToUploadStep.Title>
              <HowToUploadStep.Subtitle>1</HowToUploadStep.Subtitle>
              <ul className='my-0 font-body-b2-r'>
                <li>0</li>
                <li>1</li>
                <li>2</li>
                <li>3</li>
              </ul>
            </HowToUploadStep.TextWrapper>{' '}
          </Trans>
        </HowToUploadStep.Layout>

        <HowToUploadStep.Layout>
          <HowToUploadStep.Image step={2} />
          <Trans i18nKey='purchases.uploadYourPurchasesStep2'>
            <HowToUploadStep.TextWrapper>
              <HowToUploadStep.Title>0</HowToUploadStep.Title>
              <HowToUploadStep.Subtitle>1</HowToUploadStep.Subtitle>
              <ul className='my-0 font-body-b2-r'>
                <li>0</li>
                <li>1</li>
                <li>2</li>
              </ul>
            </HowToUploadStep.TextWrapper>
          </Trans>
        </HowToUploadStep.Layout>

        <HowToUploadStep.Layout>
          <HowToUploadStep.Image step={3} />
          <Trans i18nKey='purchases.uploadYourPurchasesStep3'>
            <HowToUploadStep.TextWrapper>
              <HowToUploadStep.Title>0</HowToUploadStep.Title>
              <HowToUploadStep.Subtitle>1</HowToUploadStep.Subtitle>
            </HowToUploadStep.TextWrapper>
          </Trans>
        </HowToUploadStep.Layout>
      </div>

      <div className='grid grid-cols-2 gap-x-2 mt-10'>
        <Button
          lookAndFeel='secondary'
          text={t('purchases.cancel')}
          onClick={onClose}
          style={{ width: 'unset', flexGrow: 1 }}
          size='small'
        />
        <Nuvo onUpload={onUpload} onClose={onClose} />
      </div>
    </Modal.WithPortal>
  );
};
