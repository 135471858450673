import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteLCA } from '../../../../../services/api/lca/lcaPortfolio';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';

export const useDeleteLCA = () => {
  const dispatch = useDispatch();
  const { t } = useLCATranslation();
  const org = useSelectedOrganization();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteLCA,
    onSuccess: () => {
      dispatch(setNotification(t('deletionModal.successDelete')));

      queryClient.invalidateQueries({
        queryKey: ['lcaPortfolio', org?.id]
      });
    }
  });

  return { deleteLCA: mutateAsync, isDeleting: isPending };
};
