import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterInfo2 } from '../../../types/entities/user';
import StepText from '../../ui/stepPage/templateSteps/StepText';
type Props = {
  nextStep: (value: RegisterInfo2) => void;
  stepNumber: number;
  registerInfo: RegisterInfo2;
};
const StepCompanyName = ({ nextStep, stepNumber, registerInfo }: Props) => {
  const { t, i18n } = useTranslation();
  const [companyName, setCompanyName] = useState('');

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  };

  const handleNextStep = () => {
    nextStep({
      ...registerInfo,
      company_name: companyName
    });
  };

  return (
    <StepText
      nextStep={handleNextStep}
      stepNumber={stepNumber}
      title={t('onboardingUser.stepCompanyName.title')}
      //   tooltip={t('onboardingUser.stepCompanyName.tooltip')}
      buttonText={t('onboardingUser.stepCompanyName.button')}
      placeholder={t('onboardingUser.stepCompanyName.placeholder')}
      onChangeValue={onChangeName}
      value={companyName}
      icon={'/images/icons/briefcase.svg'}
    />
  );
};

export default StepCompanyName;
