import { useTranslation } from 'react-i18next';
import Label from '../../label/Label';
import Line from '../line/Line';
import './styles.scss';
import { ReactNode } from 'react';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: ReactNode;
  isSelected: boolean;
  lookAndFeel?: 'big' | 'small' | 'breadcrumb';
  error?: string;
}

const useCarouselTranslation = () => {
  const { t: translate, i18n } = useTranslation();

  const t = (text: ReactNode) => {
    if (typeof text === 'string' && i18n.exists(text)) {
      return translate(text);
    }

    return text;
  };

  return { t };
};

const StepCarousel = ({ text, isSelected, lookAndFeel = 'big', error, ...rest }: Props) => {
  const { t } = useCarouselTranslation();

  if (lookAndFeel === 'big') {
    return (
      <div className='step-carousel big' {...rest}>
        <span className={`${error ? 'error-text-color' : ''} body3-font`}>{t(text)}</span>
        <Line isSelected={isSelected}></Line>
      </div>
    );
  }

  if (lookAndFeel === 'small') {
    return (
      <div className='step-carousel' {...rest}>
        <Label lookAndFeel={isSelected ? 'primary' : 'secondary'}>{t(text)}</Label>
      </div>
    );
  }

  if (lookAndFeel === 'breadcrumb') {
    return (
      <div
        className={`${
          isSelected ? 'on-light-text-color' : 'on-light-text-color2'
        } step-carousel body1-font`}
        {...rest}>
        {t(text)}
        {!isSelected && ' > '}
      </div>
    );
  }

  return null;
};

export default StepCarousel;
