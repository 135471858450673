import { useRef, useState } from 'react';
import { InputSize } from '../../../../../types/utilsEnums/input';
import Option from './Option';
import './styles.scss';
import { gray_color } from '../../../../../styles/colors';
type Props = {
  options: SelectOptionFormat[];
  idSelected?: string;
  handleChangeValue: (option: SelectOptionFormat) => void;
  handleGetDown?: () => Promise<void>;
  size?: InputSize;
};
const OptionList = ({ options, idSelected, handleChangeValue, handleGetDown, size }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const handleScrollEnd = async () => {
    if (!ref.current || loading === true || !handleGetDown) return;
    const { scrollTop, scrollHeight, clientHeight } = ref.current;

    if (scrollTop + clientHeight + 5 < scrollHeight) return;

    setLoading(true);
    await handleGetDown();
    setLoading(false);
  };

  return (
    <div className='select-options-list' onScroll={handleScrollEnd} ref={ref}>
      {options.map((option) => (
        <Option
          key={option.optionalKey ?? option.id}
          onClickOption={handleChangeValue}
          isSelected={option.id === idSelected}
          value={option}
          size={size}
        />
      ))}

      {loading && (
        <Option
          key={'loading'}
          onClickOption={() => console.log('loading select')}
          isSelected={false}
          value={{
            id: 'loading',
            name: 'Loading...'
          }}
          size={size}
          style={{ color: gray_color }}
        />
      )}
    </div>
  );
};

export default OptionList;
