import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { EventSites, PreEventFormData } from '../../../../../../types/entities/events';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputMultiple from '../../../../../ui/formComponents/inputMultiple/InputMultiple';
import InputNumber from '../../../../../ui/formComponents/inputNumber/InputNumber';
import SlideToggle from '../../../../../ui/formComponents/slideToggle/SlideToggle';

type Props = {
  setFormData: Dispatch<SetStateAction<PreEventFormData>>;
  formData: EventSites[];
};
const Site = ({ setFormData, formData }: Props) => {
  const { t } = useTranslation();

  const site = formData.length > 0 ? formData[0] : { area: 0, hours: 0, renewable_energy: false };

  const onChangeArea = (value: string) => {
    const sites = [...formData];

    if (sites.length > 0) {
      sites[0].area = value;
      setFormData((prev) => ({
        ...prev,
        sites
      }));
      return;
    }
    sites.push({ area: value, hours: '', renewable_energy: false });
    setFormData((prev) => ({
      ...prev,
      sites
    }));
  };

  const onChangeHours = (value: string) => {
    const sites = [...formData];

    if (sites.length > 0) {
      sites[0].hours = value;
      setFormData((prev) => ({
        ...prev,
        sites
      }));
      return;
    }
    sites.push({ hours: value, area: '', renewable_energy: false });
    setFormData((prev) => ({
      ...prev,
      sites
    }));
  };

  const onChangeRenewable = (value: boolean) => {
    const sites = [...formData];

    if (sites.length > 0) {
      sites[0].renewable_energy = value;
      setFormData((prev) => ({
        ...prev,
        sites
      }));
      return;
    }
    sites.push({ area: '', hours: '', renewable_energy: value });
    setFormData((prev) => ({
      ...prev,
      sites
    }));
  };

  return (
    <div className='pre-event-site'>
      <FormWrapper>
        <FormElementFull>
          <InputMultiple
            inputs={[
              {
                label: t('eventData.siteSurface'),
                tooltip: t('eventData.siteSurfaceTooltip'),
                input: (
                  <InputNumber
                    icon={'/images/icons/ruler.svg'}
                    placeholder={'0'}
                    value={site.area ? site.area.toString() : ''}
                    onChangeValue={onChangeArea}
                  />
                )
              },
              {
                label: t('eventData.durationHours'),
                input: (
                  <InputNumber
                    icon=''
                    placeholder='0'
                    value={site.hours ? site.hours.toString() : ''}
                    onChangeValue={onChangeHours}
                  />
                )
              }
            ]}
          />
        </FormElementFull>
        <SlideToggle
          label={t('eventData.hasRenewableEnergy')}
          checked={site.renewable_energy || false}
          setChecked={onChangeRenewable}
        />
      </FormWrapper>
    </div>
  );
};

export default Site;
