import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createLCANode } from '../../../../services/api/lca/lcaScenarios';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import type { LCABlockBack } from '../../types/LCABlock';

type Params = {
  acv_id: string;
};

export const useCreateNode = ({ acv_id }: Params) => {
  const queryClient = useQueryClient();
  const selectedOrg = useSelectedOrganization();

  const { mutateAsync } = useMutation({
    mutationFn: createLCANode,
    onSuccess: (data) => {
      queryClient.setQueryData(['lcaBlocks', selectedOrg?.id, acv_id], (prev: LCABlockBack[]) => [
        ...prev,
        data
      ]);

      queryClient.invalidateQueries({ queryKey: ['lcaBlocks', selectedOrg?.id, acv_id] });
    }
  });

  return {
    createNode: mutateAsync
  };
};
