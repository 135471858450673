import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../../../constants/routes';
import BlueWrapper from '../../../../../../../communicate/components/blueWrapper/BlueWrapper';
import './styles.scss';

const DatadisInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToDatadisPage = () => navigate(ROUTES.MEASURE_DATADIS);

  return (
    <BlueWrapper onClick={handleNavigateToDatadisPage} className='datadis-information'>
      <img src='/images/icons/diamond2.svg' alt='datadis information' />
      {t('facilities.datadisInformation')}
    </BlueWrapper>
  );
};

export default DatadisInformation;
