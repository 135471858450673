import { AxiosResponse } from 'axios';
import { OrganizationRecommendation } from '../../../../types/entities/organization';
import apiFetch from '../../../../utils/apiFetch';
import { MITECO_STATUS_KEY } from '../constants';

export const getMITECOStatus = async (orgId?: string) => {
  if (!orgId) {
    return null;
  }

  const response: AxiosResponse<OrganizationRecommendation[]> = await apiFetch(
    'GET',
    `/organization_recommendations/organization/${orgId}`
  );

  const MITECOStatus = response.data.find((item) => item.name === MITECO_STATUS_KEY);

  return MITECOStatus ?? null;
};
