import { useEffect, useState } from 'react';
import LoaderStandard from '../loaders/loaderStandard/LoaderStandard';

type Props = {
  file: File | string;
};
const DocumentPDFV2 = ({ file }: Props) => {
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        setLoading(true);
        const response = await fetch(file as string);
        const blob = await response.blob();
        // convert blob to base64
        const reader = new FileReader();
        reader.onload = (e) => {
          const buffer = e.target?.result as ArrayBuffer;
          const fileBlob = new Blob([new Uint8Array(buffer)], { type: 'application/pdf' });

          setFileUrl(URL.createObjectURL(fileBlob));
          setLoading(false);
        };
        reader.readAsArrayBuffer(blob);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error fetching pdf', error);
      }
    };

    if (typeof file !== 'string') {
      setLoading(true);
      const blob = new Blob([file], { type: 'application/pdf' });
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffer = e.target?.result as ArrayBuffer;
        const fileBlob = new Blob([new Uint8Array(buffer)], { type: 'application/pdf' });

        setFileUrl(URL.createObjectURL(fileBlob));
        setLoading(false);
      };
      reader.readAsArrayBuffer(blob);
      return;
    }
    fetchPdf();

    // Cleanup
    return () => {
      setFileUrl('');
    };
  }, [file]);

  //   if (!fileUrl) return null;

  if (loading)
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <LoaderStandard />
      </div>
    );
  return (
    <iframe
      src={fileUrl}
      width='100%'
      height={'100%'}
      title='pdf'
      style={{ border: '1px solid', borderRadius: '0.5rem' }}
      loading='lazy'
    />
  );
};

export default DocumentPDFV2;
