import { ReactNode } from 'react';
import './styles.scss';

type Props = {
  children: ReactNode;
};

const EmptyModal = ({ children }: Props) => {
  return (
    <div className='empty-data-modal-wrapper'>
      <div className='empty-data-modal-text-wrapper subtitle3-font '>{children}</div>
    </div>
  );
};

export default EmptyModal;
