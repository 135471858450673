/* eslint-disable prettier/prettier */
import {
  SET_INTERACTIVE_TUTORIAL_AS_NOT_READY,
  SET_INTERACTIVE_TUTORIAL_AS_READY
} from '../actions/types';

type InteractiveTutorialType = {
  state: boolean;
};
const initialState: InteractiveTutorialType = {
  state: false
};

type TutorialActions = {
  type: string;
  payload: InteractiveTutorialType;
};
export default function alert(
  state: InteractiveTutorialType = initialState,
  action: TutorialActions
) {
  const { type } = action;

  switch (type) {
    case SET_INTERACTIVE_TUTORIAL_AS_READY:
      return { state: true };
    case SET_INTERACTIVE_TUTORIAL_AS_NOT_READY:
      return { state: false };
    default:
      return state;
  }
}
