import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../../actions/notification';
import useAdaptCriteria from '../../../../../../../adapters/sbtiCriteria';
import useErrors from '../../../../../../../customHooks/useErrors';
import { updateOrCreateCriteria } from '../../../../../../../services/api/sbti';
import useCriteriaContext from '../../hooks/useCriteriaContext';

const useCreateCriteria = () => {
  const { t } = useTranslation();
  const { criteriaContext } = useCriteriaContext();
  const { adaptCriteriaFront2Back } = useAdaptCriteria();
  const dispatch = useDispatch();

  const ERRORS = useErrors();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const patchCriteria = async (formData: ISbtiCriteriaFront) => {
    if (loading) return;
    const criteriaBack = adaptCriteriaFront2Back(formData);

    setLoading(true);
    const response = await updateOrCreateCriteria(criteriaBack);
    setLoading(false);

    if (response?.response?.status >= 400 || !response)
      return setError(ERRORS.FORM.SOMETHING_WENT_WRONG);

    criteriaContext.handleNewCriterias(response);
    criteriaContext.handleCloseCriteria();
    dispatch(setNotification(t('notification.modifiedCriteria')));
    return response;
  };

  return { patchCriteria, loading, error };
};

export default useCreateCriteria;
