import { useTranslation } from 'react-i18next';
import { EsgMetricInfo } from '../../../../types/entities/esgMetrics';
import { Column } from '../../../../types/table';
import Label from '../../../ui/label/Label';
import { GapLabel } from '../components/GapLabel';
import { calculateGap, getCurrentYear, getPreviousYear } from '../utils/utils';

export const useColumns = (selectedCategory?: string, modeForVisualization?: string) => {
  const { t } = useTranslation();

  const columnsImpacts: Column[] = [
    {
      title: t(`dashboard.${modeForVisualization}`),
      dataIndex: 'entity',
      key: 'entity'
    },

    {
      title: t('dashboard.previousYear', { year: getPreviousYear() }),
      dataIndex: `valuePreviousYear`,
      key: 'lastYear',
      align: 'right'
    },
    {
      title: t('dashboard.currentYear', { year: getCurrentYear() }),
      dataIndex: `valueCurrentYear`,
      key: 'currentYear',
      align: 'right'
    }
  ];

  const columnsMetrics = [
    {
      title: t(`dashboard.metric`),
      dataIndex: 'translatedMetric',
      key: 'metric',
      width: '45%',
      render: (_: unknown, data: EsgMetricInfo) =>
        data.codeType ? (
          <>
            {data.codeType.toUpperCase()} - {data.code} {data.translatedMetric}
          </>
        ) : (
          data.translatedMetric
        )
    },

    {
      title: t('dashboard.type'),
      dataIndex: `type`,
      key: 'type',
      render: (value: string) => (
        <Label lookAndFeel='primary'>{t(`esgMetricsDetails.type.${value}`)}</Label>
      )
    },
    {
      title: t('dashboard.previousYear', { year: getPreviousYear() }),
      dataIndex: `valuePreviousYear`,
      key: 'lastYear_value'
      // render: (value: number) => (value ? value : '-')
    },
    {
      title: t('dashboard.currentYear', { year: getCurrentYear() }),
      dataIndex: `valueCurrentYear`,
      key: 'currentYear_value'
      // render: (value: number) => (value
    },
    {
      title: t('dashboard.unit'),
      dataIndex: `unit`,
      key: 'unit'
    },
    {
      title: t('dashboard.progressEsg'),
      dataIndex: ``,
      key: 'progress',
      render: (_: unknown, data: EsgMetricInfo) => {
        const gap = calculateGap(
          data.valueCurrentYearObject.value,
          data.valuePreviousYearObject.value
        );
        return (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <GapLabel
              gap={Number(gap.toFixed(2))}
              previousYear={data.valuePreviousYearObject.value}
              currentYear={data.valueCurrentYearObject.value}
              unit={data.unit}
            />
          </div>
        );
      }
    }
  ];

  return { columnsImpacts, columnsMetrics };
};
