import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import CompanyInvestments from './companyDetailGroupFund/CompanyInvestments';

const CompanyDetail = () => {
  const selectedOrganization = useSelectedOrganization();
  if (!selectedOrganization) return null;
  if (!selectedOrganization.is_group_fund) return null;

  // Wrapper just in case we want to show the Company Detail of a regular child company, not necessarily only for the ones of a group fund
  return <CompanyInvestments />;
};

export default CompanyDetail;
