import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToDecimalNonZero } from '../../../../../utils/numberToDecimal';
import Card from '../../../../ui/cards/card/Card';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';

type Props = {
  onClick: () => void;
  children: ReactNode;
};
// Principal Card Component
const CardEsg = ({ onClick, children }: Props) => {
  return (
    <Card listView={false} onClick={onClick}>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
};

// Card Title Component
const Title = ({ title }: { title: string }) => {
  return <span className='headline4-font'>{title}</span>;
};

// Card Description Component
const Description = ({ descriptions }: { descriptions: string[] }) => {
  return (
    <div>
      {descriptions.map((description, index) => (
        <div key={index}>
          <span className='subtitle3-font'>{description}</span>
        </div>
      ))}
    </div>
  );
};

// Card Subtitle Component
const Subtitle = ({
  mandatoryDatapoints,
  datapoints
}: {
  mandatoryDatapoints: number;
  datapoints: number;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });

  const mandatoryDatapointsText = `${mandatoryDatapoints} ${t('mandatoryDatapoints')}`;
  const datapointsText = `${datapoints} ${t('datapoints')}`;

  return <Description descriptions={[datapointsText, mandatoryDatapointsText]} />;
};

// Card Completeness Component
const Completeness = ({ completeness }: { completeness: number }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });

  return (
    <span className='subtitle3-font'>
      <span className='highlight-text-color'>{formatNumberToDecimalNonZero(completeness)}%</span>{' '}
      {t('completeness')}
    </span>
  );
};

// Card ESRS Component
const Esrs = ({ esrs }: { esrs: string }) => {
  return <div className='text-tag body3-font tag-bg-text-color tag-space-between'>{esrs}</div>;
};

const CardContentDisabled = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='card-wrapper__content' style={{ opacity: 0.5 }}>
      {children}
    </div>
  );
};

const Tooltip = ({ text, children }: { text: string; children: React.ReactNode }) => {
  return <TooltipWrapper text={text}>{children}</TooltipWrapper>;
};

CardEsg.Title = Title;
CardEsg.Description = Description;
CardEsg.Subtitle = Subtitle;
CardEsg.Completeness = Completeness;
CardEsg.Esrs = Esrs;
CardEsg.ContentDisabled = CardContentDisabled;
CardEsg.Tooltip = Tooltip;

export default CardEsg;
