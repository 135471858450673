import { gray_color } from '../../../../styles/colors';
import './styles.scss';

type Bar = {
  value: number;
  color: string;
};
type Props = {
  total: number;
  values: Bar[];
  remaining: number;
};
function StackedBar({ total, values, remaining }: Props) {
  return (
    <div className='stacked-bar'>
      {values.map((elem, index) => (
        <div
          className='bar'
          key={index}
          style={{
            width: `calc(${(elem.value * 100) / total}% + 4px)`,
            backgroundColor: elem.color || gray_color,
            zIndex: values.length - index
          }}></div>
      ))}
    </div>
  );
}

export default StackedBar;
