import groupByCategoryJson from './json/groupByCategory.json';
import groupByCategoryHoldingJson from './json/groupByCategoryHolding.json';

const WASTE_WATER_TREATMENTS = ['waste_water_treatments', 'waste_water_treatment_discharge'];

export const filterTreatmentCategory = (groupedByCategory) => {
  return groupedByCategory.filter((group) => !WASTE_WATER_TREATMENTS.includes(group.category));
};

export const groupByCategory = (featureFlags) => {
  const data = groupByCategoryJson.map((element) => ({
    ...element,
    total_emissions: 0
  }));

  return data.filter((element) => {
    if (!featureFlags || !Object.keys(featureFlags).includes(element.category)) return true;
    return featureFlags[element.category];
  });
};

export const groupByCategoryHolding = (featureFlags) => {
  const data = groupByCategoryHoldingJson.map((element) => ({
    ...element,
    total_emissions: 0
  }));

  return data.filter((element) => {
    if (!featureFlags || !Object.keys(featureFlags).includes(element.category)) return true;
    return featureFlags[element.category];
  });
};
