import { IOrganizationGoalBack } from '../../../../../types/entities/organizationGoal';
import { substractPercentage } from '../tableComponent/utils';

export const sumScopesYearly = (scopes: {
  scope1: TotalImpactYearly[];
  scope2: TotalImpactYearly[];
  scope3: TotalImpactYearly[];
}) => {
  const scope1Years = scopes.scope1.map((element) => element.year);
  const scope2Years = scopes.scope2.map((element) => element.year);
  const scope3Years = scopes.scope3.map((element) => element.year);

  const years = Array.from(new Set([...scope1Years, ...scope2Years, ...scope3Years]));

  const newScope1: { [key: string]: number } = {};

  years.forEach((year) => {
    const scopeYear = scopes.scope1.filter((elem) => elem.year === year);
    newScope1[year] = scopeYear.reduce((acc, curr) => acc + curr.emissions, 0);
  });

  const newScope2: { [key: string]: number } = {};

  years.forEach((year) => {
    const scopeYear = scopes.scope2.filter((elem) => elem.year === year);
    newScope2[year] = scopeYear.reduce((acc, curr) => acc + curr.emissions, 0);
  });

  const newScope3: { [key: string]: number } = {};

  years.forEach((year) => {
    const scopeYear = scopes.scope3.filter((elem) => elem.year === year);
    newScope3[year] = scopeYear.reduce((acc, curr) => acc + curr.emissions, 0);
  });

  return { scope1: newScope1, scope2: newScope2, scope3: newScope3 };
};

type Calculation = {
  baseYear: number;
  currentYear: number;
  goalYear: number;
  baseScope1: number;
  baseScope2: number;
  baseScope3: number;
  reductionPercentageScope1: number;
  reductionPercentageScope2: number;
  reductionPercentageScope3: number;
};

export const calculateMiddleYears = ({
  baseYear,
  currentYear,
  goalYear,
  baseScope1,
  baseScope2,
  baseScope3,
  reductionPercentageScope1,
  reductionPercentageScope2,
  reductionPercentageScope3
}: Calculation) => {
  const yearsToCalculate = Array.from({ length: goalYear - baseYear }, (_, i) => i + baseYear + 1);

  const reductionPercentageScope1Reduced = reductionPercentageScope1 / yearsToCalculate.length;
  const reductionPercentageScope2Reduced = reductionPercentageScope2 / yearsToCalculate.length;
  const reductionPercentageScope3Reduced = reductionPercentageScope3 / yearsToCalculate.length;

  const groupByDate: {
    scope1: { [key: string]: number };
    scope2: { [key: string]: number };
    scope3: { [key: string]: number };
  } = {
    scope1: {},
    scope2: {},
    scope3: {}
  };

  yearsToCalculate.forEach((year, index) => {
    groupByDate.scope1[year] =
      year < currentYear
        ? 0
        : substractPercentage(baseScope1, reductionPercentageScope1Reduced * (index + 1));

    groupByDate.scope2[year] =
      year < currentYear
        ? 0
        : substractPercentage(baseScope2, reductionPercentageScope2Reduced * (index + 1));

    groupByDate.scope3[year] =
      year < currentYear
        ? 0
        : substractPercentage(baseScope3, reductionPercentageScope3Reduced * (index + 1));
  });

  return groupByDate;
};
export const calculateLine1 = (
  data: {
    scope1: {
      [key: string]: number;
    };
    scope2: {
      [key: string]: number;
    };
    scope3: {
      [key: string]: number;
    };
  },
  goals: IOrganizationGoalBack | null,
  baseYear: number,
  goalYear: number
) => {
  if (!goals) return [];
  const yearsToCalculate = Array.from({ length: goalYear - baseYear + 1 }, (_, i) => i + baseYear);

  const totalArray: (number | undefined)[] = [];

  const totalCo2 =
    (data.scope1[baseYear] || 0) + (data.scope2[baseYear] || 0) + (data.scope3[baseYear] || 0);

  const totalCo2Final =
    substractPercentage(data.scope1[baseYear], Number(goals.scope1_goal)) +
    substractPercentage(data.scope2[baseYear], Number(goals.scope2_goal)) +
    substractPercentage(data.scope3[baseYear], Number(goals.scope3_goal));

  const difference = (totalCo2 - totalCo2Final) / (yearsToCalculate.length - 1);

  yearsToCalculate.forEach((_, i) => totalArray.push(totalCo2 - difference * i));

  return totalArray;
};
