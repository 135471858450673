import { useTranslation } from 'react-i18next';
import FormNumber from '../../../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import { ResponseMetric } from '../NarrativeModal';

type Props = {
  name: string;
  response: ResponseMetric;
  setResponse: (response: ResponseMetric) => void;
};
const GovernanceFactory = ({ name, response, setResponse }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: `controlPanel.metrics.narrativeModal.metrics.governance.${name}`
  });

  if (
    name === 'numeric_org_all_sectors_convictions_violations_laws_anti_corruption' ||
    name === 'numeric_org_all_sectors_convictions_violations_laws_anti_bribery'
  ) {
    return (
      <FormNumber
        iconV2='input_numeric'
        label={t('label')}
        onChange={(value: string) => {
          setResponse(value);
        }}
        value={(response as string) ?? ''}
        tooltip={t('tooltip')}
      />
    );
  }
  return <></>;
};

export default GovernanceFactory;
