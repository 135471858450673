function changeOpacity(rgb: string, opacity: number): string {
  // Check if the RGB format is valid (e.g., "rgb(255, 0, 0)")
  const rgbMatch = rgb.match(/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/);

  if (!rgbMatch) {
    throw new Error("Invalid RGB format. Please use 'rgb(r, g, b)'.");
  }

  const [_, r, g, b] = rgbMatch;

  // Ensure opacity is between 0 and 1
  const alpha = Math.max(0, Math.min(1, opacity));

  // Return the RGBA color with the desired opacity
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default changeOpacity;
