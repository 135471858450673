import { ReactNode } from 'react';
import Tooltip from '../../tooltip/Tooltip';
import './styles.scss';

type Props = {
  label?: string | ReactNode;
  label2?: string | ReactNode;
  label3?: string | ReactNode;
  tooltip?: string;
};

const InputLabel = ({ label, label2, label3, tooltip }: Props) => {
  if (!label && !tooltip) return null;

  return (
    <div className='tooltip-wrapper input-label-wrapper'>
      {label && (
        <label className='input-label-font on-light-text-color label-wrapper'>
          <span>{label}</span>
          {label2 && (
            <div className='second-label'>
              <span>{label2}</span>
              {tooltip && <Tooltip text={tooltip} position='top' />}
            </div>
          )}
          {label3 && (
            <div className='third-label'>
              <span>{label3}</span>
              {/* {tooltip && <Tooltip text={tooltip} position='top' />} */}
            </div>
          )}

          {tooltip && !label2 && (
            <div style={{ position: 'relative' }}>
              <Tooltip text={tooltip} position='right' />
            </div>
          )}
        </label>
      )}
    </div>
  );
};

export default InputLabel;
