import React, { useEffect, useState } from 'react';
import { getUnits } from '../../../../../services/api/unit';

const useGetData = () => {
  const [loadingUnits, setLoadingUnits] = useState<boolean>(false);
  const [units, setUnits] = useState<SelectOptionFormat[]>([]);
  const fetchData = async () => {
    try {
      setLoadingUnits(true);
      const response = await getUnits();
      if (!response) return;
      const unitsFiltered = response.filter(
        (elem: { name: string }) =>
          elem.name === 'kilogram_(kg)' || elem.name === 'metric_tonne_(t)'
      );
      setUnits(unitsFiltered);
    } finally {
      setLoadingUnits(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { units, loadingUnits };
};

export default useGetData;
