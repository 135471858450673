import formatNumber from '../../../../utils/formatNumber';
import CommonStatusLabel from '../../../ui/statusLabels/commonStatusLabel/CommonStatusLabels';

type Props = {
  gap: number;
  previousYear: number;
  currentYear: number;
  unit?: string;
};

export const GapLabel = ({ gap, previousYear, currentYear, unit }: Props) => {
  const renderColor = (gap: number) => {
    if (gap === 0) return 'disabled';
    if (currentYear > previousYear) return 'error';

    return 'success';
  };

  const renderSign = (gap: number) => {
    if (gap === 0) return ' ';
    if (gap > 0) return '- ';

    return '+ ';
  };

  return (
    <CommonStatusLabel
      iconColor={renderColor(gap)}
      status={renderColor(gap)}
      icon={Number(gap) <= 0 ? 'grow_up' : 'grow_up_2'}>
      <div style={{ maxWidth: '7vw' }}>
        <span>
          {renderSign(gap)}
          {formatNumber(Math.abs(gap))}
        </span>
        <span>{unit ? unit : 't CO₂ eq.'}</span>
      </div>
    </CommonStatusLabel>
  );
};
