import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterInfo2 } from '../../../types/entities/user';
import StepText from '../../ui/stepPage/templateSteps/StepText';
type Props = {
  nextStep: (value: RegisterInfo2) => void;
  stepNumber: number;
  registerInfo: RegisterInfo2;
};
const StepVat = ({ nextStep, stepNumber, registerInfo }: Props) => {
  const { t, i18n } = useTranslation();
  const [vat, setVat] = useState('');

  const onChangeVat = (e: ChangeEvent<HTMLInputElement>) => {
    setVat(e.target.value);
  };

  const handleNextStep = () => {
    nextStep({
      ...registerInfo,
      vat
    });
  };

  return (
    <StepText
      nextStep={handleNextStep}
      stepNumber={stepNumber}
      title={t('onboardingUser.stepVat.title')}
      //   tooltip={t('onboardingUser.stepVat.tooltip')}
      buttonText={t('onboardingUser.stepVat.button')}
      placeholder={t('onboardingUser.stepVat.placeholder')}
      onChangeValue={onChangeVat}
      value={vat}
      icon={'/images/icons/idcard.svg'}
    />
  );
};

export default StepVat;
