import { useTranslation } from 'react-i18next';

import {
  IFrontendFormBusinessTravel,
  IGetBackendBusinessTravel,
  IPostBackendBusinessTravel
} from '../types/entities/businessTravels';
import { CarSize, TransportOptions } from '../types/entitiesEnums/employeePeriod';
import { convertDateToString, convertStringToDate } from '../utils/convertDates';

export const adaptBusinessTravelBackToFront = (
  value: IGetBackendBusinessTravel
): IFrontendFormBusinessTravel => {
  const { t } = useTranslation();
  let transportType = value.transport_type;
  if (value.transport_type === TransportOptions.CAR && value.vehicle_size === CarSize.SMALL) {
    transportType = TransportOptions.CAR_SMALL;
  } else if (
    value.transport_type === TransportOptions.CAR &&
    value.vehicle_size === CarSize.MEDIUM
  ) {
    transportType = TransportOptions.CAR_MEDIUM;
  } else if (
    value.transport_type === TransportOptions.CAR &&
    value.vehicle_size === CarSize.LARGE
  ) {
    transportType = TransportOptions.CAR_LARGE;
  }

  return {
    transport: {
      id: value.transport_type || '',
      name: t(`businessTravels.${transportType}`)
    },
    size: value.size || value.vehicle_size || '',
    fuelType: {
      id: value.fuel_type || '',
      name: t(`employees.${value.fuel_type}`)
    },
    name: value.name || '',
    travelNumber: value.travel_number ? String(value.travel_number) : '1',
    origin: value.origin || '',
    destination: value.destination || '',
    startDate: value.start_date ? convertDateToString(new Date(value.start_date)) : '',
    endDate: value.end_date ? convertDateToString(new Date(value.end_date)) : '',
    travelType: value.travel_type || '',
    totalKm: value.distance_km || '',
    errors: []
  };
};

export const adaptBusinessTravelFrontToBack = (
  value: IFrontendFormBusinessTravel
): IPostBackendBusinessTravel => {
  let renewableEnergy = '';
  const fuel = { ...value.fuelType };
  if (value.fuelType.id === 'electric_renewable') {
    renewableEnergy = 'yes';
    fuel.id = 'electric';
  } else if (value.fuelType.id === 'electric') renewableEnergy = 'no';

  return {
    transport_type: value.transport.id,
    vehicle_size: value.size,
    fuel_type: value.fuelType.id,
    renewable_energy: renewableEnergy || null,
    name: value.name,
    origin: value.origin || null,
    destination: value.destination || null,
    travel_type: value.travelType,
    start_date: convertStringToDate(value.startDate),
    end_date: convertStringToDate(value.endDate),
    travel_number: +value.travelNumber,
    status: 'active',
    distance_km: value.totalKm ? Number(value.totalKm) : null
  };
};
