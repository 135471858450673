import { ReactNode } from 'react';

export const getCurrentStep = (
  ids: string[],
  steps: { step: ReactNode; highlighted: ReactNode }[],
  current: string | null
) => {
  if (ids.length !== steps.length) throw new Error('Ids and steps have to have same length!');
  if (!current) return;
  const currentIndex = ids.indexOf(current);
  if (currentIndex < 0) return;
  return steps[currentIndex];
};
