import { TFunction } from 'i18next';
import { buildName } from '../components/dashboard/dashboardEsg/utils/utils';
import { EsgMetricBackend, EsgMetricFrontend } from '../types/entities/esgMetrics';

export const adaptEsgMetricsBackToFront = (
  values: EsgMetricBackend[],
  t: TFunction
): { parsedData: EsgMetricFrontend; metrics: SelectOptionFormat[] } => {
  const parsedData: EsgMetricFrontend = {
    environmental: values
      .filter((value) => value.type !== 'social_governance')
      .map((value) => ({
        metric: value.metric,
        translatedMetric:
          value.type === 'wastes'
            ? t(`low_codes.${value.metric}`)
            : t(`esgMetricsDetails.${value.metric}`),
        type: value.type,
        unit: value.unit,
        dateRanges: value.values.map((range) => ({
          startDate: range.start_date,
          endDate: range.end_date,
          value: range.value ? range.value : 0,
          errorMessage: range.error_message ? range.error_message : null
        })),
        valuePreviousYear: value.values[0]?.value ? value.values[0].value : 0,
        valueCurrentYear: value.values[1]?.value ? value.values[1].value : 0,

        valuePreviousYearObject: { value: value.values[0]?.value ? value.values[0].value : 0 },
        valueCurrentYearObject: { value: value.values[1]?.value ? value.values[1].value : 0 },
        errorMessage: value.error_message,
        code: value.code ?? null,
        codeType: value.code_type ?? null
      })),
    socialGovernance: values
      .filter((value) => value.type === 'social_governance')
      .map((value) => ({
        metric: value.metric,

        translatedMetric: t(`esgMetricsDetails.${value.metric}`),
        type: value.type,
        unit: value.unit === 'number' ? 'Nº' : value.unit,
        dateRanges: value.values.map((range) => ({
          startDate: range.start_date,
          endDate: range.end_date,
          value: range.value ? range.value : 0,
          errorMessage: range.error_message ? range.error_message : null
        })),
        valuePreviousYear: value.values[0]?.value ? value.values[0].value : 0,
        valueCurrentYear: value.values[1]?.value ? value.values[1].value : 0,
        valuePreviousYearObject: { value: value.values[0]?.value ? value.values[0].value : 0 },
        valueCurrentYearObject: { value: value.values[1]?.value ? value.values[1].value : 0 },
        errorMessage: value.error_message,
        code: value.code ?? null,
        codeType: value.code_type ?? null
      }))
  };

  const metrics = values
    .filter((elem) => elem.type !== 'social_governance')
    .map((value) => ({
      id: value.metric,
      name: buildName(value, t)
    }));

  return { parsedData, metrics };
};
