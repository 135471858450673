import { useEffect, useState } from 'react';
import useAdapterTocsBack2Front from '../adapters/tocs';
import { getTocs } from '../services/api/logistics';
import { ITOCBackend, ITOCFrontend } from '../types/entities/logistics';

function useTocs() {
  const { adaptTocBack2Front } = useAdapterTocsBack2Front();
  const [tocs, setTocs] = useState<ITOCFrontend[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getTocsFunc = async () => {
    setLoading(true);
    const { response, error } = await getTocs();
    setLoading(false);

    if (error || !response?.data) return;

    const adaptedTocs: ITOCFrontend[] = response.data?.map((toc: ITOCBackend) =>
      adaptTocBack2Front(toc)
    );

    // console.log(adaptedTocs);

    const filteredTocs: ITOCFrontend[] = [];
    adaptedTocs.forEach((toc) => {
      delete toc.id;
      if (
        !filteredTocs.find(
          (filteredToc) => filteredToc.name === toc.name && filteredToc.type === toc.type
        )
      ) {
        filteredTocs.push(toc);
      }
    });

    setTocs(filteredTocs.sort(sort));
  };

  useEffect(() => {
    getTocsFunc();
  }, []);

  return { tocs, loading };
}

const sort = (a: ITOCFrontend, b: ITOCFrontend) => {
  const nameA = `${a.name.toLowerCase()}_${a.type.toLowerCase()}`;
  const nameB = `${b.name.toLowerCase()}_${b.type.toLowerCase()}`;

  return nameA.localeCompare(nameB);
};

export default useTocs;
