const pricePerTons = [
  {
    numberOfTons: 1,
    price: 56.3
  },
  {
    numberOfTons: 10,
    price: 51.2
  },
  {
    numberOfTons: 50,
    price: 46.6
  },
  {
    numberOfTons: 100,
    price: 42.3
  },
  {
    numberOfTons: 250,
    price: 38.5
  },
  {
    numberOfTons: 500,
    price: 35
  },
  {
    numberOfTons: 1000,
    price: 30.4
  },
  {
    numberOfTons: 2000,
    price: 26.5
  },
  {
    numberOfTons: 5000,
    price: 23
  }
];

export default function getPricePerTon(tons: number) {
  let result = 56.3;
  pricePerTons.forEach((pricePerTon) => {
    if (tons >= pricePerTon.numberOfTons) {
      result = pricePerTon.price;
    }
  });

  return result;
}
