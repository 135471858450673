import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiFetch from '../../../../utils/apiFetch';
import Button from '../../../ui/button/Button';
import './styles.scss';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';

type Props = {
  deletePurchase: (id: string) => void;
  purchaseToDelete: string;
};
function DeletePurchase({ purchaseToDelete, deletePurchase }: Props) {
  const { t } = useTranslation();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeletePurchase();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeletePurchase = async () => {
    setLoadingButton(true);
    await apiFetch('delete', `/purchases/${purchaseToDelete}`);
    deletePurchase(purchaseToDelete);
    setLoadingButton(false);
  };

  return (
    <div className='delete-modal'>
      <FormHeader
        title={t('purchases.deletePurchase')}
        description={t('purchases.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('purchases.delete')}
          onClick={handleDeletePurchase}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
}

export default DeletePurchase;
