import { HTMLAttributes, ReactNode } from 'react';
import './styles.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title: ReactNode;
  description?: ReactNode;
}

const FormHeader = ({ title, description, ...rest }: Props) => {
  return (
    <div className='form-header' {...rest}>
      <h1 className='font-headings-h3-sb'>{title}</h1>
      {description ? <h2 className='font-14 weight-400'>{description}</h2> : null}
    </div>
  );
};

export default FormHeader;
