import InputGoogleMaps from '../../inputGoogleMaps/InputGoogleMaps';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children'>;
type InputGoogleMapsType = React.ComponentProps<typeof InputGoogleMaps>;

type Props = InputGoogleMapsType & InputWrapperType;

const FormGoogleMaps = (props: Props) => {
  return (
    <InputWrapper {...props}>
      <InputGoogleMaps {...props} />
    </InputWrapper>
  );
};

export default FormGoogleMaps;
