import { useTranslation } from 'react-i18next';
import { createRandomDataModeling } from '../../../../../mocks/dashboardChart';
import { IOrganizationGoalBack } from '../../../../../types/entities/organizationGoal';
import { convertStringToDateBackend } from '../../../../../utils/convertDates';
import Dot from '../../../../ui/dot/Dot';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import { groupDataByScope, substractPercentage } from '../tableComponent/utils';
import MockedChart from './MockedChart';
import { StackedBarLineChart } from './StackedBarLineChart';
import './styles.scss';
import { calculateLine1, calculateMiddleYears, sumScopesYearly } from './utils';

type Props = {
  loading: boolean;
  organizationGoal: IOrganizationGoalBack | null;
  totalImpactByCategoryYearly: TotalImpactYearly[] | null;
  organizationModeling: IOrganizationModelingBack[] | null;
};

function filterYearsGreaterThanN(
  data: { [year: number]: number },
  n: number
): { [year: number]: number } {
  const filteredData: { [year: number]: number } = {};

  for (const year in data) {
    if (Object.prototype.hasOwnProperty.call(data, year) && Number(year) >= n) {
      filteredData[Number(year)] = data[Number(year)];
    }
  }

  return filteredData;
}
function sortObj(obj: any) {
  return Object.keys(obj)
    .sort()
    .reduce(function (result: any, key) {
      result[key] = obj[key];
      return result;
    }, {});
}

const removeDuplicates = (
  calculated: { [key: string]: number },
  real: {
    [key: string]: number;
  }
) => {
  if (!calculated) return calculated;
  const dates = Object.keys(real);
  const calculatedClone = structuredClone(calculated);
  dates.forEach((date) => {
    delete calculatedClone[date];
  });

  return calculatedClone;
};

const Progress = ({
  loading,
  organizationGoal,
  totalImpactByCategoryYearly,
  organizationModeling
}: Props) => {
  const { t } = useTranslation();

  const disabled = !organizationGoal;

  const totalImpactsReduced = totalImpactByCategoryYearly?.map((totalImpact) => {
    const model = organizationModeling?.find(
      (element) => element.category_name === totalImpact.category
    );
    const totalImpactCopy = structuredClone(totalImpact);
    if (!model) return totalImpactCopy;
    totalImpactCopy.emissions = substractPercentage(
      totalImpactCopy.emissions,
      Number(model.reduction_percentage)
    );
    return totalImpactCopy;
  });

  const groupedByScope = groupDataByScope(totalImpactByCategoryYearly || []);
  const groupedByScopeReduced = groupDataByScope(totalImpactsReduced || []);

  const groupedByDate = sumScopesYearly(groupedByScope);
  const groupedByDateReduced = sumScopesYearly(groupedByScopeReduced);

  const currentYear = new Date().getFullYear();
  const baseYear = organizationGoal?.goal_year_date
    ? convertStringToDateBackend(organizationGoal.base_year_date).getFullYear()
    : currentYear;
  const goalYear = organizationGoal?.goal_year_date
    ? convertStringToDateBackend(organizationGoal.goal_year_date).getFullYear()
    : currentYear;

  const baseScope1 = groupedByDate.scope1[baseYear];
  const baseScope2 = groupedByDate.scope2[baseYear];
  const baseScope3 = groupedByDate.scope3[baseYear];

  const baseScope1Reduced = groupedByDateReduced.scope1[baseYear];
  const baseScope2Reduced = groupedByDateReduced.scope2[baseYear];
  const baseScope3Reduced = groupedByDateReduced.scope3[baseYear];

  const percentage1 = ((baseScope1 - baseScope1Reduced) * 100) / baseScope1;
  const percentage2 = ((baseScope2 - baseScope2Reduced) * 100) / baseScope2;
  const percentage3 = ((baseScope3 - baseScope3Reduced) * 100) / baseScope3;

  const groupedByDateCalculated = calculateMiddleYears({
    baseYear,
    currentYear,
    goalYear,
    baseScope1,
    baseScope2,
    baseScope3,
    reductionPercentageScope1: percentage1,
    reductionPercentageScope2: percentage2,
    reductionPercentageScope3: percentage3
  });

  const groupedByDateCalculatedRemovedData = {
    scope1: removeDuplicates(groupedByDateCalculated.scope1, groupedByDate.scope1),
    scope2: removeDuplicates(groupedByDateCalculated.scope2, groupedByDate.scope2),
    scope3: removeDuplicates(groupedByDateCalculated.scope3, groupedByDate.scope3)
  };

  const compleateData = (data: { [key: string]: number }) => {
    const dataReturned = structuredClone(data);
    const keys = Object.keys(dataReturned);

    const startDate = keys[0];
    const endDate = keys[keys.length - 1];

    const dates = [];

    for (let i = Number(startDate); i < Number(endDate); i++) {
      dates.push(i);
    }

    dates.forEach((date) => {
      if (dataReturned[date]) return;
      dataReturned[date] = 0;
    });

    return dataReturned;
  };

  const scope1Values: (number | undefined)[] = Object.values(compleateData(groupedByDate.scope1));
  const scope2Values: (number | undefined)[] = Object.values(compleateData(groupedByDate.scope2));
  const scope3Values: (number | undefined)[] = Object.values(compleateData(groupedByDate.scope3));

  let scope1ValuesCalculated: (number | undefined)[] = [];
  let scope2ValuesCalculated: (number | undefined)[] = [];
  let scope3ValuesCalculated: (number | undefined)[] = [];

  for (let i = 0; i < scope1Values.length; i++) {
    scope1ValuesCalculated.push(undefined);
    scope2ValuesCalculated.push(undefined);
    scope3ValuesCalculated.push(undefined);
  }

  const scope1Filtered = filterYearsGreaterThanN(
    groupedByDateCalculatedRemovedData.scope1,
    currentYear
  );

  const scope2Filtered = filterYearsGreaterThanN(
    groupedByDateCalculatedRemovedData.scope2,
    currentYear
  );

  const scope3Filtered = filterYearsGreaterThanN(
    groupedByDateCalculatedRemovedData.scope3,
    currentYear
  );

  scope1ValuesCalculated = scope1ValuesCalculated
    .concat(Object.values(scope1Filtered))
    .map((value) => (value !== undefined && value < 0 ? 0 : value));
  scope2ValuesCalculated = scope2ValuesCalculated
    .concat(Object.values(scope2Filtered))
    .map((value) => (value !== undefined && value < 0 ? 0 : value));
  scope3ValuesCalculated = scope3ValuesCalculated
    .concat(Object.values(scope3Filtered))
    .map((value) => (value !== undefined && value < 0 ? 0 : value));

  const finalData = {
    scope1: { ...groupedByDate.scope1, ...groupedByDateCalculatedRemovedData.scope1 },
    scope2: { ...groupedByDate.scope2, ...groupedByDateCalculatedRemovedData.scope2 },
    scope3: { ...groupedByDate.scope3, ...groupedByDateCalculatedRemovedData.scope3 }
  };
  const finalSortedData = sortObj(finalData);

  const line1 = calculateLine1(groupedByDate, organizationGoal, baseYear, goalYear);

  if (loading) return <LoaderCard className='item-chart' />;
  return (
    <div className='card item-chart'>
      <div className='progress-wrapper'>
        <h2 className='headline4-font objective-title'>{t('modeling.progress.title')}</h2>
        <div className='progress-legend-section'>
          <div className='on-card-gray-bg-color progress-legend'>
            <Dot type={'scope1'} />
            <span className='body1-bold-font'>{t('dashboard.scope1')}</span>
          </div>
          <div className='on-card-gray-bg-color progress-legend'>
            <Dot type={'scope2'} />
            <span className='body1-bold-font'>{t('dashboard.scope2')}</span>
          </div>
          <div className='on-card-gray-bg-color progress-legend'>
            <Dot type={'scope3'} />
            <span className='body1-bold-font'>{t('dashboard.scope3')}</span>
          </div>
          <div className='on-card-gray-bg-color progress-legend'>
            <img src='/images/icons/line.svg' alt='line' style={{ marginRight: '0.3rem' }} />
            <span className='body1-bold-font'>{t('modeling.progress.reductionGoals')}</span>
          </div>
        </div>
        <div style={{ height: '95%', padding: '0 1rem 0 0.5rem' }}>
          {!disabled ? (
            <StackedBarLineChart
              labels={Object.keys(finalSortedData.scope1)}
              datasets={{
                scope1: scope1Values,
                scope2: scope2Values,
                scope3: scope3Values,
                scope1Mocked: scope1ValuesCalculated,
                scope2Mocked: scope2ValuesCalculated,
                scope3Mocked: scope3ValuesCalculated,
                line1
              }}
            />
          ) : (
            <MockedChart {...createRandomDataModeling()} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Progress;
