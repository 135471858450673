import moment from 'moment';
import { useEffect } from 'react';
import { isValidDate } from '../../../../constants/regex';
import { InputSize } from '../../../../types/utilsEnums/input';
import { convertDateToString } from '../../../../utils/convertDates';
import InputCalendar, { ErrorDate } from '../inputCalendar/InputCalendar';
import './styles.scss';
interface Props {
  startDate: string;
  endDate: string;
  handleChangeStartDate: (date: string) => void;
  handleChangeEndDate: (date: string) => void;
  handleStartDateError?: (value: ErrorDate) => void;
  handleEndDateError?: (value: ErrorDate) => void;
  maxStartDate?: string;
  maxEndDate?: string;
  minStartDate?: string;
  minEndDate?: string;
  disabled?: boolean;
  disabledStartDate?: boolean;
  disabledEndDate?: boolean;
  size?: InputSize;
  position?: string;
}

const InputCalendarDouble = ({
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  handleStartDateError,
  handleEndDateError,
  maxStartDate = convertDateToString(new Date(new Date().setDate(new Date().getDate() - 1))), // yesterday
  maxEndDate = convertDateToString(new Date()),
  minStartDate,
  minEndDate,
  disabled,
  disabledStartDate,
  disabledEndDate,
  position,
  size
}: Props) => {
  const handleDateErrorIsBefore = () => {
    if (!isValidDate(startDate) || !isValidDate(endDate)) return;
    const momentStartDate = moment(startDate, 'DD/MM/YYYY');
    const momentEndDate = moment(endDate, 'DD/MM/YYYY');

    return momentEndDate.isBefore(momentStartDate);
  };

  const handleStartDateErrorBefore = () => {
    if (handleDateErrorIsBefore()) handleChangeEndDate('');
  };

  const handleEndDateErrorAfter = () => {
    if (handleDateErrorIsBefore()) handleChangeStartDate('');
  };

  useEffect(() => {
    handleStartDateErrorBefore();
  }, [startDate]);

  useEffect(() => {
    handleEndDateErrorAfter();
  }, [endDate]);

  return (
    <>
      <InputCalendar
        dateValue={startDate}
        handleChangeDate={handleChangeStartDate}
        handleDateError={handleStartDateError}
        disabled={disabled || disabledStartDate}
        maxDate={maxStartDate}
        minDate={minStartDate}
        size={size}
        position={position}
      />
      <div className='divider-line' />
      <InputCalendar
        dateValue={endDate}
        handleChangeDate={handleChangeEndDate}
        handleDateError={handleEndDateError}
        disabled={disabled || disabledEndDate}
        maxDate={maxEndDate}
        minDate={minEndDate}
        size={size}
        position={position}
      />
    </>
  );
};

export default InputCalendarDouble;
