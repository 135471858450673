export const getYears = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);

  const minYear = 2015;
  const maxYear = today.getFullYear();

  const years: SelectOptionFormat[] = [];
  for (let i = minYear; i <= maxYear; i++) {
    years.push({ id: i.toString(), name: i.toString() });
  }

  return years.sort((a, b) => parseInt(b.id) - parseInt(a.id));
};
