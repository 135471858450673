import { IOrganizationGoalBack, IOrganizationGoalFront } from '../types/entities/organizationGoal';
import {
  convertDateFrontToBack,
  convertDateToStringBackend,
  convertStringToDate,
  convertStringToDateBackend
} from '../utils/convertDates';

export const adaptOrganizationGoalFrontToBack = (
  value: IOrganizationGoalFront
): IOrganizationGoalBack => {
  const dateBaseYear = convertStringToDate(`01/01/${value.baseYear.id}`);
  dateBaseYear.setFullYear(Number(value.yearToCompare.id));
  return {
    id: value.id,
    base_year_date: convertDateFrontToBack(`01/01/${value.baseYear.id}`),
    goal_year_date: convertDateToStringBackend(dateBaseYear),
    scope1_goal: Number(value.reductionScope1),
    scope2_goal: Number(value.reductionScope2),
    scope3_goal: Number(value.reductionScope3)
  };
};

export const adaptOrganizationGoalBackToFront = (
  value: IOrganizationGoalBack
): IOrganizationGoalFront => {
  const baseYear = String(convertStringToDateBackend(value.base_year_date).getFullYear());
  const yearToCompare = String(convertStringToDateBackend(value.goal_year_date).getFullYear());

  return {
    id: value.id,
    baseYear: { id: baseYear, name: baseYear },
    yearToCompare: { id: yearToCompare, name: yearToCompare },
    reductionScope1: String(value.scope1_goal),
    reductionScope2: String(value.scope2_goal),
    reductionScope3: String(value.scope3_goal),
    errors: []
  };
};
