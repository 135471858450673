import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { SIZE_PAGINATION_BIG, VIDEOS } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { UserContext } from '../../../context/userContext';
import usePaginatedList from '../../../customHooks/paginatedList';
import useDebounceValue from '../../../customHooks/useDebounceValue';
import { FundShortType, FundType } from '../../../types/entities/funds';
import { InputSize } from '../../../types/utilsEnums/input';
import { limitString } from '../../../utils/limitString';
import { numberToDecimalNonZero } from '../../../utils/numberToDecimal';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import Button from '../../ui/button/Button';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import CardList from '../../ui/cardList/CardListRefactored';
import Card from '../../ui/cards/card/Card';
import CardsFilter from '../../ui/filter/CardsFilter';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import Icon from '../../ui/icon/Icon';
import Modal from '../../ui/modal/Modal';
import CreateFund from './components/createFund/CreateFund';
import DeleteFund from './components/deleteFund/DeleteFund';
import EditFund from './components/editFund/EditFund';
import { FilterOptionType } from '../../layout/Filters/FilterSection';
import useFilters from '../../layout/Filters/hooks/useFilters';
import NewPAIAdded from './components/newPAIAdded/NewPAIAdded';
import { localStorageGetItem, localStorageSetItem } from '../../../utils/localStorage';

const Funds = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const [showCreateFund, setShowCreateFund] = useState(false);
  const [listView, setListView] = useState(false);
  const [showNewPAIAdded, setShowNewPAIAdded] = useState(false);

  const [fundIdToDelete, setFundIdToDelete] = useState('');
  const [fundIdToEdit, setFundIdToEdit] = useState('');

  const [searchValue, setSearchValue] = useState('');

  const debounceValue = useDebounceValue(searchValue);

  const size = SIZE_PAGINATION_BIG;
  const [url, setUrl] = useState('/funds/list_by_organization');
  const [sideFilters, setFilters] = useState<FilterOptionType[]>([]);

  const filters = {};

  const newPAISeen = localStorageGetItem('newPAISeen');

  useEffect(() => {
    if (!newPAISeen) {
      setShowNewPAIAdded(true);
    } else {
      setShowNewPAIAdded(false);
    }
  }, [newPAISeen]);

  useFilters({
    setUrl,
    url,
    setFilters,
    filters: sideFilters,
    removeFilters: () => setFilters([]),
    remove: false,
    searchFilters: [
      {
        key: 'fund_name',
        value: debounceValue,
        setValue: setSearchValue
      }
    ]
  });

  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values,
    setValues: setFunds
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading: () => {
      console.log('not needed');
    },
    organization: user?.selectedOrganization,
    filters
  });

  useEffect(() => {
    if (dateError) return;

    fetchData();
  }, [url, startDate, endDate]);

  const funds: FundShortType[] = values;

  const handleChangeListView = () => {
    setListView(!listView);
  };

  const onClickCard = (id: string, name: string) => {
    navigate(`${ROUTES.MEASURE_FUNDS}/${id}?name=${name}`);
  };

  const onCloseEditFundData = () => {
    setFundIdToEdit('');
  };

  const onCloseDeleteFund = () => setFundIdToDelete('');

  const addFund = (newFund: FundType) => {
    setFunds((prev) => prev.concat(newFund));
    dispatch(setNotification(t('notification.addFund')));
    setShowCreateFund(false);
  };

  const deleteFund = (id: string) => {
    setFunds((prev) => prev.filter((fund) => fund.id !== id));
    setFundIdToDelete('');
    dispatch(setNotification(t('notification.deleteFund')));
  };

  const editFundData = (newFund: FundType) => {
    setFunds((prev) => prev.map((element) => (element.id === newFund.id ? newFund : element)));
    setFundIdToDelete('');
    dispatch(setNotification(t('notification.editFund')));
    onCloseEditFundData();
  };

  if (!foundOrganization) {
    return null;
  }

  return (
    <section className='facilities'>
      <div className='facilities__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('measureMain.title')}</h3>
        <Breadcrumb />
      </div>
      <CardsFilter listView={listView} onChangeListView={handleChangeListView}>
        <>
          <FormText
            icon={'/images/icons/search.svg'}
            placeholder={t('funds.searchPlaceholder')}
            onChange={(e: any) => setSearchValue(e.target.value)}
            value={searchValue}
            size={InputSize.SMALL}
          />
        </>
      </CardsFilter>
      <div id={'funds'} className='infinite-scroll-wrapper-card'>
        <InfiniteScroll
          dataLength={funds ? funds.length : 0}
          next={fetchMoreData}
          hasMore={hasMore}
          style={{ overflow: 'visible' }}
          endMessage={<></>}
          loader={undefined}
          scrollableTarget={'funds'}>
          <CardList
            listView={listView}
            title={t('funds.newFund')}
            description={t('funds.newFundDescription')}
            buttons={
              <Button
                lookAndFeel={'primary'}
                text={t('funds.create')}
                onClick={() => setShowCreateFund(true)}
                size='small'
              />
            }
            loading={false}>
            {funds.map((elem) => {
              const total: number | undefined = elem.co2e
                ? numberToDecimalNonZero(+elem.co2e / 1000, 4)
                : undefined;
              return (
                <Card
                  key={elem.id}
                  listView={listView}
                  onClick={() => onClickCard(elem.id, elem.fund_name)}>
                  <Card.Options>
                    <ButtonDropdown
                      button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                      options={[
                        {
                          id: 'edit',
                          name: t('funds.edit'),
                          onClick: () => setFundIdToEdit(elem.id)
                        },
                        {
                          id: 'delete',
                          name: t('funds.delete'),
                          onClick: () => setFundIdToDelete(elem.id)
                        }
                      ]}></ButtonDropdown>
                  </Card.Options>
                  <Card.Content>
                    <Card.Icon icon={'/images/icons/holding.svg'} alt={elem.fund_name} />
                    <span className='headline4-font'>{limitString(elem.fund_name, 25)}</span>
                    {elem.funds_total_value && (
                      <span className='subtitle3-font'>
                        {limitString(`${elem.funds_total_value} €`, listView ? 200 : 28)}
                      </span>
                    )}
                    {total && <Card.Total total={total} unit={'t CO₂ eq.'} />}
                  </Card.Content>
                </Card>
              );
            })}
          </CardList>
        </InfiniteScroll>
      </div>
      {/* {videoLink ? (
        <>
          <VideoTutorialPopup
            title={t('funds.videoTitle')}
            description={t('funds.discover')}
            image={'/images/pumi.png'}
            onClick={onOpenVideo}
          />
          <Modal.WithPortal show={showVideo} onClose={onCloseVideo} width='700px'>
            <FormHeader title={t('funds.demo')} />
            <YoutubeVideo url={videoLink} />
          </Modal.WithPortal>
        </>
      ) : null} */}

      <Modal.WithPortal
        show={showCreateFund}
        onClose={() => setShowCreateFund(false)}
        width='600px'
        maxWidth='600px'>
        <CreateFund addFund={addFund} />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!fundIdToEdit}
        onClose={onCloseEditFundData}
        width='600px'
        maxWidth='600px'>
        <EditFund id={fundIdToEdit} editFundData={editFundData} />
      </Modal.WithPortal>
      <Modal.WithPortal show={!!fundIdToDelete} onClose={onCloseDeleteFund}>
        <DeleteFund id={fundIdToDelete} deleteFundData={deleteFund} />
      </Modal.WithPortal>
      <Modal.WithPortal
        width='600px'
        maxWidth='600px'
        show={showNewPAIAdded}
        onClose={() => {
          localStorageSetItem('newPAISeen', true);
          setShowNewPAIAdded(false);
        }}>
        <NewPAIAdded
          handleClose={() => {
            localStorageSetItem('newPAISeen', true);
            setShowNewPAIAdded(false);
          }}
        />
      </Modal.WithPortal>
    </section>
  );
};

export default Funds;
