import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconBackground from '../../../ui/iconBackground/IconBackground';
import './styles.scss';

type Props = {
  id: string;
  idSelected: string;
  text: string;
  icon: string;
  iconSelected: string;
  route: string | null;
  isNew: boolean;
  onClickMainSection: (id: string) => void;
};

function MainSection({
  id,
  idSelected,
  text,
  icon,
  iconSelected,
  route,
  isNew,
  onClickMainSection
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickShowSubSections = () => {
    onClickMainSection(id);
    if (route) {
      navigate(route);
    }
  };

  const selected = idSelected === id;
  return (
    <div className='main-section-wrapper'>
      <div className={`main-section`} onClick={onClickShowSubSections}>
        <div className='section-name' id={id}>
          <IconBackground icon={selected ? iconSelected : icon} alt={text} selected={selected} />
          <h5 className='on-light-text-color side-menu-section-font'>{text}</h5>
          {isNew ? (
            <span className='tag-new span-small-font tag2-bg-text-color'>{t('sideMenu.new')}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default MainSection;
