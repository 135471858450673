import { useEffect, useState } from 'react';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { getDataGroupsEntities } from '../../../../../services/api/grouping';
import { getMatrixChilds } from '../../../../../services/api/matrices';

type Props = {
  organizationId: string;
  category: string;
};
const useData = ({ organizationId, category }: Props) => {
  const selectedOrganization = useSelectedOrganization();

  const [organizations, setOrganizations] = useState<SelectOptionFormat[]>([]);
  const [entities, setEntities] = useState<SelectOptionFormat[]>([]);

  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [loadingEntities, setLoadingEntities] = useState(false);
  const fetchChilds = async () => {
    setLoadingOrganizations(true);
    const data = await getMatrixChilds(selectedOrganization?.id ?? '');
    setLoadingOrganizations(false);
    if (!data) return;
    const dataMapped = data.map((elem: { child_id: string; child_name: string }) => ({
      id: elem.child_id,
      name: elem.child_name
    }));
    dataMapped.push({
      id: selectedOrganization?.id ?? '',
      name: selectedOrganization?.company_name ?? ''
    });
    setOrganizations(dataMapped);
  };

  useEffect(() => {
    if (!selectedOrganization) return;
    fetchChilds();
  }, []);

  const fetchData = async () => {
    setLoadingEntities(true);
    const data = await getDataGroupsEntities(organizationId, category);
    setLoadingEntities(false);
    if (!data) return;
    setEntities(
      data.entities.map((elem: { entity_id: string; entity_name: string }) => ({
        id: elem.entity_id,
        name: elem.entity_name
      }))
    );
  };

  useEffect(() => {
    if (!organizationId || !category) return;
    fetchData();
  }, [organizationId, category]);

  return { entities, organizations, loadingOrganizations, loadingEntities };
};

export default useData;
