import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createLCABackbone } from '../../../../../services/api/lca/lcaPortfolio';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';

export const useCreateLCAModel = () => {
  const queryClient = useQueryClient();
  const selectedOrg = useSelectedOrganization();

  const { mutateAsync } = useMutation({
    mutationFn: createLCABackbone,
    onMutate: () => {
      // Do something before the mutation
    },
    onError: () => {
      // Do something when the mutation fails
    },
    onSuccess: (result, params) => {
      // Do something when the mutation is successful
      queryClient.setQueryData(['lcaBlocks', selectedOrg?.id, result.acv_id], () => {
        return [result.process, result.final_product];
      });

      queryClient.invalidateQueries({ queryKey: ['lcaPortfolio'] });

      queryClient.setQueryData(['lca', result.acv_id], () => {
        return {
          id: result.acv_id,
          name: result.final_product.name,
          impact_categories: params.impactCategories
        };
      });
    }
  });

  return {
    createLCAModel: mutateAsync
  };
};
