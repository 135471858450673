import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';

type Props = {
  button: {
    text: string;
    onClick: () => void;
  };
};
function OrderPlaced({ button }: Props) {
  const { t } = useTranslation();

  return (
    <div className='order-placed'>
      <img src='/images/orderPlaced.svg' alt='order-placed' />
      <h1 className='headline3-font'>{t('offset.congratulations')}</h1>
      <p className='subtitle3-font'>{t('offset.congratulationsDescription')}</p>
      <Button lookAndFeel='primary' text={button.text} onClick={button.onClick} size='medium' />
    </div>
  );
}

export default OrderPlaced;
