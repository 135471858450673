import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('tasks.category'),
      dataIndex: 'category',
      key: 'category'
    },

    {
      title: t('tasks.assignedTo'),
      dataIndex: 'assigned_to',
      key: 'assigned_to'
    },
    {
      title: t('tasks.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('tasks.tags'),
      dataIndex: 'tags',
      key: 'tags'
    },
    {
      title: t('tasks.dueDate'),
      dataIndex: 'due_date',
      key: 'due_date'
    },
    {
      title: t('tasks.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
