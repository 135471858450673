import { useTranslation } from 'react-i18next';
import getPrefixes from '../constants/phonePrefixes';
import {
  IPurchaseGeneralBackend,
  IPurchaseSpendBasedBackend,
  IPurchaseSpendBasedFormFront,
  IPurchaseSupplierSpecificBackend,
  IPurchaseSupplierSpecificFormFront,
  PurchaseType
} from '../types/purchases';
import { convertStringToDate } from '../utils/convertDates';
import { formatDate } from '../utils/formatDate';
import { currencySymbols } from '../constants/currencySymbols';

/* SPEND BASED */

export const adaptPurchasesSpendBasedBackToFront = (
  value: IPurchaseSpendBasedBackend
): IPurchaseSpendBasedFormFront => {
  const { i18n } = useTranslation();
  return {
    businessName: value.supplier?.business_name ?? '',
    isRecycled: Boolean(value.recycled),
    price: value.quantity ? value.quantity.toString() : '',
    currency: value.unit
      ? { id: value.unit.id, name: currencySymbols[value.unit.name] }
      : { id: '', name: '' },
    country: value.country
      ? {
          id: value.country,
          name:
            getPrefixes(i18n.resolvedLanguage).find((prefix) => prefix.code === value.country)
              ?.name || value.country
        }
      : { id: '', name: '' },
    purchaseDate: value.purchase_date
      ? formatDate(new Date(value.purchase_date))
      : formatDate(new Date()),
    description: value.description || '',
    co2e: value.co2e || 0,
    supplierCountry: value.supplier?.country
      ? {
          id: value.supplier.country,
          name:
            getPrefixes(i18n.resolvedLanguage).find(
              (prefix) => prefix.code === value.supplier?.country
            )?.name || value.supplier.country
        }
      : { id: '', name: '' },
    errors: []
  };
};

export const adaptPurchasesSpendBasedFrontToBack = (value: IPurchaseSpendBasedFormFront) => ({
  business_name: value.businessName,
  supplier_country: value.supplierCountry.id,
  recycled: value.isRecycled ? 1 : 0,
  quantity: Number(value.price),
  unit_id: value.currency.id,
  country: value.country.id,
  purchase_date: convertStringToDate(value.purchaseDate),
  description: value.description
});

const adaptPurchaseDuplicateSpendBased = (value: IPurchaseSpendBasedBackend) => {
  return {
    quantity: value.quantity,
    unit_id: value.unit?.id ?? value.unit_id,
    country: value.country,
    description: value.description,
    recycled: value.recycled,
    business_name: value.supplier?.business_name ?? '',
    purchase_date: value.purchase_date,
    organization_id: value.organization_id
  };
};

/* SUPPLIER SPECIFIC */

const adaptPurchaseDuplicateSupplierSpecific = (value: IPurchaseSupplierSpecificBackend) => {
  return {
    product_name: value.product_name,
    quantity: value.quantity,
    unit_id: value.unit?.id ?? value.unit_id,
    purchase_date: value.purchase_date,
    description: value.description
  };
};

export const adaptPurchasesSupplierSpecificBackToFront = (
  value: IPurchaseSupplierSpecificBackend
): IPurchaseSupplierSpecificFormFront => {
  const { t } = useTranslation();
  return {
    supplier: value?.supplier
      ? {
          id: value.supplier,
          name: value.supplier
        }
      : { id: '', name: '' },
    product: value.product_name
      ? {
          id: value.product_name,
          name: value.product_name
        }
      : { id: '', name: '' },
    purchaseDate: value.purchase_date
      ? formatDate(new Date(value.purchase_date))
      : formatDate(new Date()),
    description: value.description || '',
    quantity: value.quantity ? value.quantity.toString() : '',
    unit: value.unit
      ? { id: value.unit.id, name: t(`units.${value.unit.name}`) }
      : { id: '', name: '' },
    co2e: value.co2e || 0,
    errors: []
  };
};

export const adaptPurchasesSupplierSpecificFrontToBack = (
  value: IPurchaseSupplierSpecificFormFront
): Partial<IPurchaseSupplierSpecificBackend> => ({
  product_name: value.product.id,
  quantity: Number(value.quantity),
  purchase_date: convertStringToDate(value.purchaseDate),
  unit_id: value.unit.id,
  description: value.description,
  supplier: value.supplier.id
});

/* GENERIC */

export const adaptPurchaseDuplicate = (value: IPurchaseGeneralBackend) => {
  if (value.purchase_type === PurchaseType.SPEND_BASE) {
    return adaptPurchaseDuplicateSpendBased(value);
  }

  return adaptPurchaseDuplicateSupplierSpecific(value);
};
