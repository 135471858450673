import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import { deleteAvoidedEmission } from '../../../../../services/api/avoidedEmissions';
import Button from '../../../../ui/button/Button';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';

type Props = {
  id: string;
  deleteAvoidedEmissionById: (id: string) => void;
};

const DeleteAvoidedEmission = ({ id, deleteAvoidedEmissionById }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteAvoidedEmission = async () => {
    if (!user?.selectedOrganization || loadingButton) return;
    setLoadingButton(true);
    const response = await deleteAvoidedEmission(id, user.selectedOrganization);

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    deleteAvoidedEmissionById(id);
    setLoadingButton(false);
  };

  return (
    <div className='delete-modal'>
      <FormHeader
        title={t('socialAndGovernance.delete')}
        description={t('socialAndGovernance.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('socialAndGovernance.delete')}
          onClick={handleDeleteAvoidedEmission}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteAvoidedEmission;
