import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';
import { ProfileCard } from '../profileCard/ProfileCard';
import AssumptionsModal from '../../assumptions/Assumptions';
import Modal from '../../ui/modal/Modal';
import { useState } from 'react';

export const Assumptions = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Modal.WithPortal
        show={isOpen}
        onClose={() => setIsOpen(false)}
        width='900px'
        maxWidth='900px'>
        <AssumptionsModal />
      </Modal.WithPortal>

      <ProfileCard.Layout>
        <div className='flex gap-x-4 items-center'>
          <ProfileCard.Content>
            <ProfileCard.Title>{t('profile.assumptions')}</ProfileCard.Title>
            <ProfileCard.Description>{t('profile.assumptionsDescription')}</ProfileCard.Description>
          </ProfileCard.Content>
          <Button
            lookAndFeel='primary'
            text={t('profile.viewAssumptions')}
            size='medium'
            style={{ width: 'fit-content', minWidth: '72px' }}
            onClick={onClick}
          />
        </div>
      </ProfileCard.Layout>
    </>
  );
};
