import Checkbox from '../../ui/formComponents2/checkbox/Checkbox';
import { FilterOption } from './FilterSection';

type FilterCheckboxType = {
  item: FilterOption;
  selected: boolean;
  onChange: (value: boolean) => void;
};

const FilterCheckbox = ({ item, selected, onChange }: FilterCheckboxType) => {
  return (
    <div className='filters__option'>
      <Checkbox text={item.label} onChange={onChange} selected={selected} type='square' />
    </div>
  );
};

export default FilterCheckbox;
