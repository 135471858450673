import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendar from '../../../../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import { FormDataStateESG } from '../ModalDatapoint';

type Props = {
  formDataState: FormDataStateESG;
};

export const DateComponent = ({ formDataState }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { formData, setFormData } = formDataState;
  const { onChangeValue } = useOnChangeValue({ setFormData });
  return (
    <FormWrapper>
      <FormCalendar
        label={t('startDate')}
        handleChangeDate={onChangeValue('value')}
        dateValue={formData.value as string}
      />
    </FormWrapper>
  );
};
