import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import { OrganizationRecommendation } from '../../../types/entities/organization';
import { User } from '../../../types/entities/user';
import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';

type Props = {
  id?: string;
  name: string;
  title: string;
  description: string;
  webUrl: string;
  characteristics: string[];
  imageUrl: string;
  user: User;
  status?: 'completed' | 'pending';
  addRecommendation: (recommendation: OrganizationRecommendation) => void;
  removeRecommendation: (id: string) => void;
};
function RecommendationDetail({
  id,
  name,
  title,
  description,
  webUrl,
  characteristics,
  imageUrl,
  user,
  status,
  addRecommendation,
  removeRecommendation
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);

  const handleCreateRecommendation = async () => {
    setLoadingButton(true);

    const foundOrganization = user?.organizations?.find(
      (org) => org.id === user.selectedOrganization
    );

    const orgName = foundOrganization ? foundOrganization.company_name : '';

    if (user.selectedOrganization) {
      const res = await apiFetch('post', '/organization_recommendations', {
        name,
        user_id: user.id,
        email: user.email,
        organization_name: orgName,
        organization_id: user.selectedOrganization
      });

      addRecommendation(res.data);

      dispatch(setNotification(t('notification.createRecommendation')));
    }
    setLoadingButton(false);
  };

  const handleRemoveRecommendation = async () => {
    setLoadingButton(true);
    if (id) {
      const res = await apiFetch(
        'delete',
        `/organization_recommendations/${id}`,
        {},
        { 'x-organization-id': user.selectedOrganization }
      );
      removeRecommendation(id);
      dispatch(setNotification(t('notification.deleteRecommendation')));
    }

    setLoadingButton(false);
  };

  const renderRecommendationStatus = () => {
    if (status === 'pending') {
      return (
        <Button
          lookAndFeel={'tertiary'}
          text={t('recommendations.applied')}
          size='small'
          onClick={() => {
            console.log('');
          }}
        />
      );
    } else if (status === 'completed') {
      return (
        <Button
          lookAndFeel={'completed'}
          text={t('recommendations.completed')}
          size='small'
          onClick={() => {
            //
          }}
        />
      );
    } else {
      return (
        <Button
          lookAndFeel={'primary'}
          text={t('recommendations.apply')}
          size='small'
          onClick={handleCreateRecommendation}
          loading={loadingButton}
        />
      );
    }
  };

  const h1Class =
    title.split(' ').length > 5 ? 'recommendations__title' : 'recommendations__title-small';

  return (
    <div className='card'>
      <div className='card-column'>
        <h1 className={h1Class}>{title}</h1>
        <div className='card-inside'>
          <div className='card-inside-column'>
            <span className='span-font'>{description}</span>
          </div>
          <div className='card-inside-column'>
            {characteristics.length > 0 && (
              <div className='characteristics-wrapper'>
                {characteristics.map((elem, index) => {
                  if (elem) {
                    return (
                      <div className='characteristic' key={`${elem}-${index}`}>
                        <img src='/images/icons/tickGradientCircle.svg' alt='tick-circle' />
                        <span className='span-font'>{elem}</span>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        </div>
        <div className='buttons'>
          {renderRecommendationStatus()}
          {status === 'pending' && (
            <div className='cancel-button-wrapper'>
              <span className='body3-font'>{t('recommendations.cancelRequest')}</span>
              <span
                className='body3-font highlight-text-color'
                onClick={handleRemoveRecommendation}>
                {t('recommendations.iWantToCancel')}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className='card-column'>
        <img className='recommendation-img' src={imageUrl} alt='recommendation-detail' />
      </div>
    </div>
  );
}

export default RecommendationDetail;
