import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../tooltip/Tooltip';
import './styles.scss';

type Props = {
  label?: React.ReactNode;
  value: string;
  onChangeValue: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  fontClass?: string;
  error?: ErrorType;
  height?: string;
  maxLength?: number;
  tooltip?: string;
};
function InputTextArea({
  label,
  value,
  onChangeValue,
  fontClass = 'input-font',
  error,
  height,
  maxLength = 500,
  tooltip
}: Props) {
  const { t } = useTranslation();

  const handleOnChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const maxChars = maxLength || 500;
    const { value } = e.target;
    if (value.length <= maxChars) {
      onChangeValue(e);
    }
  };

  return (
    <div className='input'>
      {(label || tooltip) && (
        <div className='label-wrapper'>
          <label className='input-label-font on-light-text-color'>{label}</label>
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}
      <div className={`input__textarea ${fontClass}`} style={{ height }}>
        <textarea
          onChange={handleOnChangeValue}
          value={value}
          className={fontClass}
          maxLength={maxLength}
        />
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
}

export default InputTextArea;
