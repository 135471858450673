/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import {
  getEmployeeFormData,
  getEmployeeFormDataQR
} from '../../../../../services/api/employeePeriod';

type Props = {
  isQrStatus: boolean;
  id?: string;
};

const useGetData = ({ isQrStatus, id }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [organizationId, setOrganizationId] = useState('');
  const [logo, setLogo] = useState<string | null>(null);
  const [lastPeriod, setLastPeriod] = useState<any>(null);

  const getData = async () => {
    if (!id) return;

    let response: any = null;
    if (isQrStatus) response = await getEmployeeFormDataQR(id);
    if (!isQrStatus) response = await getEmployeeFormData(id);

    if (!response || !response.ok) {
      setError(true);
      setLoading(false);
      return;
    }

    if (isQrStatus) setOrganizationId(id);
    else {
      const data = await response.json();
      setOrganizationId(data.organization_id);
      setLogo(data.logo_url);
      setLastPeriod(data.last_period);
    }

    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return { loading, error, organizationId, logo, lastPeriod };
};

export default useGetData;
