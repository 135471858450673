import { Vehicle } from '../../../../../../types/entities/vehicle';
import { useTranslation } from 'react-i18next';
import { limitString } from '../../../../../../utils/limitString';
import Label from '../../../../../ui/label/Label';
import Card from '../../../../../ui/cards/card/Card';
import ButtonDropdown from '../../../../../ui/buttonDropdown/ButtonDropdown';
import Icon from '../../../../../ui/icon/Icon';
import { numberToDecimalNonZero } from '../../../../../../utils/numberToDecimal';

type Props = {
  vehicle: Vehicle;
  description: string;
  listView: boolean;
  onClickCard: (id: string) => void;
  dropdownOptions: {
    id: string;
    name: string;
    onClick: () => void;
  }[];
};

const VehicleCardActive = ({
  vehicle,
  description,
  listView,
  onClickCard,
  dropdownOptions
}: Props) => {
  const { t, i18n } = useTranslation();

  const total: number | undefined = vehicle.co2e
    ? numberToDecimalNonZero(vehicle.co2e / 1000, 4)
    : undefined;

  return (
    <Card key={vehicle.id} listView={listView} onClick={() => onClickCard(vehicle.id)}>
      <Card.Options>
        <ButtonDropdown
          button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
          options={dropdownOptions}></ButtonDropdown>
      </Card.Options>
      <Card.Content>
        {
          <>
            <Card.Icon
              icon={
                vehicle.type === 'passenger' ? '/images/icons/car.svg' : '/images/icons/truck.svg'
              }
              alt={vehicle.name}
            />
            <span className='headline4-font'>
              {vehicle.unknown_vehicle_type && i18n.exists(`vehicles.${vehicle.name}`)
                ? t(`vehicles.${vehicle.name}`)
                : limitString(vehicle?.name || '', 25)}
            </span>
            <span className='subtitle3-font'>{limitString(description, listView ? 200 : 30)}</span>
            {vehicle.status === 'archived' && (
              <Label lookAndFeel='disabled'>{t('vehicles.archived')}</Label>
            )}
            {total && <Card.Total total={total} unit={'t CO₂ eq.'} />}
          </>
        }
      </Card.Content>
    </Card>
  );
};

export default VehicleCardActive;
