import { useEffect, useState } from 'react';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { getOrganizationsIntensityMetrics } from '../../../../../services/api/intensityMetricsGroupings';

type OrganizationIntensityMetric = {
  organization_id: string;
  organization_name: string;
  intensity_metric_groups: IntensityMetricGroup[];
};
const useGetData = (id: string) => {
  const [organizationsIntensityMetrics, setOrganizationIntensityMetrics] = useState<
    OrganizationIntensityMetric[]
  >([]);

  const [loading, setLoading] = useState(false);

  const selectedOrganization = useSelectedOrganization();

  const fetchData = async () => {
    setLoading(true);
    const data = await getOrganizationsIntensityMetrics(id);
    setLoading(false);
    if (!data) return;
    setOrganizationIntensityMetrics(data);
  };

  useEffect(() => {
    if (!selectedOrganization) return;
    fetchData();
  }, []);
  return { organizationsIntensityMetrics, loading };
};

export default useGetData;
