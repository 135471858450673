import React, { Dispatch, SetStateAction } from 'react';
import { FormSocialData, OptionalQuestion } from '../../../../../../../types/entities/socialData';
import QuestionBoolean from '../../../question/QuestionBoolean';
import QuestionFloat from '../../../question/QuestionFloat';
import QuestionDoubleFloat from '../../../question/QuestionDoubleFloat';
import FormElementFull from '../../../../../../ui/formComponents/formElementFull/FormElementFull';

type Props = {
  formData: FormSocialData;
  setFormData: Dispatch<SetStateAction<FormSocialData>>;
  questions: OptionalQuestion[];
};
const FormPIAS = ({ formData, setFormData, questions }: Props) => {
  const renderQuestion = (question: OptionalQuestion) => {
    if (question.type === 'boolean') {
      return <QuestionBoolean question={question} formData={formData} setFormData={setFormData} />;
    }
    if (question.type === 'float') {
      return <QuestionFloat question={question} formData={formData} setFormData={setFormData} />;
    }
    if (question.type === 'float_double') {
      return (
        <QuestionDoubleFloat question={question} formData={formData} setFormData={setFormData} />
      );
    }

    return null;
  };
  return (
    <>
      {questions.map((question) => (
        <FormElementFull key={question.id}>{renderQuestion(question)}</FormElementFull>
      ))}
    </>
  );
};

export default FormPIAS;
