import { secondaryCodePatchAdapter } from '../../components/admin/purchases/codeManagement/secondary/adapter';
import { ISecondaryCodeBackend, ISecondaryCodeFront } from '../../types/secondaryCode';
import apiFetch from '../../utils/apiFetch';
import { IFilter, getUrl } from '../../utils/url';

const URL = '/mapping-purchase-code';

export const getSecondaryCodes = (filters: IFilter[]) => async (page: number) => {
  const queryParams = [
    {
      key: 'page',
      value: page
    }
  ];

  try {
    const url = getUrl(URL, {
      queryParams,
      filters
    });

    const response = await apiFetch('GET', url);

    return response.data as Pagination<ISecondaryCodeBackend>;
  } catch (err) {
    return undefined;
  }
};

export const patchSecondaryCode = async (id: string, data: Partial<ISecondaryCodeFront>) => {
  try {
    const url = `${URL}/${id}`;

    const response = await apiFetch('PATCH', url, secondaryCodePatchAdapter(data));

    return response.data as ISecondaryCodeBackend;
  } catch (err) {
    return undefined;
  }
};
