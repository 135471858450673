export const LCA_DETAIL_OPTIONS = {
  environmental_impacts: 'environmental_impacts',
  canvas: 'canvas'
} as const;

export const LCA_DETAIL_STEPS = [
  {
    id: LCA_DETAIL_OPTIONS.canvas,
    name: 'lcaDetail.canvas'
  },
  {
    id: LCA_DETAIL_OPTIONS.environmental_impacts,
    name: 'lcaDetail.environmentalImpacts'
  }
];

export const LCA_ENVIRONMENTAL_IMPACTS_HIGHEST_LEVEL_COMPONENTS = {
  production: 'Producción',
  construction: 'Construcción',
  use: 'Uso',
  end_of_life: 'Final de vida'
} as const;

export const LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS = {
  A1: 'lcaDetail.stages.a1',
  A2: 'lcaDetail.stages.a2',
  A3: 'lcaDetail.stages.a3',
  A4: 'lcaDetail.stages.a4',
  A5: 'lcaDetail.stages.a5',
  B1: 'lcaDetail.stages.b1',
  B2: 'lcaDetail.stages.b2',
  B3: 'lcaDetail.stages.b3',
  B4: 'lcaDetail.stages.b4',
  B5: 'lcaDetail.stages.b5',
  B6: 'lcaDetail.stages.b6',
  B7: 'lcaDetail.stages.b7',
  C1: 'lcaDetail.stages.c1',
  C2: 'lcaDetail.stages.c2',
  C3: 'lcaDetail.stages.c3',
  C4: 'lcaDetail.stages.c4'
} as const;

export const LCA_METHODOLOGIES = {
  'EF v3.1 EN15804': 'df6be0e7-fa97-4dfe-aafa-f6c47073bae4',
  EN15804: 'cb36c3be-4ded-4ef9-a858-a6517c4460bd',
  'IPCC 2021': 'c369d7ef-26ab-43a8-88cd-514a78b6864b',
  USEtox: 'e69869c5-f8f9-4a41-9abf-97be2bfd2411',
  'CML v4.8 2016': '3faeff35-b3d9-4968-80b1-dc0595f795b4',
  'ReCiPe 2016 v1.03, midpoint (H)': 'a2d76354-086b-4c71-81bc-dfd3b692b738',
  'IPCC 2021 no LT': '09bf0d41-07bf-4038-bdeb-b08e329c8a41'
} as const;

export const AZKOYEN_OLD_IDS = [
  '4f49a48b-05d8-4a1e-a618-da62f041bb74',
  '42ec113b-e4de-4f60-b766-4e16092445af',
  '005412f0-8c19-4120-b0d6-e1a4c04513a2',
  'b96137e4-506c-4875-b0b1-1a1e84f6613f',
  'b1363425-fb3b-46b8-a784-398114198349',
  '36a01700-4a22-4c15-aecf-7f78bc1a6f95',
  '157b7878-d53e-46c4-ad79-9147b316ca23',
  '069f0ed9-2ec6-4ec2-8ff3-fd378fe39f55',
  'ab444f9d-5af1-469d-9fcd-a6937dca5bb5',
  '7a65de3c-8e7e-411a-abc6-a43bbd9b507c',
  '4eb722e1-f926-413b-b422-e61967565efe',
  '784a06ec-bfca-48f1-bec2-0e2efb2ed8f0'
];
