import { useTranslation } from 'react-i18next';

const useLocations = () => {
  const { t } = useTranslation();
  return [
    {
      id: 'urban_high_density',
      name: t('events.urban_high_density')
    },
    {
      id: 'urban_medium_density',
      name: t('events.urban_medium_density')
    },
    {
      id: 'suburban_low_density',
      name: t('events.suburban_low_density')
    }
  ];
};

export default useLocations;
