import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../actions/notification';
import { UserContext } from '../../../../../../context/userContext';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';
import { sendEmails } from '../../../../../../services/api/emails';
import { IPurchaseSupplierSpecificFormFront } from '../../../../../../types/purchases';

import checkFormErrors from '../../../../../../utils/checkFormErrors';
import { convertDateToString } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormCalendar from '../../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../../ui/formComponents2/formInputs/formText/FormText';
import InputNumber from '../../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../../ui/formComponents2/select/Select';
import useProducts from './useProducts';
import useSuppliers from './useSupplier';
import useUnits from './useUnits';

type Props = {
  handleSubmit: (purchase: IPurchaseSupplierSpecificFormFront) => Promise<void>;
  formDataFilled?: IPurchaseSupplierSpecificFormFront;
  error?: ErrorType;
};

function FormSupplierSpecific({ handleSubmit, formDataFilled, error }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();
  const dispatch = useDispatch();

  const emptyFormData = {
    product: { id: '', name: '' },
    quantity: '',
    unit: { id: '', name: '' },
    purchaseDate: '',
    description: '',
    supplier: { id: '', name: '' },
    errors: [],
    co2e: 0
  };

  const [formData, setFormData] = useState<IPurchaseSupplierSpecificFormFront>(
    formDataFilled || emptyFormData
  );

  const products = useProducts();
  const suppliers = useSuppliers({ product: formData.product.id });
  const units = useUnits({ item: formData.product.id, supplier: formData.supplier.id });

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const removeSupplier = () => onChangeValue('supplier')({ id: '', name: '' });
  const removeUnit = () => onChangeValue('unit')({ id: '', name: '' });

  const onChangeProduct = (value: SelectOptionFormat) => {
    onChangeValue('product')(value);
    removeSupplier();
    removeUnit();
  };

  const onChangeSupplier = (value: SelectOptionFormat) => {
    onChangeValue('supplier')(value);
    removeUnit();
  };

  const handleSendEmail = async () => {
    if (!user) return;
    const response = await sendEmails(
      `El usuario ${user.email} de la organización ${selectedOrganization?.company_name} ha pedido un ACV`,
      undefined,
      undefined,
      ['cecilia@dcycle.io', 'clients@dcycle.io']
    );

    if (response?.response?.status >= 400) {
      dispatch(setNotification(t('notification.analysisStartedError')));
      return;
    }
    dispatch(setNotification(t('notification.analysisStarted')));
  };

  const handleErrors = () => {
    const optionalFields = ['description'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors.length !== 0 ? newErrors : false;
  };

  const handleCLickSubmit = async () => {
    if (handleErrors() || loadingButton) return;
    setLoadingButton(true);
    await handleSubmit(formData);
    setLoadingButton(false);
  };

  return (
    <>
      <div className='form-wrapper'>
        <FormText
          label={t('purchases.description')}
          icon={'/images/icons/shoppingBagGradient.svg'}
          placeholder={t('purchases.description')}
          value={formData.description}
          onChange={onChangeValue('description')}
        />
        <FormSelect
          icon={'/images/icons/pin.svg'}
          placeholder={t('purchases.selectProduct')}
          label={
            <>
              {t('purchases.productTitle')}{' '}
              <span onClick={handleSendEmail} className='highlight-text-color pointer'>
                {t('here')}
              </span>
            </>
          }
          value={formData.product}
          onChange={onChangeProduct}
          error={formData.errors.find((elem) => elem.error === 'product')}
          options={products}
        />
        <FormSelect
          icon={'/images/icons/pin.svg'}
          placeholder={t('purchases.selectSupplier')}
          label={t('purchases.supplier')}
          value={formData.supplier}
          onChange={onChangeSupplier}
          error={formData.errors.find((elem) => elem.error === 'supplier')}
          options={suppliers}
        />
        <FormCalendar
          label={t('purchases.date')}
          handleChangeDate={onChangeValue('purchaseDate')}
          error={formData.errors.find(
            (elem) => elem.error === 'date' || elem.error === 'purchaseDate'
          )}
          dateValue={formData.purchaseDate}
          maxDate={convertDateToString(new Date())}
          minDate={'01/01/2010'}
          handleDateError={handleDateError('purchaseDate')}
        />

        <InputWrapper
          label={t('purchases.quantity')}
          icon='/images/icons/plus.svg'
          error={formData.errors.find(
            (elem) => elem.error === 'quantity' || elem.error === 'unit'
          )}>
          <InputNumber
            value={formData.quantity}
            onChange={onChangeValue('quantity')}
            placeholder={t('purchases.enterQuantity')}
          />
          <Select
            value={formData.unit}
            onChange={onChangeValue('unit')}
            options={units}
            placeholder={t('purchases.selectUnit')}
          />
        </InputWrapper>
      </div>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('purchases.save')}
          onClick={handleCLickSubmit}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
}

export default FormSupplierSpecific;
