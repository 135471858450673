import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LANGUAGES } from '../../../../../constants';
import { NotificationContext } from '../../../../../context/notificationContext';
import { UserContext } from '../../../../../context/userContext';
import useTemporalOptions from '../../../../../customHooks/useTemporalOptions';
import { IGetBackendBusinessTravel } from '../../../../../types/entities/businessTravels';
import { Employee } from '../../../../../types/entities/employee';

import { SendFormType } from '../../../../../types/utilsEnums/form';
import apiFetch from '../../../../../utils/apiFetch';
import getBase64 from '../../../../../utils/getBase64';
import Button from '../../../../ui/button/Button';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import Select from '../../../../ui/formComponents/select/Select';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import ButtonsSection from './ButtonsSection/ButtonsSection';
import UploadCSV from './UploadCSV/UploadCSV';

export type Props = {
  type: SendFormType;
  handleGoToManual: () => void;
  close: () => void;
  addEmployees?: (employees: Employee[]) => void;
  addBusinessTrips?: (businessTrips: IGetBackendBusinessTravel[]) => void;
};

export type Body = {
  subject: string;
  file: string | null | ArrayBuffer;
  organization_id: string | undefined;
  lang: string;
  temporal?: string;
};
export const SendEmailCSVContent = ({
  handleGoToManual,
  close,
  type,
  addEmployees,
  addBusinessTrips
}: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const { options } = useTemporalOptions();

  const setNotification = useContext(NotificationContext);
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<SelectOptionFormat>(options[0]);
  const [file, setFile] = useState<File | null>(null);

  const [subject, setSubject] = useState('');
  const subjectHasChange = useRef(false);

  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const [language, setLanguage] = useState<SelectOptionFormat>({
    id: i18n.resolvedLanguage,
    name: t(`languages.${i18n.resolvedLanguage}`)
  });

  useEffect(() => {
    if (!subjectHasChange.current)
      setSubject(t('sendEmailForm.subjectDefault', { lng: language.id }));
  }, [language]);

  const sendCsv = () => {
    if (!user || !file) {
      return null;
    }
    getBase64(file, async (file_url: string | null | ArrayBuffer) => {
      if (!file_url) {
        return null;
      }

      let url = '';
      const body: Body = {
        subject,
        file: file_url,
        organization_id: user?.selectedOrganization,
        lang: language.id
      };
      if (type === 'employee') {
        url = '/employees/csv';
      }
      if (type === 'businessTravel') {
        url = '/business_travels/csv';
        body.temporal = selectedOption.id;
      }

      try {
        setLoadingButton(true);
        const response = await apiFetch('POST', url, body);
        setLoadingButton(false);
        if (addEmployees) {
          addEmployees(response.data);
        }
        if (addBusinessTrips) {
          addBusinessTrips(response.data);
        }

        dispatch(setNotification(t('notification.customFormSent')));
        close();
      } catch (err: any) {
        let error = {
          error: 'badCSV',
          description: t('error.badCSV')
        };
        if (err.response.data === 'MAX_EMAILS_EXCEEDED') {
          error = {
            error: 'badCSV',
            description: t('error.maxEmailsExceeded')
          };
        }
        setLoadingButton(false);
        setErrors((errors) => errors.concat(error));
      }
    });
  };

  //  TEMPORARY
  const handleSelectOption = (value: SelectOptionFormat) => {
    setSelectedOption(value);
  };

  // UPLOAD FILE
  const handleUploadFile = (fileUploaded: File) => {
    setFile(fileUploaded);
    setErrors([]);
  };

  // SEND EMAIL
  const handleSendEmail = () => {
    sendCsv();
  };

  const onChangeLanguage = (value: SelectOptionFormat) => {
    setLanguage(value);
  };

  const onChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
    subjectHasChange.current = true;
    setSubject(e.target.value);
  };

  return (
    <>
      <ButtonsSection handleGoToManual={handleGoToManual} type={type} />
      <UploadCSV
        file={file}
        handleUploadFile={handleUploadFile}
        error={errors.find((elem) => elem.error === 'badCSV')}
      />
      {/* {type === 'businessTravel' ? (
              <SendTemporary
                handleSelectOption={handleSelectOption}
                selectedOption={selectedOption}
                options={options}
              />
            ) : null} */}

      <FormWrapper>
        <Select
          options={LANGUAGES.map((language) => ({
            id: language,
            name: t(`languages.${language}`)
          }))}
          icon={'/images/icons/globe.svg'}
          placeholder={t('sendEmailForm.language')}
          label={t('sendEmailForm.language')}
          value={language}
          onChangeValue={onChangeLanguage}
          height='30px'
        />
        {type === 'employee' && (
          <FormElementFull>
            <FormText
              label={t('sendEmailForm.subjectTitle')}
              value={subject}
              icon='/images/icons/envelope.svg'
              placeholder={t('sendEmailForm.subjectPlaceholder')}
              onChange={onChangeSubject}
            />
          </FormElementFull>
        )}
      </FormWrapper>
      <div className='button-send-email'>
        <Button
          lookAndFeel={'primary'}
          onClick={handleSendEmail}
          text={t('sendEmailForm.send')}
          size={'medium'}
          height={'small'}
          loading={loadingButton}
        />
      </div>
    </>
  );
};
