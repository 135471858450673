import { useLCANodeMetadata } from './useLCANodeMetadata';

type Params = {
  acv_id: string;
  node_id: string;
};

export const useLCAMaterial = ({ acv_id, node_id }: Params) => {
  const { data } = useLCANodeMetadata({
    acv_id
  });

  return data?.material[node_id];
};
