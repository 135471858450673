import { useTranslation } from 'react-i18next';

const useSortingOptions = () => {
  const { t } = useTranslation();
  const options = [
    {
      id: 'short_name',
      name: t('input.sorting.datapoints.short_name')
    },
    {
      id: 'data_point_name',
      name: t('input.sorting.datapoints.data_point_name')
    },
    // { id: 'status', name: t('input.sorting.datapoints.status') },
    { id: 'relevance', name: t('input.sorting.datapoints.relevance') }
  ];
  return options;
};

export default useSortingOptions;
