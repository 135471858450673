import { SIZE_PAGINATION_BIG } from '../../constants';
import apiFetch from '../../utils/apiFetch';

type GetListProps = {
  groupId: string;
  page: number;
  size: number;
};

export const getListCustomEFDetail = async ({
  groupId,
  page,
  size = SIZE_PAGINATION_BIG
}: GetListProps) => {
  try {
    const response = await apiFetch(
      'GET',
      `/custom_emission_factors/list/${groupId}`,
      {},
      {},
      {
        page,
        size
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

type GetProps = {
  id: string;
};

export const getCustomEFDetail = async ({ id }: GetProps) => {
  try {
    const response = await apiFetch('GET', `/custom_emission_factors/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

type PostProps = {
  id: string;
  data: ICustomEFBack2Send;
};

export const postCustomEFDetail = async ({ id, data }: PostProps) => {
  try {
    const response = await apiFetch('POST', `/custom_emission_factors/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

type PatchProps = {
  id: string;
  data: ICustomEFBack2Send;
};

export const patchCustomEFDetail = async ({ id, data }: PatchProps) => {
  try {
    const response = await apiFetch('PATCH', `/custom_emission_factors/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCustomEFDetail = async (id: string, groupId: string) => {
  try {
    const response = await apiFetch(
      'DELETE',
      `/custom_emission_factors/${id}`,
      {},
      {
        x_custom_emission_group_id: groupId
      }
    );
    return true;
  } catch (error) {
    return null;
  }
};
