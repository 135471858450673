import { useReactFlow } from '@xyflow/react';
import { CustomControl } from './CustomControl';

export const FitViewBTN = () => {
  const { fitView } = useReactFlow();

  return (
    <CustomControl onClick={() => fitView({ duration: 1000 })} icon='expand' color='gray-dark' />
  );
};
