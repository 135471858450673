import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import useGetIntensityMetrics from '../../../useGetIntensityMetrics';

const useOptions = () => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const approachOptions: SelectOptionFormat[] = [
    {
      id: 'operational',
      name: t('ghgReport.approachType.operational')
    },
    {
      id: 'financial',
      name: t('ghgReport.approachType.financial')
    },
    {
      id: 'equity',
      name: t('ghgReport.approachType.equity')
    }
  ];

  const verificationOptions = [
    {
      id: 'eqa',
      name: t('ghgReport.verification.eqa')
    },
    {
      id: 'none',
      name: t('ghgReport.verification.none')
    },
    {
      id: 'other',
      name: t('ghgReport.verification.other')
    }
  ];

  let intensityMetricOptions: SelectOptionFormat[] = [];

  const { intensityMetricGroups, loading } = useGetIntensityMetrics();

  intensityMetricOptions = intensityMetricGroups.map((group) => ({
    id: group.id,
    name: group.unit
  }));

  const options = {
    intensityMetricOptions,
    approachOptions,
    verificationOptions
  };

  if (!flags?.intensityMetrics) return { ...options, intensityMetricOptions: [], loading: false };

  return { ...options, loading };
};

export default useOptions;
