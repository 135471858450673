import { useContext } from 'react';
import { convertDateBackToFront, convertDateFrontToBack } from '../utils/convertDates';
import { UserContext } from '../context/userContext';
import { convertBase64 } from '../customHooks/useGetBase64';
import { useTranslation } from 'react-i18next';

const useAdapterCustomEF = () => {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const adaptCustomEFFront2Back2Send = async (
    customEF: ICustomEFFront
  ): Promise<ICustomEFBack2Send> => {
    const unitId = customEF.type === 'simple' ? customEF.simpleUnit.id : customEF.customUnit.id;
    const base64 = await convertBase64(customEF.doc);

    const emissionFactorValues: EmissionFactorValueBack[] = [];
    if (customEF.type === 'simple') {
      emissionFactorValues.push({
        value: Number(customEF.simpleEF),
        gas_type: 'co2e'
      });
    }

    if (customEF.type === 'advanced') {
      emissionFactorValues.push({
        value: Number(customEF.customEF1),
        gas_type: 'co2'
      });
      emissionFactorValues.push({
        value: Number(customEF.customEF2),
        gas_type: 'ch4'
      });
      emissionFactorValues.push({
        value: Number(customEF.customEF3),
        gas_type: 'n2o'
      });
    }

    return {
      id: customEF.id,
      ef_name: customEF.name,
      unit_id: unitId,
      factor_uploaded_by: user?.id || '',
      tag: customEF.type,
      uncertainty_grade: Number(customEF.uncertaintyGrade),
      factor_start_date: convertDateFrontToBack(customEF.startDate),
      factor_end_date: convertDateFrontToBack(customEF.endDate),
      additional_docs: base64 || undefined,
      emission_factor_values: emissionFactorValues
    };
  };

  const adaptCustomEFBack2Front = (customEFGroup: ICustomEFBack): ICustomEFFront => {
    let simpleEF;
    let simpleUnit;

    let customEF1;
    let customEF2;
    let customEF3;
    let customUnit;

    if (customEFGroup.tag === 'simple') {
      simpleEF = customEFGroup.emission_factor_values[0].value;
      simpleUnit = {
        id: customEFGroup.unit_id,
        name: customEFGroup.unit ? t(`units.${customEFGroup.unit.name}`) : ''
      };
    }

    if (customEFGroup.tag === 'advanced') {
      customEF1 = customEFGroup.emission_factor_values[0].value;
      customEF2 = customEFGroup.emission_factor_values[1].value;
      customEF3 = customEFGroup.emission_factor_values[2].value;
      customUnit = {
        id: customEFGroup.unit_id,
        name: customEFGroup.unit ? t(`units.${customEFGroup.unit.name}`) : ''
      };
    }

    return {
      id: customEFGroup.id,
      name: customEFGroup.ef_name,
      startDate: convertDateBackToFront(customEFGroup.factor_start_date),
      endDate: convertDateBackToFront(customEFGroup.factor_end_date),
      type: customEFGroup.tag as 'simple' | 'advanced',

      // simple ef
      simpleEF: simpleEF?.toString() ?? '',
      simpleUnit: simpleUnit || { id: '', name: '' },

      // custom ef
      customEF1: customEF1?.toString() ?? '',
      customEF2: customEF2?.toString() ?? '',
      customEF3: customEF3?.toString() ?? '',
      customUnit: customUnit || { id: '', name: '' },

      uncertaintyGrade: customEFGroup.uncertainty_grade.toString(),
      doc: undefined,
      errors: []
    };
  };

  return { adaptCustomEFBack2Front, adaptCustomEFFront2Back2Send };
};

export default useAdapterCustomEF;
