import Icon from '../../ui/icon/Icon';
import './styles.scss';
type Props = {
  category: SelectOptionFormat;
  removeCategory: (id: string) => void;
};
const CategorySelected = ({ category, removeCategory }: Props) => {
  const handleRemove = () => {
    removeCategory(category.id);
  };

  return (
    <div className='category-selected'>
      {category.name}
      <Icon icon='close' onClick={handleRemove} color='gradient' />
    </div>
  );
};

export default CategorySelected;
