import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../../actions/notification';
import { UserContext } from '../../../../../../../context/userContext';
import useErrors from '../../../../../../../customHooks/useErrors';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../../../../customHooks/useSelectedOrganization';
import { uploadFilePresignedUrl } from '../../../../../../../services/api/aws';
import { sendEmails } from '../../../../../../../services/api/emails';
import { getPresignedUrlEsg } from '../../../../../../../services/api/esg';
import checkFormErrors from '../../../../../../../utils/checkFormErrors';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../../../../ui/formComponents/inputFile/InputFileSingle';
import { StarLabel } from '../../../../../../ui/statusLabels/starLabel/StarLabel';

type Props = {
  onClose: () => void;
};

type IFormData = {
  file?: File;
  errors: ErrorType[];
};

const UploadModal = ({ onClose }: Props) => {
  const { t } = useTranslation('', { keyPrefix: 'doubleMateriality.uploadModal' });
  const { t: tn } = useTranslation('');
  const ERRORS = useErrors();
  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const [formData, setFormData] = useState<IFormData>({
    errors: []
  });
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmitEmail = async () => {
    setLoading(true);

    const subject = `El usuario ${user?.email} de la organización ${selectedOrganization?.company_name} ha subido un archivo de Doble Materialidad`;

    const response = await sendEmails(subject, undefined, undefined, ['clients@dcycle.io']);

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    // handle errors
    if (handleErrors().length > 0 || !formData.file?.name) return;

    setLoading(true);

    // get presigned url
    const responsePresignedUrl = await getPresignedUrlEsg({
      fileName: formData.file?.name,
      folder: 'double_materiality'
    });

    // error when getting presigned url
    if (responsePresignedUrl?.response?.status >= 400) {
      setLoading(false);
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    // upload file to presigned url
    const response = await uploadFilePresignedUrl(formData.file, responsePresignedUrl?.upload_url);

    // error uploading file
    if (!response) {
      setLoading(false);
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    // Finish function
    setLoading(false);
    handleSubmitEmail();
    dispatch(setNotification(tn('notification.successUploadFile')));
    onClose();
  };

  const handleErrors = () => {
    const newErrors = checkFormErrors(formData, []);
    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));
    return newErrors;
  };

  return (
    <>
      <FormHeader title={<div className='flex'>{t('title')}</div>} />
      <FormWrapper>
        <FormElementFull>
          <p className='subtitle3-font '>{t('description')}</p>
          <InputFileSingle
            handleFile={onChangeValue('file')}
            buttonText={t('button')}
            fileName={formData.file?.name ?? ''}
            allowedTypes={[]}
            error={formData.errors.find((error) => error.error === 'file')}
            labelText={''}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('save')}
          size='medium'
          onClick={handleSubmit}
          loading={loading}
        />
      </FormButtonSection>
      <ErrorText>{error?.description}</ErrorText>
    </>
  );
};

export default UploadModal;
