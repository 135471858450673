import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParentOrg } from '../../../types/entities/organization';
import { User } from '../../../types/entities/user';
import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';
import InputText from '../../ui/formComponents/inputText/InputText';
import './styles.scss';

type Props = {
  holding: ParentOrg;
  user: User;
  onUnbind: () => void;
};
function UnbindAccount({ holding, user, onUnbind }: Props) {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleUnbindHolding();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleUnbindHolding = async () => {
    try {
      setLoadingButton(true);
      await apiFetch('DELETE', `/matrices/rel/${user?.selectedOrganization}/${holding.id}`, null, {
        'x-organization-id': user?.selectedOrganization
      });
      setLoadingButton(false);
      onUnbind();
    } catch (error) {
      setLoadingButton(false);
    }
  };

  return (
    <div className='unbind-account'>
      <h1 className='headline4-font'>{t('profile.unbindAccount')}</h1>
      <h2 className='subtitle3-font '>{t('profile.areYouSureUnbindAccount')}</h2>
      <div className='form-wrapper'>
        <InputText
          icon={'/images/icons/chain.svg'}
          placeholder={t('profile.writeHoldingId')}
          label={t('profile.holdingId')}
          value={holding.vat}
          height='31px'
          fontClass='input-small-font'
          size='small'
          disabled
        />
      </div>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('profile.unbind')}
          onClick={handleUnbindHolding}
          size='small'
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default UnbindAccount;
