import { getSmoothStepPath, useNodes, useReactFlow, useConnection, Position } from '@xyflow/react';

type Props = {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
};

export const CustomConnector = ({ fromX, fromY, toX, toY }: Props) => {
  const { fromHandle, toHandle } = useConnection();

  const { getInternalNode } = useReactFlow();
  const nodes = useNodes();
  const selectedNodes = nodes.filter((node) => node.selected && node.type !== 'product');

  const handleBounds = selectedNodes.flatMap((userNode) => {
    const node = getInternalNode(userNode.id);

    if (!node?.internals.handleBounds?.source) {
      return [];
    }

    return node.internals.handleBounds.source?.map((bounds) => ({
      id: node.id,
      positionAbsolute: node.internals.positionAbsolute,
      bounds
    }));
  });

  if (!handleBounds.length && !fromHandle) {
    return null;
  }

  const stroke = fromHandle?.id ?? '#6E86F7';

  if (!handleBounds.length) {
    const [d] = getSmoothStepPath({
      sourceX: fromX,
      sourceY: fromY,
      sourcePosition: fromHandle?.position ?? Position.Right,
      targetX: toX,
      targetY: toY,
      targetPosition: toHandle?.position ?? Position.Left,
      borderRadius: 8
    });

    return (
      <g>
        <path fill='none' strokeWidth={1.5} stroke={stroke} d={d} />
        <circle cx={toX} cy={toY} fill='#fff' r={3} stroke={stroke} strokeWidth={1.5} />
      </g>
    );
  }

  return handleBounds.map(({ id, positionAbsolute, bounds }) => {
    const fromHandleX = bounds.x + bounds.width / 2;
    const fromHandleY = bounds.y + bounds.height / 2;
    const fromX = positionAbsolute.x + fromHandleX;
    const fromY = positionAbsolute.y + fromHandleY;
    const [d] = getSmoothStepPath({
      sourceX: fromX,
      sourceY: fromY,
      sourcePosition: fromHandle?.position ?? Position.Right,
      targetX: toX,
      targetY: toY,
      targetPosition: toHandle?.position ?? Position.Left,
      borderRadius: 8
    });

    return (
      <g key={`${id}-${bounds.id}`}>
        <path fill='none' strokeWidth={1.5} stroke={stroke} d={d} />
        <circle cx={toX} cy={toY} fill='#fff' r={3} stroke={stroke} strokeWidth={1.5} />
      </g>
    );
  });
};
