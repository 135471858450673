import { ValidationError } from '../../components/validation/hooks/useGetData';
import apiFetch from '../../utils/apiFetch';
const ASSUMPTIONS_URL = '/data-validations/assumptions';

export const createValidationAssumption = async (comment: string, error: ValidationError) => {
  try {
    const response = await apiFetch('POST', `${ASSUMPTIONS_URL}`, { comment, error });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const editValidationAssumption = async (assumption_id: string, assumption: string) => {
  try {
    const response = await apiFetch('PATCH', `${ASSUMPTIONS_URL}/${assumption_id}`, {
      assumption_id,
      comment: assumption
    });
    return response.data;
  } catch (err) {
    return null;
  }
};
