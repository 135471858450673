import { cloneElement, useEffect, useRef } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactElement;
};
export const HeadwayBadge: React.FC<Props> = ({ children }) => {
  const hasLoaded = useRef(false);

  const { t } = useTranslation('translation', { keyPrefix: 'headwayBadge' });

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    const config = {
      selector: '#headway-badge-container',
      account: 'ypZO97',
      trigger: '#headway-badge-trigger',
      translations: {
        title: t('title'),
        readMore: t('readMore'),
        footer: t('footer'),
        labels: {
          new: t('new'),
          fix: t('fix'),
          improvement: t('improvement')
        }
      }
    };

    script.onload = function () {
      if (hasLoaded.current) return;

      hasLoaded.current = true;
      window.Headway.init(config);
    };

    return () => {
      document.head.removeChild(script);
    };
  });

  return (
    <div id='headway-badge-container'>
      {cloneElement(children, { id: 'headway-badge-trigger' })}
    </div>
  );
};
