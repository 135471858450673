import { TFunction } from 'i18next';
import { IFrontendFormTask, IGetBackendTask, IPostBackendTask } from '../types/entities/task';
import {
  convertDateBackToFrontTimestamp,
  convertDateFrontToBackTimestamp
} from '../utils/convertDates';

export const adaptTaskFrontToBack = (value: IFrontendFormTask): IPostBackendTask => {
  if (value.metric) {
    return {
      created_by: value.createdBy,
      organization_id: value.organizationId,
      assigned_to: value.userSelected.id,
      description: value.description,
      due_date: convertDateFrontToBackTimestamp(value.dueDate),
      reminder_date: convertDateFrontToBackTimestamp(value.dueDate), // FOR NOW
      category: value.category.id,
      tags: value.tagsSelected,
      organization_data_point_id: value.metric
    };
  } else {
    return {
      created_by: value.createdBy,
      organization_id: value.organizationId,
      assigned_to: value.userSelected.id,
      description: value.description,
      due_date: convertDateFrontToBackTimestamp(value.dueDate),
      reminder_date: convertDateFrontToBackTimestamp(value.dueDate), // FOR NOW
      category: value.category.id,
      tags: value.tagsSelected
    };
  }
};

export const adaptTaskBackToFront = (value: IGetBackendTask, t: TFunction): IFrontendFormTask => {
  return {
    userSelected: {
      id: value?.assigned_to,
      name: value?.assigned_user
    },
    category: {
      id: value.category,
      name: t(`tasks.categories.${value.category}`)
    },
    dueDate: convertDateBackToFrontTimestamp(value.due_date),
    description: value.description,
    tag: '',
    tagsSelected: value.tags ?? [],
    createdBy: value.created_by ?? '',
    organizationId: value.organization_id ?? '',
    files: value.attachments.map((elem) => ({
      id: elem.id,
      name: elem.file_name,
      file: null
    })),
    errors: []
  };
};
