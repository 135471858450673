import moment from 'moment';
import { ConfigureDataType, FormConfigureDataType } from '../../types/entities/groupFunds';
import apiFetch from '../../utils/apiFetch';
import { convertDateFrontToBack } from '../../utils/convertDates';

export const configureGroupFunds = async (
  child_id: string,
  formData: FormConfigureDataType,
  organization_id: string
) => {
  try {
    const data: ConfigureDataType = {
      parent_id: organization_id,
      child_id,
      fund_id: formData.fund.id,
      tag: formData.tag,
      investment_value: Number(formData.investment),
      debt_financing: formData.isDebtFinancing,
      enterprise_value: Number(formData.companyValue),
      investment_date: moment(formData.investmentDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      answer: 'accepted'
    };

    const response = await apiFetch('POST', `/matrices/confirm/groups_funds`, data);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const createInvestment = async (child_id: string, formData: FormConfigureDataType) => {
  try {
    const response = await apiFetch('POST', '/funds_relations', {
      fund_id: formData.fund.id,
      child_id,
      current_investment_value: +formData.investment,
      current_enterprise_value: +formData.companyValue,
      investment_date: convertDateFrontToBack(formData.investmentDate),
      tag: formData.tag
    });

    return response.data;
  } catch (error) {
    return null;
  }
};

export const editInvestment = async (
  investmentId: string,
  organizationId: string,
  formData: FormConfigureDataType
) => {
  try {
    const response = await apiFetch('PATCH', `/funds_relations_historic/${investmentId}`, {
      fund_id: formData.fund.id,
      investee_organization_id: organizationId,
      current_investment_value: formData.investment,
      current_enterprise_value: formData.companyValue,
      investment_date: convertDateFrontToBack(formData.investmentDate),
      tag: formData.tag
    });

    return response.data;
  } catch (error) {
    return null;
  }
};
