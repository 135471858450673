import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import useOnChangeValue from '../../customHooks/useOnChangeValue';
import useScreenSize from '../../customHooks/useScreenSize';
import useSelectedOrganization from '../../customHooks/useSelectedOrganization';
import useYearOptions from '../../customHooks/useYearOptions';
import checkFormErrors from '../../utils/checkFormErrors';
import dateToTimestamp from '../../utils/dateToTimestamp';
import { getDateOfYear } from '../../utils/getDate';
import Button from '../ui/button/Button';
import FormHeader from '../ui/formComponents/formHeader/FormHeader';
import FormSelect from '../ui/formComponents2/formInputs/formSelect/FormSelect';
import Icon from '../ui/icon/Icon';
import Label from '../ui/label/Label';
import Modal from '../ui/modal/Modal';
import StepCarouselList from '../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../ui/stepCarouselList/useStepCarousel';
import TableComponent from '../ui/table/Table';
import Assumption from './components/Assumption';
import ErrorDescription from './components/errorDescription/ErrorDescription';
import useGetData, {
  ErrorTypesValidation,
  VALIDATION_CATEGORIES,
  ValidationError
} from './hooks/useGetData';
import useStepsByCategory from './hooks/useStepsByCategory';
import './styles.scss';

export enum ValidationCategory {
  FACILITIES = 'facilities',
  VEHICLES = 'vehicles',
  ALL = 'all'
}
type FormData = {
  startDate?: number;
  endDate?: number;
  year: SelectOptionFormat;
  errors: ErrorType[];
};
type Props = {
  category: ValidationCategory;
  entity: string;
  entityId: string;
  subcategories: string[];
};
const Validation = ({ category, entity, entityId, subcategories }: Props) => {
  const [showAssumption, setShowAssumption] = useState(false);
  const [selectedError, setSelectedError] = useState<ValidationError | undefined>();
  const { height } = useScreenSize();
  const { years, maxYear } = useYearOptions();
  const [formData, setFormData] = useState<FormData>({
    year: {
      id: maxYear.toString(),
      name: maxYear.toString()
    },
    errors: []
  });
  const { t } = useTranslation('translation', { keyPrefix: 'validation' });
  const selectedOrganization = useSelectedOrganization();
  const navigate = useNavigate();

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const stepOptions = useStepsByCategory(category);

  let stepOptionsFiltered = [...stepOptions];

  if (subcategories && subcategories.length > 0) {
    stepOptionsFiltered = stepOptions.filter((elem) => subcategories.includes(elem.id));
  }

  const {
    stepSelected,
    handleSelect: handleSelectCarousel,
    steps
  } = useStepCarousel({
    stepsText: stepOptionsFiltered
  });

  const { data, setRequestParams, loading, setTriggerUpdate } = useGetData(
    (stepSelected?.id as VALIDATION_CATEGORIES) ?? ''
  );

  useEffect(() => {
    if (formData.year) {
      handleValidate();
    }
  }, [formData, category, stepSelected]);

  const handleValidate = async () => {
    const newErrors = checkFormErrors(formData, formData.errors);
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    if (stepSelected && selectedOrganization?.id) {
      setRequestParams({
        start_date: dateToTimestamp(getDateOfYear(Number(formData.year.id), 0, 1)),
        end_date: dateToTimestamp(getDateOfYear(Number(formData.year.id), 11, 31)),
        category: stepSelected.id as VALIDATION_CATEGORIES,
        selectedId: [entityId]
      });
    }
  };

  const columns = [
    {
      title: t('descriptionColumn'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('errorColumn'),
      dataIndex: 'error_type',
      key: 'error_type'
    },
    {
      title: '',
      dataIndex: 'assumption',
      key: 'assumption'
    }
  ];

  // Navigate to the create page of the entity of the error
  const navigateTo = () => {
    if (category === ValidationCategory.FACILITIES) {
      navigate(ROUTES.MEASURE_FACILITIES + '/' + entityId + '?show=create');
    }
    if (category === ValidationCategory.VEHICLES) {
      navigate(ROUTES.MEASURE_VEHICLES + '/' + entityId + '?show=create');
    }
  };

  // Navigate to the edit or delete page of the entity of the error
  const navigateToId = (id: string, mode = 'edit') => {
    if (!id) return;
    if (category === ValidationCategory.FACILITIES) {
      navigate(ROUTES.MEASURE_FACILITIES + '/' + entityId + `?show=${mode}&id=` + id);
    }
    if (category === ValidationCategory.VEHICLES) {
      navigate(ROUTES.MEASURE_VEHICLES + '/' + entityId + `?show=${mode}&id=` + id);
    }
  };

  const getEmissionId = (elem: ValidationError) => {
    if (elem.invoice_id) return elem.invoice_id;
    if (elem.consumption_id) return elem.consumption_id;
  };

  // Component that represents the error Label in the list
  const renderError = (elem: ValidationError) => {
    const { error, assumption } = elem;
    const warningTag = <Icon icon='warning' color='warning' />;
    let children = (
      <>
        <Icon icon='clock' color='pending' />
        {t('processing')}
      </>
    );
    let lookAndFeel: 'secondary' | 'warning' | 'success' = 'secondary';

    const hereSpan = <strong style={{ textDecoration: 'underline' }}>{t('here')}</strong>;

    if (error === ErrorTypesValidation.MISSING_RANGE) {
      children = (
        <>
          {warningTag}
          {t('missingData')}
          <span className='pointer' onClick={navigateTo}>
            {hereSpan}
          </span>
        </>
      );
      lookAndFeel = 'warning';
    }
    if (
      error === ErrorTypesValidation.MISSING_ATTACHMENT ||
      error === ErrorTypesValidation.MISSING_CUPS ||
      error === ErrorTypesValidation.VALUES_NOT_OK
    ) {
      children = (
        <>
          {warningTag}
          {t('missingData')}
          <span className='pointer' onClick={() => navigateToId(getEmissionId(elem) ?? '')}>
            {hereSpan}
          </span>
        </>
      );
      lookAndFeel = 'warning';
    }
    if (error === ErrorTypesValidation.WRONG_INVOICE_CUPS) {
      children = (
        <>
          {warningTag}
          {t('changeData')}
          <span className='pointer' onClick={() => navigateToId(getEmissionId(elem) ?? '')}>
            {hereSpan}
          </span>
        </>
      );
      lookAndFeel = 'warning';
    }

    if (error === ErrorTypesValidation.DUPLICATE || error === ErrorTypesValidation.OVERLAP) {
      children = (
        <>
          {warningTag}
          {t('deleteOne')}
          <span
            className='pointer'
            onClick={() => navigateToId(getEmissionId(elem) ?? '', 'delete')}>
            {hereSpan}
          </span>
        </>
      );
      lookAndFeel = 'warning';
    }
    if (error === ErrorTypesValidation.DUPLICATE_CUPS) {
      children = (
        <>
          {warningTag}
          {t('deleteOne')}
          <span
            className='pointer'
            onClick={() => navigateToId(getEmissionId(elem) ?? '', 'delete')}>
            {hereSpan}
          </span>
        </>
      );
      lookAndFeel = 'warning';
    }

    if (error === ErrorTypesValidation.DIVIDED_INVOICE) {
      children = (
        <>
          {warningTag}
          {t('addJustification')}
          <span
            className='pointer'
            onClick={() => {
              setSelectedError(elem);
              setShowAssumption(true);
            }}>
            {hereSpan}
          </span>
        </>
      );
      lookAndFeel = 'warning';
    }
    if (assumption && assumption.id) {
      children = (
        <>
          <Icon icon='success' color='success' />
          {t('verified')}
        </>
      );
      lookAndFeel = 'success';
    }

    return (
      <Label lookAndFeel={lookAndFeel} style={{ borderRadius: '22px', padding: '0.5rem' }}>
        <div className='flex' style={{ alignItems: 'center', gap: '0.25rem' }}>
          {children}
        </div>
      </Label>
    );
  };

  // Parse the data to be shown in the table
  const parseData = (data: ValidationError[]) => {
    const whiteListAssumptionCreate = [
      ErrorTypesValidation.MISSING_RANGE,
      ErrorTypesValidation.MISSING_ATTACHMENT,
      ErrorTypesValidation.MISSING_CUPS,
      ErrorTypesValidation.DIVIDED_INVOICE
    ];
    return data?.map((elem) => {
      return {
        ...elem,
        description: <ErrorDescription elem={elem} />,
        error_type: renderError(elem),
        assumption: whiteListAssumptionCreate.includes(elem.error) ? (
          <Button
            lookAndFeel='secondary'
            size='small'
            onClick={() => {
              setSelectedError(elem);
              setShowAssumption(true);
            }}>
            {elem.assumption ? t('edit') : t('doNotHave')}
          </Button>
        ) : (
          <></>
        )
      };
    });
  };

  return (
    <div className='validation'>
      <FormHeader
        title={t('title', {
          entity
        })}
        description={t('description')}
      />
      <div style={{ width: '40%', marginBottom: '1rem' }}>
        <FormSelect
          icon='/images/icons/calendar50.svg'
          placeholder={t('selectOption')}
          label={t('selectYear')}
          options={years}
          value={formData.year}
          onChange={onChangeValue('year')}
          error={formData.errors.find((elem) => elem.error === 'year')}
          sort={false}
        />
      </div>
      <StepCarouselList steps={steps} handleSelect={handleSelectCarousel} lookAndFeel={'big'} />
      <div className='validation__wrapper on-card-gray-bg-color'>
        <div
          className='validation__list card'
          id='validation_card'
          style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
          <TableComponent size='small' data={parseData(data)} columns={columns} loading={loading} />
        </div>
      </div>
      <Modal show={showAssumption} onClose={() => setShowAssumption(false)}>
        <Assumption
          error={selectedError}
          onFinishUpdate={() => {
            setShowAssumption(false);
            setTriggerUpdate(true);
          }}
        />
      </Modal>
    </div>
  );
};

export default Validation;
