import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { ParentOrg } from '../../../types/entities/organization';
import Button from '../../ui/button/Button';
import { ProfileCard } from '../profileCard/ProfileCard';
import Modal from '../../ui/modal/Modal';
import UnbindAccount from '../unbindAccount/UnbindAccount';
import { useContext } from 'react';
import { UserContext } from '../../../context/userContext';
import { useUnbindModal } from '../hooks/useUnbindModal';

type Props = {
  holding: ParentOrg;
};

export const AccountLinked: React.FC<Props> = ({ holding }) => {
  const { t } = useTranslation();

  const org = useSelectedOrganization();

  const user = useContext(UserContext);

  const { isOpen, openModal, closeModal, onUnbind } = useUnbindModal();

  if (!user) {
    return null;
  }

  return (
    <>
      <Modal.WithPortal show={isOpen} onClose={closeModal} width='428px' maxWidth='428px'>
        <UnbindAccount holding={holding} user={user} onUnbind={onUnbind} />
      </Modal.WithPortal>

      <ProfileCard.Layout>
        <div className='flex gap-x-4 items-center'>
          <ProfileCard.Content>
            <ProfileCard.Title>{t('profile.accountLinked')}</ProfileCard.Title>
            <ProfileCard.Description>
              {t('profile.accountLinkedDescription').replace('{{holdingId}}', holding.vat)}
            </ProfileCard.Description>
          </ProfileCard.Content>
          <Button
            lookAndFeel='primary'
            size='medium'
            text={t('profile.unbind')}
            style={{ width: 'fit-content' }}
            disabled={org?.role !== 'owner'}
            onClick={openModal}
          />
        </div>
      </ProfileCard.Layout>
    </>
  );
};
