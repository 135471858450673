import { FacilityFuelType } from '../../../../../../../types/entities/facilityFuel';
import { InvoiceFormData } from '../constants';

type Params = {
  mode: string;
  formData: InvoiceFormData;
  facilityFuels: FacilityFuelType[];
};
export const buildFacilityFuel = ({ mode, formData, facilityFuels }: Params) => {
  let facility_fuel: FacilityFuelType | undefined;

  if (mode === 'default') {
    facility_fuel = facilityFuels?.find(
      (fuel) => fuel.id === (formData.facilityFuel as SelectOptionFormat).id
    );
  }

  return facility_fuel;
};
