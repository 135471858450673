import {
  AMAZON_API_TOKEN_URI,
  AMAZON_CLIENT_ID,
  AMAZON_CLIENT_SECRET_ID,
  AMAZON_GRANT_TYPES
} from '../constants/amzAuth';
import controller from '../environments/controller';
import { AmazonAuthType } from '../types/utils/amzAuthType';

const BASE_URL = controller().clientUrl;

export const amzAuthGetToken = async ({
  auth_code
}: {
  auth_code: string;
  amazon_state: string;
}) => {
  const body = `grant_type=${AMAZON_GRANT_TYPES.AUTHORIZATION_CODE}&code=${auth_code}&client_id=${AMAZON_CLIENT_ID}&client_secret=${AMAZON_CLIENT_SECRET_ID}&redirect_uri=${BASE_URL}/dashboard`;
  try {
    const response = await fetch(AMAZON_API_TOKEN_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body
    });
    return await response.json();
  } catch (error) {
    return error;
  }
};

const amzAuthService = (authServiceParams: AmazonAuthType) => {
  const { amazon_callback_uri, state, redirect_uri, status, amazon_state } = authServiceParams;
  if (amazon_callback_uri && state && redirect_uri && status && amazon_state) {
    const redirectUri = `${amazon_callback_uri}?amazon_state=${amazon_state}&status=${status}&state=200&redirect_uri=${BASE_URL}/dashboard`;
    window.open(redirectUri, '_self');
  }
};

export default amzAuthService;
