import { createContext, useEffect, useState } from 'react';
import Modal from '../../../../ui/modal/Modal';
import { FormProvider } from './FormContext';
import { STEP_COMPONENTS, STEP_IDS, STEPS } from './constants';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { StepProvider } from '../../../../ui/stepCarouselList/StepContext';
import { formSchema } from './schema';
import { adaptZodErrors } from '../../../../../adapters/zodErrors';
import { useModalContext } from '../ModalHandler';
import { useConfigureProcessForm } from '../../hooks/useConfigureProcessForm';

type ConfigureProcessModalContextType = {
  nodeId: string | null;
  setNodeId: React.Dispatch<React.SetStateAction<string | null>>;
};
const Context = createContext<ConfigureProcessModalContextType | null>(null);

const Provider: React.FC = ({ children }) => {
  const [nodeId, setNodeId] = useState<string | null>(null);

  return <Context.Provider value={{ nodeId, setNodeId }}>{children}</Context.Provider>;
};

export const ConfigureProcessModal = () => {
  const { setModalData, modalData } = useModalContext();

  const { form, setForm, errors, setErrors } = useConfigureProcessForm(modalData?.target);

  const { t } = useLCATranslation();

  const { stepSelected, steps, handleSelect } = useStepCarousel({
    stepsText: STEPS.map((step) => ({ id: step.id, text: t(step.text) })),
    selected: STEPS[0].id
  });

  useEffect(() => {
    handleSelect(STEPS[0].id);
  }, [modalData?.target]);

  const Component = STEP_COMPONENTS[stepSelected?.id ?? STEPS[0].id];

  if (!modalData?.target || modalData.type !== 'edit_process') {
    return null;
  }

  const onSelect = (id: string) => {
    const validationSuccess = formSchema[stepSelected?.id ?? STEP_IDS.FIRST]?.safeParse(form);

    if (!validationSuccess.success) {
      setErrors(adaptZodErrors(validationSuccess.error, t));
      return;
    }

    handleSelect(id);
  };

  return (
    <Modal.WithPortal
      show={Boolean(modalData.target) && modalData.type === 'edit_process'}
      onClose={() => setModalData(undefined)}
      width='720px'>
      <StepProvider steps={steps} selectedStep={stepSelected} handleSelect={onSelect}>
        <FormProvider form={form} setForm={setForm}>
          <Component errors={errors} setErrors={setErrors} />
        </FormProvider>
      </StepProvider>
    </Modal.WithPortal>
  );
};

ConfigureProcessModal.Root = Provider;
