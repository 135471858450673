import { IOrganizationGoalBack } from '../../types/entities/organizationGoal';
import apiFetch from '../../utils/apiFetch';

export const getOrganizationGoal = async () => {
  try {
    const response = await apiFetch('GET', `/organization_goals`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const postOrganizationGoal = async (value: IOrganizationGoalBack) => {
  try {
    const response = await apiFetch('POST', `/organization_goals`, value);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const patchOrganizationGoal = async (id: string, value: IOrganizationGoalBack) => {
  try {
    const response = await apiFetch('PATCH', `/organization_goals/${id}`, value);

    return response.data;
  } catch (error) {
    return error;
  }
};
