import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../../../context/userContext';
import { useFeatureFlags } from '../../../../../../../../customHooks/useFeatureFlags';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import {
  getTreatmentById,
  patchTreatment,
  postTreatment
} from '../../../../../../../../services/api/treatment';
import { InvoiceType } from '../../../../../../../../types/entities/invoice';
import checkFormErrors from '../../../../../../../../utils/checkFormErrors';
import { formatDate } from '../../../../../../../../utils/formatDate';
import Button from '../../../../../../../ui/button/Button';
import ErrorText from '../../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormSectionTitle from '../../../../../../../ui/formComponents/formSectionTitle/FormSectionTitle';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputCalendar from '../../../../../../../ui/formComponents/inputCalendar/InputCalendar';
import InputNumber from '../../../../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../../../../ui/formComponents/inputText/InputText';
import InputTextSelect from '../../../../../../../ui/formComponents/inputTextSelect/InputTextSelect';
import CustomSkeletonLoader from '../../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import './styles.scss';
import useUnits from './useUnits';

export type FormDataTreatment = {
  name: string;
  nPerson: string;
  caudalQuantity: string;
  caudalUnit: SelectOptionFormat;
  startDate: string;
  endDate: string;
  dboQuantity: string;
  dboUnit: SelectOptionFormat;
  nitrogenUnit: SelectOptionFormat;
  caudalOutQuantity?: string;
  nitrogenQuantity?: string;
  dboOutQuantity: string;
  recoveredMethaneUnit: SelectOptionFormat;
  recoveredMethane: string;
  sludgeEvacuated: string;
  errors: ErrorType[];
};

export type TreatmentUnits = {
  caudalUnits: Unit[];
  dboUnits: Unit[];
  recoveredMethaneUnits: Unit[];
  nitrogenUnits: Unit[];
};

type Props = {
  addInvoice?: (value: InvoiceType) => void;
  editInvoice?: (id: string, invoice: InvoiceType) => void;
  id?: string;
  facilityId: string;
};

const Treatment = ({ addInvoice, editInvoice, id, facilityId }: Props) => {
  const flags = useFeatureFlags();

  const formDataEmpty: FormDataTreatment = {
    name: '',
    nPerson: '',
    caudalQuantity: '',
    caudalUnit: {
      id: '',
      name: ''
    },
    startDate: '',
    endDate: '',
    dboQuantity: '',
    dboUnit: {
      id: '',
      name: ''
    },
    nitrogenUnit: {
      id: '',
      name: ''
    },
    caudalOutQuantity: '',
    nitrogenQuantity: '',
    dboOutQuantity: '',
    recoveredMethaneUnit: {
      id: '',
      name: ''
    },
    recoveredMethane: '',
    sludgeEvacuated: '',
    errors: []
  };

  const user = useContext(UserContext);
  const { t } = useTranslation();
  const { units } = useUnits();
  const [formData, setFormData] = useState<FormDataTreatment>(formDataEmpty);
  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [loadingData, setLoadingData] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    const getTreatmentToEdit = async () => {
      if (!user?.selectedOrganization || !id) return;

      setLoadingData(true);
      const response = await getTreatmentById(id, user.selectedOrganization);
      if (response?.response?.status >= 400) {
        setLoadingData(false);
        return;
      }

      const caudalUnitFind = units.caudalUnits?.find(
        (unit) => unit.id === response.quantity_unit_id
      );

      const dboUnitFind = units.dboUnits?.find((unit) => unit.id === response.dbo_unit_id);

      const recoveredMethaneUnitFind = units.recoveredMethaneUnits?.find(
        (unit) => unit.id === response.ch4_recovered_unit_id
      );

      const nitrogenUnitFind = units.nitrogenUnits?.find(
        (unit) => unit.type === 'waste_water_treatment_nitrogen'
      );

      let caudalUnit = {
        id: '',
        name: ''
      };
      let dboUnit = {
        id: '',
        name: ''
      };
      let recoveredMethaneUnit = {
        id: '',
        name: ''
      };

      let nitrogenUnit = {
        id: '',
        name: ''
      };

      if (caudalUnitFind) {
        caudalUnit = {
          id: caudalUnitFind?.id,
          name: t(`units.${caudalUnitFind.name}`)
        };
      }
      if (dboUnitFind) {
        dboUnit = {
          id: dboUnitFind?.id,
          name: t(`units.${dboUnitFind.name}`)
        };
      }
      if (recoveredMethaneUnitFind) {
        recoveredMethaneUnit = {
          id: recoveredMethaneUnitFind?.id,
          name: t(`units.${recoveredMethaneUnitFind.name}`)
        };
      }

      if (nitrogenUnitFind) {
        nitrogenUnit = {
          id: nitrogenUnitFind?.id,
          name: t(`units.${nitrogenUnitFind.name}`)
        };
      }

      setFormData({
        name: response.name,
        nPerson: response.population_size_served,
        caudalQuantity: response.incoming_water_quantity,
        caudalUnit,
        startDate: formatDate(new Date(response.start_date)),
        endDate: formatDate(new Date(response.end_date)),
        dboQuantity: response.dbo_in_value,
        dboUnit,
        nitrogenUnit,
        caudalOutQuantity: response?.effluent_quantity,
        nitrogenQuantity: response?.nt_value,
        dboOutQuantity: response.dbo_out_value,
        recoveredMethaneUnit,
        recoveredMethane: response.ch4_recovered,
        sludgeEvacuated: response.sludge_evacuated,
        errors: []
      });
      setLoadingData(false);
    };

    getTreatmentToEdit();
  }, [units]);

  const handleErrors = () => {
    const optionalFields = ['errors', 'name'];
    if (!flags?.discharges) {
      optionalFields.push('caudalOutQuantity');
      optionalFields.push('nitrogenQuantity');
    }
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleCreateTreatment = async () => {
    if (!!handleErrors() || !user?.selectedOrganization || !user?.id || !addInvoice) return;
    setLoadingButton(true);

    const response = await postTreatment(formData, user.selectedOrganization, facilityId, user.id);

    if (response?.response?.status === 422 && response?.response?.data === 'NEGATIVE_CH4') {
      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: 'negative_ch4',
          description: t('treatment.negativeCh4')
        })
      }));
    }

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }
    const newInvoice: InvoiceType = {
      id: response.id,
      unit: units.caudalUnits?.find((unit) => unit.id === response.quantity_unit_id) || {
        id: '',
        name: '',
        type: ''
      },
      quantity: response.incoming_water_quantity,
      invoice_id: response.name,
      cups: '',
      type: 'waste_water_treatment',
      status: response.status,
      start_date: new Date(response.start_date).toDateString(),
      end_date: new Date(response.end_date).toDateString(),
      user: {
        first_name: response.uploaded_by
      },
      percentage: response.percentage,
      facility_percentages: response.facility_percentages ?? [],
      base_quantity: response.base_quantity ?? response.incoming_water_quantity
    };
    addInvoice(newInvoice);
    setLoadingButton(false);
  };

  const handleEditTreatment = async () => {
    if (!!handleErrors() || !user?.selectedOrganization || !user?.id || !id || !editInvoice) return;
    setLoadingButton(true);
    const response = await patchTreatment(id, formData, user.selectedOrganization);
    if (response?.response?.status === 422 && response?.response?.data === 'NEGATIVE_CH4') {
      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: 'negative_ch4',
          description: t('treatment.negativeCh4')
        })
      }));
    }

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }
    const newInvoice: InvoiceType = {
      id: response.id,
      unit: units.caudalUnits[0],
      quantity: response.incoming_water_quantity,
      invoice_id: response.name,
      cups: '',
      type: 'waste_water_treatment',
      status: response.status,
      start_date: new Date(response.start_date).toDateString(),
      end_date: new Date(response.end_date).toDateString(),
      user: {
        first_name: response.uploaded_by
      },
      percentage: response.percentage,
      facility_percentages: response.facility_percentages ?? [],
      base_quantity: response.base_quantity ?? response.incoming_water_quantity
    };
    editInvoice(id, newInvoice);
    setLoadingButton(false);
  };

  if (loadingData) return <CustomSkeletonLoader count={2} />;

  return (
    <>
      <FormWrapper>
        <InputText
          icon={'/images/icons/road.svg'}
          label={t('treatment.nameLabel')}
          placeholder={t('treatment.writeName')}
          value={formData.name}
          onChangeValue={onChangeValue('name')}
        />
        <InputNumber
          icon={'/images/icons/person.svg'}
          label={t('treatment.nPersonLabel')}
          placeholder={t('treatment.writenPerson')}
          value={formData.nPerson}
          onChangeValue={onChangeValue('nPerson')}
          error={formData.errors.find((error) => error.error === 'nPerson')}
        />
        <InputTextSelect
          icon={'/images/icons/wave.svg'}
          label={t('treatment.caudal')}
          placeholder={t('treatment.writeCaudal')}
          textValue={formData.caudalQuantity}
          onChangeTextValue={onChangeValue('caudalQuantity')}
          selectOptions={units.caudalUnits.map((unit) => ({
            ...unit,
            name: t(`units.${unit.name}`)
          }))}
          onChangeSelectOption={onChangeValue('caudalUnit')}
          selectOptionValue={formData.caudalUnit}
          textType='number'
          error={formData.errors.find(
            (error) => error.error === 'caudalQuantity' || error.error === 'caudalUnit'
          )}
        />
        <InputCalendar
          mode='range'
          label={t('treatment.date')}
          startDateValue={formData.startDate}
          handleChangeStartDate={onChangeValue('startDate')}
          endDateValue={formData.endDate}
          handleChangeEndDate={onChangeValue('endDate')}
          error={formData.errors.find(
            (error) => error.error === 'startDate' || error.error === 'endDate'
          )}
        />
        <InputTextSelect
          icon={'/images/icons/plus.svg'}
          label={t('treatment.dboQuantity')}
          placeholder={t('treatment.writeDboQuantity')}
          textValue={formData.dboQuantity}
          onChangeTextValue={onChangeValue('dboQuantity')}
          selectOptions={units.dboUnits.map((unit) => ({
            ...unit,
            name: t(`units.${unit.name}`)
          }))}
          onChangeSelectOption={onChangeValue('dboUnit')}
          selectOptionValue={formData.dboUnit}
          textType='number'
          error={formData.errors.find(
            (error) => error.error === 'dboQuantity' || error.error === 'dboUnit'
          )}
        />

        <InputTextSelect
          icon={'/images/icons/plus.svg'}
          label={t('treatment.dboOutQuantity')}
          placeholder={t('treatment.writeDboOutQuantity')}
          textValue={formData.dboOutQuantity}
          onChangeTextValue={onChangeValue('dboOutQuantity')}
          selectOptions={units.dboUnits.map((unit) => ({
            ...unit,
            name: t(`units.${unit.name}`)
          }))}
          onChangeSelectOption={onChangeValue('dboUnit')}
          selectOptionValue={formData.dboUnit}
          textType='number'
          error={formData.errors.find(
            (error) => error.error === 'dboOutQuantity' || error.error === 'dboUnit'
          )}
        />

        <InputTextSelect
          icon={'/images/icons/plus.svg'}
          label={t('treatment.recoveredMethane')}
          placeholder={t('treatment.writeRecoveredMethane')}
          textValue={formData.recoveredMethane}
          onChangeTextValue={onChangeValue('recoveredMethane')}
          selectOptions={units.recoveredMethaneUnits.map((unit) => ({
            ...unit,
            name: t(`units.${unit.name}`)
          }))}
          onChangeSelectOption={onChangeValue('recoveredMethaneUnit')}
          selectOptionValue={formData.recoveredMethaneUnit}
          textType='number'
          error={formData.errors.find(
            (error) => error.error === 'recoveredMethane' || error.error === 'recoveredMethaneUnit'
          )}
        />

        <InputNumber
          icon={'/images/icons/person.svg'}
          label={t('treatment.sludgeEvacuated')}
          placeholder={t('treatment.writeSludgeEvacuated')}
          value={formData.sludgeEvacuated}
          onChangeValue={onChangeValue('sludgeEvacuated')}
          error={formData.errors.find((error) => error.error === 'sludgeEvacuated')}
        />

        {flags?.discharges && (
          <>
            <FormSectionTitle width='5rem'>{t('treatment.dischargesTitle')}</FormSectionTitle>
            <InputTextSelect
              icon={'/images/icons/plus.svg'}
              label={t('treatment.caudalOutQuantity')}
              placeholder={t('treatment.writeCaudalOutQuantity')}
              textValue={formData?.caudalOutQuantity ?? ''}
              onChangeTextValue={onChangeValue('caudalOutQuantity')}
              selectOptions={units.caudalUnits.map((unit) => ({
                ...unit,
                name: t(`units.${unit.name}`)
              }))}
              onChangeSelectOption={onChangeValue('caudalUnit')}
              selectOptionValue={formData.caudalUnit}
              textType='number'
              error={formData.errors.find(
                (error) => error.error === 'caudalOutQuantity' || error.error === 'caudalUnit'
              )}
            />
            <InputTextSelect
              icon={'/images/icons/plus.svg'}
              label={t('treatment.nitrogenQuantity')}
              placeholder={t('treatment.writeNitrogenQuantity')}
              textValue={formData?.nitrogenQuantity ?? ''}
              onChangeTextValue={onChangeValue('nitrogenQuantity')}
              selectOptions={units.nitrogenUnits.map((unit) => ({
                ...unit,
                name: t(`units.${unit.name}`)
              }))}
              onChangeSelectOption={onChangeValue('nitrogenUnit')}
              selectOptionValue={formData.nitrogenUnit}
              textType='number'
              error={formData.errors.find(
                (error) => error.error === 'nitrogenQuantity' || error.error === 'nitrogenUnit'
              )}
            />
          </>
        )}
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('treatment.confirm')}
          onClick={addInvoice ? handleCreateTreatment : handleEditTreatment}
          loading={loadingButton}
        />
      </FormButtonSection>
      <ErrorText style={{ marginTop: '0.5rem' }}>
        {formData.errors.find((error) => error.error === 'negative_ch4')?.description}
      </ErrorText>
    </>
  );
};

export default Treatment;
