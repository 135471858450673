import { useTranslation } from 'react-i18next';

import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import '../styles.scss';

function StepThanksPepeJeans() {
  const { t } = useTranslation();

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitleThankPepeJeans')}
      subtitle={t('employeeForm.formSubtitleThankPepeJeans')}
    />
  );
}

export default StepThanksPepeJeans;
