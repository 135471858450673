import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeReport, ExtraInformationType } from '../../../../../types/entities/employee';
import numberToDecimal from '../../../../../utils/numberToDecimal';
import Icon from '../../../../ui/icon/Icon';
import ReportTable from '../../../components/reportTable/ReportTable';
import CardHeader from '../../cardHeader/CardHeader';
import CardTotalCo2 from '../../cardTotalCo2/CardTotalCo2';
import ExtraInformation from './components/extraInformation/ExtraInformation';
import LineChart from './components/lineChart/LineChart';
import useGetParsedData from './hooks/getParseData';
import './styles.scss';
import { getIndexHalfEmission } from './utils/getPercentageHalfEmissions';
import getTotalCo2e from './utils/getTotalCo2e';
import groupByTransportType from './utils/groupByTransportType';

type Props = {
  employees: EmployeeReport[];
  extraInformation: ExtraInformationType;
};
function Employees({ employees, extraInformation }: Props) {
  const LIST_SIZE = 4;
  const { t } = useTranslation();
  const { parseData } = useGetParsedData(LIST_SIZE);
  const totalCo2 = useMemo(() => getTotalCo2e(employees), [employees]);
  const employeesGroupedByTranport = useMemo(
    () => groupByTransportType(employees, totalCo2),
    [employees]
  );

  const header = [
    {
      key: 'name',
      name: t('report.employeesSection.name')
    },
    {
      key: 'nEmployees',
      name: t('report.employeesSection.nEmployees')
    },
    {
      key: 'co2',
      name: t('report.employeesSection.co2')
    },
    {
      key: 'total',
      name: t('report.employeesSection.total')
    }
  ];
  const tableData = parseData(employeesGroupedByTranport);
  const indexHalfEmission = getIndexHalfEmission(employees, totalCo2);

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <div className='header-wrapper'>
          <CardHeader
            title={t('report.employees')}
            description={t('report.employeesDescription')}
          />
          <ExtraInformation {...extraInformation} />
        </div>

        <CardTotalCo2 totalCo2={totalCo2} />
        <ReportTable data={tableData} header={header} />
      </div>
      <div className='card main-bg-color'>
        <div className='scope-tag'>
          <div className='text-tag font-10 tag-bg-text-color tag'>{`${t(
            'measureMain.scope'
          )} 3`}</div>
        </div>
        <div className='right-side'>
          <div className='line-chart'>
            <span className='body2-font'>{t('report.distribution')}</span>
            <div className='line-chart-wrapper on-card-gray-bg-color'>
              <LineChart
                index={indexHalfEmission}
                chartData={employees
                  ?.sort((a, b) => b.co2e - a.co2e)
                  ?.map((employee) => employee.co2e)}
              />
            </div>
          </div>
          <div className='warning-wrapper body1-font tag-bg-text-color' style={{ width: '100%' }}>
            <p>
              <Icon icon='warning' color='gradient' />
              {t('report.employeesSection.improveMovility', {
                total: numberToDecimal((indexHalfEmission / employees.length) * 100)
              })}
            </p>
          </div>
          {/* <div className='rest-info-wrapper'>
            <InfoLabel
              img={{
                src: '/images/icons/pin.svg',
                alt: 'localization'
              }}
              title={t('report.employeesSection.totalEmployees')}
              data={employees.length}
            />
            <InfoLabel
              img={{
                src: '/images/icons/plane.svg',
                alt: 'localization'
              }}
              title={t('report.employeesSection.co2PerEmployee')}
              data={
                Number.isNaN(totalCo2 / employees.length)
                  ? 0
                  : numberToDecimal(totalCo2 / employees.length)
              }
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Employees;
