import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../../../types/entities/user';
import apiFetch from '../../../../../utils/apiFetch';
import Button from '../../../../ui/button/Button';
import './styles.scss';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';

type Props = {
  deleteRequest: (id: string) => void;
  requestToDelete: string;
  user: User | null;
};
function DeleteRequest({ user, requestToDelete, deleteRequest }: Props) {
  const { t } = useTranslation();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteRequest();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteRequest = async () => {
    try {
      setLoadingButton(true);
      await apiFetch(
        'DELETE',
        `/invite_users/email/${requestToDelete}/organization/${user?.selectedOrganization}`,
        null,
        {
          'x-organization-id': user?.selectedOrganization
        }
      );
      deleteRequest(requestToDelete);
      setLoadingButton(false);
    } catch (err) {
      setLoadingButton(false);
    }
  };

  return (
    <div className='delete-modal'>
      <FormHeader
        title={t('companies.deleteRequest')}
        description={t('companies.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('companies.delete')}
          onClick={handleDeleteRequest}
          loading={loadingButton}
          size='small'
        />
      </FormButtonSection>
    </div>
  );
}

export default DeleteRequest;
