import { useQuery } from '@tanstack/react-query';
import { getNodeInputs } from '../../../../services/api/lca/lcaPortfolio';

type Params = {
  node_id?: string;
  acv_id?: string;
};

export const useNodeInputs = ({ acv_id, node_id }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['lca-node-inputs', acv_id, node_id],
    queryFn: () => {
      if (!acv_id || !node_id) {
        return;
      }

      return getNodeInputs({ acv_id, node_id });
    },
    select: (data) => {
      return data?.map((piece) => {
        return {
          id: piece.material_id,
          material_id: piece.material_id,
          name: piece.name,
          country: piece.country,
          code: piece.code,
          location: piece.location,
          impact_source_id: piece.impact_source_id,
          supplier: piece.supplier,
          quantity: piece.quantity.toString(),
          unit: piece.unit,
          unit_id: piece.unit_id,
          type: piece.type,
          unit_list: piece.unit_list
        };
      });
    },
    enabled: !!acv_id && !!node_id
  });

  return { data, isLoading };
};
