import { getFacilitiesPaginated } from '../../../../../../../services/api/facility';
import { IFilter } from '../../../../../../../utils/url';
import { Facility } from '../../../../../../../types/entities/facility';
import useFetchInfiniteList from '../../../../../../ui/infiniteList/hooks/useFetchInfiniteList';

type Props = {
  searchValue?: string;
};
const useFetchFacilities = ({ searchValue }: Props) => {
  const fetchData = async (pageNumber: number) => {
    const filters: IFilter[] = [];
    if (searchValue) {
      filters.push({
        field: 'name',
        value: searchValue,
        type: 'lk'
      });
    }
    const data = await getFacilitiesPaginated(pageNumber, filters);

    return data;
  };

  const {
    data,
    fetchData: fetch,
    firstLoading,
    loading,
    page
  } = useFetchInfiniteList<Facility>(fetchData, [searchValue]);

  return { data, fetch, firstLoading, loading, page };
};

export default useFetchFacilities;
