import React from 'react';
import './styles.scss';
type Legend = {
  name: string;
  icon: string;
};
type Props = {
  legends: Legend[];
  children: React.ReactNode;
};
const LineChartWrapper = ({ legends, children }: Props) => {
  return (
    <div className='on-card-gray-bg-color line-chart-wrapper'>
      {legends.length > 0 && (
        <div className='legend-wrapper'>
          {legends.map((elem) => (
            <div className='legend' key={elem.name}>
              <img src={elem.icon} />
              <span className='body1-bold-font'>{elem.name}</span>
            </div>
          ))}
        </div>
      )}
      <div className='chart-wrapper'>{children}</div>
    </div>
  );
};

export default LineChartWrapper;
