import { useTranslation } from 'react-i18next';
import { patchFund } from '../../../../../services/api/funds';
import { FormFundData, FundType } from '../../../../../types/entities/funds';
import CustomSkeletonLoader from '../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import FormFund from '../formFund/FormFund';
import useGetData from './hooks/useGetData';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useEffect, useState } from 'react';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../ui/button/Button';
import ErrorText from '../../../../ui/errorText/ErrorText';
import checkFormErrors from '../../../../../utils/checkFormErrors';

type Props = {
  id: string;
  editFundData: (value: FundType) => void;
};

const EditFund = ({ editFundData, id }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'funds' });
  const { t: tErrors } = useTranslation('translation', { keyPrefix: 'error' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const { fundData, loading } = useGetData({ id });

  const [formData, setFormData] = useState<FormFundData>(fundData);
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState<ErrorType>();

  useEffect(() => {
    setFormData(fundData);
  }, [JSON.stringify(fundData)]);

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));

    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleEditFund = async () => {
    if (handleErrors()) return;
    setLoadingButton(true);
    const response = await patchFund(id, formData);
    setLoadingButton(false);
    if (response?.response?.status >= 400) {
      setError({
        error: 'general',
        description: tErrors('somethingWentWrong')
      });
      return;
    }
    editFundData(response);
  };

  return (
    <>
      <FormHeader title={t('titleEdit')} description={t('subtitleEdit')} />
      {loading ? (
        <CustomSkeletonLoader count={4} style={{ marginBottom: '1rem' }} />
      ) : (
        <FormFund formDataFilled={fundData} formData={formData} setFormData={setFormData} />
      )}
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={tGeneral('save')}
          onClick={handleEditFund}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText style={{ marginTop: '0.5rem' }}>{error.description}</ErrorText>}
    </>
  );
};

export default EditFund;
