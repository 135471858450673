import { ISecondaryCodeBackend, ISecondaryCodeFront } from '../../../../../../types/secondaryCode';

export const secondaryCodeAdapter = (
  backendResponse: ISecondaryCodeBackend[]
): ISecondaryCodeFront[] => {
  return backendResponse.map((item) => {
    return {
      id: item.id,
      code: item.code,
      framework: item.framework,
      status: item.status,
      primaryCode: item.primary_code?.code ?? '',
      codeType: item.code_type
    };
  });
};

export const secondaryCodePatchAdapter = (data: Partial<ISecondaryCodeFront>) => {
  return {
    framework: data.framework,
    status: data.status,
    code_type: data.codeType,
    code: data.code,
    primary_code: data.primaryCode
  };
};
