import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { updateIntensityMetricGroup } from '../../../../services/api/intensityMetrics';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import InputText from '../../../ui/formComponents/inputText/InputText';
import Select from '../../../ui/formComponents/select/Select';
import useScopeOptions from '../useScopeOptions';

type FormData = {
  scopes: SelectOptionFormat;
  activityVolume: string;
  errors: ErrorType[];
};

const customInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big'
};

type Props = {
  intensityMetricGroup: IntensityMetricGroup;
  editIntensityMetricGroup: (intensityMetricGroup: IntensityMetricGroup) => void;
};
const EditIntensityMetricGroup = ({ intensityMetricGroup, editIntensityMetricGroup }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormData>({
    scopes: intensityMetricGroup.scopes
      ? {
          id: intensityMetricGroup.scopes.join(','),
          name: t(`createIntensityMetricGroup.${intensityMetricGroup.scopes.join(',')}`)
        }
      : {
          id: '',
          name: ''
        },
    activityVolume: intensityMetricGroup.unit || '',
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const scopeOptions = useScopeOptions();

  const handleEdit = async () => {
    const optionalFields: string[] = ['isGrouping'];
    // Validate form with errors
    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    // Call api to create
    setLoadingButton(true);
    const updatedIntensityMetricGroup = await updateIntensityMetricGroup(
      intensityMetricGroup.id,
      {
        scopes: formData.scopes.id.split(',').map((elem) => parseInt(elem, 10)),
        unit: formData.activityVolume,
        name: formData.activityVolume
      },
      user?.selectedOrganization ?? ''
    );

    setLoadingButton(false);
    // Callback to close modal and add new item to list
    editIntensityMetricGroup({
      ...intensityMetricGroup,
      scopes: formData.scopes.id.split(',').map((elem) => parseInt(elem, 10)),
      unit: formData.activityVolume
    });
  };

  return (
    <div className='create-intensity-metric-group'>
      <FormHeader
        title={t('editIntensityMetricGroup.title')}
        description={t('editIntensityMetricGroup.description')}
      />
      <FormWrapper>
        <Select
          {...customInputProps}
          icon='/images/icons/catalog.svg'
          label={t('createIntensityMetricGroup.scopes')}
          placeholder={t('createIntensityMetricGroup.selectOption')}
          options={scopeOptions}
          value={formData.scopes}
          onChangeValue={onChangeValue('scopes')}
          error={formData.errors.find((elem) => elem.error === 'scopes')}
          tooltipText={t('createIntensityMetricGroup.tooltipScopes')}
          disabled={intensityMetricGroup.is_grouping}
        />
        <InputText
          {...customInputProps}
          icon={'/images/icons/intensityMetric.svg'}
          label={t('createIntensityMetricGroup.activityVolume')}
          placeholder={t('createIntensityMetricGroup.writeActivityVolume')}
          value={formData.activityVolume}
          onChangeValue={onChangeValue('activityVolume')}
          error={formData.errors.find((elem) => elem.error === 'activityVolume')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('editIntensityMetricGroup.edit')}
          size='medium'
          onClick={handleEdit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default EditIntensityMetricGroup;
