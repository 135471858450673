import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import './styles.scss';

type Props = {
  steps: ReactNode[];
  step: number;
  showNext?: boolean;
};
function StepController({ steps, step, showNext = true }: Props) {
  return (
    <AnimatePresence>
      <motion.div initial={{ y: '300px', opacity: 0 }} animate={{ y: 0, opacity: 1 }}>
        <motion.div
          initial={{ opacity: 1 }}
          exit={{
            opacity: 0,
            transition: { duration: 0.3 }
          }}
          layout
          key={`step-${step}-and-step-${step + 1}`}>
          {steps[step - 1]}
        </motion.div>
        {/* {showNext ? (
          <motion.div
            className='disabled-opacity'
            initial={{ opacity: 0.5 }}
            exit={{
              opacity: 1,
              transition: { duration: 0.3 }
            }}
            key={step + 1}>
            {steps[step]}
          </motion.div>
        ) : null} */}
      </motion.div>
    </AnimatePresence>
  );
}

export default StepController;
