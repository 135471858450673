import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterInfo2 } from '../../../types/entities/user';

import StepSelector from '../../ui/stepPage/templateSteps/StepSelector';
type Props = {
  nextStep: (value: RegisterInfo2) => void;
  stepNumber: number;
  registerInfo: RegisterInfo2;
};
const StepHolding = ({ nextStep, stepNumber, registerInfo }: Props) => {
  const { t } = useTranslation();
  const [holdingOption, setHoldingOption] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });

  const onChangeHoldingOption = (option: SelectOptionFormat) => {
    setHoldingOption(option);
  };

  const handleNextStep = () => {
    nextStep({
      ...registerInfo,
      sector: holdingOption.id === 'yes' ? 'holding' : ''
    });
  };

  return (
    <StepSelector
      nextStep={handleNextStep}
      stepNumber={stepNumber}
      title={t('onboardingUser.stepHolding.title')}
      //   tooltip={t('onboardingUser.stepHolding.tooltip')}
      buttonText={t('onboardingUser.stepHolding.button')}
      placeholder={t('onboardingUser.stepHolding.placeholder')}
      onChangeValue={onChangeHoldingOption}
      value={holdingOption}
      options={[
        {
          id: 'yes',
          name: t('onboardingUser.stepHolding.yes')
        },
        {
          id: 'no',
          name: t('onboardingUser.stepHolding.no')
        }
      ]}
      icon={'/images/icons/holding.svg'}
    />
  );
};

export default StepHolding;
