import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardLogistic.categoryColumns' });
  return [
    {
      title: t('category'),
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('numberOfTrips')}</div>,
      dataIndex: 'numberOfTrips',
      key: 'numberOfTrips'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('kms')}</div>,
      dataIndex: 'kms',
      key: 'kms'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('kgCO2e')}</div>,
      dataIndex: 'kgCO2e',
      key: 'kgCO2e'
    }
  ];
};

export default useColumns;
