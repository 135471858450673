import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATADIS_HOW_TO } from '../../../../constants/index';
import useErrors from '../../../../customHooks/useErrors';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import {
  getDatadisNifAuthorization,
  postDatadisConnection
} from '../../../../services/api/datadis';
import { FormDataDatadisConnection } from '../../../../types/utils/datadisConnection';

import InformationCard from '../../../ui/InformationCard/InformationCard';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import Modal from '../../../ui/modal/Modal';
import Confirmation from './Confirmation';
import Form from './Form';

type Props = {
  addAuthorizedNif: (nif: string) => void;
  firstNif: boolean;
};

const AddDatadisConnection = ({ addAuthorizedNif, firstNif }: Props) => {
  const { t, i18n } = useTranslation();
  const ERRORS = useErrors();
  const selectedOrganization = useSelectedOrganization();

  const DATADIS_HOW_TO_TRANSLATED =
    i18n.resolvedLanguage === 'es' ? DATADIS_HOW_TO.es : DATADIS_HOW_TO.en;

  const [errorRegistration, setErrorRegistration] = useState<ErrorType | null>(null);
  const [errorConfirmation, setErrorConfirmation] = useState<ErrorType | null>(null);
  const [confirmedNif, setConfirmedNif] = useState<string>('');

  const [showIsNifCorrect, setShowIsNifCorrect] = useState(false);
  const handleHideIsNifCorrect = () => setShowIsNifCorrect(false);

  const formDataFilled: FormDataDatadisConnection = {
    nif: firstNif && selectedOrganization?.vat ? selectedOrganization?.vat : '',
    errors: []
  };

  const handleAdd = async (formData: FormDataDatadisConnection) => {
    if (!selectedOrganization) return;

    setErrorRegistration(null);
    const response = await getDatadisNifAuthorization(formData.nif, selectedOrganization.id);

    if (response?.response?.status >= 400) {
      setErrorRegistration(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    if (response?.status === 'unauthorized') {
      setErrorRegistration(ERRORS.DATADIS.NOT_REGISTERED);
      return;
    }

    if (response?.response?.status === 504) {
      setErrorRegistration(ERRORS.DATADIS.TIMEOUT);
      return;
    }

    setConfirmedNif(response.authorized_nif);
    setShowIsNifCorrect(true);
  };

  const handleConfirm = async () => {
    if (!confirmedNif || !selectedOrganization) return;

    setErrorConfirmation(null);
    const response = await postDatadisConnection(confirmedNif, selectedOrganization.id);

    if (response?.response?.status === 409) {
      setErrorConfirmation(ERRORS.DATADIS.ALREADY_REGISTERED);
      return;
    }

    if (response?.response?.status >= 400) {
      setErrorConfirmation(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    if (response?.response?.status === 504) {
      setErrorConfirmation(ERRORS.DATADIS.TIMEOUT);
      return;
    }

    handleHideIsNifCorrect();
    addAuthorizedNif(response.identifier);
  };

  return (
    <div className='new-fund'>
      <FormHeader
        title={t('datadisConnectionAdd.title')}
        description={t('datadisConnectionAdd.subtitle')}
      />
      <InformationCard
        image='/images/howTo/datadis.svg'
        description={t('datadisConnection.howTo')}
        url={DATADIS_HOW_TO_TRANSLATED}
      />
      <Form
        handleSubmit={handleAdd}
        error={errorRegistration}
        formDataFilled={formDataFilled}
        firstNif
      />
      <Modal show={showIsNifCorrect} onClose={handleHideIsNifCorrect}>
        <Confirmation
          handleConfirm={handleConfirm}
          handleClose={handleHideIsNifCorrect}
          nif={confirmedNif}
          error={errorConfirmation}
        />
      </Modal>
    </div>
  );
};

export default AddDatadisConnection;
