import { HTMLAttributes, ReactNode, ReactSVGElement, cloneElement } from 'react';
import { highlight_color, highlight_color2 } from '../../../../styles/colors';
import { getIconPx } from '../utils/getIconPx';
import { IconSize } from '../../icon/Icon';
import { getViewBox } from '../../../../utils/getViewBox';

interface Props extends HTMLAttributes<SVGElement> {
  icon: ReactNode;
  size: IconSize;
}

const SvgGradient = ({ icon, size, ...rest }: Props) => {
  const sizePx = getIconPx(size);
  const viewBox = getViewBox(size);

  return (
    <svg width={sizePx} height={sizePx} {...rest}>
      {/* Define the linear gradient */}
      <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
        <stop offset='0%' style={{ stopColor: highlight_color }} />
        <stop offset='100%' style={{ stopColor: highlight_color2 }} />
      </linearGradient>

      {cloneElement(icon as ReactSVGElement, {
        fill: 'url(#gradient)',
        width: '100%',
        height: '100%',
        viewBox
      })}
    </svg>
  );
};

export default SvgGradient;
