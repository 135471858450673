import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { updateEvent } from '../../../../services/api/events';
import { EventType } from '../../../../types/entities/events';

import checkFormErrors from '../../../../utils/checkFormErrors';
import { convertStringToDate } from '../../../../utils/convertDates';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import InputCalendar from '../../../ui/formComponents/inputCalendar/InputCalendar';
import InputGoogleMaps from '../../../ui/formComponents/inputGoogleMaps/InputGoogleMaps';
import InputText from '../../../ui/formComponents/inputText/InputText';
import Select from '../../../ui/formComponents/select/Select';
import useLocations from '../useLocations';

type Props = {
  editEvent: (event: EventType) => void;
  eventToEdit: EventType;
};
const customInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big'
};
type FormData = {
  name: string;
  start_date: string;
  end_date: string;
  location_type: SelectOptionFormat;
  location: string;
  errors: ErrorType[];
};
const EditEvent = ({ editEvent, eventToEdit }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormData>({
    name: eventToEdit.name ?? '',
    start_date: eventToEdit.start_date
      ? moment(new Date(eventToEdit.start_date)).format('DD/MM/YYYY')
      : moment(new Date()).format('DD/MM/YYYY'),
    end_date: eventToEdit.end_date
      ? moment(new Date(eventToEdit.end_date)).format('DD/MM/YYYY')
      : moment(new Date()).format('DD/MM/YYYY'),
    location_type: {
      id: eventToEdit.location_type,
      name: t(`events.${eventToEdit.location_type}`)
    },
    location: eventToEdit.location,
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const locationTypeOptions = useLocations();

  const handleDateError = (error?: ErrorType) => {
    if (!error) {
      const newErrors = formData.errors.filter((elem) => elem.error !== 'date');
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    if (formData.errors.some((elem) => elem.error === 'date')) return;

    setFormData((prev) => ({ ...prev, errors: [...prev.errors, error] }));
  };

  const handleEdit = async () => {
    const optionalFields: string[] = [];
    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    setLoadingButton(true);

    const updatedEvent = {
      name: formData.name,
      location_type: formData.location_type.id,
      location: formData.location,
      start_date: new Date(moment(convertStringToDate(formData.start_date)).format('YYYY-MM-DD')),
      end_date: new Date(moment(convertStringToDate(formData.end_date)).format('YYYY-MM-DD'))
    };

    try {
      // Call api to add event

      const eventResponse = await updateEvent(
        eventToEdit.id,
        updatedEvent,
        user?.selectedOrganization ?? ''
      );
      setLoadingButton(false);

      // Get response from api and add it to the list
      editEvent({
        ...updatedEvent,
        id: eventToEdit.id,
        co2e_pre: eventResponse.co2e_pre,
        co2e_post: eventResponse.co2e_post,
        start_date: eventResponse.start_date,
        end_date: eventResponse.end_date,
        country: 'ES'
      });
    } catch (error) {
      setLoadingButton(false);
    }
  };
  // 10 years from now
  const maxEndDate = new Date();
  maxEndDate.setFullYear(maxEndDate.getFullYear() + 10);
  return (
    <div className='create-event'>
      <FormHeader title={t('eventForm.editTitle')} description={t('eventForm.description')} />
      <FormWrapper>
        <InputText
          {...customInputProps}
          icon={'/images/icons/pin50.svg'}
          label={t('eventForm.name')}
          placeholder={t('eventForm.writeName')}
          value={formData.name}
          onChangeValue={onChangeValue('name')}
          error={formData.errors.find((error) => error.error === 'name')}
        />
        <InputCalendar
          {...customInputProps}
          mode='range'
          label={t('eventForm.date')}
          handleChangeStartDate={onChangeValue('start_date')}
          handleChangeEndDate={onChangeValue('end_date')}
          startDateValue={formData.start_date}
          endDateValue={formData.end_date}
          handleDateError={handleDateError}
          maxStartDate={maxEndDate}
          maxEndDate={maxEndDate}
          error={formData.errors.find(
            (elem) =>
              elem.error === 'startDate' || elem.error === 'endDate' || elem.error === 'date'
          )}
          allowSameDate
        />
        <Select
          {...customInputProps}
          icon={'/images/icons/map.svg'}
          label={t('eventForm.locationType')}
          placeholder={t('eventForm.selectLocationType')}
          options={locationTypeOptions}
          value={formData.location_type}
          onChangeValue={onChangeValue('location_type')}
          error={formData.errors.find((error) => error.error === 'location_type')}
          sort={false}
        />
        <InputGoogleMaps
          {...customInputProps}
          icon={'/images/icons/pin50.svg'}
          label={t('eventForm.location')}
          placeholder={t('eventForm.writeLocation')}
          value={formData.location}
          onChangeValue={onChangeValue('location')}
          onSelectMapsValue={onChangeValue('location')}
          error={formData.errors.find((error) => error.error === 'location')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('eventForm.create')}
          size='medium'
          onClick={handleEdit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default EditEvent;
