import React from 'react';
import useGetData from './hooks/useGetData';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../ui/modal/Modal';
import FormSectionTitle from '../../../../ui/formComponents/formSectionTitle/FormSectionTitle';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';

type Props = {
  id: string;
  name: string;
};
const UsageMetrics = ({ id, name }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.usageMetrics' });
  const data = useGetData({ id });
  return (
    <>
      <Modal.Header title={t('title', { name })} />
      <Modal.Content>
        <FormSectionTitle width='6rem'>
          <span style={{ whiteSpace: 'nowrap' }}>{t('metrics')}</span>
        </FormSectionTitle>
        <FormWrapper>
          <FormText label={t('facilities')} disabled value={data?.facilities.toString()} />
          <FormText label={t('vehicles')} disabled value={data?.vehicles.toString()} />
          <FormText label={t('employees')} disabled value={data?.employees.toString()} />
          <FormText
            label={t('business_travels')}
            disabled
            value={data?.business_travels.toString()}
          />
          <FormText label={t('purchases')} disabled value={data?.purchases.toString()} />
          <FormText label={t('shipments')} disabled value={data?.transport_downstream.toString()} />
          <FormText label={t('deliveries')} disabled value={data?.transport_upstream.toString()} />
        </FormWrapper>
        <FormSectionTitle>{t('limits')}</FormSectionTitle>
        <FormWrapper>
          {data &&
            Object.keys(data.limits).map((key: string) => (
              <FormText
                key={key}
                label={t(key)}
                disabled
                value={data.limits[
                  key as
                    | 'facilities'
                    | 'vehicles'
                    | 'employees'
                    | 'business_travels'
                    | 'users'
                    | 'shipments'
                    | 'deliveries'
                    | 'purchases'
                ].toString()}
              />
            ))}
        </FormWrapper>
      </Modal.Content>
    </>
  );
};

export default UsageMetrics;
