import { useTranslation } from 'react-i18next';
import Tag from '../../../../ui/tag/Tag';

type Props = {
  total: number;
  error: number;
  pending: number;
  success: number;
  loading: boolean;
};

const Legend = ({ total, error, pending, success, loading = true }: Props) => {
  const { t } = useTranslation();

  return (
    <Tag
      tags={[
        {
          text: <span className='on-light-text-color tag-font'>Total</span>
        },
        {
          text: (
            <div className='total-wrapper'>
              <span className='highlight-text-color tag-bigger-font'>{loading ? '-' : total}</span>
              <span className='on-light-text-color tag-font'>{t(`uploadedFiles.total`)}</span>
              <>
                <span className='highlight-text-color tag-bigger-font'>
                  {loading ? '-' : pending}
                </span>
                <span className='on-light-text-color tag-font'>{t(`status.pending`)}</span>
              </>

              <>
                <span className='highlight-text-color tag-bigger-font'>
                  {loading ? '-' : error}
                </span>
                <span className='on-light-text-color tag-font'>{t(`status.error`)}</span>
              </>

              <>
                <span className='highlight-text-color tag-bigger-font'>
                  {loading ? '-' : success}
                </span>
                <span className='on-light-text-color tag-font'>{t(`status.success`)}</span>
              </>
            </div>
          )
        }
      ]}
    />
  );
};

export default Legend;
