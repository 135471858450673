import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const ShadowBackground = ({ className, ...rest }: Props) => (
  <div className={`modal-wrapper modal-bg-color ${className}`} {...rest} />
);

const ShadowBackgroundWithPortal = ({ className, ...rest }: Props) => (
  <>
    <div className={`modal-wrapper-with-portal ${className}`} {...rest} />
    <div className='modal-bg-color modal-bg' />
  </>
);

ShadowBackground.WithPortal = ShadowBackgroundWithPortal;

export default ShadowBackground;
