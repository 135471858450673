export function getWeekDays(t) {
  const cards = [
    {
      id: 'monday',
      icon: '',
      checkbox: true,
      title: t('employees.monday')
    },
    {
      id: 'tuesday',
      icon: '',
      checkbox: true,
      title: t('employees.tuesday')
    },
    {
      id: 'wednesday',
      icon: '',
      checkbox: true,
      title: t('employees.wednesday')
    },
    {
      id: 'thursday',
      icon: '',
      checkbox: true,
      title: t('employees.thursday')
    },
    {
      id: 'friday',
      icon: '',
      checkbox: true,
      title: t('employees.friday')
    },
    {
      id: 'saturday',
      icon: '',
      checkbox: true,
      title: t('employees.saturday')
    },
    {
      id: 'sunday',
      icon: '',
      checkbox: true,
      title: t('employees.sunday')
    }
  ];
  return cards;
}
