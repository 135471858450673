import { IconName } from '../../../../types/utils/iconName';
import Icon from '../../icon/Icon';
import Label from '../../label/Label';
import '../styles.scss';
type Props = React.ComponentProps<typeof Label> & {
  icon?: IconName;
  showIcon?: boolean;
};

const DisabledLabel = ({ children, showIcon = true, icon = 'locked', ...rest }: Props) => {
  return (
    <Label {...rest} lookAndFeel='disabled'>
      <div className='label-loading-wrapper disabled-text-color'>
        {showIcon && <Icon icon={icon} color='disabled' size='medium' />}
        {children}
      </div>
    </Label>
  );
};

export default DisabledLabel;
