import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import apiFetch from 'utils/apiFetch';
const VEHICLES_VALIDATION_ENDPOINT = '/data-validations/validate-vehicles';

export const getVehicle = async (vehicle_id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/vehicles/${vehicle_id}`,
      {},
      { 'x-organization-id': organization_id }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getVehicles = async (organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      '/vehicles',
      {},
      { 'x-organization-id': organization_id }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

type VehicleBody = {
  known_vehicle_id: string | null;
  unknown_vehicle_id: string | null;
  vehicle_fuel_id: string | null;
  type: string;
  name: string;
  ownership: string;
  license_plate?: string;
  is_known: boolean;
  organization_id: string;
  country: string;
  status?: string;
};

export const createVehicle = async (vehicle: VehicleBody, organization_id: string) => {
  try {
    const response = await apiFetch('POST', '/vehicles', vehicle, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (err: any) {
    const errorMessage = err.response.data;
    if (errorMessage.includes('Vehicle with license plate')) {
      return errorMessage;
    }
    return null;
  }
};

type VehicleBodyPatch = {
  known_vehicle_id?: string;
  unknown_vehicle_id?: string;
  vehicle_fuel_id?: string;
  type?: string;
  name?: string;
  ownership?: string;
  license_plate?: string;
  is_known?: boolean;
  organization_id?: string;
  country?: string;
  status?: string;
};
export const updateVehicle = async (vehicle_id: string, vehicle: VehicleBodyPatch) => {
  try {
    const response = await apiFetch('PATCH', `/vehicles/${vehicle_id}`, vehicle);
    return response.data;
  } catch (err: any) {
    const errorMessage = err.response.data;
    if (errorMessage.includes('Vehicle with license plate')) {
      return errorMessage;
    }
    return null;
  }
};

export const deleteVehicle = async (vehicle_id: string, organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/vehicles/${vehicle_id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getKnownVehicles = async (brand: string) => {
  try {
    const response = await apiFetch('GET', `/known_vehicles/${brand}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getUnknownVehicles = async () => {
  const response = await apiFetch('GET', '/unknown_vehicles');
  return response.data;
};

export const getVehicleFuels = async (country?: string, vehicle_type?: string) => {
  try {
    const queryParams: {
      country?: string;
      vehicle_type?: string;
    } = {};
    if (country) {
      queryParams.country = country;
    }
    if (vehicle_type) {
      queryParams.vehicle_type = vehicle_type;
    }
    const response = await apiFetch('GET', '/vehicle_fuels', null, null, queryParams);
    return response.data;
  } catch (err) {
    return null;
  }
};

type VehicleFuelV2 = {
  id: string;
  fuel: string;
  country: string;
  vehicle_types: string[];
  units: Unit[];
};

export const getVehicleFuelsV2 = async (country?: string, vehicle_type?: string) => {
  const response = await apiFetch(
    'GET',
    getUrl('/vehicle_fuels', {
      queryParams: generateQueryParamsFromObject({ country, vehicle_type })
    })
  );

  return response.data as VehicleFuelV2[];
};

export const getVehiclesValidation = async (
  startDate: number,
  endDate: number,
  listVehiclesId: [string]
) => {
  try {
    const response = await apiFetch(
      'GET',
      `${VEHICLES_VALIDATION_ENDPOINT}?start_date=${startDate}&end_date=${endDate}&list_vehicle_ids=${listVehiclesId.join(
        ','
      )}`
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getLicensePlates = async () => {
  try {
    const response = await apiFetch('GET', '/vehicles/license-plates');
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getPresignedUrlVehicles = async (file_name: string) => {
  try {
    const response = await apiFetch('POST', '/vehicles/bulk/csv', {
      file_name
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
