import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('companies.company'),
      dataIndex: 'company_name',
      key: 'company_name'
    },
    {
      title: t('companies.country'),
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: t('companies.companyValue'),
      dataIndex: 'enterprise_value',
      key: 'enterprise_value'
    },
    {
      title: t('companies.investmentValue'),
      dataIndex: 'investment_value',
      key: 'investment_value'
    },
    // {
    //   title: t('companies.IsDebtFinancing'),
    //   dataIndex: 'debt_financing',
    //   key: 'debt_financing'
    // },
    {
      title: t('companies.investmentDate'),
      dataIndex: 'investment_date',
      key: 'investment_date'
    },
    {
      title: t('companies.tag'),
      dataIndex: 'tag',
      key: 'tag'
    },
    {
      title: 'CO₂ eq.',
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  return columns;
};

export default useColumns;
