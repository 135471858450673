import { useState } from 'react';
import useErrors from '../../../../customHooks/useErrors';
import FormHeader from '../../formComponents/formHeader/FormHeader';
import FormButtonSection from '../../formComponents/formButtonSection/FormButtonSection';
import ErrorText from '../../errorText/ErrorText';
import { useTranslation } from 'react-i18next';
import Button from '../../button/Button';

type Props = {
  id: string;
  title?: string;
  description?: string;
  handleDelete: (id: string) => void;
  onClose: () => void;
  deleteElement: (id: string) => Promise<boolean | null>;
};

const DeleteModal = ({ id, title, description, handleDelete, onClose, deleteElement }: Props) => {
  const { t } = useTranslation('', { keyPrefix: 'deleteModal' });
  const ERRORS = useErrors();

  title = title || t('title');
  description = description || t('description');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const submitDelete = async () => {
    if (loading) return;
    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await deleteElement(id);

    setLoading(false);

    if (!response) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    handleDelete(id);
    onClose();
  };

  return (
    <div className='delete-modal'>
      <FormHeader title={title} description={description} />
      <FormButtonSection>
        <Button lookAndFeel={'cancel'} size='medium' text={t('cancel')} onClick={onClose} />
        <Button lookAndFeel='warning' text={t('delete')} onClick={submitDelete} loading={loading} />
      </FormButtonSection>
      {error && (
        <ErrorText
          style={{
            marginTop: '0.5rem'
          }}>
          {error.description}
        </ErrorText>
      )}
    </div>
  );
};

export default DeleteModal;
