import { useFlags } from 'launchdarkly-react-client-sdk';
import useSelectedOrganization from './useSelectedOrganization';
import {
  filterTreatmentCategory,
  groupByCategory as groupByCategoryFunction
} from '../constants/groupByCategoryFunction';
import { WATER_WASTE_SUPPLY_SECTOR } from '../constants/sectors';

const useTreatmentSector = () => {
  const flags = useFlags();
  const { sector } = useSelectedOrganization() || {};
  const isTreatmentOrganization = sector === WATER_WASTE_SUPPLY_SECTOR;

  let groupByCategory = groupByCategoryFunction(flags);
  if (!isTreatmentOrganization) {
    groupByCategory = filterTreatmentCategory(groupByCategory);
  }
  return {
    isTreatmentOrganization,
    flags,
    groupByCategory
  };
};

export default useTreatmentSector;
