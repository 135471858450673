import { useTranslation } from 'react-i18next';
import useErrors from '../../../../customHooks/useErrors';
import Form from '../form/Form';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import { useState } from 'react';
import useGetCustomEFGroup from './hooks/useGetCustomEFGroup';
import { patchCustomEFGroup } from '../../../../services/api/customEF';
import useAdapterCustomEFGroup from '../../../../adapters/customEFGroup';

type Props = {
  id: string;
  onClose: () => void;
  onEditGroup: (element: ICustomEFGroupBack) => void;
};

const EditGroupModal = ({ id, onClose, onEditGroup }: Props) => {
  const ERRORS = useErrors();
  const { t } = useTranslation('translation', { keyPrefix: 'customize.create' });
  const { adaptCustomEFGroupFront2Back } = useAdapterCustomEFGroup();

  const { customEFGroup, loading } = useGetCustomEFGroup(id);

  const [error, setError] = useState<ErrorType>();

  const handleCreate = async (element: ICustomEFGroupFront) => {
    // adapt data
    const elementFormatted = adaptCustomEFGroupFront2Back(element);

    const response = await patchCustomEFGroup({ id, data: elementFormatted });

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    onEditGroup(response);
    onClose();
  };

  return (
    <>
      <FormHeader title={t('titleEdit')} description={t('descriptionEdit')} />
      <Form
        onSubmit={handleCreate}
        error={error}
        loading={loading}
        formDataFilled={customEFGroup}
      />
    </>
  );
};

export default EditGroupModal;
