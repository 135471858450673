import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { updateOrgEf } from '../../../../services/api/orgEf';
import { useLatestMITECOVersion } from '../../../../customHooks/api/useLatestMITECOVersion';

export const useUpdateOrgEfToLatest = () => {
  const org = useSelectedOrganization();
  const queryClient = useQueryClient();

  const { data: latestMITECOVersion } = useLatestMITECOVersion();

  const { mutate } = useMutation({
    mutationFn: updateOrgEf(org?.id),
    onSuccess: (data: OrgEf) => {
      queryClient.setQueryData(['orgEf', org?.id], data);
    }
  });

  return {
    updateOrgEf: () => {
      if (!latestMITECOVersion) {
        return;
      }

      mutate({ id: latestMITECOVersion });
    }
  };
};
