export const environmentDiego = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_NP6H4JJJb',
      userPoolWebClientId: '7mqll7s3v5ln54dn3n4bip394n',
      oauth: {
        domain: 'dcycle-backend-auth-dacuna97-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://baje18ayyb.execute-api.eu-west-1.amazonaws.com/dev',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};

export const environmentMigue = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_ltHxpvtoG',
      userPoolWebClientId: '5uge8b2kgrrh9gailrdalstnjd',
      oauth: {
        domain: 'dcycle-backend-auth-migueldev-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://raspx3i4t3.execute-api.eu-west-1.amazonaws.com/dev',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};

export const environmentVinni = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_EvxwqgLLk',
      userPoolWebClientId: '7r79vqr3e6ehb15d9486bu5721',
      oauth: {
        domain: 'dcycle-backend-auth-sandbox-vincorreia-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://fj15jmw0m1.execute-api.eu-west-1.amazonaws.com/dev',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};

export const environmentGonzalojman = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_y48Vd4zqc',
      userPoolWebClientId: 'ndmlomvti04b1q1r7j6gm3l0f',
      oauth: {
        domain: 'dcycle-backend-auth-sandboxgonzalojman-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://vmt8sogeaj.execute-api.eu-west-1.amazonaws.com/dev',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};

export const environmentPato = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_NpwXsqbOB',
      userPoolWebClientId: '3lansc2o5o9n0tdp9jpbrnqlfk',
      oauth: {
        domain: 'dcycle-backend-auth-pato-dev-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://0g4qgso08j.execute-api.eu-west-1.amazonaws.com/dev',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};
export const environmentJuanma = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_a44gff2tY',
      userPoolWebClientId: '1qg61qrak1rv0kuagk12jv719b',
      oauth: {
        domain: 'dcycle-backend-auth-juanma-dev-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'http://localhost:3000/callback',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://ond7f7vowg.execute-api.eu-west-1.amazonaws.com/dev',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'http://localhost:3000/'
};

const environment = {
  growth: environmentMigue,
  autonomy: environmentVinni,
  delight: environmentDiego,
  vinni: environmentVinni,
  migue: environmentMigue,
  diego: environmentDiego,
  gonzalojman: environmentGonzalojman,
  pato: environmentPato,
  juanma: environmentJuanma
};

export const getEnvironmentSandbox = (user?: string) => {
  if (!user) return environmentDiego;
  if (!Object.keys(environment).includes(user)) return environmentDiego;
  return environment[user as keyof typeof environment];
};
