import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../ui/button/Button';
import './styles.scss';

type Props = {
  handleClose: () => void;
};
const LimitCompleted = ({ handleClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'archiveVehicle' });
  return (
    <div className='limit-completed-vehicles'>
      <h1 className='headline2-font'>{t('limitTitle')}</h1>
      <p className='span-font'>{t('limitDescription')}</p>
      <FormButtonSection>
        <Button lookAndFeel={'primary'} text={t('accept')} onClick={handleClose} size='small' />
      </FormButtonSection>
    </div>
  );
};

export default LimitCompleted;
