import { PurchaseFramework } from 'utils/mapToPurchaseFramework';
import cnaeDescriptions from 'constants/json/cnae_description.json';
import naceDescriptions from 'constants/json/nace_description.json';
import sicDescriptions from 'constants/json/sic_description.json';

export const DESCRIPTION_MAP = {
  [PurchaseFramework.CNAE]: cnaeDescriptions,
  [PurchaseFramework.NACE]: naceDescriptions,
  [PurchaseFramework.SIC]: sicDescriptions
} as const;
