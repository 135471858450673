import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/routes';
import {
  gray_color2,
  scope1_color,
  scope2_color,
  scope3_color
} from '../../../../../styles/colors';
import { Organization } from '../../../../../types/entities/organization';
import { checkFreePlan } from '../../../../../utils/checkFreePlan';
import numberToDecimal, {
  formatNumberToDecimalNonZero
} from '../../../../../utils/numberToDecimal';
import Button from '../../../../ui/button/Button';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import ScopeTag from '../../../../ui/scopeTag/ScopeTag';
import './styles.scss';

type Props = {
  data: ScopeData[];
  organization: Organization;
  loading: boolean;
  disabled?: boolean;
};
function CompanyEnvironmentImpact({ data, organization, loading, disabled = false }: Props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  let pieChartData = [{ title: '', value: 100, color: gray_color2, key: 'bg' }];
  let totalCo2 = 0;

  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  const renderFilledData = () => {
    return (
      <div className='company-environment-scopes__information'>
        <ScopeTag scope={1} value={scope1} totalCo2={totalCo2} />
        <ScopeTag scope={2} value={scope2} totalCo2={totalCo2} />
        <ScopeTag scope={3} value={scope3} totalCo2={totalCo2} />
      </div>
    );
  };

  const renderCo2Information = () => {
    let fontSize = 20;
    const maxDigits = 5;
    if (numberToDecimal(totalCo2).toString().length > maxDigits) {
      fontSize = 20 - (numberToDecimal(totalCo2).toString().length - maxDigits) - 2;
    }
    return (
      <div className='co2-information'>
        <div
          className={`co2-value highlight-text-color co2-dashboard-font`}
          style={{ fontSize: `${fontSize}px` }}>
          {formatNumberToDecimalNonZero(totalCo2)}
        </div>
        <span className='co2-units on-light-text-color body1-font'>t CO₂ eq.</span>
      </div>
    );
  };

  if (data && data.filter((elem) => elem.total_emissions > 0).length > 0) {
    data.forEach((value) => {
      totalCo2 += value.total_emissions;

      if (value.scope === 1) {
        scope1 += value.total_emissions;
      }
      if (value.scope === 2) {
        scope2 += value.total_emissions;
      }
      if (value.scope === 3) {
        scope3 += value.total_emissions;
      }
    });

    totalCo2 = totalCo2 / 1000; // convert to tons
    scope1 = scope1 / 1000; // convert to tons
    scope2 = scope2 / 1000; // convert to tons
    scope3 = scope3 / 1000; // convert to tons

    let scope1Value = (scope1 * 100) / totalCo2;
    let scope2Value = (scope2 * 100) / totalCo2;
    let scope3Value = (scope3 * 100) / totalCo2;

    if (scope1Value < 1 && scope1 !== 0) scope1Value = 0.1;
    if (scope2Value < 1 && scope2 !== 0) scope2Value = 2.1;
    if (scope3Value < 1 && scope3 !== 0) scope3Value = 4.1;

    pieChartData = [
      { title: '', value: scope1Value, color: scope1_color, key: 'scope1' },
      { title: '', value: scope2Value, color: scope2_color, key: 'scope2' },
      { title: '', value: scope3Value, color: scope3_color, key: 'scope3' }
    ];

    pieChartData.sort((a, b) => {
      return b.value - a.value;
    });
  }

  if (loading || !organization) {
    return <LoaderCard />;
  }

  return (
    <div className='card dashboard-card' id='step2'>
      <div className='dashboard-card__header'>
        <h4 className='headline4-font on-light-text-color'>
          {t('dashboardLogistic.logisticImpact')}
        </h4>
      </div>
      <div className='company-environment-wrapper__body small-device-flex'>
        <div className='company-environment-wrapper__two'>
          <div className='co2-wrapper round on-card-gray-bg-color'>
            <div className='co2-progress-bar'>
              <PieChart // your data
                data={pieChartData}
                // width and height of the view box
                viewBoxSize={[100, 100]}
                lineWidth={24}
                totalValue={100}
                rounded
                animate></PieChart>
              {renderCo2Information()}
            </div>
          </div>
        </div>
        <div className='company-environment-wrapper__two'>
          {renderFilledData()}
          {!disabled && (
            <div className='button-wrapper'>
              <Button
                lookAndFeel='primary'
                size='medium'
                text={t('dashboardLogistic.uploadTrips')}
                onClick={() => navigate(ROUTES.MEASURE_LOGISTICS + '?show=requests')}
              />
              <Button
                lookAndFeel={checkFreePlan(organization) ? 'blocked' : 'secondary'}
                text={t('dashboardLogistic.uploadRecharges')}
                onClick={() => navigate(ROUTES.MEASURE_LOGISTICS + '?show=recharges')}
                size='medium'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyEnvironmentImpact;
