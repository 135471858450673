import { useTranslation } from 'react-i18next';
import { useFormContext } from '../hooks/useForm';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormTextArea from '../../../../ui/formComponents2/formInputs/formTextArea/FormTextArea';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';

const Description = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'orgDescriptionModal.ghg_report.form.description'
  });
  const { formData, onChangeValue } = useFormContext();

  const org = useSelectedOrganization();

  return (
    <>
      <FormElementFull>
        <FormTextArea
          label={t('description')}
          placeholder={t('descriptionPlaceholder', { companyName: org?.company_name })}
          tooltip={t('descriptionTooltip')}
          value={formData.cnae}
          onChange={onChangeValue('cnae')}
          error={formData.errors.find((elem) => elem.error === 'cnae')}
        />
      </FormElementFull>
      <FormElementFull>
        <FormTextArea
          label={t('thresholds')}
          placeholder={t('thresholdsPlaceholder', { companyName: org?.company_name })}
          tooltip={t('thresholdsTooltip')}
          value={formData.thresholds}
          onChange={onChangeValue('thresholds')}
          error={formData.errors.find((elem) => elem.error === 'thresholds')}
        />
      </FormElementFull>
    </>
  );
};

export default Description;
