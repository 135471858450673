import { useTranslation } from 'react-i18next';
import './styles.scss';
type Props = {
  startDate: Date;
  endDate: Date;
};
const Methodology = ({ startDate, endDate }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className='methodology-report'>
      <div className='content'>
        <div className='title'>
          <div className='border-left'></div>
          <h1 className='on-dark-text-color headline2-font'>{t('report.methodology')}</h1>
        </div>
        <div className='description on-dark-text-color subtitle3-font'>
          {t('report.methodologyInfo')
            .replace('{{startDate}}', startDate.toLocaleDateString())
            .replace('{{endDate}}', endDate.toLocaleDateString())}
        </div>
      </div>
      <img src={`/images/report/${i18n.resolvedLanguage}/methodology.svg`} alt='graphics' />
    </div>
  );
};

export default Methodology;
