import { IconName } from '../../../../types/utils/iconName';
import { IconColor } from '../../IconBase/IconBase';
import Icon from '../../icon/Icon';
import Label from '../../label/Label';
import '../styles.scss';
import './styles.scss';

type Props = {
  icon: IconName;
  status: 'primary' | 'secondary' | 'error' | 'success' | 'tertiary' | 'disabled';
  children: React.ReactNode;
  iconColor: IconColor;
};

const CommonStatusLabel = ({ icon, status, children, iconColor, ...rest }: Props) => {
  return (
    <Label {...rest} lookAndFeel={status}>
      <div className='tag-container'>
        <Icon icon={icon} color={iconColor} />
        {children}
      </div>
    </Label>
  );
};

export default CommonStatusLabel;
