import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../layout/mainPageLayout/MainPageLayout';
import Button from '../../ui/button/Button';
import Modal from '../../ui/modal/Modal';
import { LCAScenariosUpload } from './LCAScenariosUpload';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';

export const LCAAdminScenarios = () => {
  const { t } = useTranslation();
  const [showUpload, setShowUpload] = useState(false);
  const dispatch = useDispatch();
  const uploadLca = () => {
    setShowUpload(false);
    dispatch(setNotification(t('notification.uploadLcaResultsSuccess')));
  };

  return (
    <>
      <MainPageLayout
        sectionTitle='Upload scenario'
        title='Upload LCA scenarios'
        description='Upload LCA scenarios to be used in the LCA Scenarios page.'
        breadcrumb={<Breadcrumb />}
        buttons={
          <Button
            lookAndFeel='primary'
            text={t('lcaAdmin.upload')}
            onClick={() => setShowUpload(true)}
            size='small'
          />
        }
      />
      <Modal show={showUpload} onClose={() => setShowUpload(false)}>
        <LCAScenariosUpload uploadLca={uploadLca} />
      </Modal>
    </>
  );
};
