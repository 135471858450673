import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { addIntensityMetricGroupsToGrouping } from '../../../../../services/api/intensityMetricsGroupings';
import Button from '../../../../ui/button/Button';
import DividerHorizontal from '../../../../ui/dividerHorizontal/DividerHorizontal';
import Checkbox from '../../../../ui/formComponents/checkbox/Checkbox';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import CustomSkeletonLoader from '../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import './styles.scss';
import useGetData from './useGetData';

type Props = {
  addIntensityMetrics: (intensityMetric: IntensityMetric[]) => void;
};
const GroupIntensityMetrics = ({ addIntensityMetrics }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { organizationsIntensityMetrics, loading } = useGetData(id ?? '');

  const [selectedIntensityMetrics, setSelectedIntensityMetrics] = useState<string[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async () => {
    if (!id) return;
    setLoadingButton(true);
    const result = await addIntensityMetricGroupsToGrouping(id, selectedIntensityMetrics);
    if (result) {
      // Add intensity metrics to the list

      addIntensityMetrics(result);
    }
    setLoadingButton(false);
  };
  return (
    <div className='group-intensity-metrics'>
      <FormHeader
        title={t('groupIntensityMetrics.title')}
        description={t('groupIntensityMetrics.description')}
      />
      {loading && <CustomSkeletonLoader count={1} />}
      {organizationsIntensityMetrics.length === 0 && !loading && (
        <span className='body1-font'>{t('groupIntensityMetrics.noIntensityMetrics')}</span>
      )}
      <div className='group-intensity-metrics__list'>
        {organizationsIntensityMetrics.map((organization, index) => (
          <>
            <div className='organization-intensity-metrics' key={organization.organization_id}>
              <div className='organization-name headline4-font'>
                {organization.organization_name}
              </div>
              <div className='intensity-metric-groups'>
                {organization.intensity_metric_groups.map((intensityMetricGroup) => (
                  <div className='intensity-metric-group' key={intensityMetricGroup.id}>
                    <Checkbox
                      text={null}
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setSelectedIntensityMetrics([
                            ...selectedIntensityMetrics,
                            intensityMetricGroup.id
                          ]);
                        } else {
                          setSelectedIntensityMetrics(
                            selectedIntensityMetrics.filter((id) => id !== intensityMetricGroup.id)
                          );
                        }
                      }}
                      selected={selectedIntensityMetrics.includes(intensityMetricGroup.id)}
                    />
                    <span className='body1-font'>{intensityMetricGroup.unit}</span>
                  </div>
                ))}
              </div>
            </div>
            {index < organizationsIntensityMetrics.length - 1 && <DividerHorizontal />}
          </>
        ))}
      </div>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('groupIntensityMetrics.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default GroupIntensityMetrics;
