import { useQuery } from '@tanstack/react-query';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { getOrganizationInfo } from '../../../services/api/organization';
import { ParentOrg } from '../../../types/entities/organization';

export const useOrgParent = () => {
  const org = useSelectedOrganization();

  const { data, isLoading } = useQuery({
    queryKey: ['orgParents', org?.id],
    queryFn: async () => {
      if (!org) {
        throw new Error('No org id provided');
      }

      const orgData = await getOrganizationInfo(org?.id);

      return orgData.parent as ParentOrg | undefined;
    }
  });
  return { data, isLoading };
};
