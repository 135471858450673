import apiFetch from '../../utils/apiFetch';

type awsCustomersProps = {
  organization_id?: string;
  marketplace_id: string;
  access_token: string;
  refresh_token: string;
};

const setTokenForAmzCustomer = async (props: awsCustomersProps) => {
  try {
    const response = await apiFetch('POST', `/amz_business_customers`, props);
    return response.data;
  } catch (error) {
    return error;
  }
};

export default setTokenForAmzCustomer;
