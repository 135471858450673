import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { moveFacilityToHub } from '../../../../../services/api/hubs';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import HubForm from '../hubForm/EditHubForm';

type Props = {
  facilityToMove: any;
  convertToHub: (id: string) => void;
};

export const MoveFacilityToHub = ({ facilityToMove, convertToHub }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'hubs' });
  const [loadingButton, setLoadingButton] = useState(false);

  const [formData, setFormData] = useState<FormDataHubs>({
    type: 'owned',
    name: facilityToMove.name,
    hubCategory: {
      id: '',
      name: ''
    },
    address: facilityToMove.address,
    categories: [
      { id: 'heat', name: t('facilityDetail.combustion') },
      { id: 'electricity', name: t('facilityDetail.electricity') },
      { id: 'recharge', name: t('facilityDetail.recharge') },
      { id: 'water', name: t('facilityDetail.water') }
    ],
    isSuperCharger: false,
    errors: []
  });

  const handleErrors = () => {
    const optionalFields: string[] = [];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors.length !== 0 ? newErrors : false;
  };

  const handleSubmit = async () => {
    const newErrors = handleErrors();
    if (newErrors || loadingButton) return;
    setLoadingButton(true);

    const body: any = {
      name: formData.name,
      type: formData.type,
      address: formData.address,
      facility_id: facilityToMove.id ? facilityToMove.id : '',
      category: formData.hubCategory.id,
      supercharger: false
    };

    const response = await moveFacilityToHub(body);
    setLoadingButton(false);

    if (!response || response?.response?.status >= 400) return;

    convertToHub(facilityToMove.id);
  };

  return (
    <div className='create-hub'>
      <FormHeader
        title={t('createHub.convertToHub')}
        description={t('createHub.convertToHubDescription')}
      />
      <HubForm
        formData={formData}
        setFormData={setFormData}
        disabledFields={['type', 'name', 'isSuperCharger']}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          onClick={handleSubmit}
          loading={loadingButton}
          text={t('create')}
        />
      </FormButtonSection>
    </div>
  );
};
