import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../../ui/button/Button';
import InputText from '../../../../../ui/formComponents/inputText/InputText';

import './styles.scss';

type Props = {
  employee: string;
  handleOnChangeNewUser: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnClickAddEmail: () => void;
  errors: ErrorType[];
};

const AddEmployee = ({ employee, handleOnChangeNewUser, handleOnClickAddEmail, errors }: Props) => {
  const { t } = useTranslation();

  const newErrors = errors.filter((error) => error.error === 'email');

  return (
    <>
      <div className='add-email-wrapper'>
        <h3 className='body1-bold-font'>{t('sendEmailForm.addTitle')}</h3>
        <div className='add-email'>
          <InputText
            icon={'/images/icons/person.svg'}
            placeholder={'Email'}
            value={employee}
            onChangeValue={handleOnChangeNewUser}
            error={newErrors ? newErrors[0] : undefined}
          />
          <Button
            lookAndFeel={'primary'}
            size={'small'}
            onClick={handleOnClickAddEmail}
            text={t('sendEmailForm.add')}
          />
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
