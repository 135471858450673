import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('companyDetail.companyGroupFund.investmentDate'),
      dataIndex: 'investmentDate',
      key: 'investmentDate'
    },
    {
      title: t('companyDetail.companyGroupFund.associatedFund'),
      dataIndex: 'fundName',
      key: 'fundName'
    },
    {
      title: t('companyDetail.companyGroupFund.investmentValue'),
      dataIndex: 'investmentValue',
      key: 'investmentValue'
    },
    {
      title: t('companyDetail.companyGroupFund.enterpriseValue'),
      dataIndex: 'enterpriseValue',
      key: 'enterpriseValue'
    },
    {
      title: t('companyDetail.companyGroupFund.tag'),
      dataIndex: 'tag',
      key: 'tag'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
};

export default useColumns;
