import { LogisticReportBack, LogisticReportFront } from '../types/entities/logistics';

export const adaptLogisticReportBackToFront = (
  reportBack: LogisticReportBack
): LogisticReportFront => {
  const vehicle_ids = Object.entries(reportBack.tocs_info);
  const vehicleInfo = vehicle_ids.reduce((acc, [key, value]) => {
    const newAcc = {
      ...acc,
      [key]: {
        iv: value.iv,
        tkm: value.tkm,
        kgCO2e: value.emissions,
        fuelsUsed: value.fuels_used,
        tonsTransported: value.tons_transported,
        kmTotal: value.km_total,
        kmEmpty: value.km_empty,
        emptyRunningFactor: value.empty_running_factor
      }
    };

    return newAcc;
  }, {});

  const hoc_ids = Object.entries(reportBack.hocs_info);
  const hocsInfoParsed = hoc_ids.reduce((acc, [key, value]) => {
    const newAcc = {
      ...acc,
      [key]: {
        hubName: value.hub_name,
        emissions: value.emissions,
        intensityValue: value.intensity_value,
        clientTons: value.tonne
      }
    };
    return newAcc;
  }, {});

  const transport_mode_ids = Object.entries(reportBack.transport_mode_info);
  const transportModeInfoParsed = transport_mode_ids.reduce((acc, [key, value]) => {
    const newAcc = {
      ...acc,
      [key]: {
        iv: value.iv,
        tkm: value.tkm,
        emissions: value.emissions,
        activityDistance: value.activity_distance,
        adjustmentFactor: value.adjustment_factor
      }
    };
    return newAcc;
  }, {});

  const hub_category_ids = Object.entries(reportBack.hub_category_info);
  const hubCategoryInfoParsed = hub_category_ids.reduce((acc, [key, value]) => {
    const newAcc = {
      ...acc,
      [key]: {
        iv: value.iv,
        tons: value.tons,
        emissions: value.emissions
      }
    };
    return newAcc;
  }, {});

  return {
    total: reportBack.total_scopes ? reportBack.total_scopes.emissions : undefined,
    scope1: reportBack?.scope_1
      ? {
          hubsEmissions: reportBack.scope_1.hubs_emissions,
          ttwEmissions: reportBack.scope_1.ttw_emissions
        }
      : undefined,
    scope2: reportBack?.scope_2
      ? {
          hubsEmissions: reportBack.scope_2.hubs_emissions
        }
      : undefined,
    scope3: reportBack?.scope_3
      ? {
          wttEmissions: reportBack.scope_3.wtt_emissions,
          wtwSubcontracted: reportBack.scope_3.wtw_subcontracted_emissions,
          wtwEmissionsCleaning: reportBack.scope_3.cleaning_emissions,
          hubEmissions: reportBack.scope_3.hub_emissions
        }
      : undefined,
    vehicleInfo,
    emissionFactorsUsed: {
      logisticFuels: reportBack.emission_factors_used?.logistics_fuels.map((fuel) => ({
        fuelName: fuel.fuel_name,
        fuelUnit: {
          id: fuel.fuel_unit.name,
          name: fuel.fuel_unit.name,
          type: fuel.fuel_unit.type
        },
        region: fuel.region,
        wtt: fuel.wtt,
        ttw: fuel.ttw,
        wtw: fuel.wtw
      })),
      logisticsTocs: reportBack.emission_factors_used?.logistics_tocs.map((toc) => ({
        tocName: toc.toc_name,
        wtwEfSubcontracted: toc.wtw_ef_subcontracted,
        defaultLoad: toc.empty_running_factor_subcontracted
      }))
    },
    tocEmissions: {
      iv: reportBack.company_emissions.transport_activity.iv,
      tkm: reportBack.company_emissions.transport_activity.tkm,
      kgCO2e: reportBack.company_emissions.transport_activity.emissions
    },
    hocEmissions: {
      iv: reportBack.company_emissions.hub_operation.iv,
      tkm: reportBack.company_emissions.hub_operation.tonne,
      kgCO2e: reportBack.company_emissions.hub_operation.emissions
    },
    hocsInfo: hocsInfoParsed,
    transportModeInfo: transportModeInfoParsed,
    hubCategoryInfo: hubCategoryInfoParsed
  };
};
