import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { questions } from '../../../../../../constants/employeeCommutingQuestions';
import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { EmployeePeriodFormType } from '../../../../../../types/entities/employeePeriod';
import { TransportOptions } from '../../../../../../types/entitiesEnums/employeePeriod';

import { useParams } from 'react-router-dom';
import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';
import '../styles.scss';

type Props = {
  title?: string;
};

function StepType({ title }: Props) {
  const { t } = useTranslation();
  const { startDate, endDate } = useParams();

  const { formData, setFormData, stepCounter, handleNext, handleBack } =
    useContext(EmployeeFormContext);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleErrors = () => {
    const newErrors: ErrorType[] = [];

    if (!formData?.fuelType.id)
      newErrors.push({
        error: 'fuelType',
        description: t('error.requiredField')
      });

    if (newErrors.length > 0) {
      setFormData((previous: EmployeePeriodFormType) => ({ ...previous, errors: newErrors }));
      return newErrors;
    }
    return false;
  };

  const fuelTypeOptionsParse = () => {
    const foundQuestion = questions.children.find(
      (question: { answer: string }) => question.answer === formData.transport.id
    );
    if (!foundQuestion) {
      return [];
    }

    const fuels = foundQuestion?.children?.map((fuel: { answer: any }) => {
      return {
        id: fuel.answer,
        name: t(`employees.${fuel.answer}`)
      };
    });
    return fuels || [];
  };

  const fuelTypeOptions: SelectOptionFormat[] = fuelTypeOptionsParse();

  const handleOnChange = (value: SelectOptionFormat) => {
    const renewableEnergy =
      value.id === 'electric_renewable' ? 'yes' : value.id === 'electric' ? 'no' : undefined;
    value.id = value.id === 'electric_renewable' ? 'electric' : value.id;

    onChangeValue('fuelType')(value);
    onChangeValue('renewableEnergy')(renewableEnergy);
  };

  const handleNextStep = () => {
    if (handleErrors() || !handleNext) return;
    const next = formData.transport.id === TransportOptions.CAR ? 'carpooling' : 'summary';
    handleNext(next);
  };

  if (!handleNext || !handleBack) return null;

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle1', {
        date: `${startDate && convertDateBackToFront(startDate)} - ${
          endDate && convertDateBackToFront(endDate)
        }`
      })}
      subtitle={t('employeeForm.formSubtitle2')}>
      <StepWrapper
        title={t(`employeeForm.typeTitle.${formData.transport.id}`)}
        stepNumber={stepCounter}>
        <FormSelect
          icon={'/images/icons/car.svg'}
          placeholder={t('employeeForm.writeType')}
          value={formData.fuelType}
          onChange={handleOnChange}
          error={formData?.errors?.find((error) => error.error === 'fuelType')}
          options={fuelTypeOptions}
        />
        <Button lookAndFeel={'primary'} onClick={handleNextStep} text={t('employeeForm.next')} />
      </StepWrapper>
      <SwitchStep handleNext={handleNextStep} handleBack={handleBack} />
    </StepControllerContainer>
  );
}

export default StepType;
