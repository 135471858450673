import { useEffect, useState } from 'react';
import { getCriteriaList } from '../../../../../../services/api/sbti';
import { useParams } from 'react-router-dom';

const useShowModeling = () => {
  const { id } = useParams();
  const [showSbtiModeling, setShowSbtiModeling] = useState(false);
  const [loading, setLoading] = useState(true);

  const getShowModeling = async () => {
    if (!id) return;
    setLoading(true);
    const response = await getCriteriaList(id);
    setLoading(false);

    if (response?.response?.status >= 400) return;

    const criteriaList: ISbtiCriteriaBack[] = response;

    const criteriaListToBeCompleted = criteriaList?.filter(
      (criteria) => ['C6', 'C7', 'C8', 'C9'].includes(criteria.criteria) // TODO: check if these are the correct criteria
    ); // see all the criteria that have to be accepted to show the modeling

    const newShowCriteria =
      criteriaListToBeCompleted.length === 0
        ? false
        : criteriaListToBeCompleted.every((criteria) => criteria.status === 'accepted');

    setShowSbtiModeling(newShowCriteria);
  };

  useEffect(() => {
    getShowModeling();
  }, [id]);

  return { showSbtiModeling, loading };
};

export default useShowModeling;
