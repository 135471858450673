import { useEffect, useState } from 'react';
import { getIntensityMetricGroupById } from '../../../../services/api/intensityMetrics';

type Props = {
  id: string;
  organization_id: string;
};
const useGetData = ({ id, organization_id }: Props) => {
  const [intensityMetricGroup, setIntensityMetricGroup] = useState<IntensityMetricGroup>();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const intMetricGroup = await getIntensityMetricGroupById(id, organization_id);
    setLoading(false);
    setIntensityMetricGroup(intMetricGroup);
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  return { intensityMetricGroup, setIntensityMetricGroup, loading };
};

export default useGetData;
