import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmployeeFormContext, employeeFormEmpty } from '../../../../../context/employeeFormContext';
import useErrors from '../../../../../customHooks/useErrors';
import useStack from '../../../../../customHooks/useStack';
import { postEmployeesForm, postEmployeesFormQr } from '../../../../../services/api/employees';
import { EmployeePeriodFormType } from '../../../../../types/entities/employeePeriod';
import { CarSize, CommutingType } from '../../../../../types/entitiesEnums/employeePeriod';

import ErrorPageNotFound from '../../../../layout/errorPage/ErrorPageNotFound/ErrorPageNotFound';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import StepInit from './stepInit/StepInit';
import StepNewInit from './stepNewInit/stepNewInit';
import StepNewInitPepeJeans from './stepNewInitPepeJeans/StepNewInitPepeJeans';
import StepTotalKmPepeJeans from './stepTotalKmPepeJeans/StepTotalKmPepeJeans';
import './styles.scss';
import useGetData from './useGetData';
import useSteps from './useSteps';

type Props = {
  isQR: boolean;
};

const EmployeeFormController = ({ isQR }: Props) => {
  const { id: initialId, lang, startDate, endDate } = useParams();

  const { i18n, t } = useTranslation();

  const [id, setId] = useState(initialId);
  const [type, setType] = useState<CommutingType | null>(null);
  const [isQrStatus, setIsQrStatus] = useState(isQR);

  const [errorSubmit, setErrorSubmit] = useState<ErrorType>();
  const ERRORS = useErrors();

  const { loading, error, organizationId, logo } = useGetData({ isQrStatus, id });

  // PEPE JEANS
  const isPepeJeans = organizationId === '4fc3e425-0dab-4dd2-93de-f8054cbb9e32';
  // const isPepeJeans = true;

  const [formData, setFormData] = useState<EmployeePeriodFormType>(employeeFormEmpty);
  const {
    currentElement,
    pushToStack,
    popFromStack,
    size: stepCounter,
    initStack
  } = useStack<ReactNode>({
    initialStack: [<StepInit key='init' />] // StepInit in charge of change steps
  });

  const { steps } = useSteps({ type, isQrStatus, isPepeJeans }); // PEPE JEANS

  // PEPE JEANS
  useEffect(() => {
    if (isPepeJeans) {
      initStack([<StepTotalKmPepeJeans key='init' />]);
      setFormData({
        transport: { id: '', name: '' },
        size: CarSize.NONE,
        fuelType: { id: '', name: '' },
        weeklyTravels: '3',
        dailyTrips: '1',
        totalKm: '',
        startDate: '01/05/2022',
        endDate: '01/05/2023',
        telecommuting: {
          id: 'no',
          name: t('employeeForm.goToOffice')
        },
        carpooling: { id: '', name: '' },
        errors: []
      });
    }
  }, [organizationId, steps]);
  // PEPE JEANS

  // when steps changes, first step is added to the stack
  useEffect(() => {
    if (type && Object.values(steps)?.at(0)) pushToStack(Object.values(steps)[0]);
  }, [steps]);

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang]);

  const handleNext = (next: string) => pushToStack(steps[next]);
  const handleBack = () => {
    if (stepCounter === 2) {
      setType(null);
      setFormData(employeeFormEmpty);
    }
    popFromStack();
  };

  const handleNewAnswer = () => {
    setIsQrStatus(false);
    setType(null);
    if (!isPepeJeans) initStack([<StepNewInit key='newInit' />]);
    if (isPepeJeans) initStack([<StepNewInitPepeJeans key='newInit' />]); // PEPE JEANS
    setFormData(employeeFormEmpty);
  };

  let handleSubmit;

  const handleSubmitInItinerePepeJeans = async () => {
    if (!id) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const response = await postEmployeesForm(formData, id, CommutingType.IN_ITINERE);
    if (!response) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const responseError = await response.text();
    if (String(responseError) === '"EXISTING_ACTIVE_PERIOD_FOR_ITINERE"') {
      setErrorSubmit(ERRORS.EMPLOYEES.EXISTING_ACTIVE_PERIOD_FOR_ITINERE);
      return;
    }
  };

  const handleSubmitInItinere = async () => {
    if (!id) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const response = await postEmployeesForm(formData, id, CommutingType.IN_ITINERE);
    if (!response) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const responseError = await response.text();
    if (String(responseError) === '"EXISTING_ACTIVE_PERIOD_FOR_ITINERE"') {
      setErrorSubmit(ERRORS.EMPLOYEES.EXISTING_ACTIVE_PERIOD_FOR_ITINERE);
      return;
    }
    handleNewAnswer();
  };

  const handleSubmitInItinereQR = async () => {
    if (!id) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }
    const response = await postEmployeesFormQr(formData, id);

    if (!response) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const responseError = await response.text();
    if (String(responseError) === '"EXISTING_ACTIVE_PERIOD_FOR_ITINERE"') {
      setErrorSubmit(ERRORS.EMPLOYEES.EXISTING_ACTIVE_PERIOD_FOR_ITINERE);
      return;
    }
    const responseData = JSON.parse(responseError);
    setId(responseData.id);
    handleNewAnswer();
  };

  const handleSubmitInLabore = async () => {
    if (!id) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }
    const response = await postEmployeesForm(formData, id, CommutingType.IN_LABORE);

    if (!response) {
      setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    if (response.ok) handleNewAnswer();

    const responseError = await response.text();
    if (String(responseError) === '"IN_LABORE_TO_CREATE_NOT_OVERLAPPING_WITH_IN_ITINERE"') {
      setErrorSubmit(ERRORS.EMPLOYEES.IN_LABORE_NOT_IN_PERIOD);
      return;
    }
    setErrorSubmit(ERRORS.FORM.SOMETHING_WENT_WRONG);
  };

  if (type === CommutingType.IN_ITINERE && !isQrStatus) handleSubmit = handleSubmitInItinere;
  if (type === CommutingType.IN_ITINERE && isQrStatus) handleSubmit = handleSubmitInItinereQR;
  if (type === CommutingType.IN_LABORE) handleSubmit = handleSubmitInLabore;
  if (isPepeJeans) handleSubmit = handleSubmitInItinerePepeJeans;

  const handleInItinere = () => setType(CommutingType.IN_ITINERE);
  const handleInLabore = () => setType(CommutingType.IN_LABORE);

  if (loading) return <LoaderTables />;
  if (error) return <ErrorPageNotFound />;

  return (
    <EmployeeFormContext.Provider
      value={{
        type,
        logo,
        formData,
        setFormData,
        stepCounter,
        handleNext,
        handleBack,
        handleInItinere,
        handleInLabore,
        handleNewAnswer,
        handleSubmit,
        errorSubmit
      }}>
      {currentElement}
    </EmployeeFormContext.Provider>
  );
};

export default EmployeeFormController;
