import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { gray_color2, scope1_color, scope2_color, scope3_color } from '../../../styles/colors';
import numberToDecimal from '../../../utils/numberToDecimal';
import ScopeTag from '../../ui/scopeTag/ScopeTag';
import Tooltip from '../../ui/tooltip/Tooltip';
import './styles.scss';

type Props = {
  data: ScopeData[];
  offsets: OffsetType[];
};
function OrganizationCO2Info({ data, offsets }: Props) {
  const { t } = useTranslation();

  let pieChartData = [{ title: '', value: 100, color: gray_color2 }];
  const halfPieChartData = [{ title: '', value: 100, color: gray_color2 }];

  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  let totalCo2Emissions = 0;
  let totalCo2 = 0;
  let totalOffset = 0;

  if (offsets && offsets.length > 0) {
    offsets.forEach((offset) => {
      totalOffset += offset.offset + offset.negatived;
    });
  }

  if (data && data.filter((elem) => elem.total_emissions > 0).length > 0) {
    data.forEach((value) => {
      totalCo2 += value.total_emissions;

      if (value.scope === 1) {
        scope1 += value.total_emissions;
      }
      if (value.scope === 2) {
        scope2 += value.total_emissions;
      }
      if (value.scope === 3) {
        scope3 += value.total_emissions;
      }
    });

    let scope1Value = numberToDecimal((scope1 * 100) / totalCo2);
    let scope2Value = numberToDecimal((scope2 * 100) / totalCo2);
    let scope3Value = numberToDecimal((scope3 * 100) / totalCo2);

    if (scope1Value === 0 && scope1 !== 0) scope1Value = 0.1;
    if (scope2Value === 0 && scope2 !== 0) scope2Value = 0.1;
    if (scope3Value === 0 && scope3 !== 0) scope3Value = 0.1;

    pieChartData = [
      { title: '', value: scope1Value, color: scope1_color },
      { title: '', value: scope2Value, color: scope2_color },
      { title: '', value: scope3Value, color: scope3_color }
    ];

    pieChartData.sort((a, b) => {
      return b.value - a.value;
    });
  }

  totalCo2Emissions = totalCo2;

  halfPieChartData.unshift({
    title: '',
    value: totalOffset <= totalCo2Emissions ? (totalOffset * 100) / totalCo2Emissions : 100,
    color: scope1_color
  });
  if (totalOffset <= totalCo2Emissions) {
    halfPieChartData[1] = {
      title: '',
      value: 100 - (totalOffset * 100) / totalCo2Emissions,
      color: gray_color2
    };
  } else {
    halfPieChartData.pop();
  }

  const renderPieChartCo2Information = () => {
    const difference = (totalCo2Emissions - totalOffset) / 1000;
    let fontSize = 20;
    const maxDigits = 5;
    if (numberToDecimal(totalCo2).toString().length > maxDigits) {
      fontSize = 20 - (numberToDecimal(totalCo2).toString().length - maxDigits) - 2;
    }
    return (
      <div className='piechart-co2-information'>
        <div
          className='co2-value highlight-text-color co2-offset-font'
          style={{ fontSize: `${fontSize}px` }}>
          {numberToDecimal(difference)}
        </div>
        <span className='co2-units on-light-text-color co2-offset-unit-font'>t CO₂ eq.</span>
      </div>
    );
  };

  const renderHalfPieChartCo2Information = () => {
    const unitHalfPieChart = 't CO₂ eq.';
    const totalCo2HalfPiechart = totalOffset / 1000;
    return (
      <div className='half-piechart-co2-information'>
        <div className='co2-value co2-offset-font'>{numberToDecimal(totalCo2HalfPiechart)}</div>
        <span className='co2-units on-light-text-color co2-offset-unit-font'>
          {unitHalfPieChart}
        </span>
      </div>
    );
  };
  const renderNotOffsettedCo2Information = () => {
    let unitDifference = 'kg CO₂ eq.';
    let difference = totalCo2Emissions - totalOffset;

    if (difference >= 1000) {
      difference = difference / 1000;
      unitDifference = 't CO₂ eq.';
    }
    if (difference < 0) {
      difference = 0;
      unitDifference = 'kg CO₂ eq.';
    }
    return (
      <div className='co2-information'>
        <div className='co2-value co2-offset-font'>{numberToDecimal(difference)}</div>
        <span className='co2-units on-light-text-color co2-offset-unit-font'>{unitDifference}</span>
      </div>
    );
  };

  const renderScopesInfo = () => {
    const scope1Value = scope1 / 1000;
    const scope2Value = scope2 / 1000;
    const scope3Value = scope3 / 1000;

    return (
      <div className='scopes-info'>
        <ScopeTag scope={1} value={scope1Value} shortText noIcons />
        <ScopeTag scope={2} value={scope2Value} shortText noIcons />
        <ScopeTag scope={3} value={scope3Value} shortText noIcons />
      </div>
    );
  };

  return (
    <div className='organization-co2-info'>
      <div className='card'>
        <div className='card__tooltip'>
          <Tooltip text={t('offset.environmentalDebtTooltip')} position='right' />
        </div>
        <div className='card-column card-info-piechart'>
          <h1 className='headline4-font'>{t('offset.environmentalDebt')}</h1>
          <p className='span-font'>{t('offset.environmentalDebtDescription')}</p>
          {renderScopesInfo()}
        </div>
        <div className='card-column'>
          <div className='co2-wrapper round on-card-gray-bg-color'>
            <div className='co2-progress-bar'>
              <PieChart // your data
                data={pieChartData}
                // width and height of the view box
                viewBoxSize={[100, 100]}
                lineWidth={18}
                totalValue={100}
                rounded
                animate></PieChart>
              {renderPieChartCo2Information()}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card'>
        <Tooltip text={t('offset.yetNotOffsetTooltip')} position='right' />
        <div className='card-column card-info'>
          <h1 className='headline4-font'>{t('offset.yetNotOffset')}</h1>
          <p className='span-font'>{t('offset.yetNotOffsetDescription')}</p>
        </div>
        <div className='card-column'>
          {renderNotOffsettedCo2Information()}
          <div className='bars-wrapper'>
            <div className='text-tag body3-font tag-bg-text-color'>{t('offset.youCanAvoid')}</div>
            <img src='/images/icons/chartBars.svg' alt='bar chart' />
          </div>
        </div>
      </div> */}

      <div className='card'>
        <div className='card__tooltip'>
          <Tooltip text={t('offset.impactOffsetTooltip')} />
        </div>
        <div className='card-column card-info'>
          <h1 className='headline4-font'>{t('offset.impactOffset')}</h1>
          <p className='span-font'>{t('offset.impactOffsetDescription')}</p>
          {/* <div className='percentage-tag body3-font secondary-light-bg-color secondary-text-color'>{`+${numberToDecimal((100 * totalOffset) / totalCo2Emissions)} %`}</div> */}
        </div>
        <div className='card-column'>
          <div className='half-co2-wrapper on-card-gray-bg-color'>
            <div className='half-co2-progress-bar'>
              <PieChart // your data
                data={halfPieChartData}
                // width and height of the view box
                viewBoxSize={[100, 50]}
                startAngle={180}
                lengthAngle={180}
                lineWidth={18}
                totalValue={100}
                rounded
                animate></PieChart>
              {renderHalfPieChartCo2Information()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationCO2Info;
