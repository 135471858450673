import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import { deleteBusinessTravel } from '../../../../../services/api/businessTravels';
import Button from '../../../../ui/button/Button';
import '../styles.scss';

type Props = {
  removeBusinessTravel: (id: string) => void;
  businessTravelToDelete: string;
};
function DeleteBusinessTravel({ businessTravelToDelete, removeBusinessTravel }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteBusinessTravel();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleDeleteBusinessTravel = async () => {
    if (!user?.selectedOrganization) return;
    setLoadingButton(true);

    const response = await deleteBusinessTravel({
      id: businessTravelToDelete,
      organizationId: user.selectedOrganization
    });

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    removeBusinessTravel(businessTravelToDelete);
    setLoadingButton(false);
  };

  return (
    <div className='delete-modal'>
      <h1 className='headline4-font'>{t('businessTravels.deleteBusinessTravel')}</h1>
      <h2 className='subtitle3-font '>{t('businessTravels.areYouSureDelete')}</h2>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('businessTravels.delete')}
          onClick={handleDeleteBusinessTravel}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default DeleteBusinessTravel;
