import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import employeeCategorization from '../../../../../../constants/employeeCategorization';
import { gray_color2, scope1_color, scope2_color } from '../../../../../../styles/colors';
import { EmployeeReport, EmployeeReportByType } from '../../../../../../types/entities/employee';
import { formatRoundNumber } from '../../../../../../utils/formatNumber';
import numberToDecimal from '../../../../../../utils/numberToDecimal';

type Props = {
  type: 'polluting' | 'sustainable';
  employees: EmployeeReport[];
};

const icons = {
  sustainable: '/images/icons/leaf.svg',
  polluting: '/images/icons/warning50.svg'
};

function EmployeePieChartCard({ type, employees }: Props) {
  const { t } = useTranslation();

  const renderCo2Information = (percentage: number, employeesNumber: number) => {
    return (
      <div className='co2-information'>
        <div className='co2-value small-card-font'>{`${
          !isNaN(percentage) ? numberToDecimal(percentage) : 0
        }%`}</div>
        <span className='co2-units on-light-text-color body3-font'>{`${employeesNumber} ${t(
          'report.employees'
        )}`}</span>
      </div>
    );
  };

  let totalCo2 = 0;
  let totalCo2ByType = 0;

  const pieChartData = [{ title: '', value: 100, color: gray_color2 }];
  const employeesByType: EmployeeReportByType[] = [];
  let employeesNumber = 0;

  employees.forEach((employee) => {
    const employeeParsed = {
      ...employee,
      renewable_energy: employee.renewable_energy === 'yes' ? 'yes' : null
    };

    const classificationFound = employeeCategorization.find(
      (elem) =>
        elem.transport_type === employeeParsed.transport_type &&
        elem.size === employeeParsed.vehicle_size &&
        elem.fuel_type === employeeParsed.fuel_type &&
        elem.renewable_energy === employeeParsed.renewable_energy
    );

    totalCo2 += employee.co2e;

    if (!classificationFound) return;
    if (classificationFound.classification.includes(type)) {
      employeesNumber += 1;
      totalCo2ByType += employee.co2e;

      const foundIndex = employeesByType.findIndex(
        (elem) => elem.transport_type === employeeParsed.transport_type
      );
      if (foundIndex === -1) {
        employeesByType.push({
          transport_type: employeeParsed.transport_type,
          total: 1,
          subcategories: [
            {
              fuel_type: employeeParsed.fuel_type,
              renewable_energy: employeeParsed.renewable_energy,
              total: 1
            }
          ]
        });
      } else {
        employeesByType[foundIndex].total += 1;
        const foundSubcategoryIndex = employeesByType[foundIndex].subcategories.findIndex(
          (elem) =>
            elem.fuel_type === employeeParsed.fuel_type &&
            elem.renewable_energy === employeeParsed.renewable_energy
        );
        if (foundSubcategoryIndex === -1) {
          employeesByType[foundIndex].subcategories.push({
            fuel_type: employeeParsed.fuel_type,
            renewable_energy: employeeParsed.renewable_energy,
            total: 1
          });
        } else {
          // employeesByType[foundIndex].total += 1;
          employeesByType[foundIndex].subcategories[foundSubcategoryIndex].total += 1;
        }
      }
    }
  });

  pieChartData.push({
    title: type,
    value: (employeesNumber * 100) / employees.length,
    color: 'url(#gradient1)'
  });

  const icon = icons[type];

  if (pieChartData.length === 0) {
    pieChartData.push({ value: 100, color: gray_color2, title: '' });
  }

  return (
    <div className='distribution-element'>
      <div className='icon-title'>
        <img src={icon} alt='polluting' />
        <h2 className='body2-font'>{t(`report.${type}`)}</h2>
      </div>
      <div className='card-info-co2-full on-card-gray-bg-color'>
        <div className='co2-progress-bar'>
          <PieChart // your data
            data={pieChartData}
            // width and height of the view box
            viewBoxSize={[100, 100]}
            lineWidth={18}
            totalValue={100}
            paddingAngle={5}
            rounded
            animate>
            <defs>
              <linearGradient id='gradient1'>
                <stop offset='0%' stopColor={scope1_color} />
                <stop offset='100%' stopColor={scope2_color} />
              </linearGradient>
            </defs>
          </PieChart>
          {renderCo2Information((employeesNumber * 100) / employees.length, employeesNumber)}
        </div>
        <div className='legend-distribution'>
          {employeesByType.map((elem) =>
            elem.transport_type ? (
              <div className='legend-item-wrapper' key={elem.transport_type}>
                <div className='legend-item'>
                  {/* <div className='legend-color' style={{ backgroundColor: elem.color }}></div> */}
                  <span className='legend-text body2-font'>
                    {t(`employees.${elem.transport_type}`)}
                  </span>
                  <span className='legend-percentage body3-font'>{elem.total}</span>
                </div>
                <div className='legend-item-subcategories'>
                  {elem.subcategories.map((subelem, index) =>
                    subelem.fuel_type ? (
                      <div className='flex' key={`${subelem.fuel_type}-${index}`}>
                        <div className='legend-item-subcategory body3-font on-light-text-color2'>
                          {subelem.renewable_energy === 'yes'
                            ? t('employees.electric_renewable')
                            : t(`employees.${subelem.fuel_type}`)}
                        </div>
                        <span className='legend-percentage body3-font on-light-text-color2'>
                          {subelem.total}
                        </span>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
      <div className='co2-info'>
        <span className='body2-font'>{formatRoundNumber(totalCo2ByType / 1000)}</span>
        <span className='body3-font'>t CO₂ eq.</span>
        <span className='highlight-report-text-color body2-font'>
          (
          {formatRoundNumber(
            totalCo2 > 0 && totalCo2ByType > 0 ? (totalCo2ByType * 100) / totalCo2 : 0
          )}
          %)
        </span>
      </div>
    </div>
  );
}

export default EmployeePieChartCard;
