import { useTranslation } from 'react-i18next';
import { Column } from '../../../../types/table';
import Dot from '../../../ui/dot/Dot';

type GroupByCategory = {
  total_emissions: number;
  category: string;
  scope: number;
};

export const useTransformData = (
  data: GroupByCategory[],
  scope: number
): { categoriesColumns: Column[] } => {
  const categoriesColumns: any = [];
  const { t } = useTranslation();

  data
    .filter((elem) => elem.category !== 'employees_in_labore')
    .map((elem) => ({
      ...elem,
      category: elem.category.replace(/_in_labore/g, '').replace(/_in_itinere/, '')
    }))
    .forEach((elem) =>
      scope && elem.scope === scope
        ? categoriesColumns.push({
            title: (
              <span className='text-center'>
                {elem.scope === 1 || elem.scope === 2 || elem.scope === 3 ? (
                  <Dot type={`scope${elem.scope}`} />
                ) : null}
                {t(`categories.${elem.category}`)}
              </span>
            ),
            dataIndex: elem.category,
            key: elem.category,
            render: (value: string) => {
              return <span className='text-center'>{!value ? '0' : value}</span>;
            }
          })
        : null
    );

  return { categoriesColumns };
};
