import { useTranslation } from 'react-i18next';
import './styles.scss';

const Measure = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className='measure-report'>
      <div className='content'>
        <div className='title'>
          <div className='border-left'></div>
          <h1 className='on-dark-text-color headline2-font'>{t('report.measure')}</h1>
        </div>
        <div className='description on-dark-text-color subtitle3-font'>
          {t('report.measureInfo')}
        </div>
      </div>
      <img src={`/images/report/${i18n.resolvedLanguage}/measure.svg`} alt='graphics' />
    </div>
  );
};

export default Measure;
