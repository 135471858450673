import { Status } from '../../../../../../types/utilsEnums/status';
import { StatusTag } from '../../../../../ui/statusTag';
import { PURCHASE_STATUS } from '../../constants';

const STATUS_MAP = {
  [Status.ERROR]: Status.IN_REVIEW,
  [Status.PENDING]: Status.IN_REVIEW,
  [Status.IN_REVIEW]: Status.IN_REVIEW,
  [Status.IN_PROGRESS]: Status.LOADING,
  [Status.ACTIVE]: Status.ACTIVE
} as const;

type Props = {
  status: (typeof PURCHASE_STATUS)[keyof typeof PURCHASE_STATUS];
  text: string;
};

export const PurchasesStatusTag: React.FC<Props> = ({ status, text }) => {
  return <StatusTag status={STATUS_MAP[status]} text={text} />;
};
