import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { ChangeEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GOOGLE_MAPS_API_KEY } from '../../../../constants/googleMapsApiKey';
import Tooltip from '../../tooltip/Tooltip';

import './styles.scss';

type Props = {
  icon: string;
  label?: string | ReactNode;
  placeholder: string;
  value: string;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelectMapsValue: (value: string) => void;
  fontClass?: string;
  error?: ErrorType;
  height?: string;
  size?: string; // small for smaller paddings and margins
  tooltip?: string;
  disabled?: boolean;
};
const libraries: any[] = ['places'];

const InputGoogleMaps = ({
  icon,
  label,
  placeholder,
  value,
  onChangeValue,
  onSelectMapsValue,
  error,
  fontClass = 'input-font',
  height = '33px',
  size = '',
  tooltip = '',
  disabled = false
}: Props) => {
  const { t } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
    // language: i18n.resolvedLanguage
  });

  const [autocomplete, setAutoComplete] = useState<any>();

  const onLoad = (autocompleteValue: any) => {
    setAutoComplete(autocompleteValue);
  };

  const onPlaceChanged = () => {
    if (!autocomplete || disabled) return;
    onSelectMapsValue(autocomplete.getPlace().formatted_address);
  };

  if (!isLoaded) {
    return null;
  }
  return (
    <div className='input'>
      {(label || tooltip) && (
        <div className='label-wrapper'>
          {label && <label className='input-label-font on-light-text-color'>{label}</label>}
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}

      <div
        className={`input__text flex ${fontClass} ${size} ${
          disabled ? 'input-disabled-bg-color' : ''
        } ${error ? 'error-border-color' : 'input-border-color'}`}
        style={{ height }}>
        {icon && (
          <div className={`image-wrapper ${error ? 'error-border-color' : 'input-border-color'}`}>
            <img src={icon} alt='input-icon' width={18} />
          </div>
        )}
        <Autocomplete
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
          className='maps-autocomplete'
          types={['address']}>
          <input
            type='text'
            className={`input__text__content on-light-text-color ${fontClass} ${
              disabled ? 'transparent-bg-color' : ''
            } ${error ? 'error-border-color' : 'input-border-color'}`}
            value={value}
            placeholder={placeholder}
            onChange={onChangeValue}
            disabled={disabled}
          />
        </Autocomplete>
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputGoogleMaps;
