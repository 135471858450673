import { ColumnHookCallback, OnEntryChange, OnEntryInit, ResultValues } from 'nuvo-react';
import { transformNuvoResultsIntoCsv, validateDateFields } from '../../../../../utils/nuvo';
import { convertStringToDateBackend } from '../../../../../utils/convertDates';
import { TFunction } from 'react-i18next';

export const recycledHook: ColumnHookCallback = (values) =>
  values.map(([item, index]) => {
    return [
      {
        value: typeof item === 'number' ? item : 0
      },
      index
    ];
  });

export async function parseNuvoResults(results: ResultValues, fileName: string, category: string) {
  const content = transformNuvoResultsIntoCsv(results);

  const finalFileName = `${fileName || category}.csv`;

  // transform content to File
  const file = new File([content], finalFileName, { type: 'text/csv' });

  return { finalFileName, file };
}

export const treatString = (value: string) => {
  if (value.includes(',')) {
    return value.replaceAll(',', '.');
  }
  return value;
};

export const treatRest = <T extends object>(obj: T) => {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'string') {
      newObj[key] = treatString(value);
      continue;
    }

    newObj[key] = value;
  }

  return newObj;
};

export const validateRow =
  (t: TFunction): OnEntryInit =>
  (row) => {
    const date = row.purchase_date as string;

    const recycledPercentage = row.recycled as number;

    const errors = {} as any;

    const currency = row.euro_gross_quantity;

    if (!row || !date) return;

    if (recycledPercentage < 0 || recycledPercentage > 1) {
      errors.recycled = {
        value: recycledPercentage,
        info: [
          {
            level: 'error',
            message: t('purchases.nuvo.errors.recycled')
          }
        ]
      };
    }

    if (convertStringToDateBackend(date) > new Date()) {
      errors.purchase_date = {
        value: date,
        info: [
          {
            level: 'error',
            message: t('purchases.nuvo.errors.purchaseDate')
          }
        ]
      };
    }

    const minYearErrors = validateDateFields(['purchase_date'])(row);

    for (const key in minYearErrors) {
      errors[key] = minYearErrors[key];
    }

    if (typeof currency === 'number' && currency < 0) {
      errors.euro_gross_quantity = {
        value: currency,
        info: [
          {
            level: 'error',
            message: 'Value must be positive'
          }
        ]
      };
    }

    return errors;
  };

export const onEntryChange =
  (t: TFunction): OnEntryChange =>
  (rows) => {
    const validate = validateRow(t);

    return rows
      .filter((row) => Object.keys(validate(row.data, row.rowIndex) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validate(row.data, row.rowIndex)
          }
        };
      });
  };
