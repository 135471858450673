import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import { Trans, useTranslation } from 'react-i18next';
import { ExcelModal } from './components/ExcelModal';
import Button from '../../ui/button/Button';
import Icon from '../../ui/icon/Icon';
import { useContext, useState } from 'react';
import { requestMITECOAssistance } from './services/requestMITECOAssistance';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { UserContext } from '../../../context/userContext';
import { useMITECO } from './hooks/useMITECO';
import { cancelMITECOAssistance } from './services/cancelMITECOAssistance';
import { ReactComponent as MITECOImg } from '../../../assets/img/MITECO_Communicate.svg';
import { CertificationPage } from '../../ui/certificationPage/CertificationPage';
import type { Variant } from './types';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import Label from '../../ui/label/Label';
import { VariantType } from '../../ui/certificationPage/types';
import { useMITECOOrgEfName } from '../../../customHooks/api/useMITECOOrgEfName';

export const MITECO = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'certifications.MITECO' });
  const dispatch = useDispatch();
  const { status, loading, setLoading, MITECOAssistance, refetch } = useMITECO();
  const { data } = useMITECOOrgEfName();

  const org = useSelectedOrganization();
  const user = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);

  const onClickRequestMITECOAssistance = async () => {
    if (!org || !user || loading) {
      return;
    }

    try {
      setLoading(true);

      await requestMITECOAssistance({ orgName: org.company_name, user });

      await refetch();

      dispatch(setNotification(t('notification.success')));
    } catch {
      dispatch(setNotification(t('notification.error')));
    } finally {
      setLoading(false);
    }
  };

  const onClickCancelMITECOAssistance = async () => {
    if (!MITECOAssistance) {
      return;
    }

    try {
      setLoading(true);
      await cancelMITECOAssistance({ id: MITECOAssistance.id });

      await refetch();
    } catch {
      dispatch(setNotification(t('notification.error')));
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const variants: Record<VariantType, Variant> = {
    DEFAULT: {
      lookAndFeel: 'primary',
      text: t('requestMITECOAssistance'),
      onClick: onClickRequestMITECOAssistance,
      iconNode: undefined
    },
    REQUESTED: {
      lookAndFeel: 'completed',
      text: t('requested'),
      iconNode: <Icon icon='success' color='white' />,
      onClick: onClickCancelMITECOAssistance
    },
    CANCEL: {
      lookAndFeel: 'warning',
      text: t('cancel'),
      onClick: onClickCancelMITECOAssistance,
      iconNode: undefined
    }
  };

  if (!org) {
    return null;
  }

  return (
    <section className='main-page-layout flex-col gap-y-8'>
      <SectionHeader title={t('title')} subtitle={t('subtitle')} />

      <ExcelModal show={showModal} onClose={() => setShowModal(false)} />
      <CertificationPage.Layout cols={3}>
        <div className='flex-col gap-y-6 col-span-2'>
          <CertificationPage.Title>{t('section.title')}</CertificationPage.Title>
          <CertificationPage.Description>
            <Trans i18nKey='certifications.MITECO.section.description' />
          </CertificationPage.Description>
          <CertificationPage.SectionLayout>
            <CertificationPage.SectionTitle>{t('section.checks')}</CertificationPage.SectionTitle>
            <CertificationPage.Checks
              checks={[t('check.1'), t('check.2'), t('check.3'), t('check.4')]}
            />
          </CertificationPage.SectionLayout>
          <CertificationPage.SectionLayout>
            <CertificationPage.SectionTitle>
              {t('section.orderedList')}
            </CertificationPage.SectionTitle>
            <div className='font-body-b1-r'>
              <Trans i18nKey='certifications.MITECO.section.orderedListItems'>
                <ol className='m-0 pl-4'>
                  <li>1</li>
                  <li>2</li>
                  <li>3</li>
                  <li>4</li>
                  <li>5</li>
                </ol>
              </Trans>
            </div>
          </CertificationPage.SectionLayout>
          <CertificationPage.ButtonSection>
            <CertificationPage.Button VARIANTS={variants} isRequested={status} loading={loading} />
            <Button
              size='medium'
              lookAndFeel={org.subscription_plan !== 'free_plan' ? 'secondary' : 'blocked'}
              text={t('downloadExcel')}
              onClick={handleOpenModal}
              iconNode={<Icon icon='download' color='gray-dark' size='medium' />}
              style={{ width: '275px' }}
            />
          </CertificationPage.ButtonSection>
          <Label lookAndFeel='secondary'>
            <div className='flex items-center gap-2'>
              <Icon icon='information' color='pending' size='small' />
              {t('disclaimer', { version: data })}
            </div>
          </Label>
        </div>
        <MITECOImg />
      </CertificationPage.Layout>
    </section>
  );
};
