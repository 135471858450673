import { useMutation, useQueryClient } from '@tanstack/react-query';
import { duplicateLCA } from '../../../../services/api/lca/lcaPortfolio';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { useLCATranslation } from '../../../../customHooks/translations/useLCATranslation';
import { InfiniteData } from '@tanstack/react-query';
import { LCAPortfolioResponse } from '../types/LCAPortfolio';
import uuid from 'uuid';
import { Status } from '../../../../types/utilsEnums/status';

export const useDuplicateLCA = () => {
  const queryClient = useQueryClient();
  const org_id = useSelectedOrganization()?.id;
  const dispatch = useDispatch();

  const { t } = useLCATranslation();

  const { mutate } = useMutation({
    mutationFn: duplicateLCA,
    onSuccess: (_, params) => {
      dispatch(setNotification(t('portfolio.duplicateSuccess')));
      queryClient.setQueriesData({ queryKey: ['lcaPortfolio', org_id] }, (input) => {
        const oldData = input as InfiniteData<LCAPortfolioResponse, unknown>;

        const acvToDuplicate = oldData.pages
          .flatMap((page) => {
            return page.items;
          })
          .find((item) => {
            return item.id === params.acv_id;
          });

        if (!acvToDuplicate) {
          return oldData;
        }

        oldData.pages[0].items.unshift({
          ...acvToDuplicate,
          name: `Copy - ${acvToDuplicate.name}`,
          status: Status.LOADING,
          id: uuid.v4()
        });

        return {
          ...oldData
        };
      });
    },
    onError: () => {
      dispatch(setNotification(t('portfolio.duplicateError')));
    }
  });

  return {
    duplicateLCA: mutate
  };
};
