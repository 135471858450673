import Button from '../../ui/button/Button';
import Modal from '../../ui/modal/Modal';
import { useLCATranslation } from '../../../customHooks/translations/useLCATranslation';
import { useDeleteLCA } from '../Portfolio/LCADetail/hooks/useDeleteLCA';

type Props = {
  lcaToDelete: string | null;
  onClose: () => void;
};

export const DeleteLCAModal: React.FC<Props> = ({ lcaToDelete, onClose }) => {
  const { t } = useLCATranslation();

  const { deleteLCA, isDeleting } = useDeleteLCA();

  const onDelete = async () => {
    if (!lcaToDelete) {
      return;
    }
    await deleteLCA(lcaToDelete);

    onClose();
  };

  return (
    <Modal.WithPortal show={Boolean(lcaToDelete)} onClose={onClose}>
      <Modal.Header title={t('portfolio.deleteLCA.title')} />
      <Modal.Content>
        <div className='flex-col gap-y-2'>
          <span>{t('portfolio.deleteLCA.description')}</span>
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel={'secondary'}
          onClick={onClose}
          text={t('portfolio.deleteLCA.cancel')}
        />
        <Button
          lookAndFeel={'primary'}
          onClick={onDelete}
          text={t('portfolio.deleteLCA.delete')}
          loading={isDeleting}
        />
      </Modal.Buttons>
    </Modal.WithPortal>
  );
};
