import {
  IPurchaseGeneralBackend,
  IPurchaseSpendBasedFormFront,
  IPurchaseSupplierSpecificBackend
} from '../../types/purchases';
import apiFetch from '../../utils/apiFetch';
import { adaptPurchasesSpendBasedFrontToBack } from '../../adapters/purchases';
import { AxiosResponse } from 'axios';
import { URLOptions, getUrl } from '../../utils/url';

/* ----------------------- CRUD -----------------------*/

export const getPurchasesByOrgIdStatus = async (organization_id: string, status: string) => {
  try {
    type QueryParams = {
      status?: string;
    };
    const queryParams: QueryParams = {};
    if (status && status !== 'all') {
      queryParams['status'] = status;
    }
    const response = await apiFetch(
      'GET',
      `/purchases/organizations/${organization_id}/template`,
      {},
      {
        'x-organization-id': organization_id
      },
      queryParams
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

/* POST */
export const postPurchaseSpendBased = async (
  organization_id: string,
  purchase: IPurchaseSpendBasedFormFront
) => {
  try {
    const response: AxiosResponse<IPurchaseGeneralBackend> = await apiFetch(
      'POST',
      '/purchases',
      { ...adaptPurchasesSpendBasedFrontToBack(purchase), organization_id },
      {
        'x-organization-id': organization_id
      }
    );

    return response;
  } catch (error) {
    return undefined;
  }
};

export const postPurchaseSupplierSpecific = async (
  organization_id: string,
  purchase: Partial<IPurchaseSupplierSpecificBackend>
) => {
  try {
    const response: AxiosResponse<IPurchaseGeneralBackend> = await apiFetch(
      'POST',
      '/purchases/supplier_specific',
      { ...purchase, organization_id },
      {
        'x-organization-id': organization_id
      }
    );
    return response;
  } catch (error) {
    return undefined;
  }
};

/* PATCH */
export const patchPurchase = async (
  id: string,
  organization_id: string,
  purchase: Partial<IPurchaseGeneralBackend>
) => {
  try {
    const response: AxiosResponse<IPurchaseGeneralBackend> = await apiFetch(
      'PATCH',
      `/purchases/${id}`,
      purchase,
      {
        'x-organization-id': organization_id
      }
    );

    return response;
  } catch (error) {
    return undefined;
  }
};

export const deletePurchasesFromOrganization = async (
  organization_id: string,
  status: string,
  startDate?: string,
  endDate?: string
) => {
  try {
    type QueryParams = {
      status?: string;
      startDate?: string;
      endDate?: string;
    };
    const queryParams: QueryParams = {};
    if (status && status !== 'all') {
      queryParams['status'] = status;
    }
    if (startDate && endDate) {
      queryParams['startDate'] = startDate;
      queryParams['endDate'] = endDate;
    }
    const response = await apiFetch(
      'DELETE',
      `/purchases/organizations/${organization_id}`,
      {},
      {
        'x-organization-id': organization_id
      },
      queryParams
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

/* ----------------------- REST -----------------------*/

export const getPurchasesSectors = async () => {
  try {
    const response = await apiFetch('GET', '/purchases_sectors');
    return response.data;
  } catch (err) {
    return null;
  }
};

export const uploadFilePurchases = async (
  file: string,
  file_name: string,
  organization_id: string
) => {
  try {
    const response = await apiFetch(
      'POST',
      '/purchases/upload',
      {
        file,
        file_name
      },
      {
        'x-organization-id': organization_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getPresignedUrlPurchases = async (file_name: string, org_id: string) => {
  try {
    const response = await apiFetch(
      'POST',
      '/purchases/bulk/csv',
      {
        file_name,
        org_id
      },
      {
        'x-organization-id': org_id
      }
    );
    return response.data;
  } catch (err: any) {
    if (
      err?.response?.status === 422 &&
      err.response.data &&
      'invalid_csv_file' in err.response.data
    ) {
      return err.response.data;
    }
    return null;
  }
};

export const recalculatePurchasesFromOrganization = async (
  organization_id: string,
  user_id: string
) => {
  try {
    const response = await apiFetch(
      'PATCH',
      `/purchases/recalculate`,
      {},
      {
        'x-organization-id': organization_id,
        'user-id': user_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getPurchasesProducts = async (country: string, date: string) => {
  try {
    const response = await apiFetch('GET', '/purchases_products', null, null, {
      purchase_year: date,
      purchase_country: country
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const isPurchaseRecyclable = async (id: string, country: string, year: string) => {
  try {
    const response = await apiFetch('GET', '/purchases_recyclable', null, null, {
      product_name: id,
      country: country,
      year
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPurchasesSuppliers = async (organizationId: string, product: string) => {
  try {
    const response = await apiFetch('GET', '/purchases/suppliers', null, null, {
      material: product,
      organization_id: organizationId
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPurchasesItems = async (organizationId: string) => {
  try {
    const response = await apiFetch('GET', '/purchases/items', null, null, {
      organization_id: organizationId
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPurchases = async (
  urlOptions?: URLOptions
): Promise<Pagination<IPurchaseGeneralBackend, { total2: number; size: number }>> => {
  const response = await apiFetch('GET', getUrl('/purchases', urlOptions));

  return response.data;
};

export const getSupplierSpecificUnits = async (
  organizationId: string,
  item: string,
  supplier: string
) => {
  try {
    const response = await apiFetch('GET', `/units/${item}/${supplier}`, null, null, {
      organization_id: organizationId
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
