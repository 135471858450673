import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import { IPurchaseSupplierFrontend } from 'types/purchaseSupplier';
import { useContext, useState } from 'react';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import { PURCHASE_FRAMEWORK_OPTIONS, mapToPurchaseFramework } from 'utils/mapToPurchaseFramework';
import Button from 'components/ui/button/Button';
import { getCountries, getCountryNameByCode } from 'constants/phonePrefixes';
import { UserContext } from 'context/userContext';
import { patchSupplier } from 'services/api/purchaseSuppliers';
import { supplierFormSchema } from '../../schema/supplierForm';
import { InputSize } from 'types/utilsEnums/input';
import { useMutateCache } from 'lib/ReactQuery/hooks';
import { useFrameworkCodes } from '../../hooks/useFrameworkCodes';
import { extractCode, extractFramework } from './utils';
import type { FormDataType } from './types';
import { usePurchaseCodes } from 'customHooks/translations/usePurchaseCodes';

type Props = {
  data: IPurchaseSupplierFrontend;
  setData: React.Dispatch<React.SetStateAction<IPurchaseSupplierFrontend | null>>;
};

export const SuppliersManagementEditForm: React.FC<Props> = ({ data, setData }) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'purchases.suppliersTable.modal'
  });

  const { invalidate } = useMutateCache(['purchaseSuppliers']);

  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormDataType>({
    name: data.name,
    code: extractCode(data),
    framework: extractFramework(data),
    country: data.country ?? 'ES',
    errors: []
  });

  const { t: frameworkT } = usePurchaseCodes({ keyPrefix: formData.framework });

  const { data: frameworkCodes, isLoading } = useFrameworkCodes({ framework: formData.framework });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const foundOrganization = user?.organizations?.find(
    (item) => item.id === user.selectedOrganization
  );

  const getError = (key: keyof Exclude<FormDataType, 'errors'>) => {
    return formData.errors.find((elem) => elem.error === key);
  };

  const handleSubmit = async () => {
    const parsedFormData = supplierFormSchema.safeParse(formData);

    if (!parsedFormData.success) {
      const error = parsedFormData.error;

      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: error.errors[0].path.join('.'),
          description: t(error?.errors[0]?.message) ?? ''
        })
      }));

      return;
    }
    const response = await patchSupplier({
      id: data.id,
      name: formData.name,
      country: formData.country,
      purchaseCode: {
        code: formData.code,
        framework: formData.framework,
        codeType: data.purchaseCode?.codeType ?? 'sector'
      }
    });

    if (!response) {
      throw new Error('Error updating supplier');
    }

    invalidate();

    setData(null);
  };

  return (
    <div className={`grid-cols-6 gap-x-2 gap-y-8 ${styles['modal']}`}>
      <div className={`col-span-6 gap-y-2 flex-col`}>
        <h3 className='headline3-font'>{t('title')}</h3>
        <p className='subtitle3-font'>{t('subTitle')}</p>
      </div>
      <FormText
        label={t('name')}
        placeholder={t('placeholders.name')}
        icon='/images/icons/briefcase.svg'
        size={InputSize.MEDIUM}
        disabled
        value={formData.name}
        error={getError('name')}
        className='col-span-3'
      />
      <FormSelect
        icon={'/images/icons/pin.svg'}
        placeholder={t('placeholders.country')}
        label={t('country')}
        value={{
          id: formData.country,
          name: getCountryNameByCode(formData.country, i18n.resolvedLanguage)
        }}
        onChange={(value) => onChangeValue('country')(value.id)}
        error={getError('country')}
        options={getCountries(
          i18n.resolvedLanguage,
          foundOrganization ? foundOrganization.country : 'ES'
        )}
        disabled
        className='col-span-3'
      />

      <FormSelect
        className='col-span-2'
        label={t('framework')}
        icon='/images/icons/dropdownOptions.svg'
        size={InputSize.MEDIUM}
        placeholder={t('placeholders.framework')}
        options={PURCHASE_FRAMEWORK_OPTIONS}
        value={{
          id: formData?.framework,
          name: mapToPurchaseFramework(formData?.framework)
        }}
        onChange={(value) => {
          if (value?.id !== formData.framework) {
            onChangeValue('code')('');
          }
          onChangeValue('framework')(value?.id);
        }}
        error={getError('framework')}
      />

      <FormSelect
        label={t('code')}
        icon='/images/icons/inputNumeric.svg'
        size={InputSize.MEDIUM}
        placeholder={t('placeholders.code')}
        options={frameworkCodes}
        value={{
          id: formData?.code,
          name: formData.code ? frameworkT(formData.code) : ''
        }}
        disabled={isLoading}
        loading={isLoading}
        onChange={(value) => onChangeValue('code')(value?.id)}
        error={getError('code')}
        className='col-span-4'
      />

      <Button
        lookAndFeel='secondary'
        text={t('cancel')}
        size='medium'
        className='col-span-3'
        style={{ marginTop: '8px' }}
        onClick={() => setData(null)}
      />
      <Button
        lookAndFeel='primary'
        text={t('save')}
        size='medium'
        className='col-span-3'
        disabled={formData.framework === data.codeType && formData.code === data.code}
        style={{ marginTop: '8px' }}
        onClick={handleSubmit}
      />
    </div>
  );
};
