import { ReactNode } from 'react';
import AccordionItem, { AccordionItemType } from './AccordionItem';
import './styles.scss';

type AccordionType = {
  content?: AccordionItemType[];
  title?: string;
  children?: ReactNode;
};
const Accordion = ({ title, children }: AccordionType) => {
  return (
    <div className='accordion'>
      {title && <h1>{title}</h1>}
      {children}
    </div>
  );
};

Accordion.Item = AccordionItem;

export default Accordion;
