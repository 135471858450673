import { useQuery } from '@tanstack/react-query';
import { getLCAComponents } from '../services/getLCAComponents';

type Params = {
  id?: string;
  enabled?: boolean;
  methodologyId?: string;
  impactCategory?: string;
  parentId?: string;
};

export const useLCAComponents = ({
  id,
  methodologyId,
  impactCategory,
  parentId,
  enabled = true
}: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['lca', id, methodologyId, impactCategory, parentId, enabled],
    queryFn: () => getLCAComponents({ id, methodologyId, impactCategory, parentId }),
    enabled: Boolean(id && methodologyId && impactCategory) && enabled
  });

  return { data, isLoading };
};
