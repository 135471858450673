import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import ButtonArrow, { ButtonArrowType } from '../buttonArrow/ButtonArrow';
import Divider from '../divider/Divider';
import './styles.scss';

type Props = {
  filters: ReactNode[];
  removeFilters: () => void;
  counterFiltersSelected: number;
  handleClickPosition?: () => void;
  maxWidth?: string;
};

const FilterSection = ({
  filters,
  removeFilters,
  counterFiltersSelected = 0,
  handleClickPosition,
  maxWidth = '400px'
}: Props) => {
  const { t } = useTranslation();

  const [showFilters, setShowFilters] = useState(false);
  const [showLeftButton, setShowLeftButton] = useState(true);
  const [showRightButton, setShowRightButton] = useState(true);

  const refFilterCarousel = useRef<HTMLDivElement>(null);

  const handleShowFilters = () => setShowFilters(true);
  const handleHideFilters = () => setShowFilters(false);

  useEffect(() => {
    if (!refFilterCarousel?.current) return;
    handleShowButtonArrows(refFilterCarousel.current);
  }, [showFilters]);

  // handle when to show left and right buttons arrows

  const handleShowButtonArrows = (current: HTMLDivElement, scrollLeft?: number) => {
    const newScrollLeft = scrollLeft ?? current.scrollLeft;

    if (newScrollLeft <= 0) setShowLeftButton(false);
    else setShowLeftButton(true);

    if (newScrollLeft >= current.scrollWidth - current.offsetWidth) setShowRightButton(false);
    else setShowRightButton(true);
  };

  // handlers previous and next

  const handlePrevious = () => {
    if (!refFilterCarousel?.current) return;
    const newScrollLeft = refFilterCarousel.current.scrollLeft - 200;
    refFilterCarousel.current.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
    handleShowButtonArrows(refFilterCarousel.current, newScrollLeft);
    if (handleClickPosition) handleClickPosition();
  };

  const handleNext = () => {
    if (!refFilterCarousel?.current) return;
    const newScrollLeft = refFilterCarousel.current.scrollLeft + 200;
    refFilterCarousel.current.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
    handleShowButtonArrows(refFilterCarousel.current, newScrollLeft);
    if (handleClickPosition) handleClickPosition();
  };

  if (!showFilters)
    return (
      <Button
        style={{ height: '100%' }}
        icon={'/images/icons/blackPlus.svg'}
        lookAndFeel={'secondary'}
        onClick={handleShowFilters}
        size='tiny'>
        <div className='add-filter-body'>
          {t('filter.show')}
          <span className='highlight-text-color'>
            {counterFiltersSelected !== 0 ? counterFiltersSelected : ''}
          </span>
        </div>
      </Button>
    );

  return (
    <div className='filter-content'>
      <div className='filter-buttons'>
        <Button
          icon={'/images/icons/blackCross.svg'}
          text={t('filter.hide')}
          lookAndFeel={'secondary'}
          onClick={handleHideFilters}
          size='tiny'
        />
        <Button
          text={t('filter.remove')}
          lookAndFeel={'secondary'}
          onClick={removeFilters}
          size='tiny'
        />
        <Divider />
      </div>

      {showLeftButton && (
        <div className='button-arrow-wrapper'>
          <ButtonArrow type={ButtonArrowType.LEFT} onClick={handlePrevious} />
        </div>
      )}

      <div className='filters-wrapper'>
        <div className='filters-carousel' style={{ maxWidth }} ref={refFilterCarousel}>
          {filters.map((filter, index) => (
            <div key={index} className='filter-cell'>
              {filter}
            </div>
          ))}
        </div>
      </div>

      {showRightButton && (
        <div className='button-arrow-wrapper'>
          <ButtonArrow type={ButtonArrowType.RIGHT} onClick={handleNext} />
        </div>
      )}
    </div>
  );
};

export default FilterSection;
