import { useTranslation } from 'react-i18next';

export type MilestoneType = {
  id: string;
  title: string;
  img: string;
  steps: { title: string; tooltip?: string }[];
  ribbon: string;
  url: string;
  urlText: string;
};
const useMilestones = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'milestones.elements' });
  const data: MilestoneType[] = [
    {
      id: 'miteco_certification',
      img: '/images/miteco.svg',
      title: t('miteco.title'),
      steps: [
        {
          title: t('miteco.contest')
        },
        { title: t('miteco.grant') },
        { title: t('miteco.marketing') }
      ],
      ribbon: t('miteco.ribbon'),
      url: 'https://drive.google.com/file/d/1iGr9MAafraQ7aGYTP7E6qvVKM5m0iB03/view?usp=sharing',
      urlText: t('miteco.urlText')
    },
    {
      id: 'carbon_footprint_measurement',
      img: '/images/measure.svg',
      title: t('measure.title'),
      steps: [
        { title: t('measure.impactToClient') },
        { title: t('measure.einf.title'), tooltip: t('measure.einf.tooltip') },
        { title: t('measure.report') }
      ],
      ribbon: t('measure.ribbon'),
      url: 'https://drive.google.com/file/d/1YP1AndqpQ6Q8ur3GEnmPt_bNs8mi0DuL/view?usp=sharing',
      urlText: t('measure.urlText')
    },
    {
      id: 'complete_certifications',
      img: '/images/communicate.svg',
      title: t('communicate.title'),
      steps: [
        {
          title: t('communicate.carbonNegative.title'),
          tooltip: t('communicate.carbonNegative.tooltip')
        },
        {
          title: t('communicate.carbonNeutral.title'),
          tooltip: t('communicate.carbonNeutral.tooltip')
        },
        { title: t('communicate.others') }
      ],
      ribbon: t('communicate.ribbon'),
      url: 'https://drive.google.com/file/d/1z00Jl2ImWPCGzCedcm79GZjRlNO_craE/view?usp=sharing',
      urlText: t('communicate.urlText')
    }
  ];
  return data;
};

export default useMilestones;
