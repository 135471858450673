export default function checkFormErrors(
  formData: Record<string, any>,
  currentErrors: ErrorType[],
  optionalFields: string[] = []
) {
  const newErrors: ErrorType[] = [...currentErrors];

  const parseValueToCheck = (value: any) => {
    if (typeof value === 'object' && !value) {
      return value;
    }
    if (typeof value === 'object' && 'id' in value) {
      return value.id;
    }
    if (typeof value === 'object' && 'phone' in value) {
      return value.phone;
    }

    return value;
  };

  Object.keys(formData).forEach((key: string) => {
    // Get value to check depending on the formData[key] type
    let valueToCheck = parseValueToCheck(formData[key]);

    if ((!isNaN(valueToCheck) && valueToCheck === 0) || valueToCheck === false) {
      // If value is 0, it's not an error

      valueToCheck = true;
    }

    if (
      !valueToCheck &&
      !optionalFields.includes(key) &&
      key !== 'errors' &&
      !currentErrors.some((elem) => elem.error === key)
    ) {
      newErrors.push({ error: key });
    }
  });
  return newErrors;
}
