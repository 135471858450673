import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { getCountryNameByCode } from '../../../../constants/phonePrefixes';
import { gray_color2, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import { InvestmentReport } from '../../../../types/entities/holdings';
import { limitString } from '../../../../utils/limitString';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Tag from '../../../ui/tag/Tag';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';
import './styles.scss';

type InvestmentsByCountry = {
  country: string;
  co2e: number;
  company_name: string;
  company_id: string;
};
type Props = {
  investments: InvestmentReport[];
};
const Investments = ({ investments }: Props) => {
  const { t, i18n } = useTranslation();
  const colors = [scope1_color, scope2_color, scope3_color];

  let totalCo2 = 0;
  investments.forEach((elem) => {
    totalCo2 += elem.co2e;
  });
  const calculateRemaining = () => {
    let top3Co2 = 0;
    let total = 0;
    if (investments.length < 4) {
      return 0;
    }
    investments.forEach((elem, index) => {
      if (index < 3) {
        top3Co2 += elem.co2e;
      }
      total += elem.co2e;
    });
    return total - top3Co2;
  };

  const renderCo2Information = () => {
    return (
      <div className='co2-information'>
        <div className='co2-value co2-dashboard-font-small'>{numberToDecimal(totalCo2 / 1000)}</div>
        <span className='co2-units on-light-text-color body1-font'>t CO₂ eq.</span>
      </div>
    );
  };

  const renderCountriesInfo = (
    investmentsByCountrySorted: InvestmentsByCountry[],
    remainingCo2: number
  ) => {
    return (
      <div className='countries-wrapper'>
        {investmentsByCountrySorted.slice(0, 3).map((elem, index) => (
          <Tag
            key={elem.company_id}
            tags={[
              {
                icon1: `/images/icons/dotScope${index + 1}.svg`,
                text: (
                  <span className='on-light-text-color tag-font'>
                    {getCountryNameByCode(elem.country, i18n.resolvedLanguage)}
                  </span>
                )
              },
              {
                text: (
                  <span className='on-light-text-color tag-bigger-font'>
                    {numberToDecimal(elem.co2e / 1000)}
                  </span>
                )
              },
              {
                text: <span className='on-light-text-color tag-smaller-font'>t CO₂ eq.</span>
              }
            ]}
          />
        ))}
        <Tag
          key='remaining'
          tags={[
            {
              icon1: `/images/icons/dotGrayBigger.svg`,
              text: <span className='on-light-text-color tag-font'>{t('report.remaining')}</span>
            },
            {
              text: (
                <span className='on-light-text-color tag-bigger-font'>
                  {numberToDecimal(remainingCo2 / 1000)}
                </span>
              )
            },
            {
              text: <span className='on-light-text-color tag-smaller-font'>t CO₂ eq.</span>
            }
          ]}
        />
      </div>
    );
  };

  // Group investments by country and sum co2e
  const investmentsByCountry: InvestmentsByCountry[] = [];
  investments.forEach((child) => {
    const country = investmentsByCountry.findIndex((country) => country.country === child.country);
    if (country === -1) {
      investmentsByCountry.push({
        country: child.country,
        co2e: child.co2e,
        company_name: child.org_name,
        company_id: child.id
      });
    } else {
      investmentsByCountry[country].co2e += child.co2e;
    }
  });

  // sort investmentsByCountry by co2e
  const investmentsByCountrySorted = investmentsByCountry.sort((a, b) => b.co2e - a.co2e);

  // create pie chart data

  const pieChartData = [{ title: 'remaining', value: 100, color: gray_color2 }];

  investmentsByCountrySorted.slice(0, 3).forEach((elem, index) => {
    pieChartData.push({
      title: elem.country,
      value: (elem.co2e * 100) / totalCo2,
      color: colors[index]
    });
  });
  let remainingCo2 = 0;
  investmentsByCountrySorted.slice(3).forEach((elem) => {
    pieChartData[0].value -= (elem.co2e * 100) / totalCo2;
    remainingCo2 += elem.co2e;
  });

  pieChartData.sort((a, b) => {
    return b.value - a.value;
  });

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader
          title={t('report.investments')}
          description={t('report.investmentsDescription')}
        />
        <CardTotalCo2 totalCo2={totalCo2} />
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.top3Companies')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
          <span className='body2-font'>% Total</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {investments.slice(0, 3).map((investment) => (
            <div className='card-info-rows' key={investment.id}>
              <span className='name-logo body1-bold-font'>
                <img src='/images/icons/organization.svg' alt='organization' />
                {limitString(investment.org_name, 20)}
              </span>
              <span className='body1-font'>{numberToDecimal(investment.co2e / 1000)}</span>
              <span className='percentage body1-font tag-bg-text-color'>{`${
                totalCo2 ? numberToDecimal((investment.co2e * 100) / totalCo2) : 0
              } %`}</span>
            </div>
          ))}
          <div className='card-info-rows '>
            <span className='name-logo body1-bold-font'>
              <img src='/images/icons/organization.svg' alt='organization' />
              {t('report.remaining')}
            </span>
            <span className='body1-font'>{numberToDecimal(calculateRemaining() / 1000)}</span>
            <span className='percentage body1-font tag-bg-text-color'>{`${
              totalCo2 ? numberToDecimal((calculateRemaining() * 100) / totalCo2) : 0
            } %`}</span>
          </div>
        </div>
      </div>
      <div className='card card-piechart main-bg-color'>
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.geographicalDistribution')}</span>
        </div>
        <div className='card-info-co2 on-card-gray-bg-color'>
          <div className='co2-progress-bar'>
            <PieChart // your data
              data={pieChartData}
              // width and height of the view box
              viewBoxSize={[100, 100]}
              lineWidth={24}
              totalValue={100}
              rounded
              animate></PieChart>
            {renderCo2Information()}
          </div>
          {renderCountriesInfo(investmentsByCountrySorted, remainingCo2)}
        </div>
        <div className='card-footer'>
          <span className='body2-font'>{t('report.total')}</span>
        </div>
        <div className='total-info-cards'>
          <div className='total-info on-card-gray-bg-color'>
            <span className='body2-font'>{t('report.totalCompanies')}</span>
            <span className='highlight-report-text-color body1-bold-font'>
              {investments.length}
            </span>
          </div>
          {/* <div className='total-info on-card-gray-bg-color'>
            <span className='body2-font'>{t('report.dataQuality')}</span>
            <span className='highlight-report-text-color body1-bold-font'>
              {numberToDecimal(dataQuality)}%
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Investments;
