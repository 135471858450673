import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User, UserEdit } from '../../types/entities/user';
import apiFetch from '../../utils/apiFetch';
import Button from '../ui/button/Button';
import Select from '../ui/formComponents/select/Select';

import './styles.scss';

type Props = {
  editMember: (member: UserEdit, id: string) => void;
  memberToEdit: User;
  user: User | null;
  members: User[];
};
function EditCreateMember({ memberToEdit, user, editMember, members }: Props) {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  const [role, setRole] = useState({
    id: memberToEdit?.role || '',
    name: t(`members.${memberToEdit.role}`)
  });

  const [errors, setErrors] = useState<ErrorType[]>([]);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleEditMember();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const onChangeRole = (value: SelectOptionFormat) => {
    const newErrors = errors.filter((elem) => elem.error !== 'role');
    setErrors(newErrors);

    setRole(value);
  };

  const handleEditMember = () => {
    const newErrors = [...errors];

    if ((!role.id || !role.name) && !errors.some((elem) => elem.error === 'role')) {
      newErrors.push({ error: 'role' });
    }

    // Check if you are changing only owner to member, if it is like that do not allow request because there has to be at least 1 owner

    const owners = members.filter((member) => member.role === 'owner');

    if (owners?.length === 1 && owners[0].id === memberToEdit.id && role.id !== 'owner') {
      newErrors.push({
        error: 'role',
        description: t('members.atLeast1Owner')
      });
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
    } else {
      if (memberToEdit) {
        setLoadingButton(true);
        apiFetch(
          'patch',
          `/memberships/users/${memberToEdit.id}/organizations/${user?.selectedOrganization}`,
          {
            role: role.id
          }
        )
          .then((response) => {
            if (editMember) {
              editMember(response.data, memberToEdit.id);
            }
            setLoadingButton(false);
          })
          .catch((err) => {
            setLoadingButton(false);
          });
      }
    }
  };

  return (
    <div className='edit-member'>
      <h1 className='headline4-font'>{`${t('members.editMember')} ${memberToEdit.first_name}`}</h1>
      <h2 className='subtitle3-font '>{t('members.editMemberDescription')}</h2>
      <div className='select-inputs-wrapper'>
        <Select
          icon={'/images/icons/key.svg'}
          placeholder={t('members.selectRole')}
          label={t('members.role')}
          value={role}
          onChangeValue={onChangeRole}
          options={[
            {
              id: 'owner',
              name: t('members.owner')
            },
            {
              id: 'member',
              name: t('members.member')
            }
          ]}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'role')}
        />
      </div>

      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('members.save')}
          onClick={handleEditMember}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default EditCreateMember;
