import { HTMLAttributes } from 'react';
import formatNumber from '../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import IconBackground from '../../iconBackground/IconBackground';
import './styles.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  listView?: boolean;
}
const Card = ({ children, listView, ...rest }: Props) => {
  return (
    <div
      className={`card-wrapper card-border-color ${listView ? 'card-wrapper--list' : ''}`}
      {...rest}>
      {children}
    </div>
  );
};
// ##########################################################################################
// Top right element, commonly an icon as a button
const CardOptions = ({ children }: { children: React.ReactNode }) => {
  return <div className='card-wrapper__options'>{children}</div>;
};

Card.Options = CardOptions;

// ##########################################################################################
type CardIconProps = React.ComponentProps<typeof IconBackground>;
const CardIcon = (props: CardIconProps) => {
  return <IconBackground {...props} />;
};

Card.Icon = CardIcon;

// ##########################################################################################
const CardContent = ({ children }: { children: React.ReactNode }) => {
  return <div className='card-wrapper__content'>{children}</div>;
};

Card.Content = CardContent;

// ##########################################################################################
const CardContentDisabled = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='card-wrapper__content' style={{ opacity: 0.5 }}>
      {children}
    </div>
  );
};

Card.ContentDisabled = CardContentDisabled;

// ##########################################################################################
interface PropsCardTotal extends HTMLAttributes<HTMLDivElement> {
  total: number;
  unit: string;
}
const CardTotal = ({ total, unit, ...rest }: PropsCardTotal) => {
  return (
    <div className='card-wrapper__total' {...rest}>
      <span className='highlight-text-color small-card-font'>
        {formatNumber(numberToDecimalNonZero(total, 2))}
      </span>
      <span className='body1-font'>{unit}</span>
    </div>
  );
};

Card.Total = CardTotal;

export default Card;
