import numberToDecimal from './numberToDecimal';

const formatNumber = (value?: number) => {
  if (!value) return '0';
  const language = navigator.language || 'es-ES';
  return value.toLocaleString([language, language]);
};

export default formatNumber;

export const formatRoundNumber = (value?: number) => {
  if (!value) return '0';
  const language = navigator.language || 'es-ES';
  value = numberToDecimal(Number(value));
  return value.toLocaleString([language, language]);
};

export const formatNumberToNdecimal = (value?: number, decimals = 2) => {
  if (!value) return '0.00';
  const language = navigator.language || 'es-ES';
  return value.toLocaleString([language, language], {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};
