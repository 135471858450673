import useCriteriaContext from '../../../hooks/useCriteriaContext';

const useOptions = () => {
  const { criteriaContext } = useCriteriaContext();

  const inscriptionYear = criteriaContext.criteriaList.find(
    (criteria) => criteria.criteria === 'C6'
  )?.submit_year;

  let options: SelectOptionFormat[] = [];
  if (inscriptionYear) {
    options = [
      {
        id: String(inscriptionYear - 1),
        name: String(inscriptionYear - 1)
      },
      {
        id: String(inscriptionYear - 2),
        name: String(inscriptionYear - 2)
      }
    ];
  }

  return options;
};

export default useOptions;
