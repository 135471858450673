import { useTranslation } from 'react-i18next';

const useTransportOptions = () => {
  const { t } = useTranslation();

  const transportTypes = [
    { id: 'air', name: t('shipments.air') },
    { id: 'rail', name: t('shipments.rail') },
    { id: 'maritime', name: t('shipments.maritime') },
    { id: 'truck', name: t('shipments.truck') },
    { id: 'car', name: t('shipments.car') },
    { id: 'electric_car', name: t('shipments.electric_car') },
    { id: 'motorbike', name: t('shipments.motorbike') },
    { id: 'bicycle', name: t('shipments.bicycle') },
    { id: 'electric_bicycle', name: t('shipments.electric_bicycle') },
    { id: 'electric_kick_scooter', name: t('shipments.electric_kick_scooter') },
    { id: 'do_not_know', name: t('shipments.doNotKnow') }
  ];
  return transportTypes;
};

export default useTransportOptions;
