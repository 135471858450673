import Bubble from './notificationBubble/NotificationBubble';
import './styles.scss';
type Props = {
  children: React.ReactNode;
  total: number;
  animation?: 'bounce' | 'rubberBand' | 'shake' | 'wobble' | 'jello';
  animationIntervalSeconds?: number;
};
const NotificationBubbleWrapper = ({
  children,
  animation = 'bounce',
  animationIntervalSeconds = 3,
  total
}: Props) => {
  return (
    <div className='notification-bubble-wrapper'>
      {children}
      {total > 0 && (
        <Bubble
          style={{
            position: 'absolute',
            top: '-6px',
            left: 'calc(100% - 12px)',
            fontSize: total >= 100 ? '8px' : '12px'
          }}
          animation={animation}
          animationIntervalSeconds={animationIntervalSeconds}>
          {total >= 100 ? '+99' : total}
        </Bubble>
      )}
    </div>
  );
};

export default NotificationBubbleWrapper;
