import { useState } from 'react';
import { BlueTag } from '../../../../../ui/blueTag/BlueTag';
import Button from '../../../../../ui/button/Button';
import { RadioButton } from '../../../../../ui/formComponents2/radioButton/RadioButton';
import Modal from '../../../../../ui/modal/Modal';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { useForm } from './FormContext';
import { STEP_ENUM } from './constants';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import { useCreateLCAModel } from '../../hooks/useCreateLCAModel';
import { useNavigate } from 'react-router-dom';
import { LCA_DETAIL_OPTIONS } from '../../../LCADetail/constants';

type Props = {
  setSection: (section: string) => void;
  onClose: () => void;
};

const IMPACT_CATEGORIES = {
  recommended: ['climate_change', 'eutrophication', 'water_use'],
  all: [
    'climate_change',
    'climate_change_fossil',
    'climate_change_biogenic',
    'climate_change_land_use',
    'ozone_depletion',
    'human_toxicity_carcinogenic',
    'human_toxicity_non_carcinogenic',
    'particulate_matter_formation',
    'ionising_radiation',
    'ozone_depletion',
    'acidification',
    'eutrophication_terrestrial',
    'eutrophication_freshwater',
    'eutrophication_marine',
    'ecotoxicity_freshwater',
    'land_use',
    'water_scarcity',
    'material_resources_depletion',
    'fossil_fuels_depletion'
  ]
};

const detectCheckedOption = (impactCategories: string[]) => {
  if (impactCategories.length === 3) return 'recommended';
  if (impactCategories.length === 19) return 'all';
  return '';
};

export const ImpactCategoriesPage: React.FC<Props> = ({ onClose }) => {
  const { form, setForm, partialValidate, errors, validate } = useForm();
  const navigate = useNavigate();
  const { createLCAModel } = useCreateLCAModel();

  const [checkedOption, setCheckedOption] = useState(detectCheckedOption(form.impactCategories));

  const { t } = useLCATranslation({ keyPrefix: 'portfolio.createLCA.modal' });
  const { t: impactCategoriesT } = useLCATranslation({ keyPrefix: 'lcaDetail.impactCategories' });

  const handleOptionChange = (option: string) => (value: boolean) => {
    if (value && option in IMPACT_CATEGORIES) {
      setCheckedOption(option);
      setForm((prev) => {
        const newValue = {
          ...prev,
          impactCategories: IMPACT_CATEGORIES[option as keyof typeof IMPACT_CATEGORIES]
        };

        if (errors?.impactCategories) {
          partialValidate(STEP_ENUM.IMPACT_CATEGORIES, 'impactCategories', newValue);
        }

        return newValue;
      });
    }
  };

  const onSubmit = async () => {
    const isValid = validate(STEP_ENUM.IMPACT_CATEGORIES);

    if (!isValid) return;

    const response = await createLCAModel(form);

    navigate(`/lca/portfolio/${response.acv_id}?mode=${LCA_DETAIL_OPTIONS.canvas}`);
  };

  return (
    <>
      <Modal.Content>
        <div className='flex-col gap-y-4 mb-10'>
          <div className='flex flex-col gap-y-1'>
            <RadioButton
              text={t('recommendedPackage')}
              onChange={handleOptionChange('recommended')}
              selected={checkedOption === 'recommended'}
            />
            <div className='flex gap-x-1'>
              {IMPACT_CATEGORIES.recommended.map((category) => (
                <BlueTag key={category}>{impactCategoriesT(category)}</BlueTag>
              ))}
            </div>
          </div>
          <div className='flex flex-col gap-y-1'>
            <RadioButton
              text={t('completePackage')}
              onChange={handleOptionChange('all')}
              selected={checkedOption === 'all'}
            />
            <div className='flex gap-x-1'>
              <TooltipWrapper
                text={IMPACT_CATEGORIES.all
                  .map((category) => impactCategoriesT(category))
                  .join('\n')}>
                <BlueTag>{t('allImpactCategories')}</BlueTag>
              </TooltipWrapper>
            </div>
          </div>
          {errors?.impactCategories && (
            <ErrorText style={{ marginTop: '4px' }}>
              {errors.impactCategories.description}
            </ErrorText>
          )}
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Button lookAndFeel='secondary' text={t('cancelButton')} size='medium' onClick={onClose} />
        <Button lookAndFeel='primary' text={t('startLCA')} size='medium' onClick={onSubmit} />
      </Modal.Buttons>
    </>
  );
};
