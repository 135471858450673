import React from 'react';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../ui/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../constants/routes';

const RequestRenewableEnergy = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'requestRenewableEnergy' });
  const navigate = useNavigate();
  const navigateToBookMeeting = () => {
    navigate(ROUTES.BOOK_MEETING);
  };
  return (
    <div className='flex flex-col items-center gap-6' style={{ textAlign: 'center' }}>
      <img src='/images/woman.svg' alt='woman' width='192' />
      <span className='font-20 weight-600'>{t('title')}</span>
      <span className='font-14 weight-400 '>{t('description')}</span>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          onClick={navigateToBookMeeting}
          text={t('talkToExpert')}
          size='small'
        />
      </FormButtonSection>
    </div>
  );
};

export default RequestRenewableEnergy;
