import { SIZE_PAGINATION_BIG } from '../../constants';
import apiFetch from '../../utils/apiFetch';
import { convertStringToDate } from '../../utils/convertDates';
import dateToTimestamp from '../../utils/dateToTimestamp';

const HEAVY_FILE_STATUS_URL = '/heavy_files/status';

type GetProps = {
  category: string;
  nextToken: string | null;
  size?: number;
  startDate?: string;
  endDate?: string;
  name?: string;
  status?: string;
};

export const getGroupingByCategory = async ({
  category,
  nextToken,
  size = SIZE_PAGINATION_BIG,
  startDate,
  endDate,
  name,
  status
}: GetProps) => {
  try {
    const params = new URLSearchParams();

    if (nextToken) params.append('next_token', nextToken);
    params.append('size', size.toString());
    if (startDate)
      params.append('start_date', dateToTimestamp(convertStringToDate(startDate)).toString());
    if (endDate)
      params.append('end_date', dateToTimestamp(convertStringToDate(endDate)).toString());
    if (name) params.append('name', name);
    if (status) params.append('status', status);

    const response = await apiFetch(
      'GET',
      `${HEAVY_FILE_STATUS_URL}/${category}?${params.toString()}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
