import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/routes';
import Button from '../../../../ui/button/Button';
import Tooltip from '../../../../ui/tooltip/Tooltip';

import './styles.scss';
import { checkFreePlan } from '../../../../../utils/checkFreePlan';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';

function Tasks() {
  const navigate = useNavigate();

  const organization = useSelectedOrganization();

  const { t } = useTranslation();

  const navigateToTasks = () => {
    navigate(ROUTES.TODOS);
  };

  return (
    <div className='improve-tasks card'>
      <div className='card-header'>
        <h5 className='headline4-font'>{t('improveMain.tasks')}</h5>
        <div className='right-section'>
          <Button
            lookAndFeel={checkFreePlan(organization) ? 'blocked' : 'primary'}
            text={t('improveMain.goToTasks')}
            onClick={navigateToTasks}
            size='small'
          />
          <Tooltip text={t('improveMain.tasksTooltip')} position='left' />
        </div>
      </div>
      <div className='card-content'>
        <div>
          <h1 className='headline2-font'>{t('improveMain.tasksTitle')}</h1>
          <p className='subtitle2-font'>{t('improveMain.tasksDescription')}</p>
        </div>
        <img src='/images/tasksPreview.svg' alt='tasks preview' />
      </div>
    </div>
  );
}

export default Tasks;
