export enum FuelTypes {
  ELECTRIC = 'electric',
  DO_NOT_KNOW = 'do_not_know',
  ELECTRIC_RENEWABLE = 'electric_renewable'
}

export enum Status {
  ACTIVE = 'active',
  PENDING = 'pending',
  ERROR = 'error',
  IN_PROGRESS = 'in_progress',
  LOADING = 'loading',
  BLOCKED = 'blocked',
  TERMINATED = 'blocked'
}
