import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../../context/userContext';
import { ValueUnitsLogistics } from '../../../../../../types/entities/logistics';

type Props = {
  startDate: string;
  endDate: string;
};

type TocsInfo = {
  vehicleId: string;
  iv: ValueUnitsLogistics;
  tkm: ValueUnitsLogistics;
  kgCO2e: ValueUnitsLogistics;
  tonsTransported: ValueUnitsLogistics;
  kmTotal: ValueUnitsLogistics;
  kmEmpty: ValueUnitsLogistics;
};

type TocsInfoResponse = {
  [vehicleId: string]: {
    iv: ValueUnitsLogistics;
    tkm: ValueUnitsLogistics;
    kgCO2e: ValueUnitsLogistics;
    tonsTransported: ValueUnitsLogistics;
    kmTotal: ValueUnitsLogistics;
    kmEmpty: ValueUnitsLogistics;
  };
};
const useGetData = ({ startDate, endDate }: Props) => {
  const user = useContext(UserContext);
  const [tocsInfo, setTocsInfo] = useState<TocsInfo[]>([]);
  const fetchData = async () => {
    // const response = await getLogisticsReport(
    //   '',
    //   convertStringToDate(startDate),
    //   convertStringToDate(endDate),
    //   user?.selectedOrganization ?? ''
    // );

    const _tocsInfo: TocsInfoResponse = {
      air_unknown_long_haul_more_than_1500_km_kerosene: {
        iv: {
          value: 971,
          units: 'g CO₂e/tkm'
        },
        tkm: {
          value: 10761.69,
          units: 'tkm'
        },
        kgCO2e: {
          value: 10449.6,
          units: 'kg CO₂e'
        },
        tonsTransported: {
          value: 1,
          units: 't'
        },
        kmTotal: {
          value: 10761.694,
          units: 'km'
        },
        kmEmpty: {
          value: 0,
          units: 'km'
        }
      },
      rigid_truck_12_20_t_gvw_average_diesel: {
        iv: {
          value: 179,
          units: 'g CO₂e/tkm'
        },
        tkm: {
          value: 42,
          units: 'tkm'
        },
        kgCO2e: {
          value: 7.52,
          units: 'kg CO₂e'
        },
        tonsTransported: {
          value: 0.5,
          units: 't'
        },
        kmTotal: {
          value: 84,
          units: 'km'
        },
        kmEmpty: {
          value: 14.28,
          units: 'km'
        }
      },
      general_cargo_non_container_vessel_0_4999_dwt_mdo: {
        iv: {
          value: 25.8,
          units: 'g CO₂e/tkm'
        },
        tkm: {
          value: 4259.6,
          units: 'tkm'
        },
        kgCO2e: {
          value: 109.9,
          units: 'kg CO₂e'
        },
        tonsTransported: {
          value: 0.5,
          units: 't'
        },
        kmTotal: {
          value: 8519.2,
          units: 'km'
        },
        kmEmpty: {
          value: 0,
          units: 'km'
        }
      },
      train_average_diesel: {
        iv: {
          value: 16.2,
          units: 'g CO₂e/tkm'
        },
        tkm: {
          value: 474.76,
          units: 'tkm'
        },
        kgCO2e: {
          value: 7.69,
          units: 'kg CO₂e'
        },
        tonsTransported: {
          value: 0.5,
          units: 't'
        },
        kmTotal: {
          value: 949.51,
          units: 'km'
        },
        kmEmpty: {
          value: 313.338,
          units: 'km'
        }
      },
      artic_truck_up_to_40_t_gvw_average_diesel: {
        iv: {
          value: 2167.72,
          units: 'g CO₂e/tkm'
        },
        tkm: {
          value: 389.03,
          units: 'tkm'
        },
        kgCO2e: {
          value: 843.3,
          units: 'kg CO₂e'
        },
        tonsTransported: {
          value: 0.5,
          units: 't'
        },
        kmTotal: {
          value: 778.05,
          units: 'km'
        },
        kmEmpty: {
          value: 132.269,
          units: 'km'
        }
      },
      north_american_road_tl_dry_van: {
        iv: {
          value: 94,
          units: 'g CO₂e/tkm'
        },
        tkm: {
          value: 67.59,
          units: 'tkm'
        },
        kgCO2e: {
          value: 6.35,
          units: 'kg CO₂e'
        },
        tonsTransported: {
          value: 0.5,
          units: 't'
        },
        kmTotal: {
          value: 135.188,
          units: 'km'
        },
        kmEmpty: {
          value: 0,
          units: 'km'
        }
      }
    };

    const tocsInfoParsed: TocsInfo[] = Object.entries(_tocsInfo).map(([key, value]) => ({
      vehicleId: key,
      iv: value.iv,
      tkm: value.tkm,
      kgCO2e: value.kgCO2e,
      tonsTransported: value.tonsTransported,
      kmTotal: value.kmTotal,
      kmEmpty: value.kmEmpty
    }));

    setTocsInfo(tocsInfoParsed);
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);
  return { tocsInfo };
};

export default useGetData;
