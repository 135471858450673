import React from 'react';

type Props<T> = {
  initialStack: T[];
};

function useStack<T>({ initialStack }: Props<T>) {
  const [stack, setStack] = React.useState<T[]>(initialStack);

  const pushToStack = (item: T) => {
    setStack([...stack, item]);
  };

  const popFromStack = () => {
    if (stack.length === 0) {
      return;
    }
    const newStack = [...stack];
    const poppedItem = newStack.pop();
    setStack(newStack);
    return poppedItem;
  };

  const currentElement = stack[stack.length - 1];

  const initStack = (value: T[] = initialStack) => {
    setStack(value);
  };

  return { stack, pushToStack, popFromStack, currentElement, size: stack.length, initStack };
}

export default useStack;
