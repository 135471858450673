import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import { PredefinedTask } from '../../../types/entities/task';

import { convertDateBackToFrontTimestamp } from '../../../utils/convertDates';
import replaceLinkWithAnchorTag from '../../../utils/replaceLinkWithAnchorTag';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import Checkbox from '../../ui/formComponents/checkbox/Checkbox';
import FormSelect from '../../ui/formComponents2/formInputs/formSelect/FormSelect';
import Label from '../../ui/label/Label';
import Modal from '../../ui/modal/Modal';
import TableComponent from '../../ui/table/Table';
import CreateTasks from './components/CreateTasks';
import useGetData from './hooks/useGetData';
import usePredefinedTasks from './hooks/usePredefinedTasks';
import './styles.scss';

type FormData = {
  organization: SelectOptionFormat;
  tasks: PredefinedTask[];
  errors: ErrorType[];
};
const TasksList = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    organization: {
      id: '',
      name: ''
    },
    tasks: [],
    errors: []
  });

  const [showCreate, setShowCreate] = useState<boolean>(false);

  const { organizationOptions, members } = useGetData({
    organization_id: formData.organization.id
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const predefinedTasks = usePredefinedTasks(formData.organization.id);

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      tasks: predefinedTasks
    }));
  }, [JSON.stringify(predefinedTasks)]);

  const columns = [
    {
      title: t('tasks.category'),
      dataIndex: 'category',
      key: 'category'
    },

    {
      title: t('tasks.assignedTo'),
      dataIndex: 'assigned_to',
      key: 'assigned_to'
    },
    {
      title: t('tasks.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('tasks.tags'),
      dataIndex: 'tags',
      key: 'tags'
    },
    {
      title: t('tasks.dueDate'),
      dataIndex: 'due_date',
      key: 'due_date'
    }
  ];

  const renderCategory = (task: PredefinedTask) => {
    const { category } = task;
    return (
      <div className='category'>
        <Checkbox
          type='square'
          text={<span className={`span-font `}>{t(`tasks.categories.${category}`)}</span>}
          onChange={(value: boolean) => {
            if (!task.assigned_to.id) {
              setFormData((prev) => ({
                ...prev,
                errors: [...prev.errors, { error: task.id, message: 'Select a member' }]
              }));
              return;
            }
            // Set selected to true to task of formData with same id
            const tasks = [...formData.tasks];
            const index = tasks.findIndex((elem) => elem.description === task.description);
            tasks[index].selected = value;
            setFormData((prev) => ({
              ...prev,
              tasks
            }));
          }}
          selected={formData.tasks.find((elem) => elem.id === task.id)?.selected ?? false}
        />
      </div>
    );
  };
  const renderTags = (tags: string[]) => {
    return (
      <div
        className='tags'
        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '0.5rem' }}>
        {tags.map((elem) => (
          <Label lookAndFeel='secondary' key={elem}>
            {elem}
          </Label>
        ))}
      </div>
    );
  };

  const addAssignedTo = (id: string, value: SelectOptionFormat) => {
    const tasks = [...formData.tasks];
    const index = tasks.findIndex((elem) => elem.id === id);
    tasks[index].assigned_to = value;
    setFormData((prev) => ({
      ...prev,
      tasks,
      errors: prev.errors.filter((error) => error.error !== id)
    }));
  };

  const parseData = (data: PredefinedTask[]) => {
    return data.map((elem, index) => ({
      ...elem,
      description: replaceLinkWithAnchorTag(elem.description, t('tasks.link')),
      category: renderCategory(elem),
      tags: renderTags(elem.tags),
      due_date: convertDateBackToFrontTimestamp(elem.due_date),
      assigned_to: (
        <FormSelect
          placeholder={'Select a member'}
          options={members}
          value={elem?.assigned_to ?? { id: '', name: '' }}
          onChange={(value: SelectOptionFormat) => addAssignedTo(elem.id, value)}
          error={formData.errors.find((error) => error.error === elem.id)}
          avoidOffScreen={data.length - 1 === index}
        />
      )
    }));
  };

  const handleCreateTasks = () => {
    setShowCreate(false);
    dispatch(setNotification(t('notification.createTask')));
  };

  return (
    <div className='admin-tasks'>
      <SectionHeader title={t('tasks.title')} />
      <div
        style={{
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '1rem',
          boxSizing: 'border-box'
        }}>
        <div className='admin-tasks__header'>
          <FormSelect
            icon='/images/icons/organization.svg'
            placeholder={'Select an organization'}
            label='Organization'
            options={organizationOptions}
            value={formData.organization}
            onChange={onChangeValue('organization')}
            error={formData.errors.find((error) => error.error === 'organization')}
          />
          <Button
            lookAndFeel='primary'
            text='Create'
            onClick={() => setShowCreate(true)}
            style={{ width: 'fit-content' }}
            size='medium'
            disabled={
              formData.tasks.filter((elem) => elem.selected && elem.assigned_to).length === 0
            }
          />
        </div>
        <div className='predefined-tasks-list' style={{ width: '100%', paddingBottom: '150px' }}>
          <TableComponent data={parseData(formData.tasks)} columns={columns} loading={false} />
        </div>
      </div>
      <Modal show={showCreate} onClose={() => setShowCreate(false)}>
        <CreateTasks
          tasks={formData.tasks.filter((elem) => elem.selected && elem.assigned_to)}
          handleCreateTasks={handleCreateTasks}
        />
      </Modal>
    </div>
  );
};

export default TasksList;
