import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import FormWrapper from '../../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormTextArea from '../../../../../../../ui/formComponents2/formInputs/formTextArea/FormTextArea';
import { FormDataStateESG } from '../ModalDatapoint';

type Props = {
  formDataState: FormDataStateESG;
};

export const TextAreaComponent = ({ formDataState }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { formData, setFormData } = formDataState;
  const { onChangeValue } = useOnChangeValue({ setFormData });
  return (
    <FormWrapper>
      <FormTextArea
        label={t('textareaModalLabel')}
        value={formData.value as string}
        onChange={onChangeValue('value')}
        error={formData.errors.find((elem: ErrorType) => elem.error === 'value')}
      />
    </FormWrapper>
  );
};
