import { useState } from 'react';
import Modal from '../../../../ui/modal/Modal';
import YoutubeVideo from '../../../../ui/videoPlayer/YoutubeVideo';
import VideoTutorialPopup from '../../../../ui/videoTutorialPopup/VideoTutorialPopup';
import { useTranslation } from 'react-i18next';
import { GLEC_ARTICLE, VIDEOS } from '../../../../../constants';

const Video = () => {
  const { t, i18n } = useTranslation();
  const videos = VIDEOS.logistics as { [key: string]: string };
  const videoLink = videos[i18n.resolvedLanguage] as string;

  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <VideoTutorialPopup
        title={t('logistics.glecLogistic')}
        description={t('logistics.discover')}
        image='/images/miguel.jpg'
        onClick={() => setShowVideo(true)}
      />
      <Modal show={showVideo} onClose={() => setShowVideo(false)} width='700px'>
        <h3 className='font-headings-h3-sb mb-8'>{t('logistics.video.title')}</h3>
        <div className='flex-col gap-y-4 font-body-b2-sb mb-8'>
          <p className='m-0'>{t('logistics.video.subTitle')}</p>
          <a
            href={
              i18n.resolvedLanguage in GLEC_ARTICLE
                ? GLEC_ARTICLE[i18n.resolvedLanguage as 'en' | 'es']
                : GLEC_ARTICLE.es
            }
            target='_blank'
            rel='noreferrer noopener'
            className='link'>
            {t('logistics.video.articleLink')}
          </a>
        </div>
        <div className='flex-col gap-y-2 font-body-b2-sb'>
          <p className='m-0'>{t('logistics.video.videoTitle')}</p>
          <YoutubeVideo url={videoLink} />
        </div>
      </Modal>
    </>
  );
};

export default Video;
