import { useTranslation } from 'react-i18next';
import { formatRoundNumber } from '../../../../utils/formatNumber';
import './styles.scss';

type Props = {
  totalCo2: number;
};
function CardTotalCo2({ totalCo2 }: Props) {
  const { t } = useTranslation();
  return (
    <div className='report-card-total-co2'>
      <h2 className='body2-font'>{t('report.total')}</h2>
      <div className='card-info-co2 on-card-gray-bg-color'>
        <span className='co2 co2-dashboard-font'>{formatRoundNumber(totalCo2 / 1000)}</span>
        <span className='unit body3-font'>t CO₂ eq.</span>
      </div>
    </div>
  );
}

export default CardTotalCo2;
