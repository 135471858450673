import { useTranslation } from 'react-i18next';
import { sectors } from '../../../../constants/sectors';
import './styles.scss';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import Icon from '../../../ui/icon/Icon';
import { IconName } from '../../../../types/utils/iconName';

type Props = {
  approach?: string;
  baseYear?: number;
  baseyearChangeJustification?: string;
  contactInfo?: string;
};

type DescriptionItemProps = {
  title: string;
  value: string;
  image: IconName;
};

const DescriptionItem = ({ title, value, image }: DescriptionItemProps) => {
  return (
    <div className='ghg-business-description__item on-card-gray-bg-color'>
      <div className='ghg-business-description__item__image-wrapper'>
        <Icon icon={image} color={'gradient'} size='medium' />
        <h2 className='body2-font'>{title}</h2>
      </div>
      <p className='body3-font word-break'>{value}</p>
    </div>
  );
};
function BusinessDescription({
  approach,
  baseYear,
  baseyearChangeJustification,
  contactInfo
}: Props) {
  const { t, i18n } = useTranslation();
  const organization = useSelectedOrganization();

  const sectorsParsed = sectors.map((elem) => {
    return {
      id: elem.id,
      name: elem.title[i18n.language.split('-')[0] as keyof Language]
    };
  });

  const sectorFound = sectorsParsed.find((elem) => elem.id === organization?.sector);

  const items: {
    title: string;
    value?: string;
    image: IconName;
  }[] = [];

  if (organization?.trade_name)
    items.push({
      title: t('ghgReport.tradeName'),
      value: organization?.trade_name,
      image: 'facilities'
    });

  if (organization?.vat)
    items.push({
      title: t('ghgReport.socialDenomination'),
      value: organization?.vat,
      image: 'location'
    });

  if (sectorFound?.name)
    items.push({
      title: t('ghgReport.sector'),
      value: sectorFound?.name,
      image: 'sector'
    });

  if (approach)
    items.push({
      title: t('ghgReport.approach'),
      value: t(`ghgReport.approachType.${approach}`),
      image: 'leaf'
    });

  if (baseYear)
    items.push({
      title: t('ghgReport.baseYear'),
      value: t(`ghgReport.baseYearData`, { baseYear }),
      image: 'calendar'
    });

  if (baseyearChangeJustification)
    items.push({
      title: t('ghgReport.baseyearChangeJustification'),
      value: t('ghgReport.yes', { reason: baseyearChangeJustification }),
      image: 'upload'
    });
  else {
    items.push({
      title: t('ghgReport.baseyearChangeJustification'),
      value: t('ghgReport.no'),
      image: 'upload'
    });
  }

  if (contactInfo)
    items.push({
      title: t('ghgReport.contactInfo'),
      value: contactInfo,
      image: 'user'
    });

  if (organization?.description)
    items.push({
      title: t('ghgReport.description'),
      value: organization.description,
      image: 'vat'
    });

  return (
    <div className='ghg-business-description'>
      {items.map((item) => (
        <DescriptionItem
          key={item.title + item.value}
          title={item.title}
          value={item.value || ''}
          image={item.image}
        />
      ))}
    </div>
  );
}

BusinessDescription.Item = DescriptionItem;

export default BusinessDescription;
