import { useTranslation } from 'react-i18next';

type Props = {
  transportDirection: string;
};
const useSortingOptions = ({ transportDirection }: Props) => {
  const { t } = useTranslation();
  const sortingOptions = [
    { id: 'created_at', name: t('input.sorting.shipments.creationDate') },
    { id: 'quantity_transported', name: t('input.sorting.shipments.quantity') },
    { id: 'start_date', name: t(`input.sorting.shipments.${transportDirection}.startDate`) },
    { id: 'co2e', name: t('input.sorting.shipments.co2eKg') }
  ];

  return sortingOptions;
};

export default useSortingOptions;
