import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../../../customHooks/useFeatureFlags';
import { Status } from '../../../../../../types/utilsEnums/status';
import Button from '../../../../../ui/button/Button';
import { StatusTag } from '../../../../../ui/statusTag';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import TagStarTooltip from '../components/tagStar/TagStarTooltip';
import '../styles.scss';

const useCriteria = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.columns' });
  const flags = useFeatureFlags();

  const [criteria, setCriteria] = useState<ISbtiCriteriaTable>();
  const handleSetCriteria = (criteria: ISbtiCriteriaTable) => setCriteria(criteria);
  const handleCloseCriteria = () => setCriteria(undefined);

  const columns = [
    {
      title: t('criteria'),
      dataIndex: 'criteriaText',
      key: 'criteriaText',
      width: 600,
      render: (criteriaText: string) => (
        <span
          style={{
            whiteSpace: 'pre-line'
          }}>
          {criteriaText}
        </span>
      )
    },
    {
      title: t('response'),
      dataIndex: 'answer',
      key: 'answer',
      width: 500
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status: ISbtiCriteriaStatus, criteria: ISbtiCriteriaTable) => {
        type AllowedTypes = Status.ACTIVE | Status.PENDING | Status.ERROR | Status.BLOCKED;

        const statusObject = {
          accepted: Status.ACTIVE,
          pending: Status.PENDING,
          rejected: Status.ERROR,
          blocked: Status.BLOCKED,
          not_apply: Status.ACTIVE
        };

        const statusText = {
          accepted: t('accepted'),
          pending: t('pending'),
          rejected: t('rejected'),
          blocked: t('blocked'),
          not_apply: t('not_apply')
        };

        let blockedByString = '';
        const length = criteria.blockedBy?.length || 0;

        criteria?.blockedBy?.forEach((criteria, i) => {
          if (i === length - 1) {
            blockedByString += criteria;
            return;
          }
          blockedByString += criteria + ', ';
        });

        const statusTooltip = {
          accepted: t('acceptedTooltip'),
          pending: t('pendingTooltip'),
          rejected: t('rejectedTooltip'),
          blocked: t('blockedTooltip', { blockedBy: blockedByString }),
          not_apply: t('notApplyTooltip')
        };

        return (
          <TooltipWrapper text={statusTooltip[status]}>
            <StatusTag status={statusObject[status] as AllowedTypes} text={statusText[status]} />
          </TooltipWrapper>
        );
      }
    },
    {
      title: null,
      dataIndex: ['status', 'criteria'],
      key: 'action',
      width: 68,
      render: (_: unknown, criteria: ISbtiCriteriaTable) => {
        // if blocked or not_apply, return null
        if (criteria.status === 'blocked' || criteria.status === 'not_apply') return null;

        // if not has action, return star saying that criteria is auto filled with ia
        if (!criteria?.hasAction)
          return (
            <div className='button-container'>
              <TagStarTooltip />
            </div>
          );

        // if has action and status is pending, return complete button
        return (
          <div className='button-container'>
            <Button
              size='small'
              lookAndFeel={flags?.sbti ? 'secondary' : 'blocked'}
              text={criteria.answer === '-' ? t('complete') : t('edit')}
              onClick={() => handleSetCriteria(criteria)}
            />
          </div>
        );
      }
    }
  ];

  return { columns, criteria, handleCloseCriteria };
};

export default useCriteria;
