import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  options: SelectOptionFormat[];
  sort?: boolean;
  optional?: boolean;
  searchValue?: string;
};
const useOptionsFiltered = ({ options, sort = true, optional = false, searchValue }: Props) => {
  const { t } = useTranslation();
  const optionsFiltered: SelectOptionFormat[] = useMemo(() => {
    let optionsFilteredValue = options;

    if (sort && optionsFilteredValue?.sort) {
      optionsFilteredValue = optionsFilteredValue.sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    if (
      optional &&
      optionsFilteredValue?.unshift &&
      !optionsFilteredValue.some((elem) => !elem.id && elem.name === t('select.doNotKnow'))
    ) {
      optionsFilteredValue.unshift({ name: t('select.doNotKnow'), id: '' });
    }
    if (searchValue) {
      optionsFilteredValue = optionsFilteredValue.filter((elem) =>
        searchValue ? elem.name.toLowerCase().includes(searchValue.toLowerCase()) : true
      );
    }

    return optionsFilteredValue;
  }, [JSON.stringify(options), sort, optional, searchValue]);

  return optionsFiltered;
};

export default useOptionsFiltered;
