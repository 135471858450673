import { useNavigate } from 'react-router-dom';
import './styles.scss';

type Props = {
  index: number;
  crumbs: string[];
  crumb: string;
};
function Crumb({ index, crumbs, crumb }: Props) {
  const navigate = useNavigate();

  const handleOnClickCrumb = () => {
    let path = '';
    for (let i = 0; i <= index; i += 1) {
      path += `/${crumbs[i]}`;
    }

    navigate(path);
  };

  return (
    <span
      className={`subtitle2-font ${
        index < crumbs.length - 1 ? 'on-light-text-color2' : 'on-light-text-color'
      }`}
      key={index}
      onClick={handleOnClickCrumb}>{`${crumb} ${index < crumbs.length - 1 ? '> ' : ''}`}</span>
  );
}

type CustomCrumbProps = Props & {
  handleCrumbClick: (key: string) => void;
};

const CustomCrumb: React.FC<CustomCrumbProps> = ({ index, crumbs, crumb, handleCrumbClick }) => {
  const handleOnClickCrumb = () => {
    handleCrumbClick(crumbs[index]);
  };

  return (
    <span
      className={`m-0 font-body-b1-r ${
        index < crumbs.length - 1 ? 'on-light-text-color2' : 'on-light-text-color'
      }`}
      key={index}
      onClick={handleOnClickCrumb}>{`${crumb} ${index < crumbs.length - 1 ? '> ' : ''}`}</span>
  );
};

Crumb.Custom = CustomCrumb;

export default Crumb;
