import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useErrors from '../../../../../../customHooks/useErrors';
import { deleteUseAndEndOfLife } from '../../../../../../services/api/useAndEndOfLife';

import Button from '../../../../../ui/button/Button';
import ErrorText from '../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  deleteElement: (id: string) => void;
  id: string;
};

const DeleteForm = ({ deleteElement, id }: Props) => {
  const { t } = useTranslation('', { keyPrefix: 'useAndEndOfLife.form' });
  const ERRORS = useErrors();

  const [error, setError] = useState<ErrorType>();
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteElement = async () => {
    if (loadingButton) return;

    setLoadingButton(true);
    const response = await deleteUseAndEndOfLife({ id });
    setLoadingButton(false);

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    deleteElement(id);
  };

  return (
    <div className='delete-modal'>
      <FormHeader title={t('deleteTitle')} description={t('deleteDescription')} />

      <FormButtonSection>
        <Button
          lookAndFeel={'cancel'}
          size='medium'
          text={t('cancel')}
          onClick={() => alert()}
          loading={loadingButton}
        />
        <Button
          lookAndFeel='primary'
          text={t('deleteButton')}
          onClick={handleDeleteElement}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

export default DeleteForm;
