import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useErrors from '../../../../../customHooks/useErrors';
import { IFrontendFormBusinessTravel } from '../../../../../types/entities/businessTravels';
import { TransportOptions } from '../../../../../types/entitiesEnums/employeePeriod';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import Button from '../../../../ui/button/Button';
import ErrorText from '../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import InputGoogleMaps from '../../../../ui/formComponents2/inputGoogleMaps/InputGoogleMaps';
import InputWrapper from '../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import SelectCardWrapper from '../../../../ui/selectCard/SelectCardWrapper';
import useFormData from './useFormData';

type Props = {
  submit: (formData: IFrontendFormBusinessTravel) => Promise<void>;
  error?: ErrorType;
  formDataFilled?: IFrontendFormBusinessTravel;
};

const FormBusinessTravel = ({ submit, error, formDataFilled }: Props) => {
  const { t } = useTranslation();
  const ERRORS = useErrors();

  const cards: Card[] = [
    {
      id: 'round',
      icon: '/images/icons/doubleArrow.svg',
      title: t('businessTravels.round')
    },
    {
      id: 'one_way',
      icon: '/images/icons/arrowRight.svg',
      title: t('businessTravels.oneWay')
    }
  ];

  const {
    formData,
    setFormData,
    fuelTypeOptions,
    handleDateError,
    originDestinationDisabled,
    totalKmDisabled,
    onChangeValue,
    onChangeTransport,
    onChangeOrigin,
    onChangeDestination,
    onChangeTotalKm
  } = useFormData({ formDataFilled });

  const [loadingButton, setLoadingButton] = useState(false);

  const handleErrors = () => {
    const optionalFields = ['name', 'size'];

    if (formData.transport.id === TransportOptions.AIRCRAFT) optionalFields.push('fuelType');
    if (formData.origin || formData.destination) optionalFields.push('totalKm');
    if (formData.totalKm) optionalFields.push('origin', 'destination');

    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    if (formData.destination === formData.origin && formData.origin)
      newErrors.push(ERRORS.BUSINESS_TRAVEL.ORIGIN_DESTINATION_CANNOT_BE_THE_SAME);

    setFormData((previous) => ({
      ...previous,
      errors: newErrors
    }));

    return newErrors.length > 0 ? true : false;
  };

  const cleanData = (formData: IFrontendFormBusinessTravel) => {
    const newFormData = structuredClone(formData);

    if (originDestinationDisabled) {
      newFormData.origin = '';
      newFormData.destination = '';
    }

    if (totalKmDisabled) {
      newFormData.totalKm = '';
    }

    return newFormData;
  };

  const handleOnClickSubmit = async () => {
    if (handleErrors()) return;
    setLoadingButton(true);
    await submit(cleanData(formData));
    setLoadingButton(false);
  };

  return (
    <div className='new-business-travel'>
      <div className='business-travel-type'>
        <h3 className='body1-bold-font'>{t('businessTravels.businessTravelType')}</h3>
        <SelectCardWrapper
          cards={cards}
          onClickCard={onChangeValue('travelType')}
          error={formData.errors.find((elem) => elem.error === 'travelType')}
          type={cards.find((card) => card.id === formData.travelType)}
        />
      </div>
      <FormWrapper>
        <FormText
          icon={'/images/icons/person.svg'}
          placeholder={t('businessTravels.writeName')}
          label={t('businessTravels.nameOptional')}
          onChange={onChangeValue('name')}
          error={formData.errors.find((elem) => elem.error === 'name')}
          value={formData.name}
        />
        <FormNumber
          label={t('businessTravels.travelNumber')}
          icon={'/images/icons/calendar50.svg'}
          placeholder={''}
          value={formData.travelNumber}
          onChange={onChangeValue('travelNumber')}
          error={formData.errors.find((elem) => elem.error === 'travelNumber')}
          onlyIntegers
        />

        <FormElementFull>
          <InputWrapper
            icon={'/images/icons/pin.svg'}
            label={t('businessTravels.route')}
            error={formData.errors?.find(
              (error) =>
                error.error === 'invalidAddress' ||
                error.error === 'origin' ||
                error.error === 'destination'
            )}
            disabled={originDestinationDisabled}>
            <InputGoogleMaps
              placeholder={t('shipments.origin')}
              value={formData.origin}
              onChange={onChangeOrigin}
              disabled={originDestinationDisabled}
            />
            <InputGoogleMaps
              placeholder={t('shipments.destination')}
              value={formData.destination}
              onChange={onChangeDestination}
              disabled={originDestinationDisabled}
            />
          </InputWrapper>
        </FormElementFull>

        <FormNumber
          icon='/images/icons/pin.svg'
          label={t('businessTravels.totalKm')}
          placeholder={t('businessTravels.enterTotalKm')}
          value={formData.totalKm}
          onChange={onChangeTotalKm}
          disabled={totalKmDisabled}
        />

        <FormSelect
          icon={'/images/icons/plane.svg'}
          placeholder={t('businessTravels.writeTransport')}
          label={t('businessTravels.transport')}
          onChange={onChangeTransport}
          error={formData.errors.find((elem) => elem.error === 'transport')}
          value={formData.transport}
          options={[
            { id: TransportOptions.CAR_SMALL, name: t('businessTravels.carSmall') },
            { id: TransportOptions.CAR_MEDIUM, name: t('businessTravels.carMedium') },
            { id: TransportOptions.CAR_LARGE, name: t('businessTravels.carLarge') },
            { id: TransportOptions.TRAIN, name: t('businessTravels.train') },
            { id: TransportOptions.TROLLEYBUS, name: t('businessTravels.trolleybus') },
            { id: TransportOptions.BUS, name: t('businessTravels.bus') },
            { id: TransportOptions.AIRCRAFT, name: t('businessTravels.aircraft') },
            { id: TransportOptions.MOTORBIKE, name: t('businessTravels.motorbike') }
          ]}
        />
        {fuelTypeOptions && fuelTypeOptions.length > 0 && (
          <FormSelect
            icon={'/images/icons/plug.svg'}
            placeholder={t('businessTravels.selectFuelType')}
            label={t('businessTravels.fuelType')}
            onChange={onChangeValue('fuelType')}
            error={formData.errors.find((elem) => elem.error === 'fuelType')}
            value={formData.fuelType}
            options={fuelTypeOptions}
          />
        )}
        <FormCalendarDouble
          label={t('businessTravels.temporalDistribution')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          handleStartDateError={handleDateError('startDate')}
          handleEndDateError={handleDateError('endDate')}
          error={formData.errors.find(
            (error) => error.error === 'startDate' || error.error === 'endDate'
          )}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('businessTravels.save')}
          onClick={handleOnClickSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </div>
  );
};

export default FormBusinessTravel;
