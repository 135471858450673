import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../../actions/notification';
import { ReactComponent as SVGMoreInfo } from '../../../../../../../assets/img/double_materiality_more_info.svg';
import { UserContext } from '../../../../../../../context/userContext';
import useSelectedOrganization from '../../../../../../../customHooks/useSelectedOrganization';
import { sendEmails } from '../../../../../../../services/api/emails';
import Button from '../../../../../../ui/button/Button';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import { StarLabel } from '../../../../../../ui/statusLabels/starLabel/StarLabel';

type Props = {
  onClose: () => void;
};

const MoreInfoModal = ({ onClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'doubleMateriality.moreInfoModal' });
  const { t: tn } = useTranslation('');

  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const subject = `El usuario ${user?.email} de la organización ${selectedOrganization?.company_name} ha clicado en más información de Doble Materialidad`;

    const response = await sendEmails(subject, undefined, undefined, [
      'cecilia@dcycle.io',
      'vanesa@dcycle.io',
      'ricardo.mendez@dcycle.io'
    ]);

    if (response?.response?.status >= 400) {
      setLoading(false);
      return;
    }

    // Finish function
    setLoading(false);
    dispatch(setNotification(tn('doubleMateriality.notification.success')));
    onClose();
  };

  return (
    <>
      <FormHeader
        title={
          <div className='flex'>
            {t('title')}
            <StarLabel text='Dcycle IA' tooltip={tn('general.dcycleIATooltip')} />
          </div>
        }
      />
      <FormWrapper>
        <SVGMoreInfo width={'100%'} />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('send')}
          size='medium'
          onClick={handleSubmit}
          loading={loading}
        />
      </FormButtonSection>
    </>
  );
};

export default MoreInfoModal;
