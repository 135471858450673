import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteIntensityMetric } from '../../../../../services/api/intensityMetrics';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  intensityMetricToDelete: IntensityMetric;
  removeIntensityMetric: (id: string) => void;
  closeDeleteModal: () => void;
};
const DeleteIntensityMetric = ({
  intensityMetricToDelete,
  removeIntensityMetric,
  closeDeleteModal
}: Props) => {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);

  const handleDelete = async () => {
    // Delete intensity metric group api call
    setLoadingButton(true);
    const data = await deleteIntensityMetric(intensityMetricToDelete.id);
    setLoadingButton(false);
    if (!data) return;
    // Callback and remove from list
    removeIntensityMetric(intensityMetricToDelete.id);
  };

  return (
    <div className='delete-intensity-metric-group'>
      <FormHeader
        title={t('deleteIntensityMetric.title')}
        description={t('deleteIntensityMetric.description')}
      />
      <FormButtonSection>
        <Button lookAndFeel={'secondary'} onClick={closeDeleteModal} text={t('general.cancel')} />
        <Button
          lookAndFeel={'warning'}
          onClick={handleDelete}
          text={t('deleteIntensityMetric.delete')}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteIntensityMetric;
