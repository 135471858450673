import { useState } from 'react';
import Modal from '../../../../../ui/modal/Modal';
import { STEPS, STEP_ENUM } from './constants';
import { useForm, withFormProvider } from './FormContext';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import StepCarouselList from '../../../../../ui/stepCarouselList/StepCarouselList';
import { MetadataPage } from './MetadataPage';
import { ImpactCategoriesPage } from './ImpactCategoriesPage';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const STEPS_COMPONENTS = {
  [STEP_ENUM.METADATA]: MetadataPage,
  [STEP_ENUM.IMPACT_CATEGORIES]: ImpactCategoriesPage
};

const CreateLCAModalWithoutProvider: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { validate } = useForm();
  const onClose = () => {
    setIsOpen(false);
  };

  const { t } = useLCATranslation({ keyPrefix: 'portfolio.createLCA.modal' });

  const [section, setSection] = useState(STEP_ENUM.METADATA);

  const handleChangeSection = (newSection: string) => {
    const isValid = validate(section);

    if (isValid) {
      setSection(newSection);
    }
  };

  const SectionComponent = STEPS_COMPONENTS[section];

  return (
    <Modal.WithPortal show={isOpen} onClose={onClose}>
      <Modal.Header title={t('title')} description={t('description')} />
      <StepCarouselList
        steps={STEPS.map((step) => {
          return {
            id: step.id,
            isSelected: step.id === section,
            text: t(step.text)
          };
        })}
        handleSelect={handleChangeSection}
        className='pb-8'
      />
      <SectionComponent setSection={handleChangeSection} onClose={onClose} />
    </Modal.WithPortal>
  );
};

export const CreateLCAModal = withFormProvider(CreateLCAModalWithoutProvider);
