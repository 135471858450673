import apiFetch from '../../utils/apiFetch';

export const findOrCreateSbti = async (signal?: AbortSignal) => {
  try {
    // return await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({ id: 1 });
    //   }, 2000);
    // });
    const response = await apiFetch('PATCH', '/sbti', null, null, null, null, undefined, signal);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCriteriaList = async (id: string) => {
  try {
    // return await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve([
    //       {
    //         id: '1',
    //         category: 'category1',
    //         criteria: 'C1',
    //         yes_no: 'yes',
    //         status: 'accepted'
    //       },
    //       {
    //         id: '2',
    //         category: 'category1',
    //         criteria: 'C2',
    //         co2e_biogenic: 300,
    //         status: 'accepted'
    //       },
    //       {
    //         id: '2',
    //         category: 'category1',
    //         criteria: 'C4',
    //         co2e_biogenic: 300,
    //         status: 'accepted'
    //       },
    //       {
    //         id: '5',
    //         category: 'category238764',
    //         criteria: 'C5',
    //         status: 'accepted',
    //         sector: 'cement'
    //       },
    //       {
    //         id: '6',
    //         category: 'category238764',
    //         criteria: 'C6',
    //         status: 'pending'
    //       },
    //       {
    //         id: 'sdf',
    //         category: 'category238764',
    //         criteria: 'C9',
    //         status: 'pending'
    //       },
    //       {
    //         id: 'sdf',
    //         category: 'category238764',
    //         criteria: 'C16',
    //         status: 'pending'
    //       },
    //       {
    //         id: 'sdf',
    //         category: 'category238764',
    //         criteria: 'C20_1',
    //         status: 'pending'
    //       }
    //     ]);
    //   }, 2000);
    // });
    const response = await apiFetch('GET', `/sbti/criteria/list/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCriteria = async (id: string) => {
  try {
    // return await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(undefined);
    //   }, 2000);
    // });
    const response = await apiFetch('GET', `/sbti/criteria/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateOrCreateCriteria = async (criteria: ISbtiCriteriaSendBack) => {
  try {
    // return await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve([
    //       {
    //         id: '1',
    //         category: 'category1',
    //         criteria: 'C1',
    //         yes_no: 'yes',
    //         status: 'accepted'
    //       },
    //       {
    //         id: '2',
    //         category: 'category1',
    //         criteria: 'C4',
    //         co2e_biogenic: 300,
    //         status: 'accepted'
    //       }
    //     ]);
    //   }, 2000);
    // });
    const response = await apiFetch('PATCH', `/sbti/criteria/${criteria.id}`, criteria);
    return response.data;
  } catch (error) {
    return error;
  }
};
