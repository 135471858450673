import { ChangeEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '../inputLabel/InputLabel';

import Select from '../select/Select';

import './styles.scss';

type Props = {
  icon: string;
  label?: string | React.ReactNode;
  placeholder: string;
  tooltip?: string;
  textValue: string;
  onChangeTextValue: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: ErrorType;
  selectOptions: SelectOptionFormat[];
  onChangeSelectOption: (value: SelectOptionFormat) => void;
  selectOptionValue: SelectOptionFormat;
  tags?: React.ReactNode[];
  height?: string;
  size?: 'small' | 'big'; // small for smaller paddings and margins
  fontClass?: string;
  textType?: 'text' | 'number';
  sort?: boolean;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  maxWidth?: string;
  disabled?: boolean;
  disabledText?: boolean;
  precision?: number;
};

const InputTextSelect = ({
  icon,
  label,
  placeholder,
  tooltip,
  textValue,
  onChangeTextValue,
  error,
  selectOptions,
  onChangeSelectOption,
  selectOptionValue,
  tags,
  fontClass = 'input-font',
  height = '33px',
  size = 'small',
  textType = 'text',
  sort = true,
  onKeyPress,
  maxWidth,
  disabled = false,
  disabledText = false,
  precision = 2 // number of decimals if textType is number
}: Props) => {
  const { t } = useTranslation();

  const handleOnChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabledText) return;
    const result = e;
    const regexNumber = new RegExp(`^\\d+(\\.\\d{0,${precision}})?$`);
    if ((textType === 'number' && regexNumber.test(e.target.value)) || e.target.value === '') {
      onChangeTextValue(result);
    }
    if (textType === 'text') {
      onChangeTextValue(result);
    }
  };

  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyPress) {
      onKeyPress(e);
    }
  };

  return (
    <div className='input'>
      <InputLabel label={label} tooltip={tooltip} />
      <div
        className={`input__text-select flex ${fontClass} ${size} ${
          error ? 'error-border-color' : 'input-border-color'
        } ${disabled ? 'input-disabled-bg-color' : ''}`}
        style={{ height }}>
        {icon && (
          <div className={`image-wrapper  ${error ? 'error-border-color' : 'input-border-color'}`}>
            <img src={icon} alt='input-icon' width={18} />
          </div>
        )}
        <input
          type='text'
          className={`input__text-select__content on-light-text-color ${fontClass} ${
            disabled ? 'transparent-bg-color' : ''
          }`}
          value={textValue}
          placeholder={placeholder}
          onChange={handleOnChangeValue}
          onKeyPress={handleOnKeyPress}
          style={{ maxWidth }}
        />
        {tags?.map((tag) => {
          return (
            <>
              <div className='divider-line--select'></div>
              <span className='tag-element'>{tag}</span>
            </>
          );
        })}
        <div className='divider-line--select'></div>
        <Select
          value={selectOptionValue}
          options={selectOptions}
          placeholder={
            selectOptions.length > 0 && selectOptions[0].name ? selectOptions[0].name : ''
          }
          onChangeValue={onChangeSelectOption}
          type='basic'
          height={height}
          fontClass={fontClass}
          size={size}
          sort={sort}
          disabled={disabled}
        />
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputTextSelect;
