import { enGB, es } from 'date-fns/locale';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';
import { highlight_color } from '../../../../styles/colors';

import { convertStringToDate } from '../../../../utils/convertDates';
import { formatDate } from '../../../../utils/formatDate';
import Tooltip from '../../tooltip/Tooltip';
import InputLabel from '../inputLabel/InputLabel';
import InputDatePredefined from './InputDatePredefined';
import InputDateText from './InputDateText';
import './styles.scss';

type Props = {
  label?: string;
  mode?: string;
  error?: ErrorType;
  height?: string;
  fontClass?: string;
  size?: 'small' | 'big';
  tooltip?: string;
  handleChangeStartDate: (value: string) => void;
  handleChangeEndDate?: (value: string) => void;
  handleDateError?: (error?: ErrorType) => void;
  startDateValue: string;
  endDateValue?: string;
  disabled?: boolean;
  position?: string;
  predefinedDates?: boolean;
  maxStartDate?: Date | null;
  maxEndDate?: Date | null;
  minStartDate?: Date;
  minEndDate?: Date;
  resetStartDate?: () => void;
  resetEndDate?: () => void;
  allowSameDate?: boolean;
};

function InputCalendar({
  mode = 'single',
  tooltip,
  height = '31px',
  fontClass = 'input-font',
  label,
  error,
  size = 'small',
  handleChangeStartDate,
  handleChangeEndDate = (value: string) => {
    return;
  },
  disabled = false,
  position,
  startDateValue,
  endDateValue = '',
  handleDateError,
  predefinedDates = false,
  // next year january 1st
  maxStartDate = new Date(),
  maxEndDate = new Date(),
  // 2000 january 1st
  minStartDate = new Date(2000, 0, 1),
  minEndDate = new Date(2000, 0, 1),
  resetStartDate,
  resetEndDate,
  allowSameDate
}: Props) {
  const { t, i18n } = useTranslation();

  // regex for DD/MM/YYYY
  const regexddmmyyyy =
    /^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\d|2[0-8])[- /.]02)[- /.]\d{4}|29[- /.]02[- /.](\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$/g;

  const [showCalendarStartDate, setShowCalendarStartDate] = useState(false);
  const [showCalendarEndDate, setShowCalendarEndDate] = useState(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [validRange, setValidRange] = useState(true);

  useEffect(() => {
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  }, [startDateValue, endDateValue]);

  const wrapperRef: any = useRef(null);

  let locale = enGB;
  if (i18n.language === 'es') {
    locale = es;
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        (wrapperRef.current && !wrapperRef.current.contains(event.target)) ||
        event.target.parentNode.className.includes('input__select__content')
      ) {
        setShowCalendarStartDate(false);
        setShowCalendarEndDate(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelectStartDate = (date: Date) => {
    let validRange = true;
    if (mode === 'range' && endDate) {
      // Check if the start date is after the end date
      validRange = allowSameDate
        ? date <= convertStringToDate(endDate)
        : date < convertStringToDate(endDate);
    }

    if (!validRange) {
      setEndDate('');
      handleChangeEndDate('');
    }

    const stringDate = formatDate(date);
    setStartDate(stringDate);
    setValidRange(true);
    handleChangeStartDate(stringDate);
    setShowCalendarStartDate(false);
    if (validRange && handleDateError) {
      handleDateError(undefined);
    }
  };

  const handleSelectEndDate = (date: Date) => {
    let validRange = true;
    if (mode === 'range' && startDate) {
      // Check if the start date is after the end date
      validRange = allowSameDate
        ? date >= convertStringToDate(startDate)
        : date > convertStringToDate(startDate);
    }

    if (!validRange) {
      setStartDate('');
      handleChangeStartDate('');
    }

    const stringDate = formatDate(date);
    handleChangeEndDate(stringDate);
    setEndDate(stringDate);
    setValidRange(true);
    setShowCalendarEndDate(false);
    if (validRange && handleDateError) {
      handleDateError(undefined);
    }
  };

  const onChangeRawStartDate = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const newDate = e.target.value;
    setStartDate(newDate);

    if (newDate === '') {
      handleResetStartDate();
      return;
    }
    let validRange = true;
    if (!regexddmmyyyy.test(newDate)) {
      validRange = false;
    }
    // Convert the string to a date
    const startDateParsed = convertStringToDate(newDate);
    const endDateParsed = convertStringToDate(endDate);

    if (mode === 'range') {
      // Check if the start date is after the end date
      if (allowSameDate) {
        validRange = validRange && startDateParsed <= endDateParsed;
      } else {
        validRange = validRange && startDateParsed < endDateParsed;
      }
      if (maxStartDate) {
        validRange = validRange && startDateParsed <= maxStartDate;
      }
    }

    if (!validRange) {
      setValidRange(false);
      if (handleDateError) {
        handleDateError({
          error: 'date',
          description: t('error.invalidDate')
        });
      }
      return;
    }

    handleChangeStartDate(newDate);
    setValidRange(validRange);
    if (handleDateError && validRange) {
      handleDateError(undefined);
    }
  };
  const onChangeRawEndDate = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const newDate = e.target.value;
    setEndDate(newDate);

    if (newDate === '') {
      handleResetEndDate();
      return;
    }
    let validRange = true;
    if (!regexddmmyyyy.test(newDate)) {
      validRange = false;
    }
    // Convert the string to a date
    const endDateParsed = convertStringToDate(newDate);
    const startDateParsed = convertStringToDate(startDate);

    if (mode === 'range') {
      // Check if the start date is after the end date
      if (allowSameDate) {
        validRange = validRange && endDateParsed >= startDateParsed;
      } else {
        validRange = validRange && endDateParsed > startDateParsed;
      }
      if (maxEndDate) {
        validRange = validRange && endDateParsed <= maxEndDate;
      }
    }

    if (!validRange) {
      setValidRange(false);
      if (handleDateError) {
        handleDateError({
          error: 'date',
          description: t('error.invalidDate')
        });
      }
      return;
    }
    handleChangeEndDate(newDate);
    setValidRange(validRange);
    if (handleDateError && validRange) {
      handleDateError(undefined);
    }
  };

  const handleShowCalendarStartDate = () => {
    if (disabled) return;
    setShowCalendarStartDate(true);
    setShowCalendarEndDate(false);
  };

  const handleShowCalendarEndDate = () => {
    if (disabled) return;
    setShowCalendarEndDate(true);
    setShowCalendarStartDate(false);
  };

  const handleHideCalendar = () => {
    setShowCalendarStartDate(false);
    setShowCalendarEndDate(false);
  };

  const handleResetStartDate = () => {
    setStartDate('');
    setValidRange(true);
    if (resetStartDate) {
      resetStartDate();
    }
  };

  const handleResetEndDate = () => {
    setEndDate('');
    setValidRange(true);
    if (resetEndDate) {
      resetEndDate();
    }
  };

  const hasError = error || !validRange;

  return (
    <div className={`input calendar-${mode} `} ref={wrapperRef}>
      <InputLabel label={label} tooltip={tooltip} />

      <div
        className={`input__calendar input-border ${size} ${
          hasError ? 'error-border-color' : 'input-border-color'
        } ${disabled ? 'input-disabled-bg-color' : ''}`}
        style={{ height }}>
        <div className={`image-wrapper ${hasError ? 'error-border-color' : 'input-border-color'}`}>
          {!validRange ? (
            <Tooltip
              position='top'
              size='small'
              icon='/images/icons/warning.svg'
              text={t('error.invalidDate')}
            />
          ) : (
            <img src='/images/icons/calendar50.svg' alt='input-icon' />
          )}
        </div>
        {predefinedDates && (
          <InputDatePredefined
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={(value: string) => {
              setStartDate(value);
              handleChangeStartDate(value);
            }}
            onChangeEndDate={(value: string) => {
              setEndDate(value);
              handleChangeEndDate(value);
            }}
            size={size}
            height={height}
            fontClass={fontClass}
          />
        )}
        <div className='input__calendar__content'>
          <InputDateText
            fontClass={fontClass}
            showCalendar={showCalendarStartDate}
            date={startDate}
            onChange={onChangeRawStartDate}
            onClick={handleShowCalendarStartDate}
            onKeyDown={handleHideCalendar}
            resetDate={handleResetStartDate}
            disabled={disabled}
          />
          {mode === 'range' && (
            <>
              <div
                className={`divider-line--small ${hasError ? 'error-bg-color' : ''}`}
                style={{ height: `calc((${height} * 55) / 100)` }}></div>
              <InputDateText
                fontClass={fontClass}
                showCalendar={showCalendarEndDate}
                date={endDate}
                onChange={onChangeRawEndDate}
                onClick={handleShowCalendarEndDate}
                onKeyDown={handleHideCalendar}
                resetDate={handleResetEndDate}
                disabled={disabled}
              />
            </>
          )}
          {showCalendarStartDate && validRange && (
            <div className={`calendar-positioner ${position ?? ''}`}>
              <div className='calendar-wrapper'>
                <Calendar
                  dateDisplayFormat='P'
                  date={startDate ? convertStringToDate(startDate) : new Date()}
                  onChange={handleSelectStartDate}
                  color={highlight_color}
                  locale={locale}
                  maxDate={maxStartDate ? maxStartDate : undefined}
                  minDate={minStartDate}
                />
              </div>
            </div>
          )}
          {showCalendarEndDate && validRange && (
            <div className={`calendar-positioner calendar-positioner__end-date ${position ?? ''}`}>
              <div className='calendar-wrapper'>
                <Calendar
                  dateDisplayFormat='P'
                  date={endDate ? convertStringToDate(endDate) : new Date()}
                  onChange={handleSelectEndDate}
                  color={highlight_color}
                  locale={locale}
                  maxDate={maxEndDate ? maxEndDate : undefined}
                  minDate={minEndDate}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
}

export default InputCalendar;
