import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const FormElementHalf = ({ ...props }: Props) => {
  return (
    <div className='form-line-half' {...props}>
      {props.children}
    </div>
  );
};

export default FormElementHalf;
