import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getPendingTasks } from '../../../actions/auth';
import { UserContext } from '../../../context/userContext';
import PoweredByDcycle from '../../layout/poweredByDcycle/PoweredByDcycle';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputText from '../../ui/formComponents/inputText/InputText';
import Container from './container/Container';
import ListOrganization from './listOrganization/ListOrganization';
import './styles.scss';

const OrganizationSelector = () => {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(getPendingTasks(user?.id ?? ''));
  }, []);

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Container>
      <FormWrapper className='org-selector-form-wrapper'>
        <InputText
          icon={'/images/icons/organization.svg'}
          placeholder={t('organizationSelector.searchByName')}
          value={searchValue}
          onChangeValue={onChangeSearchValue}
        />
      </FormWrapper>
      <ListOrganization searchValue={searchValue} />
      {process.env.REACT_APP_PARTNER !== 'dcycle' && <PoweredByDcycle />}
    </Container>
  );
};

export default OrganizationSelector;
