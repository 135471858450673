import { StepCarouselType } from '../../../types/stepCarousel';
import StepCarousel from './stepCarousel/StepCarousel';
import './styles.scss';

interface Props<T extends string> extends React.HTMLAttributes<HTMLDivElement> {
  steps: StepCarouselType<T>[] | ReadonlyArray<StepCarouselType<T>>;
  handleSelect: (id: T) => void;
  lookAndFeel?: 'big' | 'small' | 'breadcrumb';
  blockClick?: boolean;
  errors?: string[];
}

const StepCarouselList = <T extends string>({
  steps,
  handleSelect,
  lookAndFeel = 'big',
  blockClick = false,
  errors,
  className,
  ...rest
}: Props<T>) => {
  let stepsToUse = [...steps];

  if (lookAndFeel === 'breadcrumb') {
    const index = steps.findIndex((elem) => elem.isSelected);
    stepsToUse = steps.slice(0, index + 1);
  }

  const handleOnClick = (id: T) => () => {
    if (blockClick) return;
    handleSelect(id);
  };

  const classes = ['step-carousel-list'];

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')} {...rest}>
      {stepsToUse.map((step: StepCarouselType<T>) => (
        <StepCarousel
          {...step}
          lookAndFeel={lookAndFeel}
          key={step.id}
          error={errors?.find(
            (err) => err === step.id || !!step.errorKeys?.find((key) => key === err)
          )}
          onClick={handleOnClick(step.id)}
        />
      ))}
    </div>
  );
};

export default StepCarouselList;
