import { ReactNode } from 'react';
import './styles.scss';

type Element = {
  value: ReactNode;
  colspan?: number;
  width?: string;
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  header?: Element[];
  rows: Element[][];
  disabled?: boolean;
}

const ExcelTableRefactor = ({ header, rows, disabled, ...rest }: Props) => {
  const className = `${rest.className} excel-table ${disabled ? 'disabled' : ''}`;

  return (
    <div {...rest} className={className}>
      <table>
        {header && <Header header={header} />}
        <Rows rows={rows} />
      </table>
    </div>
  );
};

const Header = ({ header }: { header: Element[] }) => (
  <thead>
    <tr>
      {header.map((element, index) => (
        <th key={index} colSpan={element.colspan || 1} style={{ width: element.width }}>
          {element.value}
        </th>
      ))}
    </tr>
  </thead>
);

const Rows = ({ rows }: { rows: Element[][] }) => (
  <tbody>
    {rows.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((column, columnIndex) => (
          <td key={columnIndex} colSpan={column.colspan || 1} style={{ width: column.width }}>
            {column.value}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export default ExcelTableRefactor;
