import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';
import {
  createValidationAssumption,
  editValidationAssumption
} from '../../../services/api/assumption';
import checkFormErrors from '../../../utils/checkFormErrors';
import Button from '../../ui/button/Button';
import FormButtonSection from '../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputTextArea from '../../ui/formComponents/inputTextArea/InputTextArea';
import { ValidationError } from '../hooks/useGetData';

type Props = {
  onFinishUpdate: () => void;
  error?: ValidationError;
};

type FormData = {
  description: string;
  errors: ErrorType[];
};

const Assumption = ({ error, onFinishUpdate }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'validation.assumptions' });
  const [formData, setFormData] = useState<FormData>({
    description: error?.assumption?.comment || '',
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    const { description } = formData;
    const newErrors = checkFormErrors(formData, formData.errors);
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    if (description && error) {
      let response;
      const { assumption, ...rest } = error;
      setLoadingButton(true);
      if (error?.assumption) {
        response = await editValidationAssumption(error?.assumption.id, description);
      } else {
        response = await createValidationAssumption(description, rest);
      }
      setLoadingButton(false);
      if (response) {
        onFinishUpdate();
      }
    }
  };

  return (
    <div className='assumption'>
      <FormHeader title={t('title')} description={t('description')} />
      <FormWrapper>
        <InputTextArea
          label={t('descriptionLabel')}
          value={formData.description}
          onChangeValue={onChangeValue('description')}
          error={formData.errors.find((error) => error.error === 'description')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          text={t('save')}
          lookAndFeel='primary'
          onClick={handleSubmit}
          size='medium'
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default Assumption;
