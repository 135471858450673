import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateVehicle } from '../../../../services/api/vehicle';
import { Vehicle } from '../../../../types/entities/vehicle';
import { replaceStrongTags } from '../../../../utils/stringToBold';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import './styles.scss';
type Props = {
  vehicleToArchive: Vehicle;
  closeModal: () => void;
  handleArchive: (id: string) => void;
};
const ArchiveVehicle = ({ vehicleToArchive, closeModal, handleArchive }: Props) => {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);
  const handleSubmit = async () => {
    setLoadingButton(true);
    const data = await updateVehicle(vehicleToArchive.id, { status: 'archived' });
    setLoadingButton(false);
    if (!data) return;
    handleArchive(vehicleToArchive.id);
  };
  return (
    <div className='archive-vehicle'>
      <h1 className='headline2-font'>{t('archiveVehicle.title')}</h1>
      <p className='span-font'>
        {replaceStrongTags(t('archiveVehicle.description', { name: vehicleToArchive.name }))}
      </p>

      <FormButtonSection>
        <Button
          lookAndFeel={'secondary'}
          text={t('archiveVehicle.cancel')}
          onClick={closeModal}
          size='medium'
        />
        <Button
          lookAndFeel={'primary'}
          text={t('archiveVehicle.archive')}
          onClick={handleSubmit}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
    </div>
  );
};

export default ArchiveVehicle;
