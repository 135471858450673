import { useEffect, useState } from 'react';
import { VALIDATION_CATEGORIES, ValidationError } from '../../validation/hooks/useGetData';
import { getVehiclesValidation } from '../../../services/api/vehicle';
import { getFacilitiesValidation } from '../../../services/api/facility';

type Props = {
  category: VALIDATION_CATEGORIES;
  start_date?: number;
  end_date?: number;
};
const useGetData = ({ category, start_date, end_date }: Props) => {
  const [data, setData] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const fetchData = async () => {
    if (!start_date || !end_date || !category) return;
    setLoading(true);
    let validationData = [];
    if (category === 'consumptions') {
      validationData = await getVehiclesValidation(start_date, end_date, ['']);
    } else {
      validationData = await getFacilitiesValidation(start_date, end_date, category, ['']);
    }

    setData(validationData.errors);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [start_date, end_date, category, triggerUpdate]);
  return { loading, data, setTriggerUpdate };
};

export default useGetData;
