import React, { useState } from 'react';
import Button from '../../../../ui/button/Button';
import Modal from '../../../../ui/modal/Modal';
import { MaterialTable } from '../MaterialTable';
import { FormType } from './constants';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { mergeFormMaterialsAndSelectedMaterials } from './build';
import { Filters } from '../../../../layout/NewFilters/Filters';
import { IFilter } from '../../../../../utils/url';
import { FilterText } from '../../../../layout/NewFilters/FilterText';
import { DEFAULT_HEIGHT } from '../../constants';

type Props = {
  materials: FormType['materials'];
  setMaterials: (materials: FormType['materials']) => void;
  onCancel: () => void;
};

const TYPE_FILTER = { field: 'type', type: 'eq', value: 'material' } as const;

export const MaterialSelection: React.FC<Props> = ({ materials, onCancel, setMaterials }) => {
  const { t } = useLCATranslation();

  const [materialsSelected, setMaterialsSelected] = useState(materials);
  const [filters, setFilters] = useState<IFilter[]>([TYPE_FILTER]);

  const handleSubmit = () => {
    const mergedMaterials = mergeFormMaterialsAndSelectedMaterials(materialsSelected, materials);

    setMaterials(mergedMaterials);
  };

  return (
    <Filters.Root.FiltersOnly filters={filters} setFilters={setFilters}>
      <Modal.Content style={{ flexGrow: 1, height: DEFAULT_HEIGHT }}>
        <div className='flex-col gap-y-4'>
          <div className='w-80'>
            <FilterText type='il' field='name' placeholder='Nombre del material' />
          </div>
          <MaterialTable
            selectedMaterials={materialsSelected}
            setSelectedMaterials={setMaterialsSelected}
            filters={filters}
          />
        </div>
      </Modal.Content>
      <Modal.Buttons>
        <Button
          lookAndFeel='secondary'
          text={t('lcaDetail.processFlow.materialModal.cancel')}
          size='small'
          onClick={onCancel}
        />
        <Button
          lookAndFeel='primary'
          text={t('lcaDetail.processFlow.materialModal.addMaterials')}
          size='small'
          onClick={handleSubmit}
        />
      </Modal.Buttons>
    </Filters.Root.FiltersOnly>
  );
};
