import { useParams, useSearchParams } from 'react-router-dom';
import { useLCATranslation } from '../../../../customHooks/translations/useLCATranslation';
import SectionHeader from '../../../layout/sectionHeader/SectionHeader';
import { useLCADetail } from './hooks/useLCADetail';
import { EnvironmentalImpacts } from './EnvironmentalImpacts/EnvironmentalImpacts';
import { PageParams } from './types/PageParams';
import LoaderTables from '../../../ui/loaders/loaderTables/LoaderTables';
import { LCADetailCanvas } from './Canvas/LCADetailCanvas';
import { AZKOYEN_OLD_IDS, LCA_DETAIL_OPTIONS } from './constants';
import { getMode } from './utils/getMode';

const LCA_DETAIL_SECTION_COMPONENTS = {
  [LCA_DETAIL_OPTIONS.environmental_impacts]: EnvironmentalImpacts,
  [LCA_DETAIL_OPTIONS.canvas]: LCADetailCanvas
} as const;

const getDefaultMode = (id?: string) => {
  if (!id || AZKOYEN_OLD_IDS.includes(id)) {
    return LCA_DETAIL_OPTIONS.environmental_impacts;
  }

  return LCA_DETAIL_OPTIONS.canvas;
};

export const LCADetail = () => {
  const { t } = useLCATranslation();
  const params = useParams<PageParams>();

  const [searchParams] = useSearchParams({ mode: getDefaultMode(params.id) });

  const mode = getMode(searchParams.get('mode'), params.id);

  const { data, isLoading } = useLCADetail(params.id);

  const Section = LCA_DETAIL_SECTION_COMPONENTS[mode];

  return (
    <section className='flex-col gap-y-6 h-95svh overflow-hidden border-box'>
      <SectionHeader title={t('lcaDetail.sectionHeader', { acv_name: data?.name ?? '' })} />
      {isLoading && <LoaderTables />}
      {!isLoading && <Section />}
    </section>
  );
};
