export const sortCriteriaTable = (a: ISbtiCriteriaTable, b: ISbtiCriteriaTable) => {
  const order = [
    'C1',
    'C2',
    'C3',
    'C4',
    'C5',
    'C6',
    'C7',
    'C8',
    'C9',
    'C10',
    'C11',
    'C12',
    'C13',
    'C14',
    'C15',
    'C16',
    'C17',
    'C18',
    'C19',
    'C20',
    'C20_1',
    'C20_2',
    'C21',
    'C22',
    'C23',
    'C24'
  ];

  const aIndex = order.indexOf(a.criteria);
  const bIndex = order.indexOf(b.criteria);

  return aIndex - bIndex;
};
