import { z } from 'zod';

export const formSchema = z.object({
  name: z.string().min(1, 'error.required'),
  quantity: z
    .string()
    .min(1, 'error.required')
    .refine((value) => {
      return !isNaN(Number(value));
    }, 'error.invalid_number'),
  unit: z.object({
    id: z.string().min(1, 'error.required'),
    name: z.string().min(1, 'error.required')
  })
});
