import { useQuery } from '@tanstack/react-query';
import { usePurchaseCodes } from 'customHooks/translations/usePurchaseCodes';
import { getFrameworkCodes } from 'services/api/purchaseSuppliers';
import { PurchaseFramework } from 'utils/mapToPurchaseFramework';

type Params = {
  framework: PurchaseFramework.CNAE | PurchaseFramework.SIC;
};
export const useFrameworkCodes = ({ framework }: Params) => {
  const { t } = usePurchaseCodes({ keyPrefix: framework });

  const { data, isLoading } = useQuery({
    queryKey: ['frameworkCodes', framework],
    queryFn: () => getFrameworkCodes(framework),
    select: (data) =>
      data.map((item) => ({
        id: item,
        name: t(item)
      }))
  });

  return { data: data ?? [], isLoading };
};
