import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../../../../constants/googleMapsApiKey';
import InputText from '../inputText/InputText';

type Props = React.ComponentProps<typeof InputText>;

const libraries: any[] = ['places'];

const InputGoogleMaps = ({ ...props }: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });

  const [autocomplete, setAutoComplete] = useState<any>();

  const onLoad = (autocompleteValue: any) => {
    setAutoComplete(autocompleteValue);
  };

  const onPlaceChanged = () => {
    if (!autocomplete || props.disabled || !props?.onChange) return;
    props.onChange(autocomplete.getPlace().formatted_address);
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      className='maps-autocomplete'
      types={['address']}>
      <InputText {...props} />
    </Autocomplete>
  );
};

export default InputGoogleMaps;
