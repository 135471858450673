import { useQuery } from '@tanstack/react-query';
import { adaptEsgImpactsBackToFront } from '../../../../adapters/esgImpacts';
import { getEsgImpacts } from '../../../../services/api/total_impacts';
import { EsgImpactsBackend } from '../../../../types/entities/esgMetrics';

type Props = {
  dateRanges: string[][];
  mode: string;
  metric: string;
};
export const useGetEsgImpacts = ({ dateRanges, mode, metric }: Props) => {
  return useQuery({
    queryKey: ['useGetEsgImpacts', mode, metric, dateRanges],
    queryFn: async () => getEsgImpacts(dateRanges, mode, metric),
    select: (data: EsgImpactsBackend[]) => adaptEsgImpactsBackToFront(data)
  });
};
