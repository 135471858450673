import { useEffect, useState } from 'react';
import { getUnits } from '../../../../../../../../services/api/unit';
import { TreatmentUnits } from './Treatment';

const useUnits = () => {
  const unitsEmpty: TreatmentUnits = {
    caudalUnits: [],
    dboUnits: [],
    recoveredMethaneUnits: [],
    nitrogenUnits: []
  };
  const [units, setUnits] = useState<TreatmentUnits>(unitsEmpty);

  useEffect(() => {
    const fetchData = async () => {
      const responseCaudal = await getUnits({ type: 'waste_water_treatments_flow_total' });
      const responseDBO = await getUnits({ type: 'waste_water_treatment_dbo' });
      const responseCh4 = await getUnits({ type: 'waste_water_treatment_ch4' });
      const responseNitrogen = await getUnits({ type: 'waste_water_treatment_nitrogen' });

      setUnits(() => ({
        caudalUnits: responseCaudal ?? [],
        dboUnits: responseDBO ?? [],
        recoveredMethaneUnits: responseCh4 ?? [],
        nitrogenUnits: responseNitrogen ?? []
      }));
    };
    fetchData();
  }, []);

  return {
    units
  };
};

export default useUnits;
