import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  LCAMaterialMetadata,
  LCAProcessMetadata,
  postLCAReferenceOutput
} from '../../../../services/api/lca/lcaPortfolio';

export const useCreateLCAReferenceOutput = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: postLCAReferenceOutput,

    onSuccess: (data, params) => {
      queryClient.setQueryData(
        ['lca-node-metadata', params.acv_id],
        (oldData: { material: Array<LCAMaterialMetadata>; process: Array<LCAProcessMetadata> }) => {
          const oldProcess = oldData.process.find((process) => process.node_id === params.node_id);

          const newProcess = {
            ...oldProcess,
            node_id: params.node_id,
            reference_output: data.name,
            quantity: data.quantity,
            unit: data.unit
          };

          return {
            ...oldData,
            process: (JSON.parse(JSON.stringify([...oldData.process])) as Array<LCAProcessMetadata>)
              .filter((process) => process.node_id !== params.node_id)
              .concat(newProcess)
          };
        }
      );
    }
  });

  return {
    createLCAReferenceOutput: mutate
  };
};
