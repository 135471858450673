import { ReactNode } from 'react';
import './styles.scss';
type Props = {
  id: string;
  title?: string;
  description?: string;
  children: ReactNode;
};
function CommonSection({ id, title, description, children }: Props) {
  return (
    <div id={id} className='ghg-common-section'>
      <div className='card main-bg-color common-section-inner-div'>
        {title && (
          <div className='title'>
            <div className='border-left'></div>
            <h1 className='small-card-font'>{title}</h1>
          </div>
        )}
        {description && <p className='body3-font description'>{description}</p>}
        {children}
      </div>
    </div>
  );
}

export default CommonSection;
