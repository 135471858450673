import { useTranslation } from 'react-i18next';
import ErrorPage from '../ErrorPage';

const ErrorPageNotFound = () => {
  const { t } = useTranslation();

  return <ErrorPage text={t('errorPage.notFound')} />;
};

export default ErrorPageNotFound;
