import React from 'react';
import Button from '../../../../ui/button/Button';
import Tooltip from '../../../../ui/tooltip/Tooltip';
import './styles.scss';
type Props = {
  title: string;
  nextStep: () => void;
  buttonText: string;
  input: React.ReactNode;
  loadingButton: boolean;
  tooltip?: string;
};
const Step = ({ title, nextStep, buttonText, input, loadingButton, tooltip }: Props) => {
  return (
    <div className='register-company-step'>
      <div className='register-company-step__title'>
        <h1 className='register-step-title-font'>{title}</h1>
        {tooltip && <Tooltip text={tooltip} />}
      </div>
      <div className='input-wrapper'>{input}</div>
      <div className='register-company-step__button-wrapper'>
        <Button
          onClick={nextStep}
          text={buttonText}
          lookAndFeel='primary'
          loading={loadingButton}
        />
      </div>
    </div>
  );
};

export default Step;
