import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { UserContext } from '../../../context/userContext';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { getMatrix } from '../../../services/api/matrices';
import { Holding } from '../../../types/entities/holdings';
import { OrganizationRecommendation } from '../../../types/entities/organization';
import apiFetch from '../../../utils/apiFetch';
import { convertStringToDate } from '../../../utils/convertDates';
import SlideToggle2 from '../../ui/formComponents/slideToggle2/SlideToggle2';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';
import OrganizationCO2Info from '../organizationInfo/OrganizationCO2Info';
import RecommendationDetail from './RecommendationDetail';
import SliderCard from './SliderCard';
import './styles.scss';

function Recommendations() {
  const { t, i18n } = useTranslation();

  const user = useContext(UserContext);

  const flags = useFeatureFlags();

  const foundOrganization = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );
  if (!foundOrganization) return null;

  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const [recommendation, setRecommendation] = useState('energy');
  const [recommendations, setRecommendations] = useState<OrganizationRecommendation[]>([]);

  const [data, setData] = useState<ScopeData[]>([
    { scope: 1, total_emissions: 0 },
    { scope: 2, total_emissions: 0 },
    { scope: 3, total_emissions: 0 }
  ]);

  const [offsets, setOffsets] = useState<OffsetType[]>([]);

  const [loading, setLoading] = useState(false);

  const [groupDataMode, setGroupDataMode] = useState<'total' | 'investment'>(
    flags?.consolidation ? 'total' : 'investment'
  );

  useEffect(() => {
    const fetchData = async () => {
      if (dateError) return;
      try {
        if (user?.selectedOrganization) {
          setLoading(true);

          let dataHolding: Holding | null = null;
          let scopesData: ScopeData[] = [
            { scope: 1, total_emissions: 0 },
            { scope: 2, total_emissions: 0 },
            { scope: 3, total_emissions: 0 }
          ];
          if (
            user?.selectedOrganization &&
            user.selectedOrganization &&
            user.selectedOrganization === user.selectedOrganization &&
            !foundOrganization.is_group_fund
          ) {
            dataHolding = await getMatrix(
              user?.selectedOrganization ?? '',
              convertStringToDate(startDate),
              convertStringToDate(endDate),
              groupDataMode
            );

            dataHolding?.childs_info.forEach((child) => {
              child.co2e.forEach((elem) => {
                if (groupDataMode === 'total') {
                  if (elem.scope === 1) {
                    scopesData[0].total_emissions += elem.total_emissions;
                  }
                  if (elem.scope === 2) {
                    scopesData[1].total_emissions += elem.total_emissions;
                  }
                  if (elem.scope === 3) {
                    scopesData[2].total_emissions += elem.total_emissions;
                  }
                } else {
                  scopesData[2].total_emissions += elem.total_emissions;
                }
              });
            });
            dataHolding?.parent_co2e?.forEach((elem) => {
              if (elem.scope === 1) {
                scopesData[0].total_emissions += elem.total_emissions;
              }
              if (elem.scope === 2) {
                scopesData[1].total_emissions += elem.total_emissions;
              }
              if (elem.scope === 3) {
                scopesData[2].total_emissions += elem.total_emissions;
              }
            });
          } else {
            const res = await apiFetch('POST', '/total_impacts/scope', {
              organization_id: user.selectedOrganization,
              start_date: new Date(moment(convertStringToDate(startDate)).format('YYYY-MM-DD')),
              end_date: new Date(moment(convertStringToDate(endDate)).format('YYYY-MM-DD'))
            });

            scopesData = res.data;
          }
          if (dataHolding && foundOrganization.is_group_fund) {
            // add to the scope 3 the childs co2e
            // find index of scope 3
            const index = scopesData.findIndex((elem) => elem.scope === 3);
            if (index !== -1) {
              scopesData[index].total_emissions += dataHolding.childs_co2e;
            } else {
              scopesData.push({
                scope: 3,
                total_emissions: dataHolding.childs_co2e
              });
            }
          }
          setData(scopesData);

          const responseOffset = await apiFetch('POST', '/total_impacts/offsets', {
            organization_id: user.selectedOrganization,
            start_date: moment(convertStringToDate(startDate)).format('YYYY-MM-DD'),
            end_date: moment(convertStringToDate(endDate)).format('YYYY-MM-DD'),
            scopes: [1, 2, 3]
          });
          if (responseOffset.data.offsets) {
            setOffsets(responseOffset.data.offsets);
          }
          const responseRecommendations = await apiFetch(
            'GET',
            `/organization_recommendations/organization/${user.selectedOrganization}`
          );
          setRecommendations(responseRecommendations.data);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [user, startDate, endDate, groupDataMode]);

  const addRecommendation = (recommendation: OrganizationRecommendation) => {
    setRecommendations([...recommendations, recommendation]);
  };

  const removeRecommendation = (id: string) => {
    setRecommendations(recommendations.filter((recommendation) => recommendation.id !== id));
  };

  const chooseRecomendation = () => {
    if (!user) {
      return null;
    }
    switch (recommendation) {
      /* case 'mitecoInscription':
        return (
          <RecommendationDetail
            id={recommendations.find((elem) => elem.name === recommendation)?.id}
            name={recommendation}
            title={t('recommendations.mitecoInscriptionStep.title')}
            description={t('recommendations.mitecoInscriptionStep.description')}
            webUrl={'https://google.es'}
            characteristics={[
              t('recommendations.mitecoInscriptionStep.characteristic1'),
              t('recommendations.mitecoInscriptionStep.characteristic2'),
              t('recommendations.mitecoInscriptionStep.characteristic3')
            ]}
            imageUrl={`/images/recommendations/${i18n.resolvedLanguage}/recommendation5.svg`}
            user={user}
            status={recommendations.find((elem) => elem.name === recommendation)?.status}
            addRecommendation={addRecommendation}
            removeRecommendation={removeRecommendation}
          />
        ); */
      case 'energy':
        return (
          <RecommendationDetail
            id={recommendations.find((elem) => elem.name === recommendation)?.id}
            name={recommendation}
            title={t('recommendations.energyStep.title')}
            description={t('recommendations.energyStep.description')}
            webUrl={'https://google.es'}
            characteristics={[
              t('recommendations.energyStep.characteristic1'),
              t('recommendations.energyStep.characteristic2'),
              t('recommendations.energyStep.characteristic3')
            ]}
            imageUrl={`/images/recommendations/${i18n.resolvedLanguage}/recommendation1.svg`}
            user={user}
            status={recommendations.find((elem) => elem.name === recommendation)?.status}
            addRecommendation={addRecommendation}
            removeRecommendation={removeRecommendation}
          />
        );
      case 'verification':
        return (
          <RecommendationDetail
            id={recommendations.find((elem) => elem.name === recommendation)?.id}
            name={recommendation}
            title={t('recommendations.verificationStep.title')}
            description={t('recommendations.verificationStep.description')}
            webUrl={'https://google.es'}
            characteristics={[
              t('recommendations.verificationStep.characteristic1'),
              t('recommendations.verificationStep.characteristic2'),
              t('recommendations.verificationStep.characteristic3')
            ]}
            imageUrl={`/images/recommendations/${i18n.resolvedLanguage}/recommendation2.svg`}
            user={user}
            status={recommendations.find((elem) => elem.name === recommendation)?.status}
            addRecommendation={addRecommendation}
            removeRecommendation={removeRecommendation}
          />
        );
      case 'trazability':
        return (
          <RecommendationDetail
            id={recommendations.find((elem) => elem.name === recommendation)?.id}
            name={recommendation}
            title={t('recommendations.trazabilityStep.title')}
            description={t('recommendations.trazabilityStep.description')}
            webUrl={'https://google.es'}
            characteristics={[
              t('recommendations.trazabilityStep.characteristic1'),
              t('recommendations.trazabilityStep.characteristic2'),
              t('recommendations.trazabilityStep.characteristic3')
            ]}
            imageUrl={`/images/recommendations/${i18n.resolvedLanguage}/recommendation3.svg`}
            user={user}
            status={recommendations.find((elem) => elem.name === recommendation)?.status}
            addRecommendation={addRecommendation}
            removeRecommendation={removeRecommendation}
          />
        );
      case 'otherStudies':
        return (
          <RecommendationDetail
            id={recommendations.find((elem) => elem.name === recommendation)?.id}
            name={recommendation}
            title={t('recommendations.otherStudiesStep.title')}
            description={t('recommendations.otherStudiesStep.description')}
            webUrl={'https://google.es'}
            characteristics={[
              t('recommendations.otherStudiesStep.characteristic1'),
              t('recommendations.otherStudiesStep.characteristic2'),
              t('recommendations.otherStudiesStep.characteristic3')
            ]}
            imageUrl={`/images/recommendations/${i18n.resolvedLanguage}/recommendation4.svg`}
            user={user}
            status={recommendations.find((elem) => elem.name === recommendation)?.status}
            addRecommendation={addRecommendation}
            removeRecommendation={removeRecommendation}
          />
        );
      default:
        return <></>;
    }
  };

  const selectRecommendation = (id: string) => {
    setRecommendation(id);
  };

  const handleSwitchGroupMode = () => {
    if (loading) return;
    if (groupDataMode === 'total') {
      setGroupDataMode('investment');
    } else {
      setGroupDataMode('total');
    }
  };

  return (
    <section className='recommendations'>
      <div className='recommendations__header page-header'>
        <h3 className='headline3-font on-light-text-color'>{t('recommendations.title')}</h3>
        <h4 className='subtitle2-font on-light-text-color'>{t('recommendations.subtitle')}</h4>
      </div>
      {flags?.consolidation && (
        <div className='recommendations__mode'>
          <SlideToggle2
            options={[
              {
                id: 'total',
                name: t('dashboard.consolidated'),
                tooltip: t('dashboard.consolidatedTooltip')
              },
              {
                id: 'investment',
                name: t('dashboard.investment'),
                tooltip: t('dashboard.investmentTooltip')
              }
            ]}
            value={{ id: groupDataMode, name: groupDataMode }}
            setValue={handleSwitchGroupMode}
          />
        </div>
      )}
      {loading ? (
        <LoaderTables />
      ) : (
        <div className='recommendations__body'>
          <OrganizationCO2Info data={data} offsets={offsets} />
          <div className='recommendations__slider'>
            <SliderCard
              id='energy'
              title={t('recommendations.step1')}
              subtitle={t('recommendations.energy')}
              status={recommendations.find((elem) => elem.name === 'energy')?.status}
              percentage={'+18.7%'}
              onSelect={selectRecommendation}
              recommendation={recommendation}
              icon={'/images/icons/thunder50.svg'}
              // recommended
              premium={false}
              subscriptionPlan={foundOrganization.subscription_plan}
            />
            <SliderCard
              id='verification'
              title={t('recommendations.step2')}
              subtitle={t('recommendations.verification')}
              status={recommendations.find((elem) => elem.name === 'verification')?.status}
              percentage={'+18.7%'}
              onSelect={selectRecommendation}
              recommendation={recommendation}
              icon={'/images/icons/thumbsup50.svg'}
              premium
              subscriptionPlan={foundOrganization.subscription_plan}
            />
            <SliderCard
              id='trazability'
              title={t('recommendations.step3')}
              subtitle={t('recommendations.trazability')}
              status={recommendations.find((elem) => elem.name === 'trazability')?.status}
              percentage={'+18.7%'}
              onSelect={selectRecommendation}
              recommendation={recommendation}
              icon={'/images/icons/stacked50.svg'}
              premium
              subscriptionPlan={foundOrganization.subscription_plan}
            />
            <SliderCard
              id='otherStudies'
              title={t('recommendations.step4')}
              subtitle={t('recommendations.otherStudies')}
              status={recommendations.find((elem) => elem.name === 'otherStudies')?.status}
              percentage={'+18.7%'}
              onSelect={selectRecommendation}
              recommendation={recommendation}
              icon={'/images/icons/bookmark.svg'}
              premium
              subscriptionPlan={foundOrganization.subscription_plan}
            />
            {/* <SliderCard
              id='mitecoInscription'
              title={t('recommendations.step5')}
              subtitle={t('recommendations.mitecoInscription')}
              status={recommendations.find((elem) => elem.name === 'mitecoInscription')?.status}
              percentage={'+18.7%'}
              onSelect={selectRecommendation}
              recommendation={recommendation}
              icon={'/images/icons/leaf.svg'}
              premium
              subscriptionPlan={foundOrganization.subscription_plan}
            /> */}
          </div>
          <div className='recommendations__detail'>{chooseRecomendation()}</div>
        </div>
      )}
    </section>
  );
}

export default Recommendations;
