import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FreeTrialContext } from '../../../../context/freeTrialContext';
import { UserContext } from '../../../../context/userContext';
import { usePremiumRoutes } from '../../../../customHooks/usePremiumRoutes';
import './styles.scss';
type Props = {
  section: SubSectionType;
  subscriptionPlan: string;
};

function SubSection({ section, subscriptionPlan }: Props) {
  const { text, route } = section;
  const freeTrialContext = useContext(FreeTrialContext);
  const PREMIUM_ROUTES = usePremiumRoutes();
  const user = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname + location.search;

  const handleOnClick = () => {
    const premium = PREMIUM_ROUTES.find((elem) => elem === route);

    if (!route && (user?.email.includes('dcycle') || !user?.selectedOrganization)) {
      return;
    }

    if (!route) {
      section.onClickCallback?.();
      return;
    }

    if (!premium || subscriptionPlan !== 'free_plan') {
      const urlSearchParams = new URLSearchParams(route.split('?')[1]);

      const actualUrlSearchParams = new URLSearchParams(location.search.split('?')[1]);

      urlSearchParams.forEach((value, key) => {
        actualUrlSearchParams.set(key, value);
      });

      const routeParsed = route.split('?')[0] + '?' + actualUrlSearchParams.toString();
      navigate(routeParsed, { replace: false });
    }

    if (premium && subscriptionPlan === 'free_plan') {
      freeTrialContext?.setShowPremiumFeatureModal(true);
    }

    section.onClickCallback?.();
  };

  if (section.hide) return null;

  const selected = route && pathname?.includes(route) ? 'active' : '';
  return (
    <>
      <div
        className={`subsection side-menu-subsection-font on-light-text-color ${selected}`}
        onClick={handleOnClick}>
        <span className={selected}>{text}</span>
      </div>
    </>
  );
}

export default SubSection;
