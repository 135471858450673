import './styles.scss';
type Props = {
  email: string;
  remove: (email: string) => void;
};
function UserInvitedTag({ email, remove }: Props) {
  const removeUser = () => {
    remove(email);
  };
  return (
    <div className='user-invited-tag on-light-text-color gray-bg-color2 body1-bold-font'>
      {email}
      <img src='/images/icons/close.svg' alt='close-icon' onClick={removeUser} />
    </div>
  );
}

export default UserInvitedTag;
