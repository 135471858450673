import { validate } from 'email-validator';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HoldingCompany } from '../../../../../types/entities/holdings';
import { User } from '../../../../../types/entities/user';
import apiFetch from '../../../../../utils/apiFetch';
import Button from '../../../../ui/button/Button';
import InputText from '../../../../ui/formComponents/inputText/InputText';
import Modal from '../../../../ui/modal/Modal';
import UserInvitedTag from '../../../../ui/userInvitedTag/UserInvitedTag';
import './styles.scss';

type Props = {
  user: User;
  addCompanies: (companiesToAdd: HoldingCompany[]) => void;
};
function InviteByMail({ user, addCompanies }: Props) {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [users, setUsers] = useState<string[]>([]);
  const [errors, setErrors] = useState<ErrorType[]>([]);

  const [loadingButton, setLoadingButton] = useState(false);

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const newErrors = errors.filter((elem) => elem.error !== 'email');
    setErrors(newErrors);
    setEmail(e.target.value);
  };

  const inviteCompany = async () => {
    try {
      setLoadingButton(true);
      if (users.length === 0) {
        setErrors([{ error: 'email' }]);
      } else {
        setErrors([]);
        const response = await apiFetch(
          'POST',
          '/invite_users',
          users.map((email) => ({
            invited_by_user_id: user.id,
            email,
            organization_id: user?.selectedOrganization,
            role: 'child',
            lang: i18n.resolvedLanguage
          })),
          {
            'x-organization-id': user?.selectedOrganization
          }
        );
        if (response.status === 201) {
          addCompanies(
            users.map((email) => ({ child_id: '', child_name: email, status: 'pending' }))
          );
        }
      }
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
    }
  };

  const addUser = () => {
    if (email !== '' && !users.includes(email) && validate(email)) {
      setUsers([...users, email]);
    } else {
      setErrors([
        {
          error: 'email',
          description: t('error.invalidEmail')
        }
      ]);
    }
    setEmail('');
  };

  const removeUser = (email: string) => {
    const newUsers = users.filter((elem) => elem !== email);
    setUsers(newUsers);
  };

  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addUser();
    }
  };

  return (
    <div className='invite-company'>
      <Modal.Header
        title={t('companies.inviteCompany')}
        description={t('companies.inviteCompanyDescription')}
      />
      <div className='form-wrapper'>
        <InputText
          icon={'/images/icons/person.svg'}
          placeholder={t('companies.writeEmail')}
          label={t('companies.email')}
          value={email}
          onChangeValue={onChangeEmail}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'email')}
          onKeyPress={handleOnKeyPress}
        />
      </div>
      <div className='users-wrapper'>
        {users.map((user, index) => (
          <UserInvitedTag email={user} remove={removeUser} key={index} />
        ))}
      </div>
      <div className='buttons'>
        <Button lookAndFeel='secondary' text={t('companies.add')} size='small' onClick={addUser} />
        <Button
          lookAndFeel='primary'
          text={t('companies.send')}
          onClick={inviteCompany}
          size='small'
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default InviteByMail;
