import { useRef } from 'react';
import { MilestoneType } from '../../../../../../../customHooks/useMilestones';
import Tooltip from '../../../../../../ui/tooltip/Tooltip';
import { replaceStrongTags } from '../../../../../../../utils/stringToBold';

type Props = {
  data: MilestoneType;
  handleSelectMilestone: (milestone: MilestoneType) => void;
  selected?: boolean;
};

const MilestoneCard = ({ data, handleSelectMilestone, selected = false }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { title, img, url, urlText, steps } = data;
  const handleSelect = () => {
    handleSelectMilestone(data);
  };

  const StepComponent = ({ title }: { title: string }) => {
    return (
      <div className='step'>
        <img src='/images/icons/tickGradientCircle2.svg' />
        <span className='subtitle3-font'>{title}</span>
      </div>
    );
  };
  return (
    <div
      className={`milestone-card pointer ${
        selected ? 'highlight-border-color tag-bg-color' : 'card-border-color'
      }`}
      onClick={handleSelect}
      ref={ref}>
      <div>
        <h3 className='headline4-font'>{title}</h3>
        <img src={img} alt='welcome' className='main-image' />
      </div>
      <div className='milestone-card__steps'>
        {steps.map((step, index) => {
          return (
            (step?.tooltip && (
              <div className='tooltip-info'>
                <StepComponent title={step.title} />
                <Tooltip
                  text={replaceStrongTags(step.tooltip)}
                  size='small'
                  position={step.title.toLowerCase().includes('carbon') ? 'top' : 'right'}
                  key={index}
                  containerPosition={ref.current?.offsetParent?.getBoundingClientRect()}
                  icon='/images/icons/infoGradient.svg'
                />
              </div>
            )) || <StepComponent title={step.title} key={index} />
          );
        })}
      </div>
      <a
        href={url}
        target='_blank'
        rel='noreferrer'
        className='highlight-text-color subtitle3-font'>
        {urlText}
      </a>
    </div>
  );
};

export default MilestoneCard;
