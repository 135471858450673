import Icon from '../../../../ui/icon/Icon';
import Label from '../../../../ui/label/Label';
import '../styles.scss';
import { renderStatus } from '../utils/utils';

type Props = {
  status: string;
  text?: string;
} & React.ComponentProps<typeof Label>;

const StatusTagEmployee = ({ text, status, ...rest }: Props) => {
  const parsedStatus = renderStatus(status);
  return (
    <Label {...rest} lookAndFeel={parsedStatus.color}>
      <div className='label-loading-wrapper'>
        <Icon icon={parsedStatus?.icon} color={parsedStatus.textColor} size='medium' />
        {text}
      </div>
    </Label>
  );
};

export default StatusTagEmployee;
