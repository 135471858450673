import { useTranslation } from 'react-i18next';
import { useFormContext } from '../hooks/useForm';
import InputLabel from '../../../../ui/formComponents2/inputUtils/inputWrapper/components/InputLabel';
import Checkbox from '../../../../ui/formComponents/checkbox/Checkbox';
import InputMultiselector from '../../../../ui/formComponents2/inputMultiselector/InputMultiselector';
import FormTextArea from '../../../../ui/formComponents2/formInputs/formTextArea/FormTextArea';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import ErrorText from '../../../../ui/errorText/ErrorText';

const Scopes = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'orgDescriptionModal.ghg_report.form.scopes'
  });
  const { formData, selectAll, isAllSelected, onChangeScopes, onChangeValue, onChangeCategories } =
    useFormContext();
  return (
    <>
      <div className='input'>
        <InputLabel label={t('scopes')} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.33rem'
          }}>
          <Checkbox onChange={selectAll} text={t('all')} selected={isAllSelected} type='square' />
          {/* <InputMultiselector items={formData.scopes} onChange={onChangeScopes} /> */}
          <InputMultiselector items={formData.categories} onChange={onChangeCategories} />
          <ErrorText>
            {formData.errors.find((elem) => elem.error === 'scopes')?.description}
          </ErrorText>
        </div>
      </div>
      <FormTextArea
        value={formData.justificationScopes}
        label={t('justificationScopes')}
        placeholder={t('justificationScopesPlaceholder')}
        onChange={onChangeValue('justificationScopes')}
        error={formData.errors.find((elem) => elem.error === 'justificationScopes')}
      />

      <FormElementFull>
        <FormTextArea
          label={t('otherAssumptions')}
          placeholder={t('otherAssumptionsPlaceholder')}
          tooltip={t('otherAssumptionsTooltip')}
          value={formData.otherAssumptions}
          onChange={onChangeValue('otherAssumptions')}
          error={formData.errors.find((elem) => elem.error === 'otherAssumptions')}
        />
      </FormElementFull>
    </>
  );
};

export default Scopes;
