import { Vehicle } from '../../../../../../types/entities/vehicle';
import { useTranslation } from 'react-i18next';
import { limitString } from '../../../../../../utils/limitString';
import Card from '../../../../../ui/cards/card/Card';
import ButtonDropdown from '../../../../../ui/buttonDropdown/ButtonDropdown';
import Icon from '../../../../../ui/icon/Icon';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import useErrors from '../../../../../../customHooks/useErrors';

type Props = {
  vehicle: Vehicle;
  description: string;
  listView: boolean;
  dropdownOptions: {
    id: string;
    name: string;
    onClick: () => void;
  }[];
};

const VehicleCardDisabled = ({ vehicle, description, listView, dropdownOptions }: Props) => {
  const { t, i18n } = useTranslation();

  const ERRORS = useErrors();

  const errorTitle = t('vehicles.errorTitle');

  const handlerErrorLicensePlate = (text: string) => {
    const splittedText = text.split(':');
    return <li>{ERRORS.VEHICLES.ERR_DUPLICATE_LICENSE_PLATE(splittedText[1]).description}</li>;
  };

  const errorText = vehicle?.error_messages?.map((error) => {
    if (error.includes('ERR_DUPLICATE_LICENSE_PLATE')) return handlerErrorLicensePlate(error);

    const errorObject = ERRORS.VEHICLES[error as keyof typeof ERRORS.VEHICLES];

    if (typeof errorObject === 'function') return '';

    return <li key={error}>{errorObject.description}</li>;
  });

  return (
    <Card key={vehicle.id} listView={listView}>
      <Card.Options>
        <ButtonDropdown
          button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
          options={dropdownOptions}></ButtonDropdown>
      </Card.Options>
      <TooltipWrapper
        text={
          <>
            <p>{errorTitle}</p>
            <ul
              style={{
                padding: '0 0.5rem'
              }}>
              {errorText}
            </ul>
          </>
        }>
        <Card.ContentDisabled>
          <>
            <Card.Icon
              icon={
                vehicle.type === 'passenger' ? '/images/icons/car.svg' : '/images/icons/truck.svg'
              }
              alt={vehicle.name}
            />

            <span className='headline4-font'>
              {vehicle.unknown_vehicle_type && i18n.exists(`vehicles.${vehicle.name}`)
                ? t(`vehicles.${vehicle.name}`)
                : limitString(vehicle?.name || '', 25)}
            </span>
            <span className='subtitle3-font'>{limitString(description, listView ? 200 : 30)}</span>
          </>
        </Card.ContentDisabled>
      </TooltipWrapper>
    </Card>
  );
};

export default VehicleCardDisabled;
