import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useModal from '../hooks/useModal';

const ModalC5 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });
  const { t: tOptions } = useTranslation('translation', {
    keyPrefix: 'sbti.criteriaForm.modal.services'
  });

  const mandatory = ['yesNo'];

  // const sectorOptions: SelectOptionFormat<ISbtiCriteriaSectors>[] = [
  //   {
  //     id: 'power',
  //     name: tOptions('power')
  //   },
  //   {
  //     id: 'services_building',
  //     name: tOptions('services_building')
  //   },
  //   {
  //     id: 'residential_building',
  //     name: tOptions('residential_building')
  //   },
  //   {
  //     id: 'cement',
  //     name: tOptions('cement')
  //   },
  //   {
  //     id: 'aluminium',
  //     name: tOptions('aluminium')
  //   },
  //   {
  //     id: 'pulp_and_paper',
  //     name: tOptions('pulp_and_paper')
  //   },
  //   {
  //     id: 'iron_and_steel',
  //     name: tOptions('iron_and_steel')
  //   },
  //   {
  //     id: 'other',
  //     name: tOptions('other')
  //   }
  // ];

  const { formData, setFormData, loading, patchCriteria, loadingButton, errorPatch } =
    useModal(mandatory);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  return (
    <>
      <FormHeader title={t('title')} />
      {loading && (
        <CustomSkeletonLoader
          count={1}
          style={{
            marginBottom: '2rem'
          }}
        />
      )}

      {!loading && (
        <FormWrapper>
          <FormSelect
            tooltip={t('tooltipModalC5')}
            label={t('c5Modal')}
            iconV2='holding'
            onChange={onChangeValue('yesNo')}
            value={
              formData.yesNo || {
                id: '',
                name: ''
              }
            }
            options={[
              { id: 'yes', name: t('yes') },
              { id: 'no', name: t('no') }
            ]}
            error={formData.errors.find((error) => error.error === 'yesNo')}
          />
          {/* <FormSelect
            label={t('sector')}
            iconV2='sector'
            value={
              formData.sector || {
                id: '',
                name: ''
              }
            }
            onChange={onChangeValue('sector')}
            options={sectorOptions}
            error={formData.errors.find((error) => error.error === 'sector')}
          /> */}
        </FormWrapper>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('save')}
          loading={loadingButton}
          size='small'
          onClick={() => patchCriteria(formData)}
        />
      </FormButtonSection>
      <ErrorText>{errorPatch && errorPatch.description}</ErrorText>
    </>
  );
};

export default ModalC5;
