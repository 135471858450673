import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const GrayBackground = ({ className, ...rest }: Props) => {
  const newClassName = 'embebed-code ' + className;
  return <div className={newClassName} {...rest} />;
};

export default GrayBackground;
