import { useEffect, useState } from 'react';
import { TransportCombination } from '../../../../../../types/entities/shipment';
import { getTransportCombinations } from '../../../../../../services/api/transportRoutes';

const useGetTransportCombinations = () => {
  const [transportCombinations, setTransportCombinations] = useState<TransportCombination>(
    {} as TransportCombination
  );
  const [loading, setLoading] = useState<boolean>(false);

  const fetchTransportCombinations = async () => {
    setLoading(true);
    const response = await getTransportCombinations();
    setLoading(false);
    if (!response) return;
    const parsedResponse: any = {};
    Object.keys(response).forEach((key) => {
      parsedResponse[key] = response[key].map(
        (item: { electric: boolean; refrigerated: boolean }) => ({
          electric: item.electric ? 'yes' : 'no',
          refrigerated: item.refrigerated ? 'yes' : 'no'
        })
      );
    });
    setTransportCombinations(parsedResponse);
  };

  useEffect(() => {
    fetchTransportCombinations();
  }, []);

  return { transportCombinations, loading };
};

export default useGetTransportCombinations;
