import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { FormFundData } from '../../../../../types/entities/funds';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import useGetData from '../createFund/hooks/useGetData';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import Label from '../../../../ui/label/Label';
import InputMultiselector from '../../../../ui/formComponents2/inputMultiselector/InputMultiselector';
import CustomSkeletonLoader from '../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import ErrorText from '../../../../ui/errorText/ErrorText';
import InputLabel from '../../../../ui/formComponents2/inputUtils/inputWrapper/components/InputLabel';

type Props = {
  formData: FormFundData;
  setFormData: Dispatch<SetStateAction<FormFundData>>;
  formDataFilled?: FormFundData;
};

const FormFund = ({ formData, setFormData }: Props) => {
  const { t } = useTranslation();

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const { questions, loading } = useGetData();

  useEffect(() => {
    if (formData.questions.length >= 2) {
      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.filter((error) => error.error !== 'questions')
      }));
    }
  }, [formData.questions.length]);

  const onChangeQuestion = (questionId: string) => (value: boolean) => {
    const foundQuestion = formData.questions?.find((question) => question.id === questionId);
    if (!foundQuestion) {
      setFormData((prev) => ({
        ...prev,
        questions: [
          ...prev.questions,
          {
            id: questionId,
            name: questions.find((question) => question.id === questionId)?.name ?? ''
          }
        ]
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        questions: prev.questions.filter((question) => question.id !== questionId)
      }));
    }
  };

  const questionsError = formData.errors.find((error) => error.error === 'questions');

  if (loading)
    return (
      <FormWrapper>
        <CustomSkeletonLoader count={3} />
      </FormWrapper>
    );
  return (
    <>
      <FormWrapper>
        <FormText
          icon={'/images/icons/holding.svg'}
          label={t('funds.labelName')}
          placeholder={t('funds.writeName')}
          value={formData.name}
          onChange={onChangeValue('name')}
          error={formData.errors.find((error) => error.error === 'name')}
        />
        <FormNumber
          icon={'/images/icons/tag.svg'}
          label={t('funds.labelSize')}
          placeholder={t('funds.writeSize')}
          value={formData.size}
          onChange={onChangeValue('size')}
          error={formData.errors.find((error) => error.error === 'size')}
        />
        <FormElementFull>
          <FormSelect
            icon={'/images/icons/papersGradient.svg'}
            label={t('funds.article')}
            placeholder={t('funds.writeArticle')}
            options={[
              {
                id: 'article_9',
                name: t('funds.articles.article_9')
              },
              {
                id: 'article_6',
                name: t('funds.articles.article_6')
              }
            ]}
            value={formData?.article}
            onChange={onChangeValue('article')}
            error={formData.errors.find((error) => error.error === 'article')}
            sort={false}
          />
          {formData.article.id && (
            <Label lookAndFeel='secondary' style={{ marginTop: '0.5rem' }}>
              {t(`funds.articles.${formData.article.id}_disclaimer`)}
            </Label>
          )}
        </FormElementFull>
        <InputLabel label={t('funds.voluntaryPaisSelected')} />
        <div
          className='flex flex-col gap-4'
          style={{ height: '6rem', overflowY: 'auto', width: '100%' }}>
          <InputMultiselector
            items={questions.map((elem) => ({
              id: elem.id,
              label: elem.name,
              selected: !!formData.questions?.find((question) => question.id === elem.id)
            }))}
            onChange={onChangeQuestion}
          />
        </div>
        {!!questionsError && (
          <ErrorText style={{ marginTop: '0.5rem' }}>{questionsError.description}</ErrorText>
        )}
      </FormWrapper>
    </>
  );
};

export default FormFund;
