import { useEffect, useState } from 'react';
import { getFacilitiesValidation } from '../../../services/api/facility';
import { getVehiclesValidation } from '../../../services/api/vehicle';
import { VALIDATION_FACILITIES_CATEGORIES } from '../../../types/entities/facility';
import { VEHICLES_VALIDATION_CATEGORY } from '../../../types/entities/vehicle';

export type VALIDATION_CATEGORIES = VEHICLES_VALIDATION_CATEGORY & VALIDATION_FACILITIES_CATEGORIES;

const VEHICLE_CATEGORY = 'consumptions';

export enum ErrorTypesValidation {
  MISSING_RANGE = 'MISSING-RANGE',
  MISSING_ATTACHMENT = 'MISSING-ATTACHMENT',
  MISSING_CUPS = 'MISSING-CUPS',
  VALUES_NOT_OK = 'VALUES-NOT-OK',
  DUPLICATE = 'DUPLICATE',
  WRONG_INVOICE_CUPS = 'WRONG-INVOICE-CUPS',
  DUPLICATE_CUPS = 'DUPLICATE-CUPS',
  OVERLAP = 'OVERLAP',
  DIVIDED_INVOICE = 'DIVIDED-INVOICE'
}
export type ValidationError = {
  assumption?: {
    id: string;
    comment: string;
  };
  error: ErrorTypesValidation;
  start_date?: number;
  end_date?: number;
  invoice_id?: string;
  consumption_id?: string;
  other_facility_name?: string;
  facility_fuel?: string;
  category?: string;
  [key: string]: any;
  // Probably more data will come from the back in the validation errors, to identify the invoices and the vehicle consumptions, such as the invoice number
};

const useGetData = (category: VALIDATION_CATEGORIES) => {
  const [data, setData] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [params, setRequestParams] = useState<{
    start_date: number;
    end_date: number;
    category: VALIDATION_CATEGORIES;
    selectedId: [string];
  } | null>();
  useEffect(() => {
    const callData = async () => {
      setLoading(true);
      let validationData = [];
      if (category === VEHICLE_CATEGORY && params) {
        validationData = await getVehiclesValidation(
          params.start_date,
          params.end_date,
          params.selectedId
        );
      } else if (params) {
        validationData = await getFacilitiesValidation(
          params.start_date,
          params.end_date,
          category as VALIDATION_CATEGORIES,
          params.selectedId
        );
      }
      setData(validationData?.errors);
      setLoading(false);
    };
    callData();
  }, [category, params, triggerUpdate]);

  return { data, setRequestParams, loading, setTriggerUpdate };
};

export default useGetData;
