import React from 'react';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import { useTranslation } from 'react-i18next';

const WastesDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <DescriptionBox
      descriptions={[
        t('ghgReport.wastesDisclaimer.description1'),
        t('ghgReport.wastesDisclaimer.description2'),
        t('ghgReport.wastesDisclaimer.description3'),
        t('ghgReport.wastesDisclaimer.description4'),
        t('ghgReport.wastesDisclaimer.description5'),
        t('ghgReport.wastesDisclaimer.description6')
      ]}
    />
  );
};

export default WastesDisclaimer;
