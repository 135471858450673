import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EMPLOYEE_FORM_GUIDE } from '../../../../../../constants';
import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import { replaceStrongTags } from '../../../../../../utils/stringToBold';
import InformationCardPlain from '../../../../../ui/InformationCard/InformationCardPlain';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import '../styles.scss';

function StepNewInitPepeJeans() {
  const { t, i18n } = useTranslation();

  const EMPLOYEE_FORM_GUIDE_TRANSLATED =
    i18n.resolvedLanguage === 'es' ? EMPLOYEE_FORM_GUIDE.es : EMPLOYEE_FORM_GUIDE.en;

  const { handleInItinere } = useContext(EmployeeFormContext);

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle2')}
      subtitle={replaceStrongTags(t('employeeForm.formSubtitle3'))}>
      <a
        href={EMPLOYEE_FORM_GUIDE_TRANSLATED}
        target='_blank'
        rel='noreferrer'
        className='highlight-text-color pointer subtitle3-font'>
        {t('employeeForm.guideHelp')}
      </a>
      <StepWrapper
        title={t('employeeForm.addMoreType')}
        tooltip={t('employeeForm.addMoreTypeTooltip')}
        stepNumber={1}
        width={500}>
        <div className='information-card-wrapper'>
          <InformationCardPlain
            image={
              <div className='image-flex-wrapper'>
                <img src='/images/icons/house.svg' alt='in itinere' />
                <img src='/images/icons/arrow.svg' alt='in itinere' />
                <img src='/images/icons/building.svg' alt='in itinere' />
              </div>
            }
            description={replaceStrongTags(t('employeeForm.addInItinere'))}
            onClick={handleInItinere}
          />
        </div>
      </StepWrapper>
    </StepControllerContainer>
  );
}

export default StepNewInitPepeJeans;
