import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

function LoaderStandard(props: Props) {
  const newClassName = `dot-falling ${props.className}`;

  return <div {...props} className={newClassName}></div>;
}

export default LoaderStandard;
