import { replaceStrongTags } from '../../../../utils/stringToBold';
import { ErrorTypesValidation, ValidationError } from '../../hooks/useGetData';
import { useTranslation } from 'react-i18next';
import { convertDateBackToFrontTimestamp } from '../../../../utils/convertDates';

type Props = {
  elem: ValidationError;
};

const ErrorDescription = ({ elem }: Props) => {
  const { t } = useTranslation();
  // Component that represents the description of the error in the list, depending on the error type
  const { error, start_date, end_date } = elem;
  let description = '';

  if (error === ErrorTypesValidation.MISSING_RANGE) {
    description = t('validation.errors.missingRange', {
      start_date: start_date && convertDateBackToFrontTimestamp(start_date),
      end_date: end_date && convertDateBackToFrontTimestamp(end_date),
      missing_range_cups: elem.cups
        ? t('validation.errors.missingRangeCups', { cups: elem.cups })
        : ''
    });
  }
  if (error === ErrorTypesValidation.MISSING_ATTACHMENT) {
    description = t('validation.errors.missingAttachment');
  }
  if (error === ErrorTypesValidation.MISSING_CUPS) {
    description = t('validation.errors.missingCups');
  }
  if (error === ErrorTypesValidation.VALUES_NOT_OK && elem.facility_id) {
    description = t('validation.errors.valuesNotOk');
  }
  if (error === ErrorTypesValidation.VALUES_NOT_OK && elem.vehicle_id) {
    description = t('validation.errors.valuesNotOkConsumption');
  }
  if (error === ErrorTypesValidation.DUPLICATE) {
    description = t('validation.errors.duplicate');
  }
  if (error === ErrorTypesValidation.WRONG_INVOICE_CUPS) {
    description = t('validation.errors.wrongInvoiceCups');
  }
  if (error === ErrorTypesValidation.DUPLICATE_CUPS) {
    description = t('validation.errors.duplicateCups', {
      cups: elem.cups ?? '',
      facilityName: elem.other_facility_name ?? ''
    });
  }
  if (error === ErrorTypesValidation.DIVIDED_INVOICE) {
    description = t('validation.errors.addJustificationForDividedInvoice');
  }

  if (error === ErrorTypesValidation.OVERLAP) {
    description =
      elem.category === 'electricity'
        ? t('validation.errors.overlapElectricity', {
            overlapElectricityCUPS: elem.cups
              ? t('validation.errors.overlapElectricityCUPS', { cups: elem.cups })
              : ''
          })
        : t('validation.errors.overlap', {
            fuel: t(`facility_fuels.${elem.facility_fuel}`) ?? '',
            source:
              t(
                `validation.errors.source${
                  elem.category
                    ? elem.category.charAt(0).toUpperCase() + elem.category?.slice(1)
                    : ''
                }`
              ) ?? ''
          });
  }

  return <>{replaceStrongTags(description)}</>;
};

export default ErrorDescription;
