import apiFetch from '../../../../utils/apiFetch';

type Params = {
  category: string;
  fileId: string;
  userId: string;
};

export const deleteFile = async ({ fileId, category, userId }: Params) => {
  const url = `/organizations/files/uploaded/${fileId}`;

  return await apiFetch('DELETE', url, null, { 'x-user-id': userId }, { category });
};
