import { useTranslation } from 'react-i18next';
import { OrgInfo } from '../../../../types/entities/holdings';
import { Column } from '../../../../types/table';
import formatNumber from '../../../../utils/formatNumber';
import Dot from '../../../ui/dot/Dot';
import Table from '../../../ui/table/Table';
import CardHeader from '../cardHeader/CardHeader';
import './styles.scss';

type Props = {
  dataHoldingList: OrgInfo[];
  scope?: number;
  categoriesColumns?: Column[];
  showTotal?: boolean;
  indexSlice?: number;
  lengthData?: number;
};
const ChildsInfo = ({
  dataHoldingList,
  scope,
  categoriesColumns,
  showTotal = true,
  indexSlice,
  lengthData
}: Props) => {
  const { t } = useTranslation();

  const columns: Column[] = [
    {
      title: t('dashboard.company'),
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: (
        <span>
          <Dot type={'scope1'} />
          {t('dashboard.scope1')}
        </span>
      ),
      dataIndex: 'scope1',
      key: 'scope1',
      render: (value?: string) => {
        return <span>{!value ? '0' : value}</span>;
      },
      align: 'right'
    },
    {
      title: (
        <span>
          <Dot type={'scope2'} />
          {t('dashboard.scope2')}
        </span>
      ),
      dataIndex: 'scope2',
      key: 'scope2',
      render: (value?: string) => {
        return <span>{!value ? '0' : value}</span>;
      },
      align: 'right'
    },
    {
      title: (
        <span>
          <Dot type={'scope3'} />
          {t('dashboard.scope3')}
        </span>
      ),
      dataIndex: 'scope3',
      key: 'scope3',
      render: (value?: string) => {
        return <span>{!value ? '0' : value}</span>;
      },
      align: 'right'
    },
    {
      title: 'Total',
      dataIndex: 'co2e',
      key: 'co2e',
      align: 'right',
      render: (value?: string) => (
        <span className='highlight-report-text-color tag-bigger-font'>{value}</span>
      )
    }
  ];

  const addedCategoriesColumn = categoriesColumns || [];

  const columns2: Column[] = [
    {
      title: t('dashboard.company'),
      dataIndex: 'company',
      key: 'company'
    },
    ...addedCategoriesColumn,
    {
      title: 'Total',
      dataIndex: 'co2e',
      key: 'co2e',
      align: 'right',
      render: (value?: string) => (
        <span className='highlight-report-text-color tag-bigger-font'>{value}</span>
      )
    },
    {
      title: '',
      dataIndex: 'goToCompany',
      key: 'goToCompany'
    }
  ];

  const columns2Filtered = columns2.filter((column) => column.key !== 'co2e' || showTotal);

  const renderChildsInfoHeader = () => {
    if (!scope) {
      return (
        <CardHeader
          title={t('report.childsInfo')}
          description={t('report.childsInfoDescription')}
        />
      );
    }
    if (scope === 1) {
      return (
        <CardHeader
          title={t('report.childsInfoScope1')}
          description={t('report.childsInfoScope1Description')}
        />
      );
    }
    if (scope === 2) {
      return (
        <CardHeader
          title={t('report.childsInfoScope2')}
          description={t('report.childsInfoScope2Description')}
        />
      );
    }
    if (scope === 3) {
      return (
        <CardHeader
          title={`${t('report.childsInfoScope3')} (${indexSlice}/${lengthData})`}
          description={t('report.childsInfoScope3Description')}
        />
      );
    }
  };

  let dataHoldingListParsed = dataHoldingList.map((elem) => ({
    ...elem,
    co2e: (
      <span className='highlight-report-text-color tag-bigger-font'>
        {formatNumber(Number(elem.co2e))}
      </span>
    )
  }));

  if (scope) {
    dataHoldingListParsed = dataHoldingList.map((elem: any) => ({
      ...elem,
      co2e: formatNumber(elem[`scope${scope}`])
    }));
  }

  return (
    <div className='childs-info-report'>
      <div className='card main-bg-color'>
        {renderChildsInfoHeader()}
        <div className='childs-list-wrapper'>
          <Table
            columns={scope ? columns2Filtered.flat() : columns}
            data={dataHoldingListParsed}
            loading={false}
            size={'small'}
          />
        </div>
      </div>
    </div>
  );
};

export default ChildsInfo;
