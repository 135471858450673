import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';
import CardCTA from '../../ui/cards/cardCTA/CardCTA';
import Modal from '../../ui/modal/Modal';
import { CustomMetricsTable } from './components/customMetricsTable/CustomMetricsTable';
import SectionHeader from './components/sectionHeader/SectionHeader';
import UploadModal from './components/uploadModal/UploadModal';

export const CustomMetrics = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <section>
        <SectionHeader title={t('customizeMainPage.title')} />
        <CardCTA style={{ margin: '2rem 0 1rem 0' }}>
          <CardCTA.Header>
            <span className='headline4-font'>{t('customizeMainPage.customMetrics.title')}</span>
            <span className='subtitle3-font'>
              {t('customizeMainPage.customMetrics.startDescription')}
            </span>
          </CardCTA.Header>
          <CardCTA.Buttons>
            <Button
              lookAndFeel='primary'
              text={t('esgMetrics.uploadData')}
              size='small'
              onClick={() => setShowModal(true)}
            />
          </CardCTA.Buttons>
        </CardCTA>
        <CustomMetricsTable />
      </section>

      <Modal show={showModal} onClose={() => setShowModal(false)} maxWidth='650px' width='650px'>
        <UploadModal onClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
};
