import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../constants/routes';
import ErrorPage from '../ErrorPage';

const ErrorPageSomethingWentWrong = () => {
  const { t } = useTranslation();

  return <ErrorPage text={t('errorPage.somethingWentWrong')} goBack={ROUTES.IMPROVE_DASHBOARD} />;
};

export default ErrorPageSomethingWentWrong;
