import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import { MAX_PAYLOAD_SIZE } from '../../../../../constants';
import useGetBase64 from '../../../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { sendShipmentFileByEmail } from '../../../../../services/api/transportRoutes';
import { User } from '../../../../../types/entities/user';

import Button from '../../../../ui/button/Button';
import FileItem from '../../../../ui/fileItem/FileItem';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import InputFileSingle from '../../../../ui/formComponents/inputFile/InputFileSingle';
import './styles.scss';
type Props = {
  user: User;
  transportDirection: string;
  onUploadFile: () => void;
};

type FormData = {
  file: File | null;
  errors: ErrorType[];
};

const UploadShipmentsFile = ({ user, transportDirection, onUploadFile }: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    file: null,
    errors: []
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { fileBase64 } = useGetBase64(formData.file as File);

  const handleRemoveFile = (index: number) => {
    const newErrors = formData.errors.filter((error) => error.error !== 'file');
    setFormData((prev) => ({
      ...prev,
      file: null,
      errors: newErrors
    }));
  };

  const handleUploadFile = async () => {
    try {
      if (!formData.file) {
        setFormData((prev) => ({
          ...prev,
          errors: [
            {
              error: 'file',
              description: t('error.fileRequired')
            }
          ]
        }));
        return;
      }
      const fileSize = formData.file.size / (1024 * 1024);

      if (fileSize >= MAX_PAYLOAD_SIZE) {
        setFormData((prev) => ({
          ...prev,
          errors: [
            {
              error: 'file',
              description: t('error.maxPayloadSize')
            }
          ]
        }));
        return;
      }
      setLoadingButton(true);
      if (fileBase64) {
        const response = await sendShipmentFileByEmail(
          formData.file,
          formData.file.name,
          transportDirection
        );
        if (!response) {
          setFormData((prev) => ({
            ...prev,
            errors: [
              {
                error: 'file',
                description: t('error.somethingWentWrong')
              }
            ]
          }));
          return;
        }
        onUploadFile();
      }
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.log(error);
    }
  };

  const downloadFile = () => {
    const url = '/files/shipments/transport_and_distribution_template_Dcycle.csv';
    window.open(url, '_blank');
  };

  const renderTitle = () => {
    const text = t('shipmentsUpload.uploadYourFile');
    const replacements = [
      {
        search: '{{templates}}',
        component: (
          <span className='highlight-text-color pointer' onClick={downloadFile}>
            {t('shipmentsUpload.exampleTemplates')}
          </span>
        )
      }
      // {
      //   search: '{{info}}',
      //   component: (
      //     <span className='highlight-text-color pointer' onClick={handleShowHowToExport}>
      //       {t('shipmentsUpload.fromYourAccounting')}
      //     </span>
      //   )
      // }
    ];

    let replacedText: any = text;

    replacements.forEach(({ search, component }) => {
      replacedText = reactStringReplace(replacedText, search, () => component);
    });

    return replacedText;
  };

  return (
    <div className='upload-shipments-file'>
      <FormHeader
        title={t('shipments.uploadFileTitle')}
        description={t('shipments.uploadFileDescription')}
      />
      <p className='file-label body1-bold-font'>{renderTitle()}</p>
      <InputFileSingle
        handleFile={onChangeValue('file')}
        buttonText={t('shipments.uploadFile')}
        labelText={t('shipments.dragAndDropFile')}
        fileName={formData.file?.name || ''}
        allowedTypes={[]}
        error={formData.errors.find((error) => error.error === 'file')}
      />
      {formData.file ? (
        <>
          <p className='file-label body1-bold-font'>{t('shipments.fileUploaded')}</p>
          <div className='files-wrapper'>
            <FileItem
              file={formData.file}
              index={0}
              icon={'/images/icons/paper.svg'}
              removeFile={handleRemoveFile}
            />
          </div>
        </>
      ) : null}
      <div className='buttons'>
        <Button
          lookAndFeel={'primary'}
          onClick={handleUploadFile}
          text={t('shipments.save')}
          loading={loadingButton}
        />
      </div>
    </div>
  );
};

export default UploadShipmentsFile;
