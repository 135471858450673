import { useTranslation } from 'react-i18next';
import useDragScroll from '../../../../../customHooks/useDragScroll';
import { InputSize } from '../../../../../types/utilsEnums/input';
import FilterSection from '../../../../ui/filterSection/FilterSection';
import InputCalendarDouble from '../../../../ui/formComponents2/inputCalendarDouble/InputCalendarDouble';
import InputNumber from '../../../../ui/formComponents2/inputNumber/InputNumber';
import InputText from '../../../../ui/formComponents2/inputText/InputText';
import InputWrapperPlain from '../../../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import InputWrapperPlainLabel from '../../../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlainLabel';
import Select from '../../../../ui/formComponents2/select/Select';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import useConstants from './hooks/useConstants';
import useFetchData from './hooks/useFetchData';
import useFilters from './hooks/useFilters';
import Notification from '../common/components/notification/Notification';
import './styles.scss';
import { useState } from 'react';
import Modal from '../../../../ui/modal/Modal';
import EstimateAllShipments from '../common/components/estimateAllShipments/EstimateAllShipments';
import formatNumber from '../../../../../utils/formatNumber';
import numberToDecimal, {
  formatNumberToDecimalNonZero
} from '../../../../../utils/numberToDecimal';
import EstimateShipment from '../common/components/estimateShipment/EstimateShipment';
import { ShipmentStatus } from '../../../../../types/entitiesEnums/shipments';
import SuccessLabel from '../../../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../../../ui/tooltip/TooltipWrapper';
import WarningLabel from '../../../../ui/statusLabels/warningLabel/WarningLabel';
import PendingLabel from '../../../../ui/statusLabels/pendingLabel/PendingLabel';
import Tooltip from '../../../../ui/tooltip/Tooltip';

type Props = {
  clients: SelectOptionFormat[];
  handleGetDown: () => Promise<void>;
  loadingClients: boolean;
  handleSearchClient: (searchValue: string) => Promise<void>;
};

const Shipment = ({ clients, handleGetDown, loadingClients, handleSearchClient }: Props) => {
  const { t, i18n } = useTranslation();
  const { columns, tocs } = useConstants();

  // const [showEstimateAllShipments, setShowEstimateAllShipments] = useState(false);

  // const [shipmentToEstimate, setShipmentToEstimate] = useState<ILogisticsShipmentFrontend>();

  const {
    onChangeValue,
    handleDateError,
    handleRemoveFilters,
    counterFiltersSelected,
    filters,
    debouncedFilters
  } = useFilters();

  const { fetchData, firstLoading, loading, total, data, editElement } =
    useFetchData(debouncedFilters);

  const { containerRef, handleMouseDown, handleMouseMove, handleMouseUp, handleWheel } =
    useDragScroll();

  const getErrorMessage = (errorMessages: string[]) => {
    if (errorMessages.length === 0) {
      return {
        error: t('general.withErrors'),
        tooltip: t('logistics.errors.general')
      };
    }
    if (errorMessages.length > 1) {
      return {
        error: t('general.reviewSeveral'),
        tooltip: (
          <>
            <span>{t('general.reviewTheFollowingIncidences')}</span>
            <ul>
              {errorMessages.map((elem: string) => (
                <li key={elem}>
                  {i18n.exists(`logistics.errors.${elem.toLowerCase()}_tooltip`)
                    ? t(`logistics.errors.${elem.toLowerCase()}_tooltip`)
                    : t('logistics.errors.general')}
                </li>
              ))}
            </ul>
          </>
        )
      };
    }
    const error = errorMessages[0];
    if (!error) {
      return {
        error: t('general.withErrors'),
        tooltip: t('logistics.errors.general')
      };
    }
    // check if t(`logistics.errors.${error.toLowerCase()}`) exists and tooltip also
    return {
      error: i18n.exists(`logistics.errors.${error.toLowerCase()}`)
        ? t(`logistics.errors.${error.toLowerCase()}`)
        : t('general.withErrors'),
      tooltip: i18n.exists(`logistics.errors.${error.toLowerCase()}_tooltip`)
        ? t(`logistics.errors.${error.toLowerCase()}_tooltip`)
        : t('logistics.errors.general')
    };
  };

  const statusIconSelect = (status: string, errorMessages: string[], showTooltip = true) => {
    if (status === ShipmentStatus.ACTIVE)
      return <SuccessLabel>{t('general.completed')}</SuccessLabel>;

    if (status === ShipmentStatus.ERROR) {
      if (showTooltip) {
        const { error, tooltip } = getErrorMessage(errorMessages);
        return (
          <TooltipWrapper text={tooltip} position='top' shouldAppear={!!tooltip}>
            <WarningLabel style={{ whiteSpace: 'nowrap' }}>{error}</WarningLabel>
          </TooltipWrapper>
        );
      }

      return <WarningLabel>{t('general.withErrors')}</WarningLabel>;
    }

    if (status === ShipmentStatus.PENDING) {
      return (
        <TooltipWrapper text={t('logistics.pendingTooltip')}>
          <PendingLabel>{t('general.pending')}</PendingLabel>
        </TooltipWrapper>
      );
    }

    return <></>;
  };

  const renderEstimated = (estimationIds: string[], idToSearch: string) => {
    if (!estimationIds || estimationIds.length === 0) return <></>;
    if (estimationIds.includes(idToSearch)) {
      return <Tooltip text={t(`logistics.estimations.${idToSearch.toLowerCase()}`)}></Tooltip>;
    }
    return <></>;
  };

  const parseData = (data: ILogisticsShipmentFrontend[]) => {
    return data.map((item) => ({
      shipmentNumber: item.uniqueMovementId,
      client: item.client,
      date: item.tripDate, // Assuming tripDate is already in the desired format
      distance: (
        <div className='flex gap-2 items-center'>
          <span style={{ textAlign: 'right', width: '100%', display: 'block' }}>
            {item.distanceKm !== undefined && item.distanceKm >= 0
              ? formatNumber(numberToDecimal(item.distanceKm))
              : '-'}
          </span>
          {renderEstimated(item.estimatedData, 'DISTANCE_KM')}
        </div>
      ),
      load: (
        <div className='flex gap-2 items-center'>
          <span style={{ textAlign: 'right', width: '100%', display: 'block' }}>
            {item.loadTonne !== undefined && item.loadTonne >= 0
              ? formatNumber(numberToDecimal(item.loadTonne))
              : '-'}
          </span>
          {renderEstimated(item.estimatedData, 'LOAD')}
        </div>
      ),
      tonKm: (
        <span style={{ textAlign: 'right', width: '100%', display: 'block' }}>
          {item.tkm !== undefined && item.tkm >= 0 ? formatNumber(numberToDecimal(item.tkm)) : '-'}
        </span>
      ),
      emissionIntensity: (
        <div className='flex gap-2 items-center'>
          <span style={{ textAlign: 'right', width: '100%', display: 'block' }}>
            {item.emissionIntensity !== undefined && item.emissionIntensity >= 0
              ? formatNumber(numberToDecimal(item.emissionIntensity))
              : '-'}
          </span>
          {renderEstimated(item.estimatedData, 'EMISSION_INTENSITY')}
        </div>
      ),
      co2: (
        <span style={{ textAlign: 'right', width: '100%', display: 'block' }}>
          {item.kgCO2e !== undefined && item.kgCO2e >= 0
            ? formatNumber(numberToDecimal(item.kgCO2e))
            : '-'}
        </span>
      ),
      toc: item.vehicleType ? (
        <div className='flex gap-2 items-center'>
          {t(`logistics.tocs.${item.vehicleType}`)}
          {renderEstimated(item.estimatedData, 'TOC')}
        </div>
      ) : (
        '-'
      ),
      status: statusIconSelect(item.status, item.errorMessages ?? [])
    }));
  };

  // const estimateElementMock = () => {
  //   editElement({
  //     id: '1ES583R123Z9469',
  //     uniqueMovementId: '1ES583R123',
  //     client: 'Client 1',
  //     loadKg: 7.9,
  //     vehicleLicensePlate: '9183CRK',
  //     trailerLicensePlate: '2384DXY',
  //     tripDate: '01/01/2024',
  //     tkm: 7.9 * 100,
  //     distanceKm: 100,
  //     emissionIntensity: 7.9 * 100 * 0.0052,
  //     gCO2e: ((7.9 * 100 * 0.0052 * 1000) / 7.9) * 100,
  //     vehicleType: 'bulk_carrier_non_container_vessel_60000_99999_dwt_hfo'
  //   });
  // };

  return (
    <>
      {/* <Notification handleOnClick={() => setShowEstimateAllShipments(true)} /> */}
      <InfiniteList
        className='item-table logistics-shipment-table'
        fetchData={fetchData}
        data={parseData(data)}
        columns={columns}
        loading={loading}
        firstLoading={firstLoading}
        total={total}
        header={
          <>
            <div
              ref={containerRef}
              className='table-filters pointer'
              style={{ overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap' }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onWheel={handleWheel}>
              {/* close all open modals */}
              <InputWrapperPlain
                size={InputSize.MEDIUM}
                icon='/images/icons/filter.svg'
                width='13rem'>
                <Select
                  placeholder={t('logistics.clientPlaceholder')}
                  options={clients}
                  value={filters.client}
                  onChange={onChangeValue('client')}
                  size={InputSize.MEDIUM}
                  handleGetDown={handleGetDown}
                  loading={loadingClients}
                  searchBackendCallback={handleSearchClient}
                />
              </InputWrapperPlain>
              <InputWrapperPlain
                size={InputSize.MEDIUM}
                icon='/images/icons/calendar50.svg'
                width='13rem'
                error={filters.errors.find(
                  (error) => error.error === 'startDate' || error.error === 'endDate'
                )}>
                <InputCalendarDouble
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  handleChangeStartDate={onChangeValue('startDate')}
                  handleChangeEndDate={onChangeValue('endDate')}
                  handleStartDateError={handleDateError('startDate')}
                  handleEndDateError={handleDateError('endDate')}
                />
              </InputWrapperPlain>
              <div>
                <FilterSection
                  maxWidth='100%'
                  filters={[
                    <InputWrapperPlainLabel
                      key='id'
                      size={InputSize.MEDIUM}
                      leftSection={<span className='report-card-font'>{t('logistics.id')}</span>}
                      width='13rem'>
                      <InputText
                        value={filters.id}
                        onChange={onChangeValue('id')}
                        placeholder={t('general.search')}
                      />
                    </InputWrapperPlainLabel>,
                    <InputWrapperPlainLabel
                      key='distanceKm'
                      size={InputSize.MEDIUM}
                      leftSection={
                        <span className='report-card-font'>{t('logistics.distanceKm')}</span>
                      }
                      width='13rem'>
                      <InputNumber
                        value={filters.distanceKmLower}
                        onChange={onChangeValue('distanceKmLower')}
                        placeholder={t('logistics.distanceKmLowerPlaceholder')}
                      />
                      <InputNumber
                        value={filters.distanceKmUpper}
                        onChange={onChangeValue('distanceKmUpper')}
                        placeholder={t('logistics.distanceKmUpperPlaceholder')}
                      />
                    </InputWrapperPlainLabel>,
                    <InputWrapperPlainLabel
                      key='loadKg'
                      size={InputSize.MEDIUM}
                      leftSection={
                        <span className='report-card-font'>{t('logistics.loadKg')}</span>
                      }
                      width='15.5rem'>
                      <InputNumber
                        value={filters.loadKgLower}
                        onChange={onChangeValue('loadKgLower')}
                        placeholder={t('logistics.loadKgLowerPlaceholder')}
                      />
                      <InputNumber
                        value={filters.loadKgUpper}
                        onChange={onChangeValue('loadKgUpper')}
                        placeholder={t('logistics.loadKgUpperPlaceholder')}
                      />
                    </InputWrapperPlainLabel>,
                    <InputWrapperPlainLabel
                      key='tkm'
                      size={InputSize.MEDIUM}
                      leftSection={<span className='report-card-font'>{t('logistics.tkm')}</span>}
                      width='20.5rem'>
                      <InputNumber
                        value={filters.tkmLower}
                        onChange={onChangeValue('tkmLower')}
                        placeholder={t('logistics.tkmLowerPlaceholder')}
                      />
                      <InputNumber
                        value={filters.tkmUpper}
                        onChange={onChangeValue('tkmUpper')}
                        placeholder={t('logistics.tkmUpperPlaceholder')}
                      />
                    </InputWrapperPlainLabel>,
                    <InputWrapperPlainLabel
                      key='toc'
                      size={InputSize.MEDIUM}
                      leftSection={<span className='report-card-font'>{t('logistics.toc')}</span>}
                      width='13rem'>
                      <Select
                        placeholder={''}
                        options={tocs}
                        value={filters.vehicleType}
                        onChange={onChangeValue('vehicleType')}
                        optional
                      />
                    </InputWrapperPlainLabel>
                  ]}
                  removeFilters={handleRemoveFilters}
                  counterFiltersSelected={counterFiltersSelected}
                />
              </div>
            </div>
            <div className='table-tags'>
              <TotalLegend total={total} loading={loading} i18key={'logistics'} />
            </div>
          </>
        }
      />
      {/* <Modal.WithPortal
        show={showEstimateAllShipments}
        onClose={() => setShowEstimateAllShipments(false)}
        width='584px'
        maxWidth='584px'>
        <EstimateAllShipments
          handleClose={() => {
            setShowEstimateAllShipments(false);
            estimateElementMock();
          }}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!shipmentToEstimate}
        onClose={() => setShipmentToEstimate(undefined)}
        width='584px'
        maxWidth='584px'>
        <EstimateShipment
          handleClose={() => {
            setShipmentToEstimate(undefined);
            estimateElementMock();
          }}
          shipment={shipmentToEstimate as ILogisticsShipmentFrontend}
        />
      </Modal.WithPortal> */}
    </>
  );
};

export default Shipment;
