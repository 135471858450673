import { z } from 'zod';

const firstStepSchema = z.object({
  name: z.string().min(1, 'error.name_required'),
  country: z.object({
    id: z.string().min(1, 'error.country_required'),
    name: z.string().min(1, 'error.country_required')
  })
});

const secondStepSchema = firstStepSchema.extend({
  inputs: z.array(
    z.object({
      material_id: z.string(),
      quantity: z.string().refine((value) => {
        return value !== '' && !isNaN(Number(value));
      }),
      unit: z.object({
        id: z.string().min(1, 'error.unit_required'),
        name: z.string()
      })
    })
  )
});

export const formSchema = {
  FIRST: firstStepSchema,
  SECOND: secondStepSchema
} as const;
