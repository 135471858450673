import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import { SIZE_PAGINATION_BIG } from '../../../../../../../../constants';
import { ROUTES } from '../../../../../../../../constants/routes';
import { FilterDatesContext } from '../../../../../../../../context/filterDatesContext';
import { UserContext } from '../../../../../../../../context/userContext';
import usePaginatedList from '../../../../../../../../customHooks/paginatedList';
import useDebounceValue from '../../../../../../../../customHooks/useDebounceValue';
import {
  Facility,
  WasteWaterTreatmentFacility
} from '../../../../../../../../types/entities/facility';
import { InputSize } from '../../../../../../../../types/utilsEnums/input';
import CardList from '../../../../../../../ui/cardList/CardList';
import CardsFilter from '../../../../../../../ui/filter/CardsFilter';
import FormText from '../../../../../../../ui/formComponents2/formInputs/formText/FormText';
import EmptyModal from '../../emptyModal/EmptyModal';
import './styles.scss';

type Props = {
  onChangeFacility: (id: string, name: string) => void;
  next: () => void;
};

function Facilities({ onChangeFacility, next }: Props) {
  const { t } = useTranslation();
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [listView, setListView] = useState(false);

  const size = SIZE_PAGINATION_BIG;
  const url = '/facilities';

  // Filters
  const [searchValue, setSearchValue] = useState('');

  const debounceValue = useDebounceValue(searchValue);

  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const filters = {
    inputTextList: [
      {
        name: 'name',
        value: debounceValue
      }
    ]
  };

  // fetchPagination
  const { fetchData, fetchMoreData, hasMore, values, total } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: user?.selectedOrganization,
    filters
  });
  const facilities: (Facility | WasteWaterTreatmentFacility)[] = values;

  useEffect(() => {
    if (dateError) return;
    fetchData();
  }, [user, searchValue, startDate, endDate]);

  const onClickCard = (id: string) => {
    onChangeFacility(
      id,
      facilities.find((facility) => facility.id === id)?.name ?? t('facilities')
    );
    next();
  };

  const handleChangeListView = () => {
    setListView(!listView);
  };

  const handleNavigate = () => {
    navigate(`${ROUTES.MEASURE_FACILITIES}`);
  };

  if (!foundOrganization) {
    return null;
  }

  return (
    <div className='avoided-emission'>
      <CardsFilter listView={listView} onChangeListView={handleChangeListView}>
        <>
          <FormText
            icon={'/images/icons/search.svg'}
            placeholder={t('facilities.searchPlaceholder')}
            onChange={(e: any) => setSearchValue(e.target.value)}
            value={searchValue}
            size={InputSize.SMALL}
          />
        </>
      </CardsFilter>
      <div id={'facility'} className='infinite-scroll-wrapper-card list-in-avoided-emissions'>
        {facilities.length !== 0 && (
          <InfiniteScroll
            dataLength={facilities ? facilities.length : 0}
            next={fetchMoreData}
            hasMore={hasMore}
            style={{ overflow: 'visible' }}
            endMessage={<></>}
            loader={undefined}
            scrollableTarget={'facility'}>
            <CardList
              listView={listView}
              title={t('facilities.newFacility')}
              description={t('facilities.newFacilityDescription')}
              data={facilities.map((facility) => ({
                id: facility.id,
                title: facility.name,
                description: facility.country,
                co2e: facility.co2e,
                icon:
                  facility.facility_purpose_type !== 'waste_water_facilities'
                    ? '/images/icons/building.svg'
                    : '/images/icons/road.svg'
              }))}
              blocked={facilities && total >= foundOrganization?.limit_facilities}
              loading={loading}
              onClickCard={onClickCard}
              dropDownOptions={[]}
              buttonText={t('facilities.create')}
            />
          </InfiniteScroll>
        )}
        {facilities.length === 0 && !loading && (
          <EmptyModal>
            {t('avoidedEmissions.noFacilities.first')}
            <span onClick={handleNavigate} className='highlight-text-color clickable'>
              {t('avoidedEmissions.noFacilities.second')}
            </span>
            {t('avoidedEmissions.noFacilities.third')}
          </EmptyModal>
        )}
      </div>
    </div>
  );
}

export default Facilities;
