import React, { Dispatch, SetStateAction } from 'react';
import { FormSocialData } from '../../../../../../../types/entities/socialData';
import FormYesNoRadio from '../../../../../../ui/formComponents2/formInputs/formYesNoRadio/FormYesNoRadio';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import { useTranslation } from 'react-i18next';
import FormNumber from '../../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import useSelectedOrganization from '../../../../../../../customHooks/useSelectedOrganization';
type Props = {
  formData: FormSocialData;
  setFormData: Dispatch<SetStateAction<FormSocialData>>;
};
const FormSocialLaboral = ({ formData, setFormData }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'socialAndGovernance' });
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const selectedOrganization = useSelectedOrganization();

  const disabled = selectedOrganization?.role === 'fund';

  return (
    <>
      <FormYesNoRadio
        label={t('hasGlobalCompact')}
        tooltip={t('hasGlobalCompactTooltip')}
        value={formData.hasGlobalCompact}
        onChange={onChangeValue('hasGlobalCompact')}
        error={formData.errors.find((error) => error.error === 'hasGlobalCompact')}
        disabled={disabled}
      />
      <FormYesNoRadio
        label={t('isGlobalCompactViolated')}
        tooltip={t('isGlobalCompactViolatedTooltip')}
        value={formData.isGlobalCompactViolated}
        onChange={onChangeValue('isGlobalCompactViolated')}
        error={formData.errors.find((error) => error.error === 'isGlobalCompactViolated')}
        disabled={disabled}
      />
      <FormYesNoRadio
        label={t('isRelatedGuns')}
        value={formData.isRelatedGuns}
        onChange={onChangeValue('isRelatedGuns')}
        error={formData.errors.find((error) => error.error === 'isRelatedGuns')}
        disabled={disabled}
      />
      <FormNumber
        icon='/images/icons/euro.svg'
        label={t('anualRevenue')}
        placeholder={t('enterQuantity')}
        value={formData.anualRevenue}
        onChange={onChangeValue('anualRevenue')}
        error={formData.errors.find((elem) => elem.error === 'anualRevenue')}
        maxLength={10}
        disabled={disabled}
      />
      <FormNumber
        icon='/images/icons/database.svg'
        label={t('womanRevenue')}
        tooltip={t('womanRevenueTooltip')}
        placeholder={t('enterQuantity')}
        value={formData.womanRevenue}
        onChange={onChangeValue('womanRevenue')}
        error={formData.errors.find((elem) => elem.error === 'womanRevenue')}
        maxLength={10}
        disabled={disabled}
      />
      <FormNumber
        icon='/images/icons/database.svg'
        label={t('manRevenue')}
        tooltip={t('manRevenueTooltip')}
        placeholder={t('enterQuantity')}
        value={formData.manRevenue}
        onChange={onChangeValue('manRevenue')}
        error={formData.errors.find((elem) => elem.error === 'manRevenue')}
        maxLength={10}
        disabled={disabled}
      />
      <FormNumber
        icon={'/images/icons/inputNumeric.svg'}
        label={t('numberWomanBoardDirectors')}
        tooltip={t('numberWomanBoardDirectorsTooltip')}
        onChange={onChangeValue('numberWomanBoardDirectors')}
        placeholder={t('enterQuantity')}
        value={formData.numberWomanBoardDirectors}
        onlyIntegers
        error={formData.errors.find((elem) => elem.error === 'numberWomanBoardDirectors')}
        maxLength={10}
        disabled={disabled}
      />
      <FormNumber
        icon={'/images/icons/inputNumeric.svg'}
        label={t('numberMenBoardDirectors')}
        tooltip={t('numberMenBoardDirectorsTooltip')}
        onChange={onChangeValue('numberMenBoardDirectors')}
        placeholder={t('enterQuantity')}
        value={formData.numberMenBoardDirectors}
        onlyIntegers
        error={formData.errors.find((elem) => elem.error === 'numberMenBoardDirectors')}
        maxLength={10}
        disabled={disabled}
      />
    </>
  );
};

export default FormSocialLaboral;
