import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import Button from '../../../../ui/button/Button';
import ErrorText from '../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../../ui/formComponents/inputFile/InputFileSingle';
import { StarLabel } from '../../../../ui/statusLabels/starLabel/StarLabel';

type Props = {
  onClose: () => void;
};

type IFormData = {
  file?: File;
  errors: ErrorType[];
};

const UploadModal = ({ onClose }: Props) => {
  const { t } = useTranslation('', { keyPrefix: 'customizeMainPage.customMetrics.uploadModal' });
  const { t: tn } = useTranslation('');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const [formData, setFormData] = useState<IFormData>({
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    // handle errors
    if (handleErrors().length > 0 || !formData.file?.name) return;

    setLoading(true);

    // Finish function
    setLoading(false);

    dispatch(setNotification(tn('notification.successUploadFile')));
    onClose();
  };

  const handleErrors = () => {
    const newErrors = checkFormErrors(formData, []);
    setFormData((prev) => ({
      ...prev,
      errors: newErrors
    }));
    return newErrors;
  };

  return (
    <>
      <FormHeader
        title={
          <div className='flex'>
            {t('title')}
            <StarLabel text='Dcycle IA' tooltip={tn('general.dcycleIATooltip')} />
          </div>
        }
      />
      <FormWrapper>
        <FormElementFull>
          <p className='subtitle3-font '>{t('description')}</p>
          <InputFileSingle
            handleFile={onChangeValue('file')}
            buttonText={t('button')}
            fileName={formData.file?.name ?? ''}
            allowedTypes={[]}
            error={formData.errors.find((error) => error.error === 'file')}
            labelText={''}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('save')}
          size='medium'
          onClick={handleSubmit}
          loading={loading}
        />
      </FormButtonSection>
      <ErrorText>{error?.description}</ErrorText>
    </>
  );
};

export default UploadModal;
