import { HTMLAttributes, MouseEvent, ReactNode, cloneElement, forwardRef, useContext } from 'react';
import { FreeTrialContext } from '../../../context/freeTrialContext';
import Icon from '../icon/Icon';
import './styles.scss';

export type ButtonLookAndFeel =
  | 'primary'
  | 'secondary'
  | 'cancel'
  | 'completed'
  | 'pending'
  | 'tertiary'
  | 'disabled'
  | 'blocked'
  | 'link'
  | 'link-dark'
  | 'warning';

type ButtonSize = 'tiny' | 'small' | 'medium';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  text?: ReactNode;
  lookAndFeel: ButtonLookAndFeel;
  icon?: string;
  iconNode?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onClickBlocked?: () => void;
  size?: ButtonSize;
  height?: ButtonSize;
  disabled?: boolean;
  type?: 'submit' | 'button';
  loading?: boolean;
  children?: ReactNode;
  hasIcon?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      lookAndFeel,
      onClick,
      icon,
      iconNode,
      size,
      height,
      disabled = false,
      type = 'button',
      loading,
      children,
      hasIcon = true,
      onClickBlocked,
      ...rest
    },
    ref
  ) => {
    const freeTrialContext = useContext(FreeTrialContext);

    let buttonStyle = `${lookAndFeel}-button`;
    if (disabled) {
      buttonStyle = 'disabled-button';
    }

    const heightButton = height ? `height-${height}` : '';

    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (!disabled && !loading && lookAndFeel !== 'blocked') {
        onClick?.(e);
      }

      if (lookAndFeel === 'blocked') {
        if (onClickBlocked) {
          onClickBlocked();
        } else {
          freeTrialContext.setShowPremiumFeatureModal(true);
        }
      }
    };

    const className = [buttonStyle, heightButton];

    if (size) {
      className.push(size);
    }

    if (!iconNode && !icon) {
      className.push('no-icon');
    }

    if (rest.className) {
      className.push(rest.className);
    }

    const IconElement =
      disabled && iconNode
        ? cloneElement(iconNode as React.ReactElement, { color: 'disabled' })
        : iconNode;

    return (
      <button
        {...rest}
        ref={ref}
        className={className.join(' ')}
        onClick={handleOnClick}
        disabled={disabled || loading}
        type={type}>
        {loading && <i className='fa fa-spinner fa-spin'></i>}
        {icon && lookAndFeel !== 'blocked' && <img src={icon} alt={'button'} />}
        {lookAndFeel !== 'blocked' && IconElement}
        {lookAndFeel === 'blocked' && hasIcon && (
          <Icon icon='locked' color={disabled ? 'disabled' : 'pending'} />
        )}
        {text && text}
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
