import { convertDateToStringBackend } from '../../../../../../utils/convertDates';
import Objective from './objective/Objective';
import Progress from './progress/Progress';
import TableComponent from './tableComponent/TableComponent';

const ModelingNotAvailable = () => {
  return (
    <section className='modeling-sbti'>
      <div className='modeling-container'>
        <Objective loading={false} organizationGoal={null} />
        <Progress
          loading={false}
          totalImpactByCategoryYearly={null}
          organizationGoal={null}
          organizationModeling={null}
        />
        <TableComponent
          loading={false}
          baseYear={undefined}
          currentYear={convertDateToStringBackend(new Date())}
          goalYearScope1and2={undefined}
          goalYearScope3={undefined}
          totalImpactByCategoryYearly={null}
          organizationModeling={null}
          handleOnChangeReductionPercentage={() => () => null}
          handleOnChangeReductionAction={() => () => null}
          organizationGoal={null}
        />
      </div>
    </section>
  );
};

export default ModelingNotAvailable;
