import { Doughnut } from 'react-chartjs-2';
import {
  centerTextDoughnut,
  getDoughnutSectionIndex,
  onHover,
  overlappingSections
} from './plugins';
import { getDataset, getTotal } from './utils';
import { FrontLCAComponent } from '../../types/LCADetail';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { useColorPalette } from '../ColorPaletteContext/ColorPaletteContext';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import { useLCAMethodology } from '../LCAMethodologyContext/LCAMethodologyContext';
import { useTranslation } from 'react-i18next';
import { formatLCAUnit } from '../../utils/formatLCAUnit';
import { numberToScientificNotation } from '../../../../../../utils/numberToDecimal';

ChartJS.register(ArcElement);

type Props = {
  data?: FrontLCAComponent[] | readonly FrontLCAComponent[];
  onSelectSection: (index: number, component: FrontLCAComponent) => void;
  isLoading?: boolean;
};

export const LCADoughnutChart: React.FC<Props> = ({ data, onSelectSection, isLoading }) => {
  const { colorPalette } = useColorPalette();

  const { methodology } = useLCAMethodology();

  const { t } = useLCATranslation();

  const { t: translateUnit } = useTranslation('translation', { keyPrefix: 'units_short' });

  const chartData = getDataset(data, colorPalette);

  const total = getTotal(chartData.datasets[0].data);

  const disabledElements = data
    ?.map((item, index) => {
      if (item.is_last) {
        return index;
      }

      return undefined;
    })
    .filter((value): value is number => value !== undefined);

  const isEnabled = Boolean(methodology && data) && !isLoading;

  return (
    <Doughnut
      plugins={[centerTextDoughnut, overlappingSections]}
      options={{
        devicePixelRatio: 2,
        cutout: '85%',
        responsive: true,
        maintainAspectRatio: false,
        onClick(_event, elements) {
          if (!isEnabled || !data) {
            return;
          }

          const index = getDoughnutSectionIndex(elements);

          if (index !== undefined && !disabledElements?.includes(index)) {
            onSelectSection(index, data[index]);
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: isEnabled
          },
          centerTextDoughnut: {
            disabled: !isEnabled,
            text: isEnabled ? numberToScientificNotation(total) : '-',
            subText: methodology?.unit.name
              ? formatLCAUnit(translateUnit(methodology?.unit.name), methodology.category)
              : t('lcaDetail.selectImpactCategory')
          }
        },
        onHover: (event, elements, chart) => {
          if (!isEnabled) {
            return;
          }

          const filteredElements = elements.filter((element) => {
            return !disabledElements?.includes(element.index);
          });

          onHover(event, filteredElements, chart);
        }
      }}
      data={chartData}
    />
  );
};
