import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { questions } from '../../../../../constants/employeeCommutingQuestions';
import { UserContext } from '../../../../../context/userContext';
import useErrors from '../../../../../customHooks/useErrors';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import useYesNoOptions from '../../../../../customHooks/useYesNoOptions';
import { postEmployeePeriod } from '../../../../../services/api/employeePeriod';
import {
  EmployeePeriod,
  FormEmployeePeriodData
} from '../../../../../types/entities/employeePeriod';
import { CarSize, TransportOptions } from '../../../../../types/entitiesEnums/employeePeriod';

import moment from 'moment';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import { formatDate } from '../../../../../utils/formatDate';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import StatusTagEmployee from '../infoTag/StatusTagEmployee';

type Props = {
  employeeId: string;
  setPeriods: (employeeId: string, periods: EmployeePeriod[]) => void;
};

function AddPeriod({ employeeId, setPeriods }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const currentYear = moment().format('YYYY');

  const ERRORS = useErrors();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  const [fuelTypeOptions, setFuelTypeOptions] = useState<SelectOptionFormat[]>();
  const [sizeOptions, setSizeOptions] = useState<SelectOptionFormat[]>();

  const [formData, setFormData] = useState<FormEmployeePeriodData>({
    transport: { id: '', name: '' },
    size: CarSize.NONE,
    fuelType: { id: '', name: '' },
    totalKm: '',
    weeklyTravels: [],
    numberWeeklyTravels: '',
    startDate: formatDate(oneYearAgo),
    endDate: '',
    situation: { id: 'active', name: t('employees.active') },
    telecommuting: { id: '', name: '' },
    carpooling: { id: '', name: '' },
    errors: []
  });

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const [loadingButton, setLoadingButton] = useState(false);
  const yesNoOptions = useYesNoOptions();

  const onChangeTransport = (value: SelectOptionFormat) => {
    let size = CarSize.NONE;
    if (value.id === TransportOptions.CAR_SMALL) size = CarSize.SMALL;
    if (value.id === TransportOptions.CAR_MEDIUM) size = CarSize.MEDIUM;
    if (value.id === TransportOptions.CAR_LARGE) size = CarSize.LARGE;
    if (value.id.includes(TransportOptions.CAR)) value.id = TransportOptions.CAR;

    onChangeValue('transport')(value);
    const foundQuestion = questions.children.find(
      (question: { answer: string }) => question.answer === value.id
    );
    if (!foundQuestion) return;

    setFormData((prev) => ({
      ...prev,
      size,
      fuelType: { id: '', name: '' },
      carpooling: { id: '', name: '' }
    }));

    const fuels = foundQuestion?.children?.map((fuel: { answer: any }) => ({
      id: fuel.answer,
      name: t(`employees.${fuel.answer}`)
    }));
    setFuelTypeOptions(fuels);
  };

  const onChangeTelecommuting = (value: SelectOptionFormat) => {
    const newFormData = { ...formData };
    newFormData.telecommuting = value;
    if (value.id === 'yes') {
      newFormData.fuelType = { id: '', name: '' };
      newFormData.size = CarSize.NONE;
      newFormData.totalKm = '';
      newFormData.weeklyTravels = [];
      newFormData.carpooling = { id: '', name: '' };
      newFormData.transport = { id: 'telecommuting', name: '' };
      setSizeOptions([]);
      setFuelTypeOptions([]);
    }
    setFormData(newFormData);
  };

  const handleErrors = () => {
    const newErrors: ErrorType[] = formData.errors;

    if (formData.telecommuting.id === 'yes' || formData.situation.id !== 'active') {
      const optionalFields = [
        'totalKm',
        'transport',
        'size',
        'fuelType',
        'weeklyTravels',
        'carpooling',
        'numberWeeklyTravels'
      ];
      if (formData.situation.id !== 'active') optionalFields.push('telecommuting');

      newErrors.push(...checkFormErrors(formData, [], optionalFields));

      if (newErrors.length > 0) {
        setFormData((prev) => ({
          ...prev,
          errors: newErrors
        }));
      }
      return newErrors;
    }

    const optionalFields: string[] = [];

    if (
      formData.weeklyTravels?.length === 0 &&
      !formData.errors.some((elem) => elem.error === 'weeklyTravels')
    ) {
      newErrors.push({ error: 'weeklyTravels' });
    }

    if (!sizeOptions || sizeOptions.length === 0) {
      optionalFields.push('size');
      optionalFields.push('carpooling');
    }

    if (!fuelTypeOptions || fuelTypeOptions.length === 0) {
      optionalFields.push('fuelType');
    }

    newErrors.push(...checkFormErrors(formData, [], optionalFields));

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
    }

    return newErrors;
  };

  const handleAddPeriod = async () => {
    const newErrors: ErrorType[] = handleErrors();
    if (!user?.selectedOrganization || newErrors.length !== 0) return;

    setLoadingButton(true);

    // Parsing data
    let renewableEnergy = '';
    const fuel = { ...formData.fuelType };
    if (formData.fuelType.id === 'electric_renewable') {
      renewableEnergy = 'yes';
      fuel.id = 'electric';
    } else if (formData.fuelType.id === 'electric') {
      renewableEnergy = 'no';
    }

    const response = await postEmployeePeriod(
      employeeId,
      formData,
      'in_itinere',
      renewableEnergy,
      fuel,
      user.selectedOrganization
    );

    if (response?.response?.status === 422 && response.response.data === 'Invalid addresses.') {
      newErrors.push({ error: 'invalidAddress', description: t('error.invalidAddress') });
    }

    if (response?.response?.data === 'IN_LABORE_TO_CREATE_NOT_OVERLAPPING_WITH_IN_ITINERE') {
      newErrors.push({
        error: 'IN_LABORE_NOT_IN_PERIOD',
        description: t('error.inLaboreNotInPeriod')
      });
    }

    if (response?.response?.data === 'EXISTING_ACTIVE_PERIOD_FOR_ITINERE') {
      newErrors.push({
        error: 'EXISTING_ACTIVE_PERIOD_FOR_ITINERE',
        description: t('error.existingActivePeriodForItinere')
      });
    }

    if (response?.response?.status >= 400 && newErrors.length === 0) {
      newErrors.push(ERRORS.FORM.SOMETHING_WENT_WRONG);
    }

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      setLoadingButton(false);
      return;
    }

    setPeriods(employeeId, response?.periods);
    setLoadingButton(false);
    dispatch(setNotification(t('notification.createPeriod')));
  };

  const onChangeWeeklyTravels = (value: string) => {
    if (Number(value) > 7 || Number(value) < 0) return;

    onChangeValue('weeklyTravels')(Array.from({ length: Number(value) }, (_, index) => index));
  };

  return (
    <div className='new-employee'>
      <FormHeader
        title={t('employees.addNewEmployee')}
        description={t('employees.addNewEmployeeDescription')}
      />
      <FormWrapper>
        <FormSelect
          icon={'/images/icons/turnOff.svg'}
          label={t('employees.situation')}
          error={formData.errors.find((elem) => elem.error === 'situation')}
          placeholder={t('employees.selectSituation')}
          options={[
            { id: 'active', name: t('employees.active') },
            { id: 'inactive', name: t('employees.inactive') },
            { id: 'terminated', name: t('employees.terminated') }
          ]}
          value={formData.situation}
          onChange={onChangeValue('situation')}
        />
        <FormCalendarDouble
          tooltip={t('employees.dateRangeTooltip')}
          label={t('employees.registerDate')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          handleStartDateError={handleDateError('startDate')}
          handleEndDateError={handleDateError('endDate')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          maxEndDate={`31/12/${currentYear}`}
          minStartDate={'01/01/1960'}
          minEndDate={'01/01/1960'}
          error={formData.errors.find(
            (elem) => elem.error === 'startDate' || elem.error === 'endDate'
          )}
        />

        {formData.situation.id === 'active' && (
          <FormSelect
            iconV2='user'
            label={t('employeeForm.telecommuting')}
            error={formData.errors.find((elem) => elem.error === 'telecommuting')}
            placeholder={t('employeeForm.telecommuting')}
            options={[
              { id: 'yes', name: t('employeeForm.workFromHome') },
              { id: 'no', name: t('employeeForm.goToOffice') }
            ]}
            value={formData.telecommuting}
            onChange={onChangeTelecommuting}
          />
        )}

        {formData.telecommuting.id === 'no' && formData.situation.id === 'active' && (
          <>
            <FormNumber
              iconV2='calendar'
              label={t(`employees.weeklyTravels`)}
              placeholder={t('employees.weeklyTravelsPlaceholder')}
              onChange={onChangeWeeklyTravels}
              value={formData.weeklyTravels?.length === 0 ? '' : formData.weeklyTravels?.length}
              error={formData.errors.find((elem) => elem.error === 'weeklyTravels')}
            />

            <FormNumber
              iconV2='calendar'
              tooltip={t(`employeeForm.numberWeeklyTravelsTooltip`)}
              label={t(`employees.numberWeeklyTravels`)}
              placeholder={t('employees.writeNumberWeeklyTravels')}
              onChange={onChangeValue('numberWeeklyTravels')}
              value={formData.numberWeeklyTravels}
              error={formData.errors.find((elem) => elem.error === 'numberWeeklyTravels')}
            />

            <FormNumber
              iconV2='location'
              label={t('employees.totalKm')}
              placeholder={t('employees.writeTotalKm')}
              onChange={onChangeValue('totalKm')}
              value={formData.totalKm}
              error={formData.errors.find((elem) => elem.error === 'totalKm')}
              decimals={3}
            />

            <FormSelect
              iconV2='car'
              label={t('employees.transport')}
              error={formData.errors.find((elem) => elem.error === 'transport')}
              placeholder={t('employees.writeTransport')}
              options={[
                { id: TransportOptions.CAR_SMALL, name: t('employees.carSmall') },
                { id: TransportOptions.CAR_MEDIUM, name: t('employees.carMedium') },
                { id: TransportOptions.CAR_LARGE, name: t('employees.carLarge') },
                { id: TransportOptions.METRO, name: t('employees.metro') },
                { id: TransportOptions.TRAIN, name: t('employees.train') },
                { id: TransportOptions.TROLLEYBUS, name: t('employees.trolleybus') },
                { id: TransportOptions.BUS, name: t('employees.bus') },
                { id: TransportOptions.BICYCLE, name: t('employees.bicycle') },
                { id: TransportOptions.MOTORBIKE, name: t('employees.motorbike') },
                { id: TransportOptions.WALKING, name: t('employees.walking') }
              ]}
              value={formData.transport}
              onChange={onChangeTransport}
            />
            {formData.size !== CarSize.NONE && (
              <>
                <FormSelect
                  label={t('employeeForm.carpooling')}
                  iconV2='car'
                  error={formData.errors.find((elem) => elem.error === 'carpooling')}
                  placeholder={t('yesNoOptions.enterYesNo')}
                  options={yesNoOptions}
                  value={formData.carpooling}
                  onChange={onChangeValue('carpooling')}
                />
              </>
            )}
            {fuelTypeOptions && fuelTypeOptions.length > 0 && (
              <FormSelect
                iconV2='electricity2'
                label={t('employees.fuelType')}
                error={formData.errors.find((elem) => elem.error === 'fuelType')}
                placeholder={t('employees.selectFuelType')}
                options={fuelTypeOptions}
                value={formData.fuelType}
                onChange={onChangeValue('fuelType')}
              />
            )}
          </>
        )}
      </FormWrapper>

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('employees.save')}
          onClick={handleAddPeriod}
          loading={loadingButton}
          style={{ marginBottom: '1em' }}
        />
      </FormButtonSection>
      <StatusTagEmployee status='info' text={t('employees.periodsTooltips')} />
      <span className='error-text error-font error-text-color'>
        {
          formData.errors.find(
            (elem) =>
              elem.error === 'email_exists' ||
              elem.error === 'IN_LABORE_NOT_IN_PERIOD' ||
              elem.error === 'EXISTING_ACTIVE_PERIOD_FOR_ITINERE' ||
              elem.error === ERRORS.FORM.SOMETHING_WENT_WRONG.error
          )?.description
        }
      </span>
    </div>
  );
}

export default AddPeriod;
