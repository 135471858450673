import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import checkFormErrors from '../../../../../../../utils/checkFormErrors';
import useCriteriaContext from '../../hooks/useCriteriaContext';
import useCreateCriteria from './useCreateCriteria';
import useGetCriteria from './useGetCriteria';

const useModal = (mandatory?: string[]) => {
  const { t } = useTranslation();
  const { criteriaContext } = useCriteriaContext();
  const { criteria: criteriaResponse, loading, optionalsFields } = useGetCriteria();

  const emptyFormData: ISbtiCriteriaFront = {
    id: criteriaContext.criteria.id,
    criteria: criteriaContext.criteria.criteria,
    yesNo: {
      id: '',
      name: ''
    },
    goalYear: {
      id: '',
      name: ''
    },
    submitYear: {
      id: '',
      name: ''
    },
    baseYear: {
      id: '',
      name: ''
    },
    ambitionLevel: {
      id: '',
      name: ''
    },
    method: {
      id: '',
      name: ''
    },
    percentage: '',
    marketAndLocation: {
      id: '',
      name: ''
    },
    errors: []
  };

  const [formData, setFormData] = useState<ISbtiCriteriaFront>(emptyFormData);

  const getOptionalsFromMandatory = () => {
    return optionalsFields.filter((element) => !mandatory?.includes(element));
  };

  const handleErrors = () => {
    const optionals = getOptionalsFromMandatory();

    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionals);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  const handleSubmit = (formData: ISbtiCriteriaFront) => {
    if (handleErrors()) return;
    patchCriteria(formData);
  };

  useEffect(() => {
    if (criteriaResponse) setFormData(criteriaResponse);
  }, [criteriaResponse]);

  const { patchCriteria, loading: loadingButton, error } = useCreateCriteria();

  return {
    formData,
    setFormData,
    loading,
    patchCriteria: handleSubmit,
    loadingButton,
    errorPatch: error
  };
};

export default useModal;
