import InputCalendar from '../../inputCalendar/InputCalendar';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children' | 'icon'>;
type InputCalendarType = React.ComponentProps<typeof InputCalendar>;

type Props = InputCalendarType & InputWrapperType;

const FormCalendar = (props: Props) => {
  return (
    <InputWrapper icon='/images/icons/calendar50.svg' {...props}>
      <InputCalendar {...props} />
    </InputWrapper>
  );
};

export default FormCalendar;
