import { getSecondaryCodes } from '../../../../../../services/api/secondaryCode';
import { IFilter } from '../../../../../../utils/url';
import useFetchInfiniteList from '../../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import { secondaryCodeAdapter } from '../adapter';

export const useSecondaryCodes = (filters: IFilter[]) => {
  const { data, loading, total, fetchData, firstLoading, editElement } = useFetchInfiniteList(
    getSecondaryCodes(filters),
    [JSON.stringify(filters)]
  );

  return { data: secondaryCodeAdapter(data), loading, total, fetchData, firstLoading, editElement };
};
