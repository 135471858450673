import { Organization } from '../types/entities/organization';

export const checkFreePlan = (organization?: Organization) => {
  if (!organization) return true;
  return (
    organization.subscription_plan === 'free_plan' ||
    new Date(organization.subscription_plan_expire_date) < new Date()
  );
};

export const checkExpiredPlan = (organization?: Organization) => {
  if (!organization) return true;
  return new Date(organization.subscription_plan_expire_date) < new Date();
};
