import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import Button from '../../ui/button/Button';
import './styles.scss';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';

function SubscriptionReminder() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const foundOrganization = useSelectedOrganization();
  const navigateToBudgetCalculator = () => {
    navigate(ROUTES.BUDGET_CALCULATOR);
  };

  if (!foundOrganization || foundOrganization.subscription_plan !== 'free_plan') {
    return null;
  }
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const expireDate = new Date(foundOrganization.subscription_plan_expire_date);
  const daysLeft = Math.floor((expireDate.getTime() - today.getTime()) / (1000 * 3600 * 24));

  const location = useLocation();
  const { pathname } = location;

  if (pathname === ROUTES.BUDGET_CALCULATOR) {
    return null;
  }

  return (
    <div className='subscription-reminder main-bg-color'>
      <div className='subscription-reminder-content'>
        <h5 className='on-light-text-color subtitle3-font'>
          {t('subscriptionReminder.remainingTime').replace(
            '{{days}}',
            daysLeft < 0 ? '0' : daysLeft.toString()
          )}
        </h5>
        <Button
          lookAndFeel='primary'
          text={t('subscriptionReminder.talkToExpert')}
          onClick={navigateToBudgetCalculator}
          size='tiny'></Button>
      </div>
    </div>
  );
}

export default SubscriptionReminder;
