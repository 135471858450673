import {
  ColumnAPI,
  DropdownOptionAPI,
  HookedRecordValue,
  LanguageType,
  OnEntryChange,
  OnEntryInit,
  RejectSubmitResult,
  Row
} from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { countriesNuvo } from '../../../../../../../constants/countriesNuvo';
import useErrors from '../../../../../../../customHooks/useErrors';
import useNuvoButton from '../../../../../../../customHooks/useNuvoButton';
import useNuvoFileName from '../../../../../../../customHooks/useNuvoFileName';
import useSelectedOrganization from '../../../../../../../customHooks/useSelectedOrganization';
import { uploadFilePresignedUrl } from '../../../../../../../services/api/aws';
import { getPresignedUrlLogistics } from '../../../../../../../services/api/logistics';
import { postNuvoAnalytics } from '../../../../../../../services/api/nuvoAnalytics';
import { basicNuvoStyle } from '../../../../../../../styles/nuvo';
import { ITOCFrontend } from '../../../../../../../types/entities/logistics';
import { transformNuvoResultsIntoCsv } from '../../../../../../../utils/nuvo';
import NuvoImporter from '../../../../../../ui/nuvoImporter/NuvoImporter';
import moment from 'moment';

type Props = {
  lookAndFeel?: 'primary' | 'secondary';
  tocs: ITOCFrontend[];
};

const InputNuvoRequests = ({ lookAndFeel = 'primary', tocs }: Props) => {
  const { t, i18n } = useTranslation();
  const selectedOrganization = useSelectedOrganization();
  const ERRORS = useErrors();

  const category = 'logistics_requests';

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName({ lookAndFeel });
  useNuvoButton({ onClick: handleGetFileName, lookAndFeel });

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const tocsDropdownOptions: DropdownOptionAPI[] = tocs?.map((toc: ITOCFrontend) => ({
    label: toc.name_translated,
    value: toc.name,
    type: 'string'
  }));

  const tocCategoriesDropdownOptions: DropdownOptionAPI[] = ['road', 'maritime', 'air', 'rail'].map(
    (elem) => ({
      label: t(`templates.logistics_requests.toc_categories.${elem}`),
      value: elem,
      type: 'string'
    })
  );

  const columns: ColumnAPI[] = [
    {
      key: 'unique_movement_id',
      label: t('templates.logistics_requests.unique_movement_id'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'movement_stretch',
      label: t('templates.logistics_requests.movement_stretch'),
      columnType: 'string'
    },
    {
      key: 'movement_stage',
      label: t('templates.logistics_requests.movement_stage'),
      columnType: 'string'
    },
    {
      key: 'vehicle_license_plate',
      label: t('templates.logistics_requests.vehicle_license_plate'),
      columnType: 'string'
    },
    {
      key: 'trip_date',
      label: t('templates.logistics_requests.trip_date'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD'
    },
    {
      key: 'origin_place',
      label: t('templates.business_travels.origin_place'),
      columnType: 'string',
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km']
        }
      ]
    },
    {
      key: 'origin_zip_code',
      label: t('templates.business_travels.origin_postal_code'),
      columnType: 'string'
    },
    {
      key: 'origin_country',
      label: t('templates.business_travels.origin_country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km']
        }
      ]
    },
    {
      key: 'origin_hub_name',
      label: t('templates.logistics_requests.origin_hub_name'),
      columnType: 'string'
    },
    {
      key: 'destination_place',
      label: t('templates.business_travels.destination_place'),
      columnType: 'string',
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km']
        }
      ]
    },
    {
      key: 'destination_zip_code',
      label: t('templates.business_travels.destination_postal_code'),
      columnType: 'string'
    },
    {
      key: 'destination_country',
      label: t('templates.business_travels.destination_country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo
    },
    {
      key: 'distance_km',
      label: t('templates.business_travels.distance_km'),
      columnType: 'float',
      validations: [
        {
          validate: 'required_without_all',
          columns: [
            'origin_place',
            'origin_zip_code',
            'origin_country',
            'destination_place',
            'destination_zip_code',
            'destination_country'
          ]
        }
      ]
    },
    {
      key: 'client',
      label: t('templates.logistics_requests.client'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'load', // Not necessarily in KG
      label: t('templates.logistics_requests.load'),
      columnType: 'float'
    },
    {
      key: 'load_unit',
      label: t('templates.logistics_requests.load_unit'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: 'KG',
          value: 'kg',
          type: 'string'
        },
        {
          label: 'TONS',
          value: 't',
          type: 'string'
        },
        {
          label: 'FEU',
          value: 'feu',
          type: 'string'
        },
        {
          label: 'TEU',
          value: 'teu',
          type: 'string'
        },
        {
          label: 'PALLET',
          value: 'pallet',
          type: 'string'
        }
      ]
    },
    {
      key: 'load_factor',
      label: t('templates.logistics_requests.load_factor'),
      columnType: 'float'
    },
    {
      key: 'cleaning',
      label: t('templates.logistics_requests.cleaning'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.yes'),
          value: 'yes',
          type: 'string'
        },
        {
          label: t('templates.no'),
          value: 'no',
          type: 'string'
        }
      ]
    },
    {
      key: 'subcontractor',
      label: t('templates.logistics_requests.subcontractor'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.yes'),
          value: 'yes',
          type: 'string'
        },
        {
          label: t('templates.no'),
          value: 'no',
          type: 'string'
        }
      ]
    },
    {
      key: 'toc',
      label: t('templates.logistics_recharges.toc'),
      columnType: 'category',
      dropdownOptions: tocsDropdownOptions,
      validations: [
        {
          validate: 'required_without',
          columns: ['toc_category']
        }
      ],
      isMultiSelect: false
    },
    {
      key: 'toc_category',
      label: t('templates.logistics_requests.toc_category'),
      columnType: 'category',
      dropdownOptions: tocCategoriesDropdownOptions,
      validations: [
        {
          validate: 'required_without',
          columns: ['toc']
        }
      ],
      isMultiSelect: false
    },
    {
      key: 'trailer_license_plate',
      label: t('templates.logistics_requests.trailer_license_plate'),
      columnType: 'string'
    }
  ];

  const validateRow: OnEntryInit = (row: Row) => {
    const errors = {} as any;

    // check that load_factor is between 0 and 1
    const load_factor = row.load_factor as number;
    if (load_factor && (load_factor < 0 || load_factor > 1)) {
      errors.load_factor = {
        value: load_factor,
        info: [
          {
            level: 'error',
            message: t('nuvo.errors.between0And1')
          }
        ]
      };
    }

    // check that trip_date is not in the future
    const trip_date = row.trip_date as string;
    const momentTripDate = trip_date ? moment(trip_date, 'YYYY-MM-DD') : null;
    if (momentTripDate && momentTripDate.isAfter(moment())) {
      errors.trip_date = {
        value: trip_date,
        info: [
          {
            level: 'error',
            message: t('error.nuvo.futureDate')
          }
        ]
      };
    }

    // check that load is not negative, it can be null because it is optional
    const load = row.load as number;
    if (load && load < 0) {
      errors.load = {
        value: load,
        info: [
          {
            level: 'error',
            message: t('error.nuvo.valueMustBePositive')
          }
        ]
      };
    }

    // check that distance_km is not negative if present
    const distance_km = row.distance_km as number;
    if (distance_km && distance_km < 0) {
      errors.distance_km = {
        value: distance_km,
        info: [
          {
            level: 'error',
            message: t('error.nuvo.valueMustBePositive')
          }
        ]
      };
    }

    return errors;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => Object.keys(validateRow(row.data, row.rowIndex) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validateRow(row.data, row.rowIndex)
          }
        };
      });
  };

  return (
    <NuvoImporter
      lookAndFeel={lookAndFeel}
      btnI18nKey='logistics.trips'
      settings={{
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        identifier: 'logistics_trips_template_Dcycle',
        columns
      }}
      onCancel={handleExit}
      onEntryInit={validateRow}
      onEntryChange={onEntryChange}
      columnHooks={{
        cleaning: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 'no'
            },
            index
          ]),
        subcontractor: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 'yes'
            },
            index
          ]),
        load_unit: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 'kg'
            },
            index
          ]),
        load_factor: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 1
            },
            index
          ])
      }}
      onResults={async (results, errors, complete) => {
        if (results.length <= 0 || !selectedOrganization?.id) return complete(nuvoError);

        // cannot submit file with errors
        if (errors.length > 0) return complete(nuvoErrorFounded);

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(results);

        const finalFileName = `${fileName || category}.csv`;

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // get presinged url
        const data = await getPresignedUrlLogistics(finalFileName, 'requests');

        // error uploading file
        if (!data || data?.response?.status >= 400) return complete(nuvoError);

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data?.requests?.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category
        });

        complete();
      }}
    />
  );
};

export default InputNuvoRequests;
