export const sessionStorageGetItem = (key: string) => {
  const value = sessionStorage.getItem(key);
  if (!value) return null;
  return JSON.parse(value);
};

export const sessionStorageSetItem = (key: string, item: unknown) => {
  sessionStorage.setItem(key, JSON.stringify(item));
};

export const sessionStorageDeleteItem = (key: string) => {
  sessionStorage.removeItem(key);
};
