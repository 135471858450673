import { Position } from '../hooks/useHandleStep';

type Props = {
  position: Position | null;
};

const HighlightedElement = ({ position }: Props) => {
  return (
    <div
      className='no-opacity'
      style={{
        visibility: position ? 'visible' : 'hidden',
        ...position
      }}
    />
  );
};

export default HighlightedElement;
