import TooltipWrapper from '../tooltip/TooltipWrapper';
import './styles.scss';

type Props = {
  children: any;
  width?: string;
};

export const Ellipsis = ({ children, width = '100px' }: Props) => {
  return (
    <TooltipWrapper
      disableHoverableContent={false}
      text={children}
      wordBreak='break-all'
      style={{
        textOverflow: 'ellipsis',
        width: width,
        overflow: 'hidden'
      }}>
      {children}
    </TooltipWrapper>
  );
};
