import { IPurchaseGeneralBackend } from '../../../../../types/purchases';
import { getUnits } from '../../../../../services/api/unit';

export const injectUnit = async (
  data: IPurchaseGeneralBackend
): Promise<IPurchaseGeneralBackend> => {
  const units: Unit[] = await getUnits({ type: 'fiat_currency' });

  return { ...data, unit: units.find((unit) => unit.id === data.unit_id) };
};
