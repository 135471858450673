import { useTranslation } from 'react-i18next';

const useTemporalOptions = () => {
  const { t } = useTranslation();
  const options: SelectOptionFormat[] = [
    {
      id: 'monthly',
      name: t('temporalOption.monthly')
    },
    {
      id: 'yearly',
      name: t('temporalOption.yearly')
    },
    {
      id: 'never',
      name: t('temporalOption.never')
    }
  ];

  return {
    options
  };
};

export default useTemporalOptions;
