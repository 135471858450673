import { CSSProperties } from 'react';
import { LCAProcessMetadata } from '../../../../../services/api/lca/lcaPortfolio';
import { ScenarioNode } from '../../types/LCAScenarios';

export const checkIssues = (
  nodeMetadata: LCAProcessMetadata | undefined,
  data: ScenarioNode['data']
) => {
  const issues = [];
  if (!nodeMetadata?.input_types?.consumption && !nodeMetadata?.input_types?.energy) {
    issues.push('noInputs');
  }

  if (!nodeMetadata?.country) {
    issues.push('noLocation');
  }

  if (!data.inputs.length) {
    issues.push('noRawMaterial');
  }

  return issues;
};

export const checkMetadata = (nodeMetadata: LCAProcessMetadata | undefined) => {
  if (!nodeMetadata) {
    return false;
  }

  return Boolean(nodeMetadata.country);
};

export const generateTags = (
  nodeMetadata: LCAProcessMetadata | undefined,
  data: ScenarioNode['data']
) => {
  const tags = [];

  tags.push({
    lookAndFeel: 'primary',
    disabled: !nodeMetadata?.input_types?.consumption && !nodeMetadata?.input_types?.energy,
    value: 'inputs'
  });

  tags.push({
    lookAndFeel: 'primary',
    disabled: !nodeMetadata?.country,
    value: 'location'
  });

  tags.push({
    lookAndFeel: 'primary',
    disabled: !data.inputs.length,
    value: 'rawMaterial'
  });

  return tags;
};

export const getHandleStyle = (
  type: 'target' | 'source',
  hasProperConnection = true
): CSSProperties => {
  if (type === 'target') {
    return {
      top: '0',
      left: '0',
      opacity: '0',
      zIndex: hasProperConnection ? '1' : '0',
      border: '0',
      position: 'absolute',
      width: '100%',
      height: '100%',
      transform: 'none'
    };
  }

  return {
    boxSizing: 'border-box',
    backgroundColor: hasProperConnection ? '#EAEAEA' : '#FFFF',
    width: hasProperConnection ? '0.5rem' : '0.75rem',
    height: hasProperConnection ? '0.5rem' : '0.75rem',
    border: hasProperConnection ? '0px solid transparent' : '2px solid #D8D1D1'
  };
};
