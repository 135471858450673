import { useTranslation } from 'react-i18next';

const useCards = () => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 'rented',
      icon: '/images/icons/tag.svg',
      title: t('vehicles.rented')
    },
    {
      id: 'owned',
      icon: '/images/icons/database.svg',
      title: t('vehicles.owned')
    }
  ];

  return cards;
};

export default useCards;
