import { ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ADMIN_WHITE_LIST } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';

type OwnProps = {
  children: ReactNode;
};

type Props = OwnProps;

function AdminRoute({ children }: Props) {
  const user = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    let isAdmin = false;

    ADMIN_WHITE_LIST.forEach((email) => {
      if (user?.email.includes(email)) {
        isAdmin = true;
        return;
      }
    });

    if (process.env.ENVIRONMENT === 'prod') {
      isAdmin = !!user?.organizations?.find(
        (org) => org.id === process.env.REACT_APP_DCYCLE_MAIN_ID
      ); // PROD Dcycle Main ID
    }

    if (!user) {
      navigate(ROUTES.LOGIN);
    } else if (!isAdmin) {
      navigate(ROUTES.NOT_FOUND);
    }
  }, [user?.email]);

  return <>{children}</>;
}

export default AdminRoute;
