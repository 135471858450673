import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../ui/tooltip/Tooltip';

const useConstants = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife.columns' });

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('code'),
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: t('life'),
      dataIndex: 'life',
      key: 'life'
    },
    {
      title: t('year'),
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: t('soldUnits'),
      dataIndex: 'soldUnits',
      key: 'soldUnits'
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '0.5rem'
          }}>
          {t('transformation')}
          <Tooltip text={t('cat10Tooltip')} />
        </div>
      ),
      dataIndex: 'transformation',
      key: 'transformation'
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '0.5rem'
          }}>
          {' '}
          {t('use')}
          <Tooltip text={t('cat11Tooltip')} />
        </div>
      ),
      dataIndex: 'use',
      key: 'use'
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            gap: '0.5rem'
          }}>
          {' '}
          {t('endOfLife')}
          <Tooltip text={t('cat12Tooltip')} />
        </div>
      ),
      dataIndex: 'endOfLife',
      key: 'endOfLife'
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: null,
      dataIndex: 'edit',
      key: 'edit'
    }
  ];

  return { columns };
};

export default useConstants;
