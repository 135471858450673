import apiFetch from '../../utils/apiFetch';

export const getCategories = async () => {
  try {
    const response = await apiFetch('GET', `/framework_categories/dcycle`, null);
    return response.data;
  } catch (error) {
    return error;
  }
};
