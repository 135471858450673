import { useEffect, useState } from 'react';
import { findOrCreateSbti } from '../../../../services/api/sbti';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';

export const useSbtiId = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getId = async (signal?: AbortSignal) => {
    setLoading(true);
    const response = await findOrCreateSbti(signal);
    setLoading(false);

    if (response?.response?.status >= 400 || !response?.id) return;

    navigate(`${ROUTES.IMPROVE_SBTI}/${response.id}`);
  };

  useEffect(() => {
    // If the component is unmounted, the request is aborted
    // If you switch screens and the request finalize after the component is unmounted, the response is ignored
    // Because if not, the component will try to navigate to the previous screen
    const controller = new AbortController();
    getId(controller.signal);
    return () => controller.abort();
  }, []);

  return { loading };
};
