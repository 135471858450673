import { useTranslation } from 'react-i18next';
import {
  IPurchaseGeneralBackend,
  IPurchaseSpendBasedBackend,
  IPurchaseSupplierSpecificBackend,
  PurchaseType
} from '../../../../../../types/purchases';
import { limitString } from '../../../../../../utils/limitString';
import numberToDecimal from '../../../../../../utils/numberToDecimal';
import Label from '../../../../../ui/label/Label';
import { StatusTag } from '../../../../../ui/statusTag';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { Dropdown } from '../../components/Dropdown';
import { getAllCountries } from '../../../../../../constants/phonePrefixes';
import { currencySymbols } from '../../../../../../constants/currencySymbols';
import { useFeatureFlags } from '../../../../../../customHooks/useFeatureFlags';
import { AMZ_BUSINESS_ID } from '../../constants';
import { Status } from '../../../../../../types/utilsEnums/status';
import { PurchasesStatusTag } from '../../components/statusTag/StatusTag';
import { PurchaseStatus } from '../../type';

export const useParseData = (handlers: React.ComponentProps<typeof Dropdown>['handlers']) => {
  const { t, i18n } = useTranslation();

  const flags = useFeatureFlags();

  const renderCountry = (country: string) => {
    const countries = getAllCountries();
    const countrySelected = countries.find((element) => element.code === country);
    return countrySelected
      ? countrySelected.name[
          `${i18n.resolvedLanguage || 'en'}` as keyof (typeof countrySelected)['name']
        ]
      : country;
  };

  const renderDescription = (description: string | undefined) => {
    return (
      <div className='icon-text-wrapper'>
        <span title={description}>{description ? limitString(description, 20) : '-'}</span>
      </div>
    );
  };

  const decideTag = (status?: PurchaseStatus, flag?: boolean) => {
    if (status === Status.ACTIVE || !flag || !status) {
      return (
        <PurchasesStatusTag
          status={Status.ACTIVE}
          text={t(`purchases.purchasesTable.tags.active`)}
        />
      );
    }

    return (
      <TooltipWrapper text={t(`purchases.purchasesTable.tooltip.${status}`)} position='top'>
        <PurchasesStatusTag status={status} text={t(`purchases.purchasesTable.tags.${status}`)} />
      </TooltipWrapper>
    );
  };

  const parseDataSpendBased = (purchase: IPurchaseSpendBasedBackend) => ({
    ...purchase,
    sector: purchase?.sector ? limitString(t(`purchases.sectors.${purchase.sector}`), 20) : '-',
    product_name: purchase.product_name ? (
      <span title={t(`purchases.products.${purchase.product_name}`)}>
        {limitString(t(`purchases.products.${purchase.product_name}`), 40)}
      </span>
    ) : (
      '-'
    ),
    frequency: t(`purchases.frequencies.${purchase.frequency}`),
    recycled: purchase.recycled ? t('purchases.yes') : t('purchases.no'),
    supplier: purchase.supplier?.business_name ?? '-',
    quantity:
      purchase.quantity && purchase?.unit?.name
        ? `${purchase.quantity} ${currencySymbols[purchase?.unit?.name]}`
        : '-',
    country: purchase.supplier?.country ? renderCountry(purchase.supplier.country) : '-',
    purchase_date: new Date(purchase.purchase_date).toLocaleDateString(),
    description: renderDescription(purchase.description),
    file_url: purchase.file_url ? <img src='/images/icons/attached.svg' alt='attached' /> : '',
    status: decideTag(purchase.status, flags?.purchasesUpload),
    disabled: false,
    type: <Label lookAndFeel='secondary'>{t('purchases.spendBased')}</Label>,
    edit: (
      <Dropdown
        purchase={{ ...purchase, purchase_type: PurchaseType.SPEND_BASE }}
        handlers={handlers}
        disabled={purchase.supplier_id === AMZ_BUSINESS_ID}
      />
    ),
    co2e:
      typeof purchase.co2e === 'number' && purchase.status !== Status.IN_PROGRESS ? (
        <span className='highlight-text-color small-card-font'>
          {numberToDecimal(purchase.co2e, 2)}kg
        </span>
      ) : (
        '-'
      )
  });

  const parseDataSupplierSpecific = (purchase: IPurchaseSupplierSpecificBackend) => ({
    ...purchase,
    sector: '-',
    product_name: purchase.product_name ? limitString(purchase.product_name, 40) : '-',
    frequency: t(`purchases.frequencies.once`),
    recycled: '-',
    quantity:
      purchase.quantity && purchase?.unit?.name
        ? `${purchase.quantity} ${t(`units_short.${purchase?.unit?.name}`)}`
        : '-',
    country: '-',
    purchase_date: new Date(purchase.purchase_date).toLocaleDateString(),
    description: renderDescription(purchase.description),
    file_url: purchase.file_url ? <img src='/images/icons/attached.svg' alt='attached' /> : '',
    status:
      flags?.purchasesUpload && purchase.status ? (
        <StatusTag status={purchase.status} />
      ) : (
        <StatusTag status={Status.ACTIVE} />
      ),
    disabled: flags?.purchasesUpload ? purchase.status !== 'active' : false,
    type: <Label lookAndFeel='primary'>{t('purchases.supplierSpecific')}</Label>,
    edit: (
      <Dropdown
        purchase={{ ...purchase, purchase_type: PurchaseType.SUPPLIER_SPECIFIC }}
        handlers={handlers}
      />
    )
  });
  const parseData = (purchases: IPurchaseGeneralBackend[]) => {
    const data = purchases.map((purchase) => {
      const parseDataFunctions = {
        [PurchaseType.SPEND_BASE]: parseDataSpendBased,
        [PurchaseType.SUPPLIER_SPECIFIC]: parseDataSupplierSpecific
      };

      if (PurchaseType.SPEND_BASE === purchase.purchase_type) {
        return parseDataFunctions[PurchaseType.SPEND_BASE](purchase);
      }

      return parseDataFunctions[PurchaseType.SUPPLIER_SPECIFIC](purchase);
    });

    return data;
  };

  return parseData;
};
