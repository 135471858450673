import { createContext } from 'react';
import { formatDate } from '../utils/formatDate';

type FilterDates = {
  startDate: string;
  setStartDate: React.Dispatch<any>;
  endDate: string;
  setEndDate: React.Dispatch<any>;
  dateError: ErrorType | undefined;
  setDateError: React.Dispatch<any>;
};
const today = new Date();
today.setHours(0, 0, 0, 0);
today.setDate(today.getDate());
const oneYearAgo = new Date();
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
oneYearAgo.setMonth(0);
oneYearAgo.setDate(1);
oneYearAgo.setHours(0, 0, 0, 0);
const FilterDatesContext = createContext<FilterDates>({
  startDate: formatDate(oneYearAgo),
  endDate: formatDate(today),
  setStartDate: () => {
    /* do nothing */
  },
  setEndDate: () => {
    /* do nothing */
  },
  dateError: undefined,
  setDateError: () => {
    /* do nothing */
  }
});

export { FilterDatesContext };
