import { useTranslation } from 'react-i18next';
import GrayBackground from '../../../../../../ui/grayBackground/GrayBackground';
import LoaderCard from '../../../../../../ui/loaders/loaderCard/LoaderCard';
import { AmbitionLevel } from '../progress/Progress';
import './styles.scss';

type Props = {
  loading: boolean;
  organizationGoal: IOrganizationGoalSbtiBack | null;
};

const Objective = ({ loading, organizationGoal }: Props) => {
  const { t: tr } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.answers' });
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.modelingObjectives' });

  const color = organizationGoal ? 'highlight-text-color' : 'disabled-text-color';
  const colorScope3 =
    organizationGoal && organizationGoal.scope_3_goal_year
      ? 'highlight-text-color'
      : 'disabled-text-color';

  const goalYearScope1and2 = organizationGoal
    ? `${organizationGoal.base_year} - ${organizationGoal.scope_1_2_goal_year}`
    : 'YYYY - YYYY';

  const goalYearScope3 =
    organizationGoal && organizationGoal.scope_3_goal_year
      ? `${organizationGoal.base_year} - ${organizationGoal.scope_3_goal_year}`
      : 'YYYY - YYYY';

  const ambitionLevelScope1and2 = organizationGoal
    ? `${tr(organizationGoal.ambition_level_scope_1_2)} - ${
        AmbitionLevel[organizationGoal.ambition_level_scope_1_2]
      }%`
    : '0ºC - 0%';

  const ambitionLevelScope3 = organizationGoal ? `${tr('2')} - ${AmbitionLevel[2]}%` : '0ºC - 0%';

  const method =
    organizationGoal && organizationGoal.scope_3_goal_year
      ? tr(organizationGoal.method)
      : t('noData');

  if (loading) return <LoaderCard className='item-objective' />;

  return (
    <div className='card item-objective'>
      <h2 className='headline4-font objective-title'>{t('title')}</h2>
      <GrayBackground className='objective-wrapper-sbti'>
        <div className='objective-data-sbti' style={{ height: '100%' }}>
          <h3>{t('scope1and2')}</h3>
          <div className='objective-container'>
            <div className='objective'>
              <h4 className={'headline4-font'}>
                <span className={`${color}`}>{goalYearScope1and2}</span>
              </h4>
              <p className='subtitle3-font'>{t('baseYearAndGoalYear')}</p>
            </div>
            <div className='objective'>
              <h4 className={`headline4-font`}>
                <span className={`${color}`}>{ambitionLevelScope1and2}</span>
              </h4>
              <p className='subtitle3-font'>{t('ambitionLevelAndReduction')}</p>
            </div>
          </div>
          <h3>{t('scope3')}</h3>
          <div className='objective-container'>
            <div className='objective'>
              <h4 className={`headline4-font`}>
                <span className={`${colorScope3}`}>{goalYearScope3}</span>
              </h4>
              <p className='subtitle3-font'>{t('baseYearAndGoalYear')}</p>
            </div>
            <div className='objective'>
              <h4 className={`headline4-font`}>
                <span className={`${colorScope3}`}>{ambitionLevelScope3}</span>
              </h4>
              <p className='subtitle3-font'>{t('ambitionLevelAndReduction')}</p>
            </div>
          </div>
          <div className='objective'>
            <h4 className={`headline4-font`}>
              <span className={`${color}`}>{method}</span>
            </h4>
            <p className='subtitle3-font'>{t('method')}</p>
          </div>
        </div>
      </GrayBackground>
    </div>
  );
};

export default Objective;
