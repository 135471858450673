import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getFacilityFuelsByFacilityId,
  getSuppliersByFacilityId
} from '../../../../../services/api/invoices';
import { Facility } from '../../../../../types/entities/facility';
import { InvoiceStatus } from '../../../../../types/entitiesEnums/invoice';
import ErrorLabel from '../../../../ui/statusLabels/errorLabel/ErrorLabel';
import PendingLabel from '../../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../../ui/statusLabels/successLabel/SuccessLabel';

type UseFacilityFiltersType = {
  optionsForFilter: any;
  setOptionsForFilters: (options: any) => void;
  facility?: Facility;
  category: string;
  open: boolean;
};

const useSideBarFilters = ({
  optionsForFilter,
  setOptionsForFilters,
  facility,
  category,
  open
}: UseFacilityFiltersType) => {
  const { t } = useTranslation();

  const fetchSuppliers = async () => {
    const data = await getSuppliersByFacilityId(facility?.id ?? '');
    setOptionsForFilters((prevState: any) => ({
      ...prevState,
      supplier: data
        .filter((elem: { type: string }) => elem.type === category)
        .map((elem: { name: string; id: string }) => ({
          name: elem.name,
          type: 'supplier_id',
          id: elem.id
        }))
    }));
  };

  const fetchFacilityFuels = async () => {
    const data = await getFacilityFuelsByFacilityId(facility?.id ?? '');
    if (!data) return;
    setOptionsForFilters((prevState: any) => ({
      ...prevState,
      fuel: data
        .filter((elem: { type: string }) => elem.type === category)
        .map((elem: { name: string; id: string }) => ({
          name: t(`facility_fuels.${elem.name}`),
          type: 'facility_fuel_id',
          id: elem.id
        }))
    }));
  };

  useEffect(() => {
    if (!open || !facility) return;
    if (category === 'electricity' || category === 'heat') {
      fetchSuppliers();
    }
    if (category === 'heat' || category === 'recharge') {
      fetchFacilityFuels();
    }
  }, [open, category]);

  useEffect(() => {
    if (facility) {
      setOptionsForFilters({
        cups:
          category === 'electricity' && facility.cups_list
            ? facility.cups_list.map((elem) => ({
                name: elem,
                type: 'cups',
                id: elem
              }))
            : [],
        supplier: [],
        fuel: []
      });
    }
  }, [facility?.id, category]);

  return [
    {
      options: [
        {
          id: ['heat', 'recharge', 'electricity', 'water'].includes(category)
            ? InvoiceStatus.ACTIVE
            : InvoiceStatus.UPLOADED,
          name: <SuccessLabel>{t('general.completed')}</SuccessLabel>,
          type: 'status'
        },
        {
          id: InvoiceStatus.LOADING,
          name: <PendingLabel>{t('general.processing')}</PendingLabel>,
          type: 'status'
        },
        {
          id: InvoiceStatus.ERROR,
          name: <ErrorLabel>{t('general.withErrors')}</ErrorLabel>,
          type: 'status'
        }
      ],
      title: t('facilityDetail.status'),
      elToShow: 0
    },
    {
      options: optionsForFilter?.cups.map((value: { name: string; type: string }) => ({
        id: value.name,
        name: value.name,
        type: value.type
      })),
      title: 'CUPS',
      elToShow:
        optionsForFilter?.cups?.length > 3 ? Math.floor(optionsForFilter?.cups?.length / 2) : 0
    },
    {
      options: optionsForFilter?.supplier.map(
        (value: { name: string; type: string; id: string }) => ({
          id: value.id,
          name: value.name,
          type: value.type
        })
      ),
      title: t('facilityDetail.supplier'),
      elToShow: 0
    },
    {
      options: optionsForFilter?.fuel.map((value: { name: string; type: string; id: string }) => ({
        id: value.id,
        name: value.name,
        type: value.type
      })),
      title: t('facilityDetail.fuel'),
      elToShow: 0
    }
  ];
};

export default useSideBarFilters;
