type GroupedByCategory = {
  baseYear?: number;
  currentYear?: number;
  goalYear?: number;
  category: string;
  scope?: number;
};

export interface UnionCategoryGoal extends GroupedByCategory {
  percentage?: string;
  action?: string;
}

export const listCategoriesScope: { [key: string]: number } = {
  scope1: 1,
  stationary: 1,
  transport: 1,
  recharge: 1,
  scope2: 2,
  electricity: 2,
  scope3: 3,
  water: 3,
  employees: 3,
  travels: 3,
  transport_distribution_downstream: 3,
  transport_distribution_upstream: 3,
  purchases: 3,
  wastes: 3
};

// list of categories to show, because you dont want to show all the categories.
export const listCategories = Object.keys(listCategoriesScope);

export const substractPercentage = (a?: number, percentage?: number) => {
  if (!percentage && a) return a;
  if (!a || !percentage) return 0;
  const restPercentage = 100 - percentage;
  return (a * restPercentage) / 100;
};

export const groupDataByScope = (
  array: TotalImpactYearly[]
): { scope1: TotalImpactYearly[]; scope2: TotalImpactYearly[]; scope3: TotalImpactYearly[] } => {
  const scope1: TotalImpactYearly[] = [];
  const scope2: TotalImpactYearly[] = [];
  const scope3: TotalImpactYearly[] = [];

  array.forEach((element) => {
    if (element.scope === 1) scope1.push(element);
    if (element.scope === 2) scope2.push(element);
    if (element.scope === 3) scope3.push(element);
  });

  return { scope1, scope2, scope3 };
};

export const groupByCategory = (
  array: TotalImpactYearly[],
  baseYear: number,
  currentYear: number
) => {
  const groupedByCategory: GroupedByCategory[] = [];

  listCategories.forEach((category) => {
    const result = { baseYear: 0, currentYear: 0, goalYear: 0, category, scope: -1 };

    array.forEach((element) => {
      if (element.category !== category) return;

      if (Number(element.year) === baseYear) result.baseYear += element.emissions;
      if (Number(element.year) === currentYear) result.currentYear += element.emissions;
      if (Number(element.year) === baseYear) result.goalYear += element.emissions;

      result.scope = element.scope;
    });

    if (result.scope === -1) return;
    groupedByCategory.push(result);
  });

  return groupedByCategory;
};

export const unionWithGoals = (
  goals: IOrganizationModelingBack[],
  array: GroupedByCategory[]
): UnionCategoryGoal[] => {
  return array.map((element) => {
    const goal = goals.find((goal) => goal.category_name === element.category);
    if (!goal) return { ...element, action: '', percentage: '' };
    return {
      ...element,
      goalYear: substractPercentage(element.goalYear, Number(goal.reduction_percentage)),
      action: goal.reduction_description,
      percentage: goal.reduction_percentage
    };
  });
};

export const getScopeData = (data: UnionCategoryGoal[], category: string): UnionCategoryGoal => {
  const scopeGrouped: UnionCategoryGoal = {
    baseYear: 0,
    currentYear: 0,
    goalYear: 0,
    percentage: '',
    action: '',
    category,
    scope: 0
  };

  data.forEach((element) => {
    if (scopeGrouped.baseYear !== undefined) scopeGrouped.baseYear += element.baseYear || 0;
    if (scopeGrouped.currentYear !== undefined)
      scopeGrouped.currentYear += element.currentYear || 0;
    if (scopeGrouped.goalYear !== undefined) scopeGrouped.goalYear += element.goalYear || 0;
    scopeGrouped.scope = element.scope;
  });

  return scopeGrouped;
};
