import { useTranslation } from 'react-i18next';
import { useLCATranslation } from '../../../../../../customHooks/translations/useLCATranslation';
import { LCA_IMPACT_CATEGORIES_TRANSLATION_KEYS } from '../../../constants';
import type { FrontLCAMethodology } from '../../types/LCADetail';
import { LCACard } from '../LCACard';
import { useLCAMethodology } from '../LCAMethodologyContext/LCAMethodologyContext';
import { getImpactCategoryIcon } from './utils';
import { formatLCAUnit } from '../../utils/formatLCAUnit';

type Props = {
  data: FrontLCAMethodology;
};

export const LCAMethodologyCard: React.FC<Props> = ({ data }) => {
  const { setMethodology } = useLCAMethodology();

  const { t } = useLCATranslation();

  const { t: translateUnit } = useTranslation('translation', { keyPrefix: 'units_short' });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setMethodology(data);
  };

  return (
    <button className='reset pointer' onClick={handleClick}>
      <LCACard.Layout>
        <LCACard.Content>
          <LCACard.Icon icon={getImpactCategoryIcon(data.impact_category)} />
          <div className='flex-col gap-y-2 flex-grow py-1 justify-center'>
            <h4 className='m-0 font-body-b1-b'>
              {t(LCA_IMPACT_CATEGORIES_TRANSLATION_KEYS[data.impact_category])}
            </h4>
            <LCACard.Tag>
              {t('lcaDetail.methodology')}: {data.methodology}
            </LCACard.Tag>
          </div>
          <LCACard.TotalImpact
            impact={data.impact}
            unit={formatLCAUnit(translateUnit(data.unit.name), data.category)}
          />
        </LCACard.Content>
      </LCACard.Layout>
    </button>
  );
};
