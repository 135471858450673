import { numberToScientificNotation } from '../../../../../utils/numberToDecimal';
import { FrontLCAComponent, LCAComponent } from '../types/LCADetail';

export const adaptLCAComponent = (data: LCAComponent[]): FrontLCAComponent[] => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    impact: numberToScientificNotation(item.impacts),
    parent_id: item.parent_id,
    is_last: item.is_last
  }));
};
