import { useEffect, useState } from 'react';
import { getIntensityMetricGroups } from '../../services/api/intensityMetrics';

const useGetIntensityMetrics = () => {
  const [intensityMetricGroups, setIntensityMetricGroups] = useState<IntensityMetricGroup[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getIntensityMetricsGroups = async () => {
      setLoading(true);
      const data = await getIntensityMetricGroups();
      setLoading(false);
      if (data && data.items) {
        setIntensityMetricGroups(data.items);
      }
    };
    getIntensityMetricsGroups();
  }, []);
  return { intensityMetricGroups, loading };
};

export default useGetIntensityMetrics;
