import { TFunction } from 'i18next';

export const adaptLCAImpactCategories = (impact_categories?: string[], t?: TFunction) => {
  return (
    impact_categories?.map((category) => ({
      id: category,
      name: t ? t(`lcaDetail.impactCategories.${category}`) : category
    })) ?? []
  );
};
