import { useEffect, useState } from 'react';
import { useACVId } from './useACVId';
import { useLCAProcess } from './useLCAProcess';
import { useTranslation } from 'react-i18next';
import { buildForm } from '../components/ConfigureProcessModal/utils';
import { useNodeInputs } from './useNodeInputs';

export const useConfigureProcessForm = (node_id?: string) => {
  const { i18n } = useTranslation();

  const { acv_id } = useACVId();

  const metadata = useLCAProcess({ node_id: node_id ?? '', acv_id });
  const { data: nodeMaterials } = useNodeInputs({ node_id, acv_id });

  const [form, setForm] = useState(buildForm(metadata, nodeMaterials, i18n.resolvedLanguage));
  const [errors, setErrors] = useState<ErrorContainer<typeof form> | null>(null);

  useEffect(() => {
    setForm(buildForm(metadata, nodeMaterials, i18n.resolvedLanguage));
    setErrors(null);
  }, [JSON.stringify(metadata), JSON.stringify(nodeMaterials)]);

  return { form, setForm, errors, setErrors };
};
