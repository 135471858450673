import { Dispatch } from 'react';
import uuid from 'uuid';
import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from './types';

type NotificationDispatch = {
  type: string;
  payload?: NotificationType | string;
};
export const setNotification =
  (msg: string, timeout = 5000) =>
  (dispatch: Dispatch<NotificationDispatch>) => {
    const id = uuid.v4();
    dispatch({
      type: SET_NOTIFICATION,
      payload: {
        id,
        msg
      }
    });

    setTimeout(() => dispatch({ type: REMOVE_NOTIFICATION }), timeout);
  };

export const removeNotification = () => (dispatch: Dispatch<NotificationDispatch>) => {
  dispatch({ type: REMOVE_NOTIFICATION });
};
