import { InputSize } from '../../../../types/utilsEnums/input';
import './styles.scss';

type Props = {
  value: SelectOptionFormat;
  onClickOption: (option: any) => void;
  fontClass?: string;
  height?: string;
  optionIndex: number;
  index: number;
  size?: InputSize;
  changeSelectedIndex: (index: number) => void;
};

const SelectOption = ({
  value,
  onClickOption,
  fontClass = 'input-font',
  optionIndex,
  index,
  size = InputSize.MEDIUM,
  changeSelectedIndex
}: Props) => {
  const handleOnClick = () => {
    onClickOption(value);
  };

  const onHoverOption = () => {
    changeSelectedIndex(index);
  };
  const sizeClassName = size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';

  return (
    <div
      className={`select-options__item ${
        index === 0 && !value.id ? 'placeholder' : 'on-light-text-color'
      } ${fontClass} ${index === optionIndex ? 'active' : ''} ${sizeClassName}`}
      id={value.id}
      key={value.id}
      onClick={handleOnClick}
      onMouseEnter={onHoverOption}>
      {value.name}
    </div>
  );
};

export default SelectOption;
