import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import controller from '../../../../environments/controller';
import { PredefinedTask } from '../../../../types/entities/task';
import {
  convertDateFrontToBackTimestamp,
  convertDateToString
} from '../../../../utils/convertDates';

const usePredefinedTasks = (organization_id: string) => {
  const { i18n } = useTranslation();
  const user = useContext(UserContext);
  const BASE_URL = controller().clientUrl;

  if (!organization_id) return [];

  const todayPlus7Days = new Date();
  todayPlus7Days.setDate(todayPlus7Days.getDate() + 7);

  type TasksByLanguage = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any[];
  };
  const tasksByLanguage: TasksByLanguage = {
    es: [
      {
        description:
          'Crea tus instalaciones. https://scribehow.com/shared/Crea_tus_instalaciones__n3T4QJqeThWhtG9XpXcbDA',
        category: 'facilities',
        tags: ['Instalaciones', 'Alcance 1', 'Alcance 2', 'Alcance 3']
      },
      {
        description:
          'Sube las facturas de electricidad de cada mes del año a medir para cada instalación. https://scribehow.com/shared/Subir_facturas_de_electricidad__6731lF_STMSRI6Ulxy1ELg',
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones', 'Electricidad', 'Alcance 2']
      },
      {
        description:
          'Sube las facturas de agua de cada mes del año a medir para cada instalación. https://scribehow.com/shared/Subir_facturas_de_agua__vGmMj53VRpKEacadp6zLXQ',
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones', 'Agua', 'Alcance 3']
      },
      {
        description:
          'Sube las facturas de gas natural de cada mes del año a medir para cada instalación. https://scribehow.com/shared/Subir_facturas_de_combustion__2euJVZFtS92zKt28eKtm-A',
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones', 'Combustión', 'Alcance 1']
      },
      {
        description:
          'Subir manualmente consumos de combustión (Gasoleo B, gas butano, propano o similar) para cada instalación. https://scribehow.com/shared/Subir_facturas_de_combustion__2euJVZFtS92zKt28eKtm-A',
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones', 'Combustión', 'Alcance 1']
      },
      {
        description:
          'Revisar y subir (si hubieron) recargas en los equipos de A/A, climatización o refrigeración para cada instalación. https://scribehow.com/shared/Recargas__hC7n_7UUQa62cl6ExV0uAw',
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones', 'Recargas', 'Alcance 1']
      },
      {
        description:
          'Subir los datos de residuos para cada instalación. https://scribehow.com/shared/Como_subir_residuos_automaticamente__sIC-HBjzSqqb5uf4_sIx2A',
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones', 'Residuos', 'Alcance 3']
      },
      {
        description: `Verifica los datos de tus facturas de instalaciones que te faltan para avanzar en tu medición. ${BASE_URL}measure/facilities`,
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones']
      },
      {
        description: `Verifica los datos de los consumos de vehículos que te faltan para avanzar en tu medición. ${BASE_URL}measure/vehicles`,
        category: 'invoices',
        tags: ['Facturas', 'Instalaciones']
      },
      {
        description:
          'Crear vehículos manualmente. https://scribehow.com/shared/Como_crear_vehiculos_propios_o_alquilados__zgj1fnDiQQa8CufLTvS6Vw',
        category: 'vehicles',
        tags: ['Vehículos', 'Alcance 1']
      },
      {
        description:
          'Crear vehículos agrícolas o industriales de la empresa. https://scribehow.com/shared/Guia_Como_crear_un_vehiculo_tipo_agricola_o_industrial_y_agregar_sus_consumos__HbnfPmuBR_qgHteLkh9DFA',
        category: 'vehicles',
        tags: ['Vehículos', 'Alcance 1']
      },
      {
        description:
          'Crear vehículos automáticamente. https://scribehow.com/shared/Como_crear_vehiculos_automaticamente__2IIdCktxRz2PdA7RwyCHkg',
        category: 'vehicles',
        tags: ['Vehículos', 'Alcance 1']
      },
      {
        description:
          'Subir los consumos de combustible de forma manual y automática. https://scribehow.com/shared/Como_subir_los_consumos_de_tus_vehiculos_de_forma_manual_y_automatica__N90Rf1Q-Tae3eTOxwZ8zQQ',
        category: 'vehicle_consumptions',
        tags: ['Vehículos', 'Consumos', 'Alcance 1']
      },
      {
        description:
          'Definir los limites de la medición: instalaciones medidas, (qué está dentro de la medición y lo que no está). https://scribehow.com/shared/Envianos_la_informacion_de_la_inscripcion_de_MITECO__aWksw-E2SXmDMidEo6vRnA',
        category: 'other',
        tags: ['MITECO']
      },
      {
        description:
          'Enviar el formulario de empleados o crearlos de manera manual. https://scribehow.com/shared/Como_enviar_el_formulario_de_empleados_y_rellenar_los_datos__fKD5dc9lQ1WccdKQf2mpgA?referrer=documents',
        category: 'employees',
        tags: ['Empleados', 'Alcance 3']
      },
      {
        description:
          'Rellenar la encuesta de movilidad de empleados. https://scribehow.com/shared/Como_rellenar_la_encuesta_de_movilidad_de_tus_empleados__0rCPPXq-RxCXeINHN2Kl4Q',
        category: 'employees',
        tags: ['Empleados', 'Alcance 3']
      },
      {
        description:
          'Descargar el QR para empleados sin acceso al mail. https://scribehow.com/shared/Como_descargar_el_QR_para_empleados_sin_acceso_al_mail__shjC4L2TTTWwgb1MjPIU-A',
        category: 'employees',
        tags: ['Empleados', 'Alcance 3']
      },
      {
        description:
          'Descarga la encuesta e imprímela en caso de que los empleados no tengan teléfono móvil. https://drive.google.com/file/d/1u-cWTYihANvX-JwxRStuHNMI7cjprj_S/view',
        category: 'employees',
        tags: ['Empleados', 'Alcance 3']
      },
      {
        description:
          'Dar de baja a un empleado y luego activar su medición de nuevo. https://scribehow.com/shared/Como_dar_de_baja_a_un_empleado_y_luego_activarlo_de_nuevo__ODDVrUwfTmyRSM9sxwEGZg',
        category: 'employees',
        tags: ['Empleados', 'Alcance 3']
      },
      {
        description:
          'Añadir los viajes de negocio de manera automática https://scribehow.com/shared/Anade_viajes_de_negocio_de_manera_automatica__xCNGPdCBTp6MeYJzJVxmsQ',
        category: 'business_travels',
        tags: ['Viajes de negocio', 'Alcance 3']
      },
      {
        description:
          'Añadir los viajes de negocio de forma manual. https://scribehow.com/shared/Anade_tus_viajes_de_negocio_de_manera_manual__V-GWXjkcQreHh1zwDuuDPQ',
        category: 'business_travels',
        tags: ['Viajes de negocio', 'Alcance 3']
      },
      {
        description:
          'Subir mercancías recibidas de manera manual o automática. https://scribehow.com/shared/Como_subir_mercancia_enviada_y_recibida_de_forma_manual_o_automatica__H5mmUALDSzK7Fp00ywuJ1g',
        category: 'transport_upstream',
        tags: ['Mercancía recibida', 'Alcance 3']
      },
      {
        description:
          'Subir mercancías enviadas de manera manual o automática. https://scribehow.com/shared/Como_subir_mercancia_enviada_y_recibida_de_forma_manual_o_automatica__H5mmUALDSzK7Fp00ywuJ1g',
        category: 'transport_downstream',
        tags: ['Mercancía enviada', 'Alcance 3']
      },
      {
        description:
          'Subir archivo de compras de forma automática. https://scribehow.com/shared/Sube_tus_compras_automaticamente__4fz0eldlQSK3JRs_2VK_pw',
        category: 'purchases',
        tags: ['Compras', 'Alcance 3']
      },
      {
        description:
          'Subir archivo de compras de forma manual. https://scribehow.com/shared/Sube_tus_compras_manualmente__fJG4jcu-SamP2bekdZFb0A',
        category: 'purchases',
        tags: ['Compras', 'Alcance 3']
      },
      {
        description:
          'Completa tus 24 criterios SBTi y conoce si estás aceptado. https://scribehow.com/shared/Completa_tus_24_criterios_SBTi_y_conoce_si_estas_aceptado__N75obguyTYywnIYvY4fnIg',
        category: 'sbti',
        tags: ['Objetivos SBTi']
      },
      {
        description:
          'Desarrolla tu objetivo de reducción SBTi en Modelización. https://scribehow.com/shared/Desarrolla_tu_objetivo_de_reduccion_SBTi_en_Modelizacion__AuLjF0lEQdG8FZwdmCqyUA?referrer=workspace ',
        category: 'sbti',
        tags: ['Objetivos SBTi']
      },
      {
        description:
          'Preparar documentación para MITECO. https://scribehow.com/shared/Como_preparar_tu_documentacion_para_MITECO__WsukpOltQqa9Sew1wzgTOw',
        category: 'other',
        tags: ['MITECO']
      },
      {
        description:
          'Modificar los datos de un empleado cuando cambia de domicilio. https://scribehow.com/shared/Como_modificar_tus_datos_cuando_te_cambias_de_domicilio__xJ3SluC-RlaNn-nLdwYnew',
        category: 'employees',
        tags: ['Empleados', 'Alcance 3']
      },
      {
        description:
          'Medir en las empresas de Logística bajo el marco GLEC. https://scribehow.com/shared/Como_medir_en_las_empresas_de_Logistica_bajo_el_marco_GLEC__ZzztbxeHQUmU4-PYIzgB9Q',
        category: 'other',
        tags: ['Logística', 'GLEC']
      },
      {
        description:
          'Crear métricas de intensidad. https://scribehow.com/shared/Como_crear_metricas_de_intensidad_personalizadas__itNZP364QL6F37i_9nC-kQ',
        category: 'other',
        tags: ['Métricas de Intensidad']
      },
      {
        description:
          'Crear factores de emisión personalizados. https://scribehow.com/shared/Como_crear_un_factor_de_emision_personalizado__En2nFT6QSdSpLq9YNiFvpw',
        category: 'other',
        tags: ['Factores de Emisión']
      },
      {
        description:
          'Crear un factor personalizado para redimir los GdOs. https://scribehow.com/shared/Como_crear_un_factor_personalizado_para_redimir_los_GdOs__tWKeicYgRny8_DTeZneVkA',
        category: 'other',
        tags: ['GdOs']
      },
      {
        description:
          'Añadir las emisiones evitadas de tus placas solares. https://scribehow.com/shared/Como_subir_las_emisiones_evitadas_de_tus_placas_solares___A4fbvw4RWGl8OTaPEXCWA',
        category: 'other',
        tags: ['Emisiones Evitadas']
      },
      {
        description:
          'Rellenar tus datos de social y gobernanza. https://scribehow.com/shared/Como_rellenar_tus_datos_de_social_y_gobernanza__oK3zpqPUQOqH7eTmi0J8Ig',
        category: 'other',
        tags: ['Social y Gobernanza']
      },
      {
        description:
          'Conéctate a Datadis. https://scribehow.com/shared/Datadis__EYTSGo4kR9uIFzbwDB754w',
        category: 'invoices',
        tags: ['Alcance 2', 'Electricidad']
      },
      {
        description:
          'Invitar a más miembros de tu equipo. https://scribehow.com/shared/Invita_a_mas_miembros_de_tu_equipo__4gJ5CuOUS5aiV4YQePjqNw',
        category: 'other',
        tags: ['Miembros']
      },
      {
        description:
          'Reenviar la encuesta de movilidad. https://scribehow.com/shared/Como_enviar_el_formulario_de_empleados_al_mail_o_crearlos_de_manera_manual__kg6B26yWSrmCoUxJzHSNiA',
        category: 'employees',
        tags: ['Empleados', 'Alcance 3']
      },
      {
        description:
          'Cómo dividir los consumos de tus facturas: https://scribehow.com/shared/Aprende_como_dividir_tus_facturas_en_Dcycle__SxO-ojNTTr6TG2vRpd89xQ',
        category: 'invoices',
        tags: [
          'Facturas',
          'Instalaciones',
          'Electricidad',
          'Agua',
          'Recargas',
          'Combustión',
          'Alcance 1',
          'Alcance 2',
          'Alcance 3'
        ]
      },
      {
        description:
          'Cómo actualizar los factores de emisión en Dcycle: https://scribehow.com/shared/Como_actualizar_los_factores_de_emision__-c9sTBaxQaC1rg5X1UetrQ?referrer=workspace',
        category: 'other',
        tags: ['Factores de Emisión']
      },
      {
        description:
          'Crea empresas dentro de tu cuenta holding: https://scribehow.com/shared/Como_crear_empresas_dentro_de_una_cuenta_holding__B_mATAWARlea0YiwzgVSSw',
        category: 'other',
        tags: ['Holding']
      },
      {
        description:
          'Crear y asignar tareas a miembros del equipo: https://scribehow.com/shared/Google_Chrome_Workflow__hy4UOsCZScql1uxxFSgLIA',
        category: 'other',
        tags: ['Tareas']
      },
      {
        description:
          'Vincula tu empresa con tu gestora de fondos: https://scribehow.com/shared/Vincula_tu_empresa_con_tu_gestora_de_fondos__WsMFqLl4TTeyP-v_bNZb8w?referrer=search',
        category: 'other',
        tags: ['Vinculación']
      }
    ],
    en: [
      {
        description:
          'Create your facilities. https://scribehow.com/shared/Create_your_facilities__n3T4QJqeThWhtG9XpXcbDA',
        category: 'facilities',
        tags: ['Facilities', 'Scope 1', 'Scope 2', 'Scope 3']
      },
      {
        description:
          'Upload the electricity invoices for each month of the year to measure for each facility. https://scribehow.com/shared/Upload_electricity_invoices__6731lF_STMSRI6Ulxy1ELg',
        category: 'invoices',
        tags: ['Invoices', 'Facilities', 'Electricity', 'Scope 2']
      },
      {
        description:
          'Upload the water invoices for each month of the year to measure for each facility. https://scribehow.com/shared/Upload_water_invoices__vGmMj53VRpKEacadp6zLXQ',
        category: 'invoices',
        tags: ['Invoices', 'Facilities', 'Water', 'Scope 3']
      },
      {
        description:
          'Upload the natural gas invoices for each month of the year to measure for each facility. https://scribehow.com/shared/Upload_combustion_invoices__2euJVZFtS92zKt28eKtm-A',
        category: 'invoices',
        tags: ['Invoices', 'Facilities', 'Combustion', 'Scope 1']
      },
      {
        description:
          'Manually upload combustion consumption (Diesel B, butane gas, propane, or similar) for each facility. https://scribehow.com/shared/Upload_combustion_invoices__2euJVZFtS92zKt28eKtm-A',
        category: 'invoices',
        tags: ['Invoices', 'Facilities', 'Combustion', 'Scope 1']
      },
      {
        description:
          'Review and upload (if any) refills on A/C, heating, or cooling equipment for each facility. https://scribehow.com/shared/Refills__hC7n_7UUQa62cl6ExV0uAw',
        category: 'invoices',
        tags: ['Invoices', 'Facilities', 'Refills', 'Scope 1']
      },
      {
        description:
          'Upload waste data for each facility. https://scribehow.com/shared/How_to_upload_waste_data_automatically__sIC-HBjzSqqb5uf4_sIx2A',
        category: 'invoices',
        tags: ['Invoices', 'Facilities', 'Waste', 'Scope 3']
      },
      {
        description: `Check the data of your facility invoices that you are missing to advance in your measurement. ${BASE_URL}measure/facilities`,
        category: 'invoices',
        tags: ['Invoices', 'Facilities']
      },
      {
        description: `Check the data of the vehicle consumption that you are missing to advance in your measurement. ${BASE_URL}measure/vehicles`,
        category: 'invoices',
        tags: ['Invoices', 'Facilities']
      },
      {
        description:
          'Create vehicles manually. https://scribehow.com/shared/How_to_create_company-owned_or_leased_vehicles__zgj1fnDiQQa8CufLTvS6Vw',
        category: 'vehicles',
        tags: ['Vehicles', 'Scope 1']
      },
      {
        description:
          'Create agricultural or industrial vehicles for the company. https://scribehow.com/shared/Guide_How_to_create_an_agricultural_or_industrial_vehicle_and_add_its_consumption__HbnfPmuBR_qgHteLkh9DFA',
        category: 'vehicles',
        tags: ['Vehicles', 'Scope 1']
      },
      {
        description:
          'Create vehicles automatically. https://scribehow.com/shared/How_to_create_vehicles_automatically__2IIdCktxRz2PdA7RwyCHkg',
        category: 'vehicles',
        tags: ['Vehicles', 'Scope 1']
      },
      {
        description:
          'Manually and automatically upload fuel consumption for your vehicles. https://scribehow.com/shared/How_to_upload_vehicle_consumption_manually_and_automatically__N90Rf1Q-Tae3eTOxwZ8zQQ',
        category: 'vehicle_consumptions',
        tags: ['Vehicles', 'Consumption', 'Scope 1']
      },
      {
        description:
          'Define measurement limits: measured facilities, (what is within the measurement and what is not). https://scribehow.com/shared/Send_us_the_MITECO_registration_information__aWksw-E2SXmDMidEo6vRnA',
        category: 'other',
        tags: ['MITECO']
      },
      {
        description:
          'Submit the employee form or create them manually. https://scribehow.com/shared/How_to_submit_the_employee_form_and_fill_out_the_data__fKD5dc9lQ1WccdKQf2mpgA?referrer=documents',
        category: 'employees',
        tags: ['Employees', 'Scope 3']
      },
      {
        description:
          'Complete the employee mobility survey. https://scribehow.com/shared/How_to_fill_out_your_employee_mobility_survey__0rCPPXq-RxCXeINHN2Kl4Q',
        category: 'employees',
        tags: ['Employees', 'Scope 3']
      },
      {
        description:
          'Download the QR for employees without access to email. https://scribehow.com/shared/How_to_download_the_QR_for_employees_without_access_to_email__shjC4L2TTTWwgb1MjPIU-A',
        category: 'employees',
        tags: ['Employees', 'Scope 3']
      },
      {
        description:
          'Download the survey and print it in case employees do not have a mobile phone. https://drive.google.com/file/d/1u-cWTYihANvX-JwxRStuHNMI7cjprj_S/view',
        category: 'employees',
        tags: ['Employees', 'Scope 3']
      },
      {
        description:
          'Deactivate an employee and then reactivate their measurement. https://scribehow.com/shared/How_to_deactivate_an_employee_and_then_reactivate_them__ODDVrUwfTmyRSM9sxwEGZg',
        category: 'employees',
        tags: ['Employees', 'Scope 3']
      },
      {
        description:
          'Automatically add business trips. https://scribehow.com/shared/Add_business_trips_automatically__xCNGPdCBTp6MeYJzJVxmsQ',
        category: 'business_travels',
        tags: ['Business Trips', 'Scope 3']
      },
      {
        description:
          'Manually add business trips. https://scribehow.com/shared/Add_your_business_trips_manually__V-GWXjkcQreHh1zwDuuDPQ',
        category: 'business_travels',
        tags: ['Business Trips', 'Scope 3']
      },
      {
        description:
          'Upload deliveries manually or automatically. https://scribehow.com/shared/How_to_upload_received_and_sent_merchandise_manually_or_automatically__H5mmUALDSzK7Fp00ywuJ1g',
        category: 'transport_upstream',
        tags: ['Deliveries', 'Scope 3']
      },
      {
        description:
          'Upload shipments manually or automatically. https://scribehow.com/shared/How_to_upload_received_and_sent_merchandise_manually_or_automatically__H5mmUALDSzK7Fp00ywuJ1g',
        category: 'transport_downstream',
        tags: ['Shipments', 'Scope 3']
      },
      {
        description:
          'Automatically upload purchase file. https://scribehow.com/shared/Upload_your_purchases_automatically__4fz0eldlQSK3JRs_2VK_pw',
        category: 'purchases',
        tags: ['Purchases', 'Scope 3']
      },
      {
        description:
          'Manually upload purchase file. https://scribehow.com/shared/Manually_upload_your_purchases__fJG4jcu-SamP2bekdZFb0A',
        category: 'purchases',
        tags: ['Purchases', 'Scope 3']
      },
      {
        description:
          'Complete your 24 SBTi criteria and find out if you are accepted. https://scribehow.com/shared/Complete_your_24_SBTi_criteria_and_find_out_if_you_are_accepted__N75obguyTYywnIYvY4fnIg',
        category: 'sbti',
        tags: ['SBTi Objectives']
      },
      {
        description:
          'Develop your SBTi reduction objective in Modeling. https://scribehow.com/shared/Develop_your_SBTi_reduction_objective_in_Modeling__AuLjF0lEQdG8FZwdmCqyUA?referrer=workspace ',
        category: 'sbti',
        tags: ['SBTi Objectives']
      },
      {
        description:
          'Prepare documentation for MITECO. https://scribehow.com/shared/How_to_prepare_your_documentation_for_MITECO__WsukpOltQqa9Sew1wzgTOw',
        category: 'other',
        tags: ['MITECO']
      },
      {
        description:
          "Modify an employee's data when they change address. https://scribehow.com/shared/How_to_modify_your_data_when_you_change_your_address__xJ3SluC-RlaNn-nLdwYnew",
        category: 'employees',
        tags: ['Employees', 'Scope 3']
      },
      {
        description:
          'Measure in Logistics companies under the GLEC framework. https://scribehow.com/shared/How_to_measure_in_Logistics_companies_under_the_GLEC_framework__ZzztbxeHQUmU4-PYIzgB9Q',
        category: 'other',
        tags: ['Logistics', 'GLEC']
      },
      {
        description:
          'Create custom intensity metrics. https://scribehow.com/shared/How_to_create_custom_intensity_metrics__itNZP364QL6F37i_9nC-kQ',
        category: 'other',
        tags: ['Intensity Metrics']
      },
      {
        description:
          'Create custom emission factors. https://scribehow.com/shared/How_to_create_a_custom_emission_factor__En2nFT6QSdSpLq9YNiFvpw',
        category: 'other',
        tags: ['Emission Factors']
      },
      {
        description:
          'Create a custom factor to redeem GdOs. https://scribehow.com/shared/How_to_create_a_custom_factor_to_redeem_GdOs__tWKeicYgRny8_DTeZneVkA',
        category: 'other',
        tags: ['GdOs']
      },
      {
        description:
          'Add avoided emissions from your solar panels. https://scribehow.com/shared/Como_subir_las_emisiones_evitadas_de_tus_placas_solares___A4fbvw4RWGl8OTaPEXCWA',
        category: 'other',
        tags: ['Avoided Emissions']
      },
      {
        description:
          'Fill out your social and governance data. https://scribehow.com/shared/How_to_fill_out_your_social_and_governance_data__oK3zpqPUQOqH7eTmi0J8Ig',
        category: 'other',
        tags: ['Social and Governance']
      },
      {
        description:
          'Connect to Datadis. https://scribehow.com/shared/Datadis__EYTSGo4kR9uIFzbwDB754w',
        category: 'invoices',
        tags: ['Scope 2', 'Electricity']
      },
      {
        description:
          'Invite more members of your team. https://scribehow.com/shared/Invite_more_members_of_your_team__4gJ5CuOUS5aiV4YQePjqNw',
        category: 'other',
        tags: ['Members']
      },
      {
        description:
          'Resend the mobility survey. https://scribehow.com/shared/How_to_send_the_employee_form_to_the_mail_or_create_it_manually__kg6B26yWSrmCoUxJzHSNiA',
        category: 'employees',
        tags: ['Employees', 'Scope 3']
      },
      {
        description:
          'How to split your bill consumption: https://scribehow.com/shared/Aprende_como_dividir_tus_facturas_en_Dcycle__SxO-ojNTTr6TG2vRpd89xQ',
        category: 'invoices',
        tags: [
          'Invoices',
          'Facilities',
          'Electricity',
          'Water',
          'Recharges',
          'Combustion',
          'Scope 1',
          'Scope 2',
          'Scope 3'
        ]
      },
      {
        description:
          'How to update emission factors in Dcycle: https://scribehow.com/shared/Como_actualizar_los_factores_de_emision__-c9sTBaxQaC1rg5X1UetrQ?referrer=workspace',
        category: 'other',
        tags: ['Emission Factors']
      },
      {
        description:
          'Create companies within your holding account: https://scribehow.com/shared/Como_crear_empresas_dentro_de_una_cuenta_holding__B_mATAWARlea0YiwzgVSSw',
        category: 'other',
        tags: ['Holding']
      },
      {
        description:
          'Create and assign tasks to team members: https://scribehow.com/shared/Google_Chrome_Workflow__hy4UOsCZScql1uxxFSgLIA',
        category: 'other',
        tags: ['Tasks']
      },
      {
        description:
          'Link your company with your fund manager: https://scribehow.com/shared/Vincula_tu_empresa_con_tu_gestora_de_fondos__WsMFqLl4TTeyP-v_bNZb8w?referrer=search',
        category: 'other',
        tags: ['Linking']
      }
    ]
  };

  const defaultLanguage = 'en';
  const languageToUse = i18n.resolvedLanguage === 'es' ? 'es' : defaultLanguage;
  const predefinedTasks: PredefinedTask[] = tasksByLanguage[languageToUse].map((task, index) => ({
    ...task,
    id: index,
    assigned_to: {
      id: '',
      name: ''
    },
    created_by: user?.id ?? '',
    organization_id,
    due_date: convertDateFrontToBackTimestamp(convertDateToString(todayPlus7Days)),
    reminder_date: convertDateFrontToBackTimestamp(convertDateToString(todayPlus7Days)),
    selected: false
  }));
  return predefinedTasks;
};

export default usePredefinedTasks;
