import { useState } from 'react';
import Checkbox from '../../../ui/formComponents2/checkbox/Checkbox';
import Icon from '../../../ui/icon/Icon';
import Modal from '../../../ui/modal/Modal';
import Button from '../../../ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useUpdateOrgEfToLatest } from '../hooks/useUpdateOrgEfToLatest';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';

type Props = {
  show: boolean;
  onClose: () => void;
};

export const OutdatedEfModal: React.FC<Props> = ({ show, onClose }) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const { updateOrgEf } = useUpdateOrgEfToLatest();

  const dispatch = useDispatch();

  const handleCheck = () => {
    setChecked((prev) => !prev);
  };

  const handleProceed = () => {
    updateOrgEf();
    dispatch(setNotification(t('measureMain.outdatedEfs.modal.notification')));
    onClose();
  };

  return (
    <Modal.WithPortal show={show} onClose={onClose}>
      <Modal.Header title={t('measureMain.outdatedEfs.modal.title')} />
      <Modal.Content>
        <p className='m-0 max-w-70ch font-body-b2-r mb-4'>
          {t('measureMain.outdatedEfs.modal.paragraph')}
        </p>

        <span className='m-0 px-2 py-1 w-fit-content tag-bg-text-color text-tag font-body-b2-r rounded-16 flex items-center gap-x-1 mb-4'>
          <Icon icon='information' color='blocked' size='medium' />
          {t('measureMain.outdatedEfs.modal.disclaimer')}
        </span>

        <ul className='flex-col gap-y-2 pl-4 mt-0 mb-8'>
          <li className='m-0 font-body-b2-r'>
            {t('measureMain.outdatedEfs.modal.bulletPoints.1')}
          </li>
          <li className='m-0 font-body-b2-r'>
            {t('measureMain.outdatedEfs.modal.bulletPoints.3')}
          </li>
        </ul>

        <Checkbox
          text={t('measureMain.outdatedEfs.modal.checkboxText')}
          selected={checked}
          onChange={handleCheck}
        />
      </Modal.Content>
      <div className='flex gap-x-3 mt-10'>
        <Button
          lookAndFeel='secondary'
          text={t('measureMain.outdatedEfs.modal.cancel')}
          size='small'
          onClick={onClose}
        />
        <Button
          lookAndFeel='primary'
          text={t('measureMain.outdatedEfs.modal.proceed')}
          onClick={handleProceed}
          disabled={!checked}
          size='small'
        />
      </div>
    </Modal.WithPortal>
  );
};
