import { AccordionItemType } from './AccordionItem';

type AccordionContextType = {
  setActive: (active: boolean) => void;
  imgSrc: JSX.Element;
  active?: boolean;
  closeFromDown?: boolean;
} & Pick<AccordionItemType, 'label' | 'iconLeft' | 'highlight'>;

const AccordionHandler = ({
  label,
  iconLeft,
  highlight,
  active,
  setActive,
  imgSrc
}: AccordionContextType) => {
  return (
    <div
      className={`filters_section-h5 label ${
        (highlight ? 'highlight-text-color' : 'on-light-text-color') || ''
      } ${iconLeft && 'justify-start'}`}
      onClick={() => setActive(!active)}>
      {iconLeft && imgSrc}
      {label}
      {!iconLeft && imgSrc}
    </div>
  );
};

export default AccordionHandler;
