import { useEffect } from 'react';
import './styles.scss';

type Props = {
  url: string;
};
const CalendlyMeeting = ({ url }: Props) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    if (head) {
      head.appendChild(script);
    }
  }, []);
  return (
    <section className='schedule-form'>
      <div className='calendly-inline-widget' data-url={url} />
    </section>
  );
};

export default CalendlyMeeting;
