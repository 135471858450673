import apiFetch from '../../../../../utils/apiFetch';
import { adaptMethodologies } from '../adapters/adaptMethodologies';
import type { FrontLCAMethodology } from '../types/LCADetail';

const URL = '/acv/methodologies';

export const getMethodologies = async (acvId?: string): Promise<FrontLCAMethodology[]> => {
  if (!acvId) {
    return [];
  }

  const response = await apiFetch('GET', `${URL}/${acvId}`);

  return adaptMethodologies(response.data?.items);
};
