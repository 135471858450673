import SectionHeader from '../layout/sectionHeader/SectionHeader';
import { useLCATranslation } from '../../customHooks/translations/useLCATranslation';
import { Card } from '../layout/Cards/Cards';
import { useLCALanding } from './hooks/useLCALanding';
import { LCA_LANDING_ICONS } from './constants';
import LoaderTables from '../ui/loaders/loaderTables/LoaderTables';

export const LCA = () => {
  const { t } = useLCATranslation();

  const { data, isLoading } = useLCALanding();

  if (isLoading) {
    return (
      <section className='flex-col gap-y-6'>
        <SectionHeader title={t('landing.sectionHeader')} />
        <LoaderTables />
      </section>
    );
  }

  return (
    <section className='flex-col gap-y-6'>
      <SectionHeader title={t('landing.sectionHeader')} />
      <Card.Container>
        {data?.map((lca) => (
          <Card.Link to={lca.type} key={lca.type}>
            <Card.Layout>
              <Card.Icon icon={LCA_LANDING_ICONS[lca.type]} />
              <Card.Title text={t(`landing.title.${lca.type}`)} />
              <Card.Subtitle text={t(`landing.subtitle.${lca.type}`)} />
              <div className='flex gap-x-1 items-center'>
                <Card.Data text={lca.value.toString()} />
                <Card.Unit text={t(`landing.unit.${lca.type}`)} />
              </div>
            </Card.Layout>
          </Card.Link>
        ))}
      </Card.Container>
    </section>
  );
};
