import { Invite, User } from '../../types/entities/user';
import apiFetch from '../../utils/apiFetch';

export const deleteInvite = async (email: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'DELETE',
      `/invite_users/email/${email}/organization/${organization_id}`,
      null,
      {
        'x-organization-id': organization_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getInvites = async () => {
  try {
    const response = await apiFetch('GET', '/invite_user/organization');
    return response.data;
  } catch (err) {
    return null;
  }
};

type GetUserInvitesParams = {
  user: User | null;
};

export const getUserInvites = async ({ user }: GetUserInvitesParams): Promise<Invite[]> => {
  if (!user) {
    throw new Error('No user provided');
  }

  const response = await apiFetch('get', `/invite_user/${user?.email}/holding`, null, {
    'x-organization-id': user?.selectedOrganization
  });

  return response.data;
};
