import React, { useState } from 'react';
import useData from './useData';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import Button from '../../../ui/button/Button';
import InfiniteList from '../../../ui/infiniteList/InfiniteList';
import { ROUTES } from '../../../../constants/routes';
import Modal from '../../../ui/modal/Modal';
import AddElements from './addElements/AddElements';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import DeleteElement from './deleteElement/DeleteElement';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { switchGrouping } from '../../../../actions/auth';

const GroupingDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const selectedOrganization = useSelectedOrganization();

  const groupingDetailData = useData(id ?? '');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showAddElements, setShowAddElements] = useState(false);
  const [entities, setEntities] = useState<any[]>([]); // IN FUTURE: change to [Entity]
  const [total, setTotal] = useState(0);

  const [entityToDelete, setEntityToDelete] = useState('');

  const renderDropdownComponent = (elem: any) => {
    const options = [
      {
        id: `${elem.entity_id}-delete`,
        name: t('groupingDetail.delete'),
        onClick: () => {
          setEntityToDelete(elem.entity_id);
        }
      }
    ];
    return (
      <ButtonDropdown
        button={<img src='/images/icons/editPen.svg' height={19} width={18} alt='edit-pen' />}
        options={options}
      />
    );
  };

  const renderIcon = (type: string) => {
    if (type === 'facilities') {
      return '/images/icons/buildingGray.svg';
    }
    if (type === 'vehicles') {
      return '/images/icons/carGray.svg';
    }
    return '';
  };
  const parseData = (data: any) => {
    return data.map((item: any) => {
      return {
        key: item.entity_id,
        organization: item.organization_name,
        entity_name: (
          <div className='icon-text-wrapper'>
            <img src={renderIcon(item.entity_type)} alt='paper-gray' />
            {item.entity_name}
          </div>
        ),
        category: t(`groupingDetail.form.${item.entity_type}`),
        edit: renderDropdownComponent(item)
      };
    });
  };

  const navigateToDashboard = () => {
    dispatch(switchGrouping(id ?? ''));
    navigate(ROUTES.IMPROVE_DASHBOARD);
  };

  const handleAddElements = (elements: any[]) => {
    dispatch(setNotification(t('notification.addGroupingElement')));

    setEntities([...entities, ...elements]);
    setShowAddElements(false);
  };

  const handleDeleteElement = (id: string) => {
    dispatch(setNotification(t('notification.deleteGroupingElement')));
    setEntities(entities.filter((elem) => elem.entity_id !== id));
    setEntityToDelete('');
  };

  const columns = [
    // {
    //   title: t('groupingDetail.organization'),
    //   dataIndex: 'organization',
    //   key: 'organization'
    // },
    {
      title: t('groupingDetail.entityName'),
      dataIndex: 'entity_name',
      key: 'entity_name'
    },
    {
      title: t('groupingDetail.category'),
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
  if (!id) return null;

  return (
    <MainPageLayout
      sectionTitle={t('groupingDetail.title')}
      title={t('groupingDetail.start')}
      description={t('groupingDetail.startDescription')}
      breadcrumb={
        <Breadcrumb
          crumbsReplace={id ? [{ key: id, value: groupingDetailData?.name || '' }] : []}
        />
      }
      buttons={
        <>
          <Button
            lookAndFeel={'primary'}
            text={t('groupingDetail.addElements')}
            size='small'
            onClick={() => setShowAddElements(true)}
          />
          <Button
            lookAndFeel='secondary'
            text={t('groupingDetail.goToDashboard')}
            size='small'
            onClick={navigateToDashboard}
          />
        </>
      }>
      <InfiniteList
        i18key={'groupingDetail'}
        url={`/data_groups/impacts/${id}`}
        values={entities}
        setValues={setEntities}
        columns={columns}
        parseData={parseData}
        total={total}
        setTotal={setTotal}
        organization={selectedOrganization?.id ?? ''}
      />
      <Modal
        show={showAddElements}
        onClose={() => setShowAddElements(false)}
        width='500px'
        maxWidth='500px'>
        <AddElements
          id={id}
          handleAddElements={handleAddElements}
          entitiesAlreadyAdded={entities}
        />
      </Modal>
      <Modal
        show={!!entityToDelete}
        onClose={() => setEntityToDelete('')}
        width='500px'
        maxWidth='500px'>
        <DeleteElement
          id={entityToDelete}
          dataGroupId={id}
          handleDeleteElement={handleDeleteElement}
        />
      </Modal>
    </MainPageLayout>
  );
};

export default GroupingDetail;
