import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { useState } from 'react';
import { CATEGORIES } from './constants';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormCalendarDoubleSelect from '../../../../ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';
import Icon from '../../../../ui/icon/Icon';
import Button from '../../../../ui/button/Button';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';

type FormData = {
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};

const SelectScope: React.FC = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    startDate: '',
    endDate: '',
    errors: []
  });

  const [category, setCategory] = useState<SelectOptionFormat<string>>({ id: '', name: '' });
  const [co2e, setCo2e] = useState<string>('0');

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const options = CATEGORIES.map((category) => ({
    id: category,
    name: t(`categories.${category}`)
  }));

  const handleSetCategory = (value: SelectOptionFormat<string>) => {
    setCategory(value);
  };

  return (
    <>
      <div className='grid grid-cols-4 font-body-b2-sb gap-y-4 gap-x-6'>
        <span className='mb-0'>Categoría de alcances</span>
        <span className='mb-0 col-span-2'>Fecha de inicio - Fecha de fin</span>
        <span className='mb-0'>Cantidad t CO₂ eq.</span>
        <FormSelect
          options={options}
          value={category}
          onChange={handleSetCategory}
          iconV2='dropdown'
        />
        <div className='col-span-2'>
          <FormCalendarDoubleSelect
            startDate={formData.startDate}
            endDate={formData.endDate}
            handleChangeStartDate={onChangeValue('startDate')}
            handleChangeEndDate={onChangeValue('endDate')}
          />
        </div>
        <div className='flex gap-x-6 items-center'>
          <FormNumber onChange={setCo2e} value={co2e} />
          <Icon
            icon='trash'
            color='gray-dark'
            size='medium'
            className='pointer'
            onClick={(event) => {
              event.preventDefault();
              console.log('delete');
            }}
          />
        </div>
        <Button
          lookAndFeel='link-dark'
          text='Añadir nuevo impacto total'
          className='flex items-center gap-x-1 py-2 mb-10 pointer'
          size='small'
          iconNode={<Icon icon='add' size='medium' color='gray-dark' />}
          onClick={() => console.log('add')}
        />
      </div>
    </>
  );
};

export const ImportEmissionsModalPages = {
  SelectScope
};
