import { useFeatureFlags } from '../../../../../../../../../../customHooks/useFeatureFlags';
import { useTranslation } from 'react-i18next';
import InputWrapper from '../../../../../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import { InputSize } from '../../../../../../../../../../types/utilsEnums/input';
import Select from '../../../../../../../../../ui/formComponents2/select/Select';
import InputNumber from '../../../../../../../../../ui/formComponents2/inputNumber/InputNumber';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import InputHeaderWrapper from '../../../../../../../../../ui/formComponents2/inputUtils/inputHeaderWrapper/InputHeaderWrapper';
import numberToDecimal from '../../../../../../../../../../utils/numberToDecimal';
import { Percentages } from '../../../../../../../../../../types/entities/invoice';
import useGetFacilitiesByOrg from '../../../../hooks/useGetFacilitiesByOrg';
import { UserContext } from '../../../../../../../../../../context/userContext';
import TooltipWrapper from '../../../../../../../../../ui/tooltip/TooltipWrapper';
import Button from '../../../../../../../../../ui/button/Button';
import Icon from '../../../../../../../../../ui/icon/Icon';
import useSelectedOrganization from '../../../../../../../../../../customHooks/useSelectedOrganization';

interface Props {
  value: Percentages;
  formData: {
    quantity?: string;
    unit?: SelectOptionFormat;
    errors: ErrorType[];
    percentages: Percentages[];
  };
  setFormData: Dispatch<SetStateAction<any>>;
  organizationOptions: SelectOptionFormat[];
  index: number;
  units: SelectOptionFormat[];
}
const DivideConsumption = ({
  formData,
  value,
  setFormData,
  organizationOptions,
  index,
  units
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'facilityDetail' });
  const { t: tUnits } = useTranslation('translation', { keyPrefix: 'units_short' });
  const flags = useFeatureFlags();

  const user = useContext(UserContext);

  const foundOrg = user?.organizations?.find((org) => org.id === value.organization.id);

  const selectedOrganization = useSelectedOrganization();

  let disabled = false;

  if (
    foundOrg &&
    foundOrg.id !== user?.selectedOrganization &&
    selectedOrganization?.role !== 'owner' &&
    selectedOrganization?.role !== 'parent'
  ) {
    disabled = true;
  }

  if (!foundOrg) {
    disabled = true;
  }

  if (!value.organization.id) {
    disabled = false;
  }

  const { facilityOptions, loading } = useGetFacilitiesByOrg({
    organizationId: disabled ? '' : value.organization.id
  });

  if (!flags?.consumptionDivision) return null;

  const onChangeOrganization = (value: SelectOptionFormat) => {
    const newPercentages = [...formData.percentages];
    newPercentages[index].organization = value;
    newPercentages[index].facility = { id: '', name: '' };
    setFormData((prev: any) => ({ ...prev, percentages: newPercentages }));
  };

  const onChangeFacility = (value: SelectOptionFormat) => {
    const newPercentages = [...formData.percentages];
    newPercentages[index].facility = value;
    setFormData((prev: any) => ({
      ...prev,
      percentages: newPercentages,
      errors: prev.errors.filter((error: ErrorType) => error.error !== 'percentages_facility')
    }));
  };

  const onChangePercentage = (value: string) => {
    const newPercentages = [...formData.percentages];
    newPercentages[index].percentage = value ? value : '';
    setFormData((prev: any) => ({
      ...prev,
      percentages: newPercentages,
      errors: prev.errors.filter((error: ErrorType) => error.error !== 'percentages_facility')
    }));
  };

  const checkError = () => {
    const foundError = formData.errors.find((error) => error.error === 'percentages_facility');

    if (!Number(value.percentage) && value.facility.id && foundError) {
      return foundError;
    }

    // check if in percentages there is a percentage without facility id
    if (!value.facility.id && foundError) {
      return foundError;
    }
  };

  const handleRemove = (index: number) => {
    const newPercentages = formData.percentages.filter((elem, i) => i !== index);
    setFormData((prev: any) => ({ ...prev, percentages: newPercentages }));
  };

  const foundUnit = units.find((unit) => unit.id === formData.unit?.id);

  const filteredFacilityOptions = facilityOptions.filter(
    (elem) => !formData.percentages.some((percentage) => percentage.facility.id === elem.id)
  );

  // Check if it is the only division with more than 0%
  const checkIfOnlyDivision = formData.percentages.every(
    (elem) => parseFloat(elem.percentage) === 0 || elem.facility.id === value.facility.id
  );

  return (
    <div className={`flex gap-2 ${checkError() ? 'items-start' : 'items-end'}`} key={index}>
      <InputWrapper
        iconV2='business'
        label={index === 0 ? t('companyAndFacility') : ''}
        size={InputSize.MEDIUM}
        error={checkError()}
        disabled={disabled}>
        <Select
          value={value.organization}
          onChange={onChangeOrganization}
          options={organizationOptions}
          disabled={organizationOptions.length === 1 || disabled}
        />
        <Select
          value={value.facility}
          onChange={onChangeFacility}
          options={filteredFacilityOptions}
          disabled={value.organization.id === '' || loading || disabled}
          loading={loading}
        />
        <InputHeaderWrapper
          label={index === 0 ? t('consumptionDivisionLabel') : ''}
          tooltip={index === 0 ? t('consumptionDivisionTooltip') : ''}>
          <InputNumber
            onChange={onChangePercentage}
            value={value.percentage}
            max={100}
            min={0}
            placeholder={t('consumptionDivisionPlaceholder')}
            disabled={disabled}
          />
          {formData.quantity ? (
            <span
              className='disabled-text-color font-12'
              style={{ paddingRight: '0.5rem', whiteSpace: 'nowrap' }}>
              {`(${numberToDecimal((+formData?.quantity * parseFloat(value.percentage)) / 100)} ${
                foundUnit ? tUnits(`${foundUnit?.name}`) : ''
              })`}
            </span>
          ) : (
            <></>
          )}
        </InputHeaderWrapper>
      </InputWrapper>

      <TooltipWrapper
        text={t('removeDivisionTooltip')}
        shouldAppear={checkIfOnlyDivision}
        style={{ alignSelf: 'auto' }}>
        <Button
          lookAndFeel={'secondary'}
          iconNode={<Icon icon='close' color='gray-dark' />}
          onClick={() => handleRemove(index)}
          style={{ width: 'fit-content' }}
          size='small'
          disabled={checkIfOnlyDivision || disabled}
        />
      </TooltipWrapper>
    </div>
  );
};

export default DivideConsumption;
