import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { EventFood, PreEventFormData } from '../../../../../../types/entities/events';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputBreakfast from './InputBreakfast';
import InputFood from './InputFood';

type Props = {
  setFormData: Dispatch<SetStateAction<PreEventFormData>>;
  formData: EventFood[];
};
const Food = ({ setFormData, formData }: Props) => {
  const { t } = useTranslation();

  const onChangeBreakfast = (value: string) => {
    const newFormData = [...formData];

    // find index of element with type === 'breakfast'
    const foundIndex = newFormData.findIndex((element) => element.type === 'breakfast');
    // if element is found, change its value
    if (foundIndex !== -1) {
      newFormData[foundIndex].type = 'breakfast';
      newFormData[foundIndex].dish = 'unique';
      newFormData[foundIndex].quantity = +value;
      setFormData((prev) => ({ ...prev, food: newFormData }));
      return;
    }
    // if element is not found, add it to the array
    newFormData.push({
      type: 'breakfast',
      dish: 'unique',
      quantity: +value
    });

    setFormData((prev) => ({ ...prev, food: newFormData }));
  };

  const onChangeFood = (value: EventFood) => {
    const newFormData = [...formData];

    // find index of element with value.type
    const foundIndex = newFormData.findIndex(
      (element) => element.type === value.type && element.dish === value.dish
    );
    // if element is found, change its value
    if (foundIndex !== -1) {
      newFormData[foundIndex].type = value.type;
      newFormData[foundIndex].dish = value.dish;
      newFormData[foundIndex].quantity = +value.quantity;
      setFormData((prev) => ({ ...prev, food: newFormData }));
      return;
    }

    // if element is not found, add it to the array
    newFormData.push({
      type: value.type,
      dish: value.dish,
      quantity: +value.quantity
    });

    setFormData((prev) => ({ ...prev, food: newFormData }));
  };

  const foundBreakfast = formData.find((elem) => elem.type === 'breakfast');
  return (
    <div className='pre-event-food'>
      <FormWrapper>
        <InputBreakfast
          onChangeValue={onChangeBreakfast}
          value={
            foundBreakfast && foundBreakfast.quantity ? foundBreakfast.quantity.toString() : ''
          }
        />
        <InputFood
          icon={'/images/icons/burger.svg'}
          onChangeValue={onChangeFood}
          data={formData}
          type={'lunch'}
        />
        <InputFood
          icon={'/images/icons/sushi.svg'}
          onChangeValue={onChangeFood}
          data={formData}
          type={'dinner'}
        />
      </FormWrapper>
    </div>
  );
};

export default Food;
