import FormYesNoRadio from '../../../../../../../ui/formComponents2/formInputs/formYesNoRadio/FormYesNoRadio';
import { useTranslation } from 'react-i18next';
import { ResponseMetric } from '../NarrativeModal';
import FormElementShort from '../../../../../../../ui/formComponents/formElementShort/FormElementShort';

type Props = {
  name: string;
  response: ResponseMetric;
  setResponse: (response: ResponseMetric) => void;
};
const SocialFactory = ({ name, response, setResponse }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: `controlPanel.metrics.narrativeModal.metrics.social.${name}`
  });

  if (
    name === 'text_org_all_sectors_global_compact_monitoring_protocol' ||
    name === 'text_org_all_sectors_global_compact_violated'
  ) {
    return (
      <FormElementShort>
        <FormYesNoRadio
          label={t('label')}
          value={(response as SelectOptionFormat) ?? { id: '', name: '' }}
          onChange={(value: SelectOptionFormat) => {
            setResponse(value);
          }}
          tooltip={t('tooltip')}
        />
      </FormElementShort>
    );
  }
  return <></>;
};

export default SocialFactory;
