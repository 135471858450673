import { getEmployees } from '../../../../../services/api/employees';
import { Employee } from '../../../../../types/entities/employee';
import { IFilter, ISorter } from '../../../../../utils/url';
import useFetchInfiniteList from '../../../../ui/infiniteList/hooks/useFetchInfiniteList';

type Params = {
  filters?: IFilter[];
  sorters?: ISorter[];
};
const makeFetch =
  ({ filters, sorters }: Params) =>
  async (page: number) => {
    const queryParams = [
      {
        key: 'page',
        value: page
      }
    ];
    const response = await getEmployees({ queryParams, filters, sorters });

    return response;
  };

export const useGetEmployees = ({ filters, sorters }: Params) => {
  const { rest, ...response } = useFetchInfiniteList<Employee, any>(
    makeFetch({ filters, sorters }),
    [JSON.stringify(filters), JSON.stringify(sorters)]
  );

  return {
    ...response,
    total2: rest?.total2,
    totalTerminated: rest?.total_terminated
  };
};
