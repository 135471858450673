import { Dispatch, SetStateAction, useState } from 'react';
import { Facility } from '../../../../../../types/entities/facility';
import { InvoiceAuto } from '../../../../../../types/entities/invoice';
import SelectInvoiceType from '../createInvoice/components/common/selectInvoiceType/SelectInvoiceType';
import './styles.scss';
import UploadInvoicesUsual from './uploadInvoicesUsual/UploadInvoiceUsual';

type Props = {
  setInvoiceMode?: Dispatch<SetStateAction<string>>;
  setTotalPending?: Dispatch<SetStateAction<number>>;
  addInvoice: (invoicesUploaded: InvoiceAuto[]) => void;
  setTotal?: Dispatch<SetStateAction<number>>;
  facility: Facility;
  defaultType?: string;
};

const UploadInvoices = ({
  addInvoice,
  setInvoiceMode,
  setTotalPending,
  setTotal,
  facility,
  defaultType
}: Props) => {
  const [type, setType] = useState(facility.categories.length === 1 ? facility.categories[0] : '');

  const selectInvoiceType = (
    <SelectInvoiceType
      mode={'auto'}
      action={'create'}
      onClickCard={(id) => setType(id)}
      type={type}
      facilityType={facility.facility_purpose_type}
      facilityCategories={facility.categories}
    />
  );

  return (
    <UploadInvoicesUsual
      facility={facility}
      addInvoice={addInvoice}
      type={type}
      selectInvoiceType={selectInvoiceType}
      setInvoiceMode={setInvoiceMode}
      setTotalPending={setTotalPending}
      setTotal={setTotal}
    />
  );
};

export default UploadInvoices;
