import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { getPAIReport } from '../../../../services/api/funds';
import checkFormErrors from '../../../../utils/checkFormErrors';
import ModalNoFunds from '../../../measure/funds/components/fundDetail/modalNoFunds/ModalNoFunds';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import useGetData from './hooks/useGetData';
import './styles.scss';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import ReportRequested from '../../../measure/funds/components/fundDetail/reportRequested/ReportRequested';
import ErrorText from '../../../ui/errorText/ErrorText';
import Icon from '../../../ui/icon/Icon';
import Label from '../../../ui/label/Label';
import CustomSkeletonLoader from '../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
type Props = {
  closeModal: () => void;
  fundDefault?: SelectOptionFormat;
};

type FormDataType = {
  year: SelectOptionFormat;
  fund: SelectOptionFormat;
  errors: ErrorType[];
};

function PaiReportDataForm({ closeModal, fundDefault }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);

  const minYear = 2015;
  const maxYear = today.getFullYear() - 1;

  const years = [];
  for (let i = maxYear; i >= minYear; i--) {
    years.push({ id: i.toString(), name: i.toString() });
  }

  const [formData, setFormData] = useState<FormDataType>({
    year: {
      id: maxYear.toString(),
      name: maxYear.toString()
    },
    fund: fundDefault ?? {
      id: '',
      name: ''
    },
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const { funds, loading } = useGetData();

  const [loadingButton, setLoadingButton] = useState(false);
  const [showReportRequested, setShowReportRequested] = useState(false);

  const handleGeneratePaiReport = async () => {
    if (!user?.selectedOrganization) return;

    const newErrors: ErrorType[] = checkFormErrors(formData, [], []);

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    setLoadingButton(true);
    const response = await getPAIReport(formData.fund.id, formData.year.id);
    setLoadingButton(false);

    if (response.error === 'RETRY_LATER') {
      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: 'general',
          description: t('error.retryLater')
        })
      }));
      return;
    }

    if (response.error === 'EMPTY_FUND_FOR_THIS_YEAR') {
      setFormData((prev) => ({
        ...prev,
        errors: prev.errors.concat({
          error: 'general',
          description: t('error.emptyFundForThisYear')
        })
      }));
      return;
    }

    setShowReportRequested(true);
  };

  if (funds.length === 0 && !loading) return <ModalNoFunds closeModal={closeModal} />;

  if (showReportRequested && !loading) {
    return <ReportRequested closeModal={closeModal} />;
  }

  const generalError = formData.errors.find((error) => error.error === 'general');
  return (
    <div className='pai-organization-description-form'>
      <FormHeader
        title={t(`orgDescriptionModal.paiReport.completeData`)}
        description={t(`orgDescriptionModal.paiReport.completeDataDescription`)}
      />
      {loading ? (
        <CustomSkeletonLoader count={2} />
      ) : (
        <>
          <FormWrapper>
            <FormElementFull>
              <FormSelect
                icon='/images/icons/calendar.svg'
                placeholder={t('orgDescriptionModal.selectOption')}
                label={t('orgDescriptionModal.paiReport.fund')}
                options={funds}
                value={formData.fund}
                onChange={onChangeValue('fund')}
                error={formData.errors.find((elem) => elem.error === 'fund')}
                sort={false}
              />
            </FormElementFull>
            <FormElementFull>
              <FormSelect
                icon='/images/icons/calendar.svg'
                placeholder={t('orgDescriptionModal.selectOption')}
                label={t('orgDescriptionModal.paiReport.year')}
                options={years}
                value={formData.year}
                onChange={onChangeValue('year')}
                error={formData.errors.find((elem) => elem.error === 'year')}
                sort={false}
              />
            </FormElementFull>
            <Label lookAndFeel='secondary'>
              <div className='flex gap-2 items-center'>
                <Icon icon='information' color='pending' />
                <span className='font-12 weight-400'>
                  {t('orgDescriptionModal.paiReport.disclaimer')}
                </span>
              </div>
            </Label>
          </FormWrapper>

          <FormButtonSection>
            <Button
              lookAndFeel={'primary'}
              iconNode={<Icon icon='download' color='white' />}
              onClick={handleGeneratePaiReport}
              text={t(`orgDescriptionModal.paiReport.saveAndGenerateReport`)}
              size='medium'
              loading={loadingButton}
            />
          </FormButtonSection>
          {generalError && <ErrorText>{generalError?.description}</ErrorText>}
        </>
      )}
    </div>
  );
}

export default PaiReportDataForm;
