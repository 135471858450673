import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import getPricePerTon from '../../../../constants/getPricePerTon';
import {
  EventAttendee,
  EventDrink,
  EventEmployee,
  EventFood,
  EventMiscellanous,
  EventType,
  PreEventBody,
  PreEventTableData
} from '../../../../types/entities/events';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import Modal from '../../../ui/modal/Modal';
import TableComponent from '../../../ui/table/Table';
import PreEventData from './preEventData/PreEventData';
import './styles.scss';

import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { ROUTES } from '../../../../constants/routes';
import { UserContext } from '../../../../context/userContext';
import { requestReport } from '../../../../services/api/events';
import { createCheckoutSession } from '../../../../services/api/payment';
import OrderPlaced from '../../../improve/offset/OrderPlaced';
import OffsetSummary from '../../../improve/offset/offsetSummary/OffsetSummary';
import EditInvoicingData from '../../../invoicing/editInvoicingData/EditInvoicingData';
import Icon from '../../../ui/icon/Icon';
import Label from '../../../ui/label/Label';
import DeleteEventData from './deleteEventData/DeleteEventData';
import useGetData from './useGetData';

type EventToDelete = {
  id: string;
  type: string;
};
const EventDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useContext(UserContext);

  const foundOrganization = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [showPreEvent, setShowPreEvent] = useState(false);

  const [eventToDelete, setEventToDelete] = useState<EventToDelete>({
    id: '',
    type: ''
  });

  const { eventDetailData, preEventData, loading } = useGetData({ id: id ?? '' });

  const [preEvent, setPreEvent] = useState<PreEventBody>();
  const [eventDetail, setEventDetail] = useState<EventType>();

  const [quantityToOffset, setQuantityToOffset] = useState({
    quantity: 0,
    type: '',
    show: false
  });
  const [showEditInvoicingData, setShowEditInvoicingData] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (preEventData) {
      setPreEvent(preEventData);
    }
    if (eventDetailData) {
      setEventDetail(eventDetailData);
    }
  }, [preEventData, eventDetailData]);

  useEffect(() => {
    if (searchParams.get('success')) {
      try {
        setOrderPlaced(true);
        searchParams.delete('success');
        setSearchParams(searchParams);
      } catch (error) {
        console.error(error);
      }
    }

    if (searchParams.get('canceled')) {
      console.error(searchParams.get('canceled'));
      searchParams.delete('canceled');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleHideModal = () => {
    setShowPreEvent(false);
    setEventToDelete({
      id: '',
      type: ''
    });
    setQuantityToOffset({
      quantity: 0,
      type: '',
      show: false
    });
    setOrderPlaced(false);
  };

  const handleShowPreEvent = () => {
    setShowPreEvent(true);
  };

  const renderDropdownComponent = (event: PreEventTableData) => {
    return (
      <ButtonDropdown
        button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
        options={[
          {
            id: `${event.id}-edit`,
            name: t('eventDetail.edit'),
            onClick: () => {
              setShowPreEvent(true);
            }
          },
          {
            id: `${event.id}-delete`,
            name: t('eventDetail.delete'),
            onClick: () => {
              setEventToDelete({ id: id ?? '', type: event.status });
            }
          },
          {
            id: `${event.id}-request-report`,
            name: t('eventDetail.requestReport'),
            onClick: async () => {
              // Call api endpoint
              await requestReport(id ?? '', event.status, user?.selectedOrganization ?? '');
              dispatch(setNotification(t('notification.requestReportSuccess')));
            }
          }
        ]}
      />
    );
  };

  const getSitesInfo = () => {
    const sitesInfo = {
      area: '0',
      hours: '0'
    };
    if (preEvent?.sites && preEvent.sites.length > 0) {
      sitesInfo.area = preEvent.sites[0].area ?? '0';
      sitesInfo.hours = preEvent.sites[0].hours ?? '0';
    }
    return sitesInfo;
  };

  const parseData = () => {
    const { area, hours } = getSitesInfo();
    const preEventParsed = {
      id: 'pre-event',
      area,
      hours,
      employees:
        preEvent?.employees?.reduce((acc: number, curr: EventEmployee) => acc + curr.quantity, 0) ??
        0,
      attendees:
        preEvent?.attendees?.reduce((acc: number, curr: EventAttendee) => acc + curr.quantity, 0) ??
        0,
      food: preEvent?.food?.reduce((acc: number, curr: EventFood) => acc + curr.quantity, 0) ?? 0,
      drinks:
        preEvent?.drinks?.reduce((acc: number, curr: EventDrink) => acc + curr.quantity, 0) ?? 0,
      purchases:
        preEvent?.miscellaneous?.reduce(
          (acc: number, curr: EventMiscellanous) => acc + curr.quantity,
          0
        ) ?? 0,
      co2e: eventDetail?.co2e_pre ?? 0,
      offset: eventDetail?.co2e_pre ?? 0,
      status: 'pre-event'
    };

    const events = [preEventParsed];

    return events.map((event) => {
      const pricePerTon = getPricePerTon(event.offset / 1000);
      return {
        ...event,
        area: (
          <div className='icon-text-wrapper'>
            <img src='/images/icons/pinGray.svg' alt='pin' />
            <span>{`${event.area} m2`}</span>
          </div>
        ),
        hours: `${event.hours} h`,
        employees: event.employees,
        attendees: event.attendees,
        food: `${event.food} ud.`,
        drinks: `${event.drinks} ud.`,
        purchases: `${event.purchases} kg`,
        co2e:
          event.co2e >= 1000
            ? `${numberToDecimal(event.co2e / 1000)} tons`
            : `${numberToDecimal(event.co2e)} kg`,
        offset: `${numberToDecimal((event.offset / 1000) * pricePerTon)}€ (${numberToDecimal(
          pricePerTon
        )} €/ton CO₂ eq.)`,
        status: (
          <Label>
            <span>{t(`eventDetail.${event.status}`)}</span>
          </Label>
        ),
        key: event.id,
        edit: renderDropdownComponent(event)
      };
    });
  };

  const removeEvent = (type: string) => {
    if (type === 'pre-event') {
      setPreEvent({
        employees: [],
        attendees: [],
        food: [],
        drinks: [],
        miscellaneous: [],
        sites: []
      });
      setEventDetail((prev) =>
        prev
          ? {
              ...prev,
              co2e_pre: 0
            }
          : undefined
      );
      handleHideModal();
    }

    dispatch(setNotification(t('notification.eventDataDeleted')));
  };

  const updatePreEvent = (value: PreEventBody, co2e: number, hide: boolean) => {
    setPreEvent(value);
    setEventDetail((prev) =>
      prev
        ? {
            ...prev,
            co2e_pre: co2e
          }
        : undefined
    );
    if (hide) {
      handleHideModal();
    }
  };

  const handleSetOffsetQuantity = () => {
    if (eventDetail?.co2e_pre) {
      setQuantityToOffset({ quantity: eventDetail.co2e_pre, type: 'offset-pre-event', show: true });
    }
  };

  const handleSetNegativizeQuantity = () => {
    if (eventDetail?.co2e_pre) {
      setQuantityToOffset({
        quantity: eventDetail.co2e_pre * 2,
        type: 'negativize-pre-event',
        show: true
      });
    }
  };

  const handleSubmitOffset = async () => {
    if (!user || !quantityToOffset.quantity || !eventDetail?.start_date || !eventDetail?.end_date)
      return;
    //Call api endpoint to create Stripe session
    try {
      setLoadingButton(true);
      const data = await createCheckoutSession({
        payment_type: 'offset',
        payment_entity: quantityToOffset.type.includes('pre-event') ? 'pre_event' : 'post_event',
        price:
          quantityToOffset.quantity / 1000 < 10
            ? (getPricePerTon(quantityToOffset.quantity / 1000) *
                (quantityToOffset.quantity / 1000) +
                200) *
              100
            : getPricePerTon(quantityToOffset.quantity / 1000) *
              (quantityToOffset.quantity / 1000) *
              100,
        product_name: t('offset.generateCleanCertifiedEnergy'),
        email: user.email,
        organization_id: user?.selectedOrganization ?? '',
        product_description: t('offset.ods13'),
        offset: quantityToOffset.quantity,
        offset_type: quantityToOffset.type.includes('offset') ? 1 : 2,
        scopes: [1, 2, 3]
        // start_date: moment(convertStringToDate(eventDetail?.start_date)).format('YYYY-MM-DD'),
        // end_date: moment(convertStringToDate(eventDetail?.end_date)).format('YYYY-MM-DD')
      });
      setLoadingButton(false);
      window.location.href = data.url;
    } catch (err) {
      setLoadingButton(false);
      console.error(err);
    }
  };

  const onShowEditInvoicingDataModal = () => {
    if (!foundOrganization) return;

    if (
      foundOrganization.vat &&
      foundOrganization.invoicing_country &&
      foundOrganization.invoicing_street_address &&
      foundOrganization.invoicing_province &&
      foundOrganization.invoicing_postal_code &&
      foundOrganization.invoicing_trade_name
    ) {
      handleSubmitOffset();
    } else {
      setShowEditInvoicingData(true);
      setQuantityToOffset((prev) => ({
        ...prev,
        show: true
      }));
    }
  };
  const onCloseEditInvoicingDataModal = () => {
    setShowEditInvoicingData(false);
  };

  const goToRecommendations = () => {
    navigate(ROUTES.IMPROVE_RECOMMENDATIONS);
  };

  const columns = [
    {
      title: t('eventDetail.area'),
      dataIndex: 'area',
      key: 'area'
    },
    {
      title: t('eventDetail.duration'),
      dataIndex: 'hours',
      key: 'hours'
    },
    {
      title: t('eventDetail.employees'),
      dataIndex: 'employees',
      key: 'employees'
    },
    {
      title: t('eventDetail.attendees'),
      dataIndex: 'attendees',
      key: 'attendees'
    },
    {
      title: t('eventDetail.food'),
      dataIndex: 'food',
      key: 'food'
    },
    {
      title: t('eventDetail.drinks'),
      dataIndex: 'drinks',
      key: 'drinks'
    },
    {
      title: t('eventDetail.purchases'),
      dataIndex: 'purchases',
      key: 'purchases'
    },
    {
      title: t('eventDetail.total'),
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: t('eventDetail.offset'),
      dataIndex: 'offset',
      key: 'offset'
    },
    {
      title: t('eventDetail.status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];

  return (
    <MainPageLayout
      sectionTitle={t('eventDetail.title')}
      title={t('eventDetail.start')}
      description={t('eventDetail.startDescription')}
      breadcrumb={
        <Breadcrumb crumbsReplace={id ? [{ key: id, value: eventDetail?.name ?? '' }] : []} />
      }
      buttons={
        !loading && (
          <Button
            lookAndFeel={'primary'}
            onClick={handleShowPreEvent}
            text={t('eventDetail.preEventData')}
            size='small'
          />
        )
      }>
      <div className='event-detail-table card-border-color main-bg-color solid-border'>
        {eventDetail?.co2e_pre && (
          <div className='buttons'>
            <Button
              lookAndFeel={'secondary'}
              text={t('eventDetail.negativize')}
              onClick={handleSetNegativizeQuantity}
              size='small'
            />
            <Button
              lookAndFeel={'primary'}
              text={t('eventDetail.offset')}
              onClick={handleSetOffsetQuantity}
              size='small'
            />
          </div>
        )}
        <TableComponent data={parseData()} columns={columns} loading={loading} />
      </div>
      <Modal show={showPreEvent} onClose={handleHideModal} width='700px' maxWidth='700px'>
        {eventDetail && preEvent ? (
          <PreEventData
            updatePreEvent={updatePreEvent}
            eventDetail={eventDetail}
            preEvent={preEvent}
          />
        ) : null}
      </Modal>
      <Modal show={!!eventToDelete.id} onClose={handleHideModal} width='500px'>
        <DeleteEventData
          eventToDelete={eventToDelete.id}
          type={eventToDelete.type}
          removeEvent={removeEvent}
        />
      </Modal>
      <Modal show={quantityToOffset.show} onClose={handleHideModal} width='600px' maxWidth='600px'>
        <OffsetSummary
          optionSelectedTitle={t(`eventDetail.${quantityToOffset.type}-title`)}
          optionSelectedDescription={t(`eventDetail.${quantityToOffset.type}-description`)}
          projectSelectedTitle={t('offset.generateCleanCertifiedEnergy')}
          projectSelectedDescription={t('offset.ods13')}
          co2={quantityToOffset.quantity}
          price={getPricePerTon(quantityToOffset.quantity / 1000)}
          handleSubmit={onShowEditInvoicingDataModal}
          loadingButton={loadingButton}
        />
      </Modal>
      <Modal
        show={showEditInvoicingData}
        onClose={onCloseEditInvoicingDataModal}
        width='428px'
        maxWidth='428px'>
        {foundOrganization ? (
          <EditInvoicingData
            editInvoicingData={handleSubmitOffset}
            organization={foundOrganization}
            buttonText={t('offset.saveAndOffset')}
          />
        ) : null}
      </Modal>
      <Modal show={orderPlaced} onClose={handleHideModal} width='428px' maxWidth='428px'>
        <OrderPlaced
          button={{
            text: t('offset.goToRecommendations'),
            onClick: goToRecommendations
          }}
        />
      </Modal>
    </MainPageLayout>
  );
};

export default EventDetail;
