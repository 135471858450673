import { useTranslation } from 'react-i18next';
import { EsgMetricsEnhanced } from '../../../../../../../../types/entities/esgMetrics';
import { Column } from '../../../../../../../../types/table';
import Button from '../../../../../../../ui/button/Button';
import FormSelect from '../../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import DisabledLabel from '../../../../../../../ui/statusLabels/disabledLabel/DisabledLabel';
import PendingLabel from '../../../../../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../../../../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../../../../../../ui/tooltip/TooltipWrapper';
import { useFeatureFlags } from '../../../../../../../../customHooks/useFeatureFlags';

type Props = {
  setDatapointToEdit: (datapoint: EsgMetricsEnhanced) => void;
  handleEditRelevance: (value: SelectOptionFormat, datapoint: EsgMetricsEnhanced) => void;
  setShowPremiumModal: (value: boolean) => void;
  category?: string;
  subcategory?: string;
};

const capitalizeFirstLetter = (text: string) => {
  if (text?.length === 0) {
    return text; // Return the empty string if input is empty
  }
  return text?.charAt(0).toUpperCase() + text?.slice(1);
};

const useColumns = ({
  setDatapointToEdit,
  handleEditRelevance,
  setShowPremiumModal,
  category,
  subcategory
}: Props) => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const renderTag = (elem: EsgMetricsEnhanced) => {
    const tagMap: { [key: string]: JSX.Element } = {
      COMPLETE: <SuccessLabel key='completed'>{t('general.withData')}</SuccessLabel>,
      INCOMPLETE: <PendingLabel key='pending'>{t('general.pending')}</PendingLabel>,
      OPTIONAL: (
        <DisabledLabel key='optional' icon='information'>
          {t('general.optional')}
        </DisabledLabel>
      )
    };
    let text: string = elem.status;
    if (elem.status === 'INCOMPLETE' && elem.relevance === 'not_mandatory') {
      text = 'OPTIONAL';
    }

    return tagMap[text];
  };

  const renderTypeTag = (elem: EsgMetricsEnhanced) => {
    return (
      <div
        className='text-tag body3-font tag-bg-text-color tag-space-between'
        style={{ width: 'fit-content', padding: '4px 8px' }}>
        {capitalizeFirstLetter(t(`esgMetrics.dataTypes.${elem.data_type}`))}
      </div>
    );
  };
  // on social, first and last tabs

  const columns: Column[] = [
    {
      title: t('esgMetrics.general.idDatapoint'),
      dataIndex: 'short_name',
      key: 'short_name'
    },
    {
      title: t('esgMetrics.general.datapoint'),
      dataIndex: 'id',
      key: 'id',
      width: '55%',
      render: (text: string) => (
        <span>{capitalizeFirstLetter(t(`esgMetrics.datapoints.${text}`))}</span>
      )
    },
    {
      title: t('esgMetrics.general.state'),
      dataIndex: 'status',
      key: 'state',
      render: (_: unknown, data: EsgMetricsEnhanced) => renderTag(data)
    },
    {
      title: t('esgMetrics.general.relevance'),
      dataIndex: 'relevance',
      key: 'relevance',
      render: (_: unknown, data: EsgMetricsEnhanced) => (
        <TooltipWrapper
          text={
            !flags?.csrd
              ? t('esgMetrics.general.relevanceBlockedTooltip')
              : t('esgMetrics.general.relevanceTooltip')
          }
          shouldAppear={data.relevance === 'always_mandatory' || !flags?.csrd}>
          <div style={{ width: '3rem' }}>
            <FormSelect
              value={{
                id: data.relevance,
                name: t(`esgMetrics.${data.relevance?.replace('always_mandatory', 'mandatory')}`)
              }}
              onChange={(value: SelectOptionFormat) => handleEditRelevance(value, data)}
              options={[
                {
                  id: 'mandatory',
                  name: t('esgMetrics.mandatory')
                },
                {
                  id: 'not_mandatory',
                  name: t('esgMetrics.not_mandatory')
                }
              ]}
              disabled={data.relevance === 'always_mandatory' || !flags?.csrd}
              sort={false}
            />
          </div>
        </TooltipWrapper>
      )
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: '12%',
      render: (_: unknown, data: EsgMetricsEnhanced) => {
        return data.value ? (
          <Button
            lookAndFeel={flags?.csrd ? 'secondary' : 'blocked'}
            size='small'
            text={t('esgMetrics.general.edit')}
            onClick={() => setDatapointToEdit(data)}
            style={{ whiteSpace: 'nowrap' }}
            onClickBlocked={
              flags?.csrd
                ? undefined
                : () => {
                    setShowPremiumModal(true);
                  }
            }
          />
        ) : (
          <Button
            lookAndFeel={flags?.csrd ? 'secondary' : 'blocked'}
            size='small'
            text={t('esgMetrics.general.addResponse')}
            onClick={() => setDatapointToEdit(data)}
            style={{ whiteSpace: 'nowrap' }}
            onClickBlocked={
              flags?.csrd
                ? undefined
                : () => {
                    setShowPremiumModal(true);
                  }
            }
          />
        );
      }
    }
  ];
  // add type tag in third position of the array
  if (
    (category === 'social' && subcategory === 'workers_in_the_value_chain') ||
    (category === 'social' && subcategory === 'own_workforce')
  ) {
    columns.splice(2, 0, {
      title: t('esgMetrics.general.type'),
      dataIndex: 'data_type',
      key: 'data_type',
      render: (_: unknown, data: EsgMetricsEnhanced) => renderTypeTag(data)
    });
  }
  return columns;
};

export default useColumns;
