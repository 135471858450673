import { AxiosError, AxiosResponse } from 'axios';
import apiFetch from '../../utils/apiFetch';
import { URLOptions, getUrl } from '../../utils/url';

type Response = {
  response?: AxiosResponse;
  error?: AxiosError;
};

export const createLca = async (lca: LCABody) => {
  try {
    const response = await apiFetch('POST', '/lcas', lca, {
      'x-organization-id': lca.organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getLcaById = async (id: string) => {
  try {
    const queryOptions: URLOptions = {
      filters: [
        {
          field: 'epd_phase',
          value: 'Materials and components (A1)',
          type: 'eq'
        }
      ]
    };

    const url = getUrl(`/lcas/${id}`, queryOptions);

    const response = await apiFetch('GET', url);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const startAnalysis = async (id: string, x_organization_id: string) => {
  try {
    const response = await apiFetch(
      'POST',
      `/lcas/ready`,
      null,
      {
        'x-organization-id': x_organization_id
      },
      {
        lca_id: id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteLca = async (id: string, x_organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/lcas/${id}`, null, {
      'x-organization-id': x_organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const uploadLcaFile = async (organization_id: string, file_name: string) => {
  try {
    const response = await apiFetch('POST', '/lcas/project_data/upload', {
      file_name,
      organization_id
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getComparativeResults = async (id: string, x_organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      '/lcas/project_data/comparative_results',
      null,
      {
        'x-organization-id': x_organization_id
      },
      {
        lca_id: id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getMaterialsResults = async (
  id: string,
  product_name: string,
  granularity: string
) => {
  try {
    const response = await apiFetch(
      'GET',
      '/lcas/project_data/material_composition',
      null,
      {},
      {
        lca_id: id,
        product_name,
        granularity
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getLcaList = async (page: number, size: number, searchValue?: string) => {
  const url = getUrl(`/lcas/sold-products`, {
    queryParams: [
      {
        key: 'page',
        value: page
      },
      {
        key: 'size',
        value: size
      }
    ],
    filters: [
      {
        field: 'name',
        value: searchValue,
        type: 'lk'
      }
    ]
  });

  try {
    const response = await apiFetch('GET', url);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getLcaListNotPaginated = async () => {
  try {
    const response = await apiFetch('GET', `/lcas`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export async function getUseAndEndOfLifeById({ lcaId }: { lcaId: string }): Promise<Response> {
  try {
    const response = await apiFetch('GET', `/lca_production_details/${lcaId}`);
    return { response };
  } catch (error: unknown) {
    return { error: error as AxiosError };
  }
}

type LcaProductListProps = {
  lcaId: string;
};

export async function getLcaProductListNotPaginated({
  lcaId
}: LcaProductListProps): Promise<Response> {
  try {
    const response = await apiFetch('GET', `/lca_project_data/lca/${lcaId}`);
    return { response };
  } catch (error: unknown) {
    return { error: error as AxiosError };
  }
}
