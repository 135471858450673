import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounceValue from '../../../customHooks/useDebounceValue';
import { IFilter } from '../../../utils/url';
import { useFiltersContext } from './Filters';
import InputWrapperPlain from '../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import InputCalendarDouble from '../../ui/formComponents2/inputCalendarDouble/InputCalendarDouble';
import { ErrorDate } from '../../ui/formComponents2/inputCalendar/InputCalendar';
import { FilterSection } from './FilterSection';
import moment from 'moment';
import { checkIfValidStringDate } from '../../../utils/convertDates';
import { RadioButton } from '../../ui/formComponents2/radioButton/RadioButton';

type Params = {
  fields: string[];
};

const DEFAULT_DATE = moment.utc().format('DD/MM/YYYY');

const getStartOfDayTimestamp = (date: Date) => {
  return date.setUTCHours(0, 0, 0, 0) / 1000;
};
const convertStringToTimestamp = (date: string) => {
  return moment.utc(date, 'DD/MM/YYYY').toDate().getTime() / 1000;
};

const getEndOfDayTimestamp = (date: Date) => {
  return date.setUTCHours(23, 59, 59) / 1000;
};

const getThisYearFilter = ({ fields }: Params): IFilter[] => {
  const firstDayOfThisYearTimeStamp = getStartOfDayTimestamp(moment.utc().startOf('year').toDate());

  const lastDayOfThisYearTimeStamp = getEndOfDayTimestamp(moment.utc().endOf('year').toDate());

  const value = [`${firstDayOfThisYearTimeStamp}`, `${lastDayOfThisYearTimeStamp}`];

  return fields.map((field) => ({ field, value, type: 'rd' }));
};

const getLastYearFilter = ({ fields }: Params): IFilter[] => {
  const firstDayOfLastYearTimeStamp = getStartOfDayTimestamp(
    moment.utc().subtract(1, 'year').startOf('year').toDate()
  );

  const lastDayOfLastYearTimeStamp = getEndOfDayTimestamp(
    moment.utc().subtract(1, 'year').endOf('year').toDate()
  );

  const value = [`${firstDayOfLastYearTimeStamp}`, `${lastDayOfLastYearTimeStamp}`];

  return fields.map((field) => ({ field, value, type: 'rd' }));
};

const getLast3MonthsFilter = ({ fields }: Params): IFilter[] => {
  const startOfLast3Months = getStartOfDayTimestamp(moment.utc().subtract(3, 'months').toDate());

  const startOfToday = getStartOfDayTimestamp(moment.utc().toDate());

  const value = [`${startOfLast3Months}`, `${startOfToday}`];

  return fields.map((field) => ({ field, value, type: 'rd' }));
};

const getCustomDatesFilter = (
  { fields }: Params,
  startDate: string,
  endDate: string
): IFilter[] => {
  const value = [`${convertStringToTimestamp(startDate)}`, `${convertStringToTimestamp(endDate)}`];

  return fields.map((field) => ({ field, value, type: 'rd' }));
};

const CustomDateFilter = ({ fields }: Params) => {
  const [startDate, setStartDate] = useState(DEFAULT_DATE);
  const [endDate, setEndDate] = useState(DEFAULT_DATE);

  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const { setFilters } = useFiltersContext();

  const debounceValueStartDate = useDebounceValue(startDate);
  const debounceValueEndDate = useDebounceValue(endDate);

  const handleOnChangeStartDate = (value: string) => {
    setStartDateError('');
    setStartDate(value);
  };

  const handleOnChangeEndDate = (value: string) => {
    setEndDateError('');
    setEndDate(value);
  };

  useEffect(() => {
    if (
      checkIfValidStringDate(debounceValueStartDate) &&
      checkIfValidStringDate(debounceValueEndDate) &&
      checkIfValidStringDate(startDate) &&
      checkIfValidStringDate(endDate)
    ) {
      const filters = getCustomDatesFilter({ fields }, startDate, endDate);

      setFilters((prevFilters) => {
        const newFilters = prevFilters.filter((filter) => !fields.includes(filter.field));

        return [...newFilters, ...filters];
      });
    }
  }, [debounceValueStartDate, debounceValueEndDate]);

  return (
    <InputWrapperPlain
      error={
        startDateError || endDateError
          ? {
              error: 'date',
              description: startDateError || endDateError
            }
          : undefined
      }>
      <InputCalendarDouble
        startDate={startDate}
        endDate={endDate}
        handleChangeEndDate={handleOnChangeEndDate}
        handleChangeStartDate={handleOnChangeStartDate}
        handleStartDateError={(value: ErrorDate) => setStartDateError(value)}
        handleEndDateError={(value: ErrorDate) => setEndDateError(value)}
        position='top'
      />
    </InputWrapperPlain>
  );
};

const OPTIONS = {
  ALL: () => [],
  THIS_YEAR: getThisYearFilter,
  LAST_YEAR: getLastYearFilter,
  LAST_3_MONTHS: getLast3MonthsFilter,
  CUSTOM: () => []
} as const;

const FiltersDate = ({ fields }: Params) => {
  const [option, setOption] = useState<keyof typeof OPTIONS>('ALL' as keyof typeof OPTIONS);
  const { t } = useTranslation('translation', { keyPrefix: 'general.filters' });

  const { setFilters, filters } = useFiltersContext();

  const filtersExist = filters.some((filter) => fields.includes(filter.field));

  useEffect(() => {
    if (!filtersExist && option !== 'ALL') {
      setOption('ALL');
    }
  }, [filtersExist, option]);

  return (
    <FilterSection.Layout title={t('dateRange')}>
      <>
        {Object.entries(OPTIONS).map(([key, value]) => {
          if (key === 'CUSTOM') {
            return (
              <>
                <RadioButton
                  text={t(key)}
                  key={key}
                  selected={option === key}
                  onChange={() => {
                    setOption(key as keyof typeof OPTIONS);

                    const filters = getCustomDatesFilter({ fields }, DEFAULT_DATE, DEFAULT_DATE);

                    setFilters((prevFilters) => {
                      const newFilters = prevFilters.filter(
                        (filter) => !fields.includes(filter.field)
                      );

                      return [...newFilters, ...filters];
                    });
                  }}
                />
                {option === key && <CustomDateFilter fields={fields} />}
              </>
            );
          }

          return (
            <RadioButton
              text={t(key)}
              key={key}
              selected={option === key}
              onChange={() => {
                setOption(key as keyof typeof OPTIONS);

                if (key === 'ALL') {
                  setFilters((prevFilters) => {
                    const newFilters = prevFilters.filter(
                      (filter) => !fields.includes(filter.field)
                    );

                    return newFilters;
                  });

                  return;
                }

                const filters = value({ fields });

                setFilters((prevFilters) => {
                  const newFilters = prevFilters.filter((filter) => !fields.includes(filter.field));

                  return [...newFilters, ...filters];
                });
              }}
            />
          );
        })}
      </>
    </FilterSection.Layout>
  );
};

export default FiltersDate;
