import apiFetch from '../../utils/apiFetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendSlackMessage = async (message: any, channel: string) => {
  try {
    const response = await apiFetch('POST', '/slack', {
      message,
      webhook_url: channel
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
