import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { EmployeePeriodFormType } from '../../../../../../types/entities/employeePeriod';

import { useParams } from 'react-router-dom';
import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import FormNumber from '../../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';

type Props = {
  next: string;
  title?: ReactNode;
};

const StepDailyTrips = ({ next, title }: Props) => {
  const { t } = useTranslation();
  const { startDate, endDate } = useParams();

  const { formData, setFormData, stepCounter, handleNext, handleBack } =
    useContext(EmployeeFormContext);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleErrors = () => {
    const newErrors: ErrorType[] = [];

    if (!formData?.dailyTrips)
      newErrors.push({
        error: 'dailyTrips',
        description: t('error.requiredField')
      });

    if (formData?.dailyTrips && Number(formData?.dailyTrips) < 1) {
      newErrors.push({
        error: 'dailyTrips',
        description: t('error.minValue', { minValue: 1 })
      });
    }

    if (newErrors.length > 0) {
      setFormData((previous: EmployeePeriodFormType) => ({ ...previous, errors: newErrors }));
      return newErrors;
    }
    return false;
  };

  const handleNextStep = () => {
    if (handleErrors() || !handleNext) return;
    handleNext(next);
  };

  if (!handleNext || !handleBack) return null;

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle1', {
        date: `${startDate && convertDateBackToFront(startDate)} - ${
          endDate && convertDateBackToFront(endDate)
        }`
      })}
      subtitle={t('employeeForm.formSubtitle2')}>
      <StepWrapper
        title={t('employeeForm.dailyTrips')}
        tooltip={t('employeeForm.dailyTripsTooltip')}
        stepNumber={stepCounter}>
        <FormNumber
          icon={'/images/icons/calendar.svg'}
          placeholder={t('employeeForm.enterDailyTrips')}
          value={formData?.dailyTrips}
          onChange={onChangeValue('dailyTrips')}
          error={formData?.errors?.find((error) => error.error === 'dailyTrips')}
        />
        <Button lookAndFeel={'primary'} onClick={handleNextStep} text={t('employeeForm.next')} />
      </StepWrapper>
      <SwitchStep handleNext={handleNextStep} handleBack={handleBack} />
    </StepControllerContainer>
  );
};

export default StepDailyTrips;
