import { Status } from '../../../../types/utilsEnums/status';

export const SHIPMENT_STATUS = {
  [Status.ACTIVE]: Status.ACTIVE,
  [Status.ERROR]: Status.ERROR
} as const;

export enum SHIPMENT_ERRORS {
  ERR_GOOGLE_MAPS_QUOTA_REACHED = 'ERR_GOOGLE_MAPS_QUOTA_REACHED',
  ERR_GOOGLE_MAPS_ZERO_RESULTS = 'ERR_GOOGLE_MAPS_ZERO_RESULTS', // Revisar ruta
  ERR_GOOGLE_MAPS_GENERAL_ERROR = 'ERR_GOOGLE_MAPS_GENERAL_ERROR',
  ERR_GOOGLE_MAPS_REQUEST_DENIED = 'ERR_GOOGLE_MAPS_REQUEST_DENIED',
  ERR_GOOGLE_MAPS_INVALID_REQUEST = 'ERR_GOOGLE_MAPS_INVALID_REQUEST',
  ERR_GOOGLE_MAPS_UNKNOWN_ERROR = 'ERR_GOOGLE_MAPS_UNKNOWN_ERROR',
  ERR_GOOGLE_MAPS_MAX_ELEMENTS_EXCEEDED = 'ERR_GOOGLE_MAPS_MAX_ELEMENTS_EXCEEDED',
  ERR_GOOGLE_MAPS_MAX_DIMENSIONS_EXCEEDED = 'ERR_GOOGLE_MAPS_MAX_DIMENSIONS_EXCEEDED',
  ERR_GOOGLE_MAPS_NOT_FOUND = 'ERR_GOOGLE_MAPS_NOT_FOUND', // Revisar varios (Revisar origen / Revisar destino)
  ERR_GOOGLE_MAX_ROUTE_LENGTH_EXCEEDED = 'ERR_GOOGLE_MAX_ROUTE_LENGTH_EXCEEDED',

  ERR_AIRCRAFT_DISTANCE_CALCULATION_ERROR = 'ERR_AIRCRAFT_DISTANCE_CALCULATION_ERROR',
  ERR_ROAD_DISTANCE_SHORTER_THAN_MARITIME = 'ERR_ROAD_DISTANCE_SHORTER_THAN_MARITIME',

  ERR_EF_NOT_FOUND = 'ERR_EF_NOT_FOUND',
  ERR_EF_KMS_NOT_FOUND = 'ERR_EF_KMS_NOT_FOUND',

  ERR_CREATION_EMPTY_ENTITIES = 'ERR_CREATION_EMPTY_ENTITIES',

  ERR_TOTAL_IMPACTS = 'ERR_TOTAL_IMPACTS'
}
