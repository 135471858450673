import { createContext, useContext } from 'react';

type CriteriaContextType = {
  criteria: ISbtiCriteriaTable;
  criteriaList: ISbtiCriteriaBack[];
  handleCloseCriteria: () => void;
  handleNewCriterias: (criteria: ISbtiCriteriaBack[]) => void;
};

const CriteriaContext = createContext<CriteriaContextType | undefined>(undefined);

const useCriteriaContext = () => {
  const context = useContext(CriteriaContext);
  if (context === undefined) {
    throw new Error('useCriteriaContext must be used within a CriteriaProvider');
  }
  return { criteriaContext: context };
};

export const CriteriaProvider = CriteriaContext.Provider;

export default useCriteriaContext;
