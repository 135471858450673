import { createContext, useContext } from 'react';

type ImpactCategoryContextType = SelectOptionFormat<string>;

const ImpactCategoryContext = createContext<ImpactCategoryContextType | null>(null);

const useImpactCategoryContext = () => {
  const context = useContext(ImpactCategoryContext);

  if (!context) {
    throw new Error('useImpactCategoryContext must be used within a ImpactCategoryContextProvider');
  }

  return context;
};

type Props = {
  impactCategory: SelectOptionFormat<string>;
  children: React.ReactNode;
};
const Provider: React.FC<Props> = ({ impactCategory, children }) => {
  return (
    <ImpactCategoryContext.Provider value={impactCategory}>
      {children}
    </ImpactCategoryContext.Provider>
  );
};

export { Provider as ImpactCategoryProvider, useImpactCategoryContext };
