import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../../context/userContext';
import controller from '../../../../../../environments/controller';
import { EventAttendee, PreEventFormData } from '../../../../../../types/entities/events';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import QrCode from '../../../../../ui/qrCode/QrCode';
import InputAttendee from './InputAttendee';
import './styles.scss';

const environment = controller();

type Props = {
  setFormData: Dispatch<SetStateAction<PreEventFormData>>;
  formData: EventAttendee[];
  eventId: string;
  eventName: string;
};
const Attendees = ({ setFormData, formData, eventId, eventName }: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (elem) => elem.id === user?.selectedOrganization
  );
  const options = [
    { id: 'car', name: t('employees.car') },
    { id: 'metro', name: t('employees.metro') },
    { id: 'train', name: t('employees.train') },
    // { id: 'trolleybus', name: t('employees.trolleybus') },
    { id: 'bus', name: t('employees.bus') },
    { id: 'bicycle', name: t('employees.bicycle') },
    // { id: 'motorbike', name: t('employees.motorbike') },
    { id: 'aircraft', name: t('businessTravels.aircraft') }
  ];

  const onChangeValueList = (value: EventAttendee) => {
    const newList = [...formData];
    // Check if the transport is already in the employees list
    const foundIndex = newList.findIndex((elem) => elem.transport_type === value.transport_type);

    if (foundIndex > -1) {
      newList[foundIndex].distance = value.distance;
      newList[foundIndex].quantity = value.quantity;
      setFormData((prev) => ({
        ...prev,
        attendees: newList
      }));
      return;
    }
    newList.push(value);
    setFormData((prev) => ({ ...prev, attendees: newList }));
  };

  const downloadPdf = () => {
    // Download pdf file in public/files/events/{i18n.resolvedLanguage}/events.pdf
    const url = `/files/events/${i18n.resolvedLanguage}/events.pdf`;
    const link = document.createElement('a');
    link.href = url;
    link.download = 'events.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadQrCode = () => {
    //Download svg inside the div with class qr-event-attendees
    const svg = document.querySelector('.qr-event-attendees svg');

    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);

    const blob = new Blob([svgData], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'image.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    downloadPdf();
  };

  type FormUrl = {
    [key: string]: string;
  };
  const environmentTypeform: FormUrl = environment.typeformEventUrl;
  const typeFormUrl = environmentTypeform[i18n.resolvedLanguage]
    .replace('{event_id}', eventId)
    .replace('{event_name}', eventName)
    .replace('{organization_id}', user?.selectedOrganization ?? '')
    .replace('{organization_name}', foundOrganization?.company_name ?? '');

  const labels = [t('eventData.numberOfPeople'), t('eventData.personKm'), t('eventData.transport')];
  return (
    <div className='pre-event-attendees'>
      <FormWrapper>
        {options.map((option, index) => {
          const foundFormData = formData.find((elem) => elem.transport_type === option.id);
          return (
            <FormElementFull key={option.id}>
              <InputAttendee
                transport={option}
                labels={index === 0 ? labels : undefined}
                onChangeValue={onChangeValueList}
                formData={
                  foundFormData ?? {
                    quantity: 0,
                    distance: 0,
                    transport_type: option.id
                  }
                }
              />
            </FormElementFull>
          );
        })}
      </FormWrapper>
      <div className='qr-event-attendees'>
        <p className='body1-bold-font'>
          <span>{t('eventData.qrLabel')}</span>
          <span className='highlight-text-color pointer' onClick={downloadQrCode}>
            {t('eventData.download')}
          </span>
        </p>
        <QrCode value={typeFormUrl} maxWidth={80} />
      </div>
    </div>
  );
};

export default Attendees;
