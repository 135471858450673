import { useMITECOOrgEf } from './useMITECOOrgEf';
import { useMITECOVersions } from './useMITECOVersions';

export const useMITECOOrgEfName = () => {
  const { data: efVersion } = useMITECOOrgEf();
  const { data: MITECOVersions } = useMITECOVersions();

  return {
    data:
      MITECOVersions.find((version) => version.id === efVersion?.version)?.name ??
      efVersion?.version
  };
};
