import { IconName } from '../../../../../types/utils/iconName';
import Icon from '../../../../ui/icon/Icon';
import { IconColor } from '../../../../ui/IconBase/IconBase';
import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
  icon: IconName;
  color: IconColor;
  disabled?: boolean;
  label?: string;
};

export const CustomControl: React.FC<Props> = ({ onClick, icon, color, disabled, label }) => {
  const handleClick = () => {
    if (disabled) return;

    onClick();
  };

  return (
    <button
      className={`cursor-pointer flex items-center gap-x-2 bg-white ${styles['action-btn']}`}
      aria-disabled={disabled}
      onClick={handleClick}>
      <Icon icon={icon} color={disabled ? 'disabled' : color} size='medium' />
      {label && <span className='font-body-b2-sb'>{label}</span>}
    </button>
  );
};
