export const uploadFilePresignedUrl = async (file: File, presignedUrl: string) => {
  try {
    // const processedFile = await processFile(file);

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    await new Promise((resolve) => (reader.onload = () => resolve(reader.result)));
    const file_bytes = reader.result;
    // console.log('file_bytes', file_bytes);
    // console.log('typeof file_bytes', typeof file_bytes);

    const response = await fetch(presignedUrl, {
      method: 'PUT',
      // headers: {
      //   'Content-Type': file.type
      // },
      body: file_bytes
    });

    if (!response.ok) {
      console.error('Error uploading file', response);
      console.error('Error body', await response.text());
      return null;
    }
    const responseString = response as unknown as string;
    return responseString.toString().split('?')[0];
  } catch (err: any) {
    console.error(err);
    return null;
  }
};

async function processFile(file: File): Promise<File> {
  // Check if the file has a .csv extension
  if (!file.name.toLowerCase().endsWith('.csv')) {
    // If not a CSV file, return the original file
    return file;
  }

  // Read the contents of the CSV file
  const fileContent: string = await readFileContent(file);

  // Convert the CSV content to semicolon-separated format
  const semicolonSeparatedContent: string = convertToSemicolonCSV(fileContent);

  // Save the new content to a Blob
  const blob = new Blob([semicolonSeparatedContent], { type: 'text/csv' });

  // Create a new File from the Blob
  const newFile = new File([blob], `${file.name}.csv`, { type: 'text/csv' });

  return newFile;
}

async function readFileContent(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target && event.target.result) {
        const content = event.target.result as string;
        resolve(content);
      } else {
        reject(new Error('Failed to read file content.'));
      }
    };

    reader.readAsText(file);
  });
}

function convertToSemicolonCSV(csvContent: string): string {
  // Assuming the CSV content is comma-separated
  const lines = csvContent.split('\n');
  const semicolonSeparatedLines = lines.map((line) => line.split(',').join(';'));
  return semicolonSeparatedLines.join('\n');
}
