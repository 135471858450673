import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { createIntensityMetric } from '../../../../../services/api/intensityMetrics';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import {
  convertDateFrontToBack,
  convertDateFrontToBackTimestamp
} from '../../../../../utils/convertDates';
import numberToDecimal from '../../../../../utils/numberToDecimal';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDoubleSelect from '../../../../ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';

type FormData = {
  startDate: string;
  endDate: string;
  quantity: string;
  errors: ErrorType[];
};

type Props = {
  addIntensityMetric: (intensityMetric: IntensityMetric) => void;
  intensityMetricGroup: IntensityMetricGroup;
};
const CreateIntensityMetric = ({ addIntensityMetric, intensityMetricGroup }: Props) => {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormData>({
    startDate: '',
    endDate: '',
    quantity: '',
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleCreate = async () => {
    const optionalFields: string[] = [];
    // Validate form with errors
    const newErrors = checkFormErrors(formData, [], optionalFields);

    if (Number(formData.quantity) === 0) {
      newErrors.push({
        error: 'quantity',
        description: t('error.valueBiggerThan0')
      });
    }

    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    setLoadingButton(true);
    // Call api and callback to add to list
    const intensityMetric = await createIntensityMetric(
      {
        start_date: convertDateFrontToBackTimestamp(formData.startDate),
        end_date: convertDateFrontToBackTimestamp(formData.endDate),
        quantity: parseFloat(formData.quantity),
        intensity_metric_group_id: intensityMetricGroup.id
      },
      user?.selectedOrganization ?? ''
    );
    setLoadingButton(false);

    if (intensityMetric === 'INTENSITY_METRICS_ALEADY_EXISTS_IN_THIS_PERIOD') {
      setFormData((prev) => ({
        ...prev,
        errors: [
          ...prev.errors,
          {
            error: 'startDate',
            description: t('error.intensityMetricsAlreadyExists')
          }
        ]
      }));
      return;
    }
    if (!intensityMetric) return;

    // Callback to add it
    addIntensityMetric({
      id: intensityMetric.id,
      start_date: convertDateFrontToBack(formData.startDate),
      end_date: convertDateFrontToBack(formData.endDate),
      intensity_metric: `${numberToDecimal(intensityMetric.value)} Kg CO₂ eq. / ${
        intensityMetricGroup.unit
      }`,
      quantity: parseFloat(formData.quantity),
      value: intensityMetric.value,
      user: {
        first_name: user?.first_name ?? '',
        last_name: user?.last_name ?? ''
      }
    });
  };

  return (
    <div className='create-intensity-metric'>
      <FormHeader
        title={t('createIntensityMetric.title')}
        description={t('createIntensityMetric.description')}
      />
      <FormWrapper>
        <FormElementFull>
          <FormCalendarDoubleSelect
            startDate={formData.startDate}
            endDate={formData.endDate}
            handleChangeStartDate={onChangeValue('startDate')}
            handleChangeEndDate={onChangeValue('endDate')}
            label={t('createIntensityMetric.metricDates')}
            error={formData.errors.find(
              (error) => error.error === 'startDate' || error.error === 'endDate'
            )}
            maxEndDate={'01-01-2030'}
          />
        </FormElementFull>
        <FormNumber
          label={`${t('createIntensityMetric.quantity')} (${intensityMetricGroup.unit})`}
          icon={'/images/icons/intensityMetric.svg'}
          placeholder={t('createIntensityMetric.quantityPlaceholder')}
          value={formData.quantity}
          onChange={onChangeValue('quantity')}
          error={formData.errors.find((error) => error.error === 'quantity')}
          decimals={5}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('createIntensityMetric.create')}
          onClick={handleCreate}
          loading={loadingButton}></Button>
      </FormButtonSection>
    </div>
  );
};

export default CreateIntensityMetric;
