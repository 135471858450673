import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFund } from '../../../../../../services/api/funds';
import { FormFundData, FundType } from '../../../../../../types/entities/funds';

type Props = {
  id: string;
};

const useGetData = ({ id }: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [fundData, setFundData] = useState<FormFundData>({
    name: '',
    size: '',
    article: {
      id: '',
      name: ''
    },
    questions: [],
    errors: []
  });

  useEffect(() => {
    const getData = async () => {
      const response = await getFund(id);

      if (response?.response?.status >= 400) {
        setLoading(false);
        return;
      }

      const fundResponse: FundType = response;
      setFundData({
        name: fundResponse.fund_name,
        size: fundResponse.funds_total_value,
        article: {
          id: fundResponse.funds_type,
          name: t(`funds.articles.${fundResponse.funds_type}`)
        },
        questions: fundResponse.pai_extra_questions.map((question) => ({
          id: question.id,
          name: t(`funds.questions.${question.pai_extra_question_code}`)
        })),
        errors: []
      });
      setLoading(false);
    };

    getData();
  }, []);

  return { fundData, loading };
};

export default useGetData;
