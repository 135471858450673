import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { User } from '../../../../types/entities/user';
import Button from '../../../ui/button/Button';
import './styles.scss';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import checkFormErrors from '../../../../utils/checkFormErrors';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormCalendarDoubleSelect from '../../../ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';

type Props = {
  user: User;
  closeModal: () => void;
};
type FormData = {
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};
function SnippetReportDataForm({ closeModal }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    startDate: '',
    endDate: '',
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleEditOrgDescription = async () => {
    const errors = checkFormErrors(formData, formData.errors, []);
    if (errors.length > 0) {
      return;
    }
    navigate(`${ROUTES.SNIPPET}?start_date=${formData.startDate}&end_date=${formData.endDate}`);
    closeModal();
  };

  return (
    <div className='organization-description-form'>
      <FormHeader
        title={t(`orgDescriptionModal.snippet.completeData`)}
        description={t(`orgDescriptionModal.snippet.completeDataDescription`)}
      />
      <FormWrapper>
        <FormCalendarDoubleSelect
          label={t('orgDescriptionModal.selectDateRange')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          startDate={formData.startDate ? (formData.startDate as string) : ''}
          endDate={formData.endDate ? (formData.endDate as string) : ''}
          error={formData.errors.find(
            (error) =>
              error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
          )}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleEditOrgDescription}
          text={t(`orgDescriptionModal.snippet.saveAndGenerateReport`)}
          size='medium'
        />
      </FormButtonSection>
    </div>
  );
}

export default SnippetReportDataForm;
