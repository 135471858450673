import { useQuery } from '@tanstack/react-query';
import { getLCADetail } from '../services/getLCADetail';
import { AZKOYEN_OLD_IDS } from '../constants';

const getIsOldFlow = (id?: string) => {
  if (!id) {
    return false;
  }

  return AZKOYEN_OLD_IDS.includes(id);
};

export const useLCADetail = (id?: string) => {
  const old_flow = getIsOldFlow(id);

  const { data, isLoading } = useQuery({
    queryKey: ['lca', id],
    queryFn: () => getLCADetail(id, old_flow),
    enabled: Boolean(id)
  });

  return { data, isLoading };
};
