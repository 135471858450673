import { useTranslation } from 'react-i18next';
import useTocs from '../../../../../../customHooks/useTocs';

const useConstants = () => {
  const { t: headersTranslations } = useTranslation('', { keyPrefix: 'logistics.table' });
  const { tocs: tocs_raw } = useTocs();

  const columns = [
    {
      title: headersTranslations('shipmentNumber'),
      dataIndex: 'shipmentNumber',
      key: 'shipmentNumber',
      width: 80
    },
    {
      title: headersTranslations('client'),
      dataIndex: 'client',
      key: 'client'
      // width: 100
    },
    {
      title: headersTranslations('date'),
      dataIndex: 'date',
      key: 'date',
      width: 80
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
          <span>{headersTranslations('distance')}</span>
          <span>(km)</span>
        </div>
      ),
      dataIndex: 'distance',
      key: 'distance',
      width: 100
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
          <span>{headersTranslations('load')}</span>
          <span>(t)</span>
        </div>
      ),
      dataIndex: 'load',
      key: 'load',
      width: 60
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
          <span>{headersTranslations('tonKm')}</span>
          <span>(tkm)</span>
        </div>
      ),
      dataIndex: 'tonKm',
      key: 'tonKm',
      width: 100
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
          <span>{headersTranslations('emissionIntensity')}</span>
          <span>(g CO₂eq. / tkm)</span>
        </div>
      ),
      dataIndex: 'emissionIntensity',
      key: 'emissionIntensity',
      width: 140
    },
    {
      title: (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
          <span>{headersTranslations('co2')}</span>
          <span>(kg CO₂eq.)</span>
        </div>
      ),
      dataIndex: 'co2',
      key: 'co2',
      width: 140
    },
    {
      title: headersTranslations('status'),
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: headersTranslations('toc'),
      dataIndex: 'toc',
      key: 'toc'
      // width: 100
    }
  ];

  const tocsSuperchargerHub = tocs_raw
    .filter((toc) => toc.category === 'road' && toc.wtw === 0)
    .map((toc) => ({
      id: toc.name,
      name: toc.name_translated
    }));

  // No time
  const tocs = tocs_raw.map((toc) => ({
    id: toc.name,
    name: toc.name_translated
  }));

  return { columns, tocs, tocsSuperchargerHub };
};

export default useConstants;
