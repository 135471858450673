import { useTranslation } from 'react-i18next';
import { checkFlags } from '../utils/flags';
import { useFeatureFlags } from './useFeatureFlags';

const useTaskCategories = () => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();
  const categories = [
    'facilities',
    'vehicles',
    'purchases',
    'transport_downstream',
    'transport_upstream',
    'business_travels',
    'employees',
    'invoices',
    'vehicle_consumptions',
    'sbti',
    'other'
  ];
  return categories
    .filter((category) => checkFlags(category, flags))
    .map((elem) => ({
      id: elem,
      name: t(`tasks.categories.${elem}`)
    }));
};

export default useTaskCategories;
