import moment from 'moment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiFetch from '../../../utils/apiFetch';
import { convertStringToDate } from '../../../utils/convertDates';
import InputCalendar from '../formComponents/inputCalendar/InputCalendar';
import InputTextDebounce from '../formComponents/inputTextDebounce/InputTextDebounce';
import MultiSelect from '../formComponents/multiSelect/MultiSelect';
import Table from '../table/Table';
import TotalLegend from '../totalLegend/TotalLegend';
import './styles.scss';

type Filter = {
  inputTextKey?: string;
  date?: boolean;
  options?: SelectOptionFormat[];
  otherOptions?: any;
};

type Props = {
  i18key: string;
  url: string;
  values: any[];
  setValues: any;
  columns: any;
  parseData: any;
  organization?: string;
  filters?: Filter;
  total: number;
  total2?: number;
  total2Key?: string; // In case the result is not called total2 in the response of the backend
  setTotal: (newTotal: number, modifyLimit: boolean) => void;
  setTotal2?: (newTotal: number) => void;
  buttons?: React.ReactNode;
  hide?: boolean;
};

function InfiniteList({
  i18key,
  url,
  values,
  setValues,
  columns,
  parseData,
  organization,
  filters,
  total,
  total2,
  total2Key = 'total2',
  setTotal,
  setTotal2,
  buttons,
  hide
}: Props) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const makingRequest = useRef(false);

  const [loading, setLoading] = useState(true);

  const [inputText, setInputText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [otherFilters, setOtherFilters] = useState<SelectOptionFormat[]>([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const size = 15;

  let optionsString = '';

  if (filters?.options) {
    optionsString = otherFilters.reduce(
      (previousValue, currentValue) => `${previousValue}${currentValue.id},`,
      ''
    );
    optionsString = optionsString.slice(0, -1);
  }

  let otherOptions = '';

  if (filters?.otherOptions) {
    const keys = Object.keys(filters.otherOptions);
    const values = Object.values(filters.otherOptions);

    for (let i = 0; i < keys.length; i++) {
      otherOptions = `&${keys[i]}=${values[i]}`;
    }
  }

  // Fetch Data
  const fetchData = async () => {
    try {
      const operator = url.includes('?') ? '&' : '?';
      const inputTextFilter = filters?.inputTextKey ? `&${filters?.inputTextKey}=${inputText}` : '';
      let urlWithParams = `${url}${operator}page=${1}${inputTextFilter}&size=${size}&filters=${optionsString}${otherOptions}`;

      let startDateParsed = startDate ? convertStringToDate(startDate) : null;
      let endDateParsed = endDate ? convertStringToDate(endDate) : null;

      if (startDateParsed) {
        startDateParsed = new Date(moment(startDateParsed).format('YYYY-MM-DD'));
      }
      if (endDateParsed) {
        endDateParsed = new Date(moment(endDateParsed).format('YYYY-MM-DD'));
      }

      const startDateTimestamp = startDateParsed
        ? Math.floor(startDateParsed.getTime() / 1000)
        : null;
      const endDateTimestamp = endDateParsed ? Math.floor(endDateParsed.getTime() / 1000) : null;

      if (startDateTimestamp) {
        urlWithParams += `&start_date=${startDateTimestamp}`;
      }
      if (endDateTimestamp) {
        urlWithParams += `&end_date=${endDateTimestamp}`;
      }

      const response = await apiFetch('GET', urlWithParams, null, {
        'x-organization-id': organization
      });

      setLoading(false);
      setPage(2);

      const values = response.data.items;

      setValues(values);

      // If there are filters in the request we do not want to modify the limit
      const modifyLimit = startDate || endDate || inputText || optionsString ? false : true;
      setTotal(response.data.total, modifyLimit);
      if (response.data[total2Key] >= 0 && setTotal2) {
        setTotal2(response.data[total2Key]);
      }
      setHasMore(response.data.items.length < response.data.total);
    } catch (error) {
      setLoading(false);
      setTotal(0, false);
      setPage(1);
      setHasMore(false);
      setValues([]);
    }
  };

  const fetchMoreData = async () => {
    const operator = url.includes('?') ? '&' : '?';
    const inputTextFilter = filters?.inputTextKey ? `&${filters?.inputTextKey}=${inputText}` : '';

    let urlWithParams = `${url}${operator}page=${page}&size=${size}${inputTextFilter}&filters=${optionsString}${otherOptions}`;
    let startDateParsed = startDate ? convertStringToDate(startDate) : null;
    let endDateParsed = endDate ? convertStringToDate(endDate) : null;
    if (startDateParsed) {
      startDateParsed = new Date(moment(startDateParsed).format('YYYY-MM-DD'));
    }
    if (endDateParsed) {
      endDateParsed = new Date(moment(endDateParsed).format('YYYY-MM-DD'));
    }
    const startDateTimestamp = startDateParsed
      ? Math.floor(startDateParsed.getTime() / 1000)
      : null;
    const endDateTimestamp = endDateParsed ? Math.floor(endDateParsed.getTime() / 1000) : null;

    if (startDateTimestamp) {
      urlWithParams += `&start_date=${startDateTimestamp}`;
    }
    if (endDateTimestamp) {
      urlWithParams += `&end_date=${endDateTimestamp}`;
    }
    const response = await apiFetch('GET', urlWithParams, null, {
      'x-organization-id': organization
    });

    setLoading(false);
    const newValues = response.data.items;
    setValues((prev: any[]) => [...prev, ...newValues]);
    setPage((prev) => prev + 1);
    // If there are filters in the request we do not want to modify the limit
    const modifyLimit = startDate || endDate || inputText || optionsString ? false : true;
    setTotal(response.data.total, modifyLimit);
    if (response.data[total2Key] >= 0 && setTotal2) {
      setTotal2(response.data[total2Key]);
    }
    setHasMore(newValues.length < response.data.total);
  };

  // Use effect
  useEffect(() => {
    ref.current?.scrollTo(0, 0);
    fetchData();
  }, [inputText, startDate, endDate, otherFilters, otherOptions, url]);

  // On change
  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e?.target?.value);
  };
  const handleChangeStartDate = (date: string) => {
    setStartDate(date);
  };
  const handleChangeEndDate = (date: string) => {
    setEndDate(date);
  };

  const resetStartDate = () => {
    setStartDate('');
  };
  const resetEndDate = () => {
    setEndDate('');
  };

  const onChangeFilters = (value: SelectOptionFormat) => {
    const filtersOld = [...otherFilters];
    filtersOld.push(value);
    setOtherFilters(filtersOld);
  };
  const onRemoveFilters = (id: string) => {
    setOtherFilters(otherFilters.filter((element) => element?.id !== id));
  };

  const parsedValues = parseData(values);

  const handleScrollEnd = async () => {
    if (!ref.current || !hasMore || makingRequest.current === true) return;
    const { scrollTop, scrollHeight, clientHeight } = ref.current;

    if (scrollTop + clientHeight + 5 < scrollHeight) return;

    makingRequest.current = true;
    await fetchMoreData();
    makingRequest.current = false;
  };

  if (hide) return null;

  return (
    <div className='infinite-scroll-container card-border-color main-bg-color solid-border'>
      <div className='table-header'>
        {filters && Object.keys(filters).length > 0 && (
          <div className='table-filters'>
            {filters?.inputTextKey ? (
              <InputTextDebounce
                icon={'/images/icons/search.svg'}
                placeholder={t(`${i18key}.inputFilter`)}
                onChangeValue={onChangeSearchValue}
                height='24px'
                fontClass='input-smaller-font'
                size='small'
              />
            ) : null}
            {filters?.date ? (
              <InputCalendar
                mode='range'
                height='24px'
                fontClass='input-smaller-font'
                size='small'
                predefinedDates
                handleChangeStartDate={handleChangeStartDate}
                handleChangeEndDate={handleChangeEndDate}
                startDateValue={startDate}
                endDateValue={endDate}
                resetStartDate={resetStartDate}
                resetEndDate={resetEndDate}
              />
            ) : null}
            {filters?.options ? (
              <MultiSelect
                icon='/images/icons/filter.svg'
                placeholder={t(`${i18key}.filters`)}
                value={{ id: '', name: '' }}
                onChangeValue={onChangeFilters}
                onRemoveValue={onRemoveFilters}
                options={filters.options}
                height='24px'
                size='small'
                fontClass='input-smaller-font'
                selectedOptions={otherFilters}
              />
            ) : null}
          </div>
        )}
        <div className='table-tags'>
          {buttons ? <div className='buttons'>{buttons}</div> : null}
          <TotalLegend total={total} total2={total2} loading={loading} i18key={i18key} />
        </div>
      </div>
      <div className='infinite-scroll-wrapper' onScroll={handleScrollEnd} ref={ref}>
        <Table columns={columns} data={parsedValues} loading={loading} />
      </div>
    </div>
  );
}

export default InfiniteList;
