import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { EVENTS_ARTICLE, SIZE_PAGINATION_BIG } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import usePaginatedList from '../../../customHooks/paginatedList';
import { EventType } from '../../../types/entities/events';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import CardList from '../../ui/cardList/CardList';
import CardsFilter from '../../ui/filter/CardsFilter';
import HelpCTA from '../../ui/helpCTA/HelpCTA';
import Modal from '../../ui/modal/Modal';
import CreateEvent from './createEvent/CreateEvent';
import DeleteEvent from './deleteEvent/DeleteEvent';
import EditEvent from './editEvent/EditEvent';
import './styles.scss';

const Events = () => {
  const { t, i18n } = useTranslation();
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [eventToEdit, setEventToEdit] = useState<EventType>();
  const [eventToDelete, setEventToDelete] = useState('');
  const [loading, setLoading] = useState(true);
  const [listView, setListView] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );

  const size = SIZE_PAGINATION_BIG;
  const url = '/events';

  const filters = {
    inputTextList: undefined,
    startDate: undefined,
    endDate: undefined
  };

  // fetchPagination
  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values: events,
    setValues: setEvents,
    total
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: user?.selectedOrganization,
    filters
  });

  const today = new Date();
  const aWeekAgo = new Date();
  aWeekAgo.setDate(today.getDate() - 7);

  useEffect(() => {
    fetchData();
  }, [user]);

  const handleChangeListView = () => {
    setListView(!listView);
  };

  const handleHideModal = () => {
    setShowCreateEvent(false);
    setEventToEdit(undefined);
    setEventToDelete('');
  };

  const handleShowCreateEvent = () => {
    setShowCreateEvent(true);
  };

  const onClickCard = (id: string) => {
    navigate(`${ROUTES.MEASURE_EVENTS}/${id}`);
  };

  const addEvent = (value: EventType) => {
    let newEvents: EventType[] = [];
    if (events) {
      newEvents = [...events, value];
    }
    setEvents(newEvents);

    dispatch(setNotification(t('notification.eventCreated')));

    handleHideModal();
  };

  const editEvent = (event: EventType) => {
    const newEvents = events?.map((e) => {
      if (e.id === event.id) {
        return event;
      }
      return e;
    });
    setEvents(newEvents);
    dispatch(setNotification(t('notification.eventUpdated')));

    handleHideModal();
  };

  const deleteEvent = (id: string) => {
    const newEvents = events?.filter((e) => e.id !== id);
    setEvents(newEvents);
    dispatch(setNotification(t('notification.eventDeleted')));
    handleHideModal();
  };

  const handleEditEvent = (id: string) => {
    const foundEvent = events?.find((event) => event.id === id);
    if (foundEvent) {
      setEventToEdit(foundEvent);
    }
  };

  const navigateToArticle = () => {
    type EventsArticleUrl = {
      [key: string]: string;
    };
    const url = EVENTS_ARTICLE as unknown as EventsArticleUrl;
    window.open(url[i18n.resolvedLanguage]);
  };

  if (!foundOrganization) {
    return null;
  }

  return (
    <section className='events'>
      <SectionHeader title={t('events.title')} breadcrumb={<Breadcrumb />} />
      <HelpCTA description={t('events.howWeMeasureEventCarbonFootprint')}>
        <div className='flex events-help-buttons'>
          <Button
            onClick={navigateToArticle}
            text={t('events.methodology')}
            lookAndFeel={'primary'}
            size='tiny'
          />
        </div>
      </HelpCTA>
      <CardsFilter listView={listView} onChangeListView={handleChangeListView} />
      <div id={'event'} className='infinite-scroll-wrapper-card'>
        <InfiniteScroll
          dataLength={events ? events.length : 0}
          next={fetchMoreData}
          hasMore={hasMore}
          style={{ overflow: 'visible' }}
          endMessage={<></>}
          loader={undefined}
          scrollableTarget={'event'}>
          <CardList
            listView={listView}
            title={t('events.newEvent')}
            description={t('events.newEventDescription')}
            data={events.map((event) => ({
              id: event.id,
              title: event.name,
              description: event.location,
              co2e: event.co2e_pre,
              icon: '/images/icons/pin50.svg'
            }))}
            blocked={events && total >= foundOrganization?.limit_events}
            showCreateModal={handleShowCreateEvent}
            loading={loading}
            onClickCard={onClickCard}
            dropDownOptions={[
              {
                id: 'edit',
                name: t('events.edit'),
                onClick: handleEditEvent
              },
              {
                id: 'delete',
                name: t('events.delete'),
                onClick: setEventToDelete
              }
            ]}
            buttonText={t('events.create')}
          />
        </InfiniteScroll>
      </div>

      <Modal show={showCreateEvent} onClose={handleHideModal} width='600px' maxWidth='600px'>
        <CreateEvent addEvent={addEvent} />
      </Modal>
      <Modal show={!!eventToEdit} onClose={handleHideModal} width='600px' maxWidth='600px'>
        {eventToEdit && <EditEvent editEvent={editEvent} eventToEdit={eventToEdit} />}
      </Modal>
      <Modal show={!!eventToDelete} onClose={handleHideModal} width='600px' maxWidth='600px'>
        <DeleteEvent removeEvent={deleteEvent} eventToDelete={eventToDelete} />
      </Modal>
    </section>
  );
};

export default Events;
