import React from 'react';
import './styles.scss';
type Props = {
  value: string;
  tag: React.ReactNode;
  description: string;
  disabled?: boolean;
};
const Metric = ({ value, tag, description, disabled = false }: Props) => {
  return (
    <div className='metric'>
      {/* <div className='metric__line highlight-bg-color'></div> */}
      <div className='metric-info'>
        <div className='metric-info__header'>
          <div className='metric-info__value'>
            <span
              className={`${
                disabled ? 'disabled-text-color' : 'highlight-text-color'
              } co2-dashboard-font-small`}>
              {value}
            </span>
          </div>
          {tag}
        </div>
        <span className='subtitle3-font'>{description}</span>
      </div>
    </div>
  );
};

export default Metric;
