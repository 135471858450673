const useYearOptions = (
  yearsFilled: number[] = [],
  minYear = 2015,
  maxYear = new Date().getFullYear()
) => {
  const years = [];
  for (let i = maxYear; i >= minYear; i--) {
    if (!yearsFilled.includes(i)) {
      years.push({ id: i.toString(), name: i.toString() });
    }
  }
  return { years, maxYear: years[0].id };
};

export default useYearOptions;
