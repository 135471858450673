import { useTranslation } from 'react-i18next';
import Button from '../../../ui/button/Button';
import './styles.scss';
import { replaceStrongTags } from '../../../../utils/stringToBold';

interface Props {
  title: string;
  description: string;
  handleNext?: () => void;
  handleSkip?: () => void;
  index: number;
  total: number;
}

const TutorialStep = ({ title, description, handleNext, handleSkip, index, total }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='tutorial-modal-wrapper'>
      <div className='tutorial-header'>
        <h1 className='small-card-font'>{title}</h1>
        <h2 className='subtitle3-font '>{replaceStrongTags(description)}</h2>
      </div>
      <div className='tutorial-button-section'>
        {handleSkip && (
          <Button
            text={t('interactiveTutorial.skip')}
            lookAndFeel={'secondary'}
            height='small'
            onClick={handleSkip}
          />
        )}

        {handleNext && (
          <Button
            text={index !== total ? t('interactiveTutorial.next') : t('interactiveTutorial.finish')}
            lookAndFeel={'primary'}
            onClick={handleNext}
            height='small'
          />
        )}
        <div className='step-numbers-tutorial on-light-text-color input-smaller-font'>{`${index}/${total}`}</div>
      </div>
    </div>
  );
};

export default TutorialStep;
