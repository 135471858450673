import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../constants/routes';
import Button from '../../../ui/button/Button';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import CustomSkeletonLoader from '../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useAdapterGHG from './adapter/useAdapterGHG';
import useForm, { FormProvider } from './hooks/useForm';
import useOptions from './hooks/useOptions';
import useStepCarousel from '../../../ui/stepCarouselList/useStepCarousel';
import StepCarouselList from '../../../ui/stepCarouselList/StepCarouselList';
import GeneralInfo from './components/GeneralInfo';
import Description from './components/Description';
import Scopes from './components/Scopes';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import useErrors from '../../../../customHooks/useErrors';
import ErrorText from '../../../ui/errorText/ErrorText';
import { postGHGReportInfo } from '../../../../services/api/ghg';
import './styles.scss';
import checkFormErrors from '../../../../utils/checkFormErrors';
import { useDispatch } from 'react-redux';
import { updateOrganizationInfo } from '../../../../actions/auth';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import Icon from '../../../ui/icon/Icon';
import { getIntensityMetricsByIdPaginated } from '../../../../services/api/intensityMetrics';
import { convertDateBackToFront } from '../../../../utils/convertDates';
import FormCalendarDoubleSelect from '../../../ui/formComponents2/formInputs/formCalendarDoubleSelect/FormCalendarDoubleSelect';
import DisclaimerConfirm from './DisclaimerConfirm';
import Modal from '../../../ui/modal/Modal';

const STEPS = [
  {
    id: 'generalInfo',
    text: 'orgDescriptionModal.ghg_report.form.generalInfo.title'
  },
  {
    id: 'description',
    text: 'orgDescriptionModal.ghg_report.form.description.title'
  },
  {
    id: 'scopes',
    text: 'orgDescriptionModal.ghg_report.form.scopes.title'
  }
];

type Props = {
  onClose: () => void;
};

function GHGReportDataForm({ onClose }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'orgDescriptionModal.ghg_report' });
  const { t: tG } = useTranslation();
  const ERRORS = useErrors();
  const { adaptFront2Back } = useAdapterGHG();
  const dispatch = useDispatch();
  const selectedOrganization = useSelectedOrganization();

  const [error, setError] = useState<ErrorType>();

  const {
    intensityMetricOptions,
    approachOptions,
    verificationOptions,
    loading: loadingOptions
  } = useOptions();

  const {
    loadingData,
    isAllSelected,
    formData,
    setFormData,
    onChangeValue,
    onChangeScopes,
    onChangeCategories,
    selectAll
  } = useForm();

  // ñapa in order to set the values from the options
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      approach: (approachOptions.find((elem) => elem.id === prev.approach.id) as SelectOptionFormat<
        '' | 'operational' | 'financial'
      >) || {
        id: '',
        name: ''
      },
      verification: (verificationOptions.find(
        (element) => element.id === prev.verification.id
      ) as SelectOptionFormat<'eqa' | '' | 'none' | 'other'>) || {
        id: '',
        name: ''
      },
      intensityMetric1: intensityMetricOptions.find(
        (elem) => elem.id === prev.intensityMetric1.id
      ) || {
        id: '',
        name: ''
      },
      intensityMetric2: intensityMetricOptions.find(
        (elem) => elem.id === prev.intensityMetric2.id
      ) || {
        id: '',
        name: ''
      }
    }));
  }, [JSON.stringify(intensityMetricOptions)]);

  const { stepSelected, steps, handleSelect, handleNext } = useStepCarousel({
    stepsText: STEPS
  });

  const [loadingButtonSubmit, setLoadingButtonSubmit] = useState(false);
  const [loadingButtonNext, setLoadingButtonNext] = useState(false);
  const [loadingButtonCreateReport, setLoadingButtonCreateReport] = useState(false);

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [queryParams, setQueryParams] = useState({
    intensity_metric_id_1: '',
    intensity_metric_id_2: ''
  });

  const postData = async (data: IGHGFront) => {
    if (!selectedOrganization?.id) return;
    const dataAdapted = adaptFront2Back(data);

    const response = await postGHGReportInfo(dataAdapted);
    await dispatch(
      updateOrganizationInfo(selectedOrganization.id, {
        description: formData.cnae,
        trade_name: formData.tradeName
      })
    );

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }
    return response;
  };

  const handleErrors = () => {
    const optionals = ['intensityMetric2', 'otherAssumptions', 'scopes'];

    if (formData.changeBaseYear.id === 'no') optionals.push('justificationChangeBaseYear');
    if (formData.verification.id !== 'other') optionals.push('verificationOther');

    const errors = checkFormErrors(formData, [], optionals);

    if (formData.categories.every((category) => !category.selected))
      errors.push({
        error: 'categories',
        description: tG('error.requiredField')
      });

    setFormData((prev) => ({ ...prev, errors }));
    return errors.length ? errors : false;
  };

  const onSubmit = async () => {
    if (loadingButtonCreateReport || loadingButtonSubmit) return;
    setLoadingButtonSubmit(true);
    await postData(formData);
    setLoadingButtonSubmit(false);
    // TODO setNotification
    onClose();
  };

  const onSubmitNext = async () => {
    if (loadingButtonCreateReport || loadingButtonSubmit) return;
    setLoadingButtonNext(true);
    await postData(formData);
    setLoadingButtonNext(false);
    handleNext();
  };

  const onSubmitCreateReport = async () => {
    if (loadingButtonCreateReport || loadingButtonSubmit || handleErrors()) return;
    // let foundIntensityMetric1 = undefined;
    // let foundIntensityMetric2 = undefined;
    const showDisclaimerAux = false;
    // get intensity metrics inside intensity metric groups selected
    // if (formData.intensityMetric1.id) {
    //   const intensityMetrics1 = await getIntensityMetricsByIdPaginated(
    //     formData.intensityMetric1.id,
    //     1,
    //     100
    //   );

    //   foundIntensityMetric1 = intensityMetrics1.items.find(
    //     (elem: IntensityMetricReport) =>
    //       convertDateBackToFront(elem.start_date) === formData.startDate &&
    //       convertDateBackToFront(elem.end_date) === formData.endDate
    //   );

    //   if (!foundIntensityMetric1) {
    //     showDisclaimerAux = true;
    //     setShowDisclaimer(true);
    //   }
    // }
    // if (formData.intensityMetric2.id) {
    //   const intensityMetrics2 = await getIntensityMetricsByIdPaginated(
    //     formData.intensityMetric2.id,
    //     1,
    //     100
    //   );
    //   foundIntensityMetric2 = intensityMetrics2.items.find(
    //     (elem: IntensityMetricReport) =>
    //       convertDateBackToFront(elem.start_date) === formData.startDate &&
    //       convertDateBackToFront(elem.end_date) === formData.endDate
    //   );

    //   if (!foundIntensityMetric2) {
    //     showDisclaimerAux = true;
    //     setShowDisclaimer(true);
    //   }
    // }
    setLoadingButtonCreateReport(true);
    const response = await postData(formData);
    setLoadingButtonCreateReport(false);
    if (!response) return;

    const queryParams = {
      intensity_metric_id_1: formData.intensityMetric1.id ?? '',
      intensity_metric_id_2: formData.intensityMetric2.id ?? ''
    };

    setQueryParams(queryParams);

    if (!showDisclaimerAux) {
      // TODO setNotification
      window.open(
        `${ROUTES.COMMUNICATE_GHG}?intensity_metric_id_1=${queryParams.intensity_metric_id_1}&intensity_metric_id_2=${queryParams.intensity_metric_id_2}`,
        '_blank'
      );
    }
  };

  const generalInfoField: string[] = [
    'year',
    'tradeName',
    'contactInformation',
    'baseYear',
    'changeBaseYear',
    'justificationChangeBaseYear',
    'verification',
    'verificationOther',
    'approach',
    'intensityMetric1',
    'intensityMetric2'
  ];
  const generalInfoErrors = formData.errors.filter((elem) => generalInfoField.includes(elem.error));

  const descriptionField: string[] = ['cnae', 'thresholds'];
  const descriptionErrors = formData.errors.filter((elem) => descriptionField.includes(elem.error));

  const scopesField: string[] = ['scopes', 'justificationScopes'];
  const scopesErrors = formData.errors.filter((elem) => scopesField.includes(elem.error));

  const errorsCarousel: string[] = [];
  if (generalInfoErrors.length) errorsCarousel.push('generalInfo');
  if (descriptionErrors.length) errorsCarousel.push('description');
  if (scopesErrors.length) errorsCarousel.push('scopes');

  // If loading information, show skeleton loader
  if (loadingData)
    return (
      <div className='organization-description-form'>
        <FormHeader title={t(`title`)} />
        <CustomSkeletonLoader count={7} />
      </div>
    );

  return (
    <div className='organization-description-form'>
      <FormHeader title={t(`title`)} />
      <FormWrapper
        style={{
          maxHeight: '475px',
          overflow: 'auto'
        }}>
        <FormCalendarDoubleSelect
          label={tG('orgDescriptionModal.selectDateRange')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          startDate={formData.startDate ? (formData.startDate as string) : ''}
          endDate={formData.endDate ? (formData.endDate as string) : ''}
          error={formData.errors.find(
            (error) =>
              error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
          )}
          width='50%'
        />
        <FormElementFull>
          <StepCarouselList steps={steps} handleSelect={handleSelect} errors={errorsCarousel} />
        </FormElementFull>
        <FormProvider
          value={{
            loadingData,
            isAllSelected,
            formData,
            onChangeValue,
            onChangeScopes,
            onChangeCategories,
            selectAll,
            intensityMetricOptions,
            approachOptions,
            verificationOptions,
            loadingOptions,
            onClose,
            postData
          }}>
          {stepSelected?.id === 'generalInfo' && <GeneralInfo />}
          {stepSelected?.id === 'description' && <Description />}
          {stepSelected?.id === 'scopes' && <Scopes />}
        </FormProvider>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'secondary'}
          onClick={onSubmit}
          text={t(`saveAndClose`)}
          size='medium'
          loading={loadingButtonSubmit}
        />
        {stepSelected?.id !== 'scopes' && (
          <Button
            lookAndFeel={'primary'}
            onClick={onSubmitNext}
            text={t(`saveAndNext`)}
            size='medium'
            loading={loadingButtonNext}
          />
        )}
        {stepSelected?.id === 'scopes' && (
          <Button
            lookAndFeel={'primary'}
            iconNode={<Icon icon={'download'} color={'white'} />}
            onClick={onSubmitCreateReport}
            text={t(`saveAndContinue`)}
            size='medium'
            loading={loadingButtonCreateReport}
          />
        )}
      </FormButtonSection>
      <ErrorText>{error?.description}</ErrorText>
      <Modal.WithPortal
        show={showDisclaimer}
        onClose={() => setShowDisclaimer(false)}
        width='584px'
        maxWidth='584px'>
        <DisclaimerConfirm handleClose={() => setShowDisclaimer(false)} queryParams={queryParams} />
      </Modal.WithPortal>
    </div>
  );
}

export default GHGReportDataForm;
