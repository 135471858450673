import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyContent = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  return (
    <>
      <img src='/images/notifications/empty.svg' alt='empty' />
      <span className='font-14 regular-font'>{t('emptyCase')}</span>
    </>
  );
};

export default EmptyContent;
