import { useTranslation } from 'react-i18next';
import { EventFood } from '../../../../../../types/entities/events';
import FormElementFull from '../../../../../ui/formComponents/formElementFull/FormElementFull';
import InputMultiple from '../../../../../ui/formComponents/inputMultiple/InputMultiple';
import InputNumber from '../../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../../ui/formComponents/inputText/InputText';

type Props = {
  icon: string;
  onChangeValue: (value: EventFood) => void;
  data: EventFood[];
  type: string;
};
const InputFood = ({ icon, onChangeValue, data, type }: Props) => {
  const { t } = useTranslation();

  const meatValue = data.find((elem) => elem.dish === 'meat' && elem.type === type);
  const fishValue = data.find((elem) => elem.dish === 'fish' && elem.type === type);
  const vegetarianValue = data.find((elem) => elem.dish === 'vegetarian' && elem.type === type);

  const onChangeMeat = (value: string) => {
    onChangeValue({
      type,
      dish: 'meat',
      quantity: +value
    });
  };

  const onChangeFish = (value: string) => {
    onChangeValue({
      type,
      dish: 'fish',
      quantity: +value
    });
  };

  const onChangeVegetarian = (value: string) => {
    onChangeValue({
      type,
      dish: 'vegetarian',
      quantity: +value
    });
  };

  return (
    <FormElementFull>
      <InputMultiple
        inputs={[
          {
            input: <InputText icon={icon} placeholder={''} value={t(`eventData.${type}`)} />,
            label: t('eventData.typeUnits')
          },
          {
            input: (
              <InputNumber
                icon={''}
                placeholder={'0'}
                value={meatValue?.quantity ? meatValue?.quantity.toString() : ''}
                onChangeValue={onChangeMeat}
              />
            ),
            label: t('eventData.meat'),
            tooltip: t('eventData.meatTooltip')
          },
          {
            input: (
              <InputNumber
                icon={''}
                placeholder={'0'}
                value={fishValue?.quantity ? fishValue?.quantity.toString() : ''}
                onChangeValue={onChangeFish}
              />
            ),
            label: t('eventData.fish'),
            tooltip: t('eventData.fishTooltip')
          },
          {
            input: (
              <InputNumber
                icon={''}
                placeholder={'0'}
                value={vegetarianValue?.quantity ? vegetarianValue?.quantity.toString() : ''}
                onChangeValue={onChangeVegetarian}
              />
            ),
            label: t('eventData.vegetables'),
            tooltip: t('eventData.vegetablesTooltip')
          }
        ]}
      />
    </FormElementFull>
  );
};

export default InputFood;
