import './styles.scss';

type Bar = {
  value: number;
  color: string;
};
type Props = {
  total: number;
  data: Bar;
};
function StackedBarVertical({ total, data }: Props) {
  return (
    <div className='stacked-bar-vertical'>
      <div
        className='bar highlight-bg-color50'
        style={{
          height: `${(data.value * 100) / total}%`,
          backgroundColor: data.color,
          zIndex: 1
        }}></div>
    </div>
  );
}

export default StackedBarVertical;
