import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { getPurchaseSuppliers } from '../../../../../../services/api/purchaseSuppliers';
import { generateQueryParamsFromObject, IFilter } from '../../../../../../utils/url';
import { getPurchaseSuppliersAdapter } from '../adapters/getPurchaseSupplierAdapter';
import { IPurchaseSupplierBackend } from '../../../../../../types/purchaseSupplier';

type Params = {
  filters: IFilter[];
};

const makeFetch = async ({ filters, page }: Params & { page: number }) => {
  const queryParams = generateQueryParamsFromObject({ page });

  const response = await getPurchaseSuppliers({ queryParams, filters });

  return response;
};

export const useGetSuppliers = ({ filters }: Params) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, refetch, fetchNextPage } = useInfiniteQuery({
    queryKey: ['purchaseSuppliers', JSON.stringify(filters)],
    queryFn: ({ pageParam }) => makeFetch({ filters, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    select: (data) => {
      const formattedPages = data.pages.reduce(
        (acc, current) => {
          return {
            page: current.page,
            total: current.total,
            total2: current.total2,
            size: current.size,
            items: [...acc.items, ...current.items]
          };
        },
        { page: 1, total: 0, total2: 0, size: 0, items: [] }
      );

      return formattedPages;
    }
  });

  const setTotal = (total: number) => {
    queryClient.setQueryData(
      ['purchaseSuppliers', JSON.stringify(filters)],
      (
        data: InfiniteData<
          Pagination<IPurchaseSupplierBackend, { total2: number; size: number }>,
          number
        >
      ) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => ({ ...page, total }))
        };
      }
    );
  };

  const editElement = (element: IPurchaseSupplierBackend) => {
    queryClient.setQueryData(
      ['purchaseSuppliers', JSON.stringify(filters)],
      (
        data: InfiniteData<
          Pagination<IPurchaseSupplierBackend, { total2: number; size: number }>,
          number
        >
      ) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => ({
            ...page,
            items: page.items.map((item) => {
              if (item.id === element.id) {
                return element;
              }

              return item;
            })
          }))
        };
      }
    );
  };

  return {
    data: data ? getPurchaseSuppliersAdapter(data?.items) : [],
    fetchData: fetchNextPage,
    firstLoading: isLoading,
    loading: isFetching,
    editElement,
    total: data?.total ?? 0,
    total2: data?.total2 ?? 0,
    setTotal,
    refreshData: refetch
  };
};
