/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { gray_color, scope1_color, scope2_color } from '../../../styles/colors';
import { numberToScientificNotation } from '../../../utils/numberToDecimal';
import TooltipChart from '../tooltip/TooltipChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {
  chartData: any;
  disabled: boolean;
};
export const SingleBarChart = ({ chartData, disabled }: Props) => {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const chart = chartRef.current;

    if (disabled) {
      chart.data.datasets[0].backgroundColor = gray_color;
      chart.data.datasets[0].borderColor = gray_color;
      return;
    }

    if (chart) {
      const ctx = chart.ctx;

      // Create a gradient
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, scope2_color);
      gradient.addColorStop(1, scope1_color);

      // Update dataset with gradient
      chart.data.datasets[0].backgroundColor = gradient;
      chart.data.datasets[0].borderColor = gradient;
      chart.update();
    }
  }, [disabled]);

  const options: any = {
    devicePixelRatio: 4,
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x1: {
        position: 'bottom',
        grid: {
          display: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          backdropColor: 'red',
          font: function (context: any) {
            const size = 10;
            return {
              size,
              family: 'Inter',
              weight: 400,
              lineHeight: 1.2
            };
          },
          padding: 0,
          margin: 0
        }
      },
      x2: {
        position: 'bottom',
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: function () {
            const size = 10;
            return {
              size,
              family: 'Inter',
              weight: 400,
              lineHeight: 1.2
            };
          },
          padding: 0,
          callback: function (index: number) {
            const value = disabled ? 0 : chartData[0][index].value;

            if (!value || value === 'undefined') return 0;
            return numberToScientificNotation(Number(value));
          }
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          font: {
            size: 0,
            family: 'Inter',
            weight: 400,
            lineHeight: 1.2
          }
        },
        min: 0
      }
    },
    hover: {
      onHover: (e: any) => {
        const el = document.getElementById('canvas1');
        if (el) el.style.cursor = e[0] ? 'pointer' : 'default';
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,

        external: function (context: any) {
          if (chartRef && chartRef.current) {
            const tooltip = chartRef.current.tooltip;

            if (tooltip.opacity === 0) {
              setShowTooltip(false);
            } else {
              // assuming your tooltip is `position: fixed`
              // set position of tooltip
              const position = context.chart.canvas.getBoundingClientRect();
              const left = position.left + window.scrollX + tooltip.caretX;
              const top = position.top + window.scrollY + tooltip.caretY - 20;

              setLeft(left);
              setTop(top);

              if (disabled) {
                setValue('0 kg CO₂ eq.');
                setShowTooltip(false);
              } else {
                setShowTooltip(false);
              }
            }
          }
        }
      }
    },
    font: {
      size: 10,
      family: 'Inter',
      weight: 400,
      lineHeight: 1.2
    }
  };
  const datasets = [
    {
      label: 'company1',
      data: chartData[0].map((elem: any) => elem.value),
      barThickness: 12,
      borderRadius: 8
    }
  ];

  const data = {
    labels: chartData[0].map((elem: any) => elem.label),
    datasets
  };

  return (
    <>
      {showTooltip && <TooltipChart text={value} top={top} left={left} />}

      <div>
        <Bar options={options} data={data} ref={chartRef} style={{ maxWidth: '100%' }} />
      </div>
    </>
  );
};
