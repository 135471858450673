import { createContext, useContext } from 'react';
import { ColorPaletteKey } from '../../types/ColorPalette';

type IColorPaletteContext = {
  colorPalette: ColorPaletteKey | undefined;
  setColorPalette: (colorPalette: ColorPaletteKey | undefined) => void;
};
const ColorPaletteContext = createContext<IColorPaletteContext | undefined>(undefined);

type ColorPaletteProviderProps = IColorPaletteContext & {
  children: React.ReactNode;
};

export const ColorPaletteProvider: React.FC<ColorPaletteProviderProps> = ({
  colorPalette,
  setColorPalette,
  children
}) => {
  return (
    <ColorPaletteContext.Provider value={{ colorPalette, setColorPalette }}>
      {children}
    </ColorPaletteContext.Provider>
  );
};

export const useColorPalette = () => {
  const context = useContext(ColorPaletteContext);
  if (!context) {
    return {
      colorPalette: undefined,
      setColorPalette: () => {
        throw new Error('Cannot set color palette outside of ColorPaletteProvider');
      }
    };
  }

  return context;
};
