import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import useGetBase64 from '../../../customHooks/useGetBase64';
import useOnChangeValue from '../../../customHooks/useOnChangeValue';

import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import { uploadFilePresignedUrl } from '../../../services/api/aws';
import { getPresignedUrlBusinessTravels } from '../../../services/api/businessTravels';
import checkFormErrors from '../../../utils/checkFormErrors';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../layout/mainPageLayout/MainPageLayout';
import Button from '../../ui/button/Button';
import ErrorList from '../../ui/errorList/ErrorList';
import FormButtonSection from '../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../ui/formComponents/inputFile/InputFileSingle';
import InputText from '../../ui/formComponents/inputText/InputText';
import Modal from '../../ui/modal/Modal';
import ErrorLabel from '../../ui/statusLabels/errorLabel/ErrorLabel';
import DeleteBusinessTravel from './deleteBusinessTravel/DeleteBusinessTravel';
import GetBusinessTravelError from './getBusinessTravelError/GetBusinessTravelError';
import './styles.scss';

type FormData = {
  organizationId: string;
  file: File | null;
  errors: ErrorType[];
};
const commonInputProps = {
  height: '31px',
  size: 'small' as 'small' | 'big' | undefined
};

const BusinessTravelUpload = () => {
  const { t } = useTranslation();

  const flags = useFeatureFlags();
  const [formData, setFormData] = useState<FormData>({
    organizationId: '',
    file: null,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGetBusinessTravelWithErrors, setShowGetBusinessTravelWithErrors] = useState(false);
  const [showErrorsUpload, setShowErrorsUpload] = useState(false);
  const [requestErrors, setRequestErrors] = useState<UploadFileBulkError[]>([]);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { fileBase64 } = useGetBase64(formData.file as File);

  const dispatch = useDispatch();

  const handleShowErrorsUpload = () => setShowErrorsUpload(true);
  const handleHideErrorsUpload = () => setShowErrorsUpload(false);

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  const handleSubmit = async () => {
    // Call api to upload CSV file after converting to base64
    if (handleErrors()) return;
    if (!formData.file || !fileBase64) {
      return;
    }
    setLoadingButton(true);
    setRequestErrors([]);

    const data = await getPresignedUrlBusinessTravels({
      data: fileBase64 as string,
      file_name: formData.file?.name ?? 'business_travel.csv'
    });
    const response = await uploadFilePresignedUrl(formData.file, data.upload_url);

    setLoadingButton(false);
    if (!response) {
      dispatch(setNotification(t('error.somethingWentWrong')));
      setLoadingButton(false);
      return;
    }
    if ('invalid_csv_file' in data) {
      setRequestErrors(data.invalid_csv_file);
      setLoadingButton(false);
      return;
    }
    dispatch(setNotification(t('businessTravelUpload.success')));
  };

  const downloadTemplateCsv = async () => {
    // Download the csv file in public/files/businessTravel/business_travels_template_Dcycle.csv
    const link = document.createElement('a');
    link.href =
      process.env.PUBLIC_URL + '/files/businessTravel/business_travels_template_Dcycle.csv';
    link.setAttribute('download', 'business_travel_template.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDelete = async () => {
    // Call api to upload CSV file after converting to base64
    const errors = checkFormErrors(formData, formData.errors, ['file']);
    if (errors.length > 0) {
      setFormData({
        ...formData,
        errors
      });
      return;
    }
    setShowDeleteModal(true);
  };

  const handleShowGetBusinessTravelWithErrors = () => {
    setShowGetBusinessTravelWithErrors(true);
  };

  const handleHideModal = () => {
    setShowDeleteModal(false);
    setShowGetBusinessTravelWithErrors(false);
  };

  if (!flags?.businessTravelUpload) {
    return null;
  }

  return (
    <>
      <MainPageLayout
        sectionTitle={t('businessTravelUpload.title')}
        title={t('businessTravelUpload.createTemplateTitle')}
        description={t('businessTravelUpload.createTemplateStartDescription')}
        breadcrumb={<Breadcrumb />}
        buttons={
          <>
            <Button
              lookAndFeel='primary'
              text={t('businessTravelUpload.createTemplate')}
              onClick={downloadTemplateCsv}
              size='small'
            />
            <Button
              lookAndFeel='secondary'
              onClick={handleShowGetBusinessTravelWithErrors}
              text={t('businessTravelUpload.getBusinessTravelWithErrors')}
              size='small'
            />
          </>
        }>
        <div className='businessTravel-upload card'>
          <FormWrapper>
            <InputText
              {...commonInputProps}
              icon={'/images/icons/organization.svg'}
              placeholder={t('businessTravelUpload.organizationId')}
              label={t('businessTravelUpload.organizationId')}
              value={formData.organizationId}
              onChangeValue={onChangeValue('organizationId')}
              error={formData.errors.find((error) => error.error === 'organizationId')}
            />
            <FormElementFull>
              <InputFileSingle
                handleFile={onChangeValue('file')}
                buttonText={t('businessTravelUpload.upload')}
                labelText={t('businessTravelUpload.uploadLabel')}
                fileName={formData.file?.name || ''}
                allowedTypes={['text/csv']}
                error={formData.errors.find((error) => error.error === 'file')}
              />
            </FormElementFull>
          </FormWrapper>
          <FormButtonSection>
            <Button
              lookAndFeel='primary'
              text={t('businessTravelUpload.save')}
              onClick={handleSubmit}
              loading={loadingButton}
            />
            <Button
              lookAndFeel='secondary'
              text={t('businessTravelUpload.delete')}
              onClick={handleDelete}
            />
          </FormButtonSection>
          {requestErrors.length > 0 && (
            <div className='error-wrapper'>
              <ErrorLabel onClick={handleShowErrorsUpload}>
                {t('businessTravels.rowsWithErrors', { rowNumber: requestErrors.length })}
              </ErrorLabel>
            </div>
          )}
        </div>
      </MainPageLayout>
      <Modal show={showDeleteModal} onClose={handleHideModal}>
        <DeleteBusinessTravel
          organizationId={formData.organizationId}
          deleteCallback={handleHideModal}
        />
      </Modal>
      <Modal show={showGetBusinessTravelWithErrors} onClose={handleHideModal}>
        <GetBusinessTravelError />
      </Modal>
      <Modal show={showErrorsUpload} onClose={handleHideErrorsUpload} width='600px'>
        <ErrorList
          data={requestErrors}
          columns={[
            {
              title: 'name',
              dataIndex: 'name',
              key: 'name'
            },
            {
              title: t('error.rowNumber'),
              dataIndex: 'row_index',
              key: 'row_index'
            },
            {
              title: 'Error',
              dataIndex: 'error',
              key: 'error'
            }
          ]}
        />
      </Modal>
    </>
  );
};

export default BusinessTravelUpload;
