import { Dispatch, SetStateAction } from 'react';
import { EventMiscellanous, PreEventFormData } from '../../../../../../types/entities/events';
import FormWrapper from '../../../../../ui/formComponents/formWrapper/FormWrapper';
import InputPurchase from './InputPurchase';

type Props = {
  setFormData: Dispatch<SetStateAction<PreEventFormData>>;
  formData: EventMiscellanous[];
};
const Purchases = ({ setFormData, formData }: Props) => {
  const purchasesTypes = ['paper', 'plastic', 'cardboard', 'clothing'];

  const getIconSrc = (type: string) => {
    type Icon = {
      [key: string]: string;
    };
    const icons: Icon = {
      paper: '/images/icons/tag.svg',
      cardboard: '/images/icons/cardBox.svg',
      plastic: '/images/icons/stacked50.svg',
      clothing: '/images/icons/tshirt.svg'
    };
    return icons[type];
  };

  const onChangeValue = (value: EventMiscellanous) => {
    const newFormData = formData.filter((elem) => elem.type !== value.type);
    newFormData.push(value);
    setFormData((prev) => ({
      ...prev,
      purchases: newFormData
    }));
  };
  return (
    <div className='pre-event-purchases'>
      <FormWrapper>
        {purchasesTypes.map((purchase) => (
          <InputPurchase
            key={purchase}
            type={purchase}
            icon={getIconSrc(purchase)}
            value={formData.find((elem) => elem.type === purchase)?.quantity.toString() ?? ''}
            onChangeValue={onChangeValue}
          />
        ))}
      </FormWrapper>
    </div>
  );
};

export default Purchases;
