import { useTranslation } from 'react-i18next';
import './styles.scss';

const Name = ({ name }: { name: string }) => {
  const { t } = useTranslation();

  const renderIcon = (type: string) => {
    const icons = {
      car: {
        src: '/images/icons/car.svg',
        alt: 'car'
      },
      train: {
        src: '/images/icons/train.svg',
        alt: 'train'
      },
      walking: {
        src: '/images/icons/person.svg',
        alt: 'walking'
      },
      remaining: {
        src: '/images/icons/people.svg',
        alt: 'remaining'
      }
    };

    type =
      type === 'metro' || type === 'train' || type === 'bus' || type === 'trolleybus'
        ? 'train'
        : type;

    return icons[type as keyof typeof icons] ?? icons['remaining'];
  };

  return (
    <div className='name-employee-report-table'>
      <img {...renderIcon(name)} />
      <span className='name-logo body1-bold-font'>{t(`report.${name}`)}</span>
    </div>
  );
};

export default Name;
