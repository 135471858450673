import apiFetch from '../../utils/apiFetch';

export const getOrganizationFiles = async (organizationId: string, fileType: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/organization_files/${organizationId}`,
      null,
      {
        'x-organization-id': organizationId
      },
      {
        file_type: fileType
      }
    );
    return response.data;
  } catch (error) {
    return null;
  }
};
