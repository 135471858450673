import { HTMLAttributes } from 'react';
import './styles.scss';
import { IconName } from '../../../types/utils/iconName';
import Icon from '../icon/Icon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  icon?: string;
  iconV2?: IconName;
  alt?: string;
  onClick?: () => void;
  selected?: boolean;
  round?: boolean;
}

// make props extend HTMLAttributes<HTMLDivElement>
function IconBackground({ icon, iconV2, alt, onClick, selected = false, round, ...rest }: Props) {
  const renderIcon = () => {
    if (!icon && !iconV2) throw Error('Must have at least one icon');
    if (iconV2) return <Icon icon={iconV2} color={'gradient'} />;
    return <img src={icon} alt={alt} />;
  };

  return (
    <div
      className={`icon-wrapper ${selected ? 'highlight-bg-color' : 'icon-bg-color'} ${
        round ? 'round' : ''
      } ${onClick ? 'pointer' : ''}`}
      onClick={onClick}
      {...rest}>
      {renderIcon()}
    </div>
  );
}

export default IconBackground;
