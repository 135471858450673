import {
  ColumnAPI,
  DropdownOptionAPI,
  LanguageType,
  OnEntryChange,
  OnEntryInit,
  RejectSubmitResult,
  Row
} from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { countriesNuvo } from '../../../../../../../constants/countriesNuvo';
import useErrors from '../../../../../../../customHooks/useErrors';
import useNuvoFileName from '../../../../../../../customHooks/useNuvoFileName';
import { uploadFilePresignedUrl } from '../../../../../../../services/api/aws';
import { getPresignedUrlLogistics } from '../../../../../../../services/api/logistics';
import { postNuvoAnalytics } from '../../../../../../../services/api/nuvoAnalytics';
import { basicNuvoStyle } from '../../../../../../../styles/nuvo';
import { transformNuvoResultsIntoCsv, validateDateFields } from '../../../../../../../utils/nuvo';
import NuvoImporter from '../../../../../../ui/nuvoImporter/NuvoImporter';
import useNuvoButton from '../../../../../../../customHooks/useNuvoButton';
import { ITOCFrontend } from '../../../../../../../types/entities/logistics';
import moment from 'moment';

type Props = {
  lookAndFeel?: 'primary' | 'secondary';
  tocs: ITOCFrontend[];
};

const InputNuvoRecharges = ({ lookAndFeel, tocs }: Props) => {
  const { t, i18n } = useTranslation();
  const ERRORS = useErrors();

  const category = 'logistics_recharges';

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName({ lookAndFeel });
  useNuvoButton({ onClick: handleGetFileName, lookAndFeel });

  const tocsDropdownOptions: DropdownOptionAPI[] = tocs?.map((toc: ITOCFrontend) => ({
    label: toc.name_translated,
    value: toc.name,
    type: 'string'
  }));

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'country',
      label: t('templates.logistics_recharges.country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'vehicle_license_plate',
      label: t('templates.logistics_recharges.vehicle_license_plate'),
      columnType: 'string'
    },
    {
      key: 'recharge_date',
      label: t('templates.logistics_recharges.date'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD'
    },
    {
      key: 'quantity',
      label: t('templates.logistics_recharges.quantity'),
      columnType: 'float',
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'fuel',
      label: t('templates.logistics_recharges.fuel'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.logistics_recharges.fuel_types.marine_diesel_oil'),
          value: 'marine_diesel_oil',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.marine_gas_oil'),
          value: 'marine_gas_oil',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.gasoline'),
          value: 'gasoline',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.bio_ethanol'),
          value: 'bio_ethanol',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.gasoline_5_bio_ethanol'),
          value: 'gasoline_5_bio_ethanol',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.diesel'),
          value: 'diesel',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_1'),
          value: 'b_1',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_2'),
          value: 'b_2',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_5'),
          value: 'b_5',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_7'),
          value: 'b_7',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_10'),
          value: 'b_10',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_20'),
          value: 'b_20',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_50'),
          value: 'b_50',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.b_100'),
          value: 'b_100',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.hvo'),
          value: 'hvo',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.liquefied_petroleum_gas'),
          value: 'liquefied_petroleum_gas',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.compressed_natural_gas'),
          value: 'compressed_natural_gas',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.liquefied_natural_gas'),
          value: 'liquefied_natural_gas',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.biomethane'),
          value: 'biomethane',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.bio_liquefied_natural_gas'),
          value: 'bio_liquefied_natural_gas',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.heavy_fuel_oil'),
          value: 'heavy_fuel_oil',
          type: 'string'
        },
        {
          label: t('templates.logistics_recharges.fuel_types.aviation_fuel'),
          value: 'aviation_fuel',
          type: 'string'
        }
      ],
      validations: [
        {
          validate: 'required'
        }
      ],
      isMultiSelect: false
    },
    {
      key: 'toc',
      label: t('templates.logistics_recharges.toc'),
      columnType: 'category',
      dropdownOptions: tocsDropdownOptions,
      validations: [
        {
          validate: 'required'
        }
      ],
      isMultiSelect: false
    }
  ];

  const validateRow: OnEntryInit = (row: Row) => {
    const errors = {} as any;

    const minYearErrors = validateDateFields(['recharge_date'])(row);

    for (const key in minYearErrors) {
      errors[key] = minYearErrors[key];
    }

    // check that recharge_date is not in the future
    const recharge_date = row.recharge_date as string;
    const momentTripDate = recharge_date ? moment(recharge_date, 'YYYY-MM-DD') : null;
    if (momentTripDate && momentTripDate.isAfter(moment())) {
      errors.recharge_date = {
        value: recharge_date,
        info: [
          {
            level: 'error',
            message: t('error.nuvo.futureDate')
          }
        ]
      };
    }

    // check that quantity is not negative
    const quantity = row.quantity as number;
    if (quantity && quantity < 0) {
      errors.quantity = {
        value: quantity,
        info: [
          {
            level: 'error',
            message: t('error.nuvo.valueMustBePositive')
          }
        ]
      };
    }

    return errors;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => Object.keys(validateRow(row.data, row.rowIndex) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validateRow(row.data, row.rowIndex)
          }
        };
      });
  };

  return (
    <NuvoImporter
      lookAndFeel={lookAndFeel}
      btnI18nKey='logistics.consumptions'
      settings={{
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        identifier: 'logistics_consumptions_template_Dcycle',
        columns
      }}
      onEntryInit={validateRow}
      onEntryChange={onEntryChange}
      onCancel={handleExit}
      onResults={async (results, errors, complete) => {
        // cannot submit file with errors
        if (errors.length > 0) return complete(nuvoErrorFounded);

        if (results.length <= 0) return complete(nuvoError);

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(results);

        const finalFileName = `${fileName || category}.csv`;

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // get presinged url
        const data = await getPresignedUrlLogistics(finalFileName, 'recharges');

        // error uploading file
        if (!data || data?.response?.status >= 400) return complete(nuvoError);

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data?.recharges?.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category
        });

        complete();
      }}
    />
  );
};

export default InputNuvoRecharges;
