import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { deleteVehicleConsumptionFromOrganization } from '../../../../services/api/vehicleConsumptions';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import Select from '../../../ui/formComponents/select/Select';
import FormCalendarDouble from '../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';

type Props = {
  organizationId: string;
  deleteCallback: () => void;
};

type FormData = {
  status: SelectOptionFormat;
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};

const DeleteVehicleConsumption = ({ organizationId, deleteCallback }: Props) => {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    status: {
      id: 'all',
      name: t('vehicleConsumptionUpload.all')
    },
    startDate: '',
    endDate: '',
    errors: []
  });

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (formData.startDate || formData.endDate) {
      const newErrors = checkFormErrors(formData, formData.errors);
      if (newErrors.length > 0) {
        setFormData((prev) => ({
          ...prev,
          errors: newErrors
        }));
        return;
      }
    }
    setLoadingButton(true);
    const data = await deleteVehicleConsumptionFromOrganization(
      organizationId,
      formData.status.id,
      formData.startDate,
      formData.endDate
    );
    setLoadingButton(false);
    deleteCallback();
    if (!data) {
      dispatch(setNotification(t('error.somethingWentWrong')));
      return;
    }
    dispatch(setNotification(t('vehicleConsumptionUpload.successDelete')));
  };
  return (
    <div className='delete-vehicleConsumption'>
      <FormHeader
        title={t('vehicleConsumptionUpload.deleteTitle')}
        description={t('vehicleConsumptionUpload.deleteDescription')}
      />
      <FormWrapper>
        <FormCalendarDouble
          label={t('dates.rangeDates')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          handleStartDateError={handleDateError('startDate')}
          handleEndDateError={handleDateError('endDate')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          minStartDate={'01/01/1960'}
          minEndDate={'01/01/1960'}
          error={formData.errors.find(
            (elem) => elem.error === 'startDate' || elem.error === 'endDate'
          )}
        />
        <Select
          icon='/images/icons/shoppingBagGradient.svg'
          label={t('vehicleConsumptionUpload.status')}
          placeholder={''}
          options={[
            {
              id: 'all',
              name: t('vehicleConsumptionUpload.all')
            },
            {
              id: 'error',
              name: t('vehicleConsumptionUpload.error')
            }
          ]}
          value={formData.status}
          onChangeValue={onChangeValue('status')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          text='Delete'
          lookAndFeel='primary'
          onClick={handleDelete}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteVehicleConsumption;
