import { useTranslation } from 'react-i18next';
import { Column } from '../../../../types/table';
import { getCurrentYear, getPreviousYear } from '../../../dashboard/dashboardEsg/utils/utils';

const useColumns = () => {
  const { t } = useTranslation();
  const previousYear = getPreviousYear();
  const currentYear = getCurrentYear();

  const columns: Column[] = [
    {
      title: t('customizeMainPage.customMetrics.customMetric'),
      dataIndex: 'custom_metric',
      key: 'custom_metric',
      render: (text: string) => <span>{t(`controlPanel.metrics.list.${text}`)}</span>,
      width: '50%'
    },
    {
      title: t('customizeMainPage.customMetrics.type'),
      dataIndex: 'type',
      key: 'type',
      render: (_value, row) => {
        return (
          <span>
            {t(`controlPanel.metrics.categories.${row.type}`) +
              ' - ' +
              t(`controlPanel.metrics.categories.subtype.${row.sub_type}`)}
          </span>
        );
      }
    },
    {
      title: t('customizeMainPage.customMetrics.year') + ' ' + previousYear,
      dataIndex: 'previous_year',
      key: 'previous_year'
    },
    {
      title: t('customizeMainPage.customMetrics.year') + ' ' + currentYear,
      dataIndex: 'current_year',
      key: 'current_year'
    },
    {
      title: t('customizeMainPage.customMetrics.unit'),
      dataIndex: 'unit',
      key: 'unit'
    }
  ];
  return columns;
};

export default useColumns;
