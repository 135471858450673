import apiFetch from '../../utils/apiFetch';

export const getOrganizationsIntensityMetrics = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/intensity_metric_groupings/${id}/organizations`);

    return response.data;
  } catch (err) {
    return null;
  }
};

export const addIntensityMetricGroupsToGrouping = async (
  intensityMetricGroupId: string,
  intensityMetricGroupIds: string[]
) => {
  try {
    const response = await apiFetch('POST', `/intensity_metric_groupings`, {
      parent_id: intensityMetricGroupId,
      child_ids: intensityMetricGroupIds
    });
    return response.data;
  } catch (err) {
    return null;
  }
};
