import { useEffect, useState } from 'react';

type Props = {
  wrapperRef: any;
};
const useDivPosition = ({ wrapperRef }: Props) => {
  const [divPosition, setDivPosition] = useState({ left: 0, top: 0, width: 0, height: 0 });

  const handlePositionChange = () => {
    if (!wrapperRef.current) return;
    const data = wrapperRef.current.getBoundingClientRect();

    setDivPosition({
      left: data.x,
      top: data.y,
      width: data.width,
      height: data.height
    });
  };

  useEffect(() => {
    handlePositionChange();
    window.addEventListener('resize', handlePositionChange);
    window.addEventListener('scroll', handlePositionChange, { capture: true });

    return () => {
      window.removeEventListener('resize', handlePositionChange);
      window.removeEventListener('scroll', handlePositionChange);
    };
  }, [wrapperRef.current]);

  return divPosition;
};

export default useDivPosition;
