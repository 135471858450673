const employeeCategorization = [
  {
    transport_type: 'metro',
    size: null,
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'metro',
    size: null,
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'metro',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'trolleybus',
    size: null,
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'trolleybus',
    size: null,
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'trolleybus',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'bicycle',
    size: null,
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'bicycle',
    size: null,
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'bicycle',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'bicycle',
    size: null,
    fuel_type: 'not_fuel_based',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'motorbike',
    size: null,
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'motorbike',
    size: null,
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'motorbike',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'motorbike',
    size: null,
    fuel_type: 'petrol',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'motorbike',
    size: null,
    fuel_type: 'diesel',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'train',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'train',
    size: null,
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'train',
    size: null,
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'aircraft',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'walking',
    size: null,
    fuel_type: '',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'aircraft',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'bus',
    size: null,
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'bus',
    size: null,
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'bus',
    size: null,
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'bus',
    size: null,
    fuel_type: 'lpg',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'bus',
    size: null,
    fuel_type: 'natural_gas',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'bus',
    size: null,
    fuel_type: 'diesel',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'bus',
    size: null,
    fuel_type: 'petrol',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'electric',
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'electric',
    renewable_energy: 'yes',
    classification: 'sustainable'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'natural_gas',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'natural_gas',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'natural_gas',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'diesel',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'diesel',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'diesel',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'petrol',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'petrol',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'petrol',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'hybrid',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'hybrid',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'hybrid',
    renewable_energy: null,
    classification: 'medium_polluting'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'do_not_know',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'small',
    fuel_type: 'lpg',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'medium',
    fuel_type: 'lpg',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'car',
    size: 'large',
    fuel_type: 'lpg',
    renewable_energy: null,
    classification: 'very_polluting'
  },
  {
    transport_type: 'remote_work',
    size: null,
    fuel_type: null,
    renewable_energy: null,
    classification: 'sustainable'
  },
  {
    transport_type: 'walking',
    size: null,
    fuel_type: null,
    renewable_energy: null,
    classification: 'sustainable'
  }
];

export default employeeCategorization;
