import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import { TransportSection } from '../../../../../types/entities/shipment';

import InputGoogleMapsCombinedTransportSections from '../../../../ui/formComponents/inputGoogleMaps/InputGoogleMapsCombinedTransportSections';
import Select from '../../../../ui/formComponents/select/Select';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';
import IconBackground from '../../../../ui/iconBackground/IconBackground';
import useTransportOptions from '../hooks/useTransportOptions';

type Props = {
  transportSectionIndex: number;
  modifyTransportSection: (
    transportSectionIndex: number,
    transportSection: TransportSection
  ) => void;
  addTransportSection: (index: number) => void;
  removeTransportSection: (index: number) => void;
  transportSection: TransportSection;
  transportSections: TransportSection[];
  refrigerated: boolean;
  errors?: ErrorType[];
  disabled?: boolean;
  disabledDestination?: boolean;
};
function TransportSections({
  transportSectionIndex,
  modifyTransportSection,
  addTransportSection,
  removeTransportSection,
  transportSection,
  transportSections,
  refrigerated,
  errors,
  disabled = false,
  disabledDestination = false
}: Props) {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const renderTransportType = () => {
    if (transportSection.transport_type === 'road') {
      if (transportSection.electric && transportSection.travel_method === 'car') {
        return 'electric_car';
      }
      if (transportSection.electric && transportSection.travel_method === 'bicycle') {
        return 'electric_bicycle';
      } else {
        return transportSection.travel_method;
      }
    } else {
      return transportSection.transport_type;
    }
  };

  const [origin, setOrigin] = useState(transportSection.origin);
  const [destination, setDestination] = useState(transportSection.destination);
  const [kilometers, setKilometers] = useState<string | undefined>(
    transportSection.transport_kilometers
  );
  const [transport, setTransport] = useState<SelectOptionFormat>({
    id: transportSection.transport_type,
    name: transportSection.transport_type ? t(`shipments.${renderTransportType()}`) : ''
  });

  useEffect(() => {
    setOrigin(transportSection.origin);
    setDestination(transportSection.destination);
    setKilometers(transportSection.transport_kilometers);
    setTransport({
      id: transportSection.transport_type,
      name: transportSection.transport_type ? t(`shipments.${renderTransportType()}`) : ''
    });
  }, [transportSection, transportSections]);

  const onChangeOriginValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    const value = e.target.value;
    onSelectOriginMaps(value);
  };

  const onChangeDestinationValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabledDestination) return;

    const value = e.target.value;
    onSelectDestinationMaps(value);
  };

  const onSelectDestinationMaps = (value: string) => {
    if (disabledDestination) return;

    setDestination(value);
    modifyTransportSection(transportSectionIndex, {
      ...transportSection,
      origin,
      destination: value,
      transport_type: transport.id,
      transport_kilometers: kilometers
    });
  };

  const onSelectOriginMaps = (value: string) => {
    if (disabled) return;

    if (
      transportSectionIndex === 0 ||
      !origin ||
      transportSections[transportSectionIndex - 1].destination === ''
    ) {
      setOrigin(value);
      modifyTransportSection(transportSectionIndex, {
        ...transportSection,
        origin: value,
        destination,
        transport_type: transport.id,
        transport_kilometers: kilometers
      });
    }
  };

  const onChangeTransport = (value: SelectOptionFormat) => {
    if (disabled) return;

    setTransport(value);
    modifyTransportSection(transportSectionIndex, {
      ...transportSection,
      origin,
      destination,
      transport_type: value.id,
      transport_kilometers: kilometers
    });
  };

  const onAddTransportSection = () => {
    if (disabled) return;
    addTransportSection(transportSectionIndex);
  };

  const onRemoveTransportSection = () => {
    if (disabled) return;
    removeTransportSection(transportSectionIndex);
  };

  const onChangeValue = (value: string) => {
    setKilometers(value);
    modifyTransportSection(transportSectionIndex, {
      ...transportSection,
      origin,
      destination,
      transport_type: transport.id,
      transport_kilometers: value
    });
  };

  const transportOptions = useTransportOptions();

  const disabledGoogleMaps =
    disabled ||
    (transportSectionIndex > 0 &&
      origin !== '' &&
      transportSections[transportSectionIndex - 1].destination !== '');

  return (
    <div className='routes-wrapper'>
      {transportSectionIndex > 0 && <div className='line-divider' />}
      <div className='transport-container'>
        {flags?.addShipmentKilometers && (
          <div className='single-transport-container'>
            <FormNumber
              icon='/images/icons/pin.svg'
              label={t('businessTravels.totalKm')}
              placeholder={t('businessTravels.enterTotalKm')}
              value={kilometers}
              onChange={onChangeValue}
            />
          </div>
        )}
        <div
          className={
            flags?.addShipmentKilometers ? 'single-transport-container' : 'single-input-transport'
          }>
          <h3 className='body1-bold-font'>{t('shipments.transport')}</h3>
          <div className='transport' style={{ display: transportSectionIndex > 0 ? 'flex' : '' }}>
            <Select
              icon='/images/icons/shipmentGradient.svg'
              placeholder={t('shipments.selectOption')}
              options={transportOptions.filter((elem) => {
                if (refrigerated && (elem.id === 'electric_car' || elem.id === 'car')) {
                  return false;
                }
                return true;
              })}
              value={transport}
              height='31px'
              onChangeValue={onChangeTransport}
              size='small'
              sort={false}
              error={errors?.find(
                (error) => error.error === `transportSectionsTransportType-${transportSectionIndex}`
              )}
              disabled={disabled}
            />
            {transportSectionIndex > 0 && (
              <IconBackground
                icon={'/images/icons/closeBlue.svg'}
                alt={'close'}
                onClick={onRemoveTransportSection}
              />
            )}
          </div>
        </div>
      </div>
      <div className='column'>
        <h3 className='body1-bold-font'>{t('shipments.route')}</h3>
        <div className='origin-destination'>
          <InputGoogleMapsCombinedTransportSections
            icon={'/images/icons/pin.svg'}
            placeholder={t('shipments.origin')}
            placeholder2={t('shipments.destination')}
            value={origin}
            onChangeValue={onChangeOriginValue}
            onSelectMapsValue={onSelectOriginMaps}
            value2={destination}
            onChangeValue2={onChangeDestinationValue}
            onSelectMapsValue2={onSelectDestinationMaps}
            height='31px'
            fontClass='input-small-font'
            size='small'
            disabled={disabledGoogleMaps}
            disabledDestination={disabledDestination}
            error={errors?.find(
              (error) =>
                !kilometers && error.error === `transportSectionsRoute-${transportSectionIndex}`
            )}
          />
          {disabledGoogleMaps && disabledDestination ? null : (
            <div className='add-route' onClick={onAddTransportSection}>
              <img src='/images/icons/plusGray.svg' alt='add' />
              <p className='body1-bold-font input-placeholder-color'>
                {t('shipments.addAnotherRoute')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransportSections;
