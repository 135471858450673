import React from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';

const PurchasesDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <DescriptionBox
      descriptions={[
        t('ghgReport.purchasesDisclaimer.description1'),
        t('ghgReport.purchasesDisclaimer.description2'),
        t('ghgReport.purchasesDisclaimer.description3'),
        t('ghgReport.purchasesDisclaimer.description4'),
        t('ghgReport.purchasesDisclaimer.description5')
      ]}
    />
  );
};

export default PurchasesDisclaimer;
