import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { DATADIS_HOW_TO, SIZE_PAGINATION_BIG } from '../../../constants';
import { ROUTES } from '../../../constants/routes';
import { UserContext } from '../../../context/userContext';
import usePaginatedList from '../../../customHooks/paginatedList';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Button from '../../ui/button/Button';
import CardList from '../../ui/cardList/CardList';
import CardsFilter from '../../ui/filter/CardsFilter';
import HelpCTA from '../../ui/helpCTA/HelpCTA';
import Modal from '../../ui/modal/Modal';
import AddDatadisConnection from './addDatadisConnection/AddDatadisConnection';
import './styles.scss';

function Datadis() {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [listView, setListView] = useState(false);
  const [showCreateDatadisConextion, setShowDatadisConnection] = useState(false);

  const size = SIZE_PAGINATION_BIG;
  const url = '/organization_identifiers';

  const user = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // fetchPagination
  const {
    fetchData,
    fetchMoreData,
    hasMore,
    values,
    setValues: setAuthorizedNifs,
    total
  } = usePaginatedList({
    url,
    listSize: size,
    setLoading,
    organization: user?.selectedOrganization
  });
  const authorizedNif: string[] = values.map((value) => value.identifier);

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeListView = () => setListView(!listView);
  const handleHideCreateDatadisConnection = () => setShowDatadisConnection(false);
  const handleShowCreateDatadisConnection = () => setShowDatadisConnection(true);
  const handleShowConnection = (nif: string) => navigate(`${ROUTES.MEASURE_DATADIS}/${nif}`);

  // CRUD functions
  const addAuthorizedNif = (newNif: string) => {
    setAuthorizedNifs((prev) => prev.concat({ identifier: newNif }));
    dispatch(setNotification(t('notification.addDatadisConnection')));
    handleHideCreateDatadisConnection();
  };

  const navigateToDatadisDocumentation = () => {
    const lang = i18n.resolvedLanguage === 'es' ? 'es' : 'en';
    window.open(DATADIS_HOW_TO[lang], '_blank');
  };

  return (
    <section className='datadis'>
      <SectionHeader title={t('measureMain.title')} breadcrumb={<Breadcrumb />} />
      <div className='header-wrapper'>
        <CardsFilter listView={listView} onChangeListView={handleChangeListView} />
        <HelpCTA description={t('datadisConnection.howItWorks')}>
          <div className='flex buttons'>
            <Button
              onClick={navigateToDatadisDocumentation}
              text={t('datadisConnection.datadis')}
              lookAndFeel={'primary'}
              size='tiny'
            />
          </div>
        </HelpCTA>
      </div>
      <div id={'authorizedNifs'} className='infinite-scroll-wrapper-card'>
        <InfiniteScroll
          dataLength={total}
          next={fetchMoreData}
          hasMore={hasMore}
          style={{ overflow: 'visible' }}
          endMessage={<></>}
          loader={undefined}
          scrollableTarget={'authorizedNifs'}>
          <CardList
            listView={listView}
            title={t('datadisConnection.datadis')}
            description={t('datadisConnection.datadisDescription')}
            data={authorizedNif.map((nif) => ({
              id: nif,
              title: nif,
              icon: '/images/icons/thunder50.svg'
            }))}
            showCreateModal={handleShowCreateDatadisConnection}
            loading={loading}
            onClickCard={handleShowConnection}
            buttonText={t('datadisConnection.addNif')}
            blocked={false}
            hideCo2e
            dropDownOptions={[]}
          />
        </InfiniteScroll>
      </div>

      <Modal
        show={showCreateDatadisConextion}
        onClose={handleHideCreateDatadisConnection}
        width='600px'
        maxWidth='600px'>
        <AddDatadisConnection
          addAuthorizedNif={addAuthorizedNif}
          firstNif={authorizedNif.length === 0}
        />
      </Modal>
    </section>
  );
}

export default Datadis;
