import apiFetch from '../../utils/apiFetch';

type UnitParams = {
  type: string;
};
export const getUnits = async (params?: UnitParams, returnRequest = false) => {
  try {
    let queryParams = {};
    if (params) {
      queryParams = params;
    }
    const request = apiFetch('GET', '/units', null, null, queryParams);
    if (returnRequest) return request;
    return (await request).data;
  } catch (err) {
    return null;
  }
};

export const getUnitsByCountry = async (country: string, returnRequest = false) => {
  try {
    const request = apiFetch('GET', `/units/country/${country}`);
    if (returnRequest) return request;
    return (await request).data;
  } catch (err) {
    return null;
  }
};
