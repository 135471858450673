export const environment = {
  amplify: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_sRX3SaZGx',
      userPoolWebClientId: '20q2mbskmi33p5v6uoprbn2ort',
      oauth: {
        domain: 'dcycle-backend-auth-dcyclestag-stag.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://stag-app.dcycle.io/callback',
        redirectSignOut: 'https://stag-app.dcycle.io/',
        responseType: 'code'
      }
    }
  },
  backendUrl: 'https://lz42nr5j28.execute-api.eu-west-1.amazonaws.com/stag',
  ldClientSideID: '63e0c1ec62e30213915d1fd6',
  typeformEventUrl: {
    en: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    pt: 'https://w1wvlfnneny.typeform.com/to/OePNUyB7#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}',
    es: 'https://w1wvlfnneny.typeform.com/to/BInb1r4v#event_id={event_id}&event_name={event_name}&organization_id={organization_id}&organization_name={organization_name}'
  },
  clientUrl: 'https://stag-app.dcycle.io/'
};
