import { useTranslation } from 'react-i18next';
import { convertDateBackToFrontTimestamp } from '../../../../utils/convertDates';

type Props = {
  assumptions: Assumption[];
  category: Category;
};

type LineProps = {
  assumption: Assumption;
};

const AssumptionLine: React.FC<LineProps> = ({ assumption }) => {
  const { t } = useTranslation();

  const spans = [];

  let error = assumption.error;

  if (error === 'VALUES_NOT_OK' && assumption.category === 'vehicle') {
    error += '_CONSUMPTION';
  }

  spans.push(
    <span>
      {t('ghgReport.facility')}: {assumption.entity_name}
    </span>
  );

  spans.push(
    <span>
      {t('ghgReport.note')}:{' '}
      {t(`ghgReport.errors.${assumption.error}`, {
        start_date: assumption.start_date
          ? convertDateBackToFrontTimestamp(assumption.start_date)
          : undefined,
        end_date: assumption.end_date
          ? convertDateBackToFrontTimestamp(assumption.end_date)
          : undefined
      })}
    </span>
  );

  spans.push(
    <p className='m-0'>
      {t('ghgReport.comment')}: {assumption.comment}
    </p>
  );

  return (
    <li className='flex-col gap-y-2 border-1 rounded-4 border-solid solid-border p-2'>{spans}</li>
  );
};

export const Assumptions: React.FC<Props> = ({ assumptions, category }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ghgReport' });

  if (!assumptions.length) {
    return null;
  }

  return (
    <div key={category}>
      <span className='font-body-b1-sb'>{t(`assumptionCategories.${category}`)}</span>
      <ul className='font-body-b2-r flex-col gap-y-2'>
        {assumptions.map((assumption) => {
          return (
            <AssumptionLine
              key={assumption.category + assumption.comment}
              assumption={assumption}
            />
          );
        })}
      </ul>
    </div>
  );
};
