import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../actions/notification';
import { VIDEOS } from '../../../../constants';
import { UserContext } from '../../../../context/userContext';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { sendEmails } from '../../../../services/api/emails';
import Button from '../../../ui/button/Button';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import IconBackground from '../../../ui/iconBackground/IconBackground';
import Label from '../../../ui/label/Label';
import Modal from '../../../ui/modal/Modal';
import YoutubeVideo from '../../../ui/videoPlayer/YoutubeVideo';
import ModalProject1 from './modalProject1/ModalProject1';
import ModalProject2 from './modalProject2/ModalProject2';
import OffsetCard from './offsetCard/OffsetCard';
import OffsetCardBlue from './offsetCard/OffsetCardBlue';
import OffsetCardHover from './offsetCard/OffsetCardHover';
import './styles.scss';

const OffsetProjects = () => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const selectedOrganization = useSelectedOrganization();
  const dispatch = useDispatch();

  const videoLink = i18n.resolvedLanguage === 'es' ? VIDEOS.offset.es : VIDEOS.offset.en;

  const [showVideo, setShowVideo] = useState(false);
  const handleShowVideo = () => setShowVideo(true);
  const handleHideVideo = () => setShowVideo(false);

  const [show1project, setShow1Project] = useState(false);
  const handleShow1Project = () => setShow1Project(true);
  const handleHide1Project = () => setShow1Project(false);

  const [show2project, setShow2Project] = useState(false);
  const handleShow2Project = () => setShow2Project(true);
  const handleHide2Project = () => setShow2Project(false);

  const [loadingButton, setLoadingButton] = useState(false);

  const handleOnClick = (value: string) => async () => {
    if (!user || loadingButton) return;

    setLoadingButton(true);

    const response = await sendEmails(
      `El usuario ${user.email} de la organización ${selectedOrganization?.company_name} ${value}`,
      undefined,
      undefined,
      ['cecilia@dcycle.io', 'luis@dcycle.io', 'aischa@dcycle.io']
    );

    setLoadingButton(false);

    handleHide1Project();
    handleHide2Project();

    if (response?.response?.status >= 400) {
      dispatch(setNotification(t('notification.askForProjectError')));
      return;
    }
    dispatch(setNotification(t('notification.askForProject')));
  };

  return (
    <>
      <OffsetCardHover style={{ width: '38%' }}>
        <div className='project-image'>
          <div className='ods-cards'>
            <img src='/images/offset/ods8.svg' alt='' width='33%' />
            <img src='/images/offset/ods13.svg' alt='' width='33%' />
            <img src='/images/offset/ods15.svg' alt='' width='33%' />
          </div>

          <img
            className='project-image-wrapper'
            src='/images/offset/galiciaProject.svg'
            alt='offset project'
          />
        </div>

        <div className='center-wrapper-offset'>
          <h4 className='co2-offset-font title-text'>{t('offset.text.galiciaTitle')}</h4>
          <p className='subtitle-text span-font'>{t('offset.text.galiciaSubtitle')}</p>
          <div className='span-wrapper'>
            <Label lookAndFeel='secondary'>{t('offset.text.galiciaInfo1')}</Label>
            <Label lookAndFeel='secondary'>{t('offset.text.galiciaInfo2')}</Label>
            <Label lookAndFeel='secondary'>{t('offset.text.galiciaInfo3')}</Label>
          </div>
          <div className='button-wrapper-offset'>
            <Button
              lookAndFeel={'primary'}
              size='medium'
              text={t('offset.text.button1')}
              onClick={handleShow1Project}
            />
          </div>
        </div>
      </OffsetCardHover>
      <OffsetCardHover style={{ width: '38%' }}>
        <div className='project-image'>
          <img
            className='project-image-wrapper'
            src='/images/offset/santaMariaProject.svg'
            alt='offset project'
          />
        </div>
        <div className='center-wrapper-offset'>
          <h4 className='co2-offset-font title-text'>{t('offset.text.oaxacaTitle')}</h4>
          <p className='subtitle-text span-font'>{t('offset.text.oaxacaSubtitle')}</p>
          <div className='span-wrapper'>
            <Label lookAndFeel='secondary'>{t('offset.text.oaxacaInfo1')}</Label>
            <Label lookAndFeel='secondary'>{t('offset.text.oaxacaInfo2')}</Label>
          </div>
          <div className='button-wrapper-offset'>
            <Button
              lookAndFeel={'secondary'}
              size='medium'
              text={t('offset.text.button1')}
              onClick={handleShow2Project}
            />
          </div>
        </div>
      </OffsetCardHover>

      <div className='wrapper-lateral-card' style={{ width: '28%' }}>
        <OffsetCard>
          <div className='center-wrapper-offset'>
            <div className='button-wrapper-offset'>
              <img
                width={60}
                src={'/images/juanjo.png'}
                alt='title'
                draggable={false}
                className='video-icon'
              />
            </div>
            <h4 className='co2-offset-font title-text'>{t('offset.text.compensate')}</h4>
            <p className='subtitle-text span-font'>{t('offset.text.compensateSubtitle')}</p>
            <div className='button-wrapper-offset'>
              <IconBackground
                icon='/images/icons/play.svg'
                alt={'play'}
                selected
                round
                onClick={handleShowVideo}
              />
            </div>
          </div>
        </OffsetCard>
        <OffsetCardBlue style={{ height: '100%' }}>
          <div className='center-second-card'>
            <h4 className='co2-offset-font second-card-title'>{t('offset.text.needHelp')}</h4>
            <Button
              lookAndFeel={'secondary'}
              size='medium'
              text={<span className='alternative-text'>{t('offset.text.weHelpYou')}</span>}
              style={{ border: 'none', maxWidth: '100px' }}
              onClick={handleOnClick('ha pedido información de un nuevo proyecto')}
            />
          </div>
        </OffsetCardBlue>
      </div>
      <Modal show={showVideo} onClose={handleHideVideo} width='800px' maxWidth='800px'>
        <YoutubeVideo url={videoLink} />
        <FormHeader
          title={t('offset.text.videoTitle')}
          description={t('offset.text.videoDescription')}
          style={{ marginTop: '1rem' }}
        />
      </Modal>
      <Modal show={show1project} onClose={handleHide1Project} width='800px' maxWidth='800px'>
        <ModalProject1
          handleAskForInformation={handleOnClick(
            'ha pedido información del proyecto Galicia rexenera'
          )}
          loadingButton={loadingButton}
        />
      </Modal>
      <Modal show={show2project} onClose={handleHide2Project} width='800px' maxWidth='800px'>
        <ModalProject2
          handleAskForInformation={handleOnClick('ha pedido información del proyectoSanta María')}
          loadingButton={loadingButton}
        />
      </Modal>
    </>
  );
};

export default OffsetProjects;
