import { TFunction } from 'i18next';

import {
  renderFrameworks,
  renderStatus
} from '../components/dashboard/controlPanel/components/metrics/utils/utils';
import ButtonDropdown from '../components/ui/buttonDropdown/ButtonDropdown';
import Icon from '../components/ui/icon/Icon';
import { EsgCustomMetrics } from '../types/entities/esgMetrics';

export const adaptBackToFrontTablePanelControl = (data: EsgCustomMetrics[], t: TFunction) => {
  const paredData = data?.map((item) => {
    return {
      name: t(`controlPanel.metrics.list.${item.custom_metric}`),
      category:
        t(`controlPanel.metrics.categories.${item.type}`) +
        ' - ' +
        t(`controlPanel.metrics.categories.subtype.${item.sub_type}`),
      assigned_to: 'unassigned',
      frameworks: renderFrameworks(['CSRD', 'ISO 14001', 'EINF'], t),
      status: renderStatus('complete', t),
      edit: (
        <ButtonDropdown
          button={<Icon icon={'elipsis_horizontal'} color='gray-dark' />}
          options={[]}
          disabled={true}
        />
      )
    };
  });

  return paredData;
};
