import Table from 'rc-table';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { convertStringToDateBackend } from '../../../../../utils/convertDates';
import formatNumber from '../../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../../utils/numberToDecimal';
import InputNumber from '../../../../ui/formComponents2/inputNumber/InputNumber';
import InputText from '../../../../ui/formComponents2/inputText/InputText';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import Tooltip from '../../../../ui/tooltip/Tooltip';
import './styles.scss';
import {
  getScopeData,
  groupByCategory,
  groupDataByScope,
  listCategories,
  listCategoriesScope,
  unionWithGoals
} from './utils';

type Props = {
  loading: boolean;
  baseYear?: string; // date
  currentYear: string; // date
  goalYear?: string; // date
  organizationModeling: IOrganizationModelingBack[] | null;
  totalImpactByCategoryYearly: TotalImpactYearly[] | null;
  handleOnChangeReductionPercentage: (category: string) => (value: string) => void;
  handleOnChangeReductionAction: (category: string) => (e: ChangeEvent<HTMLInputElement>) => void;
};

const TableComponent = ({
  loading,
  totalImpactByCategoryYearly,
  organizationModeling,
  baseYear,
  currentYear,
  goalYear,
  handleOnChangeReductionPercentage,
  handleOnChangeReductionAction
}: Props) => {
  const { t } = useTranslation();

  const columns = [
    {
      key: 'category',
      dataIndex: 'category',
      title: t('modeling.table.category')
    },
    {
      key: 'reduction_percentage',
      dataIndex: 'reduction_percentage',
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}>
          {t('modeling.table.reduction_percentage')}{' '}
          <Tooltip text={t('modeling.table.reduction_percentage_tooltip')} />
        </div>
      )
    },
    {
      key: 'reduction_action',
      dataIndex: 'reduction_action',
      title: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0.5rem'
          }}>
          {t('modeling.table.reduction_action')}{' '}
          <Tooltip text={t('modeling.table.reduction_action_tooltip')} />
        </div>
      )
    },
    {
      key: 'base_year',
      dataIndex: 'base_year',
      title: (
        <div style={{ textAlign: 'right' }}>
          {t('modeling.table.base_year', {
            year: organizationModeling
              ? convertStringToDateBackend(baseYear ?? '').getFullYear()
              : '2019'
          })}
        </div>
      )
    },
    {
      key: 'current_year',
      dataIndex: 'current_year',
      title: (
        <div style={{ textAlign: 'right' }}>
          {t('modeling.table.current_year', {
            year: convertStringToDateBackend(currentYear).getFullYear()
          })}
        </div>
      )
    },
    {
      key: 'goal_year',
      dataIndex: 'goal_year',
      title: (
        <div style={{ textAlign: 'right' }}>
          {t('modeling.table.goal_year', {
            year: organizationModeling
              ? convertStringToDateBackend(goalYear ?? '').getFullYear()
              : '2030'
          })}
        </div>
      )
    }
  ];

  const parseData = () => {
    if (!totalImpactByCategoryYearly || !baseYear || !organizationModeling) return [];

    const groupedDataScopeArray = groupDataByScope(totalImpactByCategoryYearly);

    const baseYearNumber = Number(convertStringToDateBackend(baseYear).getFullYear());
    const currentYearNumber = Number(convertStringToDateBackend(currentYear).getFullYear());

    const scope1Grouped = groupByCategory(
      groupedDataScopeArray.scope1,
      baseYearNumber,
      currentYearNumber
    );
    const scope2Grouped = groupByCategory(
      groupedDataScopeArray.scope2,
      baseYearNumber,
      currentYearNumber
    );
    const scope3Grouped = groupByCategory(
      groupedDataScopeArray.scope3,
      baseYearNumber,
      currentYearNumber
    );

    const categoriesIncludedScope1 = Array.from(
      new Set(scope1Grouped.map((element) => element.category))
    );

    const categoriesIncludedScope2 = Array.from(
      new Set(scope2Grouped.map((element) => element.category))
    );

    const categoriesIncludedScope3 = Array.from(
      new Set(scope3Grouped.map((element) => element.category))
    );

    const categoriesIncluded = categoriesIncludedScope1
      .concat(categoriesIncludedScope2)
      .concat(categoriesIncludedScope3);

    const categoriesNotIncluded = listCategories.filter(
      (category) => !categoriesIncluded.includes(category) && !category.includes('scope')
    );

    categoriesNotIncluded.forEach((category) => {
      scope1Grouped.push({
        baseYear: 0,
        currentYear: 0,
        goalYear: 0,
        category,
        scope: listCategoriesScope[category]
      });
    });

    const scope1 = unionWithGoals(organizationModeling, scope1Grouped);
    const scope2 = unionWithGoals(organizationModeling, scope2Grouped);
    const scope3 = unionWithGoals(organizationModeling, scope3Grouped);

    const scope1Data = getScopeData(scope1, 'scope1');
    const scope2Data = getScopeData(scope2, 'scope2');
    const scope3Data = getScopeData(scope3, 'scope3');

    const totalImpacts = [scope1Data, ...scope1, scope2Data, ...scope2, scope3Data, ...scope3];

    totalImpacts.sort((a, b) => {
      const categoryA = a.category;
      const categoryB = b.category;

      const indexA = listCategories.indexOf(categoryA);
      const indexB = listCategories.indexOf(categoryB);

      return indexA - indexB;
    });

    const data = totalImpacts.map((element) => ({
      category: (
        <span className={element.category.includes('scope') ? 'report-card-font' : ''}>
          {t(`categories.${element.category}`)}
        </span>
      ),
      reduction_percentage: (
        <>
          {!element.category.includes('scope') && (
            <div className='wrapper-input-number-modeling secondary-text-color'>
              <InputNumber
                className='secondary-text-color placeholder-secondary-color'
                value={element.percentage}
                onChange={handleOnChangeReductionPercentage(element.category)}
                placeholder='0'
                max={100}
                min={0}
              />
              <span className='body1-font '>%</span>
            </div>
          )}
        </>
      ),
      reduction_action: (
        <>
          {!element.category.includes('scope') && (
            <InputText
              className='secondary-text-color'
              value={element.action}
              onChange={handleOnChangeReductionAction(element.category)}
              placeholder={t('modeling.table.placeholder')}
            />
          )}
        </>
      ),
      base_year: (
        <div style={{ textAlign: 'right' }}>
          {formatNumber(numberToDecimalNonZero(element.baseYear || 0))}
        </div>
      ),
      current_year: (
        <div style={{ textAlign: 'right' }}>
          {formatNumber(numberToDecimalNonZero(element.currentYear || 0))}
        </div>
      ),
      goal_year: (
        <div style={{ textAlign: 'right' }}>
          {formatNumber(numberToDecimalNonZero(element.goalYear || 0))}
        </div>
      )
    }));

    return data;
  };

  const dataParsed = parseData();

  const onRenderRow = (record: any, index: any) => {
    if ([3, 5].includes(index)) return { className: 'border' };
    if ([4, 6].includes(index)) return { className: 'border-top' };
    return {};
  };

  if (loading) return <LoaderCard className='item-table' />;
  return (
    <div className='card item-table'>
      <div className='wrapper-table-component'>
        <h3 className='headline4-font' style={{ marginTop: '24px', marginBottom: '0.66rem' }}>
          {t('modeling.table.title')}
        </h3>
        <Table
          columns={columns}
          data={dataParsed}
          emptyText={t('table.empty')}
          expandable={{
            defaultExpandAllRows: true
          }}
          onRow={onRenderRow}
        />
      </div>
    </div>
  );
};

export default TableComponent;
