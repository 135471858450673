import { useState } from 'react';
import { deleteDataGroupAssociationData } from '../../../../../services/api/grouping';
import Button from '../../../../ui/button/Button';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  dataGroupId: string;
  handleDeleteElement: (id: string) => void;
};
const DeleteElement = ({ id, dataGroupId, handleDeleteElement }: Props) => {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async () => {
    setLoadingButton(true);
    const data = await deleteDataGroupAssociationData(dataGroupId, id);
    setLoadingButton(false);
    if (!data) return;
    handleDeleteElement(id);
  };
  return (
    <div className='delete-element-grouping'>
      <FormHeader
        title={t('groupingDetail.deleteTitle')}
        description={t('groupingDetail.deleteDescription')}
      />
      <Button
        lookAndFeel={'primary'}
        text={t('groupingDetail.delete')}
        onClick={handleSubmit}
        loading={loadingButton}
      />
    </div>
  );
};

export default DeleteElement;
