import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useCriteriaContext from '../../hooks/useCriteriaContext';
import useModal from '../hooks/useModal';

import useConstants from './hooks/useConstants';

const ModalC9 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });

  const { criteriaContext } = useCriteriaContext();

  const { options, units } = useConstants();
  const criteriaC5 = criteriaContext.criteriaList.find((criteria) => criteria.criteria === 'C5');

  const { formData, setFormData, loading, patchCriteria, loadingButton, errorPatch } = useModal();

  const unit =
    formData?.method?.id !== 'sectorial' ? '' : units[criteriaC5?.sector as ISbtiCriteriaSectors];

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const onChangeMethod = (method: SelectOptionFormat) => {
    onChangeValue('productionQuantity')('');
    onChangeValue('method')(method);
  };

  return (
    <>
      <FormHeader title={t('title')} />
      {loading && (
        <CustomSkeletonLoader
          count={1}
          style={{
            marginBottom: '2rem'
          }}
        />
      )}

      {!loading && (
        <FormWrapper>
          <FormSelect
            tooltip={t('tooltipModalC9')}
            label={t('method')}
            iconV2='multitag'
            onChange={onChangeMethod}
            value={
              formData.method || {
                id: '',
                name: ''
              }
            }
            options={options}
            error={formData.errors.find((error) => error.error === 'method')}
          />
          {/* <InputWrapper
            label={t('productQuantity')}
            iconV2='grow_up'
            disabled={formData.method?.id !== 'sectorial'}
            error={formData.errors.find((error) => error.error === 'productionQuantity')}>
            <InputNumber
              onChange={onChangeValue('productionQuantity')}
              value={formData.productionQuantity}
            />
            <InputText value={unit} disabled={true} placeholder={t('unit')} />
          </InputWrapper> */}
        </FormWrapper>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('save')}
          loading={loadingButton}
          size='small'
          onClick={() => patchCriteria(formData)}
        />
      </FormButtonSection>
      <ErrorText>{errorPatch && errorPatch.description}</ErrorText>
    </>
  );
};

export default ModalC9;
