import { useTranslation } from 'react-i18next';

const useCards = () => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 'owned',
      icon: '/images/icons/buildingPerson.svg',
      title: t('hubs.owned')
    },
    {
      id: 'subcontracted',
      icon: '/images/icons/buildingDollar.svg',
      title: t('hubs.subcontracted')
    }
  ];

  return cards;
};

export default useCards;
