import apiFetch from '../../../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../../../../utils/url';
import { adaptLCAComponent } from '../adapters/adaptLCAComponent';
import type { FrontLCAComponent } from '../types/LCADetail';

const URL = '/acv/portfolio';

type Params = {
  id?: string;
  methodologyId?: string;
  impactCategory?: string;
  parentId?: string;
};

export const getLCAComponents = async ({
  id,
  methodologyId,
  impactCategory,
  parentId
}: Params): Promise<FrontLCAComponent[] | undefined> => {
  if (!id || !methodologyId || !impactCategory) {
    throw new Error('Missing required parameters');
  }

  const response = await apiFetch(
    'GET',
    getUrl(`${URL}/${id}/components`, {
      queryParams: generateQueryParamsFromObject({
        methodology_id: methodologyId,
        impact_category: impactCategory,
        parent_id: parentId
      })
    })
  );

  return adaptLCAComponent(response.data.items);
};
