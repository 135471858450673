import { useTranslation } from 'react-i18next';
import { PremiumFeatureModal } from '../PremiumFeatureModal';
import { ReactComponent as CDPIMG } from '../../../../assets/img/cdp.svg';
import { SOURCE_OF_TRUTH_ARTICLE } from '../../../../constants';

export const CDPModal = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'premiumFeatureAdvise.modalInstances.cdp'
  });

  return (
    <PremiumFeatureModal.Layout>
      <PremiumFeatureModal.Title>
        <div className='flex gap-x-2'>
          <span>{t('title')}</span> <PremiumFeatureModal.ProTag />
        </div>
      </PremiumFeatureModal.Title>
      <PremiumFeatureModal.ImageContainer>
        <CDPIMG />
      </PremiumFeatureModal.ImageContainer>
      <PremiumFeatureModal.BulletPointContainer>
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.1.title')}
          description={t('bulletPoints.1.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.2.title')}
          description={t('bulletPoints.2.description')}
        />
        <PremiumFeatureModal.BulletPoint
          title={t('bulletPoints.3.title')}
          description={t('bulletPoints.3.description')}
        />
      </PremiumFeatureModal.BulletPointContainer>
      <PremiumFeatureModal.Button.Container>
        <PremiumFeatureModal.Button.Schedule />
        <PremiumFeatureModal.Button.ReadMore url={SOURCE_OF_TRUTH_ARTICLE} />
      </PremiumFeatureModal.Button.Container>
    </PremiumFeatureModal.Layout>
  );
};
