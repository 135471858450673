import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOrgEf } from '../../../services/api/orgEf';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';

export const useUpdateOrgEf = () => {
  const org = useSelectedOrganization();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: updateOrgEf(org?.id),
    onSuccess: (data: OrgEf) => {
      queryClient.setQueryData(['orgEf', org?.id], data);
    }
  });

  return { updateOrgEf: mutate };
};
