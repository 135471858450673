export const AMAZON_API_TOKEN_URI = String(process.env.REACT_APP_AMAZON_TOKEN_URI);
export const AMAZON_CLIENT_ID = String(process.env.REACT_APP_AMAZON_CLIENT_ID);
export const AMAZON_CLIENT_SECRET_ID = String(process.env.REACT_APP_AMAZON_SECRET_CLIENT_ID);
export const DEFAULT_MARKET_PLACE_ID = 'A1RKKUPIHCS9HS';
export enum AMAZON_STATUSES {
  AUTHORIZATION_SUCCESSFULL = 'authentication_successful',
  AUTHORIZATION_FAILURE = 'authentication_failure',
  AUTHORIZATION_CODE_USE_SUCCESSFUL = 'auth_code_use_successful',
  AUTHORIZATION_CODE_USE_FAILURE = 'auth_code_use_failure'
}

export enum AMAZON_GRANT_TYPES {
  AUTHORIZATION_CODE = 'authorization_code'
}
export const AMAZON_QUERY_PARAMS = {
  AUTH_CODE_PARAM: 'auth_code',
  CALLBACK_URI_PARAM: 'amazon_callback_uri',
  STATE_PARAM: 'amazon_state'
};

export enum LEVEL_DOMAINS_TO_COUNTRY_CODES {
  com = 'us',
  ca = 'ca',
  mx = 'mx',
  br = 'br',
  es = 'es',
  uk = 'uk',
  fr = 'fr',
  nl = 'nl',
  de = 'de',
  pl = 'pl',
  sa = 'sa',
  be = 'be',
  it = 'it',
  se = 'se',
  za = 'za',
  eg = 'eg',
  tr = 'tr',
  ae = 'ae',
  in = 'in',
  sg = 'sg',
  au = 'au',
  jp = 'jp'
}

export type LevelDomain = keyof typeof LEVEL_DOMAINS_TO_COUNTRY_CODES;

export enum AMAZON_MARKETPLACE_IDS {
  ca = 'A2EUQ1WTGCTBG2',
  us = 'ATVPDKIKX0DER',
  mx = 'A1AM78C64UM0Y8',
  br = 'A2Q3Y263D00KWC',
  es = 'A1RKKUPIHCS9HS',
  uk = 'A1F83G8C2ARO7P',
  fr = 'A13V1IB3VIYZZH',
  nl = 'A1805IZSGTT6HS',
  de = 'A1PA6795UKMFR9',
  pl = 'A1C3SOZRARQ6R3',
  sa = 'A17E79C6D8DWNP',
  be = 'AMEN7PMS3EDWL',
  it = 'APJ6JRA9NG5V4',
  se = 'A2NODRKZP88ZB9',
  za = 'AE08WJ6YKNBMC',
  eg = 'ARBP9OOSHTCHU',
  tr = 'A33AVAJ2PDY3EV',
  ae = 'A2VIGQ35RCS4UG',
  in = 'A21TJRUUN4KGV',
  sg = 'A19VAU5U5O7RUS',
  au = 'A39IBJ37TRP1C6',
  jp = 'A1VC38T7YXB528'
}
