import { useImpactCategoryContext } from '../../Portfolio/LCADetail/Canvas/ImpactCategoryContext';
import { useACVId } from './useACVId';
import { useLCAImpacts } from './useLCAImpacts';

type Params = {
  node_id?: string;
};
export const useNodeLCAImpact = ({ node_id }: Params) => {
  const { acv_id } = useACVId();
  const impact_category = useImpactCategoryContext();

  const { data } = useLCAImpacts(acv_id, impact_category);

  return { data: data?.items?.find((impact) => impact.block_id === node_id) };
};
