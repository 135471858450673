import { FacilityFuelType } from '../../../../../../../types/entities/facilityFuel';
import { User } from '../../../../../../../types/entities/user';
import { InvoiceFormData } from '../constants';
import { buildFacilityFuel } from './buildFacilityFuel';
import { buildInvoice } from './buildInvoice';
import { buildSupplier } from './buildSupplier';

type Params = {
  formData: InvoiceFormData;
  user: Partial<User> | null;
  type: string;
  mode: string;
  facilityFuels: FacilityFuelType[];
  facility_id: string;
};

export const buildPostEssentials = ({
  formData,
  user,
  type,
  mode,
  facilityFuels,
  facility_id
}: Params) => {
  const parsedUnitId = mode === 'custom' ? formData.unitCustom.id : formData.unitDefault.id;

  return {
    invoice: buildInvoice({ formData, user, type, mode, parsedUnitId, facility_id }),
    facility_fuel: buildFacilityFuel({ mode, formData, facilityFuels }),
    supplier: buildSupplier({ mode, formData }),
    parsedUnitId
  };
};
