import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilter, MultipleFilterType } from '../../../utils/url';
import Accordion from '../../ui/accordion/Accordion';
import FilterCheckbox from './FilterCheckbox';
import { useFiltersContext } from './Filters';
import { useDefaultOnChangeSection } from './hooks/useDefaultOnChangeSection';

export type FilterOption = {
  value: string;
  label: ReactNode;
};
type Items = {
  visibles: FilterOption[];
  hidden: FilterOption[];
};

export type FilterSectionType = {
  title: ReactNode;
  field: string;
  options: FilterOption[];
  type: MultipleFilterType;
  maxElements?: number;
};

const getFilterItem = (item: FilterOption, filter?: IFilter) => {
  if (!filter) {
    return false;
  }

  if (Array.isArray(filter?.value)) {
    return filter.value.includes(item.value);
  }

  return filter?.value === item.value;
};

const SectionLayout = ({ title, children }: { title: ReactNode; children: ReactNode }) => {
  return (
    <div className='filters__section'>
      <section className='filters__options'>
        <Accordion>
          <Accordion.Item label={title} iconLeft active content={children} />
        </Accordion>
      </section>
    </div>
  );
};

type MultipleBaseType = {
  title: ReactNode;
  options: FilterOption[];
  maxElements?: number;
  field: string;
  onChange: (item: string) => (value: boolean) => void;
};

const MultipleBase: React.FC<MultipleBaseType> = ({
  title,
  onChange,
  options,
  maxElements,
  field
}) => {
  const { t } = useTranslation();

  const { filters } = useFiltersContext();

  const filter = filters.find((item) => item.field === field);

  const items: Items = {
    visibles: options,
    hidden: []
  };

  if (typeof maxElements === 'number' && options.length > maxElements) {
    items.visibles = options.slice(0, maxElements);
    items.hidden = options.slice(maxElements);
  }

  return (
    <SectionLayout title={title}>
      {items?.visibles.map((item) => (
        <FilterCheckbox
          item={item}
          key={`filter-${field}-${item.value}`}
          selected={getFilterItem(item, filter)}
          onChange={onChange(item.value)}
        />
      ))}
      {Boolean(items?.hidden?.length) && (
        <Accordion>
          <Accordion.Item
            highlight
            label={`${t('general.filters.show')} (${items?.hidden?.length})`}
            content={items?.hidden?.map((item) => (
              <FilterCheckbox
                item={item}
                key={`filter-${field}-${item.value}`}
                selected={getFilterItem(item, filter)}
                onChange={onChange(item.value)}
              />
            ))}
            closeFromDown
          />
        </Accordion>
      )}
    </SectionLayout>
  );
};

const MultipleSection = ({ title, field, options, maxElements, type }: FilterSectionType) => {
  const { onChange } = useDefaultOnChangeSection({ field, type });

  return (
    <MultipleSection.Base
      title={title}
      options={options}
      maxElements={maxElements}
      onChange={onChange}
      field={field}
    />
  );
};

MultipleSection.Base = MultipleBase;

export const FilterSection = {
  Layout: SectionLayout,
  Multiple: MultipleSection
};
