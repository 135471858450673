import useCategories from '../constants/categories';

const useAdapterCustomEFGroup = () => {
  const { categories } = useCategories();

  const adaptCustomEFGroupBack2Front = (customEFGroup: ICustomEFGroupBack): ICustomEFGroupFront => {
    const category = categories.find((cat) => cat.id === customEFGroup.category) || {
      id: '',
      name: ''
    };

    return {
      id: customEFGroup.id,
      name: customEFGroup.name,
      ghgType: customEFGroup.ghg_type === 0 ? false : true,
      category: category as SelectOptionFormat<CustomEFCategories>,
      description: customEFGroup.description,
      errors: []
    };
  };

  const adaptCustomEFGroupFront2Back = (customEFGroup: ICustomEFGroupFront): ICustomEFGroupBack => {
    let ghgType = undefined;

    if (customEFGroup.category.id === 'heat') {
      ghgType = customEFGroup.ghgType ? 1 : 0;
    }

    return {
      id: customEFGroup.id,
      name: customEFGroup.name,
      ghg_type: !ghgType ? 0 : 1,
      category: customEFGroup.category.id,
      description: customEFGroup.description
    };
  };

  return { adaptCustomEFGroupBack2Front, adaptCustomEFGroupFront2Back };
};

export default useAdapterCustomEFGroup;
