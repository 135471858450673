import { InvoiceType } from '../../../../../../../../types/entities/invoice';

export const parseUnit = (invoice: InvoiceType, currencyUnit?: SelectOptionFormat) => {
  if (
    currencyUnit?.id &&
    invoice.original_unit_id &&
    invoice.original_unit_id === currencyUnit.id
  ) {
    return currencyUnit.name;
  }
  return invoice.unit.name;
};

export const parseQuantity = (invoice: InvoiceType, currencyUnit?: SelectOptionFormat) => {
  if (
    currencyUnit?.id &&
    invoice.original_unit_id &&
    invoice.original_unit_id === currencyUnit.id &&
    invoice.original_quantity &&
    invoice.original_quantity >= 0
  ) {
    return invoice.original_quantity;
  }
  return invoice.quantity;
};
