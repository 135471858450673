import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SIZE_PAGINATION_SMALL } from '../../../../../constants';
import { getUseAndEndOfLifeList } from '../../../../../services/api/useAndEndOfLife';
import { IUseAndEndOfLifeBack } from '../../../../../types/entities/useAndEndOfLife';
import formatNumber from '../../../../../utils/formatNumber';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';
import Icon from '../../../../ui/icon/Icon';
import useFetchInfiniteList from '../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import Label from '../../../../ui/label/Label';

const useFetch = () => {
  const { idToEdit, idToRemove, handleSetIdToEdit, handleSetIdToRemove, getOptions } =
    useElements();

  const parseData = useParseData();

  // Must use useCallback
  const fetch = useCallback(async (page: number) => {
    const response = await getUseAndEndOfLifeList({ page, size: SIZE_PAGINATION_SMALL });
    if (!response || response?.response?.status >= 400) return;
    return response;
  }, []);

  const { fetchData, firstLoading, loading, total, data, addElement, editElement, removeElement } =
    useFetchInfiniteList<IUseAndEndOfLifeBack>(fetch, []);

  return {
    data: parseData(data, getOptions),
    total,
    loading,
    firstLoading,
    fetchData,
    addElement,
    editElement,
    removeElement,
    idToEdit,
    idToRemove,
    handleSetIdToEdit,
    handleSetIdToRemove
  };
};

const useElements = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife' });

  const [idToEdit, setIdToEdit] = useState('');
  const [idToRemove, setIdToRemove] = useState('');

  const handleSetIdToEdit = (id: string) => setIdToEdit(id);
  const handleSetIdToRemove = (id: string) => setIdToRemove(id);

  const getOptions = (id: string) => {
    const options = [];

    options.push({
      id: `${id}-edit`,
      name: t('edit'),
      onClick: () => handleSetIdToEdit(id)
    });

    options.push({
      id: `${id}-delete`,
      name: t('delete'),
      onClick: () => handleSetIdToRemove(id)
    });

    return options;
  };

  return {
    idToEdit,
    idToRemove,
    handleSetIdToEdit,
    handleSetIdToRemove,
    getOptions
  };
};

const useParseData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'useAndEndOfLife' });

  const parseData = (
    data: IUseAndEndOfLifeBack[],
    getOptions: (id: string) => DropDownActionOption[]
  ) => {
    return data?.map((elem) => ({
      code: elem?.product_name ?? '-',
      name: elem?.lca_name ?? '-',
      year: String(elem?.production_year) ?? '-',
      life: elem?.lifespan_years ?? '-',
      soldUnits: elem.production_quantity ?? '-',
      transformation: (
        <span className='highlight-text-color'>
          {elem?.intermediate_processing_co2e
            ? formatNumber(elem?.intermediate_processing_co2e)
            : '-'}{' '}
          kg CO₂ eq
        </span>
      ),
      use: (
        <span className='highlight-text-color'>
          {elem?.usage_co2e ? formatNumber(elem?.usage_co2e) : '-'} kg CO₂ eq
        </span>
      ),
      endOfLife: (
        <span className='highlight-text-color'>
          {elem?.waste_co2e ? formatNumber(elem?.waste_co2e) : '-'} kg CO₂ eq
        </span>
      ),
      type:
        elem.type === 'final' ? <Label>{t('final')}</Label> : <Label>{t('intermediate')}</Label>,
      edit: (
        <ButtonDropdown
          button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
          options={getOptions(elem.id ?? '')}
        />
      )
    }));
  };

  return parseData;
};

export default useFetch;
