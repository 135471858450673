import { useTranslation } from 'react-i18next';

import { GroupDataMode } from '../../../../types/entitiesEnums/report';
import IconBackground from '../../../ui/iconBackground/IconBackground';
import './styles.scss';
type Props = {
  categoriesToShow: CategoryWithScope[];
  groupDataMode: GroupDataMode;
};
const MeasureMain = ({ groupDataMode, categoriesToShow }: Props) => {
  const { t } = useTranslation();

  const categoriesList = [
    {
      name: t('report.facilities'),
      categories: ['stationary', 'recharge', 'electricity', 'water', 'waste']
    },
    { name: t('report.vehicles'), categories: ['transport'] },
    { name: t('report.employees'), categories: ['employees'] },
    { name: t('report.travels'), categories: ['travels'] },
    { name: t('report.shipmentsShort'), categories: ['transport_distribution_downstream'] },
    { name: t('report.deliveriesShort'), categories: ['transport_distribution_upstream'] },
    { name: t('report.purchases'), categories: ['purchases'] }
    //  {
    //    name: t('report.soldProductsIntermediateProcessing'),
    //    categories: ['sold_products_intermediate_processing']
    //  },
    //  { name: t('report.soldProductsUsage'), categories: ['sold_products_usage'] },
    //  { name: t('report.soldProductsWaste'), categories: ['sold_products_waste'] }
  ];
  if (groupDataMode === GroupDataMode.INVESTMENT) {
    categoriesList.push({ name: t('report.investments'), categories: ['investments'] });
  }

  const filterCategories = () => {
    if (!categoriesList) return [];
    const categories = categoriesList.filter((category) => {
      const categoryToShow = categoriesToShow.find((categoryToShow) =>
        category.categories?.includes(categoryToShow.category)
      );
      if (categoryToShow) {
        return categoryToShow;
      }
    });
    return categories;
  };

  return (
    <div className='measure-main-report'>
      <div className='main-bg-color card'>
        <IconBackground icon={'/images/icons/stacked50.svg'} alt={'stacked'} />
        <h1 className='report-card-font'>{t('report.methodology')}</h1>
        <p className='body1-font'>{t('report.methodologyDescription')}</p>
        <div className='card-info on-card-gray-bg-color'>
          {categoriesToShow.find((category) => category.scope === 1) && (
            <span className='body1-bold-font'>
              <div className='dot highlight-bg-color50'></div>
              {t('report.scope1')}
            </span>
          )}
          {categoriesToShow.find((category) => category.scope === 2) && (
            <span className='body1-bold-font'>
              <div className='dot highlight-bg-color50'></div>
              {t('report.scope2')}
            </span>
          )}
          {categoriesToShow.find((category) => category.scope === 3) && (
            <span className='body1-bold-font'>
              <div className='dot highlight-bg-color50'></div>
              {t('report.scope3')}
            </span>
          )}
        </div>
      </div>
      <div className='main-bg-color card'>
        <IconBackground icon={'/images/icons/papersGradient.svg'} alt={'papers'} />
        <h1 className='report-card-font'>{t('report.measure')}</h1>
        <p className='body1-font'>{t('report.measureDescription')}</p>

        <div className='card-info on-card-gray-bg-color'>
          {filterCategories().map((category) => (
            <span className='body1-bold-font' key={category.name}>
              <div className='dot highlight-bg-color50'></div>
              {category.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeasureMain;
