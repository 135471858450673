import './styles.scss';

type Props = {
  img: {
    src: string;
    alt: string;
  };
  title: string;
  data: number | string;
};

const InfoLabel = ({ img, title, data }: Props) => {
  return (
    <div className='total-info-data on-card-gray-bg-color'>
      <div className='title-wrapper'>
        <img className='image-info' {...img} />
        <span className='body1-bold-font'>{title}</span>
      </div>
      <span className='body1-font'>{data}</span>
    </div>
  );
};

export default InfoLabel;
