import React, { useEffect, useRef } from 'react';
import exportAsImage from '../../../utils/exportAsImage';
import Button from '../../ui/button/Button';
import './styles.scss';

type Props = {
  htmlContent: string;
  showComparison: boolean;
  lcaName: string;
  codeFirstLetterUppercase: string;
  lang: string;
  name: string;
};
const SnippetPreview = ({
  htmlContent,
  showComparison,
  lcaName,
  codeFirstLetterUppercase,
  lang,
  name
}: Props) => {
  const ref = useRef(null);
  const convertToPngAndDownload = async () => {
    try {
      let width = 3840;
      let height = 2460;

      if (!showComparison) {
        width = 932;
        height = 800;
      }

      await exportAsImage(
        ref.current,
        `${lcaName}_${codeFirstLetterUppercase}_${lang.toUpperCase()}_${name}_snippet`,
        width,
        height
      );
    } catch (err) {
      console.error(err);
    }
  };
  // Use effect to download the image only once and when the component is mounted
  useEffect(() => {
    if (!ref.current) return;
    // convertToPngAndDownload();
  }, []);

  return (
    <div className='snippet-preview' style={{ width: showComparison ? '1000px' : '400px' }}>
      <div
        ref={ref}
        className={`export ${!showComparison ? 'no-comparison' : ''}`}
        data-name={name}>
        <div
          dangerouslySetInnerHTML={{
            __html: htmlContent
          }}
        />
      </div>
      <Button text='Download' onClick={convertToPngAndDownload} lookAndFeel='primary' />
    </div>
  );
};

export default SnippetPreview;
