import { ReactElement } from 'react';
import './styles.scss';

type OwnProps = {
  text: ReactElement | string;
  position?: string;
  top: number;
  left: number;
};
type Props = OwnProps;

const TooltipChart = ({ text, position = 'top', top, left }: Props) => {
  let style = {};
  if (top && left) {
    style = { position: 'fixed', top: `${top}px`, left: `${left}px` };
  } else {
    style = { position: 'relative', bottom: '16px' };
  }
  return (
    <div className='tooltip-chart center' style={style}>
      <div className={`tooltip-wrapper ${position}`}>
        {position === 'top' && (
          <div className='tooltip-triangle'>
            <img src='/images/tooltipArrow.svg' alt='tooltip-arrow' />
          </div>
        )}
        <div className={'tooltip-modal tag2-bg-text-color tooltip-chart-font on-dark-text-color'}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default TooltipChart;
