import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GOOGLE_MAPS_API_KEY } from '../../../../constants/googleMapsApiKey';
import Tooltip from '../../tooltip/Tooltip';

import './styles.scss';

type Props = {
  icon: string;
  label?: string;
  placeholder: string;
  placeholder2: string;
  value: string;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelectMapsValue: (value: string) => void;
  value2: string;
  onChangeValue2: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelectMapsValue2: (value: string) => void;
  fontClass?: string;
  error?: ErrorType;
  height?: string;
  size?: string; // small for smaller paddings and margins
  tooltip?: string;
  disabled?: boolean;
  disabledDestination?: boolean;
};
const libraries: any[] = ['places'];

const InputGoogleMapsCombinedTransportSections = ({
  icon,
  label,
  placeholder,
  placeholder2,
  value,
  onChangeValue,
  onSelectMapsValue,
  value2,
  onChangeValue2,
  onSelectMapsValue2,
  error,
  fontClass = 'input-font',
  height = '33px',
  size = '',
  tooltip = '',
  disabled = false,
  disabledDestination = false
}: Props) => {
  const { t, i18n } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
    // language: i18n.resolvedLanguage
  });

  const [autocomplete, setAutoComplete] = useState<any>();
  const [autocomplete2, setAutoComplete2] = useState<any>();

  const onLoad = (autocompleteValue: any) => {
    setAutoComplete(autocompleteValue);
  };

  const onLoad2 = (autocompleteValue: any) => {
    setAutoComplete2(autocompleteValue);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null && autocomplete) {
      onSelectMapsValue(autocomplete.getPlace()?.formatted_address);
    } else {
      console.error('Autocomplete is not loaded yet! or input is disabled');
    }
  };

  const onPlaceChanged2 = () => {
    if (autocomplete2 !== null && autocomplete2) {
      onSelectMapsValue2(autocomplete2.getPlace().formatted_address);
    } else {
      console.error('Autocomplete is not loaded yet!');
    }
  };
  if (!isLoaded) {
    return null;
  }

  return (
    <div className='input'>
      {(label || tooltip) && (
        <div className='label-wrapper'>
          {label && <label className='input-label-font on-light-text-color'>{label}</label>}
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}

      <div
        className={`input__google-maps flex ${fontClass} ${size} ${
          error ? 'error-border-color' : 'input-border-color'
        }`}
        style={{ height, alignItems: 'stretch' }}>
        {icon && (
          <div
            className={`image-wrapper ${disabled ? 'input-disabled-bg-color' : ''} ${
              error ? 'error-border-color' : 'input-border-color'
            }`}>
            <img src={icon} alt='input-icon' width={18} />
          </div>
        )}
        {!disabled ? (
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            className='maps-autocomplete'>
            <input
              type='text'
              className={`input__google-maps__content on-light-text-color ${fontClass} ${
                disabled ? 'input-disabled-bg-color' : ''
              } ${error ? 'error-border-color' : 'input-border-color'}`}
              value={value}
              placeholder={placeholder}
              onChange={onChangeValue}
            />
          </Autocomplete>
        ) : (
          <div className='maps-autocomplete'>
            <input
              type='text'
              className={`input__google-maps__content on-light-text-color ${fontClass} ${
                disabled ? 'input-disabled-bg-color' : ''
              } ${error ? 'error-border-color' : 'input-border-color'}`}
              value={value}
              placeholder={placeholder}
              onChange={onChangeValue}
            />
          </div>
        )}
        <div className='divider-line'></div>

        {!disabledDestination ? (
          <Autocomplete
            onLoad={onLoad2}
            onPlaceChanged={onPlaceChanged2}
            className='maps-autocomplete'>
            <input
              type='text'
              className={`input__google-maps__content on-light-text-color input-bg-color ${fontClass} ${
                error ? 'error-border-color' : 'input-border-color'
              }`}
              value={value2}
              placeholder={placeholder2}
              onChange={onChangeValue2}
            />
          </Autocomplete>
        ) : (
          <input
            type='text'
            className={`input__google-maps__content on-light-text-color input-bg-color ${fontClass} ${
              disabledDestination ? 'input-disabled-bg-color' : ''
            } ${error ? 'error-border-color' : 'input-border-color'}`}
            value={value2}
            placeholder={placeholder2}
            onChange={onChangeValue2}
          />
        )}
      </div>
      {error && (
        <span className='input-error-text error-font error-text-color'>
          {error.description ? error.description : t('error.requiredField')}
        </span>
      )}
    </div>
  );
};

export default InputGoogleMapsCombinedTransportSections;
