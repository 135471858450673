import { AxiosResponse } from 'axios';
import apiFetch from '../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from '../../utils/url';

export const getIsOwnerOfParent = async (userId: string, orgId: string) => {
  const response: AxiosResponse<boolean> = await apiFetch(
    'GET',
    getUrl('/memberships/is_owner', {
      queryParams: generateQueryParamsFromObject({ of_parent: true })
    }),
    null,
    {
      'x-user-id': userId,
      'x-organization-id': orgId
    }
  );

  return response.data;
};
