import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { completeTaskCurrentUser } from '../../actions/auth';
import { setNotification } from '../../actions/notification';
import { UserContext } from '../../context/userContext';
import useSelectedOrganization from '../../customHooks/useSelectedOrganization';
import { markTaskAsCompleted } from '../../services/api/tasks';
import { IGetBackendTask } from '../../types/entities/task';
import { convertDateBackToFrontTimestamp, convertStringToDate } from '../../utils/convertDates';
import MainPageLayout from '../layout/mainPageLayout/MainPageLayout';
import Button from '../ui/button/Button';
import ButtonDropdown from '../ui/buttonDropdown/ButtonDropdown';
import Checkbox from '../ui/formComponents/checkbox/Checkbox';
import InfiniteList from '../ui/infiniteList/InfiniteList';
import Label from '../ui/label/Label';
import Modal from '../ui/modal/Modal';
import CreateTask from './components/createTask/CreateTask';

import EditTask from './components/editTask/EditTask';

import DeleteTask from './components/deleteTask/DeleteTask';

import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useFeatureFlags } from '../../customHooks/useFeatureFlags';
import { checkFlags } from '../../utils/flags';
import replaceLinkWithAnchorTag from '../../utils/replaceLinkWithAnchorTag';
import useColumns from './hooks/useColumns';
import useGetData from './hooks/useGetData';

function Tasks() {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const flags = useFeatureFlags();

  const { id } = useParams();

  const [showCreate, setShowCreate] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<string>();
  const [tasks, setTasks] = useState<IGetBackendTask[]>([]);
  const [total, setTotal] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);

  const { users, tags, loading } = useGetData();

  const dispatch = useDispatch();

  const selectedOrganization = useSelectedOrganization();

  const columns = useColumns();

  const handleAddTask = (task: IGetBackendTask) => {
    setTasks((prev) => [task, ...prev]);
    setShowCreate(false);
    dispatch(setNotification(t('notification.createTask')));
    if (task.assigned_to === user?.id) {
      const newTasks = [...(user?.tasks?.items ?? [])];
      newTasks.push(task);
      dispatch(
        completeTaskCurrentUser({
          total: user.tasks.total + 1,
          total_completed: user.tasks.total_completed,
          items: newTasks
        })
      );
    }
  };

  const handleEditTask = (task: IGetBackendTask) => {
    const foundTask = tasks.find((elem) => elem.id === task.id);

    if (!foundTask) return;

    if (foundTask.assigned_to === user?.id && task.assigned_to !== user?.id) {
      // If task is assigned to another user that is not the current user, then remove it from the current user's list and update the total
      dispatch(
        completeTaskCurrentUser({
          total: user.tasks.total - 1,
          total_completed: user.tasks.total_completed,
          items: user?.tasks.items.filter((elem) => elem.id !== task.id) // remove task from list because it has been completed
        })
      );
    } else if (foundTask.assigned_to !== user?.id && task.assigned_to === user?.id) {
      // If task is assigned to the current user, then add it to the current user's list and update the total
      const newTasks = [...(user?.tasks?.items ?? [])];
      newTasks.push(task);
      dispatch(
        completeTaskCurrentUser({
          total: user.tasks.total + 1,
          total_completed: user.tasks.total_completed,
          items: newTasks
        })
      );
    }

    setTasks((prev) => prev.map((elem) => (elem.id === task.id ? task : elem)));

    dispatch(setNotification(t('notification.editTask')));

    // Remove id from url
    navigate(ROUTES.TODOS);
  };

  const handleDeleteTask = (id: string) => {
    if (!user) return;
    setTasks((prev) => prev.filter((elem) => elem.id !== id));
    setTaskToDelete(undefined);
    setTotal(total - 1);
    const foundTask = tasks.find((elem) => elem.id === id);
    if (foundTask?.completed) {
      setTotalCompleted(totalCompleted - 1);
      dispatch(
        completeTaskCurrentUser({
          total: user.tasks.total - 1,
          total_completed: user.tasks.total_completed - 1,
          items: user?.tasks.items.filter((elem) => elem.id !== id)
        })
      );
    } else {
      dispatch(
        completeTaskCurrentUser({
          total: user.tasks.total - 1,
          total_completed: user.tasks.total_completed,
          items: user?.tasks.items.filter((elem) => elem.id !== id)
        })
      );
    }
    dispatch(setNotification(t('notification.deleteTask')));
  };

  const markAsCompleted = async (task: IGetBackendTask) => {
    try {
      await markTaskAsCompleted(task.id, !task.completed);
      handleEditTask({ ...task, completed: !task.completed });
      if (task.completed) {
        setTotalCompleted(totalCompleted - 1);
      } else {
        setTotalCompleted(totalCompleted + 1);
      }
      if (task.assigned_to === user?.id) {
        if (task.completed) {
          const newTasks = [...(user?.tasks?.items ?? [])];
          newTasks.push(task);
          dispatch(
            completeTaskCurrentUser({
              total: user.tasks.total,
              total_completed: user.tasks.total_completed - 1,
              // add task to list because it is pending again to be completed
              items: newTasks
            })
          );
        } else {
          dispatch(
            completeTaskCurrentUser({
              total: user.tasks.total,
              total_completed: user.tasks.total_completed + 1,
              items: user?.tasks.items.filter((elem) => elem.id !== task.id) // remove task from list because it has been completed
            })
          );
        }
      }
    } catch (error) {
      dispatch(setNotification(t('notification.somethingWentWrong')));
    }
  };

  const renderCategory = (task: IGetBackendTask) => {
    const { category, completed } = task;
    return (
      <div className='category'>
        <Checkbox
          text={
            <span className={`span-font ${completed ? 'disabled-text-color' : ''}`}>
              {t(`tasks.categories.${category}`)}
            </span>
          }
          onChange={() => {
            markAsCompleted(task);
          }}
          selected={task.completed}
        />
      </div>
    );
  };
  const renderTags = (tags: string[]) => {
    return (
      <div
        className='tags'
        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '0.5rem' }}>
        {tags.map((elem) => (
          <Label lookAndFeel='secondary' key={elem}>
            {elem}
          </Label>
        ))}
      </div>
    );
  };

  const renderDropdown = (task: IGetBackendTask) => {
    const options = [
      {
        id: `${task.id}-edit`,
        name: t('tasks.edit'),
        onClick: () => {
          // setTaskToEdit(task);
          // set the task id to edit in the url
          navigate(`${ROUTES.TODOS}/${task.id}`);
        }
      },
      {
        id: `${task.id}-delete`,
        name: t('tasks.delete'),
        onClick: () => {
          if (task.id) {
            setTaskToDelete(task.id);
          }
        }
      }
    ];
    return (
      <ButtonDropdown
        button={<img src='/images/icons/editPen.svg' height={19} width={18} alt='edit-pen' />}
        options={options}
      />
    );
  };

  const renderStatus = (task: IGetBackendTask) => {
    const { completed, due_date } = task;
    const dueDate = convertStringToDate(convertDateBackToFrontTimestamp(due_date));
    enum TaskStatus {
      PENDING = 'pending',
      COMPLETED = 'completed',
      OVERDUE = 'overdue'
    }
    let status = TaskStatus.PENDING;
    if (!completed && dueDate) {
      status = dueDate < new Date() ? TaskStatus.OVERDUE : TaskStatus.PENDING;
    } else if (completed) {
      status = TaskStatus.COMPLETED;
    }
    let lookAndFeel: 'primary' | 'success' | 'error' = 'primary';
    if (status === TaskStatus.COMPLETED) {
      lookAndFeel = 'success';
    }
    if (status === TaskStatus.OVERDUE) {
      lookAndFeel = 'error';
    }

    return <Label lookAndFeel={lookAndFeel}>{t(`tasks.${status}`)}</Label>;
  };

  const filteredTaksFeaturedFlag = tasks.filter((task) => checkFlags(task.category, flags));

  const parseData = (data: IGetBackendTask[]) => {
    return data.map((elem) => ({
      ...elem,
      category: renderCategory(elem),
      tags: renderTags(elem.tags),
      due_date: convertDateBackToFrontTimestamp(elem.due_date),
      edit: renderDropdown(elem),
      disabled: elem.completed,
      assigned_to: elem.assigned_user,
      status: renderStatus(elem),
      description: replaceLinkWithAnchorTag(elem.description, t('tasks.link'))
    }));
  };

  return (
    <MainPageLayout
      sectionTitle={t('tasks.title')}
      title={t('tasks.start')}
      description={t('tasks.startDescription')}
      buttons={
        !loading && (
          <Button
            lookAndFeel={'primary'}
            onClick={() => setShowCreate(true)}
            icon={'/images/icons/plusWhite.svg'}
            text={t('tasks.create')}
            size='small'
          />
        )
      }>
      <InfiniteList
        i18key={'tasks'}
        url={'/tasks'}
        values={filteredTaksFeaturedFlag}
        setValues={setTasks}
        columns={columns}
        parseData={parseData}
        organization={selectedOrganization?.id}
        total={total}
        setTotal={setTotal}
        total2={totalCompleted}
        setTotal2={setTotalCompleted}
        total2Key='total_completed'
        filters={{
          options: users
        }}
      />
      <Modal show={showCreate} onClose={() => setShowCreate(false)} maxWidth='600px' width='600px'>
        <CreateTask
          handleAddTask={handleAddTask}
          users={users}
          tags={tags}
          preSetOrganization={null}
        />
      </Modal>
      <Modal show={!!id} onClose={() => navigate(ROUTES.TODOS)} maxWidth='600px' width='600px'>
        <EditTask handleEditTask={handleEditTask} users={users} tags={tags} />
      </Modal>
      <Modal show={!!taskToDelete} onClose={() => setTaskToDelete(undefined)} maxWidth='600px'>
        <DeleteTask handleDeleteTask={handleDeleteTask} id={taskToDelete ?? ''} />
      </Modal>
    </MainPageLayout>
  );
}

export default Tasks;
