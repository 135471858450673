import { ReactNode } from 'react';
import './styles.scss';

type Props = {
  show: boolean;
  current?: { step: ReactNode; highlighted: ReactNode };
  children: ReactNode;
};

const InteractiveTutorialTool = ({ children, show, current }: Props) => {
  return (
    <>
      {show && current && (
        <>
          <div className='opacity'>{current.highlighted}</div>
          {current.step}
        </>
      )}
      {children}
    </>
  );
};

export default InteractiveTutorialTool;
