import { useState } from 'react';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import ErrorText from '../../../../ui/errorText/ErrorText';
import useErrors from '../../../../../customHooks/useErrors';
import { deleteHub } from '../../../../../services/api/hubs';

type Props = {
  id: string;
  handleDelete: (id: string) => void;
  onClose: () => void;
};

const DeleteHub = ({ id, handleDelete, onClose }: Props) => {
  const { t } = useTranslation('', { keyPrefix: 'hubs.hubForm' });
  const ERRORS = useErrors();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const submitDelete = async () => {
    if (loading) return;
    setLoading(true);

    const response = await deleteHub(id);

    setLoading(false);

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    handleDelete(id);
    onClose();
  };

  return (
    <div className='delete-modal'>
      <FormHeader title={t('deleteTitle')} description={t('deleteDescription')} />

      <FormButtonSection>
        <Button lookAndFeel={'cancel'} size='small' text={t('cancel')} onClick={onClose} />
        <Button
          lookAndFeel='warning'
          text={t('deleteButton')}
          onClick={submitDelete}
          loading={loading}
          size='small'
        />
      </FormButtonSection>
      {error && (
        <ErrorText
          style={{
            marginTop: '0.5rem'
          }}>
          {error.description}
        </ErrorText>
      )}
    </div>
  );
};

export default DeleteHub;
