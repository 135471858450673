import type { IconName } from '../../types/utils/iconName';
import type { LCALandingType } from './types/LCALanding';

export const LCA_LANDING_TYPES = {
  portfolio: 'portfolio',
  scenarios: 'scenarios'
} as const;

export const LCA_LANDING_ICONS = {
  [LCA_LANDING_TYPES.portfolio]: 'shirt',
  [LCA_LANDING_TYPES.scenarios]: 'cards'
} as Record<LCALandingType, IconName>;

export const LCA_DECIMALS = 20;
