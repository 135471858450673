import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';

import {
  AvoidedEmissionListElement,
  FormFacilityData
} from '../../../../../types/entities/avoidedEmission';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import InputNumber from '../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../ui/formComponents/inputText/InputText';
import FacilitySelection from './components/facilitySelection/FacilitySelection';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';

type Props = {
  addAvoidedEmission: (avoidedEmission: AvoidedEmissionListElement) => void;
};

const AddAvoidedEmission = ({ addAvoidedEmission }: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormFacilityData>({
    // useState<FormFacilityData | FormYData | ...>();
    kind: 'facility',
    name: '',
    quantity: '',
    facilityId: '',
    facilityName: '',
    category: '',
    list: [],
    invoice_id: '',
    start_date: '',
    end_date: '',
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });

  return (
    <div className='new-responses'>
      <FormHeader
        title={t('avoidedEmissions.title')}
        description={t('avoidedEmissions.subtitle')}
      />
      <FormWrapper style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <FormText
          label={t('avoidedEmissions.name')}
          icon={'/images/icons/plus.svg'}
          placeholder={t('avoidedEmissions.enterName')}
          value={formData.name}
          onChange={onChangeValue('name')}
          error={formData.errors.find((error) => error.error === 'name')}
        />
        <FormNumber
          label={t('avoidedEmissions.quantityDescription')}
          icon={'/images/icons/catalog.svg'}
          placeholder={t('avoidedEmissions.enterQuantity')}
          value={formData.quantity}
          onChange={onChangeValue('quantity')}
          error={formData.errors.find((error) => error.error === 'quantity')}
        />
      </FormWrapper>

      <FacilitySelection
        addAvoidedEmission={addAvoidedEmission}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};

export default AddAvoidedEmission;
