export const CATEGORIES_ENUM = {
  STATIONARY: 'stationary',
  STATIONARY_BIOGENIC: 'stationary_biogenic',
  RECHARGE: 'recharge',
  TRANSPORT: 'transport',
  VEHICLES_BIOGENIC: 'transport_biogenic',
  WASTES: 'wastes',
  ELECTRICITY: 'electricity',
  WATER: 'water',
  EMPLOYEES_IN_LABORE: 'employees_in_labore',
  EMPLOYEES_IN_ITINERE: 'employees_in_itinere',
  TRAVELS: 'travels',
  TRANSPORT_DISTRIBUTION_DOWNSTREAM: 'transport_distribution_downstream',
  SOLD_PRODUCTS_INTERMEDIATE_PROCESSING: 'sold_products_intermediate_processing',
  TRANSPORT_DISTRIBUTION_UPSTREAM: 'transport_distribution_upstream',
  SOLD_PRODUCTS_USAGE: 'sold_products_usage',
  PURCHASES: 'purchases',
  SOLD_PRODUCTS_WASTE: 'sold_products_waste'
} as const;

export const CATEGORIES = Object.values(CATEGORIES_ENUM);
