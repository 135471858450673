import { Dispatch, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NotificationContext } from '../../../../../context/notificationContext';
import { UserContext } from '../../../../../context/userContext';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { IPurchaseGeneralBackend } from '../../../../../types/purchases';
import { IFilter, ISorter } from '../../../../../utils/url';
import { FilterSection } from '../../../../layout/NewFilters/FilterSection';
import { FilterText } from '../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../layout/NewFilters/Filters';
import FilterHandlers from '../../../../layout/NewFilters/FiltersHandlers';
import Button from '../../../../ui/button/Button';
import Icon from '../../../../ui/icon/Icon';
import { ICON_SIZE_MAP } from '../../../../ui/icon/utils/constants';
import InfiniteList from '../../../../ui/infiniteList/InfiniteListV2';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import Modal from '../../../../ui/modal/Modal';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import { PURCHASE_STATUS } from '../constants';
import FiltersDate from '../../../../layout/NewFilters/FiltersDate';
import UploadPurchasesFile from '../uploadPurchasesFile/UploadPurchasesFile';
import AddPurchase from '../addPurchase/AddPurchase';
import DeletePurchase from '../DeletePurchase';
import EditPurchase from '../editPurchase/EditPurchase';
import { useColumns } from './hooks/useColumns';
import { useParseData } from './hooks/useParseData';
import { useGetPurchases } from './hooks/useGetPurchases';
import { Reviewing } from '../components/Reviewing';
import { PurchasesStatusTag } from '../components/statusTag/StatusTag';
import { ModalHowToUpload } from '../components/ModalHowToUpload';
import { usePurchasesLoader } from '../../../../../reducers/purchasesLoader';

type ContextProps = {
  setShowUploadPurchasesFile: (value: boolean) => void;
  showUploadPurchasesFile: boolean;
  children: React.ReactNode;
};

type Context = {
  purchases: IPurchaseGeneralBackend[] | null;
  setPurchases: (value: IPurchaseGeneralBackend[] | null) => void;
  setShowUploadPurchasesFile: (value: boolean) => void;
  showUploadPurchasesFile: boolean;
  setShowAddPurchase: (value: boolean) => void;
  showAddPurchase: boolean;
  totalLimit?: number;
  setTotalLimit: Dispatch<React.SetStateAction<number | undefined>>;
};

const PurchasesContext = createContext<Context | null>(null);

export const usePurchases = () => {
  const context = useContext(PurchasesContext);
  if (!context) {
    throw new Error('usePurchases must be used within a PurchasesProvider');
  }
  return context;
};

export const PurchasesProvider = ({
  children,
  setShowUploadPurchasesFile,
  showUploadPurchasesFile
}: ContextProps) => {
  const [purchases, setPurchases] = useState<IPurchaseGeneralBackend[] | null>(null);
  const [totalLimit, setTotalLimit] = useState<number>();
  const [showAddPurchase, setShowAddPurchase] = useState(false);

  return (
    <PurchasesContext.Provider
      value={{
        purchases,
        setPurchases,
        setShowUploadPurchasesFile,
        showUploadPurchasesFile,
        setTotalLimit,
        showAddPurchase,
        setShowAddPurchase,
        totalLimit
      }}>
      {children}
    </PurchasesContext.Provider>
  );
};

type EmptyTextProps = {
  isUploading: boolean;
  isFiltering: boolean;
};

const EmptyText: React.FC<EmptyTextProps> = ({ isUploading, isFiltering }) => {
  const { t } = useTranslation();

  if (isUploading) {
    return (
      <div className='flex gap-x-2 items-center'>
        <LoaderTables mode='fit' />
        <span>{t('purchases.uploading')}</span>
      </div>
    );
  }

  if (isFiltering) {
    return <span>{t('purchases.emptyFiltered')}</span>;
  }

  return <span>{t('purchases.empty')}</span>;
};

const DEFAULT_SORTERS: ISorter[] = [
  {
    field: 'created_at',
    order: 'desc'
  }
];

type MainProps = {
  children?: React.ReactNode;
};

const PurchasesManagementMain = ({ children }: MainProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const setNotification = useContext(NotificationContext);
  const user = useContext(UserContext);
  const isUploading = usePurchasesLoader();

  const {
    setTotalLimit,
    setShowUploadPurchasesFile,
    showAddPurchase,
    setShowAddPurchase,
    showUploadPurchasesFile
  } = usePurchases();

  const [purchaseToEdit, setPurchaseToEdit] = useState<IPurchaseGeneralBackend>();
  const [purchaseToDelete, setPurchaseToDelete] = useState('');
  const [, setPurchaseToPauseFrequency] = useState('');
  const [filters, setFilters] = useState<IFilter[]>([]);

  const [open, setOpen] = useState(false);

  const {
    data: rawData,
    fetchData,
    refreshData,
    loading,
    firstLoading,
    total,
    total2: purchasesInReview,
    addPurchase: createPurchase,
    invalidate
  } = useGetPurchases({
    filters,
    sorters: DEFAULT_SORTERS
  });

  /* ADD */
  const addPurchase = (purchase: IPurchaseGeneralBackend) => {
    createPurchase(purchase);
    setShowAddPurchase(false);
    setShowUploadPurchasesFile(false);
    dispatch(setNotification(t('notification.createPurchase')));
    invalidate();
  };

  /* DELETE */

  const deletePurchase = () => {
    dispatch(setNotification(t('notification.deletePurchase')));
    setPurchaseToDelete('');
    invalidate();
  };

  const editPurchase = () => {
    invalidate();

    dispatch(setNotification(t('notification.editPurchase')));

    setPurchaseToEdit(undefined);
  };

  const onCloseModal = () => {
    setShowAddPurchase(false);
    setPurchaseToDelete('');
    setPurchaseToPauseFrequency('');
    setShowUploadPurchasesFile(false);
    setPurchaseToEdit(undefined);
  };

  const parseData = useParseData({
    setPurchaseToDelete,
    setPurchaseToEdit,
    setPurchaseToPauseFrequency
  });

  useEffect(() => {
    if (!isUploading && !filters.length) {
      refreshData();
    }

    if (!isUploading && filters.length) {
      setFilters([]);
    }
  }, [isUploading]);

  // We need to set the total limit when the total changes (so we can unblock or block the button)
  // this is because we are reusing previous logic that was previously in the Purchases component, we should refactor this in the future
  useEffect(() => {
    setTotalLimit(total);
  }, [total]);

  const data = isUploading ? [] : parseData(rawData);

  const { columns } = useColumns();
  return (
    <>
      {children}
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <Filters.Menu>
          <FiltersDate fields={['purchase_date']} />
          <FilterSection.Multiple
            title={t('purchases.status')}
            field='status'
            type='in'
            options={Object.values(PURCHASE_STATUS).map((status) => ({
              value: status,
              label: (
                <PurchasesStatusTag
                  status={status}
                  text={t(`purchases.purchasesTable.tags.${status}`)}
                />
              )
            }))}
          />
        </Filters.Menu>

        <Modal show={showUploadPurchasesFile} onClose={onCloseModal} width='600px' maxWidth='600px'>
          {user && <UploadPurchasesFile user={user} addPurchase={addPurchase} />}
        </Modal>

        <Modal show={showAddPurchase} onClose={onCloseModal} width='600px' maxWidth='600px'>
          <AddPurchase addPurchase={addPurchase} />
        </Modal>
        <Modal show={!!purchaseToDelete} onClose={onCloseModal} width='428px' maxWidth='428px'>
          <DeletePurchase deletePurchase={deletePurchase} purchaseToDelete={purchaseToDelete} />
        </Modal>
        <Modal show={!!purchaseToEdit} onClose={onCloseModal} width='600px' maxWidth='600px'>
          {purchaseToEdit && (
            <EditPurchase purchaseToEdit={purchaseToEdit} editPurchase={editPurchase} />
          )}
        </Modal>

        <InfiniteList
          data={data}
          fetchData={fetchData}
          columns={columns}
          loading={loading}
          firstLoading={firstLoading}
          total={total}
          header={
            <>
              <div className='flex gap-x-2 items-center'>
                <FilterText
                  field='description'
                  type='il'
                  placeholder={t('purchases.inputFilter')}
                />
                <FilterHandlers blacklistedFilters={{ all: ['description'] }} />
              </div>
              <div className='flex gap-x-4 items-center'>
                <Reviewing.Root isReviewing={!isUploading && Boolean(purchasesInReview)}>
                  <Reviewing.Tooltip text={t('purchases.reviewingTooltip')}>
                    <Reviewing.Main
                      title={t('purchases.reviewing', { count: purchasesInReview })}
                      setFilters={setFilters}
                    />
                  </Reviewing.Tooltip>
                </Reviewing.Root>
                <TotalLegend total={total} loading={loading} i18key='purchases' />
              </div>
            </>
          }
          emptyText={<EmptyText isUploading={isUploading} isFiltering={Boolean(filters.length)} />}
        />
      </Filters.Root>
    </>
  );
};

const Buttons = () => {
  const { t } = useTranslation();
  const organization = useSelectedOrganization();
  const [show, setShow] = useState(false);
  const { totalLimit, setShowAddPurchase } = usePurchases();

  const handleShowAddPurchaseModal = () => {
    setShowAddPurchase(true);
  };

  const isBlocked =
    totalLimit === undefined || (organization && totalLimit >= organization.limit_purchases);

  const BTNComponent = isBlocked ? (
    <Button lookAndFeel='blocked' text={t('purchases.uploadBTNCard')} size='small' />
  ) : (
    <Button
      lookAndFeel='primary'
      text={t('purchases.uploadBTNCard')}
      size='small'
      onClick={() => setShow(true)}
      iconNode={<Icon icon='upload' color='white' size={ICON_SIZE_MAP.MEDIUM} />}
    />
  );

  return (
    <>
      <ModalHowToUpload show={show} onClose={() => setShow(false)} />
      {BTNComponent}
      <Button
        lookAndFeel={isBlocked ? 'blocked' : 'secondary'}
        text={t('purchases.addManual')}
        size='small'
        onClick={handleShowAddPurchaseModal}
        iconNode={!isBlocked && <Icon icon='add' color='gray' size={ICON_SIZE_MAP.MEDIUM} />}
      />
    </>
  );
};

export const PurchasesManagement = {
  Main: PurchasesManagementMain,
  Buttons,
  Context: PurchasesProvider
};
