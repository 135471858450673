import { useEffect, useState } from 'react';
import { adaptHeavyFilesStatusBackToFront } from '../../../../adapters/heavyFilesStatus';
import { getGroupingByCategory } from '../../../../services/api/heavyFilesStatus';
import {
  DynamoPaginationBackend,
  IHeavyFileStatusFrontend
} from '../../../../types/entities/heavyFilesStatus';
import { HEAVY_FILE_STATUS } from '../../../../types/entitiesEnums/heavyFilesStatus';

type Props = {
  category: string;
  filters: {
    startDate: string;
    endDate: string;
    status: string;
    name: string;
  };
};

const useFetchData = ({ category, filters }: Props) => {
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [total, setTotal] = useState(0);
  const [errors, setErrors] = useState(0);
  const [pending, setPending] = useState(0);
  const [success, setSuccess] = useState(0);
  const [heavyFileStatus, setHeavyFileStatus] = useState<IHeavyFileStatusFrontend[]>([]);

  const [loading, setLoading] = useState(false);

  const fetchDataFirstTime = async () => {
    // adapt data
    const data = await fetchData();
    if (!data) return;

    const heavyFileStatusData = data?.heavy_files_status?.map((value) =>
      adaptHeavyFilesStatusBackToFront(value)
    );

    return heavyFileStatusData;
  };

  const fetchMoreData = async () => {
    if (loading || !nextToken) return;

    // adapt data
    const data = await fetchData();
    if (!data) return;

    const heavyFileStatusData = data?.heavy_files_status?.map((value) =>
      adaptHeavyFilesStatusBackToFront(value)
    );

    // add new data to table
    setHeavyFileStatus(heavyFileStatus.concat(heavyFileStatusData));
  };

  const fetchData = async () => {
    // get new data
    setLoading(true);
    const response = await getGroupingByCategory({
      category,
      nextToken: nextToken,
      startDate: filters.startDate,
      endDate: filters.endDate,
      status: filters.status,
      name: filters.name
    });
    setLoading(false);

    // if error, return
    if (response?.response?.status >= 400 || !response?.heavy_files_status) return;

    // adapt data
    const data: DynamoPaginationBackend = response;

    // update data
    setTotal(data.total);
    setErrors(data.error);
    setPending(data.in_progress + data.in_queue);
    setSuccess(data.success);
    setNextToken(data.next_token);

    return data;
  };

  const deleteFile = (id: string) => {
    setHeavyFileStatus((prev) =>
      prev.map((file) => (file.id === id ? { ...file, status: HEAVY_FILE_STATUS.DELETING } : file))
    );
  };

  useEffect(() => {
    let ignore = false;

    fetchDataFirstTime().then((data) => {
      if (ignore || !data) return;

      setHeavyFileStatus(data);
    });

    return () => {
      ignore = true;
    };
  }, [filters.endDate, filters.startDate, filters.name, filters.status, category]);

  return {
    fetchData: fetchMoreData,
    loading,
    heavyFileStatus,
    total,
    errors,
    pending,
    success,
    deleteFile
  };
};

export default useFetchData;
