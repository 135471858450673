import apiFetch from '../../utils/apiFetch';

export const getCustomMetrics = async () => {
  try {
    const response = await apiFetch('GET', '/esg/custom_metrics');
    return response.data;
  } catch (err) {
    return err;
  }
};
