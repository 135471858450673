import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../../customHooks/useFeatureFlags';
import { IPurchaseGeneralBackend } from '../../../../../types/purchases';
import { PurchaseType } from '../../../../../types/entitiesEnums/purchases';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import AddPurchaseSpendBase from './AddPurchaseSpendBase';
import AddPurchaseSupplierSpeciffic from './AddPurchaseSupplierSpecific';
import './styles.scss';

type Props = {
  addPurchase: (purchase: IPurchaseGeneralBackend) => void;
};

function OldAddPurchase({ addPurchase }: Props) {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const steps = [
    {
      id: PurchaseType.SPEND_BASE,
      text: t('purchases.spendBased')
    },
    {
      id: PurchaseType.SUPPLIER_SPECIFIC,
      text: t('purchases.supplierSpecific')
    }
  ];

  const {
    steps: stepsCompleated,
    stepSelected,
    handleSelect
  } = useStepCarousel({ stepsText: steps });

  return (
    <div className='new-purchase'>
      <FormHeader
        title={t('purchases.createNewPurchase')}
        description={t('purchases.createNewPurchaseDescription')}
      />

      {flags?.purchasesSupplierSpecific ? (
        <>
          <div className='step-carousel-position'>
            <span className='input-label-font'>{t('purchases.purchaseType')}</span>{' '}
            <StepCarouselList
              steps={stepsCompleated}
              handleSelect={handleSelect}
              lookAndFeel='small'
            />
          </div>

          {stepSelected?.id === PurchaseType.SPEND_BASE && (
            <AddPurchaseSpendBase addPurchase={addPurchase} />
          )}

          {stepSelected?.id === PurchaseType.SUPPLIER_SPECIFIC && (
            <AddPurchaseSupplierSpeciffic addPurchase={addPurchase} />
          )}
        </>
      ) : (
        <AddPurchaseSpendBase addPurchase={addPurchase} />
      )}
    </div>
  );
}

function AddPurchase({ addPurchase }: Props) {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const steps = [
    {
      id: PurchaseType.SPEND_BASE,
      text: t('purchases.spendBased')
    },
    {
      id: PurchaseType.SUPPLIER_SPECIFIC,
      text: t('purchases.supplierSpecific')
    }
  ];

  const {
    steps: stepsCompleated,
    stepSelected,
    handleSelect
  } = useStepCarousel({ stepsText: steps });

  if (!flags?.purchaseSuppliers) {
    return <OldAddPurchase addPurchase={addPurchase} />;
  }

  return (
    <div className='flex-col gap-y-8'>
      <h3 className='font-headings-h3-sb m-0'>{t('purchases.createNewPurchase')}</h3>

      {flags?.purchasesSupplierSpecific ? (
        <>
          <div className='flex gap-x-2 items-center'>
            <span className='input-label-font'>{t('purchases.purchaseType')}</span>{' '}
            <StepCarouselList
              steps={stepsCompleated}
              handleSelect={handleSelect}
              lookAndFeel='small'
            />
          </div>

          {stepSelected?.id === PurchaseType.SPEND_BASE && (
            <AddPurchaseSpendBase addPurchase={addPurchase} />
          )}

          {stepSelected?.id === PurchaseType.SUPPLIER_SPECIFIC && (
            <AddPurchaseSupplierSpeciffic addPurchase={addPurchase} />
          )}
        </>
      ) : (
        <AddPurchaseSpendBase addPurchase={addPurchase} />
      )}
    </div>
  );
}
export default AddPurchase;
