import { useEffect, useState } from 'react';
import { getUnits } from '../../../../../../../services/api/unit';
import { getCustomEFGroupUnits } from '../../../../../../../services/api/customEF';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useUnits = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'units' });
  const { id } = useParams();
  const [units, setUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (!id) return;
    setLoading(true);
    const newUnits = await getCustomEFGroupUnits(id);
    setLoading(false);
    let newUnitsFiltered = [];
    newUnitsFiltered = newUnits.filter((unit: Unit) => unit.name !== 'cubic_metre_(m3)_total');
    // if (group?.category === 'waste') {
    //   newUnitsFiltered = newUnits.filter((unit: Unit) => unit.name === 'kilogram_(kg)');
    // }
    const newUnitsMapped = newUnitsFiltered.map((unit: Unit) => ({
      id: unit.id,
      name: unit.name
    }));
    setUnits(newUnitsMapped);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const unitsTranslated = units.map((unit) => ({
    ...unit,
    name: t(unit.name)
  }));

  return { units: unitsTranslated, loading };
};

export default useUnits;
