import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteComment } from '../../../../../../services/api/tasks';
import { TaskComment } from '../../../../../../types/entities/task';
import { convertDateBackToFrontTimestamp } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import Modal from '../../../../../ui/modal/Modal';
import NameIcon from '../../../../../ui/nameIcon/NameIcon';

type Props = {
  comment: TaskComment;
  removeComment: (id: string) => void;
};
const Comment = ({ comment, removeComment }: Props) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleSubmit = async () => {
    setShowDeleteModal(false);
    await deleteComment(comment.id);
    removeComment(comment.id);
  };
  return (
    <div className='task-comment'>
      {comment.creator_user && (
        <div className='task-comment__header'>
          <NameIcon name={comment.creator_user} lastName={''} />
          <span className='body1-bold-font'>{comment.creator_user}</span>
          {comment.created_at && (
            <span className='body2-font disabled-text-color'>
              {convertDateBackToFrontTimestamp(comment.created_at)}
            </span>
          )}
        </div>
      )}
      <div className='task-comment__body'>
        <span className='body1-font'>{comment.comment}</span>
        <img
          src='/images/icons/close.svg'
          alt='close'
          className='pointer'
          onClick={() => setShowDeleteModal(true)}
          width={14}
          height={14}
        />
      </div>
      <Modal show={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <FormHeader title={t('tasks.deleteComment')} />
        <FormButtonSection>
          <Button text={t('tasks.delete')} lookAndFeel='primary' onClick={handleSubmit} />
        </FormButtonSection>
      </Modal>
    </div>
  );
};

export default Comment;
