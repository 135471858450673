import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { esgUnits } from '../../../../../../../../services/api/esg';

export const useGetUnits = (dataType: string) => {
  const { t } = useTranslation();
  const { data, isFetching } = useQuery({
    queryKey: ['units', dataType],
    queryFn: async () => await esgUnits(dataType),
    select: (data) => {
      return data?.map((elem: SelectOptionFormat) => ({
        id: elem.id,
        name: t(`units.${elem.name}`)
      }));
    }
  });

  return { data, isLoading: isFetching };
};
