import {
  ColumnAPI,
  DropdownOptionAPI,
  HookedRecordValue,
  LanguageType,
  OnEntryChange,
  OnEntryInit,
  RejectSubmitResult
} from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import useErrors from '../../../../../customHooks/useErrors';
import useNuvoFileName from '../../../../../customHooks/useNuvoFileName';
import { uploadFilePresignedUrl } from '../../../../../services/api/aws';
import { postNuvoAnalytics } from '../../../../../services/api/nuvoAnalytics';
import { getPresignedUrlShipments } from '../../../../../services/api/transportRoutes';
import { basicNuvoStyle } from '../../../../../styles/nuvo';
import { transformNuvoResultsIntoCsv, validateDateFields } from '../../../../../utils/nuvo';
import NuvoImporter from '../../../../ui/nuvoImporter/NuvoImporter';
import { convertStringToDateBackend } from '../../../../../utils/convertDates';
import useGetTransportCombinations from './hooks/useGetTransportCombinations';
import { useNuvoCountries } from '../../../../../customHooks/useNuvoCountries';
import useNuvoButton from '../../../../../customHooks/useNuvoButton';

type Props = {
  type: 'upstream' | 'downstream';
};

const InputNuvo = ({ type }: Props) => {
  const { t, i18n } = useTranslation();
  const ERRORS = useErrors();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName();
  useNuvoButton({ onClick: handleGetFileName });

  const { transportCombinations, loading } = useGetTransportCombinations();

  const { countriesNuvo } = useNuvoCountries();

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const transportOptions: DropdownOptionAPI[] = [
    {
      label: t('templates.shipments.transport_type_types.aircraft'),
      value: 'aircraft',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.truck'),
      value: 'truck',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.car'),
      value: 'car',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.container_ship'),
      value: 'container_ship',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.train'),
      value: 'train',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.motorbike'),
      value: 'motorbike',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.bicycle'),
      value: 'bicycle',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.electric_kick_scooter'),
      value: 'electric_kick_scooter',
      type: 'string'
    },
    {
      label: t('templates.shipments.transport_type_types.do_not_know'),
      value: 'do_not_know',
      type: 'string'
    }
  ];

  const columns: ColumnAPI[] = [
    {
      key: 'description(optional)',
      label: t('templates.shipments.description'),
      columnType: 'string'
    },
    {
      key: 'logistics_supplier(optional)',
      label: t('templates.shipments.logistics_supplier'),
      columnType: 'string'
    },
    {
      key: 'date',
      label: t('templates.shipments.date'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD'
    },
    {
      key: 'origin_place',
      label: t('templates.shipments.origin_place'),
      columnType: 'string'
      // validations: [
      //   {
      //     validate: 'required_without',
      //     columns: ['distance_km']
      //   }
      // ]
    },
    {
      key: 'origin_postal_code',
      label: t('templates.shipments.origin_postal_code'),
      columnType: 'string'
    },
    {
      key: 'origin_country',
      label: t('templates.shipments.origin_country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km']
        }
      ]
    },
    {
      key: 'destination_place',
      label: t('templates.shipments.destination_place'),
      columnType: 'string'
      // validations: [
      //   {
      //     validate: 'required_without',
      //     columns: ['distance_km']
      //   }
      // ]
    },
    {
      key: 'destination_postal_code',
      label: t('templates.shipments.destination_postal_code'),
      columnType: 'string'
    },
    {
      key: 'destination_country',
      label: t('templates.shipments.destination_country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required_without',
          columns: ['distance_km']
        }
      ]
    },
    {
      key: 'distance_km',
      label: t('templates.shipments.distance_km'),
      columnType: 'float',
      validations: [
        {
          validate: 'required_without',
          columns: ['origin_country', 'destination_country']
        }
      ]
    },
    {
      key: 'transport_type',
      label: t('templates.shipments.transport_type'),
      columnType: 'category',
      dropdownOptions: transportOptions,
      isMultiSelect: false,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'electric',
      label: t('templates.shipments.electric'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.yes'),
          value: 'yes',
          type: 'string'
        },
        {
          label: t('templates.no'),
          value: 'no',
          type: 'string'
        }
      ]
    },
    {
      key: 'refrigerated',
      label: t('templates.shipments.refrigerated'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.yes'),
          value: 'yes',
          type: 'string'
        },
        {
          label: t('templates.no'),
          value: 'no',
          type: 'string'
        }
      ]
    },
    {
      key: 'quantity_kg',
      label: t('templates.shipments.quantity_kg'),
      columnType: 'float',
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  const validateRow: OnEntryInit = (row) => {
    const quantityKg = row.quantity_kg as number;
    const errors = {} as any;

    if (quantityKg < 0) {
      errors.quantity_kg = {
        value: quantityKg,
        info: [
          {
            level: 'error',
            message: t('templates.shipments.errors.quantity_kg')
          }
        ]
      };
    }

    // Check if date is in the future
    const date = row.date as string;
    const dateToCheck = convertStringToDateBackend(date);
    dateToCheck.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setDate(today.getDate());

    if (dateToCheck > today) {
      errors.date = {
        value: date,
        info: [
          {
            level: 'error',
            message: t('templates.shipments.errors.date')
          }
        ]
      };
    }

    const dateErrors = validateDateFields(['date'])(row);

    for (const key in dateErrors) {
      errors[key] = dateErrors[key];
    }

    // Check if distance is negative

    const distanceKm = row.distance_km as number;

    if (distanceKm && distanceKm < 0) {
      errors.distance_km = {
        value: distanceKm,
        info: [
          {
            level: 'error',
            message: t('templates.shipments.errors.distance_km')
          }
        ]
      };
    }

    // Check if transport_type is mapped to a valid transportOptions value
    const transportType = row.transport_type as string;

    if (!transportOptions.map((option) => option.value).includes(transportType)) {
      errors.transport_type = {
        value: transportType,
        info: [
          {
            level: 'error',
            message: t('templates.shipments.errors.transport_type')
          }
        ]
      };
    } else {
      // check if transportType is a valid key in transportCombinations
      const electric = row.electric as string;
      const refrigerated = row.refrigerated as string;
      const transportCombination =
        transportCombinations[
          transportType as
            | 'aircraft'
            | 'truck'
            | 'car'
            | 'container_ship'
            | 'train'
            | 'motorbike'
            | 'bicycle'
            | 'electric_kick_scooter'
            | 'do_not_know'
        ];

      if (!transportCombination) {
        errors.transport_type = {
          value: transportType,
          info: [
            {
              level: 'error',
              message: t('templates.shipments.errors.transport_type')
            }
          ]
        };
      } else {
        const foundCombination = transportCombination.find(
          (combination) =>
            combination.electric === electric && combination.refrigerated === refrigerated
        );
        if (!foundCombination) {
          errors.electric = {
            value: electric,
            info: [
              {
                level: 'error',
                message: t('templates.shipments.errors.invalidCombination')
              }
            ]
          };
          errors.refrigerated = {
            value: refrigerated,
            info: [
              {
                level: 'error',
                message: t('templates.shipments.errors.invalidCombination')
              }
            ]
          };
        }
      }
    }

    return errors;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => Object.keys(validateRow(row.data, row.rowIndex) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...validateRow(row.data, row.rowIndex)
          }
        };
      });
  };

  const category = 'transport_and_distribution';

  if (loading) return null;

  return (
    <NuvoImporter
      btnI18nKey={`shipments.${type}.uploadFile`}
      settings={{
        multipleFileUpload: true,
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        identifier: 'transport_and_distribution_template_Dcycle', // Template file name
        columns
      }}
      onEntryChange={onEntryChange}
      onEntryInit={validateRow}
      onCancel={handleExit}
      columnHooks={{
        electric: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 'no'
            },
            index
          ]),
        refrigerated: (values: HookedRecordValue[]) =>
          values.map(([item, index]) => [
            {
              value: item || 'no'
            },
            index
          ])
        // transport_type: (values: HookedRecordValue[]) =>
        //   values.map(([item, index]) => {
        //     console.log('item', item);
        //     return [
        //       {
        //         value: item === '' ? 'do_not_know' : item
        //       },
        //       index
        //     ];
        //   })
      }}
      onResults={async (results, errors, complete) => {
        // cannot submit file with errors
        if (errors.length > 0) return complete(nuvoErrorFounded);

        if (results.length <= 0) return complete(nuvoError);

        const finalFileName = `${fileName || category}.csv`;

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(results);

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // get presigned url
        const data = await getPresignedUrlShipments(finalFileName, type);

        // error uploading file
        if (data?.response?.status >= 400) return complete(nuvoError);

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category: category
        });

        complete();
      }}
    />
  );
};

export default InputNuvo;
