export const sectors = [
  {
    id: 'administrative',
    title: {
      es: 'Administración auxiliar',
      en: 'Auxiliary administration',
      pt: 'Administração auxiliar'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'artistic',
    title: {
      es: 'Arte, cultura y entretenimiento',
      en: 'Art, culture and entertainment',
      pt: 'Arte, cultura e entretenimento'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'domestic',
    title: {
      es: 'Actividades domésticas',
      en: 'Domestic activities',
      pt: 'Atividades domésticas'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'extraterritorial',
    title: {
      es: 'Extraterritorial',
      en: 'Extraterritorial',
      pt: 'Extraterritorial'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'finance',
    title: {
      es: 'Finanzas y seguros',
      en: 'Finance and insurance',
      pt: 'Finanças e seguros'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'real_estate',
    title: {
      es: 'Venta y alquiler de inmuebles',
      en: 'Sale and rental of real estate',
      pt: 'Venda e aluguel de imóveis'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'arquitecture',
    title: {
      es: 'Arquitectura',
      en: 'Architecture',
      pt: 'Arquitetura'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'scientific',
    title: {
      es: 'Ciencia y tecnología',
      en: 'Science and technology',
      pt: 'Ciência e tecnologia'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'sanitary',
    title: {
      es: 'Sanidad',
      en: 'Sanitary',
      pt: 'Sanidade'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'public_administration',
    title: {
      es: 'Administración pública y defensa',
      en: 'Public administration and defense',
      pt: 'Administração pública e defesa'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'agriculture',
    title: {
      es: 'Agricultura, ganadería, silvicultura y pesca',
      en: 'Agriculture, farming, forestry and fishing',
      pt: 'Agricultura, pecuária, silvicultura e pesca'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'wholesale',
    title: {
      es: 'Comercio al por mayor',
      en: 'Wholesale trade',
      pt: 'Comércio atacadista'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'trade_textile',
    title: {
      es: 'Comercio de textil y moda',
      en: 'Textile and fashion trade',
      pt: 'Comércio de têxteis e moda'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'trade_food',
    title: {
      es: 'Comercio de alimentos y nutrición',
      en: 'Food and nutrition trade',
      pt: 'Comércio de alimentos e nutrição'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'trade_cosmetics',
    title: {
      es: 'Comercio de cosmética y cuidado personal',
      en: 'Cosmetics and personal care trade',
      pt: 'Comércio de cosméticos e cuidados pessoais'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'trade_furniture',
    title: {
      es: 'Comercio de muebles y hogar',
      en: 'Furniture and home trade',
      pt: 'Comércio de móveis e casa'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'trade_cleaning',
    title: {
      es: 'Comercio de productos de limpieza',
      en: 'Cleaning products trade',
      pt: 'Comércio de produtos de limpeza'
    }
  },
  {
    id: 'construction',
    title: {
      es: 'Construcción y reformas',
      en: 'Construction and renovations',
      pt: 'Construção e reformas'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'education',
    title: {
      es: 'Educación',
      en: 'Education',
      pt: 'Educação'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'hostelry',
    title: {
      es: 'Hostelería',
      en: 'Hostelry',
      pt: 'Hotelaria'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'manufacture',
    title: {
      es: 'Industria manufacturera',
      en: 'Manufacturing industry',
      pt: 'Indústria de manufatura'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'manufacture_textile',
    title: {
      es: 'Producción textil y moda',
      en: 'Textile and fashion production',
      pt: 'Produção têxtil e moda'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'manufacture_food',
    title: {
      es: 'Producción de alimenticia',
      en: 'Food production',
      pt: 'Produção de alimentos'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'manufacture_cosmetics',
    title: {
      es: 'Producción cosmética ',
      en: 'Cosmetics production',
      pt: 'Produção cosmética'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'manufacture_furniture',
    title: {
      es: 'Producción de muebles y hogar',
      en: 'Furniture and home production',
      pt: 'Produção de móveis e casa'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'manufacture_cleaning',
    title: {
      es: 'Producción de productos de limpieza',
      en: 'Cleaning products production',
      pt: 'Produção de produtos de limpeza'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'extractive',
    title: {
      es: 'Industrias extractivas',
      en: 'Extractive industries',
      pt: 'Indústrias extrativas'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'information_communications',
    title: {
      es: 'Información y comunicaciones',
      en: 'Information and communications',
      pt: 'Informação e comunicações'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'marketing',
    title: {
      es: 'Marketing y publicidad',
      en: 'Marketing and advertising',
      pt: 'Marketing e publicidade'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'other',
    title: {
      es: 'Otros servicios',
      en: 'Other services',
      pt: 'Outros serviços'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'water_waste_supply',
    title: {
      es: 'Suministro de agua, actividades de saneamiento, gestión de residuos y descontaminación',
      en: 'Water supply, activities of sanitation, management of waste and disinfection',
      pt: 'Fornecimento de água, atividades de saneamento, gestão de resíduos e desinfecção'
    },
    description: {
      en: '',
      es: ''
    }
  },
  {
    id: 'electricity_gas_supply',
    title: {
      es: 'Suministro de energía eléctrica, gas, vapor y aire acondicionado',
      en: 'Electricity supply, gas, vapor and air conditioning',
      pt: 'Fornecimento de eletricidade, gás, vapor e ar condicionado'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  },
  {
    id: 'transport',
    title: {
      es: 'Transporte y almacenamiento',
      en: 'Transportation and storage',
      pt: 'Transporte e armazenamento'
    },
    description: {
      en: '',
      es: '',
      pt: ''
    }
  }
];

export const WATER_WASTE_SUPPLY_SECTOR = 'water_waste_supply';
