import { useState, useEffect, useContext } from 'react';
import { FiltersContext } from './FilterContext';
import Accordion from '../../ui/accordion/Accordion';
import InputDateFilter from '../../ui/formComponents2/inputDateFilter/InputDateFilter';
import { useTranslation } from 'react-i18next';
import useDebounceValue from '../../../customHooks/useDebounceValue';

const FiltersDate = () => {
  const { dates, setDates, clearDates, setClearDates } = useContext(FiltersContext);
  const [startDate, setStartDate] = useState(dates?.startDate || '');
  const [endDate, setEndDate] = useState(dates?.endDate || '');
  const { t } = useTranslation('translation', { keyPrefix: 'general.filters' });

  const debounceValueStartDate = useDebounceValue(startDate);
  const debounceValueEndDate = useDebounceValue(endDate);
  useEffect(() => {
    // Check if it is a valid date
    if (debounceValueStartDate && debounceValueEndDate && setDates) {
      setDates({ startDate: debounceValueStartDate, endDate: debounceValueEndDate });
    } else if (!debounceValueStartDate && setDates) {
      setDates(undefined);
    }
    if (clearDates && setClearDates) {
      setStartDate('');
      setEndDate('');
      setClearDates(false);
    }
  }, [debounceValueStartDate, debounceValueEndDate, clearDates]);

  return (
    <div className='filters__section'>
      <section className='filters__options'>
        <Accordion>
          <Accordion.Item
            label={t('dateRange')}
            iconLeft
            active
            content={
              <InputDateFilter
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
        </Accordion>
      </section>
    </div>
  );
};

export default FiltersDate;
