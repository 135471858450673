import { useSearchParams } from 'react-router-dom';
import { Modeling } from '../components/modeling/Modeling';

import { Criteria } from '../components/criteria/Criteria';

const STEPS = {
  criteria: 'criteria',
  modeling: 'modeling'
} as const;

const useSteps = () => {
  const [query, setQuery] = useSearchParams({ step: STEPS.criteria });

  const stepSelected = query.get('step');

  const steps = Object.values(STEPS).map((value) => {
    return {
      id: value,
      text: `sbti.${value}`,
      isSelected: query.get('step') === value
    };
  });

  const handleSelect = (step: string) => setQuery({ step });

  const getComponent = (step: string | null) => {
    const components = {
      criteria: Criteria,
      modeling: Modeling
    };

    return components[step as keyof typeof components] || <Criteria />;
  };

  return {
    steps,
    stepSelected,
    handleSelect,
    Component: getComponent(stepSelected)
  };
};

export default useSteps;
