import { useTranslation } from 'react-i18next';
import { ValidationCategory } from '../Validation';

const useStepsByCategory = (category: ValidationCategory) => {
  const { t } = useTranslation();
  let data: { id: string; text: string }[] = [];

  if (category === ValidationCategory.FACILITIES) {
    data = [
      {
        id: 'heat',
        text: t('validation.heat')
      },
      {
        id: 'electricity',
        text: t('validation.electricity')
      },

      {
        id: 'recharge',
        text: t('validation.recharges')
      }
    ];
  }
  if (category === ValidationCategory.VEHICLES) {
    data = [
      {
        id: 'consumptions',
        text: t('validation.consumptions')
      }
    ];
  }

  if (category === ValidationCategory.ALL) {
    data = [
      {
        id: 'electricity',
        text: t('validation.electricity')
      },
      {
        id: 'heat',
        text: t('validation.heat')
      },
      {
        id: 'recharge',
        text: t('validation.recharges')
      },
      {
        id: 'consumptions',
        text: t('validation.consumptions')
      }
    ];
  }
  return data;
};

export default useStepsByCategory;
