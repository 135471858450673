import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { ShipmentForm, TransportSection } from '../../../../../types/entities/shipment';
import { convertDateToString } from '../../../../../utils/convertDates';
import FormElementFull from '../../../../ui/formComponents/formElementFull/FormElementFull';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendar from '../../../../ui/formComponents2/formInputs/formCalendar/FormCalendar';
import FormSelect from '../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import { ErrorDate } from '../../../../ui/formComponents2/inputCalendar/InputCalendar';
import InputNumber from '../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../ui/formComponents2/select/Select';
import CustomSkeletonLoader from '../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useGetData from '../hooks/useGetData';
import TransportSections from '../transportSections/TransportSections';

type Props = {
  formData: ShipmentForm;
  setFormData: Dispatch<SetStateAction<ShipmentForm>>;
  transportDirection: 'upstream' | 'downstream';
  loading?: boolean;
};
const FormShipment = ({ formData, setFormData, transportDirection, loading }: Props) => {
  const { t } = useTranslation();

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { units, loadingUnits } = useGetData();

  useEffect(() => {
    if (loading || loadingUnits) return;
    // Find unit with id in formData.unit.id
    const unit = units.find((elem) => elem.id === formData.unit.id);
    if (!unit) return;
    setFormData((prev) => ({
      ...prev,
      unit: {
        id: unit.id,
        name: t(`units.${unit.name}`)
      }
    }));
  }, [loading, loadingUnits]);

  const onChangeRefrigerated = (value: SelectOptionFormat) => {
    // set all transport sections transport type to empty string

    setFormData((prev) => ({
      ...prev,
      refrigerated: value,
      transportSections: prev.transportSections.map((elem) => {
        return { ...elem, transport_type: '' };
      }),
      errors: prev.errors.filter((elem) => elem.error !== 'refrigerated')
    }));
  };

  const modifyTransportSection = (index: number, transportSection: TransportSection) => {
    const newTransportSections = [...formData.transportSections];
    newTransportSections[index] = transportSection;
    if (index + 1 < formData.transportSections.length) {
      newTransportSections[index + 1].origin = transportSection.destination;
    }

    let newErrors = formData.errors.filter((error) => error.error !== 'invalidTravelMode');

    // Check if origin and destination are not empty
    if (transportSection.destination !== '' && transportSection.origin !== '') {
      newErrors = newErrors.filter((elem) => elem.error !== `transportSectionsRoute-${index}`);
    }
    if (transportSection.transport_type !== '') {
      newErrors = newErrors.filter(
        (elem) => elem.error !== `transportSectionsTransportType-${index}`
      );
    }
    setFormData((prev) => ({
      ...prev,
      transportSections: newTransportSections,
      errors: newErrors
    }));
  };

  const addTransportSection = (index: number) => {
    const newTransportSections = [...formData.transportSections];
    let origin = '';
    if (index + 1 > 0) {
      origin = newTransportSections[index].destination;
    }
    newTransportSections.splice(index + 1, 0, {
      location_origin_id: '',
      location_destination_id: '',
      origin,
      destination: '',
      transport_type: '',
      transport_kilometers: ''
    });
    setFormData((prev) => ({
      ...prev,
      transportSections: newTransportSections
    }));
  };

  const removeTransportSection = (index: number) => {
    const newTransportSections = [...formData.transportSections];
    // if it is not the first one nor the last one
    if (index + 1 > 0 && index + 1 < newTransportSections.length) {
      newTransportSections[index + 1].origin = newTransportSections[index - 1].destination;
    }
    newTransportSections.splice(index, 1);

    setFormData((prev) => ({
      ...prev,
      transportSections: newTransportSections
    }));
  };

  const handleDateError = (error?: ErrorDate) => {
    if (!error) {
      const newErrors = formData.errors.filter(
        (elem) => elem.error !== 'date' && elem.error !== 'startDate'
      );
      setFormData((prev) => ({ ...prev, errors: newErrors }));
      return;
    }
    if (formData.errors.some((elem) => elem.error === 'date')) return;
    setFormData((prev) => ({ ...prev, errors: [...prev.errors, error] }));
  };

  if (loadingUnits || loading) {
    return <CustomSkeletonLoader count={6} style={{ marginBottom: '0.5rem' }} />;
  }
  return (
    <FormWrapper>
      <FormText
        icon={'/images/icons/catalog.svg'}
        placeholder={t('shipments.writeShipmentDescription')}
        label={t('shipments.descriptionOptional')}
        value={formData.name}
        onChange={onChangeValue('name')}
        error={formData.errors.find((elem) => elem.error === 'name')}
      />
      <InputWrapper
        icon={'/images/icons/plus.svg'}
        label={t('shipments.quantity')}
        error={formData.errors.find((elem) => elem.error === 'quantity' || elem.error === 'unit')}>
        <InputNumber
          placeholder={t('shipments.quantity')}
          onChange={onChangeValue('quantity')}
          value={formData.quantity as string}
        />
        <Select
          placeholder={''}
          options={units.map((elem) => {
            return {
              id: elem.id,
              name: t(`units.${elem.name}`)
            };
          })}
          value={formData.unit}
          onChange={onChangeValue('unit')}
        />
      </InputWrapper>
      <FormCalendar
        label={t(`shipments.${transportDirection}.startDate`)}
        handleChangeDate={onChangeValue('startDate')}
        error={formData.errors.find((elem) => elem.error === 'startDate' || elem.error === 'date')}
        dateValue={formData.startDate}
        handleDateError={handleDateError}
        maxDate={convertDateToString(new Date())}
      />
      <FormSelect
        icon='/images/icons/clock.svg'
        placeholder={t('shipments.selectFrequency')}
        label={t('shipments.frequency')}
        options={[
          { id: 'once', name: t('shipments.once') },
          { id: 'weekly', name: t('shipments.weekly') },
          { id: 'monthly', name: t('shipments.monthly') }
        ]}
        value={formData.frequency}
        onChange={onChangeValue('frequency')}
        error={formData.errors.find((elem) => elem.error === 'frequency')}
      />
      <FormText
        icon='/images/icons/stacked50.svg'
        placeholder={t('shipments.writeSupplier')}
        label={t('shipments.supplierOptional')}
        value={formData.supplier}
        onChange={onChangeValue('supplier')}
        error={formData.errors.find((elem) => elem.error === 'supplier')}
      />
      <FormSelect
        icon='/images/icons/thunder50.svg'
        placeholder={t('shipments.selectOption')}
        label={t('shipments.commodityRefrigerated')}
        options={[
          { id: 'yes', name: t('shipments.yes') },
          { id: 'no', name: t('shipments.no') }
        ]}
        value={formData.refrigerated}
        onChange={onChangeRefrigerated}
        error={formData.errors.find((elem) => elem.error === 'refrigerated')}
      />
      <FormElementFull>
        {formData.transportSections.map((elem, index) => (
          <TransportSections
            key={index}
            transportSectionIndex={index}
            transportSection={elem}
            transportSections={formData.transportSections}
            modifyTransportSection={modifyTransportSection}
            addTransportSection={addTransportSection}
            removeTransportSection={removeTransportSection}
            refrigerated={formData.refrigerated.id === 'yes'}
            errors={formData.errors}
          />
        ))}
      </FormElementFull>
    </FormWrapper>
  );
};

export default FormShipment;
