import { useEffect, useState } from 'react';
import { timeout } from '../../../../utils/time';

export type Position = {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
};

const useHandleStep = (steps: string[]) => {
  const [current, setCurrent] = useState<string | null>(null);

  const [position, setPosition] = useState<Position | null>(null);
  const [currentElement, setCurrentElement] = useState<HTMLElement | null>(null);

  const handleNextRetry = async (numberRetires: number, time: number) => {
    let element = null;
    let i = 0;

    while (!element && i < numberRetires) {
      await timeout(time);
      element = handleNext();
      i++;
    }
  };

  const handleNext = () => {
    if (!current) return;

    // next index
    const index = steps.map((step) => step).indexOf(current);
    if (index === -1) return;
    const nextIndex = index + 1;

    // next current step
    let newCurrentStep = null;
    if (steps.length !== nextIndex) newCurrentStep = steps[nextIndex];

    if (!newCurrentStep) {
      setCurrent(newCurrentStep);
      return;
    }

    const newElement: HTMLElement | null = document.querySelector(newCurrentStep);

    setCurrentElement(newElement);
    setCurrent(newCurrentStep);
    handleResize(newElement);

    return newElement;
  };

  const handleSkip = () => {
    setCurrent(null);
    setPosition(null);
  };
  const handleStart = () => setCurrent(steps[0]);

  // position stuff

  const handleResize = (element: HTMLElement | null) => {
    if (!element) return;
    const elementPosition = element.getBoundingClientRect();
    setPosition({
      left: elementPosition.left,
      top: elementPosition.top,
      right: elementPosition.right,
      bottom: elementPosition.bottom,
      width: elementPosition.width,
      height: elementPosition.height
    });
  };

  useEffect(() => {
    const handleResizeElement = () => handleResize(currentElement);

    window.addEventListener('resize', handleResizeElement);
    window.addEventListener('scroll', handleResizeElement, { capture: true });

    return () => {
      window.removeEventListener('resize', handleResizeElement);
      window.removeEventListener('scroll', handleResizeElement);
    };
  }, [current]);

  const handlePositionNull = () => setPosition(null);

  return {
    current,
    position,
    handleNext,
    handleNextRetry,
    handleSkip,
    handleStart,
    handlePositionNull
  };
};

export default useHandleStep;
