import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { scope3_color } from '../../../../styles/colors';
import { InvestmentReport } from '../../../../types/entities/holdings';
import { Organization } from '../../../../types/entities/organization';
import numberToDecimal, {
  formatNumberToDecimalNonZero,
  numberToScientificNotation
} from '../../../../utils/numberToDecimal';
import LineChart2 from '../../../ui/charts/LineChart2';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import LineChartWrapper from '../common/lineChartWrapper/LineChartWrapper';
import List from '../common/list/List';
import useDataCo2 from '../hooks/useDataCo2';

type Props = {
  data: InvestmentReport[];
  dataByMonth: TotalImpactPerMonth[];
  startDate: string;
  endDate: string;
  organizationCo2e: number;
  organization: Organization;
};
const Investments = ({
  data,
  dataByMonth,
  startDate,
  endDate,
  organizationCo2e,
  organization
}: Props) => {
  const { t, i18n } = useTranslation();
  const columns = [t('ghgReport.companies'), '%', t('ghgReport.co2eq.(tns)')];
  const rows: string[][] = [];

  let totalCo2 = 0;

  let totalCo2Top3 = 0;
  const top3Orgs = data.slice(0, 3);
  top3Orgs.forEach((elem) => {
    totalCo2Top3 += elem.co2e;
  });
  totalCo2Top3 = numberToDecimal(totalCo2Top3 / 1000);

  data.forEach((elem: InvestmentReport) => {
    totalCo2 += elem.co2e;
    rows.push([elem.org_name, '0', numberToScientificNotation(elem.co2e / 1000)]);
  });

  totalCo2 = numberToDecimal(totalCo2 / 1000);
  // Add percentage column value to each row now that we have the total
  rows.forEach((row) => {
    row[1] = numberToScientificNotation((parseFloat(row[2]) / totalCo2) * 100);
  });

  const { dataCo2, monthLabels } = useDataCo2({ startDate, endDate });
  // Group data by date
  const groupedByMonth = [] as GroupByMonth[];

  dataByMonth.forEach((elem: TotalImpactPerMonth) => {
    const elemDate = moment(new Date(elem.year, elem.month - 1)).format('YYYY-MM');
    const foundIndex = groupedByMonth.findIndex((elem2) => elem2.date === elemDate);

    if (foundIndex > -1) {
      groupedByMonth[foundIndex].emissions_per_month += elem.emissions_per_month;
    } else {
      groupedByMonth.push({
        date: elemDate,
        emissions_per_month: elem.emissions_per_month
      });
    }
  });

  groupedByMonth.forEach((elem) => {
    const foundIndex = dataCo2.findIndex((elem2) => elem2.x === elem.date);
    if (foundIndex > -1) {
      dataCo2[foundIndex].y += elem.emissions_per_month / 1000;
    }
  });

  const descriptions = [
    t('ghgReport.investmentsEmissionsDescription.total', {
      total: totalCo2.toString(),
      percentage: numberToDecimal(((totalCo2 * 1000) / organizationCo2e) * 100).toString(),

      tradeName: organization.company_name
    }),
    // t('ghgReport.investmentsEmissionsDescription.offset'),
    // t('ghgReport.investmentsEmissionsDescription.dataQuality', {
    //   quality: averageDataQuality.toString()
    // }),
    t('ghgReport.investmentsEmissionsDescription.top3', {
      totalCompanies: top3Orgs.length.toString(),
      percentage: numberToDecimal((totalCo2Top3 / totalCo2) * 100).toString()
    })
  ];
  if (top3Orgs.length > 0) {
    descriptions.push(
      t('ghgReport.investmentsEmissionsDescription.companyTop', {
        companyName: top3Orgs[0].org_name
      })
    );
  }

  return (
    <div className='ghg-investments'>
      <LineChartWrapper
        legends={[
          {
            name: 't CO₂ eq.',
            icon: '/images/icons/dotScope3.svg'
          }
        ]}>
        <LineChart2
          labels={monthLabels}
          chartData={[
            {
              label: 'scope1',
              borderColor: scope3_color,
              data: dataCo2.map((elem) => elem.y),
              borderWidth: 1,
              pointRadius: 0,
              pointHoverRadius: 0,
              stepped: false,
              backgroundColor: 'rgba(41, 69, 255, 0.1)',
              fill: true,
              tension: 0
            }
          ]}
          disabled={false}
        />
      </LineChartWrapper>
      <div className='list-description-wrapper'>
        <List
          icon={'/images/icons/organization.svg'}
          columns={columns}
          rows={rows}
          totalRow={['', numberToScientificNotation(totalCo2)]}
        />
        <DescriptionBox descriptions={descriptions} />
      </div>
    </div>
  );
};

export default Investments;
