import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { editHub } from '../../../../../services/api/hubs';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import EditHubForm from '../hubForm/EditHubForm';

type Props = {
  hubToEdit: IBackendHub;
  handleEditHub: (value: IBackendHub) => void;
};
const EditHub = ({ hubToEdit, handleEditHub }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'hubs' });
  const { t: tr } = useTranslation('translation', { keyPrefix: 'logisticHocs' });

  const [formData, setFormData] = useState<FormDataHubs>({
    type: hubToEdit.type,
    name: hubToEdit.name,
    hubCategory: {
      id: hubToEdit.category ? hubToEdit.category : hubToEdit.hub_category,
      name: hubToEdit.category ? tr(hubToEdit.category) : tr(hubToEdit.hub_category)
    },
    address: hubToEdit.address,
    categories: [
      { id: 'heat', name: t('facilityDetail.combustion') },
      { id: 'electricity', name: t('facilityDetail.electricity') },
      { id: 'recharge', name: t('facilityDetail.recharge') },
      { id: 'water', name: t('facilityDetail.water') },
      { id: 'wastes', name: t('facilityDetail.waste') }
    ],
    isSuperCharger: hubToEdit.supercharger ? hubToEdit.supercharger : false,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const selectedOrganization = useSelectedOrganization();

  const handleErrors = () => {
    const optionalFields: string[] = [];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors.length !== 0 ? newErrors : false;
  };

  const handleSubmit = async () => {
    setLoadingButton(true);

    const newErrors = handleErrors();
    if (newErrors) return;
    setLoadingButton(true);

    let hubEdited = null;

    const body: any = {
      name: formData.name,
      type: formData.type,
      address: formData.address,
      organization_id: selectedOrganization ? selectedOrganization?.id : '',
      category: formData.hubCategory.id,
      categories: formData.categories.map((elem) => elem.id)
    };

    hubEdited = await editHub(hubToEdit.id, body);
    setLoadingButton(false);

    if (!hubEdited) return;

    handleEditHub(hubEdited);
  };

  return (
    <div className='create-hub'>
      <FormHeader
        title={t('createHub.editHubTitle')}
        description={t('createHub.editHubDescription')}
      />
      <EditHubForm
        formData={formData}
        setFormData={setFormData}
        // editing={true}
        disabledFields={
          hubToEdit.type === 'subcontracted' ? ['address', 'type', 'category', 'supercharger'] : []
        }
      />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          onClick={handleSubmit}
          loading={loadingButton}
          text={t('create')}
        />
      </FormButtonSection>
    </div>
  );
};

export default EditHub;
