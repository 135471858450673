function removeSpacesAndDotsFromFileName(fileName: string) {
  // Split the file name into name and extension parts
  const parts = fileName.split('.');

  // Get the file extension
  const extension = parts.pop();

  // Remove spaces and dots from the name part
  const namePart = parts.join('.').replace(/[ .]/g, '');

  // Join the name part and extension with a dot
  const newFileName = namePart + '.' + extension;

  return newFileName;
}

export default removeSpacesAndDotsFromFileName;
