import { useEffect, useState } from 'react';

type Props = {
  startDate: string;
  endDate: string;
};
const useGetData = ({ startDate, endDate }: Props) => {
  const [dataScopes, setDataScopes] = useState<ScopeData[]>([]);

  const generateRandomDataScopes = () => {
    const randomDataScopes: ScopeData[] = [
      { scope: 1, total_emissions: 647.28, measured: true },
      { scope: 2, total_emissions: 17.5, measured: true },
      { scope: 3, total_emissions: 196.02 + 10600.82, measured: true }
    ];

    setDataScopes(randomDataScopes);
  };

  useEffect(() => {
    generateRandomDataScopes();
  }, [startDate, endDate]);

  return { dataScopes };
};

export default useGetData;
