export const getDateOfYear = (year: number, month: number, day: number) =>
  new Date(year, month, day);

const getToday = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate());
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setMonth(0);
  oneYearAgo.setDate(1);
  oneYearAgo.setHours(0, 0, 0, 0);
  return { today, oneYearAgo };
};

export default getToday;
