import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

function LoaderCard(props: Props) {
  const newClassName = `center-wrapper card ${props.className}`;

  return (
    <div {...props} className={newClassName}>
      <div className={`loader-standard-wrapper`}>
        <div className='dot-falling'></div>
      </div>
    </div>
  );
}

export default LoaderCard;
