import * as Tooltip from '@radix-ui/react-tooltip';
import { HTMLProps, ReactNode, useState } from 'react';
import './styles.scss';

type Props = {
  text: string | ReactNode;
  position?: 'top' | 'right' | 'left' | 'bottom';
  children: ReactNode;
  size?: string;
  style?: HTMLProps<HTMLDivElement>['style'];
  shouldAppear?: boolean;
  disableHoverableContent?: boolean;
  asChild?: boolean;
  wordBreak?: 'break-all' | 'keep-all' | 'normal';
};

const TooltipWrapper = ({
  text,
  shouldAppear = true,
  position = 'top',
  size = 'medium',
  children,
  asChild = false,
  disableHoverableContent,
  style,
  wordBreak = 'normal'
}: Props) => {
  if (!shouldAppear) return <>{children}</>;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const render = asChild ? (
    children
  ) : (
    <div className={`tooltip ${size}`} style={style}>
      {children}
    </div>
  );
  return (
    <Tooltip.Root
      open={isOpen}
      onOpenChange={handleOpenChange}
      disableHoverableContent={disableHoverableContent ?? true}>
      <Tooltip.Trigger asChild onClick={() => setIsOpen(true)}>
        {render}
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content side={position} style={{ zIndex: 101 }}>
          <Tooltip.Arrow className='tooltip-arrow' />
          <div className={`tooltip-modal tag2-bg-text-color tooltip-font ${wordBreak}`}>{text}</div>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

export default TooltipWrapper;
