import React, { ReactNode, useEffect, useState } from 'react';
import './styles.scss';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  animation?: 'bounce' | 'rubberBand' | 'shake' | 'wobble' | 'jello' | '';
  animationIntervalSeconds?: number;
}
const NotificationBubble = ({
  children,
  animation = 'bounce',
  animationIntervalSeconds = 3,
  ...rest
}: Props) => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowAnimation((prev) => !prev);
    }, animationIntervalSeconds * 1000);

    return () => {
      // Cleanup: clear the interval when component unmounts
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div
      className={`notification-bubble weight-600 animated ${showAnimation ? animation : ''}`}
      {...rest}>
      {children}
    </div>
  );
};

export default NotificationBubble;
