import { useTranslation } from 'react-i18next';
import { ExtraInformationType } from '../../../../../../../types/entities/employee';
import './styles.scss';

const ExtraInformation = ({
  pending,
  switched,
  terminated,
  // newEmployees = 0,
  year
}: ExtraInformationType) => {
  const { t } = useTranslation();
  // const totalMissing = 0 + pending + switched + terminated;
  return (
    <div className='extra-information body1-font tag-bg-text-color'>
      {/* <p className='body2-font'>
        {t('report.employeesSection.missing', { total: totalMissing, year })}
      </p> */}
      <p className='body2-font'>{t('report.employeesSection.pending', { pending })}</p>
      {/* <p className='body3-font'>{t('report.employeesSection.switched', { switched })}</p> */}
      {/* <p className='body3-font'>
        {t('report.employeesSection.terminated', { terminated, year: year + 1 })}
      </p> */}

      {/* {newEmployees && (
        <p className='body3-font'>
          {t('report.employeesSection.new', { new: newEmployees, year: year + 1 })}
        </p>
      )} */}
    </div>
  );
};

export default ExtraInformation;
