import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLogisticHocs } from '../../../../../../services/api/logistics';

const useGetData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'logisticHocs' });
  const [hocOptions, setHocOptions] = useState<SelectOptionFormat[]>([]);
  const fetchHocs = async () => {
    const data = await getLogisticHocs();

    const options = data.map((elem: any) => ({
      id: elem.category,
      name: t(elem.category)
    }));

    setHocOptions(options);
  };

  useEffect(() => {
    fetchHocs();
  }, []);
  return { hocOptions };
};

export default useGetData;
