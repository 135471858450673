import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'purchases' });

  const columns = [
    {
      title: t('productName'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('productCategory'),
      dataIndex: 'product_name',
      key: 'product_name',
      className: 'max-chars-10'
    },
    {
      title: t('supplier'),
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: t('supplierCountry'),
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: t('date'),
      dataIndex: 'purchase_date',
      key: 'purchase_date'
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: 175
    },
    {
      title: t('co2eq'),
      dataIndex: 'co2e',
      key: 'co2e'
    },
    {
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      width: 100
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];

  return { columns };
};
