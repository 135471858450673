import { adaptTaskFrontToBack } from '../../adapters/tasks';
import { IFrontendFormTask, IPostBackendTask } from '../../types/entities/task';
import apiFetch from '../../utils/apiFetch';
import { uploadFilePresignedUrl } from './aws';

export const getTags = async (returnRequest = false) => {
  try {
    const request = apiFetch('GET', '/tasks/tags');
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const createTask = async (body: IFrontendFormTask) => {
  try {
    const bodyParsed = adaptTaskFrontToBack(body);
    const response = await apiFetch('POST', '/tasks', bodyParsed);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const createPredefinedTask = async (body: IPostBackendTask, returnRequest = false) => {
  try {
    const request = apiFetch('POST', '/tasks', body);
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (err) {
    return null;
  }
};

export const editTask = async (body: IFrontendFormTask, id: string) => {
  try {
    const bodyParsed = adaptTaskFrontToBack(body);
    const response = await apiFetch('PATCH', `/tasks/${id}`, bodyParsed);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const markTaskAsCompleted = async (id: string, completed: boolean) => {
  try {
    const response = await apiFetch('PATCH', `/tasks/${id}`, { completed });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteTask = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `/tasks/${id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getTasksByUser = async (user_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      '/tasks',
      {},
      {},
      {
        page: 1,
        size: 100,
        filters: user_id
      }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

// This gets the presigned url and uploads it aswell to S3
export const uploadAttachmentsToTask = async (task_id: string, file: File) => {
  try {
    const response = await apiFetch('POST', `/tasks/${task_id}/attachment/upload`, {
      file_name: file.name
    });
    const data = response.data;
    const presignedUrl = data.upload_url;

    await uploadFilePresignedUrl(file, presignedUrl);

    return {
      id: data.file_id,
      name: data.file_name,
      url: data.upload_url
    };
  } catch (err) {
    return null;
  }
};

export const deleteAttachmentFromTask = async (task_id: string, attachment_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/tasks/${task_id}/attachment/${attachment_id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getTaskById = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/tasks/${id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getCommentsByTaskId = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/tasks/${id}/comments`);
    return response.data;
  } catch (err) {
    return [];
  }
};

export const createComment = async (task_id: string, comment: string, created_by: string) => {
  try {
    const response = await apiFetch('POST', `/tasks/comments`, { task_id, comment, created_by });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteComment = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `/tasks/comments/${id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};
