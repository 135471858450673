import { useEffect } from 'react';
import { groupBy } from '../../../../utils/arrays';
import {
  checkIfValidStringDate,
  convertDateFrontToBackTimestamp
} from '../../../../utils/convertDates';
import { FilterContextType } from '../FilterContext';

type UseFiltersType = Pick<
  FilterContextType,
  | 'setFilters'
  | 'dates'
  | 'filters'
  | 'setDates'
  | 'removeFilters'
  | 'remove'
  | 'setClearDates'
  | 'dateKeys'
  | 'searchFilters'
> & { setUrl: (url: string) => void; url: string };

const useFilters = ({
  setUrl,
  url,
  setFilters,
  filters,
  removeFilters,
  remove,
  dates = undefined,
  setDates = undefined,
  setClearDates = undefined,
  dateKeys = ['start_date', 'end_date'],
  searchFilters = []
}: UseFiltersType) => {
  const buildCustomFilters = (urlToBuild: string) => {
    let urlUpdated = urlToBuild;
    if (!filters.length || remove) return urlUpdated;
    const orderByType = Object.entries(groupBy(filters, 'type'));
    const convertToString = orderByType
      .map((value) => {
        const [key, values] = value as [string, Array<any>];
        const parsedValues = values.map((value) => `"${value.id}"`);
        return `${key}:in[${parsedValues.toString()}]`;
      })
      .join('$');

    if (urlUpdated.includes('filter_by')) {
      urlUpdated = `${urlUpdated}$${convertToString}`;
    } else {
      const operator = urlUpdated.includes('?') ? '&' : '?';

      urlUpdated = `${urlUpdated}${operator}filter_by=${convertToString}`;
    }

    return urlUpdated;
  };

  const buildDateFilters = (urlToBuild: string) => {
    let urlUpdated = urlToBuild;

    if (!dates || remove) return urlUpdated;

    // check if dates are valid, the dates come in the format dd/mm/yyyy
    if (!checkIfValidStringDate(dates.startDate) || !checkIfValidStringDate(dates.endDate))
      return urlUpdated;

    const startDateFormated = convertDateFrontToBackTimestamp(dates.startDate);
    const endDateFormated = convertDateFrontToBackTimestamp(dates.endDate);
    if (isNaN(startDateFormated) || isNaN(endDateFormated)) return urlUpdated;
    let dateFilters = '';

    if (urlUpdated.includes('filter_by')) {
      dateKeys.forEach((elem: string) => {
        dateFilters += `$${elem}:rd[${startDateFormated},${endDateFormated}]`;
      });
      urlUpdated += `${dateFilters}`;
    } else {
      const operator = urlUpdated.includes('?') ? '&' : '?';
      dateKeys.forEach((elem: string, index: number) => {
        if (index === 0) {
          dateFilters += `${operator}filter_by=${elem}:rd[${startDateFormated},${endDateFormated}]`;
        } else {
          dateFilters += `$${elem}:rd[${startDateFormated},${endDateFormated}]`;
        }
      });

      urlUpdated += `${dateFilters}`;
    }
    return urlUpdated;
  };

  const buildSearchFilters = (urlToBuild: string) => {
    let urlUpdated = urlToBuild;
    if (!searchFilters.length || remove) return urlUpdated;

    const emptySearchFilters = searchFilters.filter((searchFilter) => searchFilter.value === '');

    // let stopFiltering = false;
    // emptySearchFilters.forEach((searchFilter) => {
    //   const { key } = searchFilter;
    //   if (!urlUpdated.includes(`${key}:lk`)) {
    //     stopFiltering = true;
    //   } else {
    //     stopFiltering = false;
    //   }
    // });
    // console.log('stopFiltering', stopFiltering);
    // // This will stop filtering if all searchFilters are empty
    // if (stopFiltering) {
    //   return urlUpdated;
    // }

    searchFilters.forEach((searchFilter) => {
      const { key, value } = searchFilter;

      if (!value) return;
      if (urlUpdated.includes('filter_by')) {
        urlUpdated = `${urlUpdated}$${key}:il${value}`;
      } else {
        const operator = urlUpdated.includes('?') ? '&' : '?';
        urlUpdated = `${urlUpdated}${operator}filter_by=${key}:il${value}`;
      }
    });
    return urlUpdated;
  };

  useEffect(() => {
    let urlUpdated = url;

    const urlSearchParams = new URLSearchParams(urlUpdated.split('?')[1]);

    // Delete filter_by query param
    urlSearchParams.delete('filter_by');
    urlUpdated = urlUpdated.split('?')[0];
    // If there were other params that were not filter_by, add them again
    if (urlSearchParams.toString()) {
      urlUpdated += `?${urlSearchParams.toString()}`;
    }

    urlUpdated = buildDateFilters(urlUpdated);
    urlUpdated = buildSearchFilters(urlUpdated);
    urlUpdated = buildCustomFilters(urlUpdated);

    setUrl(urlUpdated);
  }, [dates, JSON.stringify(filters.map((elem) => elem.id)), JSON.stringify(searchFilters)]);

  useEffect(() => {
    if (!remove) return;
    setFilters([]);
    removeFilters(false);
    if (searchFilters) {
      searchFilters.forEach((searchFilter) => {
        searchFilter.setValue('');
      });
    }
    if (dates && setDates && setClearDates) {
      setDates(undefined);
      setClearDates(true);
    }
    // remove from url anything that has filter_by until a & is found
    const regex = new RegExp(`filter_by[^&]*`, `g`);
    let urlUpdated = url.replace(regex, '');
    urlUpdated = urlUpdated.replace('?&', '?');
    setUrl(urlUpdated);
  }, [remove]);
};

export default useFilters;
