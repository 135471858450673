import { HTMLAttributes, ReactNode } from 'react';
import './styles.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const OffsetCard = ({ children, className, ...rest }: Props) => {
  const newClassName = 'offset-card ' + (className || '');
  return (
    <div className={newClassName} {...rest}>
      {children}
    </div>
  );
};

export default OffsetCard;
