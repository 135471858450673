import { enGB, es } from 'date-fns/locale';
import { ChangeEvent, memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useTranslation } from 'react-i18next';
import { regexNumberAndSlash, regexddmmyyyy } from '../../../../constants/regex';
import useOutsideClick from '../../../../customHooks/useOutsideClick';
import { highlight_color } from '../../../../styles/colors';
import { InputSize } from '../../../../types/utilsEnums/input';
import { convertDateToString, convertStringToDate } from '../../../../utils/convertDates';
import { formatDate } from '../../../../utils/formatDate';
import InputText from '../inputText/InputText';
import './styles.scss';

type Props = {
  handleChangeDate: (value: string) => void;
  handleDateError?: (value: ErrorDate) => void;
  dateValue: string;
  disabled?: boolean;
  position?: string;
  maxDate?: string;
  minDate?: string;
  resetDate?: () => void;
  size?: InputSize;
};

const arePropsEqual = (oldProps: Props, newProps: Props) =>
  oldProps.dateValue === newProps.dateValue &&
  oldProps.disabled === newProps.disabled &&
  oldProps.maxDate === newProps.maxDate &&
  oldProps.minDate === newProps.minDate &&
  oldProps.position === newProps.position;

export enum ErrorDate {
  FORMAT = 'FORMAT',
  MAX_DATE = 'MAX_DATE',
  MIN_DATE = 'MIN_DATE'
}

const InputCalendar = memo(function InputCalendar({
  handleChangeDate,
  disabled = false,
  position,
  dateValue,
  handleDateError,
  // next year january 1st
  maxDate = convertDateToString(new Date()),
  // 2000 january 1st
  minDate = '01/01/2000',
  resetDate,
  size
}: Props) {
  const { i18n } = useTranslation();

  const [showCalendarDate, setShowCalendarDate] = useState(false);
  const [date, setDate] = useState<string>(dateValue);

  const [positionCalendar, setPositionCalendar] = useState<string | undefined>(position);

  const wrapperRef = useOutsideClick(() => setShowCalendarDate(false));

  const calendarRef = useRef<HTMLDivElement>(null);

  // Calendar double, if startDate is bigger than endDate, change dateValue, so we need to check it here
  useEffect(() => {
    setDate(dateValue);
  }, [dateValue]);

  useLayoutEffect(() => {
    if (!calendarRef.current) return;
    const dataCard = calendarRef.current.getBoundingClientRect();

    if (dataCard.right > window.innerWidth) setPositionCalendar('left');
    if (dataCard.left < 0) setPositionCalendar('right');
    if (dataCard.bottom > window.innerHeight) setPositionCalendar('top');
    if (dataCard.top < 0) setPositionCalendar('bottom');
  }, [showCalendarDate]);

  const handleSelectDate = (date: Date) => {
    const stringDate = formatDate(date);

    setDate(stringDate);
    handleChangeDate(stringDate);
    setShowCalendarDate(false);
  };

  const onChangeRawDate = (e: ChangeEvent<HTMLInputElement>) => {
    let newDate = e.target.value;

    if (!regexNumberAndSlash.test(newDate) || newDate.length === 11) return;

    if ((newDate.length === 2 || newDate.length === 5) && date.slice(-1) !== '/') newDate += '/';

    setDate(newDate);
    handleChangeDate(newDate);

    if (!handleDateError) return;

    if (!regexddmmyyyy.test(newDate) && newDate) {
      handleDateError(ErrorDate.FORMAT);
      return;
    }

    if (convertStringToDate(maxDate) < convertStringToDate(newDate) && newDate) {
      handleDateError(ErrorDate.MAX_DATE);
      return;
    }

    if (convertStringToDate(minDate) > convertStringToDate(newDate) && newDate) {
      handleDateError(ErrorDate.MIN_DATE);
      return;
    }
  };

  const handleShowCalendarDate = () => {
    if (disabled) return;
    setShowCalendarDate(true);
  };

  const handleHideCalendar = () => setShowCalendarDate(false);

  return (
    <div className='calendar-range input__calendar input__select__content' ref={wrapperRef}>
      <div className='calendar-input'>
        <InputText
          placeholder='dd/mm/yyyy'
          value={date}
          onChange={onChangeRawDate}
          onClick={handleShowCalendarDate}
          onKeyDown={handleHideCalendar}
          size={size}
          disabled={disabled}
        />
      </div>
      {showCalendarDate && (
        <div className={`calendar-positioner ${positionCalendar ?? ''}`}>
          <div className={`calendar-wrapper`} ref={calendarRef}>
            <Calendar
              dateDisplayFormat='P'
              date={
                regexddmmyyyy.test(date) ? convertStringToDate(date) : convertStringToDate(maxDate)
              }
              onChange={handleSelectDate}
              color={highlight_color}
              locale={i18n.language === 'es' ? es : enGB}
              maxDate={convertStringToDate(maxDate)}
              minDate={convertStringToDate(minDate)}
              // shownDate={maxDate ? convertStringToDate(maxDate) : new Date()}
            />
          </div>
        </div>
      )}
    </div>
  );
}, arePropsEqual);

export default InputCalendar;
