import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import { deleteSocialData } from '../../../../../services/api/socialData';
import Button from '../../../../ui/button/Button';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';

type Props = {
  id: string;
  deleteResponse: (id: string) => void;
  onClose: () => void;
};

const DeleteResponse = ({ id, deleteResponse, onClose }: Props) => {
  const { t } = useTranslation();
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteResponse = async () => {
    if (!user?.selectedOrganization || loadingButton) return;
    setLoadingButton(true);
    const response = await deleteSocialData(id);

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    deleteResponse(id);
    setLoadingButton(false);
  };

  return (
    <div className='delete-modal'>
      <FormHeader
        title={t('socialAndGovernance.delete')}
        description={t('socialAndGovernance.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel={'secondary'}
          text={tGeneral('cancel')}
          onClick={onClose}
          size='small'
        />
        <Button
          lookAndFeel='warning'
          text={tGeneral('delete')}
          onClick={handleDeleteResponse}
          loading={loadingButton}
          size='small'
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteResponse;
