type Props = {
  url: string;
  setUrl: (url: string) => void;
};
const useOnSort = ({ url, setUrl }: Props) => {
  const onSort = (selectedOption: SelectOptionFormat, direction?: 'asc' | 'desc') => {
    let urlUpdated = url;
    const urlSearchParams = new URLSearchParams(urlUpdated.split('?')[1]);
    // Delete sort_by query param
    urlSearchParams.delete('sort_by');
    urlUpdated = urlUpdated.split('?')[0];
    // If there were other params that were not sort_by, add them again
    if (urlSearchParams.toString()) {
      urlUpdated += `?${urlSearchParams.toString()}`;
    }
    const updatedUrl = `sort_by=${selectedOption.id}:${direction}`;

    const operator = urlUpdated.includes('?') ? '&' : '?';
    let requestUrl;
    if (selectedOption.id === 'default') {
      requestUrl = `${urlUpdated}`;
    } else {
      requestUrl = `${urlUpdated}${operator}${updatedUrl}`;
    }
    setUrl(requestUrl);
  };
  return onSort;
};

export default useOnSort;
