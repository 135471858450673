import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { convertStringToDate } from '../../../../utils/convertDates';
import { formatDate } from '../../../../utils/formatDate';
import Select from '../select/Select';

type Props = {
  onChangeStartDate: (value: string) => void;
  onChangeEndDate: (value: string) => void;
  size: 'big' | 'small' | undefined;
  height: string;
  fontClass: string;
  startDate: string;
  endDate: string;
};

const InputDatePredefined = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  size,
  height,
  fontClass
}: Props) => {
  const { t } = useTranslation();
  const [preDefinedDate, setPreDefinedDate] = useState<SelectOptionFormat>({ id: '', name: '' });

  useEffect(() => {
    // Check startDate and endDate to set preDefinedDate to the corresponding value
    const startDateParsed = convertStringToDate(startDate);
    const endDateParsed = convertStringToDate(endDate);

    // Check if startDateParsed and endDateParsed correspond to last year 01/01 - 12/31
    const lastYearStartDate = new Date(moment(new Date()).format('YYYY-MM-DD'));
    const lastYearEndDate = new Date(moment(new Date()).format('YYYY-MM-DD'));
    lastYearStartDate.setFullYear(lastYearStartDate.getFullYear() - 1);
    lastYearEndDate.setFullYear(lastYearEndDate.getFullYear() - 1);
    lastYearStartDate.setMonth(0, 1);
    lastYearEndDate.setMonth(11, 31);

    const last9MonthsFromToday =
      new Date().getMonth() - 9 > 0 ? new Date().getMonth() - 9 : 12 + (new Date().getMonth() - 9);

    const last6MonthsFromToday =
      new Date().getMonth() - 6 > 0 ? new Date().getMonth() - 6 : 12 + (new Date().getMonth() - 6);

    const last3MonthsFromToday =
      new Date().getMonth() - 3 > 0 ? new Date().getMonth() - 3 : 12 + (new Date().getMonth() - 3);

    const lastMonthFromToday =
      new Date().getMonth() - 1 > 0 ? new Date().getMonth() - 1 : 12 + (new Date().getMonth() - 1);
    if (
      startDateParsed.getTime() === lastYearStartDate.getTime() &&
      endDateParsed.getTime() === lastYearEndDate.getTime()
    ) {
      setPreDefinedDate({ id: 'last_year', name: t('calendar.lastYear') });
    }
    // Check if startDateParsed and endDateParsed correspond to last 9 months
    else if (
      startDateParsed.getMonth() === last9MonthsFromToday &&
      startDateParsed.getDate() === endDateParsed.getDate() &&
      endDateParsed.getMonth() === new Date().getMonth()
    ) {
      setPreDefinedDate({ id: 'last_9_months', name: t('calendar.last9Months') });
    }
    // Check if startDateParsed and endDateParsed correspond to last 6 months
    else if (
      startDateParsed.getMonth() === last6MonthsFromToday &&
      startDateParsed.getDate() === endDateParsed.getDate() &&
      endDateParsed.getMonth() === new Date().getMonth()
    ) {
      setPreDefinedDate({ id: 'last_6_months', name: t('calendar.last6Months') });
    }
    // Check if startDateParsed and endDateParsed correspond to last 3 months
    else if (
      startDateParsed.getMonth() === last3MonthsFromToday &&
      startDateParsed.getDate() === endDateParsed.getDate() &&
      endDateParsed.getMonth() === new Date().getMonth()
    ) {
      setPreDefinedDate({ id: 'last_3_months', name: t('calendar.last3Months') });
    }
    // Check if startDateParsed and endDateParsed correspond to last month
    else if (
      startDateParsed.getMonth() === lastMonthFromToday &&
      startDateParsed.getDate() === endDateParsed.getDate() &&
      endDateParsed.getMonth() === new Date().getMonth()
    ) {
      setPreDefinedDate({ id: 'last_month', name: t('calendar.lastMonth') });
    }
    // Check if startDateParsed and endDateParsed correspond to this year
    else if (
      startDateParsed.getMonth() === 0 &&
      startDateParsed.getDate() === 1 &&
      endDateParsed.getMonth() === new Date().getMonth() &&
      endDateParsed.getDate() === new Date().getDate() &&
      startDateParsed.getFullYear() === endDateParsed.getFullYear() &&
      startDateParsed.getFullYear() === new Date().getFullYear()
    ) {
      setPreDefinedDate({ id: 'this_year', name: t('calendar.thisYear') });
    } else if (preDefinedDate?.id === 'custom') {
      setPreDefinedDate({ id: 'custom', name: t('calendar.custom') });
    } else {
      setPreDefinedDate({ id: 'default', name: t('dashboard.all') });
    }
  }, [startDate, endDate]);

  const onChangePreDefinedDate = (value: SelectOptionFormat) => {
    setPreDefinedDate(value);
    const startDateParsed = new Date(moment(new Date()).format('YYYY-MM-DD'));
    const endDateParsed = new Date(moment(new Date()).format('YYYY-MM-DD'));
    if (value.id === 'last_month') {
      startDateParsed.setMonth(startDateParsed.getMonth() - 1);
    } else if (value.id === 'last_3_months') {
      startDateParsed.setMonth(startDateParsed.getMonth() - 3);
    } else if (value.id === 'last_6_months') {
      startDateParsed.setMonth(startDateParsed.getMonth() - 6);
    } else if (value.id === 'last_9_months') {
      startDateParsed.setMonth(startDateParsed.getMonth() - 9);
    } else if (value.id === 'last_year') {
      startDateParsed.setFullYear(startDateParsed.getFullYear() - 1);
      endDateParsed.setFullYear(endDateParsed.getFullYear() - 1);
      startDateParsed.setMonth(0, 1);
      endDateParsed.setMonth(11, 31);
    } else if (value.id === 'this_year') {
      startDateParsed.setMonth(0, 1);
    }
    onChangeStartDate(formatDate(startDateParsed));
    onChangeEndDate(formatDate(endDateParsed));
  };

  return (
    <Select
      placeholder={t('calendar.predefinedDate')}
      options={[
        { id: 'this_year', name: t('calendar.thisYear') },
        { id: 'last_year', name: t('calendar.lastYear') },
        { id: 'last_9_months', name: t('calendar.last9Months') },
        { id: 'last_6_months', name: t('calendar.last6Months') },
        { id: 'last_3_months', name: t('calendar.last3Months') },
        { id: 'last_month', name: t('calendar.lastMonth') }
      ]}
      value={preDefinedDate}
      onChangeValue={onChangePreDefinedDate}
      size={size}
      height={height}
      fontClass={fontClass}
    />
  );
};

export default InputDatePredefined;
