import { ReactNode, useState } from 'react';
import styles from './certificationPage.module.scss';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import { Variant } from './types';

type LayoutProps = {
  children: ReactNode;
  cols?: number;
};

const PageLayout: React.FC<LayoutProps> = ({ children, cols = 2 }) => {
  return (
    <div
      className={`grid-cols-${cols} gap-x-20 p-8 main-bg-color border-solid border-gray-2-light border-1 rounded-8 place-center`}>
      {children}
    </div>
  );
};

type TitleProps = {
  children: ReactNode;
};
const PageTitle: React.FC<TitleProps> = ({ children }) => {
  return <h2 className='font-headings-h2-sb m-0'>{children}</h2>;
};

type DescriptionProps = {
  children: ReactNode;
};

const PageDescription: React.FC<DescriptionProps> = ({ children }) => {
  return <p className='font-body-b1-r m-0'>{children}</p>;
};

type ChecksProps = {
  checks: string[];
};

const PageChecks: React.FC<ChecksProps> = ({ checks }) => {
  return (
    <ul className={styles['checks']}>
      {checks.map((check) => {
        return (
          <li key={check}>
            <Icon icon='success' color='gradient' />
            <span>{check}</span>
          </li>
        );
      })}
    </ul>
  );
};

type ButtonSectionProps = {
  children: ReactNode;
};
const PageButtonSection: React.FC<ButtonSectionProps> = ({ children }) => {
  return <div className='flex items-end gap-x-2 grow'>{children}</div>;
};

type SectionTitleProps = {
  children: ReactNode;
};

const PageSectionTitle: React.FC<SectionTitleProps> = ({ children }) => {
  return <h4 className='font-body-b1-sb m-0'>{children}</h4>;
};

type SectionLayoutProps = {
  children: ReactNode;
};

const PageSectionLayout: React.FC<SectionLayoutProps> = ({ children }) => {
  return <div className='flex-col gap-y-2'>{children}</div>;
};

type CertificationButtonProps = {
  VARIANTS: {
    DEFAULT: Variant;
    REQUESTED: Variant;
    CANCEL: Variant;
  };
  isRequested: boolean;
  loading: boolean;
};

const CertificationButton: React.FC<CertificationButtonProps> = ({
  VARIANTS,
  isRequested,
  loading
}) => {
  const [hovered, setHovered] = useState(false);

  let variant = VARIANTS.DEFAULT;

  if (isRequested && !loading) {
    variant = VARIANTS.REQUESTED;
  }

  if (isRequested && hovered && !loading) {
    variant = VARIANTS.CANCEL;
  }

  const handleHoverIn = () => {
    if (isRequested) {
      setHovered(true);
    }
  };

  const handleHoverOut = () => {
    if (isRequested || hovered) {
      setHovered(false);
    }
  };

  return (
    <Button
      {...variant}
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
      loading={loading}
      size='medium'
      style={{ width: '275px' }}
    />
  );
};

export const CertificationPage = {
  Layout: PageLayout,
  Title: PageTitle,
  Description: PageDescription,
  Checks: PageChecks,
  ButtonSection: PageButtonSection,
  Button: CertificationButton,
  SectionTitle: PageSectionTitle,
  SectionLayout: PageSectionLayout
};
