import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { UserContext } from '../../../context/userContext';
import { getOrganizationById } from '../../../services/api/admin';
import { Organization } from '../../../types/entities/organization';
import { User } from '../../../types/entities/user';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import AddMember from '../../members/addMember/AddMember';
import Button from '../../ui/button/Button';
import InputText from '../../ui/formComponents/inputText/InputText';
import Modal from '../../ui/modal/Modal';
import Table from '../../ui/table/Table';
import './styles.scss';

const OrganizationDetail = () => {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const params = useParams<{ id: string }>();
  const [organization, setOrganization] = useState<Organization>();
  const [loading, setLoading] = useState(true);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [members, setMembers] = useState<User[]>([]);

  // Filters
  const [searchValue, setSearchValue] = useState('');

  const fetchData = async () => {
    if (!params.id) {
      return;
    }
    const data = await getOrganizationById(params.id);

    setLoading(false);
    if (data) {
      setOrganization(data);
      setMembers(data.users);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const parseValues = () => {
    if (!organization || !members) {
      return [];
    }
    return members
      ?.filter((user) => user.email.includes(searchValue))
      .map((user) => {
        return {
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.prefix + user.phone_number
        };
      });
  };

  const handleInviteUser = () => {
    setShowInviteUser(true);
  };

  const onCloseModal = () => {
    setShowInviteUser(false);
  };

  const inviteUser = (member: User) => {
    const oldMembers = [...members];

    oldMembers.push({
      ...member,
      email: member.email
    });

    setMembers(oldMembers);

    setNotification(t('notification.invitationSent'));
    onCloseModal();
  };

  const addMember = (member: User) => {
    const oldMembers = [...members];

    oldMembers.push({
      ...member,
      email: member.email
    });

    setMembers(oldMembers);
    onCloseModal();
    setNotification(t('notification.addMember'));
  };

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('users.id')
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t('users.email')
    },
    {
      key: 'first_name',
      dataIndex: 'first_name',
      title: t('users.firstName')
    },
    {
      key: 'last_name',
      dataIndex: 'last_name',
      title: t('users.lastName')
    },
    {
      key: 'phone_number',
      dataIndex: 'phone_number',
      title: t('users.phoneNumber')
    }
  ];

  if (!organization) {
    return null;
  }
  return (
    <section className='organization-detail'>
      <SectionHeader
        title={organization?.company_name || ''}
        breadcrumb={
          <Breadcrumb
            crumbsReplace={
              params.id ? [{ key: params.id, value: organization?.company_name || '' }] : []
            }
          />
        }
      />
      <div className='organization-detail__buttons'>
        <Button
          lookAndFeel={'primary'}
          text={t('members.invite')}
          onClick={handleInviteUser}
          size='small'
        />
      </div>
      <div className='organization-detail__table-wrapper solid-border main-bg-color'>
        <div className='table-header'>
          <div className='table-filters'>
            <InputText
              icon={'/images/icons/search.svg'}
              placeholder={t('facilities.searchPlaceholder')}
              onChangeValue={onChangeSearchValue}
              height='24px'
              fontClass='input-smaller-font'
              size='small'
              value={searchValue}
            />
          </div>
        </div>
        <Table columns={columns} data={parseValues()} loading={loading} />
      </div>
      <Modal show={showInviteUser} onClose={onCloseModal} width='428px' maxWidth='428px'>
        <AddMember
          user={user}
          addMember={addMember}
          inviteUser={inviteUser}
          members={members}
          organizationId={organization.id}
        />
      </Modal>
    </section>
  );
};

export default OrganizationDetail;
