export const getPhaseTitle = (
  hasImpactCategory: boolean,
  hasLcaFolder: boolean,
  lcaComponentsUsedLength: number
) => {
  if (!hasImpactCategory) {
    return 'lcaDetail.impactCategoryPhaseTitle';
  }

  if (!hasLcaFolder) {
    return 'lcaDetail.firstPhaseTitle';
  }

  if (lcaComponentsUsedLength === 0) {
    return 'lcaDetail.secondPhaseTitle';
  }

  return 'lcaDetail.defaultPhaseTitle';
};
