import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../constants/routes';
import { useFeatureFlags } from '../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../customHooks/useSelectedOrganization';

const useCards = () => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();
  const selectedOrganization = useSelectedOrganization();

  const cards: CardTooltip[] = [
    {
      id: 'emission-factors',
      data: [
        {
          icon: '/images/icons/thermometer.svg',
          title: t('customizeMainPage.emissionFactors.title'),
          description: t('customizeMainPage.emissionFactors.description'),
          route: ROUTES.CUSTOM_EMISSION_FACTORS,
          total: ''
        }
      ],
      tooltip: t('customizeMainPage.emissionFactors.tooltip'),
      disabled: false,
      freeTrial: false,
      group: []
    }
  ];
  if (flags?.intensityMetrics) {
    cards.push({
      id: 'intensity-metrics',
      data: [
        {
          icon: '/images/icons/intensityMetric.svg',
          title: t('customizeMainPage.intensityMetrics.title'),
          description: t('customizeMainPage.intensityMetrics.description'),
          route: ROUTES.CUSTOM_INTENSITY_METRICS,
          total: ''
        }
      ],
      tooltip: t('customizeMainPage.intensityMetrics.tooltip'),
      disabled: false,
      freeTrial: false,
      group: []
    });
  }

  if (flags?.grouping) {
    cards.push({
      id: 'grouping',
      data: [
        {
          icon: '/images/icons/folder.svg',
          title: t('customizeMainPage.grouping.title'),
          description: t('customizeMainPage.grouping.description'),
          route: ROUTES.CUSTOM_GROUPING,
          total: ''
        }
      ],
      tooltip: t('customizeMainPage.grouping.tooltip'),
      disabled: false,
      freeTrial: false,
      group: []
    });
  }

  if (flags?.showMetrics) {
    cards.push({
      id: 'custom-metrics',
      data: [
        {
          icon: '/images/icons/catalog.svg',
          title: t('customizeMainPage.customMetrics.title'),
          description: t('customizeMainPage.customMetrics.description'),
          route: ROUTES.CUSTOM_METRICS,
          total: ''
        }
      ],
      tooltip: t('customizeMainPage.customMetrics.tooltip'),
      disabled: false,
      freeTrial: false,
      group: [],
      new: true
    });
  }

  return cards;
};

export default useCards;
