import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { adaptLogisticReportBackToFront } from '../../../../../adapters/logistics';
import { ROUTES } from '../../../../../constants/routes';
import { UserContext } from '../../../../../context/userContext';
import useErrors from '../../../../../customHooks/useErrors';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { getLogisticsReport } from '../../../../../services/api/logistics';
import { LogisticReportFront } from '../../../../../types/entities/logistics';
import { InputSize } from '../../../../../types/utilsEnums/input';
import { convertStringToDate } from '../../../../../utils/convertDates';
import Button from '../../../../ui/button/Button';
import ErrorText from '../../../../ui/errorText/ErrorText';
import InputCalendarDouble from '../../../../ui/formComponents2/inputCalendarDouble/InputCalendarDouble';
import InputWrapperPlain from '../../../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import Select from '../../../../ui/formComponents2/select/Select';
import Icon from '../../../../ui/icon/Icon';
import LoaderTables from '../../../../ui/loaders/loaderTables/LoaderTables';
import ExcelTableRefactor from '../../../../ui/table/excelTable.tsx/ExcelTableRefactor';
import useGetLogisticExcelData from './hooks/useGetLogisticExcelData';
import './styles.scss';

type Filters = {
  client: SelectOptionFormat;
  startDate: string;
  endDate: string;
  lang: SelectOptionFormat;
  errors: ErrorType[];
};

type Props = {
  clients: SelectOptionFormat[];
  loadingClients: boolean;
  errorClient?: ErrorType;
  handleGetDown: () => Promise<void>;
  handleSearchClient: (searchValue: string) => Promise<void>;
};

const checkDateIsSameYear = (date1: string, date2: string) => {
  const date1Parsed = convertStringToDate(date1);
  const date2Parsed = convertStringToDate(date2);

  return date1Parsed.getFullYear() === date2Parsed.getFullYear();
};

const Client = ({
  clients,
  errorClient,
  handleGetDown,
  loadingClients,
  handleSearchClient
}: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const selectedOrganization = useSelectedOrganization();

  const [filters, setFilters] = useState<Filters>({
    client: {
      id: '',
      name: ''
    },
    startDate: '',
    endDate: '',
    lang: {
      id: i18n.resolvedLanguage,
      name: t(`languages.${i18n.resolvedLanguage}`)
    },
    errors: []
  });

  const {
    parseSelfOrganizationData,
    parseTocData,
    parseGeneralData,
    parseHocsData,
    parseTransportModes,
    parseHubsCategory,
    empty
  } = useGetLogisticExcelData(filters.startDate, filters.endDate, filters.lang.id);

  const [logisticReport, setLogisticReport] = useState<LogisticReportFront>(empty);
  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData: setFilters });

  const ERRORS = useErrors();
  const [loading, setLoading] = useState(false);

  const handleCheckDates = (attribute: 'startDate' | 'endDate') => {
    const opositeAttribute = attribute === 'startDate' ? 'endDate' : 'startDate';

    return (value: string) => {
      setFilters((prev) => {
        const newErrors = [];

        if (prev[opositeAttribute] && !checkDateIsSameYear(value, prev[opositeAttribute])) {
          newErrors.push(ERRORS.FORM.MUST_BE_WITHIN_SAME_YEAR);
        }

        return {
          ...prev,
          [attribute]: value,
          errors: newErrors
        };
      });
    };
  };

  const fetchLogisticsReport = async () => {
    if (
      !user?.selectedOrganization ||
      !filters.client.id ||
      !filters.startDate ||
      !filters.endDate ||
      filters.errors.length != 0
    )
      return;

    setLoading(true);
    const data = await getLogisticsReport(
      filters.client.id,
      convertStringToDate(filters.startDate),
      convertStringToDate(filters.endDate),
      user.selectedOrganization
    );
    setLoading(false);

    if (!data) return;
    if (data.error) {
      setFilters((prev) => ({
        ...prev,
        errors: filters.errors.concat({
          error: 'date',
          description: t('logistics.invalidDateRange')
        })
      }));
      return;
    }

    const dataParsed = adaptLogisticReportBackToFront(data);
    setLogisticReport(dataParsed);
  };

  useEffect(() => {
    fetchLogisticsReport();
  }, [filters.client.id, filters.startDate, filters.endDate]);

  const handleGoToGLECReport = () => {
    const queryParameters = new URLSearchParams();
    queryParameters.append('start_date', filters.startDate);
    queryParameters.append('end_date', filters.endDate);
    queryParameters.append('client_id', filters.client.id);
    queryParameters.append('lang', filters.lang.id);
    if (selectedOrganization?.logo_url)
      queryParameters.append('company_logo', selectedOrganization.logo_url);

    window.open(`${ROUTES.COMMUNICATE_GLEC}?${queryParameters.toString()}`, '_blank');
  };

  const handleGoToFiles = () => navigate(`${ROUTES.MEASURE_UPLOADED_FILES}/logistics-recharges`);

  const disabled =
    filters.errors.length != 0 ||
    filters.client.id === '' ||
    filters.startDate === '' ||
    filters.endDate === '' ||
    filters.lang.id === '' ||
    !!errorClient;

  const selfOrganizationData = parseSelfOrganizationData(logisticReport);
  const tocData = parseTocData(logisticReport);
  const hocsData = parseHocsData(logisticReport);
  const transportModesData = parseTransportModes(logisticReport);
  const hubsCategoryData = parseHubsCategory(logisticReport);
  const generalData = parseGeneralData();

  const dateErrors = filters.errors.find(
    (error) => error.error === 'startDate' || error.error === 'endDate' || error.error === 'date'
  );

  return (
    <div className='logistics-content'>
      <div className='glec-carbon-footprint card'>
        <div style={{ display: 'flex', gap: '0.5rem', marginBottom: '1.5rem' }}>
          <InputWrapperPlain
            size={InputSize.MEDIUM}
            icon='/images/icons/person.svg'
            width='13rem'
            error={filters.errors.find((error) => error.error === 'client')}>
            <Select
              placeholder={t('logistics.clientPlaceholder')}
              options={clients}
              value={filters.client}
              onChange={onChangeValue('client')}
              size={InputSize.MEDIUM}
              sort={false}
              handleGetDown={handleGetDown}
              loading={loadingClients}
              searchBackendCallback={handleSearchClient}
            />
          </InputWrapperPlain>
          <div className='input'>
            <InputWrapperPlain
              size={InputSize.MEDIUM}
              icon='/images/icons/calendar50.svg'
              width='13rem'
              error={dateErrors}>
              <InputCalendarDouble
                startDate={filters.startDate}
                endDate={filters.endDate}
                handleChangeStartDate={handleCheckDates('startDate')}
                handleChangeEndDate={handleCheckDates('endDate')}
                handleStartDateError={handleDateError('startDate')}
                handleEndDateError={handleDateError('endDate')}
              />
            </InputWrapperPlain>
            <ErrorText style={{ marginTop: '0.5rem', maxWidth: '32ch' }}>
              {dateErrors && (dateErrors?.description ?? t('error.requiredField'))}
            </ErrorText>
          </div>
          <InputWrapperPlain size={InputSize.MEDIUM} icon='/images/icons/globe.svg' width='13rem'>
            <Select
              placeholder={t('')}
              options={[
                { id: 'en', name: t('languages.en') },
                { id: 'es', name: t('languages.es') }
              ]}
              value={filters.lang}
              onChange={onChangeValue('lang')}
              size={InputSize.MEDIUM}
            />
          </InputWrapperPlain>
          <div className='ml-auto'>
            <Button
              iconNode={<Icon icon='download' color='gray' />}
              size='small'
              lookAndFeel={'primary'}
              style={{ width: 'max-content' }}
              text={t('logistics.download')}
              onClick={handleGoToGLECReport}
              disabled={disabled}
            />
          </div>

          <div style={{ marginRight: '0.5rem' }}>
            <Button
              lookAndFeel='secondary'
              iconNode={<Icon icon='files_documents' color='gray-dark' />}
              size='small'
              onClick={handleGoToFiles}
              text={t('logistics.uploadedFiles')}
            />
          </div>
        </div>

        {loading ? (
          <LoaderTables />
        ) : (
          <>
            {selfOrganizationData && (
              <ExcelTableRefactor
                header={selfOrganizationData?.header}
                rows={selfOrganizationData?.rows}
                disabled={disabled}
              />
            )}
            <ExcelTableRefactor header={tocData?.header} rows={tocData?.rows} disabled={disabled} />
            <ExcelTableRefactor
              header={hocsData?.header}
              rows={hocsData?.rows}
              disabled={disabled}
            />
            {transportModesData.rows.length > 0 && (
              <ExcelTableRefactor
                header={transportModesData?.header}
                rows={transportModesData?.rows}
                disabled={disabled}
              />
            )}
            {hubsCategoryData?.rows.length > 0 && (
              <ExcelTableRefactor
                header={hubsCategoryData?.header}
                rows={hubsCategoryData?.rows}
                disabled={disabled}
              />
            )}
            <ExcelTableRefactor rows={generalData?.rows} disabled={disabled} />
          </>
        )}
      </div>
    </div>
  );
};

export default Client;
