import { useEffect, useState } from 'react';
import useErrors from '../../../../../../../customHooks/useErrors';
import { getCustomEFDetail } from '../../../../../../../services/api/customEFDetails';

type Props = {
  id: string;
};

const useElementToEdit = ({ id }: Props) => {
  const ERRORS = useErrors();

  const [element, setElement] = useState<ICustomEFBack>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType>();

  const getElementToEdit = async () => {
    if (!id) {
      setElement(undefined);
      return;
    }
    if (loading) return;

    setLoading(true);
    const response = await getCustomEFDetail({ id });
    setLoading(false);

    const status = response?.response?.status || -1;

    if (status >= 400 || !response) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    setElement(response);
  };

  useEffect(() => {
    getElementToEdit();
  }, [id]);

  return { element, loading, error };
};

export default useElementToEdit;
