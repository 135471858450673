import { ILCASoldProductPostBack } from '../../types/entities/useAndEndOfLife';
import apiFetch from '../../utils/apiFetch';

interface IGetProps {
  page: number;
  size: number;
}

export const getUseAndEndOfLifeList = async ({ page, size }: IGetProps) => {
  try {
    const response = await apiFetch('GET', '/lca_production_details', null, null, { page, size });
    return response.data;
  } catch (error) {
    return error;
  }
};

interface IPostProps {
  element: ILCASoldProductPostBack;
}

export const createUseAndEndOfLife = async ({ element }: IPostProps) => {
  try {
    const response = await apiFetch('POST', '/lca_production_details', element);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateUseAndEndOfLife = async ({ element }: IPostProps) => {
  try {
    const response = await apiFetch(
      'PATCH',
      `/lca/sold_products/production_details/${element.id}`,
      element
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

interface IDeleteProps {
  id: string;
}

export const deleteUseAndEndOfLife = async ({ id }: IDeleteProps) => {
  try {
    const response = await apiFetch('DELETE', `/lca_production_details/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
