import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { months } from '../../../../constants/months';
import { scope1_color, scope3_color } from '../../../../styles/colors';
import { FacilityReport } from '../../../../types/entities/facility';
import { Organization } from '../../../../types/entities/organization';
import {
  formatNumberToDecimalNonZero,
  numberToScientificNotation
} from '../../../../utils/numberToDecimal';
import LineChartDoubleAxes from '../../../ui/charts/LineChartDoubleAxes';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';
import LineChartWrapper from '../common/lineChartWrapper/LineChartWrapper';
import List from '../common/list/List';
import useDataCo2 from '../hooks/useDataCo2';
import './styles.scss';

type Props = {
  dataElectricity: TotalImpactElectricity[];
  startDate: string;
  endDate: string;
  organizationCo2e: number;
  organization: Organization;
  renewableEnergy: number;
  facilities: FacilityReport[];
};
function ElectricityEmissions({
  dataElectricity,
  startDate,
  endDate,
  organizationCo2e,
  organization,
  renewableEnergy,
  facilities
}: Props) {
  const { t, i18n } = useTranslation();

  const { dataCo2, monthLabels } = useDataCo2({ startDate, endDate });
  const dataKwH = dataCo2.map((elem) => ({ x: elem.x, y: 0 }));

  // Group dataElectricity by date
  const groupedByMonth = [] as GroupByMonthWithQuantity[];

  let totalCo2e = 0;
  let totalKwH = 0;

  dataElectricity.forEach((elem: TotalImpactElectricity) => {
    const foundIndex = groupedByMonth.findIndex(
      (elem2) => elem2.date === moment(new Date(elem.date)).format('YYYY-MM')
    );
    totalCo2e += elem.emissions_per_month;
    totalKwH += elem.quantity;
    if (foundIndex > -1) {
      groupedByMonth[foundIndex].emissions_per_month += elem.emissions_per_month;
      groupedByMonth[foundIndex].quantity += elem.quantity;
    } else {
      groupedByMonth.push({
        date: moment(new Date(elem.date)).format('YYYY-MM'),
        emissions_per_month: elem.emissions_per_month,
        quantity: elem.quantity
      });
    }
  });

  dataElectricity
    .sort((a: TotalImpactElectricity, b: TotalImpactElectricity) => (a.date < b.date ? -1 : 1))
    .forEach((elem: TotalImpactElectricity) => {
      const foundIndex = dataCo2.findIndex(
        (elem2) => elem2.x === moment(new Date(elem.date)).format('YYYY-MM')
      );

      if (foundIndex > -1) {
        dataCo2[foundIndex].y += elem.emissions_per_month;
        dataKwH[foundIndex].y += elem.quantity;
      }
    });

  const rows: string[][] = [];
  dataCo2.forEach((elem, index) => {
    const dateString = elem.x;
    const date = new Date(dateString);
    const month = months.find((elem) => elem.number === date.getMonth() + 1);
    const lang = i18n.resolvedLanguage;
    rows.push([
      month?.name[lang],
      numberToScientificNotation(dataKwH[index].y),
      numberToScientificNotation(elem.y / 1000)
    ]);
  });

  const sortedGroupedByMonthKwh = dataKwH
    .filter((elem) => elem.y !== 0)
    .sort((a, b) => (a.y < b.y ? 1 : -1));

  const monthsWithHighestKwh: {
    name: string;
    monthNumber: number;
  }[] = [];

  sortedGroupedByMonthKwh.forEach((elem, index) => {
    if (index > 2) return;
    const month = months.find((month) => month.number === new Date(elem.x).getMonth() + 1);
    // push month in the order that should be normal for a user to monthsWithHighestKwh
    if (month) {
      monthsWithHighestKwh.push({
        name: month.name[i18n.resolvedLanguage],
        monthNumber: month.number
      });
    }
  });

  const monthsWithLessKwh: {
    name: string;
    monthNumber: number;
  }[] = [];

  sortedGroupedByMonthKwh.reverse().forEach((elem, index) => {
    if (index > 2) return;
    const month = months.find((month) => month.number === new Date(elem.x).getMonth() + 1);
    // monthsWithLessKwh.push(month ? month.name[i18n.resolvedLanguage] : '');
    if (month) {
      monthsWithLessKwh.push({
        name: month.name[i18n.resolvedLanguage],
        monthNumber: month.number
      });
    }
  });

  const facilitiesWithHighestKwh: FacilityReport[] = [];
  const facilitiesElectricityGrouped: FacilityReport[] = [];
  facilities
    .filter(
      (facility) =>
        facility.category === 'electricity' && facility.ghg_gas === 'co2e' && facility.quantity > 0
    )
    .sort((a, b) => (a.quantity < b.quantity ? 1 : -1))
    .forEach((elem) => {
      // group if facility is repeated
      const foundIndex = facilitiesElectricityGrouped.findIndex((elem2) => elem2.id === elem.id);
      if (foundIndex > -1) {
        facilitiesElectricityGrouped[foundIndex].quantity += elem.quantity;
        facilitiesElectricityGrouped[foundIndex].total_emissions += elem.total_emissions;
      } else {
        facilitiesElectricityGrouped.push(elem);
      }
    });

  facilitiesElectricityGrouped.forEach((elem, index) => {
    if (index > 1) return;
    facilitiesWithHighestKwh.push(elem);
  });

  return (
    <div className='electricity-emissions'>
      <LineChartWrapper
        legends={[
          {
            name: 'kWh',
            icon: '/images/icons/dotScope1.svg'
          },
          {
            name: 't CO₂ eq.',
            icon: '/images/icons/dotScope3.svg'
          }
        ]}>
        <>
          <div className='legends-wrapper-chart'>
            <div className='legend-chart body2-font'>kWh</div>
            <div className='legend-chart body2-font'>t CO₂ eq.</div>
          </div>
          <LineChartDoubleAxes
            labels={monthLabels}
            chartData={[
              {
                label: 'kWh',
                borderColor: scope1_color,
                data: dataKwH.map((elem) => elem.y),
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: 'rgba(41, 69, 255, 0.1)',
                fill: true,
                yAxisID: 'y'
              },
              {
                label: 't CO₂ eq.',
                borderColor: scope3_color,
                data: dataCo2.map((elem) => elem.y / 1000),
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: 'rgba(206, 210, 250, 0.1)',
                fill: true,
                yAxisID: 'y1'
              }
            ]}
          />
        </>
      </LineChartWrapper>
      <div className='list-description-wrapper'>
        <List
          icon={'/images/icons/calendar50.svg'}
          columns={[t('ghgReport.month'), 'kWh', 'CO₂eq. (tons)']}
          rows={rows}
          totalRow={[
            numberToScientificNotation(totalKwH),
            numberToScientificNotation(totalCo2e / 1000)
          ]}
        />
        <DescriptionBox
          descriptions={[
            t('ghgReport.electricityEmissionsDescription.total', {
              total: numberToScientificNotation(totalCo2e / 1000),
              percentage: numberToScientificNotation((totalCo2e / organizationCo2e) * 100),
              tradeName: organization.trade_name ?? ''
            }),
            t('ghgReport.electricityEmissionsDescription.intensity', {
              intensity: numberToScientificNotation(totalCo2e / totalKwH)
            }),
            t('ghgReport.electricityEmissionsDescription.renewable', {
              percentage: numberToScientificNotation(renewableEnergy)
            }),
            t('ghgReport.electricityEmissionsDescription.monthsMoreElectricity', {
              months: monthsWithHighestKwh
                .sort((a, b) => (a.monthNumber < b.monthNumber ? -1 : 1))
                .map((elem) => elem.name)
                .join(', ')
            }),
            t('ghgReport.electricityEmissionsDescription.monthsLessElectricity', {
              months: monthsWithLessKwh
                .sort((a, b) => (a.monthNumber < b.monthNumber ? -1 : 1))
                .map((elem) => elem.name)
                .join(', ')
            })
          ]}
        />
      </div>
    </div>
  );
}

export default ElectricityEmissions;
