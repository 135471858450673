import { ColumnAPI } from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import useFuels from '../../hooks/useFuels';
import { countriesNuvo } from 'constants/countriesNuvo';
import { useNuvoVehicleTypes } from '../../hooks/useNuvoVehicleTypes';

export const useColumns = () => {
  const { t } = useTranslation();
  const { data: vehiclesType, isLoading: loadingVehicleTypes } = useNuvoVehicleTypes();
  const { data: nuvoFuels, isLoading: loadingFuels } = useFuels({ country: 'ES' });

  const filteredFuels = nuvoFuels.filter(
    (fuel) =>
      fuel.value !== 'electric' && fuel.value !== 'hybrid' && fuel.value !== 'electric_renewable'
  );

  const fuels = filteredFuels.map((fuel) => ({
    label: fuel.name,
    value: fuel.id,
    type: 'string',
    validations: [
      {
        validate: {
          EQ: { unknown_vehicle_id: fuel.vehicle_types }
        }
      }
    ]
  }));

  const columns: ColumnAPI[] = [
    {
      key: 'name',
      label: t('templates.vehicles.vehicle_name'),
      columnType: 'string'
    },
    {
      key: 'license_plate',
      label: t('templates.vehicles.license_plate'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        },
        {
          validate: 'unique'
        }
      ]
    },
    {
      key: 'country',
      label: t('templates.vehicles.country'),
      columnType: 'category',
      dropdownOptions: countriesNuvo.map((country) => ({
        ...country,
        value: country.label
      })),
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'type',
      label: t('templates.vehicles.vehicle_use'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.vehicles.passengers'),
          value: 'passenger',
          type: 'string'
        },
        {
          label: t('templates.vehicles.merchandise'),
          value: 'freight',
          type: 'string'
        }
      ],
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'unknown_vehicle_id',
      label: t('templates.vehicles.vehicle_type'),
      columnType: 'category',
      dropdownOptions: vehiclesType,
      validations: [
        {
          validate: 'required'
        }
      ]
    },
    {
      key: 'vehicle_fuel_id',
      label: t('templates.vehicles.fuel_type'),
      columnType: 'category',
      dropdownOptions: fuels,
      validations: [
        {
          validate: 'required'
        }
      ]
    },

    {
      key: 'ownership',
      label: t('templates.vehicles.ownership'),
      columnType: 'category',
      dropdownOptions: [
        {
          label: t('templates.vehicles.owner'),
          value: 'rented',
          type: 'string'
        },
        {
          label: t('templates.vehicles.rented'),
          value: 'owned',
          type: 'string'
        }
      ],
      validations: [
        {
          validate: 'required'
        }
      ]
    }
  ];

  return { columns, isLoading: loadingVehicleTypes || loadingFuels };
};
