import { useTranslation } from 'react-i18next';
import { FacilityDashboard } from '../../../../types/entities/facility';
import { OrganizationShort } from '../../../../types/entities/organization';
import type { Column } from '../../../../types/table';

import { formatRoundNumber } from '../../../../utils/formatNumber';
import { limitString } from '../../../../utils/limitString';
import Dot from '../../../ui/dot/Dot';
import Icon from '../../../ui/icon/Icon';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';
import List from '../list/List';

type Props = {
  facilities: FacilityDashboard[];
  organizations: OrganizationShort[];
  totalCo2: number;
  categoriesToShow: CategoryWithScope[];
};
function Facilities({ facilities, organizations, totalCo2, categoriesToShow }: Props) {
  const { t } = useTranslation();

  const showStationary = categoriesToShow.find((category) => category.category === 'stationary');
  const showRecharge = categoriesToShow.find((category) => category.category === 'recharge');
  const showElectricity = categoriesToShow.find((category) => category.category === 'electricity');
  const showWater = categoriesToShow.find((category) => category.category === 'water');
  const showWastes = categoriesToShow.find((category) => category.category === 'wastes');
  const showWasteWaterTreatment = categoriesToShow.find(
    (category) => category.category === 'waste_water_treatments'
  );

  const columns: Column[] = [
    {
      title: t('report.facilities'),
      dataIndex: 'name',
      key: 'name',
      width: '20%'
    }
  ];

  if (showStationary) {
    columns.push({
      title: (
        <span className='text-center'>
          <Dot type={`scope1`} />
          {t('categories.stationary')}
        </span>
      ),
      dataIndex: 'stationary',
      key: 'stationary',
      align: 'right'
    });
  }
  if (showRecharge) {
    columns.push({
      title: (
        <span className='text-center'>
          <Dot type={`scope1`} />
          {t('categories.recharge')}
        </span>
      ),
      dataIndex: 'recharge',
      key: 'recharge',
      align: 'right'
    });
  }
  if (showWasteWaterTreatment) {
    columns.push({
      title: (
        <span className='text-center'>
          <Dot type={`scope1`} />
          {t('categories.waste_water_treatments')}
        </span>
      ),
      dataIndex: 'waste_water_treatments',
      key: 'waste_water_treatments',
      align: 'right'
    });
  }
  if (showElectricity) {
    columns.push({
      title: (
        <span className='text-center'>
          <Dot type={`scope2`} />
          {t('categories.electricity')}
        </span>
      ),
      dataIndex: 'electricity',
      key: 'electricity',
      align: 'right'
    });
  }
  if (showWater) {
    columns.push({
      title: (
        <span className='text-center'>
          <Dot type={`scope3`} />
          {t('categories.water')}
        </span>
      ),
      dataIndex: 'water',
      key: 'water',
      align: 'right'
    });
  }
  if (showWastes) {
    columns.push({
      title: (
        <span className='text-center'>
          <Dot type={`scope3`} />
          {t('categories.wastes')}
        </span>
      ),
      dataIndex: 'wastes',
      key: 'wastes',
      align: 'right'
    });
  }

  columns.push({
    title: 'Total',
    dataIndex: 'co2e',
    key: 'co2e',
    align: 'right'
  });

  if (organizations?.length > 0) {
    columns.push({
      title: t('report.organization'),
      dataIndex: 'organization',
      key: 'organization',
      align: 'right'
    });
  }
  const listData = facilities
    .sort((a, b) => b.co2e.total - a.co2e.total)
    .map((facility) => {
      return {
        id: facility.id,
        name: (
          <span className='name-logo body1-bold-font'>
            <Icon icon='facilities' color='gradient' />
            {limitString(facility.name, 25)}
          </span>
        ),
        stationary: facility.co2e.stationary
          ? formatRoundNumber(facility.co2e.stationary / 1000)
          : 0,
        recharge: facility.co2e.recharge ? formatRoundNumber(facility.co2e.recharge / 1000) : 0,
        electricity: facility.co2e.electricity
          ? formatRoundNumber(facility.co2e.electricity / 1000)
          : 0,
        water: facility.co2e.water ? formatRoundNumber(facility.co2e.water / 1000) : 0,
        wastes: facility.co2e.wastes ? formatRoundNumber(facility.co2e.wastes / 1000) : 0,
        waste_water_treatments: facility.co2e.waste_water_treatments
          ? formatRoundNumber(facility.co2e.waste_water_treatments / 1000)
          : 0,
        co2e: (
          <span className='highlight-report-text-color body1-bold-font'>
            {facility.co2e.total ? formatRoundNumber(facility.co2e.total / 1000) : 0}
          </span>
        ),
        // percentage: (
        //   <span className='percentage body1-font tag-bg-text-color text-center'>{`${
        //     totalCo2 ? numberToDecimal((facility.co2e * 100) / totalCo2) : 0
        //   } %`}</span>
        // ),
        organization:
          organizations.find((elem) => elem.id === facility.organization_id)?.company_name || ''
      };
    });

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader
          title={t('report.facilities')}
          description={t('report.facilitiesDescription')}
        />
        <CardTotalCo2 totalCo2={totalCo2} />
        <div className='rows-wrapper on-card-gray-bg-color'>
          <List data={listData} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default Facilities;
