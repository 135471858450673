import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { createIntensityMetricGroup } from '../../../../services/api/intensityMetrics';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';
import SelectCardWrapper from '../../../ui/selectCard/SelectCardWrapper';
import useScopeOptions from '../useScopeOptions';

type FormData = {
  scopes: SelectOptionFormat;
  activityVolume: string;
  isGrouping: boolean;
  errors: ErrorType[];
};
type Props = {
  addIntensityMetricGroup: (intensityMetricGroup: IntensityMetricGroup) => void;
};

const CreateIntensityMetricGroup = ({ addIntensityMetricGroup }: Props) => {
  const { t } = useTranslation();
  const selectedOrganization = useSelectedOrganization();
  const [formData, setFormData] = useState<FormData>({
    scopes: {
      id: '',
      name: ''
    },
    activityVolume: '',
    isGrouping: false,
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const scopeOptions = useScopeOptions();

  const handleCreate = async () => {
    const optionalFields: string[] = ['isGrouping'];
    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);
    // if there are errors, set them and return
    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return;
    }
    setLoadingButton(true);
    // Call api to create
    const newIntensityMetricGroup = await createIntensityMetricGroup(
      {
        scopes: formData.scopes.id.split(',').map((elem) => parseInt(elem, 10)),
        unit: formData.activityVolume,
        name: formData.activityVolume,
        is_grouping: formData.isGrouping
      },
      selectedOrganization?.id ?? ''
    );
    setLoadingButton(false);

    if (!newIntensityMetricGroup) return;

    // Callback to close modal and add new item to list
    addIntensityMetricGroup({
      scopes: formData.scopes.id.split(',').map((elem) => parseInt(elem, 10)),
      unit: formData.activityVolume,
      id: newIntensityMetricGroup.id,
      is_grouping: formData.isGrouping,
      years: []
    });
  };

  const cards = [
    {
      id: 'simple',
      icon: '/images/icons/intensityMetric.svg',
      title: t('createIntensityMetricGroup.simple')
    },
    {
      id: 'grouping',
      icon: '/images/icons/folder.svg',
      title: t('createIntensityMetricGroup.grouping')
    }
  ];

  return (
    <div className='create-intensity-metric-group'>
      <FormHeader
        title={t('createIntensityMetricGroup.title')}
        description={t('createIntensityMetricGroup.description')}
      />
      {!selectedOrganization?.is_group_fund && (
        <div>
          <h3 className='body1-bold-font'>{t('createIntensityMetricGroup.type')}</h3>
          <SelectCardWrapper
            cards={cards}
            onClickCard={(id) => {
              setFormData((prev) => ({
                ...prev,
                isGrouping: id === 'grouping',
                scopes:
                  id === 'grouping'
                    ? {
                        id: '1,2,3',
                        name: t('createIntensityMetricGroup.1,2,3')
                      }
                    : prev.scopes
              }));
            }}
            type={formData.isGrouping ? cards[1] : cards[0]}
          />
        </div>
      )}
      <FormWrapper>
        <FormSelect
          icon='/images/icons/catalog.svg'
          label={t('createIntensityMetricGroup.scopes')}
          placeholder={t('createIntensityMetricGroup.selectOption')}
          options={scopeOptions}
          value={formData.scopes}
          onChange={onChangeValue('scopes')}
          error={formData.errors.find((elem) => elem.error === 'scopes')}
          disabled={formData.isGrouping}
          tooltip={t('createIntensityMetricGroup.tooltipScopes')}
        />

        <FormText
          icon={'/images/icons/intensityMetric.svg'}
          label={t('createIntensityMetricGroup.activityVolume')}
          placeholder={t('createIntensityMetricGroup.writeActivityVolume')}
          value={formData.activityVolume}
          onChange={onChangeValue('activityVolume')}
          error={formData.errors.find((elem) => elem.error === 'activityVolume')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('createIntensityMetricGroup.create')}
          size='medium'
          onClick={handleCreate}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default CreateIntensityMetricGroup;
