import './styles.scss';
type Props = {
  darkStyle?: boolean;
  position?: 'bottom' | 'free';
  size?: 'small' | 'medium' | 'large';
};
const PoweredByDcycle = ({ darkStyle, position = 'bottom', size = 'medium' }: Props) => {
  const getFontBySize = () => {
    if (size === 'small') return 'font-5';
    if (size === 'medium') return 'font-10';
    if (size === 'large') return 'font-14';
  };
  return (
    <div className={`powered-by-dcycle ${position} ${size}`}>
      <span className={`${getFontBySize()} weight-400 ${darkStyle ? 'on-dark-text-color' : ''}`}>
        Powered by
      </span>
      <img
        src={darkStyle ? '/images/dcycleLogo.svg' : '/images/dcycleLogoBlack.svg'}
        alt='dcycle'
      />
    </div>
  );
};

export default PoweredByDcycle;
