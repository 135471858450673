import { useTranslation } from 'react-i18next';
import NuvoImporter from '../../../../ui/nuvoImporter/NuvoImporter';
import { ColumnAPI, LanguageType, RejectSubmitResult, ResultValues, SettingsAPI } from 'nuvo-react';
import { basicNuvoStyle } from '../../../../../styles/nuvo';
import useNuvoFileName from '../../../../../customHooks/useNuvoFileName';
import useErrors from '../../../../../customHooks/useErrors';
import { getPresignedUrlPurchases } from '../../../../../services/api/purchases';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { uploadFilePresignedUrl } from '../../../../../services/api/aws';
import { postNuvoAnalytics } from '../../../../../services/api/nuvoAnalytics';
import { useDispatch } from 'react-redux';
import { startPurchaseLoader, stopPurchaseLoader } from '../../../../../actions/purchasesLoader';
import { useNuvoCountries } from '../../../../../customHooks/useNuvoCountries';
import useNuvoButton from '../../../../../customHooks/useNuvoButton';
import { onEntryChange, parseNuvoResults, recycledHook, validateRow } from './utils';
import { nuvoAdapter } from './adapter';
import { CATEGORY, MAX_NUVO_ENTRIES, TEMPLATE_FILE_NAME, TIMEOUT_IN_MS } from './constants';
import { setOrganizationLimitOfficialSuppliersBonusAction } from '../../../../../actions/auth';

type Props = {
  onUpload?: (results: ResultValues) => void;
  onClose?: () => void;
};
export const Nuvo: React.FC<Props> = ({ onUpload, onClose }) => {
  const { t, i18n } = useTranslation();

  const organization = useSelectedOrganization();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName();
  useNuvoButton({ onClick: handleGetFileName });

  const ERRORS = useErrors();

  const { countriesNuvo } = useNuvoCountries();

  const dispatch = useDispatch();

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'purchased_product',
      label: t('purchases.nuvo.columns.productName'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ],

      description: t('purchases.nuvo.descriptions.productName'),
      alternativeMatches: [
        'product',
        'product name',
        'purchased product',
        'product_name',
        'purchased_product',
        'produto',
        'nome do produto',
        'produto comprado',
        'produto_comprado',
        'nome_do_produto',
        'producto',
        'nombre del producto',
        'producto comprado',
        'producto_comprado',
        'nombre_del_producto'
      ]
    },
    {
      key: 'purchase_date',
      label: t('purchases.nuvo.columns.purchaseDate'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD',
      description: t('purchases.nuvo.descriptions.purchaseDate'),
      alternativeMatches: [
        'date',
        'purchase date',
        'fecha de compra',
        'data da compra',
        'data de compra',
        'purchase_date',
        'fecha_de_compra',
        'data_da_compra',
        'data_de_compra'
      ]
    },
    {
      key: 'business_name',
      label: t('purchases.nuvo.columns.businessName'),
      columnType: 'string',
      validations: [
        {
          validate: 'required'
        }
      ],
      description: t('purchases.nuvo.descriptions.businessName'),
      alternativeMatches: ['supplier', 'provider', 'proveedor', 'fornecedor']
    },
    {
      key: 'country',
      label: t('purchases.nuvo.columns.supplierCountry'),
      columnType: 'category',
      dropdownOptions: countriesNuvo,
      validations: [
        {
          validate: 'required',
          errorMessage: ERRORS.NUVO.COUNTRY_NOT_FOUND
        }
      ],
      description: t('purchases.nuvo.descriptions.supplierCountry'),
      alternativeMatches: [
        'country',
        'country of origin',
        'country of purchase',
        'country of origin of purchase',
        'país de origen',
        'país de compra',
        'país de origen de la compra',
        'pais de origen',
        'pais de compra',
        'pais de origen de la compra',
        'país de origem',
        'país de compra',
        'país de origem da compra',
        'pais de origem',
        'pais de origem da compra'
      ]
    },
    {
      key: 'euro_gross_quantity',
      label: t('purchases.nuvo.columns.euroGrossQuantity'),
      columnType: 'currency_eur',
      validations: [
        {
          validate: 'required'
        },
        {
          validate: 'regex',
          regex: '^[0-9]+(.[0-9]{1,2})?$'
        }
      ],
      description: t('purchases.nuvo.descriptions.euroGrossQuantity'),
      alternativeMatches: [
        'euros',
        'price',
        'quantity',
        'euro_gross_quantity',
        'euro gross quantity',
        'euro gross',
        'euro_gross',
        'bruto',
        'euro bruto',
        'euro_bruto',
        'precio',
        'euro precio',
        'euro_precio',
        'cantidad',
        'euro cantidad',
        'euro_cantidad',
        'quantidade',
        'euro quantidade',
        'euro_quantidade',
        'preço',
        'euro preço',
        'euro_preço'
      ]
    },
    {
      key: 'recycled',
      label: t('purchases.nuvo.columns.recycled'),
      columnType: 'float',
      description: t('purchases.nuvo.descriptions.recycled'),
      alternativeMatches: [
        'reciclado',
        'reciclada',
        'reciclados',
        'recicladas',
        'recycled',
        'reused',
        'reutilizado'
      ]
    }
  ];

  const settings: SettingsAPI = {
    multipleFileUpload: true,
    language: i18n.resolvedLanguage as LanguageType,
    style: basicNuvoStyle,
    automaticHeaderDetection: true,
    maxEntries: MAX_NUVO_ENTRIES,
    identifier: TEMPLATE_FILE_NAME, // Template file name
    columns
  };

  const onCancel = () => {
    onClose?.();
    handleExit();
  };

  return (
    <NuvoImporter
      btnI18nKey='purchases.uploadFile'
      settings={settings}
      onCancel={onCancel}
      columnHooks={{
        recycled: recycledHook
      }}
      onEntryInit={validateRow(t)}
      onEntryChange={onEntryChange(t)}
      onResults={async (results, errors, complete) => {
        if (errors.length > 0) {
          complete(nuvoErrorFounded);
          return;
        }

        if (results.length <= 0) {
          complete(nuvoError);
          return;
        }

        const adaptedResults = nuvoAdapter(results);

        const { finalFileName, file } = await parseNuvoResults(adaptedResults, fileName, CATEGORY);

        if (!organization) return complete(nuvoError);

        const data = await getPresignedUrlPurchases(finalFileName, organization?.id ?? '');

        if (data?.response?.status >= 400) return complete(nuvoError);

        const response = await uploadFilePresignedUrl(file, data?.upload_url);

        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category: CATEGORY
        });

        startPurchaseLoader(dispatch);

        setTimeout(() => {
          stopPurchaseLoader(dispatch);
        }, TIMEOUT_IN_MS);

        dispatch(setOrganizationLimitOfficialSuppliersBonusAction(organization.id, 'api_handling'));

        onUpload?.(results);

        complete();
      }}
    />
  );
};
