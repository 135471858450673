import React, { useContext, useEffect, useState } from 'react';
import { getLogisticsReport } from '../../../../../../services/api/logistics';
import { UserContext } from '../../../../../../context/userContext';
import { convertStringToDate } from '../../../../../../utils/convertDates';
import { ValueUnitsLogistics } from '../../../../../../types/entities/logistics';

type Props = {
  startDate: string;
  endDate: string;
};

type HocsInfo = {
  hub_name: string;
  intensity_value: ValueUnitsLogistics;
  emissions: ValueUnitsLogistics;
  tonne: ValueUnitsLogistics;
};

type HocsInfoResponse = {
  [hub_name: string]: {
    hub_name: string;
    intensity_value: ValueUnitsLogistics;
    emissions: ValueUnitsLogistics;
    tonne: ValueUnitsLogistics;
  };
};
const useGetData = ({ startDate, endDate }: Props) => {
  const [hocsInfo, setHocsInfo] = useState<HocsInfo[]>([]);
  const fetchData = async () => {
    const _hocsInfo: HocsInfoResponse = {
      'Almacén 1': {
        hub_name: 'Almacén 1',
        intensity_value: {
          value: 10700,
          units: 'g CO₂e/t '
        },
        emissions: {
          value: 5.35,
          units: 'kg CO₂e'
        },
        tonne: {
          value: 0.5,
          units: 't'
        }
      },
      'Almacén supercargador': {
        hub_name: 'Almacén supercargador',
        intensity_value: {
          value: 0,
          units: 'g CO₂e/t '
        },
        emissions: {
          value: 4,
          units: 'kg CO₂e'
        },
        tonne: {
          value: 0,
          units: 't'
        }
      },
      'Almacén 2': {
        hub_name: 'Almacén 2',
        intensity_value: {
          value: 600,
          units: 'g CO₂e/t '
        },
        emissions: {
          value: 0.3,
          units: 'kg CO₂e'
        },
        tonne: {
          value: 0.5,
          units: 't'
        }
      },
      'Almacén 3': {
        hub_name: 'Almacén 3',
        intensity_value: {
          value: 10700,
          units: 'g CO₂e/t '
        },
        emissions: {
          value: 5.35,
          units: 'kg CO₂e'
        },
        tonne: {
          value: 0.5,
          units: 't'
        }
      },
      'Almacén 4': {
        hub_name: 'Almacén 4',
        intensity_value: {
          value: 0,
          units: 'g CO₂e/t '
        },
        emissions: {
          value: 0,
          units: 'kg CO₂e'
        },
        tonne: {
          value: 0.5,
          units: 't'
        }
      },
      'Almacén supercargador 2': {
        hub_name: 'Almacén supercargador',
        intensity_value: {
          value: 0,
          units: 'g CO₂e/t '
        },
        emissions: {
          value: 13.5,
          units: 'kg CO₂e'
        },
        tonne: {
          value: 0,
          units: 't'
        }
      },
      'Almacén 5': {
        hub_name: 'Almacén 5',
        intensity_value: {
          value: 17500,
          units: 'g CO₂e/t '
        },
        emissions: {
          value: 8.75,
          units: 'kg CO₂e'
        },
        tonne: {
          value: 0.5,
          units: 't'
        }
      }
    };

    setHocsInfo(Object.keys(_hocsInfo).map((key) => _hocsInfo[key]));
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);
  return { hocsInfo };
};

export default useGetData;
