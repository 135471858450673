import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getPrefixes from '../../constants/phonePrefixes';
import { Organization } from '../../types/entities/organization';
import FormWrapper from '../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../ui/formComponents2/formInputs/formText/FormText';

type Props = {
  showEditInvoicingDataModal: () => void;
  role: string;
  organization: Organization;
};
function InvoicingData({ showEditInvoicingDataModal, role, organization }: Props) {
  const { t, i18n } = useTranslation();
  const [tradeName, settradeName] = useState(organization.invoicing_trade_name || '');
  const [vat, setVat] = useState(organization.vat || '');
  const [address, setAddress] = useState(organization.invoicing_street_address || '');
  const [country, setCountry] = useState(
    organization.invoicing_country
      ? { id: organization.invoicing_country, name: organization.invoicing_country }
      : { id: '', name: '' }
  );
  const [province, setProvince] = useState(organization.invoicing_province || '');
  const [postalCode, setPostalCode] = useState(organization.invoicing_postal_code || '');

  useEffect(() => {
    settradeName(organization.invoicing_trade_name || '');
    setVat(organization.vat || '');
    setAddress(organization.invoicing_street_address || '');
    setCountry(
      organization.invoicing_country
        ? { id: organization.invoicing_country, name: organization.invoicing_country }
        : { id: '', name: '' }
    );
    setProvince(organization.invoicing_province || '');
    setPostalCode(organization.invoicing_postal_code || '');
  }, [organization]);

  return (
    <div className='card-profile-info main-bg-color solid-border'>
      <div className='card-profile-info__header page-header'>
        <h1 className='headline4-font'>{t('profile.cardInfo')}</h1>
        {role === 'owner' && (
          <img
            src='/images/icons/editPen.svg'
            alt='edit-pen'
            className='edit pointer'
            onClick={showEditInvoicingDataModal}
          />
        )}
      </div>
      {role === 'owner' ? (
        <FormWrapper>
          <FormText
            icon={'/images/icons/building.svg'}
            label={t('profile.tradeName')}
            placeholder={''}
            value={tradeName}
            onChange={function (e: ChangeEvent<HTMLInputElement>): void {
              console.log('Function not implemented.');
            }}
            disabled
          />
          <FormText
            icon={'/images/icons/creditcard.svg'}
            label={t('profile.vat')}
            placeholder={''}
            value={vat}
            onChange={function (e: ChangeEvent<HTMLInputElement>): void {
              console.log('Function not implemented.');
            }}
            disabled
          />
          <FormText
            icon={'/images/icons/map.svg'}
            label={t('profile.address')}
            placeholder={''}
            value={address}
            onChange={function (e: ChangeEvent<HTMLInputElement>): void {
              console.log('Function not implemented.');
            }}
            disabled
          />
          <FormText
            icon={'/images/icons/pin.svg'}
            placeholder=''
            label={t('profile.country')}
            value={
              getPrefixes(i18n.resolvedLanguage).find((prefix) => prefix.code === country.id)
                ?.name || country.id
            }
            onChange={function (e: ChangeEvent<HTMLInputElement>): void {
              throw new Error('Function not implemented.');
            }}
            disabled
          />
          <FormText
            icon={'/images/icons/directions.svg'}
            label={t('profile.province')}
            placeholder={''}
            value={province}
            onChange={function (e: ChangeEvent<HTMLInputElement>): void {
              console.log('Function not implemented.');
            }}
            disabled
          />
          <FormText
            icon={'/images/icons/compass.svg'}
            label={t('profile.postalCode')}
            placeholder={''}
            value={postalCode}
            onChange={function (e: ChangeEvent<HTMLInputElement>): void {
              console.log('Function not implemented.');
            }}
            disabled
          />
        </FormWrapper>
      ) : (
        <span className='subtitle3-font not-allowed'>{t('profile.notAllowed')}</span>
      )}
    </div>
  );
}

export default InvoicingData;
