import SuccessLabel from '../../../ui/statusLabels/successLabel/SuccessLabel';
import { useTranslation } from 'react-i18next';
import ErrorLabel from '../../../ui/statusLabels/errorLabel/ErrorLabel';
import PendingLabel from '../../../ui/statusLabels/pendingLabel/PendingLabel';
import { Status } from '../../../../types/utilsEnums/status';

export const useCodeManagementFilterOptions = () => {
  const { t } = useTranslation();

  const options = [
    {
      value: Status.ACTIVE,
      label: <SuccessLabel>{t('codeManagement.completed')}</SuccessLabel>
    },
    {
      value: Status.PENDING,
      label: <PendingLabel>{t('codeManagement.pending')}</PendingLabel>
    },
    {
      value: Status.ERROR,
      label: <ErrorLabel>{t('codeManagement.error')}</ErrorLabel>
    }
  ];

  return options;
};
