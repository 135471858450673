/* eslint-disable prettier/prettier */
import { useState } from 'react';
import useErrors from '../../../../../customHooks/useErrors';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { postPurchaseSpendBased } from '../../../../../services/api/purchases';
import {
  IPurchaseGeneralBackend,
  IPurchaseSpendBasedFormFront
} from '../../../../../types/purchases';

import FormSpendBased from '../form/formSpendBased/FormSpendBased';
import { injectUnit } from '../utils/injectUnit';

type Props = {
  addPurchase: (purchase: IPurchaseGeneralBackend) => void;
};

function AddPurchaseSpendBase({ addPurchase }: Props) {
  const selectedOrganization = useSelectedOrganization();
  const ERRORS = useErrors();

  const [error, setError] = useState<ErrorType>();

  const handleSubmit = async (purchase: IPurchaseSpendBasedFormFront) => {
    if (!selectedOrganization) return;
    const response = await postPurchaseSpendBased(selectedOrganization.id, purchase);

    if (!response || response.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const data = await injectUnit(response.data);

    addPurchase(data);
  };

  return <FormSpendBased handleSubmit={handleSubmit} error={error} />;
}

export default AddPurchaseSpendBase;
