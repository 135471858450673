import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setNotification } from '../../../../actions/notification';
import { SIZE_PAGINATION_SMALL } from '../../../../constants';
import { UserContext } from '../../../../context/userContext';
import { getIntensityMetricsByIdPaginated } from '../../../../services/api/intensityMetrics';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import MainPageLayout from '../../../layout/mainPageLayout/MainPageLayout';
import Button from '../../../ui/button/Button';
import ButtonDropdown from '../../../ui/buttonDropdown/ButtonDropdown';
import InfiniteList from '../../../ui/infiniteList/InfiniteListV2';
import useFetchInfiniteList from '../../../ui/infiniteList/hooks/useFetchInfiniteList';
import Modal from '../../../ui/modal/Modal';
import NameIcon from '../../../ui/nameIcon/NameIcon';
import TotalLegend from '../../../ui/totalLegend/TotalLegend';
import CreateIntensityMetric from './createIntensityMetric/CreateIntensityMetric';
import DeleteIntensityMetric from './deleteIntensityMetric.tsx/DeleteIntensityMetric';
import EditIntensityMetric from './editIntensityMetric/EditIntensityMetric';
import GroupIntensityMetrics from './groupIntensityMetrics/GroupIntensityMetrics';
import useColumns from './hooks/useColumns';
import useGetData from './useGetData';
import Icon from '../../../ui/icon/Icon';
import { convertDateBackToFront } from '../../../../utils/convertDates';
import CustomSkeletonLoader from '../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import LoaderStandard from '../../../ui/loaders/loaderStandard/LoaderStandard';

const IntensityMetricsDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const [showCreate, setShowCreate] = useState(false);
  const [intensityMetricToEdit, setIntensityMetricToEdit] = useState<IntensityMetric>();
  const [intensityMetricToDelete, setIntensityMetricToDelete] = useState<IntensityMetric>();

  const [showGroupIntensityMetrics, setShowGroupIntensityMetrics] = useState(false);

  const minYear = 2015;
  const maxYear = new Date().getFullYear();

  const years = [];
  for (let i = maxYear; i >= minYear; i--) {
    years.push({ id: i.toString(), name: i.toString() });
  }

  const url = `/intensity_metrics/list/${id}`;

  const fetch = async (page: number): Promise<Pagination<IntensityMetric> | undefined> => {
    if (!id) return;

    const response = await getIntensityMetricsByIdPaginated(id, page, SIZE_PAGINATION_SMALL);
    if (response?.response?.status >= 400) return;
    return response;
  };

  const {
    fetchData,
    firstLoading,
    loading: loadingTable,
    total,
    addElement,
    editElement,
    removeElement,
    addElements,
    data: intensityMetrics
  } = useFetchInfiniteList<IntensityMetric>(fetch, [url]);

  const { intensityMetricGroup, setIntensityMetricGroup, loading } = useGetData({
    id: id ?? '',
    organization_id: user?.selectedOrganization ?? ''
  });

  const handleShowCreate = () => {
    setShowCreate(true);
  };

  const handleCloseModals = () => {
    setShowCreate(false);
    setIntensityMetricToEdit(undefined);
    setIntensityMetricToDelete(undefined);
    setShowGroupIntensityMetrics(false);
  };

  const renderDropdownComponent = (intensityMetric: IntensityMetric) => {
    const options = [
      {
        id: `${intensityMetric.id}-delete`,
        name: t('intensityMetricsDetail.delete'),
        onClick: () => {
          setIntensityMetricToDelete(intensityMetric);
        }
      },
      {
        id: `${intensityMetric.id}-edit`,
        name: t('intensityMetricsDetail.edit'),
        onClick: () => {
          setIntensityMetricToEdit(intensityMetric);
        }
      }
    ];
    return (
      <ButtonDropdown
        button={<Icon icon='elipsis_horizontal' color='gray-dark' />}
        options={options}
      />
    );
  };

  const parseData = (intensityMetrics: IntensityMetric[]) => {
    return intensityMetrics.map((intensityMetric) => {
      return {
        ...intensityMetric,
        date: `${convertDateBackToFront(intensityMetric.start_date)} - ${convertDateBackToFront(
          intensityMetric.end_date
        )}`,
        activity_volume: intensityMetricGroup ? (
          <div className='icon-text-wrapper'>
            <Icon icon='growth' color='gray-dark' />
            <span>{`${intensityMetric.quantity} ${intensityMetricGroup.unit}`}</span>
          </div>
        ) : null,
        intensity_metric: `${numberToDecimalNonZero(intensityMetric.value / 1000, 3)} t CO₂ eq. / ${
          intensityMetricGroup?.unit
        }`,
        created_by: (
          <NameIcon
            name={intensityMetric.user?.first_name || ''}
            lastName={intensityMetric.user?.last_name || ''}
          />
        ),
        key: `${intensityMetric.start_date} - ${intensityMetric.end_date}`,
        edit: renderDropdownComponent(intensityMetric)
      };
    });
  };

  const addIntensityMetric = (intensityMetric: IntensityMetric) => {
    addElement(intensityMetric);
    handleCloseModals();
    dispatch(setNotification(t('notification.createIntensityMetric')));
    setIntensityMetricGroup((prev) => {
      if (!prev) return prev;
      return {
        ...prev
      };
    });
  };

  const addIntensityMetrics = (intensityMetricsToAdd: IntensityMetric[]) => {
    addElements(intensityMetricsToAdd);
    handleCloseModals();
    dispatch(setNotification(t('notification.createIntensityMetric')));
  };

  const editIntensityMetric = (intensityMetric: IntensityMetric) => {
    handleCloseModals();
    dispatch(setNotification(t('notification.editIntensityMetric')));
    editElement(intensityMetric);
  };

  const deleteIntensityMetric = (id: string) => {
    removeElement(id);
    handleCloseModals();
    dispatch(setNotification(t('notification.deleteIntensityMetric')));
  };

  const columns = useColumns();
  if (intensityMetricGroup?.is_grouping) {
    // remove edit column
    columns.pop();
  }

  return (
    <MainPageLayout
      sectionTitle={t('intensityMetricsDetail.title')}
      title={t('intensityMetricsDetail.start')}
      description={
        <span style={{ whiteSpace: 'pre-line' }}>
          {t('intensityMetricsDetail.startDescription')}
        </span>
      }
      breadcrumb={
        <Breadcrumb
          crumbsReplace={id ? [{ key: id, value: intensityMetricGroup?.unit ?? '' }] : []}
        />
      }
      buttons={
        loading ? (
          <CustomSkeletonLoader
            count={1}
            style={{ width: '100px', flexBasis: '100px', flexGrow: 0 }}
          />
        ) : (
          <>
            {intensityMetricGroup?.is_grouping ? (
              <Button
                lookAndFeel='primary'
                text={t('intensityMetricsDetail.groupIntensityMetrics')}
                size='small'
                onClick={() => setShowGroupIntensityMetrics(true)}
              />
            ) : (
              <Button
                lookAndFeel={'primary'}
                onClick={handleShowCreate}
                icon={'/images/icons/plusWhite.svg'}
                text={t('intensityMetricsDetail.add')}
                size='small'
              />
            )}
          </>
        )
      }>
      <InfiniteList
        fetchData={fetchData}
        columns={columns}
        data={parseData(intensityMetrics)}
        loading={loadingTable || loading}
        firstLoading={firstLoading}
        total={total}
        header={
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <TotalLegend total={total} loading={false} i18key={'intensityMetricsDetail'} />
          </div>
        }
      />
      <Modal show={showCreate} onClose={handleCloseModals} width='584px' maxWidth='584px'>
        {intensityMetricGroup ? (
          <CreateIntensityMetric
            addIntensityMetric={addIntensityMetric}
            intensityMetricGroup={intensityMetricGroup}
          />
        ) : null}
      </Modal>
      <Modal
        show={!!intensityMetricToEdit}
        onClose={handleCloseModals}
        width='584px'
        maxWidth='584px'>
        {intensityMetricToEdit && intensityMetricGroup ? (
          <EditIntensityMetric
            intensityMetric={intensityMetricToEdit}
            editIntensityMetric={editIntensityMetric}
            intensityMetricGroup={intensityMetricGroup}
          />
        ) : null}
      </Modal>
      <Modal
        show={!!intensityMetricToDelete}
        onClose={handleCloseModals}
        width='584px'
        maxWidth='584px'>
        {intensityMetricToDelete && (
          <DeleteIntensityMetric
            intensityMetricToDelete={intensityMetricToDelete}
            removeIntensityMetric={deleteIntensityMetric}
            closeDeleteModal={handleCloseModals}
          />
        )}
      </Modal>
      <Modal
        show={showGroupIntensityMetrics}
        onClose={() => setShowGroupIntensityMetrics(false)}
        width='584px'
        maxWidth='584px'>
        <GroupIntensityMetrics addIntensityMetrics={addIntensityMetrics} />
      </Modal>
    </MainPageLayout>
  );
};

export default IntensityMetricsDetail;
