import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { UserContext } from '../../../../../context/userContext';
import { configureGroupFunds } from '../../../../../services/api/groupFunds';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import { FormConfigureDataType, GroupFunds } from '../../../../../types/entities/groupFunds';
import AddInvestmentToFundForm from '../../common/addInvestmentToFundForm/FormInvestment';
import './style.scss';

type Props = {
  company: GroupFunds;
  handleOpenDeletePending: (company: GroupFunds) => void;
  updateCompanyStatus: (id: string, childCompany: GroupFunds) => void;
};

function ConfigureCompanyGroupFunds({
  company,
  handleOpenDeletePending,
  updateCompanyStatus
}: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  const companyId = company.child_id;

  const [formData, setFormData] = useState<FormConfigureDataType>({
    fund: {
      id: '',
      name: ''
    },
    investment: '',
    isDebtFinancing: false,
    companyValue: '',
    investmentDate: '',
    tag: '',
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleError = () => {
    const optionalFields = ['tag', 'isDebtFinancing'];
    const newErrors = checkFormErrors(formData, [], optionalFields);

    if (Number(formData.investment) > Number(formData.companyValue)) {
      newErrors.push({
        error: 'investment',
        description: t('configureGroupFunds.investmentGreaterThanCompanyValue')
      });
    }

    if (newErrors.length > 0) {
      setFormData((prev) => ({
        ...prev,
        errors: newErrors
      }));
      return newErrors;
    }

    return false;
  };

  const handleLinkCompany = async () => {
    if (loadingButton || handleError() || !user?.selectedOrganization) return;
    setLoadingButton(true);

    const response = await configureGroupFunds(companyId, formData, user?.selectedOrganization);

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    dispatch(setNotification(t('notification.companyConfigured')));
    setLoadingButton(false);
    updateCompanyStatus(companyId, response);
  };

  const deleteRequest = () => {
    handleOpenDeletePending(company);
  };

  return (
    <div className='configure-company'>
      <FormHeader
        title={t('companies.configureCompany')}
        description={t('companies.configureCompanyDescription')}
      />
      <AddInvestmentToFundForm
        formData={formData}
        setFormData={setFormData}
        organizationId={companyId}
        newLink
      />
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleLinkCompany}
          loading={loadingButton}
          text={t('configureGroupFunds.save')}
        />
      </FormButtonSection>

      <span className='highlight-text-color body1-bold-font pointer' onClick={deleteRequest}>
        {t('companies.deleteRequest')}
      </span>
    </div>
  );
}

export default ConfigureCompanyGroupFunds;
