import React from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionBox from '../common/descriptionBox/DescriptionBox';

const ElectricityEmissionsDisclaimer = () => {
  const { t } = useTranslation();
  return (
    <DescriptionBox
      descriptions={[
        t('ghgReport.electricityDisclaimer.description1'),
        t('ghgReport.electricityDisclaimer.description2'),
        t('ghgReport.electricityDisclaimer.description3'),
        t('ghgReport.electricityDisclaimer.description4')
      ]}
    />
  );
};

export default ElectricityEmissionsDisclaimer;
