import { useTranslation } from 'react-i18next';
import { LcaType } from '../../../../types/entities/useAndEndOfLife';
import type { Column } from '../../../../types/table';
import formatNumber from '../../../../utils/formatNumber';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';
import List from '../list/List';

type Props = {
  data: LcaType[];
};

export const Waste = ({ data }: Props) => {
  const { t } = useTranslation();
  let totalCo2 = 0;
  data?.forEach((transform) => (totalCo2 += transform.co2e));
  const top3Data = data?.sort((a, b) => b.co2e - a.co2e).slice(0, 3);

  const columns: Column[] = [
    {
      title: t('report.top3lcas'),
      dataIndex: 'name',
      key: 'name',
      width: '40%'
    },
    {
      title: t('report.production'),
      dataIndex: 'production',
      key: 'production',
      align: 'right'
    },
    {
      title: 't CO₂ eq',
      dataIndex: 'co2',
      key: 'co2',
      align: 'right'
    },
    {
      title: t('report.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right'
    }
  ];

  const listData = top3Data.map((element) => {
    return {
      name: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
          <img src='/images/icons/wasteSoldProduct.svg' />
          <span> {element.product_name}</span>
        </div>
      ),
      production: element.production_quantity,
      co2: formatNumber(element.co2e / 1000),
      total: (
        <span className='lca-tag percentage body1-font tag-bg-text-color'>{`${
          totalCo2 ? formatNumber((element.co2e * 100) / totalCo2) : 0
        } %`}</span>
      )
    };
  });

  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <div className='flex justify-between'>
          <CardHeader
            title={t('report.soldProductsWaste')}
            description={t('report.soldProductsWasteDescription') + ' (cat.12)'}
          />
          <div className='text-tag body3-font tag-bg-text-color tag-space-between'>{`${t(
            'measureMain.scope'
          )} 3`}</div>
        </div>

        <CardTotalCo2 totalCo2={totalCo2} />
        <div className='rows-wrapper on-card-gray-bg-color'>
          <List data={listData} columns={columns} />
        </div>
      </div>
    </div>
  );
};
