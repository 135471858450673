import React from 'react';
import './styles.scss';
import Label from '../../../../../ui/label/Label';
type Props = {
  id: string;
  name: string;
  removeElement: (id: string) => void;
};
const TagAdded = ({ id, name, removeElement }: Props) => {
  const handleRemove = () => {
    removeElement(id);
  };

  return (
    <div className='tag-added' onClick={handleRemove}>
      <Label lookAndFeel='secondary'>
        <span className='subtitle3-font image-text-wrapper'>{name}</span>
        <img className='delete-icon' src='/images/icons/closeBlue.svg' alt='delete tag' />
      </Label>
    </div>
  );
};

export default TagAdded;
