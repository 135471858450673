import { useEffect, useState } from 'react';
import { useACVId } from './useACVId';
import { useNodeMaterials } from './useNodeMaterials';
import { buildFormFromMetadata } from '../components/SelectMaterialModal/build';
import { useValidate } from '../components/SelectMaterialModal/useValidate';
import { useLCAMaterial } from './useLCAMaterial';

export const useConfigureMaterialForm = (node_id?: string) => {
  const { acv_id } = useACVId();

  const metadata = useLCAMaterial({ node_id: node_id ?? '', acv_id });
  const { data: nodeMaterials } = useNodeMaterials({ node_id, acv_id });

  const [form, setForm] = useState(buildFormFromMetadata(metadata, nodeMaterials));
  const { errors, validate, setErrors } = useValidate();

  useEffect(() => {
    setForm(buildFormFromMetadata(metadata, nodeMaterials));
    setErrors(null);
  }, [JSON.stringify(metadata), JSON.stringify(nodeMaterials)]);

  return { form, setForm, errors, setErrors, validate };
};
