// regex for DD/MM/YYYY
export const regexddmmyyyy =
  /^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\d|2[0-8])[- /.]02)[- /.]\d{4}|29[- /.]02[- /.](\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$/g;

export const regexNumberAndSlash = /^[0-9/]*$/;

export function isValidDate(dateString: string) {
  const dateParts = dateString.split('/');
  if (dateParts.length !== 3) return false; // Date must have three parts: day, month, year

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
  const year = parseInt(dateParts[2], 10);

  if (year < 1000) return false;

  const date = new Date(year, month, day);

  // Check if the date components match the input and the date is valid
  return date.getDate() === day && date.getMonth() === month && date.getFullYear() === year;
}
