import { useParams } from 'react-router-dom';
import type {
  FrontLCAComponent,
  FrontLCAMethodology,
  LCAFoldersResponse
} from '../types/LCADetail';
import { useLCADetail } from '../hooks/useLCADetail';
import { PageParams } from '../types/PageParams';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { LCA_IMPACT_CATEGORIES_TRANSLATION_KEYS } from '../../constants';
import { LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS } from '../constants';
import { isKey } from '../../../../../utils/isKey';

export const usePhase = (
  methodology: FrontLCAMethodology | undefined,
  lcaFolder: keyof LCAFoldersResponse | undefined,
  lcaComponentBeingUsed: FrontLCAComponent | undefined
) => {
  const { id } = useParams<PageParams>();

  const { t } = useLCATranslation();

  const { data: lcaMetaData } = useLCADetail(id);

  if (
    lcaComponentBeingUsed &&
    isKey(lcaComponentBeingUsed.name, LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS)
  ) {
    return t(LCA_ENVIRONMENTAL_IMPACT_STAGES_TRANSLATIONS[lcaComponentBeingUsed.name]);
  }

  if (lcaComponentBeingUsed) {
    return lcaComponentBeingUsed.name;
  }

  if (lcaFolder) {
    return t(`lcaDetail.${lcaFolder}`);
  }

  if (methodology) {
    return t(LCA_IMPACT_CATEGORIES_TRANSLATION_KEYS[methodology.impact_category]);
  }

  if (lcaMetaData) {
    return lcaMetaData.name;
  }

  return '';
};
