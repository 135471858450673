import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import { TransportOptions } from '../../../../../../types/entitiesEnums/employeePeriod';
import Button from '../../../../../ui/button/Button';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';
import { ID_ORGS_PRIVACY_COMMUNICATION } from '../stepInit/privacyCommunication/constants';
import Line from './Line';
import './styles.scss';

function StepSummary() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { formData, stepCounter, handleBack, handleSubmit, errorSubmit } =
    useContext(EmployeeFormContext);

  const [loading, setLoading] = useState(false);

  const handleClickSubmit = async () => {
    if (!handleSubmit) return;
    setLoading(true);
    await handleSubmit();
    setLoading(false);
  };

  if (!handleBack) return null;

  const privacyField = ID_ORGS_PRIVACY_COMMUNICATION.map((elem) => elem.id.includes(id ?? ''));

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle3')}
      subtitle={t('employeeForm.formSubtitle4')}>
      <StepWrapper title={t('employeeForm.summary')} stepNumber={stepCounter} width={500}>
        <div className='summary-steps-container'>
          {formData.name && (
            <Line
              title={
                privacyField ? t('employeeForm.privacyFieldAludec') : t('employeeForm.nameSummary')
              }
              text={formData.name}
            />
          )}
          <Line
            title={t('employeeForm.summaryData.dateTitle')}
            text={`${formData.startDate} - ${formData?.endDate || t('employees.now')}`}
          />
          {formData.telecommuting.id && (
            <Line
              title={t('employeeForm.summaryData.workModelTitle')}
              text={formData.telecommuting.name}
            />
          )}

          {formData.weeklyTravels && (
            <Line
              title={t('employeeForm.summaryData.weeklyTravelsTitle')}
              text={formData.weeklyTravels}
            />
          )}
          {formData.dailyTrips && (
            <Line
              title={t('employeeForm.summaryData.dailyTripsTitle')}
              text={formData.dailyTrips}
            />
          )}
          {formData.totalKm && (
            <Line title={t('employeeForm.summaryData.totalKmTitle')} text={formData.totalKm} />
          )}
          {Boolean(formData.transport.id) && formData.transport.id !== 'telecommuting' && (
            <Line
              title={t('employeeForm.summaryData.transportTitle')}
              text={`${formData.transport.name} ${formData.fuelType.name}`}
            />
          )}

          {formData.transport.id === TransportOptions.CAR && (
            <Line
              title={t('employeeForm.summaryData.carpoolingTitle')}
              text={formData.carpooling.name}
            />
          )}
        </div>

        <Button
          lookAndFeel={'primary'}
          onClick={handleClickSubmit}
          text={loading ? t('employeeForm.sending') : t('employeeForm.send')}
          loading={loading}
        />
        {errorSubmit && (
          <span className='error-text error-font error-text-color'>{errorSubmit.description}</span>
        )}
      </StepWrapper>

      <SwitchStep handleBack={handleBack} />
    </StepControllerContainer>
  );
}

export default StepSummary;
