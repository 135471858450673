import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { setNotification } from '../../../actions/notification';
import { useTranslation } from 'react-i18next';

export const useUnbindModal = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const org = useSelectedOrganization();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onUnbind = () => {
    queryClient.invalidateQueries({ queryKey: ['orgParents', org?.id] });

    dispatch(setNotification(t('notification.unbindHolding')));

    setIsOpen(false);
  };

  return { isOpen, setIsOpen, openModal, closeModal, onUnbind };
};
