import { getUnknownVehicles } from 'services/api/vehicle';
import { useQuery } from '@tanstack/react-query';

export const useVehiclesTypes = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['unknownVehicles'],
    queryFn: () => getUnknownVehicles()
  });

  return { data: data ?? [], isLoading };
};
