import type { ActiveElement, Chart, ChartEvent, Plugin } from 'chart.js';
import { LCA_DISABLED_COLOR_PALETTE } from './constants';

export const centerTextDoughnut: Plugin<
  'doughnut',
  { text: string; subText?: string; disabled?: boolean }
> = {
  id: 'centerTextDoughnut',
  afterDatasetsDraw: (chart: Chart, _args, options) => {
    const HALF_REM = 8;
    const ctx = chart.ctx;
    const textX = chart.getDatasetMeta(0).data[0].x;
    const textY = chart.getDatasetMeta(0).data[0].y - HALF_REM;

    const elementWidth = chart.width * 0.5;

    ctx.restore();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const fontSize = 3;

    ctx.font = `normal 600 ${fontSize}rem Inter`;

    const linearGradient = ctx.createLinearGradient(
      0,
      textY - fontSize * HALF_REM,
      0,
      textY + fontSize * HALF_REM
    );

    linearGradient.addColorStop(0, '#2945FF');
    linearGradient.addColorStop(1, '#38CFD9E8');

    ctx.fillStyle = linearGradient;
    ctx.textBaseline = 'middle';

    if (options.disabled) {
      ctx.fillStyle = LCA_DISABLED_COLOR_PALETTE[0];
    }

    ctx.fillText(options.text, textX, textY);

    ctx.save();

    if (options.subText === undefined) {
      return;
    }

    const subTextFontSize = options.disabled ? 1.25 : 2;

    ctx.font = `normal 400 ${subTextFontSize}rem Inter`;

    const words = options.subText.split(' ');
    let line = '';
    const lines = [];

    const sizeToCheck = elementWidth > HALF_REM * 40 ? HALF_REM * 40 : elementWidth;

    for (let index = 0; index < words.length; index++) {
      const testLine = line + words[index] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > sizeToCheck && index > 0) {
        lines.push(line);
        line = words[index] + ' ';
      } else {
        line = testLine;
      }
    }

    if (line.length > 0) {
      lines.push(line);
    }

    ctx.fillStyle = '#192A3ECC';

    for (let index = 0; index < lines.length; index++) {
      ctx.fillText(
        lines[index],
        textX,
        textY + fontSize * HALF_REM * 2 + index * subTextFontSize * 16
      );
    }

    ctx.save();
  }
};

export const overlappingSections: Plugin<'doughnut'> = {
  id: 'overlappingSections',
  afterDatasetsDraw(chart) {
    const { ctx } = chart;

    const x = chart.getDatasetMeta(0).data[0].x;
    const y = chart.getDatasetMeta(0).data[0].y;
    const angle = Math.PI / 180;

    chart.getDatasetMeta(0).data.forEach((_, index) => {
      const dataAny = chart.getDatasetMeta(0).data[index] as any;
      const innerRadius = dataAny.innerRadius as number;
      const outerRadius = dataAny.outerRadius as number;
      const endAngle = dataAny.endAngle as number;

      const radius = (outerRadius - innerRadius) / 2;

      const coords = [];

      for (let i = -0.08; i < 0.08; i += 0.01) {
        const xCoord = (innerRadius + radius) * Math.cos(endAngle + i);
        const yCoord = (innerRadius + radius) * Math.sin(endAngle + i);

        coords.push({ x: xCoord, y: yCoord });
      }

      ctx.save();
      ctx.fillStyle = dataAny.options.backgroundColor ?? 'brown';
      ctx.translate(x, y);
      ctx.beginPath();
      coords.forEach(({ x, y }) => {
        ctx.arc(x, y, radius, 0, angle * 360, false);
      });
      ctx.fill();
      ctx.restore();
    });
  }
};

export const getDoughnutSectionIndex = (elements: ActiveElement[]) => {
  if (elements.length === 0) {
    return;
  }

  const { index } = elements[0];

  return index;
};

export const onHover = (_event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
  chart.canvas.style.cursor = elements.length > 0 ? 'pointer' : 'default';
};

export const customRoundedBgColorDoughnut: Plugin<
  'doughnut',
  { color: string | CanvasGradient | CanvasPattern }
> = {
  id: 'customRoundedBgColorDoughnut',
  beforeDatasetDraw: (chart, args, options) => {
    const { ctx } = chart;
    const { x, y, outerRadius } = chart.getDatasetMeta(0).data[0] as any;
    const { color } = options;

    ctx.save();
    ctx.fillStyle = color;

    ctx.beginPath();
    ctx.arc(x, y, outerRadius + 25, 0, Math.PI * 2);
    ctx.fill();

    ctx.restore();
  }
};
