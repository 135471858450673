const NotFound = () => {
  return (
    <>
      <h1 className=''>Page Not Found</h1>
      <p className=''>Sorry, this page does not exist.</p>
    </>
  );
};

export default NotFound;
