import CompaniesGroupFunds from './groupFunds/companiesGroupFunds/CompaniesGroupFunds';
import CompaniesHolding from './holding/companiesHolding/CompaniesHolding';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import CompaniesWrapper from './common/companiesWrapper/CompaniesWrapper';
import { useSearchParams } from 'react-router-dom';
import ParentCompanies from './parentCompanies/ParentCompanies';

const Companies = () => {
  const foundOrganization = useSelectedOrganization();
  const [searchParams, setSearchParams] = useSearchParams();

  if (!foundOrganization?.is_group_fund) {
    return (
      <CompaniesWrapper>
        {searchParams.get('type') === 'parents' ? <ParentCompanies /> : <CompaniesHolding />}
      </CompaniesWrapper>
    );
  }
  return (
    <CompaniesWrapper>
      <CompaniesGroupFunds />
    </CompaniesWrapper>
  );
};

export default Companies;
