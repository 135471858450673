import React, { useEffect, useState } from 'react';
import { getUnits, getUnitsByCountry } from '../../../../../../../services/api/unit';
import { useFeatureFlags } from '../../../../../../../customHooks/useFeatureFlags';

type Props = {
  type: string;
  country: string;
};
const useGetUnits = ({ type, country }: Props) => {
  const [units, setUnits] = useState<SelectOptionFormat[]>([]);

  const flags = useFeatureFlags();
  // get exactly cubic_metre_(m3) and kilowatt_hour_(kwh) (should be refactor)
  useEffect(() => {
    const getUnitWater = async () => {
      if (type !== 'water') return;
      const data = await getUnits({ type: 'gas' });
      if (data?.response?.status >= 400) return;
      setUnits(data.filter((elem: Unit) => elem.name === 'cubic_metre_(m3)'));
    };

    const getUnitElectricity = async () => {
      if (type !== 'electricity') return;
      const dataElectricity = await getUnits({ type: 'energy' });
      // Call endpoint that returns units of currency given a country
      const dataCurrency = await getUnitsByCountry(country);
      if (dataElectricity?.response?.status >= 400) return;
      const data = [...dataElectricity];
      if (dataCurrency && dataCurrency.id) {
        data.push(dataCurrency);
      }

      const units = data.filter(
        (elem: Unit) =>
          elem.name === 'kilowatt_hour_(kwh)' ||
          (flags?.eurosInInvoices && elem.type === 'fiat_currency')
      );

      setUnits(units);
    };

    getUnitWater();
    getUnitElectricity();
  }, [type]);

  return { units, setUnits };
};

export default useGetUnits;
