import { useState } from 'react';

import { Mode } from '../../../../types/entitiesEnums/esgMetrics';
import {
  getCurrentYear,
  getPreviousYear,
  getUnit,
  parseImpactFacilityAndOrgData
} from '../utils/utils';
import { useGetEsgMetrics } from './useGetEsgMetrics';

const usePreviusYearComparison = () => {
  const previousYear = getPreviousYear();
  const currentYear = getCurrentYear();

  const [selects, setSelects] = useState({
    previusYearComparisonIntensityMetric: { id: '', name: '' },
    previusYearComparisonESGmetric: { id: '', name: '' },
    mode: Mode.ABSOLUT
  });

  const { previusYearComparisonIntensityMetric, previusYearComparisonESGmetric, mode } = selects;

  const onChangeValue = function (attribute: keyof typeof selects) {
    return function changeValue(value: SelectOptionFormat<string>) {
      setSelects((prev) => ({
        ...prev,
        [attribute]: value,
        mode: Mode.INTENSITY
      }));
    };
  };

  const { data: previusYearComparisonData, isLoading: isLoadingPreviusYearComparison } =
    useGetEsgMetrics({
      intensityMetric:
        previusYearComparisonIntensityMetric.id !== ''
          ? previusYearComparisonIntensityMetric.id
          : null,
      dateRanges: [
        [`${previousYear}/01/01`, `${previousYear}/12/31`],
        [`${currentYear}/01/01`, `${currentYear}/12/31`]
      ],
      mode: mode
    });

  const impactFacilityAndOrgData = parseImpactFacilityAndOrgData({
    data: previusYearComparisonData?.parsedData,
    valueCategoryChartId: previusYearComparisonESGmetric.id
  });

  const unit = getUnit({
    data: previusYearComparisonData?.parsedData,
    valueCategoryChartId: previusYearComparisonESGmetric.id
  });

  return {
    previusYearComparisonData,
    impactFacilityAndOrgData,
    isLoadingPreviusYearComparison,
    previusYearComparisonIntensityMetric,
    previusYearComparisonESGmetric,
    setPreviusYearComparisonESGmetric: onChangeValue('previusYearComparisonESGmetric'),
    setPreviusYearComparisonIntensityMetric: onChangeValue('previusYearComparisonIntensityMetric'),
    unit
  };
};

export default usePreviusYearComparison;
