import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteFacility } from '../../../../services/api/facility';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import ErrorText from '../../../ui/errorText/ErrorText';

type Props = {
  removeFacility: (id: string) => void;
  closeModal: () => void;
  facilityToDelete: string;
};
function DeleteFacility({ facilityToDelete, removeFacility, closeModal }: Props) {
  const { t } = useTranslation();

  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState<ErrorType>();
  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteFacility();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleDeleteFacility = async () => {
    setError(undefined);
    setLoadingButton(true);
    const deletedFacility = await deleteFacility(facilityToDelete);
    if (!deletedFacility) {
      setError({
        error: 'general',
        description: t('error.somethingWentWrong')
      });
    }
    removeFacility(facilityToDelete);
  };

  return (
    <>
      <FormHeader
        title={t('facilities.deleteFacility')}
        description={t('facilities.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='secondary'
          text={t('general.cancel')}
          onClick={closeModal}
          size='medium'
        />
        <Button
          lookAndFeel='warning'
          text={t('facilities.delete', { type: t('general.facility') })}
          onClick={handleDeleteFacility}
          loading={loadingButton}
          size='medium'
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
}

export default DeleteFacility;
