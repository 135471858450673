import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removeOrganizationFromUser, switchOrganization } from '../../../../../actions/auth';
import { setNotification } from '../../../../../actions/notification';
import { UserContext } from '../../../../../context/userContext';
import apiFetch from '../../../../../utils/apiFetch';
import ButtonCopyBoard from '../../../../ui/buttonCopyBoard/ButtonCopyBoard';
import ButtonDropdown from '../../../../ui/buttonDropdown/ButtonDropdown';
import Modal from '../../../../ui/modal/Modal';
import ConfigureCompanyGroupFunds from '../configureCompanyGroupFunds/ConfigureCompanyGroupFunds';
import './styles.scss';
import CardList from '../../../../ui/cardList/CardListRefactored';
import CardsFilter from '../../../../ui/filter/CardsFilter';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import Card from '../../../../ui/cards/card/Card';
import ErrorLabel from '../../../../ui/statusLabels/errorLabel/ErrorLabel';
import Icon from '../../../../ui/icon/Icon';
import { replaceStrongTags } from '../../../../../utils/stringToBold';
import { GroupFunds } from '../../../../../types/entities/groupFunds';
import { InputSize } from '../../../../../types/utilsEnums/input';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/routes';
import { limitString } from '../../../../../utils/limitString';
import getCountryName from '../../../../../utils/getCountryName';
import RequestAccess from './requestAccess/RequestAccess';
import Divest from './divest/Divest';
import Button from '../../../../ui/button/Button';
import Label from '../../../../ui/label/Label';
import DeletePending from './deletePending/DeletePending';

const CompaniesGroupFunds = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const user = useContext(UserContext);

  const foundOrgHolding = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  if (!foundOrgHolding || !user?.selectedOrganization || foundOrgHolding.role !== 'owner') {
    return null;
  }

  const dispatch = useDispatch();

  const tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [companies, setCompanies] = useState<GroupFunds[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [companyToConfigure, setCompanyToConfigure] = useState<GroupFunds>();
  const [requestToDelete, setRequestToDelete] = useState<GroupFunds>();
  const [companyToDivest, setCompanyToDivest] = useState<GroupFunds>();
  const [companyToRequestAccess, setCompanyToRequestAccess] = useState<GroupFunds>();
  const [listView, setListView] = useState(false);
  const [showDivestedCompanies, setShowDivestedCompanies] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const url = `/matrices/groups_funds/${user?.selectedOrganization}/status`;
      const response = await apiFetch('GET', url, null, {
        'x-organization-id': user?.selectedOrganization
      });
      setCompanies(response.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderCompanyName = (name: string) => {
    return <span className='ellipsis'>{`${name}` || '-'}</span>;
  };

  const getDropdownOptions = (company: GroupFunds) => {
    if (company.status === 'pending') {
      return [
        {
          id: `${company.child_name}-link`,
          name: t('companies.link'),
          onClick: () => {
            setCompanyToConfigure(company);
          }
        },
        {
          id: `${company.child_name}-delete`,
          name: t('companies.delete'),
          onClick: () => {
            setRequestToDelete(company);
          }
        }
      ];
    } else if (company.status === 'accepted') {
      const options = [
        {
          id: `${company.child_name}-delete`,
          name: t('companies.divest'),
          onClick: () => {
            setCompanyToDivest(company);
          }
        }
      ];
      if (company.role !== 'fund') {
        options.unshift({
          id: `${company.child_name}-requestAccess`,
          name: t('companies.requestAccess'),
          onClick: () => {
            setCompanyToRequestAccess(company);
          }
        });
      } else {
        // Push as first element in options
        options.unshift({
          id: `${company.child_name}-access`,
          name: t('companies.access'),
          onClick: async () => {
            await dispatch(switchOrganization(company.child_id));
            navigate(ROUTES.IMPROVE_DASHBOARD);
            window.location.reload();
          }
        });
      }
      return options;
    }
    return [];
  };

  const handleCloseConfigureCompanyModal = () => {
    setCompanyToConfigure(undefined);
  };

  const handleCloseDeleteRequestModal = () => {
    setRequestToDelete(undefined);
    handleCloseConfigureCompanyModal();
  };

  const handleCloseDivestCompanyModal = () => {
    setCompanyToDivest(undefined);
  };

  const updateCompanyStatus = (id: string, childCompany: GroupFunds) => {
    const companiesOld = [...companies];
    const index = companiesOld.findIndex((element) => element.child_id === id);
    if (index < 0) return;
    companiesOld[index] = {
      ...companiesOld[index],
      child_name: childCompany.child_name,
      status: childCompany.status,
      enterprise_value: childCompany.enterprise_value,
      data_progress: childCompany.data_progress
    };
    setCompanies(companiesOld);
    handleCloseConfigureCompanyModal();
    dispatch(setNotification(t('notification.companyConfigured')));
  };

  const divestCompany = (id: string) => {
    const companiesOld = [...companies];
    const index = companiesOld.findIndex((element) => element.child_id === id);
    if (index < 0) return;
    companiesOld[index].status = 'disinvested';
    setCompanies(companiesOld);
    handleCloseDivestCompanyModal();
    handleCloseConfigureCompanyModal();
    const userOrganizations = user?.organizations;
    const foundOrg = userOrganizations?.find((organization) => organization.id === id);
    if (foundOrg && foundOrg.role === 'fund') {
      dispatch(removeOrganizationFromUser(id));
    }
    dispatch(setNotification(t('notification.companyDivested')));
  };

  const deleteRequest = (id: string) => {
    const companiesOld = [...companies];
    const index = companiesOld.findIndex((element) => element.child_id === id);
    companiesOld.splice(index, 1);
    setCompanies(companiesOld);
    handleCloseDeleteRequestModal();
    dispatch(setNotification(t('notification.requestDeleted')));
  };

  if (!user?.selectedOrganization) return null;

  const foundHolding = user?.organizations?.find(
    (organization) => organization.id === user?.selectedOrganization
  );

  if (!foundHolding) return null;

  // Filter function to check if child org name matches searchValue state variable, using useCallback to avoid unnecessary re-renders, and memoize the function
  const filterCompanies = useCallback(
    (company: GroupFunds) => {
      const includesName = company.child_name
        ? company.child_name.toLowerCase().includes(searchValue.toLowerCase())
        : false;
      const inRange = true;

      return includesName && inRange;
    },
    [searchValue]
  );

  const filteredCompanies = useMemo(() => {
    return companies.filter(filterCompanies);
  }, [companies, filterCompanies]);

  return (
    <>
      <div className='facilities__subheader'>
        <CardsFilter listView={listView} onChangeListView={() => setListView(!listView)}>
          <FormText
            icon={'/images/icons/search.svg'}
            placeholder={t('facilities.searchPlaceholder')}
            onChange={(e: { target: { value: string } }) => setSearchValue(e.target.value)}
            value={searchValue}
            size={InputSize.SMALL}
          />
        </CardsFilter>
        <div className='main-bg-color card-border-color legend-wrapper'>
          <Button
            lookAndFeel={showDivestedCompanies ? 'primary' : 'secondary'}
            text={t('companies.divestedCompanies')}
            size='small'
            iconNode={
              showDivestedCompanies ? (
                <Icon icon='archive' color='white' />
              ) : (
                <Icon icon='archive' color='gray-dark' />
              )
            }
            onClick={() => setShowDivestedCompanies(!showDivestedCompanies)}
          />
          <TotalLegend total={filteredCompanies.length} loading={loading} i18key={'companies'} />
        </div>
      </div>
      <CardList
        listView={listView}
        title={t('companies.start')}
        description={t('companies.startDescriptionGroupFunds')}
        buttons={
          <ButtonCopyBoard
            lookAndFeel='primary'
            text={
              <div className='flex' style={{ gap: '0.25rem', alignItems: 'center' }}>
                <Icon icon={'copy'} color={'white'} />
                {`${t('companies.copyId')} ${limitString(foundOrgHolding.vat, 8)}`}
              </div>
            }
            textToCopy={foundOrgHolding.vat}
            size='small'
          />
        }
        loading={loading}>
        {filteredCompanies
          ?.sort((a, b) => {
            // first the ones with status === "pending"
            if (a.status === 'pending' && b.status !== 'pending') return -1;
            else if (a.status !== 'pending' && b.status === 'pending') return 1;
            // finally the other ones
            else {
              // order by name
              if (a.child_name < b.child_name) return -1;
              else if (a.child_name > b.child_name) return 1;
              else return 0;
            }
          })
          .filter((elem) =>
            showDivestedCompanies ? elem.status === 'disinvested' : elem.status !== 'disinvested'
          )
          .map((elem) => {
            const dropdownOptions = getDropdownOptions(elem);

            const commonContent = (
              <>
                <Card.Icon
                  icon='/images/icons/organization.svg'
                  alt={elem.child_name}
                  style={{ opacity: elem.status === 'pending' ? 0.5 : 1 }}
                />
                <span
                  className='headline4-font'
                  style={{ opacity: elem.status === 'pending' ? 0.5 : 1 }}>
                  {renderCompanyName(elem.child_name)}
                </span>
                {elem.status !== 'pending' && (
                  <span className='subtitle3-font'>{`${getCountryName(
                    elem.country,
                    i18n.resolvedLanguage
                  )} - ${elem.enterprise_value} €`}</span>
                )}
                {elem.status === 'pending' && (
                  <ErrorLabel
                    onClick={(event) => {
                      event.stopPropagation();
                      setCompanyToConfigure(elem);
                    }}>
                    {replaceStrongTags(t('companies.linkHere'))}
                  </ErrorLabel>
                )}
                {elem.status === 'disinvested' && (
                  <Label lookAndFeel='disabled'>{t('companies.divested')}</Label>
                )}
              </>
            );
            return (
              <Card
                key={elem.child_id}
                listView={listView}
                onClick={() => {
                  if (elem.status === 'pending') {
                    setCompanyToConfigure(elem);
                    return;
                  }
                  // Navigate to company detail to see the investments
                  navigate(
                    ROUTES.MEASURE_COMPANIES +
                      '/' +
                      elem.child_id +
                      '?name=' +
                      elem.child_name +
                      '&role=' +
                      elem.role
                  );
                }}>
                {elem.status !== 'disinvested' && (
                  <Card.Options>
                    <ButtonDropdown
                      button={<Icon icon='elipsis_horizontal' color={'gray-dark'} />}
                      options={dropdownOptions}></ButtonDropdown>
                  </Card.Options>
                )}

                {elem.status === 'disinvested' ? (
                  <Card.ContentDisabled>{commonContent}</Card.ContentDisabled>
                ) : (
                  <Card.Content>{commonContent}</Card.Content>
                )}
              </Card>
            );
          })}
      </CardList>
      <Modal.WithPortal
        show={!!companyToConfigure}
        onClose={handleCloseConfigureCompanyModal}
        width='600px'
        maxWidth='600px'>
        {companyToConfigure && (
          <ConfigureCompanyGroupFunds
            company={companyToConfigure}
            handleOpenDeletePending={(company: GroupFunds) => setRequestToDelete(company)}
            updateCompanyStatus={updateCompanyStatus}
          />
        )}
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!requestToDelete}
        onClose={handleCloseDeleteRequestModal}
        width='500px'
        maxWidth='500px'>
        {requestToDelete && (
          <DeletePending
            childId={requestToDelete?.child_id}
            parentId={user?.selectedOrganization ?? ''}
            idToRemove={requestToDelete?.child_id}
            nameToRemove={requestToDelete?.child_name}
            deleteRequest={deleteRequest}
            handleClose={handleCloseDeleteRequestModal}
          />
        )}
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!companyToDivest}
        onClose={handleCloseDivestCompanyModal}
        width='584px'
        maxWidth='584px'>
        {companyToDivest && (
          <Divest
            companyToDivest={companyToDivest}
            divestCompany={divestCompany}
            handleClose={handleCloseDivestCompanyModal}
          />
        )}
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!companyToRequestAccess}
        onClose={() => setCompanyToRequestAccess(undefined)}
        width='584px'
        maxWidth='584px'>
        {companyToRequestAccess && (
          <RequestAccess
            company={companyToRequestAccess}
            handleClose={() => setCompanyToRequestAccess(undefined)}
          />
        )}
      </Modal.WithPortal>
    </>
  );
};

export default CompaniesGroupFunds;
