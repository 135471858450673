import { FormFundData, FundPostType } from '../../types/entities/funds';
import apiFetch from '../../utils/apiFetch';
import { convertDateFrontToBack } from '../../utils/convertDates';
import { generateQueryParamsFromObject, getUrl } from '../../utils/url';

export const postFund = async (formData: FormFundData) => {
  try {
    const data: FundPostType = {
      fund_name: formData.name,
      funds_total_value: formData.size,
      funds_type: formData.article.id,
      pai_extra_questions: formData.questions?.map((question) => question.id) ?? []
    };

    const response = await apiFetch('POST', '/funds', data);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteFund = async (id: string) => {
  try {
    const response = await apiFetch('DELETE', `/funds/${id}`, null);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFund = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/funds/${id}`, null);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFundList = async () => {
  try {
    const response = await apiFetch('GET', `/funds/list_by_organization?page=1&size=50`, null);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const patchFund = async (id: string, formData: FormFundData) => {
  try {
    const data: FundPostType = {
      fund_name: formData.name,
      funds_total_value: formData.size,
      funds_type: formData.article.id,
      pai_extra_questions: formData.questions?.map((question) => question.id) ?? []
    };

    const response = await apiFetch('PATCH', `/funds/${id}`, data);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPAIReportJSON = async (id: string, year: string) => {
  try {
    const url = getUrl(`/funds/report/${id}/${year}/json`, {
      queryParams: generateQueryParamsFromObject({ switch: 'total' })
    });

    const response = await apiFetch('GET', url);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPAIReport = async (id: string, year: string) => {
  try {
    await apiFetch('GET', `/funds/report/${id}/${year}`);
    return {
      success: true
    };
  } catch (error: any) {
    if (error.response.data === 'EMPTY_FUND_FOR_THIS_YEAR') {
      return {
        error: 'EMPTY_FUND_FOR_THIS_YEAR'
      };
    }
    return {
      error: 'RETRY_LATER'
    };
  }
};

export const getFundsQuestions = async () => {
  try {
    const response = await apiFetch('GET', `/pai_extra_questions`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const requestPIASReportPDF = async (id: string) => {
  try {
    const response = await apiFetch('POST', `/funds/${id}/report/pdf`);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getCompaniesFromFundPaginated = async (
  id: string,
  page: number,
  size: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterBy: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortBy: any
) => {
  try {
    const response = await apiFetch('GET', `/funds/list_by_fund/${id}`, null, null, {
      page,
      size,
      filter_by: filterBy,
      sort_by: sortBy
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFundsRelationsHistoric = async (id: string, page: number, size: number) => {
  try {
    const response = await apiFetch('GET', `/funds_relations_historic/company/${id}`, null, null, {
      page,
      size
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getLatestFundInvestmentByCompany = async (id: string, fundId?: string) => {
  try {
    const queryParams = fundId
      ? {
          fund_id: fundId
        }
      : {};
    const response = await apiFetch(
      'GET',
      `/funds_relations/latest/${id}`,
      null,
      null,
      queryParams
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export const divestFundRelation = async (
  id: string,
  parent_id: string,
  investment_date: string
) => {
  try {
    const response = await apiFetch('POST', `/funds_relations/disinvest`, {
      child_id: id,
      parent_id,
      investment_date: convertDateFrontToBack(investment_date)
    });

    return response.data;
  } catch (error) {
    return error;
  }
};
