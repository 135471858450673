import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../actions/notification';
import Button from '../button/Button';

type ButtonProps = React.ComponentProps<typeof Button>;
type ButtonPropsWithOutOnClick = Omit<ButtonProps, 'onClick'>;
type NewButtonProps = {
  textToCopy: string;
};

type Props = NewButtonProps & ButtonPropsWithOutOnClick;

const ButtonCopyBoard = ({ textToCopy, ...props }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickButton = () => {
    navigator.clipboard.writeText(textToCopy);
    dispatch(setNotification(t('notification.copyOnBoard')));
  };
  return <Button {...props} onClick={onClickButton} />;
};

export default ButtonCopyBoard;
