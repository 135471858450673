import { useTranslation } from 'react-i18next';
import { MORE_INFO_OAXACA } from '../../../../../constants';
import Button from '../../../../ui/button/Button';

type Props = {
  handleAskForInformation: () => void;
  loadingButton: boolean;
};

const ModalProject2 = ({ handleAskForInformation, loadingButton }: Props) => {
  const { t } = useTranslation();
  const redirectUrl = MORE_INFO_OAXACA;
  const handleRedirect = () => window.open(redirectUrl);

  return (
    <div className='first-row' style={{ padding: '1rem' }}>
      <div className='text-wrapper-gap-card'>
        <span className='headline2-font'>{t('offset.text.modal2.title')}</span>
        <h2 className='span-font' style={{ margin: 0 }}>
          {t('offset.text.modal2.subtitle')}
        </h2>

        <div className='list-wrapper-offset'>
          <div className='element-offset span-font'>
            <img
              className='image-checked'
              src='/images/icons/tickGradientCircle.svg'
              alt='checked'
            />
            <span>{t('offset.text.modal2.span1')}</span>
          </div>

          <div className='element-offset span-font'>
            <img
              className='image-checked'
              src='/images/icons/tickGradientCircle.svg'
              alt='checked'
            />
            <span>{t('offset.text.modal2.span2')}</span>
          </div>

          <div className='element-offset span-font'>
            <img
              className='image-checked'
              src='/images/icons/tickGradientCircle.svg'
              alt='checked'
            />
            <span>{t('offset.text.modal2.span3')}</span>
          </div>
        </div>
        <div className='button-wrapper-modal'>
          <Button
            lookAndFeel={'primary'}
            size='medium'
            text={t('offset.text.modal2.button')}
            onClick={handleAskForInformation}
            loading={loadingButton}
          />
          <Button
            lookAndFeel={'secondary'}
            size='medium'
            text={t('offset.text.button1')}
            onClick={handleRedirect}
          />
        </div>
      </div>
      <img
        style={{ width: '100%', marginTop: '2rem' }}
        className='image-offseting'
        src={'/images/offset/santaMariaProjectInfo.svg'}
        alt='offset'
      />
    </div>
  );
};

export default ModalProject2;
