import { useContext } from 'react';
import { UserContext } from '../../../../../../context/userContext';
import { getPurchases } from '../../../../../../services/api/purchases';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useTotal = () => {
  const user = useContext(UserContext);

  const queryClient = useQueryClient();

  const orgId = user?.organizations?.find((org) => org.id === user.selectedOrganization)?.id;

  const { data, isFetching } = useQuery({
    queryKey: ['purchasesTotal', orgId],
    queryFn: () => getPurchases()
  });

  const setTotal = (total: ((prev: number) => number) | number) => {
    if (typeof total === 'function') {
      queryClient.setQueryData(['purchasesTotal', orgId], (prev: typeof data) => {
        return {
          ...prev,
          total: total(prev?.total ?? 0)
        };
      });
      return;
    }

    queryClient.setQueryData(['purchasesTotal', orgId], (prev: typeof data) => {
      return {
        ...prev,
        total
      };
    });
  };

  return {
    total: data?.total,
    loading: isFetching,
    setTotal
  };
};
