const prefixes = [
  {
    name: { es: 'Afganistán', en: 'Afghanistan', fr: 'Afghanistan', pt: 'Afeganistão' },
    dial_code: '+93',
    code: 'AF',
    code2: 'AFG'
  },
  {
    name: { es: 'Albania', en: 'Albania', fr: 'Albanie', pt: 'Albânia' },
    dial_code: '+355',
    code: 'AL',
    code2: 'ALB'
  },
  {
    name: { es: 'Alemania', en: 'Germany', fr: 'Allemagne', pt: 'Alemanha' },
    dial_code: '+49',
    code: 'DE',
    code2: 'DEU'
  },
  {
    name: { es: 'Andorra', en: 'Andorra', fr: 'Andorra', pt: 'Andorra' },
    dial_code: '+376',
    code: 'AD',
    code2: 'AND'
  },
  {
    name: { es: 'Angola', en: 'Angola', fr: 'Angola', pt: 'Angola' },
    dial_code: '+244',
    code: 'AO',
    code2: 'AGO'
  },
  {
    name: { es: 'Anguila', en: 'Anguilla', fr: 'Anguilla', pt: 'Anguilla' },
    dial_code: '+1 264',
    code: 'AI',
    code2: 'AIA'
  },
  {
    name: { es: 'Antártida', en: 'Antarctica', fr: "L'Antarctique", pt: 'Antártida' },
    dial_code: '+672',
    code: 'AQ',
    code2: 'ATA'
  },
  {
    name: {
      es: 'Antigua y Barbuda',
      en: 'Antigua and Barbuda',
      fr: 'Antigua et Barbuda',
      pt: 'Antígua e Barbuda'
    },
    dial_code: '+1 268',
    code: 'AG',
    code2: 'ATG'
  },
  {
    name: { es: 'Arabia Saudita', en: 'Saudi Arabia', fr: 'Arabie Saoudite', pt: 'Arábia Saudita' },
    dial_code: '+966',
    code: 'SA',
    code2: 'SAU'
  },
  {
    name: { es: 'Argelia', en: 'Algeria', fr: 'Algérie', pt: 'Argélia' },
    dial_code: '+213',
    code: 'DZ',
    code2: 'DZA'
  },
  {
    name: { es: 'Argentina', en: 'Argentina', fr: 'Argentine', pt: 'Argentina' },
    dial_code: '+54',
    code: 'AR',
    code2: 'ARG'
  },
  {
    name: { es: 'Armenia', en: 'Armenia', fr: "L'Arménie", pt: 'Armênia' },
    dial_code: '+374',
    code: 'AM',
    code2: 'ARM'
  },
  {
    name: { es: 'Aruba', en: 'Aruba', fr: 'Aruba', pt: 'Aruba' },
    dial_code: '+297',
    code: 'AW',
    code2: 'ABW'
  },
  {
    name: { es: 'Australia', en: 'Australia', fr: 'Australie', pt: 'Austrália' },
    dial_code: '+61',
    code: 'AU',
    code2: 'AUS'
  },
  {
    name: { es: 'Austria', en: 'Austria', fr: 'Autriche', pt: 'Áustria' },
    dial_code: '+43',
    code: 'AT',
    code2: 'AUT'
  },
  {
    name: { es: 'Azerbaiyán', en: 'Azerbaijan', fr: "L'Azerbaïdjan", pt: 'Azerbaijão' },
    dial_code: '+994',
    code: 'AZ',
    code2: 'AZE'
  },
  {
    name: { es: 'Bélgica', en: 'Belgium', fr: 'Belgique', pt: 'Bélgica' },
    dial_code: '+32',
    code: 'BE',
    code2: 'BEL'
  },
  {
    name: { es: 'Bahamas', en: 'Bahamas', fr: 'Bahamas', pt: 'Bahamas' },
    dial_code: '+1 242',
    code: 'BS',
    code2: 'BHS'
  },
  {
    name: { es: 'Bahrein', en: 'Bahrain', fr: 'Bahreïn', pt: 'Bahrein' },
    dial_code: '+973',
    code: 'BH',
    code2: 'BHR'
  },
  {
    name: { es: 'Bangladesh', en: 'Bangladesh', fr: 'Bangladesh', pt: 'Bangladesh' },
    dial_code: '+880',
    code: 'BD',
    code2: 'BGD'
  },
  {
    name: { es: 'Barbados', en: 'Barbados', fr: 'Barbade', pt: 'Barbados' },
    dial_code: '+1 246',
    code: 'BB',
    code2: 'BRB'
  },
  {
    name: { es: 'Belice', en: 'Belize', fr: 'Belize', pt: 'Belize' },
    dial_code: '+501',
    code: 'BZ',
    code2: 'BLZ'
  },
  {
    name: { es: 'Benín', en: 'Benin', fr: 'Bénin', pt: 'Benin' },
    dial_code: '+229',
    code: 'BJ',
    code2: 'BEN'
  },
  {
    name: { es: 'Bhután', en: 'Bhutan', fr: 'Le Bhoutan', pt: 'Butão' },
    dial_code: '+975',
    code: 'BT',
    code2: 'BTN'
  },
  {
    name: { es: 'Bielorrusia', en: 'Belarus', fr: 'Biélorussie', pt: 'Bielorrússia' },
    dial_code: '+375',
    code: 'BY',
    code2: 'BLR'
  },
  {
    name: { es: 'Birmania', en: 'Myanmar', fr: 'Myanmar', pt: 'Mianmar' },
    dial_code: '+95',
    code: 'MM',
    code2: 'MMR'
  },
  {
    name: { es: 'Bolivia', en: 'Bolivia', fr: 'Bolivie', pt: 'Bolívia' },
    dial_code: '+591',
    code: 'BO',
    code2: 'BOL'
  },
  {
    name: {
      es: 'Bosnia y Herzegovina',
      en: 'Bosnia and Herzegovina',
      fr: 'Bosnie-Herzégovine',
      pt: 'Bósnia e Herzegovina'
    },
    dial_code: '+387',
    code: 'BA',
    code2: 'BIH'
  },
  {
    name: { es: 'Botsuana', en: 'Botswana', fr: 'Botswana', pt: 'Botswana' },
    dial_code: '+267',
    code: 'BW',
    code2: 'BWA'
  },
  {
    name: { es: 'Brasil', en: 'Brazil', fr: 'Brésil', pt: 'Brasil' },
    dial_code: '+55',
    code: 'BR',
    code2: 'BRA'
  },
  {
    name: { es: 'Brunéi', en: 'Brunei', fr: 'Brunei', pt: 'Brunei' },
    dial_code: '+673',
    code: 'BN',
    code2: 'BRN'
  },
  {
    name: { es: 'Bulgaria', en: 'Bulgaria', fr: 'Bulgarie', pt: 'Bulgária' },
    dial_code: '+359',
    code: 'BG',
    code2: 'BGR'
  },
  {
    name: { es: 'Burkina Faso', en: 'Burkina Faso', fr: 'Burkina Faso', pt: 'Burkina Faso' },
    dial_code: '+226',
    code: 'BF',
    code2: 'BFA'
  },
  {
    name: { es: 'Burundi', en: 'Burundi', fr: 'Burundi', pt: 'Burundi' },
    dial_code: '+257',
    code: 'BI',
    code2: 'BDI'
  },
  {
    name: { es: 'Cabo Verde', en: 'Cape Verde', fr: 'Cap-Vert', pt: 'Cabo Verde' },
    dial_code: '+238',
    code: 'CV',
    code2: 'CPV'
  },
  {
    name: { es: 'Camboya', en: 'Cambodia', fr: 'Cambodge', pt: 'Camboja' },
    dial_code: '+855',
    code: 'KH',
    code2: 'KHM'
  },
  {
    name: { es: 'Camerún', en: 'Cameroon', fr: 'Cameroun', pt: 'Camarões' },
    dial_code: '+237',
    code: 'CM',
    code2: 'CMR'
  },
  {
    name: { es: 'Canadá', en: 'Canada', fr: 'Canada', pt: 'canadá' },
    dial_code: '+1',
    code: 'CA',
    code2: 'CAN'
  },
  {
    name: { es: 'Chad', en: 'Chad', fr: 'Tchad', pt: 'Chade' },
    dial_code: '+235',
    code: 'TD',
    code2: 'TCD'
  },
  {
    name: { es: 'Chile', en: 'Chile', fr: 'Chili', pt: 'Chile' },
    dial_code: '+56',
    code: 'CL',
    code2: 'CHL'
  },
  {
    name: { es: 'China', en: 'China', fr: 'Chine', pt: 'China' },
    dial_code: '+86',
    code: 'CN',
    code2: 'CHN'
  },
  {
    name: { es: 'Chipre', en: 'Cyprus', fr: 'Chypre', pt: 'Chipre' },
    dial_code: '+357',
    code: 'CY',
    code2: 'CYP'
  },
  {
    name: {
      es: 'Ciudad del Vaticano',
      en: 'Vatican City State',
      fr: 'Cité du Vatican',
      pt: 'Cidade do Vaticano'
    },
    dial_code: '+39',
    code: 'VA',
    code2: 'VAT'
  },
  {
    name: { es: 'Colombia', en: 'Colombia', fr: 'Colombie', pt: 'Colômbia' },
    dial_code: '+57',
    code: 'CO',
    code2: 'COL'
  },
  {
    name: { es: 'Comoras', en: 'Comoros', fr: 'Comores', pt: 'Comores' },
    dial_code: '+269',
    code: 'KM',
    code2: 'COM'
  },
  {
    name: {
      es: 'República del Congo',
      en: 'Republic of the Congo',
      fr: 'République du Congo',
      pt: 'República do Congo'
    },
    dial_code: '+242',
    code: 'CG',
    code2: 'COG'
  },
  {
    name: {
      es: 'República Democrática del Congo',
      en: 'Democratic Republic of the Congo',
      fr: 'République démocratique du Congo',
      pt: 'República Democrática do Congo'
    },
    dial_code: '+243',
    code: 'CD',
    code2: 'COD'
  },
  {
    name: { es: 'Corea del Norte', en: 'North Korea', fr: 'Corée du Nord', pt: 'Coreia do Norte' },
    dial_code: '+850',
    code: 'KP',
    code2: 'PRK'
  },
  {
    name: { es: 'Corea del Sur', en: 'South Korea', fr: 'Corée du Sud', pt: 'Coreia do Sul' },
    dial_code: '+82',
    code: 'KR',
    code2: 'KOR'
  },
  {
    name: { es: 'Costa de Marfil', en: 'Ivory Coast', fr: "Côte-d'Ivoire", pt: 'Costa do Marfim' },
    dial_code: '+225',
    code: 'CI',
    code2: 'CIV'
  },
  {
    name: { es: 'Costa Rica', en: 'Costa Rica', fr: 'Costa Rica', pt: 'Costa Rica' },
    dial_code: '+506',
    code: 'CR',
    code2: 'CRI'
  },
  {
    name: { es: 'Croacia', en: 'Croatia', fr: 'Croatie', pt: 'Croácia' },
    dial_code: '+385',
    code: 'HR',
    code2: 'HRV'
  },
  {
    name: { es: 'Cuba', en: 'Cuba', fr: 'Cuba', pt: 'Cuba' },
    dial_code: '+53',
    code: 'CU',
    code2: 'CUB'
  },
  {
    name: { es: 'Curazao', en: 'Curaçao', fr: 'Curaçao', pt: 'Curaçao' },
    dial_code: '+5999',
    code: 'CW',
    code2: 'CWU'
  },
  {
    name: { es: 'Dinamarca', en: 'Denmark', fr: 'Danemark', pt: 'Dinamarca' },
    dial_code: '+45',
    code: 'DK',
    code2: 'DNK'
  },
  {
    name: { es: 'Dominica', en: 'Dominica', fr: 'Dominique', pt: 'Dominica' },
    dial_code: '+1 767',
    code: 'DM',
    code2: 'DMA'
  },
  {
    name: { es: 'Ecuador', en: 'Ecuador', fr: 'Equateur', pt: 'Equador' },
    dial_code: '+593',
    code: 'EC',
    code2: 'ECU'
  },
  {
    name: { es: 'Egipto', en: 'Egypt', fr: 'Egypte', pt: 'Egito' },
    dial_code: '+20',
    code: 'EG',
    code2: 'EGY'
  },
  {
    name: { es: 'El Salvador', en: 'El Salvador', fr: 'El Salvador', pt: 'El Salvador' },
    dial_code: '+503',
    code: 'SV',
    code2: 'SLV'
  },
  {
    name: {
      es: 'Emiratos Árabes Unidos',
      en: 'United Arab Emirates',
      fr: 'Emirats Arabes Unis',
      pt: 'Emirados Árabes Unidos'
    },
    dial_code: '+971',
    code: 'AE',
    code2: 'ARE'
  },
  {
    name: { es: 'Eritrea', en: 'Eritrea', fr: 'Erythrée', pt: 'Eritreia' },
    dial_code: '+291',
    code: 'ER',
    code2: 'ERI'
  },
  {
    name: { es: 'Eslovaquia', en: 'Slovakia', fr: 'Slovaquie', pt: 'Eslováquia' },
    dial_code: '+421',
    code: 'SK',
    code2: 'SVK'
  },
  {
    name: { es: 'Eslovenia', en: 'Slovenia', fr: 'Slovénie', pt: 'Eslovênia' },
    dial_code: '+386',
    code: 'SI',
    code2: 'SVN'
  },
  {
    name: { es: 'España', en: 'Spain', fr: 'Espagne', pt: 'Espanha' },
    dial_code: '+34',
    code: 'ES',
    code2: 'ESP'
  },
  {
    name: {
      es: 'Estados Unidos de América',
      en: 'United States of America',
      fr: "États-Unis d'Amérique",
      pt: 'Estados Unidos da América'
    },
    dial_code: '+1',
    code: 'US',
    code2: 'USA'
  },
  {
    name: { es: 'Estonia', en: 'Estonia', fr: "L'Estonie", pt: 'Estônia' },
    dial_code: '+372',
    code: 'EE',
    code2: 'EST'
  },
  {
    name: { es: 'Etiopía', en: 'Ethiopia', fr: 'Ethiopie', pt: 'Etiópia' },
    dial_code: '+251',
    code: 'ET',
    code2: 'ETH'
  },
  {
    name: { es: 'Filipinas', en: 'Philippines', fr: 'Philippines', pt: 'Filipinas' },
    dial_code: '+63',
    code: 'PH',
    code2: 'PHL'
  },
  {
    name: { es: 'Finlandia', en: 'Finland', fr: 'Finlande', pt: 'Finlândia' },
    dial_code: '+358',
    code: 'FI',
    code2: 'FIN'
  },
  {
    name: { es: 'Fiyi', en: 'Fiji', fr: 'Fidji', pt: 'Fiji' },
    dial_code: '+679',
    code: 'FJ',
    code2: 'FJI'
  },
  {
    name: { es: 'Francia', en: 'France', fr: 'France', pt: 'França' },
    dial_code: '+33',
    code: 'FR',
    code2: 'FRA'
  },
  {
    name: { es: 'Gabón', en: 'Gabon', fr: 'Gabon', pt: 'Gabão' },
    dial_code: '+241',
    code: 'GA',
    code2: 'GAB'
  },
  {
    name: { es: 'Gambia', en: 'Gambia', fr: 'Gambie', pt: 'Gâmbia' },
    dial_code: '+220',
    code: 'GM',
    code2: 'GMB'
  },
  {
    name: { es: 'Georgia', en: 'Georgia', fr: 'Géorgie', pt: 'Geórgia' },
    dial_code: '+995',
    code: 'GE',
    code2: 'GEO'
  },
  {
    name: { es: 'Ghana', en: 'Ghana', fr: 'Ghana', pt: 'Gana' },
    dial_code: '+233',
    code: 'GH',
    code2: 'GHA'
  },
  {
    name: { es: 'Gibraltar', en: 'Gibraltar', fr: 'Gibraltar', pt: 'Gibraltar' },
    dial_code: '+350',
    code: 'GI',
    code2: 'GIB'
  },
  {
    name: { es: 'Granada', en: 'Grenada', fr: 'Grenade', pt: 'Granada' },
    dial_code: '+1 473',
    code: 'GD',
    code2: 'GRD'
  },
  {
    name: { es: 'Grecia', en: 'Greece', fr: 'Grèce', pt: 'Grécia' },
    dial_code: '+30',
    code: 'GR',
    code2: 'GRC'
  },
  {
    name: { es: 'Groenlandia', en: 'Greenland', fr: 'Groenland', pt: 'Groenlândia' },
    dial_code: '+299',
    code: 'GL',
    code2: 'GRL'
  },
  {
    name: { es: 'Guadalupe', en: 'Guadeloupe', fr: 'Guadeloupe', pt: 'Guadalupe' },
    dial_code: '+590',
    code: 'GP',
    code2: 'GLP'
  },
  {
    name: { es: 'Guam', en: 'Guam', fr: 'Guam', pt: 'Guam' },
    dial_code: '+1 671',
    code: 'GU',
    code2: 'GUM'
  },
  {
    name: { es: 'Guatemala', en: 'Guatemala', fr: 'Guatemala', pt: 'Guatemala' },
    dial_code: '+502',
    code: 'GT',
    code2: 'GTM'
  },
  {
    name: {
      es: 'Guayana Francesa',
      en: 'French Guiana',
      fr: 'Guyane française',
      pt: 'Guiana Francesa'
    },
    dial_code: '+594',
    code: 'GF',
    code2: 'GUF'
  },
  {
    name: { es: 'Guernsey', en: 'Guernsey', fr: 'Guernesey', pt: 'Guernsey' },
    dial_code: '+44',
    code: 'GG',
    code2: 'GGY'
  },
  {
    name: { es: 'Guinea', en: 'Guinea', fr: 'Guinée', pt: 'Guiné' },
    dial_code: '+224',
    code: 'GN',
    code2: 'GIN'
  },
  {
    name: {
      es: 'Guinea Ecuatorial',
      en: 'Equatorial Guinea',
      fr: 'Guinée Equatoriale',
      pt: 'Guiné Equatorial'
    },
    dial_code: '+240',
    code: 'GQ',
    code2: 'GNQ'
  },
  {
    name: { es: 'Guinea-Bissau', en: 'Guinea-Bissau', fr: 'Guinée-Bissau', pt: 'Guiné-Bissau' },
    dial_code: '+245',
    code: 'GW',
    code2: 'GNB'
  },
  {
    name: { es: 'Guyana', en: 'Guyana', fr: 'Guyane', pt: 'Guiana' },
    dial_code: '+592',
    code: 'GY',
    code2: 'GUY'
  },
  {
    name: { es: 'Haití', en: 'Haiti', fr: 'Haïti', pt: 'Haiti' },
    dial_code: '+509',
    code: 'HT',
    code2: 'HTI'
  },
  {
    name: { es: 'Honduras', en: 'Honduras', fr: 'Honduras', pt: 'Honduras' },
    dial_code: '+504',
    code: 'HN',
    code2: 'HND'
  },
  {
    name: { es: 'Hong kong', en: 'Hong Kong', fr: 'Hong Kong', pt: 'Hong Kong' },
    dial_code: '+852',
    code: 'HK',
    code2: 'HKG'
  },
  {
    name: { es: 'Hungría', en: 'Hungary', fr: 'Hongrie', pt: 'Hungria' },
    dial_code: '+36',
    code: 'HU',
    code2: 'HUN'
  },
  {
    name: { es: 'India', en: 'India', fr: 'Inde', pt: 'Índia' },
    dial_code: '+91',
    code: 'IN',
    code2: 'IND'
  },
  {
    name: { es: 'Indonesia', en: 'Indonesia', fr: 'Indonésie', pt: 'Indonésia' },
    dial_code: '+62',
    code: 'ID',
    code2: 'IDN'
  },
  {
    name: { es: 'Irán', en: 'Iran', fr: 'Iran', pt: 'Irã' },
    dial_code: '+98',
    code: 'IR',
    code2: 'IRN'
  },
  {
    name: { es: 'Irak', en: 'Iraq', fr: 'Irak', pt: 'Iraque' },
    dial_code: '+964',
    code: 'IQ',
    code2: 'IRQ'
  },
  {
    name: { es: 'Irlanda', en: 'Ireland', fr: 'Irlande', pt: 'Irlanda' },
    dial_code: '+353',
    code: 'IE',
    code2: 'IRL'
  },
  {
    name: { es: 'Isla Bouvet', en: 'Bouvet Island', fr: 'Bouvet Island', pt: 'Ilha Bouvet' },
    dial_code: '+',
    code: 'BV',
    code2: 'BVT'
  },
  {
    name: { es: 'Isla de Man', en: 'Isle of Man', fr: 'Ile de Man', pt: 'Ilha do Homem' },
    dial_code: '+44',
    code: 'IM',
    code2: 'IMN'
  },
  {
    name: {
      es: 'Isla de Navidad',
      en: 'Christmas Island',
      fr: 'Christmas Island',
      pt: 'Ilha Christmas'
    },
    dial_code: '+61',
    code: 'CX',
    code2: 'CXR'
  },
  {
    name: { es: 'Isla Norfolk', en: 'Norfolk Island', fr: 'Île de Norfolk', pt: 'Ilha Norfolk' },
    dial_code: '+672',
    code: 'NF',
    code2: 'NFK'
  },
  {
    name: { es: 'Islandia', en: 'Iceland', fr: 'Islande', pt: 'Islândia' },
    dial_code: '+354',
    code: 'IS',
    code2: 'ISL'
  },
  {
    name: { es: 'Islas Bermudas', en: 'Bermuda Islands', fr: 'Bermudes', pt: 'Bermudas' },
    dial_code: '+1 441',
    code: 'BM',
    code2: 'BMU'
  },
  {
    name: { es: 'Islas Caimán', en: 'Cayman Islands', fr: 'Iles Caïmans', pt: 'Ilhas Cayman' },
    dial_code: '+1 345',
    code: 'KY',
    code2: 'CYM'
  },
  {
    name: {
      es: 'Islas Cocos (Keeling)',
      en: 'Cocos (Keeling) Islands',
      fr: 'Cocos (Keeling',
      pt: 'Ilhas Cocos (Keeling)'
    },
    dial_code: '+61',
    code: 'CC',
    code2: 'CCK'
  },
  {
    name: { es: 'Islas Cook', en: 'Cook Islands', fr: 'Iles Cook', pt: 'Ilhas Cook' },
    dial_code: '+682',
    code: 'CK',
    code2: 'COK'
  },
  {
    name: { es: 'Islas de Åland', en: 'Åland Islands', fr: 'Îles Åland', pt: 'Ilhas Åland' },
    dial_code: '+358',
    code: 'AX',
    code2: 'ALA'
  },
  {
    name: { es: 'Islas Feroe', en: 'Faroe Islands', fr: 'Iles Féro', pt: 'Ilhas Faroe' },
    dial_code: '+298',
    code: 'FO',
    code2: 'FRO'
  },
  {
    name: {
      es: 'Islas Georgias del Sur y Sandwich del Sur',
      en: 'South Georgia and the South Sandwich Islands',
      fr: 'Géorgie du Sud et les Îles Sandwich du Sud',
      pt: 'Ilhas Geórgia do Sul e Sandwich do Sul'
    },
    dial_code: '+500',
    code: 'GS',
    code2: 'SGS'
  },
  {
    name: {
      es: 'Islas Heard y McDonald',
      en: 'Heard Island and McDonald Islands',
      fr: 'Les îles Heard et McDonald',
      pt: 'Ilha Heard e Ilhas McDonald'
    },
    dial_code: '+61',
    code: 'HM',
    code2: 'HMD'
  },
  {
    name: { es: 'Islas Maldivas', en: 'Maldives', fr: 'Maldives', pt: 'Maldivas' },
    dial_code: '+960',
    code: 'MV',
    code2: 'MDV'
  },
  {
    name: {
      es: 'Islas Malvinas',
      en: 'Falkland Islands (Malvinas)',
      fr: 'Iles Falkland (Malvinas',
      pt: 'Ilhas Malvinas (Falkland)'
    },
    dial_code: '+500',
    code: 'FK',
    code2: 'FLK'
  },
  {
    name: {
      es: 'Islas Marianas del Norte',
      en: 'Northern Mariana Islands',
      fr: 'Iles Mariannes du Nord',
      pt: 'Ilhas Marianas do Norte'
    },
    dial_code: '+1 670',
    code: 'MP',
    code2: 'MNP'
  },
  {
    name: {
      es: 'Islas Marshall',
      en: 'Marshall Islands',
      fr: 'Iles Marshall',
      pt: 'Ilhas Marshall'
    },
    dial_code: '+692',
    code: 'MH',
    code2: 'MHL'
  },
  {
    name: {
      es: 'Islas Pitcairn',
      en: 'Pitcairn Islands',
      fr: 'Iles Pitcairn',
      pt: 'Ilhas Pitcairn'
    },
    dial_code: '+870',
    code: 'PN',
    code2: 'PCN'
  },
  {
    name: { es: 'Islas Salomón', en: 'Solomon Islands', fr: 'Iles Salomon', pt: 'Ilhas Salomão' },
    dial_code: '+677',
    code: 'SB',
    code2: 'SLB'
  },
  {
    name: {
      es: 'Islas Turcas y Caicos',
      en: 'Turks and Caicos Islands',
      fr: 'Iles Turques et Caïques',
      pt: 'Ilhas Turks e Caicos'
    },
    dial_code: '+1 649',
    code: 'TC',
    code2: 'TCA'
  },
  {
    name: {
      es: 'Islas Ultramarinas Menores de Estados Unidos',
      en: 'United States Minor Outlying Islands',
      fr: 'États-Unis Îles mineures éloignées',
      pt: 'Estados Unidos Ilhas Menores Distantes'
    },
    dial_code: '+246',
    code: 'UM',
    code2: 'UMI'
  },
  {
    name: {
      es: 'Islas Vírgenes Británicas',
      en: 'Virgin Islands',
      fr: 'Iles Vierges',
      pt: 'Ilhas Virgens'
    },
    dial_code: '+1 284',
    code: 'VG',
    code2: 'VGB'
  },
  {
    name: {
      es: 'Islas Vírgenes de los Estados Unidos',
      en: 'United States Virgin Islands',
      fr: 'Îles Vierges américaines',
      pt: 'Ilhas Virgens Americanas'
    },
    dial_code: '+1 340',
    code: 'VI',
    code2: 'VIR'
  },
  {
    name: { es: 'Israel', en: 'Israel', fr: 'Israël', pt: 'Israel' },
    dial_code: '+972',
    code: 'IL',
    code2: 'ISR'
  },
  {
    name: { es: 'Italia', en: 'Italy', fr: 'Italie', pt: 'Itália' },
    dial_code: '+39',
    code: 'IT',
    code2: 'ITA'
  },
  {
    name: { es: 'Jamaica', en: 'Jamaica', fr: 'Jamaïque', pt: 'Jamaica' },
    dial_code: '+1 876',
    code: 'JM',
    code2: 'JAM'
  },
  {
    name: { es: 'Japón', en: 'Japan', fr: 'Japon', pt: 'Japão' },
    dial_code: '+81',
    code: 'JP',
    code2: 'JPN'
  },
  {
    name: { es: 'Jersey', en: 'Jersey', fr: 'Maillot', pt: 'Jersey' },
    dial_code: '+44',
    code: 'JE',
    code2: 'JEY'
  },
  {
    name: { es: 'Jordania', en: 'Jordan', fr: 'Jordan', pt: 'Jordânia' },
    dial_code: '+962',
    code: 'JO',
    code2: 'JOR'
  },
  {
    name: { es: 'Kazajistán', en: 'Kazakhstan', fr: 'Le Kazakhstan', pt: 'Cazaquistão' },
    dial_code: '+7',
    code: 'KZ',
    code2: 'KAZ'
  },
  {
    name: { es: 'Kenia', en: 'Kenya', fr: 'Kenya', pt: 'Quênia' },
    dial_code: '+254',
    code: 'KE',
    code2: 'KEN'
  },
  {
    name: { es: 'Kirguistán', en: 'Kyrgyzstan', fr: 'Kirghizstan', pt: 'Quirguistão' },
    dial_code: '+996',
    code: 'KG',
    code2: 'KGZ'
  },
  {
    name: { es: 'Kiribati', en: 'Kiribati', fr: 'Kiribati', pt: 'Kiribati' },
    dial_code: '+686',
    code: 'KI',
    code2: 'KIR'
  },
  {
    name: { es: 'Kuwait', en: 'Kuwait', fr: 'Koweït', pt: 'Kuwait' },
    dial_code: '+965',
    code: 'KW',
    code2: 'KWT'
  },
  {
    name: { es: 'Líbano', en: 'Lebanon', fr: 'Liban', pt: 'Líbano' },
    dial_code: '+961',
    code: 'LB',
    code2: 'LBN'
  },
  {
    name: { es: 'Laos', en: 'Laos', fr: 'Laos', pt: 'Laos' },
    dial_code: '+856',
    code: 'LA',
    code2: 'LAO'
  },
  {
    name: { es: 'Lesoto', en: 'Lesotho', fr: 'Lesotho', pt: 'Lesoto' },
    dial_code: '+266',
    code: 'LS',
    code2: 'LSO'
  },
  {
    name: { es: 'Letonia', en: 'Latvia', fr: 'La Lettonie', pt: 'Letônia' },
    dial_code: '+371',
    code: 'LV',
    code2: 'LVA'
  },
  {
    name: { es: 'Liberia', en: 'Liberia', fr: 'Liberia', pt: 'Libéria' },
    dial_code: '+231',
    code: 'LR',
    code2: 'LBR'
  },
  {
    name: { es: 'Libia', en: 'Libya', fr: 'Libye', pt: 'Líbia' },
    dial_code: '+218',
    code: 'LY',
    code2: 'LBY'
  },
  {
    name: { es: 'Liechtenstein', en: 'Liechtenstein', fr: 'Liechtenstein', pt: 'Liechtenstein' },
    dial_code: '+423',
    code: 'LI',
    code2: 'LIE'
  },
  {
    name: { es: 'Lituania', en: 'Lithuania', fr: 'La Lituanie', pt: 'Lituânia' },
    dial_code: '+370',
    code: 'LT',
    code2: 'LTU'
  },
  {
    name: { es: 'Luxemburgo', en: 'Luxembourg', fr: 'Luxembourg', pt: 'Luxemburgo' },
    dial_code: '+352',
    code: 'LU',
    code2: 'LUX'
  },
  {
    name: { es: 'México', en: 'Mexico', fr: 'Mexique', pt: 'México' },
    dial_code: '+52',
    code: 'MX',
    code2: 'MEX'
  },
  {
    name: { es: 'Mónaco', en: 'Monaco', fr: 'Monaco', pt: 'Mônaco' },
    dial_code: '+377',
    code: 'MC',
    code2: 'MCO'
  },
  {
    name: { es: 'Macao', en: 'Macao', fr: 'Macao', pt: 'Macao' },
    dial_code: '+853',
    code: 'MO',
    code2: 'MAC'
  },
  {
    name: { es: 'Macedônia', en: 'Macedonia', fr: 'Macédoine', pt: 'Macedônia' },
    dial_code: '+389',
    code: 'MK',
    code2: 'MKD'
  },
  {
    name: { es: 'Madagascar', en: 'Madagascar', fr: 'Madagascar', pt: 'Madagascar' },
    dial_code: '+261',
    code: 'MG',
    code2: 'MDG'
  },
  {
    name: { es: 'Malasia', en: 'Malaysia', fr: 'Malaisie', pt: 'Malásia' },
    dial_code: '+60',
    code: 'MY',
    code2: 'MYS'
  },
  {
    name: { es: 'Malawi', en: 'Malawi', fr: 'Malawi', pt: 'Malawi' },
    dial_code: '+265',
    code: 'MW',
    code2: 'MWI'
  },
  {
    name: { es: 'Mali', en: 'Mali', fr: 'Mali', pt: 'Mali' },
    dial_code: '+223',
    code: 'ML',
    code2: 'MLI'
  },
  {
    name: { es: 'Malta', en: 'Malta', fr: 'Malte', pt: 'Malta' },
    dial_code: '+356',
    code: 'MT',
    code2: 'MLT'
  },
  {
    name: { es: 'Marruecos', en: 'Morocco', fr: 'Maroc', pt: 'Marrocos' },
    dial_code: '+212',
    code: 'MA',
    code2: 'MAR'
  },
  {
    name: { es: 'Martinica', en: 'Martinique', fr: 'Martinique', pt: 'Martinica' },
    dial_code: '+596',
    code: 'MQ',
    code2: 'MTQ'
  },
  {
    name: { es: 'Mauricio', en: 'Mauritius', fr: 'Iles Maurice', pt: 'Maurício' },
    dial_code: '+230',
    code: 'MU',
    code2: 'MUS'
  },
  {
    name: { es: 'Mauritania', en: 'Mauritania', fr: 'Mauritanie', pt: 'Mauritânia' },
    dial_code: '+222',
    code: 'MR',
    code2: 'MRT'
  },
  {
    name: { es: 'Mayotte', en: 'Mayotte', fr: 'Mayotte', pt: 'Mayotte' },
    dial_code: '+262',
    code: 'YT',
    code2: 'MYT'
  },
  {
    name: {
      es: 'Micronesia',
      en: 'Micronesia',
      fr: 'Micronesia',
      pt: 'Micronésia'
    },
    dial_code: '+691',
    code: 'FM',
    code2: 'FSM'
  },
  {
    name: { es: 'Moldavia', en: 'Moldova', fr: 'Moldavie', pt: 'Moldávia' },
    dial_code: '+373',
    code: 'MD',
    code2: 'MDA'
  },
  {
    name: { es: 'Mongolia', en: 'Mongolia', fr: 'Mongolie', pt: 'Mongólia' },
    dial_code: '+976',
    code: 'MN',
    code2: 'MNG'
  },
  {
    name: { es: 'Montenegro', en: 'Montenegro', fr: 'Monténégro', pt: 'Montenegro' },
    dial_code: '+382',
    code: 'ME',
    code2: 'MNE'
  },
  {
    name: { es: 'Montserrat', en: 'Montserrat', fr: 'Montserrat', pt: 'Montserrat' },
    dial_code: '+1 664',
    code: 'MS',
    code2: 'MSR'
  },
  {
    name: { es: 'Mozambique', en: 'Mozambique', fr: 'Mozambique', pt: 'Moçambique' },
    dial_code: '+258',
    code: 'MZ',
    code2: 'MOZ'
  },
  {
    name: { es: 'Namibia', en: 'Namibia', fr: 'Namibie', pt: 'Namíbia' },
    dial_code: '+264',
    code: 'NA',
    code2: 'NAM'
  },
  {
    name: { es: 'Nauru', en: 'Nauru', fr: 'Nauru', pt: 'Nauru' },
    dial_code: '+674',
    code: 'NR',
    code2: 'NRU'
  },
  {
    name: { es: 'Nepal', en: 'Nepal', fr: 'Népal', pt: 'Nepal' },
    dial_code: '+977',
    code: 'NP',
    code2: 'NPL'
  },
  {
    name: { es: 'Nicaragua', en: 'Nicaragua', fr: 'Nicaragua', pt: 'Nicarágua' },
    dial_code: '+505',
    code: 'NI',
    code2: 'NIC'
  },
  {
    name: { es: 'Niger', en: 'Niger', fr: 'Niger', pt: 'Níger' },
    dial_code: '+227',
    code: 'NE',
    code2: 'NER'
  },
  {
    name: { es: 'Nigeria', en: 'Nigeria', fr: 'Nigeria', pt: 'Nigéria' },
    dial_code: '+234',
    code: 'NG',
    code2: 'NGA'
  },
  {
    name: { es: 'Niue', en: 'Niue', fr: 'Niou', pt: 'Niue' },
    dial_code: '+683',
    code: 'NU',
    code2: 'NIU'
  },
  {
    name: { es: 'Noruega', en: 'Norway', fr: 'Norvège', pt: 'Noruega' },
    dial_code: '+47',
    code: 'NO',
    code2: 'NOR'
  },
  {
    name: {
      es: 'Nueva Caledonia',
      en: 'New Caledonia',
      fr: 'Nouvelle-Calédonie',
      pt: 'Nova Caledônia'
    },
    dial_code: '+687',
    code: 'NC',
    code2: 'NCL'
  },
  {
    name: { es: 'Nueva Zelanda', en: 'New Zealand', fr: 'Nouvelle-Zélande', pt: 'Nova Zelândia' },
    dial_code: '+64',
    code: 'NZ',
    code2: 'NZL'
  },
  {
    name: { es: 'Omán', en: 'Oman', fr: 'Oman', pt: 'Omã' },
    dial_code: '+968',
    code: 'OM',
    code2: 'OMN'
  },
  {
    name: { es: 'Países Bajos', en: 'Netherlands', fr: 'Pays-Bas', pt: 'Holanda' },
    dial_code: '+31',
    code: 'NL',
    code2: 'NLD'
  },
  {
    name: { es: 'Pakistán', en: 'Pakistan', fr: 'Pakistan', pt: 'Paquistão' },
    dial_code: '+92',
    code: 'PK',
    code2: 'PAK'
  },
  {
    name: { es: 'Palau', en: 'Palau', fr: 'Palau', pt: 'Palau' },
    dial_code: '+680',
    code: 'PW',
    code2: 'PLW'
  },
  {
    name: { es: 'Palestina', en: 'Palestine', fr: 'La Palestine', pt: 'Palestina' },
    dial_code: '+970',
    code: 'PS',
    code2: 'PSE'
  },
  {
    name: { es: 'Panamá', en: 'Panama', fr: 'Panama', pt: 'Panamá' },
    dial_code: '+507',
    code: 'PA',
    code2: 'PAN'
  },
  {
    name: {
      es: 'Papúa Nueva Guinea',
      en: 'Papua New Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      pt: 'Papua Nova Guiné'
    },
    dial_code: '+675',
    code: 'PG',
    code2: 'PNG'
  },
  {
    name: { es: 'Paraguay', en: 'Paraguay', fr: 'Paraguay', pt: 'Paraguai' },
    dial_code: '+595',
    code: 'PY',
    code2: 'PRY'
  },
  {
    name: { es: 'Perú', en: 'Peru', fr: 'Pérou', pt: 'Peru' },
    dial_code: '+51',
    code: 'PE',
    code2: 'PER'
  },
  {
    name: {
      es: 'Polinesia Francesa',
      en: 'French Polynesia',
      fr: 'Polynésie française',
      pt: 'Polinésia Francesa'
    },
    dial_code: '+689',
    code: 'PF',
    code2: 'PYF'
  },
  {
    name: { es: 'Polonia', en: 'Poland', fr: 'Pologne', pt: 'Polônia' },
    dial_code: '+48',
    code: 'PL',
    code2: 'POL'
  },
  {
    name: { es: 'Portugal', en: 'Portugal', fr: 'Portugal', pt: 'Portugal' },
    dial_code: '+351',
    code: 'PT',
    code2: 'PRT'
  },
  {
    name: { es: 'Puerto Rico', en: 'Puerto Rico', fr: 'Porto Rico', pt: 'Porto Rico' },
    dial_code: '+1',
    code: 'PR',
    code2: 'PRI'
  },
  {
    name: { es: 'Qatar', en: 'Qatar', fr: 'Qatar', pt: 'Catar' },
    dial_code: '+974',
    code: 'QA',
    code2: 'QAT'
  },
  {
    name: { es: 'Reino Unido', en: 'United Kingdom', fr: 'Royaume-Uni', pt: 'Reino Unido' },
    dial_code: '+44',
    code: 'GB',
    code2: 'GBR'
  },
  {
    name: {
      es: 'República Centroafricana',
      en: 'Central African Republic',
      fr: 'République Centrafricaine',
      pt: 'República Centro-Africana'
    },
    dial_code: '+236',
    code: 'CF',
    code2: 'CAF'
  },
  {
    name: {
      es: 'República Checa',
      en: 'Czech Republic',
      fr: 'République Tchèque',
      pt: 'República Tcheca'
    },
    dial_code: '+420',
    code: 'CZ',
    code2: 'CZE'
  },
  {
    name: {
      es: 'República Dominicana',
      en: 'Dominican Republic',
      fr: 'République Dominicaine',
      pt: 'República Dominicana'
    },
    dial_code: '+1 809',
    code: 'DO',
    code2: 'DOM'
  },
  {
    name: {
      es: 'República de Sudán del Sur',
      en: 'South Sudan',
      fr: 'Soudan du Sud',
      pt: 'Sudão do Sul'
    },
    dial_code: '+211',
    code: 'SS',
    code2: 'SSD'
  },
  {
    name: { es: 'Reunión', en: 'Réunion', fr: 'Réunion', pt: 'Reunião' },
    dial_code: '+262',
    code: 'RE',
    code2: 'REU'
  },
  {
    name: { es: 'Ruanda', en: 'Rwanda', fr: 'Rwanda', pt: 'Ruanda' },
    dial_code: '+250',
    code: 'RW',
    code2: 'RWA'
  },
  {
    name: { es: 'Rumanía', en: 'Romania', fr: 'Roumanie', pt: 'Romênia' },
    dial_code: '+40',
    code: 'RO',
    code2: 'ROU'
  },
  {
    name: { es: 'Rusia', en: 'Russia', fr: 'La Russie', pt: 'Rússia' },
    dial_code: '+7',
    code: 'RU',
    code2: 'RUS'
  },
  {
    name: {
      es: 'Sahara Occidental',
      en: 'Western Sahara',
      fr: 'Sahara Occidental',
      pt: 'Saara Ocidental'
    },
    dial_code: '+212',
    code: 'EH',
    code2: 'ESH'
  },
  {
    name: { es: 'Samoa', en: 'Samoa', fr: 'Samoa', pt: 'Samoa' },
    dial_code: '+685',
    code: 'WS',
    code2: 'WSM'
  },
  {
    name: {
      es: 'Samoa Americana',
      en: 'American Samoa',
      fr: 'Les Samoa américaines',
      pt: 'Samoa Americana'
    },
    dial_code: '+1 684',
    code: 'AS',
    code2: 'ASM'
  },
  {
    name: {
      es: 'San Bartolomé',
      en: 'Saint Barthélemy',
      fr: 'Saint-Barthélemy',
      pt: 'São Bartolomeu'
    },
    dial_code: '+590',
    code: 'BL',
    code2: 'BLM'
  },
  {
    name: {
      es: 'San Cristóbal y Nieves',
      en: 'Saint Kitts and Nevis',
      fr: 'Saint Kitts et Nevis',
      pt: 'São Cristóvão e Nevis'
    },
    dial_code: '+1 869',
    code: 'KN',
    code2: 'KNA'
  },
  {
    name: { es: 'San Marino', en: 'San Marino', fr: 'San Marino', pt: 'San Marino' },
    dial_code: '+378',
    code: 'SM',
    code2: 'SMR'
  },
  {
    name: {
      es: 'San Martín (Francia)',
      en: 'Saint Martin (French part)',
      fr: 'Saint-Martin (partie française)',
      pt: 'Saint Martin (parte francesa)'
    },
    dial_code: '+1 599',
    code: 'MF',
    code2: 'MAF'
  },
  {
    name: {
      es: 'San Pedro y Miquelón',
      en: 'Saint Pierre and Miquelon',
      fr: 'Saint-Pierre-et-Miquelon',
      pt: 'Saint Pierre e Miquelon'
    },
    dial_code: '+508',
    code: 'PM',
    code2: 'SPM'
  },
  {
    name: {
      es: 'San Vicente y las Granadinas',
      en: 'Saint Vincent and the Grenadines',
      fr: 'Saint-Vincent et Grenadines',
      pt: 'São Vicente e Granadinas'
    },
    dial_code: '+1 784',
    code: 'VC',
    code2: 'VCT'
  },
  {
    name: {
      es: 'Santa Elena',
      en: 'Saint Helena',
      fr: 'Sainte-Hélène',
      pt: 'Santa Helena'
    },
    dial_code: '+290',
    code: 'SH',
    code2: 'SHN'
  },
  {
    name: { es: 'Santa Lucía', en: 'Saint Lucia', fr: 'Sainte-Lucie', pt: 'Santa Lúcia' },
    dial_code: '+1 758',
    code: 'LC',
    code2: 'LCA'
  },
  {
    name: {
      es: 'Santo Tomé y Príncipe',
      en: 'Sao Tome and Principe',
      fr: 'Sao Tomé et Principe',
      pt: 'São Tomé e Príncipe'
    },
    dial_code: '+239',
    code: 'ST',
    code2: 'STP'
  },
  {
    name: { es: 'Senegal', en: 'Senegal', fr: 'Sénégal', pt: 'Senegal' },
    dial_code: '+221',
    code: 'SN',
    code2: 'SEN'
  },
  {
    name: { es: 'Serbia', en: 'Serbia', fr: 'Serbie', pt: 'Sérvia' },
    dial_code: '+381',
    code: 'RS',
    code2: 'SRB'
  },
  {
    name: { es: 'Seychelles', en: 'Seychelles', fr: 'Les Seychelles', pt: 'Seychelles' },
    dial_code: '+248',
    code: 'SC',
    code2: 'SYC'
  },
  {
    name: { es: 'Sierra Leona', en: 'Sierra Leone', fr: 'Sierra Leone', pt: 'Serra Leoa' },
    dial_code: '+232',
    code: 'SL',
    code2: 'SLE'
  },
  {
    name: { es: 'Singapur', en: 'Singapore', fr: 'Singapour', pt: 'Cingapura' },
    dial_code: '+65',
    code: 'SG',
    code2: 'SGP'
  },
  {
    name: { es: 'Sint Maarten', en: 'Sint Maarten', fr: 'Saint-Martin', pt: 'Sint Maarten' },
    dial_code: '+1 721',
    code: 'SX',
    code2: 'SMX'
  },
  {
    name: { es: 'Siria', en: 'Syria', fr: 'Syrie', pt: 'Síria' },
    dial_code: '+963',
    code: 'SY',
    code2: 'SYR'
  },
  {
    name: { es: 'Somalia', en: 'Somalia', fr: 'Somalie', pt: 'Somália' },
    dial_code: '+252',
    code: 'SO',
    code2: 'SOM'
  },
  {
    name: { es: 'Sri lanka', en: 'Sri Lanka', fr: 'Sri Lanka', pt: 'Sri Lanka' },
    dial_code: '+94',
    code: 'LK',
    code2: 'LKA'
  },
  {
    name: { es: 'Sudáfrica', en: 'South Africa', fr: 'Afrique du Sud', pt: 'África do Sul' },
    dial_code: '+27',
    code: 'ZA',
    code2: 'ZAF'
  },
  {
    name: { es: 'Sudán', en: 'Sudan', fr: 'Soudan', pt: 'Sudão' },
    dial_code: '+249',
    code: 'SD',
    code2: 'SDN'
  },
  {
    name: { es: 'Suecia', en: 'Sweden', fr: 'Suède', pt: 'Suécia' },
    dial_code: '+46',
    code: 'SE',
    code2: 'SWE'
  },
  {
    name: { es: 'Suiza', en: 'Switzerland', fr: 'Suisse', pt: 'Suíça' },
    dial_code: '+41',
    code: 'CH',
    code2: 'CHE'
  },
  {
    name: { es: 'Surinám', en: 'Suriname', fr: 'Surinam', pt: 'Suriname' },
    dial_code: '+597',
    code: 'SR',
    code2: 'SUR'
  },
  {
    name: {
      es: 'Svalbard y Jan Mayen',
      en: 'Svalbard and Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
      pt: 'Svalbard e Jan Mayen'
    },
    dial_code: '+47',
    code: 'SJ',
    code2: 'SJM'
  },
  {
    name: { es: 'Swazilandia', en: 'Swaziland', fr: 'Swaziland', pt: 'Suazilândia' },
    dial_code: '+268',
    code: 'SZ',
    code2: 'SWZ'
  },
  {
    name: { es: 'Tayikistán', en: 'Tajikistan', fr: 'Le Tadjikistan', pt: 'Tajiquistão' },
    dial_code: '+992',
    code: 'TJ',
    code2: 'TJK'
  },
  {
    name: { es: 'Tailandia', en: 'Thailand', fr: 'Thaïlande', pt: 'Tailândia' },
    dial_code: '+66',
    code: 'TH',
    code2: 'THA'
  },
  {
    name: { es: 'Taiwán', en: 'Taiwan', fr: 'Taiwan', pt: 'Taiwan' },
    dial_code: '+886',
    code: 'TW',
    code2: 'TWN'
  },
  {
    name: { es: 'Tanzania', en: 'Tanzania', fr: 'Tanzanie', pt: 'Tanzânia' },
    dial_code: '+255',
    code: 'TZ',
    code2: 'TZA'
  },
  {
    name: {
      es: 'Territorio Británico del Océano Índico',
      en: 'British Indian Ocean Territory',
      fr: "Territoire britannique de l'océan Indien",
      pt: 'Território Britânico do Oceano Índico'
    },
    dial_code: '+246',
    code: 'IO',
    code2: 'IOT'
  },
  {
    name: {
      es: 'Territorios Australes y Antárticas Franceses',
      en: 'French Southern Territories',
      fr: 'Terres australes françaises',
      pt: 'Terras Austrais e Antárticas Francesas'
    },
    dial_code: '+',
    code: 'TF',
    code2: 'ATF'
  },
  {
    name: { es: 'Timor Oriental', en: 'East Timor', fr: 'Timor-Oriental', pt: 'Timor Leste' },
    dial_code: '+670',
    code: 'TL',
    code2: 'TLS'
  },
  {
    name: { es: 'Togo', en: 'Togo', fr: 'Togo', pt: 'Togo' },
    dial_code: '+228',
    code: 'TG',
    code2: 'TGO'
  },
  {
    name: { es: 'Tokelau', en: 'Tokelau', fr: 'Tokélaou', pt: 'Tokelau' },
    dial_code: '+690',
    code: 'TK',
    code2: 'TKL'
  },
  {
    name: { es: 'Tonga', en: 'Tonga', fr: 'Tonga', pt: 'Tonga' },
    dial_code: '+676',
    code: 'TO',
    code2: 'TON'
  },
  {
    name: {
      es: 'Trinidad y Tobago',
      en: 'Trinidad and Tobago',
      fr: 'Trinidad et Tobago',
      pt: 'Trinidad e Tobago'
    },
    dial_code: '+1 868',
    code: 'TT',
    code2: 'TTO'
  },
  {
    name: { es: 'Tunez', en: 'Tunisia', fr: 'Tunisie', pt: 'Tunísia' },
    dial_code: '+216',
    code: 'TN',
    code2: 'TUN'
  },
  {
    name: { es: 'Turkmenistán', en: 'Turkmenistan', fr: 'Le Turkménistan', pt: 'Turcomenistão' },
    dial_code: '+993',
    code: 'TM',
    code2: 'TKM'
  },
  {
    name: { es: 'Turquía', en: 'Turkey', fr: 'Turquie', pt: 'Turquia' },
    dial_code: '+90',
    code: 'TR',
    code2: 'TUR'
  },
  {
    name: { es: 'Tuvalu', en: 'Tuvalu', fr: 'Tuvalu', pt: 'Tuvalu' },
    dial_code: '+688',
    code: 'TV',
    code2: 'TUV'
  },
  {
    name: { es: 'Ucrania', en: 'Ukraine', fr: "L'Ukraine", pt: 'Ucrânia' },
    dial_code: '+380',
    code: 'UA',
    code2: 'UKR'
  },
  {
    name: { es: 'Uganda', en: 'Uganda', fr: 'Ouganda', pt: 'Uganda' },
    dial_code: '+256',
    code: 'UG',
    code2: 'UGA'
  },
  {
    name: { es: 'Uruguay', en: 'Uruguay', fr: 'Uruguay', pt: 'Uruguai' },
    dial_code: '+598',
    code: 'UY',
    code2: 'URY'
  },
  {
    name: { es: 'Uzbekistán', en: 'Uzbekistan', fr: "L'Ouzbékistan", pt: 'Uzbequistão' },
    dial_code: '+998',
    code: 'UZ',
    code2: 'UZB'
  },
  {
    name: { es: 'Vanuatu', en: 'Vanuatu', fr: 'Vanuatu', pt: 'Vanuatu' },
    dial_code: '+678',
    code: 'VU',
    code2: 'VUT'
  },
  {
    name: { es: 'Venezuela', en: 'Venezuela', fr: 'Venezuela', pt: 'Venezuela' },
    dial_code: '+58',
    code: 'VE',
    code2: 'VEN'
  },
  {
    name: { es: 'Vietnam', en: 'Vietnam', fr: 'Vietnam', pt: 'Vietnã' },
    dial_code: '+84',
    code: 'VN',
    code2: 'VNM'
  },
  {
    name: {
      es: 'Wallis y Futuna',
      en: 'Wallis and Futuna',
      fr: 'Wallis et Futuna',
      pt: 'Wallis e Futuna'
    },
    dial_code: '+681',
    code: 'WF',
    code2: 'WLF'
  },
  {
    name: { es: 'Yemen', en: 'Yemen', fr: 'Yémen', pt: 'Iêmen' },
    dial_code: '+967',
    code: 'YE',
    code2: 'YEM'
  },
  {
    name: { es: 'Yibuti', en: 'Djibouti', fr: 'Djibouti', pt: 'Djibuti' },
    dial_code: '+253',
    code: 'DJ',
    code2: 'DJI'
  },
  {
    name: { es: 'Zambia', en: 'Zambia', fr: 'Zambie', pt: 'Zâmbia' },
    dial_code: '+260',
    code: 'ZM',
    code2: 'ZMB'
  },
  {
    name: { es: 'Zimbabue', en: 'Zimbabwe', fr: 'Zimbabwe', pt: 'Zimbábue' },
    dial_code: '+263',
    code: 'ZW',
    code2: 'ZWE'
  }
];

export default function getPrefixes(language = 'en') {
  return prefixes.map((prefix) => {
    return {
      ...prefix,
      name: prefix.name[language]
    };
  });
}

export const getCountries = (language = 'en', organizationCountry) => {
  const countries = prefixes.map((prefix) => {
    return {
      id: prefix.code,
      name: prefix.name[language]
    };
  });

  let organizationCountryParsed = organizationCountry;

  if (organizationCountryParsed === 'EN') {
    organizationCountryParsed = 'GB';
  }
  const foundCountryIndex = countries.findIndex(
    (country) => country.id === organizationCountryParsed
  );
  if (foundCountryIndex !== -1) {
    const element = { ...countries[foundCountryIndex] };

    countries.splice(foundCountryIndex, 1);
    countries.unshift(element);
  }
  return countries;
};

export const getAllCountries = () => {
  return prefixes.map((prefix) => {
    return { name: prefix.name, code: prefix.code };
  });
};

export const getCountryNameByCode = (code, language = 'en') => {
  const codeParsed = code === 'EN' ? 'GB' : code;
  const country = prefixes.find((prefix) => prefix.code === codeParsed);
  return country ? country.name[language] : '';
};
