import { Handle, Position } from '@xyflow/react';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import { NODE_ICONS, NODE_TYPES } from '../../constants';
import { ScenarioNode } from '../../types/LCAScenarios';
import { NodeImpact } from '../../../../../services/api/lca/lcaPortfolio';
import { useNodeLCAImpact } from '../../hooks/useNodeLCAImpact';
import { buildLegendUnitFromNodeImpact } from '../../../utils';
import { CustomNode } from './CustomNode';
import { getHandleStyle } from './utils';

type PieceNodeProps = {
  data: ScenarioNode['data'];
  disableHover?: boolean;
  nodeImpact?: NodeImpact;
  selected?: boolean;
};

const NodeAppearance: React.FC<PieceNodeProps> = ({ data, disableHover, nodeImpact, selected }) => {
  const { t } = useLCATranslation();

  return (
    <CustomNode.Layout disableHover={disableHover} selected={selected}>
      <div className='flex-col gap-y-4'>
        <CustomNode.Content>
          <CustomNode.Icon icon={NODE_ICONS[NODE_TYPES.piece]} lookAndFeel='piece' />
          <div className='flex-col'>
            <span className='font-body-b2-r text-gray-light'>{t('lcaScenarios.piece')}</span>
            <span className='font-body-b1-sb'>{data.label}</span>
          </div>
          {nodeImpact && (
            <CustomNode.TotalImpact
              impact={nodeImpact.sum_total_impact.toFixed(2)}
              unit={buildLegendUnitFromNodeImpact(nodeImpact)}
              position='right'
            />
          )}
        </CustomNode.Content>
        {Boolean(data.tags?.length) && (
          <div className='flex gap-x-2'>
            {data.tags?.map((tag) => (
              <CustomNode.Tag key={data.id + tag} disabled>
                {tag}
              </CustomNode.Tag>
            ))}
          </div>
        )}
      </div>
    </CustomNode.Layout>
  );
};

export const PieceNode = ({ data, selected }: PieceNodeProps) => {
  const process = data.inputs.find((input) => input.type === NODE_TYPES.process);

  const { data: nodeImpact } = useNodeLCAImpact({ node_id: process?.id });

  return (
    <>
      <Handle
        type='target'
        position={Position.Left}
        isConnectableStart={false}
        isConnectableEnd={data.inputs.length === 0}
        style={getHandleStyle('target')}
      />
      <NodeAppearance data={data} nodeImpact={nodeImpact} selected={selected} />
      <Handle
        type='source'
        position={Position.Right}
        isConnectable={false}
        style={{ visibility: 'hidden' }}
      />
    </>
  );
};

PieceNode.Appearance = NodeAppearance;
