import { useEffect, useState } from 'react';
import useErrors from './useErrors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useGetData<T>(fecth: () => Promise<any>, deps: unknown[]) {
  const ERRORS = useErrors();

  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorType | null>(null);

  const fetchData = async () => {
    if (loading) return;

    setLoading(true);
    const response = await fecth();
    setLoading(false);

    if (!response || response instanceof Error || response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    setData(response);
  };

  useEffect(() => {
    fetchData();
  }, deps);

  return { data, loading, error };
}

export default useGetData;
