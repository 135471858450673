import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EMPLOYEE_FORM_GUIDE } from '../../../../../../constants';
import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import { replaceStrongTags } from '../../../../../../utils/stringToBold';
import Button from '../../../../../ui/button/Button';
import LoaderFormEmployee from '../../../../../ui/loaders/loaderFormEmployees/LoaderFormEmployee';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import { LastPeriodSummary } from '../lastPeriodSummary/LastPeriodSummary';
import useGetData from '../useGetData';
import { PrivacyCommunication } from './privacyCommunication/PrivacyCommunication';
import { ID_ORGS_PRIVACY_COMMUNICATION } from './privacyCommunication/constants';
import './styles.scss';

function StepInit() {
  const { t, i18n } = useTranslation();
  const { startDate, endDate, id } = useParams();

  const { lastPeriod, loading } = useGetData({ isQrStatus: false, id });
  const [isChecked, setIsChecked] = useState(false);

  const isQrRoute = window.location.pathname.includes('employee-form-qr');

  const EMPLOYEE_FORM_GUIDE_TRANSLATED =
    i18n.resolvedLanguage === 'es' ? EMPLOYEE_FORM_GUIDE.es : EMPLOYEE_FORM_GUIDE.en;

  const { handleInItinere } = useContext(EmployeeFormContext);

  const showPrivacyCommunication =
    isQrRoute && !!ID_ORGS_PRIVACY_COMMUNICATION.find((org) => org.id.includes(id ?? ''));

  const selectedOrganization = ID_ORGS_PRIVACY_COMMUNICATION.find((org) => org.id === id);

  return (
    <>
      {loading ? (
        <LoaderFormEmployee />
      ) : (
        <StepControllerContainer
          title={t('employeeForm.formTitle1', {
            date: `${startDate && convertDateBackToFront(startDate)} - ${
              endDate && convertDateBackToFront(endDate)
            }`
          })}
          subtitle={
            showPrivacyCommunication
              ? replaceStrongTags(
                  t('employeeForm.formSubtitle1', { org: selectedOrganization?.name })
                )
              : replaceStrongTags(t('employeeForm.formSubtitleNotAludec'))
          }>
          <a
            href={EMPLOYEE_FORM_GUIDE_TRANSLATED}
            target='_blank'
            rel='noreferrer'
            className='highlight-text-color pointer subtitle3-font'>
            {t('employeeForm.guideHelp')}
          </a>

          {!lastPeriod ? (
            <>
              <div className='button-start'>
                <Button
                  lookAndFeel={'primary'}
                  size='small'
                  onClick={handleInItinere}
                  text={t('employeeForm.startForm')}
                  disabled={showPrivacyCommunication && !isChecked}
                />
              </div>

              {showPrivacyCommunication && (
                <PrivacyCommunication check={{ isChecked, setIsChecked }} />
              )}
            </>
          ) : (
            <StepWrapper title={t('employeeForm.lastPeriod')} stepNumber={1} width={500}>
              <div className='information-card-wrapper'>
                <LastPeriodSummary lastPeriod={lastPeriod}></LastPeriodSummary>
              </div>
            </StepWrapper>
          )}
        </StepControllerContainer>
      )}
    </>
  );
}

export default StepInit;
