import { createContext, useContext } from 'react';
import type { FrontLCAMethodology } from '../../types/LCADetail';

type ILCAMethodologyContext = {
  methodology?: FrontLCAMethodology;
  setMethodology: (value?: FrontLCAMethodology) => void;
};

const LCAMethodologyContext = createContext<ILCAMethodologyContext | null>(null);

export const useLCAMethodology = () => {
  const context = useContext(LCAMethodologyContext);
  if (!context) {
    throw new Error('useLCAMethodology must be used within a LCAMethodologyProvider');
  }
  return context;
};

type Props = {
  methodology?: FrontLCAMethodology;
  setMethodology: (value?: FrontLCAMethodology) => void;
  children: React.ReactNode;
};

export const LCAMethodologyProvider: React.FC<Props> = ({
  children,
  methodology,
  setMethodology
}) => {
  return (
    <LCAMethodologyContext.Provider value={{ methodology, setMethodology }}>
      {children}
    </LCAMethodologyContext.Provider>
  );
};
