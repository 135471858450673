import { useTranslation } from 'react-i18next';
import useTreatmentSector from '../../../../../customHooks/useTreatmentSector';

const useCategories = () => {
  const { t } = useTranslation();

  const { isTreatmentOrganization } = useTreatmentSector();
  const categoriesToInclude = [
    { id: 'heat', name: t('facilityDetail.combustion') },
    { id: 'electricity', name: t('facilityDetail.electricity') },
    { id: 'recharge', name: t('facilityDetail.recharge') },
    { id: 'water', name: t('facilityDetail.water') },
    { id: 'wastes', name: t('facilityDetail.waste') }
  ];

  if (isTreatmentOrganization)
    categoriesToInclude.push({
      id: 'waste_water_treatment',
      name: t('facilityDetail.waste_water_treatment')
    });
  return categoriesToInclude;
};

export default useCategories;
