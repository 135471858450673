import { useTranslation } from 'react-i18next';
import SelectCardWrapper from '../../../../../../../ui/selectCard/SelectCardWrapper';
import './styles.scss';

type Props = {
  onChangeSelectCategory: (category: string) => void;
  next: () => void;
};

const InvoiceType = ({ onChangeSelectCategory, next }: Props) => {
  const { t } = useTranslation();
  const cards = [
    {
      id: 'heat',
      icon: '/images/icons/fire50.svg',
      title: t('facilityDetail.combustion'),
      disabled: false
    },
    {
      id: 'recharge',
      icon: '/images/icons/velocimeter.svg',
      title: t('facilityDetail.recharge'),
      disabled: false
    },
    {
      id: 'electricity',
      icon: '/images/icons/thunder50.svg',
      title: t('facilityDetail.electricity'),
      disabled: false
    }
    // {
    //   id: 'waste',
    //   icon: '/images/icons/waste.svg',
    //   title: t('facilityDetail.waste'),
    //   disabled: false
    // }
  ];

  const onClickCard = (category: string) => {
    onChangeSelectCategory(category);
    next();
  };

  return (
    <div className='select-type-wrapper'>
      <h3 className='body1-bold-font'>{t('facilityDetail.selectInvoiceType')}</h3>
      <SelectCardWrapper
        cards={cards}
        onClickCard={onClickCard}
        type={{ id: '', icon: '', title: '' }}
      />
    </div>
  );
};

export default InvoiceType;
