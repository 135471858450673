import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { UserContext } from '../../../../../context/userContext';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import apiFetch from '../../../../../utils/apiFetch';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import { convertDateFrontToBack } from '../../../../../utils/convertDates';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormCalendarDouble from '../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';

type Props = {
  onConfirm: () => void;
  email: string;
};

type FormDataEmail = {
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};

export const ModalResendFormTable = ({ email, onConfirm }: Props) => {
  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const currentYear = moment().format('YYYY');

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<ErrorType>();
  const [formData, setFormData] = useState<FormDataEmail>({
    startDate: '',
    endDate: '',
    errors: []
  });
  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const resend = () => {
    if (handleErrors()) return;
    const start_date = convertDateFrontToBack(formData.startDate);
    const end_date = convertDateFrontToBack(formData.endDate);

    apiFetch('post', '/employees/form', {
      emails: [email],
      organization_id: user?.selectedOrganization,
      lang: i18n.resolvedLanguage,
      start_date,
      end_date
    })
      .then((response) => {
        dispatch(setNotification(t('notification.customFormSent')));
        onConfirm();
      })
      .catch((err: any) => {
        dispatch(setNotification(t('notification.errorSendingForm')));
      });
  };

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  return (
    <div className='confirm-resend'>
      <FormHeader
        title={t('employees.confirmResendEmployee').replace('{{employee}}', email)}
        description={t('employees.confirmResendDescriptionEmployee')}
      />
      <FormCalendarDouble
        tooltip={t('employees.dateRangeTooltip')}
        label={t('sendEmailForm.measurement_period')}
        handleChangeStartDate={onChangeValue('startDate')}
        handleChangeEndDate={onChangeValue('endDate')}
        handleStartDateError={handleDateError('startDate')}
        handleEndDateError={handleDateError('endDate')}
        startDate={formData.startDate}
        endDate={formData.endDate}
        maxEndDate={`31/12/${currentYear}`}
        minStartDate={'01/01/1960'}
        minEndDate={'01/01/1960'}
        error={formData?.errors?.find(
          (elem) => elem.error === 'startDate' || elem.error === 'endDate'
        )}
      />

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('employees.resend')}
          onClick={resend}
          loading={loading}
          style={{ marginTop: '2em' }}
        />
      </FormButtonSection>
      {error && <p className='error-text-color body1-bold-font'>{error.description}</p>}
    </div>
  );
};
