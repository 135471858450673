import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const TutorialModalCenter = (props: Props) => {
  return (
    <div
      className='modal main-bg-color card-border-color modal-box-shadow modal-no-opacity round-border'
      {...props}
    />
  );
};

export default TutorialModalCenter;
