import { useContext } from 'react';
import { UserContext } from '../../../../context/userContext';
import { Organization } from '../../../../types/entities/organization';
import Table from '../../../ui/table/Table';
import OrganizationSelection from './organizationSelection/OrganizationSelection';
import './styles.scss';

type Props = {
  searchValue: string;
};
const ListOrganization = ({ searchValue }: Props) => {
  const user = useContext(UserContext);

  const columns = [
    {
      title: '',
      dataIndex: 'company',
      key: 'company'
    }
  ];
  let organizations: Organization[] = [];
  if (user?.organizations && user?.organizations.length > 0) {
    organizations = [...user.organizations];
  }
  organizations = organizations.sort((a, b) => (a.company_name < b.company_name ? -1 : 1));

  if (searchValue) {
    organizations = organizations?.filter((organization) =>
      organization.company_name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  const data = organizations?.map((organization) => ({
    company: <OrganizationSelection key={organization.id} organization={organization} />,
    id: organization.id
  }));

  if (!data) {
    return null;
  }

  return (
    <div className='list-organizations card-bg-color'>
      <div className='table'>
        <div className='scroll'>
          <Table columns={columns} data={data} loading={false} size={'small'} />
        </div>
      </div>
    </div>
  );
};

export default ListOrganization;
