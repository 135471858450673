import { InputSize } from '../../../../../types/utilsEnums/input';
import Icon from '../../../icon/Icon';
import './styles.scss';

type Props = {
  rotate: boolean;
  size?: InputSize;
};
const Chevron = ({ rotate, size }: Props) => {
  return (
    <Icon
      icon='chevron_down'
      color='gradient'
      size='medium'
      className={`chevron ${rotate ? 'rotate180' : ''} ${size ?? ''}`}
    />
  );
};

export default Chevron;
