import { useTranslation } from 'react-i18next';

type Unit = { [key in ISbtiCriteriaSectors]: string };

const useConstants = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });
  const { t: tOptions } = useTranslation('translation', {
    keyPrefix: 'sbti.criteriaForm.modal.services'
  });

  const options: SelectOptionFormat<ISbtiCriteriaMethod>[] = [
    {
      id: 'absolute',
      name: t('absolute')
    },
    {
      id: 'sectorial',
      name: t('sectorial')
    }
  ];

  const units: Unit = {
    power: tOptions('mwh'),
    services_building: tOptions('square_meters'),
    residential_building: tOptions('square_meters'),
    cement: tOptions('cementTonnes'),
    aluminium: tOptions('aluminiumTonnes'),
    pulp_and_paper: tOptions('pulpAndPaperTonnes'),
    iron_and_steel: tOptions('ironAndSteelTonnes'),
    other: tOptions('otherUnit')
  };

  return { options, units };
};

export default useConstants;
