import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { scope1_color, scope2_color } from '../../../../styles/colors';
import numberToDecimal from '../../../../utils/numberToDecimal';
import TooltipChart from '../../tooltip/TooltipChart';
import './styles.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChartComparison({ chartData, disabled }) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [value, setValue] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const [data, setData] = useState({
    labels: [],
    datasets: []
  });
  const chartRef = useRef(null);

  const options = {
    devicePixelRatio: 4,
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x1: {
        position: 'bottom',
        grid: {
          display: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: function (context) {
            return {
              size: 10,
              family: 'Inter',
              weight: 400,
              lineHeight: 1.2
            };
          },
          padding: 0,
          margin: 0
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          color: 'rgba(25, 42, 62, 0.8)',
          font: {
            size: 0,
            family: 'Inter',
            weight: 400,
            lineHeight: 1.2
          }
        }
      }
    },
    hover: {
      onHover: (e) => {
        var el = document.getElementById('canvas1');
        el.style.cursor = e[0] ? 'pointer' : 'default';
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          if (chartRef && chartRef.current) {
            const tooltip = chartRef.current.tooltip;
            if (tooltip.opacity === 0) {
              setShowTooltip(false);
            } else {
              // assuming your tooltip is `position: fixed`
              // set position of tooltip
              const position = context.chart.canvas.getBoundingClientRect();
              const left = position.left + window.pageXOffset + tooltip.caretX;
              const top = position.top + window.pageYOffset + tooltip.caretY - 20;

              setLeft(left);
              setTop(top);
              if (disabled) {
                setValue('0 kg CO₂ eq.');
                setShowTooltip(true);
              } else {
                let value = tooltip.dataPoints[0].raw;
                value = value / 1000;
                let unit = 't CO₂ eq.';
                setValue(`${numberToDecimal(value)} ${unit}`);
                setShowTooltip(true);
              }
            }
          }
        }
      }
    },
    font: {
      size: 10,
      family: 'Inter',
      weight: 400,
      lineHeight: 1.2
    }
  };

  const createLinearGradient = () => {
    const ctx = chartRef.current.ctx;
    const area = chartRef.current.chartArea;
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, scope2_color);
    gradient.addColorStop(1, scope1_color);
    return gradient;
  };

  useEffect(() => {
    if (!chartRef?.current) return;
    let datasets = [
      {
        label: 'company1',
        data: chartData[0].map((elem) => elem.value),
        backgroundColor: chartData[0].map((elem) => {
          if (elem.color === 'gradient') {
            return createLinearGradient();
          }
          return elem.color;
        }),
        borderColor: '#fff',
        // borderColor: chartData[0].map((elem) => {
        //   if (elem.color === 'gradient') {
        //     return createLinearGradient();
        //   }
        //   return elem.color;
        // }),
        barThickness: 14,
        borderRadius: 8,
        borderWidth: 2,
        hoverBorderColor: '#fff'
      }
    ];

    if (chartData.length > 1) {
      datasets.push({
        label: 'company2',
        data: chartData[1].map((elem) => elem.value),
        backgroundColor: chartData[1].map((elem) => {
          if (elem.color === 'gradient') {
            return createLinearGradient();
          }
          return elem.color;
        }),
        borderColor: '#fff',
        barThickness: 14,
        borderRadius: 8,
        borderWidth: 2,
        hoverBorderColor: '#fff'
      });
      const auxData = {
        labels: chartData[0].map((elem) =>
          elem.label.length <= 12 ? elem.label : threeDotsAtEnd(elem.label, 10)
        ),
        datasets
      };
      setData(auxData);
    }
  }, [chartRef.current]);

  const threeDotsAtEnd = (str, maxLength) => {
    if (str.length < 5) {
      return str;
    }
    const strModified = str.slice(0, maxLength);
    return `${strModified}...`;
  };

  return (
    <>
      {showTooltip && <TooltipChart text={value} top={top} left={left} />}
      <div className='bar-chart-comparison'>
        <Bar options={options} data={data} ref={chartRef} style={{ maxWidth: '100%' }} />
      </div>
    </>
  );
}

export default BarChartComparison;
