import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATADIS_HOW_TO } from '../../../../constants';
import useErrors from '../../../../customHooks/useErrors';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { FormDataDatadisConnection } from '../../../../types/utils/datadisConnection';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';

type Props = {
  handleSubmit: (formData: FormDataDatadisConnection) => Promise<void>;
  error: ErrorType | null;
  formDataFilled?: FormDataDatadisConnection;
  firstNif: boolean;
};

const emptyFormData: FormDataDatadisConnection = {
  nif: '',
  errors: []
};

const Form = ({ handleSubmit, error, formDataFilled, firstNif }: Props) => {
  const { t, i18n } = useTranslation();
  const ERRORS = useErrors();

  const [formData, setFormData] = useState<FormDataDatadisConnection>(
    formDataFilled || emptyFormData
  );

  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  const handleClickSubmit = async () => {
    if (loadingButton || handleErrors()) return;
    setLoadingButton(true);
    await handleSubmit(formData);
    setLoadingButton(false);
  };

  const renderError = () => {
    if (!error) return null;

    if (error.error === ERRORS.DATADIS.NOT_REGISTERED.error) {
      const lang = i18n.resolvedLanguage === 'es' ? 'es' : 'en';
      return (
        <span className='error-text error-font error-text-color'>
          {error.description}{' '}
          <a
            href={DATADIS_HOW_TO[lang]}
            className='highlight-text-color'
            target='_blank'
            rel='noreferrer'>
            {t('datadisConnection.registerYourself')}
          </a>
        </span>
      );
    }
    return <span className='error-text error-font error-text-color'>{error.description}</span>;
  };
  firstNif;

  return (
    <>
      <FormWrapper>
        <FormText
          icon={'/images/icons/suitcase.svg'}
          placeholder={t('datadisConnection.nifPlaceholder')}
          label={t('datadisConnection.nifLabel')}
          tooltip={
            firstNif ? t('datadisConnection.nifTooltip') : t('datadisConnection.nifTooltip2')
          }
          value={formData.nif}
          onChange={onChangeValue('nif')}
          error={formData.errors.find((error) => error.error === 'nif')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={
            loadingButton ? t('datadisConnection.nifValidationInProgress') : t('employees.save')
          }
          onClick={handleClickSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {renderError()}
    </>
  );
};

export default Form;
