import ErrorText from '../../errorText/ErrorText';
import CheckboxWrapper from '../inputUtils/checkboxWrapper/CheckboxWrapper';
import { RadioButton } from '../radioButton/RadioButton';
import FormTextArea from './formTextArea/FormTextArea';

type RadioButtonJustifiedProps<
  IDS extends string,
  T extends SelectOptionFormat<IDS>[],
  _IDS extends T[number]['id'] = T[number]['id']
> = {
  value: SelectOptionFormat<IDS>;
  onChangeRadio: (value: SelectOptionFormat) => void;
  options: T;
  onChangeJustification: (value: string) => void;
  disabled?: {
    [key in _IDS]?: boolean;
  } & { all?: boolean };
  translations?: {
    [key in _IDS]?: string;
  } & {
    justificationPlaceholder?: string;
  };
  whenToJustify: _IDS[];
  label?: string;
  tooltip?: string;
  justificationValue: string;
  error?: ErrorType;
  errorTextArea?: ErrorType;
};

export const RadioButtonsJustified = <IDS extends string, T extends SelectOptionFormat<IDS>[]>({
  translations,
  options,
  onChangeRadio,
  ...props
}: RadioButtonJustifiedProps<IDS, T>) => {
  const onChange = (option: (typeof options)[number]['id']) => () => {
    if (props.disabled?.[option] || props.disabled?.all) {
      return;
    }
    onChangeRadio(options.find((o) => o.id === option) ?? { id: '', name: '' });
  };

  return (
    <CheckboxWrapper label={props.label} tooltip={props.tooltip} disabled={props.disabled?.all}>
      {options.map((option) => (
        <RadioButton
          key={option.id}
          text={translations?.[option.id] ?? option.name}
          onChange={onChange(option.id)}
          selected={props.value.id === option.id}
          disabled={props.disabled?.[option.id] ?? props.disabled?.all}
        />
      ))}

      {props.whenToJustify.includes(props.value.id) && (
        <FormTextArea
          onChange={props.onChangeJustification}
          value={props.justificationValue}
          error={props.errorTextArea}
          placeholder={translations?.justificationPlaceholder}
        />
      )}
      <ErrorText>{props?.error?.description}</ErrorText>
    </CheckboxWrapper>
  );
};
