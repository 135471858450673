import ReportTable from '../reportTable/ReportTable';
import './styles.scss';

type Props = React.ComponentProps<typeof ReportTable>;

const ReportTableStandard = ({ ...props }: Props) => {
  return <ReportTable {...props} className={'table-standard'} />;
};

export default ReportTableStandard;
