import TooltipWrapper from '../../tooltip/TooltipWrapper';
type ToggleOption = {
  id: string;
  name: string;
  tooltip?: string;
};
type Props = {
  value: ToggleOption;
  option: ToggleOption;
  tooltipPosition?: 'top' | 'left';
};
const ToggleElement = ({ value, option, tooltipPosition = 'top' }: Props) => {
  return (
    <div
      key={option.id}
      className={`status-switch ${
        option.id === value.id ? 'highlight-bg-color on-dark-text-color' : 'disabled-text-color'
      }`}>
      <TooltipWrapper text={option.tooltip ?? ''} position={tooltipPosition} size='small'>
        <span className='body1-font'>{option.name}</span>
      </TooltipWrapper>
    </div>
  );
};

export default ToggleElement;
