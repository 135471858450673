import { t } from 'i18next';
import { useState } from 'react';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { GroupingFormData } from '../../../../types/entities/grouping';

import checkFormErrors from '../../../../utils/checkFormErrors';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';

type Props = {
  formData: GroupingFormData;
  setFormData: any;
  onSubmit: () => void;
};
const GroupingForm = ({ formData, setFormData, onSubmit }: Props) => {
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const [loadingButton, setLoadingButton] = useState(false);

  const handleErrors = () => {
    const optionalFields = ['description'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    if (newErrors.length > 0 && formData) {
      setFormData({
        ...formData,
        errors: newErrors
      });
    }
    if (newErrors.length !== 0) return newErrors;
    return false;
  };

  const handleSubmit = async () => {
    if (loadingButton || handleErrors()) return;

    setLoadingButton(true);
    await onSubmit();
    setLoadingButton(false);
  };

  const generalError = formData.errors.find((elem) => elem.error === 'general');
  return (
    <div>
      <FormWrapper>
        <FormText
          icon='/images/icons/folder.svg'
          label={t('grouping.name')}
          placeholder={t('grouping.writeName')}
          onChange={onChangeValue('name')}
          value={formData.name}
        />
        <FormText
          icon='/images/icons/bulletList.svg'
          label={t('grouping.description')}
          placeholder={t('grouping.writeDescription')}
          onChange={onChangeValue('description')}
          value={formData.description}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('grouping.save')}
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {generalError && (
        <span className='body1-font error-text-color'>{generalError.description}</span>
      )}
    </div>
  );
};

export default GroupingForm;
