import InputNumber from '../../../ui/formComponents/inputNumber/InputNumber';
import './styles.scss';

type Props = {
  icon: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
};
function BudgetInput({ icon, label, value, onChange }: Props) {
  return (
    <div className='budget-input'>
      <img src={icon} alt={label} />
      <label className='headline4-font' htmlFor={label}>
        {label}
      </label>
      <InputNumber icon={''} placeholder={''} value={value} onChangeValue={onChange} />
    </div>
  );
}

export default BudgetInput;
