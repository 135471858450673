const Layout: React.FC = ({ children }) => {
  return (
    <div
      className='absolute'
      style={{
        top: '-1.75rem',
        right: '0.5rem'
      }}>
      {children}
    </div>
  );
};

export const Toolbar = {
  Layout
};
