import PoweredByDcycle from '../../layout/poweredByDcycle/PoweredByDcycle';
import './styles.scss';
type Props = {
  children: React.ReactNode;
  img?: string;
  title: string;
  subtitle: string;
};
const OnboardingImageWrapper = ({ children, img, title, subtitle }: Props) => {
  return (
    <section
      className={`onboarding-image-wrapper ${!img ? 'onboarding-image-wrapper-center' : ''}`}>
      <img className='main-logo' src='/images/logoBlack.svg' alt='logo' />
      <div className='onboarding-container'>
        <div className='left-section'>
          <div className='left-section__header'>
            <h1 className='headline1-font on-light-text-color'>{title}</h1>
            <h3 className='subtitle1-font on-light-text-color'>{subtitle}</h3>
          </div>
          {children}
        </div>
        {img ? (
          <div className='right-section'>
            <img src={img} alt='onboarding' />
          </div>
        ) : null}
      </div>
      {process.env.REACT_APP_PARTNER !== 'dcycle' && <PoweredByDcycle />}
    </section>
  );
};

export default OnboardingImageWrapper;
