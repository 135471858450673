export const createCSVToSendByEmail = (type: string) => {
  const csv = ['employee_1@gmail.com', 'employee_2@gmail.com', 'employee_3@gmail.com'].join('\n');
  window.URL = window.webkitURL || window.URL;

  const contentType = 'text/csv';

  const csvFile = new Blob([csv], { type: contentType });
  // anchor link
  const element = document.createElement('a');
  element.href = URL.createObjectURL(csvFile);
  element.download = `${type}.csv`;

  // simulate link click
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};
