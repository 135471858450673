import { formatDate } from '../../../utils/formatDate';
import './styles.scss';
type Props = {
  index: number;
  removeFile?: (index: number) => void;
  file: File;
  icon: string;
  createdAt?: Date;
  fileUrl?: string;
  error?: string;
};

function FileItem({ file, index, removeFile, icon, createdAt, fileUrl, error = '' }: Props) {
  const handleRemoveFile = () => {
    if (removeFile) {
      removeFile(index);
    }
  };

  const downloadFile = () => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  };

  return (
    <div className='file-item'>
      <div className='file-icon-wrapper'>
        <img src={icon} alt='invoice-uploaded' />
        <span className={`subtitle3-font ${error ? 'error-text-color' : ''}`}>{file.name}</span>
        {error && <span className='error-text-color subtitle3-font'>({error})</span>}
      </div>
      {createdAt && <span className='subtitle3-font'>{formatDate(createdAt)}</span>}
      {fileUrl && (
        <img
          src='/images/icons/downloadGradient.svg'
          alt='download'
          onClick={downloadFile}
          className='download'
        />
      )}
      {removeFile && (
        <img
          src='/images/icons/close.svg'
          alt='close'
          onClick={handleRemoveFile}
          className='pointer'
        />
      )}
    </div>
  );
}

export default FileItem;
