import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '../../ui/accordion/Accordion';
import FilterCheckbox from './FilterCheckbox';

export type FilterOptionType = SelectOptionFormat & { type?: string };

export type FilterSectionType = {
  title: string;
  options: FilterOptionType[];
  elToShow: number;
};

const FilterSection = ({ title, options, elToShow }: FilterSectionType) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<{
    visibles: FilterOptionType[];
    hidden?: FilterOptionType[];
  } | null>(null);

  useEffect(() => {
    if (elToShow) {
      const hidden = options.splice(options.length - elToShow);
      const visibles = options;
      setItems({ visibles, hidden });
    } else {
      const visibles = options;
      setItems({ visibles });
    }
  }, [options]);
  return (
    <div className='filters__section'>
      <section className='filters__options'>
        <Accordion>
          <Accordion.Item
            label={title}
            iconLeft
            active
            content={
              <>
                {items?.visibles.map((item) => (
                  <FilterCheckbox item={item} key={`filter-${item.id}`} />
                ))}
                {items?.hidden?.length && items?.hidden?.length > 0 && (
                  <Accordion>
                    <Accordion.Item
                      highlight
                      label={`${t('general.filters.show')} (${items?.hidden?.length})`}
                      content={items?.hidden?.map((item) => (
                        <FilterCheckbox item={item} key={`filter-${item.id}`} />
                      ))}
                      closeFromDown
                    />
                  </Accordion>
                )}
              </>
            }
          />
        </Accordion>
      </section>
    </div>
  );
};

export default FilterSection;
