import React from 'react';
import formatNumber from '../../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import ButtonDropdown from '../../buttonDropdown/ButtonDropdown';
import Icon from '../../icon/Icon';
import IconBackground from '../../iconBackground/IconBackground';
import './styles.scss';

type Props = {
  id: string;
  title: string;
  description?: string;
  total?: number;
  unit: string;
  icon: string;
  onClickCard: (id: string) => void;
  dropDownOptions: DropDownActionOption[];
  button?: React.ReactNode; // It goes on the top right corner
  listView: boolean;
  tag?: string;
};
function Card({
  id,
  title,
  description,
  total,
  unit,
  onClickCard,
  dropDownOptions,
  button,
  icon,
  listView,
  tag
}: Props) {
  const handleOnClickCard = () => {
    onClickCard(id);
  };

  return (
    <div
      className={`${
        listView ? 'card-dropdown-list' : 'card-dropdown'
      } main-bg-color card-border-color`}
      onClick={handleOnClickCard}>
      {tag && (
        <div className='text-tag body2-font tag-bg-text-color corner-position tag-border-color'>
          {tag}
        </div>
      )}
      {dropDownOptions && dropDownOptions.length > 0 ? (
        <ButtonDropdown
          button={<Icon icon='elipsis_horizontal' color='gray-dark' />}
          options={dropDownOptions}
        />
      ) : null}
      {button ? <div className='button-wrapper'>{button}</div> : null}
      <div
        className={`${
          listView ? 'card-dropdown-list__content-wrapper' : 'card-dropdown__content-wrapper'
        }`}>
        {icon && <IconBackground icon={icon} alt={title} />}
        <h1 className='headline4-font'>{title}</h1>
        {description && <p className='subtitle3-font'>{description}</p>}
        {total !== undefined && total >= 0 && (
          <div className='total-info'>
            <span className='highlight-text-color small-card-font'>
              {formatNumber(numberToDecimalNonZero(total, 2))}
            </span>
            {unit && <p className='body1-font'>{unit}</p>}
          </div>
        )}
      </div>
    </div>
  );
}

export default Card;
