import { useContext, useEffect, useState } from 'react';
import { DATA_QUALITY_THRESHOLD } from '../../../../../constants';
import { FilterDatesContext } from '../../../../../context/filterDatesContext';
import { UserContext } from '../../../../../context/userContext';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { getDataQuality } from '../../../../../services/api/data_quality';
import { getOrganizationSectorComparison } from '../../../../../services/api/organization';
import { convertStringToDate } from '../../../../../utils/convertDates';
import numberToDecimal from '../../../../../utils/numberToDecimal';

const useGetData = () => {
  const [dataQualityPercentage, setDataQualityPercentage] = useState(0);
  const [sectorComparison, setSectorComparison] = useState(0);
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext);
  const { endDate } = useContext(FilterDatesContext);

  const selectedOrganization = useSelectedOrganization();

  const fetchSectorComparison = async (dataQuality: number) => {
    if (dataQuality < DATA_QUALITY_THRESHOLD) return null;

    const data = await getOrganizationSectorComparison(
      selectedOrganization?.id ?? '',
      convertStringToDate(endDate)
    );

    if (data) {
      setSectorComparison(data);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const dataQualityPercentageResponse = await getDataQuality(user?.selectedOrganization ?? '');

    setDataQualityPercentage(numberToDecimal(dataQualityPercentageResponse.data_quality_value));

    await fetchSectorComparison(dataQualityPercentageResponse.data_quality_value);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { dataQualityPercentage, sectorComparison, loading };
};

export default useGetData;
