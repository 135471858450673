import LanguageItem from './languageItem/LanguageItem';
import './styles.scss';

const LanguageSelector = () => {
  const languages = ['en', 'es', 'pt'];
  return (
    <div className='language-selector'>
      {languages.map((lang) => (
        <LanguageItem lang={lang} key={lang} />
      ))}
    </div>
  );
};

export default LanguageSelector;
