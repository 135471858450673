import { useTranslation } from 'react-i18next';
import { EventMiscellanous } from '../../../../../../types/entities/events';
import InputNumber from '../../../../../ui/formComponents/inputNumber/InputNumber';

type Props = {
  type: string;
  value: string;
  icon: string;
  onChangeValue: (value: EventMiscellanous) => void;
};
const InputPurchase = ({ type, icon, value, onChangeValue }: Props) => {
  const { t } = useTranslation();
  const onChangeQuantity = (quantity: string) => {
    onChangeValue({
      type,
      quantity: +quantity
    });
  };
  return (
    <InputNumber
      tooltip={type === 'paper' ? t('eventData.purchasesTooltip') : ''}
      label={t(`eventData.purchasesTypes.${type}`)}
      icon={icon}
      placeholder={'0'}
      value={value}
      onChangeValue={onChangeQuantity}></InputNumber>
  );
};

export default InputPurchase;
