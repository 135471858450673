import { MouseEvent } from 'react';
import './styles.scss';

type Props = {
  value: SelectOptionFormat;
  onClickOption: (option: any) => void;
  fontClass?: string;
  height?: string;
  hideShowOptions: () => void;
  disabled?: boolean;
};

const ButtonDropdownOption = ({
  value,
  onClickOption,
  fontClass = 'input-font',
  hideShowOptions,
  disabled
}: Props) => {
  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (disabled) return;

    onClickOption(value);
    hideShowOptions();
  };

  const classes = ['button-dropdown-options__item'];

  const deleteOption = value.id.includes('delete') ? 'strong-error-text-color' : '';

  if (disabled) {
    classes.push('disabled disabled-text-color');
  } else {
    classes.push('on-light-text-color');
  }

  classes.push(fontClass);

  if (deleteOption) {
    classes.push(deleteOption);
  }

  return (
    <div className={classes.join(' ')} key={value.id} onClick={handleOnClick}>
      {value.name}
    </div>
  );
};

export default ButtonDropdownOption;
