import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardLogistic.tocColumns' });
  return [
    {
      title: t('vehicleId'),
      dataIndex: 'vehicleId',
      key: 'vehicleId'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('tonsTransported')}</div>,
      dataIndex: 'tonsTransported',
      key: 'tonsTransported'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('kmEmpty')}</div>,
      dataIndex: 'kmEmpty',
      key: 'kmEmpty'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('kmTotal')}</div>,
      dataIndex: 'kmTotal',
      key: 'kmTotal'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('intensityValue')}</div>,
      dataIndex: 'intensityValue',
      key: 'intensityValue'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('tkm')}</div>,
      dataIndex: 'tkm',
      key: 'tkm'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('kgCO2e')}</div>,
      dataIndex: 'kgCO2e',
      key: 'kgCO2e'
    }
  ];
};

export default useColumns;
