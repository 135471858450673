import React, { createContext } from 'react';
import { SideModal } from '../../../layout/sideModal/SideModal';
import { CreateNode } from './CreateNode/CreateNode';
import { ConfigureReferenceOutputModal } from './ConfigureReferenceOutputModal/ConfigureReferenceOutputModal';
import { useACVId } from '../hooks/useACVId';
import { ConfigureProcessModal } from './ConfigureProcessModal/ConfigureProcessModal';
import { SelectMaterialModal } from './SelectMaterialModal/SelectMaterialModal';
import { ModalData } from '../types/LCAScenarios';

type ModalContextType = {
  modalData?: ModalData;
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

type RootProps = {
  modalData?: ModalData;
  setModalData: React.Dispatch<React.SetStateAction<ModalData | undefined>>;
};
const Root: React.FC<RootProps> = ({ children, modalData, setModalData }) => {
  return (
    <ModalContext.Provider value={{ modalData, setModalData }}>{children}</ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

type MainProps = {
  final_product: string;
};

const Main: React.FC<MainProps> = ({ final_product }) => {
  const { modalData, setModalData } = useModalContext();
  const { acv_id } = useACVId();

  const handleClose = () => {
    setModalData(undefined);
  };

  return (
    <>
      <SideModal.FullHeightBase isOpen={modalData?.type === 'create_node'} setIsOpen={handleClose}>
        {modalData?.type === 'create_node' && <CreateNode />}
      </SideModal.FullHeightBase>

      {modalData?.type === 'edit_material' && <SelectMaterialModal final_product={final_product} />}

      {modalData?.type === 'edit_process' && <ConfigureProcessModal />}

      <SideModal.FullHeightBase
        isOpen={Boolean(modalData?.target) && modalData?.type === 'reference_output'}
        setIsOpen={handleClose}>
        {modalData?.type === 'reference_output' && (
          <ConfigureReferenceOutputModal acv_id={acv_id} />
        )}
      </SideModal.FullHeightBase>
    </>
  );
};

export const ModalHandler = {
  Root,
  Main
};
