import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  handleOnClick: () => void;
};

const LinkToCSV = ({ handleOnClick }: Props) => {
  const { t } = useTranslation();
  return (
    <h3 className='body1-bold-font link-to-csv' onClick={handleOnClick}>
      {t('sendEmailForm.csv')}
    </h3>
  );
};

export default LinkToCSV;
