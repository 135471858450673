import apiFetch from '../../../../../utils/apiFetch';
import { LCAItem } from '../../../types/LCAItem';

const URL = '/lca/portfolio';
const OLD_URL = '/acv/portfolio';

export const getLCADetail = async (id?: string, old_flow?: boolean): Promise<LCAItem> => {
  if (!id) {
    throw new Error('No id provided');
  }

  const url = old_flow ? OLD_URL : URL;

  const response = await apiFetch('GET', `${url}/${id}`);

  return response.data;
};
