import { useQuery } from '@tanstack/react-query';
import { getLCAFolders } from '../services/getLCAFolders';

type Params = {
  id?: string;
  methodologyId?: string;
  impactCategory?: string;
};

export const useLCAFolders = ({ id, methodologyId, impactCategory }: Params) => {
  const { data, isLoading } = useQuery({
    queryKey: ['lca', 'folders', id, methodologyId, impactCategory],
    queryFn: () => getLCAFolders({ id, methodologyId, impactCategory }),
    enabled: Boolean(id && methodologyId && impactCategory)
  });

  return { data, isLoading };
};
