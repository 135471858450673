import { EmployeeReport } from '../../../../../../types/entities/employee';

const getIndexHalfEmission = (employees: EmployeeReport[], totalCo2: number) => {
  let total = 0;
  let employeesWithMostEmissions = 0;

  for (let i = 0; i < employees.length; i++) {
    total += employees[i].co2e;
    employeesWithMostEmissions += 1;
    if (total > totalCo2 / 2) break;
  }

  return employeesWithMostEmissions;
};

export { getIndexHalfEmission };
