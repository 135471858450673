export const createSlackMessage = (userEmail: string, organization: string) => {
  return {
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `El usuario ${userEmail} ha entrado en la pantalla budget-calculator a las ${new Date().toLocaleTimeString()}`
        }
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Usuario:*\n${userEmail}`
          },
          {
            type: 'mrkdwn',
            text: `*Organización:*\n${organization}`
          },
          {
            type: 'mrkdwn',
            text: `*Date:*\n${new Date().toDateString()}`
          }
        ]
      }
    ]
  };
};
