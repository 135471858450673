export const sectorsEquivalence = [
  { sector: 'administrative', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'administrative', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'administrative', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'administrative', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'administrative', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'administrative', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'administrative', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'administrative', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'administrative', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'administrative', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'administrative', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'administrative', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'administrative', size: 'small', year: 2011, co2_avg_s12: 134.88 },
  { sector: 'administrative', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'administrative', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'administrative', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'administrative', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'administrative', size: 'medium', year: 2012, co2_avg_s12: 828.15 },
  { sector: 'administrative', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'administrative', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'administrative', size: 'small', year: 2013, co2_avg_s12: 59.69 },
  { sector: 'administrative', size: 'medium', year: 2013, co2_avg_s12: 804.01 },
  { sector: 'administrative', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'administrative', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'administrative', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'administrative', size: 'medium', year: 2014, co2_avg_s12: 1939.35 },
  { sector: 'administrative', size: 'micro', year: 2014, co2_avg_s12: 28.73 },
  { sector: 'administrative', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'administrative', size: 'small', year: 2015, co2_avg_s12: 285.93 },
  { sector: 'administrative', size: 'medium', year: 2015, co2_avg_s12: 673.28 },
  { sector: 'administrative', size: 'micro', year: 2015, co2_avg_s12: 50.3 },
  { sector: 'administrative', size: 'large', year: 2015, co2_avg_s12: 86307.78 },
  { sector: 'administrative', size: 'small', year: 2016, co2_avg_s12: 743.06 },
  { sector: 'administrative', size: 'medium', year: 2016, co2_avg_s12: 1232.24 },
  { sector: 'administrative', size: 'micro', year: 2016, co2_avg_s12: 62.87 },
  { sector: 'administrative', size: 'large', year: 2016, co2_avg_s12: 106029.14 },
  { sector: 'administrative', size: 'small', year: 2017, co2_avg_s12: 175.73 },
  { sector: 'administrative', size: 'medium', year: 2017, co2_avg_s12: 465.67 },
  { sector: 'administrative', size: 'micro', year: 2017, co2_avg_s12: 11.81 },
  { sector: 'administrative', size: 'large', year: 2017, co2_avg_s12: 21149.44 },
  { sector: 'administrative', size: 'small', year: 2018, co2_avg_s12: 195.72 },
  { sector: 'administrative', size: 'medium', year: 2018, co2_avg_s12: 795.23 },
  { sector: 'administrative', size: 'micro', year: 2018, co2_avg_s12: 21.24 },
  { sector: 'administrative', size: 'large', year: 2018, co2_avg_s12: 18732.83 },
  { sector: 'administrative', size: 'small', year: 2019, co2_avg_s12: 245.74 },
  { sector: 'administrative', size: 'medium', year: 2019, co2_avg_s12: 601.9 },
  { sector: 'administrative', size: 'micro', year: 2019, co2_avg_s12: 8.9 },
  { sector: 'administrative', size: 'large', year: 2019, co2_avg_s12: 11998.77 },
  { sector: 'administrative', size: 'small', year: 2020, co2_avg_s12: 211.69 },
  { sector: 'administrative', size: 'medium', year: 2020, co2_avg_s12: 624.85 },
  { sector: 'administrative', size: 'micro', year: 2020, co2_avg_s12: 2393.62 },
  { sector: 'administrative', size: 'large', year: 2020, co2_avg_s12: 12526.25 },
  { sector: 'administrative', size: 'small', year: 2021, co2_avg_s12: 82.92 },
  { sector: 'administrative', size: 'medium', year: 2021, co2_avg_s12: 370.7 },
  { sector: 'administrative', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'administrative', size: 'large', year: 2021, co2_avg_s12: 5334.06 },
  { sector: 'artistic', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'artistic', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'artistic', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'artistic', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'artistic', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'artistic', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'artistic', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'artistic', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'artistic', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'artistic', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'artistic', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'artistic', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'artistic', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'artistic', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'artistic', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'artistic', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'artistic', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'artistic', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'artistic', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'artistic', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'artistic', size: 'small', year: 2013, co2_avg_s12: 535.24 },
  { sector: 'artistic', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'artistic', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'artistic', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'artistic', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'artistic', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'artistic', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'artistic', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'artistic', size: 'small', year: 2015, co2_avg_s12: 407.78 },
  { sector: 'artistic', size: 'medium', year: 2015, co2_avg_s12: 3173.81 },
  { sector: 'artistic', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'artistic', size: 'large', year: 2015, co2_avg_s12: 1361 },
  { sector: 'artistic', size: 'small', year: 2016, co2_avg_s12: 213.05 },
  { sector: 'artistic', size: 'medium', year: 2016, co2_avg_s12: 39140.65 },
  { sector: 'artistic', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'artistic', size: 'large', year: 2016, co2_avg_s12: 942624.56 },
  { sector: 'artistic', size: 'small', year: 2017, co2_avg_s12: 216.91 },
  { sector: 'artistic', size: 'medium', year: 2017, co2_avg_s12: 14868.13 },
  { sector: 'artistic', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'artistic', size: 'large', year: 2017, co2_avg_s12: 1007 },
  { sector: 'artistic', size: 'small', year: 2018, co2_avg_s12: 278.96 },
  { sector: 'artistic', size: 'medium', year: 2018, co2_avg_s12: 7184.57 },
  { sector: 'artistic', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'artistic', size: 'large', year: 2018, co2_avg_s12: 843261.43 },
  { sector: 'artistic', size: 'small', year: 2019, co2_avg_s12: 188.83 },
  { sector: 'artistic', size: 'medium', year: 2019, co2_avg_s12: 6601.47 },
  { sector: 'artistic', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'artistic', size: 'large', year: 2019, co2_avg_s12: 67448.57 },
  { sector: 'artistic', size: 'small', year: 2020, co2_avg_s12: 184 },
  { sector: 'artistic', size: 'medium', year: 2020, co2_avg_s12: 5654.31 },
  { sector: 'artistic', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'artistic', size: 'large', year: 2020, co2_avg_s12: 73043.14 },
  { sector: 'artistic', size: 'small', year: 2021, co2_avg_s12: 239.02 },
  { sector: 'artistic', size: 'medium', year: 2021, co2_avg_s12: 1004.77 },
  { sector: 'artistic', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'artistic', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'domestic', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'domestic', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'domestic', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'domestic', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'domestic', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'domestic', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'domestic', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'domestic', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'domestic', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'domestic', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'domestic', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'domestic', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'domestic', size: 'small', year: 2011, co2_avg_s12: 100.94 },
  { sector: 'domestic', size: 'medium', year: 2011, co2_avg_s12: 77.22 },
  { sector: 'domestic', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'domestic', size: 'large', year: 2011, co2_avg_s12: 62719.58 },
  { sector: 'domestic', size: 'small', year: 2012, co2_avg_s12: 2531.97 },
  { sector: 'domestic', size: 'medium', year: 2012, co2_avg_s12: 2556.91 },
  { sector: 'domestic', size: 'micro', year: 2012, co2_avg_s12: 122.08 },
  { sector: 'domestic', size: 'large', year: 2012, co2_avg_s12: 43299.8 },
  { sector: 'domestic', size: 'small', year: 2013, co2_avg_s12: 693 },
  { sector: 'domestic', size: 'medium', year: 2013, co2_avg_s12: 1893.46 },
  { sector: 'domestic', size: 'micro', year: 2013, co2_avg_s12: 76.58 },
  { sector: 'domestic', size: 'large', year: 2013, co2_avg_s12: 37837.25 },
  { sector: 'domestic', size: 'small', year: 2014, co2_avg_s12: 464.47 },
  { sector: 'domestic', size: 'medium', year: 2014, co2_avg_s12: 1330.19 },
  { sector: 'domestic', size: 'micro', year: 2014, co2_avg_s12: 17.23 },
  { sector: 'domestic', size: 'large', year: 2014, co2_avg_s12: 33164.39 },
  { sector: 'domestic', size: 'small', year: 2015, co2_avg_s12: 119.21 },
  { sector: 'domestic', size: 'medium', year: 2015, co2_avg_s12: 1711.05 },
  { sector: 'domestic', size: 'micro', year: 2015, co2_avg_s12: 101.27 },
  { sector: 'domestic', size: 'large', year: 2015, co2_avg_s12: 23219.38 },
  { sector: 'domestic', size: 'small', year: 2016, co2_avg_s12: 1549.39 },
  { sector: 'domestic', size: 'medium', year: 2016, co2_avg_s12: 4305.67 },
  { sector: 'domestic', size: 'micro', year: 2016, co2_avg_s12: 96.27 },
  { sector: 'domestic', size: 'large', year: 2016, co2_avg_s12: 151747.26 },
  { sector: 'domestic', size: 'small', year: 2017, co2_avg_s12: 1794.85 },
  { sector: 'domestic', size: 'medium', year: 2017, co2_avg_s12: 195265.74 },
  { sector: 'domestic', size: 'micro', year: 2017, co2_avg_s12: 107.68 },
  { sector: 'domestic', size: 'large', year: 2017, co2_avg_s12: 116486.22 },
  { sector: 'domestic', size: 'small', year: 2018, co2_avg_s12: 16002.16 },
  { sector: 'domestic', size: 'medium', year: 2018, co2_avg_s12: 2004.85 },
  { sector: 'domestic', size: 'micro', year: 2018, co2_avg_s12: 38.1 },
  { sector: 'domestic', size: 'large', year: 2018, co2_avg_s12: 119366.02 },
  { sector: 'domestic', size: 'small', year: 2019, co2_avg_s12: 275.6 },
  { sector: 'domestic', size: 'medium', year: 2019, co2_avg_s12: 1709.3 },
  { sector: 'domestic', size: 'micro', year: 2019, co2_avg_s12: 18.53 },
  { sector: 'domestic', size: 'large', year: 2019, co2_avg_s12: 72568.65 },
  { sector: 'domestic', size: 'small', year: 2020, co2_avg_s12: 248.12 },
  { sector: 'domestic', size: 'medium', year: 2020, co2_avg_s12: 1242.74 },
  { sector: 'domestic', size: 'micro', year: 2020, co2_avg_s12: 1012.28 },
  { sector: 'domestic', size: 'large', year: 2020, co2_avg_s12: 59225.8 },
  { sector: 'domestic', size: 'small', year: 2021, co2_avg_s12: 237.49 },
  { sector: 'domestic', size: 'medium', year: 2021, co2_avg_s12: 773.55 },
  { sector: 'domestic', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'domestic', size: 'large', year: 2021, co2_avg_s12: 27996.68 },
  { sector: 'extraterritorial', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'extraterritorial', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'extraterritorial', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'extraterritorial', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'extraterritorial', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'extraterritorial', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'extraterritorial', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'extraterritorial', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'extraterritorial', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'extraterritorial', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'extraterritorial', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'extraterritorial', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'extraterritorial', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'extraterritorial', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'extraterritorial', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'extraterritorial', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'extraterritorial', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'extraterritorial', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'extraterritorial', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'extraterritorial', size: 'large', year: 2012, co2_avg_s12: 37598 },
  { sector: 'extraterritorial', size: 'small', year: 2013, co2_avg_s12: 535.24 },
  { sector: 'extraterritorial', size: 'medium', year: 2013, co2_avg_s12: 31.47 },
  { sector: 'extraterritorial', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'extraterritorial', size: 'large', year: 2013, co2_avg_s12: 17027 },
  { sector: 'extraterritorial', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'extraterritorial', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'extraterritorial', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'extraterritorial', size: 'large', year: 2014, co2_avg_s12: 17260 },
  { sector: 'extraterritorial', size: 'small', year: 2015, co2_avg_s12: 407.78 },
  { sector: 'extraterritorial', size: 'medium', year: 2015, co2_avg_s12: 958.57 },
  { sector: 'extraterritorial', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'extraterritorial', size: 'large', year: 2015, co2_avg_s12: 18453 },
  { sector: 'extraterritorial', size: 'small', year: 2016, co2_avg_s12: 469.35 },
  { sector: 'extraterritorial', size: 'medium', year: 2016, co2_avg_s12: 897.94 },
  { sector: 'extraterritorial', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'extraterritorial', size: 'large', year: 2016, co2_avg_s12: 13529 },
  { sector: 'extraterritorial', size: 'small', year: 2017, co2_avg_s12: 879.44 },
  { sector: 'extraterritorial', size: 'medium', year: 2017, co2_avg_s12: 899.49 },
  { sector: 'extraterritorial', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'extraterritorial', size: 'large', year: 2017, co2_avg_s12: 14537 },
  { sector: 'extraterritorial', size: 'small', year: 2018, co2_avg_s12: 1213.87 },
  { sector: 'extraterritorial', size: 'medium', year: 2018, co2_avg_s12: 828.18 },
  { sector: 'extraterritorial', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'extraterritorial', size: 'large', year: 2018, co2_avg_s12: 13132 },
  { sector: 'extraterritorial', size: 'small', year: 2019, co2_avg_s12: 28.12 },
  { sector: 'extraterritorial', size: 'medium', year: 2019, co2_avg_s12: 459.71 },
  { sector: 'extraterritorial', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'extraterritorial', size: 'large', year: 2019, co2_avg_s12: 8341 },
  { sector: 'extraterritorial', size: 'small', year: 2020, co2_avg_s12: 7.87 },
  { sector: 'extraterritorial', size: 'medium', year: 2020, co2_avg_s12: 2130.86 },
  { sector: 'extraterritorial', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'extraterritorial', size: 'large', year: 2020, co2_avg_s12: 4950 },
  { sector: 'extraterritorial', size: 'small', year: 2021, co2_avg_s12: 21.44 },
  { sector: 'extraterritorial', size: 'medium', year: 2021, co2_avg_s12: 1192.63 },
  { sector: 'extraterritorial', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'extraterritorial', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'finance', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'finance', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'finance', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'finance', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'finance', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'finance', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'finance', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'finance', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'finance', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'finance', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'finance', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'finance', size: 'large', year: 2010, co2_avg_s12: 9559.2 },
  { sector: 'finance', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'finance', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'finance', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'finance', size: 'large', year: 2011, co2_avg_s12: 7766.94 },
  { sector: 'finance', size: 'small', year: 2012, co2_avg_s12: 6614.91 },
  { sector: 'finance', size: 'medium', year: 2012, co2_avg_s12: 2851.26 },
  { sector: 'finance', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'finance', size: 'large', year: 2012, co2_avg_s12: 6539.46 },
  { sector: 'finance', size: 'small', year: 2013, co2_avg_s12: 3065.65 },
  { sector: 'finance', size: 'medium', year: 2013, co2_avg_s12: 4604.15 },
  { sector: 'finance', size: 'micro', year: 2013, co2_avg_s12: 9.4 },
  { sector: 'finance', size: 'large', year: 2013, co2_avg_s12: 49094.35 },
  { sector: 'finance', size: 'small', year: 2014, co2_avg_s12: 1051.3 },
  { sector: 'finance', size: 'medium', year: 2014, co2_avg_s12: 21767.16 },
  { sector: 'finance', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'finance', size: 'large', year: 2014, co2_avg_s12: 42587.05 },
  { sector: 'finance', size: 'small', year: 2015, co2_avg_s12: 593.73 },
  { sector: 'finance', size: 'medium', year: 2015, co2_avg_s12: 32577.82 },
  { sector: 'finance', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'finance', size: 'large', year: 2015, co2_avg_s12: 62465.7 },
  { sector: 'finance', size: 'small', year: 2016, co2_avg_s12: 802.05 },
  { sector: 'finance', size: 'medium', year: 2016, co2_avg_s12: 26496.09 },
  { sector: 'finance', size: 'micro', year: 2016, co2_avg_s12: 0.02 },
  { sector: 'finance', size: 'large', year: 2016, co2_avg_s12: 81268.18 },
  { sector: 'finance', size: 'small', year: 2017, co2_avg_s12: 688.16 },
  { sector: 'finance', size: 'medium', year: 2017, co2_avg_s12: 21325.98 },
  { sector: 'finance', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'finance', size: 'large', year: 2017, co2_avg_s12: 79831.16 },
  { sector: 'finance', size: 'small', year: 2018, co2_avg_s12: 512.41 },
  { sector: 'finance', size: 'medium', year: 2018, co2_avg_s12: 8859.41 },
  { sector: 'finance', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'finance', size: 'large', year: 2018, co2_avg_s12: 74616.13 },
  { sector: 'finance', size: 'small', year: 2019, co2_avg_s12: 1210.95 },
  { sector: 'finance', size: 'medium', year: 2019, co2_avg_s12: 13879.29 },
  { sector: 'finance', size: 'micro', year: 2019, co2_avg_s12: 86.01 },
  { sector: 'finance', size: 'large', year: 2019, co2_avg_s12: 51529.59 },
  { sector: 'finance', size: 'small', year: 2020, co2_avg_s12: 711.39 },
  { sector: 'finance', size: 'medium', year: 2020, co2_avg_s12: 16311.37 },
  { sector: 'finance', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'finance', size: 'large', year: 2020, co2_avg_s12: 69464.38 },
  { sector: 'finance', size: 'small', year: 2021, co2_avg_s12: 604.69 },
  { sector: 'finance', size: 'medium', year: 2021, co2_avg_s12: 1004.77 },
  { sector: 'finance', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'finance', size: 'large', year: 2021, co2_avg_s12: 12771.58 },
  { sector: 'real_estate', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'real_estate', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'real_estate', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'real_estate', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'real_estate', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'real_estate', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'real_estate', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'real_estate', size: 'large', year: 2009, co2_avg_s12: 495352 },
  { sector: 'real_estate', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'real_estate', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'real_estate', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'real_estate', size: 'large', year: 2010, co2_avg_s12: 323887.84 },
  { sector: 'real_estate', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'real_estate', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'real_estate', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'real_estate', size: 'large', year: 2011, co2_avg_s12: 277439.04 },
  { sector: 'real_estate', size: 'small', year: 2012, co2_avg_s12: 151.13 },
  { sector: 'real_estate', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'real_estate', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'real_estate', size: 'large', year: 2012, co2_avg_s12: 116282.62 },
  { sector: 'real_estate', size: 'small', year: 2013, co2_avg_s12: 139.17 },
  { sector: 'real_estate', size: 'medium', year: 2013, co2_avg_s12: 3042.87 },
  { sector: 'real_estate', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'real_estate', size: 'large', year: 2013, co2_avg_s12: 112754.58 },
  { sector: 'real_estate', size: 'small', year: 2014, co2_avg_s12: 113.13 },
  { sector: 'real_estate', size: 'medium', year: 2014, co2_avg_s12: 1288.46 },
  { sector: 'real_estate', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'real_estate', size: 'large', year: 2014, co2_avg_s12: 90986.55 },
  { sector: 'real_estate', size: 'small', year: 2015, co2_avg_s12: 132.69 },
  { sector: 'real_estate', size: 'medium', year: 2015, co2_avg_s12: 1187.92 },
  { sector: 'real_estate', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'real_estate', size: 'large', year: 2015, co2_avg_s12: 114394.99 },
  { sector: 'real_estate', size: 'small', year: 2016, co2_avg_s12: 283.46 },
  { sector: 'real_estate', size: 'medium', year: 2016, co2_avg_s12: 850.11 },
  { sector: 'real_estate', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'real_estate', size: 'large', year: 2016, co2_avg_s12: 104759 },
  { sector: 'real_estate', size: 'small', year: 2017, co2_avg_s12: 133.94 },
  { sector: 'real_estate', size: 'medium', year: 2017, co2_avg_s12: 1016.17 },
  { sector: 'real_estate', size: 'micro', year: 2017, co2_avg_s12: 17724.7 },
  { sector: 'real_estate', size: 'large', year: 2017, co2_avg_s12: 69459.56 },
  { sector: 'real_estate', size: 'small', year: 2018, co2_avg_s12: 251.29 },
  { sector: 'real_estate', size: 'medium', year: 2018, co2_avg_s12: 1044.82 },
  { sector: 'real_estate', size: 'micro', year: 2018, co2_avg_s12: 201.37 },
  { sector: 'real_estate', size: 'large', year: 2018, co2_avg_s12: 48558.57 },
  { sector: 'real_estate', size: 'small', year: 2019, co2_avg_s12: 1232.25 },
  { sector: 'real_estate', size: 'medium', year: 2019, co2_avg_s12: 796.39 },
  { sector: 'real_estate', size: 'micro', year: 2019, co2_avg_s12: 78.72 },
  { sector: 'real_estate', size: 'large', year: 2019, co2_avg_s12: 43743.17 },
  { sector: 'real_estate', size: 'small', year: 2020, co2_avg_s12: 137.48 },
  { sector: 'real_estate', size: 'medium', year: 2020, co2_avg_s12: 651.95 },
  { sector: 'real_estate', size: 'micro', year: 2020, co2_avg_s12: 97.12 },
  { sector: 'real_estate', size: 'large', year: 2020, co2_avg_s12: 16378.63 },
  { sector: 'real_estate', size: 'small', year: 2021, co2_avg_s12: 134.28 },
  { sector: 'real_estate', size: 'medium', year: 2021, co2_avg_s12: 1080.2 },
  { sector: 'real_estate', size: 'micro', year: 2021, co2_avg_s12: 116.78 },
  { sector: 'real_estate', size: 'large', year: 2021, co2_avg_s12: 212419.61 },
  { sector: 'scientific', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'scientific', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'scientific', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'scientific', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'scientific', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'scientific', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'scientific', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'scientific', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'scientific', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'scientific', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'scientific', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'scientific', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'scientific', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'scientific', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'scientific', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'scientific', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'scientific', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'scientific', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'scientific', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'scientific', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'scientific', size: 'small', year: 2013, co2_avg_s12: 130.3 },
  { sector: 'scientific', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'scientific', size: 'micro', year: 2013, co2_avg_s12: 4.38 },
  { sector: 'scientific', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'scientific', size: 'small', year: 2014, co2_avg_s12: 19.4 },
  { sector: 'scientific', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'scientific', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'scientific', size: 'large', year: 2014, co2_avg_s12: 456.69 },
  { sector: 'scientific', size: 'small', year: 2015, co2_avg_s12: 55.37 },
  { sector: 'scientific', size: 'medium', year: 2015, co2_avg_s12: 272.78 },
  { sector: 'scientific', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'scientific', size: 'large', year: 2015, co2_avg_s12: 467.59 },
  { sector: 'scientific', size: 'small', year: 2016, co2_avg_s12: 139.97 },
  { sector: 'scientific', size: 'medium', year: 2016, co2_avg_s12: 544.87 },
  { sector: 'scientific', size: 'micro', year: 2016, co2_avg_s12: 0.75 },
  { sector: 'scientific', size: 'large', year: 2016, co2_avg_s12: 672.88 },
  { sector: 'scientific', size: 'small', year: 2017, co2_avg_s12: 6291.41 },
  { sector: 'scientific', size: 'medium', year: 2017, co2_avg_s12: 326.37 },
  { sector: 'scientific', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'scientific', size: 'large', year: 2017, co2_avg_s12: 48241.2 },
  { sector: 'scientific', size: 'small', year: 2018, co2_avg_s12: 105.12 },
  { sector: 'scientific', size: 'medium', year: 2018, co2_avg_s12: 359.29 },
  { sector: 'scientific', size: 'micro', year: 2018, co2_avg_s12: 55.9 },
  { sector: 'scientific', size: 'large', year: 2018, co2_avg_s12: 725.74 },
  { sector: 'scientific', size: 'small', year: 2019, co2_avg_s12: 64.45 },
  { sector: 'scientific', size: 'medium', year: 2019, co2_avg_s12: 362.77 },
  { sector: 'scientific', size: 'micro', year: 2019, co2_avg_s12: 24.57 },
  { sector: 'scientific', size: 'large', year: 2019, co2_avg_s12: 20700.66 },
  { sector: 'scientific', size: 'small', year: 2020, co2_avg_s12: 60.57 },
  { sector: 'scientific', size: 'medium', year: 2020, co2_avg_s12: 144.43 },
  { sector: 'scientific', size: 'micro', year: 2020, co2_avg_s12: 34.82 },
  { sector: 'scientific', size: 'large', year: 2020, co2_avg_s12: 551.42 },
  { sector: 'scientific', size: 'small', year: 2021, co2_avg_s12: 46.53 },
  { sector: 'scientific', size: 'medium', year: 2021, co2_avg_s12: 132.47 },
  { sector: 'scientific', size: 'micro', year: 2021, co2_avg_s12: 25.13 },
  { sector: 'scientific', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'sanitary', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'sanitary', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'sanitary', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'sanitary', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'sanitary', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'sanitary', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'sanitary', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'sanitary', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'sanitary', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'sanitary', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'sanitary', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'sanitary', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'sanitary', size: 'small', year: 2011, co2_avg_s12: 882.05 },
  { sector: 'sanitary', size: 'medium', year: 2011, co2_avg_s12: 280.95 },
  { sector: 'sanitary', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'sanitary', size: 'large', year: 2011, co2_avg_s12: 19975.79 },
  { sector: 'sanitary', size: 'small', year: 2012, co2_avg_s12: 1147.66 },
  { sector: 'sanitary', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'sanitary', size: 'micro', year: 2012, co2_avg_s12: 48.65 },
  { sector: 'sanitary', size: 'large', year: 2012, co2_avg_s12: 16149.02 },
  { sector: 'sanitary', size: 'small', year: 2013, co2_avg_s12: 1202.41 },
  { sector: 'sanitary', size: 'medium', year: 2013, co2_avg_s12: 1726.11 },
  { sector: 'sanitary', size: 'micro', year: 2013, co2_avg_s12: 83.67 },
  { sector: 'sanitary', size: 'large', year: 2013, co2_avg_s12: 27044.36 },
  { sector: 'sanitary', size: 'small', year: 2014, co2_avg_s12: 1523.75 },
  { sector: 'sanitary', size: 'medium', year: 2014, co2_avg_s12: 4391.85 },
  { sector: 'sanitary', size: 'micro', year: 2014, co2_avg_s12: 76.18 },
  { sector: 'sanitary', size: 'large', year: 2014, co2_avg_s12: 22051.45 },
  { sector: 'sanitary', size: 'small', year: 2015, co2_avg_s12: 1425.77 },
  { sector: 'sanitary', size: 'medium', year: 2015, co2_avg_s12: 4280.33 },
  { sector: 'sanitary', size: 'micro', year: 2015, co2_avg_s12: 249.77 },
  { sector: 'sanitary', size: 'large', year: 2015, co2_avg_s12: 31632.17 },
  { sector: 'sanitary', size: 'small', year: 2016, co2_avg_s12: 1276.61 },
  { sector: 'sanitary', size: 'medium', year: 2016, co2_avg_s12: 587011.09 },
  { sector: 'sanitary', size: 'micro', year: 2016, co2_avg_s12: 219.29 },
  { sector: 'sanitary', size: 'large', year: 2016, co2_avg_s12: 35078.93 },
  { sector: 'sanitary', size: 'small', year: 2017, co2_avg_s12: 1098.34 },
  { sector: 'sanitary', size: 'medium', year: 2017, co2_avg_s12: 3898.53 },
  { sector: 'sanitary', size: 'micro', year: 2017, co2_avg_s12: 251.9 },
  { sector: 'sanitary', size: 'large', year: 2017, co2_avg_s12: 45762.07 },
  { sector: 'sanitary', size: 'small', year: 2018, co2_avg_s12: 1097.28 },
  { sector: 'sanitary', size: 'medium', year: 2018, co2_avg_s12: 5438.89 },
  { sector: 'sanitary', size: 'micro', year: 2018, co2_avg_s12: 225.17 },
  { sector: 'sanitary', size: 'large', year: 2018, co2_avg_s12: 37058.37 },
  { sector: 'sanitary', size: 'small', year: 2019, co2_avg_s12: 1370.91 },
  { sector: 'sanitary', size: 'medium', year: 2019, co2_avg_s12: 6243.8 },
  { sector: 'sanitary', size: 'micro', year: 2019, co2_avg_s12: 544.67 },
  { sector: 'sanitary', size: 'large', year: 2019, co2_avg_s12: 43895.73 },
  { sector: 'sanitary', size: 'small', year: 2020, co2_avg_s12: 1329.97 },
  { sector: 'sanitary', size: 'medium', year: 2020, co2_avg_s12: 5197.17 },
  { sector: 'sanitary', size: 'micro', year: 2020, co2_avg_s12: 352.8 },
  { sector: 'sanitary', size: 'large', year: 2020, co2_avg_s12: 66507.75 },
  { sector: 'sanitary', size: 'small', year: 2021, co2_avg_s12: 2259.82 },
  { sector: 'sanitary', size: 'medium', year: 2021, co2_avg_s12: 9761.15 },
  { sector: 'sanitary', size: 'micro', year: 2021, co2_avg_s12: 250 },
  { sector: 'sanitary', size: 'large', year: 2021, co2_avg_s12: 121520.22 },
  { sector: 'public_administration', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'public_administration', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'public_administration', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'public_administration', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'public_administration', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'public_administration', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'public_administration', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'public_administration', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'public_administration', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'public_administration', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'public_administration', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'public_administration', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'public_administration', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'public_administration', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'public_administration', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'public_administration', size: 'large', year: 2011, co2_avg_s12: 160530.31 },
  { sector: 'public_administration', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'public_administration', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'public_administration', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'public_administration', size: 'large', year: 2012, co2_avg_s12: 161211.3 },
  { sector: 'public_administration', size: 'small', year: 2013, co2_avg_s12: 71.49 },
  { sector: 'public_administration', size: 'medium', year: 2013, co2_avg_s12: 5176.83 },
  { sector: 'public_administration', size: 'micro', year: 2013, co2_avg_s12: 21.31 },
  { sector: 'public_administration', size: 'large', year: 2013, co2_avg_s12: 158171.65 },
  { sector: 'public_administration', size: 'small', year: 2014, co2_avg_s12: 143.16 },
  { sector: 'public_administration', size: 'medium', year: 2014, co2_avg_s12: 33228.5 },
  { sector: 'public_administration', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'public_administration', size: 'large', year: 2014, co2_avg_s12: 160891.75 },
  { sector: 'public_administration', size: 'small', year: 2015, co2_avg_s12: 215.41 },
  { sector: 'public_administration', size: 'medium', year: 2015, co2_avg_s12: 833.67 },
  { sector: 'public_administration', size: 'micro', year: 2015, co2_avg_s12: 66.68 },
  { sector: 'public_administration', size: 'large', year: 2015, co2_avg_s12: 77505.98 },
  { sector: 'public_administration', size: 'small', year: 2016, co2_avg_s12: 340.46 },
  { sector: 'public_administration', size: 'medium', year: 2016, co2_avg_s12: 815.84 },
  { sector: 'public_administration', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'public_administration', size: 'large', year: 2016, co2_avg_s12: 61357.4 },
  { sector: 'public_administration', size: 'small', year: 2017, co2_avg_s12: 229.31 },
  { sector: 'public_administration', size: 'medium', year: 2017, co2_avg_s12: 904.36 },
  { sector: 'public_administration', size: 'micro', year: 2017, co2_avg_s12: 102.43 },
  { sector: 'public_administration', size: 'large', year: 2017, co2_avg_s12: 47999.31 },
  { sector: 'public_administration', size: 'small', year: 2018, co2_avg_s12: 186.91 },
  { sector: 'public_administration', size: 'medium', year: 2018, co2_avg_s12: 744.85 },
  { sector: 'public_administration', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'public_administration', size: 'large', year: 2018, co2_avg_s12: 4111.87 },
  { sector: 'public_administration', size: 'small', year: 2019, co2_avg_s12: 129.08 },
  { sector: 'public_administration', size: 'medium', year: 2019, co2_avg_s12: 671.32 },
  { sector: 'public_administration', size: 'micro', year: 2019, co2_avg_s12: 12.76 },
  { sector: 'public_administration', size: 'large', year: 2019, co2_avg_s12: 3196.32 },
  { sector: 'public_administration', size: 'small', year: 2020, co2_avg_s12: 60.6 },
  { sector: 'public_administration', size: 'medium', year: 2020, co2_avg_s12: 265.67 },
  { sector: 'public_administration', size: 'micro', year: 2020, co2_avg_s12: 31.37 },
  { sector: 'public_administration', size: 'large', year: 2020, co2_avg_s12: 1777.77 },
  { sector: 'public_administration', size: 'small', year: 2021, co2_avg_s12: 52.7 },
  { sector: 'public_administration', size: 'medium', year: 2021, co2_avg_s12: 335.3 },
  { sector: 'public_administration', size: 'micro', year: 2021, co2_avg_s12: 13.93 },
  { sector: 'public_administration', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'agriculture', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'agriculture', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'agriculture', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'agriculture', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'agriculture', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'agriculture', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'agriculture', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'agriculture', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'agriculture', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'agriculture', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'agriculture', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'agriculture', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'agriculture', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'agriculture', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'agriculture', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'agriculture', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'agriculture', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'agriculture', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'agriculture', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'agriculture', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'agriculture', size: 'small', year: 2013, co2_avg_s12: 958.12 },
  { sector: 'agriculture', size: 'medium', year: 2013, co2_avg_s12: 3985.23 },
  { sector: 'agriculture', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'agriculture', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'agriculture', size: 'small', year: 2014, co2_avg_s12: 11240.67 },
  { sector: 'agriculture', size: 'medium', year: 2014, co2_avg_s12: 1787.59 },
  { sector: 'agriculture', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'agriculture', size: 'large', year: 2014, co2_avg_s12: 3741.08 },
  { sector: 'agriculture', size: 'small', year: 2015, co2_avg_s12: 2440.91 },
  { sector: 'agriculture', size: 'medium', year: 2015, co2_avg_s12: 1820.87 },
  { sector: 'agriculture', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'agriculture', size: 'large', year: 2015, co2_avg_s12: 156344.1 },
  { sector: 'agriculture', size: 'small', year: 2016, co2_avg_s12: 1671.01 },
  { sector: 'agriculture', size: 'medium', year: 2016, co2_avg_s12: 1579.58 },
  { sector: 'agriculture', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'agriculture', size: 'large', year: 2016, co2_avg_s12: 39572.14 },
  { sector: 'agriculture', size: 'small', year: 2017, co2_avg_s12: 2372.21 },
  { sector: 'agriculture', size: 'medium', year: 2017, co2_avg_s12: 517.45 },
  { sector: 'agriculture', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'agriculture', size: 'large', year: 2017, co2_avg_s12: 78250.51 },
  { sector: 'agriculture', size: 'small', year: 2018, co2_avg_s12: 432.2 },
  { sector: 'agriculture', size: 'medium', year: 2018, co2_avg_s12: 122.78 },
  { sector: 'agriculture', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'agriculture', size: 'large', year: 2018, co2_avg_s12: 18234.12 },
  { sector: 'agriculture', size: 'small', year: 2019, co2_avg_s12: 74.73 },
  { sector: 'agriculture', size: 'medium', year: 2019, co2_avg_s12: 92.66 },
  { sector: 'agriculture', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'agriculture', size: 'large', year: 2019, co2_avg_s12: 16695.75 },
  { sector: 'agriculture', size: 'small', year: 2020, co2_avg_s12: 73.84 },
  { sector: 'agriculture', size: 'medium', year: 2020, co2_avg_s12: 66.17 },
  { sector: 'agriculture', size: 'micro', year: 2020, co2_avg_s12: 16.7 },
  { sector: 'agriculture', size: 'large', year: 2020, co2_avg_s12: 14506.81 },
  { sector: 'agriculture', size: 'small', year: 2021, co2_avg_s12: 21.12 },
  { sector: 'agriculture', size: 'medium', year: 2021, co2_avg_s12: 30.72 },
  { sector: 'agriculture', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'agriculture', size: 'large', year: 2021, co2_avg_s12: 48990.64 },
  { sector: 'vehicles', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'vehicles', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'vehicles', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'vehicles', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'vehicles', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'vehicles', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'vehicles', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'vehicles', size: 'large', year: 2009, co2_avg_s12: 72533.02 },
  { sector: 'vehicles', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'vehicles', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'vehicles', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'vehicles', size: 'large', year: 2010, co2_avg_s12: 39592.92 },
  { sector: 'vehicles', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'vehicles', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'vehicles', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'vehicles', size: 'large', year: 2011, co2_avg_s12: 43331.01 },
  { sector: 'vehicles', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'vehicles', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'vehicles', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'vehicles', size: 'large', year: 2012, co2_avg_s12: 28206.35 },
  { sector: 'vehicles', size: 'small', year: 2013, co2_avg_s12: 535.24 },
  { sector: 'vehicles', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'vehicles', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'vehicles', size: 'large', year: 2013, co2_avg_s12: 17038.1 },
  { sector: 'vehicles', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'vehicles', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'vehicles', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'vehicles', size: 'large', year: 2014, co2_avg_s12: 66525.32 },
  { sector: 'vehicles', size: 'small', year: 2015, co2_avg_s12: 407.78 },
  { sector: 'vehicles', size: 'medium', year: 2015, co2_avg_s12: 3173.81 },
  { sector: 'vehicles', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'vehicles', size: 'large', year: 2015, co2_avg_s12: 9743.6 },
  { sector: 'vehicles', size: 'small', year: 2016, co2_avg_s12: 301.79 },
  { sector: 'vehicles', size: 'medium', year: 2016, co2_avg_s12: 120.98 },
  { sector: 'vehicles', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'vehicles', size: 'large', year: 2016, co2_avg_s12: 7190.59 },
  { sector: 'vehicles', size: 'small', year: 2017, co2_avg_s12: 879.44 },
  { sector: 'vehicles', size: 'medium', year: 2017, co2_avg_s12: 117.79 },
  { sector: 'vehicles', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'vehicles', size: 'large', year: 2017, co2_avg_s12: 13652.99 },
  { sector: 'vehicles', size: 'small', year: 2018, co2_avg_s12: 18.87 },
  { sector: 'vehicles', size: 'medium', year: 2018, co2_avg_s12: 94.65 },
  { sector: 'vehicles', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'vehicles', size: 'large', year: 2018, co2_avg_s12: 10195.07 },
  { sector: 'vehicles', size: 'small', year: 2019, co2_avg_s12: 17.39 },
  { sector: 'vehicles', size: 'medium', year: 2019, co2_avg_s12: 75.06 },
  { sector: 'vehicles', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'vehicles', size: 'large', year: 2019, co2_avg_s12: 7261.8 },
  { sector: 'vehicles', size: 'small', year: 2020, co2_avg_s12: 4.24 },
  { sector: 'vehicles', size: 'medium', year: 2020, co2_avg_s12: 94 },
  { sector: 'vehicles', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'vehicles', size: 'large', year: 2020, co2_avg_s12: 5110.82 },
  { sector: 'vehicles', size: 'small', year: 2021, co2_avg_s12: 5.3 },
  { sector: 'vehicles', size: 'medium', year: 2021, co2_avg_s12: 42.79 },
  { sector: 'vehicles', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'vehicles', size: 'large', year: 2021, co2_avg_s12: 17776.54 },
  { sector: 'construction', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'construction', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'construction', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'construction', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'construction', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'construction', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'construction', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'construction', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'construction', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'construction', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'construction', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'construction', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'construction', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'construction', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'construction', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'construction', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'construction', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'construction', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'construction', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'construction', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'construction', size: 'small', year: 2013, co2_avg_s12: 535.24 },
  { sector: 'construction', size: 'medium', year: 2013, co2_avg_s12: 290.14 },
  { sector: 'construction', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'construction', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'construction', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'construction', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'construction', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'construction', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'construction', size: 'small', year: 2015, co2_avg_s12: 161.86 },
  { sector: 'construction', size: 'medium', year: 2015, co2_avg_s12: 3173.81 },
  { sector: 'construction', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'construction', size: 'large', year: 2015, co2_avg_s12: 1361 },
  { sector: 'construction', size: 'small', year: 2016, co2_avg_s12: 97.78 },
  { sector: 'construction', size: 'medium', year: 2016, co2_avg_s12: 39140.65 },
  { sector: 'construction', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'construction', size: 'large', year: 2016, co2_avg_s12: 942624.56 },
  { sector: 'construction', size: 'small', year: 2017, co2_avg_s12: 99.53 },
  { sector: 'construction', size: 'medium', year: 2017, co2_avg_s12: 14868.13 },
  { sector: 'construction', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'construction', size: 'large', year: 2017, co2_avg_s12: 1007 },
  { sector: 'construction', size: 'small', year: 2018, co2_avg_s12: 92.09 },
  { sector: 'construction', size: 'medium', year: 2018, co2_avg_s12: 804.69 },
  { sector: 'construction', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'construction', size: 'large', year: 2018, co2_avg_s12: 843261.43 },
  { sector: 'construction', size: 'small', year: 2019, co2_avg_s12: 19.9 },
  { sector: 'construction', size: 'medium', year: 2019, co2_avg_s12: 721.14 },
  { sector: 'construction', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'construction', size: 'large', year: 2019, co2_avg_s12: 511681.24 },
  { sector: 'construction', size: 'small', year: 2020, co2_avg_s12: 5.59 },
  { sector: 'construction', size: 'medium', year: 2020, co2_avg_s12: 345.11 },
  { sector: 'construction', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'construction', size: 'large', year: 2020, co2_avg_s12: 2143 },
  { sector: 'construction', size: 'small', year: 2021, co2_avg_s12: 239.02 },
  { sector: 'construction', size: 'medium', year: 2021, co2_avg_s12: 1004.77 },
  { sector: 'construction', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'construction', size: 'large', year: 2021, co2_avg_s12: 2572 },
  { sector: 'education', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'education', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'education', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'education', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'education', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'education', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'education', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'education', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'education', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'education', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'education', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'education', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'education', size: 'small', year: 2011, co2_avg_s12: 45.81 },
  { sector: 'education', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'education', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'education', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'education', size: 'small', year: 2012, co2_avg_s12: 70.48 },
  { sector: 'education', size: 'medium', year: 2012, co2_avg_s12: 87.23 },
  { sector: 'education', size: 'micro', year: 2012, co2_avg_s12: 10.6 },
  { sector: 'education', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'education', size: 'small', year: 2013, co2_avg_s12: 29.36 },
  { sector: 'education', size: 'medium', year: 2013, co2_avg_s12: 53.46 },
  { sector: 'education', size: 'micro', year: 2013, co2_avg_s12: 5.78 },
  { sector: 'education', size: 'large', year: 2013, co2_avg_s12: 1102.96 },
  { sector: 'education', size: 'small', year: 2014, co2_avg_s12: 42.51 },
  { sector: 'education', size: 'medium', year: 2014, co2_avg_s12: 40.58 },
  { sector: 'education', size: 'micro', year: 2014, co2_avg_s12: 5.94 },
  { sector: 'education', size: 'large', year: 2014, co2_avg_s12: 1040.6 },
  { sector: 'education', size: 'small', year: 2015, co2_avg_s12: 107.45 },
  { sector: 'education', size: 'medium', year: 2015, co2_avg_s12: 42.64 },
  { sector: 'education', size: 'micro', year: 2015, co2_avg_s12: 4.95 },
  { sector: 'education', size: 'large', year: 2015, co2_avg_s12: 1033.47 },
  { sector: 'education', size: 'small', year: 2016, co2_avg_s12: 74.55 },
  { sector: 'education', size: 'medium', year: 2016, co2_avg_s12: 60.42 },
  { sector: 'education', size: 'micro', year: 2016, co2_avg_s12: 4.8 },
  { sector: 'education', size: 'large', year: 2016, co2_avg_s12: 949.46 },
  { sector: 'education', size: 'small', year: 2017, co2_avg_s12: 50.71 },
  { sector: 'education', size: 'medium', year: 2017, co2_avg_s12: 73.93 },
  { sector: 'education', size: 'micro', year: 2017, co2_avg_s12: 9.25 },
  { sector: 'education', size: 'large', year: 2017, co2_avg_s12: 1243.84 },
  { sector: 'education', size: 'small', year: 2018, co2_avg_s12: 38.29 },
  { sector: 'education', size: 'medium', year: 2018, co2_avg_s12: 74.34 },
  { sector: 'education', size: 'micro', year: 2018, co2_avg_s12: 7.67 },
  { sector: 'education', size: 'large', year: 2018, co2_avg_s12: 1233.63 },
  { sector: 'education', size: 'small', year: 2019, co2_avg_s12: 27.46 },
  { sector: 'education', size: 'medium', year: 2019, co2_avg_s12: 116.94 },
  { sector: 'education', size: 'micro', year: 2019, co2_avg_s12: 59.78 },
  { sector: 'education', size: 'large', year: 2019, co2_avg_s12: 1232.6 },
  { sector: 'education', size: 'small', year: 2020, co2_avg_s12: 34.73 },
  { sector: 'education', size: 'medium', year: 2020, co2_avg_s12: 99.72 },
  { sector: 'education', size: 'micro', year: 2020, co2_avg_s12: 7.94 },
  { sector: 'education', size: 'large', year: 2020, co2_avg_s12: 611.75 },
  { sector: 'education', size: 'small', year: 2021, co2_avg_s12: 9.24 },
  { sector: 'education', size: 'medium', year: 2021, co2_avg_s12: 50.42 },
  { sector: 'education', size: 'micro', year: 2021, co2_avg_s12: 8.46 },
  { sector: 'education', size: 'large', year: 2021, co2_avg_s12: 213.61 },
  { sector: 'hostelry', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'hostelry', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'hostelry', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'hostelry', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'hostelry', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'hostelry', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'hostelry', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'hostelry', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'hostelry', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'hostelry', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'hostelry', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'hostelry', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'hostelry', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'hostelry', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'hostelry', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'hostelry', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'hostelry', size: 'small', year: 2012, co2_avg_s12: 516.82 },
  { sector: 'hostelry', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'hostelry', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'hostelry', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'hostelry', size: 'small', year: 2013, co2_avg_s12: 120.65 },
  { sector: 'hostelry', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'hostelry', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'hostelry', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'hostelry', size: 'small', year: 2014, co2_avg_s12: 725.28 },
  { sector: 'hostelry', size: 'medium', year: 2014, co2_avg_s12: 52.37 },
  { sector: 'hostelry', size: 'micro', year: 2014, co2_avg_s12: 85.48 },
  { sector: 'hostelry', size: 'large', year: 2014, co2_avg_s12: 11008 },
  { sector: 'hostelry', size: 'small', year: 2015, co2_avg_s12: 144.42 },
  { sector: 'hostelry', size: 'medium', year: 2015, co2_avg_s12: 59.58 },
  { sector: 'hostelry', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'hostelry', size: 'large', year: 2015, co2_avg_s12: 5817.35 },
  { sector: 'hostelry', size: 'small', year: 2016, co2_avg_s12: 319.16 },
  { sector: 'hostelry', size: 'medium', year: 2016, co2_avg_s12: 59.58 },
  { sector: 'hostelry', size: 'micro', year: 2016, co2_avg_s12: 5.12 },
  { sector: 'hostelry', size: 'large', year: 2016, co2_avg_s12: 5003.57 },
  { sector: 'hostelry', size: 'small', year: 2017, co2_avg_s12: 306.69 },
  { sector: 'hostelry', size: 'medium', year: 2017, co2_avg_s12: 300.13 },
  { sector: 'hostelry', size: 'micro', year: 2017, co2_avg_s12: 5.62 },
  { sector: 'hostelry', size: 'large', year: 2017, co2_avg_s12: 35118.57 },
  { sector: 'hostelry', size: 'small', year: 2018, co2_avg_s12: 442.78 },
  { sector: 'hostelry', size: 'medium', year: 2018, co2_avg_s12: 289.78 },
  { sector: 'hostelry', size: 'micro', year: 2018, co2_avg_s12: 6.16 },
  { sector: 'hostelry', size: 'large', year: 2018, co2_avg_s12: 3263.18 },
  { sector: 'hostelry', size: 'small', year: 2019, co2_avg_s12: 1272.31 },
  { sector: 'hostelry', size: 'medium', year: 2019, co2_avg_s12: 199.79 },
  { sector: 'hostelry', size: 'micro', year: 2019, co2_avg_s12: 191.01 },
  { sector: 'hostelry', size: 'large', year: 2019, co2_avg_s12: 3223.16 },
  { sector: 'hostelry', size: 'small', year: 2020, co2_avg_s12: 164.06 },
  { sector: 'hostelry', size: 'medium', year: 2020, co2_avg_s12: 163.32 },
  { sector: 'hostelry', size: 'micro', year: 2020, co2_avg_s12: 14.39 },
  { sector: 'hostelry', size: 'large', year: 2020, co2_avg_s12: 1612.01 },
  { sector: 'hostelry', size: 'small', year: 2021, co2_avg_s12: 23.95 },
  { sector: 'hostelry', size: 'medium', year: 2021, co2_avg_s12: 105.04 },
  { sector: 'hostelry', size: 'micro', year: 2021, co2_avg_s12: 0 },
  { sector: 'hostelry', size: 'large', year: 2021, co2_avg_s12: 449.26 },
  { sector: 'manufacture', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'manufacture', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'manufacture', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'manufacture', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'manufacture', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'manufacture', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'manufacture', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'manufacture', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'manufacture', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'manufacture', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'manufacture', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'manufacture', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'manufacture', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'manufacture', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'manufacture', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'manufacture', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'manufacture', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'manufacture', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'manufacture', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'manufacture', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'manufacture', size: 'small', year: 2013, co2_avg_s12: 399.99 },
  { sector: 'manufacture', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'manufacture', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'manufacture', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'manufacture', size: 'small', year: 2014, co2_avg_s12: 331.15 },
  { sector: 'manufacture', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'manufacture', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'manufacture', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'manufacture', size: 'small', year: 2015, co2_avg_s12: 390.8 },
  { sector: 'manufacture', size: 'medium', year: 2015, co2_avg_s12: 3173.81 },
  { sector: 'manufacture', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'manufacture', size: 'large', year: 2015, co2_avg_s12: 1361 },
  { sector: 'manufacture', size: 'small', year: 2016, co2_avg_s12: 208.78 },
  { sector: 'manufacture', size: 'medium', year: 2016, co2_avg_s12: 39140.65 },
  { sector: 'manufacture', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'manufacture', size: 'large', year: 2016, co2_avg_s12: 1328 },
  { sector: 'manufacture', size: 'small', year: 2017, co2_avg_s12: 879.44 },
  { sector: 'manufacture', size: 'medium', year: 2017, co2_avg_s12: 14868.13 },
  { sector: 'manufacture', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'manufacture', size: 'large', year: 2017, co2_avg_s12: 4323.48 },
  { sector: 'manufacture', size: 'small', year: 2018, co2_avg_s12: 1213.87 },
  { sector: 'manufacture', size: 'medium', year: 2018, co2_avg_s12: 1753.72 },
  { sector: 'manufacture', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'manufacture', size: 'large', year: 2018, co2_avg_s12: 342.05 },
  { sector: 'manufacture', size: 'small', year: 2019, co2_avg_s12: 555.03 },
  { sector: 'manufacture', size: 'medium', year: 2019, co2_avg_s12: 179.69 },
  { sector: 'manufacture', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'manufacture', size: 'large', year: 2019, co2_avg_s12: 296.13 },
  { sector: 'manufacture', size: 'small', year: 2020, co2_avg_s12: 184 },
  { sector: 'manufacture', size: 'medium', year: 2020, co2_avg_s12: 1929.6 },
  { sector: 'manufacture', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'manufacture', size: 'large', year: 2020, co2_avg_s12: 226.53 },
  { sector: 'manufacture', size: 'small', year: 2021, co2_avg_s12: 239.02 },
  { sector: 'manufacture', size: 'medium', year: 2021, co2_avg_s12: 1004.77 },
  { sector: 'manufacture', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'manufacture', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'extractive', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'extractive', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'extractive', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'extractive', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'extractive', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'extractive', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'extractive', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'extractive', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'extractive', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'extractive', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'extractive', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'extractive', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'extractive', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'extractive', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'extractive', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'extractive', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'extractive', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'extractive', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'extractive', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'extractive', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'extractive', size: 'small', year: 2013, co2_avg_s12: 535.24 },
  { sector: 'extractive', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'extractive', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'extractive', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'extractive', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'extractive', size: 'medium', year: 2014, co2_avg_s12: 96.88 },
  { sector: 'extractive', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'extractive', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'extractive', size: 'small', year: 2015, co2_avg_s12: 14.4 },
  { sector: 'extractive', size: 'medium', year: 2015, co2_avg_s12: 84.21 },
  { sector: 'extractive', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'extractive', size: 'large', year: 2015, co2_avg_s12: 1361 },
  { sector: 'extractive', size: 'small', year: 2016, co2_avg_s12: 30.42 },
  { sector: 'extractive', size: 'medium', year: 2016, co2_avg_s12: 76.39 },
  { sector: 'extractive', size: 'micro', year: 2016, co2_avg_s12: 5.73 },
  { sector: 'extractive', size: 'large', year: 2016, co2_avg_s12: 942624.56 },
  { sector: 'extractive', size: 'small', year: 2017, co2_avg_s12: 41.22 },
  { sector: 'extractive', size: 'medium', year: 2017, co2_avg_s12: 298.56 },
  { sector: 'extractive', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'extractive', size: 'large', year: 2017, co2_avg_s12: 1007 },
  { sector: 'extractive', size: 'small', year: 2018, co2_avg_s12: 21.5 },
  { sector: 'extractive', size: 'medium', year: 2018, co2_avg_s12: 70.69 },
  { sector: 'extractive', size: 'micro', year: 2018, co2_avg_s12: 2.48 },
  { sector: 'extractive', size: 'large', year: 2018, co2_avg_s12: 843261.43 },
  { sector: 'extractive', size: 'small', year: 2019, co2_avg_s12: 454.85 },
  { sector: 'extractive', size: 'medium', year: 2019, co2_avg_s12: 442.66 },
  { sector: 'extractive', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'extractive', size: 'large', year: 2019, co2_avg_s12: 511681.24 },
  { sector: 'extractive', size: 'small', year: 2020, co2_avg_s12: 4.72 },
  { sector: 'extractive', size: 'medium', year: 2020, co2_avg_s12: 422.37 },
  { sector: 'extractive', size: 'micro', year: 2020, co2_avg_s12: 2.02 },
  { sector: 'extractive', size: 'large', year: 2020, co2_avg_s12: 293509.07 },
  { sector: 'extractive', size: 'small', year: 2021, co2_avg_s12: 4.45 },
  { sector: 'extractive', size: 'medium', year: 2021, co2_avg_s12: 77.88 },
  { sector: 'extractive', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'extractive', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'information_communications', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'information_communications', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'information_communications', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'information_communications', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'information_communications', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'information_communications', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'information_communications', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'information_communications', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'information_communications', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'information_communications', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'information_communications', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'information_communications', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'information_communications', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'information_communications', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'information_communications', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'information_communications', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'information_communications', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'information_communications', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'information_communications', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'information_communications', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'information_communications', size: 'small', year: 2013, co2_avg_s12: 38.86 },
  { sector: 'information_communications', size: 'medium', year: 2013, co2_avg_s12: 231.87 },
  { sector: 'information_communications', size: 'micro', year: 2013, co2_avg_s12: 2.27 },
  { sector: 'information_communications', size: 'large', year: 2013, co2_avg_s12: 3657.66 },
  { sector: 'information_communications', size: 'small', year: 2014, co2_avg_s12: 37.28 },
  { sector: 'information_communications', size: 'medium', year: 2014, co2_avg_s12: 245.82 },
  { sector: 'information_communications', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'information_communications', size: 'large', year: 2014, co2_avg_s12: 3707.74 },
  { sector: 'information_communications', size: 'small', year: 2015, co2_avg_s12: 48.85 },
  { sector: 'information_communications', size: 'medium', year: 2015, co2_avg_s12: 525.61 },
  { sector: 'information_communications', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'information_communications', size: 'large', year: 2015, co2_avg_s12: 3240 },
  { sector: 'information_communications', size: 'small', year: 2016, co2_avg_s12: 35.59 },
  { sector: 'information_communications', size: 'medium', year: 2016, co2_avg_s12: 612.04 },
  { sector: 'information_communications', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'information_communications', size: 'large', year: 2016, co2_avg_s12: 2451.03 },
  { sector: 'information_communications', size: 'small', year: 2017, co2_avg_s12: 110.03 },
  { sector: 'information_communications', size: 'medium', year: 2017, co2_avg_s12: 845.75 },
  { sector: 'information_communications', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'information_communications', size: 'large', year: 2017, co2_avg_s12: 3467.41 },
  { sector: 'information_communications', size: 'small', year: 2018, co2_avg_s12: 141.69 },
  { sector: 'information_communications', size: 'medium', year: 2018, co2_avg_s12: 110.27 },
  { sector: 'information_communications', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'information_communications', size: 'large', year: 2018, co2_avg_s12: 2573.04 },
  { sector: 'information_communications', size: 'small', year: 2019, co2_avg_s12: 2874.99 },
  { sector: 'information_communications', size: 'medium', year: 2019, co2_avg_s12: 146.13 },
  { sector: 'information_communications', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'information_communications', size: 'large', year: 2019, co2_avg_s12: 1687.18 },
  { sector: 'information_communications', size: 'small', year: 2020, co2_avg_s12: 98.27 },
  { sector: 'information_communications', size: 'medium', year: 2020, co2_avg_s12: 150.87 },
  { sector: 'information_communications', size: 'micro', year: 2020, co2_avg_s12: 208.12 },
  { sector: 'information_communications', size: 'large', year: 2020, co2_avg_s12: 1675.34 },
  { sector: 'information_communications', size: 'small', year: 2021, co2_avg_s12: 239.02 },
  { sector: 'information_communications', size: 'medium', year: 2021, co2_avg_s12: 83.64 },
  { sector: 'information_communications', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'information_communications', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'other', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'other', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'other', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'other', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'other', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'other', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'other', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'other', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'other', size: 'small', year: 2010, co2_avg_s12: 911.73 },
  { sector: 'other', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'other', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'other', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'other', size: 'small', year: 2011, co2_avg_s12: 924.8 },
  { sector: 'other', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'other', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'other', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'other', size: 'small', year: 2012, co2_avg_s12: 1002.65 },
  { sector: 'other', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'other', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'other', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'other', size: 'small', year: 2013, co2_avg_s12: 450.25 },
  { sector: 'other', size: 'medium', year: 2013, co2_avg_s12: 340.08 },
  { sector: 'other', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'other', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'other', size: 'small', year: 2014, co2_avg_s12: 397.47 },
  { sector: 'other', size: 'medium', year: 2014, co2_avg_s12: 645.38 },
  { sector: 'other', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'other', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'other', size: 'small', year: 2015, co2_avg_s12: 322.14 },
  { sector: 'other', size: 'medium', year: 2015, co2_avg_s12: 408.01 },
  { sector: 'other', size: 'micro', year: 2015, co2_avg_s12: 38.22 },
  { sector: 'other', size: 'large', year: 2015, co2_avg_s12: 1361 },
  { sector: 'other', size: 'small', year: 2016, co2_avg_s12: 301.9 },
  { sector: 'other', size: 'medium', year: 2016, co2_avg_s12: 601.6 },
  { sector: 'other', size: 'micro', year: 2016, co2_avg_s12: 35.47 },
  { sector: 'other', size: 'large', year: 2016, co2_avg_s12: 942624.56 },
  { sector: 'other', size: 'small', year: 2017, co2_avg_s12: 418.53 },
  { sector: 'other', size: 'medium', year: 2017, co2_avg_s12: 2496.05 },
  { sector: 'other', size: 'micro', year: 2017, co2_avg_s12: 23.07 },
  { sector: 'other', size: 'large', year: 2017, co2_avg_s12: 1007 },
  { sector: 'other', size: 'small', year: 2018, co2_avg_s12: 447.58 },
  { sector: 'other', size: 'medium', year: 2018, co2_avg_s12: 2035.78 },
  { sector: 'other', size: 'micro', year: 2018, co2_avg_s12: 35.14 },
  { sector: 'other', size: 'large', year: 2018, co2_avg_s12: 839.05 },
  { sector: 'other', size: 'small', year: 2019, co2_avg_s12: 228.27 },
  { sector: 'other', size: 'medium', year: 2019, co2_avg_s12: 1499.04 },
  { sector: 'other', size: 'micro', year: 2019, co2_avg_s12: 19.32 },
  { sector: 'other', size: 'large', year: 2019, co2_avg_s12: 664.08 },
  { sector: 'other', size: 'small', year: 2020, co2_avg_s12: 112.61 },
  { sector: 'other', size: 'medium', year: 2020, co2_avg_s12: 1045.79 },
  { sector: 'other', size: 'micro', year: 2020, co2_avg_s12: 20.9 },
  { sector: 'other', size: 'large', year: 2020, co2_avg_s12: 460.58 },
  { sector: 'other', size: 'small', year: 2021, co2_avg_s12: 69.27 },
  { sector: 'other', size: 'medium', year: 2021, co2_avg_s12: 1004.77 },
  { sector: 'other', size: 'micro', year: 2021, co2_avg_s12: 7.58 },
  { sector: 'other', size: 'large', year: 2021, co2_avg_s12: 458.76 },
  { sector: 'water_waste_supply', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'water_waste_supply', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'water_waste_supply', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'water_waste_supply', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'water_waste_supply', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'water_waste_supply', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'water_waste_supply', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'water_waste_supply', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'water_waste_supply', size: 'small', year: 2010, co2_avg_s12: 12.38 },
  { sector: 'water_waste_supply', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'water_waste_supply', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'water_waste_supply', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'water_waste_supply', size: 'small', year: 2011, co2_avg_s12: 12.53 },
  { sector: 'water_waste_supply', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'water_waste_supply', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'water_waste_supply', size: 'large', year: 2011, co2_avg_s12: 34004 },
  { sector: 'water_waste_supply', size: 'small', year: 2012, co2_avg_s12: 12.88 },
  { sector: 'water_waste_supply', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'water_waste_supply', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'water_waste_supply', size: 'large', year: 2012, co2_avg_s12: 43360 },
  { sector: 'water_waste_supply', size: 'small', year: 2013, co2_avg_s12: 134.4 },
  { sector: 'water_waste_supply', size: 'medium', year: 2013, co2_avg_s12: 49.79 },
  { sector: 'water_waste_supply', size: 'micro', year: 2013, co2_avg_s12: 97.4 },
  { sector: 'water_waste_supply', size: 'large', year: 2013, co2_avg_s12: 31810 },
  { sector: 'water_waste_supply', size: 'small', year: 2014, co2_avg_s12: 108.49 },
  { sector: 'water_waste_supply', size: 'medium', year: 2014, co2_avg_s12: 221.36 },
  { sector: 'water_waste_supply', size: 'micro', year: 2014, co2_avg_s12: 2.87 },
  { sector: 'water_waste_supply', size: 'large', year: 2014, co2_avg_s12: 29597 },
  { sector: 'water_waste_supply', size: 'small', year: 2015, co2_avg_s12: 65.65 },
  { sector: 'water_waste_supply', size: 'medium', year: 2015, co2_avg_s12: 2170.88 },
  { sector: 'water_waste_supply', size: 'micro', year: 2015, co2_avg_s12: 27.69 },
  { sector: 'water_waste_supply', size: 'large', year: 2015, co2_avg_s12: 28650 },
  { sector: 'water_waste_supply', size: 'small', year: 2016, co2_avg_s12: 59.29 },
  { sector: 'water_waste_supply', size: 'medium', year: 2016, co2_avg_s12: 985.93 },
  { sector: 'water_waste_supply', size: 'micro', year: 2016, co2_avg_s12: 22.07 },
  { sector: 'water_waste_supply', size: 'large', year: 2016, co2_avg_s12: 12337.74 },
  { sector: 'water_waste_supply', size: 'small', year: 2017, co2_avg_s12: 43.54 },
  { sector: 'water_waste_supply', size: 'medium', year: 2017, co2_avg_s12: 9138.13 },
  { sector: 'water_waste_supply', size: 'micro', year: 2017, co2_avg_s12: 66.46 },
  { sector: 'water_waste_supply', size: 'large', year: 2017, co2_avg_s12: 3105.49 },
  { sector: 'water_waste_supply', size: 'small', year: 2018, co2_avg_s12: 370.97 },
  { sector: 'water_waste_supply', size: 'medium', year: 2018, co2_avg_s12: 703.9 },
  { sector: 'water_waste_supply', size: 'micro', year: 2018, co2_avg_s12: 57.77 },
  { sector: 'water_waste_supply', size: 'large', year: 2018, co2_avg_s12: 19430.11 },
  { sector: 'water_waste_supply', size: 'small', year: 2019, co2_avg_s12: 274.74 },
  { sector: 'water_waste_supply', size: 'medium', year: 2019, co2_avg_s12: 472.04 },
  { sector: 'water_waste_supply', size: 'micro', year: 2019, co2_avg_s12: 28.92 },
  { sector: 'water_waste_supply', size: 'large', year: 2019, co2_avg_s12: 10578.86 },
  { sector: 'water_waste_supply', size: 'small', year: 2020, co2_avg_s12: 11.75 },
  { sector: 'water_waste_supply', size: 'medium', year: 2020, co2_avg_s12: 122.12 },
  { sector: 'water_waste_supply', size: 'micro', year: 2020, co2_avg_s12: 14.81 },
  { sector: 'water_waste_supply', size: 'large', year: 2020, co2_avg_s12: 6601.1 },
  { sector: 'water_waste_supply', size: 'small', year: 2021, co2_avg_s12: 12.15 },
  { sector: 'water_waste_supply', size: 'medium', year: 2021, co2_avg_s12: 30.28 },
  { sector: 'water_waste_supply', size: 'micro', year: 2021, co2_avg_s12: 4.02 },
  { sector: 'water_waste_supply', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2013, co2_avg_s12: 535.24 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2015, co2_avg_s12: 407.78 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2015, co2_avg_s12: 3173.81 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2015, co2_avg_s12: 1361 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2016, co2_avg_s12: 469.35 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2016, co2_avg_s12: 39140.65 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2016, co2_avg_s12: 942624.56 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2017, co2_avg_s12: 879.44 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2017, co2_avg_s12: 14868.13 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2017, co2_avg_s12: 1007 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2018, co2_avg_s12: 1213.87 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2018, co2_avg_s12: 1753.72 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2018, co2_avg_s12: 843261.43 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2019, co2_avg_s12: 555.03 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2019, co2_avg_s12: 1856.37 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2019, co2_avg_s12: 511681.24 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2020, co2_avg_s12: 34.53 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2020, co2_avg_s12: 1929.6 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2020, co2_avg_s12: 293509.07 },
  { sector: 'electricity_gas_supply', size: 'small', year: 2021, co2_avg_s12: 239.02 },
  { sector: 'electricity_gas_supply', size: 'medium', year: 2021, co2_avg_s12: 1004.77 },
  { sector: 'electricity_gas_supply', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'electricity_gas_supply', size: 'large', year: 2021, co2_avg_s12: 40954.81 },
  { sector: 'transport', size: 'small', year: 2008, co2_avg_s12: 654.24 },
  { sector: 'transport', size: 'medium', year: 2008, co2_avg_s12: 7540.5 },
  { sector: 'transport', size: 'micro', year: 2008, co2_avg_s12: 306.77 },
  { sector: 'transport', size: 'large', year: 2008, co2_avg_s12: 1013 },
  { sector: 'transport', size: 'small', year: 2009, co2_avg_s12: 654.24 },
  { sector: 'transport', size: 'medium', year: 2009, co2_avg_s12: 7540.5 },
  { sector: 'transport', size: 'micro', year: 2009, co2_avg_s12: 306.77 },
  { sector: 'transport', size: 'large', year: 2009, co2_avg_s12: 283942.51 },
  { sector: 'transport', size: 'small', year: 2010, co2_avg_s12: 462.06 },
  { sector: 'transport', size: 'medium', year: 2010, co2_avg_s12: 201.84 },
  { sector: 'transport', size: 'micro', year: 2010, co2_avg_s12: 306.77 },
  { sector: 'transport', size: 'large', year: 2010, co2_avg_s12: 124346.65 },
  { sector: 'transport', size: 'small', year: 2011, co2_avg_s12: 350.17 },
  { sector: 'transport', size: 'medium', year: 2011, co2_avg_s12: 179.09 },
  { sector: 'transport', size: 'micro', year: 2011, co2_avg_s12: 32.8 },
  { sector: 'transport', size: 'large', year: 2011, co2_avg_s12: 86538.09 },
  { sector: 'transport', size: 'small', year: 2012, co2_avg_s12: 1506.06 },
  { sector: 'transport', size: 'medium', year: 2012, co2_avg_s12: 1580.89 },
  { sector: 'transport', size: 'micro', year: 2012, co2_avg_s12: 60.44 },
  { sector: 'transport', size: 'large', year: 2012, co2_avg_s12: 4751 },
  { sector: 'transport', size: 'small', year: 2013, co2_avg_s12: 535.24 },
  { sector: 'transport', size: 'medium', year: 2013, co2_avg_s12: 1709.96 },
  { sector: 'transport', size: 'micro', year: 2013, co2_avg_s12: 37.6 },
  { sector: 'transport', size: 'large', year: 2013, co2_avg_s12: 1746 },
  { sector: 'transport', size: 'small', year: 2014, co2_avg_s12: 1246 },
  { sector: 'transport', size: 'medium', year: 2014, co2_avg_s12: 5156.58 },
  { sector: 'transport', size: 'micro', year: 2014, co2_avg_s12: 36.07 },
  { sector: 'transport', size: 'large', year: 2014, co2_avg_s12: 1363 },
  { sector: 'transport', size: 'small', year: 2015, co2_avg_s12: 407.78 },
  { sector: 'transport', size: 'medium', year: 2015, co2_avg_s12: 3173.81 },
  { sector: 'transport', size: 'micro', year: 2015, co2_avg_s12: 76.98 },
  { sector: 'transport', size: 'large', year: 2015, co2_avg_s12: 1361 },
  { sector: 'transport', size: 'small', year: 2016, co2_avg_s12: 469.35 },
  { sector: 'transport', size: 'medium', year: 2016, co2_avg_s12: 39140.65 },
  { sector: 'transport', size: 'micro', year: 2016, co2_avg_s12: 45.24 },
  { sector: 'transport', size: 'large', year: 2016, co2_avg_s12: 942624.56 },
  { sector: 'transport', size: 'small', year: 2017, co2_avg_s12: 879.44 },
  { sector: 'transport', size: 'medium', year: 2017, co2_avg_s12: 14868.13 },
  { sector: 'transport', size: 'micro', year: 2017, co2_avg_s12: 2033.66 },
  { sector: 'transport', size: 'large', year: 2017, co2_avg_s12: 1007 },
  { sector: 'transport', size: 'small', year: 2018, co2_avg_s12: 1213.87 },
  { sector: 'transport', size: 'medium', year: 2018, co2_avg_s12: 1753.72 },
  { sector: 'transport', size: 'micro', year: 2018, co2_avg_s12: 65.1 },
  { sector: 'transport', size: 'large', year: 2018, co2_avg_s12: 843261.43 },
  { sector: 'transport', size: 'small', year: 2019, co2_avg_s12: 555.03 },
  { sector: 'transport', size: 'medium', year: 2019, co2_avg_s12: 1856.37 },
  { sector: 'transport', size: 'micro', year: 2019, co2_avg_s12: 97.56 },
  { sector: 'transport', size: 'large', year: 2019, co2_avg_s12: 511681.24 },
  { sector: 'transport', size: 'small', year: 2020, co2_avg_s12: 184 },
  { sector: 'transport', size: 'medium', year: 2020, co2_avg_s12: 1929.6 },
  { sector: 'transport', size: 'micro', year: 2020, co2_avg_s12: 323.61 },
  { sector: 'transport', size: 'large', year: 2020, co2_avg_s12: 293509.07 },
  { sector: 'transport', size: 'small', year: 2021, co2_avg_s12: 239.02 },
  { sector: 'transport', size: 'medium', year: 2021, co2_avg_s12: 1004.77 },
  { sector: 'transport', size: 'micro', year: 2021, co2_avg_s12: 53.24 },
  { sector: 'transport', size: 'large', year: 2021, co2_avg_s12: 40954.81 }
];
