import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Crumb from './Crumb';
import './styles.scss';

type CustomCrumb = {
  key: string; // value in the url, for example: the id of an installation
  value: string; // value that you want to appear in the breadcrumb
};
type Props = {
  crumbsReplace?: CustomCrumb[];
};

function Breadcrumb({ crumbsReplace = [] }: Props) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const crumbs = pathname.split('/');
  crumbs.splice(0, 1);

  return (
    <div className='breadcrumb'>
      {crumbs.map((crumb, index) => {
        const customCrumbFound = crumbsReplace.find((customCrumb) => customCrumb.key === crumb);
        if (customCrumbFound) {
          return (
            <Crumb
              index={index}
              key={`crumb-${index}`}
              crumbs={crumbs}
              crumb={`${customCrumbFound.value.substring(0, 30)}${
                customCrumbFound.value.length > 40 ? '...' : ''
              }`}
            />
          );
        }
        return (
          <Crumb
            index={index}
            crumbs={crumbs}
            crumb={t(`breadcrumb.${crumb}`)}
            key={`crumb-${index}`}
          />
        );
      })}
    </div>
  );
}

type CustomBreadcrumbProps = {
  breadCrumbs: CustomCrumb[];
  handleCrumbClick: (key: string) => void;
};

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({ breadCrumbs, handleCrumbClick }) => {
  return (
    <div className='breadcrumb'>
      {breadCrumbs.map((crumb, index) => {
        return (
          <Crumb.Custom
            index={index}
            crumbs={breadCrumbs.map((breadCrumb) => breadCrumb.key)}
            crumb={`${crumb.value.substring(0, 30)}${crumb.value.length > 40 ? '...' : ''}`}
            key={`crumb-${index}`}
            handleCrumbClick={handleCrumbClick}
          />
        );
      })}
    </div>
  );
};

Breadcrumb.Custom = CustomBreadcrumb;

export default Breadcrumb;
