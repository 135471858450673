import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../ui/button/Button';
import { updateFundMembership } from '../../../../../services/api/membership';
import { IFrontendParentCompany } from '../../../../../types/entities/organization';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  company: IFrontendParentCompany;
  handleClose: () => void;
  handleOnSubmit: (company: IFrontendParentCompany) => void;
};
const GiveAccess = ({ company, handleClose, handleOnSubmit }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'giveAccessFund' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });

  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = async () => {
    setLoadingButton(true);
    const data = await updateFundMembership(company.id, 'fund');
    setLoadingButton(false);
    if (!data) return;
    handleOnSubmit(company);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <FormHeader title={t('title', { fundName: company.name })} description={t('description')} />
      <FormButtonSection>
        <Button
          lookAndFeel='secondary'
          text={tGeneral('cancel')}
          onClick={handleClose}
          size='small'
        />
        <Button
          lookAndFeel='primary'
          text={t('giveAccess')}
          onClick={handleSubmit}
          size='small'
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default GiveAccess;
