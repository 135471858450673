import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../../constants/routes';
import { UserContext } from '../../../../../context/userContext';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import controller from '../../../../../environments/controller';
import checkFormErrors from '../../../../../utils/checkFormErrors';
import Button from '../../../../ui/button/Button';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import FormCalendarDouble from '../../../../ui/formComponents2/formInputs/formCalendarDouble/FormCalendarDouble';
import QrCode from '../../../../ui/qrCode/QrCode';
import { InfoTag } from '../infoTag/InfoTag';
import { transformDateBackend } from '../utils/utils';
import './styles.scss';

type FormData = {
  startDate: string;
  endDate: string;
  errors: ErrorType[];
};

const QRCodeModal = () => {
  const environment = controller();
  const currentYear = moment().format('YYYY');

  const { t, i18n } = useTranslation();
  const user = useContext(UserContext);

  const [formData, setFormData] = useState<FormData>({
    startDate: '',
    endDate: '',
    errors: []
  });

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  const downloadPdf = () => {
    const url = `/files/employees/${i18n.resolvedLanguage}/Employees.pdf`;
    const link = document.createElement('a');
    link.href = url;
    link.download = 'employees.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    // Download pdf file in public/files/events/{i18n.resolvedLanguage}/events.pdf
    return;
  };

  const handleDownload = () => {
    handleErrors();
    //Download svg inside the div with class qr-event-attendees
    const svg = document.querySelector('.qr-wrapper svg');

    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);

    const blob = new Blob([svgData], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'image.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    downloadPdf();
  };

  const handleErrors = () => {
    const newErrors: ErrorType[] = checkFormErrors(formData, []);

    if (newErrors.length === 0) return;

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors;
  };

  return (
    <>
      <FormHeader title={t('employees.qrTitle')} description={t('employees.qrDescription')} />
      <InfoTag />
      <FormWrapper>
        <FormCalendarDouble
          tooltip={t('employees.dateRangeTooltip')}
          label={t('sendEmailForm.measurement_period')}
          handleChangeStartDate={onChangeValue('startDate')}
          handleChangeEndDate={onChangeValue('endDate')}
          handleStartDateError={handleDateError('startDate')}
          handleEndDateError={handleDateError('endDate')}
          startDate={formData.startDate}
          endDate={formData.endDate}
          maxEndDate={`31/12/${currentYear}`}
          minStartDate={'01/01/1960'}
          minEndDate={'01/01/1960'}
          error={formData?.errors?.find(
            (elem) => elem.error === 'startDate' || elem.error === 'endDate'
          )}
        />
      </FormWrapper>

      {formData.startDate && formData.endDate && (
        <div className='qr-wrapper'>
          <QrCode
            value={`${
              environment.amplify.Auth.oauth.redirectSignOut
            }${ROUTES.EMPLOYEE_FORM_QR.slice(1)}/${user?.selectedOrganization}/${
              i18n.resolvedLanguage
            }/${transformDateBackend(formData?.startDate)}/${transformDateBackend(
              formData?.endDate
            )}`}
            maxWidth={95}
          />
        </div>
      )}

      <Button
        text={t('employees.download')}
        lookAndFeel={'primary'}
        onClick={handleDownload}
        size='small'
      />
    </>
  );
};

export default QRCodeModal;
