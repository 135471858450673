import { ReactNode } from 'react';
import TooltipWrapper from './TooltipWrapper';
import './styles.scss';

type Props = {
  text: string | ReactNode;
  position?: 'top' | 'right' | 'left';
  icon?: string;
  size?: string;
  containerPosition?: DOMRect;
};

const Tooltip = ({ icon = '/images/icons/info.svg', ...rest }: Props) => {
  return (
    <TooltipWrapper {...rest}>
      <img src={icon} alt='info-icon' className='icon-tooltip' />
    </TooltipWrapper>
  );
};

export default Tooltip;
