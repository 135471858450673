import moment from 'moment';
import { useEffect, useState } from 'react';
import { groupByCategoryHolding as groupByCategoryHoldingFunction } from '../../../../constants/groupByCategoryFunction';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { getDataQualityMatrix } from '../../../../services/api/data_quality';
import { getIntensityMetricById } from '../../../../services/api/intensityMetrics';
import { getMatrix } from '../../../../services/api/matrices';
import {
  getBusinessTravelsGroupedByRouteAndTransport,
  getEmployeesFacade,
  getTotalImpactPurchases
} from '../../../../services/api/total_impacts';
import { EmployeeReport, ExtraInformationType } from '../../../../types/entities/employee';
import { FacilityDashboard, FacilityReport } from '../../../../types/entities/facility';
import { Holding, InvestmentReport, OrgInfo } from '../../../../types/entities/holdings';
import { OrganizationShort } from '../../../../types/entities/organization';
import { PurchasesReport } from '../../../../types/purchases';

import { BusinessTravelReport } from '../../../../types/entities/businessTravels';
import { TransportDistributionReport } from '../../../../types/entities/shipment';
import {
  LcasTransformReport,
  LcasUsageReport,
  LcasWasteReport
} from '../../../../types/entities/useAndEndOfLife';
import { User } from '../../../../types/entities/user';
import { VehicleReport } from '../../../../types/entities/vehicle';
import { GroupDataMode } from '../../../../types/entitiesEnums/report';
import apiFetch from '../../../../utils/apiFetch';
import makeRequestsInBatches from '../../../../utils/makeRequestInBatches';
import { numberToDecimalNonZero } from '../../../../utils/numberToDecimal';

type Props = { groupDataMode?: GroupDataMode; user?: User };

const useGetData = ({ groupDataMode, user }: Props) => {
  const flags = useFeatureFlags();
  const groupByCategoryHolding = groupByCategoryHoldingFunction(flags).map((group) => ({
    ...group,
    category: group.category
  }));

  const [intensityMetric, setIntensityMetric] = useState<IntensityMetricReport>({
    id: '',
    name: '',
    value: 0,
    unit: '',
    start_date: '',
    end_date: ''
  });
  const [facilities, setFacilities] = useState<FacilityDashboard[]>();
  const [vehicles, setVehicles] = useState<VehicleReport[]>();
  const [employees, setEmployees] = useState<EmployeeReport[]>();
  const [businessTravels, setBusinessTravels] = useState<BusinessTravelReport[]>();
  const [shipments, setShipments] = useState<TransportDistributionReport>();
  const [deliveries, setDeliveries] = useState<TransportDistributionReport>();
  const [purchases, setPurchases] = useState<PurchasesReport>({
    total: 0,
    total_recycled: 0,
    items: []
  });
  const [investments, setInvestments] = useState<InvestmentReport[]>([]);
  const [transform, setTransform] = useState<LcasTransformReport[]>([]);
  const [usage, setUsage] = useState<LcasUsageReport[]>([]);
  const [waste, setWaste] = useState<LcasWasteReport[]>([]);
  const [organizations, setOrganizations] = useState<OrganizationShort[]>([]);
  const [employeesLength, setEmployeesLength] = useState<number>(0);
  const [dataScopes, setDataScopes] = useState<ScopeData[]>([
    {
      scope: 1,
      total_emissions: 0,
      measured: false
    },
    {
      scope: 2,
      total_emissions: 0,
      measured: false
    },
    {
      scope: 3,
      total_emissions: 0,
      measured: false
    }
  ]);
  const [dataCategories, setDataCategories] = useState<CategoryData[]>([]);
  const [dataQuality, setDataQuality] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [extraInformation, setExtraInformation] = useState<ExtraInformationType>({
    pending: 0,
    inactive: 0,
    terminated: 0,
    switched: 0,
    year: Number(new URLSearchParams(window.location.search).get('start_date')?.split('/')[2])
  });
  const [dataHolding, setDataHolding] = useState<Holding>();
  const [totalCo2Facilities, setTotalCo2Facilities] = useState<number>(0);

  const foundOrg = user?.organizations?.find(
    (organization) => organization.id === user.selectedOrganization
  );

  const fetchData = async (
    start_date: Date | null,
    end_date: Date | null,
    categories_to_show: CategoryWithScope[]
  ) => {
    if (!start_date || !end_date || !user?.selectedOrganization || !groupDataMode) return;
    setLoading(true);
    const requests = [];

    let requestLcaWaste = null;
    if (categories_to_show.find((elem) => elem.category === 'sold_products_waste')) {
      requestLcaWaste = () =>
        apiFetch('POST', '/total_impacts/sold_products_waste', {
          // start_date: Math.floor(new Date('2024-01-25').getTime() / 1000),
          // end_date: Math.floor(new Date('2024-01-25').getTime() / 1000)
          start_date: Math.floor(start_date.getTime() / 1000),
          end_date: Math.floor(end_date.getTime() / 1000)
        });
    }
    requests.push(requestLcaWaste);

    let requestLcaUsage = null;
    if (categories_to_show.find((elem) => elem.category === 'sold_products_usage')) {
      requestLcaUsage = () =>
        apiFetch('POST', '/total_impacts/sold_products_usage', {
          // start_date: Math.floor(new Date('2024-01-25').getTime() / 1000),
          // end_date: Math.floor(new Date('2024-01-25').getTime() / 1000)
          start_date: Math.floor(start_date.getTime() / 1000),
          end_date: Math.floor(end_date.getTime() / 1000)
        });
    }
    requests.push(requestLcaUsage);

    let requestLcaTransform = null;
    if (
      categories_to_show.find((elem) => elem.category === 'sold_products_intermediate_processing')
    ) {
      requestLcaTransform = () =>
        apiFetch('POST', '/total_impacts/sold_products_intermediate_processing', {
          // start_date: Math.floor(new Date('2024-01-25').getTime() / 1000),
          // end_date: Math.floor(new Date('2024-01-25').getTime() / 1000)
          start_date: Math.floor(start_date.getTime() / 1000),
          end_date: Math.floor(end_date.getTime() / 1000)
        });
    }
    requests.push(requestLcaTransform);

    let requestVehicles = null;
    if (categories_to_show.find((elem) => elem.category === 'transport')) {
      requestVehicles = () =>
        apiFetch('POST', '/total_impacts/vehicles_type', {
          organization_id: user?.selectedOrganization,
          start_date,
          end_date,
          switch: groupDataMode
        });
    }
    requests.push(requestVehicles);

    let requestFacilities = null;
    if (
      categories_to_show.find(
        (elem) =>
          elem.category === 'stationary' ||
          elem.category === 'recharge' ||
          elem.category === 'wastes' ||
          elem.category === 'electricity' ||
          elem.category === 'water'
      )
    ) {
      requestFacilities = () =>
        apiFetch('POST', '/total_impacts/facilities', {
          start_date,
          end_date,
          organization_id: user.selectedOrganization,
          switch: groupDataMode
        });
    }
    requests.push(requestFacilities);

    let requestEmployees = null;
    if (categories_to_show.find((elem) => elem.category === 'employees')) {
      requestEmployees = () =>
        getEmployeesFacade(
          user.selectedOrganization ?? '',
          start_date,
          end_date,
          groupDataMode,
          true
        );
    }
    requests.push(requestEmployees);

    let requestBusinessTravels = null;
    if (categories_to_show.find((elem) => elem.category === 'travels')) {
      requestBusinessTravels = () =>
        getBusinessTravelsGroupedByRouteAndTransport(
          user.selectedOrganization ?? '',
          start_date,
          end_date,
          groupDataMode,
          true
        );
    }
    requests.push(requestBusinessTravels);

    let requestTransportDistributionDownstream = null;
    if (categories_to_show.find((elem) => elem.category === 'transport_distribution_downstream')) {
      requestTransportDistributionDownstream = () =>
        apiFetch('POST', '/total_impacts/transport_distribution', {
          start_date,
          end_date,
          organization_id: user.selectedOrganization,
          direction: 'downstream',
          switch: groupDataMode
        });
    }
    requests.push(requestTransportDistributionDownstream);

    let requestTransportDistributionUpstream = null;
    if (categories_to_show.find((elem) => elem.category === 'transport_distribution_upstream')) {
      requestTransportDistributionUpstream = () =>
        apiFetch('POST', '/total_impacts/transport_distribution', {
          start_date,
          end_date,
          organization_id: user.selectedOrganization,
          direction: 'upstream',
          switch: groupDataMode
        });
    }
    requests.push(requestTransportDistributionUpstream);

    // Get Employees of organization to show sample of completed employees and pending employees
    let requestEmployeesLength = null;
    if (categories_to_show.find((elem) => elem.category === 'employees')) {
      requestEmployeesLength = () =>
        apiFetch('GET', '/employees', {}, { 'x-organization-id': user?.selectedOrganization });
    }
    requests.push(requestEmployeesLength);

    // const requestCategories = () =>
    //   apiFetch('post', '/total_impacts/category', {
    //     organization_id: user?.selectedOrganization,
    //     start_date,
    //     end_date,
    //     switch: GroupDataMode.TOTAL
    //   });
    // requests.push(requestCategories);

    let requestDataQuality = () => apiFetch('get', `/data_quality/${user?.selectedOrganization}`);
    if (user.selectedOrganization && user.selectedOrganization === user.selectedOrganization) {
      requestDataQuality = () => getDataQualityMatrix(user.selectedOrganization ?? '', true);
    }
    requests.push(requestDataQuality);

    let requestPurchases = null;
    if (categories_to_show.find((elem) => elem.category === 'purchases')) {
      requestPurchases = () =>
        getTotalImpactPurchases(
          start_date,
          end_date,
          user?.selectedOrganization ?? '',
          groupDataMode,
          '',
          true
        );
    }
    requests.push(requestPurchases);

    let requestInvestments = null;
    requestInvestments = () =>
      getMatrix(user.selectedOrganization ?? '', start_date, end_date, groupDataMode, true);
    requests.push(requestInvestments);

    // Check if year is present and the value of the intensity metric in the query url params
    const query = new URLSearchParams(window.location.search);
    const valueIntensityMetric = query.get('intensity_metric');
    const year = query.get('start_date')?.split('/')[2];

    let requestIntensityMetrics = null;
    if (valueIntensityMetric) {
      // Check if the intensity metric is the employees intensity metric that is by default the one that we use
      if (
        valueIntensityMetric &&
        year !== null &&
        valueIntensityMetric !== 'employees' &&
        user.selectedOrganization
      ) {
        //Request to get the intensity metric value by id and year
        requestIntensityMetrics = () => getIntensityMetricById(valueIntensityMetric, true);
      }
    }
    requests.push(requestIntensityMetrics);

    const [
      responseWaste,
      responseUsage,
      responseTransform,
      responseVehicles,
      responseFacilities,
      responseEmployees,
      responseBusinessTravels,
      responseTransportDistributionDownstream,
      responseTransportDistributionUpstream,
      responseEmployeesLength,
      responseDataQuality,
      responsePurchases,
      responseInvestments,
      responseIntensityMetrics
    ] = await makeRequestsInBatches(requests);

    if (responseWaste?.data) {
      const wasteSorted = responseWaste.data.sort(
        (a: LcasWasteReport, b: LcasWasteReport) => b.co2e - a.co2e
      );
      setWaste(wasteSorted);
    }

    if (responseUsage?.data) {
      const usageSorted = responseUsage.data.sort(
        (a: LcasUsageReport, b: LcasUsageReport) => b.co2e - a.co2e
      );
      setUsage(usageSorted);
    }

    if (responseTransform?.data) {
      const transformSorted = responseTransform.data.sort(
        (a: LcasTransformReport, b: LcasTransformReport) => b.co2e - a.co2e
      );

      setTransform(transformSorted);
    }

    if (responseVehicles?.data?.sort) {
      const vehiclesSorted = responseVehicles.data.sort(
        (a: VehicleReport, b: VehicleReport) => b.co2e - a.co2e
      );
      setVehicles(vehiclesSorted);
    }

    if (responseFacilities) {
      const facilitiesSorted = responseFacilities.data
        .filter((elem: FacilityReport) => elem.ghg_gas === 'co2e')
        .sort((a: FacilityReport, b: FacilityReport) => b.total_emissions - a.total_emissions);

      const facilitiesParsed: FacilityDashboard[] = [];
      responseFacilities.data
        .filter((elem: FacilityReport) => elem.ghg_gas === 'co2e')
        .forEach((elem: FacilityReport) => {
          const foundFacilityIndex = facilitiesParsed.findIndex(
            (facility) => facility.id === elem.id && facility.name === elem.name
          );
          if (foundFacilityIndex === -1) {
            facilitiesParsed.push({
              id: elem.id,
              name: elem.name,
              co2e: {
                total: elem.total_emissions,
                [elem.category]: elem.total_emissions
              },
              organization_id: elem.organization_id
            });
          } else {
            facilitiesParsed[foundFacilityIndex].co2e.total += elem.total_emissions;
            // Check if category exists
            if (!facilitiesParsed[foundFacilityIndex].co2e[elem.category]) {
              facilitiesParsed[foundFacilityIndex].co2e[elem.category] = 0;
            }
            facilitiesParsed[foundFacilityIndex].co2e[elem.category] += elem.total_emissions;
          }
        });
      setFacilities(facilitiesParsed);

      let totalCo2Fac = 0;
      facilitiesSorted.forEach((facility: FacilityReport) => {
        if (categories_to_show.find((elem) => elem.category === facility.category)) {
          totalCo2Fac += facility.total_emissions;
        }
      });
      setTotalCo2Facilities(totalCo2Fac);
    }

    if (responseEmployees) {
      const employeesSorted = responseEmployees.data.employees.sort(
        (a: EmployeeReport, b: EmployeeReport) => b.co2e - a.co2e
      );
      setEmployees(employeesSorted);
      setExtraInformation((prev) => ({
        ...prev,
        ...responseEmployees.data
      }));
    }
    if (responseBusinessTravels) {
      const businessTravelsSorted = responseBusinessTravels.data.sort(
        (a: BusinessTravelReport, b: BusinessTravelReport) => b.co2e - a.co2e
      );
      setBusinessTravels(businessTravelsSorted);
    }
    if (responseTransportDistributionDownstream) {
      setShipments(responseTransportDistributionDownstream.data);
    }
    if (responseTransportDistributionUpstream) {
      setDeliveries(responseTransportDistributionUpstream.data);
    }

    if (responseEmployeesLength) {
      setEmployeesLength(responseEmployeesLength.data.total);
    }

    let totalCo2 = 0;
    const scopeValues = [
      {
        scope: 1,
        total_emissions: 0,
        measured: false
      },
      {
        scope: 2,
        total_emissions: 0,
        measured: false
      },
      {
        scope: 3,
        total_emissions: 0,
        measured: false
      }
    ];

    const categoryValues = [] as CategoryData[];

    setDataQuality(responseDataQuality.data.data_quality_value);

    if (responsePurchases) {
      const purchasesSorted = responsePurchases.data.items.sort(
        (a: any, b: any) => b.co2e - a.co2e
      );
      setPurchases({
        total: responsePurchases.data.total,
        total_recycled: responsePurchases.data.total_recycled,
        items: purchasesSorted
      });
    }

    const investmentsData: Holding = {
      ...(responseInvestments?.data as Holding),
      parent_co2e: responseInvestments?.data.parent_co2e?.map((elem: any) => ({
        ...elem,
        category: elem.category
          .replace(/_in_labore/g, '')
          .replace(/_in_itinere/, '')
          .replace('passenger', 'transport')
          .replace('freight', 'transport')
      })),
      childs_info: responseInvestments?.data.childs_info?.map((elem: OrgInfo) => ({
        ...elem,
        co2e: elem.co2e.map((elem2) => ({
          ...elem2,
          category: elem2.category
            .replace(/_in_labore/g, '')
            .replace(/_in_itinere/, '')
            .replace('passenger', 'transport')
            .replace('freight', 'transport')
        }))
      }))
    };

    // Clean groupByCategoryHolding so the emissions are empty
    groupByCategoryHolding.forEach((elem) => {
      elem.total_emissions = 0;
    });

    if (responseInvestments && !!investmentsData.childs_info) {
      setDataHolding(investmentsData);

      if (groupDataMode === GroupDataMode.INVESTMENT) {
        const investmentsParsed = investmentsData.childs_info.map((investment: OrgInfo) => {
          let investmentCo2 = 0;
          investment.co2e.forEach((elem) => {
            const categoryParsed = elem.category
              .replace(/_in_labore/g, '')
              .replace(/_in_itinere/, '')
              .replace('passenger', 'transport')
              .replace('freight', 'transport');
            if (categories_to_show.find((category) => category.category === categoryParsed)) {
              investmentCo2 += elem.total_emissions;
            }
          });
          return {
            ...investment,
            id: investment.org_id,
            co2e: investmentCo2
          };
        });
        const groupByScope = [
          { scope: 1, total_emissions: 0 },
          { scope: 2, total_emissions: 0 },
          { scope: 3, total_emissions: 0 }
        ];

        investmentsData.childs_info.forEach((child) => {
          child.co2e.forEach((elem) => {
            const categoryParsed = elem.category
              .replace(/_in_labore/g, '')
              .replace(/_in_itinere/, '')
              .replace('passenger', 'transport')
              .replace('freight', 'transport');
            if (categories_to_show.find((category) => category.category === categoryParsed)) {
              // only scope 3 because investments are scope 3
              groupByScope[2].total_emissions += elem.total_emissions;

              const foundCategoryIndex = groupByCategoryHolding.findIndex(
                (elem2) => elem2.category === 'investments'
              );
              if (foundCategoryIndex !== -1) {
                groupByCategoryHolding[foundCategoryIndex].total_emissions += elem.total_emissions;
              } else {
                groupByCategoryHolding.push({
                  category: 'investments',
                  total_emissions: elem.total_emissions,
                  scope: 3
                });
              }
            }
          });
        });

        investmentsData.parent_co2e?.forEach((elem) => {
          const categoryParsed = elem.category
            .replace(/_in_labore/g, '')
            .replace(/_in_itinere/, '')
            .replace('passenger', 'transport')
            .replace('freight', 'transport');
          if (categories_to_show.find((category) => category.category === categoryParsed)) {
            if (elem.scope === 1) {
              groupByScope[0].total_emissions += elem.total_emissions;
            }
            if (elem.scope === 2) {
              groupByScope[1].total_emissions += elem.total_emissions;
            }
            if (elem.scope === 3) {
              groupByScope[2].total_emissions += elem.total_emissions;
            }

            const foundCategoryIndex = groupByCategoryHolding.findIndex(
              (elem2) => elem2.category === categoryParsed
            );

            if (foundCategoryIndex !== -1) {
              groupByCategoryHolding[foundCategoryIndex].total_emissions += elem.total_emissions;
            } else {
              groupByCategoryHolding.push({
                category: categoryParsed,
                total_emissions: elem.total_emissions,
                scope: elem.scope
              });
            }
          }
        });

        setDataScopes(groupByScope);

        setDataCategories(groupByCategoryHolding);

        const investmentsSorted = investmentsParsed.sort(
          (a: InvestmentReport, b: InvestmentReport) => b.co2e - a.co2e
        );
        setInvestments(investmentsSorted);
      } else {
        const orgs: OrganizationShort[] = [
          {
            id: foundOrg?.id ?? '',
            company_name: foundOrg?.company_name ?? ''
          }
        ];
        investmentsData.childs_info.forEach((child: any) => {
          orgs.push({
            id: child.org_id,
            company_name: child.org_name
          });

          child.co2e.forEach((elem: any) => {
            const categoryParsed = elem.category
              .replace(/_in_labore/g, '')
              .replace(/_in_itinere/, '')
              .replace('passenger', 'transport')
              .replace('freight', 'transport');

            if (categories_to_show.find((category) => category.category === categoryParsed)) {
              if (elem.scope === 1) {
                scopeValues[0].total_emissions += elem.total_emissions;
              }
              if (elem.scope === 2) {
                scopeValues[1].total_emissions += elem.total_emissions;
              }
              if (elem.scope === 3) {
                scopeValues[2].total_emissions += elem.total_emissions;
              }

              const foundCategoryIndex = categoryValues.findIndex(
                (elem2) => elem2.category === categoryParsed
              );
              if (foundCategoryIndex !== -1) {
                categoryValues[foundCategoryIndex].total_emissions += elem.total_emissions;
              } else {
                categoryValues.push({
                  category: categoryParsed,
                  total_emissions: elem.total_emissions,
                  scope: elem.scope
                });
              }
            }
          });
        });

        investmentsData.parent_co2e?.forEach((elem: any) => {
          const categoryParsed = elem.category
            .replace(/_in_labore/g, '')
            .replace(/_in_itinere/, '')
            .replace('passenger', 'transport')
            .replace('freight', 'transport');
          const categoryFound = categories_to_show.find(
            (category) => category.category === categoryParsed
          );
          if (categoryFound) {
            if (elem.scope === 1) {
              scopeValues[0].total_emissions += elem.total_emissions;
            }
            if (elem.scope === 2) {
              scopeValues[1].total_emissions += elem.total_emissions;
            }
            if (elem.scope === 3) {
              scopeValues[2].total_emissions += elem.total_emissions;
            }

            const foundCategoryIndex = categoryValues.findIndex(
              (elem2) => elem2.category === categoryParsed
            );

            if (foundCategoryIndex !== -1) {
              categoryValues[foundCategoryIndex].total_emissions += elem.total_emissions;
            } else {
              categoryValues.push({
                category: categoryParsed,
                total_emissions: elem.total_emissions,
                scope: elem.scope
              });
            }
          }
        });

        // Same for parent_co2e
        setDataScopes(scopeValues);
        setDataCategories(categoryValues);
        setOrganizations(orgs);
      }
    }

    scopeValues.forEach((elem) => {
      totalCo2 += elem.total_emissions;
    });

    if (
      responseIntensityMetrics &&
      responseIntensityMetrics.data &&
      responseIntensityMetrics.data.id
    ) {
      setIntensityMetric({
        id: responseIntensityMetrics.data.id,
        name: `${responseIntensityMetrics.data.unit ?? ''}`,
        value: numberToDecimalNonZero(responseIntensityMetrics.data.value / 1000, 3),
        unit: 't CO₂ eq.',
        start_date: start_date?.toISOString() ?? '',
        end_date: end_date?.toISOString() ?? ''
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!user || !user.selectedOrganization) return;
    const query = new URLSearchParams(window.location.search);
    let start_date: Date | null = null;
    let end_date: Date | null = null;

    if (query.get('start_date') && query.get('end_date')) {
      start_date = new Date(moment(query.get('start_date'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
      end_date = new Date(moment(query.get('end_date'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }
    let categories_to_show = [] as CategoryWithScope[];
    const categoriesQuery = query.get('categories');
    if (categoriesQuery) {
      const categoriesQuerySplitted = categoriesQuery.split(',');
      const categoriesToShowMapped = categoriesQuerySplitted.map((category) => {
        const foundCategory = groupByCategoryHolding.find((elem) => {
          const categoryParsed = elem.category
            .replace(/_in_labore/g, '')
            .replace(/_in_itinere/, '')
            .replace('passenger', 'transport')
            .replace('freight', 'transport');
          return categoryParsed === category;
        });
        let categoryScope = 0;
        if (foundCategory) {
          categoryScope = foundCategory.scope;
        }
        const categoryParsed = category
          .replace(/_in_labore/g, '')
          .replace(/_in_itinere/, '')
          .replace('passenger', 'transport')
          .replace('freight', 'transport');
        return {
          category: categoryParsed,
          scope: categoryScope
        };
      });
      if (groupDataMode === GroupDataMode.INVESTMENT) {
        categoriesToShowMapped.push({
          category: 'investments',
          scope: 3
        });
      }
      categories_to_show = categoriesToShowMapped;
    }

    if (!groupDataMode) return;
    fetchData(start_date, end_date, categories_to_show);
  }, [groupDataMode]);

  return {
    loading,
    dataScopes,
    dataCategories,
    intensityMetric,
    investments,
    organizations,
    facilities,
    vehicles,
    employees,
    businessTravels,
    shipments,
    deliveries,
    purchases,
    waste,
    usage,
    transform,
    employeesLength,
    dataQuality,
    extraInformation,
    dataHolding,
    totalCo2Facilities
  };
};

export default useGetData;
