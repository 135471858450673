import InputCalendarDouble from '../../inputCalendarDouble/InputCalendarDouble';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children' | 'icon'>;
type InputCalendarDoubleType = React.ComponentProps<typeof InputCalendarDouble>;

type Props = InputCalendarDoubleType & InputWrapperType;

const FormCalendarDouble = (props: Props) => {
  return (
    <InputWrapper iconV2='calendar' {...props}>
      <InputCalendarDouble {...props} />
    </InputWrapper>
  );
};

export default FormCalendarDouble;
