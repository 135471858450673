import { useState } from 'react';
import useDebounceValue from '../../../../../../customHooks/useDebounceValue';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { FiltersLogisticsShipments } from '../types';

const emptyFilters: FiltersLogisticsShipments = {
  id: '',
  client: {
    id: '',
    name: ''
  },
  startDate: '',
  endDate: '',
  vehicleLicense: '',
  trailerLicense: '',
  loadKgLower: '',
  loadKgUpper: '',
  distanceKmLower: '',
  distanceKmUpper: '',
  tkmLower: '',
  tkmUpper: '',
  vehicleType: {
    id: '',
    name: ''
  },
  tripStatus: '',
  cleaning: {
    id: '',
    name: ''
  },
  subcontractor: {
    id: '',
    name: ''
  },
  errors: []
};

const useFilters = () => {
  const [filters, setFilter] = useState<FiltersLogisticsShipments>(emptyFilters);
  const debouncedFilters = useDebounceValue(filters);

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData: setFilter });

  const handleRemoveFilters = () => setFilter(emptyFilters);
  const counterFiltersSelected = () => {
    const { errors, ...filtersWithoutErrors } = filters;

    let counter = 0;
    Object.values(filtersWithoutErrors).forEach((value: any) => {
      if (value.id === '') return;
      if (value?.id === undefined && value === '') return;
      counter++;
    });

    return counter;
  };

  return {
    onChangeValue,
    handleDateError,
    filters,
    debouncedFilters,
    handleRemoveFilters,
    counterFiltersSelected: counterFiltersSelected()
  };
};

export default useFilters;
