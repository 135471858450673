import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { UserContext } from '../../../../../context/userContext';
import { removePeriod } from '../../../../../services/api/employeePeriod';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  deletePeriod: (employeeId: string, id: string) => void;
  employeeId: string;
  periodId: string;
};
function DeletePeriod({ deletePeriod, employeeId, periodId }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const dispatch = useDispatch();

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeletePeriod();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeletePeriod = async () => {
    if (!user?.selectedOrganization) return;
    setLoadingButton(true);
    const response = await removePeriod(employeeId, periodId, user?.selectedOrganization);
    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    deletePeriod(employeeId, periodId);
    setLoadingButton(false);
    dispatch(setNotification(t('notification.deletePeriod')));
  };

  return (
    <div className='delete-modal'>
      <FormHeader
        title={t('employees.deleteEmployeePeriod')}
        description={t('employees.areYouSureDeletePeriod')}
      />

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('employees.deletePeriod')}
          onClick={handleDeletePeriod}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
}

export default DeletePeriod;
