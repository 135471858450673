import { useTranslation } from 'react-i18next';

import { ReactNode, useContext } from 'react';
import {
  EmployeeFormContext,
  employeeFormEmpty
} from '../../../../../../context/employeeFormContext';
import { EmployeePeriodFormType } from '../../../../../../types/entities/employeePeriod';

import { useParams } from 'react-router-dom';
import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import Button from '../../../../../ui/button/Button';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';
import '../styles.scss';
type Props = {
  next: string;
  title?: ReactNode;
};
function StepTelecommute({ next, title }: Props) {
  const { t } = useTranslation();
  const { startDate, endDate } = useParams();

  const { formData, setFormData, stepCounter, handleNext, handleBack } =
    useContext(EmployeeFormContext);

  const options = [
    {
      id: 'yes',
      name: t('employeeForm.workFromHome')
    },
    {
      id: 'no',
      name: t('employeeForm.goToOffice')
    }
  ];

  const handleErrors = () => {
    const newErrors: ErrorType[] = [];

    if (!formData?.telecommuting.id)
      newErrors.push({
        error: 'telecommuting',
        description: t('error.requiredField')
      });

    if (newErrors.length > 0) {
      setFormData((previous: EmployeePeriodFormType) => ({ ...previous, errors: newErrors }));
      return newErrors;
    }
    return false;
  };

  const handleNextStep = () => {
    if (handleErrors() || !handleNext) return;

    if (formData.telecommuting.id === 'yes') handleNext('summary');
    else handleNext(next);
  };

  const handleOnChangeTelecommuting = (value: SelectOptionFormat) => {
    setFormData((previous: EmployeePeriodFormType) => ({
      ...previous,
      transport: 'telecommuting',
      fuelType: employeeFormEmpty.fuelType,
      renewableEnergy: undefined,
      size: employeeFormEmpty.size,
      weeklyTravels: employeeFormEmpty.weeklyTravels,
      dailyTrips: employeeFormEmpty.dailyTrips,
      totalKm: employeeFormEmpty.totalKm,
      telecommuting: value,
      carpooling: employeeFormEmpty.carpooling,
      errors: []
    }));
  };

  if (!handleNext || !handleBack) return null;

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle1', {
        date: `${startDate && convertDateBackToFront(startDate)} - ${
          endDate && convertDateBackToFront(endDate)
        }`
      })}
      subtitle={t('employeeForm.formSubtitle2')}>
      <StepWrapper title={t(`employeeForm.workModel`)} stepNumber={stepCounter}>
        <FormSelect
          icon={'/images/icons/person.svg'}
          placeholder={t('employeeForm.selectOption')}
          value={formData.telecommuting}
          onChange={handleOnChangeTelecommuting}
          options={options}
          error={formData?.errors?.find((error) => error.error === 'telecommuting')}
        />
        <div className='button-wrapper-send-form'></div>
        <Button lookAndFeel={'primary'} onClick={handleNextStep} text={t('employeeForm.next')} />
      </StepWrapper>
      <SwitchStep handleNext={handleNextStep} handleBack={handleBack} />
    </StepControllerContainer>
  );
}

export default StepTelecommute;
