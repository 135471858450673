import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';

const useToggleOptions = () => {
  const flags = useFeatureFlags();
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel'
  });
  const toggleOptions = [
    {
      id: 'environmental',
      name: t('environmental')
    },
    {
      id: 'socialAndGovernance',
      name: t('socialAndGovernance')
    }
  ];

  if (flags?.showControlPanel) {
    toggleOptions.push({
      id: 'controlPanel',
      name: t('controlPanel')
    });
  }
  return toggleOptions;
};

export default useToggleOptions;
