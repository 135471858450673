import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { localStorageGetItem, localStorageSetItem } from '../utils/localStorage';

const FLAGS_NAME = 'flags';

export const useFeatureFlags = () => {
  const ldClient = useLDClient();
  const flagsLD = useFlags();
  const [allFlags, setAllFlags] = useState<Flag | undefined>(
    localStorageGetItem(FLAGS_NAME) || flagsLD
  );

  const onChange = (newFlags: {
    [key: string]: {
      current: boolean;
      previous: boolean;
    };
  }) => {
    // Merge the existing flag state with the new flag state

    const flags = { ...allFlags };
    Object.keys(newFlags).forEach((key) => {
      flags[key] = newFlags[key].current;
    });

    setAllFlags(flags);
    localStorageSetItem(FLAGS_NAME, flags);
  };

  useEffect(() => {
    if (!ldClient) return;

    setAllFlags(flagsLD);
    localStorageSetItem(FLAGS_NAME, flagsLD);

    // Register a callback to update the flag state whenever it changes
    ldClient.on('change', onChange);

    // Clean up the event listener when the component unmounts
    return () => {
      if (!ldClient) return;
      ldClient.off('change', onChange);
    };
  }, [ldClient, flagsLD]);

  return allFlags;
};
