import { useQuery } from '@tanstack/react-query';
import { getPurchaseSuppliers } from '../../../../../../services/api/purchaseSuppliers';
import { PURCHASE_STATUS } from '../../constants';

export const useSuppliersInReview = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['purchaseSuppliers', 'in_review'],
    queryFn: () =>
      getPurchaseSuppliers({
        filters: [
          {
            field: 'status',
            value: PURCHASE_STATUS.in_review,
            type: 'eq'
          }
        ]
      }),
    select: (data) => data.total
  });

  return {
    data,
    isLoading
  };
};
