import apiFetch from '../../utils/apiFetch';

type Props = {
  id: string;
  name: string;
};
function Download({ id, name }: Props) {
  const saveFile = async () => {
    const response: any = await apiFetch('GET', `/stripe_invoices/${id}/download`);
    window.open(response.data.url);
  };

  return (
    <img
      src='/images/icons/downloadGray.svg'
      alt='download'
      className='download-icon'
      onClick={saveFile}></img>
  );
}

export default Download;
