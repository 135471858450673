import { useEffect, useState } from 'react';
import { getFundList } from '../../../../../services/api/funds';
import { FundType } from '../../../../../types/entities/funds';

const useGetData = () => {
  const [funds, setFunds] = useState<SelectOptionFormat[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFunds = async () => {
      if (loading) return;
      setLoading(true);
      const data = await getFundList();
      if (data?.response?.status >= 400) {
        setLoading(false);
        return;
      }

      setFunds(
        data.items.map((fund: FundType) => ({
          id: fund.id,
          name: fund.fund_name
        }))
      );

      setLoading(false);
    };

    getFunds();
  }, []);

  return { funds, loading };
};

export default useGetData;
