import IconBackground from '../../../ui/iconBackground/IconBackground';
import './styles.scss';

type Props = {
  indexList: {
    title: string;
    image: string;
    id: string;
  }[];
};

function ReportIndex({ indexList }: Props) {
  return (
    <div className='report-index'>
      {indexList.map((item, index) => (
        <a className='report-index__item' key={index} href={item.id}>
          <IconBackground icon={item.image} alt='index-image' />
          <p className='small-card-font on-light-text-color'>{`0${index + 1}. ${item.title}`}</p>
        </a>
      ))}
    </div>
  );
}

export default ReportIndex;
