import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Invite, User } from '../../../types/entities/user';

import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';
import InputText from '../../ui/formComponents/inputText/InputText';
import Select from '../../ui/formComponents/select/Select';
import './styles.scss';

type Props = {
  user: User;
  onLinkAccount: () => void;
  invites: Invite[];
};

type Body = {
  parent_id?: string;
  vat?: string;
};
function LinkAccount({ user, onLinkAccount, invites }: Props) {
  const { t } = useTranslation();

  const [holdingId, setHoldingId] = useState('');
  const [holdingOption, setHoldingOption] = useState<SelectOptionFormat>({ id: '', name: '' });
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const onChangeHoldingId = (e: ChangeEvent<HTMLInputElement>) => {
    setHoldingId(e.target.value);
    const newErrors = errors.filter((elem) => elem.error !== 'holdingId');
    setErrors(newErrors);
  };

  const handleLinkHolding = async () => {
    try {
      setLoadingButton(true);
      if (!holdingId) {
        setErrors([{ error: 'holdingId' }]);
      } else {
        const body: Body = {};
        if (holdingOption.id) {
          body['parent_id'] = holdingOption.id;
        } else {
          body['vat'] = holdingId;
        }
        await apiFetch('POST', '/matrices/link', body, {
          'x-organization-id': user?.selectedOrganization
        });
        onLinkAccount();
      }
      setLoadingButton(false);
    } catch (error: any) {
      if (error.response.data === 'ORG_NOT_FOUND') {
        setErrors([
          {
            error: 'holdingId',
            description: t('error.holdingIdNotFound')
          }
        ]);
      }
      setLoadingButton(false);
    }
  };

  const onChangeHoldingOption = (value: SelectOptionFormat) => {
    setHoldingOption(value);
    setHoldingId(value.id);
    const newErrors = errors.filter((elem) => elem.error !== 'holdingId');
    setErrors(newErrors);
  };

  return (
    <div className='link-account'>
      <h1 className='headline4-font'>{t('profile.linkAccount')}</h1>
      <h2 className='subtitle3-font '>{t('profile.areYouSureLinkAccount')}</h2>
      <div className='form-wrapper'>
        {invites.length > 0 ? (
          <Select
            icon={'/images/icons/chain.svg'}
            placeholder={t('profile.selectHolding')}
            options={invites.map((elem) => ({
              id: elem.organization_id,
              name: elem.company_name || ''
            }))}
            value={holdingOption}
            onChangeValue={onChangeHoldingOption}
            height='31px'
            fontClass='input-small-font'
            size='small'
            error={errors.find((elem) => elem.error === 'holdingId')}
          />
        ) : (
          <InputText
            icon={'/images/icons/chain.svg'}
            placeholder={t('profile.writeHoldingId')}
            label={t('profile.holdingId')}
            value={holdingId}
            onChangeValue={onChangeHoldingId}
            height='31px'
            fontClass='input-small-font'
            size='small'
            error={errors.find((elem) => elem.error === 'holdingId')}
          />
        )}
      </div>
      <div className='buttons'>
        <Button
          lookAndFeel='primary'
          text={t('profile.link')}
          onClick={handleLinkHolding}
          size='small'
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default LinkAccount;
