import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboardLogistic.hubColumns' });
  return [
    {
      title: t('hub'),
      dataIndex: 'hub',
      key: 'hub'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('intensityValue')}</div>,
      dataIndex: 'intensityValue',
      key: 'intensityValue'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('managedTons')}</div>,
      dataIndex: 'managedTons',
      key: 'managedTons'
    },
    {
      title: <div style={{ textAlign: 'right' }}>{t('geiTotalEmissions')}</div>,
      dataIndex: 'geiTotalEmissions',
      key: 'geiTotalEmissions'
    }
  ];
};

export default useColumns;
