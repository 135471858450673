import React, { useState } from 'react';
import { GroupFunds } from '../../../../../../types/entities/groupFunds';
import FormHeader from '../../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../ui/button/Button';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../actions/notification';
import { replaceStrongTags } from '../../../../../../utils/stringToBold';
import { requestAccessFromGroupFund } from '../../../../../../services/api/matrices';
type Props = {
  company: GroupFunds;
  handleClose: () => void;
};
const RequestAccess = ({ company, handleClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'requestAccessFund' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tNotification } = useTranslation('translation', { keyPrefix: 'notification' });

  const [loadingButton, setLoadingButton] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    // TODO:
    setLoadingButton(true);
    const data = await requestAccessFromGroupFund(company.child_id);
    setLoadingButton(false);
    if (!data) return;
    dispatch(setNotification(tNotification('requestAccessFund')));
    handleClose();
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <FormHeader
        title={t('title')}
        description={replaceStrongTags(t('description', { name: company.child_name }))}
      />
      <FormButtonSection>
        <Button
          lookAndFeel={'secondary'}
          text={tGeneral('cancel')}
          onClick={handleClose}
          size='small'
        />
        <Button
          lookAndFeel={'primary'}
          text={t('requestAccess')}
          onClick={handleSubmit}
          size='small'
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default RequestAccess;
