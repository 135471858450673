import { useTranslation } from 'react-i18next';
import './styles.scss';

function LoaderLogin() {
  const { t } = useTranslation();
  return (
    <div className='loader-wrapper app-bg-color'>
      <h1 className='headline2-font'>{t('loader.title')}</h1>
      <div className='loader loader-login-bg-color'>
        <span className='loader-login-bg-color'></span>
        <span className='loader-login-bg-color'></span>
        <span className='loader-login-bg-color'></span>
        <span className='loader-login-bg-color'></span>
      </div>
      <img src='/images/logoGray.svg' alt='logo' />
    </div>
  );
}

export default LoaderLogin;
