/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { adaptPurchasesSupplierSpecificFrontToBack } from '../../../../../adapters/purchases';
import useErrors from '../../../../../customHooks/useErrors';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { postPurchaseSupplierSpecific } from '../../../../../services/api/purchases';
import {
  IPurchaseGeneralBackend,
  IPurchaseSupplierSpecificFormFront
} from '../../../../../types/purchases';

import FormSupplierSpecific from '../form/formSupplierSpecific/FormSupplierSpecific';

type Props = {
  addPurchase: (purchase: IPurchaseGeneralBackend) => void;
};

function AddPurchaseSupplierSpeciffic({ addPurchase }: Props) {
  const selectedOrganization = useSelectedOrganization();
  const ERRORS = useErrors();

  const [error, setError] = useState<ErrorType>();

  const handleSubmit = async (purchase: IPurchaseSupplierSpecificFormFront) => {
    if (!selectedOrganization) return;
    const newPurchase = adaptPurchasesSupplierSpecificFrontToBack(purchase);
    const response = await postPurchaseSupplierSpecific(selectedOrganization.id, newPurchase);

    if (!response || response.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    addPurchase(response.data);
  };

  return <FormSupplierSpecific handleSubmit={handleSubmit} error={error} />;
}

export default AddPurchaseSupplierSpeciffic;
