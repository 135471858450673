import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../../actions/notification';
import { parseDatapointToBackend } from '../../../../../../../adapters/datapoints';
import { FilterDatesContext } from '../../../../../../../context/filterDatesContext';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import useYearOptions from '../../../../../../../customHooks/useYearOptions';
import { createDatapointValue, updateDatapointValue } from '../../../../../../../services/api/esg';
import { EsgMetricsEnhanced } from '../../../../../../../types/entities/esgMetrics';
import {
  DataType,
  ModalType,
  modalMapping
} from '../../../../../../../types/entitiesEnums/datapoints';
import { convertStringToDate } from '../../../../../../../utils/convertDates';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementHalf from '../../../../../../ui/formComponents/formElementHalf/FormElementHalf';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { DateComponent } from './components/DateComponent';
import { TextAreaComponent } from './components/TextAreaComponent';
import { TextSelectComponent } from './components/TextSelectComponent';
import { UploadFileComponent } from './components/UploadFileComponent';
import { YearComponent } from './components/YearComponent';
import { YesNoComponent } from './components/YesNoComponent';

type Props = {
  datapoint: EsgMetricsEnhanced;
  edit?: boolean;
  closeModal: () => void;
  editElement: (newValue: EsgMetricsEnhanced, key?: keyof EsgMetricsEnhanced) => void;
};

interface FormData extends EsgMetricsEnhanced {
  year: SelectOptionFormat;
  errors: ErrorType[];
}

export type FormDataStateESG = {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
};

export const ModalDatapoint = ({ datapoint, edit, closeModal, editElement }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { t: tr } = useTranslation();
  const yearOptions = useYearOptions();
  const dispatch = useDispatch();

  const { startDate, endDate } = useContext(FilterDatesContext);

  const [loadingButton, setLoadingButton] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    ...datapoint,
    year: {
      id: '2024',
      name: '2024'
    },
    errors: []
  });

  useEffect(() => {
    const year = convertStringToDate(startDate).getFullYear();

    setFormData({
      ...datapoint,
      year: {
        id: year.toString(),
        name: year.toString()
      },
      errors: []
    });
  }, [startDate]);

  const formDataState: FormDataStateESG = {
    formData: formData,
    setFormData: setFormData
  };

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const renderFragmentAccordingToType = (type: DataType) => {
    const componentMap: { [key: string]: JSX.Element } = {
      [ModalType.YesNo]: <YesNoComponent formDataState={formDataState} />,
      [ModalType.Table]: <UploadFileComponent formDataState={formDataState} />,
      [ModalType.Narrative]: <TextAreaComponent formDataState={formDataState} />,
      [ModalType.Numeric]: <TextSelectComponent formDataState={formDataState} />,
      [ModalType.Date]: <DateComponent formDataState={formDataState} />,
      [ModalType.Year]: <YearComponent formDataState={formDataState} />
    };

    const modalType = modalMapping[type];

    return componentMap[modalType] || type;
  };

  const handleSubmit = async () => {
    setLoadingButton(true);
    let response = null;

    if (edit || datapoint.organization_data_point_id) {
      response = await updateDatapointValue(
        datapoint.organization_data_point_id,
        parseDatapointToBackend(formData),
        datapoint.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    } else {
      if (formData.type === ModalType.Table) {
        // Create datapoint first with no value to have the id
        response = await createDatapointValue(
          parseDatapointToBackend({ ...formData, value: '' }),
          datapoint.esg_category as
            | 'double_materiality'
            | 'environmental'
            | 'general_information'
            | 'social'
            | 'governance'
        );
      }
      response = await createDatapointValue(
        parseDatapointToBackend(formData),
        datapoint.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    }

    setLoadingButton(false);

    if (!response) {
      setFormData((prev) => ({
        ...prev,
        errors: [{ error: 'general', description: tr('error.somethingWentWrong') }]
      }));
      return;
    }

    editElement({
      ...formData,
      id: response.data_point_id,
      organization_data_point_id: response.organization_data_point_id ?? response.id,
      relevance: formData.relevance ?? response.relevance,
      status: formData.value ? 'COMPLETE' : 'INCOMPLETE'
    });
    dispatch(setNotification(tr('notification.updateDatapoint')));

    closeModal();
  };

  const foundGeneralError = formData.errors.find((error) => error.error === 'general');

  return (
    <>
      <FormHeader title={edit ? t('editModalTitle') : t('addResponseModalTitle')} />
      <p>
        {datapoint.short_name} - {tr(`esgMetrics.datapoints.${datapoint.id}`)}
      </p>
      <FormWrapper>
        <FormElementHalf>
          <FormSelect
            icon='/images/icons/calendar.svg'
            label={t('selectYear')}
            onChange={onChangeValue('year')}
            value={formData.year}
            options={yearOptions.years}
            error={formData.errors.find((error) => error.error === 'year')}
            sort={false}
            disabled
          />
        </FormElementHalf>
      </FormWrapper>
      {/* //depending on the incoming datapoint.type, render the second field that corresponds to it */}
      {renderFragmentAccordingToType(datapoint.data_type as DataType)}

      <FormButtonSection style={{ marginTop: '1em' }}>
        <Button lookAndFeel='secondary' text={t('cancel')} onClick={closeModal} size='medium' />
        <Button
          lookAndFeel='primary'
          text={t('save')}
          size='medium'
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {foundGeneralError && <ErrorText>{foundGeneralError.description}</ErrorText>}
    </>
  );
};
