import { useEffect, useState } from 'react';
import { getCustomEFGroup } from '../../../../../services/api/customEF';
import useAdapterCustomEFGroup from '../../../../../adapters/customEFGroup';

const useGetCustomEFGroup = (id: string) => {
  const [customEFGroup, setCustomEFGroup] = useState<ICustomEFGroupFront>();
  const [loading, setLoading] = useState(true);

  const { adaptCustomEFGroupBack2Front } = useAdapterCustomEFGroup();

  const fetchCustomEFGroup = async () => {
    // fetch data
    if (!id) return;
    setLoading(true);
    const response = await getCustomEFGroup({ id });
    setLoading(false);

    if (response?.response?.status >= 400) return;

    const responseAdapted = adaptCustomEFGroupBack2Front(response);
    setCustomEFGroup(responseAdapted);
  };

  useEffect(() => {
    fetchCustomEFGroup();
  }, [id]);

  return { customEFGroup, loading };
};

export default useGetCustomEFGroup;
