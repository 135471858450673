import { useMutation, useQueryClient } from '@tanstack/react-query';
import { patchLCA } from '../../../../../services/api/lca/lcaPortfolio';
import useSelectedOrganization from '../../../../../customHooks/useSelectedOrganization';
import { LCAItem } from '../../../types/LCAItem';

type LCAPortfolioItem = {
  id: string;
  description: string | null;
  name: string;
  organization_id: string;
};

type LCAPortfolioResponse = {
  pageParams: number[];
  pages: {
    page: number;
    size: number;
    total: number;
    items: LCAPortfolioItem[];
  }[];
};

export const usePatchLCA = () => {
  const queryClient = useQueryClient();
  const org = useSelectedOrganization();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: patchLCA,
    onSuccess: (response) => {
      queryClient.setQueryData(['lca', response.id], (oldData: LCAItem | undefined) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          ...response
        };
      });

      queryClient.setQueriesData(
        { queryKey: ['lcaPortfolio', org?.id] },
        (oldData: LCAPortfolioResponse | undefined) => {
          if (!oldData) {
            return oldData;
          }

          const updatedPages = oldData.pages.map((page) => {
            const updatedItems = page.items.map((item) => {
              if (item.id === response.id) {
                return {
                  ...item,
                  id: response.id,
                  description: response.description,
                  name: response.name,
                  organization_id: response.organization_id
                };
              }

              return item;
            });

            return {
              ...page,
              items: updatedItems
            };
          });

          return {
            ...oldData,
            pages: updatedPages
          };
        }
      );
    }
  });

  return { patchLCA: mutateAsync, isPending };
};
