import React from 'react';
import { useTranslation } from 'react-i18next';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import Button from '../../../ui/button/Button';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';

type Props = {
  handleClose: () => void;
};
const CSRDModal = ({ handleClose }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'csrdDirectiveModal' });
  const navigate = useNavigate();

  const handleOnClick = () => {
    handleClose();
    navigate(ROUTES.MEASURE_ESG_METRICS);
  };

  return (
    <div>
      <FormHeader title={t('title')} description={t('description')} />
      <div>
        <img
          src='/images/csrdDirective.gif'
          alt='CSRD Directive'
          style={{ marginBottom: '1rem', width: '100%' }}
        />
      </div>
      <FormButtonSection>
        <Button lookAndFeel='primary' onClick={handleOnClick} text={t('button')} size='small' />
      </FormButtonSection>
    </div>
  );
};

export default CSRDModal;
