import { convertDateToStringBackend } from '../../../../../utils/convertDates';
import ModelingNotAvailable from './components/ModelingNotAvailable';
import Objective from './components/objective/Objective';
import Progress from './components/progress/Progress';
import TableComponent from './components/tableComponent/TableComponent';
import useGetData from './hooks/useGetData';
import useShowModeling from './hooks/useShowModeling';
import './styles.scss';

export const Modeling = () => {
  const { showSbtiModeling, loading: loadingShowModeling } = useShowModeling();

  const {
    organizationGoal,
    organizationModeling,
    totalImpactByCategoryYearly,
    loading,
    handleOnChangeReductionPercentage,
    handleOnChangeReductionAction
  } = useGetData();

  if (!showSbtiModeling && !loadingShowModeling) return <ModelingNotAvailable />;

  return (
    <section className='modeling-sbti'>
      <div className='modeling-container'>
        <Objective loading={loading || loadingShowModeling} organizationGoal={organizationGoal} />
        <Progress
          loading={loading || loadingShowModeling}
          totalImpactByCategoryYearly={totalImpactByCategoryYearly}
          organizationGoal={organizationGoal}
          organizationModeling={organizationModeling}
        />
        <TableComponent
          loading={loading || loadingShowModeling}
          baseYear={String(organizationGoal?.base_year)}
          currentYear={convertDateToStringBackend(new Date())}
          goalYearScope1and2={String(organizationGoal?.scope_1_2_goal_year)}
          goalYearScope3={String(organizationGoal?.scope_3_goal_year)}
          totalImpactByCategoryYearly={totalImpactByCategoryYearly}
          organizationModeling={organizationModeling}
          handleOnChangeReductionPercentage={handleOnChangeReductionPercentage}
          handleOnChangeReductionAction={handleOnChangeReductionAction}
          organizationGoal={organizationGoal}
        />
      </div>
    </section>
  );
};
