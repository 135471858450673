import { Primitive } from '../types/primitive';
export const groupBy = (xs: any, key: string) => {
  return xs.reduce(function (rv: any, x: any) {
    if (!key || !x[key]) return rv;
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const isInNarrowedArray = <ArrayValues extends Primitive>(
  value: Primitive,
  array: Array<ArrayValues>
): value is ArrayValues => {
  return array.includes(value as ArrayValues);
};
