import { useTranslation } from 'react-i18next';
import TableComponent from '../../../../ui/table/Table';
import TotalLegend from '../../../../ui/totalLegend/TotalLegend';
import useColumns from '../../hooks/useColumns';
import { useGetCustomMetrics } from '../../hooks/useGetCustomMetrics';

export const CustomMetricsTable = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const { data, isLoading } = useGetCustomMetrics();

  return (
    <div className='infinite-scroll-container card-border-color main-bg-color solid-border table-container'>
      <div className='flex justify-end' style={{ marginBottom: '1.5rem' }}>
        <TotalLegend total={data?.length} loading={isLoading} i18key={'esgMetrics'} />
      </div>
      <div className='infinite-scroll-wrapper'>
        <TableComponent
          data={data || []}
          columns={columns}
          emptyText={t('table.empty')}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
