import './styles.scss';
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  description: string;
  children?: React.ReactNode;
}
const HelpCTA = ({ description, children, ...rest }: Props) => {
  return (
    <div className='help-cta' style={rest.style}>
      <div className='help-cta__wrapper'>
        <span className='body1-font'>{description}</span>
        {children ?? null}
      </div>
    </div>
  );
};

export default HelpCTA;
