import { useState } from 'react';
import { Card } from '../../../layout/Cards/Cards';
import Button from '../../../ui/button/Button';
import { CreateLCAModal } from './components/CreateLCAModal/CreateLCAModal';
import { useLCATranslation } from '../../../../customHooks/translations/useLCATranslation';

export const CreateLCA = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useLCATranslation({ keyPrefix: 'portfolio.createLCA' });

  const handleClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen && <CreateLCAModal isOpen={isOpen} setIsOpen={setIsOpen} />}
      <Card.Layout type='item' hoverEnabled={false}>
        <Card.Title text={t('title')} />
        <Card.Subtitle text={t('description')} />
        <Button lookAndFeel='primary' text={t('button')} size='small' onClick={handleClick} />
      </Card.Layout>
    </>
  );
};
