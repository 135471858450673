import { SimpleEmployee } from '../../../../../../types/entities/employee';
import Checkbox from '../../../../../ui/formComponents/checkbox/Checkbox';
import NameIcon from '../../../../../ui/nameIcon/NameIcon';

import './styles.scss';

type Props = {
  employee: SimpleEmployee;
  handleSelect: () => void;
  handleDelete: () => void;
};

const EmployeeObject = ({ employee, handleSelect, handleDelete }: Props) => {
  return (
    <div key={employee.email} className='employee-in-list'>
      <Checkbox text={''} onChange={handleSelect} selected={employee.selected} type={'square'} />
      <div className='employee-icon'>
        {employee.icon ? (
          <img className='employee-icon' src={employee.icon} alt='employee icon' />
        ) : (
          <NameIcon name={employee.email} lastName={''} />
        )}
      </div>
      <p className='body1-font'>{employee.email}</p>
      <img
        className='delete-icon'
        src='/images/icons/close.svg'
        alt='delete employee'
        onClick={handleDelete}
      />
    </div>
  );
};

export default EmployeeObject;
