import './styles.scss';

type Props = {
  name?: string;
  lastName?: string;
  onClick?: (...args: any[]) => any;
  size?: string;
  selected?: boolean;
};
function NameIcon({ name, lastName, onClick, size = 'small', selected = false }: Props) {
  const letter = name ? name[0].toUpperCase() : '';

  let fontSize = 'body1-font';

  if (size === 'big') {
    fontSize = 'name-icon-big-font';
  } else if (size === 'medium') {
    fontSize = 'name-icon-medium-font';
  }

  let bgColor = 'icon-bg-color';
  let fontColor = 'on-icon-text-color';
  if (selected) {
    bgColor = 'highlight-bg-color';
    fontColor = 'on-dark-text-color';
  }
  return (
    <div
      className={`name-icon--${size} round ${bgColor} ${fontSize} ${fontColor} `}
      title={`${name} ${lastName ?? ''}`}
      onClick={onClick}>
      {letter}
    </div>
  );
}

export default NameIcon;
