import { useEffect, useState } from 'react';
import useAdaptCriteria from '../../../../../../../adapters/sbtiCriteria';
import { getCriteria } from '../../../../../../../services/api/sbti';
import useCriteriaContext from '../../hooks/useCriteriaContext';

const useGetCriteria = () => {
  const { criteriaContext } = useCriteriaContext();
  const { adaptCriteriaBack2Front } = useAdaptCriteria();
  const optionalsFields = [
    'id',
    'category',
    'criteria',
    'status',
    'description',
    'submitYear',
    'baseYear',
    'goalYear',
    'year',
    'productionQuantity',
    'ambitionLevel',
    'method',
    'yesNo',
    'marketAndLocation',
    'categories',
    'co2e',
    'co2eBiogenic',
    'sector',
    'percentage',
    'categories',
    'errors'
  ];

  const [criteria, setCriteria] = useState<ISbtiCriteriaFront>();
  const [loading, setLoading] = useState<boolean>(false);

  const getCriteriaResponse = async () => {
    if (!criteriaContext.criteria) return;
    setLoading(true);
    const response = await getCriteria(criteriaContext.criteria.id);
    setLoading(false);

    if (!response) return;

    if (response?.response?.status >= 400) return;
    setCriteria(adaptCriteriaBack2Front(response));
  };

  useEffect(() => {
    getCriteriaResponse();
  }, [criteriaContext?.criteria?.id]);

  return { criteria, loading, optionalsFields };
};

export default useGetCriteria;
