import { TFunction } from 'i18next';
import Label from '../../../../../ui/label/Label';
import PendingLabel from '../../../../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../../../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';

export const renderFrameworks = (frameworks: string[], t: TFunction) => {
  const frameworksSliced = frameworks.length > 4 ? frameworks.slice(0, 3) : frameworks;
  return (
    <div className='flex gap-2'>
      {frameworksSliced.map((framework, index) => (
        <Label key={index} lookAndFeel='secondary' size='small' style={{ borderRadius: '4px' }}>
          {framework.replace('_', ' ')}
        </Label>
      ))}
      {frameworks.length > 4 && (
        <TooltipWrapper
          size='small'
          text={
            <div>
              <span>{t('frameworkTooltip')}</span>
              <ul style={{ margin: 0, paddingInlineStart: '1rem' }}>
                {frameworks.slice(3).map((framework, index) => (
                  <li key={index}>{framework.replace('_', ' ')}</li>
                ))}
              </ul>
            </div>
          }>
          <Label lookAndFeel='disabled' size='small' style={{ borderRadius: '4px' }}>
            {frameworks.length - 3}+
          </Label>
        </TooltipWrapper>
      )}
    </div>
  );
};

export const renderStatus = (status: string, t: TFunction) => {
  if (status === 'COMPLETE') {
    return <SuccessLabel icon='information'>{t('controlPanel.metrics.withData')}</SuccessLabel>;
  }
  return <PendingLabel>{t('general.pending')}</PendingLabel>;
};
