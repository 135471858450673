import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../../../../ui/formComponents2/checkbox/Checkbox';
import '../styles.scss';

type Props = {
  check: { isChecked: boolean; setIsChecked: (value: boolean) => void };
};
export const PrivacyCommunication = ({ check }: Props) => {
  const { t } = useTranslation();
  const { isChecked, setIsChecked } = check;
  const [renderCheck, setRenderCheck] = useState(isChecked);
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    setRenderCheck(isChecked);
  }, [isChecked]);

  return (
    <div className='privacity-communication font-10 flex items-baseline text-justify'>
      <Checkbox selected={renderCheck} type='square' onChange={setIsChecked} text=''></Checkbox>
      <p>
        {t('employees.privacyCommunicationAludec1')}
        {readMore ? (
          <span>
            {' '}
            {t('employees.privacyCommunicationAludec2')}{' '}
            <span
              className='highlight-text-color font-10 weight-600 pointer'
              onClick={() => setReadMore(!readMore)}>
              {t('general.readLess')}
            </span>
          </span>
        ) : (
          <span
            className='highlight-text-color font-10 weight-600 pointer'
            onClick={() => setReadMore(!readMore)}>
            {t('general.readMore')}
          </span>
        )}
      </p>
    </div>
  );
};
