import { LCAPatchNode, LCAPostNode } from '../components/lca/types/LCABlock';

export const adaptLCAFormToAPI = (form: ILCAFormType) => {
  return {
    name: form.name,
    start_date: form.startDate,
    end_date: form.endDate,
    value: form.value,
    unit_id: form.unit.id,
    impact_categories: form.impactCategories
  };
};

export const adaptLCAPostNodeToAPI = (node: LCAPostNode) => {
  return {
    acv_id: node.acv_id,
    type: node.type,
    target: node.target,
    coordinates_x: node.coordinate_x,
    coordinates_y: node.coordinate_y
  };
};

export const adaptLCAPatchNodeToAPI = (node: LCAPatchNode) => {
  const patchNode: {
    name?: string;
    coordinates_x?: number;
    coordinates_y?: number;
  } = {};

  if (node.name) {
    patchNode.name = node.name;
  }

  if (node.coordinate_x !== undefined) {
    patchNode.coordinates_x = node.coordinate_x;
  }

  if (node.coordinate_y !== undefined) {
    patchNode.coordinates_y = node.coordinate_y;
  }

  return patchNode;
};
