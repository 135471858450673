import { useContext, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import MilestoneCard from './components/MilestoneCard';
import Button from '../../../../../ui/button/Button';
import StepCount from '../stepCount/StepCount';
import { useDispatch } from 'react-redux';
import { updateOrganizationInfo } from '../../../../../../actions/auth';
import { UserContext } from '../../../../../../context/userContext';
import useMilestones, { MilestoneType } from '../../../../../../customHooks/useMilestones';
import useSelectedOrganization from '../../../../../../customHooks/useSelectedOrganization';

type Props = {
  handleNext?: () => void;
  handleSkip?: () => void;
};
const Milestones = ({ handleNext, handleSkip }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'milestones' });

  const user = useContext(UserContext);

  const selectedOrganization = useSelectedOrganization();

  const milestones = useMilestones();

  const dispatch = useDispatch();

  const [milestoneSelected, setMilestoneSelected] = useState<MilestoneType>();

  useEffect(() => {
    if (!selectedOrganization?.milestone) return;

    const foundMilestone = milestones.find(
      (milestone) => milestone.id === selectedOrganization?.milestone
    );

    if (!foundMilestone) return;

    setMilestoneSelected(foundMilestone);
  }, [selectedOrganization?.milestone]);
  const handleSelectMilestone = (milestone: MilestoneType) => {
    setMilestoneSelected(milestone);
  };

  const handleSubmit = () => {
    dispatch(
      updateOrganizationInfo(user?.selectedOrganization ?? '', {
        milestone: milestoneSelected?.id
      })
    );
    handleNext && handleNext();
  };

  return (
    <div className='milestones'>
      <h1 className='headline3-font text-align-center'>{t('title')}</h1>
      <h2 className='subtitle2-font text-align-center'>{t('subtitle')}</h2>
      <div className='milestones-wrapper'>
        {milestones.map((milestone) => (
          <MilestoneCard
            key={milestone.id}
            data={milestone}
            handleSelectMilestone={handleSelectMilestone}
            selected={milestoneSelected?.id === milestone.id}
          />
        ))}
      </div>
      <div className='buttons-wrapper'>
        <Button lookAndFeel='secondary' text={t('notClearYet')} onClick={handleNext} size='small' />
        <Button
          lookAndFeel='primary'
          text={t('continue')}
          onClick={handleSubmit}
          size='small'
          disabled={!milestoneSelected}
        />
      </div>
      <StepCount step={1} total={2} style={{ marginTop: '32px' }} />
    </div>
  );
};

export default Milestones;
