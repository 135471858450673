import { useTranslation } from 'react-i18next';

const useToggleOptions = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'esgMetrics.environmental'
  });
  const toggleOptions = [
    // qwerty for webinar
    {
      id: 'dataPoints',
      name: t('dataPoints')
    },
    {
      id: 'dataSources',
      name: t('dataSources')
    }
  ];
  return toggleOptions;
};

export default useToggleOptions;
