/* eslint-disable prettier/prettier */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';
import { patchAvoidedEmission } from '../../../../../services/api/avoidedEmissions';

import {
  AvoidedEmissionListElement,
  FormFacilityDataEdit
} from '../../../../../types/entities/avoidedEmission';

import checkFormErrors from '../../../../../utils/checkFormErrors';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../ui/formComponents/formWrapper/FormWrapper';
import InputNumber from '../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../ui/formComponents/inputText/InputText';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import useGetAvoidedEmission from './hooks/useGetAvoidedEmission';
import FormText from '../../../../ui/formComponents2/formInputs/formText/FormText';
import FormNumber from '../../../../ui/formComponents2/formInputs/formNumber/FormNumber';

type Props = {
  id: string;
  editAvoidedEmission: (id: string, avoidedEmission: AvoidedEmissionListElement) => void;
};

const AddAvoidedEmission = ({ id, editAvoidedEmission }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const { avoidedEmission: avoidedEmissionToEdit, loading } = useGetAvoidedEmission({ id });

  const [formData, setFormData] = useState<FormFacilityDataEdit>({
    // useState<FormFacilityData | FormYData | ...>();
    kind: 'facility',
    name: '',
    quantity: '',
    errors: []
  });

  const { onChangeValue } = useOnChangeValue({ setFormData });
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    setFormData({
      kind: 'facility',
      name: avoidedEmissionToEdit?.name ?? '',
      quantity: String(avoidedEmissionToEdit?.total_emissions ?? ''),
      errors: []
    });
  }, [avoidedEmissionToEdit]);

  const handleErrors = () => {
    const optionalFields = ['name'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData((prev: FormFacilityDataEdit) => ({
      ...prev,
      errors: newErrors
    }));

    return newErrors;
  };

  const handleEdit = async () => {
    const newErrors: ErrorType[] = handleErrors();

    if (newErrors.length > 0 || !user?.selectedOrganization || loadingButton) return;

    setLoadingButton(true);

    const response = await patchAvoidedEmission(id, formData, user.selectedOrganization);
    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    editAvoidedEmission(id, response);
    setLoadingButton(false);
  };

  return (
    <div className='new-responses'>
      <FormHeader
        title={t('avoidedEmissions.title')}
        description={t('avoidedEmissions.subtitle')}
      />
      {loading && <LoaderCard />}
      {!loading && (
        <FormWrapper>
          <FormText
            label={t('avoidedEmissions.name')}
            icon={'/images/icons/plus.svg'}
            placeholder={t('avoidedEmissions.enterName')}
            value={formData.name}
            onChange={onChangeValue('name')}
            error={formData.errors.find((error) => error.error === 'name')}
          />
          <FormNumber
            label={t('avoidedEmissions.quantityDescription')}
            icon={'/images/icons/catalog.svg'}
            placeholder={t('avoidedEmissions.enterQuantity')}
            value={formData.quantity}
            onChange={onChangeValue('quantity')}
            error={formData.errors.find((error) => error.error === 'quantity')}
          />
        </FormWrapper>
      )}

      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('avoidedEmissions.save')}
          onClick={handleEdit}
          size='small'
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default AddAvoidedEmission;
