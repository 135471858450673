import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Amplify, Auth } from 'aws-amplify';

import controller from '../environments/controller';
import { Session } from '../types/entities/user';

const environment = controller();

Amplify.configure(environment.amplify);

export function authLogin(provider = '') {
  if (provider === 'google') {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  } else {
    Auth.federatedSignIn();
  }
}

export async function authParseLoginCallback() {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    return {
      user_id: '',
      email: '',
      id: ''
    };
  }
  try {
    const sessionData = await Auth.currentAuthenticatedUser();
    return parseUserData(sessionData);
  } catch (err) {
    console.error('Error in auth parse login callback', err);
    throw err;
  }
}

export function authLogout(): void {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    // Clear localStorage values
    localStorage.setItem('x-user-id', '');
    localStorage.setItem('x-organization-id', '');
    localStorage.setItem('x-user-email', '');
    localStorage.setItem('user', '');
    // perform a f5
    window.location.reload();
  } else {
    // Sign out for non-local environments
    Auth.signOut();
  }
}

export function authGetAccessToken(): Promise<string> {
  return Auth.currentSession().then((auth) => {
    return auth.getIdToken().getJwtToken();
  });
}

export function authCheckSession(): Promise<boolean> {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    return Promise.resolve(true);
  }
  return Auth.currentSession().then((auth) => (auth ? true : false));
}

function parseUserData(sessionData: any): Session {
  /**
   * Here you receive the payload of the token
   * additional properties may be loaded in the future
   */
  return {
    user_id: sessionData.signInUserSession.idToken.payload.sub,
    email: sessionData.signInUserSession.idToken.payload.email,
    id: sessionData.signInUserSession.idToken.payload.id
  };
}
