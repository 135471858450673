import apiFetch from '../../../../utils/apiFetch';

// TODO: Make Cancel MITECO Assistance work
type CancelMITECOAssistanceParams = {
  id: string;
};
export const cancelMITECOAssistance = async ({ id }: CancelMITECOAssistanceParams) => {
  const res = await apiFetch('delete', `/organization_recommendations/${id}`);

  return res.data;
};
