import { useParams } from 'react-router-dom';
import { useMethodologies } from '../../hooks/useMethodologies';
import { LCAMethodologyCard } from '../LCAMethodologyCard/LCAMethodologyCard';
import LoaderTables from '../../../../../ui/loaders/loaderTables/LoaderTables';

type PageParams = {
  id: string;
};

export const LCAMethodologyTable = () => {
  const { id } = useParams<PageParams>();

  const { data, isLoading } = useMethodologies(id);

  if (isLoading) {
    return <LoaderTables />;
  }

  return (
    <>
      {data?.map((item) => (
        <LCAMethodologyCard data={item} key={item.id} />
      ))}
    </>
  );
};
