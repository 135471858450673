import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
type Props = {
  lang: string;
};
const LanguageItem = ({ lang }: Props) => {
  const { t, i18n } = useTranslation();
  const handleOnClick = () => {
    i18n.changeLanguage(lang);
  };

  const activeClassName = i18n.resolvedLanguage === lang ? 'body1-bold-font' : 'body1-font';
  return (
    <div className='language-item' onClick={handleOnClick}>
      <span className={activeClassName}>{t(`languages.${lang}`)}</span>
    </div>
  );
};

export default LanguageItem;
