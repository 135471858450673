import React, { useState } from 'react';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../ui/button/Button';
import { deleteTask } from '../../../../services/api/tasks';
type Props = {
  handleDeleteTask: (id: string) => void;
  id: string;
};
const DeleteTask = ({ id, handleDeleteTask }: Props) => {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);
  const handleSubmit = async () => {
    setLoadingButton(true);
    await deleteTask(id);
    handleDeleteTask(id);
    setLoadingButton(false);
  };

  return (
    <div className='delete-task'>
      <FormHeader title={t('tasks.deleteTitle')} description={t('tasks.deleteDescription')} />
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleSubmit}
          text={t('tasks.delete')}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteTask;
