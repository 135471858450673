import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../context/userContext';
import { createTask, uploadAttachmentsToTask } from '../../../../services/api/tasks';
import { IFrontendFormTask, IGetBackendTask } from '../../../../types/entities/task';

import { useQueryClient } from '@tanstack/react-query';
import checkFormErrors from '../../../../utils/checkFormErrors';
import { convertDateToString } from '../../../../utils/convertDates';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import Form from '../form/Form';
import './styles.scss';
import { EsgMetricsEnhanced } from '../../../../types/entities/esgMetrics';

type Props = {
  handleAddTask: (task: IGetBackendTask) => void;
  users: SelectOptionFormat[];
  tags: SelectOptionFormat[];
  preSetOrganization: SelectOptionFormat | null;
  selectedMetric?: EsgMetricsEnhanced;
};
const CreateTask = ({
  handleAddTask,
  users,
  tags,
  preSetOrganization = null,
  selectedMetric
}: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const dateInAWeek = new Date();
  dateInAWeek.setDate(dateInAWeek.getDate() + 7);
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState<IFrontendFormTask>({
    userSelected: {
      id: '',
      name: ''
    },
    category: {
      id: '',
      name: ''
    },
    dueDate: convertDateToString(dateInAWeek),
    description: '',
    tag: '',
    tagsSelected: [],
    createdBy: user?.id ?? '',
    organizationId:
      preSetOrganization !== null ? preSetOrganization.id : user?.selectedOrganization ?? '',
    files: [],
    errors: []
  });

  const handleSubmit = async () => {
    const optionalFields = ['description', 'tag', 'files', 'metric'];

    const newErrors = checkFormErrors(formData, formData.errors, optionalFields);

    if (newErrors.length > 0) {
      setFormData({ ...formData, errors: newErrors });
      return;
    }

    const data = await createTask(formData);
    queryClient.invalidateQueries({ queryKey: ['metrics'] });
    // Upload attachments if there are present
    if (formData.files.length > 0) {
      formData.files.forEach(async (file) => {
        if (file.file) {
          await uploadAttachmentsToTask(data.id, file.file);
        }
      });
    }
    handleAddTask(data);
  };

  useEffect(() => {
    if (selectedMetric) {
      setFormData({
        ...formData,
        category: { id: selectedMetric.category },
        metric: selectedMetric.id
      });
    }
  }, []);

  return (
    <div className='create-task'>
      <FormHeader title={t('tasks.createTitle')} description={t('tasks.createDescription')} />
      <Form
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        users={users}
        tags={tags}
      />
    </div>
  );
};

export default CreateTask;
