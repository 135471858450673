import { useTranslation } from 'react-i18next';
// import { adaptOrganizationModelingBackToFront } from '../../../adapters/organizationModeling';
import { convertDateToStringBackend } from '../../../utils/convertDates';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import Objective from './components/objective/Objective';
import Progress from './components/progress/Progress';
import TableComponent from './components/tableComponent/TableComponent';
import useGetData from './hooks/useGetData';
import './styles.scss';

const Modeling = () => {
  const { t } = useTranslation();

  const {
    organizationGoal,
    organizationModeling,
    totalImpactByCategoryYearly,
    loading,
    handleOnChangeReductionPercentage,
    handleOnChangeReductionAction,
    handleAddOrganizationGoal
  } = useGetData();

  return (
    <section className='modeling'>
      <SectionHeader title={t('modeling.title')} subtitle={t('modeling.subtitle')} />
      <hr className='split-line' />
      <div className='modeling-container'>
        <Objective
          loading={loading}
          organizationGoal={organizationGoal}
          handleAddOrganizationGoal={handleAddOrganizationGoal}
        />
        <Progress
          loading={loading}
          totalImpactByCategoryYearly={totalImpactByCategoryYearly}
          organizationGoal={organizationGoal}
          organizationModeling={organizationModeling}
        />
        <TableComponent
          loading={loading}
          baseYear={organizationGoal?.base_year_date}
          currentYear={convertDateToStringBackend(new Date())}
          goalYear={organizationGoal?.goal_year_date}
          totalImpactByCategoryYearly={totalImpactByCategoryYearly}
          organizationModeling={organizationModeling}
          handleOnChangeReductionPercentage={handleOnChangeReductionPercentage}
          handleOnChangeReductionAction={handleOnChangeReductionAction}
        />
      </div>
    </section>
  );
};

export default Modeling;
