import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';
import { DATA_QUALITY_THRESHOLD } from '../../../../constants';
import { gray_color, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import { Organization } from '../../../../types/entities/organization';
import calculateSectorEquivalence from '../../../../utils/calculateSectorEquivalence';
import { formatRoundNumber } from '../../../../utils/formatNumber';
import numberToDecimal from '../../../../utils/numberToDecimal';
import Icon from '../../../ui/icon/Icon';
import Label from '../../../ui/label/Label';
import ScopeTag from '../../../ui/scopeTag/ScopeTag';
import StackedBar from '../bar/StackedBar';
import CardHeader from '../cardHeader/CardHeader';
import './styles.scss';
type Props = {
  dataScopes: ScopeData[];
  dataCategories: CategoryData[];
  organization: Organization;
  endDate: Date;
  dataQuality: number;
  intensityMetric: IntensityMetricReport;
  categoriesToShow: CategoryWithScope[];
};

type Bar = {
  value: number;
  color: string;
};

function Impact({
  dataScopes,
  dataCategories,
  organization,
  endDate,
  dataQuality,
  intensityMetric,
  categoriesToShow
}: Props) {
  const { t } = useTranslation();

  const foundScope1 = categoriesToShow.find((category) => category.scope === 1);
  const foundScope2 = categoriesToShow.find((category) => category.scope === 2);
  const foundScope3 = categoriesToShow.find((category) => category.scope === 3);

  let pieChartData = [{ title: '', value: 100, color: gray_color }];
  let totalCo2 = 0;
  let scope1 = 0;
  let scope2 = 0;
  let scope3 = 0;

  if (dataScopes && dataScopes.length > 0) {
    dataScopes.forEach((value) => {
      if (value.scope === 1 && foundScope1) {
        scope1 += value.total_emissions;
        totalCo2 += value.total_emissions;
      }
      if (value.scope === 2 && foundScope2) {
        scope2 = value.total_emissions;
        totalCo2 += value.total_emissions;
      }
      if (value.scope === 3 && foundScope3) {
        scope3 = value.total_emissions;
        totalCo2 += value.total_emissions;
      }
    });

    pieChartData = [
      { title: '', value: (scope1 * 100) / totalCo2, color: scope1_color },
      { title: '', value: (scope2 * 100) / totalCo2, color: scope2_color },
      { title: '', value: (scope3 * 100) / totalCo2, color: scope3_color }
    ];

    pieChartData.sort((a, b) => {
      return b.value - a.value;
    });
  }

  const totalCo2PieChart = totalCo2 / 1000;
  const unit = 't CO₂ eq.';

  const renderColour = (scope: number) => {
    let color = scope1_color;

    if (scope === 2) {
      color = scope2_color;
    }
    if (scope === 3) {
      color = scope3_color;
    }
    return color;
  };

  const renderCo2Information = () => {
    const value = totalCo2PieChart;
    let fontSize = 20;
    const maxDigits = 5;
    if (formatRoundNumber(totalCo2).toString().length > maxDigits) {
      fontSize = 20 - (formatRoundNumber(totalCo2).toString().length - maxDigits) - 2;
    }
    return (
      <div className='co2-information'>
        <div className='co2-value co2-dashboard-font-small' style={{ fontSize: `${fontSize}px` }}>
          {formatRoundNumber(value)}
        </div>
        <span className='co2-units on-light-text-color body1-font'>{unit}</span>
      </div>
    );
  };

  const renderScopesInfo = () => {
    return (
      <div className='scopes-wrapper'>
        {foundScope1 && <ScopeTag scope={1} value={scope1 / 1000} textLookAndFeel='secondary' />}
        {foundScope2 && <ScopeTag scope={2} value={scope2 / 1000} textLookAndFeel='secondary' />}
        {foundScope3 && <ScopeTag scope={3} value={scope3 / 1000} textLookAndFeel='secondary' />}
      </div>
    );
  };

  const renderIcon = (type: string) => {
    let src = '/images/icons/building.svg';
    if (type === 'transport') {
      src = '/images/icons/car.svg';
    } else if (type === 'employees') {
      src = '/images/icons/people.svg';
    } else if (type === 'travels') {
      src = '/images/icons/plane.svg';
    } else if (type === 'shipments') {
      src = '/images/icons/cardBox.svg';
    } else if (type === 'deliveries') {
      src = '/images/icons/cardBox.svg';
    } else if (type === 'purchases') {
      src = '/images/icons/shoppingBagGradient.svg';
    } else if (type === 'investments') {
      src = '/images/icons/organization.svg';
    } else if (type === 'soldProductsIntermediateProcessing') {
      src = '/images/icons/transformSoldProduct.svg';
    } else if (type === 'soldProductsUsage') {
      src = '/images/icons/tshirtGradient.svg';
    } else if (type === 'soldProductsWaste') {
      src = '/images/icons/wasteSoldProduct.svg';
    }

    return src;
  };

  const renderBarSection = (total: number, values: Bar[], type: string, totalByType: number) => {
    let typeName = type;
    if (type === 'shipments' || type === 'deliveries') typeName = type.concat('Short');

    if (values.length === 0) {
      return null;
    }

    return (
      <div className='card-info-rows'>
        <span className='name-logo body1-bold-font'>
          <img src={renderIcon(type)} alt='type' />

          {t(`report.${typeName}`)}
        </span>
        <StackedBar total={total} values={values} remaining={total - totalByType} />
        <span className='body1-font'>{formatRoundNumber(totalByType)}</span>
      </div>
    );
  };

  const parseEntityByCategory = (categories: string[]) => {
    let totalEntityCo2 = 0;

    // Filter categories by the ones that are in the categoriesToShow
    const categoriesFiltered = categories.filter((categoryName) =>
      categoriesToShow.find((category) => categoryName === category.category)
    );
    const dataCategoriesFiltered = dataCategories.filter((elem) =>
      categoriesFiltered.find((category) => category === elem.category)
    );

    const parsedList: { color: string; value: number }[] = [];
    dataCategoriesFiltered.forEach((elem) => {
      totalEntityCo2 += elem.total_emissions;

      const foundColorIndex = parsedList.findIndex(
        (item) => item.color === renderColour(elem.scope)
      );
      if (foundColorIndex !== -1) {
        parsedList[foundColorIndex].value += elem.total_emissions;
      } else {
        parsedList.push({
          color: renderColour(elem.scope),
          value: elem.total_emissions
        });
      }
    });

    return { parsedList, totalEntityCo2 };
  };

  const { parsedList: facilitiesParsed, totalEntityCo2: totalFacilityCo2 } = parseEntityByCategory([
    'stationary',
    'water',
    'electricity',
    'recharge',
    'wastes',
    'waste_water_treatments'
  ]);

  const { parsedList: vehiclesParsed, totalEntityCo2: totalVehicleCo2 } = parseEntityByCategory([
    'transport'
  ]);

  const { parsedList: employeesParsed, totalEntityCo2: totalEmployeeCo2 } = parseEntityByCategory([
    'employees'
  ]);

  const { parsedList: tripsParsed, totalEntityCo2: totalTripsCo2 } = parseEntityByCategory([
    'travels'
  ]);

  const { parsedList: shipmentsParsed, totalEntityCo2: totalShipmentsCo2 } = parseEntityByCategory([
    'transport_distribution_downstream'
  ]);

  const { parsedList: deliveriesParsed, totalEntityCo2: totalDeliveriesCo2 } =
    parseEntityByCategory(['transport_distribution_upstream']);

  const { parsedList: purchasesParsed, totalEntityCo2: totalPurchasesCo2 } = parseEntityByCategory([
    'purchases'
  ]);

  const { parsedList: investmentsParsed, totalEntityCo2: totalInvestmentsCo2 } =
    parseEntityByCategory(['investments']);

  const { parsedList: transformParsed, totalEntityCo2: totalTransformCo2 } = parseEntityByCategory([
    'sold_products_intermediate_processing'
  ]);
  const { parsedList: usageParsed, totalEntityCo2: totalUsageCo2 } = parseEntityByCategory([
    'sold_products_usage'
  ]);
  const { parsedList: wasteParsed, totalEntityCo2: totalWasteCo2 } = parseEntityByCategory([
    'sold_products_waste'
  ]);

  if (totalCo2PieChart === 0) {
    pieChartData = [{ title: '', value: 100, color: gray_color }];
  }

  return (
    <div className='impact-report'>
      <div className='card main-bg-color'>
        <CardHeader title={t('report.impact')} description={t('report.impactDescription')} />
        <h2 className='body2-font'>{t('report.total')}</h2>
        <div className='card-info-co2 on-card-gray-bg-color'>
          <div className='co2-progress-bar'>
            <PieChart // your data
              data={pieChartData}
              // width and height of the view box
              // viewBoxSize={[100, 100]}
              lineWidth={24}
              totalValue={100}
              rounded
              animate></PieChart>
            {renderCo2Information()}
          </div>
          {renderScopesInfo()}
        </div>
        <div className='comparative-wrapper'>
          {intensityMetric.id && (
            <div className='comparative-column'>
              <h2 className='body2-font'>{t('report.intensityMetrics')}</h2>
              <div className='comparative on-card-gray-bg-color'>
                <div className='icon-text-wrapper'>
                  <Icon icon='growth' color='gradient' />
                  <span className='body1-bold-font'>{intensityMetric.name}</span>
                </div>
                <div className='co2-info-wrapper'>
                  <span className='small-card-font'>{intensityMetric.value}</span>
                  <p className='body3-font'>{intensityMetric.unit}</p>
                </div>
              </div>
            </div>
          )}
          {dataQuality > DATA_QUALITY_THRESHOLD && (
            <div className='comparative-column'>
              <h2 className='body2-font'>{t('report.comparative')}</h2>
              <div className='comparative on-card-gray-bg-color'>
                <div className='icon-text-wrapper'>
                  <Icon icon='sector' color='gradient' />
                  <span className='body1-bold-font'>{t('report.sectorComparative')}</span>
                </div>
                <div className='co2-info-wrapper'>
                  {dataQuality > DATA_QUALITY_THRESHOLD ? (
                    <>
                      <span className='small-card-font'>
                        {calculateSectorEquivalence(organization, endDate, totalCo2)}
                      </span>
                      <p className='body3-font'>{t('report.comparedWithXCompanies')}</p>
                    </>
                  ) : (
                    <Label lookAndFeel='secondary'>
                      <div className='icon-text-wrapper'>
                        <img src='/images/icons/diamondOnTag.svg' alt='diamond' />
                        <span>{t('error.lowDataQuality')}</span>
                      </div>
                    </Label>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='card main-bg-color '>
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.distribution')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          <div className='bar-wrapper'>
            {renderBarSection(
              totalCo2,
              facilitiesParsed,
              'facilities',
              numberToDecimal(totalFacilityCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              vehiclesParsed,
              'transport',
              numberToDecimal(totalVehicleCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              employeesParsed,
              'employees',
              numberToDecimal(totalEmployeeCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              tripsParsed,
              'travels',
              numberToDecimal(totalTripsCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              shipmentsParsed,
              'shipments',
              numberToDecimal(totalShipmentsCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              deliveriesParsed,
              'deliveries',
              numberToDecimal(totalDeliveriesCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              purchasesParsed,
              'purchases',
              numberToDecimal(totalPurchasesCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              investmentsParsed,
              'investments',
              numberToDecimal(totalInvestmentsCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              transformParsed,
              'soldProductsIntermediateProcessing',
              numberToDecimal(totalTransformCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              usageParsed,
              'soldProductsUsage',
              numberToDecimal(totalUsageCo2 / 1000)
            )}
            {renderBarSection(
              totalCo2,
              wasteParsed,
              'soldProductsWaste',
              numberToDecimal(totalWasteCo2 / 1000)
            )}
          </div>
        </div>
        <div className='card-info-columns card-footer'>
          <div className='body2-font'>{t('report.legend')}</div>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color legends'>
          <div className='card-info-rows '>
            {categoriesToShow.find((category) => category.scope === 1) && (
              <div className='legend body1-font'>
                <div className='dot round' style={{ backgroundColor: scope1_color }} />
                {t('report.scope1')}
              </div>
            )}
            {categoriesToShow.find((category) => category.scope === 2) && (
              <div className='legend body1-font'>
                <div className='dot round' style={{ backgroundColor: scope2_color }} />
                {t('report.scope2')}
              </div>
            )}
            {categoriesToShow.find((category) => category.scope === 3) && (
              <div className='legend body1-font'>
                <div className='dot round' style={{ backgroundColor: scope3_color }} />
                {t('report.scope3')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Impact;
