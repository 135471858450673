import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gray_color, scope1_color, scope2_color, scope3_color } from '../../../../styles/colors';
import {
  TransportDistributionReport,
  TransportDistributionReportElement
} from '../../../../types/entities/shipment';
import formatNumber, { formatRoundNumber } from '../../../../utils/formatNumber';
import numberToDecimal, { formatNumberToDecimalNonZero } from '../../../../utils/numberToDecimal';
import Icon from '../../../ui/icon/Icon';
import StackedBar from '../bar/StackedBar';
import CardHeader from '../cardHeader/CardHeader';
import CardTotalCo2 from '../cardTotalCo2/CardTotalCo2';

type Bar = {
  value: number;
  color: string;
};
type Props = {
  shipments: TransportDistributionReport;
};

function Shipments({ shipments }: Props) {
  const { t } = useTranslation();

  const [totalCo2, setTotalCo2] = useState(0);
  const [totalKg, setTotalKg] = useState(0);

  useEffect(() => {
    let totalCo2Sum = 0;
    let totalKgSum = 0;
    shipments?.monthly.forEach((shipment) => {
      totalCo2Sum += shipment.co2e;
      totalKgSum += shipment.quantity_transported;
    });
    setTotalCo2(totalCo2Sum);
    setTotalKg(totalKgSum);
  }, []);

  const renderBarSection = (total: number, values: Bar[], totalByElement: number) => {
    return (
      <div className='card-info-rows '>
        <span className='name-logo body1-bold-font'>
          <Icon icon='box' color='gradient' />
          {t(`report.shipmentsShort`)}
        </span>
        <StackedBar total={total} values={values} remaining={total - totalByElement} />
        <span className='font-10'>{formatRoundNumber(totalByElement / 1000)}</span>
      </div>
    );
  };

  const calculateRemaining = () => {
    let top3Co2 = 0;
    if ((shipments?.top3?.length || 0) < 3) {
      return 0;
    }
    shipments?.top3?.forEach((elem, index) => {
      if (index < 3) {
        top3Co2 += elem.co2e;
      }
    });
    return totalCo2 - top3Co2;
  };

  const renderDefaultName = (elem: TransportDistributionReportElement) => {
    if (elem.name) return elem.name;
    return `${elem.quantity_transported} ${t(`units_short.kilogram_(kg)`)} ${t(
      `shipments.${elem.transport_type}`
    )}`;
  };

  const colors = [scope2_color, scope3_color, scope1_color, gray_color];

  const shipmentColors =
    shipments?.top3?.map((shipment, index) => {
      return {
        id: shipment.name || renderDefaultName(shipment),
        name: shipment.name || renderDefaultName(shipment),
        co2e: shipment.co2e,
        color: colors[index]
      };
    }) || [];

  shipmentColors.push({
    id: 'remaining',
    name: t('report.remaining'),
    color: gray_color,
    co2e: calculateRemaining()
  });

  const totalEmissions = calculateRemaining() / 1000;
  return (
    <div className='measure-detail-report'>
      <div className='card main-bg-color'>
        <CardHeader title={t('report.shipments')} description={t('report.shipmentsDescription')} />

        <CardTotalCo2 totalCo2={totalCo2} />
        <div className='card-info-columns'>
          <span className='font-10 weight-600'>{t('report.top3Shipments')}</span>
          <span className='font-10 weight-600'>t CO₂ eq.</span>
          <span className='font-10 weight-600'>% Total</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {shipments?.top3?.map((shipment) => (
            <div className='card-info-rows' key={shipment.name || renderDefaultName(shipment)}>
              <span className='name-logo body1-bold-font'>
                <Icon icon='box' color='gradient' />
                {shipment.name || renderDefaultName(shipment)}
              </span>
              <span className='font-10'>{formatNumberToDecimalNonZero(shipment.co2e / 1000)}</span>
              <span className='percentage body1-font tag-bg-text-color'>{`${
                totalCo2 ? formatRoundNumber((shipment.co2e * 100) / totalCo2) : 0
              } %`}</span>
            </div>
          ))}
          <div className='card-info-rows '>
            <span className='name-logo body1-bold-font'>
              <Icon icon='box' color='gradient' />
              {t('report.remaining')}
            </span>
            <span className='body1-font'>
              {totalEmissions > 0
                ? formatNumberToDecimalNonZero(totalEmissions)
                : formatNumber(totalEmissions)}
            </span>
            <span className='percentage body1-font tag-bg-text-color'>{`${
              totalCo2 ? formatRoundNumber((calculateRemaining() * 100) / totalCo2) : 0
            } %`}</span>
          </div>
        </div>
      </div>
      <div className='card main-bg-color '>
        <div className='scope-tag'>
          <div className='text-tag font-10 tag-bg-text-color tag'>{`${t(
            'measureMain.scope'
          )} 3`}</div>
        </div>
        <div className='card-info-columns'>
          <span className='body2-font'>{t('report.distribution')}</span>
          <span className='body2-font'>t CO₂ eq.</span>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color'>
          {renderBarSection(
            totalCo2,
            shipmentColors.map((elem) => {
              return {
                color: elem.color,
                value: elem.co2e
              };
            }),
            numberToDecimal(totalCo2)
          )}
        </div>
        <div className='card-info-columns card-footer'>
          <div className='body2-font'>{t('report.legend')}</div>
        </div>
        <div className='rows-wrapper on-card-gray-bg-color legends'>
          <div className='card-info-rows'>
            {shipmentColors?.map((shipment) => (
              <div className='legend body1-font' key={shipment.id}>
                <div className='dot round' style={{ backgroundColor: shipment.color }} />
                {shipment.name}
              </div>
            ))}
          </div>
        </div>
        <div className='card-footer'>
          <span className='body2-font'>{t('report.total')}</span>
        </div>
        <div className='total-info-cards'>
          <div className='total-info on-card-gray-bg-color'>
            <span className='body2-font'>{t('report.totalShipments')}</span>
            <span className='highlight-report-text-color body1-bold-font'>{shipments.total}</span>
          </div>
          <div className='total-info on-card-gray-bg-color'>
            <span className='body2-font'>kg CO₂ eq./kg</span>
            <span className='highlight-report-text-color body1-bold-font'>
              {totalCo2 && totalKg ? formatRoundNumber(totalCo2 / totalKg) : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shipments;
