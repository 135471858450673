import { useTranslation } from 'react-i18next';
import { TransportOptions } from '../../../../../types/entitiesEnums/employeePeriod';

const useFilterOptions = () => {
  const { t } = useTranslation();
  const blackListFilterOptions = [
    TransportOptions.AIRCRAFT,
    TransportOptions.CAR_LARGE,
    TransportOptions.CAR_MEDIUM,
    TransportOptions.CAR_SMALL
  ];
  // generate transport options from TransportOptions enum
  const transportOptions = Object.values(TransportOptions)
    .filter((value) => !blackListFilterOptions.includes(value))
    .map((transportOption) => ({
      id: transportOption,
      name: t(`employees.${transportOption}`)
    }));
  const statusOptions = [
    { id: 'inactive', name: t(`employees.inactive`) },
    { id: 'active', name: t(`employees.active`) },
    { id: 'terminated', name: t(`employees.terminated`) }
  ];

  const pendingResponse = [{ id: 'loading', name: t(`employees.pending`) }];

  const responseWay = [
    { id: 'form', name: t(`employees.responseWayForm`) },
    { id: 'qr', name: t(`employees.responseWayQr`) },
    { id: 'manual', name: t(`employees.manual`) }
  ];

  return { transportOptions, pendingResponse, statusOptions, responseWay };
};

export default useFilterOptions;
