import { useQuery } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getLCABlocks } from '../../../../services/api/lca/lcaScenarios';
import { adaptLCABlockBackToFront } from '../../adapters/adaptLCABlockBackToFront';

type Params = {
  acv_id: string;
};
export const useLCABlocks = ({ acv_id }: Params) => {
  const org = useSelectedOrganization();

  const { data, isLoading } = useQuery({
    queryKey: ['lcaBlocks', org?.id, acv_id],
    queryFn: () => getLCABlocks({ acv_id }),
    select: (data) => data.map((block) => adaptLCABlockBackToFront(block))
  });

  return { lcaBlocks: data, isLoading };
};
