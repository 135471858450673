import { useTranslation } from 'react-i18next';
import SectionHeader from '../../layout/sectionHeader/SectionHeader';
import StepCarouselList from '../../ui/stepCarouselList/StepCarouselList';

import './styles.scss';
import useSteps from './hook/useSteps';
import { useSbtiId } from './hook/useSbtiId';
import LoaderTables from '../../ui/loaders/loaderTables/LoaderTables';

export const Sbti = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti' });

  const { steps, handleSelect, Component } = useSteps();
  const { loading } = useSbtiId();

  if (loading) return <LoaderTables />;

  return (
    <section className='sbti'>
      <SectionHeader title={t('title')} />
      <div className='sbti__steps-logo'>
        <StepCarouselList steps={steps} handleSelect={handleSelect} />
        <img className='sbti_logo' src='/images/sbti_logo.webp' />
      </div>
      <Component />
    </section>
  );
};
