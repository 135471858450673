import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import NameIcon from '../../../ui/nameIcon/NameIcon';
import { UserContext } from '../../../../context/userContext';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { TutorialContext } from '../../interactiveTutorial/mainTutorial/InteractiveTutorial';
// import useGetData from './hooks/useGetData';
import useSections from './hooks/useSections';
// import DataProgress from './components/dataProgress/DataProgress';
import DataQuality from './components/dataQuality/DataQuality';
import LoaderStandard from 'components/ui/loaders/loaderStandard/LoaderStandard';
import useGetData from './hooks/useGetData';

type Props = {
  handleOnClickProfile: () => void;
  userRef: any;
};

function UserModal({ handleOnClickProfile, userRef }: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const selectedOrganization = useSelectedOrganization();

  const role = selectedOrganization?.role;

  const tutorial = useContext(TutorialContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { dataQualityPercentage, sectorComparison, loading } = useGetData();

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        userRef.current &&
        !userRef.current.contains(event.target) &&
        !tutorial?.active
      ) {
        handleOnClickProfile();
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [wrapperRef.current, tutorial?.active]);

  const sections = useSections({ handleOnClickProfile });

  if (!user) return null;

  const { first_name, last_name, profile_img_url } = user;

  return (
    <div className='user-modal modal-box-shadow main-bg-color solid-border' ref={wrapperRef}>
      <div className='user-modal__user-info'>
        {profile_img_url ? (
          <img src={user.profile_img_url} className='round profile-img' alt='profile' />
        ) : (
          <NameIcon name={first_name} lastName={last_name} size='medium' />
        )}
        <div className='user-description'>
          <h1 className='small-card-font'>{`${first_name} ${last_name}`}</h1>
          <p className='body3-font on-light-text-color2'>{selectedOrganization?.company_name}</p>
          {role && <p className='body3-font on-light-text-color2'>{t(`header.${role}`)}</p>}
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2rem'
          }}>
          <LoaderStandard />
        </div>
      ) : (
        <DataQuality
          dataQualityPercentage={dataQualityPercentage}
          sectorComparison={sectorComparison}
        />
      )}
      {/* <DataProgress /> */}
      <ul>
        {sections.map((section) => (
          <>
            {!section.hide && (
              <>
                <li className='body1-bold-font' onClick={section.onClick} key={section.text}>
                  <div className='icon-text-wrapper'>
                    {section.icon && <img src={section.icon} alt='person' />}
                    <span className={!section.icon ? 'only-text' : ''}>{section.text}</span>
                  </div>
                  <img
                    className={`chevron ${section.conditionSubSections ? 'rotate45' : ''}`}
                    src='/images/icons/chevron.svg'
                    alt='chevron'
                  />
                </li>
                {section.conditionSubSections && (
                  <div className='subsections'>
                    {section.subSections.map((subsection) => (
                      <span className='body1-font' onClick={subsection.onClick} key={subsection.id}>
                        {subsection.name}
                      </span>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        ))}
      </ul>
    </div>
  );
}

export default UserModal;
