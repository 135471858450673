import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'socialAndGovernance.table' });
  return [
    {
      title: t('year'),
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: t('globalCompact'),
      dataIndex: 'globalCompact',
      key: 'globalCompact'
    },
    {
      title: t('infringement'),
      dataIndex: 'infringement',
      key: 'infringement'
    },
    {
      title: t('guns'),
      dataIndex: 'guns',
      key: 'guns'
    },
    {
      title: t('totalRevenue'),
      dataIndex: 'totalRevenue',
      key: 'totalRevenue'
    },
    {
      title: t('menWomenRevenue'),
      dataIndex: 'menWomenRevenue',
      key: 'menWomenRevenue'
    },
    {
      title: t('boardDirectors'),
      dataIndex: 'boardDirectors',
      key: 'boardDirectors'
    },
    {
      title: t('biodiversity'),
      dataIndex: 'biodiversity',
      key: 'biodiversity'
    },
    {
      title: t('waste'),
      dataIndex: 'waste',
      key: 'waste'
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
};

export default useColumns;
