import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { questions } from '../../../../../constants/employeeCommutingQuestions';
import useOnChangeValue from '../../../../../customHooks/useOnChangeValue';

import { IFrontendFormBusinessTravel } from '../../../../../types/entities/businessTravels';
import { CarSize, TransportOptions } from '../../../../../types/entitiesEnums/employeePeriod';

const emptyFormData: IFrontendFormBusinessTravel = {
  transport: { id: '', name: '' },
  size: '',
  fuelType: { id: '', name: '' },
  name: '',
  travelNumber: '1',
  origin: '',
  destination: '',
  startDate: '',
  endDate: '',
  travelType: '',
  totalKm: '',
  errors: []
};

type Props = {
  formDataFilled?: IFrontendFormBusinessTravel;
};

const useFormData = ({ formDataFilled }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IFrontendFormBusinessTravel>(
    formDataFilled || emptyFormData
  );

  const [originDestinationDisabled, setOriginDestinationDisabled] = useState(
    Boolean(formDataFilled?.totalKm)
  );
  const [totalKmDisabled, setTotalKmDisabled] = useState(
    Boolean(formDataFilled?.origin) && Boolean(formDataFilled?.destination)
  );

  const [fuelTypeOptions, setFuelTypeOptions] = useState<SelectOptionFormat[]>([]);

  const { onChangeValue, handleDateError } = useOnChangeValue({ setFormData });

  useEffect(() => {
    const foundQuestion = questions.children.find(
      (question: { answer: string }) => question.answer === formData.transport.id
    );

    const fuels = foundQuestion?.children?.map((fuel: { answer: any }) => ({
      id: fuel.answer,
      name: t(`employees.${fuel.answer}`)
    }));
    setFuelTypeOptions(fuels || []);
  }, [formData.transport.id, formData.size]);

  const onChangeTransport = (value: SelectOptionFormat) => {
    let size = CarSize.NONE;
    if (value.id === TransportOptions.CAR_SMALL) size = CarSize.SMALL;
    if (value.id === TransportOptions.CAR_MEDIUM) size = CarSize.MEDIUM;
    if (value.id === TransportOptions.CAR_LARGE) size = CarSize.LARGE;
    if (value.id.includes(TransportOptions.CAR)) value.id = TransportOptions.CAR;

    const foundQuestion = questions.children.find(
      (question: { answer: string }) => question.answer === value.id
    );

    const fuels = foundQuestion?.children?.map((fuel: { answer: any }) => ({
      id: fuel.answer,
      name: t(`employees.${fuel.answer}`)
    }));

    onChangeValue('transport')(value);
    setFormData((previous) => ({
      ...previous,
      size,
      fuelType: emptyFormData.fuelType
    }));
    setFuelTypeOptions(fuels || []);
  };

  const onChangeOrigin = (value: ChangeEvent<HTMLInputElement>) => {
    if (value?.target?.value === '' && !formData.destination) setTotalKmDisabled(false);
    else setTotalKmDisabled(true);
    onChangeValue('origin')(value);
  };

  const onChangeDestination = (value: ChangeEvent<HTMLInputElement>) => {
    if (value?.target?.value === '' && !formData.origin) setTotalKmDisabled(false);
    else setTotalKmDisabled(true);
    onChangeValue('destination')(value);
  };

  const onChangeTotalKm = (value: string) => {
    if (!value) setOriginDestinationDisabled(false);
    else setOriginDestinationDisabled(true);
    onChangeValue('totalKm')(value);
  };

  return {
    formData,
    setFormData,
    fuelTypeOptions,
    handleDateError,
    originDestinationDisabled,
    totalKmDisabled,
    onChangeValue,
    onChangeTransport,
    onChangeOrigin,
    onChangeDestination,
    onChangeTotalKm
  };
};

export default useFormData;
