import React from 'react';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('intensityMetricsDetail.activityVolume'),
      dataIndex: 'activity_volume',
      key: 'activity_volume'
    },
    {
      title: t('intensityMetricsDetail.metricDates'),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t('intensityMetricsDetail.intensityMetric'),
      dataIndex: 'intensity_metric',
      key: 'intensity_metric'
    },
    // {
    //   title: t('intensityMetricsDetail.createdBy'),
    //   dataIndex: 'created_by',
    //   key: 'created_by'
    // },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit'
    }
  ];
};

export default useColumns;
