import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorText from '../../../errorText/ErrorText';
import InputWrapperPlain from '../inputWrapperPlain/InputWrapperPlain';
import './styles.scss';
import InputLabel from '../inputWrapper/components/InputLabel';

type InputWrapperPlainType = ComponentProps<typeof InputWrapperPlain>;
type InputLabelType = ComponentProps<typeof InputLabel>;

interface Props extends InputWrapperPlainType, InputLabelType {
  tooltip?: string;
  error?: ErrorType;
  disabled?: boolean;
  className?: string;
}

const CheckboxWrapper = ({ error, tooltip, label, ...rest }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`input ${rest.className ?? ''}`}>
      {label && <InputLabel tooltip={tooltip} label={label} />}
      <div className='flex-col gap-y-1'>{rest.children}</div>
      <ErrorText style={{ marginTop: '0.5rem' }}>
        {error && (error?.description ?? t('error.requiredField'))}
      </ErrorText>
    </div>
  );
};

export default CheckboxWrapper;
