import { REMOVE_NOTIFICATION, SET_NOTIFICATION } from '../actions/types';

const initialState: NotificationType = {
  id: '',
  msg: ''
};

type NotificationActions = {
  type: string;
  payload: NotificationType;
};
export default function alert(state: NotificationType = initialState, action: NotificationActions) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATION:
      return { ...state, id: payload.id, msg: payload.msg };
    case REMOVE_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
}
