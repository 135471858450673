import { useTranslation } from 'react-i18next';
import Tag from '../tag/Tag';
import './styles.scss';
type Props = {
  total: number;
  total2?: number;
  total3?: number;
  loading: boolean;
  i18key: string;
};
const TotalLegend = ({ total, total2, total3, loading, i18key }: Props) => {
  const { t } = useTranslation();
  return (
    <Tag
      tags={[
        {
          icon1: '',
          text: <span className='on-light-text-color tag-font'>Total</span>,
          icon2: ''
        },
        {
          text: (
            <div className='total-wrapper'>
              <span className='highlight-text-color tag-bigger-font'>{loading ? '-' : total}</span>
              <span className='on-light-text-color tag-font'>{t(`${i18key}.total`)}</span>
              {total2 !== undefined && total2 > -1 ? (
                <>
                  <span className='highlight-text-color tag-bigger-font'>
                    {loading ? '-' : total2}
                  </span>
                  <span className='on-light-text-color tag-font'>{t(`${i18key}.total2`)}</span>
                  {total3 !== undefined ? (
                    <>
                      <span className='highlight-text-color tag-bigger-font'>
                        {loading ? '-' : total3}
                      </span>
                      <span className='on-light-text-color tag-font'>{t(`${i18key}.blocked`)}</span>
                    </>
                  ) : null}
                </>
              ) : (
                <></>
              )}
            </div>
          )
        }
      ]}
    />
  );
};

export default TotalLegend;
