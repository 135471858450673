import apiFetch from '../../utils/apiFetch';

export const getUserById = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/users/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
