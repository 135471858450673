import './styles.scss';

type Props = {
  children: React.ReactNode;
};
const DoubleCardWrapper = ({ children }: Props) => {
  return <div className='double-card-wrapper small-device-flex'>{children}</div>;
};

export default DoubleCardWrapper;
