/* eslint-disable prettier/prettier */
import { adaptLogisticShipmentBackToFront } from '../../../../../../adapters/logisticShipment';
import { SIZE_PAGINATION_SMALL } from '../../../../../../constants';
import { getLogisticShipmentList } from '../../../../../../services/api/logistics';
import useFetchInfiniteList from '../../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import { FiltersLogisticsShipments } from '../types';

const useFetchData = (debouncedFilters: FiltersLogisticsShipments) => {
  const fetch = async (
    pageNumber: number
  ): Promise<Pagination<ILogisticsShipmentFrontend> | undefined> => {
    if (debouncedFilters.errors.length > 0) return;

    const response = await getLogisticShipmentList({
      page: pageNumber,
      size: SIZE_PAGINATION_SMALL,
      id: debouncedFilters.id,
      client: debouncedFilters.client.id,
      startDate: debouncedFilters.startDate,
      endDate: debouncedFilters.endDate,
      vehicleLicense: debouncedFilters.vehicleLicense,
      trailerLicense: debouncedFilters.trailerLicense,
      loadKgLower: debouncedFilters.loadKgLower,
      loadKgUpper: debouncedFilters.loadKgUpper,
      distanceKmLower: debouncedFilters.distanceKmLower,
      distanceKmUpper: debouncedFilters.distanceKmUpper,
      tkmLower: debouncedFilters.tkmLower,
      tkmUpper: debouncedFilters.tkmUpper,
      vehicleType: debouncedFilters.vehicleType.id,
      tripStatus: debouncedFilters.tripStatus,
      cleaning: debouncedFilters.cleaning.id,
      subcontractor: debouncedFilters.subcontractor.id
    });

    if (response?.response?.status >= 400) return;

    let items = [];
    if (response?.items) {
      items = response.items.map((element: ILogisticsShipmentBackend) =>
        adaptLogisticShipmentBackToFront(element)
      );
    }
    // const mockData = [
    //   {
    //     vehicleType: 'air_unknown_long_haul_more_than_1500_km_kerosene',
    //     id: '1ES583R123Z9470',
    //     uniqueMovementId: '2ES583R124',
    //     client: 'Client 1',
    //     tripDate: '01/01/2024',
    //     iv: {
    //       value: 971,
    //       units: 'g CO₂e/tkm'
    //     },
    //     tkm: 10761.69,
    //     gCO2e: 10449.6,
    //     loadKg: 1000,
    //     distanceKm: 10761.694
    //   },
    //   {
    //     vehicleType: 'rigid_truck_12_20_t_gvw_average_diesel',
    //     id: '5121ES583R123Z9470',
    //     uniqueMovementId: '2SZ583R124',
    //     client: 'Client 1',
    //     tripDate: '01/01/2024',
    //     iv: {
    //       value: 179,
    //       units: 'g CO₂e/tkm'
    //     },
    //     tkm: 42,
    //     gCO2e: 7.52,

    //     loadKg: 0.5,
    //     distanceKm: 84 + 14.28
    //   },
    //   {
    //     vehicleType: 'general_cargo_non_container_vessel_0_4999_dwt_mdo',
    //     id: '41234DE583R123Z9470',
    //     uniqueMovementId: '512340987DAP9',
    //     client: 'Client 1',
    //     tripDate: '01/01/2024',
    //     iv: {
    //       value: 25.8,
    //       units: 'g CO₂e/tkm'
    //     },
    //     tkm: 4259.6,
    //     gCO2e: 109.9,
    //     loadKg: 0.5,
    //     distanceKm: 8519.2
    //   },
    //   {
    //     vehicleType: 'train_average_diesel',
    //     id: '0671582AHPGU123Z9470',
    //     uniqueMovementId: 'BV1078',
    //     client: 'Client 1',
    //     tripDate: '01/01/2024',
    //     iv: {
    //       value: 16.2,
    //       units: 'g CO₂e/tkm'
    //     },
    //     tkm: 474.76,
    //     gCO2e: 7.69,
    //     loadKg: 0.5,
    //     distanceKm: 949.51 + 313.338
    //   },
    //   {
    //     vehicleType: 'artic_truck_up_to_40_t_gvw_average_diesel',
    //     id: 'VU109V234',
    //     uniqueMovementId: 'BVTQEVPB8V734',
    //     client: 'Client 1',
    //     tripDate: '01/01/2024',
    //     iv: {
    //       value: 2167.72,
    //       units: 'g CO₂e/tkm'
    //     },
    //     tkm: 389.03,
    //     gCO2e: 843.3,
    //     loadKg: 0.5,
    //     distanceKm: 778.05 + 132.269
    //   },
    //   {
    //     vehicleType: 'north_american_road_tl_dry_van',
    //     id: 'V-B123',
    //     uniqueMovementId: '0V9451P283945',
    //     client: 'Client 1',
    //     tripDate: '01/01/2024',
    //     iv: {
    //       value: 94,
    //       units: 'g CO₂e/tkm'
    //     },
    //     tkm: 67.59,
    //     gCO2e: 6.35,
    //     loadKg: 0.5,
    //     distanceKm: 135.188
    //   }
    // ];

    // items = items.concat(mockData);
    // // MOCK DATA FOR ESTIMATED EMISSIONS
    // items.unshift({
    //   id: '1ES583R123Z9469',
    //   uniqueMovementId: '1ES583R123',
    //   client: 'Client 1',
    //   loadKg: null,
    //   vehicleLicensePlate: '9183CRK',
    //   trailerLicensePlate: '2384DXY',
    //   tripDate: '01/01/2024',
    //   tkm: null,
    //   distanceKm: 100,
    //   emissionIntensity: null,
    //   gCO2e: null,
    //   vehicleType: 'bulk_carrier_non_container_vessel_60000_99999_dwt_hfo'
    // });

    return {
      ...response,
      // total: 1,
      items: items
    };
  };

  const { fetchData, firstLoading, loading, total, data, editElement } =
    useFetchInfiniteList<ILogisticsShipmentFrontend>(fetch, [debouncedFilters]);

  return {
    fetchData,
    firstLoading,
    loading,
    total,
    data,
    editElement
  };
};

export default useFetchData;
