import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/routes';
import { localStorageSetItem } from '../../../../../utils/localStorage';
import TutorialModalCenter from '../../../interactiveTutorial/components/TutorialModalCenter';
import TutorialStep from '../../../interactiveTutorial/components/TutorialStep';
import HighlightedElement from '../../../interactiveTutorialTool/components/HighlightedElement';
import useHandleStep from '../../../interactiveTutorialTool/hooks/useHandleStep';
import { getBiggerPosition } from '../../../interactiveTutorialTool/utils/getBiggerPosition';
import { getCurrentStep } from '../../../interactiveTutorialTool/utils/getCurrentStep';
import TutorialModal, { Location } from '../../components/TutorialModal';
import Milestones from '../components/milestones/Milestones';

const useTutorialSteps = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // # in order to get a id

  // . in order to get a class

  // nothing in order show modal centered

  const ids = [
    'initial_step',
    // 'welcome',
    '#date',
    '#tasks',
    '#menu',
    '#measure',
    '#facilities',
    '#vehicles'
  ];

  const {
    current,
    position,
    handleNext,
    handleNextRetry,
    handleSkip: handleSkipStep,
    handleStart,
    handlePositionNull
  } = useHandleStep(ids);

  const handleSkip = () => {
    localStorageSetItem('isMainTutorialCompleted', true);
    handleSkipStep();
  };

  const index = ids.indexOf(current || '');
  const total = ids.length - 1;

  // Acoplado!!! cambiar en el futuro

  const menuPosition = position
    ? {
        ...position,
        width: position.width - 20,
        left: position.left + 10,
        height: position.height + 20,
        top: position.top - 10
      }
    : null;

  const handleNextToTask = () => {
    const element: HTMLElement | null = document.querySelector('#task_icon');
    if (!element) {
      handleSkip();
      return;
    }
    handlePositionNull();
    element.click();
    handleNextRetry(10, 200);
  };

  const handleGoToMeasure = () => {
    handlePositionNull();
    navigate(`${ROUTES.MEASURE}`);
    handleNextRetry(10, 200);
  };

  const handleFinish = () => {
    handleSkip();
  };

  const steps = [
    {
      step: (
        <TutorialModalCenter
          style={{
            width: '700px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
          <Milestones handleNext={handleNext} handleSkip={handleSkip} />
        </TutorialModalCenter>
      ),
      highlighted: <HighlightedElement position={position} />
    },
    // {
    //   step: (
    //     <TutorialModalCenter
    //       style={{
    //         width: '450px',
    //         position: 'fixed',
    //         top: '50%',
    //         left: '50%',
    //         transform: 'translate(-50%, -50%)'
    //       }}>
    //       <Welcome
    //         title={t('interactiveTutorial.welcome.title')}
    //         handleNext={handleNext}
    //         handleSkip={handleSkip}
    //       />
    //     </TutorialModalCenter>
    //   ),
    //   highlighted: <HighlightedElement position={position} />
    // },
    {
      step: (
        <TutorialModal
          style={{ width: '400px' }}
          positionElement={getBiggerPosition(position)}
          location={Location.LEFT}
          plusTop={-15}>
          <TutorialStep
            title={t('interactiveTutorial.date.title')}
            description={t('interactiveTutorial.date.description')}
            handleNext={handleNextToTask}
            handleSkip={handleSkip}
            index={index}
            total={total}
          />
        </TutorialModal>
      ),
      highlighted: <HighlightedElement position={getBiggerPosition(position)} />
    },
    {
      step: (
        <TutorialModal
          style={{ width: '400px' }}
          positionElement={getBiggerPosition(position)}
          location={Location.LEFT}>
          <TutorialStep
            title={t('interactiveTutorial.tasks.title')}
            description={t('interactiveTutorial.tasks.description')}
            handleNext={handleNext}
            handleSkip={handleSkip}
            index={index}
            total={total}
          />
        </TutorialModal>
      ),
      highlighted: <HighlightedElement position={getBiggerPosition(position)} />
    },
    {
      step: (
        <TutorialModal
          style={{ width: '400px' }}
          positionElement={getBiggerPosition(position)}
          location={Location.RIGHT}>
          <TutorialStep
            title={t('interactiveTutorial.menu.title')}
            description={t('interactiveTutorial.menu.description')}
            handleNext={handleNext}
            handleSkip={handleSkip}
            index={index}
            total={total}
          />
        </TutorialModal>
      ),
      highlighted: <HighlightedElement position={menuPosition} />
    },
    {
      step: (
        <TutorialModal
          style={{ width: '400px' }}
          positionElement={getBiggerPosition(position)}
          location={Location.RIGHT}
          plusTop={-30}>
          <TutorialStep
            title={t('interactiveTutorial.measure.title')}
            description={t('interactiveTutorial.measure.description')}
            handleNext={handleGoToMeasure}
            handleSkip={handleSkip}
            index={index}
            total={total}
          />
        </TutorialModal>
      ),
      highlighted: (
        <>
          <div
            onClick={handleGoToMeasure}
            className='no-opacity pointer'
            style={{
              ...getBiggerPosition(position)
            }}
          />
        </>
      )
    },
    {
      step: (
        <>
          <TutorialModal
            style={{ width: '400px' }}
            positionElement={getBiggerPosition(position)}
            location={Location.RIGHT}>
            <TutorialStep
              title={t('interactiveTutorial.facilities.title')}
              description={t('interactiveTutorial.facilities.description')}
              handleNext={handleNext}
              handleSkip={handleSkip}
              index={index}
              total={total}
            />
          </TutorialModal>
        </>
      ),
      highlighted: <HighlightedElement position={getBiggerPosition(position)} />
    },
    {
      step: (
        <>
          <TutorialModal
            style={{ width: '350px' }}
            positionElement={getBiggerPosition(position)}
            location={Location.RIGHT}>
            <TutorialStep
              title={t('interactiveTutorial.vehicles.title')}
              description={t('interactiveTutorial.vehicles.description')}
              handleNext={handleFinish}
              index={index}
              total={total}
            />
          </TutorialModal>
        </>
      ),
      highlighted: <HighlightedElement position={getBiggerPosition(position)} />
    }
  ];

  const currentStep = getCurrentStep(ids, steps, current);

  return { currentStep, handleStart };
};

export default useTutorialSteps;
