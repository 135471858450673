import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import CustomSkeletonLoader from '../../../../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import useModal from '../hooks/useModal';

const ModalC6 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti.criteriaForm.modal' });
  const mandatory = ['submitYear'];

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);

  const minYearBaseYear = 2015;
  const maxYearBaseYear = today.getFullYear();

  const submitYearOptions: SelectOptionFormat[] = [];
  for (let i = maxYearBaseYear; i >= minYearBaseYear; i--) {
    submitYearOptions.push({ id: i.toString(), name: i.toString() });
  }

  const { formData, setFormData, loading, patchCriteria, loadingButton, errorPatch } =
    useModal(mandatory);

  const submitYear = formData.submitYear?.id ? Number(formData.submitYear.id) : 0;
  const minYearGoalYear = submitYear ? submitYear + 5 : 0;
  const maxYearGoalYear = submitYear ? submitYear + 15 : 0;

  const goalYearOptions: SelectOptionFormat[] = [];
  for (let i = maxYearGoalYear; i >= minYearGoalYear; i--) {
    goalYearOptions.push({ id: i.toString(), name: i.toString() });
  }

  const { onChangeValue } = useOnChangeValue({ setFormData });

  useEffect(() => {
    formData.submitYear !== undefined ? mandatory.push('goalYear') : null;
  }, [formData.submitYear]);

  return (
    <>
      <FormHeader title={t('title')} />
      {loading && (
        <CustomSkeletonLoader
          count={1}
          style={{
            marginBottom: '2rem'
          }}
        />
      )}

      {!loading && (
        <FormWrapper>
          <FormSelect
            label={t('submitYear')}
            iconV2='calendar'
            onChange={onChangeValue('submitYear')}
            value={
              formData.submitYear || {
                id: '',
                name: ''
              }
            }
            options={submitYearOptions}
            error={formData.errors.find((error) => error.error === 'submitYear')}
          />
          <FormSelect
            tooltip={t('tooltipModalC6')}
            label={t('goalYear')}
            iconV2='calendar'
            value={
              formData.goalYear || {
                id: '',
                name: ''
              }
            }
            onChange={onChangeValue('goalYear')}
            options={goalYearOptions}
            disabled={formData?.submitYear === undefined || formData.submitYear.id === ''}
            error={
              formData?.submitYear !== undefined
                ? formData.errors.find((error) => error.error === 'goalYear')
                : undefined
            }
          />
        </FormWrapper>
      )}
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          text={t('save')}
          loading={loadingButton}
          size='small'
          onClick={() => patchCriteria(formData)}
        />
      </FormButtonSection>
      <ErrorText>{errorPatch && errorPatch.description}</ErrorText>
    </>
  );
};

export default ModalC6;
