import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { SIZE_PAGINATION_SMALL } from '../../../../../constants';
import { getListCustomEFDetail } from '../../../../../services/api/customEFDetails';
import useFetchInfiniteList from '../../../../ui/infiniteList/hooks/useFetchInfiniteList';
import useColumns from './useColumns';
import { useTranslation } from 'react-i18next';

type Props = {
  groupId?: string;
};

const useCustomEFDetails = ({ groupId }: Props) => {
  const dispatch = useDispatch();
  const { t: tN } = useTranslation('translation', { keyPrefix: 'notification' });

  const fetch = async (page: number) => {
    if (!groupId) return;
    const response = await getListCustomEFDetail({ groupId, page, size: SIZE_PAGINATION_SMALL });
    if (response?.response?.status >= 400) return;
    return response;
  };

  const { fetchData, firstLoading, loading, total, data, addElement, editElement, removeElement } =
    useFetchInfiniteList<ICustomEFBack>(fetch, []);

  const {
    columns,
    showAddModal,
    elementToEdit,
    elementToDelete,
    handleHideAddModal,
    handleShowAddModal,
    handleHideEdit,
    handleShowEdit,
    handleHideDelete,
    handleShowDelete
  } = useColumns();

  const handleAddElement = (element: ICustomEFBack) => {
    addElement(element);
    handleHideAddModal();
    dispatch(setNotification(tN('add')));
  };

  const handleEditElement = (element: ICustomEFBack) => {
    editElement(element);
    handleHideEdit();
    dispatch(setNotification(tN('edit')));
  };

  const handleDeleteElement = (id: string) => {
    removeElement(id);
    handleHideDelete();
    dispatch(setNotification(tN('delete')));
  };

  return {
    fetchData,
    firstLoading,
    loading,
    total,
    data,
    columns,
    showAddModal,
    elementToEdit,
    elementToDelete,
    handleHideAddModal,
    handleShowAddModal,
    handleHideEdit,
    handleShowEdit,
    handleHideDelete,
    handleShowDelete,
    handleAddElement,
    handleEditElement,
    handleDeleteElement
  };
};

export default useCustomEFDetails;
