import { useEffect, useState } from 'react';
import CustomSkeletonLoader from '../../../ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import { useTranslation } from 'react-i18next';
import SlideToggle from '../../../ui/formComponents/slideToggle/SlideToggle';
import FormSelect from '../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import useCategories from '../../../../constants/categories';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormText from '../../../ui/formComponents2/formInputs/formText/FormText';
import FormTextArea from '../../../ui/formComponents2/formInputs/formTextArea/FormTextArea';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../ui/button/Button';
import ErrorText from '../../../ui/errorText/ErrorText';
import checkFormErrors from '../../../../utils/checkFormErrors';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import FormElement from '../../../ui/formComponents/formElement/FormElement';

const emptyFormData: ICustomEFGroupFront = {
  category: {
    id: '',
    name: ''
  },
  name: '',
  description: '',
  ghgType: false,
  errors: []
};

type Props = {
  onSubmit: (element: ICustomEFGroupFront) => Promise<void>;
  formDataFilled?: ICustomEFGroupFront;
  loading?: boolean;
  error?: ErrorType;
};

const Form = ({ onSubmit, formDataFilled, loading, error }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'customize.create' });
  const [loadingButton, setLoadingButton] = useState(false);
  const { categories } = useCategories();

  const [formData, setFormData] = useState<ICustomEFGroupFront>(formDataFilled || emptyFormData);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  // initialize form data
  useEffect(() => {
    if (!formDataFilled) return;
    setFormData(formDataFilled);
  }, [formDataFilled]);

  const handleErrors = () => {
    const optionalFields: string[] = ['id', 'description', 'ghgType'];
    const newErrors: ErrorType[] = checkFormErrors(formData, [], optionalFields);

    setFormData({
      ...formData,
      errors: newErrors
    });

    return newErrors.length !== 0 ? newErrors : false;
  };

  const handleCLickSubmit = async () => {
    if (handleErrors() || loadingButton) return;
    setLoadingButton(true);
    await onSubmit(formData);
    setLoadingButton(false);
  };

  if (loading) return <CustomSkeletonLoader count={4} />;

  return (
    <>
      <FormWrapper>
        <FormElement>
          <FormSelect
            label={t('applyTo')}
            placeholder={t('selectCategory')}
            icon={'/images/icons/branch.svg'}
            options={categories}
            value={formData.category}
            onChange={onChangeValue('category')}
            error={formData.errors.find((error) => error.error === 'category')}
            disabled={!!formDataFilled}
          />
          {formData.category.id === 'heat' ? (
            <div
              className='toggle-element'
              style={{
                marginTop: '0.5rem'
              }}>
              <SlideToggle
                label={t('biogenic')}
                checked={formData.ghgType}
                setChecked={onChangeValue('ghgType')}
                disabled={!!formDataFilled}
              />
            </div>
          ) : null}
        </FormElement>
        <FormElementFull>
          <FormText
            iconV2='thermometer'
            label={t('factorName')}
            value={formData.name}
            onChange={onChangeValue('name')}
            error={formData.errors.find((error) => error.error === 'name')}
          />
        </FormElementFull>
        <FormTextArea
          label={t('factorDescription')}
          value={formData.description}
          onChange={onChangeValue('description')}
        />
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel={'primary'}
          onClick={handleCLickSubmit}
          text={t('saveAndConfigure')}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
};

export default Form;
