type CurrencySymbol = {
  [key: string]: string;
};
export const currencySymbols: CurrencySymbol = {
  'euros_(eur)': '€',
  'yuan_(cny)': '¥',
  'us_dollar_(usd)': '$',
  'british_pound_(gbp)': '£',
  'japanese_yen_(jpy)': 'JP¥',
  'indian_rupee_(inr)': '₹',
  'canadian_dollar_(cad)': 'Can$',
  'swiss_franc_(chf)': 'Fr.'
};
