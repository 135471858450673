import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FreeTrialContext } from '../../../../context/freeTrialContext';
import { UserContext } from '../../../../context/userContext';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { usePremiumRoutes } from '../../../../customHooks/usePremiumRoutes';
import { checkFlags } from '../../../../utils/flags';
import Icon from '../../../ui/icon/Icon';
import SubSection from '../subSection/SubSection';
import './styles.scss';
import Modal from '../../../ui/modal/Modal';
import { PAID_ROUTES } from '../../../../constants/routes';
type Props = {
  section: SubSectionType;
  subscriptionPlan: string;
};

function Section({ section, subscriptionPlan }: Props) {
  const { text, route, subSections, newTab } = section;

  const user = useContext(UserContext);
  const flags = useFeatureFlags();
  const PREMIUM_ROUTES = usePremiumRoutes();
  const [showSubSections, setShowSubSections] = useState(true);
  const [showModalPaidRoute, setShowModalPaidRoute] = useState(false);

  const freeTrialContext = useContext(FreeTrialContext);

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const handleOnClick = () => {
    // parse route without query params
    const routeWithoutQueryParams = route?.split('?')[0];
    const premium = PREMIUM_ROUTES.find((elem) => elem === routeWithoutQueryParams);
    const paidRoute = PAID_ROUTES.find((elem) => elem === routeWithoutQueryParams);
    if (!route) {
      setShowSubSections(!showSubSections);
      return;
    }

    if (premium && subscriptionPlan === 'free_plan') {
      freeTrialContext?.setShowPremiumFeatureModal(true);
      return;
    }

    if (paidRoute && section.paidRouteModal) {
      setShowModalPaidRoute(true);
      return;
    }

    if (section.onClickCallback && user?.selectedOrganization && !user.email.includes('dcycle')) {
      section.onClickCallback();
    }

    const urlSearchParams = new URLSearchParams(route.split('?')[1]);

    const actualUrlSearchParams = new URLSearchParams(location.search.split('?')[1]);

    urlSearchParams.forEach((value, key) => {
      actualUrlSearchParams.set(key, value);
    });

    const routeParsed = route.split('?')[0] + '?' + actualUrlSearchParams.toString();

    if (newTab) {
      window.open(routeParsed);
    } else {
      navigate(routeParsed);
    }
  };

  let selected = '';

  if (!subSections || subSections.length === 0) {
    selected = route && pathname?.includes(route) ? 'active' : '';
  }
  return (
    <div className='section-wrapper'>
      <div
        className={`section side-menu-section-font on-light-text-color ${selected}`}
        onClick={handleOnClick}>
        {subSections && subSections.length > 0 && (
          <Icon
            icon='chevron_down'
            className={`${showSubSections ? 'rotate-back' : 'rotate180'}`}
            color='gradient'
            size='medium'
          />
        )}
        {section.icon ?? null}
        <span>{text}</span>
      </div>
      {showSubSections && (
        <div className='section__subsections'>
          {subSections?.map((subSection, index) => {
            if (!checkFlags(subSection.id, flags)) return null;
            return (
              <div
                key={`subsection-${subSection.text}-${index}`}
                style={{
                  paddingTop: index === 0 ? '9px' : '0px'
                }}>
                <SubSection section={subSection} subscriptionPlan={subscriptionPlan} />
              </div>
            );
          })}
        </div>
      )}

      <Modal.WithPortal show={showModalPaidRoute} onClose={() => setShowModalPaidRoute(false)}>
        {section.paidRouteModal}
      </Modal.WithPortal>
    </div>
  );
}

export default Section;
