import { useTranslation } from 'react-i18next';
import useYearOptions from '../../../../../customHooks/useYearOptions';
import InputCalendarDouble from '../../inputCalendarDouble/InputCalendarDouble';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';
import Select from '../../select/Select';
import { useEffect, useState } from 'react';
import moment from 'moment';
import './styles.scss';
import { isValidDate } from '../../../../../constants/regex';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children' | 'icon'>;
type InputCalendarDoubleType = React.ComponentProps<typeof InputCalendarDouble>;

type Props = InputCalendarDoubleType & InputWrapperType;

const FormCalendarDoubleSelect = (props: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'general' });
  const { years } = useYearOptions();

  const [year, setYear] = useState<SelectOptionFormat>({
    id: 'custom',
    name: t('custom')
  });

  const handleDateErrorIsBefore = () => {
    if (!isValidDate(props.startDate) || !isValidDate(props.endDate)) return;
    const momentStartDate = moment(props.startDate, 'DD/MM/YYYY');
    const momentEndDate = moment(props.endDate, 'DD/MM/YYYY');

    return momentEndDate.isBefore(momentStartDate);
  };

  useEffect(() => {
    if (handleDateErrorIsBefore()) {
      props.handleChangeStartDate('');
      props.handleChangeEndDate('');
      return;
    }
    handleChangeStartDate(props.startDate);
    handleChangeEndDate(props.endDate);
  }, [props.startDate, props.endDate]);

  const handleChangeStartDate = (value: string) => {
    if (!value) {
      props.handleChangeEndDate(value);
      return;
    }
    // Not custom is 01/01/YYYY and 31/12/YYYY
    const valueToMoment = moment.utc(value, 'DD/MM/YYYY');
    const endDateToMoment = moment.utc(props.endDate, 'DD/MM/YYYY');

    const startOfYear = moment.utc(`01/01/${valueToMoment.year()}`, 'DD/MM/YYYY');
    const endOfYear = moment.utc(`31/12/${valueToMoment.year()}`, 'DD/MM/YYYY');

    if (!valueToMoment.isSame(startOfYear) || !endDateToMoment.isSame(endOfYear)) {
      setYear({
        id: 'custom',
        name: t('custom')
      });
    } else {
      setYear({
        id: valueToMoment.year().toString(),
        name: valueToMoment.year().toString()
      });
    }
    // Check if props.startDate and props.endDate are start and end of the same year
    props.handleChangeStartDate(value);
  };

  const handleChangeEndDate = (value: string) => {
    if (!value) {
      props.handleChangeEndDate(value);
      return;
    }
    const valueToMoment = moment.utc(value, 'DD/MM/YYYY');
    const startDateToMoment = moment.utc(props.startDate, 'DD/MM/YYYY');

    const startOfYear = moment.utc(`01/01/${valueToMoment.year()}`, 'DD/MM/YYYY');
    const endOfYear = moment.utc(`31/12/${valueToMoment.year()}`, 'DD/MM/YYYY');

    if (!valueToMoment.isSame(endOfYear) || !startDateToMoment.isSame(startOfYear)) {
      setYear({
        id: 'custom',
        name: t('custom')
      });
    } else {
      setYear({
        id: valueToMoment.year().toString(),
        name: valueToMoment.year().toString()
      });
    }
    // Check if props.startDate and props.endDate are start and end of the same year
    props.handleChangeEndDate(value);
  };
  return (
    <InputWrapper iconV2='calendar' {...props} className='form-calendar-double-select'>
      <Select
        options={years}
        value={year}
        onChange={(value: SelectOptionFormat) => {
          setYear(value);
          // we need to change the date values of the InputCalendarDouble regarding the year selected
          const startDateOfYear = moment
            .utc(value.name, 'YYYY')
            .startOf('year')
            .format('DD/MM/YYYY');
          const endDateOfYear = moment.utc(value.name, 'YYYY').endOf('year').format('DD/MM/YYYY');
          handleChangeStartDate(startDateOfYear);
          handleChangeEndDate(endDateOfYear);
        }}
        sort={false}
        disabled={props.disabled}
      />
      <InputCalendarDouble
        {...props}
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
      />
    </InputWrapper>
  );
};

export default FormCalendarDoubleSelect;
