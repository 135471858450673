import { PreEventBody } from '../../types/entities/events';
import apiFetch from '../../utils/apiFetch';

type EventPostBody = {
  name: string;
  location_type: string;
  location: string;
  start_date: Date;
  end_date: Date;
};
export const createEvent = async (event: EventPostBody, organization_id: string) => {
  try {
    const eventCreated = await apiFetch('POST', '/events', event, {
      'x-organization-id': organization_id
    });
    return eventCreated.data;
  } catch (err) {
    return null;
  }
};

type EventUpdateBody = {
  name: string;
  location_type: string;
  location: string;
  start_date: Date;
  end_date: Date;
};

export const updateEvent = async (
  event_id: string,
  event: EventUpdateBody,
  organization_id: string
) => {
  try {
    const eventUpdated = await apiFetch('PATCH', `/events/${event_id}`, event, {
      'x-organization-id': organization_id
    });
    return eventUpdated.data;
  } catch (err) {
    return null;
  }
};

export const deleteEvent = async (event_id: string, organization_id: string) => {
  try {
    const eventDeleted = await apiFetch(
      'DELETE',
      `/events/${event_id}`,
      {},
      {
        'x-organization-id': organization_id
      }
    );
    return eventDeleted.data;
  } catch (err) {
    return null;
  }
};

export const getEventById = async (event_id: string, organization_id: string) => {
  try {
    const event = await apiFetch(
      'GET',
      `/events/${event_id}`,
      {},
      {
        'x-organization-id': organization_id
      }
    );
    return event.data;
  } catch (err) {
    return null;
  }
};

export const patchPreEvent = async (
  event_id: string,
  body: PreEventBody,
  organization_id: string
) => {
  try {
    const event = await apiFetch('PATCH', `/pre-event/${event_id}`, body, {
      'x-organization-id': organization_id
    });
    return event.data;
  } catch (err) {
    return null;
  }
};

export const getPreEvent = async (event_id: string, organization_id: string) => {
  try {
    const event = await apiFetch(
      'GET',
      `/pre-event/${event_id}`,
      {},
      {
        'x-organization-id': organization_id
      }
    );
    return event.data;
  } catch (err) {
    return null;
  }
};

export const deletePreEvent = async (event_id: string, organization_id: string) => {
  try {
    const event = await apiFetch('DELETE', `/pre-event/${event_id}`, null, {
      'x-organization-id': organization_id
    });
    return event.data;
  } catch (err: any) {
    console.error(err.response.data);
    return null;
  }
};

export const requestReport = async (
  event_id: string,
  report_type: string,
  organization_id: string
) => {
  try {
    const event = await apiFetch('POST', `/events/${event_id}/report/${report_type}`, null, {
      'x-organization-id': organization_id
    });
    return event.data;
  } catch (err) {
    return null;
  }
};
