import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { connect, ConnectedProps } from 'react-redux';
import { updateOrganizationInfo } from '../../actions/auth';
import { ROUTES } from '../../constants/routes';
import { RegisterInfo2 } from '../../types/entities/user';
import StepController from '../ui/stepPage/StepController';
import StepControllerContainer from '../ui/stepPage/StepControllerContainer';
import StepCompanyName from './currentSteps/StepCompanyName';
import StepHolding from './currentSteps/StepHolding';
import StepSector from './currentSteps/StepSector';
import StepVat from './currentSteps/StepVat';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = {
  updateOrganizationInfo
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const OnboardingUser = ({ updateOrganizationInfo, user }: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [registerInfo, setRegisterInfo] = useState<RegisterInfo2>({
    sector: '',
    vat: '',
    employee_count_signup: 0,
    country: '',
    company_name: '',
    is_group_fund: false
  });

  const [step, setStep] = useState(1);

  const { t } = useTranslation();
  const LAST_STEP = 5;

  const nextStep = async (value: RegisterInfo2) => {
    if (step === LAST_STEP && params.id) {
      // await updateOrganizationInfo(params.id, registerInfo, user.id);
      navigate(ROUTES.IMPROVE_DASHBOARD);
    } else {
      setStep(step + 1);
      setRegisterInfo({
        ...registerInfo,
        ...value
      });
    }
  };

  const steps = [
    <StepHolding
      nextStep={nextStep}
      stepNumber={1}
      registerInfo={registerInfo}
      key='step-holding'
    />,
    <StepCompanyName
      nextStep={nextStep}
      stepNumber={2}
      registerInfo={registerInfo}
      key='step-company-name'
    />,
    <StepVat nextStep={nextStep} stepNumber={3} registerInfo={registerInfo} key='step-vat' />,
    <StepSector nextStep={nextStep} stepNumber={5} registerInfo={registerInfo} key='step-sector' />
  ];

  return (
    <StepControllerContainer
      title={t('onboardingUser.title')}
      subtitle={t('onboardingUser.subtitle')}>
      <div className='onboarding-user__form'>
        <StepController step={step} steps={steps} />
      </div>
    </StepControllerContainer>
  );
};

export default connector(OnboardingUser);
