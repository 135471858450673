import { useTranslation } from 'react-i18next';
import { months } from '../../constants/months';
import numberToDecimal from '../../utils/numberToDecimal';
import Download from './Download';

type Props = {
  data: PaymentInvoice;
};
function InvoiceCard({ data }: Props) {
  const { t, i18n } = useTranslation();

  const date = new Date(data.created_at);
  const month: any = months.find((elem) => elem.number === date.getMonth() + 1);
  return (
    <div className='card card-invoice'>
      <div className='card-invoice__header secondary-light-bg-color '>
        <img src='/images/invoicePreview.svg' alt='invoice-preview' className='img-preview' />
        <Download id={data.id} name={data.concept} />
      </div>
      <h1 className='small-card-font'>Dcycle</h1>
      <div className='card-invoice__list'>
        <div className='card-invoice__list__item'>
          <img src='/images/icons/cloud50.svg' alt='cloud' />
          <span className='body1-font'>{t('invoicing.offsetting')}</span>
        </div>
        <div className='card-invoice__list__item'>
          <img src='/images/icons/calendar50.svg' alt='calendar' />
          <span className='body1-font'>{month.name[i18n.resolvedLanguage]}</span>
        </div>
        <div className='card-invoice__list__item'>
          <img src='/images/icons/euro.svg' alt='euro' />
          <span className='body1-font'>{numberToDecimal(data.price)}</span>
        </div>
      </div>
    </div>
  );
}

export default InvoiceCard;
