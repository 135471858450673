import { useContext, useState } from 'react';
import {
  adaptBusinessTravelBackToFront,
  adaptBusinessTravelFrontToBack
} from '../../../../../adapters/businessTravel';
import { UserContext } from '../../../../../context/userContext';
import useErrors from '../../../../../customHooks/useErrors';
import { patchBusinessTravel } from '../../../../../services/api/businessTravels';

import {
  IFrontendFormBusinessTravel,
  IGetBackendBusinessTravel
} from '../../../../../types/entities/businessTravels';

import FormBusinessTravel from '../formBusinessTravel/FormBusinessTravel';

type Props = {
  editBusinessTravel: (value: IGetBackendBusinessTravel) => void;
  businessTravelToEdit: IGetBackendBusinessTravel;
};

function EditBusinessTravel({ editBusinessTravel, businessTravelToEdit }: Props) {
  const ERRORS = useErrors();
  const user = useContext(UserContext);
  const [error, setError] = useState<ErrorType>();

  const handleAddBusinessTravel = async (formData: IFrontendFormBusinessTravel) => {
    if (!user?.selectedOrganization) return;
    const backendBusinessTravelData = adaptBusinessTravelFrontToBack(formData);

    const response = await patchBusinessTravel({
      id: businessTravelToEdit.id,
      value: backendBusinessTravelData,
      organizationId: user.selectedOrganization
    });

    if (
      response?.response?.status === 422 &&
      response?.response?.data === 'INVALID_TRAVEL_MODE_BETWEEN_PLACES'
    ) {
      setError(ERRORS.BUSINESS_TRAVEL.INVALID_TRAVEL_MODE_BETWEEN_PLACES);
      return;
    }

    if (response?.response?.status) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    const backendResponseBusinessTravelData: IGetBackendBusinessTravel = response;
    editBusinessTravel(backendResponseBusinessTravelData);
  };

  const formDataFilled = adaptBusinessTravelBackToFront(businessTravelToEdit);

  return (
    <FormBusinessTravel
      submit={handleAddBusinessTravel}
      error={error}
      formDataFilled={formDataFilled}
    />
  );
}

export default EditBusinessTravel;
