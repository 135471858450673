import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EmployeeFormContext } from '../../../../../../context/employeeFormContext';
import useOnChangeValue from '../../../../../../customHooks/useOnChangeValue';
import { EmployeePeriodFormType } from '../../../../../../types/entities/employeePeriod';

import { useParams } from 'react-router-dom';
import Button from '../../../../../ui/button/Button';
import FormSelect from '../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import StepControllerContainer from '../../../../../ui/stepPage/StepControllerContainer';
import StepWrapper from '../../../../../ui/stepPage/stepWrapper/StepWrapper';
import SwitchStep from '../../../../../ui/stepPage/switchStep/SwitchStep';

import { convertDateBackToFront } from '../../../../../../utils/convertDates';
import '../styles.scss';

type Props = {
  next: string;
  title?: ReactNode;
};
function StepCarpooling({ next, title }: Props) {
  const { t } = useTranslation();
  const { startDate, endDate } = useParams();

  const { formData, setFormData, stepCounter, handleNext, handleBack } =
    useContext(EmployeeFormContext);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleErrors = () => {
    const newErrors: ErrorType[] = [];

    if (!formData?.carpooling.id)
      newErrors.push({
        error: 'carpooling',
        description: t('error.requiredField')
      });

    if (newErrors.length > 0) {
      setFormData((previous: EmployeePeriodFormType) => ({ ...previous, errors: newErrors }));
      return newErrors;
    }
    return false;
  };

  const handleNextStep = () => {
    if (handleErrors() || !handleNext) return;
    handleNext(next);
  };

  if (!handleNext || !handleBack) return null;

  return (
    <StepControllerContainer
      title={t('employeeForm.formTitle1', {
        date: `${startDate && convertDateBackToFront(startDate)} - ${
          endDate && convertDateBackToFront(endDate)
        }`
      })}
      subtitle={t('employeeForm.formSubtitle2')}>
      <StepWrapper title={t('employeeForm.carpooling')} stepNumber={stepCounter}>
        <FormSelect
          tooltip={t('employeeForm.carpoolingTooltip')}
          icon={'/images/icons/person.svg'}
          placeholder={t('yesNoOptions.enterYesNo')}
          value={formData?.carpooling}
          options={[
            { id: 'yes', name: t('employeeForm.carpoolingYes') },
            { id: 'no', name: t('employeeForm.carpoolingNo') }
          ]}
          onChange={onChangeValue('carpooling')}
          error={formData?.errors?.find((error) => error.error === 'carpooling')}
        />
        <Button lookAndFeel={'primary'} onClick={handleNextStep} text={t('employeeForm.next')} />
      </StepWrapper>
      <SwitchStep handleNext={handleNextStep} handleBack={handleBack} />
    </StepControllerContainer>
  );
}

export default StepCarpooling;
