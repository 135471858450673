import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectOption from '../select/SelectOption';
import MultiSelectValue from './MultiSelectValue';
import './styles.scss';

type Props = {
  icon?: string;
  label?: string;
  placeholder: string;
  options: SelectOptionFormat[];
  value: SelectOptionFormat;
  onChangeValue: (value: SelectOptionFormat) => void;
  onRemoveValue: (id: string) => void;
  fontClass?: string;
  error?: ErrorType;
  height?: string;
  size?: string;
  position?: 'left' | 'right' | 'none';
  selectedOptions: SelectOptionFormat[];
};

const MultiSelect = ({
  icon,
  label,
  value,
  placeholder,
  onChangeValue,
  onRemoveValue,
  options,
  error,
  fontClass = 'input-font',
  height = '33px',
  size,
  position = 'right',
  selectedOptions
}: Props) => {
  const { t } = useTranslation();

  const [showOptions, setShowOptions] = useState(false);
  const [optionIndex, setOptionIndex] = useState(0);

  const [disableAutoScroll, setDisableAutoScroll] = useState(false);

  const wrapperRef: any = useRef(null);
  const showOptionsRef: any = useRef(showOptions);

  function downHandler(e: { preventDefault: () => void; key: string }) {
    if (e.key === 'ArrowDown' && showOptionsRef.current) {
      e.preventDefault();
      setOptionIndex((prevState) => {
        return prevState < options.length - 1 ? prevState + 1 : prevState;
      });
      setDisableAutoScroll(false);
    } else if (e.key === 'Enter' && showOptionsRef.current) {
      onChangeValue(optionsFiltered[optionIndex]);
      setShowOptions(false);
      setOptionIndex(0);
    } else if (e.key === 'ArrowUp' && showOptionsRef.current) {
      e.preventDefault();
      setOptionIndex((prevState) => (prevState > 0 ? prevState - 1 : prevState));
      setDisableAutoScroll(false);
    }
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
        showOptionsRef.current = false;
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', downHandler);

    const element = optionsFiltered[optionIndex]
      ? document.getElementById(optionsFiltered[optionIndex].id)
      : null;
    if (element && !disableAutoScroll) {
      // scrollParentToChild(wrapperRef.current, element);
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', downHandler);
    };
  }, [wrapperRef, optionIndex]);

  const showHideOptions = () => {
    showOptionsRef.current = false;

    setShowOptions(!showOptions);
  };

  const onRemove = (id: string) => {
    onRemoveValue(id);
  };

  const changeSelectedIndex = (index: number) => {
    setOptionIndex(index);
    setDisableAutoScroll(true);
  };

  const optionsFiltered = selectedOptions?.filter(
    (item, index) => selectedOptions.findIndex((option) => option.id === item.id) === index
  );

  const selectedOptionsShowed = () => {
    return (
      <>
        {position === 'right' && optionsFiltered.length > 0 ? (
          <div
            className='divider-line--small'
            style={{ height: `calc((${height} * 100) / 100)` }}
          />
        ) : null}

        <div className={`selected-values ${position === 'left' ? 'left' : ''}`}>
          {optionsFiltered.map((option) => (
            <MultiSelectValue
              key={option.id}
              value={option.name}
              id={option.id}
              fontClass={fontClass}
              onRemove={onRemove}
            />
          ))}
        </div>

        {position === 'left' && optionsFiltered.length > 0 ? (
          <div
            className='divider-line--small'
            style={{ height: `calc((${height} * 100) / 100)` }}
          />
        ) : null}
      </>
    );
  };

  return (
    <div className={'input multi-select'} ref={wrapperRef}>
      {label && <label className='input-label-font on-light-text-color'>{label}</label>}
      <div className={`multi-select-wrapper ${position === 'left' ? 'left' : ''}`}>
        {position === 'left' ? selectedOptionsShowed() : null}
        <div className='error-wrapper'>
          <div
            className={`input__select flex ${fontClass} ${size} ${
              error ? 'error-border-color' : 'input-border-color'
            }`}
            style={{ height }}>
            {icon && (
              <div
                className={`image-wrapper ${error ? 'error-border-color' : 'input-border-color'}`}>
                <img src={icon} alt='input-icon' width={18} />
              </div>
            )}

            <div
              className={`input__select__content on-light-text-color ${fontClass}`}
              onClick={showHideOptions}>
              {value.name || <span className='placeholder'>{placeholder}</span>}
              <img
                src='/images/icons/chevron.svg'
                alt='chevron'
                className={`${showOptions ? 'rotate180' : ''}`}
              />
              {showOptions && (
                <div className={'select-options input-border-color'}>
                  {options.map((option, index) => (
                    <SelectOption
                      key={option.id}
                      value={option}
                      onClickOption={onChangeValue}
                      fontClass={fontClass}
                      height={height}
                      optionIndex={optionIndex}
                      index={index}
                      changeSelectedIndex={changeSelectedIndex}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          {error && (
            <span className={`error-text-filter error-font error-text-color`}>
              {error.description ? error.description : t('error.requiredField')}
            </span>
          )}
        </div>
        {position === 'right' ? selectedOptionsShowed() : null}
      </div>
    </div>
  );
};

export default MultiSelect;
