import { useTranslation } from 'react-i18next';
import Button from '../../ui/button/Button';
import { useFiltersContext, useFiltersMenuContext } from './Filters';
import './styles.scss';

type Props = {
  blacklistedFilters?: {
    all?: string[];
    count?: string[];
  };
};

const FilterHandlers: React.FC<Props> = ({ blacklistedFilters }) => {
  const { t } = useTranslation();

  const { filters, setFilters } = useFiltersContext();
  const { open, setOpen } = useFiltersMenuContext();

  const length = filters.filter(
    (filter) =>
      !blacklistedFilters?.all?.includes(filter.field) &&
      !blacklistedFilters?.count?.includes(filter.field)
  ).length;

  const removeFilters = () => {
    setFilters((prev) => prev.filter((filter) => blacklistedFilters?.all?.includes(filter.field)));
  };

  return (
    <div className='filter-content'>
      <div className='filter-buttons'>
        <Button
          icon={open ? '/images/icons/filterWhite.svg' : '/images/icons/filter.svg'}
          lookAndFeel={open ? 'primary' : 'secondary'}
          onClick={() => setOpen((prev) => !prev)}
          size='small'
          text={t('general.filter')}></Button>
        {(open || length > 0) && (
          <Button
            text={
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {t('filter.remove')}
                {length ? <div className='filter-total primary-bg-color'>{length}</div> : null}
              </div>
            }
            lookAndFeel={'secondary'}
            onClick={removeFilters}
            size='small'
            icon='/images/icons/bin.svg'
          />
        )}
      </div>
    </div>
  );
};

export default FilterHandlers;
