import { HTMLAttributes } from 'react';
import './styles.scss';

export enum ButtonArrowType {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down'
}

interface Props extends HTMLAttributes<HTMLButtonElement> {
  type: ButtonArrowType;
}

const ButtonArrow = ({ type, ...rest }: Props) => {
  return (
    <button {...rest} className={'button-arrow ' + type}>
      <img src='/images/icons/chevronRight.svg' alt='arrow-button' />
    </button>
  );
};

export default ButtonArrow;
