import { useTranslation } from 'react-i18next';
import {
  BOOK_MEETING,
  MORE_INFO_SBTI,
  SBTI_COMMITMENT_LETTER,
  SBTI_OFFICIAL_FORM
} from '../../../../constants';
import Button from '../../../ui/button/Button';
import CardCTA from '../../../ui/cards/cardCTA/CardCTA';

export const HeaderInformativeTab = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'sbti' });
  const handleRedirect = (url: string) => window.open(url);

  return (
    <CardCTA>
      <CardCTA.Header>
        <span className='headline4-font'>{t('start')}</span>
        <span className='subtitle3-font text-justify' style={{ width: '60%' }}>
          {t('startDescription1')}
        </span>

        <div
          className='subtitle3-font flex-col items-start'
          style={{ marginTop: '0.5rem', width: '60%' }}>
          <div className='flex'>
            <span>{t('instructions.step1')} </span>
            <span
              className='highlight-text-color pointer weight-600 m-0-2'
              onClick={() => handleRedirect(SBTI_OFFICIAL_FORM)}>
              {t('instructions.sbtiForm')}
            </span>
          </div>
          <div className='flex'>
            <span>{t('instructions.step2')} </span>

            <span
              className='highlight-text-color pointer weight-600 m-0-2'
              onClick={() => handleRedirect(SBTI_COMMITMENT_LETTER)}>
              {t('instructions.letter')}
            </span>
          </div>
          <span>{t('instructions.step3')}</span>
          <span>{t('instructions.step4')}</span>
          <span>{t('instructions.step5')}</span>
          <span>{t('instructions.step6')}</span>
        </div>
      </CardCTA.Header>
      <CardCTA.Buttons>
        <Button
          lookAndFeel='primary'
          text={t('moreInfo')}
          size='small'
          onClick={() => handleRedirect(MORE_INFO_SBTI)}
        />
        <Button
          lookAndFeel='secondary'
          text={t('bookMeeting')}
          size='small'
          onClick={() => handleRedirect(BOOK_MEETING)}
        />
      </CardCTA.Buttons>
    </CardCTA>
  );
};
