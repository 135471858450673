import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentYear, getPreviousYear } from '../utils/utils';
import { useColumns } from './useColumns';
import { useGetEsgImpacts } from './useGetEsgImpact';

const useEsgImpact = () => {
  const { t } = useTranslation();
  const previousYear = getPreviousYear();
  const currentYear = getCurrentYear();

  const [selects, setSelects] = useState({
    companyOrFacility: { id: 'organization', name: t('dashboard.organization') },
    companyOrFacilityEsgMetric: { id: 'total_co2e', name: t('esgMetricsDetails.total_co2e') }
  });
  const { companyOrFacility, companyOrFacilityEsgMetric } = selects;

  const { columnsImpacts } = useColumns(companyOrFacilityEsgMetric.id, companyOrFacility.id);

  const { data: impactsData, isLoading: isLoadingImpacts } = useGetEsgImpacts({
    dateRanges: [
      [`${previousYear}/01/01`, `${previousYear}/12/31`],
      [`${currentYear}/01/01`, `${currentYear}/12/31`]
    ],
    mode: selects.companyOrFacility.id,
    metric: selects.companyOrFacilityEsgMetric.id
  });

  const onChangeValue = function (attribute: keyof typeof selects) {
    return function changeValue(value: SelectOptionFormat<string>) {
      setSelects((prev) => ({
        ...prev,
        [attribute]: value
      }));
    };
  };

  const unit = impactsData ? impactsData[0]?.unit : '-';

  return {
    companyOrFacility,
    companyOrFacilityEsgMetric,
    setCompanyOrFacility: onChangeValue('companyOrFacility'),
    setCompanyOrFacilityEsgMetric: onChangeValue('companyOrFacilityEsgMetric'),
    impactsData,
    isLoadingImpacts,
    columnsImpacts,
    unit
  };
};

export default useEsgImpact;
