import { FundDetailType } from '../types/entities/groupFunds';
import {
  IBackendFundRelation,
  IBackendFundRelationHistoric,
  IBackendLatestInvestment,
  IBackendLinkedCompany,
  IBackendPatchFundRelationHistoric,
  IFrontendFundRelationHistoric,
  IFrontendLatestInvestment,
  IFrontendParentCompany,
  IGetBackendParentCompany
} from '../types/entities/organization';
import { convertDateBackToFront } from '../utils/convertDates';

export const adaptMatricesParentsBackToFront = (
  value: IGetBackendParentCompany
): IFrontendParentCompany => {
  return {
    id: value.parent_id,
    name: value.parent_name,
    country: value.country,
    isGroupFund: value.is_group_fund,
    status: value.status,
    role: value.role
  };
};

export const adaptParentLinkedCompanyBackToFront = (
  value: IBackendLinkedCompany
): IFrontendParentCompany => {
  return {
    id: value.parent_org_id,
    name: value.parent_name,
    country: value.country,
    isGroupFund: value.is_group_fund,
    status: value.status,
    role: 'parent'
  };
};

export const adaptFundRelationHistoricBackToFront = (
  value: IBackendFundRelationHistoric
): IFrontendFundRelationHistoric => {
  return {
    id: value.id,
    fundId: value.fund_id,
    fundName: value.fund_name,
    investmentValue: value.investment_value,
    enterpriseValue: value.enterprise_value,
    investmentDate: convertDateBackToFront(value.investment_date),
    tag: value.tag
  };
};

export const adaptFundRelationBackToFront = (
  value: IBackendFundRelation
): IFrontendFundRelationHistoric => {
  return {
    id: '',
    fundId: value.fund_id,
    fundName: '',
    investmentValue: value.investment_value,
    enterpriseValue: value.enterprise_value,
    investmentDate: convertDateBackToFront(value.investment_date),
    tag: value.tag
  };
};

export const adaptLatestInvestmentBackToFront = (
  value: IBackendLatestInvestment
): IFrontendLatestInvestment => {
  return {
    lastInvestmentValue: value.last_investment_value,
    lastEnterpriseValue: value.last_enterprise_value,
    lastInvestmentDate: convertDateBackToFront(value.last_investment_date),
    lastTag: value.last_tag
  };
};

export const adaptFundRelationPatchToFundRelation = (
  value: IBackendPatchFundRelationHistoric
): IBackendFundRelation => {
  return {
    fund_id: value.fund_id,
    child_id: value.investee_organization_id,
    investment_value: value.investment_value,
    enterprise_value: value.enterprise_value,
    investment_date: value.investment_date,
    tag: value.tag
  };
};
