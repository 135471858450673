import { useEffect, useState } from 'react';
import { SIZE_PAGINATION_SMALL } from '../../../../../../../constants';
import { getLcaList } from '../../../../../../../services/api/lca';
import useFetchInfiniteList from '../../../../../../ui/infiniteList/hooks/useFetchInfiniteList';

type Props = {
  searchValue: string;
};

const useFetchLcas = ({ searchValue }: Props) => {
  const [firstTimeOptions, setFirstTimeOption] = useState<SelectOptionFormat[]>([]);

  const fetchData = async (pageNumber: number) => {
    const response = await getLcaList(pageNumber, 5, searchValue);
    if (response?.response?.status >= 400) return;
    return response;
  };

  const {
    data,
    fetchData: fetch,
    firstLoading
  } = useFetchInfiniteList<ILCABack>(fetchData, [searchValue]);

  useEffect(() => {
    if (!searchValue) setFirstTimeOption(data);
  });

  return { data: searchValue ? data : firstTimeOptions, fetch, loading: firstLoading };
};

export default useFetchLcas;
