import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import { editElement } from './utils';

export const useMutateCache = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  const editInList = <T extends { id: string }>(newValue: T, key: keyof T & string) =>
    queryClient.setQueriesData<InfiniteData<{ items: T[] }>>(
      { queryKey },
      editElement(newValue, key)
    );

  const invalidate = () => queryClient.invalidateQueries({ queryKey });

  return { editInList, invalidate };
};
