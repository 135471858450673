import { useTranslation } from 'react-i18next';
import useErrors from '../../../../customHooks/useErrors';
import Form from '../form/Form';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import { useState } from 'react';
import { postCustomEFGroup } from '../../../../services/api/customEF';
import useAdapterCustomEFGroup from '../../../../adapters/customEFGroup';

type Props = {
  onClose: () => void;
  onAddGroup: (element: ICustomEFGroupBack) => void;
};

const AddGroupModal = ({ onClose, onAddGroup }: Props) => {
  const ERRORS = useErrors();
  const { t } = useTranslation('translation', { keyPrefix: 'customize.create' });

  const { adaptCustomEFGroupFront2Back } = useAdapterCustomEFGroup();

  const [error, setError] = useState<ErrorType>();

  const handleCreate = async (element: ICustomEFGroupFront) => {
    // adapt data
    const elementFormatted = adaptCustomEFGroupFront2Back(element);

    const response = await postCustomEFGroup({ data: elementFormatted });

    if (response?.response?.status >= 400) {
      setError(ERRORS.FORM.SOMETHING_WENT_WRONG);
      return;
    }

    onAddGroup(response);
    onClose();
  };

  return (
    <>
      <FormHeader title={t('titleAdd')} description={t('descriptionAdd')} />
      <Form onSubmit={handleCreate} error={error} />
    </>
  );
};

export default AddGroupModal;
