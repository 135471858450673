import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { months } from '../../../../constants/months';
import { Month } from '../../../../types/utils/months';

type Props = {
  startDate: string;
  endDate: string;
};
const useDataCo2 = ({ startDate, endDate }: Props) => {
  const { i18n } = useTranslation();

  // Get list of months between start and end date
  const dateStart = moment.utc(startDate, 'DD/MM/YYYY');
  const dateEnd = moment.utc(endDate, 'DD/MM/YYYY');
  const timeValues = [];

  while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push({ x: dateStart.format('YYYY-MM'), y: 0 });
    dateStart.add(1, 'month');
  }

  type ChartData = {
    x: string;
    y: number;
  };
  const dataCo2: ChartData[] = timeValues;

  const monthLabels = timeValues.map((elem) => {
    const date = moment(elem.x);
    const month = months.find((month) => month.number === date.month() + 1);

    const lang = i18n.resolvedLanguage as keyof Month['short'];

    return month ? `${month.short[lang]} ${date.format('YY')}` : '';
  });
  return { dataCo2, monthLabels };
};

export default useDataCo2;
