import { formatRoundNumber } from '../../../../../../utils/formatNumber';
import BlueWrapper from '../../../../components/blueWrapper/BlueWrapper';
import Name from '../components/name/Name';
import { EmployeeGroupByTransportType } from '../types/types';

const useGetParsedData = (SIZE: number) => {
  const parseData = (data: EmployeeGroupByTransportType[]) => {
    let returnData = data.map((value) => {
      let name = value.id;
      if (value.id === null && value.situation !== 'active') name = 'leave';
      return {
        name: <Name name={name} />,
        nEmployees: <p className='body1-font'>{value.nEmployees}</p>,
        co2: <p className='body1-font'>{formatRoundNumber(value.co2e / 1000)}</p>,
        total: <BlueWrapper>{formatRoundNumber(value.total)}%</BlueWrapper>
      };
    });

    returnData = returnData.slice(0, SIZE);
    const restData = data.slice(SIZE, data.length);

    const nEmployees = restData.reduce((acc, current) => acc + current.nEmployees, 0);
    const co2 = restData.reduce((acc, current) => acc + current.co2e, 0);
    const total = restData.reduce((acc, current) => acc + current.total, 0);

    returnData.push({
      name: <Name name={'remaining'} />,
      nEmployees: <p className='body1-font'>{nEmployees}</p>,
      co2: <p className='body1-font'>{formatRoundNumber(co2 / 1000)}</p>,
      total: <BlueWrapper>{formatRoundNumber(total)}%</BlueWrapper>
    });

    return returnData;
  };

  return { parseData };
};

export default useGetParsedData;
