import { EventAttendee } from '../../../../../../types/entities/events';
import InputMultiple from '../../../../../ui/formComponents/inputMultiple/InputMultiple';
import InputNumber from '../../../../../ui/formComponents/inputNumber/InputNumber';
import InputText from '../../../../../ui/formComponents/inputText/InputText';

type Props = {
  transport: SelectOptionFormat;
  labels?: string[];
  onChangeValue: (value: EventAttendee) => void;
  formData: EventAttendee;
};
const InputAttendee = ({ transport, labels, formData, onChangeValue }: Props) => {
  const onChangeAttendeeNumber = (value: string) => {
    onChangeValue({
      ...formData,
      quantity: +value
    });
  };

  const onChangeAttendeeKm = (value: string) => {
    onChangeValue({
      ...formData,
      distance: +value
    });
  };

  return (
    <InputMultiple
      inputs={[
        {
          input: (
            <InputNumber
              icon={'/images/icons/people.svg'}
              placeholder={'0'}
              value={formData.quantity ? formData.quantity.toString() : ''}
              onChangeValue={onChangeAttendeeNumber}
            />
          ),
          label: labels && labels.length > 0 ? labels[0] : ''
        },
        {
          input: (
            <InputNumber
              icon={''}
              placeholder={'0'}
              value={formData.distance ? formData.distance?.toString() : ''}
              onChangeValue={onChangeAttendeeKm}
            />
          ),
          label: labels && labels.length > 1 ? labels[1] : ''
        },
        {
          input: <InputText icon={''} placeholder={''} value={transport.name} />,
          label: labels && labels.length > 2 ? labels[2] : ''
        }
      ]}
    />
  );
};

export default InputAttendee;
