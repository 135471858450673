import { useQuery } from '@tanstack/react-query';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getLCAMaterials } from '../../../../services/api/lca/lcaPortfolio';

export const useAllLCAMaterials = () => {
  const org = useSelectedOrganization();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['lca-materials', org?.id, 'all'],
    queryFn: () => getLCAMaterials({ page: 1, size: 1_000_000 }),
    select: (data) => data.items
  });

  return {
    data,
    isLoading,
    isFetching
  };
};
