import { ReactElement } from 'react';
import './styles.scss';

type TagContent = {
  icon1?: string;
  text?: string | ReactElement;
  icon2?: string;
  text2?: string | ReactElement;
  isSecondary?: boolean;
};

type Props = {
  tags: TagContent[];
  direction?: 'row' | 'column';
};
function Tag({ tags, direction = 'row' }: Props) {
  return (
    <div className={`tag-wrapper tag-wrapper--${direction}`}>
      {tags.map((tag, index) => {
        const styleIconSecondary = tag.isSecondary ? { opacity: '50%' } : undefined;
        return (
          <div key={`tag-${index}`} className='tag-elem'>
            {tag.icon1 && <img src={tag.icon1} alt='icon1-tag' style={styleIconSecondary} />}
            {tag.text && tag.text}
            {tag.icon2 && <img src={tag.icon2} alt='icon2-tag' />}
            {tag.text2 && tag.text2}
          </div>
        );
      })}
    </div>
  );
}

export default Tag;
