import { useTranslation } from 'react-i18next';
import SectionHeader from '../../../layout/sectionHeader/SectionHeader';
import Breadcrumb from '../../../layout/breadcrumb/Breadcrumb';
import './styles.scss';

type Props = {
  children: React.ReactNode;
};

export const Layout = ({ children }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'codeManagement'
  });

  return (
    <div className='code-management__layout'>
      <SectionHeader title={t('sectionTitle')} breadcrumb={<Breadcrumb />} />
      {children}
    </div>
  );
};
