import { useState } from 'react';
import { useLCATranslation } from '../../../../../customHooks/translations/useLCATranslation';
import Button from '../../../../ui/button/Button';
import InputWrapper from '../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../ui/formComponents2/select/Select';
import TotalLegendV2 from '../../../../ui/totalLegend/TotalLegendV2';
import { LCADiagram } from '../../../Scenarios/components/LCADiagram';
import { useACVId } from '../../../Scenarios/hooks/useACVId';
import { adaptLCAImpactCategories } from '../adapters/adaptLCAImpactCategories';
import { LCADetailFacade } from '../components/LCADetailFacade';
import { useLCADetail } from '../hooks/useLCADetail';
import { ImpactCategoryProvider } from './ImpactCategoryContext';
import { buildLegendUnitFromNodeImpact } from '../../../utils';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../actions/notification';
import { useLCATotalImpact } from 'components/lca/Scenarios/hooks/useLCATotalImpact';

export const LCADetailCanvas = () => {
  const { t } = useLCATranslation();
  const { acv_id } = useACVId();
  const { data } = useLCADetail(acv_id);
  const dispatch = useDispatch();

  const impactCategories = adaptLCAImpactCategories(data?.impact_categories, t);

  const [impactCategory, setImpactCategory] = useState<SelectOptionFormat<string>>(() => {
    if (impactCategories.length > 0) {
      return impactCategories[0];
    }

    return { id: '', name: '' };
  });

  const { totalImpact, refetch, isFetching } = useLCATotalImpact(acv_id, impactCategory);

  const handleCalculate = () => {
    refetch();
    dispatch(setNotification(t('lcaDetail.processFlow.header.calculatingNotification')));
  };

  return (
    <ImpactCategoryProvider impactCategory={impactCategory}>
      <div className='flex justify-between'>
        <div className='flex gap-x-2'>
          <div className='bg-white'>
            <InputWrapper iconV2='cloud'>
              <span className='p-2 font-body-b2-sb'>
                {t('lcaDetail.processFlow.header.impactCategory')}
              </span>
              <Select
                options={impactCategories}
                value={impactCategory}
                onChange={setImpactCategory}
                style={{ width: '12.5rem' }}
              />
            </InputWrapper>
          </div>
          <Button
            lookAndFeel='primary'
            text={t('lcaDetail.processFlow.header.calculate')}
            size='small'
            style={{ width: 'fit-content' }}
            onClick={handleCalculate}
            loading={isFetching}
          />
        </div>
        <div className='flex gap-x-4'>
          <TotalLegendV2
            totalLabel={t('lcaDetail.processFlow.header.totalImpact')}
            totalElements={[
              {
                label: totalImpact ? buildLegendUnitFromNodeImpact(totalImpact) : '',
                value: totalImpact?.sum_total_impact
                  ? Number(totalImpact.sum_total_impact.toFixed(2))
                  : 0
              }
            ]}
          />
          <LCADetailFacade />
        </div>
      </div>
      <LCADiagram />
    </ImpactCategoryProvider>
  );
};
