import InputEmail from '../../inputEmail/InputEmail';
import InputWrapper from '../../inputUtils/inputWrapper/InputWrapper';

type InputWrapperType = Omit<React.ComponentProps<typeof InputWrapper>, 'children'>;
type InputEmailType = React.ComponentProps<typeof InputEmail>;

type Props = InputEmailType & InputWrapperType;

const FormEmail = (props: Props) => {
  return (
    <InputWrapper {...props}>
      <InputEmail {...props} />
    </InputWrapper>
  );
};

export default FormEmail;
