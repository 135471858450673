import {
  IFrontendFormBusinessTravel,
  IGetBackendBusinessTravel
} from '../../../../../types/entities/businessTravels';
import { User } from '../../../../../types/entities/user';

import FormBusinessTravel from '../formBusinessTravel/FormBusinessTravel';
import './styles.scss';

type Props = {
  user: User | null;
  addBusinessTravel: (value: IGetBackendBusinessTravel) => void;
  postBusinessTravel: (formData: IFrontendFormBusinessTravel) => Promise<IGetBackendBusinessTravel>;
  error?: ErrorType;
};

function AddBusinessTravel({ addBusinessTravel, postBusinessTravel, error }: Props) {
  const handleAddBusinessTravel = async (formData: IFrontendFormBusinessTravel) => {
    const response = await postBusinessTravel(formData);

    if (!response) return;
    const businessTravelData: IGetBackendBusinessTravel = response;
    addBusinessTravel(businessTravelData);
  };

  return <FormBusinessTravel submit={handleAddBusinessTravel} error={error} />;
}

export default AddBusinessTravel;
