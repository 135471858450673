import React, { useEffect, useState } from 'react';
import { getOrganizationUsageMetrics } from '../../../../../../services/api/admin';
type MetricOrganization = {
  organizationId: string;
  organizationDataQuality: string;
  limits: {
    facilities: number;
    vehicles: number;
    employees: number;
    business_travels: number;
    users: number;
    shipments: number;
    deliveries: number;
    purchases: number;
  };
  facilities: number;
  vehicles: number;
  employees: number;
  business_travels: number;
  purchases: number;
  transport_downstream: number;
  transport_upstream: number;
};
type Props = {
  id: string;
};
const useGetData = ({ id }: Props) => {
  const [data, setData] = useState<MetricOrganization>();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOrganizationUsageMetrics(id);
      if (!response) return;
      setData({
        ...response,
        organizationId: response.organization_id,
        organizationDataQuality: response.organization_data_quality
      });
    };
    fetchData();
  }, [id]);
  return data;
};

export default useGetData;
