import { useTranslation } from 'react-i18next';
import { GroupDataMode } from '../../../../types/entitiesEnums/report';
import './styles.scss';
import { convertDateToString, convertDateToStringFrontend } from '../../../../utils/convertDates';

type Props = {
  startDate: string;
  endDate: string;
  groupDataMode: GroupDataMode;
};
const FrontPage = ({ startDate, endDate }: Props) => {
  const { t } = useTranslation();
  const today = new Date();
  return (
    <div className='ghg-front-page'>
      <div className='title'>
        <h1 className='on-dark-text-color headline1-big-font'>{t('ghgReport.frontPageTitle')}</h1>
        <h1 className='on-dark-text-color headline1-big-font-bolder'>
          {t('ghgReport.frontPageTitle2')}
        </h1>
      </div>
      <div className='content'>
        <p className='on-dark-text-color subtitle3-font'>{`${t(
          'ghgReport.co2Analysis'
        )} ${startDate} ${t('ghgReport.to')} ${endDate}`}</p>
        <p
          className='on-dark-text-color subtitle3-font'
          style={{
            marginTop: '2.5rem'
          }}>
          {t('ghgReport.version', { date: convertDateToStringFrontend(today) })}
        </p>
      </div>
    </div>
  );
};

export default FrontPage;
