import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';
import InformationCard from '../../../../ui/InformationCard/InformationCard';
import './styles.scss';

type Props = {
  goBack: () => void;
};
const HowToExport = ({ goBack }: Props) => {
  const { t } = useTranslation();

  const [urlToShow, setUrlToShow] = React.useState<string>('');

  const cards = [
    {
      id: 'holded',
      image: '/images/howTo/holded.png',
      description: t('purchases.howToExportHolded'),
      url: 'https://dcycle-app-guias.webflow.io/holded'
    },
    {
      id: 'odoo',
      image: '/images/howTo/odoo.png',
      description: t('purchases.howToExportOdoo'),
      url: 'https://dcycle-app-guias.webflow.io/odoo'
    },
    {
      id: 'sage',
      image: '/images/howTo/sage.png',
      description: t('purchases.howToExportSage'),
      url: 'https://dcycle-app-guias.webflow.io/sage'
    },
    {
      id: 'other',
      image: '/images/howTo/excel.png',
      description: (
        <>
          {t('purchases.howToExportOther')}
          <span className='highlight-text-color'> hello@dcycle.io</span>
        </>
      ),
      url: ''
    }
  ];
  return (
    <div className='how-to-export'>
      <FormHeader
        title={t('purchases.howToExportTitle')}
        description={t('purchases.howToExportDescription')}
      />
      <div className='card-list'>
        {cards.map((card) => (
          <InformationCard
            key={card.id}
            image={card.image}
            description={card.description}
            url={card.url}
          />
        ))}
      </div>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('purchases.understoodGoBackAndUpload')}
          onClick={goBack}
        />
      </FormButtonSection>
    </div>
  );
};

export default HowToExport;
