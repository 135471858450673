import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateGrouping } from '../../../../services/api/grouping';
import { GroupingFormData, GroupingType } from '../../../../types/entities/grouping';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import GroupingForm from '../common/GroupingForm';

type Props = {
  editGrouping: (grouping: GroupingType) => void;
  groupingToEdit: GroupingType;
};
const EditGrouping = ({ editGrouping, groupingToEdit }: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<GroupingFormData>({
    name: groupingToEdit.name ?? '',
    description: groupingToEdit.description ?? '',
    errors: []
  });

  const onSubmit = async () => {
    const data = await updateGrouping(groupingToEdit.id, {
      name: formData.name,
      description: formData.description
    });
    if (!data) {
      setFormData({
        ...formData,
        errors: [
          ...formData.errors,
          {
            error: 'general',
            description: t('error.somethingWentWrong')
          }
        ]
      });
      return;
    }
    // Edit grouping in list
    editGrouping({
      id: groupingToEdit.id,
      name: formData.name,
      description: formData.description
    });
  };

  return (
    <div>
      <FormHeader title={t('grouping.editTitle')} description={t('grouping.editDescription')} />
      <GroupingForm formData={formData} setFormData={setFormData} onSubmit={onSubmit} />
    </div>
  );
};

export default EditGrouping;
