import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAdaptCriteria from '../../../../../../adapters/sbtiCriteria';
import { getCriteriaList } from '../../../../../../services/api/sbti';

const useCriteriaList = () => {
  const { id } = useParams();
  const { adaptCriteriaBack2Table } = useAdaptCriteria();

  const [criteriaList, setCriteriaList] = useState<ISbtiCriteriaBack[]>([]);
  const [criteriaListTable, setCriteriaListTable] = useState<ISbtiCriteriaTable[]>([]);
  const [loading, setLoading] = useState(true);

  const handleNewCriterias = (criteria: ISbtiCriteriaBack[]) => {
    if (criteria?.length === undefined) return;
    setCriteriaList(criteria);
    setCriteriaListTable(criteria?.map((criteria) => adaptCriteriaBack2Table(criteria)) || []);
  };

  const getCriteriaListFunction = async () => {
    if (!id) return;
    setLoading(true);
    const response = await getCriteriaList(id);
    setLoading(false);

    if (response?.response?.status >= 400 || !response) return;

    const criteriaListResponse: ISbtiCriteriaBack[] = response;
    handleNewCriterias(criteriaListResponse);
  };

  useEffect(() => {
    getCriteriaListFunction();
  }, [id]);

  return { criteriaList, criteriaListTable, handleNewCriterias, loading };
};

export default useCriteriaList;
