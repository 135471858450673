import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../../../context/userContext';
import { deleteFund } from '../../../../../services/api/funds';
import Button from '../../../../ui/button/Button';
import FormButtonSection from '../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from '../../../../ui/formComponents/formHeader/FormHeader';

type Props = {
  id: string;
  deleteFundData: (id: string) => void;
};

const DeleteFund = ({ id, deleteFundData }: Props) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleDeleteRepsonse = async () => {
    if (!user?.selectedOrganization || loadingButton) return;
    setLoadingButton(true);
    const response = await deleteFund(id);

    if (response?.response?.status >= 400) {
      setLoadingButton(false);
      return;
    }

    deleteFundData(id);
    setLoadingButton(false);
  };

  return (
    <div className='delete-modal'>
      <FormHeader title={t('funds.deleteFund')} description={t('funds.areYouSureDelete')} />
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('funds.delete')}
          onClick={handleDeleteRepsonse}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
};

export default DeleteFund;
