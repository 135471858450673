import React from 'react';
import InformationCardPlain from './InformationCardPlain';
import './styles.scss';
interface Props extends React.ComponentProps<typeof InformationCardPlain> {
  image: string;
  description: string | React.ReactNode;
  url: string;
}
const InformationCard = ({ url, ...rest }: Props) => {
  const handleOnClick = () => window.open(url, '_blank');
  return <InformationCardPlain {...rest} onClick={handleOnClick} />;
};

export default InformationCard;
